import { useQuery } from "@apollo/client";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import React from "react";
import { ErrorLoadingComponent } from "src/components";
import { IGetMe, IRestaurant, IRestaurantReview } from "src/models";
import { GET_RESTAURANT_REVIEWS } from "./api";
import { RestaurantReview } from "./restaurant-review";
import { useStyles } from "./restaurant-reviews.styles";

interface RestaurantReviewsProps {
  restaurant: IRestaurant;
  getMe?: IGetMe;
}

interface RestaurantReviewsData {
  getRestaurantReviewsByName: {
    count: number;
    rows: IRestaurantReview[];
  };
  error: { message: string };
  loading: boolean;
}

export const RestaurantReviews: React.FC<RestaurantReviewsProps> = ({
  restaurant,
  getMe,
}) => {
  const classes = useStyles();
  const { data, error, loading, fetchMore, variables, client } = useQuery<
    RestaurantReviewsData
  >(GET_RESTAURANT_REVIEWS, {
    variables: {
      input: {
        name: restaurant.name,
        pagePL: {
          limit: 3,
        },
      },
    },
  });
  const limitRef = React.useRef<number>(3);

  const displayShowMore = () => {
    if (data && data.getRestaurantReviewsByName) {
      return (
        data.getRestaurantReviewsByName.rows.length <
        data.getRestaurantReviewsByName.count
      );
    }
    return false;
  };
  const displayMinimize = () => {
    if (data && data.getRestaurantReviewsByName) {
      return data.getRestaurantReviewsByName.count < 4
        ? false
        : data.getRestaurantReviewsByName.rows.length ===
            data.getRestaurantReviewsByName.count;
    }
    return false;
  };

  const onClickMinimize = () => {
    const res = client.readQuery({ query: GET_RESTAURANT_REVIEWS, variables });
    client.writeQuery({
      query: GET_RESTAURANT_REVIEWS,
      variables,
      data: {
        getRestaurantReviewsByName: {
          ...res.getRestaurantReviewsByName,
          rows: res.getRestaurantReviewsByName.rows.slice(0, 3),
        },
      },
    });

    limitRef.current = 3;
  };
  const onClickDisplayMore = () =>
    fetchMore({
      variables: {
        ...variables,
        input: {
          ...variables?.input,
          pagePL: {
            ...variables?.input.pagePL,
            limit: limitRef.current + 3,
          },
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, fetchMoreResult);
      },
    }).then(() => (limitRef.current += 3));

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }

  if (data?.getRestaurantReviewsByName?.count === 0)
    return (
      <Typography
        variant="body2"
        component="p"
        style={{ marginTop: "1rem" }}
        className={classes.text}
      >
        This home restaurant has no reviews yet. Purchase a meal and let us know
        what you think!
      </Typography>
    );

  return (
    <div>
      <ul data-testid="restaurant-review-list" className={classes.container}>
        {data?.getRestaurantReviewsByName?.rows.map((review, idx) => (
          <li key={review.id}>
            <RestaurantReview
              review={review}
              restaurant={restaurant}
              getMe={getMe}
              isLastChild={
                idx + 1 === data.getRestaurantReviewsByName.rows.length
              }
            />
          </li>
        ))}
      </ul>
      {displayShowMore() && (
        <Button
          classes={{ outlined: classes.expansionButton }}
          onClick={onClickDisplayMore}
          variant="outlined"
          disabled={loading}
        >
          {loading ? <CircularProgress /> : "More Reviews"}
        </Button>
      )}
      {displayMinimize() && (
        <Button
          classes={{ outlined: classes.expansionButton }}
          variant="outlined"
          onClick={onClickMinimize}
          disabled={loading}
        >
          {loading ? <CircularProgress /> : "Minimize"}
        </Button>
      )}
    </div>
  );
};
