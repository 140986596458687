import { gql } from "@apollo/client";

export const USER_UPDATE_EMAIL = gql`
  mutation userUpdateEmail($input: String!) {
    userUpdateEmail(email: $input) {
      id
      email
      firstName
      lastName
    }
  }
`;
