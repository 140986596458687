import { gql } from "@apollo/client";

export const COOK_CREATE_EVENT = gql`
  mutation cookCreateEvent($input: CookCreateEventPL!) {
    cookCreateEvent(cookCreateEventPL: $input) {
      id
      name
      description
      locale {
        private
        public
      }
      price
      minSeats
      maxSeats
      startTime
      endTime
      bufferTime
      reserveBy
      tags
      deliveryInfo {
        baseAmount
        maximumDriveTime
        minimumOrderAmount
        pricePerMin
        pricePerMile
      }
    }
  }
`;

export const COOK_UPDATE_EVENT = gql`
  mutation cookUpdateEvent($input: EventUpdatePL!) {
    cookUpdateEvent(eventUpdatePL: $input) {
      id
      name
      description
      imagesGQL {
        publicId
        small
        medium
        large
        original
      }
      tags
      price
      private
      reserveBy
      startTime
      endTime
      minSeats
      maxSeats
      status
      type
      bufferTime
      deliveryInfo {
        baseAmount
        maximumDriveTime
        minimumOrderAmount
        pricePerMin
        pricePerMile
      }
      instructions
      locale {
        public
        private
      }
      restaurant {
        id
        imagesGQL {
          publicId
          small
          medium
          large
          original
        }
        application {
          id
          status
        }
      }
      guests {
        id
      }
      dishes {
        id
        name
        __typename @skip(if: true)
        imagesGQL {
          publicId
          small
          medium
          large
          original
        }
        DishEvent {
          id
          count
          price
        }
      }
    }
  }
`;

export const COOK_UPDATE_ACTIVE_EVENT = gql`
  mutation cookUpdateActiveEvent($input: ActiveEventUpdatePL!) {
    cookUpdateActiveEvent(activeEventUpdatePL: $input) {
      id
      name
      description
      imagesGQL {
        publicId
        small
        medium
        large
        original
      }
      price
      private
      reserveBy
      bufferTime
      startTime
      deliveryInfo {
        baseAmount
        maximumDriveTime
        minimumOrderAmount
        pricePerMile
        pricePerMin
      }
      endTime
      minSeats
      maxSeats
      instructions
      locale {
        public
        private
      }
    }
  }
`;

export const DELETE_DISH_EVENT = gql`
  mutation deleteDishEvent($input: DeleteDishEventPL!) {
    deleteDishEvent(deleteDishEventPL: $input)
  }
`;

export const CANCEL_EVENT = gql`
  mutation cookCancelEvent($input: ID!) {
    cookCancelEvent(id: $input) {
      id
      status
      updatedAt
      guests {
        id
      }
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation deleteEvent($input: ID!) {
    deleteEvent(id: $input)
  }
`;

export const PUBLISH_EVENT = gql`
  mutation cookPublishEvent($input: CookPublishEventPL!) {
    cookPublishEvent(cookPublishEventPL: $input) {
      id
      status
      name
    }
  }
`;

export const CHECKIN_EVENT = gql`
  mutation checkInEventUser($input: CheckInPL!) {
    checkInEventUser(checkInPL: $input) {
      id
      checkedIn
    }
  }
`;

export const CHECKIN_ORDER = gql`
  mutation cookCheckInOrder($input: CookCheckInOrderPL!) {
    cookCheckInOrder(cookCheckInOrderPL: $input) {
      id
      checkedIn
    }
  }
`;

export const DUPLICATE_EVENT = gql`
  mutation duplicateEvent($input: EventDuplicatePL!) {
    duplicateEvent(eventDuplicatePL: $input) {
      id
      name
      description
      imagesGQL {
        publicId
        small
        medium
        large
        original
      }
      tags
      price
      private
      reserveBy
      startTime
      endTime
      minSeats
      maxSeats
      status
      type
      bufferTime
      instructions
      locale {
        public
        private
      }
      dishes {
        id
        name
        imagesGQL {
          publicId
          small
          medium
          large
          original
        }
        DishEvent {
          id
          count
          price
        }
        __typename @skip(if: true)
      }
    }
  }
`;
