import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  bannerContainer: {
    width: "100%",
    maxWidth: "80rem",
    padding: "2rem",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  whiteListContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr",
    gap: "0px 1rem",
  },
  saveBtn: {
    marginTop: "1rem",
  },
});
