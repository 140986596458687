import { gql } from "@apollo/client";

export const USER_UPDATE_ADDRESS = gql`
  mutation userUpdateAddressWithIp($input: String!) {
    userUpdateAddressWithIp(ip: $input) {
      id
      address
    }
  }
`;
