import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  iconBtn: {
    position: "fixed",
    bottom: 20,
    right: 20,
    zIndex: 100,
    backgroundColor: COLORS.LIGHT_RED,
  },
  iconBtnRoot: {
    "&:hover": {
      backgroundColor: COLORS.RED,
    },
  },
  helperBtn: { color: COLORS.WHITE },
});
