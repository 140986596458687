import { Divider, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { IEvent, IGetMe } from "src/models";
import { DishItem } from "./dish-item";
import { useDishListStyles } from "./dish-list.styles";

interface IProps {
  getEvent: IEvent;
  getMe?: IGetMe;
}

export const DishList: FC<IProps> = ({ getEvent, getMe }) => {
  const classes = useDishListStyles();
  const categories = [
    { type: "ENTREE", label: "Entree(s)" },
    { type: "SIDE", label: "Sides" },
    { type: "DESSERT", label: "Dessert" },
    { type: "BEVERAGE", label: "Beverage" },
  ];
  return (
    <div className={classes.container}>
      {categories.map(
        (category) =>
          getEvent.dishes.find((d) => d.category === category.type) && (
            <section
              className={classes.dishCategorySection}
              key={category.type}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={classes.categoryTitle}
                >
                  {category.label}
                </Typography>
                <Divider className={classes.divider} />
              </div>
              <div className={classes.dishes}>
                {getEvent.dishes
                  .filter((d) => d.category === category.type)
                  .map((dish) => (
                    <DishItem
                      key={dish.id}
                      getEvent={getEvent}
                      dish={dish}
                      displayPrice={!getEvent.type.includes("FIXED_TIME")}
                      canNotEdit={getEvent.type.includes("FIXED_TIME")}
                    />
                  ))}
              </div>
            </section>
          )
      )}
    </div>
  );
};
