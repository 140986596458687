import { Button, Dialog, IconButton, Typography } from "@material-ui/core/";
import { Room } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { GoogleMapContainer } from "src/components";
import { IRestaurant } from "src/models";
import { COLORS } from "src/styles";
import { useStyles } from "./location-dialog.styles";

interface IProps {
  showLocationDialog: boolean;
  closeLocationDialog: () => void;
  restaurant: IRestaurant;
}

export const LocationDialog: React.FC<IProps> = ({
  showLocationDialog,
  closeLocationDialog,
  restaurant,
}) => {
  const classes = useStyles();
  const [mapHeight, setMapHeight] = useState<any>(0);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    setTimeout(() => {
      const height = inputRef?.current?.offsetHeight;
      setMapHeight(height);
    }, 300);
  }, [inputRef, showLocationDialog]);

  return (
    <Dialog
      open={showLocationDialog}
      onClose={closeLocationDialog}
      aria-labelledby="welcome-dialog"
      fullWidth
      maxWidth="sm"
      classes={{ paper: classes.dialog }}
    >
      <IconButton
        className={classes.closeBtnWelcomeDialog}
        aria-label="close-dialog"
        size="small"
        onClick={closeLocationDialog}
      >
        <CloseIcon style={{ color: COLORS.WHITE }} />
      </IconButton>

      <section
        className={classNames(classes.container, classes.headerContainer)}
      >
        <Typography
          component="h2"
          variant="h2"
          className={(classes.container, classes.h2)}
        >
          Location
        </Typography>
      </section>
      {restaurant.locale && (
        <section className={classes.h4Background}>
          <Typography
            component="h4"
            variant="h4"
            className={classNames(classes.container, classes.h4)}
          >
            <Room style={{ fontSize: "3rem" }} />
            {restaurant.locale.public}
          </Typography>
        </section>
      )}
      <section
        className={classNames(classes.container, classes.contentContainer)}
      >
        <Typography component="p" variant="body1" className={classes.subtitle}>
          Exact address is given out{" "}
          <span style={{ fontFamily: "CustomFourBold" }}>
            when you complete your order to protect the cook’s privacy.
          </span>{" "}
          Below is a map with the general pickup location within a 1/2 mile
          radius.
        </Typography>
      </section>
      {restaurant.locale && (
        <div style={{ flexGrow: 1 }} id="someDiv" ref={inputRef}>
          <section
            id="google-map-container"
            style={{
              height: `${mapHeight}px`,
              width: "100%",
              display: "flex",
              flexFlow: "row nowrap",
              justifyContent: "center",
              padding: 0,
            }}
          >
            <GoogleMapContainer
              zoom={14}
              options={{
                fullscreenControl: false,
                zoomControl: true,
                zoomControlOptions: {
                  position: 8,
                },
              }}
              circleOverlay
              center={
                restaurant.locale.coordinates
                  ? {
                      lat: restaurant.locale.coordinates.latitude,
                      lng: restaurant.locale.coordinates.longitude,
                    }
                  : undefined
              }
            />
          </section>
        </div>
      )}
      <Button
        variant="contained"
        color="secondary"
        onClick={closeLocationDialog}
        classes={{ root: classes.gotItBtn }}
      >
        Got It!
      </Button>
    </Dialog>
  );
};
