import { gql } from "@apollo/client";

export const DELETE_ROOM = gql`
  mutation adminDeleteRoom($input: ID!) {
    adminDeleteRoom(id: $input)
  }
`;

export const SEND_MSG = gql`
  mutation userSendMsg($input: UserSendMsgPL!) {
    userSendMsg(userSendMsgPL: $input)
  }
`;

export const COOK_CREATE_SPECIAL_EVENT = gql`
  mutation cookCreateSpecialEvent($input: CookCreateSpecialEventPL!) {
    cookCreateSpecialEvent(cookCreateSpecialEventPL: $input) {
      id
      url
      description
      price
      startTime
    }
  }
`;

export const DINER_INIT_CONVERSATION = gql`
  mutation dinerInitConversation($input: DinerInitConversationPL!) {
    dinerInitConversation(dinerInitConversationPL: $input)
  }
`;
