import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { _hsq } from ".";
import { tagManager } from "./utils/react-gtm";

export const withTracker = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>,
  options = {}
) => {
  const trackPage = (page: string) => {
    tagManager.track({
      event: "PageView",
      pagePath: page,
    });
  };

  return (props: P) => {
    useEffect(() => {
      trackPage(props.location.pathname);
      _hsq?.push(["trackPageView"]);
    }, [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };
};
