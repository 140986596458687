import { useMutation, useQuery } from "@apollo/client";
import { Button, Chip, Typography } from "@material-ui/core";
import { Cancel, Edit, Publish, Share } from "@material-ui/icons";
import { TimePicker } from "material-ui-pickers";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { FC, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { DateIcon } from "src/components";
import { IEvent } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { FOODNOME_PRODUCTION_BASE_URL } from "src/utils/constants";
import { DishCard } from "./dish-card/dish-card";
import { COOK_GET_COUPON, COOK_UPDATE_SOLD_OUT_TIME_SLOTS } from "./graphql";
import { useStyles } from "./header.styles";
import { Message } from "./message";
import { config } from "./share.config";

const moment = extendMoment(Moment as any);

interface IProps {
  event: IEvent;
  openConfirmationDialog: (action: "PUBLISH" | "CANCEL") => void;
  // openDuplicateDialog: () => void;
}

export const Header: FC<IProps> = ({ event, openConfirmationDialog }) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const { data } = useQuery(COOK_GET_COUPON);
  const [fromTime, setFromTime] = useState(
    moment(Number(event.startTime)).add(15, "minutes")
  );
  const [toTime, setToTime] = useState(
    moment(Number(event.startTime)).add(30, "minutes")
  );
  const [markSoldOutTimeSlots] = useMutation(COOK_UPDATE_SOLD_OUT_TIME_SLOTS, {
    refetchQueries: ["cookGetEvent"],
  });

  function getDisplayDateTime() {
    const now = moment();
    const startTime = moment(Number(event.startTime));
    const endTime = moment(Number(event.endTime));
    const updatedAt = moment(Number(event.updatedAt));
    const eventRange = moment.range(startTime, endTime);

    if (event.status === "CANCELLED")
      return `Your event was cancelled ${updatedAt.fromNow()}.`;

    if (event.status === "ACTIVE") {
      if (now.isBefore(startTime))
        return `Your event is in ${startTime.fromNow(true)}.`;
      else if (eventRange.contains(now)) return `Your event is ongoing.`;
    }

    if (event.status === "PENDING") return `Your event hasn't been published.`;

    return `Your event was ${startTime.fromNow()}.`;
  }

  return (
    <div className={classes.container}>
      <Typography
        variant="body2"
        component="p"
        onClick={() => sharedAPI.setContactModal(true)}
        className={classes.help}
      >
        Need help?
      </Typography>
      <div className={classes.headerContainer}>
        <div style={{ marginRight: ".5rem" }}>
          <Typography variant="h2" component="h2" className={classes.header}>
            {getDisplayDateTime()}
          </Typography>
          {data?.cookGetCoupon && (
            <Typography variant="body2" component="p" className={classes.note}>
              Share your discount code{" "}
              <span className={classes.couponCode}>
                {data.cookGetCoupon.code}
              </span>{" "}
              to give your customers $5 off their 1st order over $20!
            </Typography>
          )}
        </div>
        <div className={classes.actionButtonsContainer}>
          {renderActionButtons({
            event,
            onClickEdit: () => history.push(`/c/menus/edit/${id}`),
            onCancelEvent: () => openConfirmationDialog("CANCEL"),
            onPublishEvent: () => openConfirmationDialog("PUBLISH"),
            onClickShare: () =>
              sharedAPI.share(
                `${FOODNOME_PRODUCTION_BASE_URL}/menus/${event.id}`,
                config
              ),
            classes,
          })}
        </div>
      </div>
      <div className={classes.eventOverviewContainer}>
        <div className={classes.eventOverviewLayout}>
          <div className={classes.eventOverviewTop}>
            <div style={{ marginTop: 10 }}>
              <DateIcon date={event.startTime} />
            </div>
            <div className={classes.eventOverviewTopText}>
              <Typography variant="h4" component="h3" style={{ margin: 0 }}>
                <Link to={`/menus/${event.id}`} className={classes.eventTitle}>
                  {event.name}
                </Link>
              </Typography>
              <Typography
                variant="body2"
                component="time"
                className={classes.eventTime}
              >{`${moment(Number(event.startTime)).format("h:mma")}-${moment(
                Number(event.endTime)
              ).format("h:mma")}`}</Typography>
              {event.locale && (
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.eventLocation}
                >
                  {event.locale.private}
                </Typography>
              )}
            </div>
          </div>
          {!event.type.includes("FIXED_TIME") && (
            <section className={classes.soldOutSection}>
              <Typography
                variant="h6"
                component="h4"
                className={classes.subHeader}
              >
                Sold out time slot(s)
              </Typography>
              {!event.type.includes("FIXED_TIME") &&
                Boolean(event.soldOutTimeSlots?.length) && (
                  <div className={classes.soldOutContainer}>
                    {event.soldOutTimeSlots?.map((slot, idx) => {
                      return (
                        <Chip
                          className={classes.chip}
                          key={idx}
                          label={`${moment(Number(slot[0])).format(
                            "hh:mm a"
                          )} - ${moment(Number(slot[1])).format("hh:mm a")}`}
                          clickable
                          color="primary"
                          onDelete={() =>
                            markSoldOutTimeSlots({
                              variables: {
                                input: {
                                  id: event.id,
                                  soldOutTimeSlots: event.soldOutTimeSlots?.filter(
                                    (_, i) => i !== idx
                                  ),
                                },
                              },
                            }).then(() =>
                              sharedAPI.setSnackbarMsg({
                                type: "success",
                                msg: "Deleted",
                              })
                            )
                          }
                        />
                      );
                    })}
                  </div>
                )}

              <div className={classes.blockOffContainer}>
                <Typography variant="body2" component="p">
                  Stop taking orders from
                </Typography>
                <TimePicker
                  className={classes.timeTextField}
                  value={fromTime}
                  onChange={setFromTime}
                />
                <Typography variant="body2" component="p">
                  to
                </Typography>
                <TimePicker
                  className={classes.timeTextField}
                  value={toTime}
                  onChange={setToTime}
                />
                <Button
                  className={classes.saveBlockOffBtn}
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() =>
                    confirm(
                      `Are you sure you want to stop taking orders from ${moment(
                        fromTime
                      ).format("LT")} to ${moment(toTime).format("LT")}?`
                    )
                      ? markSoldOutTimeSlots({
                          variables: {
                            input: {
                              id: event.id,
                              soldOutTimeSlots: [
                                [
                                  fromTime.valueOf().toString(),
                                  toTime.valueOf().toString(),
                                ],
                                ...(event.soldOutTimeSlots ?? []),
                              ],
                            },
                          },
                        }).then(() =>
                          sharedAPI.setSnackbarMsg({
                            type: "success",
                            msg: "Added",
                          })
                        )
                      : ""
                  }
                >
                  Save
                </Button>
              </div>

              <div className={classes.eventOverviewBottom}>
                <Typography
                  variant="h6"
                  component="h4"
                  className={classes.subHeader}
                >
                  Dish(es) Sold
                </Typography>
                <ul
                  className={classes.orderedDishesList}
                  data-testid="all-ordered-dishes"
                >
                  {event.dishes
                    // .filter((d) => d.DishEvent && d.DishEvent.sold)
                    .map((d) => (
                      <DishCard key={d.id} dish={d} />
                    ))}
                </ul>
              </div>
            </section>
          )}
        </div>
        <div className={classes.eventMetricsContainer}>
          <div className={classes.guestCountContainer}>
            <Typography
              variant="h4"
              component="h4"
              className={classes.eventMetricUnits}
            >
              # guests
            </Typography>
            <Typography
              variant="h4"
              component="h4"
              className={classes.metricValue}
              data-testid="header-guest-count"
            >
              {event.guestCount}
            </Typography>
          </div>
          <div className={classes.totalSaleContainer}>
            <Typography
              variant="h4"
              component="h4"
              className={classes.eventMetricUnits}
            >
              $ total
            </Typography>
            <Typography
              variant="h4"
              component="h4"
              className={classes.metricValue}
              data-testid="header-sale-total"
            >
              ${event.totalSale?.toFixed(2)}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

function renderActionButtons({
  event,
  onClickEdit,
  onCancelEvent,
  onPublishEvent,
  onClickShare,
}: {
  event: IEvent;
  onClickEdit: () => void;
  onCancelEvent: () => void;
  onPublishEvent: () => void;
  onClickShare: () => void;
  classes: any;
}) {
  const classes = useStyles();
  const now = moment();
  const endTime = moment(Number(event.endTime));

  if (now.isAfter(endTime)) return null;

  if (event.status === "ACTIVE") {
    return (
      <>
        <Button
          onClick={onCancelEvent}
          classes={{ root: classes.actionBtn }}
          size="small"
          variant="outlined"
          endIcon={<Cancel />}
        >
          Cancel
        </Button>
        <Button
          classes={{ root: classes.actionBtn }}
          size="small"
          onClick={onClickEdit}
          variant="outlined"
          endIcon={<Edit />}
        >
          Edit
        </Button>
        <Button
          variant="outlined"
          title="share to social media"
          classes={{ root: classes.actionBtn }}
          size="small"
          aria-label="share to social media"
          onClick={onClickShare}
          endIcon={<Share />}
        >
          Share
        </Button>
        <Message event={event} />
      </>
    );
  }

  if (event.status === "PENDING") {
    return (
      <>
        <Button
          onClick={onClickEdit}
          classes={{ root: classes.actionBtn }}
          size="small"
          variant="outlined"
        >
          Edit
          <Edit style={{ marginLeft: 4 }} />
        </Button>
        <Button
          classes={{ root: classes.actionBtn }}
          onClick={onPublishEvent}
          size="small"
          variant="outlined"
        >
          Publish
          <Publish style={{ marginLeft: 4 }} />
        </Button>
        <Message event={event} />
      </>
    );
  }
}
