import {
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import HelpIcon from "@material-ui/icons/Help";
import isEqual from "lodash/isEqual";
import React, { useState } from "react";
import { Prompt } from "react-router-dom";
import { AllergenSelect } from "src/components";
import {
  DietaryTypes,
  DishCategory,
  DishOption,
  IAllergen,
  IImage
} from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { DietaryTypeSelect } from "./dietary-select";
import { DishImageUploaderContainer } from "./dish-image-uploader";
import { DishTypeSelect } from "./dish-type-select";
import { useStyles } from "./form.styles";
import { DishOptions } from "./options";
import { initialState, reducer } from "./state";
import { Actions } from "./state/actions";
import { IDishFormState } from "./state/reducers";

export interface DishFormSubmitPL {
  category?: string;
  name: string;
  description: string;
  tags: string[];
  allergens: IAllergen[];
  dietary: string;
  isPublished: boolean;
  images: IImage[];
  options: DishOption[];
  defaultPrice?: number;
}

interface IProps {
  handleSubmit: (pl: DishFormSubmitPL) => Promise<any>;
  init?: IDishFormState;
  edit?: boolean;
}

export const DishForm: React.FC<IProps> = ({
  handleSubmit,
  init = initialState,
  edit = false,
}) => {
  const classes = useStyles();
  const [state, dispatch] = React.useReducer(reducer, init);
  const [isLoading, setLoading] = useState<boolean>(false);
  const forcedUnblock = React.useRef<boolean>(true);
  const isBlocked = !isEqual(state, init) || isLoading;

  const onSubmit = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    e.stopPropagation();
    forcedUnblock.current = false;
    setLoading(true);
    const {
      dishType,
      name,
      description,
      dietary,
      isPublished,
      defaultPrice,
    } = e.target.elements;

    handleSubmit({
      options: state.options,
      category: dishType.value,
      defaultPrice: Number(state.defaultPrice),
      name: name.value,
      description: description.value,
      tags: state.tags,
      allergens: state.allergens,
      dietary: dietary.value,
      isPublished: isPublished.checked,
      images: state.images.length
        ? state.images
        : [
            {
              publicId: "publicId",
              small:
                "https://foodnome.sfo2.cdn.digitaloceanspaces.com/beats.jpg",
              medium:
                "https://foodnome.sfo2.cdn.digitaloceanspaces.com/beats.jpg",
              large:
                "https://foodnome.sfo2.cdn.digitaloceanspaces.com/beats.jpg",
              original:
                "https://foodnome.sfo2.cdn.digitaloceanspaces.com/beats.jpg",
            },
          ],
    }).catch((err) => {
      forcedUnblock.current = true;
      sharedAPI.setSnackbarMsg({
        type: "error",
        msg: err.message.replace(/graphql error: /i, ""),
      });
      setLoading(false);
    });
  };

  const onInputChange = (e) => {
    dispatch({
      type: Actions.SET_INPUT,
      key: e.target.name,
      value: e.target.value,
    });
  };

  // Dish Image Carousel Handlers
  const onUploading = () => setLoading(true);
  const onUploaded = (image) => {
    dispatch({
      type: Actions.UPLOAD_IMAGE,
      image,
    });
    setLoading(false);
  };
  const onDeleteDishImage = (publicId: string) =>
    dispatch({
      type: Actions.DELETE_DISH_IMAGE,
      publicId,
    });
  const onSelect = (name: "dietary" | "type") => (value: string) =>
    dispatch({ type: Actions.SET_INPUT, key: name, value });
  const setTags = (tags: string[]) =>
    dispatch({ type: Actions.SET_INPUT, key: "tags", value: tags });

  const addOption = (option: string) => {
    if (
      !state.options.some(
        (optionArr) => optionArr.name.toLowerCase() === option.toLowerCase()
      )
    ) {
      return dispatch({
        type: Actions.SET_INPUT,
        key: "options",
        value: [{ name: option, addition: 0 }, ...state.options],
      });
    }
    sharedAPI.setSnackbarMsg({
      type: "error",
      msg: "Option has already been added",
    });
  };
  const removeOption = (event, option: string) => {
    return dispatch({
      type: Actions.SET_INPUT,
      key: "options",
      value: state.options.filter((o) => o.name !== option),
    });
  };

  const setAllergens = (allergens: IAllergen[]) =>
    dispatch({ type: Actions.SET_INPUT, key: "allergens", value: allergens });
  const setPublish = (e: { target: { name: string; checked: boolean } }) =>
    dispatch({
      type: Actions.SET_INPUT,
      key: e.target.name,
      value: e.target.checked,
    });

  return (
    <>
      <Prompt
        when={isBlocked && forcedUnblock.current}
        message="Are you sure you want to leave without saving your changes?"
      />
      <form
        className={classes.form}
        onSubmit={(e) => onSubmit(e)}
        id="dish-form"
      >
        <div className={classes.formleft}>
          <DishImageUploaderContainer
            onDeleteImage={onDeleteDishImage}
            onUploaded={onUploaded}
            onUploading={onUploading}
            images={state.images}
          />
        </div>
        <div className={classes.formRight}>
          <Typography
            component="label"
            variant="caption"
            className={classes.label}
            htmlFor="name-input"
          >
            Dish Name*
          </Typography>
          <TextField
            size="small"
            required
            name="name"
            id="name-input"
            placeholder="Takoyaki"
            rows="2"
            onChange={onInputChange}
            value={state.name}
            fullWidth
            inputProps={{
              type: "text",
              maxLength: 200,
              pattern: "[^()]+",
              title: "alpha numeric values only, no parentheses.",
            }}
            InputProps={{
              disableUnderline: true,
              classes: {
                input: classes.input,
              },
            }}
          />

          <Typography
            component="label"
            variant="caption"
            className={classes.label}
            htmlFor="description-input"
          >
            Description*
          </Typography>
          <TextField
            size="small"
            id="description-input"
            name="description"
            rows="5"
            multiline
            required
            fullWidth
            placeholder="Tell people about your dish. Be descriptive and tell them how much is in a serving."
            onChange={onInputChange}
            value={state.description}
            inputProps={{ maxLength: 600 }}
            InputProps={{
              disableUnderline: true,
              classes: {
                input: classes.input,
              },
            }}
          />

          <Typography
            component="label"
            variant="caption"
            className={classes.label}
            htmlFor="price-input"
          >
            Price ($)*
          </Typography>
          <TextField
            size="small"
            required
            name="defaultPrice"
            id="price-input"
            placeholder="0"
            onChange={onInputChange}
            value={state.defaultPrice}
            fullWidth
            inputProps={{
              type: "number",
              step: ".01",
              min: "0",
            }}
            InputProps={{
              disableUnderline: true,
              classes: {
                input: classes.input,
              },
            }}
          />
          <DishTypeSelect
            onSelect={onSelect("type")}
            initialState={state.category as DishCategory}
          />

          <DishOptions
            options={state.options}
            addOption={addOption}
            removeOption={removeOption}
          />

          <AllergenSelect
            onSelect={setAllergens}
            selectedAllergens={state.allergens}
          />

          <DietaryTypeSelect
            onSelect={onSelect("dietary")}
            initialState={state.dietary as DietaryTypes}
          />

          <div style={{ marginBottom: "1.5rem" }}>
            <Typography
              component="label"
              variant="caption"
              className={classes.label}
              htmlFor="isPublished"
            >
              Publish dish to my profile
            </Typography>
            <Tooltip
              title={
                "Select to display the dish on your restaurant profile page."
              }
              disableFocusListener
              classes={{ tooltip: classes.toolTip }}
            >
              <IconButton disableRipple disableFocusRipple size="small">
                <HelpIcon />
              </IconButton>
            </Tooltip>
            <Checkbox
              id="isPublished"
              name="isPublished"
              checked={state.isPublished}
              onChange={setPublish}
              aria-describedby="publish-dish-info"
            />
          </div>

          <Button
            disabled={isLoading}
            classes={{ containedPrimary: classes.createDishButton }}
            variant="contained"
            color="secondary"
            type="submit"
            data-e2e={`create-dish-button`}
          >
            {isLoading ? (
              <CircularProgress style={{ color: COLORS.DARK_GREY }} />
            ) : (
              `${edit ? "Update" : "Create"} dish`
            )}
          </Button>
        </div>
      </form>
    </>
  );
};
