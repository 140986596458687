import {
  Button,
  Card,
  makeStyles,
  Snackbar,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { COLORS } from "src/styles/colors";
import { _appstore } from "src/utils/constants";

interface DownloadCheckProps {
  isCordova?: boolean;
  isApple?: boolean;
  isGoogle?: boolean;
}

const useStyles = makeStyles({
  card: {
    display: "flex",
    alignItems: "center",
    padding: ".5rem",
    border: `1px solid ${COLORS.LIGHT_GREY}`,
    boxShadow: "none",
    width: "100%",
  },
  logo: {
    border: `1px solid ${COLORS.LIGHT_GREY}`,
    borderRadius: 5,
  },
  cardLayout: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "flex-start",
    padding: ".5rem",
  },
  title: {
    fontWeight: 100,
    textAlign: "center",
    margin: "0 0 .5rem 0",
  },
});

export const DownloadCheck: React.FC<DownloadCheckProps> = ({
  isCordova = process.env.REACT_APP_ENV === "cordova",
  isApple = /iphone|ipad|ipod|ipados|ios/i.test(
    navigator.userAgent.toLowerCase()
  ),
  isGoogle = /android/i.test(navigator.userAgent.toLowerCase()),
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isCordova) {
      if (isApple || isGoogle) {
        setOpen(true);
      }
    }
  }, []);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={5000}
      ContentProps={{
        "aria-describedby": "message-id",
        className: "",
      }}
      onClose={() => setOpen(false)}
    >
      <Card className={classes.card}>
        <img
          className={classes.logo}
          alt=""
          width="64px"
          height="64px"
          src={require(`../../assets/logos/logo-icon.png`)}
        />
        <div className={classes.cardLayout}>
          <Typography
            variant="h2"
            component="h2"
            id="message-id"
            className={classes.title}
          >
            Download the Foodnome App!
          </Typography>
          <Button
            style={{ padding: 0 }}
            component="a"
            {...({
              href: isApple ? _appstore.apple : _appstore.android,
              target: "_blank",
              rel: "noopener noreferrer",
            } as any)}
          >
            <img
              style={{ width: 100 }}
              alt=""
              src={require(`../../assets/icons/${
                isApple ? "app-apple-03.svg" : "app-google-02.svg"
              }`)}
            />
          </Button>
        </div>
      </Card>
    </Snackbar>
  );
};
