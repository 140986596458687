import {
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import React from "react";
// import { getEmailError } from 'src/utils/user-helpers';
import { Link } from "react-router-dom";
import { COLORS } from "src/styles";
import { styles as useStyles } from "../styles";

interface IProps {
  getToken: (email: string) => void;
  errorText?: string;
}

export const Layout: React.FC<IProps> = ({ getToken, errorText }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.image} />
      <div className={classes.formWrapper}>
        <form
          style={{ marginTop: "2rem" }}
          className={classes.form}
          onSubmit={(event: any) => {
            event.preventDefault();
            const { email } = event.target.elements;
            return getToken(email.value.toLowerCase() as string);
          }}
        >
          <section className={classes.fieldsContainer}>
            <div className={classes.fieldsWrapper}>
              <Typography variant="h2" component="h1" className={classes.h1}>
                Forgot Your Password?
              </Typography>
              <Typography variant="body2" component="p" className={classes.p}>
                Send Reset Link
              </Typography>
              <Typography
                component="label"
                variant="caption"
                className={classes.label}
                htmlFor="email-input"
              >
                Enter your email
              </Typography>
              <TextField
                size="small"
                autoComplete="off"
                required
                name="email"
                type="email"
                variant="outlined"
                placeholder="Email"
                id="email-input"
                {...({ "data-e2e": "email" } as any)}
                fullWidth
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperTextRoot,
                  },
                }}
                helperText={errorText}
                inputProps={{ maxLength: 60 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailIcon style={{ color: COLORS.DARK_GREY }} />
                    </InputAdornment>
                  ),
                  classes: {
                    input: classes.signUpInput,
                    root: classes.inputRoot,
                    focused: classes.inputFocused,
                  },
                }}
              />
              <Button
                type="submit"
                data-testid="forgot-btn"
                id="forgot-submit-btn"
                className={classes.actionButton}
                variant="contained"
              >
                Send Reset Link
              </Button>
            </div>
            <Link
              to="/login"
              style={{
                marginTop: 10,
                textDecoration: "none",
                fontFamily: "CustomFourBold",
                color: COLORS.DARK_RED,
              }}
            >
              <Typography variant="caption" component="p">
                Or click here to Log in
              </Typography>
            </Link>
          </section>
        </form>
      </div>
    </div>
  );
};
