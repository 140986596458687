import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useLayoutStyles = makeStyles({
  logo: {
    cursor: "pointer",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  logoIconLarge: {
    display: "block",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: 135,
    },
  },
  logoIconSmall: { width: 50 },
  appBarDesktop: {
    backgroundColor: COLORS.WHITE,
    boxShadow: `none`,
    borderBottom: "solid 1px #d3d3d3",
  },
  appBarDesktopInner: {
    width: "100%",
    margin: `0 auto`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white !important",
    minHeight: 56,
  },
  rightContainer: {
    minHeight: 40,
    alignSelf: "stretch",
    display: "flex",
    alignItems: "center",
  },
});
