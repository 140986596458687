import TagManager from "react-gtm-module";

export const gtmId =
  process.env.REACT_APP_ENV === "production" ||
  process.env.REACT_APP_ENV === "cordova"
    ? (process.env.REACT_APP_GTM as string)
    : "";

interface TrackPL {
  event?: string;
  pagePath?: string;
  value?: any;
}
export const tagManager = {
  init: () => TagManager.initialize({ gtmId }),
  track: (d: TrackPL) => TagManager.initialize({ gtmId, events: d }),
};
