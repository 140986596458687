export enum Actions {
  SET_FIELD = "SET_FIELD",
  UPLOAD_IMAGE = "UPLOAD_IMAGE",
  TOGGLE_IS_MILE = "TOGGLE_IS_MILE",
}

interface SetFieldActions {
  type: Actions.SET_FIELD;
  [key: string]: any;
}

interface SetImage {
  type: Actions.UPLOAD_IMAGE;
  image?: any;
}

interface ToggleIsMile {
  type: Actions.TOGGLE_IS_MILE;
}

export type IAction = SetFieldActions | SetImage | ToggleIsMile;
