import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles/colors";

export const useStyles = makeStyles({
  dropContainer: {
    border: "1px solid #8EBC6D",
    borderRadius: "2px",
    backgroundColor: "rgba(136,173,109,0.07)",
    boxShadow: "-4px 3px 0 0 #86B563",
    width: "100%",
    height: "69px",
    cursor: "pointer",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
  },
  disabledDrop: {
    cursor: "not-allowed",
  },
  dropZoneText: {},
  label: {
    color: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
  },
  btnContainer: {
    borderStyle: "dashed",
    borderColor: COLORS.MEDIUM_GREEN,
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: "2px",
  },
  chip: {
    marginTop: "10px",
  },
});
