import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React, { FC } from "react";
import { IGetMe } from "src/models";
import { COLORS } from "src/styles/colors";

interface IPropsNomes {
  getMe: IGetMe;
  fullSize?: boolean;
  bgc?: boolean;
}

export const useStyles = makeStyles({
  nomeInfoContainer: {
    padding: "15px 12px",
  },
  text: {
    textAlign: "center",
  },
  nomeInfoFullSize: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  bgcText: {
    textAlign: "center",

    color: COLORS.WHITE,
  },
});

export const NomeInfoContainer: FC<IPropsNomes> = ({
  getMe,
  fullSize,
  bgc,
}) => {
  const classes = useStyles();
  return (
    <div
      data-testid="nome-count"
      className={classNames(
        classes.nomeInfoContainer,
        fullSize && classes.nomeInfoFullSize
      )}
      style={{
        borderRadius: fullSize ? "none" : "2px",
        backgroundColor: bgc ? COLORS.RED : "#ffe272",
      }}
    >
      {bgc ? (
        <div
          className={classes.bgcText}
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <span>You have: </span>
          <span
            style={{
              fontFamily: "Arial",
              fontWeight: "bold",
              margin: "0 15px",
            }}
          >
            {getMe.nomes}
          </span>
          <span
            style={{
              textDecoration: "underline",
              textUnderlinePosition: "under",
            }}
          >
            <i> Nomes</i>
          </span>
        </div>
      ) : (
        <div
          className={classes.text}
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <span style={{ marginRight: 7.5 }}>You have:</span>
          <span style={{ fontFamily: "Arial", fontWeight: "bold" }}>
            {getMe.nomes}
          </span>
          <span
            style={{
              textDecoration: "underline",
              textUnderlinePosition: "under",
              marginLeft: 7.5,
            }}
          >
            <i>Nomes</i>
          </span>
        </div>
      )}
    </div>
  );
};
