import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "transparent",
    },
  },
  scroller: {
    borderBottom: `1px solid ${COLORS.LIGHT_GREY}`,
    marginBottom: "1rem",
  },
  rootTab: {
    textTransform: "capitalize",
    minWidth: 0,
    minHeight: 40,
    [`@media (min-width: ${BREAK_POINTS.mobileRegular}em)`]: {
      minWidth: 120,
    },
  },
  tabLabel: {},
  selected: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: `${COLORS.MEDIUM_RED} !important`,
  },
  colorSecondary: {
    backgroundColor: COLORS.RED,
  },
  labelContainer: {
    padding: "1rem 0.5rem",
  },
  textColorInherit: {
    color: COLORS.BLACK,
    opacity: 1,
  },
  tabsRoot: {
    width: "100%",
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: COLORS.WHITE,
    minHeight: 40,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: "unset",
    },
  },
});
