import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import DayPicker, {
  DayModifiers,
  DayPickerProps as _DayPickerProps,
} from "react-day-picker";
import "react-day-picker/lib/style.css";

export interface SelectMultipleEventDatesProps {
  maxSelected?: number;
  eventStartDateTime: Moment;
  DayPickerProps?: Partial<_DayPickerProps>;
  onSelectDay?: (dates: Date[]) => void;
}

export const SelectMultipleEventDates: React.FC<SelectMultipleEventDatesProps> = ({
  maxSelected = 5,
  eventStartDateTime,
  DayPickerProps = {},
  onSelectDay = () => ({}),
}) => {
  const [selectedDays, setSelectedDays] = useState<Date[]>([]);

  useEffect(() => {
    if (selectedDays.length > maxSelected) {
      alert(
        `Oops, you can other select up to ${maxSelected} future dates to repeat this menu.`
      );
    }
  }, [selectedDays.length, maxSelected]);

  const disableDays = (day: Date) => {
    const isPast = moment(day).isBefore(moment());
    const isBeforeOriginalStart = moment(day).isSameOrBefore(
      eventStartDateTime,
      "day"
    );
    return isPast || isBeforeOriginalStart;
  };

  const handleClickDay = (date: Date, { disabled }: DayModifiers) => {
    if (disabled) return;

    const isAlreadySelected = selectedDays.some((_date) =>
      moment(_date).isSame(moment(date), "day")
    );
    if (isAlreadySelected)
      setSelectedDays((prev) => {
        const transformed = prev.filter(
          (_date) => !moment(_date).isSame(moment(date), "day")
        );
        onSelectDay(transformed);
        return transformed;
      });
    else if (selectedDays.length + 1 > maxSelected)
      alert(
        `You can only choose ${maxSelected} future days to repeat this event.`
      );
    else
      setSelectedDays((prev) => {
        const transformed = prev.concat([date]);
        onSelectDay(transformed);
        return transformed;
      });
  };

  return (
    <DayPicker
      disabledDays={disableDays}
      selectedDays={selectedDays}
      onDayClick={handleClickDay}
      {...DayPickerProps}
    />
  );
};
