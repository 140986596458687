import { Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

interface IProps {
  error?: any;
  closeDialog?: () => void;
}
export const LoginError: FunctionComponent<IProps> = ({
  error,
  closeDialog,
}) => {
  const errorMsg = error ? error.message : null;

  if (!errorMsg) {
    return null;
  }
  return (
    <Typography
      data-testid="login-error"
      color="error"
      component="p"
      variant="body2"
      style={{
        marginTop: "6px",
        textAlign: "left",
      }}
    >
      {errorMsg}.
      <Link onClick={closeDialog} to={`/get-reset-token`}>
        {" "}
        Reset your password?
      </Link>
    </Typography>
  );
};
