import { useQuery } from "@apollo/client";
import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { userAccountAPI } from "src/components/header/api";
import { chatClient } from "src/index";
import { IGetMe } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import {
  GET_ACTIVE_MODE,
  GET_AUTH_DRAWER_STATE,
  GET_ME,
} from "src/shared-graphql/queries";
import { BREAK_POINTS } from "src/styles";
import { Layout } from "./layout";

interface AuthDrawerProps extends RouteComponentProps<any> {
  isCordova?: boolean;
  forceOpen?: boolean; // for testing purposes...
}

const AuthDrawer: React.FC<AuthDrawerProps> = ({
  isCordova = process.env.REACT_APP_ENV === "cordova",
  forceOpen = false,
  history,
}) => {
  const { data: drawerState } = useQuery<{ getAuthDrawerState: boolean }>(
    GET_AUTH_DRAWER_STATE
  );
  const { data, error, loading } = useQuery<{ getMe: IGetMe }>(GET_ME, {
    fetchPolicy: "cache-and-network",
  });
  const { data: dataActiveMode } = useQuery(GET_ACTIVE_MODE);
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

  const toggleCookMode = () => {
    sharedAPI.setActiveMode(!dataActiveMode.getActiveMode);

    setTimeout(
      () =>
        history.push(
          dataActiveMode && !dataActiveMode.getActiveMode ? "/c" : "/menus"
        ),
      300
    );
  };

  const handleLogout = async () => {
    // We want to refresh the page once logged out to
    // clear state that could cause issues. Possibly not
    // the best practice
    return userAccountAPI
      .logout()
      .then(() => {
        handleClose();
        chatClient?.disconnect();
      })
      // .then(() => window.location.reload());
      .then(() => {
      // @kazam: Redirect to the homepage and reload the page.
      // Reloading has been kept for unknown historical reasons,
      // see above comment for more details, maybe unecessary
        history.push('/');
        history.go(0);
      });
  };
  const handleClose = () => sharedAPI.setAuthDrawerState(false);

  // Only required to satisfy SwipeableDrawer props
  const handleOpen = () => sharedAPI.setAuthDrawerState(true);

  if (error || loading || (data && !data.getMe)) return null;

  return (
    <Layout
      open={forceOpen || Boolean(drawerState && drawerState.getAuthDrawerState)}
      isCookMode={Boolean(dataActiveMode && dataActiveMode.getActiveMode)}
      toggleCookMode={toggleCookMode}
      handleLogout={handleLogout}
      handleClose={handleClose}
      handleOpen={handleOpen}
      user={(data && data.getMe) as IGetMe}
      isCordova={isCordova}
    />
  );
};

export default withRouter(AuthDrawer);
