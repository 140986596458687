import { gql } from "@apollo/client";
import { GET_UPCOMING_EVENTS } from "src/shared-graphql/queries";

const SEARCH_EVENTS = gql`
  query searchEvents($input: SearchEventsPL!) {
    searchEvents(searchEventsPL: $input) {
      count
      rows {
        id
        name
        private
        type
        price
        startTime
        reserveBy
        endTime
        restaurant {
          id
          name
          overallReview {
            count
            stars
            quality
            accuracy
            hospitality
          }
          host {
            id
            firstName
            lastName
            picture
          }
        }
        dishes {
          id
          name
          imagesGQL {
            publicId
            small
            medium
            large
            original
          }
          tags
          category
          dietary
          DishEvent {
            id
            price
            sold
            count
          }
          __typename @skip(if: true)
        }
        tags
        locale {
          public
          distance
          coordinates {
            longitude
            latitude
          }
        }
      }
    }
  }
`;
export { GET_UPCOMING_EVENTS, SEARCH_EVENTS };
