import { gql } from "@apollo/client";

export const CREATE_APPLICATION_STATUS = gql`
  mutation cookCreateApplicationStatus($input: CookCreateApplicationStatusPL) {
    cookCreateApplicationStatus(cookCreateApplicationStatusPL: $input) {
      id
      status
      description
      updatedAt
      createdAt
    }
  }
`;

export const COOK_CREATE_APP_STATUS = gql`
  mutation cookCreateApplication($input: CookCreateApplicationStatusPL) {
    cookCreateApplicationStatus(cookCreateApplicationStatusPL: $input) {
      status
    }
  }
`;

export const COOK_EDIT_PASSWORD = gql`
  mutation cookEditPassword($input: String!) {
    cookEditPassword(password: $input) {
      id
      firstName
      picture
      lastName
    }
  }
`;
