import { gql } from "@apollo/client";
import { GET_EVENT } from "src/shared-graphql/queries";

export { GET_EVENT };

export const GET_SERVICE_FEE = gql`
  query getMeta($input: ID) {
    getMeta(restaurantId: $input) {
      id
      serviceFee
      minimum
    }
  }
`;
