import { Avatar, Typography } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IEvent, IEventUser } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { useStyles } from "./transaction.styles";

interface IProps {
  event: IEvent;
  currentOrder: IEventUser;
}

export const OrderItems: FC<IProps> = ({ event, currentOrder }) => {
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    if (!currentOrder && event) {
      sharedAPI.setSnackbarMsg({
        type: "error",
        msg: "We cannot find your receipt",
      });
      history.push("/");
    }
  }, [currentOrder]);

  // If the reservation is a take-out and has orderedDishes
  const key = currentOrder?.eventId ? "EventUserDish" : "OrderDish";
  if (!event.type.includes("FIXED_TIME")) {
    return (
      <>
        {currentOrder?.orderedDishes?.map((dish) => {
          const foundDish = event.dishes.find((d) => dish.id === d.id);
          if (dish[key].options?.length) {
            return dish[key].options?.map((o) => {
              return (
                <div className={classes.orderContainer} key={dish.id + o.name}>
                  <Avatar
                    variant="square"
                    alt={dish.name}
                    src={dish.imagesGQL[0].small}
                    className={classes.dishImage}
                  />
                  <Typography
                    variant="body2"
                    component="p"
                    data-testid="order-quantity"
                    className={classes.subText}
                    style={{ flexGrow: 1, marginLeft: "1rem" }}
                    key={dish.id}
                  >
                    <span className={classes.boldSubText}>{o.count}</span>{" "}
                    <span>
                      x {dish.name} ({o.name})
                    </span>
                  </Typography>
                  <div className={classes.priceContainer}>
                    <Typography
                      variant="body2"
                      component="p"
                      data-testid="dish-price"
                      className={classes.dishTotalText}
                    >
                      $
                      {((foundDish?.DishEvent?.price ?? 0) + o.addition) *
                        (o.count ?? 0)}
                    </Typography>
                  </div>
                </div>
              );
            });
          }

          const dishPrice =
            foundDish && foundDish.DishEvent
              ? foundDish.DishEvent.price * dish[key].count
              : 0;
          return (
            <div className={classes.orderContainer} key={dish.id}>
              <Avatar
                variant="square"
                alt={dish.name}
                src={dish.imagesGQL[0].small}
                className={classes.dishImage}
              />

              <Typography
                variant="body2"
                component="p"
                data-testid="order-quantity"
                className={classes.subText}
                key={dish.id}
                style={{ flexGrow: 1, marginLeft: "1rem" }}
              >
                <span className={classes.boldSubText}>{dish[key].count}</span>{" "}
                <span>x {dish.name}</span>
              </Typography>
              <div className={classes.priceContainer}>
                <Typography
                  variant="body2"
                  component="p"
                  data-testid="dish-price"
                  className={classes.dishTotalText}
                >
                  ${dishPrice}
                </Typography>
              </div>
            </div>
          );
        })}
      </>
    );
  }

  // If the event is a fixed-time reservation
  return (
    <>
      <div className={classes.orderContainer}>
        <Typography variant="body2" component="p" className={classes.subText}>
          <span data-testid="order-quantity" className={classes.boldSubText}>
            {currentOrder?.reservedFor}
          </span>{" "}
          <span className={classes.eventName}>x {event.name}</span>
        </Typography>
        <Typography
          variant="body2"
          component="p"
          data-testid="event-price"
          className={classes.dishTotalText}
          style={{ marginLeft: 10 }}
        >
          ${event.price}
        </Typography>
      </div>
    </>
  );
};
