import { Button, makeStyles } from "@material-ui/core";
import AppleIcon from "@material-ui/icons/Apple";
import React, { FC } from "react";
import { COLORS } from "src/styles";

interface IProps {
  text: string;
  onClick: () => void;
}

const useStyles = makeStyles({
  appleLabel: {
    color: COLORS.BLACK,

    textTransform: "none",

    justifyContent: "space-between",
  },
  appleContained: {
    backgroundColor: COLORS.WHITE,
    border: "1px solid black",
    width: "100%",
    height: 50,
    maxWidth: 328,
    padding: 8,
    margin: "0 auto",
    "&:hover": {
      filter: "invert(1)",
    },
  },
  labelText: {
    width: "100%",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
});

export const AppleBtn: FC<IProps> = ({ onClick, text }) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      size="large"
      fullWidth
      classes={{
        label: classes.appleLabel,
        contained: classes.appleContained,
      }}
      disableElevation
      onClick={onClick}
    >
      <AppleIcon style={{ fontSize: "2.5rem", color: COLORS.BLACK }} />{" "}
      <span className={classes.labelText}>{text}</span>
    </Button>
  );
};
