import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IEvent } from "src/models";
import { formatDateShort } from "src/utils/helpers";
import { parseEventType } from "../../../detail/order/helpers";
import { mobileStyles as useStyles } from "./mobile.styles";

interface IProps {
  event: IEvent;

  status: "PAST" | "UPCOMING" | "SAVED";
  mobile?: boolean;
  scroll?: boolean;
}

export const MobileEventsCard: FC<IProps> = ({
  event,

  status,
  scroll,
}) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { eventUser } = event;
  const cardMargin =
    location.pathname === "/d" || location.pathname === "/d/" ? 10 : 0;

  return (
    <Card
      className={classes.container}
      style={{ margin: cardMargin, maxWidth: scroll ? 300 : 1100 }}
      onClick={() => {
        status === "SAVED"
          ? history.push(`/menus/${event.id}-${event.name.replace(/ /g, "-")}`)
          : history.push({
              pathname: `/d/order/${event.id}-${event.name.replace(/ /g, "-")}`,
              state: {
                event,
                status,
              },
            });
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          alt="event-image"
          height="140"
          image={event.imagesGQL?.length ? event.imagesGQL[0].medium : ""}
        />

        <CardContent>
          {Boolean(event?.eventUser?.orders?.length) && (
            <Typography
              component="p"
              variant="body2"
              className={classes.numOfOrders}
            >
              You have {event?.eventUser?.orders?.length} more orders
            </Typography>
          )}
          <div className={classes.titleRow}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography
                variant="h3"
                component="h3"
                className={classes.eventTitle}
              >
                {event.name}{" "}
              </Typography>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <Typography
              variant="body2"
              component="p"
              className={classes.dateAndType}
            >
              {formatDateShort(event.startTime)}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.dateAndType}
            >
              {parseEventType(eventUser && eventUser.dineOption, event.type[0])}
            </Typography>
            {eventUser && eventUser.paidAmount ? (
              <Typography
                variant="body2"
                component="p"
                className={classes.price}
              >
                ${eventUser.paidAmount}
              </Typography>
            ) : null}
            <div className={classes.flexRow}>
              <Typography
                variant="body2"
                component="p"
                className={classes.bold}
              >
                Ordered on:{" "}
                <Typography
                  variant="body2"
                  component="time"
                  className={classes.dateAndType}
                >
                  {moment(Number(event?.eventUser?.createdAt)).format("LLL")}
                </Typography>
              </Typography>
            </div>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
