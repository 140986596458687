import { Typography } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import React, { FC, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { IEvent } from "src/models";
import { amplitude } from "src/services";
import { StepperButtons } from "../buttons/buttons";
import { formatStartToEndDate, getImages } from "../helpers";
import { useStyles } from "./event-card-header.styles";

interface IProps {
  event: IEvent;
  isHovered: boolean;
}

export const EventCardHeader: FC<IProps> = ({ event, isHovered }) => {
  const classes = useStyles();
  const history = useHistory();
  const [index, setIndex] = useState(0);
  // const images = getImages(event);
  // This is if we want to limit the amount of photos showing. May be useful
  const images = getImages(event).slice(0, 9);

  const [date, time] = formatStartToEndDate(
    {
      startTime: event.startTime,
      endTime: event.endTime,
    },
    { removeMs: false, spacedDashes: true }
  ).split(",");

  const handleSetIndex = (idx: number) => setIndex(idx);
  const onClickRight = (e) => {
    e.stopPropagation();
    setIndex((prev) => (prev + 1) % images.length);
  };
  const onClickLeft = (e) => {
    e.stopPropagation();
    setIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };
  const eventLink = `/menus/${event.id}-${event.name.replace(/ /g, "-")}`;

  // Make sure event is not a POPUP event to check if every dish is sold out.
  const allSoldOut = event.dishes.every((dish) => {
    if (!event.type.includes("FIXED_TIME") && dish.DishEvent) {
      return dish.DishEvent.count - dish.DishEvent.sold <= 0;
    }
    return false;
  });

  if (allSoldOut)
    return (
      <div
        className={classes.carouselRoot}
        style={{ cursor: "pointer" }}
        onClick={() => {
          amplitude.getInstance().logEvent("[Event Card] click");
          history.push(eventLink);
        }}
      >
        <div className={classes.imageContainer} role="link">
          <img
            className={classes.image}
            src={images[0].medium}
            alt={`dish from ${event.restaurant.name}`}
          />
          <div className={classes.soldOutWrapper}>
            <Typography
              component="p"
              variant="h3"
              className={classes.soldOutText}
            >
              Sold Out
            </Typography>
          </div>
        </div>
      </div>
    );

  return (
    <section
      onClick={() => {
        amplitude.getInstance().logEvent("[Event Card] click");
        history.push(eventLink);
      }}
      className={classes.cardHeader}
    >
      <div>
        <SwipeableViews
          index={index}
          onChangeIndex={handleSetIndex}
          enableMouseEvents
          ignoreNativeScroll
          className={classes.carouselRoot}
        >
          {images.map((image) => (
            <div
              className={classes.imageContainer}
              role="link"
              key={image.publicId}
            >
              <img
                className={classes.image}
                src={image.medium}
                alt={`dish from ${event.restaurant.name}`}
              />
            </div>
          ))}
        </SwipeableViews>

        <Link to={eventLink} />
        <div className={classes.datesHeaderContainer}>
          <Typography
            variant="caption"
            component="p"
            className={classes.dateLayout}
          >
            <span
              data-testid="card-header-date"
              className={classes.cardHeaderDate}
            >
              {date}&nbsp;
            </span>
            <span
              data-testid="card-header-time"
              className={classes.cardHeaderTime}
            >
              {time}
            </span>
          </Typography>
        </div>
      </div>

      <div className={classes.stepperButtonLayout}>
        <ul className={classes.stepperDotsContainer}>
          {images.map((image, idx) => (
            <li
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                setIndex(idx);
              }}
              className={classes.dotContainer}
              key={image.publicId}
            >
              <FiberManualRecordIcon
                style={{ fontSize: "1rem" }}
                className={index === idx ? classes.dotActive : classes.dot}
              />
            </li>
          ))}
        </ul>
      </div>

      <StepperButtons
        isHovered={isHovered}
        onClickLeft={onClickLeft}
        onClickRight={onClickRight}
        isMoreThanOne={images.length > 1}
      />
    </section>
  );
};
