import { useQuery } from "@apollo/client";
import { parse } from "query-string";
import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { RestaurantReviewDialog } from "src/components";
import { amplitude } from "src/services";
import { GET_RESTAURANT } from "./api";
import { Layout } from "./layout";
import { Loader } from "./loader/loader";
import { TabsStateProvider } from "./tabs/context";
import reducer, { initialState } from "./tabs/state";

interface IProps {
  restaurantName?: string;
}

export const RestaurantProfileContainer: FC<IProps> = React.memo(
  ({ restaurantName }) => {
    const location = useLocation();
    const { name } = useParams<{ name: string }>();
    if (!name && !restaurantName) return <Redirect to="/404" />;
    const { data, loading, error } = useQuery(GET_RESTAURANT, {
      fetchPolicy:
        location.pathname === "/restaurants" ? "no-cache" : "cache-and-network",
      variables: {
        input: restaurantName ? restaurantName : name.split("-").join(" "),
      },
    });

    useEffect(() => {
      if (data?.getRestaurantByName) {
        const source =
          parse(location.search) && parse(location.search).s
            ? parse(location.search).s
            : "organic";
        data.getRestaurantByName &&
          amplitude.getInstance().logEvent("[Restaurant Detail] landing", {
            restaurant: data.getRestaurantByName.name,
            source,
          });
      }
    }, [data?.getRestaurantByName]);

    if (loading) return <Loader />;
    if (error) return <Redirect to="/404" />;

    return (
      <>
        <RestaurantReviewDialog />
        {data.getRestaurantByName && (
          <Helmet>
            <title>{`${data.getRestaurantByName.name} | Foodnome`}</title>
            <meta
              name="description"
              content={data.getRestaurantByName.description}
            />
          </Helmet>
        )}
        <TabsStateProvider reducer={reducer} initialState={initialState}>
          <Layout restaurant={data.getRestaurantByName} />
        </TabsStateProvider>
      </>
    );
  }
);
