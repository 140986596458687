import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  backBtnChatHeader: {
    fontSize: "3rem",
    color: COLORS.MEDIUM_GREEN,
  },
  chargeBtn: { position: "absolute", right: 6 },
  userNameText: {
    color: COLORS.BLACK,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    marginBottom: 0,
  },
  infoArea: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "1rem",
  },
  toolbar: {
    padding: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  snackBarRoot: {
    backgroundColor: COLORS.RED,
  },
});
