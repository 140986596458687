import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { isBlackListed } from "./helpers";
import { Layout } from "./layout";

export const FooterContainer: React.FC<RouteComponentProps<any>> = () => {
  if (
    isBlackListed(
      process.env.REACT_APP_ENV === "cordova"
        ? location.hash.slice(1) // this removes the "#" at the beginning if we're in cordova
        : location.pathname
    )
  ) {
    return null;
  }
  return <Layout />;
};
