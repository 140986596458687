import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { AccessTime } from "@material-ui/icons";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React, { FC, useEffect, useState } from "react";
import { BREAK_POINTS, COLORS } from "src/styles";
import { slugify } from "./helpers";
import { useStyles } from "./styles";

declare let window: any;

interface IMenuItem {
  value: string | number;
  label: string;
  disabled: boolean;
}

interface IProps {
  inputLabel: string;
  menuItems: IMenuItem[];
  onSelect: (value: any) => void;
  showLabel?: boolean;
  reset: boolean;
  defaultLabel?: string;
  defaultValue?: any;
}

export const DropSelect: FC<IProps> = ({
  inputLabel,
  menuItems,
  onSelect,
  showLabel,
  reset,
  defaultLabel,
  defaultValue,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  const [selected, setSelected] = useState(defaultValue ? defaultValue : "");
  const [isOpen, setOpenState] = useState<boolean>(false);
  const id = slugify(`${inputLabel}-${isMobile}`);

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  // handlers
  const handleOpen = () => {
    setOpenState(true);
  };

  const handleClose = () => {
    setOpenState(false);
  };
  const handleChange = ({ target: { value } }) => {
    setSelected(value);
    onSelect(value);
  };

  return (
    <form autoComplete={"off"} className={classes.form}>
      <FormControl className={classes.formControl} variant="standard" fullWidth>
        <InputLabel style={{ opacity: showLabel ? 1 : 0 }} htmlFor={id}>
          {inputLabel}
        </InputLabel>

        <Select
          native
          id={id}
          IconComponent={KeyboardArrowDownIcon}
          value={selected}
          style={{ padding: 0 }}
          classes={{
            root: classes.root,
            icon: classes.icon,
            selectMenu: classes.selectMenu,
            select: classes.selected,
          }}
          displayEmpty
          open={isOpen}
          onOpen={handleOpen}
          onClose={handleClose}
          onChange={handleChange}
          data-testid="drop-select"
          input={
            <OutlinedInput
              classes={{
                root: classes.inputRoot,
                notchedOutline: classes.notchedOutline,
                focused: classes.focused,
              }}
              startAdornment={
                <AccessTime
                  style={{
                    position: "absolute",
                    // left: 20,
                    fontSize: "2.5rem",
                    color: COLORS.MEDIUM_GREEN,
                  }}
                />
              }
              labelWidth={0}
              inputProps={{ id }}
            />
          }
        >
          {!defaultValue && (
            <option className={classes.menuItemRoot} value="">
              {defaultLabel ? defaultLabel : "# OF GUESTS"}
            </option>
          )}
          {menuItems.map(({ label, value, disabled }) => (
            <option
              className={classes.menuItemRoot}
              key={value}
              value={value}
              disabled={disabled}
            >
              {label}
            </option>
          ))}
        </Select>
      </FormControl>
    </form>
  );
};

DropSelect.defaultProps = {
  showLabel: false,
};
