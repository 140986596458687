import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { IDish } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { tableStyles as useStyles } from "./table.styles";

interface IProps {
  dishes: IDish[];
  deleteDish: (id: number) => void;
}

export const DishTable: FC<IProps> = ({ dishes, deleteDish }) => {
  const classes = useStyles();
  const history = useHistory();
  const [hovered, setHover] = useState<string>("0");

  const handleDelete = async (id) => {
    await deleteDish(id);
    sharedAPI.setSnackbarMsg({ type: "information", msg: "Dish Deleted" });
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table style={{ maxWidth: "none" }}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dishes.map((row) => (
              <TableRow
                key={row.id}
                hover
                onMouseOver={() => setHover(String(row.id))}
              >
                <TableCell>
                  {row.name}({row.id})
                </TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>
                  <Tooltip title="Edit Dish">
                    <IconButton
                      onClick={() => history.replace(`/admin/dishes/${row.id}`)}
                    >
                      <Edit
                        className={
                          String(row.id) === hovered
                            ? classes.icon
                            : classes.hidden
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Dish">
                    <IconButton onClick={() => handleDelete(row.id)}>
                      <Delete
                        className={
                          String(row.id) === hovered
                            ? classes.icon
                            : classes.hidden
                        }
                      />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
