import React, { FC } from "react";
import { IGetMe } from "src/models";
import { DinerOrdersListContainer } from "../event/orders-list";
import { MobileHeader } from "./header";
import { layoutStyles as useStyles } from "./styles";

interface IProps {
  user: IGetMe;
}

export const Layout: FC<IProps> = ({ user }) => {
  const classes = useStyles();
  return (
    <>
      <MobileHeader user={user} />
      <div className={classes.container}>
        {/* Upcoming */}
        <div className={classes.mobileContainer}>
          <DinerOrdersListContainer
            upcoming={true}
            past={false}
            title="Upcoming Orders"
          />
        </div>
        {/* Past */}
        <div className={classes.mobileContainer}>
          <DinerOrdersListContainer
            upcoming={false}
            past={true}
            title="Past Orders"
          />
        </div>
      </div>
    </>
  );
};
