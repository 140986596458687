import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { COLORS } from "src/styles";

interface IProps {
  loading: boolean;
}

const useStyles = makeStyles({
  container: {
    minHeight: "100vh",
    width: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "fixed",
    zIndex: 1200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  innerWrapper: {
    textAlign: "center",
    padding: "1em",
  },
  title: {
    textAlign: "center",

    zIndex: 99,
  },
  titleText: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
});

export const LoadingComponent: FunctionComponent<IProps> = ({ loading }) => {
  const classes = useStyles();
  if (!loading) {
    return null;
  }
  return (
    <div className={classes.container}>
      <div className={classes.innerWrapper}>
        <CircularProgress style={{ color: COLORS.RED }} />
      </div>
    </div>
  );
};
