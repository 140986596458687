import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ResponsiveDialog } from "src/components";
import { IGetMe, RestaurantReviewStatus } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { GET_MY_PENDING_RESTAURANT_REVIEWS } from "./api/queries";
import { Layout } from "./layout";

interface IProps {
  getMe: IGetMe;
  fullScreen?: boolean;
}

export const ReviewRequest: React.FC<IProps> = React.memo(({ getMe }) => {
  if (!getMe) return null;
  const history = useHistory();

  const { data, loading, error } = useQuery(GET_MY_PENDING_RESTAURANT_REVIEWS, {
    pollInterval: 1 * 60 * 1000,
  });

  const pendingReviews = data?.getMyPendingRestaurantReviews.filter(
    (review) => review.status === RestaurantReviewStatus.PENDING
  );

  useEffect(() => {
    if (data?.getMyPendingRestaurantReviews && !loading && !error) {
      if (pendingReviews.length === 0) {
        sharedAPI.setSnackbarMsg({
          type: "success",
          msg: "You have 0 pending reviews",
        });
        history.push("/menus");
      }
    } else if (error) {
      sharedAPI.setSnackbarMsg({
        type: "success",
        msg: "You have 0 pending reviews",
      });
      history.push("/menus");
    }
  }, [pendingReviews]);

  return (
    <ResponsiveDialog
      fullWidth
      maxWidth="xs"
      open={Boolean(pendingReviews?.length)}
    >
      <Layout review={pendingReviews && pendingReviews[0]} getMe={getMe} />
    </ResponsiveDialog>
  );
});
