import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  form: {
    display: "flex",
    maxWidth: 1280,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  formRight: {
    flexGrow: 1,
    margin: "0 3rem",
    paddingBottom: "env(safe-area-inset-bottom)",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      margin: "0 0 1.5rem 0",
    },
  },
  formleft: {
    minWidth: 365,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      margin: "20px 0 2rem",
    },
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      minWidth: "100%",
    },
  },
  container: {
    width: "100%",
    backgroundColor: COLORS.WHITE,
    boxShadow: "3px 4px 19px 0 rgba(31,64,80,0.28)",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: "0 0 20px 0",
    position: "relative",
  },
  label: {},
  publish: {
    color: COLORS.MEDIUM_GREEN,

    margin: "-4px 0px 6px 0px",
  },
  formContainer: { padding: "0 45px", marginBottom: 20 },
  input: {
    border: `1px solid ${COLORS.LIGHT_GREY}`,
    borderRadius: "2px",
    padding: "10px",
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    marginBottom: "1.5rem",
    "&::placeholder": {
      fontStyle: "italic",
    },
  },
  createDishButton: {
    height: 43,
    width: 141,
  },
  toolTip: {
    fontSize: "1.6rem",
  },
});
