import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";
import { IEvent } from "src/models";
import { COLORS } from "src/styles";
import { DishItemDialog } from "../../dish-dialog";
import { OrderContext } from "../../order-context";
import { ActionTypes } from "../../order-reducer";
import { useStyles } from "./order-items.styles";

interface IProps {
  getEvent: IEvent;
  noOrder: boolean;
  displayOnly?: boolean;
  isMobile?: boolean;
}

export const OrderItems: FC<IProps> = ({
  getEvent,
  noOrder,
  displayOnly,
  isMobile,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const {
    state: { orderedDishes, seats },
    dispatch,
  } = useContext<any>(OrderContext);

  return (
    <div>
      <>
        {isMobile && (
          <Typography
            variant="body2"
            component="p"
            className={classes.eventName}
          >
            {getEvent.name}
          </Typography>
        )}
        {!noOrder && (
          <Typography
            variant="body2"
            component="p"
            className={classes.yourOrder}
          >
            Your order
          </Typography>
        )}
      </>
      {noOrder && (
        <div className={classes.emptyBagContainer}>
          <img
            src={require("./images/emptybag.svg")}
            alt="empty-bag"
            className={classes.emptyBag}
          />
          <Typography
            variant="body2"
            component="p"
            style={{
              color: COLORS.DARK_GREY,

              opacity: 0.8,
            }}
          >
            Your bag is empty, add a dish!
          </Typography>
        </div>
      )}

      {!noOrder && (
        <div>
          {getEvent.type.includes("FIXED_TIME") && (
            <List className={classes.orderItem}>
              <ListItem>
                <ListItemText
                  primary={seats + "x " + `${seats > 1 ? "orders" : "order"}`}
                />

                <Typography variant="body1" component="span">
                  ${seats * getEvent.price}
                </Typography>
              </ListItem>
            </List>
          )}
          <List>
            {!getEvent.type.includes("FIXED_TIME") &&
              orderedDishes
                .filter((d) => d.count || d.options?.some((o) => o.count))
                .map((d) => {
                  if (d.options?.length) {
                    // Add dish images to the filered object
                    d.options.map(
                      (eachOption) => (eachOption.image = d.imagesGQL)
                    );

                    return d.options
                      .filter((o) => o.count)
                      .map((o) => (
                        <div key={d.id + o.name}>
                          <ListItem className={classes.orderItem}>
                            <ListItemAvatar>
                              <Avatar
                                variant="square"
                                src={o.image[0].small}
                                alt={o.name}
                                className={classes.avatar}
                              />
                            </ListItemAvatar>
                            {/* <span className={classes.count}>{o.count}</span> x{" "}
                            <DishItemDialog dish={d} getEvent={getEvent}>
                              <ButtonChild
                                name={`${d.name} (${o.name})`}
                                displayOnly={displayOnly}
                              />
                            </DishItemDialog> */}
                            <ListItemText
                              primary={
                                <>
                                  <DishItemDialog
                                    dish={d}
                                    getEvent={getEvent}
                                    initOption={o.name}
                                  >
                                    <ButtonChild
                                      name={`${d.name} (${o.name})`}
                                      displayOnly={displayOnly}
                                    />
                                  </DishItemDialog>
                                </>
                              }
                              secondary={
                                <span className={classes.count}>
                                  {o.count + "x "}
                                </span>
                              }
                            />

                            <Typography
                              variant="body1"
                              className={classes.dishPriceWrapper}
                              component="span"
                            >
                              {!displayOnly && (
                                <IconButton
                                  data-testid="remove-dish"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    dispatch({
                                      type: ActionTypes.SET_DISH_COUNT,
                                      payload: {
                                        id: d.id,
                                        option: o.name,
                                        count: 0,
                                      },
                                    });
                                  }}
                                >
                                  <Delete className={classes.delete} />
                                </IconButton>
                              )}
                              ${(d.DishEvent as any).price + o.addition}
                            </Typography>
                          </ListItem>
                          <Divider style={{ margin: ".5rem 1rem" }} />
                        </div>
                      ));
                  } else {
                    return (
                      <div key={d.id}>
                        <ListItem className={classes.orderItem}>
                          <ListItemAvatar>
                            <Avatar
                              variant="square"
                              src={d.imagesGQL[0].small}
                              alt={d.name}
                              className={classes.avatar}
                            />
                          </ListItemAvatar>
                          {/* <span className={classes.count}>{d.count}</span> x{" "}
                            <DishItemDialog dish={d} getEvent={getEvent}>
                              <ButtonChild
                                name={d.name}
                                displayOnly={displayOnly}
                                />
                            </DishItemDialog> */}
                          <ListItemText
                            primary={
                              <>
                                <DishItemDialog dish={d} getEvent={getEvent}>
                                  <ButtonChild
                                    name={d.name}
                                    displayOnly={displayOnly}
                                  />
                                </DishItemDialog>
                              </>
                            }
                            secondary={
                              <span className={classes.count}>
                                {d.count + "x "}
                              </span>
                            }
                          />

                          <Typography
                            variant="body1"
                            className={classes.dishPriceWrapper}
                            component="span"
                          >
                            {!displayOnly && (
                              <IconButton
                                data-testid="remove-dish"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  dispatch({
                                    type: ActionTypes.SET_DISH_COUNT,
                                    payload: {
                                      id: d.id,
                                      count: 0,
                                    },
                                  });
                                }}
                              >
                                <Delete className={classes.delete} />
                              </IconButton>
                            )}
                            ${(d.DishEvent as any).price}
                          </Typography>
                        </ListItem>
                        <Divider style={{ margin: ".5rem 1rem" }} />
                      </div>
                    );
                  }
                })}
          </List>
        </div>
      )}
    </div>
  );
};

const ButtonChild: FC<any> = ({ setOpen, name, displayOnly }) => {
  return (
    <button
      onClick={() => (displayOnly ? null : setOpen())}
      style={{
        all: "unset",
        maxWidth: "25rem",
      }}
    >
      <Typography
        style={{
          color: COLORS.MEDIUM_GREEN,
          display: "-webkit-box",
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordBreak: "break-word",
          lineClamp: 2,
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          cursor: displayOnly ? "unset" : "pointer",
          textAlign: "left",
          backgroundColor: "transparent",
        }}
        variant="caption"
        component="p"
      >
        {name}
      </Typography>
    </button>
  );
};
