import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { DishWithAmount, IEvent } from "src/models";
import { BREAK_POINTS, COLORS } from "src/styles";

export interface ActionButtonsProps {
  event: IEvent;
  selectedDishes: DishWithAmount[];
  showSelectedDishes: boolean;
  saveAndExit: () => Promise<any>;
  handleReview: (e: any) => any;
}

const useStyles = makeStyles({
  button: {
    display: "inline-block",
    backgroundColor: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.WHITE,
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_GREEN,
      opacity: 0.7,
    },
    "&:disabled": {
      backgroundColor: COLORS.MEDIUM_GREEN,
      opacity: 0.3,
    },
  },
  actionButtons: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "100%",
    padding: `.6rem`,
    paddingBottom: "calc(0.6rem + env(safe-area-inset-bottom))",
    position: "fixed",
    left: 290,
    right: 0,
    bottom: 0,
    backgroundColor: COLORS.WHITE,
    borderTop: "1px solid #d3d3d3",
    zIndex: 1,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      left: 0,
    },
  },
  saveAndExitButton: {
    color: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    opacity: 1,
    transition: `opacity 300ms ease-in-out`,
    "&:disabled": {
      opacity: 0,
    },
  },
});

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  event,
  selectedDishes,
  saveAndExit,
  handleReview,
}) => {
  const isActiveEvent = event.status === "ACTIVE";
  const classes = useStyles();
  return (
    <div className={classes.actionButtons}>
      {/* Check if we need to render a "Save & Exit" button for PENDING events */}
      {!isActiveEvent ? (
        <Button
          onClick={saveAndExit}
          className={classes.saveAndExitButton}
          type="button"
          aria-label="save and exit"
        >
          {"Save & Exit"}
        </Button>
      ) : (
        <div /> // For Layout Purposes
      )}
      {/* Submit Button */}
      <Button
        id="submitButton"
        variant="contained"
        style={{ minWidth: 120 }}
        data-testid="event-dish-select-submit-button"
        disabled={selectedDishes.length === 0}
        classes={{ contained: classes.button }}
        onClick={handleReview}
      >
        {selectedDishes.length === 0
          ? "Select a Dish"
          : isActiveEvent
          ? "Save & Exit"
          : "Next"}
      </Button>
    </div>
  );
};
