import { useMutation } from "@apollo/client";
import { Avatar, Button, Card, Typography } from "@material-ui/core";
import moment from "moment";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { IUser } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { eventAPI } from "../api";
import { SEND_USER_REMINDER } from "../api/graphql";
import { guestItemStyles as useStyles } from "./guest-item.styles";
interface IProps {
  onClose: any;
  guest: IUser;
}

export const ListItem: FC<IProps> = ({ onClose, guest }) => {
  const [sendReminder] = useMutation(SEND_USER_REMINDER);
  const classes = useStyles();
  return (
    <Card key={guest.id} style={{ padding: 10 }}>
      <div className={classes.card}>
        <Avatar
          alt={`${guest.firstName.charAt(0)}${guest.lastName.charAt(0)}`}
          src={guest.picture}
        />
        <section className={classes.info}>
          <Typography component="p" variant="body2">
            <Link to={`members/${guest.id}`}>
              {guest.firstName} {guest.lastName}
            </Link>{" "}
            <span className={classes.guestEmail}>{guest.email}, </span>
            <span className={classes.guestEmail}>{guest.phoneNumber}</span>
          </Typography>
          <div style={{ color: "grey" }}>
            {guest.points && guest.points.eventsAttended} events attended{" "}
            {moment(Number(guest.createdAt)).format("MM/DD/YYYY")}
            {guest.EventUser?.couponInfo && (
              <Typography variant="body2" component="p">
                Code: {guest.EventUser?.couponInfo?.code}, New User:{" "}
                {guest.EventUser?.couponInfo?.newUser ? "Yes" : "No"},
              </Typography>
            )}
            <Typography variant="body2" component="p">
              Source: {guest.EventUser?.source}
            </Typography>
          </div>
        </section>
        <section>
          <Typography variant="body2" component="p">
            ${guest.EventUser.paidAmount} (${guest.EventUser.creditAmount})
          </Typography>
        </section>
      </div>
      <section
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Button
          onClick={() =>
            confirm(`Dude, are you sure u wanna cancel the order?`) &&
            eventAPI
              .cancelEventUser(guest.EventUser.id, "CANCELED")
              .then((res) => {
                onClose();
              })
          }
          type="button"
          variant="contained"
          className={classes.cancelButton}
        >
          Cancel
        </Button>
        <Button
          onClick={() =>
            sendReminder({ variables: { input: guest.EventUser.id } })
              .then((res) =>
                sharedAPI.setSnackbarMsg({ type: "success", msg: "Done!" })
              )
              .catch(() =>
                sharedAPI.setSnackbarMsg({ type: "error", msg: "Error" })
              )
          }
          type="button"
          variant="contained"
          color="secondary"
          className={classes.cancelButton}
        >
          1 hr Reminder
        </Button>
      </section>
    </Card>
  );
};
