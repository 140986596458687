import { useQuery } from "@apollo/client";
import { parse } from "query-string";
import React, { FC, useEffect } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { GET_RESTAURANT } from "src/screens/restaurant/api";
import { Loader } from "src/screens/restaurant/loader/loader";
import { amplitude } from "src/services";
import { Layout } from "./layout";

interface IProps {
  restaurantName?: string;
}

export const RestaurantContainer: FC<IProps> = React.memo(
  ({ restaurantName }) => {
    const location = useLocation();
    const { name } = useParams<{ name: string }>();
    if (!name && !restaurantName) return <Redirect to="/404" />;
    const { data, loading, error } = useQuery(GET_RESTAURANT, {
      fetchPolicy:
        location.pathname === "/restaurants" ? "no-cache" : "cache-and-network",
      variables: {
        input: restaurantName ? restaurantName : name.split("-").join(" "),
      },
    });

    useEffect(() => {
      if (data?.getRestaurantByName) {
        const source =
          parse(location.search) && parse(location.search).s
            ? parse(location.search).s
            : "organic";
        data.getRestaurantByName &&
          amplitude.getInstance().logEvent("[Restaurant Detail] landing", {
            restaurant: data.getRestaurantByName.name,
            source,
          });
      }
    }, [data?.getRestaurantByName]);

    if (loading) return <Loader />;
    if (error) return <Redirect to="/404" />;
    return <Layout restaurant={data.getRestaurantByName} />;
  }
);
