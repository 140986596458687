import { SvgIcon } from "@material-ui/core";
import React from "react";

export const CookPermit = (props) => (
  <SvgIcon {...props}>
    <g id="Layer_2">
      <g>
        <g>
          <g id="XMLID_10_">
            <g>
              <g>
                <path
                  d="M19.26,4.14v13.19c0,2.28-1.86,4.14-4.14,4.14h-2.23v-2h2.23c1.18,0,2.14-0.96,2.14-2.14V4.14
							C17.26,2.96,16.3,2,15.12,2H4.14C2.96,2,2,2.96,2,4.14v13.19c0,1.18,0.96,2.14,2.14,2.14h2.27v2H4.14
							C1.86,21.47,0,19.61,0,17.33V4.14C0,1.85,1.86,0,4.14,0h10.98C17.4,0,19.26,1.85,19.26,4.14z"
                />
              </g>
              <g>
                <path
                  d="M14.78,8.61c0,0.59-0.46,1.06-1.05,1.06H5.71c-0.59,0-1.05-0.47-1.05-1.06c0-0.59,0.46-1.05,1.05-1.05h8.02
							C14.32,7.56,14.78,8.02,14.78,8.61z"
                />
              </g>
              <g>
                <path
                  d="M12.86,5.08c0,0.59-0.47,1.06-1.05,1.06H7.63c-0.59,0-1.05-0.47-1.05-1.06c0-0.59,0.46-1.05,1.05-1.05h4.18
							C12.39,4.03,12.86,4.49,12.86,5.08z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M14.34,15.61l-0.49-0.76l0.05-0.9c0.02-0.26-0.12-0.51-0.37-0.64l-0.81-0.4l-0.4-0.81c-0.11-0.24-0.36-0.38-0.64-0.36
							l-0.9,0.05l-0.75-0.49c-0.22-0.15-0.54-0.15-0.75,0l-0.75,0.49l-0.89-0.05C7.39,11.72,7.15,11.85,7,12.09l-0.41,0.82l-0.8,0.4
							c-0.25,0.12-0.4,0.38-0.37,0.64l0.05,0.9L4.99,15.6c-0.17,0.23-0.16,0.53-0.01,0.73l0.49,0.76l-0.05,0.89
							c-0.03,0.25,0.11,0.51,0.36,0.65l0.63,0.32v4.86c0,0.37,0.3,0.67,0.67,0.67c0.12,0,0.24-0.04,0.32-0.09l2.26-1.18l2.26,1.2
							c0.09,0.05,0.2,0.07,0.3,0.07c0.24,0,0.48-0.13,0.61-0.36c0.04-0.09,0.06-0.19,0.06-0.31v-4.86l0.65-0.32
							c0.23-0.1,0.37-0.34,0.37-0.64l-0.04-0.89l0.5-0.79C14.49,16.1,14.48,15.82,14.34,15.61z M6.82,15.11h0.05l-0.05,0.02
							C6.82,15.12,6.82,15.12,6.82,15.11z M12.32,16.48l-0.02,0.01c-0.06,0.11-0.09,0.23-0.09,0.37l0.04,0.61l-0.55,0.28
							c-0.11,0.06-0.19,0.14-0.26,0.27l-0.28,0.54l-0.62-0.04c-0.14,0-0.27,0.03-0.37,0.1l-0.51,0.34L9.11,18.6
							c-0.07-0.04-0.18-0.08-0.34-0.08c-0.01,0-0.01,0-0.01,0l-0.6,0.04l-0.27-0.51c-0.06-0.16-0.19-0.25-0.28-0.31l-0.54-0.27
							l0.05-0.62c0-0.11-0.01-0.26-0.11-0.37l-0.33-0.51l0.3-0.47c0.09-0.09,0.14-0.22,0.14-0.41l-0.05-0.62l0.54-0.27
							c0.13-0.06,0.22-0.16,0.27-0.28l0.29-0.53l0.63,0.04c0.16,0,0.29-0.04,0.36-0.1l0.51-0.34l0.49,0.32
							c0.1,0.08,0.22,0.12,0.4,0.12h0.01l0.6-0.04l0.27,0.52c0.07,0.15,0.18,0.23,0.28,0.29l0.54,0.28l-0.03,0.63
							c0,0.09,0,0.26,0.1,0.35l0.33,0.51L12.32,16.48z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
