import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ResponsiveDialog } from "src/components/dialog";
import { IGetMe } from "src/models";
import { COLORS } from "src/styles";
import { USER_LOGIN_WITH_APP } from "./api/queries";

interface IProps {
  getMe?: IGetMe;
  isCordova?: boolean;
  amount?: number;
}

export const ClaimAppBonus: React.FC<IProps> = ({
  getMe,
  isCordova = process.env.REACT_APP_ENV === "cordova",
  amount = 10,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [updateHasApp, { loading }] = useMutation(USER_LOGIN_WITH_APP, {
    refetchQueries: ["getMe"],
  });

  useEffect(() => {
    if (isCordova && getMe && !getMe?.hasApp) {
      setOpen(true);
    }
  }, [isCordova, getMe]);

  return (
    <ResponsiveDialog
      fullWidth
      maxWidth="sm"
      data-testid="ClaimAppBonus-dialog"
      open={open}
      classes={{ paper: classes.paper }}
      aria-describedby="claim-app-bonus-dialog-title"
    >
      <div className={classes.dialogTop}>
        <Typography
          variant="h1"
          component="h1"
          className={classes.title}
          id="claim-app-bonus-dialog-title"
        >
          Claim your ${amount} dollars bonus now.
        </Typography>
      </div>
      <div className={classes.dialogBottom}>
        <Button
          classes={{ contained: classes.button }}
          onClick={() => updateHasApp().finally(() => setOpen(false))}
          disabled={loading}
          variant="contained"
          size="large"
        >
          {loading ? (
            <CircularProgress style={{ color: COLORS.WHITE }} />
          ) : (
            "Claim my bonus"
          )}
        </Button>
      </div>
    </ResponsiveDialog>
  );
};

const useStyles = makeStyles({
  paper: { borderRadius: 40 },
  dialogTop: {
    backgroundImage: `url(${require(`src/assets/images/yellowveg-11.png`)})`,
    width: "100%",
    minHeight: 171,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    position: "relative",
  },
  title: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    width: "100%",
    maxWidth: 300,
    margin: "0 auto",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  dialogBottom: {
    padding: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  button: {
    boxShadow: "1px 2px 6px 0 rgba(0,0,0,0.18)",
    backgroundColor: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.WHITE,
    minWidth: 180,
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_GREEN,
      "&:disabled": {
        backgroundColor: COLORS.MEDIUM_GREEN,
        opacity: 0.7,
      },
    },
    "&:disabled": {
      backgroundColor: COLORS.MEDIUM_GREEN,
      opacity: 0.7,
    },
  },
});
