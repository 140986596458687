import { Button, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { useStyles } from "./footer.styles";

export const Footer: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.rowThree}>
      <div className={classes.largeSubText}>
        <Typography variant="h2" component="h2">
          Grab a plate to support our vision of a thriving home cook on every block.        </Typography>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push("/menus")}
        size="large"
      >
        Order a meal
      </Button>
    </div>
  );
};
