import { useQuery } from "@apollo/client";
import React, { FC, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { ErrorLoadingComponent } from "src/components";
import { IEvent } from "src/models";
import { amplitude } from "src/services";
import { GET_ME } from "src/shared-graphql/queries";
import { OrderContext } from "../order-context";
import { Layout } from "./layout";

interface IProps {
  getEvent: IEvent;
}

export const CheckoutContainer: FC<IProps> = React.memo(({ getEvent }) => {
  const { data, loading, error } = useQuery(GET_ME, { errorPolicy: "all" });
  const history = useHistory();
  const location = useLocation();
  const {
    state: { orderedDishes, seats, dialogView },
  } = useContext(OrderContext);

  useEffect(() => {
    amplitude.getInstance().logEvent("[Event Detail Checkout] Landing");
  }, []);

  if (
    Boolean(
      !getEvent.type.includes("FIXED_TIME") &&
        !orderedDishes.some(
          (d) => d.count > 0 || d.options?.some((o) => (o.count ?? 0) > 0)
        )
    ) ||
    Boolean(getEvent.type.includes("FIXED_TIME") && !seats) ||
    (!data.getMe && !loading)
  ) {
    return (
      <Redirect to={`${location.pathname.split("/").slice(0, 3).join("/")}`} />
    );
  }

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }

  return (
    <>
      <Helmet>
        <title>{`Checkout | ${getEvent.restaurant?.name}`}</title>
        <meta name="description" content={getEvent.restaurant?.name} />
      </Helmet>
      <Layout getEvent={getEvent} getMe={data.getMe} dialogView={dialogView} />
    </>
  );
});
