import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  paper: {
    minWidth: 300,
  },

  closeButton: {
    zIndex: 100,
    backgroundColor: COLORS.WHITE,
    boxShadow: `0 0 6px 0 rgba(0,0,0,0.15)`,
    position: "absolute",
    right: 5,
    top: 5,
    left: "unset",
  },
  closeIcon: {
    color: COLORS.MEDIUM_RED,
  },
});
