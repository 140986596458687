import { gql } from "@apollo/client";

export const USER_GET_NOTIFICATIONS = gql`
  query userGetNotifications($input: UserGetNotificationPL!) {
    userGetNotifications(userGetNotificationPL: $input) {
      count
      rows {
        id
        status
        headings
        subtitle
        contents
        imageUrl
        redirectUrl
        updatedAt
        createdAt
      }
    }
  }
`;

// export const USER_GET_NOTIFICATION_COUNT = gql`
//   query userGetNotificationCount {
//     userGetNotificationCount
//   }
// `;
