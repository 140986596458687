import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  shareBtnIcon: { cursor: "pointer", fontSize: 30 },
  btnLabel: {
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  btnIcon: {
    color: COLORS.MEDIUM_GREEN,
    marginRight: 2,
    fontSize: "2rem",
  },
  btn: {
    backgroundColor: COLORS.WHITE,
    width: "90px",
    height: "28px",
    borderRadius: "2px",
    marginLeft: 4,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    padding: 1,
    color: COLORS.BLACK,
    "&:hover": {
      backgroundColor: COLORS.WHITE,
    },
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "0",
      boxShadow: "none",
      minWidth: "unset",
      width: "unset",
      marginTop: "-8px",
    },
  },
  shareLinkContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: COLORS.WHITE,
    borderRadius: 6,
    height: "4rem",

    width: "100%",
  },
  input: {
    userSelect: "none",
    WebkitUserSelect: "none",
    WebkitTouchCallout: "none",
    border: `1px solid ${COLORS.MEDIUM_GREEN}`,
    height: "100%",
    width: "100%",
    padding: "0 1rem",
    fontFamily: "CustomFourBold",
    outline: "none",
    WebkitAppearance: "none",
    borderRadius: 0,
    "&:focus": {
      outline: "none",
    },
  },
  shareLinkCopyBtn: {
    height: "100%",
    borderRadius: "0px 0px 0px 0px",
  },
  shareContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
  },
  socialContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
});
