import React, { FC } from "react";
import { MissionPageCarouselContainer } from "src/components/mission-page-carousel";
import { useStyles } from "./carousel.styles";
import { StepProgress } from "./step-progress";
import { Stepper } from "./stepper";
import { ValuesViewPort } from "./values-view-port";

interface IValue {
  title: string;
  statement: string;
  image: string;
}
interface IProps {
  step: number;
  valuesData: IValue[];
  setStep: (d: number) => void;
}

export const Carousel: FC<IProps> = ({ step, setStep, valuesData }) => {
  const classes = useStyles();
  const images = valuesData.map((value) => value.image);
  return (
    <div className={classes.container}>
      <div className={classes.leftContainer}>
        <MissionPageCarouselContainer
          images={images}
          step={step}
          setStep={setStep}
        />
      </div>
      <div className={classes.valuesContent}>
        <div className={classes.textContainer}>
          <div className={classes.innerTextContainer}>
            <StepProgress step={step} images={images} />
            <div style={{ height: 265 }}>
              <ValuesViewPort
                valuesContent={valuesData}
                step={step}
                setStep={setStep}
              />
            </div>
            <Stepper setStep={setStep} step={step} steps={images.length} />
          </div>
        </div>
      </div>
    </div>
  );
};
