import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useSidebarStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    boxShadow: `-8px 2px 42px 0 rgba(42,34,64,0.14)`,
    height: "100%",
    maxWidth: 291,
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    padding: 0,
    height: 56,
    display: "flex",

    position: "relative",
    "&:hover": {
      backgroundColor: "#F4F4FE",
    },
  },
  nav: {
    flexGrow: 1,
  },
  navLink: {
    width: "100%",
    height: "100%",
    textDecoration: "none",
    justifyContent: "flex-start",
    color: COLORS.BLACK,
    paddingLeft: "3rem",
    paddingRight: "3rem",

    textTransform: "none",

    borderBottom: "1px solid #EAEAEA",
  },
  navLinkActive: {
    color: COLORS.MEDIUM_RED,
    transition: "color 50ms ease-in",
    backgroundColor: "#F4F4FE",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  navLinklabel: {
    marginLeft: "1rem",
  },
  navLinkLayout: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "80%",
    margin: "0 auto",
  },
});
