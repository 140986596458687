import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "src/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: 300,
    borderRadius: 0,
    cursor: "pointer",
    boxShadow: `1px 2px 9px 0 rgba(39,39,39,0.2)`,
    maxWidth: 330,
  },
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    padding: ".5em",
  },
  cardHeading: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.MEDIUM_GREEN,
    maxWidth: 190,
    overflow: "hidden",
    margin: 0,
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
  },
  startEndTime: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  dateIconWrapper: { padding: ".5em" },
  startEndTimeWrapper: { padding: ".5em", flexGrow: 1 },
  cardBottom: {
    flexGrow: 1,
    flexShrink: 0,
    position: "relative",
  },
  cardBottomOverlay: {
    backgroundColor: `rgba(0, 0, 0, 0.22)`,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
  cardBottomActions: {
    position: "absolute",
    bottom: "1rem",
    left: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  cardActionButton: {
    backgroundColor: COLORS.MEDIUM_GREEN,
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    "&:hover": {
      backgroundColor: COLORS.MEDIUM_GREEN,
    },
  },
  activeGuestCount: {
    backgroundColor: COLORS.WHITE,
    display: "inline-block",
    borderRadius: 17,
    padding: ".25rem .5rem",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.RED,
    textTransform: "capitalize",
  },
  zeroGuestCount: {
    backgroundColor: "transparent",
    display: "inline-block",
    borderRadius: 17,
    padding: ".25rem .5rem",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.WHITE,
    textTransform: "capitalize",
    border: "solid white 2px",
  },
  icon: {
    marginRight: ".35rem",
  },
  menuButton: {
    position: "absolute",
    right: "0",
    bottom: "0",
    color: COLORS.WHITE,
    fontSize: "3rem",
  },
  listItemText: {},
  draftOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: `rgba(0, 0, 0, 0.7)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  draftOverlayText: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.WHITE,
    textTransform: "uppercase",
    display: "inline-block",
  },
  draftOverlayContent: {
    zIndex: 1,
  },
  overlayButton: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  dateInputOutlined: {
    backgroundColor: COLORS.WHITE,
  },
  dateInput: {
    padding: 10,
    zIndex: 1,
  },
}));
