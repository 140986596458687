import { makeStyles, OutlinedInput, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React, { useEffect } from "react";
import { COLORS } from "src/styles";

export interface SearchDishFormProps {
  handleSearch: (event: { target: { value: string } }) => any;
}

const useStyles = makeStyles({
  searchInput: {
    padding: 10,
  },
});

export const SearchDishForm: React.FC<SearchDishFormProps> = ({
  handleSearch,
}) => {
  const classes = useStyles();
  const inputRef = React.useRef<any>();

  useEffect(() => {
    if (inputRef.current) {
      const input = inputRef.current;
      input.addEventListener("input", handleSearch);
      return () => {
        input.removeEventListener("input", handleSearch);
      };
    }
  }, [handleSearch]);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Typography
        component="label"
        variant="caption"
        className="hidden-label"
        htmlFor="search-dishes-input"
      >
        Search Dishes
      </Typography>
      <OutlinedInput
        type="text"
        inputRef={inputRef}
        placeholder="Search"
        classes={{ input: classes.searchInput }}
        labelWidth={0}
        inputProps={{ id: "search-dishes-input", name: "dishSearchTerm" }}
        startAdornment={<Search style={{ color: COLORS.DARK_GREY }} />}
      />
    </form>
  );
};
