import { Bounds } from "google-map-react";
import { IRestaurant } from "src/models";

export interface IState {
  center: { lat?: number; lng?: number };
  bounds?: Bounds;
  zoom: number;
  restaurants: IRestaurant[];
  loadingRestaurants: boolean;
  hoveredRestaurant?: IRestaurant;
  clickedRestaurant?: IRestaurant;
  searchAsMove: boolean;
  map?: any;
}

export const initialState: IState = {
  center: { lat: undefined, lng: undefined },
  bounds: undefined,
  zoom: 9,
  restaurants: [],
  loadingRestaurants: true,
  searchAsMove: true,
  map: undefined,
};

export enum ActionTypes {
  SET_HOVERED_RESTAURANT = "SET_HOVERED_RESTAURANT",
  SET_CLICKEDED_RESTAURANT = "SET_CLICKEDED_RESTAURANT",
  SET_RESTAURANTS = "SET_RESTAURANTS",
  SET_MAP = "SET_MAP",
  SET_MAP_POSITION = "SET_MAP_POSITION",
  SET_RESTAURANT_LOADING = "SET_RESTAURANT_LOADING",
  SET_SEARCH_AS_MOVE = "SET_SEARCH_AS_MOVE",
}

interface ISetRestaurants {
  type: ActionTypes.SET_RESTAURANTS;
  payload: IRestaurant[];
}

interface ISetMap {
  type: ActionTypes.SET_MAP;
  payload: any;
}

interface ISetHoveredRestaurants {
  type: ActionTypes.SET_HOVERED_RESTAURANT;
  payload?: IRestaurant;
}

interface ISetSearchAsMove {
  type: ActionTypes.SET_SEARCH_AS_MOVE;
  payload: boolean;
}

interface ISetClickedRestaurants {
  type: ActionTypes.SET_CLICKEDED_RESTAURANT;
  payload?: IRestaurant;
}

interface ISetMapPosition {
  type: ActionTypes.SET_MAP_POSITION;
  payload: { bounds?: Bounds; center?: { lat: number; lng: number } };
}

interface ISetRestaurantLoading {
  type: ActionTypes.SET_RESTAURANT_LOADING;
  payload: boolean;
}

export type ComponentActions =
  | ISetRestaurants
  | ISetRestaurantLoading
  | ISetMapPosition
  | ISetHoveredRestaurants
  | ISetClickedRestaurants
  | ISetSearchAsMove
  | ISetMap;

export const reducer: React.Reducer<IState, ComponentActions> = (
  state,
  action
) => {
  switch (action.type) {
    case ActionTypes.SET_RESTAURANTS:
      return {
        ...state,
        restaurants: action.payload,
      };

    case ActionTypes.SET_MAP:
      return {
        ...state,
        map: action.payload,
      };

    case ActionTypes.SET_MAP_POSITION:
      return {
        ...state,
        ...action.payload,
      };

    case ActionTypes.SET_HOVERED_RESTAURANT:
      return {
        ...state,
        hoveredRestaurant: action.payload,
      };

    case ActionTypes.SET_SEARCH_AS_MOVE:
      return {
        ...state,
        searchAsMove: action.payload,
      };

    case ActionTypes.SET_CLICKEDED_RESTAURANT:
      return {
        ...state,
        clickedRestaurant: action.payload,
      };

    case ActionTypes.SET_RESTAURANT_LOADING:
      return {
        ...state,
        loadingRestaurants: action.payload,
      };

    default:
      return state;
  }
};
