import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  label: {
    fontWeight: 100,

    color: COLORS.BLACK,
    "&$selected": {
      color: COLORS.RED,
    },
  },
  rootAction: {
    minWidth: "unset",
    "&$selected": {
      color: COLORS.RED,
    },
  },
  selected: {},
  avatarRoot: {
    backgroundColor: COLORS.RED,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
});
