import React, { FC } from "react";
import { IEvent } from "src/models";
import { EventsCard } from "./events-card";
import { MobileEventsCard } from "./mobile/mobile-event-card";

interface IProps {
  event: IEvent;
  mobile?: boolean;
  status: "PAST" | "UPCOMING" | "SAVED";
}

export const EventCard: FC<IProps> = ({ event, mobile, status }) => {
  if (mobile) {
    return <MobileEventsCard event={event} status={status} />;
  }
  return <EventsCard event={event} />;
};
