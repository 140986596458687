import { Fab, makeStyles, Tooltip } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import React, { useState } from "react";
import { ConfirmationDialog } from "src/components";
import { IDish } from "src/models";
import { COLORS } from "src/styles/colors";

interface IProps {
  dish: IDish;
  handleDelete: (dishId: number) => Promise<any>;
  handleUpdate: (dishId: number) => any;
}

const useStyles = makeStyles({
  actionButtonsContainer: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    top: "1em",
    left: "1em",
  },
  actionButton: {
    backgroundColor: COLORS.WHITE,
    fontSize: "2rem",
  },
});

export const ActionItems: React.FC<IProps> = ({
  dish,
  handleUpdate,
  handleDelete,
}) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const onDeleteDish = () => handleDelete(dish.id);

  return (
    <>
      <div className={classes.actionButtonsContainer}>
        <Tooltip title="Edit">
          <Fab
            data-testid="edit-dish-button"
            disableRipple
            className={classes.actionButton}
            size="small"
            onClick={() => handleUpdate(dish.id)}
          >
            <Edit style={{ color: COLORS.MEDIUM_RED }} />
          </Fab>
        </Tooltip>
        <Tooltip title="Delete">
          <Fab
            data-testid={`delete-dish-button-${dish.name}`}
            style={{ marginLeft: ".7em" }}
            disableRipple
            className={classes.actionButton}
            size="small"
            onClick={() => setOpenDialog(true)}
          >
            <Delete style={{ color: COLORS.MEDIUM_RED }} />
          </Fab>
        </Tooltip>
      </div>
      <ConfirmationDialog
        title={`Delete Dish`}
        fullWidth={false}
        message={`Do you want to delete ${dish.name}? You cannot undo this action.`}
        openDialog={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={onDeleteDish}
      />
    </>
  );
};
