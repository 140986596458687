import { makeStyles, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { BREAK_POINTS } from "src/styles";
import { COLORS } from "src/styles/colors";

interface IProps {
  button: any;
  title: string;
  subtext: string;
}

const useStyles = makeStyles({
  wrapper: {
    boxShadow: "-3px 12px 19px 0 #EEEEEE",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1rem",
    height: "100%",
    flex: 1,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      justifyContent: "flex-start",
    },
  },
  title: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.DARK_GREY,
    padding: 0,
    margin: 0,
  },
  subtext: {
    color: COLORS.DARK_BLUE,
  },
});

export const NoItemsContainer: FC<IProps> = ({ title, subtext, button }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div>
        <Typography variant="h2" component="h2" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body2" component="p" className={classes.subtext}>
          {subtext}
        </Typography>
        {button}
      </div>
    </div>
  );
};
