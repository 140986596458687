import { Paper, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { AllergenCreateContainer } from "../create/container";

interface IProps {
  count: number;
  pageNum: number;
}
export const Header: FunctionComponent<IProps> = ({ count, pageNum }) => {
  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
      }}
    >
      <Typography variant="h3" component="h1" style={{ marginBottom: "2rem" }}>
        Allergens ({count})
      </Typography>
      <div>
        <AllergenCreateContainer offset={(pageNum - 1) * 10} />
      </div>
    </Paper>
  );
};
