import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "src/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: COLORS.WHITE,
    height: "100%",
    borderRadius: "1rem 0 0 1rem",
    ...theme.typography.body2,
  },
  notchedOutline: { borderRadius: 0, border: "none" },
  formHelperText: {
    color: COLORS.RED,
    position: "absolute",
    bottom: -20,
    margin: 0,
  },
}));
