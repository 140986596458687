import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorLoadingComponent } from "src/components";
import { useMobileHeader } from "src/utils/hooks";
import { eventAPI } from "../api";
import { COOK_GET_EVENT } from "./graphql";
import { Layout } from "./layout";

export const EventDetailContainer: React.FC = () => {
  const [firstPaint, setFirstPaint] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { data, error, loading } = useQuery(COOK_GET_EVENT, {
    variables: { input: id.split("-")[0] },
    pollInterval: 2 * 60 * 1000, // every 10 min,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!firstPaint && loading && data) {
      setFirstPaint(true);
    }
  }, [loading]);

  useMobileHeader({
    title: data?.cookGetEvent ? `Manage "${data?.cookGetEvent?.name}"` : "",
    pathname: `/c/menus?status=ACTIVE`,
  });

  if ((loading && !firstPaint) || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }

  return (
    <Layout
      event={data?.cookGetEvent}
      cancelEvent={eventAPI.cancelEvent}
      publishEvent={eventAPI.publishEvent}
    />
  );
};
