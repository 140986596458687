import { Badge, Dialog, DialogContent, IconButton } from "@material-ui/core";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import React, { FC, useContext, useState } from "react";
import { IEvent } from "src/models";
import { COLORS } from "src/styles";
import { OrderContext } from "../../order-context";
import { useStyles } from "./mobile-order-dialog.styles";
import { OrderItems } from "./order-items";

interface IProps {
  getEvent: IEvent;
  noOrder: boolean;
  isMobile: boolean;
  variation: string;
}
export const MobileOrderDialog: FC<IProps> = ({
  getEvent,
  noOrder,
  isMobile,
  variation,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const {
    state: { orderedDishes },
  } = useContext(OrderContext);

  const bagAmount = orderedDishes.reduce(
    (acc, next) =>
      acc +
      next.count +
      (next.options?.reduce((_acc, _next) => _acc + (_next.count ?? 0), 0) ??
        0),
    0
  );

  const StyledBadge = withStyles((theme: Theme) =>
    createStyles({
      badge: {
        right: "50%",
        top: "61%",

        color: variation === "var-1" ? COLORS.MEDIUM_GREEN : COLORS.RED,
        backgroundColor: "rgba(255, 255, 255, 0)",
      },
    })
  )(Badge);

  return (
    <>
      <StyledBadge
        badgeContent={bagAmount}
        color="primary"
        variant="standard"
        onClick={(e) => {
          if (isMobile) {
            e.preventDefault();
            e.stopPropagation();
            setOpen(true);
          }
        }}
      >
        {bagAmount > 0 ? (
          <img
            aria-label="bag icon"
            style={{
              width: "2rem",
              height: "3rem",
            }}
            src={require(`src/screens/event-detail/detail/order-info/images/cart_fill.svg`)}
          />
        ) : (
          <img
            aria-label="bag icon"
            style={{
              width: "2rem",
              height: "3rem",
            }}
            src={require(`src/screens/event-detail/detail/order-info/images/cart_empty.svg`)}
          />
        )}
      </StyledBadge>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        classes={{ paper: classes.paper }}
      >
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(false);
          }}
          aria-label="close dialog"
          className={classes.closeButton}
        >
          <Close className={classes.closeIcon} />
        </IconButton>
        <DialogContent>
          <OrderItems
            getEvent={getEvent}
            noOrder={noOrder}
            displayOnly={false}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
