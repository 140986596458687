import { useMutation } from "@apollo/client";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { FC, useState } from "react";
import { ChipsInput } from "src/components";
import { IRestaurant } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { ADMIN_UPDATE_RESTAURANT, ADMIN_UPDATE_TAX_RATE } from "../api/graphql";
import { useStyles } from "./edit-dialog.styles";

interface IProps {
  open: boolean;
  restaurant: IRestaurant;
  handleClose: () => void;
}

export const EditRestaurantDialog: FC<IProps> = ({
  open,
  restaurant,
  handleClose,
}) => {
  if (!restaurant) return null;
  const [update] = useMutation(ADMIN_UPDATE_RESTAURANT);
  const [updateTaxRate] = useMutation(ADMIN_UPDATE_TAX_RATE);
  const classes = useStyles();

  const [serviceFeePerc, setServiceFeePerc] = useState<number>(
    restaurant.serviceFeePerc ?? 0
  );
  const [serviceFeeCap, setServiceFeeCap] = useState<number>(
    restaurant.serviceFeeCap ?? 0
  );
  const [serviceFeeInfoPaid, setServiceFeeInfoPaid] = useState<number>(
    restaurant.serviceFeeInfo?.paid ?? 0
  );
  const [serviceFeeInfoMonth, setServiceFeeInfoMonth] = useState<string>(
    restaurant.serviceFeeInfo?.month ?? ""
  );
  const [name, setName] = useState<string>(restaurant.name);
  const [address, setAddress] = useState<string>(restaurant.address);
  const [county, setCounty] = useState<string>(
    restaurant.county ? restaurant.county : ""
  );
  const [zipCode, setZipCode] = useState<string>(
    restaurant.zipCode ? restaurant.zipCode : ""
  );
  const [permitNumber, setPermit] = useState<string>(
    restaurant.permitNumber ? restaurant.permitNumber : ""
  );
  const [description, setDescription] = useState<string>(
    restaurant.description ? restaurant.description : ""
  );
  const [stars, setStars] = useState(
    restaurant.overallReview ? restaurant.overallReview.stars : 0
  );

  const [tags, setTags] = useState(restaurant.tags ?? []);

  const [image, setImage] = useState(
    restaurant.imagesGQL[0] ? restaurant.imagesGQL[0].original : ""
  );
  const [_loading, setLoading] = useState<boolean>(false);

  const [hasDog, setHasDog] = useState(restaurant.info.hasDog);
  const [hasCat, setHasCat] = useState(restaurant.info.hasCat);
  const [otherAnimals, setOtherAnimals] = useState(
    restaurant.info.otherAnimals
  );
  const [managerCertificate, setManagerCertificate] = useState(
    restaurant.badges.managerCertificate
  );
  const [insurance, setInsurance] = useState(restaurant.badges.insurance);

  // handlers
  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    const overallReview = { ...restaurant.overallReview }
      ? { ...restaurant.overallReview }
      : {
          count: 1,
          stars: 5,
          quality: 5,
          accuracy: 5,
          hospitality: 5,
        };
    // tslint:disable-next-line: no-string-literal
    delete overallReview["__typename"];
    overallReview.stars = stars;
    update({
      variables: {
        input: {
          restaurantId: restaurant.id,
          name,
          address,
          county,
          permitNumber,
          tags,
          description,
          images: [image],
          overallReview,
          serviceFeePerc,
          serviceFeeCap,
          serviceFeeInfo: {
            paid: serviceFeeInfoPaid,
            month: serviceFeeInfoMonth,
          },
          info: {
            hasDog,
            hasCat,
            otherAnimals,
          },
          badges: {
            managerCertificate,
            insurance,
          },
        },
      },
    })
      .then(() => {
        handleClose();
        sharedAPI.setSnackbarMsg({ type: "success", msg: "Done" });
      })
      .catch(() => sharedAPI.setSnackbarMsg({ type: "error", msg: "Error" }));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="application-dialog"
    >
      <DialogTitle>{restaurant.name}</DialogTitle>
      <DialogContent style={{ width: "400px" }}>
        <form onSubmit={submitHandler} className={classes.form}>
          <TextField
            size="small"
            disabled={_loading}
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            size="small"
            disabled={_loading}
            type="number"
            inputProps={{ min: "0", max: "5", step: ".01" }}
            label="Stars"
            value={stars}
            onChange={(e) => setStars(Number(e.target.value))}
          />
          <TextField
            size="small"
            disabled={_loading}
            type="number"
            inputProps={{ min: "0", max: "0.15", step: "0.01" }}
            label="Service Fee (0.1 as 10%)"
            value={serviceFeePerc}
            onChange={(e) => setServiceFeePerc(Number(e.target.value))}
          />
          <TextField
            size="small"
            disabled={_loading}
            type="number"
            label="Service Fee Cap"
            value={serviceFeeCap}
            onChange={(e) => setServiceFeeCap(Number(e.target.value))}
          />
          <TextField
            size="small"
            disabled={_loading}
            type="number"
            label="Service Fee Paid for this Month"
            value={serviceFeeInfoPaid}
            onChange={(e) => setServiceFeeInfoPaid(Number(e.target.value))}
          />
          <TextField
            size="small"
            disabled={_loading}
            label="Service Fee Paid Month"
            value={serviceFeeInfoMonth}
            onChange={(e) => setServiceFeeInfoMonth(e.target.value)}
          />

          <ChipsInput initialState={tags} onAddTag={setTags} label={"Tags"} />
          <TextField
            size="small"
            disabled={_loading}
            label="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <TextField
            size="small"
            disabled={_loading}
            label="Image"
            value={image}
            onChange={(e) => setImage(e.target.value)}
          />
          <TextField
            size="small"
            disabled={_loading}
            label="Zip Code"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
          <TextField
            size="small"
            disabled={_loading}
            label="County"
            value={county}
            onChange={(e) => setCounty(e.target.value)}
          />
          <TextField
            size="small"
            disabled={_loading}
            label="Permit #"
            value={permitNumber}
            onChange={(e) => setPermit(e.target.value)}
          />
          <TextField
            size="small"
            disabled={_loading}
            label="Description"
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography component="label" variant="caption" htmlFor="hasCat">
              Cat
            </Typography>
            <Checkbox
              id="hasCat"
              checked={hasCat}
              onChange={() => setHasCat(!hasCat)}
              inputProps={{
                "aria-label": "primary checkbox",
              }}
            />
            <Typography component="label" variant="caption" htmlFor="hasDog">
              Dog
            </Typography>
            <Checkbox
              id="hasDog"
              checked={hasDog}
              onChange={() => setHasDog(!hasDog)}
              inputProps={{
                "aria-label": "primary checkbox",
              }}
            />
            <Typography component="label" variant="caption">
              Other Animals
            </Typography>
            <input
              autoComplete={"off"}
              type="text"
              value={otherAnimals}
              onChange={(e) => setOtherAnimals(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography component="label" variant="caption" htmlFor="insurance">
              Insurance
            </Typography>
            <Checkbox
              id="insurance"
              checked={insurance}
              onChange={() => setInsurance(!insurance)}
              inputProps={{
                "aria-label": "primary checkbox",
              }}
            />
            <Typography
              component="label"
              variant="caption"
              htmlFor="managerCertificate"
            >
              Cert
            </Typography>
            <Checkbox
              id="managerCertificate"
              checked={managerCertificate}
              onChange={() => setManagerCertificate(!managerCertificate)}
              inputProps={{
                "aria-label": "primary checkbox",
              }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography component="label">
              {restaurant.taxRate * 100}%
            </Typography>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={() =>
                updateTaxRate({ variables: { input: restaurant.id } })
                  .then(() => {
                    sharedAPI.setSnackbarMsg({ type: "success", msg: "Done" });
                  })
                  .catch(() =>
                    sharedAPI.setSnackbarMsg({ type: "error", msg: "Error" })
                  )
              }
              style={{ margin: 10 }}
            >
              Update Rate
            </Button>
          </div>
          <Button
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            style={{ margin: 10 }}
          >
            {_loading ? <CircularProgress /> : "Submit"}
          </Button>
        </form>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
};
