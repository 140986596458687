import { Actions, IAction } from "./actions";

export interface IFormState {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: string;
  city: string;
  zipCode: string;
  state: string;
  callTime: any;
  formError: string;
}

const initialFormState: IFormState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  address: "",
  city: "",
  zipCode: "",
  state: "",
  callTime: null,
  formError: "",
};

function formReducer(state: IFormState, action: IAction): IFormState {
  switch (action.type) {
    case Actions.SET_FIELD:
      return { ...state, [action.name]: action.value };
    case Actions.SET_ERROR:
      return { ...state, formError: action.error };
    case Actions.SET_USER_INFO:
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        phoneNumber: action.phoneNumber,
        email: action.email,
      };
    default:
      return state;
  }
}

export { initialFormState, formReducer };
