import { gql } from "@apollo/client";

export const FACEBOOK_SIGN_IN = gql`
  mutation loginWithFacebook($input: FacebookUserInfo!) {
    signInWithFacebook(facebookUserInfo: $input) {
      id
      firstName
      lastName
      email
      picture
      nomes
      credits
      phoneNumber
      address
      authToken
      createdAt
      newUser
      preferences {
        NEWS_LETTER_EMAIL_SUBSCRIPTION
      }
    }
  }
`;

export const GOOGLE_SIGN_IN = gql`
  mutation signInWithGoogle($input: GoogleUserInfo!) {
    signInWithGoogle(googleUserInfo: $input) {
      email
      role
      firstName
      lastName
      nomes
      credits
      account
      createdAt
      authToken
      newUser
      preferences {
        NEWS_LETTER_EMAIL_SUBSCRIPTION
      }
    }
  }
`;

export const APPLE_SIGN_IN = gql`
  mutation signInWithApple($input: AppleUserInfo!) {
    signInWithApple(appleUserInfo: $input) {
      email
      role
      firstName
      lastName
      nomes
      credits
      account
      createdAt
      authToken
      newUser
      preferences {
        NEWS_LETTER_EMAIL_SUBSCRIPTION
      }
    }
  }
`;
