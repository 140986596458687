import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { ChevronLeft, Group, Lock, Room } from "@material-ui/icons";
import React from "react";
import { amplitude } from "src/services";
import { COLORS } from "src/styles";
import { AutoCompleteContainer } from "../auto-complete";
import { useStyles } from "./styles";

interface INameFormProps {
  firstName: string;
  lastName: string;
  address: string;
  password: string;
  requireAddress: boolean;
  isLoading: boolean;
  onSubmit: (event: any) => void;
  handleInputChange: (event: {
    target: { value: string; name: string };
  }) => void;
  onClickBack: () => void;
}

export const NameForm: React.FC<INameFormProps> = ({
  onSubmit,
  firstName,
  lastName,
  address,
  password,
  requireAddress,
  handleInputChange,
  isLoading,
  onClickBack,
}) => {
  const classes = useStyles();
  const _onSubmit = (e) => {
    amplitude.getInstance().logEvent("[Guest Checkout] submit");
    onSubmit(e);
  };
  return (
    <div style={{ paddingBottom: 40 }} className={classes.container}>
      <IconButton
        data-testid="back-button"
        classes={{ root: classes.textButton }}
        onClick={onClickBack}
      >
        <ChevronLeft style={{ fontSize: "2.5rem" }} />
      </IconButton>

      <img
        className={classes.logo}
        src={require(`src/assets/logos/cook.svg`)}
      />

      <Typography variant="h4" component="h4" className={classes.mainHeading}>
        Let's get you set up 😋
      </Typography>
      <Typography variant="body1" component="p" className={classes.subHeading}>
        Fill out the fields below so that we can easily track and secure your
        order.
      </Typography>

      <form className={classes.form} onSubmit={_onSubmit}>
        <Typography
          component="label"
          variant="caption"
          className="hidden-label"
          htmlFor="first-name-input"
        >
          first name
        </Typography>
        <OutlinedInput
          type="text"
          name="firstName"
          labelWidth={0}
          placeholder={"first name*"}
          autoComplete={"off"}
          id="first-name-input"
          value={firstName}
          onChange={handleInputChange}
          classes={{
            root: classes.inputRoot,
            input: classes.input,
          }}
          endAdornment={<Group style={{ color: COLORS.DARK_GREY }} />}
        />

        <Typography
          component="label"
          variant="caption"
          className="hidden-label"
          htmlFor="last-name-input"
        >
          last name
        </Typography>
        <OutlinedInput
          type="text"
          name="lastName"
          labelWidth={0}
          placeholder={"last name*"}
          id="last-name-input"
          value={lastName}
          autoComplete={"off"}
          onChange={handleInputChange}
          classes={{
            root: classes.inputRoot,
            input: classes.input,
          }}
          endAdornment={<Group style={{ color: COLORS.DARK_GREY }} />}
        />
        {requireAddress && (
          <>
            <Typography
              component="label"
              variant="caption"
              // className="hidden-label"
              className={classes.label}
              htmlFor="guest-dialog-autocomplete-input"
            >
              Delivery Address *
            </Typography>
            <AutoCompleteContainer
              initialState={address}
              onSelect={(e) =>
                handleInputChange({ target: { name: "address", value: e } })
              }
              onKey={(e) =>
                handleInputChange({ target: { name: "address", value: e } })
              }
              TextFieldProps={{
                variant: "outlined",
                fullWidth: true,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Room className={classes.icon} />
                    </InputAdornment>
                  ),
                  placeholder: `${
                    requireAddress ? "Delivery Address *" : "Address (Optional)"
                  }`,
                  classes: {
                    root: classes.inputRoot,
                    input: classes.input,
                  },
                  name: "address",
                  id: "guest-dialog-autocomplete-input",
                },
              }}
            />
          </>
        )}
        <Typography
          component="label"
          variant="caption"
          className={classes.label}
          htmlFor="password-input"
        >
          Create Password
        </Typography>
        <OutlinedInput
          type="password"
          name="password"
          labelWidth={0}
          placeholder={"password (optional)"}
          id="password-input"
          value={password}
          onChange={handleInputChange}
          classes={{
            root: classes.inputRoot,
            input: classes.input,
          }}
          endAdornment={<Lock style={{ color: COLORS.DARK_GREY }} />}
        />

        <Button
          type="submit"
          classes={{
            label: classes.simpleButtonLabel,
            contained: classes.continueButton,
          }}
          disabled={
            !firstName || !lastName || isLoading || (requireAddress && !address)
          }
          variant="contained"
        >
          {isLoading ? (
            <CircularProgress style={{ color: COLORS.WHITE }} />
          ) : (
            "Continue to checkout"
          )}
        </Button>
      </form>
    </div>
  );
};
