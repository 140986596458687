import { makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { COLORS } from "src/styles";

interface IProps {
  selection: string;
}

export const CookDinerToggle: FC<IProps> = ({ selection }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.container}>
      {/* Button 1 */}
      <button
        onClick={() =>
          history.push({ pathname: "/how-it-works", search: "?type=diner" })
        }
        className={classNames(classes.button, classes.buttonLeft)}
      >
        <div
          className={classNames(
            selection === "diner" ? classes.selected : null,
            classes.textContainer
          )}
        >
          <Typography
            component="div"
            variant="body1"
            style={{ fontFamily: "CustomFourBold" }}
          >
            For Diners
          </Typography>
        </div>
      </button>
      {/* Button 2 */}
      <button
        onClick={() =>
          history.push({ pathname: "/how-it-works", search: "?type=cook" })
        }
        className={classNames(classes.button, classes.buttonRight)}
      >
        <div
          className={classNames(
            selection === "cook" ? classes.selected : null,
            classes.textContainer
          )}
        >
          <Typography
            component="div"
            variant="body1"
            style={{ fontFamily: "CustomFourBold" }}
          >
            For Cooks
          </Typography>
        </div>
      </button>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    borderRadius: "5rem",
    boxShadow: "inset 0 0 8px 0 rgba(255,48,86,0.49)",
    width: "100%",
    maxWidth: "40rem",
  },
  button: {
    unset: "all",
    outline: "none",
    width: "50%",
    height: "5rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    textTransform: "capitalize",
    backgroundColor: "transparent",
    color: COLORS.MEDIUM_RED,
  },
  buttonLeft: {
    border: `1px solid ${COLORS.MEDIUM_RED}`,
    borderRight: 0,
    borderRadius: "5rem 0 0 5rem",
  },
  buttonRight: {
    border: `1px solid ${COLORS.MEDIUM_RED}`,
    borderLeft: 0,
    borderRadius: "0 5rem 5rem 0",
  },
  selected: {
    width: "96%",
    position: "relative",
    height: "90%",
    backgroundColor: COLORS.MEDIUM_RED,
    color: COLORS.WHITE,
    borderRadius: "5rem",
    boxShadow: "0 0 6px 2px #FFD0D8",

    // This is for the "triangle" under the selected container
    "&:before": {
      content: "''",
      position: "absolute",
      transform: "rotate(135deg)",
      bottom: "-1rem",
      zIndex: 1,
      borderColor: `${COLORS.MEDIUM_RED} ${COLORS.MEDIUM_RED} transparent transparent`,
      borderStyle: "solid",
      borderWidth: "1rem",
    },
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
