import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";

interface IProps {
  timeout: number;
  onKillSession?: () => void;
  events?: string[];
}

export function useActivityMonitor({
  timeout,
  onKillSession = () => ({}),
  events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"],
}: IProps) {
  const [promptUser, setPromptUser] = useState<boolean>(false);
  const timerRef = React.useRef<any>(null);
  const dependenciesRef = React.useRef<any>(null);
  const restoreSessionRef = React.useRef<any>(() => ({}));

  const dependencies = [events, timeout];

  function handleKillSession() {
    setPromptUser(false);
    onKillSession();
  }

  useEffect(() => {
    if (isEqual(dependencies, dependenciesRef.current)) return;

    function restoreSession() {
      // set promptUser to false
      setPromptUser(false);
      // clear old timer
      clearTimeout(timerRef.current);
      // set new timer
      timerRef.current = setTimeout(handlePromptUser, timeout);
    }

    function handlePromptUser() {
      // prompt user that the time is up!
      setPromptUser(true);
      // remove all event listeners
      events.forEach((event) =>
        window.removeEventListener(event, restoreSession)
      );
    }

    restoreSessionRef.current = restoreSession;

    // add all event listeners
    events.forEach((event) => window.addEventListener(event, restoreSession));
    // set timer
    timerRef.current = setTimeout(handlePromptUser, timeout);

    // cleanup effect
    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, restoreSession)
      );
      clearTimeout(timerRef.current);
    };
  }, dependencies);

  useEffect(() => {
    dependenciesRef.current = dependencies;
  });

  return {
    promptUser,
    restoreSession: restoreSessionRef.current,
    killSession: handleKillSession,
  };
}
