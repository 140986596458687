import { Divider, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Element } from "react-scroll";
import { RestaurantReviews } from "src/components";
import { IGetMe, IRestaurant } from "src/models";
import { useTopViewport } from "src/utils/hooks";
import { useStyles } from "../../layout.styles";
import { useTabsState } from "../../tabs/context";
import { OverallReview } from "./overall";

interface ReviewsProps {
  restaurant: IRestaurant;
  getMe?: IGetMe;
}

export const Reviews: React.FC<ReviewsProps> = ({ restaurant, getMe }) => {
  const classes = useStyles();
  const ref = React.useRef<any>();
  const inView = useTopViewport(ref);
  const location = useLocation();

  const { dispatch } = useTabsState();
  useEffect(() => {
    if (
      !location?.pathname?.split("/")?.includes("menus") &&
      !location?.pathname?.split("/")?.includes("events")
    ) {
      if (inView && dispatch) {
        dispatch({ type: "SET_TAB_VALUE", value: "reviews" });
      }
    }
  }, [inView]);

  return (
    <>
      <Element name="reviews" />
      <section ref={ref} style={{ marginBottom: "1rem", maxWidth: 550 }}>
        <Typography variant="h2" component="h2" className={classes.subheading}>
          Reviews for {restaurant.name}
        </Typography>
        <Divider />
        <OverallReview overallReview={restaurant.overallReview} />
        <Divider />
        <RestaurantReviews restaurant={restaurant} getMe={getMe} />
      </section>
    </>
  );
};
