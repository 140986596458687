import { Paper, Typography } from "@material-ui/core";
import React, { FC } from "react";

interface IProps {
  count: number;
}
export const Header: FC<IProps> = ({ count }) => {
  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
      }}
    >
      <Typography variant="h3" component="h1" style={{ marginBottom: "2rem" }}>
        Referrals ({count})
      </Typography>
    </Paper>
  );
};
