import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.47)",
  },
  nested: {
    paddingLeft: "4rem",
  },
  drawerPaper: {
    width: "80%",
    maxWidth: 340,
    borderTopLeftRadius: 10,
    overflow: "visible",
  },
  buildVersionContainer: {
    marginLeft: "2rem",
    color: COLORS.MEDIUM_GREY,
  },
  innerDrawer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: "space-between",
    overflowY: "auto",
    height: "100%",
  },
  userInfoContainer: {
    padding: "1.5rem",
  },
  userInfoLayout: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  nameContainer: {
    display: "flex",
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  editProfileLink: {
    textAlign: "center",
    fontFamily: "CustomFourBold",
    backgroundColor: COLORS.MEDIUM_RED,
    color: COLORS.WHITE,
    textDecoration: "none",
    borderRadius: "2px",
    padding: "0px 11.5px",

    zIndex: 1,

    textTransform: "uppercase",
    position: "absolute",
    bottom: -8,
  },
  avatar: {
    height: 54,
    width: 54,
    backgroundColor: COLORS.RED,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    textTransform: "uppercase",
  },
  credits: {
    margin: 0,
    color: "#787878",
  },
  creditAmount: {
    fontFamily: "CustomFourBold",

    fontWeight: 500,
    color: COLORS.DARK_RED,
    "&::before": {
      content: `"$"`,
    },
  },
  closeButton: {
    position: "absolute",
    top: 0,
    left: -10,
    padding: 3,
    boxShadow: "0 1px 7px 0 rgba(0,0,0,0.17)",
    backgroundColor: COLORS.WHITE,
    zIndex: 3,
    "&:hover": {
      backgroundColor: COLORS.WHITE,
    },
  },
  closeIcon: {
    color: COLORS.MEDIUM_RED,
  },
  name: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    color: COLORS.BLACK,
    textTransform: "capitalize",
    padding: 0,
    margin: 0,
    overflow: "hidden",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
  },
  list: {
    padding: 0,
  },
  listItemIcon: {
    margin: 0,
    minWidth: 0,
    color: COLORS.BLACK,
  },
  rootListItem: {
    paddingTop: 14,
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    "&:last-child": {
      marginBottom: "calc(1rem + env(safe-area-inset-bottom))",
    },
  },
  linksContainer: {
    flex: 1,
  },
  linkItemLayout: {
    maxWidth: 300,
    width: "100%",
    margin: `0 auto`,
    display: "flex",
    justifyContent: "space-between",
  },
  listHorizontal: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    padding: "1rem",
  },
  listHorizontalItem: {
    width: 95,
    padding: 0,
  },
  navlink: {
    fontWeight: 100,
    color: COLORS.BLACK,
    padding: "1rem 0.5rem",
    "&:hover": {
      [`@media (min-width: ${BREAK_POINTS.mobileRegular}em)`]: {
        backgroundColor: "#EDEDED",
        fontFamily: "CustomFourBold",
        fontWeight: 500,
      },
    },
  },
  activeNavLink: {
    backgroundColor: "#EDEDED",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  link: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    textDecoration: "none",
    color: COLORS.MEDIUM_RED,
  },
  permitInProgress: {
    fontWeight: 100,
    color: "#787878",
    margin: 0,
    display: "flex",
    alignItems: "center",
  },
});
