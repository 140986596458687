import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    position: "relative",
    width: "100%",
    flexGrow: 1,
    display: "grid",
    gridTemplateColumns: `repeat(2, 1fr)`,
    gridAutoRows: "100%",
    height: "calc(100vh - 56px)",
  },
  image: {
    gridRow: "1 / -1",
    backgroundSize: "cover",
    backgroundImage: `url(${require(`./assets/sign-up-image.jpg`)})`,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  signUpFormRight: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "0 auto",
    alignItems: "center",
    justifyContent: "center",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      gridColumn: "1 / -1",
    },
  },
});
