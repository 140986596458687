import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    width: "70%",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
    },
  },
  dishLeft: {
    color: COLORS.RED,
    marginBottom: 2,
    fontFamily: "CustomFourBold",
  },
  promoWarning: {
    color: COLORS.RED,
    margin: 0,
    fontFamily: "CustomFourBold",
  },
  BGcolor: {
    width: "100%",
    backgroundColor: "#FAFDFE",
  },
  dishesContainer: {
    width: "100%",
    padding: "2rem 2rem",
    maxWidth: 844,
    margin: "0 auto",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "2rem auto",
    },
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "2rem 0",
      width: "92%",
    },
  },
  dishes: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gridAutoColumns: "auto",
    gridGap: "1em",
  },
  dishesTitle: {
    fontFamily: "CustomFourBold",

    fontWeight: 500,
  },
  eventInfoContainer: {
    maxWidth: 844,
    margin: "0 auto",
    padding: "2rem 2rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "2rem 0",
      width: "92%",
    },
  },
  title: {
    fontFamily: "CustomFourBold",

    fontWeight: 500,
    marginBottom: "1rem",
  },
  subTitle: {
    color: "grey",

    whiteSpace: "pre-wrap",
  },
});
