import { gql, useQuery } from "@apollo/client";
import { Badge, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { CookDish } from "src/assets/icons/svg/cook-dish";
import { COLORS } from "src/styles";

export const GET_UPCOMING_MEALS = gql`
  query getDinerEvents($input: GetMyEventsPL!) {
    getDinerEvents(getDinerEventsPL: $input) {
      id
    }
  }
`;

export const UpcomingMealsBadge = () => {
  const classes = useStyles();
  const { data, error, loading } = useQuery(GET_UPCOMING_MEALS, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        status: "RESERVED",
        past: false,
        upcoming: true,
      },
    },
  });
  const [badgeCount, setBadgeCount] = useState<number | null>(null);

  useEffect(() => {
    /*
      Why am I doing this? From a UX perspective, I think it is more
      eye catching for the badge count to appear a bit delayed.
      Feel free to remove this effect.
    */
    if (data && data.getDinerEvents && data.getDinerEvents.length) {
      const timeout = setTimeout(
        () => setBadgeCount(data.getDinerEvents.length),
        1000
      );
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [data]);

  if (error) return <CookDish />;

  const badgeContent = loading ? (
    <CircularProgress
      data-testid="UpcomingMealsBadge-spinner"
      size={8}
      style={{ color: COLORS.WHITE }}
    />
  ) : (
    badgeCount
  );

  return (
    <Badge
      classes={{ anchorOriginTopRightRectangle: classes.circle }}
      showZero={false}
      badgeContent={badgeContent}
      color="primary"
    >
      <CookDish />
    </Badge>
  );
};

const useStyles = makeStyles({
  circle: {
    transform: `translate(50%, -30%)`,
  },
});
