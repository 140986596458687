import React, { FC } from "react";
import { IEvent } from "src/models";
import {
  MobileEventsCard,
  SavedEventCard,
} from "../event/orders-list/events-card";
import { ScrollHeader } from "./scroll-list.header";
import { layoutStyles as useStyles } from "./styles";

interface IProps {
  events: IEvent[];

  title: string;
  status: "PAST" | "UPCOMING" | "SAVED";
}

export const ScrollList: FC<IProps> = ({ events, title, status }) => {
  const classes = useStyles();

  if (title === "Saved Menus") {
    // Conditionally render scroll-list with saved-event cards if
    // we are showing saved events
    return (
      <>
        <ScrollHeader title={title} status={status} />
        <div className={classes.mobileEventList}>
          {events.map((event) => (
            <SavedEventCard event={event} key={event.id} scroll={true} />
          ))}
        </div>
      </>
    );
  }
  return (
    // otherwise just show scroll-list with mobile event cards
    <>
      <ScrollHeader title={title} status={status} />
      <div className={classes.mobileEventList}>
        {events.map((event) => (
          <MobileEventsCard
            event={event}
            key={event.id}
            status={status}
            scroll={true}
          />
        ))}
      </div>
    </>
  );
};
