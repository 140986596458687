import { client } from "src/apollo";
import { dinerPixel, ReactPixel } from "src/index";
import {
  CreateEventUserPL,
  ICheckoutAsGuest,
  UpdateEventUserPL,
} from "src/models";
import { GET_ME } from "src/shared-graphql/queries";
import { LocalStorageHelper } from "src/utils/helpers";
import { tagManager } from "src/utils/react-gtm";
import { CREATE_EVENT_USER, GET_EVENT } from "./graphql";
import {
  DINER_EDIT_ORDER,
  DINER_UPDATE_ORDER,
  GUEST_SIGN_IN,
  INIT_EDIT_ORDER,
  UPDATE_EVENT_USER_STATUS,
} from "./graphql/mutations";

export { DINER_EDIT_ORDER, DINER_UPDATE_ORDER };

export const authAPI = {
  checkoutAsGuest: (pl: ICheckoutAsGuest) =>
    client
      .mutate({
        mutation: GUEST_SIGN_IN,
        variables: {
          input: {
            ...pl,
            source: localStorage.getItem("foodnome-source"),
            referralCode: localStorage.getItem("foodnome-referree-code"),
          },
        },
        ...(process.env.REACT_APP_ENV !== "cordova" && {
          awaitRefetchQueries: true,
          refetchQueries: [{ query: GET_ME }],
        }),
      })
      .then((d) => {
        tagManager.track({
          event: "UserSignUp",
        });
        if (process.env.REACT_APP_ENV === "cordova") {
          if (d.data && d.data.guestSignIn && d.data.guestSignIn.authToken) {
            LocalStorageHelper.setItem(
              "foodnome_auth_token",
              d.data.guestSignIn.authToken
            );
          }
          client.query({
            query: GET_ME,
            fetchPolicy: "network-only",
            errorPolicy: "ignore",
          });
        }
        process.env.NODE_ENV !== "test" &&
          ReactPixel.trackSingle(dinerPixel, "CompleteRegistration", {
            value: 0,
            currency: "USD",
          });
        return d;
      }),
};

export interface CancelEventUserPL {
  eventId: string;
}
export const eventsAPI = {
  createEventUser: async (createEventUserPL: CreateEventUserPL) =>
    client.mutate({
      mutation: CREATE_EVENT_USER,
      variables: {
        input: createEventUserPL,
      },
      refetchQueries: [
        {
          query: GET_EVENT,
          variables: {
            input: createEventUserPL.eventId,
          },
        },
      ],
    }),

  initEditOrder: (id: number | string) =>
    client.mutate({
      mutation: INIT_EDIT_ORDER,
      variables: {
        input: id,
      },
      awaitRefetchQueries: true,
      errorPolicy: "ignore",
      refetchQueries: ["getMe", "getEvent"],
    }),

  updateEventUserStatus: async (updateEventUserPL: UpdateEventUserPL) =>
    client.mutate({
      mutation: UPDATE_EVENT_USER_STATUS,
      variables: {
        input: updateEventUserPL,
      },
      refetchQueries: ["getEvent"],
    }),
};
