import Bugsnag from "@bugsnag/js";
import {
  Button,
  InputAdornment,
  List,
  ListItem,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Email, Lock } from "@material-ui/icons";
import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { _hsq } from "src";
import {
  AuthWithApple,
  AuthWithFacebook,
  AuthWithGoogle,
} from "src/components";
import {
  AppleBtn,
  DecoratedText,
  FacebookBtn,
  GoogleBtn,
} from "src/components/shared";
import { amplitude } from "src/services";
import { COLORS } from "src/styles";
import { getEmailError } from "src/utils/user-helpers";
import { LoginError } from "./error";
import { useFormStyles } from "./form.styles";

interface IProps {
  login: (d: { email: string; password: string }) => void;
  setError: (d: any) => void;
  switchView?: () => void; // need this for the dialog user-account
  onSubmit?: (d?: any) => void;
  containerStyle?: React.CSSProperties;
  error: any;
  email?: string;
  closeDialog?: () => void;
}

export const LoginForm: FC<IProps> = ({
  login,
  error,
  setError,
  switchView,
  email,
  onSubmit,
  containerStyle,
  closeDialog = () => ({}),
}) => {
  const classes = useFormStyles();
  const [_email, setEmail] = useState(email ? email : "");
  const [password, setPassword] = useState("");
  const [thirdPartyAuthError, setThirdPartyAuthError] = useState<any>(null);

  return (
    <Paper style={containerStyle} className={classes.container}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          Bugsnag.setUser(_email, _email, "Unknown");
          amplitude.getInstance().setUserId(_email);
          _hsq?.push(["identify", { email: _email }]);
          amplitude.getInstance().logEvent("[Login] submit");
          return login({ email: _email, password });
        }}
      >
        <section className={classes.fieldsContainer}>
          <div className={classes.fieldsWrapper}>
            <img
              className={classes.logo}
              src={require(`src/assets/logos/cook.svg`)}
            />
            <Typography variant="h4" component="h4" className={classes.header}>
              Log in
            </Typography>

            <Typography
              component="label"
              variant="caption"
              className={classes.label}
              htmlFor="email"
            >
              Email
            </Typography>
            <TextField
              size="small"
              autoComplete="off"
              id="email"
              data-e2e={"login-email" as any}
              value={_email}
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              fullWidth
              placeholder="Email"
              variant="outlined"
              error={Boolean(getEmailError(_email))}
              helperText={getEmailError(_email)}
              FormHelperTextProps={{
                classes: {
                  root: classes.formHelperRoot,
                },
              }}
              inputProps={{ maxLength: 200 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Email style={{ color: COLORS.DARK_GREY }} />
                  </InputAdornment>
                ),
                classes: {
                  input: classes.input,
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                  notchedOutline: classes.outlined,
                },
              }}
            />
            <Typography
              component="label"
              variant="caption"
              className={classes.label}
              htmlFor="password"
            >
              Password
            </Typography>
            <TextField
              size="small"
              id="password"
              variant="outlined"
              data-e2e={"login-password" as any}
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              inputProps={{ maxLength: 200 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Lock style={{ color: COLORS.DARK_GREY }} />
                  </InputAdornment>
                ),
                classes: {
                  input: classes.input,
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                  notchedOutline: classes.outlined,
                },
              }}
              fullWidth
            />
            <LoginError error={error} closeDialog={closeDialog} />
            <Button
              data-e2e="login-btn"
              data-testid="login-btn"
              id="login-submit-btn"
              variant="contained"
              type="submit"
              fullWidth
              disabled={!Boolean(_email) || !Boolean(password)}
              className={classes.actionButton}
            >
              Log in
            </Button>
          </div>

          <DecoratedText>or</DecoratedText>

          <List className={classes.list}>
            <AuthWithGoogle
              onSubmit={onSubmit}
              onError={(err) => setThirdPartyAuthError(err)}
            >
              {/* The reason why we place <ListItem> inside of Auth wrapper is
               because when we don't render a button (e.g. Apple auth) additional padding is shown */}
              <ListItem classes={{ gutters: classes.listItemGutters }}>
                <GoogleBtn
                  onClick={() =>
                    amplitude
                      .getInstance()
                      .logEvent("[Signup Flow] thrid party")
                  }
                  text="Sign in with Google"
                />
              </ListItem>
            </AuthWithGoogle>
            <AuthWithFacebook
              onSubmit={onSubmit}
              onError={(err) => setThirdPartyAuthError(err)}
            >
              <ListItem classes={{ gutters: classes.listItemGutters }}>
                <FacebookBtn
                  text="Sign in with Facebook"
                  onClick={() =>
                    amplitude
                      .getInstance()
                      .logEvent("[Signup Flow] thrid party")
                  }
                />
              </ListItem>
            </AuthWithFacebook>
            <AuthWithApple
              onSubmit={onSubmit}
              onError={(err) => setThirdPartyAuthError(err)}
            >
              <ListItem classes={{ gutters: classes.listItemGutters }}>
                <AppleBtn
                  text="Sign in with Apple"
                  onClick={() =>
                    amplitude
                      .getInstance()
                      .logEvent("[Signup Flow] thrid party")
                  }
                />
              </ListItem>
            </AuthWithApple>
            {thirdPartyAuthError && (
              <Typography
                variant="body2"
                component="p"
                className={classes.thirdPartyError}
              >
                {thirdPartyAuthError}
              </Typography>
            )}
          </List>

          <div className={classes.subCard}>
            <div className={classes.createAccountContainer}>
              <Typography variant="body2" component="p">
                New to Foodnome?
              </Typography>
              {switchView ? (
                <Button
                  disableRipple
                  className={classes.loginLink}
                  onClick={switchView}
                >
                  Create Account
                </Button>
              ) : (
                <Link className={classes.loginLink} to="/sign-up">
                  <Typography variant="body2" component="span">
                    Create Account
                  </Typography>
                </Link>
              )}
            </div>
            <Typography
              variant="subtitle2"
              component="p"
              className={classes.centered}
              style={{ margin: "1em 0" }}
            >
              <Link
                onClick={() => {
                  closeDialog();
                  amplitude
                    .getInstance()
                    .logEvent("[Login] forgot password click");
                }}
                className={classes.loginLink}
                to="/get-reset-token"
              >
                Forgot your password?
              </Link>
            </Typography>
          </div>
        </section>
      </form>
    </Paper>
  );
};
