import { Paper } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { PaginationComponent } from "src/components";
import { IRestaurant } from "src/models";
import { IFetchMorePL } from "./container";
import { Header } from "./header";
import { RestaurantListTable } from "./table";

interface IProps {
  restaurants: IRestaurant[];
  totalCount: number;
  searchTerm: string;
  onFetchMore: (d: IFetchMorePL) => void;
  redirect: (d: string) => void;
  setSearchTerm: (d: string) => void;
}
export const Layout: FunctionComponent<IProps> = ({
  restaurants,
  totalCount,
  onFetchMore,
  redirect,
  searchTerm,
  setSearchTerm,
}) => {
  const _fetchMore = (d: number) =>
    onFetchMore({
      pagePL: { offset: (d - 1) * 10, limit: 10 },
    });
  return (
    <div>
      <Header
        totalCount={totalCount}
        onSearch={setSearchTerm}
        searchTerm={searchTerm}
      />
      <Paper style={{ margin: "1rem", padding: "1rem" }}>
        <RestaurantListTable restaurants={restaurants} redirect={redirect} />

        <PaginationComponent totalCount={totalCount} onChange={_fetchMore} />
      </Paper>
    </div>
  );
};
