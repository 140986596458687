import { useMutation } from "@apollo/client";
import moment from "moment";
import React, { FC, useState } from "react";
import { IPayment } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { ADMIN_REFUND_BRAINTREE } from "../api/graphql";
import { ADMIN_VOID_BRAINTREE } from "../api/graphql/mutations";

interface IProps {
  payment: IPayment;
}

export const PaymentSection: FC<IProps> = ({ payment }) => {
  const [refund] = useMutation(ADMIN_REFUND_BRAINTREE);
  const [voidR] = useMutation(ADMIN_VOID_BRAINTREE);
  const [total, setTotal] = useState(payment.totalAmount);

  return (
    <div>
      {payment.payOutId ? "Refunded, " : ""}
      Paid: {payment.totalAmount}, Created:{" "}
      {moment(Number(payment.createdAt)).format("lll")},{" "}
      {payment.raw.includes("braintree") && payment.totalAmount > 0 ? (
        <>
          <input
            value={total}
            onChange={(e) => setTotal(Number(e.target.value))}
            type="number"
            min="0"
            max={payment.totalAmount}
          />{" "}
          <button
            onClick={() =>
              refund({
                variables: {
                  input: {
                    id: payment.id,
                    amount: total,
                  },
                },
              })
                .then(() =>
                  sharedAPI.setSnackbarMsg({ type: "success", msg: "Refunded" })
                )
                .catch((e) =>
                  sharedAPI.setSnackbarMsg({
                    type: "error",
                    msg: e.message.replace(/^graphql error: /i, ""),
                  })
                )
            }
          >
            Refund
          </button>
          <button
            onClick={() =>
              voidR({
                variables: {
                  input: {
                    id: payment.id,
                  },
                },
              })
                .then(() =>
                  sharedAPI.setSnackbarMsg({ type: "success", msg: "Voided" })
                )
                .catch((e) =>
                  sharedAPI.setSnackbarMsg({
                    type: "error",
                    msg: e.message.replace(/^graphql error: /i, ""),
                  })
                )
            }
          >
            Void (Use if Refund does not work)
          </button>
        </>
      ) : (
        ""
      )}
    </div>
  );
};
