import { SvgIcon } from "@material-ui/core";
import React from "react";

export const ClickedIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 35 49.04">
    <path
      d="M35,17.55c0,14.5-15.33,30.19-16,30.87a2.05,2.05,0,0,1-1.5.62,2.14,2.14,0,0,1-1.5-.62c-.68-.68-16-16.37-16-30.87a17.5,17.5,0,1,1,35,0Z"
      fill="#B04B59" // cook dark red
      strokeWidth="2"
      stroke="#B04B59"
      strokeLinejoin="miter"
      strokeLinecap="round"
    />
  </SvgIcon>
);
