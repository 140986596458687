import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import JSONTree from "react-json-tree";
import { sharedAPI } from "src/shared-graphql";
import { copyToClipboard } from "src/utils/helpers";
import { kpiApi } from "./api";

export const KpiContainer = () => {
  const [data, setData] = useState({});

  const afterDone = (d: any) => {
    setData(d.data);
    sharedAPI.setSnackbarMsg({ type: "success", msg: "Done" });
  };
  return (
    <div style={{ display: "flex" }}>
      <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
        <section>
          <Typography variant="h4" component="h4">
            Click on the button, an email with data will be generated and sent
            to you
          </Typography>
        </section>

        <section>
          <button
            onClick={() =>
              kpiApi.getDinerCount({ StartDate: "2019-11-01" }).then(afterDone)
            }
          >
            MAD: Get number of diners who made a purchase this month
          </button>
          <button
            onClick={() =>
              kpiApi
                .getNewDinerCount({ Type: "MONTH" })
                .then(afterDone)
                .catch(() =>
                  sharedAPI.setSnackbarMsg({ type: "error", msg: "Error" })
                )
            }
          >
            Get new diner count by MONTH (This is very slow, so wait)
          </button>
          <button
            onClick={() =>
              kpiApi
                .getCooksByPayment({ StartDate: "2019-11-01" })
                .then(afterDone)
            }
          >
            MAC: Get number of cooks that have sold at least one plate in month
          </button>
        </section>

        <section>
          <button
            onClick={() =>
              kpiApi.getPayments({ StartDate: "2019-11-01" }).then(afterDone)
            }
          >
            Payments by Month
          </button>
          <button
            onClick={() =>
              kpiApi
                .getAveragePayments({ StartDate: "2019-11-01" })
                .then(afterDone)
            }
          >
            AOV: Average Payments by Month
          </button>
        </section>

        <section>
          <button
            onClick={() =>
              kpiApi
                .getAverageNumPayments({ StartDate: "2019-11-01" })
                .then(afterDone)
            }
          >
            Get Avg Num of Payments Per User by Month
          </button>
        </section>

        <section>
          <button
            onClick={() =>
              kpiApi.getLTV({ StartDate: "2019-11-01" }).then(afterDone)
            }
          >
            Get LTV
          </button>
        </section>

        <section>
          <button
            onClick={() =>
              kpiApi.getRetention({ StartDate: "2019-11-01" }).then(afterDone)
            }
          >
            Get Retention
          </button>
        </section>

        <section>
          <button
            onClick={() =>
              kpiApi
                .getMarketPlaceKpi({ StartDate: "2019-11-01" })
                .then(afterDone)
            }
          >
            Get Market Place KPI
          </button>
        </section>
      </div>

      <div style={{ display: "flex", width: "50%" }}>
        <JSONTree data={data} />
        <div style={{ width: "50%" }}>
          <button onClick={() => copyToClipboard(JSON.stringify(data))}>
            Copy
          </button>
          {JSON.stringify(data)}
        </div>
      </div>
    </div>
  );
};
