import { Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./restaurant-list.styles";
import { Suggestion } from "./suggestion";

export const RestaurantList = ({
  onSelectRestaurant,
  restaurants,
  isMobileSearchDialog,
  optionsLength,
  highlightedIndex,
  selectedItem,
  suggestionStyle,
  truncateSuggestion,
  getItemProps,
}) => {
  const classes = useStyles();
  if (onSelectRestaurant && restaurants.length) {
    return (
      <>
        <Typography
          variant="subtitle2"
          component="p"
          className={classes.restaurantHeader}
        >
          - Home Restaurants -
        </Typography>

        {restaurants.map((res, index) => (
          <Suggestion
            itemProps={getItemProps({
              item: res.name,
            })}
            key={res.name}
            suggestion={res.name}
            restaurant={res}
            index={optionsLength + index}
            highlightedIndex={highlightedIndex}
            selectedItem={selectedItem}
            suggestionStyle={suggestionStyle}
            truncateSuggestion={truncateSuggestion}
            onSelectRestaurant={onSelectRestaurant}
          />
        ))}
      </>
    );
  } else {
    return null;
  }
};
