import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { sharedAPI } from "src/shared-graphql";
import { EventsListContainer } from "../events/list/container";
import { GET_ME } from "../login/api";

export const ReferContainer = (props) => {
  const { data, loading } = useQuery(GET_ME);

  useEffect(() => {
    if (data?.getMe && !loading) {
      sharedAPI.setReferralDialogState(true);
    } else if (!data?.getMe && !loading) {
      sharedAPI.setUserSignInDialog(true);
    }
  }, [data?.getMe, loading]);

  return <EventsListContainer {...props} />;
};
