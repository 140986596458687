import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import moment from "moment";
import React, { FunctionComponent, useState } from "react";
import { IAllergen } from "src/models";

interface IProps {
  allergens: IAllergen[];
  deleteAllergen: (d: any) => void;
}

export const AllergenTable: FunctionComponent<IProps> = ({
  allergens,
  deleteAllergen,
}) => {
  const [hovered, setHover] = useState<string>("0");

  return (
    <>
      <TableContainer component={Paper}>
        <Table style={{ maxWidth: "none" }}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allergens.map((row) => (
              <TableRow key={row.id} hover onMouseOver={() => setHover(row.id)}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>
                  {moment(Number(row.createdAt)).format("MM-DD-YYYY")}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => deleteAllergen(row.id)}
                    style={row.id === hovered ? {} : { visibility: "hidden" }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
