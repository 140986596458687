import { SvgIcon } from "@material-ui/core";
import React from "react";

export const UserBecomeCook = (props) => (
  <SvgIcon {...props}>
    <g>
      <path d="M29,24.99H10c-0.55,0-1,0.45-1,1s0.45,1,1,1h19c0.55,0,1-0.45,1-1S29.55,24.99,29,24.99z" />
      <path d="M24,13.99c0.55,0,1-0.45,1-1v-6c0-0.55-0.45-1-1-1s-1,0.45-1,1v6C23,13.54,23.45,13.99,24,13.99z" />
      <path d="M14,13.99c0.55,0,1-0.45,1-1v-6c0-0.55-0.45-1-1-1s-1,0.45-1,1v6C13,13.54,13.45,13.99,14,13.99z" />
      <path d="M19,9.99c0.55,0,1-0.45,1-1v-6c0-0.55-0.45-1-1-1s-1,0.45-1,1v6C18,9.54,18.45,9.99,19,9.99z" />
      <path
        d="M15,23.99h8c3.87,0,7-3.13,7-7c0-0.55-0.45-1-1-1H9.24L3.45,13.1c-0.51-0.21-1.1,0.03-1.31,0.54
		c-0.19,0.46-0.02,0.99,0.41,1.24L8,17.62C8.33,21.24,11.37,24.01,15,23.99z M27.9,17.99c-0.48,2.33-2.52,4-4.9,4h-8
		c-2.38,0-4.42-1.67-4.9-4H27.9z"
      />
    </g>
  </SvgIcon>
);
