import { useQuery } from "@apollo/client";
import React, { FC, useState } from "react";
import { ErrorLoadingComponent } from "src/components";
import { IAllergen } from "src/models";
import { allergenAPI } from "../api";
import { GET_ALLERGENS } from "../api/graphql";
import { Layout } from "./layout";

export const AllergenListContainer: FC = () => {
  const { data, loading, error, fetchMore } = useQuery<{
    getAllergens: { count: number; rows: IAllergen[] };
  }>(GET_ALLERGENS, { variables: { input: { limit: 10 } } });

  const [pageNum, setPageNum] = useState(1);

  const _deleteAllergen = (id: string) => {
    if (data?.getAllergens.rows.length === 1 && pageNum > 1) {
      setPageNum(pageNum - 1);
      return allergenAPI.deleteAllergen(id, (pageNum - 2) * 10);
    }
    return allergenAPI.deleteAllergen(id, (pageNum - 1) * 10);
  };

  const _fetchMore = (d: number) => {
    setPageNum(d);
    fetchMore({
      variables: {
        input: { limit: 10, offset: (d - 1) * 10 },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          getAllergens: (fetchMoreResult as any).getAllergens,
        });
      },
    });
  };

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }

  return (
    <Layout
      allergens={data?.getAllergens.rows as IAllergen[]}
      count={data?.getAllergens.count as number}
      pageNum={pageNum}
      fetchMore={_fetchMore}
      deleteAllergen={_deleteAllergen}
    />
  );
};
