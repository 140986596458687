import { ApolloError } from "@apollo/client";
import { Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

interface IProps {
  error?: ApolloError;
}
export const SignUpError: FunctionComponent<IProps> = ({ error }) => {
  const errorMsg = error ? error.message : null;

  if (!errorMsg) {
    return null;
  }
  return (
    <Link to="/get-reset-token" style={{ textDecoration: "none" }}>
      <Typography
        data-testid="signup-error"
        color="error"
        style={{ marginTop: "1rem", textAlign: "center" }}
      >
        {errorMsg}
      </Typography>
    </Link>
  );
};
