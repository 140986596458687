import { Button } from "@material-ui/core";
import moment from "moment";
import React, { FC, useState } from "react";
import { IRestaurant } from "src/models";
import { useStyles } from "./date-selects.styles";

interface IProps {
  refetch: (d: { input: any }) => Promise<any>;
  restaurant: IRestaurant;
}

export const DateSelects: FC<IProps> = ({ refetch, restaurant }) => {
  const classes = useStyles();
  const [start, setStart] = useState(
    moment().subtract(1, "month").format("YYYY-MM")
  );
  return (
    <div className={classes.container}>
      Select Month:
      <input
        autoComplete={"off"}
        className={classes.input}
        type="month"
        value={start}
        onChange={(e) => {
          setStart(moment(e.target.value, "YYYY-MM").format("YYYY-MM"));
        }}
      ></input>
      <Button
        color="secondary"
        variant="contained"
        className={classes.btn}
        onClick={() =>
          refetch({
            input: {
              start: moment(start, "YYYY-MM").valueOf().toString(),
              end: moment(start, "YYYY-MM")
                .add(1, "month")
                .valueOf()
                .toString(),
              id: restaurant.id,
            },
          })
        }
      >
        Submit
      </Button>
    </div>
  );
};
