import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React, { FC } from "react";
import { IEvent } from "src/models";
import { ListItem } from "./guest-item";
import { useStyles } from "./promoDialog.styles";

interface IProps {
  close: () => any;
  event?: IEvent;
}

export const GuestsDialog: FC<IProps> = ({ close, event }) => {
  const classes = useStyles();
  if (!event) return null;
  return (
    <Dialog open={Boolean(event)} onClose={close}>
      <DialogTitle>Guest List</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {event.guests.map((guest) => (
            <ListItem onClose={close} key={guest.id} guest={guest} />
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};
