import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Email,
  Group,
  Lock,
} from "@material-ui/icons";
import { parse } from "query-string";
import React, { FC, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ISignUpPL } from "src/models";
import { amplitude } from "src/services";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import {
  getEmailError,
  getNameError,
  getPasswordError,
  isSignUpDisabled,
} from "src/utils/user-helpers";
import { signUpAPI } from "./api";
import { SignUpError } from "./error";
import { useStyles } from "./form.styles";

interface IProps {
  switchView?: () => void;
  onSubmit?: (d: any) => void;

  redirect: (pathname: string) => void;
}

export const SignUpForm: FC<IProps> = ({ switchView, redirect, onSubmit }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [emailChecked, setEmailChecked] = useState(true);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const location = useLocation();

  const handleSignUp = async (d: ISignUpPL) => {
    setLoading(true);
    setError(null);
    const queryObj = location
      ? (parse(location.search) as { code: string; method?: string })
      : null;
    const referralCode = queryObj?.code
      ? queryObj?.code
      : (localStorage.getItem("foodnome-referree-code") as string);

    d.referralCode = referralCode;
    return signUpAPI
      .signup(d)
      .then(() => {
        if (onSubmit) {
          sharedAPI.setDiscountPrompt(true);
          return onSubmit(d);
        }
        setLoading(false);
        redirect("/d");
      })
      .catch((e) => {
        setLoading(false);

        return setError(e);
      });
  };

  return (
    <form
      data-testid="sign-up-email-password-form"
      className={classes.form}
      onSubmit={(e) => {
        amplitude.getInstance().logEvent("[Signup Form] submit");
        e.preventDefault();
        return handleSignUp({
          email,
          password,
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          preferences: {
            NEWS_LETTER_EMAIL_SUBSCRIPTION: emailChecked,
          },
        });
      }}
    >
      <section className={classes.fieldsContainer}>
        <div
          className={classes.fieldsWrapper}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Typography
            component="label"
            variant="caption"
            className={classes.label}
            htmlFor="firstName"
          >
            First Name
          </Typography>
          <TextField
            size="small"
            autoComplete="new-password"
            FormHelperTextProps={{
              classes: { root: classes.formHelperText },
            }}
            variant="outlined"
            placeholder="First Name"
            fullWidth
            value={firstName}
            id="firstName"
            data-e2e={"first-name" as any}
            onChange={(e) => setFirstName(e.target.value)}
            error={Boolean(getNameError(firstName, "first"))}
            helperText={getNameError(firstName, "first")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Group style={{ color: COLORS.DARK_GREY }} />
                </InputAdornment>
              ),
              classes: {
                input: classes.signUpInput,
                root: classes.inputRoot,
                focused: classes.inputFocused,
              },
            }}
            inputProps={{ maxLength: 35 }}
          />
          <Typography
            component="label"
            variant="caption"
            className={classes.label}
            htmlFor="lastName"
          >
            Last Name
          </Typography>
          <TextField
            size="small"
            autoComplete="new-password"
            FormHelperTextProps={{
              classes: { root: classes.formHelperText },
            }}
            variant="outlined"
            placeholder="Last Name"
            fullWidth
            value={lastName}
            id="lastName"
            data-e2e={"last-name" as any}
            onChange={(e) => setLastName(e.target.value)}
            error={Boolean(getNameError(lastName, "last"))}
            helperText={getNameError(lastName, "last")}
            inputProps={{ maxLength: 35 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Group style={{ color: COLORS.DARK_GREY }} />
                </InputAdornment>
              ),
              classes: {
                input: classes.signUpInput,
                root: classes.inputRoot,
                focused: classes.inputFocused,
              },
            }}
          />
          <Typography
            component="label"
            variant="caption"
            className={classes.label}
            htmlFor="email"
          >
            Email
          </Typography>
          <TextField
            size="small"
            autoComplete="new-password"
            FormHelperTextProps={{
              classes: { root: classes.formHelperText },
            }}
            variant="outlined"
            placeholder="Email"
            id="email"
            data-e2e={"email" as any}
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
            value={email}
            fullWidth
            error={Boolean(getEmailError(email))}
            helperText={getEmailError(email)}
            inputProps={{ maxLength: 60 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Email style={{ color: COLORS.DARK_GREY }} />
                </InputAdornment>
              ),
              classes: {
                input: classes.signUpInput,
                root: classes.inputRoot,
                focused: classes.inputFocused,
              },
            }}
          />
          <Typography
            component="label"
            variant="caption"
            className={classes.label}
            htmlFor="password"
          >
            Password
          </Typography>
          <TextField
            size="small"
            FormHelperTextProps={{
              classes: { root: classes.formHelperText },
            }}
            variant="outlined"
            placeholder="Password"
            id="password"
            data-e2e={"password" as any}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            error={Boolean(getPasswordError(password))}
            helperText={getPasswordError(password)}
            inputProps={{ maxLength: 50 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Lock style={{ color: COLORS.DARK_GREY }} />
                </InputAdornment>
              ),
              classes: {
                input: classes.signUpInput,
                root: classes.inputRoot,
                focused: classes.inputFocused,
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                data-e2e="sign-up-email-update"
                checked={emailChecked}
                onChange={() => setEmailChecked(!emailChecked)}
                value="checkedB"
                color="primary"
                icon={<CheckBoxOutlineBlank />}
                checkedIcon={<CheckBox />}
              />
            }
            label={
              <Typography
                variant="body2"
                component="p"
                style={{
                  color: "grey",
                  textAlign: "left",
                }}
              >
                Opt in to receive deals & promos
              </Typography>
            }
          />
          <SignUpError error={error} />

          <Button
            className={classes.actionButton}
            variant="contained"
            color="primary"
            data-e2e="sign-up-btn"
            data-testid="sign-up-btn"
            type="submit"
            fullWidth
            disabled={
              isSignUpDisabled(email, password, firstName, lastName) ||
              isLoading
            }
          >
            {isLoading ? (
              <CircularProgress
                style={{ color: COLORS.WHITE }}
                data-testid="loading-spinner"
              />
            ) : (
              "Sign Up"
            )}
          </Button>

          <div className={classes.subCard}>
            <Typography variant="body2" component="p">
              Already have an account?
            </Typography>
            {switchView ? (
              <Button
                disableRipple
                className={classes.loginLink}
                onClick={switchView}
              >
                Log in
              </Button>
            ) : (
              <Link className={classes.loginLink} to="/login">
                Log in
              </Link>
            )}
          </div>
          <Typography
            variant="body2"
            component="p"
            style={{
              color: "grey",
              textAlign: "center",
              marginTop: "1rem",
            }}
          >
            By proceeding you agree to the{" "}
            <Link className={classes.link} to="/terms-and-conditions">
              terms and conditions
            </Link>{" "}
            and{" "}
            <Link className={classes.link} to="/privacy">
              privacy policy
            </Link>
          </Typography>
        </div>
      </section>
    </form>
  );
};
