import moment from "moment";
import { checkForSafari } from "src/utils/helpers";
import { IEventFormSubmitPL } from "../form/form";

export const formatActiveEventPL = (pl: IEventFormSubmitPL) => {
  const isSafari = checkForSafari();
  const format = `YYYY-MM-DD HH:mm`;

  const _startTime = moment(`${pl.date} ${pl.startTime}`, format);
  return {
    instructions: pl.instructions,
    name: pl.name,
    description: pl.description,
    displayLocation: pl.displayLocation,
    location: pl.address,
    minSeats: Number(pl.minSeats),
    maxSeats: Number(pl.maxSeats),
    reserveBy: moment(`${pl.reserveByDate} ${pl.reserveByTime}`, format)
      .valueOf()
      .toString(),
    startTime: _startTime.valueOf().toString(),
    endTime: _startTime.add(pl.duration, "hours").valueOf().toString(),
    images: pl.images,
    bufferTime: pl.bufferTime ? Number(pl.bufferTime) : 900000,
  };
};
