import { Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { COLORS } from "src/styles";
import "./first-steps.css";

export const stepContent = {
  diner: [
    {
      image: require("src/screens/how-it-works/assets/diner/search.png"),
      title: (
        <Typography variant="body2" component="p" className="sectionTitle">
          <Link
            to={"/menus"}
            style={{ color: COLORS.DARK_BLUE, textUnderlinePosition: "under" }}
          >
            Browse
          </Link>
        </Typography>
      ),
    },
    {
      image: require("src/screens/how-it-works/assets/diner/reserve.png"),
      title: (
        <Typography variant="body2" component="p" className="sectionTitle">
          Reserve
        </Typography>
      ),
    },
    {
      image: require("src/screens/how-it-works/assets/diner/enjoy.png"),
      title: (
        <Typography variant="body2" component="p" className="sectionTitle">
          Enjoy
        </Typography>
      ),
    },
  ],
  cook: [
    {
      image: require("src/screens/how-it-works/assets/cook/step1.png"),
      title: (
        <Typography variant="body2" component="p" className="sectionTitle">
          {" "}
          <Link
            to={"/c/profile"}
            style={{ color: COLORS.DARK_BLUE, textUnderlinePosition: "under" }}
          >
            Update Cook Profile
          </Link>
        </Typography>
      ),
    },
    {
      image: require("src/screens/how-it-works/assets/cook/step2.png"),
      title: (
        <Typography variant="body2" component="p" className="sectionTitle">
          {" "}
          <Link
            to={"/c/dishes/create"}
            style={{ color: COLORS.DARK_BLUE, textUnderlinePosition: "under" }}
          >
            Create Dishes
          </Link>
        </Typography>
      ),
    },
    {
      image: require("src/screens/how-it-works/assets/cook/step3.png"),
      title: (
        <Typography variant="body2" component="p" className="sectionTitle">
          {" "}
          <Link
            to={"/c/menus/create"}
            style={{ color: COLORS.DARK_BLUE, textUnderlinePosition: "under" }}
          >
            Create and Host Events!
          </Link>
        </Typography>
      ),
    },
  ],
};

export const getImageClassName = (selection: string, idx: number) => {
  if (selection === "diner") {
    return "imgContainer";
  } else if (selection === "cook" && idx !== 0) {
    return "imgContainer";
  } else {
    return "imgContainerAlt";
  }
};
