import throttle from "lodash/throttle";
import React, { useEffect } from "react";

export default function useScrolledBottom({
  offset = 500,
  callback,
  ref,
}: {
  offset?: number;
  callback: () => void;
  ref: any;
}) {
  const isScrollable = React.useCallback(() => {
    if (ref.current) {
      const scrollHeight = ref.current.scrollHeight;
      const clientHeight = window.innerHeight;

      if (scrollHeight > clientHeight) {
        return true;
      }

      return false;
    }
  }, [offset, callback]);

  const handleScroll = React.useCallback(
    throttle(() => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const { bottom } = rect;
        if (bottom - offset <= window.innerHeight) {
          callback();
        }
      }
    }, 500),
    [offset, callback]
  );

  useEffect(() => {
    if (isScrollable()) {
      window.addEventListener("scroll", handleScroll);
    } else {
      handleScroll();
    }
    return () => {
      handleScroll.cancel();
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
}
