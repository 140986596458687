import { useQuery } from "@apollo/client";
import { Card } from "@material-ui/core";
import React, { FC } from "react";
import { ErrorLoadingComponent } from "src/components";
import { sharedAPI } from "src/shared-graphql";
import { useMobileHeader } from "src/utils/hooks";
import { eventAPI } from "../api";
import { COOK_GET_EVENT } from "../detail/graphql";
import {
  EventForm,
  formatInitialState,
  formatSubmissionPayload,
} from "../form";
import { IEventFormSubmitPL } from "../form/form";
import { formatActiveEventPL } from "./helpers";

interface IProps {
  match: { params: { id: string } };
}

export const EditEventContainer: FC<IProps> = ({ match }) => {
  const { data = {} as any, loading, error } = useQuery(COOK_GET_EVENT, {
    variables: {
      input: match.params.id.split("-")[0],
    },
  });

  useMobileHeader({
    title: data.cookGetEvent
      ? `Edit "${
          data.cookGetEvent.name ? data.cookGetEvent.name : "Untitled Event"
        }"`
      : "",
    pathname: `/c/menus?status=PENDING`,
  });

  const initialState = data.cookGetEvent
    ? formatInitialState(data.cookGetEvent)
    : undefined;

  // ACTIVE events require a separate API to update.
  const isActive = data.cookGetEvent
    ? data.cookGetEvent.status === "ACTIVE"
    : false;

  // Handler for updating PENDING status events
  const updateEventHandler = (pl: IEventFormSubmitPL) => {
    const updateEventPL = formatSubmissionPayload(pl);
    return eventAPI.updateEvent(
      Object.assign({}, updateEventPL, { id: data.cookGetEvent.id })
    );
  };

  // Handler for updating ACTIVE status events
  const updateActiveEventHandler = (pl: IEventFormSubmitPL) => {
    const updateActiveEventPL = formatActiveEventPL(pl);
    return eventAPI
      .updateActiveEvent(
        Object.assign({}, updateActiveEventPL, { id: data.cookGetEvent.id })
      )
      .then((res) => {
        sharedAPI.setSnackbarMsg({ type: "success", msg: "Event Updated!" });
        return res;
      });
  };

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }

  return (
    <Card
      style={{
        boxShadow: "0 2px 10px 0 #BCBCBC",
        borderRadius: 2,
      }}
    >
      <EventForm
        edit
        isActive={isActive}
        initialState={initialState}
        onSubmit={isActive ? updateActiveEventHandler : updateEventHandler}
      />
    </Card>
  );
};
