import { MutationFunctionOptions } from "@apollo/client";
import { Tab, Tabs, Typography, useMediaQuery } from "@material-ui/core";
import { parse } from "query-string";
import React, { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import { INotification } from "src/models";
import { BREAK_POINTS } from "src/styles";
import { FeedContainer } from "../feed/container";
import { useLayoutStyles } from "./layout.styles";
import { Notification } from "./notification";

interface IProps {
  markAllNotifications: (d: MutationFunctionOptions) => void;
  updateNotification: (d: MutationFunctionOptions) => void;
  notifications: INotification[];
}

export const Layout: FC<IProps> = React.memo(
  ({ updateNotification, markAllNotifications, notifications }) => {
    const location = useLocation();
    const loc = parse(location.search) as { loc: "feeds" };

    const [value, setValue] = useState(loc?.loc === "feeds" ? 1 : 0);
    const classes = useLayoutStyles();

    const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

    return (
      <div
        className={classes.container}
        style={{ maxHeight: !isMobile ? 666 : "unset" }}
      >
        <section
          style={{
            position: "-webkit-sticky", // for sticky position on safari 👋
          }}
          className={classes.headerSection}
        >
          <Tabs
            value={value}
            indicatorColor="secondary"
            variant="fullWidth"
            textColor="secondary"
            onChange={(_, v) => setValue(v)}
          >
            <Tab label="Myself" classes={{ root: classes.root }} />
            <Tab label={"Community"} classes={{ root: classes.root }} />
          </Tabs>
          {/* <Typography
          variant="h2"
          component="h2"
          className={classes.notificationHeader}
        >
          Notifications
        </Typography>

        <button
          onClick={() =>
            markAllNotifications({ variables: { input: { status: "READ" } } })
          }
          className={classes.markAllBtn}
        >
          Mark all as Read
        </button> */}
        </section>

        {value === 0 && !notifications.length && (
          <section className={classes.noNotificationContainer}>
            <img
              src={require("../../screens/event-detail/detail/order-info/images/emptybag.svg")}
              alt="Image"
              className={classes.bagImage}
            />
            <Typography variant="body2" component="p">
              Looks like you do not have notifications!
            </Typography>
          </section>
        )}

        {value === 0 && (
          <section className={classes.notifications}>
            {notifications.map((notification) => (
              <Notification
                key={notification.id}
                notification={notification}
                updateNotification={updateNotification}
              />
            ))}
          </section>
        )}

        {value === 1 && <FeedContainer />}
      </div>
    );
  }
);
