import { gql } from "@apollo/client";
export const CANCEL_EVENT_USER = gql`
  mutation dinerCancelEventUser($input: ID!) {
    dinerCancelEventUser(id: $input) {
      id
      status
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation dinerCancelOrder($input: ID!) {
    dinerCancelOrder(id: $input) {
      id
      status
    }
  }
`;

export const NOTIFY_COOK = gql`
  mutation dinerNotifyCook($input: ID!) {
    dinerNotifyCook(id: $input)
  }
`;
