import { matchPath } from "react-router-dom";

const blackList = [
  "/events/:id",
  "/events/:id/checkout",
  "/events/:id/checkout/mobile-payment",
  "/menus/:id",
  "/menus/:id/checkout",
  "/menus/:id/checkout/mobile-payment",
  "/home-restaurant-info",
  "/home-restaurant-application",
  "/c/menus/edit/:id",
  "/c/menus/create",
  "/c/menus/dishes/:id",
  "/c/menus/:id",
  "/c/menus/summary/:id",
  "/chats",
  "/chat",
  "/restaurants",
  "/map",
];

export const isBlackListed = (pathname: string) => {
  return blackList.some(
    (path) => !!matchPath(pathname, { path, exact: true, strict: false })
  );
};
