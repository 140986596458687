import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  dialogPaper: {
    width: "90%",
    minWidth: "unset",
    maxWidth: 800,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    overflow: "hidden",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexDirection: "column",
      justifyContent: "unset",
    },
  },
  h3: {
    color: COLORS.BLACK,
    textAlign: "center",
    marginBottom: "10px",
  },
  foodnome: {
    color: COLORS.RED,
    fontFamily: "CustomFourBold",
  },
  body1: {
    textAlign: "center",
  },
  closeIconBtn: {
    position: "absolute",
    right: 5,
    top: 5,
    borderRadius: "50%",
    width: 30,
    height: 30,
    border: "none",
    backgroundColor: COLORS.MEDIUM_GREEN,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  fiveDollar: {
    color: COLORS.MEDIUM_GREEN,
  },
  mdClose: {
    color: "white",
    fontSize: 20,
  },
  imgContainer: {
    width: "40%",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
    },
  },
  infoContainer: {
    width: "60%",
    padding: "40px 60px",
    display: "flex",
    flexDirection: "column",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      padding: "20px",
    },
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  logo: {
    width: 50,
    margin: "0 auto 20px auto",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  continueBtn: {
    margin: "20px auto 5px auto",
    color: "white",
    width: 200,
  },
  findAMeal: { width: 200, margin: "0px auto" },
  boldText: {
    fontFamily: "CustomFourBold",
  },
});
