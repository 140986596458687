import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { sharedAPI } from "src/shared-graphql";
import { marketingAPI } from "./api";
import { TextMessage } from "./text-message";

interface MarketingItem {
  value: string;
  id: number;
}
export const MarketingContainer = () => {
  const [textMsgs, setTextMsgs] = useState<MarketingItem[]>([]);

  const fetch = () =>
    marketingAPI
      .getTextMsgs()
      .then(({ data }) => {
        setTextMsgs(data);
      })
      .catch(() =>
        sharedAPI.setSnackbarMsg({ type: "error", msg: "0 text msgs set" })
      );

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div>
      <Typography variant="body2" component="p">
        You may use [FIRSTNAME], [LASTNAME], [EMAIL], [PHONE], [LASTHR],
        [NUMOFMENUS], [NUMOFHRS] in any of the following templates
      </Typography>
      {textMsgs.map((d) => (
        <TextMessage data={d} fetch={fetch} />
      ))}
      <button
        onClick={() =>
          setTextMsgs([
            ...textMsgs,
            {
              id: textMsgs?.length ? textMsgs[textMsgs.length - 1].id + 1 : 0,
              value: "",
            },
          ])
        }
      >
        Add One More
      </button>
    </div>
  );
};
