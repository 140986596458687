import { ListItem } from "@material-ui/core";
import moment from "moment";
import React, { FC } from "react";
import { IPayment } from "src/models";
import { useStyles } from "./payment.styles";

interface IProps {
  payment: IPayment;
}
export const Payment: FC<IProps> = ({ payment }) => {
  const classes = useStyles();
  return (
    <ListItem>
      ${payment.totalAmount}, {moment(Number(payment.createdAt)).format("lll")}
    </ListItem>
  );
};
