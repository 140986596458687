import { useMutation, useQuery } from "@apollo/client";
import { Dropin } from "braintree-web-drop-in";
import React, { FC, useContext, useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { dinerPixel, ReactPixel } from "src/index";
import { ICoupon, IEvent, IGetMe } from "src/models";
import { amplitude } from "src/services";
import { sharedAPI } from "src/shared-graphql/api";
import { tagManager } from "src/utils/react-gtm";
import { GET_SERVICE_FEE } from "../../api/graphql";
import {
  DINER_CREATE_ORDER,
  DINER_ORDER_WITH_PAY,
} from "../../api/graphql/mutations";
import { OrderContext } from "../../order-context";
import { ActionTypes } from "../../order-reducer";
import { Cart } from "./cart";
import { CollectPhone } from "./collect-phone";
import { MobilePayment } from "./mobile/container";
import { RoutesDialogView } from "./routes-dialog-view";
interface IProps {
  getEvent: IEvent;
  getMe: IGetMe;
  isMobile: boolean;
}

export const Routes: FC<IProps> = ({ getEvent, getMe, isMobile }) => {
  const { data, loading } = useQuery(GET_SERVICE_FEE, {
    variables: { input: getEvent.restaurant.id },
  });
  const history = useHistory();
  const [order] = useMutation(DINER_ORDER_WITH_PAY, {
    refetchQueries: ["getMe"],
  });
  const [anotherOrder] = useMutation(DINER_CREATE_ORDER);

  const [collectPhoneOpen, setCollectPhoneOpen] = useState(false);
  const serviceFeePerc = data && data.getMeta ? data.getMeta.serviceFee : 0;
  const serviceFeeMini = data && data.getMeta ? data.getMeta.minimum : 0;
  const [couponObj, setCouponObj] = useState<ICoupon | null>(null);
  const [coupon, setCoupon] = useState("");
  const [discountPercent, setDiscount] = useState(0);
  const [couponError, setCouponError] = useState("");
  const [brainTreeInstance, setBrainTreeInstance] = useState<Dropin | null>(
    null
  );
  const [showButton, setButton] = useState<boolean>(false);

  const {
    state: {
      orderedDishes,
      seats,
      chefNotes,
      deliveryCost,
      dineOption,
      arrivalTime,
      dialogView,
    },
    dispatch,
  } = useContext(OrderContext);

  useEffect(() => {
    // If a user is on "Delivery" but then switches to a different method, we need to set the delivery cost to 0
    if (dineOption !== "DELIVERY") {
      dispatch({
        type: ActionTypes.SET_DELIVERY_COST,
        payload: 0,
      });
    }
  }, [dineOption]);

  useEffect(() => {
    if (brainTreeInstance?.isPaymentMethodRequestable()) {
      setButton(true);
    }
    brainTreeInstance?.on("paymentMethodRequestable", (event) => {
      // If user has a card selected or is typing in a card and returns a card type
      setButton(event.paymentMethodIsSelected || event.type ? true : false);
    });
    brainTreeInstance?.on("noPaymentMethodRequestable", () => {
      setButton(false);
    });
    // brainTreeInstance?.on("paymentOptionSelected", (event) => {
    //   setPaymentSelected(event.paymentOption);
    // });
  }, [brainTreeInstance]);

  const applyCoupon = () => {
    setCouponError("");
    if (!coupon) return;
    amplitude.getInstance().logEvent("[Event Detail] apply coupon", {
      eventName: getEvent.name,
      coupon: coupon.toUpperCase(),
    });
    return sharedAPI
      .verifyCoupon({
        type: "NOME",
        code: coupon,
        eventId: getEvent.id,
        restaurantId: getEvent.restaurant.id,
      })
      .then((res) => {
        setCouponObj(res.data.verifyCoupon);
        setDiscount(res.data.verifyCoupon.percent);
      })
      .catch((error) => {
        setCouponObj(null);
        setCoupon("");
        setDiscount(0);
        setCouponError(
          error && error.message
            ? error.message.replace(/^graphql error: /i, "")
            : "Sorry, we have issue applying this coupon"
        );
      });
  };
  const itemTotal = getEvent.type.includes("FIXED_TIME")
    ? getEvent.price * seats
    : orderedDishes.reduce((acc, next) => {
        let _total = 0;
        if (next.options?.length) {
          _total = next.options?.reduce(
            (_acc, _next) =>
              _acc +
              (_next?.count ?? 0) *
                (_next?.addition + (next.DishEvent?.price ?? 0)),
            0
          );
        } else {
          _total = (next.DishEvent?.price ?? 0) * next.count;
        }
        return acc + _total;
      }, 0);

  let couponDeduction = 0;
  if (couponObj) {
    couponDeduction = itemTotal * couponObj.percent;
    if (couponDeduction > couponObj.max) {
      couponDeduction = couponObj.max;
    }
  }
  const discountedItemTotal = itemTotal - couponDeduction;

  const platformFee =
    getMe && getMe.nomes + getMe.credits >= discountedItemTotal
      ? 0
      : (discountedItemTotal - getMe.nomes - getMe.credits) * serviceFeePerc >
          serviceFeeMini || !serviceFeePerc
      ? (discountedItemTotal - getMe.nomes - getMe.credits) * serviceFeePerc
      : serviceFeeMini;

  const platformFeeFake =
    getMe && getMe.nomes + getMe.credits >= discountedItemTotal
      ? 0
      : (discountedItemTotal - getMe.nomes - getMe.credits) * 0.15;

  const amplitudeNomes = discountedItemTotal + platformFee; // used for transaction amount
  const taxAmount =
    getMe && getMe.nomes + getMe.credits >= discountedItemTotal + platformFee
      ? 0
      : (discountedItemTotal + platformFee - getMe.nomes - getMe.credits) *
        getEvent.restaurant.taxRate;

  const totalWithoutSerFee =
    getMe.nomes + getMe.credits >= discountedItemTotal + deliveryCost
      ? 0
      : discountedItemTotal - getMe.nomes - getMe.credits + deliveryCost;

  const nomeUsed =
    getMe.nomes + getMe.credits >= discountedItemTotal + deliveryCost
      ? discountedItemTotal + deliveryCost
      : getMe.nomes + getMe.credits;

  const total =
    getMe.nomes + getMe.credits >= discountedItemTotal + deliveryCost
      ? 0
      : discountedItemTotal -
        getMe.nomes -
        getMe.credits +
        platformFee +
        taxAmount +
        deliveryCost;

  let notes = orderedDishes
    .filter((d) => d.notes)
    .map((d) => {
      return d.name + ": " + d.notes;
    })
    .join("; ");

  if (chefNotes) {
    notes = notes + "; " + "For delivery: " + chefNotes;
  }

  const submitOrder = async (token?: string) => {
    const _order =
      getEvent.eventUser?.status === "RESERVED" ? anotherOrder : order;
    return _order({
      variables: {
        input: {
          eventId: getEvent.id,
          notes,
          coupon,
          dishes: orderedDishes
            .filter((d) => d.count || d.options?.some((o) => o.count))
            .map((d) => ({
              id: Number(d.id),
              count: d.count,
              options: d.options
                ?.filter((o) => o.count)
                .map((o) => ({
                  name: o.name,
                  count: o.count,
                })),
            })),
          dineOption,
          arrivalTime,
          reservedFor: seats,
          source: localStorage.getItem("foodnome-source"),
          token,
        },
      },
    })
      .then(() => {
        amplitude.getInstance().logEvent("[Event Checkout] purchase", {
          amount: amplitudeNomes.toFixed(2),
          coupon: coupon.toUpperCase(),
          source: localStorage.getItem("foodnome-source") ?? "",
          method: "BrainTree",
        });
        const contents = orderedDishes
          .filter((d) => d.count || d.options?.some((o) => o.count))
          .map((d) => ({
            id: Number(d.id),
            count: d.count,
            options: d.options
              ?.filter((o) => o.count)
              .map((o) => ({
                name: o.name,
                count: o.count,
              })),
          }));

        process.env.NODE_ENV !== "test" &&
          ReactPixel.trackSingle(dinerPixel, "Purchase", {
            value: amplitudeNomes.toFixed(2),
            currency: "USD",
            coupon: coupon.toUpperCase(),
            source: localStorage.getItem("foodnome-source") ?? "",
            content_type: "product",
            contents,
          });

        tagManager.track({
          event: "UserPurchase",
          value: amplitudeNomes.toFixed(2),
        });

        localStorage.removeItem("foodnome-source");
        localStorage.removeItem(`foodnome-cart-${getEvent.id}`);
        setCollectPhoneOpen(true);
      })
      .catch((e) => {
        sharedAPI.setSnackbarMsg({
          type: "error",
          msg: e.message.replace(/^Graphql Error: /i, ""),
        });
      });
  };

  return (
    <>
      <CollectPhone
        getMe={getMe}
        dialogView={dialogView}
        open={collectPhoneOpen}
        getEvent={getEvent}
      />
      {dialogView ? (
        <RoutesDialogView
          getEvent={getEvent}
          isMobile={isMobile}
          platformFee={platformFee}
          serviceFeePerc={serviceFeePerc}
          platformFeeFake={platformFeeFake}
          itemTotal={itemTotal}
          taxAmount={taxAmount}
          deliveryCost={deliveryCost}
          discountPercent={discountPercent}
          discountedItemTotal={discountedItemTotal}
          applyCoupon={applyCoupon}
          nomeUsed={nomeUsed}
          coupon={coupon}
          setCoupon={setCoupon}
          couponError={couponError}
          submitOrder={submitOrder}
          couponObj={couponObj}
          getMe={getMe}
          dineOption={dineOption}
          total={total}
          showButton={showButton}
          dispatch={dispatch}
          brainTreeInstance={brainTreeInstance}
          setBrainTreeInstance={setBrainTreeInstance}
        />
      ) : (
        <Switch>
          <Route
            exact
            path="/menus/:id/checkout"
            render={() => (
              <Cart
                getEvent={getEvent}
                dispatch={dispatch}
                isMobile={isMobile}
                platformFee={platformFee}
                serviceFeePerc={serviceFeePerc}
                platformFeeFake={platformFeeFake}
                itemTotal={itemTotal}
                taxAmount={taxAmount}
                deliveryCost={deliveryCost}
                discountPercent={discountPercent}
                discountedItemTotal={discountedItemTotal}
                applyCoupon={applyCoupon}
                nomeUsed={nomeUsed}
                coupon={coupon}
                setCoupon={setCoupon}
                couponError={couponError}
                submitOrder={submitOrder}
                couponObj={couponObj}
                dineOption={dineOption}
                total={total}
                showButton={showButton}
                brainTreeInstance={brainTreeInstance}
                setBrainTreeInstance={setBrainTreeInstance}
              />
            )}
          ></Route>

          <Route
            exact
            path="/events/:id/checkout"
            render={() => (
              <Cart
                getEvent={getEvent}
                dispatch={dispatch}
                isMobile={isMobile}
                platformFee={platformFee}
                serviceFeePerc={serviceFeePerc}
                platformFeeFake={platformFeeFake}
                itemTotal={itemTotal}
                taxAmount={taxAmount}
                deliveryCost={deliveryCost}
                discountPercent={discountPercent}
                discountedItemTotal={discountedItemTotal}
                applyCoupon={applyCoupon}
                nomeUsed={nomeUsed}
                coupon={coupon}
                setCoupon={setCoupon}
                couponError={couponError}
                submitOrder={submitOrder}
                couponObj={couponObj}
                dineOption={dineOption}
                total={total}
                showButton={showButton}
                brainTreeInstance={brainTreeInstance}
                setBrainTreeInstance={setBrainTreeInstance}
              />
            )}
          ></Route>
          <Route
            exact
            path="/events/:id/checkout/mobile-payment"
            render={() => (
              <MobilePayment
                getEvent={getEvent}
                submitOrder={submitOrder}
                total={total}
                getMe={getMe}
              />
            )}
          ></Route>
          <Route
            exact
            path="/menus/:id/checkout/mobile-payment"
            render={() => (
              <MobilePayment
                getEvent={getEvent}
                submitOrder={submitOrder}
                total={total}
                getMe={getMe}
              />
            )}
          ></Route>
        </Switch>
      )}
    </>
  );
};
