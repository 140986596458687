import { InputProps } from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import React, { FC } from "react";
import { useStyles } from "./text-input.styles";

interface IProps {
  getInputProps: (d: any) => Partial<InputProps>;
  onChange: (d: any) => void;
  onKeyDown: (d: any) => void;
  value: string;
  InputProps?: any;
  openMenu?: any;
}

export const TextInput: FC<IProps> = ({
  getInputProps,
  onChange,
  onKeyDown,
  value,
  openMenu,
  InputProps: InProps = {},
  ...rest
}) => {
  const classes = useStyles();

  return (
    <TextField
      size="small"
      autoComplete={"off"}
      FormHelperTextProps={{ classes: { root: classes.formHelperText } }}
      style={{ width: "100%", height: "100%" }}
      InputProps={{
        classes: {
          root: classes.root,
          notchedOutline: classes.notchedOutline,
        },
        ...getInputProps({
          onChange,

          onFocus: openMenu,
          onKeyDown,
        }),
        ...InProps,
      }}
      inputProps={{
        enterKeyHint: "search",
      }}
      id="search-address"
      {...({
        "data-e2e": "address-auto-complete-input",
      } as any)}
      placeholder="Address*"
      value={value}
      name="search"
      type="text"
      variant="outlined"
      {...rest}
    />
  );
};
