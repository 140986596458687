import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Fade } from "react-awesome-reveal";
import { SearchContainer } from "../../components/search-container";
import { useStyles } from "./order-a-meal.styles";

export const OrderAMeal: FC = () => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <img
        src={require("../../assets/illustrations/spagett-13.svg")}
        className={classes.spagett}
      />
      <div className={classes.innerContainer}>
        <div className={classes.mainContent}>
          <div className={classes.textContainer}>
            <Fade triggerOnce={true} delay={250}>
              <Typography variant="h1" component="h2" className={classes.title}>
                Order a Home Cooked Meal
              </Typography>
            </Fade>
            <Fade triggerOnce={true} delay={400}>
              <Typography
                variant="h4"
                component="p"
                style={{ margin: "2.5rem 0" }}
                className={classes.text}
              >
                Find fresh, authentic food made by skilled chefs near you — from
                their permitted kitchens to your dining table!
              </Typography>
            </Fade>
            <Fade triggerOnce={true} delay={550}>
              <SearchContainer />
            </Fade>
          </div>
          <Fade
            className={classes.imageContainer}
            triggerOnce={true}
            delay={1250}
          >
            <img
              src={require("../../assets/images/tacos.jpg")}
              className={classes.image}
            />
          </Fade>
        </div>
      </div>
    </section>
  );
};
