import { Typography } from "@material-ui/core";
import React from "react";
import { IGetMe } from "src/models";
import { useContentStyles } from "../styles";
interface IProps {
  getMe: IGetMe;
}

export const InSlide1: React.FC<IProps> = ({ getMe }) => {
  const classes = useContentStyles();
  return (
    <div
      className={classes.container}
      style={{ maxWidth: "100rem", margin: "unset" }}
    >
      <Typography variant="h2" component="h2" className={classes.header}>
        Welcome,{" "}
        <span style={{ textTransform: "capitalize" }}>{getMe.firstName}</span>!
      </Typography>
      <div className={classes.redUnderline}></div>
      <Typography
        variant="body1"
        component="p"
        className={classes.text}
        style={{ fontFamily: "CustomFourBold" }}
      >
        You are on your way to starting a legal food business from your home kitchen!
      </Typography>
      <Typography variant="body1" component="p" className={classes.text}>
        COOK Connect is here to help by offering permitting, marketing,
        and business support to get you cooking as soon as possible!
      </Typography>
      <Typography variant="body1" component="p" className={classes.text}>
        Let’s take a few moments to answer a few simple questions to see if you
        qualify.
      </Typography>
    </div>
  );
};
