import { gql } from "@apollo/client";

export const GET_HOST_APPLICATION = gql`
  query cookGetApplication {
    cookGetApplication {
      id
      description
      status
      applicationStatuses {
        id
        status
        description
        createdAt
      }
      updatedAt
      createdAt
      info {
        businessName
      }
    }
  }
`;

export const GET_COOK_APPLICATION = gql`
  query cookGetApplication {
    cookGetApplication {
      status
      progress
      info {
        businessName
        address
        hasVentilation
        ventilation
        hasDishwasher
        isPublicWater
        waterCompany
        isPublicSewer
        numberOfPerson
        numberOfRestroom
        numberOfSinks
        question1
        question2
        question3
        dishInfos {
          name
          ingredients
        }
        idImage
      }
    }
  }
`;
