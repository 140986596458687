import { makeStyles } from "@material-ui/core";
import { MapOptions } from "google-map-react";
import React from "react";
import { BREAK_POINTS } from "src/styles";
import { CloseMap, MapContainer } from "../map";
import { HeaderFilter } from "./header-filter";
import { RestaurantListDesktop } from "./restaurant-list";

interface IProps {
  data: any;
  loading: boolean;
  search: any;
}

export const DesktopView: React.FC<IProps> = React.memo(
  ({ data, loading, search }) => {
    const classes = useStyles();

    const defaultMapOptionsDesktop: MapOptions = {
      fullscreenControl: false,
      disableDefaultUI: false,
      zoomControlOptions: {
        position: 7,
      },
      minZoom: 6,
      maxZoom: 16,
      clickableIcons: false,
    };
    return (
      <div className={classes.container}>
        <section className={classes.leftContainer}>
          <HeaderFilter />
          <RestaurantListDesktop />
        </section>
        <section id="google-map-container" className={classes.rightContainer}>
          <MapContainer
            data={data}
            loading={loading}
            search={search}
            defaultOptions={defaultMapOptionsDesktop}
          />

          <CloseMap style={{ position: "absolute", top: 10, left: 10 }} />
        </section>
      </div>
    );
  }
);

const useStyles = makeStyles({
  container: {
    height: "calc(100vh - 56px)",
    display: "flex",
    borderRadius: "1rem",
    flexDirection: "row",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      height: "100vh",
    },
  },

  leftContainer: {
    zIndex: 200,
    width: "50%",
    maxWidth: 585,
    display: "flex",
    flexDirection: "column",
  },
  rightContainer: {
    zIndex: 200,
    // width: "50%",
    flexGrow: 1,
    position: "relative",
  },
});
