import { gql } from "@apollo/client";

const GET_ADDRESS = gql`
  query getGoogleGeoCode($input: String!) {
    getGoogleGeoCode(input: $input) {
      status
      results {
        address_components {
          long_name
          short_name
          types
        }
        formatted_address
        geometry {
          location {
            lat
            lng
          }
          viewport {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;

const SEARCH_RESTAURANTS = gql`
  query searchRestaurants($input: SearchRestaurantsPL!) {
    searchRestaurants(searchRestaurantsPL: $input) {
      __typename @skip(if: true)
      id
      name
      tags
      description
      externalLink
    	fromCSV
      locale {
        private
        public
        coordinates {
          latitude
          longitude
          __typename @skip(if: true)
        }
      }
      overallReview {
        count
        stars
        quality
        accuracy
        hospitality
      }
      city
      dishes {
        id
        imagesGQL {
          publicId
          small
          medium
          large
          original
          __typename @skip(if: true)
        }
        DishEvent {
          id
          price
          count
          sold
        }
        __typename @skip(if: true)
      }
      events {
        id
        name
        startTime
        tags
        type
      }
      host {
        id
        firstName
        lastName
        picture
      }
    }
  }
`;

const GET_RESTAURANT_EVENTS = gql`
  query getRestaurantEvents($input: ID!) {
    getRestaurantEvents(id: $input) {
      id
      name
      type
      price
      private
      status
      startTime
      endTime
      instructions
      tags
      reserveBy
      locale {
        public
        private
        coordinates {
          longitude
          latitude
        }
      }
      eventUser {
        id
        reservedFor
        arrivalTime
        dineOption
        eventId
        paidAmount
        deliveryAddress
        deliveryCost
        creditAmount
        taxAmount
        status
        updatedAt
        checkedIn
        orderedDishes {
          id
          name
          price
          imagesGQL {
            publicId
            small
            medium
            large
            original
          }
          options {
            name
          }
          EventUserDish {
            id
            count
            options {
              name
              count
              addition
            }
          }
        }
        orders {
          id
          reservedFor
          arrivalTime
          dineOption
          paidAmount
          deliveryAddress
          deliveryCost
          creditAmount
          taxAmount
          status
          updatedAt
          checkedIn
          orderedDishes {
            id
            name
            price
            imagesGQL {
              publicId
              small
              medium
              large
              original
            }
            options {
              name
            }
            OrderDish {
              id
              count
              options {
                name
                count
                addition
              }
            }
          }
        }
      }
      dishes {
        id
        name
        options {
          name
          addition
        }
        imagesGQL {
          publicId
          small
          medium
          large
          original
        }
        DishEvent {
          id
          price
          sold
        }
        allergens {
          id
          name
        }
        __typename @skip(if: true)
      }
      restaurant {
        id
        name
        host {
          id
          firstName
          lastName
          picture
        }
      }
    }
  }
`;

export { GET_ADDRESS, SEARCH_RESTAURANTS, GET_RESTAURANT_EVENTS };
