import { gql } from "@apollo/client";

export const GET_ME = gql`
  query getMe($input: String) {
    getMe(token: $input) {
      id
      firstName
      lastName
      email
      description
      role
      nomes
      credits
      stripeId
      stripeLast4
      account
      active
      picture
      birthday
      phoneNumber
      createdAt
      hasApp
      brainTreeId
      payments {
        id
      }
      address
      restaurant {
        id
        name
        zipCode
        county
        permitNumber
        application {
          id
          status
          inspectionTime
          progress
        }
      }
      preferences {
        NEWS_LETTER_EMAIL_SUBSCRIPTION
      }
    }
  }
`;

export const GET_UPCOMING_EVENTS = gql`
  query getUpcomingEvents($input: GetUpcomingEventsPL) {
    getUpcomingEvents(getUpcomingEventsPL: $input) {
      count
      rows {
        id
        name
        private
        type
        price
        startTime
        endTime
        private
        tags
        restaurant {
          id
          name
          overallReview {
            count
            stars
            quality
            accuracy
            hospitality
          }
          host {
            id
            firstName
            lastName
            picture
          }
        }
        dishes {
          id
          name
          imagesGQL {
            publicId
            small
            medium
            large
            original
          }
          tags
          dietary
          category
          DishEvent {
            id
            price
            count
            sold
          }
          __typename @skip(if: true)
        }
        locale {
          public
          private
          coordinates {
            longitude
            latitude
          }
        }
      }
    }
  }
`;

export const GET_HOST_DISHES = gql`
  query cookGetDishes($input: CookGetDishesPL!) {
    aggregateDishes: cookGetDishes(
      cookGetDishesPL: { pagePL: { limit: null, offset: null } }
    ) {
      count
    }
    cookGetDishes(cookGetDishesPL: $input) {
      count
      rows {
        id
        __typename @skip(if: true)
        name
        description
        imagesGQL {
          publicId
          small
          medium
          large
          original
        }
        dietary
        isPublished
        defaultPrice
        updatedAt
        createdAt
      }
    }
  }
`;

export const GET_EVENT = gql`
  query getEvent($input: ID!) {
    getEvent(id: $input) {
      __typename @skip(if: true)
      id
      name
      type
      description
      imagesGQL {
        publicId
        small
        medium
        large
        original
      }
      price
      private
      status
      startTime
      endTime
      reserveBy
      bufferTime
      seatsLeft
      soldOutTimeSlots
      instructions
      tags
      deliveryInfo {
        maximumDriveTime
        minimumOrderAmount
        pricePerMin
      }
      locale {
        public
        private
        coordinates {
          longitude
          latitude
        }
      }
      eventUser {
        id
        reservedFor
        arrivalTime
        dineOption
        paidAmount
        deliveryAddress
        deliveryCost
        creditAmount
        taxAmount
        updatedAt
        notes
        checkedIn
        status
        eventId
        orderedDishes {
          id
          name
          price
          __typename @skip(if: true)
          options {
            name
            addition
          }
          EventUserDish {
            id
            count
            options {
              name
              count
              addition
            }
          }
        }
        orders {
          id
          reservedFor
          arrivalTime
          dineOption
          paidAmount
          deliveryAddress
          checkedIn
          deliveryCost
          creditAmount
          taxAmount
          updatedAt
          notes
          status
          orderedDishes {
            id
            name
            price
            __typename @skip(if: true)
            options {
              name
              addition
            }
            OrderDish {
              id
              count
              options {
                name
                count
                addition
              }
            }
          }
        }
      }
      restaurantUser {
        id
        status
      }
      dishes {
        id
        name
        imagesGQL {
          publicId
          small
          medium
          large
          original
        }
        description
        dietary
        dishLeft
        category
        DishEvent {
          __typename @skip(if: true)
          id
          price
          sold
          options {
            __typename @skip(if: true)
            name
            addition
          }
        }
        allergens {
          id
          name
        }
        __typename @skip(if: true)
      }
      restaurant {
        id
        name
        hostBio
        imagesGQL {
          publicId
          small
          medium
          large
          original
        }
        description
        taxRate
        overallReview {
          count
          stars
          quality
          accuracy
          hospitality
        }
        host {
          id
          firstName
          lastName
          picture
        }
        locale {
          public
          coordinates {
            longitude
            latitude
          }
        }
      }
    }
  }
`;

export const GET_COOK_EVENTS = gql`
  query cookGetEvents($input: CookGetEventsPL!) {
    cookGetEvents(cookGetEventsPL: $input) {
      count
      rows {
        id
        name
        status
        type
        locale {
          private
        }
        imagesGQL {
          publicId
          small
          medium
          large
          original
        }
        price
        startTime
        endTime
        reserveBy
        seatsLeft
        maxSeats
        private
        updatedAt
        createdAt
        dishes {
          id
          name
          imagesGQL {
            publicId
            small
            medium
            large
            original
          }
          description
          dishLeft
          __typename @skip(if: true)
          DishEvent {
            id
            price
            count
            sold
          }
        }
        guestCount
      }
    }
  }
`;

export const GET_DINER_EVENTS = gql`
  query getDinerEvents($input: GetMyEventsPL!) {
    getDinerEvents(getDinerEventsPL: $input) {
      id
      name
      locale {
        private
        coordinates {
          longitude
          latitude
        }
      }
      description
      price
      startTime
      endTime
      tags
      reserveBy
      maxSeats
      type
      imagesGQL {
        publicId
        small
        medium
        large
        original
      }
      dishes {
        id
        imagesGQL {
          publicId
          small
          medium
          large
          original
        }
        DishEvent {
          id
          price
        }
        __typename @skip(if: true)
      }
      eventUser {
        id
        dineOption
        reservedFor
        status
        eventId
        paidAmount
        createdAt
        arrivalTime
        orderedDishes {
          id
          name
          imagesGQL {
            publicId
            small
            medium
            large
            original
          }
          options {
            name
          }
          EventUserDish {
            id
            options {
              addition
              name
              count
            }
            count
          }
        }
        orders {
          id
        }
      }
      restaurant {
        id
        name
        application {
          id
          status
        }
        host {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_SNACKBAR_MSG = gql`
  {
    getSnackbarMsg @client {
      type
      msg
    }
  }
`;

export const GET_DISCOUNT_PROMPT = gql`
  {
    getDiscountPrompt @client
  }
`;

export const GET_DIALOG_EVENT_ID = gql`
  {
    getDialogEventId @client
  }
`;
export const GET_DIALOG_RESTAURANT_NAME = gql`
  {
    getDialogRestaurantName @client
  }
`;

export const GET_SIGNIN_DIALOG_STATUS = gql`
  {
    showSignInDialog @client
  }
`;

export const VERIFY_COUPON = gql`
  query verifyCoupon($input: VerifyCouponPL!) {
    verifyCoupon(verifyCouponPL: $input) {
      id
      code
      type
      max
      unit
      eventId
      percent
      start
      end
    }
  }
`;

export const GET_MOBILE_HEADER_TITLE = gql`
  {
    mobileHeaderTitle @client {
      title
      pathname
    }
  }
`;

export const GET_GLOBAL_DRAWER_STATE = gql`
  query globalDrawerState {
    getGlobalDrawerState @client
  }
`;

export const GET_AUTH_DRAWER_STATE = gql`
  query authDrawerState {
    getAuthDrawerState @client
  }
`;

export const GET_REFERRAL_DIALOG_STATE = gql`
  query getReferralDialogState {
    getReferralDialogState @client
  }
`;
export const GET_CONTACT_MODAL = gql`
  query getContactModalState {
    getContactModalState @client
  }
`;

export const GET_SHARE_DRAWER_CONFIG = gql`
  query getShareDrawerConfig {
    getShareDrawerConfig @client {
      link
      drawerTitle
      description
      tags
      twitter {
        description
        tags
      }
      facebook {
        description
        tag
      }
      whatsApp {
        description
      }
      email {
        subject
        body
      }
    }
  }
`;

export const GET_DINER_PROFILE_DRAWER_STATE = gql`
  query dinerProfileDrawerState {
    getDinerProfileDrawerState @client
  }
`;

export const GET_ACTIVE_MODE = gql`
  query activeMode {
    getActiveMode @client
  }
`;

export const GET_GLOBAL_LOADING_STATE = gql`
  query getGlobalLoadingState {
    getGlobalLoadingState @client
  }
`;

export const GET_COVID_DIALOG_STATE = gql`
  query getCOVID19DialogState {
    getCOVID19DialogState @client
  }
`;

export const GET_USER_UPDATE_EMAIL_STATE = gql`
  query getUserUpdateEmailDialogState {
    getUserUpdateEmailDialogState @client
  }
`;
