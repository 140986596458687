import { makeStyles, Typography } from "@material-ui/core";
import { Facebook, Instagram, YouTube } from "@material-ui/icons";
import React, { FC } from "react";
import { GoogleMapContainer, HybridLink } from "src/components";
import { COLORS } from "src/styles";
import { _companyContact, _socialMedia } from "src/utils/constants";

// tslint:disable-next-line:no-empty-interface
interface IProps {}

const useStyles = makeStyles({
  container: {
    height: 620,
    maxWidth: 380,
    display: "flex",
    flexDirection: "column",

    width: "100%",
    border: `3px solid ${COLORS.RED}`,
  },
  flexContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  header: {
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 96,
    width: "100%",
    border: `4px solid ${COLORS.RED}`,
    backgroundColor: COLORS.RED,
    color: COLORS.WHITE,
  },
  headerText: {
    color: COLORS.DARK_BLUE,
    fontFamily: "CustomFourBold",

    textAlign: "center",
  },
  subHeader: {
    color: COLORS.DARK_RED  ,

    textAlign: "center",
  },
  subText: {
    color: COLORS.DARK_BLUE,
    fontFamily: "CustomFourBold",
  },
  mapContainer: {
    marginBottom: 20,
    height: 200,
    width: 250,
  },
  iconContainer: {
    width: 156,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    fontSize: "2.5rem",

    fontWeight: "bold",
    margin: "1em 0",
  },
});

export const ContactCard: FC<IProps> = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <section className={classes.header}>
        <Typography variant="h2" component="h2" className={classes.headerText}>
          Contact Info
        </Typography>
      </section>
      <section className={classes.flexContainer}>
        <div>
          <Typography
            variant="body2"
            component="p"
            className={classes.subHeader}
          >
            Email
          </Typography>
          <Typography
            variant="body2"
            component="a"
            data-testid="email"
            className={classes.subText}
            href={`mailto:${_companyContact.info}`}
          >
            {_companyContact.info}
          </Typography>
        </div>
        {/* <div style={{ marginBottom: 20 }}>
          <Typography
            variant="body2"
            component="p"
            className={classes.subHeader}
          >
            Phone
          </Typography>
          <Typography
            variant="body2"
            component="a"
            href={`tel:9518800422`}
            className={classes.subText}
          >
            (951)-880-0422
          </Typography>
        </div> */}
        {/* Currently don't have an address to include */}
        {/* <div>
        <Typography variant="body2" component="p"  className={classes.subHeader}>Address</Typography>
      </div> */}
        <div className={classes.mapContainer}>
          <GoogleMapContainer zoom={9} />
        </div>
        <div>
          <Typography
            variant="body2"
            component="p"
            style={{ marginBottom: 0 }}
            className={classes.subHeader}
          >
            Follow Us
          </Typography>

          <div className={classes.iconContainer}>
            <HybridLink className={classes.link} href={_socialMedia.instagram}>
              <Instagram />
            </HybridLink>
            <HybridLink className={classes.link} href={_socialMedia.facebook}>
              <Facebook />
            </HybridLink>
            <HybridLink className={classes.link} href={_socialMedia.youtube}>
              <YouTube />
            </HybridLink>
          </div>
        </div>
      </section>
    </div>
  );
};
