import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS } from "src/styles";

export const locationStyles = makeStyles({
  container: {
    padding: "2rem 2rem",
    maxWidth: 844,
    margin: "0 auto",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "92%",
      padding: "2rem 0rem",
    },
  },
  mapWrapper: {
    marginTop: "1rem",
    height: 300,
    width: "100%",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      height: 300,
      width: "100%",
    },
  },
  locationTitle: {
    fontFamily: "CustomFourBold",

    fontWeight: 500,
    marginBottom: "1rem",
  },
  subTitle: {
    color: "grey",

    margin: 0,
  },
  locationTextContainer: {
    display: "flex",

    alignItems: "center",
  },
  eventInfoIcon: { marginRight: ".5rem" },
  locationContainer: {
    display: "flex",
    flexDirection: "row",
  },
});
