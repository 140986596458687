import { client } from "src/apollo";
import { LocalStorageHelper } from "src/utils/helpers";
import { GET_ME, LOGOUT } from "./graphql";

declare var FB: any;

export const userAccountAPI = {
  logout: () => {
    return client
      .mutate({
        mutation: LOGOUT,
        errorPolicy: "ignore",
      })
      .then(() => {
        if ((window as any).facebookConnectPlugin) {
          (window as any).facebookConnectPlugin.logout(
            () => null,
            () => null
          );
        }
        if ("FB" in window) {
          if (FB.getAuthResponse()) {
            return FB.logout();
          }
        }
        localStorage.removeItem("foodnome_auth_token");
        localStorage.removeItem("foodnome_become_cook_intro_complete");
        localStorage.removeItem("foodnome-referrer-link");

        // Remove cart entries on logout
        LocalStorageHelper.clearCartDataFromLocalStorage();

        client
          .query({
            query: GET_ME,
            fetchPolicy: "network-only",
            errorPolicy: "ignore",
          })
          .then(() => {
            client.cache.reset();
          });
      });
  },
};
