import { Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { BREAK_POINTS, COLORS } from "src/styles";

export const Hungry = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <section className={classes.container}>
      <div className={classes.hungryContainer}>
        <div className={classes.outerContainer}>
          <div className={classes.innerContainer}>
            <div className={classes.textContainer}>
              <img
                src={require("src/screens/how-it-works/assets/diner/heart-05.png")}
                alt="heart"
                className={classes.heartImg}
              />
              <Typography variant="h2" component="h2" className={classes.title}>
                Hungry yet?
              </Typography>
              <Typography variant="h4" component="p" className={classes.text}>
                Explore your neighborhood one meal at a time on COOK Connect. Order
                for pickup or delivery from the best local cooks & support their
                small businesses.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                classes={{ root: classes.buttonRoot }}
                onClick={() => history.push("/menus")}
              >
                Find food near you!
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.imgContainer}>
        <img
          src={require("src/screens/how-it-works/assets/diner/curbside-2.png")}
          alt="curbside pickup image"
          className={classes.pickupImg}
        />
      </div>
    </section>
  );
};

const useStyles = makeStyles({
  buttonRoot: {
    // Styles we only want to show on desktop (greater than tablet)
    [`@media (min-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "32.5rem",
      fontSize: "1.8rem",
      padding: "17px 22px",
    },
  },
  textContainer: {
    maxWidth: "45rem",
  },
  container: {
    display: "flex",
    flexDirection: "row",

    padding: "10rem 14rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: 0,
    },
  },
  tileImg: {
    height: "50rem",
    borderRadius: "1rem",
  },
  imgContainer: {
    width: "40%",
    position: "relative",
    height: "50rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  hungryContainer: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
    },
  },

  innerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "1rem",
    backgroundColor: COLORS.WHITE,
    padding: "6rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      borderRadius: 0,
      padding: "3rem",
    },
  },
  outerContainer: {
    padding: "2rem",
    borderRadius: "1rem",
    backgroundColor: COLORS.RED,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      borderRadius: 0,
    },
  },
  heartImg: {
    margin: "0 auto",
    height: "10%",
    width: "10%",
    minWidth: "6rem",
  },
  pickupImg: {
    height: "60%",
    position: "absolute",
    top: "50%",
    right: -150,
    transform: "translate(0, -50%)",
    zIndex: -1,
  },
  text: {
    paddingBottom: "3rem",
    fontFamily: "CustomFour",
  },
  title: {
    paddingTop: "3rem",
    paddingBottom: "3rem",
    fontFamily: "CustomFourBold",
  },
});
