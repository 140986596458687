import { Button, Typography } from "@material-ui/core";
import React from "react";

export const ErrorComponent = ({
  message,
  refetch,
}: {
  message: string;
  refetch?: any;
}) => {
  // let _message;
  // const graphqlErrorRegexp = /graphql error:/gi;

  // if (message.match(graphqlErrorRegexp))
  //   _message = message.replace(/graphql error:/gi, "");
  // else _message = `Oops! Something isn't right. Please try again later.`;

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "#e0e0e0",
        opacity: 0.8,
        zIndex: 99,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <section style={{ textAlign: "center" }}>
        <Typography
          variant="h1"
          component="h1"
          style={{ fontFamily: "CustomFourBold" }}
        >
          Error <span aria-label="sad emoji">{"😢"}</span>
        </Typography>
        <strong>
          <Typography variant="body2" component="p">
            Oops! Something isn't right.
          </Typography>
          <Typography variant="body2" component="p">
            {message}
          </Typography>
        </strong>
        {refetch ? (
          <Button onClick={refetch} variant="contained" color="secondary">
            Try again
          </Button>
        ) : (
          <Typography variant="body2" component="a" href="/">
            Take me back to safety
          </Typography>
        )}
      </section>
    </div>
  );
};
