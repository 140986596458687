import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { sharedAPI } from "src/shared-graphql";
import { Layout } from "./layout";

export const FAQContainer: FC = () => {
  useEffect(() => {
    sharedAPI.setMobileHeaderTitle({ title: "FAQ" });
    return () => {
      sharedAPI.setMobileHeaderTitle({ title: "" });
    };
  }, []);

  const _description =
    "All of your burning COOK Connect questions, answered? How do I get my home kitchen permitted? \
    What are the laws for selling food? What is COOK Connect?";
  return (
    <>
      <Helmet>
        <title>FAQ | COOK Connect </title>
        <meta name="description" content={_description} />
      </Helmet>
      <Layout />
    </>
  );
};
