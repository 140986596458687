export const getEmailError = (email: string) => {
  const re = new RegExp("[^@]+@[^.]+..+");
  return re.test(String(email).toLowerCase()) || !email
    ? null
    : "Invalid Email";
};

// For US Zip Codes
export const getZipError = (zip: string) => {
  const re = new RegExp(/^\d{5}(-\d{4})?$/);
  return re.test(zip) || !zip ? null : "Invalid Zip-Code";
};

export const getNameError = (name: string, type: string) => {
  const re = new RegExp(/^(?=.{1,40}$)[a-zA-Z\s]+(?:[-'\s][a-zA-Z]+)*$/);
  return re.test(String(name).toLowerCase()) || !name
    ? null
    : `Invalid ${type} name`;
};

export const getPasswordError = (password: string) => {
  if (password.length < 8 && password)
    return "Password length must be more than 8";
  const re = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/);
  return re.test(password) || !password
    ? null
    : "Password must contain at least one upper case letter";
};

export const getRPasswordError = (password1: string, password2: string) => {
  if (!password1 || !password2) {
    return null;
  } else if (password1 !== password2) {
    return "Passwords do not match";
  } else {
    return null;
  }
};

export const getPhoneNumError = (number: string) => {
  const re = new RegExp(/^[0-9]{9,10}$/);
  return re.test(number) || !number ? null : "Invalid phone number";
};

export const isDisabled = (email: string, password: string) => {
  const invalidEmail = Boolean(getEmailError(email));
  return !email || !password || invalidEmail;
};

export const isSignUpDisabled = (
  email: string,
  password: string,
  // passwordR: string,
  firstName: string,
  lastName: string
  // phoneNumber: string
) => {
  const invalidEmail = Boolean(getEmailError(email));
  const invalidFirstName = Boolean(getNameError(firstName, "first"));
  const invalidLastName = Boolean(getNameError(lastName, "last"));
  // const invalidNumber = Boolean(getPhoneNumError(phoneNumber));
  const invalidPassword = Boolean(getPasswordError(password));
  // const invalidPasswordR = Boolean(getRPasswordError(password, passwordR));
  return (
    !email ||
    !password ||
    // !passwordR ||
    !firstName ||
    !lastName ||
    // !phoneNumber ||
    invalidEmail ||
    invalidFirstName ||
    invalidLastName ||
    invalidPassword
    // invalidPasswordR
  );
};
