import { client } from "src/apollo";
import { ShareLinkConfig, VerifyCouponPL } from "src/models";
import { VERIFY_COUPON } from "src/shared-graphql/queries";
import {
  SET_ACTIVE_MODE,
  SET_AUTH_DRAWER_STATE,
  SET_CONTACT_MODAL,
  SET_COVID_DIALOG_STATE,
  SET_DIALOG_EVENT_ID,
  SET_DIALOG_RESTAURANT_ID,
  SET_DINER_PROFILE_DRAWER_STATE,
  SET_DISCOUNT_PROMPT,
  SET_GLOBAL_DRAWER_STATE,
  SET_GLOBAL_LOADING_STATE,
  SET_MOBILE_HEADER_TITLE,
  SET_REFERRAL_DIALOG_STATE,
  SET_SHARE_DRAWER,
  SET_SIGNIN_DIALOG_STATUS,
  SET_SNACKBAR_MSG,
  SET_USER_UPDATE_EMAIL_DIALOG_STATE,
} from "./mutations";

export const sharedAPI = {
  setContactModal: (state: boolean) =>
    client.mutate({
      mutation: SET_CONTACT_MODAL,
      variables: { state },
    }),
  verifyCoupon: (d: VerifyCouponPL) => {
    return client.query({
      query: VERIFY_COUPON,
      variables: { input: d },
    });
  },
  setSnackbarMsg: async (snackbarMsgPL?: {
    type: "warning" | "information" | "error" | "success";
    msg: string;
  }) => {
    return client.mutate({
      mutation: SET_SNACKBAR_MSG,
      variables: {
        input: snackbarMsgPL,
      },
    });
  },
  setDiscountPrompt: async (status: boolean) => {
    return client.mutate({
      mutation: SET_DISCOUNT_PROMPT,
      variables: {
        input: status,
      },
    });
  },
  setDialogEventId: async (eventId?: string) => {
    return client.mutate({
      mutation: SET_DIALOG_EVENT_ID,
      variables: {
        input: eventId,
      },
    });
  },
  setDialogRestaurantName: async (restaurantName?: string) => {
    return client.mutate({
      mutation: SET_DIALOG_RESTAURANT_ID,
      variables: {
        input: restaurantName,
      },
    });
  },
  setUserSignInDialog: async (status: boolean) => {
    return client.mutate({
      mutation: SET_SIGNIN_DIALOG_STATUS,
      variables: {
        input: status,
      },
    });
  },
  setUserUpdateEmailDialog: async (state: boolean) => {
    return client.mutate({
      mutation: SET_USER_UPDATE_EMAIL_DIALOG_STATE,
      variables: { state },
    });
  },
  setMobileHeaderTitle: ({
    title,
    pathname = null,
  }: {
    title: string;
    pathname?: string | null;
  }) => {
    return client.mutate({
      mutation: SET_MOBILE_HEADER_TITLE,
      variables: {
        input: {
          title,
          pathname,
        },
      },
    });
  },
  share: (link: string, shareLinkConfig?: ShareLinkConfig) => {
    const {
      drawerTitle = "Share to your followers!",
      description = "",
      tags = [],
      facebook = {},
      twitter = {},
      whatsApp = {},
      email = {},
    } = shareLinkConfig || {};
    return client.mutate({
      mutation: SET_SHARE_DRAWER,
      variables: {
        defaultArgs: {
          drawerTitle,
          description,
          tags,
          link,
        },
        twitter: {
          description,
          tags,
          ...twitter,
        },
        facebook: {
          description,
          tag: tags[0] ? tags[0] : null,
          ...facebook,
        },
        whatsApp: {
          description,
          ...whatsApp,
        },
        email: {
          subject: "",
          body: description,
          ...email,
        },
      },
    });
  },

  setAuthDrawerState: (state: boolean) =>
    client.mutate({
      mutation: SET_AUTH_DRAWER_STATE,
      variables: { state },
    }),
  setReferralDialogState: (state: boolean) =>
    client.mutate({
      mutation: SET_REFERRAL_DIALOG_STATE,
      variables: { state },
    }),
  setGlobalDrawerState: (state: boolean) =>
    client.mutate({
      mutation: SET_GLOBAL_DRAWER_STATE,
      variables: { state },
    }),
  setDinerProfileDrawerState: (state: boolean) =>
    client.mutate({
      mutation: SET_DINER_PROFILE_DRAWER_STATE,
      variables: { state },
    }),
  setActiveMode: (mode: boolean) =>
    client.mutate({
      mutation: SET_ACTIVE_MODE,
      variables: { mode },
    }),
  setGlobalLoadingState: (state: boolean) =>
    client.mutate({
      mutation: SET_GLOBAL_LOADING_STATE,
      variables: { state },
    }),
  setCOVID19DialogState: (state: boolean) =>
    client.mutate({
      mutation: SET_COVID_DIALOG_STATE,
      variables: { state },
    }),
};
