import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useOverviewStyles = makeStyles({
  widgetLeftLayout: {
    padding: ".5rem",
    maxWidth: 600,
    background: COLORS.WHITE,
    flexGrow: 1,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: "unset",
      width: "100%",
    },
  },
  detailContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "start",
  },
  heading: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    margin: "1rem 0",
  },

  // Main header
  restaurantName: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    overflow: "hidden",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    margin: "1rem 0",
  },
  restaurantDetailsLayout: {
    listStyleType: "none",
    padding: 0,
  },
  restaurantDetail: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  address: {
    overflow: "hidden",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    textTransform: "capitalize",
  },
  icon: {
    verticalAlign: "middle",
    fontSize: "2rem",
    marginRight: "0.5rem",
  },
  textSmall: {
    color: COLORS.DARK_GREY,

    padding: 0,
    display: "inline-block",
  },
  googleMapsContainer: {
    height: 111,
    maxWidth: "100%",

    margin: "1rem 0",
  },
  // About
  aboutLayout: {
    display: "flex",
    padding: "1rem 0",
  },
  reviewStarContainer: {
    display: "flex",
  },
  rightSide: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    marginLeft: "4rem",
    marginTop: "2rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginLeft: "2rem",
    },
  },
  hostAvatar: {
    backgroundColor: COLORS.RED,
    height: 100,
    width: 100,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    textTransform: "uppercase",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      height: 75,
      width: 75,
    },
  },
  hostName: {
    textAlign: "center",
    textTransform: "capitalize",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    margin: "0.5rem 0",
  },

  expandButton: {
    display: "inline-block",

    color: COLORS.DARK_GREY,
    textTransform: "capitalize",
  },
  // Dishes
  dishList: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(210px, 1fr))",
    listStyleType: "none",
    padding: 0,
    gridGap: "1rem",
  },
  dishImage: {
    height: "100%",
    objectFit: "cover",
  },
  dishDescription: {},
  dishName: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    color: COLORS.MEDIUM_GREEN,
  },
  dishesExpansionButton: {
    border: "1px solid #858486",
    borderRadius: 2,
    color: COLORS.BLACK,
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    textTransform: "capitalize",
    minWidth: 120,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:disabled": {
      border: "1px solid #858486",
      borderRadius: 2,
      color: COLORS.BLACK,
    },
  },
  // Reviews
  disclosure: {
    fontStyle: "normal",
  },
  reviewSummary: {
    display: "grid",
    gridTemplateColumns: "repeat(3, auto)",
    gridGap: ".5rem",
    marginBottom: "2rem",
    "& :last-child": {
      borderRight: "none",
    },
  },
  reviewSummaryPart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRight: "1px solid #D4D4D4",
  },
  reviewSummaryTitle: {
    color: COLORS.BLACK,
    textAlign: "center",
    padding: ".5rem",
  },
  reviewSummaryPercentage: {
    order: -1,
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    margin: 0,
  },

  // Shared
  starsContainer: {
    display: "flex",
    margin: "1rem 0",
    cursor: "pointer",
  },
  reviewAmount: {
    alignSelf: "center",
    margin: "0 0.5rem",
    flexGrow: 1,

    color: COLORS.DARK_GREY,
  },
  subheading: {
    fontFamily: "CustomFive",
    fontWeight: 500,
    margin: "0.5rem 0",
  },
  metricContainer: {
    display: "flex",
    justifyContent: "space-around",
    margin: "1rem 0",
    padding: "0.5rem 0",
    flexGrow: 1,
    borderTop: "1px solid #D4D4D4",
    borderBottom: "1px solid #D4D4D4",
  },
  followers: {
    color: COLORS.DARK_GREY,
  },
  num: {
    display: "block",
    fontFamily: "CustomFourBold",
    color: COLORS.BLACK,
  },
  subsection: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    color: "#696969",
  },
});
