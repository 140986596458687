import moment from "moment";
import { EventStatus, IEvent } from "src/models";

export const statusOptions = [
  "ALL EVENTS",
  "ACTIVE",
  "PENDING",
  "CANCELLED",
  "COMPLETED",
];

export const errorMessages = {
  invalidStart: "Cannot cancel an event that has already occurred.",
  invalidEnd: "Cannot complete event until after event endTime.",
  notActive: "Event is not an ACTIVE event.",
};

export function checkValidStartTime(event) {
  if (moment().isBefore(moment(Number(event.startTime)))) return true;
  return false;
}

export function checkValidEndTime(event) {
  if (moment().isAfter(moment(Number(event.endTime)))) return true;
  return false;
}

export function checkActiveEvent(event) {
  if (event.status !== EventStatus.ACTIVE) return false;
  return true;
}

export const getEventByStatus = (
  activeStatus: StatusTypes,
  hostEvents: IEvent[]
) => {
  if (activeStatus === StatusTypes.ALL_EVENTS) return hostEvents;
  else {
    return hostEvents.filter((event) => event.status === (activeStatus as any));
  }
};

/**
 * [Quickly check if the event if valid by checking if the event is ACTIVE and/or
 * the event has a valid startTime or valid endTime. The timeType is used
 * for instances when you want to quickly check the startTime or endTime.
 * For example if you want to check if the event can be canceled, we care
 * about if the current time is before the event startTime and not the endTime.]
 * @param  {[IEvent]} event [event to check]
 * @param  {[string]} timeType [description]
 * @return {[string | boolean]} []
 */
export function validateEventAction({
  event,
  timeType,
}: {
  event: IEvent;
  timeType?: string;
}) {
  // 1. check if the event is ACTIVE
  const isEventActive = checkActiveEvent(event);
  if (!isEventActive) return errorMessages.notActive;

  // 2. check if the current time is before the event startTime.
  if (timeType === "START_TIME" && !checkValidStartTime(event))
    return errorMessages.invalidStart;

  // 2. or check
  if (timeType === "END_TIME" && !checkValidEndTime(event))
    return errorMessages.invalidEnd;

  return true;
}

export function findIfOneGuest(event) {
  if (event.guests.length === 1) return true;
  return false;
}

export function handleAction({
  event,
  checkFor,
  callback,
  onError,
}: {
  event: IEvent;
  checkFor?: string;
  callback: (eventId: string) => Promise<any>;
  onError: (message: string) => void;
}) {
  const error = validateEventAction(
    checkFor ? { event, timeType: checkFor } : { event }
  );

  if (typeof error === "string") {
    onError(error);
    return false;
  } else return callback(event.id);
}

export enum StatusTypes {
  ALL_EVENTS = "ALL EVENTS",
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
}
