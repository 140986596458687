import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  footerBottomContainer: {
    width: "100%",
    padding: "2.5rem 0",
  },
  footerBottom: {
    margin: "0 auto",
    width: "100%",
    display: "flex",
    position: "relative",

    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "center",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },

  iconList: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gridColumnGap: "1rem",
  },

  link: {
    textDecoration: "none",
    color: "inherit",
    fontSize: "2.5rem",
    fontWeight: "bold",
    margin: "1em 0",
  },
  listItem: {
    display: "inline",
    margin: "0 .5em",
    textDecoration: "none",
    color: COLORS.MEDIUM_RED,
  },

  copyRight: {
    alignSelf: "center",

    color: "grey",
    fontWeight: 100,
  },
  submitRequest: {
    color: COLORS.DARK_RED,
    textDecoration: "none",
  },
  copyRightContainer: {
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      alignSelf: "center",
      order: 3,
      padding: "3rem 0",
    },
  },
  submitRequestContainer: {
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      alignSelf: "center",
      padding: "3rem 0",
      order: 2,
    },
  },
  socialButtonsContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",

    maxWidth: 220,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      all: "unset",
      maxWidth: 220,
      position: "relative",
      order: 1,
      width: "100%",
      alignSelf: "center",
      padding: "3rem 0",
    },
  },
});
