import { makeStyles } from "@material-ui/core";

export const listStyles = makeStyles({
  noGuestContainer: {
    minHeight: "154px",
    backgroundColor: "#e3e7ea",
    color: "#96a4ad",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    border: "4px",
    justifyContent: "center",
    margin: "20px",
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "0 6px",
    width: "100%",
  },
});
