import moment from "moment";
import React, { useEffect } from "react";
import { checkForSafari } from "src/utils/helpers";

export interface DateTimeValidation {
  rule: () => boolean;
  message: string;
}

export const useSafariDateValidations = (
  {
    date,
    active = true,
    additionalChecks = [],
  }: {
    date: string;
    active?: boolean;
    additionalChecks?: DateTimeValidation[];
  },
  { format = "YYYY/MM/DD" }: { format?: string } = {}
) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const checks = React.useMemo(() => {
    return [
      {
        rule: () => moment(date, format).isValid(),
        message: `Your date is not in the right format: ${format} or is not a valid date.`,
      },
      {
        rule: () => moment(date, format).isSameOrAfter(moment().format(format)),
        message: "Your date is in the past",
      },
      ...additionalChecks,
      {
        rule: () => true,
        message: "",
      },
    ];
  }, [date, additionalChecks]);

  useEffect(() => {
    const isSafari = checkForSafari();
    const dateInput = inputRef.current;
    if (active && isSafari && !!dateInput) {
      for (const check of checks) {
        const passed = check.rule();
        if (!passed) {
          dateInput.setCustomValidity(check.message);
          break;
        } else {
          // reset validity
          dateInput.setCustomValidity("");
        }
      }
    }
  }, [active, checks]);

  return { inputRef };
};

export const useSafariDateTimeValidations = (
  {
    datetime,
    active = true,
    additionalChecks = [],
  }: {
    datetime: { date: string; time: string };
    active?: boolean;
    additionalChecks?: DateTimeValidation[];
  },
  { format = "YYYY-MM-DD HH:mm" }: { format?: string } = {}
) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const checks = React.useMemo(() => {
    const dt = `${datetime.date} ${datetime.time}`;
    const validDateAndTime = Boolean(
      datetime.date && datetime.time && !/_/gi.test(datetime.time)
    );
    return [
      {
        rule: () => validDateAndTime,
        message: "Make sure both date and time are selected.",
      },
      {
        rule: () => moment(dt, format).isValid(),
        message: "Make sure both date and time are valid",
      },
      {
        rule: () => moment(dt, format).isSameOrAfter(moment().format(format)),
        message: "Your selected date and time are in the past",
      },
      ...additionalChecks,
    ];
  }, [datetime.date, datetime.time, additionalChecks]);

  useEffect(() => {
    const isSafari = checkForSafari();
    const dateInput = inputRef.current;
    if (!isSafari) return;
    if (active && !!dateInput) {
      for (const check of checks) {
        const passed = check.rule();
        if (!passed) {
          dateInput.setCustomValidity(check.message);
          break;
        } else {
          // reset validity
          dateInput.setCustomValidity("");
        }
      }
    }
  }, [active, checks]);

  return { inputRef };
};
