import { TextField, Typography } from "@material-ui/core";
import { Phone, Room } from "@material-ui/icons";
import React from "react";
import InputMask from "react-input-mask";
import { formStyles as useStyles } from "./styles";

interface IProps {
  address: string;
  zipcode: string;
  city: string;
  phone: string;
  onChange: ({
    target: { value, name },
  }: {
    target: { value: string; name: string };
  }) => void;
}

export const LocationPhoneFields: React.FC<IProps> = ({
  zipcode,
  phone,
  onChange,
}) => {
  // This is to prevent uncontrolled input error
  phone = phone === null ? "" : phone;
  const classes = useStyles();

  // Using this unique id to prevent duplication errors since this component
  // is shown twice in the become-a-cook page
  const uniqueId = Date.now();
  return (
    <div className={classes.col2}>
      <div>
        <Typography
          component="label"
          variant="caption"
          className={"hidden-label"}
          htmlFor={"become-cook-phone-" + uniqueId}
        >
          Phone Number*
        </Typography>
        <InputMask
          mask="1-(899)-999-9999"
          value={phone}
          onChange={(e: any) => onChange(e)}
          formatChars={{ "9": "[0-9]", "8": "[2-9]" }}
        >
          {(inputProps) => (
            <TextField
              size="small"
              autoComplete="new-password"
              {...({ "data-e2e": "become-cook-phone-" + uniqueId } as any)}
              inputProps={{ placeholder: "Phone Number" }}
              {...inputProps}
              variant="outlined"
              name="phoneNumber"
              id={"become-cook-phone-" + uniqueId}
              InputProps={{
                labelWidth: 0,
                classes: {
                  root: classes.textField,

                  input: classes.input,
                },
                startAdornment: <Phone className={classes.startAdornment} />,
              }}
              value={phone}
              type="tel"
              required
              pattern="[\-\(\)0-9]+"
              fullWidth
            />
          )}
        </InputMask>
      </div>
      <div>
        <Typography
          component="label"
          variant="caption"
          className={"hidden-label"}
          htmlFor={"become-cook-zipcode-" + uniqueId}
        >
          Zipcode*
        </Typography>
        <InputMask
          mask="99999"
          value={zipcode}
          maskChar={null}
          onChange={(e: any) => onChange(e)}
        >
          {(inputProps) => (
            <TextField
              size="small"
              {...inputProps}
              variant="outlined"
              inputProps={{ placeholder: "ZIP Code", inputMode: "numeric" }}
              InputProps={{
                labelWidth: 0,
                classes: {
                  root: classes.textField,

                  input: classes.input,
                },
                startAdornment: <Room className={classes.startAdornment} />,
              }}
              name="zipCode"
              id={"become-cook-zipcode-" + uniqueId}
              {...({ "data-e2e": "become-cook-zipcode-" + uniqueId } as any)}
              className={classes.textField}
              required
              pattern="[0-9]{5}"
              fullWidth
            />
          )}
        </InputMask>
      </div>
    </div>
  );
};
