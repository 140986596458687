import React from "react";
import { Redirect } from "react-router-dom";

interface IState {
  hasError: boolean;
}
export class ErrorBoundary extends React.Component<any, IState> {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Redirect to="/login" />;
    }
    return this.props.children;
  }
}
