import { SvgIcon } from "@material-ui/core";
import React, { FC } from "react";

interface IProps {
  className?: any;
}

export const CustomFacebookIcon: FC<IProps> = ({ className, ...props }) => (
  <SvgIcon className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <title>FB</title>
      <path
        d="M48,24A24,24,0,1,0,20.25,47.71V30.94H14.16V24h6.09V18.71c0-6,3.58-9.34,9.07-9.34a36.87,36.87,0,0,1,5.37.47v5.91h-3c-3,0-3.91,1.85-3.91,3.75V24h6.66l-1.07,6.94H27.75V47.71A24,24,0,0,0,48,24Z"
        fill="#1877f2"
      />
      <path
        d="M33.34,30.94,34.41,24H27.75V19.5c0-1.9.93-3.75,3.91-3.75h3V9.84a36.87,36.87,0,0,0-5.37-.47c-5.49,0-9.07,3.33-9.07,9.34V24H14.16v6.94h6.09V47.71a24.39,24.39,0,0,0,7.5,0V30.94Z"
        fill="#fff"
      />
    </svg>
  </SvgIcon>
);
