import { makeStyles } from "@material-ui/core";
import React from "react";
import { COLORS } from "src/styles";
import { displayPrice } from "./helpers";

interface IProps {
  originalPrice: number;
  discountedPrice: number;
  originalPriceStyle?: React.CSSProperties;
  discountedPriceStyle?: React.CSSProperties;
  strikeClassName?: string;
}

const useStyles = makeStyles({
  discounted: {
    color: COLORS.WHITE,
    marginLeft: ".5em",
    fontStyle: "normal",

    fontWeight: 500,
    fontFamily: "CustomFourBold",
  },
  original: {
    marginLeft: ".5em",
    fontStyle: "normal",

    fontWeight: 500,

    position: "relative",
    color: "rgba(255, 255, 255, 0.8)",
    "&::after": {
      content: `""`,
      borderBottom: `0.1em solid black`,
      left: 0,
      marginTop: `calc(.25em / 2 * -1)`,
      position: "absolute",
      right: 0,
      top: "58%",
    },
  },
  strike: {
    visibility: "hidden",
  },
  nondiscounted: {
    marginLeft: ".12em",
    color: "inherit",
    fontStyle: "normal",

    display: "inline",
    verticalAlign: "middle",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
});

export const Price: React.FC<IProps> = ({
  originalPrice,
  discountedPrice,
  originalPriceStyle = {},
  discountedPriceStyle = {},
  strikeClassName,
}) => {
  const classes = useStyles();
  strikeClassName = classes.original;
  discountedPrice = Number(discountedPrice.toFixed(2));
  originalPrice = Number(originalPrice.toFixed(2));
  return (
    <>
      <var className={strikeClassName}>
        <span
          aria-label={`original price: $${originalPrice}`}
          style={Object.assign({}, originalPriceStyle)}
        >
          <span aria-hidden className={classes.strike}>
            {"h"}
          </span>
          {` $${originalPrice} `}
          <span aria-hidden className={classes.strike}>
            {"h"}
          </span>
        </span>
      </var>
      <var
        aria-label={`discounted price: $${discountedPrice}`}
        className={classes.discounted}
      >
        <span style={Object.assign({}, discountedPriceStyle)}>
          ${displayPrice(discountedPrice)}
        </span>
      </var>
    </>
  );
};
