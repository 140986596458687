import { IconButton, makeStyles, useMediaQuery } from "@material-ui/core";
import React, { FC, useContext } from "react";
import { IRestaurant } from "src/models";
import { BREAK_POINTS, COLORS } from "src/styles";
import { ListMapContext } from "../container";
import { ActionTypes } from "../reducer";
import { ClickedIcon, HoveredIcon, NoMenusIcon, OpenIcon } from "./assets";

interface IProps {
  restaurant: IRestaurant;
  lat: any;
  lng: any;
}
export const RestaurantMarker: FC<IProps> = ({ restaurant }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  const {
    state: { hoveredRestaurant, clickedRestaurant, loadingRestaurants },
    dispatch,
  } = useContext(ListMapContext);

  const getRestaruantIcon = () => {
    if (clickedRestaurant?.id === restaurant.id)
      return <ClickedIcon className={classes.clickedMarker} />;
    else if (hoveredRestaurant?.id === restaurant.id)
      return <HoveredIcon className={classes.clickedMarker} />;
    else if (restaurant.events.length > 0)
      return <OpenIcon className={classes.marker} />;
    else return <NoMenusIcon className={classes.marker} />;
  };

  if (loadingRestaurants) return null;
  return (
    <IconButton
      // This is needed to accurately position marker
      style={{ position: "absolute", transform: "translate(-50%, -50%)" }}
      size="small"
      disableRipple
      onClick={(e) => {
        dispatch({
          type: ActionTypes.SET_CLICKEDED_RESTAURANT,
          payload: restaurant,
        });
        // if (!isMobile) {
        //   e.stopPropagation();
        //   sharedAPI.setDialogRestaurantName(restaurant?.name);
        // }
      }}
      onMouseEnter={() => {
        dispatch({
          type: ActionTypes.SET_HOVERED_RESTAURANT,
          payload: restaurant,
        });
      }}
      onMouseLeave={() => {
        dispatch({
          type: ActionTypes.SET_HOVERED_RESTAURANT,
          payload: undefined,
        });
      }}
    >
      {getRestaruantIcon()}
    </IconButton>
  );
};

const useStyles = makeStyles({
  marker: {
    fontSize: 24,

    "&:hover": {
      zIndex: 500,
      fontSize: 34,
      color: COLORS.RED,
      animation: "$pulse 2s infinite",
    },
  },
  clickedMarker: {
    zIndex: 500,
    fontSize: 34,

    animation: "$pulse 2s infinite",
  },
  "@keyframes pulse": {
    "0%": {
      filter: "drop-shadow(0px 0px 0px rgba(255,54,94,1))",
    },

    "70%": {
      filter: "drop-shadow(0px 0px 30px rgba(255,54,94,0.5))",
    },

    "100%": {
      filter: "drop-shadow(0px 0px 0px rgba(255,54,94,0))",
    },
  },
  "@-webkit-keyframes pulse": {
    "0%": {
      WebkitFilter: "drop-shadow(0px 0px 0px rgba(255,54,94,0.9))",
    },

    "70%": {
      WebkitFilter: "drop-shadow(0px 0px 30px rgba(255,54,94,0.4))",
    },

    "100%": {
      WebkitFilter: "drop-shadow(0px 0px 0px rgba(255,54,94,0))",
    },
  },
});
