import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    width: "100%",
    height: "100%",
  },

  bottomSection: {
    position: "relative",
    backgroundColor: COLORS.WHITE,
    borderRadius: "1rem 1rem 0 0",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "3rem",
    paddingTop: "5rem",
    paddingBottom: "9rem",
    justifyContent: "center",
    alignItems: "center",
    [`@media (max-width: ${BREAK_POINTS.mobileSmall}em)`]: {
      paddingTop: "3rem",
    },
  },
  video: {
    position: "fixed",
    zIndex: -999,
    top: 0,
    width: "auto",
    minWidth: "100%",
    objectFit: "contain",
    filter: "brightness(80%)",
    [`@media (max-width: ${BREAK_POINTS.mobileSmall}em)`]: {
      right: "-20%",
    },
  },
  title: {
    textAlign: "center",
    marginBottom: "1.5rem",
  },
  text: {
    textAlign: "center",
    fontFamily: "CustomFour",
    marginBottom: "2rem",
  },
  foodnomeLogoWhite: {
    width: "50%",
    position: "absolute",
    left: "50%",
    top: "2.5rem",
    transform: "translate(-50%, 0)",
  },

  logo: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0)",
    top: "-10%",
    width: "15%",
    maxWidth: "8rem",
  },
  textContainer: {
    width: "100%",
    maxWidth: "40rem",
  },
  squig: {
    position: "absolute",
    right: -400,
    bottom: -200,
    width: "55rem",
  },
});
