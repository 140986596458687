import { makeStyles } from "@material-ui/core";
import Switch, { SwitchProps } from "@material-ui/core/Switch";
import React from "react";
import { COLORS } from "src/styles";

export const Toggler: React.FC<SwitchProps> = (props) => {
  const classes = useStyles();
  return (
    <Switch
      data-testid="Toggler"
      color="default"
      onChange={props.onChange}
      checked={props.checked}
      icon={
        <span
          style={{
            padding: "1rem",
            color: COLORS.WHITE,
            backgroundColor: COLORS.MEDIUM_RED,
            borderRadius: 40,
            fontFamily: "CustomFourBold",
            fontSize: "1.6rem",
            fontWeight: 500,
            boxShadow: `0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)`,
          }}
        >
          Cook Mode
        </span>
      }
      checkedIcon={
        <span
          style={{
            padding: "1rem",
            backgroundColor: COLORS.MEDIUM_RED,
            borderRadius: 40,
            color: COLORS.WHITE,
            fontFamily: "CustomFourBold",
            fontSize: "1.6rem",
            fontWeight: 500,
            boxShadow: `0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)`,
          }}
        >
          Diner Mode
        </span>
      }
      classes={{
        root: classes.root,
        track: props.checked ? classes.trackCook : classes.trackDiner,
        checked: classes.checked,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
      }}
      {...props}
    />
  );
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: 240,
    height: 66,
  },
  trackDiner: {
    borderRadius: 40,
    position: "relative",
    backgroundColor: "#F6F6F6",
    boxShadow: `0 2px 6px 0 rgba(0,0,0,0.23)`,
    "&::after": {
      content: `"Diner Mode"`,
      position: "absolute",
      right: 0,
      fontSize: "1.6rem",

      fontWeight: 100,

      padding: "1rem 2rem",
      color: COLORS.BLACK,
    },
  },
  trackCook: {
    borderRadius: 40,
    backgroundColor: "#F6F6F6",
    position: "relative",
    boxShadow: `0 2px 6px 0 rgba(0,0,0,0.23)`,
    "&::after": {
      content: `"Cook Mode"`,
      position: "absolute",
      left: 0,
      fontSize: "1.6rem",
      fontWeight: 100,

      padding: "1rem 2rem",
      color: COLORS.BLACK,
    },
  },
  checked: {},
  switchBase: {
    padding: "1rem",
    borderRadius: 40,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&$checked": {
      transform: "translateX(117px)",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  thumb: {
    height: "2rem",
    width: "3.5rem",
    borderRadius: 40,
  },
});
