import { useQuery } from "@apollo/client";
import React, { FC, useEffect, useState } from "react";
import { ErrorLoadingComponent } from "src/components";
import { IDish } from "src/models";
import { useDelay } from "src/utils/hooks";
import { dishAPI } from "../api";
import { ADMIN_GET_DISHES } from "../api/graphql";
import { Layout } from "./layout";

export interface IFetchMorePL {
  pagePL: {
    offset?: number;
    limit?: number;
  };
  searchTerm?: string;
}

export const DishContainer: FC = () => {
  const { data, loading, error, fetchMore } = useQuery<{
    adminGetDishes: { count: number; rows: IDish[] };
  }>(ADMIN_GET_DISHES, {
    variables: { input: { pagePL: { limit: 10 } } },
  });

  const [pageNum, setPageNum] = useState(1);
  const [_searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDelay(_searchTerm, 300);

  useEffect(() => {
    _fetchMore({
      searchTerm: debouncedSearchTerm,
      pagePL: { offset: 0, limit: 10 },
    });
  }, [debouncedSearchTerm]);

  const _deleteDish = (id: number) => {
    if (data?.adminGetDishes.rows.length === 1 && pageNum > 1) {
      setPageNum(pageNum - 1);
      return dishAPI.adminDeleteDish(id, (pageNum - 2) * 10);
    }
    return dishAPI.adminDeleteDish(id, (pageNum - 1) * 10);
  };

  const _fetchMore = ({
    pagePL: { offset, limit },
    searchTerm,
  }: IFetchMorePL) => {
    fetchMore({
      variables: {
        input: {
          pagePL: {
            limit: limit ? limit : 10,
            offset: offset ? offset : undefined,
          },
          searchTerm,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          adminGetDishes: (fetchMoreResult as any).adminGetDishes,
        });
      },
    });
  };

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }
  return (
    <Layout
      dishes={data?.adminGetDishes.rows as IDish[]}
      count={data?.adminGetDishes.count as number}
      fetchMore={_fetchMore}
      onSearch={setSearchTerm}
      deleteDish={_deleteDish}
    />
  );
};
