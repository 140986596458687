import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles/";

export const layoutStyles = makeStyles({
  container: {
    margin: "10px 1em 1em 1em",
    width: "100%",
    borderRadius: "20px",
    backgroundColor: COLORS.WHITE,
    height: 800,
    boxShadow: "3px 4px 19px 0 rgba(31,64,80,0.28)",
    overflowY: "scroll",
  },
  title: {
    color: COLORS.BLACK,
    fontFamily: "CustomFourBold",

    fontWeight: 500,

    margin: 0,
  },
  headerContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 50px",
    width: "100%",
    marginBottom: 30,
  },
  eventsList: {
    marginTop: "2rem",
    display: "grid",
    gridRowGap: "2.5rem",
    padding: "0 1.5rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginTop: "8rem",
    },
  },
  errorLoading: {
    display: "flex",
    height: "50vh",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  header: {
    height: 40,
    width: "100%",
    borderRadius: "20px 20px 0 0",
    backgroundColor: COLORS.MEDIUM_GREEN,
    boxShadow: "0 2px 19px 0 #BBCCD0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    marginBottom: 30,
    position: "sticky",
    top: 0,
    zIndex: 400,
  },
  [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
    headerContainer: {
      justifyContent: "space-between",
      height: 120,
    },
  },
  [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
    headerContainer: {
      marginTop: 20,
    },
    container: {
      margin: "0 0",
      borderRadius: 0,
    },
    header: {
      display: "none",
    },
  },
  [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
    headerContainer: {
      justifyContent: "space-around",
    },
  },
});
