import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const noItemsStyles = makeStyles({
  container: {
    width: "100%",
    backgroundColor: COLORS.WHITE,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: 80,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },
  createContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  titleText: {
    fontFamily: "CustomFourBold",

    fontWeight: 500,

    color: COLORS.BLACK,
    margin: 0,
  },
  subText: {
    fontFamily: "CustomFourBold",

    fontWeight: 500,

    color: COLORS.DARK_BLUE,
    margin: "15px 0",
    maxWidth: 300,
    width: "100%",
  },
  imageContainer: {
    width: 250,
    overflow: "hidden",
    margin: "-30px 30px 0 0",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      margin: "30px 0 0 0",
    },
  },
  image: {
    width: "100%",
    height: "100%",
  },
  buttonRoot: {
    height: 50,
    width: 280,
    border: "2px solid #FF5967",
    borderRadius: "2px",
    backgroundColor: COLORS.MEDIUM_RED,
    color: COLORS.WHITE,
    boxShadow: "-1px 2px 4px 0 rgba(31,64,80,0.41)",
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_RED,
      color: COLORS.WHITE,
    },
  },
  buttonLabel: {
    fontFamily: "CustomFourBold",

    fontWeight: 500,
  },
  icon: {
    color: COLORS.WHITE,
    marginRight: 10,
    fontSize: "2rem",
  },
});
