import { makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  YouTube,
} from "@material-ui/icons";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { HybridLink } from "src/components";
import { COLORS } from "src/styles";
import { _socialMedia } from "src/utils/constants";
import { globalMenuLinks } from "./static-routes";

const useStyles = makeStyles(() => ({
  menuItem: {
    borderTop: "1px solid #F5F7FB",
    borderBottom: "1px solid #F5F7FB",
    transition: "none",
    backgroundColor: COLORS.WHITE,
    "&:hover": {
      backgroundColor: "#F5F7FB",
    },
    "&:focus": {
      backgroundColor: COLORS.WHITE,
    },
  },
  socialMediaMenuItem: {
    transition: "none",
    backgroundColor: COLORS.WHITE,
    "&:hover": {
      backgroundColor: COLORS.WHITE,
    },
    "&:focus": {
      backgroundColor: COLORS.WHITE,
    },
  },
  activeMenuItem: {
    backgroundColor: "#F5F7FB !important",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.MEDIUM_RED,
  },
  menuListRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  socialMediaAnchor: {
    cursor: "pointer",
    fontSize: "2.5rem",
    color: COLORS.VIOLET,
  },
  menuRoot: {
    minWidth: 326,

    borderRadius: 0,
    boxShadow: `1px 5px 17px 1px rgba(39,39,39,0.15)`,
    padding: "1rem 1.75rem",
  },
  navBarItem: {
    height: "100%",
    padding: "0 20px",
    fontSize: "3rem",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: COLORS.WHITE,
    },
  },

  backdropRoot: {
    backgroundColor: "transparent !important",
  },
}));

export const DesktopGlobalMenu: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
    e.currentTarget.blur();
  };

  return (
    <>
      <MenuItem
        data-e2e="header-unauth-menu"
        data-testid="header-unauth-menu"
        disableRipple
        style={{
          padding: "0 10px",
          minWidth: 60,
          display: "flex",
          justifyContent: "center",
        }}
        onClick={handleOpenMenu}
        classes={{ root: classes.navBarItem }}
        tabIndex={0}
        button
      >
        <span
          className={
            Boolean(anchorEl)
              ? "hamburger hamburger--collapse is-active"
              : "hamburger hamburger--collapse"
          }
          data-testid="navigation-hamburger-icon-button"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </span>
      </MenuItem>
      <Menu
        BackdropProps={
          {
            [`data-testid`]: "global-desktop-menu-backdrop",
            className: classes.backdropRoot,
          } as any
        }
        data-testid="global-desktop-menu"
        classes={{ paper: classes.menuRoot }}
        onClose={handleCloseMenu}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        MenuListProps={{ classes: { root: classes.menuListRoot } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        PaperProps={{ component: "nav" }}
        keepMounted
      >
        {globalMenuLinks.map((route) => (
          <MenuItem
            {...({
              to: route.pathname,
              className: classes.menuItem,
              activeClassName: classes.activeMenuItem,
              exact: true,
            } as any)}
            component={NavLink as any}
            key={route.pathname}
            disableRipple
            disableTouchRipple
            onClick={handleCloseMenu}
            role="link"
          >
            <Typography variant="body2" component="span">
              {route.name}
            </Typography>
          </MenuItem>
        ))}
        <MenuItem
          disableRipple
          disableTouchRipple
          key={"social-media"}
          className={classes.socialMediaMenuItem}
        >
          <ul
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              listStyleType: "none",
              padding: 0,
            }}
          >
            <li>
              <HybridLink
                className={classes.socialMediaAnchor}
                ariaLabel="instagram"
                href={_socialMedia.instagram}
              >
                <Instagram />
              </HybridLink>
            </li>
            <li>
              <HybridLink
                className={classes.socialMediaAnchor}
                ariaLabel="facebook"
                href={_socialMedia.facebook}
              >
                <Facebook />
              </HybridLink>
            </li>
            <li>
              <HybridLink
                className={classes.socialMediaAnchor}
                ariaLabel="youtube"
                href={_socialMedia.youtube}
              >
                <YouTube />
              </HybridLink>
            </li>
            <li>
              <HybridLink
                className={classes.socialMediaAnchor}
                ariaLabel="twitter"
                href={_socialMedia.twitter}
              >
                <Twitter />
              </HybridLink>
            </li>
            <li>
              <HybridLink
                className={classes.socialMediaAnchor}
                ariaLabel="linkedin"
                href={_socialMedia.linkedin}
              >
                <LinkedIn />
              </HybridLink>
            </li>
          </ul>
        </MenuItem>
      </Menu>
    </>
  );
};
