import { useQuery } from "@apollo/client";
import { CircularProgress, Paper } from "@material-ui/core";
import { TextFieldProps as MUITextFieldProps } from "@material-ui/core/TextField";
import Downshift from "downshift";
import debounce from "lodash/debounce";
import { stringify } from "query-string";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { LocalStorageHelper } from "src/utils/helpers";
import { IPrediction, IRestaurant, ISearchSuggestions } from "../../models";
import { GET_SEARCH_SUGGESTIONS } from "./api/graphql/queries";
import { AutoLocate } from "./auto-locate";
import { useStyles } from "./container.styles";
import { LocationList } from "./location.list";
import { RestaurantList } from "./restaurant-list";
import { TextInput } from "./text-input";

interface IProps {
  onSelect: (d: any) => void;
  onSelectRestaurant?: (d: IRestaurant) => void;
  onKey?: (d: any) => void;
  useGeoLocation?: true;
  initialState?: string;
  TextFieldProps?: MUITextFieldProps;
  containerStyle?: React.CSSProperties;
  suggestionStyle?: any;
  truncateSuggestion?: boolean;
  size?: string;
  autoFocus?: boolean;
  displaySearchFor?: any;
  isMobileSearchDialog?: boolean;
}
export const AutoCompleteContainer: FC<IProps> = ({
  isMobileSearchDialog = false,
  size = "medium",
  autoFocus = false,
  onSelect,
  onKey,
  useGeoLocation,
  initialState = "",
  TextFieldProps,
  suggestionStyle = {
    zIndex: 10,
  },
  containerStyle = {},
  truncateSuggestion,
  onSelectRestaurant,
  displaySearchFor = { google: true, restaurants: true },
}) => {
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<IPrediction[]>([]);
  const [_restaurants, setRestaurants] = useState<IRestaurant[]>([]);
  const [latitude, setLatitude] = useState<any>();
  const [longitude, setLongitude] = useState<any>();
  const [geoCodeLoading, setGeoCodeLoading] = useState(false);
  const [geoCodeDisabled, setDisableGeoCode] = useState(false);

  const { loading, error, data, refetch } = useQuery<{
    getSearchSuggestions: ISearchSuggestions;
  }>(GET_SEARCH_SUGGESTIONS, {
    variables: { input: "" },
  });

  // Debounced fetch address suggestions function to prevent so many requests
  const fetchAddressSuggestions = React.useCallback(
    debounce((inputText: string) => {
      refetch({ input: inputText });
    }, 250),
    []
  );

  let geo: any;

  useEffect(() => {
    return () => {
      // Cancel the debouce after component has been unmounted... doesn't cancel the refetch though
      fetchAddressSuggestions.cancel();
      // Cleanup to prevent memory leak error
      navigator.geolocation.clearWatch(geo);
    };
  }, []);

  useEffect(() => {
    setInputValue(initialState);
  }, [initialState]);

  useEffect(() => {
    if (data && !loading) {
      const { predictions } = data?.getSearchSuggestions
        .googlePredictions as any;
      const { restaurants } = data?.getSearchSuggestions as any;
      if (displaySearchFor.google && predictions)
        setOptions(
          predictions.length > 3 ? predictions.slice(0, 3) : predictions
        );
      if (displaySearchFor.restaurants && restaurants && restaurants.length)
        setRestaurants(restaurants.filter(onlyUnique));
      if (!predictions && !restaurants) resetOptions();
    }
  }, [data, loading]);

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  // Handlers
  const inputOnChange = ({ target: { value } }) => {
    // 2. set controlled input value
    setInputValue(value);
    onKey ? onKey(value) : null;
    // 3. make an network request for suggestions
    return fetchAddressSuggestions(value);
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      // Prevent Downshift's default 'Enter' behavior.
      // event.nativeEvent.preventDownshiftDefault = true;
      if (options.length > 0) {
        onSearch(options[0].description);
      } else if (_restaurants.length > 0) {
        // Not sure why I have to assert that this object wont be undefined...
        onSelectRestaurant!(_restaurants[0]);
      } else {
        alert("No results found. Try adjusting your search");
      }
      event.preventDefault();
    }
  };

  const onSearch = (l: any) => {
    const locactionObj = {
      ...(l.longitude && {
        longitude: Number(l.longitude).toFixed(5),
      }),
      ...(l.latitude && {
        latitude: Number(l.latitude).toFixed(5),
      }),
      ...(!l.longitude && !l.latitude ? { location: l } : null),
    };
    history.push({
      pathname: "/menus",
      search: stringify(locactionObj),
    });
    // SET LOCATION PREFERENCE INTO LOCAL STORAGE
    if (l)
      LocalStorageHelper.setItem(
        "FOODNOME_LOCATION_PREFERENCE",
        JSON.stringify(locactionObj)
      );
  };

  const _onSelect = (d: string) => {
    setInputValue(d);
    onSelect(d);
    forceBlur();
  };
  const resetOptions = () => setOptions([]);

  const forceBlur = () => {
    // Force blur. Useful for mobile android since there is no "done" button on keyboard
    const element = document.getElementById(
      "restaurant-list-autocomplete-input"
    );

    element?.blur();
  };

  return (
    <Downshift
      onSelect={_onSelect}
      inputValue={inputValue}
      itemToString={(d) => (d ? d.text : null)}
      onOuterClick={forceBlur}
    >
      {({
        selectedItem,
        getInputProps,
        getItemProps,
        highlightedIndex,
        getMenuProps,
        isOpen,
        openMenu,
        closeMenu,
      }) => {
        return (
          <div
            style={{
              position: "relative",
              width: "100%",

              ...containerStyle,
            }}
          >
            <TextInput
              value={inputValue}
              autoFocus={autoFocus}
              size={size}
              openMenu={() => {
                if (navigator.geolocation && useGeoLocation) {
                  openMenu();
                  setGeoCodeLoading(true);
                  geo = navigator.geolocation.watchPosition(
                    (d) => {
                      setGeoCodeLoading(false);
                      setLongitude(d.coords.longitude);
                      setLatitude(d.coords.latitude);
                    },
                    () => {
                      setGeoCodeLoading(false);
                      setDisableGeoCode(true);
                    },
                    {
                      enableHighAccuracy: false,
                      timeout: 20000,
                    }
                  );
                }
              }}
              getInputProps={getInputProps}
              onChange={inputOnChange}
              onKeyDown={onKeyDown}
              helperText={error?.message ?? ""}
              name="address"
              {...(TextFieldProps as any)}
            />
            <div
              data-testid="downshift-menu"
              {...getMenuProps()}
              style={{ zIndex: 10 }}
            >
              {((isOpen && options.length) ||
                (isOpen && _restaurants.length) ||
                (isOpen && useGeoLocation && !geoCodeDisabled)) && (
                <Paper
                  className={
                    isMobileSearchDialog
                      ? classes.isMobileSearchDialog
                      : classes.isNotMobileSearchDialog
                  }
                  square
                  data-testid="AutoCompleteContainer_suggestions"
                >
                  {loading && !data ? (
                    <div className={classes.loadingContainer}>
                      <CircularProgress data-testid="spinner" />
                    </div>
                  ) : (
                    <>
                      <AutoLocate
                        useGeoLocation={useGeoLocation}
                        geoCodeDisabled={geoCodeDisabled}
                        displaySearchFor={displaySearchFor}
                        suggestionStyle={suggestionStyle}
                        geoCodeLoading={geoCodeLoading}
                        onSelect={onSelect}
                        closeMenu={closeMenu}
                        longitude={longitude}
                        latitude={latitude}
                      />
                      <LocationList
                        options={options}
                        onSelectRestaurant={onSelectRestaurant}
                        restaurants={_restaurants}
                        highlightedIndex={highlightedIndex}
                        selectedItem={selectedItem}
                        suggestionStyle={suggestionStyle}
                        truncateSuggestion={truncateSuggestion}
                        getItemProps={getItemProps}
                      />
                      <RestaurantList
                        onSelectRestaurant={onSelectRestaurant}
                        getItemProps={getItemProps}
                        restaurants={_restaurants}
                        highlightedIndex={highlightedIndex}
                        selectedItem={selectedItem}
                        suggestionStyle={suggestionStyle}
                        truncateSuggestion={truncateSuggestion}
                        isMobileSearchDialog={isMobileSearchDialog}
                        optionsLength={options.length}
                      />
                    </>
                  )}
                </Paper>
              )}
            </div>
          </div>
        );
      }}
    </Downshift>
  );
};
