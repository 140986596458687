import { gql } from "@apollo/client";

export const ADMIN_GET_USER_ACCESS = gql`
  query adminGetUserAccess($input: ID!) {
    adminGetUserAccess(id: $input)
  }
`;

export const ADMIN_SEND_RESET_TOKEN = gql`
  query adminSendResetToken($input: ID!) {
    adminSendResetToken(id: $input)
  }
`;

export const GET_ALL_USERS = gql`
  query adminGetUsers($input: AdminGetUsersPL) {
    adminGetUsers(adminGetUsersPL: $input) {
      count
      rows {
        id
        firstName
        lastName
        email
        nomes
        address
        credits
        picture
        active
        account
        phoneNumber
        hasApp
        source
        payments {
          id
          totalAmount
        }
        role
        createdAt
        points {
          eventsAttended
          lastOrderedAt
        }
        restaurant {
          id
          name
          description
          application {
            id
            status
          }
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query adminGetUser($input: AdminGetUserPL) {
    adminGetUser(adminGetUserPL: $input) {
      id
      firstName
      lastName
      email
      nomes
      credits
      picture
      active
      account
      address
      phoneNumber
      payments {
        id
        totalAmount
        payOutId
        createdAt
        raw
      }
      transactions {
        id
        amount
        description
        createdAt
      }
      role
      createdAt
      updatedAt
      points {
        eventsAttended
      }
      events {
        id
        name
        type
        eventUser {
          id
          createdAt
          couponInfo {
            id
            code
            newUser
            deduction
          }
          notes
          orderedDishes {
            id
            name
            EventUserDish {
              id
              count
            }
          }
        }
      }
    }
  }
`;
