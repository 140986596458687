import React from "react";
import { IImage } from "src/models";
import { CoverPhoto } from "./cover-photo";

interface IProps {
  setImage: (d: IImage) => void;
  image: string;
  uploadImage: (f: File) => Promise<any>;
  coverWrapperStyle?: any;
}

export const CoverPhotoUploader: React.FC<IProps> = ({
  setImage,
  image,
  uploadImage,
  coverWrapperStyle,
}) => {
  function onUpload(file: File): Promise<any> {
    return new Promise(async (res, rej) => {
      // Make API call to cloudinary with original file
      const { data } = await uploadImage(file);

      const response = {
        publicId: data.public_id,
        small: data.eager[0].secure_url,
        medium: data.eager[0].secure_url.replace(
          "w_350,h_250,c_fill",
          "w_700,h_832,c_fill"
        ),
        large: data.eager[0].secure_url.replace(
          "w_350,h_250,c_fill",
          "w_1400,h_1663,c_fill"
        ),
        original: data.secure_url,
      };

      setImage(response);

      return res(response);
    });
  }

  return (
    <CoverPhoto
      coverWrapperStyle={coverWrapperStyle}
      src={image}
      upload={onUpload}
    />
  );
};
