import { makeStyles, useMediaQuery } from "@material-ui/core";
import React, { FC } from "react";
import { IGetMe } from "src/models";
import { PressSection } from "src/screens/home-new/sections/press/press";
import { BREAK_POINTS } from "src/styles";
import {
  About,
  Benefits,
  GettingStarted,
  StartCooking,
  SuccessStories,
  TopDesktopCook,
  TopMobileCook,
} from "./sections";

interface IProps {
  user: IGetMe;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
  },
});

export const Layout: FC<IProps> = React.memo(({ user }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  return (
    <div className={classes.container}>
      {isMobile ? (
        <TopMobileCook getMe={user} />
      ) : (
        <TopDesktopCook getMe={user} />
      )}
      <PressSection showCucumber={false} />
      <About />
      <SuccessStories />
      <Benefits />
      <GettingStarted />
      <StartCooking getMe={user} />
    </div>
  );
});
