export interface IState {
  buttonText: string;
  question1: string;
  question2: string;
  question3: string;
}

export const initialState: IState = {
  buttonText: "Next",
  question1: "",
  question2: "",
  question3: "",
};

export enum ActionTypes {
  SET_BUTTON_TEXT = "SET_BUTTON_TEXT",
  SET_QUESTION = "SET_QUESTION",
}

interface ISetButtonText {
  type: ActionTypes.SET_BUTTON_TEXT;
  payload: string;
}

interface ISetQuestion {
  type: ActionTypes.SET_QUESTION;
  name: string;
  value: string | null;
}

export type ComponentActions = ISetButtonText | ISetQuestion;

export const reducer: React.Reducer<IState, ComponentActions> = (
  state,
  action
) => {
  switch (action.type) {
    case ActionTypes.SET_BUTTON_TEXT:
      return {
        ...state,
        buttonText: action.payload,
      };
    case ActionTypes.SET_QUESTION:
      return { ...state, [action.name]: action.value };
    default:
      return state;
  }
};
