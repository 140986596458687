import { Button, Divider, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import { IRestaurant } from "src/models";
import { COLORS } from "src/styles";
import { useTopViewport } from "src/utils/hooks";
import { useTabsState } from "../tabs/context";

interface IProps {
  restaurant: IRestaurant;
  descriptionCharMax?: number;
}

export const About: React.FC<IProps> = ({
  restaurant,
  descriptionCharMax = 300,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(
    restaurant.description
      ? restaurant.description.length < descriptionCharMax
      : true
  );
  const ref = React.useRef<any>();
  const inView = useTopViewport(ref);

  const { dispatch } = useTabsState();
  useEffect(() => {
    if (inView) {
      dispatch({ type: "SET_TAB_VALUE", value: "about" });
    }
  }, [inView]);

  const description = expanded
    ? restaurant.description
    : restaurant.description.slice(0, descriptionCharMax) + "...";

  return (
    <>
      <Element name="about" />
      <section ref={ref} className={classes.container}>
        <Typography variant="h5" component="h5" className={classes.heading}>
          About
        </Typography>
        <Divider />
        <div className={classes.aboutLayout}>
          <Typography variant="body2" component="p" className={classes.hostBio}>
            {description}
            {restaurant.description &&
              restaurant.description.length > descriptionCharMax && (
                <Button
                  classes={{ root: classes.expandButton }}
                  size="small"
                  onClick={() => setExpanded((p) => !p)}
                >
                  {expanded ? "- Show Less" : "+ Read More"}
                </Button>
              )}
          </Typography>
        </div>
        <Divider />
      </section>
    </>
  );
};

const useStyles = makeStyles({
  container: {
    margin: "2rem 0",
  },
  hostBio: {
    margin: 0,
    whiteSpace: "pre-wrap",
  },
  expandButton: {
    display: "inline-block",

    color: COLORS.MEDIUM_GREEN,
    textTransform: "capitalize",
  },
  aboutLayout: {
    display: "flex",
    padding: "1rem 0",
  },
  heading: {
    fontFamily: "CustomFourBold",
    marginBottom: "1rem",
  },
});
