import { useMediaQuery } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IEvent, IGetMe } from "src/models";
import { BREAK_POINTS } from "src/styles";
import { Routes } from "./cart/routes";
import { EventInfo } from "./event-info/event-info";
import { useLayoutStyles } from "./layout.styles";

declare let window: any;

interface IProps {
  getEvent: IEvent;
  getMe: IGetMe;
  dialogView?: string;
}

export const Layout: FC<IProps> = ({ getEvent, getMe, dialogView }) => {
  const classes = useLayoutStyles();
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

  // useEffect(() => {
  //   // To show the accessory bar when selecting date
  //   if (window.cordova && window.Keyboard) {
  //     window.Keyboard.hideFormAccessoryBar(false);
  //   }
  //   return () => {
  //     // To hide the accessory bar when leaving page container
  //     if (window.cordova && window.Keyboard) {
  //       window.Keyboard.hideFormAccessoryBar(true);
  //     }
  //   };
  // }, [location]);

  useEffect(() => {
    // If user is tech savvy and tries to switch from
    // mobile view to desktop view, gets rid of the checkout
    // view that is meant for mobile view
    !dialogView && history.replace(location.pathname);
  }, [isMobile]);

  return (
    <div className={classes.container}>
      {!isMobile && <EventInfo getEvent={getEvent} getMe={getMe} />}
      <Routes isMobile={isMobile} getEvent={getEvent} getMe={getMe} />
    </div>
  );
};
