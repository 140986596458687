import {
  AppBar,
  Avatar,
  IconButton,
  Slide,
  SlideProps,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IGetMe } from "src/models";
import { BREAK_POINTS, COLORS } from "src/styles";
import { ChatActionTypes, _ChatContext } from "..";
import { ChargeDialog } from "./charge-dialog";
import { useStyles } from "./custom-chat-room-header.styles";

interface IProps {
  channel: any;
  getMe: IGetMe;
}

type TransitionProps = Omit<SlideProps, "direction">;

function TransitionDown(props: TransitionProps) {
  return (
    <Slide
      style={{ backgroundColor: COLORS.RED, color: COLORS.WHITE }}
      {...props}
      direction="up"
    />
  );
}

export const CustomChatRoomHeader: React.FC<IProps> = ({ channel, getMe }) => {
  const history = useHistory();
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  const classes = useStyles();
  const {
    dispatch,
    state: { activeChannel },
  } = useContext(_ChatContext);
  const [otherChatMembers, setOtherChatMember] = useState<any>();
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState<
    React.ComponentType<TransitionProps> | undefined
  >(undefined);

  const handleClose = () => {
    setOpen(false);
  };

  // Get other user that is NOT you
  const otherSender = Object.values(channel?.state?.members ?? {})
    .map((m: any) => m.user)
    .find((m: any) => m?.id !== getMe?.id && m?.appRole !== "ADMIN");

  useEffect(() => {
    const adminDetails = {
      role: "admin",

      name: "COOK Connect Team",
      image:
        "https://foodnome-static-assets.s3.us-west-1.amazonaws.com/images/logos/logo-transparent.png",
    };

    // const members: any = Object.values(
    //   JsonHelper.Parse(JSON.stringify(channel?.state.members), () => null)
    // );

    let otherUserDetails;

    // Get non admin users
    // onlyUsers = members.filter((member) => member.user.role === "user");

    // If onlyUsers array is 1, set data to admin data
    if (otherSender === undefined) {
      otherUserDetails = adminDetails;
    } else {
      otherUserDetails = otherSender;
    }

    // If you are a diner in an admin/foodnome team chat
    if (otherUserDetails.role === "admin") {
      setTransition(() => TransitionDown);
      setOpen(true);
    }

    setOtherChatMember(otherUserDetails);
  }, [channel, getMe]);

  return (
    <>
      <AppBar style={{ background: COLORS.WHITE, position: "inherit" }}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            id="custom-chat-header-back-btn"
            className={classes.backBtnChatHeader}
            onClick={() =>
              activeChannel
                ? dispatch({ type: ChatActionTypes.SET_ACTIVE_CHANNEL })
                : isMobile
                ? history.goBack()
                : dispatch({ type: ChatActionTypes.SET_DRAWER_STATUS })
            }
          >
            <ChevronLeft />
          </IconButton>

          {otherChatMembers?.image && <Avatar src={otherChatMembers.image} />}
          <div className={classes.infoArea}>
            <Typography
              variant="h6"
              component="p"
              className={classes.userNameText}
            >
              {otherChatMembers?.name}
            </Typography>
            {otherChatMembers !== undefined &&
              otherChatMembers?.role !== "admin" && (
                <Typography variant="caption" component="p">
                  {otherChatMembers?.online ? (
                    <span style={{ color: COLORS.GREEN }}>Online</span>
                  ) : (
                    <span style={{ color: COLORS.RED }}>Offline</span>
                  )}
                </Typography>
              )}
          </div>
          {otherChatMembers !== undefined && (
            <ChargeDialog
              getMe={getMe}
              channel={channel}
              guestId={otherChatMembers?.id}
            />
          )}

          {/* Here would be a good place to put an "info" button to view users profile or to report */}
        </Toolbar>
      </AppBar>
      {/* <StaticBanner
        color={COLORS.RED}
        textColor={COLORS.WHITE}
        message={"Messages are seen & responded to between 9-8pm M-F"}
        open={open}
        handleClose={handleClose}
      /> */}
    </>
  );
};
