import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    margin: "1rem",
    height: 370,
    display: "flex",
    flexDirection: "column",
    maxWidth: "40rem",

    borderRadius: "0.5rem",
    boxShadow: "0px 2px 8px 0px rgba(0,0,0,0.16)",
    cursor: "pointer",
  },
  datetimeText: {
    fontFamily: "CustomFour",
    color: COLORS.BLACK,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  timeIcon: {
    fontSize: "2.2rem",
    marginRight: "0.5rem",
  },
  tags: {
    textTransform: "capitalize",

    verticalAlign: "bottom",
  },
  eventTitle: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    overflow: "hidden",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
  },

  cardBody: {
    width: "100%",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
  },

  eventType: {
    textTransform: "capitalize",

    color: COLORS.PURPLE,
    fontFamily: "CustomFourBold",
  },
  viewButton: {
    marginTop: "0.5rem",

    boxShadow: "0 2px 10px 0 rgba(0,0,0,0.25)",
  },
  dateLayout: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 1rem",
    paddingBottom: "0.5rem",
  },
  timeLayout: {
    position: "absolute",
    top: 10,
    left: 10,
    display: "flex",
    flexDirection: "column",
    backgroundColor: COLORS.WHITE,
    borderRadius: "0.5rem",

    justifyContent: "center",
    alignItems: "center",

    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
  },
  redBar: {
    position: "relative",
    width: "100%",
    height: "1.2rem",
    backgroundColor: "#FF0050",
    borderRadius: "5px 5px 0 0",
  },
  imageContainer: {
    height: "25rem",
    width: "100%",

    overflow: "hidden",
  },
  image: {
    width: "inherit",
    objectFit: "cover",
  },
  soldOutWrapper: {
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.72)",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
  },
  soldOutText: {
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  typeAndTimeContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
});
