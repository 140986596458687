import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  title: {
    fontFamily: "CustomFourBold",
  },
  paper: {
    padding: "1rem",
    maxWidth: 600,
    margin: "0 auto",
    width: "100%",
  },
  fieldsContainer: {
    display: "grid",
    gridTemplateRows: "repeat(3, auto)",
    gridGap: "2rem",
  },
  input: {
    zIndex: 1,
    padding: "15px 20px",
    "&::placeholder": {
      fontWeight: 100,
    },
  },
  inputRoot: {
    "&$focused $notchedOutline": {
      borderColor: COLORS.MEDIUM_GREEN,
      borderWidth: 2,
    },
  },
  notchedOutline: {
    backgroundColor: COLORS.WHITE,
  },
  inputFocused: {},
  container: {
    height: "calc(100vh - 56px)",
    backgroundImage: `url(${require(`../assets/sliced_beets.jpg`)})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "3rem 2rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "1.5rem 1rem",
    },
  },
  formHelperTextRoot: {
    position: "absolute",
    bottom: -25,
    margin: 0,
  },
  submitButton: {
    marginTop: "2rem",
  },
  errorText: {
    margin: 0,

    color: COLORS.RED,

    fontWeight: 100,
    textAlign: "center",
  },
});
