import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  headerContainer: {
    width: "100%",
    height: 414,
    backgroundColor: COLORS.RED,
    marginBottom: 200,
  },
  headerInner: {
    display: "flex",
    width: "100%",
    height: "100%",
    paddingTop: 125,
  },
  title: {
    maxWidth: 500,
    color: COLORS.DARK_BLUE,
    textAlign: "center",
    margin: "auto",
  },
  headerLeft: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  headerRight: {
    width: "60%",
  },
  watchContainer: {
    display: "flex",
    alignItems: "center",
  },
  buttonRoot: {
    width: 150,
    textDecoration: "underline",
    textUnderlinePosition: "under",
    color: COLORS.MEDIUM_RED,

    margin: "0 20px",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      margin: "0 5px",
    },
  },
  image: {
    height: 76,
    width: 150,
  },
  image_main: {
    maxWidth: 700,
    width: "100%",
    height: 400,
    outline: "none",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: 600,
      width: "100%",
    },
  },
  videoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 10,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      paddingRight: 0,
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 10,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      paddingRight: 0,
    },
  },

  video: {
    maxWidth: 700,
    width: "100%",
    height: 400,
    outline: "none",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: 600,
      width: "100%",
    },
  },
  [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
    headerContainer: { marginBottom: 150 },
    headerRight: {
      width: "100%",
    },
    headerLeft: {
      width: "100%",
    },
    headerInner: {
      flexDirection: "column",
    },
    watchContainer: {
      display: "none",
    },
  },

  mainContent: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(24rem, 1fr))",
    alignItems: "center",
    padding: "0 4rem",
    columnGap: "7rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "0 2rem",
    },
  },
  textContainer: {
    margin: "0 auto",
    maxWidth: "48rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "3rem",
  },
  text: {
    fontFamily: "CustomFourMedium",
    textAlign: "center",
  },
});
