import { Button, Typography } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ChannelList, ChannelListMessenger } from "stream-chat-react";
import { ChatWithUs, UpcomingOrder } from ".";
import { ChatActionTypes, _ChatContext } from "../";
import { useStyles } from "./channel-list.styles";
import { DummyChatListItem } from "./dummy-chat-loader";
import { EmptyStateIndicator } from "./empty-state";
import { Header } from "./header";
import { Preview } from "./preview";

export const List = ({ getMe, activeChannel }) => {
  const filters = {
    type: "messaging",
    members: { $in: [getMe.id] },
  };
  const { dispatch } = useContext(_ChatContext);
  return (
    <>
      <Header getMe={getMe} />
      <div style={{ height: "100%", overflow: "auto" }}>
        {!activeChannel && <UpcomingOrder />}
        {/* {!activeChannel && <ChatWithUs getMe={getMe} />} */}
        <ChannelList
          sort={{ last_message_at: -1 }}
          filters={filters}
          options={{ presence: true }}
          // @ts-ignore
          EmptyStateIndicator={(props) => (
            <EmptyStateIndicator {...props} getMe={getMe} />
          )}
          // @ts-ignore
          LoadingErrorIndicator={LoadingErrorIndicator}
          LoadingIndicator={LoadingIndicator}
          Preview={(props) => (
            <Preview
              {...props}
              getMe={getMe}
              setInChatRoom={(ch) => {
                dispatch({
                  type: ChatActionTypes.SET_ACTIVE_CHANNEL,
                  payload: ch,
                });
              }}
            />
          )}
          setActiveChannelOnMount={false}
          List={ChannelListMessenger}
        />
      </div>
    </>
  );
};

const LoadingIndicator = () => (
  <>
    {[1, 2, 3, 4, 6, 7, 8].map((i) => (
      <DummyChatListItem key={i} />
    ))}
  </>
);

export const LoadingErrorIndicator = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <div className={classes.errorContainer}>
      <Warning className={classes.warning} />
      <Typography variant="body2" component="p" className={classes.msg}>
        Sorry, the chat has stopped working. Try refresh.
      </Typography>
      <Button
        onClick={() => history.push("/menus")}
        variant="contained"
        color="secondary"
        style={{ color: "white" }}
      >
        Refresh
      </Button>
    </div>
  );
};
