import { Typography, useMediaQuery } from "@material-ui/core";
import React, { FC, useContext } from "react";
import { IEvent, IGetMe } from "src/models";
import { Reviews } from "src/screens/restaurant/widgets/index";
import { BREAK_POINTS } from "src/styles";
import { OrderContext } from "../order-context";
import { CookInfo } from "./cook-info";
import { DishList } from "./dish-list";
import { Header } from "./header/header";
import { useStyles } from "./layout.styles";
import { Location } from "./location";
import { MobileOrder } from "./order-info";
import { OrderInfo } from "./order-info/order-info";

interface IProps {
  getEvent: IEvent;
  getMe?: IGetMe;
}

export const Layout: FC<IProps> = React.memo(({ getEvent, getMe }) => {
  const classes = useStyles();

  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  const {
    state: { orderedDishes, seats, dialogView },
  } = useContext(OrderContext);

  const noOrder = getEvent.type.includes("FIXED_TIME")
    ? !Boolean(seats)
    : !orderedDishes.some(
        (d) => Boolean(d.count) || !!d.options?.some((o) => o.count)
      );
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div className={classes.container}>
        {/* ============== Header ============== */}
        <Header getEvent={getEvent} getMe={getMe} dialogView={dialogView} />

        {isMobile && (
          <MobileOrder getEvent={getEvent} noOrder={noOrder} getMe={getMe} />
        )}

        {/* ============== Dishes ============== */}
        {!!getEvent.dishes?.length && (
          <div className={classes.BGcolor}>
            <div className={classes.dishesContainer}>
              <Typography
                variant="h4"
                component="h4"
                className={classes.dishesTitle}
              >
                {getEvent.type.includes("FIXED_TIME")
                  ? "View Menu Items "
                  : "Place Your Order "}
              </Typography>

              <DishList getEvent={getEvent} getMe={getMe} />
            </div>
          </div>
        )}

        <div className={classes.eventInfoContainer}>
          <Typography variant="h4" component="h4" className={classes.title}>
            Menu Info{" "}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.subTitle}
          >
            {getEvent.description}
          </Typography>
        </div>

        {/* ============== Location ============== */}
        <div className={classes.BGcolor}>
          <Location getEvent={getEvent} />
        </div>

        {/* ============== Cook Info ============== */}

        <CookInfo getEvent={getEvent} />

        {/* ============== Reviews ============== */}
        <div className={classes.BGcolor}>
          <div className={classes.eventInfoContainer}>
            <Reviews restaurant={getEvent.restaurant} />
          </div>
        </div>
      </div>
      <OrderInfo getEvent={getEvent} getMe={getMe} />
    </div>
  );
});
