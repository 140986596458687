import { useMutation } from "@apollo/client";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@material-ui/core";
import { Check, KeyboardArrowDown, Timelapse } from "@material-ui/icons";
import isEqual from "lodash/isEqual";
import moment from "moment";
import { DateRange } from "moment-range";
import React, { useEffect, useState } from "react";
import { IEvent, IUser } from "src/models";
import { COLORS } from "src/styles";
import { COOK_UPDATE_SOLD_OUT_TIME_SLOTS } from "../graphql";
import { getDisplayTime } from "../helpers";
import { timeSlotStyles as useStyles } from "./time-slot.styles";
import { UserList } from "./user-list";

interface TimeSlotProps {
  event: IEvent;
  guests: IUser[];
  timeSlot: DateRange;
  isSearchActive: boolean;
  onClickUser: (user: IUser) => void;
}

export const TimeSlot: React.FC<TimeSlotProps> = ({
  timeSlot,
  guests,
  event,
  isSearchActive,
  onClickUser,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(() => {
    const now = moment();

    if (now.isBefore(timeSlot.start)) return false;

    return guests.some((guest) => !guest.eventUser.checkedIn);
  });
  const [markSoldOutTimeSlots] = useMutation(COOK_UPDATE_SOLD_OUT_TIME_SLOTS, {
    refetchQueries: ["cookGetEvent"],
  });

  const guestsRef = React.useRef<any>(null);

  useEffect(() => {
    if (isEqual(guestsRef.current, guests)) return;

    const allOrdersComplete = guests.every(
      (guest) => guest.eventUser.checkedIn
    );
    if (allOrdersComplete) setExpanded(false);
  });

  useEffect(() => {
    guestsRef.current = guests;
  });

  const displayedTime = getDisplayTime(timeSlot.start, timeSlot.end);

  const completeTimeSlot = () =>
    guests.every((guest) => guest.eventUser.checkedIn);

  const getTitleColor = () => {
    const now = moment();
    let color = "";

    if (now.isBefore(timeSlot.start)) color = COLORS.BLACK;
    else if (timeSlot.contains(now, { excludeEnd: true }))
      color = COLORS.MEDIUM_GREEN;
    else color = COLORS.LIGHT_GREY;

    return color;
  };

  return (
    <Accordion
      disabled={isSearchActive}
      expanded={isSearchActive || expanded}
      onChange={() => setExpanded((prev) => !prev)}
      classes={{
        root: classes.expansionPanel,
        disabled: classes.disabledPanel,
      }}
      aria-disabled={isSearchActive}
      data-testid="time-slot"
    >
      <AccordionSummary
        expandIcon={
          <KeyboardArrowDown style={{ color: COLORS.MEDIUM_GREEN }} />
        }
        style={{
          fontFamily: "CustomFourBold",
          fontWeight: 500,
          color: isSearchActive ? COLORS.BLACK : getTitleColor(),
          backgroundColor: isSearchActive
            ? COLORS.WHITE
            : completeTimeSlot()
            ? "rgba(38,204,79,0.08)"
            : COLORS.WHITE,
        }}
      >
        {isSearchActive ? null : completeTimeSlot() ? (
          <Check
            style={{ marginRight: "1rem", color: COLORS.MEDIUM_GREEN }}
            data-testid="time-slot-complete"
          />
        ) : (
          <Timelapse
            style={{
              marginRight: "1rem",
              fontSize: "2rem",
              color: COLORS.LIGHT_BLUE,
            }}
            data-testid="time-slot-pending-orders"
          />
        )}
        <Typography variant="body2" component="time">
          {displayedTime}{" "}
        </Typography>
        <Typography variant="body2" component="p" style={{ margin: "0 1em" }}>
          ({guests.length})
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.panelDetail}>
        {/* It doesn't make sense to have a "Complete" button on PopUp events */}
        {!event.type.includes("FIXED_TIME") && (
          <>
            {!event.soldOutTimeSlots?.some(
              (slots) =>
                slots[0] === timeSlot.start.valueOf().toString() &&
                slots[1] === timeSlot.end.valueOf().toString()
            ) ? (
              <Button
                variant="contained"
                color="primary"
                className={classes.stopTakingOrderBtn}
                onClick={() =>
                  markSoldOutTimeSlots({
                    variables: {
                      input: {
                        id: event.id,
                        soldOutTimeSlots: event.soldOutTimeSlots?.length
                          ? [
                              ...(event.soldOutTimeSlots &&
                                event.soldOutTimeSlots),
                              [
                                timeSlot.start.valueOf().toString(),
                                timeSlot.end.valueOf().toString(),
                              ],
                            ]
                          : [
                              [
                                timeSlot.start.valueOf().toString(),
                                timeSlot.end.valueOf().toString(),
                              ],
                            ],
                      },
                    },
                  })
                }
              >
                Stop orders for this time
              </Button>
            ) : (
              <div className={classes.removeTimeSlotContainer}>
                <span className={classes.removeTimeSlotSpan}>
                  You are no longer taking orders for this time slot
                </span>
              </div>
            )}
          </>
        )}
        <UserList
          onClickUser={onClickUser}
          classes={classes}
          guests={guests}
          getEvent={event}
        />
      </AccordionDetails>
    </Accordion>
  );
};
