import { Button, ListItemIcon, Menu, MenuItem } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useState } from "react";

export interface IActions {
  show: boolean;
  icon: any;
  title: string;
  onClick: (any) => any;
}

interface ITableActionsProps {
  actions: (row: any) => IActions[];
  row: any;
}

export const TableActions: React.FC<ITableActionsProps> = ({
  actions,
  row,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        variant="text"
        color="secondary"
        onClick={handleClick}
        endIcon={Boolean(anchorEl) ? <ExpandLess /> : <ExpandMore />}
      >
        Actions
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions(row).map(
          (action) =>
            action.show && (
              <MenuItem key={action.title} onClick={action.onClick}>
                <ListItemIcon>{action.icon}</ListItemIcon>
                {action.title}
              </MenuItem>
            )
        )}
      </Menu>
    </>
  );
};
