import { makeStyles } from "@material-ui/core";

export const tableStyles = makeStyles({
  displayLocation: { color: "grey" },
  icon: { cursor: "pointer", marginLeft: "2px" },
  hidden: { visibility: "hidden" },
  form: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  textFields: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  emailIcon: {
    color: "grey",

    paddingTop: "4px",
    cursor: "pointer",
  },
  th: {
    textAlign: "left",
    padding: "0 1rem",
  },
  bold: {
    fontFamily: "CustomFourBold",
  },
});
