import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    display: "unset",
    width: "100%",
    boxShadow: "0px 10px 5px #fff, 0px -1px 5px #dedede",
  },

  checkoutBtnContainerMobile: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    zIndex: 2,
  },
  priceBadge: {
    backgroundColor: COLORS.RED,
    width: "40px",
    height: "40px",
    color: COLORS.WHITE,
    paddingTop: "6px",
    borderRadius: "50%",
    fontFamily: "CustomFourBold",
    position: "absolute",
    right: "-10px",
    top: "-10px",
  },
  cssTransitionContainer: {
    backgroundColor: COLORS.WHITE,
    zIndex: 3,
    position: "fixed",
    bottom: 0,
    width: "100%",
    boxShadow: "0px 10px 5px #fff, 0px -1px 5px #dedede",
    padding: ".5rem",
    paddingTop: 0,
    paddingBottom: "calc(.5rem + env(safe-area-inset-bottom))",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
});
