import { useQuery } from "@apollo/client";
import { Dialog } from "@material-ui/core";
import React, { FC, useState } from "react";
import { IRestaurant } from "src/models";
import { GET_RESTAURANT_GUESTS } from "./api";
import { useStyles } from "./container.styles";
import { Layout } from "./layout";

interface IProps {
  restaurant: IRestaurant;
}
export const FollowersDialog: FC<IProps> = ({ children, restaurant }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [limit, setLimit] = useState(10);
  const { data, fetchMore, loading } = useQuery(GET_RESTAURANT_GUESTS, {
    variables: {
      input: { pagePL: { limit, offset: 0 }, restaurantId: restaurant.id },
    },
  });

  const childrenWithProp = React.Children.map(children, (child: any) => {
    return React.cloneElement(child, {
      setOpen: () => setOpen(true),
      count: data?.getRestaurantGuests?.count,
      loading,
    });
  });

  const _fetchMore = () => {
    fetchMore({
      variables: {
        input: {
          pagePL: { limit: limit + 10, offset: 0 },
          restaurantId: restaurant.id,
        },
      },
    });
    setLimit(limit + 10);
  };

  return (
    <>
      {childrenWithProp}
      <Dialog
        open={open}
        classes={{ paperWidthSm: classes.paperWidthSm }}
        onClose={() => setOpen(false)}
      >
        <Layout
          guests={data?.getRestaurantGuests?.rows}
          count={data?.getRestaurantGuests?.count}
          onClose={() => setOpen(false)}
          restaurant={restaurant}
          fetchMore={_fetchMore}
        />
      </Dialog>
    </>
  );
};
