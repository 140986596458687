import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const savedEventStyles = makeStyles({
  cardContainer: {
    width: "100%",
    minWidth: 260,
    maxWidth: 300,
    textDecoration: "none",
    height: 145,
    overflow: "hidden",
    margin: 10,
    boxShadow: "0px 2px 10px #BCBCBC",
  },
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: COLORS.WHITE,
    boxShadow: "1px 2px 9px 0 rgba(39,39,39,0.2)",

    objectFit: "cover",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  textContainer: {
    width: "80%",

    opacity: 0.82,
    borderRadius: 2,
    backgroundColor: "#FFFFFF",

    padding: "2px 5px",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  eventTitle: {
    color: COLORS.BLACK,
    fontFamily: "CustomFourBold",

    fontWeight: 500,

    margin: 0,
    overflow: "hidden",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
  },
  dateAndType: {
    color: COLORS.BLACK,

    margin: 0,
  },
});
