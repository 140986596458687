import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { sharedAPI } from "src/shared-graphql";
import { GET_ME } from "src/shared-graphql/queries";

export const useCookModeAsDefault = () => {
  const { data } = useQuery(GET_ME, { errorPolicy: "ignore" });

  useEffect(() => {
    if (data && data.getMe && !!data.getMe.restaurant) {
      sharedAPI.setActiveMode(true); // where true sets current mode to cook mode
    }
  }, [data]);
};
