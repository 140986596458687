import { Dialog, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { ShareButtonsContainer } from "./share-buttons-container";

interface IProps {
  open: boolean;
  onClose: () => void;
  shareURL: string;
}

export const ShareDialog: FC<IProps> = ({ open, onClose, shareURL }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose}>
      <div className={classes.shareButtonsContainer}>
        <ShareButtonsContainer shareUrl={shareURL} />
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles({
  shareButtonsContainer: {
    padding: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
});
