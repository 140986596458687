import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { ContactForm } from "src/components";
import { BREAK_POINTS, COLORS } from "src/styles";
import { ContactCard } from "./contact-card";
// import { Partners } from "./partner-logos";

export const Layout: FC = () => {
  const classes = useStyles();
  return (
    <div>
      <div
        className={classes.salmonHero}
        style={{
          backgroundImage: `url(${require("./assets/cedar_salmon.jpg")})`,
        }}
      >
        <div className={classes.overlay} />
        <Typography
          variant="h1"
          component="h1"
          style={{
            textShadow: "-3px 2px 11px rgba(24,56,71,0.7)",
          }}
          className={classes.heroText}
        >
          Hungry for More?
        </Typography>
      </div>
      <div className={classes.gridContainer}>
        <div className={classes.colOne}>
          <Typography variant="h2" component="h2" className={classes.header}>
            Let's Chat!
          </Typography>
          <ContactForm />
        </div>
        <div className={classes.colTwo}>
          <ContactCard />
        </div>
      </div>
      {/* <Partners /> */}
    </div>
  );
};

export const useStyles = makeStyles({
  hero: {
    height: 503,
    backgroundSize: "cover",
    backgroundPosition: "25% 25%",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    position: "relative",
    overflow: "hidden",
    gridColumn: "1 / -1",
    gridRow: "1 / 4",
    zIndex: -99, // for safari
  },
  overlay: {
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.3)",
    position: "absolute",
  },
  heroText: {
    color: COLORS.WHITE,
    textAlign: "center",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    zIndex: 99,
  },
  salmonHero: {
    height: 503,
    backgroundSize: "cover",
    backgroundPosition: "0% 65% !important",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    position: "relative",
    overflow: "hidden",
    zIndex: -99, // for safari
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      height: 200,
    },
  },
  gridContainer: {
    display: "flex",
    width: "100%",
    margin: "60px 0",
    justifyContent: "space-around",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexWrap: "wrap",
    },
  },
  colOne: {
    width: 800,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 20px",
  },
  colTwo: {
    width: 400,
    display: "flex",
    justifyContent: "center",
    margin: "0 20px",
  },
  header: {
    fontFamily: "CustomFourBold, sans-serif",

    color: COLORS.DARK_BLUE,
    margin: "0 0 10px 15px",
    alignSelf: "flex-start",
    fontWeight: 500,
  },
});
