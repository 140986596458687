import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { BREAK_POINTS, COLORS } from "src/styles";

export const AboutUs = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  return (
    <section className={classes.aboutUsContainer}>
      <img
        src={require("src/screens/how-it-works/assets/spaget.svg")}
        alt="spagetti"
        className={classes.spaget}
      />
      <img
        src={require("src/screens/how-it-works/assets/thyme.svg")}
        alt="thyme"
        className={classes.thyme}
      />
      <div className={classes.tileContainer}>
        <img
          src={
            isMobile
              ? require("src/screens/how-it-works/assets/diner/M_tile.jpg")
              : require("src/screens/how-it-works/assets/diner/D_tile.jpg")
          }
          alt="tile images"
          className={classes.tileImg}
        />
      </div>
      <div className={classes.textContainer}>
        <img
          className={classes.foodnomeLogo}
          src={require("src/assets/logos/cook.svg")}
          alt="cookconnect logo"
        />
        <Typography component="h2" variant="h2" className={classes.title}>
          About us
        </Typography>
        {/* About section copy and url changed for transition to Cook Alliance, Feb 24 */}
        <Typography component="p" variant="h4" className={classes.text}>
          <span
            style={{ color: COLORS.MEDIUM_RED, fontFamily: "CustomFourBold" }}
          >
            COOK Connect{" "}
          </span>
          is the first legal marketplace for home cooked food operated by a
          nonprofit organization in the US. Our all-in-one ordering platform and
          simple management system have been specifically designed to turn
          culinary entrepreneurship dreams into a successful business — from the
          comfort of a permitted home kitchen! It offers diners the opportunity
          to support local family businesses and discover food as diverse as the
          communities we're a part of! As a nonprofit, proceeds from the
          platform are reinvested into supporting chefs and the home cooking
          movement through programs, training, and small business support.
        </Typography>
        <Typography component="p" variant="h4" className={classes.text}>
          COOK Connect is operated by COOK Alliance, the nonprofit has been
          working with legislators since 2015 to legalize Microenterprise Home
          Kitchen Operations (MEHKOs) across the country. We have advocated and
          organized for years to create a more equitable, inclusive, and
          human-centered food economy. Our work now includes education,
          permitting assistance, and mentorship for home-based culinary
          entrepreneurs. Join us as we work to empower food entrepreneurship,
          build community, and redefine our relationship to our food!
          {"  "}
          <Link
            to={{ pathname: "https://www.cookalliance.org/" }}
            className={classes.link}
            target="_blank"
          >
            Learn more about COOK Alliance
          </Link>{" "}
        </Typography>
      </div>
    </section>
  );
};

const useStyles = makeStyles({
  aboutUsContainer: {
    // Need to specify relative for the spaget and thyme
    position: "relative",
    display: "flex",
    flexDirection: "row",

    flexWrap: "wrap-reverse",
    padding: "10rem 14rem",
    justifyContent: "space-around",
    alignItems: "center",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "4rem 4rem",
    },
  },
  link: {
    color: COLORS.DARK_RED,
  },
  tileImg: {
    objectFit: "contain",
    width: "100%",
    borderRadius: "1rem",
  },
  textContainer: {
    display: "flex",
    maxWidth: "45rem",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    marginBottom: "20rem",
    margin: "5rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      margin: 0,
      marginBottom: "5rem",
    },
  },
  tileContainer: {
    width: "40%",
    maxWidth: "50rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
    },
  },
  foodnomeLogo: {
    width: "6rem",
    height: "6rem",
    margin: "0 auto",
  },
  title: {
    margin: "2rem 0",
    fontFamily: "CustomFourBold",
  },
  text: {
    fontFamily: "CustomFour",
    marginBottom: "2.5rem",
    alignItems: "left",
  },
  spaget: {
    position: "absolute",
    right: "-12rem",
    top: "10%",
    width: "43rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  thyme: {
    position: "absolute",
    right: "17rem",
    bottom: "10%",
    width: "8.4rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
});
