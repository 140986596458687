import {
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  useMediaQuery,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-scroll";
import { BREAK_POINTS, COLORS } from "src/styles";
import { useTabsState } from "./context";

const useStyles = makeStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "transparent",
    },
  },
  scroller: {
    borderBottom: `1px solid ${COLORS.LIGHT_GREY}`,
  },
  rootTab: {
    textTransform: "capitalize",
    minWidth: 0,
    minHeight: 40,
    [`@media (min-width: ${BREAK_POINTS.tablet}em)`]: {
      minWidth: 120,
    },
  },
  tabLabel: {},
  selected: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: `${COLORS.RED} !important`,
  },
  colorSecondary: {
    backgroundColor: COLORS.RED,
  },
  labelContainer: {
    padding: "1rem 0.5rem",
  },
  textColorInherit: {
    color: COLORS.BLACK,
    opacity: 1,
  },
  tabsRoot: {
    maxWidth: 593,
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: COLORS.WHITE,
    minHeight: 40,

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginLeft: "-1rem",
      marginRight: "-1rem",
      maxWidth: "unset",
    },
  },
  backArrowFab: {
    color: COLORS.MEDIUM_GREEN,
    fontSize: "2.5rem",
  },
});

// tslint:disable-next-line:no-empty-interface
interface NavigationTabsProps {
  isFabVisible?: boolean;
}

export const NavigationTabs: React.FC<NavigationTabsProps> = ({
  isFabVisible,
}) => {
  const classes = useStyles();
  const { state, dispatch } = useTabsState();
  const history = useHistory();
  const tabs = ["overview", "about", "dishes", "reviews"];
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

  return (
    <>
      <Tabs
        style={{
          position: "-webkit-sticky", // for sticky position on safari 👋
        }}
        classes={{
          root: classes.tabsRoot,
          scroller: classes.scroller,
          indicator: classes.colorSecondary,
        }}
        value={state.value}
        TabIndicatorProps={{
          children: <div />,
        }}
      >
        {!isFabVisible && isMobile && (
          <IconButton onClick={() => history.goBack()}>
            <ChevronLeft className={classes.backArrowFab} />
          </IconButton>
        )}

        {tabs.map((label) => (
          <Tab
            {...({
              to: label,
              smooth: true,
              duration: 500,
              offset: -110,
            } as any)}
            component={Link as any}
            key={label}
            label={label}
            value={label}
            classes={{
              root: classes.rootTab,
              selected: classes.selected,
              textColorInherit: classes.textColorInherit,
            }}
            onClick={() => dispatch({ type: "SET_TAB_VALUE", value: label })}
          />
        ))}
      </Tabs>
    </>
  );
};
