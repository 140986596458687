import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";
import { IGetMe } from "src/models";
import { NoMatchComponent } from "../no-match";
import { AllergenListContainer } from "./allergen/list/container";
import { ApplicationListContainer } from "./application/list/container";
import { BannerContainer } from "./banner/container";
import { CouponListContainer } from "./coupon/list/container";
import { DishContainer } from "./dish/list/container";
import { EventListContainer } from "./event/list/container";
import { KpiContainer } from "./kpi/container";
import { MarketingContainer } from "./marketing/container";
import { UserDetailContainer } from "./member/detail/container";
import { MemberListContainer } from "./member/list/container";
import { NavbarContainer } from "./navbar/container";
import { ReferralContainer } from "./referral/list/container";
import { RestaurantDetailContainer } from "./restaurant/detail/container";
import { RestaurantListContainer } from "./restaurant/list/container";
import { RestaurantReviewListContainer } from "./review/list/container";
import { useStyles } from "./routes.styles";

interface IProps {
  data: { getMe: IGetMe; loading: boolean; error: any };
}

export const AdminRoutes: FC<IProps> = ({ data }) => {
  const classes = useStyles();
  const { getMe } = data;
  return (
    <>
      <Helmet>
        <title>{"Admin Dashboard | Foodnome"}</title>
      </Helmet>

      <div style={{ display: "flex", width: "100%" }}>
        <Route path="*" component={() => <NavbarContainer data={data} />} />
        <div className={classes.container}>
          <Switch>
            <Route exact path="/admin" component={MemberListContainer} />
            <Route
              exact
              path="/admin/members"
              component={MemberListContainer}
            />
            <Route
              exact
              path="/admin/members/:id"
              component={UserDetailContainer}
            />
            <Route
              exact
              path="/admin/restaurants"
              component={RestaurantListContainer}
            />
            <Route
              exact
              path="/admin/restaurants/:id"
              component={RestaurantDetailContainer}
            />
            <Route
              path="/admin/applications"
              component={() => <ApplicationListContainer getMe={getMe} />}
            />
            <Route path="/admin/dishes/:id" component={DishContainer} />
            <Route path="/admin/dishes" component={DishContainer} />
            <Route path="/admin/coupons" component={CouponListContainer} />
            <Route path="/admin/referrals" component={ReferralContainer} />
            <Route path="/admin/allergens" component={AllergenListContainer} />
            <Route path="/admin/events" component={EventListContainer} />
            <Route
              path="/admin/reviews"
              component={RestaurantReviewListContainer}
            />
            <Route path="/admin/kpi" component={KpiContainer} />
            <Route path="/admin/marketing" component={MarketingContainer} />
            <Route path="/admin/banner" component={BannerContainer} />
            <Route component={NoMatchComponent} />
          </Switch>
        </div>
      </div>
    </>
  );
};
