import axios from 'axios';
import { SERVER_ADDRESS } from "src/apollo";

export const fetchApiVersion = async () => {
  try {
    const response = await  axios.get(`${SERVER_ADDRESS}/ping`);
    // .then(({ data }) => setBuild(data.build));
    // console.log('Printing response data:', JSON.stringify(response.data, null, 2));
    return response.data.build;
  } catch (error) {
    // console.log('Error fetching API version:', error);
    return null;
  }
};
