import { SvgIcon } from "@material-ui/core";
import React from "react";

export const NoMenusIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 16 22.42">
    <path
      d="M16,8c0,6.63-7,13.8-7.32,14.11a.89.89,0,0,1-.68.29.92.92,0,0,1-.68-.29C7,21.82,0,14.65,0,8A8,8,0,1,1,16,8Z"
      fill="#777"
      strokeWidth="2"
      stroke="white"
      strokeLinejoin="miter"
      strokeLinecap="round"
    />
  </SvgIcon>
);
