import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    position: "relative",
    width: "100%",
    display: "grid",
    gridTemplateColumns: "60% 40%",
    height: "calc(100vh - 56px)",
    maxHeight: "calc(100vh - 56px)",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "flex",
      flexDirection: "column",
      height: "unset",
      maxHeight: "unset",
    },
  },
  contentContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: "100%",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      padding: "0 1rem",
      margin: "1rem 0",
      flexGrow: 1,
      justifyContent: "flex-start",
      paddingBottom: "calc(8rem + env(safe-area-inset-bottom))",
    },
  },
  subContentContainer: {
    display: "block",
    position: "relative",
    flexGrow: 1,
    overflowY: "scroll",
    padding: "9rem",
    paddingBottom: 0,
    height: "calc(80vh - 56px)",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginTop: "5rem",
      padding: "1rem",
      height: "100%",
      overflowY: "unset",
    },
  },

  imgWrapper: {
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    overflow: "hidden",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  image: {
    flexShrink: 0,
    objectFit: "cover",
    minHeight: "100%",
    minWidth: "100%",
  },
});
