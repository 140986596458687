import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  title: {
    margin: "1.5rem",
  },
  paper: {
    padding: "1rem",
    width: "100%",
    maxWidth: "60rem",
    margin: "5rem auto",
    minHeight: "40rem",
  },
  customBrainTreeStyles: {
    "& > div[data-braintree-id=wrapper]": {
      margin: "1rem",
    },
    "& > div .braintree-options-list > div": {
      borderColor: "#00AE86",
    },
    "& div.braintree-sheet": {
      borderColor: "#00AE86",
    },
    "& div.braintree-sheet__header": {
      borderColor: "#00AE86",
    },
    "& [data-braintree-id=choose-a-way-to-pay]": {
      fontFamily: "CustomFourBold",
      fontWeight: 500,
    },
    "& [data-braintree-id=methods-label]": {
      display: "none",
    },
    "& [data-braintree-id=toggle]": {
      color: "#00AE86",
      padding: 5,
    },
    "& [data-braintree-id=toggle] > span": {
      borderColor: "#00AE86",
    },
    "& [data-braintree-id=loading-indicator]": {
      borderColor: "#00AE86",
    },
    "& [data-braintree-id=upper-container]:before": {
      borderColor: "#00AE86",
    },
    "& [data-braintree-id=methods-edit]": {
      width: "8rem",

      color: "#00AE86",
      border: "1px solid #00AE86",
      textAlign: "center",
      borderRadius: "0.5rem",
      textDecoration: "none",
    },
  },
});
