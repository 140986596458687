import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "src/styles";

export const useStyles = makeStyles((theme) => ({
  dialogContainerPaper: {
    borderRadius: 0,
  },
  deliveryTypeContainer: {
    padding: "1rem 0",
  },
  dialogTitle: {
    textAlign: "center",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  deliveryTypeSingle: {
    textAlign: "center",
  },
  iconBtn: {
    position: "absolute",
    right: 4,
    top: 4,
    width: "32px",
    height: "32px",
    padding: 0,
    zIndex: 2,
  },
  closeBtn: { color: COLORS.MEDIUM_GREEN },
  dropSelectContainer: {
    padding: "1rem 0",
  },
  dropSelectTextContainer: {
    textAlign: "center",
    fontFamily: "CustomFourBold",

    fontWeight: 500,
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 1rem",
  },
  deliveryTextContainer: {
    padding: "1rem",
  },
  deliveryAddressText: {
    textAlign: "center",
    fontFamily: "CustomFourBold",

    fontWeight: 500,
  },
  btnLabelMobile: {
    justifyContent: "center",
    textTransform: "none",
  },
  btnLabel: {
    justifyContent: "center",
    textTransform: "none",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  btnContainedLeft: {
    boxShadow: "unset",
    borderWidth: "1px 0 1px 1px",
    borderStyle: "solid",
    borderColor: "#08B48C",
    borderRadius: "2px 0 0 2px",
    "&:hover": {
      backgroundColor: "#08B48C",
    },
  },
  btnContainedLeftActive: {
    boxShadow: "unset",
    borderWidth: "1px 0 1px 1px",
    borderStyle: "solid",
    borderColor: "#08B48C",
    backgroundColor: "#08B48C",
    color: COLORS.WHITE,
    borderRadius: "2px 0 0 2px",
    "&:hover": {
      backgroundColor: "#08B48C",
    },
  },
  btnContainedRight: {
    boxShadow: "unset",
    borderWidth: "1px 1px 1px 0",
    borderStyle: "solid",
    borderColor: "#08B48C",
    borderRadius: "0 2px 2px 0",
    "&:hover": {
      backgroundColor: "#08B48C",
    },
  },
  btnContainedRightActive: {
    boxShadow: "unset",
    borderWidth: "1px 1px 1px 0",
    borderStyle: "solid",
    borderColor: "#08B48C",
    backgroundColor: "#08B48C",
    color: COLORS.WHITE,
    borderRadius: "0 2px 2px 0",
    "&:hover": {
      backgroundColor: "#08B48C",
    },
  },
  selectedBtn: {
    cursor: "pointer",
    color: COLORS.WHITE,
  },
  nonSelectedBtn: {
    cursor: "pointer",
    color: "#08B48C",
    backgroundColor: "#EBFFFA",
  },
  editDeliveryTextIcon: {
    color: COLORS.MEDIUM_GREEN,
    marginLeft: ".5rem",
  },
  yourAddressText: {
    marginTop: ".5rem",
  },
}));
