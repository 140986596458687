import { useQuery } from "@apollo/client";
import { Typography } from "@material-ui/core";
import { Button, Fab, useMediaQuery } from "@material-ui/core/";
import { Add } from "@material-ui/icons";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { FetchLoading } from "src/components";
import { EventDuplicatePL, IEvent } from "src/models";
import { GET_ME } from "src/shared-graphql/queries";
import { BREAK_POINTS, COLORS } from "src/styles";
import { StatusTypes } from "./helpers";
import { HostEventsDisplay } from "./host-events-display";
import { StatusTabs } from "./status-tabs";
import { styles as useStyles } from "./styles";

const CreateEventButton: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Button
      data-testid="create-menu-button-desktop"
      classes={{ contained: classes.createEventButton }}
      variant="contained"
      onClick={() => history.push(`/c/menus/create`)}
      startIcon={<Add style={{ fontSize: "2rem", color: COLORS.WHITE }} />}
    >
      Create Menu
    </Button>
  );
};

interface IProps {
  activeStatus: StatusTypes;
  deleteEvent: (id: string) => Promise<any>;
  duplicateEvent: (eventDuplicatePL: EventDuplicatePL) => Promise<any>;
  events: IEvent[];
  containerRef: any;
  isFetchInflight: boolean;
}

export const Layout: FC<IProps> = ({
  deleteEvent,
  duplicateEvent,
  containerRef,
  activeStatus,
  events,
  isFetchInflight,
}) => {
  const { data, loading } = useQuery(GET_ME);
  const classes = useStyles();

  const { getMe } = data;
  const history = useHistory();

  const isDesktop = useMediaQuery(`(min-width: ${BREAK_POINTS.tablet}em)`);

  return (
    <div className={classes.container}>
      <div className={classes.header} style={{ flexDirection: "column" }}>
        <div className={classes.headerLayout}>
          <Typography
            variant="h2"
            component="h1"
            className={classes.mainHeading}
          >
            Menus
          </Typography>
          <div className={classes.headerContent}>
            <div className={classes.headerActionItem}>
              {!isDesktop ? (
                <Fab
                  data-testid="create-menu-button-mobile"
                  className={classes.createEventButtonMobile}
                  onClick={() => history.push(`/c/menus/create`)}
                >
                  <Add style={{ color: COLORS.WHITE }} />
                </Fab>
              ) : (
                <CreateEventButton />
              )}
            </div>
          </div>
        </div>
        <StatusTabs activeStatus={activeStatus} />
      </div>

      <FetchLoading appear={isFetchInflight} />

      <ul
        ref={containerRef}
        data-testid="cooks-event-list"
        className={classes.eventsList}
      >
        <HostEventsDisplay
          events={events}
          activeStatus={activeStatus}
          deleteEvent={deleteEvent}
          duplicateEvent={duplicateEvent}
        />
      </ul>
    </div>
  );
};
