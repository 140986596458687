import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { sharedAPI } from "src/shared-graphql";
import { Layout } from "./layout";

export const MissionContainer: FC = () => {
  const _description =
    "Come see how COOK Connect is working to create a more diverse, \
    connected, and inclusive food economy—a food system that serves everyone.";
  useEffect(() => {
    sharedAPI.setMobileHeaderTitle({ title: "Our Mission" });
    return () => {
      sharedAPI.setMobileHeaderTitle({ title: "" });
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>The Homemade Food Revolution | COOK Connect</title>
        <meta name="description" content={_description} />
      </Helmet>
      <Layout />
    </>
  );
};
