import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#f7cbca",
    position: "relative",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    maxWidth: "140rem",
    margin: "9rem auto",
    marginBottom: "6rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      margin: "5rem auto",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginBottom: "2rem",
    },
  },
  squiggleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
  },
  squiggle: {
    width: "60%",
    minWidth: "12rem",
    maxWidth: "18rem",
  },
  title: {
    fontFamily: "CustomFourBold",
    textAlign: "center",
  },
  mainContent: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(35rem, 1fr))",

    padding: "0 4rem",
    columnGap: "7rem",

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "0 2rem",
      display: "flex",
      flexDirection: "column-reverse",
    },
  },
  textContainer: {
    margin: "0 auto",
    maxWidth: "48rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "3rem",
  },
  text: {
    fontFamily: "CustomFourMedium",
  },
  bold: {
    textDecoration: "none",
    fontFamily: "CustomFourBold",
    color: COLORS.RED,
  },
  image: {
    width: "100%",
  },

  imageContainer: {
    position: "relative",
    width: "100%",
    maxWidth: "48rem",
    margin: "auto",
  },
  link: {
    marginTop: "3rem",
    textDecoration: "none",
  },
  button: {
    padding: "2rem 3rem",
    width: "60%",
  },
  iconRoot: {
    width: 100,
    height: 100,
    borderRadius: "50%",
    color: COLORS.RED,
    "& :first-child": {
      r: 12,
    },
  },
  iconText: {
    fontWeight: 100,
  },
  stepContentRoot: {
    marginLeft: 50,
    height: 120,
    borderLeft: `2px dashed ${COLORS.RED}`,
    paddingLeft: 74,
    paddingRight: 8,
    marginTop: 0,
  },
  stepText: {
    transform: `translateY(-80px)`,

    fontWeight: 100,

    maxWidth: 447,
  },
  stepLabel: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    ...theme.typography.h4,
    lineHeight: 0,

    color: COLORS.BLACK,
  },
  iconContainer: {
    paddingRight: 25,
  },
  lastLine: {
    borderLeft: "none",
  },
  stepLabelRoot: {
    alignItems: "flex-start",
  },
  stepperRoot: {
    backgroundColor: "unset",
  },
  mobileStepContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  mobileStep: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  mobileStepIndexContainer: {
    backgroundColor: COLORS.DARK_RED,
    width: "5rem",
    height: "5rem",
    borderRadius: "50%",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1.5rem",
  },
  mobileStepIndex: {
    color: COLORS.WHITE,
    fontFamily: "CustomFour",
    textAlign: "center",
  },
  mobileStepTitle: {
    textAlign: "center",
    marginBottom: "1.5rem",
  },
  mobileStepText: {
    textAlign: "center",
    fontFamily: "CustomFour",
    marginBottom: "3rem",
  },
  desktopStepIndexContainer: {
    backgroundColor: COLORS.DARK_RED,
    width: "7.5rem",
    minWidth: "7.5rem",
    height: "7.5rem",
    minHeight: "7.5rem",
    borderRadius: "50%",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1.5rem",
  },
  desktopStepIndex: {
    color: COLORS.WHITE,
    fontFamily: "CustomFour",
    textAlign: "center",
  },
  desktopTextContainer: {
    maxWidth: "45rem",
    marginLeft: "3rem",
  },
  desktopStepContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  desktopStepTitle: {
    marginBottom: "2rem",
  },
  desktopStepText: {
    fontFamily: "CustomFour",
  },
}));
