import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles/colors";

export const layoutStyles = makeStyles({
  layout: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  },
  title: {
    fontFamily: "CustomFourBold",
  },
  container: {
    width: "100%",
    borderRadius: "20px",
    backgroundColor: COLORS.WHITE,
    minHeight: "600px",
    boxShadow: "3px 4px 19px 0 rgba(31,64,80,0.28)",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  moreBtn: {
    margin: "20px auto",
    borderRadius: "4px",
    height: "30px",
    width: "96%",
    fontFamily: "CustomFourBold",
    backgroundColor: COLORS.MEDIUM_GREEN,
    color: "white",
    cursor: "pointer",
    border: "none",
  },
});
