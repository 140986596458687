import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { FC, useContext, useState } from "react";
import { IEvent, IEventUser } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { eventTypeMap } from "src/utils/helpers";
import { GET_ME } from "../login/api";
import { DINER_EDIT_ORDER, DINER_UPDATE_ORDER } from "./api";
import { SelectDropIn } from "./detail/order-info";
import { useStyles } from "./edit-time-dialog.styles";
import { OrderContext } from "./order-context";

interface IProps {
  getEvent: IEvent;
  order: IEventUser;
}

export const EditTimeDialog: FC<IProps> = ({ getEvent, order }) => {
  const [editEventUser] = useMutation(DINER_EDIT_ORDER, {
    refetchQueries: ["getEvent"],
  });
  const [updateOrder] = useMutation(DINER_UPDATE_ORDER, {
    refetchQueries: ["getEvent"],
  });

  const editOrder = order?.eventId ? editEventUser : updateOrder;
  const [open, setOpen] = useState(false);
  const { data } = useQuery(GET_ME, { errorPolicy: "ignore" });
  const classes = useStyles();
  const {
    state: { arrivalTime, dineOption },
  } = useContext(OrderContext);

  if (getEvent.type.includes("FIXED_TIME") || order.checkedIn) return null;
  return (
    <>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        color="secondary"
      >
        Edit
      </Button>
      <Dialog
        disableBackdropClick
        open={open}
        classes={{ paperWidthSm: classes.paperWidthSm }}
        fullWidth
        data-testid="edit-order-dialog"
        onClose={() => setOpen(false)}
      >
        <Typography variant="h2" component="h2">
          Edit Order Info:
        </Typography>
        <DialogContent className={classes.dialogContent}>
          <SelectDropIn
            getEvent={getEvent}
            getMe={data?.getMe}
            key={"edit-direct"}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setOpen(false)}
            className={classes.cancelBtn}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.changeMyOrder}
            onClick={() => {
              if (!arrivalTime || arrivalTime === "0") {
                return sharedAPI.setSnackbarMsg({
                  type: "error",
                  msg: "Please select a time you want your food",
                });
              }
              if (
                window &&
                window.confirm &&
                window.confirm(
                  `Are you sure you want to change your current order of ${
                    eventTypeMap[order?.dineOption as string]
                  } on ${moment(Number(order?.arrivalTime)).format(
                    `MMM Do hh:mma`
                  )} to ${eventTypeMap[dineOption as string]} on ${moment(
                    Number(arrivalTime)
                  ).format(`MMM Do hh:mma`)}?`
                )
              ) {
                return editOrder({
                  variables: {
                    input: {
                      id: order.id,
                      dineOption,
                      arrivalTime,
                    },
                  },
                })
                  .then(() => {
                    sharedAPI.setSnackbarMsg({
                      type: "success",
                      msg: "Saved!",
                    });
                    setOpen(false);
                  })
                  .catch((e) =>
                    sharedAPI.setSnackbarMsg({
                      type: "error",
                      msg: e.message.replace(/^graphql error: /i, ""),
                    })
                  );
              }
            }}
          >
            Change My Order
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
