import { useQuery } from "@apollo/client";
import { Lottie } from "@crello/react-lottie";
import { Dialog, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { GET_GLOBAL_LOADING_STATE } from "src/shared-graphql/queries";

export const GlobalLoader: React.FC = () => {
  const { data } = useQuery(GET_GLOBAL_LOADING_STATE);
  const classes = useStyles();
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={!!data?.getGlobalLoadingState}
      fullScreen
      aria-describedby="loading-dialog-title"
    >
      <Typography
        variant="h1"
        component="h1"
        className="hidden-label"
        id="loading-dialog-title"
      >
        Loading...
      </Typography>
      <Lottie
        style={{ width: "200px" }}
        config={{
          loop: true,
          autoplay: true,
          animationData: require("src/assets/lottie/order.json"),
        }}
      />
    </Dialog>
  );
};

const useStyles = makeStyles({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: `rgba(255, 255, 255, 0.8)`,
  },
});
