import { makeStyles } from "@material-ui/core/styles";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useContentStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "0 auto",

    padding: "0 1rem",
    paddingBottom: "3rem",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    marginBottom: "1.5rem",
  },
  content: {
    color: "#292727",
  },
  list: {
    // paddingLeft: "2rem",
  },
  listItem: {
    listStyle: "initial",
    marginBottom: "1.5rem",
    paddingLeft: "1.5rem",

    "&::marker": {
      content: "'•'",
      fontSize: "4rem",
      color: COLORS.RED,
      [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
        fontSize: "2rem",
      },
    },
  },

  listItemRed: { color: COLORS.RED },
  btnContainer: {
    width: "100%",
    textAlign: "left",
    padding: "1em 0",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      textAlign: "center",
    },
  },
  btn: {
    color: COLORS.WHITE,
    width: 124,
    height: 54,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  diabledBtn: {
    cursor: "not-allowed !important",
    backgroundColor: `${COLORS.LIGHT_GREEN} !important`,
  },
  textArea: {
    backgroundColor: COLORS.WHITE,
    width: "100%",
    borderRadius: 5,

    marginTop: "1rem",
  },
  notchedOutline: {
    borderColor: "#B0B0B0",
  },
  link: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.MEDIUM_GREEN,
    textDecoration: "none",
  },
  boldText: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.BLACK,
  },
  text: {
    fontWeight: 100,
    fontFamily: "CustomFour",
    color: COLORS.BLACK,
    marginBottom: "1.5rem",
  },
  flexCenter: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignSelf: "center",
    textAlign: "center",
  },

  actionContainer: {
    marginBottom: "2rem",
  },
  redUnderline: {
    width: "50%",
    height: 1,
    backgroundColor: COLORS.RED,
    border: `2px solid ${COLORS.RED}`,
    marginBottom: "2.5rem",
  },

  questionsContainer: {
    padding: "3rem",
    backgroundColor: "#FFFBFC",
    boxShadow: "0 0 14px 0 rgba(0,0,0,0.31)",
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "2rem",
    },
  },
  question: {
    fontFamily: "CustomFourBold",
    paddingBottom: "0.5rem",
  },
}));
