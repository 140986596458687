import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { ActionDialog } from "src/components";
import { IGetMe, IRestaurant } from "src/models";
import { COLORS } from "src/styles";
import {
  DINER_GET_RESTAURANT_GUEST,
  DINER_UPSERT_RESTAURANT_GUEST,
} from "./graphql";

export interface SaveButtonProps {
  restaurant: IRestaurant;

  currentUser: IGetMe | null | undefined;
}

export const FollowButton: React.FC<SaveButtonProps> = ({
  restaurant,

  currentUser,
}) => {
  const [getRestaurantGuest, d] = useLazyQuery(DINER_GET_RESTAURANT_GUEST);
  const [upsertRestaurantGuest] = useMutation(DINER_UPSERT_RESTAURANT_GUEST, {
    refetchQueries: ["getRestaurantGuests"],
  });
  const classes = useStyles();

  useEffect(() => {
    if (currentUser) {
      getRestaurantGuest({ variables: { input: restaurant.id } });
    }
  }, []);
  // successfully fire submit handler or ask the user to sign up / log in
  // const submitHandler = bindSubmitHandler(currentUser)({
  //   openLoginDialog,
  //   openVerifyDialog,
  //   handleSubmit: () =>
  //     upsertRestaurantGuest({
  //       variables: {
  //         input: {
  //           restaurantId: restaurant.id,
  //         },
  //       },
  //     }),
  // });

  const submitHandler = () =>
    upsertRestaurantGuest({
      variables: {
        input: {
          restaurantId: restaurant.id,
        },
      },
    });

  return (
    <ActionDialog getMe={currentUser}>
      <Button
        size="small"
        color="secondary"
        variant={
          d?.data?.dinerGetRestaurantUser?.isFollowing ? "text" : "contained"
        }
        fullWidth
        className={classes.btn}
        disabled={d.loading}
        onClick={submitHandler}
        style={{
          color: d?.data?.dinerGetRestaurantUser?.isFollowing
            ? COLORS.MEDIUM_GREEN
            : COLORS.WHITE,
        }}
      >
        {d?.data?.dinerGetRestaurantUser?.isFollowing ? "Unfollow" : "Follow"}
      </Button>
    </ActionDialog>
  );
};

const useStyles = makeStyles({
  btn: {
    marginTop: "0.6rem",
  },
});
