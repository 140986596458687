import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";
import { ErrorLoadingComponent } from "src/components";
import { IPress } from "src/models";
import { useMobileHeader } from "src/utils/hooks";
import { Layout } from "./layout";

export const GET_PRESS = gql`
  query allPress {
    press @client {
      id
      date
      title
      summary
      source
      sourceLogo
      url
      imageUrl
      featured
      type
    }
  }
`;

export const PressContainer: React.FC = () => {
  useMobileHeader({
    title: "Press",
  });

  const { data = { press: [] }, loading, error } = useQuery<{
    press: IPress[];
  }>(GET_PRESS);

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }
  if (data.press.length === 0) return null;

  const featuredPress = data.press.find((p) => p.featured) || data.press[0];
  const pastPress = data.press.filter((p) => p.id !== featuredPress.id);

  return <Layout featuredPress={featuredPress} pastPress={pastPress} />;
};
