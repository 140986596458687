import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const layoutStyles = makeStyles({
  container: {
    padding: 12,
  },
  guestContainer: {
    display: "flex",
  },
  guestInfoContainer: {
    marginLeft: 6,
  },
  guestName: { margin: 0, fontFamily: "CustomFour" },
  actions: {
    justifyContent: "space-between",
  },
  shareBtnIcon: { cursor: "pointer", marginLeft: 4 },
  shareContainer: {
    display: "flex",
    alignItems: "center",

    paddingTop: 4,
  },
  avatar: {
    width: 54,
    height: 54,
  },
  title: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    padding: 10,
  },
  text: {
    marginTop: 6,
  },
  btn: {
    color: COLORS.WHITE,
  },
  paperWidthSm: {
    minWidth: "300px !important",
  },
});
