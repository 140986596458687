import React, { FC, useState } from "react";
import { ResponsiveDialog } from "src/components";
import { IEvent, ReservationActionTypes } from "src/models";
import { CancelDialog, ComplaintForm } from ".";
import { useStyles } from "./layout.styles";
import { OrderDetailsCard } from "./order/order-details";

interface IProps {
  handleAction: (action: { type: ReservationActionTypes; payload: any }) => any;
  event: IEvent;
}

export const Layout: FC<IProps> = ({ handleAction, event }) => {
  const classes = useStyles();
  // hooks
  const [open, setDialogStatus] = useState(false);
  const [targetEvent, setEvent] = useState<IEvent>({} as any);
  const [dialogType, setDialogType] = useState("");

  // handlers
  const handleOpenDialog = (type, myEvent) => {
    setEvent(myEvent);
    setDialogType(type);
    setDialogStatus(true);
  };

  return (
    <>
      <OrderDetailsCard handleOpenDialog={handleOpenDialog} event={event} />
      <ResponsiveDialog
        data-testid={`dialog-${dialogType.toLowerCase()}`}
        scroll="body"
        fullWidth
        disableBackdropClick
        open={open}
        onClose={() => setDialogStatus(false)}
        PaperProps={{ classes: { root: classes.dialogRoot } }}
        classes={{
          paper: classes.dialogPaper,
          paperScrollBody: classes.paperScrollBody,
        }}
      >
        <>
          {dialogType === "Complaint" ? (
            <ComplaintForm
              targetEvent={targetEvent}
              handleAction={handleAction}
              handleCloseDialog={setDialogStatus}
            />
          ) : (
            <CancelDialog
              targetEvent={targetEvent}
              handleAction={handleAction}
              handleCloseDialog={() => setDialogStatus(false)}
            />
          )}
        </>
      </ResponsiveDialog>
    </>
  );
};
