import { Avatar, Button, Chip, Paper, Typography } from "@material-ui/core";
import {
  CalendarToday,
  LocationOn,
  Restaurant,
  Star,
} from "@material-ui/icons";
import classNames from "classnames";
import moment from "moment";
import React, { FC } from "react";
import { useCreateChannel } from "src/components/chat2";
import { IGetMe, IRestaurant } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { eventTypeMap } from "src/utils/helpers";
import { useStyles } from "./restaurant-card-mobile.styles";

interface IProps {
  restaurant: IRestaurant;
  getMe: IGetMe;
}
export const RestaurantCardMobile: FC<IProps> = React.memo(
  ({ restaurant, getMe }) => {
    const classes = useStyles();
    const { createChannel } = useCreateChannel();

    const avatarProps = Object.assign(
      { alt: restaurant.host.firstName },
      restaurant.host.picture
        ? { src: restaurant.host.picture }
        : {
            children: `${restaurant.host.firstName[0]}${restaurant.host.lastName[0]}`,
          }
    ) as any;

    const availTypes = [
      ...(new Set(restaurant.events.map((event) => event.type).flat()) as any),
    ];

    // If user has dish images
    const image = restaurant.dishes[0]?.imagesGQL[0].small;

    return (
      <Paper
        className={classes.container}
        component="li"
        onClick={(e) => {
          e.stopPropagation();
          sharedAPI.setDialogRestaurantName(restaurant?.name);
        }}
      >
        <section className={classNames(classes.topContainer, classes.flexRow)}>
          <div className={classes.flexColumn} style={{ flexGrow: 1 }}>
            {/* Name/Title */}
            <section
              className={classes.flexRow}
              style={{ marginBottom: "1rem" }}
            >
              <Typography
                variant="subtitle1"
                component="h3"
                className={classes.title}
              >
                {restaurant.name}
              </Typography>
            </section>

            {/* Avatar and Info */}
            <section className={classes.flexRow}>
              <div className={classes.flexColumn}>
                <Avatar
                  className={
                    restaurant.fromCSV ? classes.avatarCSV : classes.avatar
                  }
                  {...avatarProps}
                />
              </div>
              <div className={classes.flexColumn}>
                {restaurant.overallReview && (
                  <div className={classes.flexRow}>
                    <Star style={{ fontSize: "2rem", color: COLORS.YELLOW }} />
                    <Typography variant="body2" component="p">
                      <span className={classes.reviewStars}>
                        {`${restaurant.overallReview.stars}/5`}
                      </span>
                      ({restaurant.overallReview.count})
                    </Typography>
                  </div>
                )}

                <div
                  className={classNames(
                    classes.flexRow,
                    classes.locationContainer
                  )}
                >
                  {restaurant.tags && restaurant.tags.length > 0 && (
                    <>
                      <Restaurant className={classes.icon} />
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.tags}
                      >
                        {restaurant.tags.join(", ")}
                      </Typography>
                    </>
                  )}
                </div>
                <div className={classes.flexRow}>
                  <LocationOn className={classes.icon} />
                  <Typography variant="body2" component="p">
                    {restaurant.city} {restaurant.state}
                  </Typography>
                </div>
              </div>
            </section>
          </div>

          <section
            className={classes.flexColumn}
            style={{ position: "relative" }}
          >
            {image && (
              <img
                className={classes.image}
                src={image}
                alt="foodnome dish image"
              />
            )}
          </section>
        </section>

        <section className={classes.flexRow} style={{ marginLeft: "1rem" }}>
          <CalendarToday className={classes.icon} />
          <Typography
            variant="h6"
            component="h6"
            className={classes.typesContainer}
          >
            {availTypes.length ? (
              <>
                Avail. for{" "}
                {availTypes
                  .map((type) => (
                    <span
                      style={{
                        color: "#5708A8",
                        fontFamily: "CustomFourBold",
                      }}
                    >
                      {eventTypeMap[type]}
                    </span>
                  ))
                  .reduce(
                    (acc: any, x: any) =>
                      acc === null ? (
                        x
                      ) : (
                        <>
                          {acc} & {x}
                        </>
                      ),
                    null
                  )}
                :
              </>
            ) : (
              "No avail. menus"
            )}
          </Typography>
        </section>
        <section className={classes.chipContainer}>
          {restaurant?.events?.slice(0, 3).map((e) => (
            <Chip
              classes={{ root: classes.chipRoot }}
              key={e.id}
              color="secondary"
              label={moment(Number(e.startTime)).format("dddd")}
              onClick={(event) => {
                event.stopPropagation();
                sharedAPI.setDialogEventId(e.id);
              }}
            />
          ))}
          {restaurant?.events.length > 3 && (
            <Button
              variant="text"
              disableFocusRipple
              disableRipple
              className={classes.moreButton}
            >
              + more
            </Button>
          )}
          {/* If restaurants have not menus up, request now button */}
          {availTypes.length === 0 && !restaurant.fromCSV && (
            <Button
              variant="outlined"
              color="secondary"
              className={classes.requestNowBtn}
              fullWidth
              onClick={(e) => {
                e.stopPropagation();

                !getMe
                  ? sharedAPI.setUserSignInDialog(true)
                  : createChannel(restaurant.host.id);
              }}
            >
              Request Now
            </Button>
          )}
          {restaurant.fromCSV && restaurant.externalLink && (
          <Button
            variant="outlined"
            color="secondary"
            className={classes.requestNowBtn}
            onClick={(e) => {
              e.stopPropagation();
              window.open(restaurant.externalLink, "_blank");
            }}
          >
            External Site
          </Button>
        )}
      </section>
      </Paper>
    );
  }
);
