import { useMutation, useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { StreamApp } from "react-activity-feed";
import { useHistory } from "react-router-dom";
import { SERVER_ADDRESS } from "src/apollo";
import { chatApi } from "src/index";
import { INotification, NotificationStatus } from "src/models";
import { getEnv } from "src/utils/helpers";
import {
  USER_GET_NOTIFICATIONS,
  USER_MARK_ALL_NOTIFICATIONS,
  USER_UPDATE_NOTIFICATION,
} from "./api";
import { Layout } from "./layout";

export const Notifications = React.memo(() => {
  const [feedToken, setFeedToken] = useState<string | null>("");
  const [fetchError, setFetchError] = useState<string>("");
  const history = useHistory();
  const env = getEnv();
  const { data, loading, error, refetch } = useQuery<{
    userGetNotifications: { count: number; rows: INotification[] };
  }>(USER_GET_NOTIFICATIONS, {
    pollInterval: 1 * 60 * 1000, // every 1 min,
    variables: { input: { pagePL: { limit: 20, offset: 0 } } },
  });

  const [markAllNotifications] = useMutation(USER_MARK_ALL_NOTIFICATIONS, {
    refetchQueries: ["userGetNotificationCount", "userGetNotifications"],
  });
  const [updateNotification] = useMutation(USER_UPDATE_NOTIFICATION);

  useEffect(() => {
    markAllNotifications({
      variables: { input: { status: NotificationStatus.UNKNOWN } },
    });
    const token = localStorage.getItem("foodnome_auth_token");
    axios
      .get(`${SERVER_ADDRESS}/stream/get-feed-token`, {
        withCredentials: true,
        ...(process.env.REACT_APP_ENV === "cordova" && {
          headers: { authorization: token ? token : "none" },
        }),
      })
      .then((d) => {
        setFeedToken(d.data.token);
      })
      .catch((err) => {
        setFeedToken(null);
        setFetchError(err);
      });
  }, []);
  useEffect(() => {
    if (error?.message?.includes("Must be logged in")) {
      history.push("/login");
    } else if (error?.message?.includes("Token does not match")) {
      history.push("/login");
    }
  }, [error]);

  if (error || fetchError) return <button onClick={refetch}>Refetch</button>;
  if (!feedToken || loading) return <CircularProgress size={34} />;

  if (!data || !data.userGetNotifications) return null;

  const content = (
    <Layout
      notifications={data.userGetNotifications.rows}
      markAllNotifications={markAllNotifications}
      updateNotification={updateNotification}
    />
  );

  // Activity stream does not work well when using spec tests
  if (env === "test") return content;

  return (
    <StreamApp apiKey={chatApi} appId="72861" token={feedToken}>
      {content}
    </StreamApp>
  );
});
