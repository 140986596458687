import { gql } from "@apollo/client";

export const GET_REVIEW = gql`
  query getRestaurantReview($input: ID!) {
    getRestaurantReview(id: $input) {
      id
      text
      quality
      hospitality
      accuracy
      restaurantId
      eventInfo {
        name
        hostName
      }
      guest {
        id
        firstName
        lastName
        picture
      }
    }
  }
`;
