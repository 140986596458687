import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  form: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  textFields: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  emailIcon: {
    color: "grey",

    paddingTop: "4px",
    cursor: "pointer",
  },
});
