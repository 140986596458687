import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { BREAK_POINTS } from "src/styles";
import { DesktopHero } from "./hero-desktop";
import { MobileHero } from "./hero-mobile";

interface HeroProps {
  onSubmit: (event: any) => void;
  queryObj: {
    location: string;
    tags: string[];
  };
  locationInputMobileRef?: any;
}

export const Hero: React.FC<HeroProps> = ({
  locationInputMobileRef,
  ...rest
}) => {
  // iPhone viewport is 768px so the way we are computing if is mobile or not is off...
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  return isMobile ? (
    <MobileHero key={JSON.stringify(rest.queryObj)} {...rest} />
  ) : (
    <DesktopHero key={JSON.stringify(rest.queryObj)} {...rest} />
  );
};
