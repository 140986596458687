import { Button, DialogContent } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { ResponsiveDialog } from "src/components";
import { IDish } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { dishAPI } from "./api";
import { getDishImages } from "./create/helpers";
import { DishForm } from "./form";
import { DishFormSubmitPL } from "./form/form";

interface CreateDishDialogProps {
  open: boolean;
  handleCloseDialog: () => void;
  onCreateDish: (dish: IDish) => void;
}

export const CreateDishDialog: React.FC<CreateDishDialogProps> = ({
  open,
  handleCloseDialog,
  onCreateDish,
}) => {
  const handleCreate = ({
    images: dishImages,
    allergens,
    ...rest
  }: DishFormSubmitPL) => {
    // set up create dish payload
    const images = getDishImages(dishImages);
    const createDishPL = Object.assign({}, rest, {
      allergens: allergens.map((_allergen) => _allergen.id),
      images,
    });
    return dishAPI
      .createDish(createDishPL)
      .then(({ data: { createDish } }) => {
        handleCloseDialog();
        return createDish;
      })
      .then((_dish: IDish) => {
        onCreateDish(_dish);
        sharedAPI.setSnackbarMsg({ type: "success", msg: "Dish Created!" });
      });
  };

  return (
    <ResponsiveDialog
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="lg"
      open={open}
      data-testid="event-dishes-create-dish-dialog"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <Button onClick={handleCloseDialog}>
          <Close style={{ fontSize: "2rem", color: COLORS.MEDIUM_GREEN }} />
        </Button>
      </div>
      <DialogContent>
        <DishForm handleSubmit={handleCreate} />
      </DialogContent>
    </ResponsiveDialog>
  );
};
