import { AppBar, IconButton, Typography } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import { stringify } from "query-string";
import React, { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { GoBack } from "../buttons";
import { styles as useStyles } from "./mobile-header.styles";

export const MobileHeader: FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const blackList = ["/c/settings", "/d/settings", "/d/passwords"];

  if (blackList.includes(location.pathname)) return null;

  return (
    <AppBar position="fixed" className={classes.container}>
      <div onClick={() => sharedAPI.setDinerProfileDrawerState(false)}>
        <GoBack />
      </div>
      <Typography variant="h3" component="h3" className={classes.title}>
        Account
      </Typography>
      <IconButton
        aria-label="Open drawer"
        onClick={() => {
          history.push({
            pathname: "/settings",
            search: stringify({
              view: "ACCOUNT",
            }),
          });
        }}
      >
        <Settings style={{ color: COLORS.MEDIUM_GREEN }} />
      </IconButton>
    </AppBar>
  );
};
