import { makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import { parse } from "query-string";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import { HybridLink } from "src/components";
import { IPress } from "src/models";
import { BREAK_POINTS, COLORS } from "src/styles";

const useStyles = makeStyles({
  link: {
    textDecoration: "none",
  },
  article: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 500,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: "unset",
    },
  },
  imageTop: {
    width: "100%",
    position: "relative",
    minHeight: 370,
    flex: 1,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      minHeight: 250,
    },
  },
  sourceLogoWrapper: {
    position: "absolute",
    bottom: 0,
    backgroundColor: `rgba(255, 255, 255, 0.9)`,
    left: 0,
    right: 0,
    padding: "1rem 5rem",
  },
  title: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    marginBottom: 0,
    paddingTop: "1rem",
    color: COLORS.BLACK,
    textTransform: "capitalize",
    overflow: "hidden",
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
  },
  date: {
    fontWeight: 100,
    color: "#626262",

    position: "relative",
    ["&::before"]: {
      content: `""`,
      position: "absolute",
      left: 0,
      top: -15,
      width: "11.5rem",
      backgroundColor: COLORS.RED,
      border: `solid 2.5px ${COLORS.RED}`,
    },
  },
  pastPressList: {
    padding: "3rem 0",
    listStyleType: "none",
    flexWrap: "wrap",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    margin: "0 auto",
    gridGap: "7rem 2rem",
    justifyContent: "space-between",
    width: "100%",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      gridTemplateColumns: "1fr",
    },
  },
  pastPressItem: {
    [`&:nth-child(even) a article`]: {
      margin: "auto",
      [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
        marginLeft: "unset",
      },
    },
    [`&:nth-child(odd) a article`]: {
      margin: "auto",
      [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
        marginRight: "unset",
      },
    },
  },
  cardBottom: {
    minHeight: "29rem",
    padding: "5rem",
    backgroundColor: COLORS.FLASH_WHITE,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      minHeight: "20rem",
    },
  },
});

export interface PastPressListProps {
  pastPress: IPress[];
}

export const PastPressList: React.FC<PastPressListProps> = ({ pastPress }) => {
  const classes = useStyles();
  const location = useLocation();
  useEffect(() => {
    if (location.search) {
      const { id } = parse(location.search) as { id?: string };
      if (id)
        scroller.scrollTo(`press-${id}`, {
          duration: 1500,
          delay: 500,
          smooth: "easeInOutCubic",
        });
    }
  }, [location.search]);

  return (
    <ol reversed className={classes.pastPressList}>
      {pastPress.map((press) => {
        const dateTime = moment(press.date);
        return (
          <li
            className={classes.pastPressItem}
            key={`${press.title}-${press.date}`}
          >
            <Element name={`press-${press.id}`}>
              <HybridLink className={classes.link} href={press.url}>
                <article className={classes.article}>
                  <div
                    style={{
                      backgroundImage: `url(${press.imageUrl})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                    }}
                    className={classes.imageTop}
                  >
                    <div className={classes.sourceLogoWrapper}>
                      <img
                        src={press.sourceLogo}
                        style={{
                          objectFit: "contain",
                          maxWidth: 200,
                          height: 41,
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.cardBottom}>
                    <Typography
                      component="time"
                      variant="subtitle1"
                      className={classes.date}
                      dateTime={dateTime.format("YYYY-MM-DD")}
                    >
                      {dateTime.format("MMMM Do, YYYY")}
                    </Typography>
                    <Typography
                      variant="h2"
                      component="h3"
                      className={classes.title}
                    >
                      {press.title}
                    </Typography>
                  </div>
                </article>
              </HybridLink>
            </Element>
          </li>
        );
      })}
    </ol>
  );
};
