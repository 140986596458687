import { Button } from "@material-ui/core";
import { Image } from "@material-ui/icons";
import React, { FC, useState } from "react";
import { useDropzone } from "react-dropzone";
import { sharedAPI } from "src/shared-graphql";
import { getFileFromAndroid } from "src/utils/android";
import { useStyles } from "./banner.styles";

interface IProps {
  src?: string;
  upload: (file: File) => Promise<any>;
}

export const RestaurantBanner: FC<IProps> = ({ src, upload }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: ".jpeg,.png,.jpg",
    disabled: loading,
  });

  function onDrop(acceptedFiles: File[]) {
    if (acceptedFiles.length) {
      const [file] = acceptedFiles;
      setLoading(true);
      upload(file).finally(() => setLoading(false));
    } else {
      sharedAPI.setSnackbarMsg({
        type: "error",
        msg:
          "File type not supported. Only .jpeg, .png, and .jpg are supported",
      });
    }
  }

  const _open = async () => {
    if ((window as any).cordova?.platformId === "android") {
      setLoading(true);
      const file = await getFileFromAndroid();
      upload(file as any).finally(() => setLoading(false));
    } else {
      return open();
    }
  };
  return (
    <>
      <div {...getRootProps()} className={classes.bannerContainer}>
        <input autoComplete={"off"} {...getInputProps()} />

        <img
          src={src}
          className={classes.overlay}
          style={{
            ...(loading || isDragActive ? {} : { filter: "blur(0px)" }),
          }}
        />

        <Button
          disabled={loading || isDragActive}
          onClick={(event) => {
            event.stopPropagation();
            _open();
          }}
          color="secondary"
          className={classes.uploadImgBtn}
          variant="contained"
        >
          <Image style={{ marginRight: ".5rem" }} /> Upload Image
        </Button>
      </div>
    </>
  );
};
