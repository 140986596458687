import {
  InputAdornment,
  makeStyles,
  MenuItem,
  Popover,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowDown, Room } from "@material-ui/icons";
import { stringify } from "query-string";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AutoCompleteContainer } from "src/components/auto-complete";
import { IGetMe } from "src/models";
import { COLORS } from "src/styles";
import { JsonHelper, LocalStorageHelper } from "src/utils/helpers";

interface IProps {
  getMe?: IGetMe;
}

function initLocation() {
  const raw = window.localStorage.getItem("FOODNOME_LOCATION_PREFERENCE");
  if (raw) {
    const res = JsonHelper.Parse(raw, () => null);
    return res.location ?? "Your Location";
  }
  return "";
}

export const LocationHeaderWidget: FC<IProps> = ({ getMe }) => {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const location = initLocation();
  const inputRef = React.useRef<any>();

  useEffect(() => {
    getMe?.address &&
      LocalStorageHelper.setItem(
        "FOODNOME_LOCATION_PREFERENCE",
        JSON.stringify({ location: getMe?.address })
      );
  }, [getMe?.address]);

  function onSelectLocation(l) {
    if (l) {
      const locationObjAsJSON = JSON.stringify({
        ...(l.longitude && {
          longitude: Number(l.longitude).toFixed(5),
        }),
        ...(l.latitude && {
          latitude: Number(l.latitude).toFixed(5),
        }),
        ...(!l.longitude && !l.latitude ? { location: l } : null),
      });

      LocalStorageHelper.setItem(
        "FOODNOME_LOCATION_PREFERENCE",
        locationObjAsJSON
      );
      history.push({
        pathname: "/menus",
        search: stringify(typeof l === "string" ? { location: l } : l),
      });
      setAnchorEl(null);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    const { address } = e.target.elements;
    if (address) {
      const addressObj = { location: address.value };
      LocalStorageHelper.setItem(
        "FOODNOME_LOCATION_PREFERENCE",
        JSON.stringify(addressObj)
      );
      history.push({
        pathname: "/menus",
        search: stringify(addressObj),
      });
      setAnchorEl(null);
    }
  }

  function openMenu(e) {
    setAnchorEl(e.currentTarget);
  }

  if (!location) return null;

  return (
    <>
      <MenuItem
        classes={{ root: classes.menuItem }}
        tabIndex={0}
        onClick={openMenu}
        button
        component={"div"}
      >
        <Room />
        <Typography
          variant="body2"
          component="address"
          style={{
            margin: "0 .25rem",
            overflow: "hidden",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            display: "inline-block",
            textTransform: "capitalize",
            maxWidth: 150,
            color: COLORS.BLACK,
          }}
        >
          {typeof location === "string" ? location : ""}
        </Typography>
        <KeyboardArrowDown
          style={{
            position: "absolute",
            top: "50%",
            transform: "translate(0, -50%)",
            right: 0,
            zIndex: 2,
            fontSize: "2.5rem",

            color: COLORS.MEDIUM_GREEN,
          }}
        />
      </MenuItem>
      <Popover
        PaperProps={{ style: { overflow: "visible" } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onEntered={() => inputRef.current.focus()}
      >
        <form
          onSubmit={handleSubmit}
          style={{
            minWidth: 300,
          }}
        >
          <Typography
            component="label"
            variant="caption"
            className="hidden-label"
            htmlFor="search-address"
          >
            Search by Location
          </Typography>
          <AutoCompleteContainer
            useGeoLocation={true}
            initialState={""}
            onSelect={onSelectLocation}
            TextFieldProps={{
              inputRef,
              variant: "outlined",
              fullWidth: true,
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Room
                      className={classes.icon}
                      style={{ color: COLORS.MEDIUM_GREEN }}
                    />
                  </InputAdornment>
                ),
                placeholder: `Try "Riverside"`,
                classes: {
                  input: classes.input,
                  root: classes.rootInputLeft,
                  notchedOutline: classes.notchedOutline,
                  inputAdornedStart: classes.inputAdornStart,
                  focused: classes.focused,
                },
                id: "search-address",
              },
            }}
          />
        </form>
      </Popover>
    </>
  );
};

const useStyles = makeStyles({
  icon: {
    color: COLORS.MEDIUM_GREEN,
    zIndex: 1,
  },
  inputAdornStart: {
    paddingLeft: 4,
  },
  notchedOutline: {},
  focused: {},
  input: {
    borderRadius: "2px 0 0 2px",
    zIndex: 1,
    padding: 10,
    "&::placeholder": {},
  },
  rootInputLeft: {
    "&$focused $notchedOutline": {
      borderColor: COLORS.MEDIUM_GREEN,
      borderWidth: 1,
    },
    "& $notchedOutline": {
      borderRadius: "2px",
      borderRight: "solid 0.5px #E0E0E0",
      backgroundColor: COLORS.WHITE,
    },
  },
  menuItem: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  menuItemSelected: {
    backgroundColor: "transparent",
  },
});
