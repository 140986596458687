import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import React from "react";

export const DateTextField: React.ForwardRefExoticComponent<
  TextFieldProps & {
    mask: string;
  }
> = React.forwardRef(({ mask, onChange, value, ...rest }, ref) => {
  // if (browserName && /safari/gi.test(browserName.toLowerCase())) {
  //   return (
  //     <InputMask
  //       onChange={onChange}
  //       value={(value as string).toUpperCase()}
  //       alwaysShowMask
  //       mask={mask}
  //     >
  //       {(inputProps) => (
  //         <TextField size="small" inputRef={ref} {...inputProps} {...rest} />
  //       )}
  //     </InputMask>
  //   );
  // }

  return (
    <TextField
      size="small"
      inputRef={ref}
      onChange={onChange}
      value={value}
      {...rest}
    />
  );
});
