import { useQuery } from "@apollo/client";
import { Dialog, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { EventDetailRoutes } from "src/screens/event-detail/routes";
import { sharedAPI } from "src/shared-graphql";
import { GET_DIALOG_EVENT_ID } from "src/shared-graphql/queries";
import { useStyles } from "./styles";

export const EventDialog = () => {
  const classes = useStyles();
  const { data } = useQuery(GET_DIALOG_EVENT_ID);

  const eventId = data?.getDialogEventId;

  return (
    <Dialog
      open={!!eventId}
      onClose={() => sharedAPI.setDialogEventId("")}
      fullWidth
      classes={{ paperWidthSm: classes.paperWidthSm }}
    >
      <IconButton
        className={classes.iconBtn}
        onClick={() => sharedAPI.setDialogEventId("")}
      >
        <Close className={classes.mdClose} />
      </IconButton>
      {eventId && <EventDetailRoutes eventId={eventId} />}
    </Dialog>
  );
};
