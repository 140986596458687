import { SvgIcon } from "@material-ui/core";
import React from "react";

export const DinerProfile = (props) => (
  <SvgIcon {...props}>
    <g id="Layer_2">
      <g>
        <g>
          <g>
            <path
              d="M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.38h-0.19c-5,0-8.97-3.79-9.08-8.69c-0.05-2.38,0.83-4.64,2.5-6.37
					c1.66-1.73,3.88-2.69,6.24-2.73h0.13c2.41,0,4.61,0.98,6.21,2.57c1.61,1.59,2.63,3.8,2.67,6.24
					C19.43,15.17,15.55,19.28,10.86,19.38z M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.38h-0.19c-5,0-8.97-3.79-9.08-8.69c-0.05-2.38,0.83-4.64,2.5-6.37
					c1.66-1.73,3.88-2.69,6.24-2.73h0.13c2.41,0,4.61,0.98,6.21,2.57c1.61,1.59,2.63,3.8,2.67,6.24
					C19.43,15.17,15.55,19.28,10.86,19.38z M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.38h-0.19c-5,0-8.97-3.79-9.08-8.69c-0.05-2.38,0.83-4.64,2.5-6.37
					c1.66-1.73,3.88-2.69,6.24-2.73h0.13c2.41,0,4.61,0.98,6.21,2.57c1.61,1.59,2.63,3.8,2.67,6.24
					C19.43,15.17,15.55,19.28,10.86,19.38z"
            />
            <path
              d="M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.35h-0.19c-2.41,0-4.67-0.88-6.36-2.5c-1.68-1.61-2.64-3.8-2.69-6.16
					C1.56,8.32,2.45,6.06,4.11,4.34c1.66-1.72,3.86-2.69,6.22-2.73h0.13c2.33,0,4.53,0.91,6.19,2.57c1.68,1.65,2.62,3.86,2.66,6.22
					C19.4,15.16,15.53,19.26,10.86,19.35z M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.35h-0.19c-2.41,0-4.67-0.88-6.36-2.5c-1.68-1.61-2.64-3.8-2.69-6.16
					C1.56,8.32,2.45,6.06,4.11,4.34c1.66-1.72,3.86-2.69,6.22-2.73h0.13c2.33,0,4.53,0.91,6.19,2.57c1.68,1.65,2.62,3.86,2.66,6.22
					C19.4,15.16,15.53,19.26,10.86,19.35z M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.35h-0.19c-2.41,0-4.67-0.88-6.36-2.5c-1.68-1.61-2.64-3.8-2.69-6.16
					C1.56,8.32,2.45,6.06,4.11,4.34c1.66-1.72,3.86-2.69,6.22-2.73h0.13c2.33,0,4.53,0.91,6.19,2.57c1.68,1.65,2.62,3.86,2.66,6.22
					C19.4,15.16,15.53,19.26,10.86,19.35z M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.35h-0.19c-2.41,0-4.67-0.88-6.36-2.5c-1.68-1.61-2.64-3.8-2.69-6.16
					C1.56,8.32,2.45,6.06,4.11,4.34c1.66-1.72,3.86-2.69,6.22-2.73h0.13c2.33,0,4.53,0.91,6.19,2.57c1.68,1.65,2.62,3.86,2.66,6.22
					C19.4,15.16,15.53,19.26,10.86,19.35z M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.35h-0.19c-2.41,0-4.67-0.88-6.36-2.5c-1.68-1.61-2.64-3.8-2.69-6.16
					C1.56,8.32,2.45,6.06,4.11,4.34c1.66-1.72,3.86-2.69,6.22-2.73h0.13c2.33,0,4.53,0.91,6.19,2.57c1.68,1.65,2.62,3.86,2.66,6.22
					C19.4,15.16,15.53,19.26,10.86,19.35z M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.35h-0.19c-2.41,0-4.67-0.88-6.36-2.5c-1.68-1.61-2.64-3.8-2.69-6.16
					C1.56,8.32,2.45,6.06,4.11,4.34c1.66-1.72,3.86-2.69,6.22-2.73h0.13c2.33,0,4.53,0.91,6.19,2.57c1.68,1.65,2.62,3.86,2.66,6.22
					C19.4,15.16,15.53,19.26,10.86,19.35z M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.35h-0.19c-2.41,0-4.67-0.88-6.36-2.5c-1.68-1.61-2.64-3.8-2.69-6.16
					C1.56,8.32,2.45,6.06,4.11,4.34c1.66-1.72,3.86-2.69,6.22-2.73h0.13c2.33,0,4.53,0.91,6.19,2.57c1.68,1.65,2.62,3.86,2.66,6.22
					C19.4,15.16,15.53,19.26,10.86,19.35z M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.35h-0.19c-2.41,0-4.67-0.88-6.36-2.5c-1.68-1.61-2.64-3.8-2.69-6.16
					C1.56,8.32,2.45,6.06,4.11,4.34c1.66-1.72,3.86-2.69,6.22-2.73h0.13c2.33,0,4.53,0.91,6.19,2.57c1.68,1.65,2.62,3.86,2.66,6.22
					C19.4,15.16,15.53,19.26,10.86,19.35z M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.35h-0.19c-2.41,0-4.67-0.88-6.36-2.5c-1.68-1.61-2.64-3.8-2.69-6.16
					C1.56,8.32,2.45,6.06,4.11,4.34c1.66-1.72,3.86-2.69,6.22-2.73h0.13c2.33,0,4.53,0.91,6.19,2.57c1.68,1.65,2.62,3.86,2.66,6.22
					C19.4,15.16,15.53,19.26,10.86,19.35z M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.35h-0.19c-2.41,0-4.67-0.88-6.36-2.5c-1.68-1.61-2.64-3.8-2.69-6.16
					C1.56,8.32,2.45,6.06,4.11,4.34c1.66-1.72,3.86-2.69,6.22-2.73h0.13c2.33,0,4.53,0.91,6.19,2.57c1.68,1.65,2.62,3.86,2.66,6.22
					C19.4,15.16,15.53,19.26,10.86,19.35z M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.35h-0.19c-2.41,0-4.67-0.88-6.36-2.5c-1.68-1.61-2.64-3.8-2.69-6.16
					C1.56,8.32,2.45,6.06,4.11,4.34c1.66-1.72,3.86-2.69,6.22-2.73h0.13c2.33,0,4.53,0.91,6.19,2.57c1.68,1.65,2.62,3.86,2.66,6.22
					C19.4,15.16,15.53,19.26,10.86,19.35z M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.35h-0.19c-2.41,0-4.67-0.88-6.36-2.5c-1.68-1.61-2.64-3.8-2.69-6.16
					C1.56,8.32,2.45,6.06,4.11,4.34c1.66-1.72,3.86-2.69,6.22-2.73h0.13c2.33,0,4.53,0.91,6.19,2.57c1.68,1.65,2.62,3.86,2.66,6.22
					C19.4,15.16,15.53,19.26,10.86,19.35z M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.35h-0.19c-2.41,0-4.67-0.88-6.36-2.5c-1.68-1.61-2.64-3.8-2.69-6.16
					C1.56,8.32,2.45,6.06,4.11,4.34c1.66-1.72,3.86-2.69,6.22-2.73h0.13c2.33,0,4.53,0.91,6.19,2.57c1.68,1.65,2.62,3.86,2.66,6.22
					C19.4,15.16,15.53,19.26,10.86,19.35z M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.35h-0.19c-2.41,0-4.67-0.88-6.36-2.5c-1.68-1.61-2.64-3.8-2.69-6.16
					C1.56,8.32,2.45,6.06,4.11,4.34c1.66-1.72,3.86-2.69,6.22-2.73h0.13c2.33,0,4.53,0.91,6.19,2.57c1.68,1.65,2.62,3.86,2.66,6.22
					C19.4,15.16,15.53,19.26,10.86,19.35z M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.35h-0.19c-2.41,0-4.67-0.88-6.36-2.5c-1.68-1.61-2.64-3.8-2.69-6.16
					C1.56,8.32,2.45,6.06,4.11,4.34c1.66-1.72,3.86-2.69,6.22-2.73h0.13c2.33,0,4.53,0.91,6.19,2.57c1.68,1.65,2.62,3.86,2.66,6.22
					C19.4,15.16,15.53,19.26,10.86,19.35z M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.35h-0.19c-2.41,0-4.67-0.88-6.36-2.5c-1.68-1.61-2.64-3.8-2.69-6.16
					C1.56,8.32,2.45,6.06,4.11,4.34c1.66-1.72,3.86-2.69,6.22-2.73h0.13c2.33,0,4.53,0.91,6.19,2.57c1.68,1.65,2.62,3.86,2.66,6.22
					C19.4,15.16,15.53,19.26,10.86,19.35z M16.69,4.14c-1.68-1.66-3.89-2.58-6.23-2.58h-0.13C7.96,1.6,5.74,2.57,4.08,4.3
					c-1.68,1.74-2.57,4-2.52,6.39c0.06,2.37,1.02,4.58,2.72,6.2c1.69,1.62,3.96,2.51,6.39,2.51h0.19c4.7-0.09,8.59-4.21,8.5-9
					C19.32,8.03,18.37,5.81,16.69,4.14z M10.86,19.35h-0.19c-2.41,0-4.67-0.88-6.36-2.5c-1.68-1.61-2.64-3.8-2.69-6.16
					C1.56,8.32,2.45,6.06,4.11,4.34c1.66-1.72,3.86-2.69,6.22-2.73h0.13c2.33,0,4.53,0.91,6.19,2.57c1.68,1.65,2.62,3.86,2.66,6.22
					C19.4,15.16,15.53,19.26,10.86,19.35z"
            />
            <path
              d="M17.84,3.03C15.87,1.07,13.23,0,10.4,0h-0.03C4.64,0.03-0.01,4.76,0,10.56c0,2.77,1.1,5.39,3.1,7.37
					c1.98,1.97,4.6,3.06,7.36,3.06h0.05c5.75-0.03,10.42-4.74,10.41-10.51C20.91,7.64,19.82,4.99,17.84,3.03z M10.86,19.4h-0.19
					c-2.43,0-4.7-0.89-6.39-2.51c-1.7-1.62-2.66-3.83-2.72-6.2C1.51,8.3,2.4,6.04,4.08,4.3c1.66-1.73,3.88-2.7,6.25-2.74h0.13
					c2.34,0,4.55,0.92,6.23,2.58c1.68,1.67,2.63,3.89,2.67,6.26C19.45,15.19,15.56,19.31,10.86,19.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M15.98,12.38c-0.72-0.32-1.3,0.73-1.3,0.73c-0.68,1.06-1.57,1.76-2.73,2.13v-0.58c0.01-0.54,0.01-1.06-0.01-1.58
					c-0.04-1.38-0.88-2.42-2.2-2.7c-0.19-0.04-0.39-0.06-0.58-0.06c-0.09,0-0.18,0.03-0.26,0.04c-0.05,0-0.09,0-0.14,0.01
					c-0.02,0-0.04,0.01-0.06,0.01c-0.04,0.01-0.07,0.02-0.11,0.03c-0.09,0.01-0.17,0.04-0.26,0.07c-0.12,0.04-0.24,0.08-0.35,0.14
					c-0.01,0-0.02,0-0.03,0.01c-0.03,0.01-0.06,0.03-0.08,0.05c-0.08,0.03-0.15,0.08-0.21,0.13c-0.08,0.05-0.16,0.11-0.23,0.17
					c-0.07,0.06-0.14,0.13-0.21,0.2c-0.21,0.2-0.37,0.44-0.49,0.71c-0.14,0.36-0.21,0.74-0.27,1.19c-0.01,0.04-0.02,0.08-0.03,0.13
					c0,0.03-0.01,0.06-0.01,0.09c-0.08-0.12-0.17-0.24-0.23-0.37c-0.15-0.27-0.33-0.56-0.63-0.65c-0.18-0.05-0.39-0.02-0.6,0.09
					c-0.71,0.4-0.36,1.06-0.21,1.34c0.81,1.5,2.18,2.61,3.86,3.11c0.63,0.19,1.27,0.29,1.91,0.29c1.06,0,2.11-0.27,3.04-0.78
					c1.28-0.69,2.2-1.65,2.73-2.83C16.28,13.5,16.65,12.67,15.98,12.38z M9.12,11.94c0.06,0,0.11,0,0.17,0.01
					c0.58,0.07,0.95,0.42,0.98,0.92c0.05,0.62,0.04,1.25,0.03,1.92v0.02c0,0.21-0.01,0.48-0.01,0.74c0,0-1.21-0.1-2.17-0.69v-0.52
					c0-0.61,0-1.23,0.12-1.81C8.33,12.09,8.76,11.94,9.12,11.94z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M17.03,7.69c-0.05-1.35-1.28-2.5-2.68-2.5c-0.01,0-0.01,0-0.01,0c-1.44,0-2.65,1.11-2.7,2.47
					c-0.01,0.31,0.25,0.81,0.56,0.94c0.19,0.08,0.38,0.08,0.55-0.01c0.21-0.1,0.36-0.34,0.43-0.66c0.23-0.97,0.61-1.15,1.18-1.15
					C15,6.79,15.31,7.1,15.47,7.91c0.04,0.19,0.15,0.76,0.76,0.76c0.03,0,0.06-0.01,0.1-0.01C17.07,8.59,17.04,7.91,17.03,7.69z
					 M16.33,8.64h-0.1c-0.59,0-0.7-0.55-0.73-0.73c-0.17-0.83-0.48-1.14-1.14-1.15c-0.59,0-0.97,0.19-1.2,1.17
					c-0.07,0.31-0.22,0.54-0.42,0.64c-0.16,0.08-0.35,0.08-0.53,0c-0.31-0.12-0.55-0.6-0.54-0.91c0.04-1.35,1.24-2.45,2.67-2.45
					h0.01c1.39,0.01,2.61,1.14,2.66,2.48C17.01,7.91,17.04,8.57,16.33,8.64z"
            />
            <path
              d="M17.06,7.69c-0.05-1.37-1.29-2.52-2.71-2.53h-0.01c-1.46,0-2.68,1.12-2.72,2.5
					c-0.01,0.32,0.25,0.83,0.57,0.96c0.2,0.08,0.4,0.08,0.57-0.01c0.21-0.11,0.37-0.35,0.45-0.67c0.22-0.95,0.57-1.13,1.15-1.13
					c0.63,0.01,0.92,0.31,1.09,1.11c0.03,0.19,0.15,0.77,0.78,0.77h0.1C17.09,8.61,17.07,7.92,17.06,7.69z M16.33,8.64h-0.1
					c-0.59,0-0.7-0.55-0.73-0.73c-0.17-0.83-0.48-1.14-1.14-1.15c-0.59,0-0.97,0.19-1.2,1.17c-0.07,0.31-0.22,0.54-0.42,0.64
					c-0.16,0.08-0.35,0.08-0.53,0c-0.31-0.12-0.55-0.6-0.54-0.91c0.04-1.35,1.24-2.45,2.67-2.45h0.01c1.39,0.01,2.61,1.14,2.66,2.48
					C17.01,7.91,17.04,8.57,16.33,8.64z"
            />
            <path
              d="M17.01,7.69c0,0.22,0.03,0.88-0.68,0.95h-0.1c-0.59,0-0.7-0.55-0.73-0.73c-0.17-0.83-0.48-1.14-1.14-1.15
					c-0.59,0-0.97,0.19-1.2,1.17c-0.07,0.31-0.22,0.54-0.42,0.64c-0.16,0.08-0.35,0.08-0.53,0c-0.31-0.12-0.55-0.6-0.54-0.91
					c0.04-1.35,1.24-2.45,2.67-2.45h0.01C15.74,5.22,16.96,6.35,17.01,7.69z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M8.44,5.9C7.96,5.46,7.31,5.19,6.6,5.19c-1.44,0-2.65,1.15-2.66,2.5c0,0.32,0.26,0.8,0.57,0.92
					c0.1,0.04,0.19,0.06,0.28,0.06c0.09,0,0.18-0.02,0.26-0.07c0.2-0.11,0.35-0.35,0.42-0.69c0.17-0.82,0.48-1.13,1.14-1.13h0.05
					c0.63,0.02,0.95,0.35,1.1,1.16c0.1,0.53,0.4,0.77,0.88,0.71c0.35-0.04,0.75-0.24,0.68-0.98C9.26,6.96,8.93,6.34,8.44,5.9z
					 M8.64,8.63C8.59,8.64,8.55,8.64,8.51,8.64c-0.39,0-0.63-0.24-0.72-0.71C7.63,7.11,7.3,6.77,6.66,6.76H6.61
					c-0.67,0-0.99,0.32-1.17,1.14c-0.07,0.34-0.21,0.57-0.4,0.68C4.89,8.66,4.72,8.66,4.52,8.59C4.22,8.47,3.97,8,3.97,7.69
					c0-1.34,1.21-2.47,2.63-2.47c1.41,0,2.57,1.05,2.7,2.45C9.36,8.39,8.98,8.59,8.64,8.63z"
            />
            <path
              d="M6.6,5.17c-1.45,0-2.68,1.15-2.68,2.52c0,0.32,0.27,0.82,0.59,0.95C4.6,8.67,4.7,8.69,4.79,8.69
					c0.09,0,0.19-0.02,0.27-0.07c0.21-0.11,0.36-0.36,0.43-0.71c0.18-0.82,0.49-1.12,1.17-1.1c0.61,0.01,0.93,0.34,1.08,1.13
					c0.1,0.55,0.41,0.8,0.9,0.74C9,8.64,9.42,8.43,9.35,7.66C9.22,6.24,8.04,5.17,6.6,5.17z M8.64,8.63
					C8.59,8.64,8.55,8.64,8.51,8.64c-0.39,0-0.63-0.24-0.72-0.71C7.63,7.11,7.3,6.77,6.66,6.76H6.61c-0.67,0-0.99,0.32-1.17,1.14
					c-0.07,0.34-0.21,0.57-0.4,0.68C4.89,8.66,4.72,8.66,4.52,8.59C4.22,8.47,3.97,8,3.97,7.69c0-1.34,1.21-2.47,2.63-2.47
					c1.41,0,2.57,1.05,2.7,2.45C9.36,8.39,8.98,8.59,8.64,8.63z"
            />
            <path
              d="M9.3,7.67c0.06,0.72-0.32,0.92-0.66,0.96C8.59,8.64,8.55,8.64,8.51,8.64c-0.39,0-0.63-0.24-0.72-0.71
					C7.63,7.11,7.3,6.77,6.66,6.76H6.61c-0.67,0-0.99,0.32-1.17,1.14c-0.07,0.34-0.21,0.57-0.4,0.68C4.89,8.66,4.72,8.66,4.52,8.59
					C4.22,8.47,3.97,8,3.97,7.69c0-1.34,1.21-2.47,2.63-2.47C8.01,5.22,9.17,6.27,9.3,7.67z"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
