import { client } from "src/apollo";
import { CreateCouponPL, IPagePL } from "src/models";
import { CREATE_COUPON, DELETE_COUPON, GET_COUPONS } from "./graphql";

export const couponAPI = {
  getCoupons: (pagePL: IPagePL) =>
    client.query({
      query: GET_COUPONS,
      variables: {
        input: pagePL,
      },
      fetchPolicy: "network-only",
    }),

  createCoupon: async (createCouponPL: CreateCouponPL, offset?: number) =>
    client.mutate({
      mutation: CREATE_COUPON,
      variables: {
        input: createCouponPL,
      },
      update: async (store) => {
        const coupons = await couponAPI.getCoupons({ limit: 10, offset });
        store.writeQuery({
          query: GET_COUPONS,
          variables: { input: { limit: 10 } },
          data: coupons.data,
        });
      },
    }),

  deleteCoupon: async (code: string, offset: number) =>
    client.mutate({
      mutation: DELETE_COUPON,
      variables: {
        input: code,
      },
      update: async (store) => {
        const coupons = await couponAPI.getCoupons({ limit: 10, offset });
        store.writeQuery({
          query: GET_COUPONS,
          variables: { input: { limit: 10 } },
          data: coupons.data,
        });
      },
    }),
};
