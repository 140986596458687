import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { Fragment, FunctionComponent, useState } from "react";
import { CreateAllergenPL } from "src/models";
import { COLORS } from "src/styles";

interface IProps {
  createAllergen: (d: CreateAllergenPL) => void;
}
export const AllergenCreateComponent: FunctionComponent<IProps> = ({
  createAllergen,
}) => {
  const [open, setStatus] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const _createAllergen = () => {
    setStatus(false);
    createAllergen({ name, description });
  };

  return (
    <Fragment>
      <Button
        variant="contained"
        color="primary"
        style={{ color: COLORS.WHITE }}
        onClick={() => setStatus(!open)}
      >
        Create Allergen
      </Button>
      <Dialog open={open} onClose={() => setStatus(false)}>
        <DialogTitle id="simple-dialog-title">Create Allergen</DialogTitle>
        <DialogContent>
          <TextField
            size="small"
            label="name"
            fullWidth
            value={name}
            onChange={(d) => setName(d.target.value)}
            margin="normal"
            inputProps={{ maxLength: 200 }}
          />
          <TextField
            size="small"
            label="description"
            fullWidth
            value={description}
            onChange={(d) => setDescription(d.target.value)}
            margin="normal"
            inputProps={{ maxLength: 200 }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setStatus(!open)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => _createAllergen()}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
