import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const styles = makeStyles({
  root: {
    backgroundColor: COLORS.WHITE,
  },
  input: {
    width: 151,
    padding: "10px 20px",
    border: `1px solid ${COLORS.LIGHT_GREY}`,
    borderRadius: "2px",
  },
  icon: {
    fill: COLORS.DARK_GREY,
    marginRight: 7.5,
  },
  notchedOutline: {
    display: "none",
  },
  menuItemRoot: {},
  menuRoot: {},
});
