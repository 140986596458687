import { Paper, Typography } from "@material-ui/core";
import React, { SFC } from "react";
import { PaginationComponent } from "src/components";
import { IDish } from "src/models";
import { AdminEditDishDialog } from "../edit-dish";
import { IFetchMorePL } from "./container";
import { Header } from "./header";
import { DishTable } from "./table";

interface IProps {
  dishes: IDish[];
  fetchMore: (d: IFetchMorePL) => any;

  deleteDish: (options: any) => void;
  onSearch: (d: string) => void;
  count: number;
}

export const Layout: SFC<IProps> = ({
  dishes,

  deleteDish,
  count,
  fetchMore,
  onSearch,
}) => {
  if (dishes && !dishes.length) {
    return (
      <div>
        Dishes:{" "}
        <Typography variant="body2" component="p">
          You don't have any.
        </Typography>
      </div>
    );
  }
  return (
    <div>
      <AdminEditDishDialog fullWidth maxWidth="xl" />
      <Header totalCount={count} onSearch={onSearch} />
      <Paper style={{ padding: "1rem", margin: "1rem" }}>
        <DishTable dishes={dishes} deleteDish={deleteDish} />

        <PaginationComponent
          totalCount={count}
          onChange={(d) => fetchMore({ pagePL: { offset: d - 1 } })}
        />
      </Paper>
    </div>
  );
};
