import { useMutation } from "@apollo/client";
import {
  Button,
  List,
  ListItem,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { MailOutline } from "@material-ui/icons";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { bugSnag, _hsq } from "src";
import {
  AppleBtn,
  DecoratedText,
  FacebookBtn,
  GoogleBtn,
} from "src/components/shared";
import { dinerPixel, ReactPixel } from "src/index";
import { amplitude } from "src/services";
import { COLORS } from "src/styles";
import {
  AuthWithApple,
  AuthWithFacebook,
  AuthWithGoogle,
} from "../auth-buttons";
import { GET_USER_BY_EMAIL } from "./graphql";
import { useStyles } from "./styles";

interface IProps {
  email: string;
  handleSubmitEmail: (event: any) => void;
  onChangeEmail: (event: any) => void;
  switchView: () => void;
  onSubmit: () => Promise<any>;
}

export const AuthWithView: React.FC<IProps> = ({
  handleSubmitEmail,
  onChangeEmail,
  onSubmit,
  email,
  switchView, // it switches to login
}) => {
  const [getUserByEmail] = useMutation(GET_USER_BY_EMAIL);
  const classes = useStyles();
  const [thirdPartyAuthError, setThirdPartyAuthError] = useState<any>(null);

  const _handleSubmitEmail = (e) => {
    e.preventDefault();
    amplitude.getInstance().setUserId(email);
    bugSnag?.setUser(email, email, "Unknown");
    _hsq?.push(["identify", { email }]);
    amplitude.getInstance().logEvent("[Guest Checkout] submit");
    if (process.env.NODE_ENV === "test") return handleSubmitEmail(e);
    return getUserByEmail({ variables: { input: email } })
      .then(() => {
        switchView();
      })
      .catch(() => {
        handleSubmitEmail(e);
      });
  };

  return (
    <div className={classes.container}>
      <img
        className={classes.logo}
        src={require(`src/assets/logos/cook.svg`)}
      />
      <Typography variant="h4" component="h4" className={classes.mainHeading}>
        Your meal awaits!
      </Typography>
      <Typography variant="body1" component="p" className={classes.subHeading}>
        Please choose an option below to finish your order.
      </Typography>

      <form className={classes.form} onSubmit={_handleSubmitEmail}>
        <Typography
          component="label"
          variant="caption"
          className="hidden-label"
          htmlFor="email-input"
        >
          Email
        </Typography>
        <OutlinedInput
          classes={{
            root: classes.inputRoot,
            input: classes.input,
          }}
          name="email"
          type="email"
          labelWidth={0}
          autoComplete={"off"}
          id="email-input"
          placeholder="email"
          value={email}
          onChange={onChangeEmail}
          endAdornment={<MailOutline style={{ color: COLORS.DARK_GREY }} />}
        />
        <Button
          classes={{ contained: classes.continueButton }}
          disabled={!email}
          type="submit"
          variant="contained"
        >
          Continue as guest
        </Button>
      </form>

      <DecoratedText>or</DecoratedText>
      <List className={classes.list}>
        <AuthWithFacebook
          onError={(err) => setThirdPartyAuthError(err)}
          onSubmit={() => {
            onSubmit();
            // This needs to fire AFTER a SUCESSFUL signup. The onSubmit function will need to be adjusted
            process.env.NODE_ENV !== "test" &&
              ReactPixel.trackSingle(dinerPixel, "CompleteRegistration", {
                value: 0,
                currency: "USD",
              });
          }}
        >
          <ListItem classes={{ gutters: classes.listItemGutters }}>
            <FacebookBtn
              text="Continue with Facebook"
              onClick={() =>
                amplitude.getInstance().logEvent("[Signup Flow] thrid party")
              }
            />
          </ListItem>
        </AuthWithFacebook>
        <AuthWithGoogle
          onError={(err) => setThirdPartyAuthError(err)}
          onSubmit={() => {
            onSubmit();
            process.env.NODE_ENV !== "test" &&
              ReactPixel.trackSingle(dinerPixel, "CompleteRegistration", {
                value: 0,
                currency: "USD",
              });
          }}
        >
          <ListItem classes={{ gutters: classes.listItemGutters }}>
            <GoogleBtn
              onClick={() =>
                amplitude.getInstance().logEvent("[Signup Flow] thrid party")
              }
              text="Continue with Google"
            />
          </ListItem>
        </AuthWithGoogle>
        <AuthWithApple
          onError={(err) => setThirdPartyAuthError(err)}
          onSubmit={() => {
            onSubmit();
            process.env.NODE_ENV !== "test" &&
              ReactPixel.trackSingle(dinerPixel, "CompleteRegistration", {
                value: 0,
                currency: "USD",
              });
          }}
        >
          <ListItem classes={{ gutters: classes.listItemGutters }}>
            <AppleBtn
              text="Continue with Apple"
              onClick={() =>
                amplitude.getInstance().logEvent("[Signup Flow] thrid party")
              }
            />
          </ListItem>
        </AuthWithApple>
        {thirdPartyAuthError && (
          <Typography
            variant="body2"
            component="p"
            className={classes.thirdPartyError}
          >
            {thirdPartyAuthError}
          </Typography>
        )}
      </List>

      <div
        style={{
          width: "100%",
          maxWidth: 328,
          borderTop: ` 1px solid #E0E0E0`,
          margin: "2rem 0",
        }}
      />

      <div className={classes.text}>
        <Typography variant="body2" component="p" style={{ margin: "auto" }}>
          Already have an account?
        </Typography>
        <Button onClick={switchView} className={classes.textButton}>
          Log in
        </Button>
      </div>
      <div className={classes.legalContainer}>
        <Typography variant="body2" component="p">
          By proceeding, you agree to the{" "}
          <Link className={classes.link} to="/terms-and-conditions">
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link className={classes.link} to="/privacy">
            Privacy Policy
          </Link>
          .
        </Typography>
      </div>
    </div>
  );
};
