import { Button, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Fade } from "react-awesome-reveal";
import { IGetMe } from "src/models";
// import { CreateRestaurantContainer } from "../../../form";
import { useStyles } from "./top-desktop.styles";

interface IProps {
  getMe: IGetMe;
}

export const TopDesktopCook: FC<IProps> = ({ getMe }) => {
  const classes = useStyles();
  return (
    <section className={classes.container}>
      {/* <video autoPlay muted loop playsInline className={classes.video}>
        <source
          src="https://foodnome.sfo2.digitaloceanspaces.com/videos/home.mp4"
          type="video/mp4"
        />
      </video> */}
      <img
        className={classes.image}
        src="https://foodnome.sfo2.digitaloceanspaces.com/start-your-home-restaurant/cook-landing.jpg"
        alt="foodnome cook image"
      />
      <div className={classes.innerContainer}>
        <Fade triggerOnce={true}>
          <div
            className={classes.card}
            data-testid="top-section-create-restaurant-form"
          >
            <Fade triggerOnce={true} delay={500}>
              <Typography variant="h1" component="h1" className={classes.title}>
                Sell Home-Cooked Food from Your Kitchen!
              </Typography>
            </Fade>
            <Fade triggerOnce={true} delay={750}>
              <img
                src={require("src/screens/home-new/assets/underline.svg")}
                className={classes.squiggle}
              />
            </Fade>
            <Fade triggerOnce={true} delay={1000}>
              <Typography variant="h5" component="h5" className={classes.text}>
                COOK Connect is the legal marketplace for home cooked food, run
                by the nonprofit that legalized Microenterprise Home Kitchen
                Operations (MEHKOs) . We support chefs & home cooks to make it
                easy to start selling food from your own kitchen! Currently COOK
                Connect is available by invitation-only as well as to members of
                the public who complete our COOK Academy program– which is open
                for enrollment!
              </Typography>
            </Fade>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              href={"https://www.cookalliance.org/cookacademy"}
              target="_blank"
            >
              Register
            </Button>
            {/* <Fade triggerOnce={true} delay={1000}>
              <CreateRestaurantContainer user={getMe} />
            </Fade> */}
          </div>
        </Fade>
      </div>
    </section>
  );
};
