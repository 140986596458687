import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  innerDrawer: {
    display: "flex",
    flexDirection: "column",
  },
  drawerBottom: {
    maxWidth: 600,
    width: "100%",
    margin: "0 auto",
  },
  title: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    textAlign: "center",
    padding: "1rem",
    margin: 0,
    backgroundColor: COLORS.WHITE,
    color: COLORS.DARK_BLUE,
  },
  shareLinkRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  shareLinkLabel: {
    fontWeight: 100,
    display: "block",
    color: "#676767",
    textAlign: "center",
    margin: ".5rem",
  },
  notchedOutline: {},
  inputRoot: {
    borderRadius: 2,

    fontWeight: 100,
  },
  input: {
    padding: "10px 15px",
    "&::placeholder": {
      fontWeight: 100,
    },
  },
  copyButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    boxShadow: "none",
    backgroundColor: COLORS.MEDIUM_GREEN,
    color: COLORS.WHITE,
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_GREEN,
    },
  },
  shareList: {
    listStyleType: "none",
    display: "grid",
    gridTemplateColumns: `repeat(auto-fit, minmax(64px, 1fr))`,
    gridGap: "1rem",
    padding: 0,
    margin: 0,
    justifyItems: "center",
  },
  shareListWrapper: {
    margin: "1rem",
  },
  icon: {
    fontSize: "4rem",
  },
});
