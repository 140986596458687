import { Avatar, Button, makeStyles, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { IGetMe } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { BREAK_POINTS, COLORS } from "src/styles";

interface IProps {
  user: IGetMe;
}

const useStyles = makeStyles({
  container: {
    marginTop: "4rem",
    backgroundColor: "#F8F8F8",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    padding: "20px",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginTop: "6rem",
    },
  },
  title: {
    color: COLORS.BLACK,
    fontFamily: "CustomFourBold",

    fontWeight: 500,

    margin: "0 0 10px",
    textTransform: "capitalize",
  },
  root: {
    width: 55,
    height: 55,
    marginRight: 20,
  },
  button: {
    height: 28,

    fontWeight: 100,
    color: COLORS.WHITE,

    paddingTop: 4,
  },
});

export const MobileHeader: FC<IProps> = ({ user }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Avatar
        alt={`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}
        src={user.picture}
        classes={{ root: classes.root }}
      />
      <div>
        <Typography variant="h2" component="h1" className={classes.title}>
          Hi, {user.firstName}!
        </Typography>
        {/* <Button
          variant="contained"
          color="secondary"
          onClick={() => sharedAPI.setReferralDialogState(true)}
          className={classes.button}
        >
          Refer a Friend
        </Button> */}
      </div>
    </div>
  );
};
