import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  dialogTitle: { fontFamily: "CustomFourBold" },
  input: { fontFamily: "CustomFour" },
  submitButton: {
    backgroundColor: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
    color: COLORS.WHITE,

    fontWeight: 500,
    height: 36,
    width: "170px",
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_GREEN,
      opacity: 0.7,
    },
    "&:disabled": {
      backgroundColor: `${COLORS.LIGHT_GREY} !important`,
    },
  },
  closeButtonWrapper: {
    position: "absolute",
    top: "1em",
    right: "1em",
  },
  closeButton: {
    minWidth: 0,
    padding: 0,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "1em 0",
  },
});
