import { makeStyles } from "@material-ui/core/styles";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    // backgroundColor: "#F9F9F9",
    backgroundColor: COLORS.WHITE,

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
    },
  },
  disabled: {
    pointerEvents: "none",
  },
  headerBackground: {
    backgroundColor: COLORS.WHITE,
    width: "100%",
    height: "120px",
    display: "flex",
    alignItems: "center",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },

  headerContainer: {
    display: "flex",
    flexDirection: "row",

    alignItems: "center",
    width: "100%",
    maxWidth: "600px",
    margin: " 0 auto",
  },
  backLink: {
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  mobileLabelContainer: {
    display: "none",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "1rem 0",
      backgroundColor: "#F9F9F9",

      fontWeight: 100,
      textAlign: "center",
      display: "block",

      margin: "auto",
    },
  },

  greeting: {
    margin: 4,
    fontWeight: 100,
  },
  sectionContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "96%",
    maxWidth: "600px",
    margin: "50px auto 10px auto",
  },
  locationContainer: {
    width: "80%",
    height: "200px",
    marginTop: "0.5rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "96%",
    },
  },
  backArrow: {
    color: COLORS.MEDIUM_GREEN,
    fontSize: "4rem",
  },
  icon: {
    color: "#BBBBBB",
    verticalAlign: "middle",
    ...theme.typography.h2,
    marginBottom: 0,
  },
  title: {
    fontFamily: "CustomFourBold",
    margin: "0 ",

    fontWeight: 500,
  },
  description: {
    fontWeight: 100,

    color: "#676767",
  },
  deliveryDescription: {
    fontWeight: 100,

    display: "flex",
    alignItems: "center",
    color: "#676767",
  },
  deliveryEditIcon: {
    color: COLORS.MEDIUM_GREEN,
  },
  avatar: {
    width: 80,
    height: 80,
    borderColor: COLORS.WHITE,
    borderStyle: "solid",
    textTransform: "capitalize",
    backgroundColor: COLORS.RED,
    color: COLORS.WHITE,
  },
  hostName: {
    fontFamily: "CustomFourBold",

    textTransform: "capitalize",
  },
  viewCookLink: {
    textDecoration: "none",
    color: COLORS.MEDIUM_GREEN,

    fontWeight: 100,
  },
  hostInfoContainer: {
    padding: "10px",
  },
  hostContainer: {
    width: "94%",
    display: "flex",
  },
  locationTextContainer: {
    display: "flex",

    alignItems: "center",
  },
}));
