import React from "react";
import { IGetMe } from "src/models";
import { AlamedaSolano, Riverside } from "./in-county";
import { OutCounty } from "./out-county/out-county";

interface IProps {
  getMe: IGetMe;
}

export const FinalDisplay: React.FC<IProps> = ({ getMe }) => {
  // ON_HOLD means that the user is out of county. PENDING is in county
  const isOnHold =
    getMe.restaurant?.application &&
    getMe.restaurant?.application.status === "ON_HOLD";

  if (isOnHold) return <OutCounty getMe={getMe} />;
  switch (
    getMe.restaurant?.county?.toLocaleLowerCase().replace("county", "").trim()
  ) {
    case "riverside":
      return <Riverside getMe={getMe} />;
    case "alameda":
      return <AlamedaSolano getMe={getMe} />;
    case "solano":
      return <AlamedaSolano getMe={getMe} />;
    case "santa barbara":
      return <AlamedaSolano getMe={getMe} />;
    case "san mateo":
      return <AlamedaSolano getMe={getMe} />;
    case "lake":
      return <AlamedaSolano getMe={getMe} />;
    // Should not get to this point but just to be safe...
    default:
      return <AlamedaSolano getMe={getMe} />;
  }
};
