import {
  Button,
  Chip,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { FC, useState } from "react";
import { COLORS } from "src/styles/colors";

interface IProps {
  label: string;
  type?: string;
  placeholder?: string;
  onAdd: (item: string) => void;
  onDelete: (item: string) => void;
  items: string[];
}

const useStyles = makeStyles({
  inputRoot: { height: 50 },
  label: {
    color: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
  },
  input: {
    border: "1px solid #8EBC6D",
    borderRadius: "2px",
    backgroundColor: "rgba(136,173,109,0.07)",
    boxShadow: "-4px 3px 0 0 #86B563",
    fontFamily: "CustomFourBold",

    color: COLORS.DARK_BLUE,
    padding: "10px 10px",
    "&::placeholder": {
      color: COLORS.MEDIUM_GREEN,
    },
  },
});

export const MultiSelectContainer: FC<IProps> = ({
  label,
  type,
  onAdd,
  onDelete,
  placeholder,
  items,
}) => {
  const classes = useStyles();
  const [value, setInput] = useState<string>("");
  const handleAddItem = (_value: string) => () => {
    onAdd(_value);
    setInput("");
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <section
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>
          <Typography
            component="label"
            variant="caption"
            className={classes.label}
            htmlFor={`${label}-input`}
          >
            {label}
          </Typography>
          <TextField
            size="small"
            style={{ width: "100%", margin: ".25em 0" }}
            id={`${label}-input`}
            type={type}
            onChange={(event) => setInput(event.target.value)}
            value={value}
            fullWidth
            inputProps={{ maxLength: 200 }}
            InputProps={{
              disableUnderline: true,
              classes: { root: classes.inputRoot, input: classes.input },
            }}
            placeholder={placeholder}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleAddItem(value)();
                event.preventDefault();
              }
            }}
          />
        </div>
        <Button
          type="button"
          onClick={handleAddItem(value)}
          disabled={value.trim() === ""}
        >
          Add
        </Button>
      </section>
      <section
        data-testid="chips"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          alignItems: "center",
          width: "100%",
          padding: "1em",
        }}
      >
        {items.map((item) => (
          <Chip
            key={item}
            label={item}
            onDelete={() => onDelete(item)}
            style={{ margin: "0.5em" }}
            deleteIcon={
              <Close
                data-testid="delete-chip-button"
                aria-label="delete chip"
              />
            }
          />
        ))}
      </section>
    </div>
  );
};

MultiSelectContainer.defaultProps = {
  type: "text",
  placeholder: "Add a tag...",
};
