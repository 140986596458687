import { useEffect, useState } from "react";
import { useLocation } from "react-router";

// This hook should be used in pages that are frequently visited from other sources. Use sparingly.
// Still needs some work
export const useShouldGoBack = () => {
  const [shouldGoBack, setShouldGoBack] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (document.referrer === window.location.href) {
      // The page was opened in a new window or tab and accessed directly

      setShouldGoBack(false);
    } else if (document.referrer.indexOf(window.location.host) === -1) {
      // The page before this one was not a page on this site

      setShouldGoBack(false);
    } else {
      setShouldGoBack(true);
    }
  }, [location.pathname]);

  return shouldGoBack;
};
