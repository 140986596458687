import { Button, IconButton, withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { FC } from "react";
import { COLORS } from "src/styles/colors";

interface IProps {
  handleClick: () => void;
  children: any;
  disabled?: boolean;
}

export const StyledCreateButton: FC<IProps> = ({ handleClick, children }) => {
  return (
    <StyledRedButton
      onClick={() => handleClick()}
      style={{ width: 205, marginTop: 0 }}
    >
      <div
        style={{
          height: 25,
          width: 25,
          borderRadius: "50%",
          backgroundColor: COLORS.WHITE,
          marginRight: 10,
          position: "relative",
        }}
      >
        <AddIcon
          style={{
            position: "relative",
            top: 2.9,
            fontSize: "1rem",
            color: COLORS.RED,
          }}
        />
      </div>
      <span
        style={{
          color: COLORS.WHITE,
          fontFamily: "CustomFourBold",
          fontWeight: 500,
        }}
      >
        {children}
      </span>
    </StyledRedButton>
  );
};

// Overrides

export const StyledRedButton = withStyles({
  root: {
    height: 43,
    width: 126,
    // border: "2px solid #FF5967",
    borderRadius: "2px",
    backgroundColor: COLORS.DARK_RED,
    marginTop: 30,
    "&:hover": {
      backgroundColor: COLORS.RED,
    },
    "&:disabled": {
      background: COLORS.MEDIUM_GREY,
      border: `2px solid ${COLORS.MEDIUM_GREY}`,
    },
  },
})(Button);

export const StyledWhiteButton = withStyles({
  root: {
    height: 43,
    width: 126,
    borderRadius: "2px",
    backgroundColor: COLORS.WHITE,
    marginTop: 30,
    color: COLORS.RED,
    "&:hover": {
      backgroundColor: COLORS.WHITE,
    },
  },
})(Button);

export const StyledManageButton = withStyles({
  root: {
    marginLeft: 7.5,
    backgroundColor: COLORS.WHITE,
    borderRadius: "50%",
    border: `1px solid ${COLORS.DARK_BLUE}`,
    padding: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.25)",
      color: COLORS.WHITE,
    },
  },
})(IconButton);
