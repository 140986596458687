import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  coverPhotoContainer: { position: "relative" },
  editButton: {
    transition: "all 400ms ease-in-out",
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    "&:hover": {
      background: "#86B563",
    },
    position: "absolute",
    top: "1em",
    left: "1em",
    background: "#86B563",
    color: COLORS.WHITE,
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
  },
  spinner: { color: COLORS.MEDIUM_GREEN },
});
