import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    borderRadius: 0,
    maxWidth: 328,
    margin: "0 auto",

    width: "100%",
    justifyContent: "center",
    alignItems: "center",

    paddingTop: "2rem",
  },
  mainHeading: {
    fontFamily: "CustomFourBold",
    margin: "1rem 0",

    fontWeight: 500,

    textAlign: "center",
  },
  subHeading: {
    display: "inline-block",

    color: COLORS.DARK_GREY,

    margin: ".5rem auto",
    fontWeight: "unset",
    textAlign: "center",
  },
  list: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    listStyleType: "none",
    width: "100%",
    maxWidth: 328,
    // margin: "0.5rem auto",
    padding: 0,
  },
  bottom: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    width: "100%",
  },
  bottomText: {
    display: "inline-block",

    color: COLORS.DARK_GREY,

    marginRight: "1em",
  },
  loginButton: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    textDecoration: "none",
    textTransform: "uppercase",
    color: COLORS.MEDIUM_RED,
  },
  emailLabel: {
    fontFamily: "CustomFourBold",
    textTransform: "uppercase",
    fontWeight: 500,

    color: COLORS.WHITE,
  },
  emailContained: {
    backgroundColor: COLORS.MEDIUM_RED,
    borderRadius: 2,
    width: "100%",
    height: 46,
    maxWidth: 328,
    margin: "0 auto",
    "&:hover": {
      backgroundColor: "rgba(255, 89, 75, 0.7)",
    },
  },
  referralListItem: {
    marginTop: "1rem",
    display: "flex",
    width: "100%",
    maxWidth: 328,
  },
  listItemGutters: {
    paddingLeft: 0,
    paddingRight: 0,
  },

  thirdPartyError: {
    fontFamily: "CustomFourBold",
    color: COLORS.RED,
    margin: "0 auto",
  },
  link: {
    color: COLORS.MEDIUM_GREEN,
    textDecoration: "none",
  },
  logo: {
    width: 40,
    height: 40,
    margin: "0 auto",
  },
});
