import { RouteComponentProps } from "react-router-dom";

export interface IGetMeWithHistoryProps extends RouteComponentProps<any> {
  data: {
    getMe: IUser;
  };
  width?: "xs" | "sm" | "md" | "lg" | "xl";
}
export interface IFoodType {
  id: string;
  name: string;
}

export enum ReferralStatus {
  ACTIVE = "ACTIVE",
  WARNING = "WARNING",
  BANNED = "BANNED",
}

export interface IReferral {
  id?: number;
  code: string;
  threshold: number;
  userId: number;
  ipAddress: string;
  referrerAmount: number;
  referredAmount: number;
  status: ReferralStatus;
  updatedAt?: string;
  createdAt?: string;

  referredBy: IUser;
  referralUsers: IReferralUser[];
}

export enum ReferralUserStatus {
  ACTIVE = "ACTIVE",
  REDEEMED = "REDEEMED",
}

export interface IReferralUser {
  id?: number;
  code: string;
  status: ReferralUserStatus;
  ipAddress: string;
  userId: number;
  referralId: number;
  amount: number;
  updatedAt?: string;
  createdAt?: string;
  referral?: IReferral;
}

export interface AdminUpdateUserPL {
  firstName: string;
  lastName: string;
  userId: any;
  email: string;
  password?: string;
  nomes?: number;
  address?: string;
  credits?: number;
  phoneNumber?: string;
  account?: string;
  active?: boolean;
  picture?: string;
  points?: {
    eventsAttended?: number;
  };
}

export interface AdminUpdateRestaurantReviewPL {
  id: number;
  text: string;
  quality: number;
  hospitality: number;
  accuracy: number;
  status: RestaurantReviewStatus;
}

export interface IImage {
  publicId: string;
  small: string;
  medium: string;
  large: string;
  original: string;
}

export interface IPrediction {
  id: string;
  place_id: string;
  description: string;
}

export interface IAddressSuggestions {
  status: string;
  predictions: IPrediction[];
}

export interface ISearchSuggestions {
  googlePredictions: { status: string; predictions: IPrediction[] };
  restaurants: IRestaurant[];
}

export interface CreateAllergenPL {
  name: string;
  description?: string;
}

export interface SendRegistrationPL {
  restaurantId: number | string;
  nameOfOwner: string;
  dba: string;
  dbaAddress: string;
  dbaCity: string;
  dbaState: string;
  dbaZip: string;
  phone: string;
  email: string;
  website: string;
  license: string;
  expDate: string;
  dob: string;
}

export interface SendApplicationPL {
  restaurantId: number | string;
  dba: string;
  phoneNumber: string;
  ownerName: string;
  foodEmployeeName: string;
  ownerAddress: string;
  ownerCity: string;
  ownerZip: string;
  email: string;
  website: string;
  sundayTime: string;
  mondayTime: string;
  tuesdayTime: string;
  wednesdayTime: string;
  thursdayTime: string;
  fridayTime: string;
  saturdayTime: string;
  sundayNumber: string;
  mondayNumber: string;
  tuesdayNumber: string;
  wednesdayNumber: string;
  thursdayNumber: string;
  fridayNumber: string;
  saturdayNumber: string;
  utensilWashingSink: boolean;
  dishwasher: boolean;
  cleanInPlace: boolean;
  chlorine: boolean;
  quaternaryAmmonium: boolean;
  iodine: boolean;
  cleaningProcess: string;
  locationServed: string;
  locationStored: string;
  remainingFood: string;
  pickUp: boolean;
  delivery: boolean;
  deliverPerson: string;
  transportation: string;
  hold: string;
  maximumGeo: string;
  maximumTime: string;
  package: string;
  publicWaterCheck: boolean;
  publicWater: string;
  privateWaterCheck: boolean;
  privateWater: string;
  publicSewerCheck: boolean;
  privateSepticCheck: boolean;
  numberPerson: string;
  numberRestroom: string;
  numberSink: string;
  refuseStored: string;
  refusePickUp: string;
  ventilation: string;
  fireExtinguisher: string;
  firstAid: string;
  equipment: string;
  meal: string;
}

export interface CreateApplicationStatusPL {
  applicationId?: number | string;
  status: string;
  description: string;
  inspectionTime?: string;
}

export interface ISignUpPL {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  referralCode?: string;
  ipAddress?: string;
  preferences?: {
    NEWS_LETTER_EMAIL_SUBSCRIPTION: boolean;
  };
}

export interface CreatePromotionPL {
  eventId: string;
  amount: number;
  reason?: string;
}

export enum RestaurantUserStatus {
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export interface IRestaurantGuest {
  id?: number;
  restaurantId: number;
  userId: number;
  status: RestaurantUserStatus;
  updatedAt?: string;
  createdAt?: string;
  restaurant?: IRestaurant;
  guest: IUser;
}

export interface IPagePL {
  offset?: number;
  limit?: number;
}

export interface ITransaction {
  id?: number;
  amount: number;
  description: string;
  updatedAt?: string;
  createdAt?: string;
  userId: number;
  eventUserId?: number;
  paymentId?: number;
  eventId?: number;
  eventUser?: IEventUser;
  payment: IPayment;
  event?: IEvent;
  reservedFor: number;
}

export enum UserRole {
  USER = "USER",
  ADMIN = "ADMIN",
  SUPER_ADMIN = "SUPER_ADMIN",
  INSPECTOR = "INSPECTOR",
}

export enum EventStatus {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  REQUESTED = "REQUESTED",
}
export interface IGetMe {
  id: string;
  role: UserRole;
  active: boolean;
  firstName: string;
  lastName: string;
  hasApp: string;
  email: string;
  picture: string;
  description: string;
  account: string;
  nomes: number;
  credits: number;
  stripeId: string;
  stripeLast4: number;
  brainTreeId: string;
  birthday: string;
  address: string;
  updatedAt: string;
  createdAt: string;
  phoneNumber: string;
  restaurant?: IRestaurant;
  EventUser: IEventUser;
  eventUser: IEventUser;
  source?: string;
  payments: IPayment[];
  preferences?: {
    NEWS_LETTER_EMAIL_SUBSCRIPTION: boolean;
  };
  points?: {
    eventsAttended: number;
    lastOrderedAt: string;
  };
}

export interface IUser {
  id: string;
  role: UserRole;
  active: boolean;
  firstName: string;
  lastName: string;
  hasApp: string;
  email: string;
  picture: string;
  description: string;
  account: string;
  nomes: number;
  credits: number;
  stripeId: string;
  stripeLast4: number;
  brainTreeId: string;
  birthday: string;
  address: string;
  updatedAt: string;
  createdAt: string;
  phoneNumber: string;
  restaurant?: IRestaurant;
  EventUser: IEventUser;
  eventUser: IEventUser;
  source?: string;
  payments: IPayment[];
  preferences?: {
    NEWS_LETTER_EMAIL_SUBSCRIPTION: boolean;
  };
  points?: {
    eventsAttended: number;
    lastOrderedAt: string;
  };
}

export interface IUpdateUserPreferences {
  NEWS_LETTER_EMAIL_SUBSCRIPTION: boolean;
}

export interface IAllergenDish {
  id?: number;
  allergenId: number;
  dishId: number;
  updatedAt?: string;
  createdAt?: string;
}

export interface IAllergen {
  id: string;
  name: string;
  description?: string;
  updatedAt?: string;
  createdAt?: string;
}

export interface IApplicationStatus {
  id: number | string;
  status: AppStatus;
  description: string;
  applicationId: number;
  application?: IApplication;
  updatedAt?: string;
  createdAt?: string;
}

export interface IApplication {
  id: string;
  device: string;
  source: string;
  assignee: string;
  progress: number;
  description: string;
  status: AppStatus;
  updatedAt?: string;
  createdAt?: string;
  restaurantId: number;
  restaurant: IRestaurant;
  applicationStatuses: IApplicationStatus[];
  inspectionTime: string;
  info: AppInfoPL;
}

export enum NotificationStatus {
  UNREAD = "UNREAD",
  UNKNOWN = "UNKNOWN",
  READ = "READ",
}

export interface INotification {
  id?: number;
  userId: number;
  status: NotificationStatus;
  headings: string;
  subtitle: string;
  contents: string;
  imageUrl: string;
  redirectUrl: string;
  updatedAt?: string;
  createdAt?: string;
}
export interface AppInfoPL {
  businessName: string;
  address: string;
  hasVentilation: boolean;
  ventilation: string;
  hasDishwasher: boolean;
  isPublicWater: boolean;
  waterCompany: string;
  isPublicSewer: boolean;
  numberOfPerson: number;
  numberOfRestroom: number;
  numberOfSinks: number;
  dishInfos: any[];
  idImage: string;
  proofAddressImage: string;
}

export enum DishCategory {
  ENTREE = "ENTREE",
  BEVERAGE = "BEVERAGE",
  SIDE = "SIDE",
  DESSERT = "DESSERT",
}

export interface IDishEvent {
  id?: number;
  eventId: string;
  dishId: number;
  price: number;
  count: number;
  sold: number;
  options?: DishOption[];
  updatedAt?: string;
  createdAt?: string;
}

export interface IDishSelect {
  count?: number;
  price?: number;
  dishId: number;
  name: string;
}

export interface DishOption {
  name: string;
  count?: number;
  addition: number;
  sold?: number;
}

export interface IDish {
  id: number;
  name: string;
  description: string;
  images: string[];
  imagesGQL: IImage[];
  isPublished: boolean;
  defaultPrice?: number;
  dishLeft: number;
  updatedAt?: string;
  createdAt?: string;
  restaurantId?: number;
  restaurant?: IRestaurant;
  allergens?: IAllergen[];
  dietary?: string;
  tags?: string[];
  count?: number;
  DishEvent?: IDishEvent;
  EventUserDish: IOrderedDish;
  OrderDish: IOrderedDish;
  category: DishCategory;
  options?: DishOption[];
  events?: IEvent[];
}

export enum UpdateStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export interface IEventUpdate {
  id?: number;
  eventId: string;
  status: UpdateStatus;
  raw?: string;
  updatedAt?: string;
  createdAt?: string;
}

interface ICouponInfo {
  id: number;
  code: string;
  percent: number;
  max: number;
  deduction: number;
  newUser: boolean;
}
export interface IPhone {
  sid: string;
  phone: string;
}

export interface IEventUser {
  id: number;
  eventId: string;
  userId: string;
  paidAmount: number;
  creditAmount: number;
  serviceFeeAmount: number;
  taxAmount: number;
  phone?: IPhone;
  arrivalTime?: string;
  dineOption: string;
  status: EventUserStatus;
  reservedFor: number;
  deliveryAddress: string;
  notes?: string;
  deliveryCost?: number;
  payments?: IPayment[];
  checkedIn?: boolean;
  updatedAt?: string;
  createdAt?: string;
  orderedDishes?: IDish[];
  source?: string;
  couponInfo?: ICouponInfo;
  orders?: IEventUser[];
}

export interface IOrderedDish {
  id?: number;
  userId: number;
  eventId: string;
  dishId: number;
  count: number;
  name: string;
  price: string;
  options: DishOption[];
  EventUserDish: EventUserDish;
  updatedAt?: string;
  createdAt?: string;
}

export interface EventUserDish {
  id?: number;
  userId?: number;
  eventId?: number;
  dishId?: number;
  count: number;
  dish: IDish;
  options?: DishOption[];
  updatedAt?: string;
  createdAt?: string;
}

export enum EventUserStatus {
  SAVED = "SAVED",
  VIEWED = "VIEWED",
  RESERVED = "RESERVED",
  CANCELED = "CANCELED",
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  HOLD = "HOLD",
}

export interface IRestaurantUser {
  id?: number;
  restaurantId: number;
  userId: number;
  status: string;
  updatedAt?: string;
  createdAt?: string;
  restaurant?: IRestaurant;
  user?: IUser;
}

export interface ILocale {
  public: string;
  private?: string;
  distance?: number;
  county?: string;
  coordinates: {
    longitude: number;
    latitude: number;
  };
}

export interface IEvent {
  id: string;
  name: string;
  description: string;
  images: string[];
  imagesGQL: IImage[];
  tags: string[];
  price: number;
  reserveBy: string;
  startTime: string;
  hidden: boolean;
  endTime: string;
  minSeats: number;
  maxSeats: number;
  status: EventStatus;
  private: boolean;
  updatedAt?: string;
  createdAt?: string;
  restaurantId: number;
  restaurant: IRestaurant;
  eventUsers: IEventUser[];
  reservations: IReservation[];
  dishes: IDish[];
  guests: IUser[];
  cancellers: IUser[];
  type: string[];
  seatsLeft: number;
  myReservation?: IEventUser;
  hasRequested?: boolean;
  isApproved?: boolean;
  myEventUserStatus?: EventUserStatus;
  eventUser?: IEventUser;
  restaurantUser?: IRestaurantUser;
  guestCount?: number;
  requestCount?: number;
  bufferTime?: number;
  instructions?: string;
  locale: ILocale;
  totalSale?: number;
  soldOutTimeSlots?: string[][];
  deliveryInfo?: {
    baseAmount: number;
    maximumDriveTime: number;
    minimumOrderAmount: number;
    pricePerMin: number;
    pricePerMile: number;
  };
}

export interface ApproveEventUserPL {
  eventId: string;
  userId: number;
  msg?: string;
}

export interface ContactGuestsPL {
  eventId: string;
  msg: string;
  guestIds: string[];
}

export interface RejectEventUserPL {
  eventId: string;
  userId: number;
  msg?: string;
}

export interface IReservation {
  id: number;
  reservedFor: number;
}

export interface IPayment {
  id?: number;
  payInId?: string;
  payOutId?: string;
  totalAmount: number;
  raw: string;
  updatedAt?: string;
  createdAt?: string;
  userId: number;
}

export interface IOverallReview {
  count: number;
  stars: number;
  quality: number;
  hospitality: number;
  accuracy: number;
}

export enum RestaurantReviewStatus {
  PENDING = "PENDING",
  SUBMITTED = "SUBMITTED",
  REJECTED = "REJECTED",
}

export interface IRestaurantReview {
  id: number;
  status: RestaurantReviewStatus;
  text: string;
  quality: number;
  hospitality: number;
  cookResponse?: string;
  flagged: boolean;
  accuracy: number;
  restaurantId: number | string;
  tipAmount: number;
  eventInfo: {
    name: string;
    hostName: string;
    time: string;
    hostPicture: string;
  };
  userId: number;
  createdAt?: string;
  updatedAt?: string;
  guest: IUser;
  restaurant: IRestaurant;
}

export interface IServiceFeeInfo {
  paid: number;
  month: string;
}

export interface IRestaurant {
  id: string;
  name: string;
  description: string;
  taxRate: number;
  address: string;
  street?: string;
  city?: string;
  state?: string;
  hostBio?: string;
  serviceFeePerc?: number;
  serviceFeeCap?: number;
  serviceFeeInfo?: IServiceFeeInfo;
  zipCode?: string;
  county?: string;
  images: string[];
  tags: string[];
  imagesGQL: IImage[];
  updatedAt?: string;
  createdAt?: string;
  userId: number;
  application: IApplication;
  host: IUser;
  events: IEvent[];
  dishes: IDish[];
  permitNumber?: string;
  complaints?: IComplaint[];
  overallReview: IOverallReview;
  restaurantReviews: IRestaurantReview[];
  locale?: ILocale;
  badges: IBadges;
  info: IInfo;
  fromCSV: boolean;
  externalLink: string;
}

interface IBadges {
  managerCertificate: boolean;
  insurance: boolean;
}

interface IInfo {
  hasDog: boolean;
  hasCat: boolean;
  otherAnimals: string;
}

export enum ComplaintType {
  FOOD_SAFETY = "FOOD_SAFETY",
  HOSPITALITY = "HOSPITALITY",
  OTHER = "OTHER",
}
export interface IComplaint {
  id: number;
  userId: number;
  restaurantId: number;
  eventId: string;
  restaurant?: IRestaurant;
  user?: IUser;
  complaint: string;
  complaintType: ComplaintType;
}

export interface CreateComplaintPL {
  restaurantId: number;
  eventId: string;
  complaint: string;
  complaintType: ComplaintType;
}

export interface CreateApplicationPL {
  description: string;
}

export interface UpdateApplicationPL {
  id: number;
  description?: string;
  status?: AppStatus;
  assignee?: string;
  inspectionTime?: string;
}

export enum AppStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  SCHEDULED = "SCHEDULED",
  SUBMITTED = "SUBMITTED",
  ACTION_REQUIRED = "ACTION_REQUIRED",
  ON_HOLD = "ON_HOLD",
  REJECTED = "REJECTED",
}

export enum DietaryTypes {
  NONE = "NONE",
  PESCETARIAN = "PESCETARIAN",
  VEGETARIAN = "VEGETARIAN",
  VEGAN = "VEGAN",
  KETO = "KETO",
}

export interface ICreateRestaurantPL {
  email: string;
  address: string;
  firstName: string;
  lastName: string;
  source?: string;
  device?: string;
  phoneNumber: string;
  captchaToken: string | null;
}

export interface IGetRestaurantPL {
  id: number;
}
export interface UpdateDishPL {
  id: number;
  name?: string;
  description?: string;
  images?: string[];
  tags?: string[];
  allergens?: string[];
  isPublished?: boolean;
  dietary?: string;
  defaultPrice?: number;
  category?: string;
  options?: DishOption[];
}

export interface CreateDishPL {
  name: string;
  description: string;
  tags?: string[];
  isPublished?: boolean;
  images: string[];
  defaultPrice?: number;
  dietary: string;
  category?: string;
  allergens: string[];
  options?: DishOption[];
}

export interface GetMyEventsPL {
  status: EventUserStatus;
  limit: number;
  past?: boolean;
  upcoming?: boolean;
  orderBy?: "createdAt" | "arrivalTime";
}

export interface CreateEventUserPL {
  eventId: string | string;
}

export interface UpdateEventUserPL {
  eventId: string | string;
  status: string;
}

export interface ActiveEventUpdatePL {
  id: number;
  name: string;
  description: string;
  instructions: string;
  displayLocation?: string;
  location: string;
  minSeats?: number;
  maxSeats?: number;
  reserveBy: string;
  startTime: string;
  private: boolean;
  images: string[];
  imagesGQL: IImage[];
}

export interface UpdateDishEventPL {
  dishId: number;
  eventId: string;
  count: number;
}

export interface ReserveEventUserPL {
  eventId: string;
  reservedFor?: number;
  coupon?: string;
  notes?: string;
}

export interface RequestEventUserPL {
  eventId: string;
  reservedFor: number;
  coupon?: string;
  notes?: string;
}

export interface CancelEventUserPL {
  eventId: string;
}

export interface UpdateReservationCountPL {
  eventId: string;
  reservedFor?: number;
}

export interface CreateEventPL {
  name: string;
  description?: string;
  instructions?: string;
  private?: boolean;
  price?: number;
  displayLocation?: string;
  location?: string;
  images?: string[];
  minSeats?: number;
  maxSeats?: number;
  reserveBy?: string;
  startTime?: string;
  endTime?: string;
  tags?: string[];
  type?: string[];
  bufferTime?: number;
}

export interface UpdateEventPL {
  id: string;
  name?: string;
  description?: string;
  price?: number;
  type?: string[];
  displayLocation?: string;
  location?: string;
  images?: string[];
  private?: boolean;
  bufferTime?: any;
  minSeats?: number;
  hidden?: boolean;
  maxSeats?: number;
  reserveBy?: string;
  startTime?: string;
  endTime?: string;
  tags?: string[];
}

export interface UpdateActiveEventPL {
  id: string;
  instructions?: string;
  deliveryInfo?: any;
  name?: string;
  description?: string;
  displayLocation?: string;
  location?: string;
  minSeats?: number;
  maxSeats?: number;
  bufferTime?: number;
  reserveBy?: string;
  startTime?: string;
  endTime?: string;
  private?: boolean;
  images?: string[];
}

export interface DeleteDishEventPL {
  eventId: string;
  dishId: number;
}

export interface CreateDishEventPL {
  eventId: string;
  dishId: number;
}

export interface CreateAllergenDishPL {
  dishId: number;
  allergenId: number;
}

export interface CheckInUserPL {
  userId: string;
  eventId: string;
}

export interface IUpdateUserPL {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  description?: string;
  birthday?: string;
  picture?: string;
  address?: string;
  account?: string;
}

export enum ReservationActionTypes {
  CANCEL = "CANCEL",
  UPDATE = "UPDATE",
  RESERVE = "RESERVE",
  COMPLAINT = "COMPLAINT",
}

export interface UpdateRestaurantPL {
  restaurantId?: any;
  name?: string;
  address?: string;
  description?: string;
  county?: string;
  hostBio?: string;
  permitNumber?: string;
  overallReview?: IOverallReview;
}

export interface AdminUpdateRestaurantPL {
  restaurantId: number | string;
  name?: string;
  address?: string;
  hostBio?: string;
  description?: string;
  images?: string[];
  serviceFeePerc?: number;
  serviceFeeCap?: number;
  serviceFeeInfo?: {
    paid?: number;
    month?: string;
  };
  county?: string;
  permitNumber?: string;
  overallReview?: IOverallReview;
  badges?: IBadges;
  info?: IInfo;
}

export interface VerifyCouponPL {
  code: string;
  type: string;
  eventId?: string | number;
  restaurantId?: string | number;
}

export enum CouponTypes {
  EVENT = "EVENT",
  NOME = "NOME",
}

export enum CouponUnits {
  DOLLAR = "DOLLAR",
  PERCENTAGE = "PERCENTAGE",
}

export interface ICoupon {
  id?: number;
  code: string;
  count: number;
  start?: number | string;
  end: number | string;
  percent: number;
  newUser: boolean;
  type: CouponTypes;
  max: number;
  unit: CouponUnits;
  createdAt?: string;
  updatedAt?: string;
  eventId?: number;
  restaurant?: IRestaurant;
}

export interface CreateCouponPL {
  code: string;
  start: string;
  end: string;
  max: number;
  count: number;
  newUser: boolean;
  percent: number;
  type: string;
  eventId?: string | null;
  restaurantId?: string | null;
}

export interface EventDuplicatePL {
  eventId: string;
  date: string;
}

export interface OrderedDishes {
  id: number;
  count: number;
  dishInfo?: IDish;
}

export interface ReserveDropInPL {
  eventId: string;
  coupon?: string;
  dishes?: OrderedDishes[];
  notes?: string;
  dineOption: string;
  arrivalTime: string;
}

export interface RequestDropInPL {
  eventId: string;
  coupon?: string;
  notes?: string;
  dishes?: OrderedDishes[];
  dineOption: string;
  arrivalTime: string;
}

export interface IDishWithCount extends IDish {
  count: number;
  notes?: string;
}

export interface UpdateDropInOrderPL {
  eventId: string;
  coupon?: string;
  dineOption?: string;
  arrivalTime?: string;
  dishes?: OrderedDishes[];
}

export interface IUpdateUserPasswordPL {
  password: string;
  newPassword: string;
}

export interface IFacebookLoginPL {
  token: string;
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  ipAddress?: string;
  picture?: string;
  referralCode?: string;
}

export interface ICheckoutAsGuest {
  email: string;
  firstName: string;
  lastName: string;
}

export interface ISidebarLink {
  label: string;
  link: string;
  icon: React.ReactElement;
  when: ((user?: IGetMe) => boolean) | boolean;
}

export interface DishWithAmount {
  id: number;
  count: string | null;
  price: string | null;
  name: string;
  images: string[];
}

/*
 These are Press interfaces for the homepage and the diner landing pages! ☝️
*/
export enum VerifiedPress {
  MARKET_PLACE = "Marketplace",
  NPR = "NPR",
  MUNCHIES = "Munchies",
  THE_NEW_FOOD_ECONOMY = "The New Food Economy",
  YAHOO = "Yahoo",
  CBS = "CBS",
  PBS = "PBS",
  CAPRADIO = "CAPRADIO",
  PE = "PE",
}

export interface IPressLogo {
  image: string;
  alt: VerifiedPress;
  url: string;
}

/*
  These are the press interfaces for the Press page.
*/
export interface IPress {
  id?: number;
  date: string;
  title: string;
  summary: string;
  source: string;
  sourceLogo: string;
  url: string;
  imageUrl: string;
  featured?: boolean;
  type: PressType;
}

export enum PressType {
  ARTICLE = "ARTICLE",
  VIDEO = "VIDEO",
}

export interface IAppInfo {
  businessName: string | null;
  address: string | null;
  hasVentilation: boolean | null;
  ventilation: string | null;
  hasDishwasher: boolean | null;
  isPublicWater: boolean | null;
  waterCompany: string | null;
  isPublicSewer: boolean | null;
  numberOfPerson: number | null;
  numberOfRestroom: number | null;
  numberOfSinks: number | null;
  dishInfos: IDishInfo[] | null;
  idImage: string | null;
  question1: string | null;
  question2: string | null;
  question3: string | null;
  __typename: string;
}

export interface IDishInfo {
  name: string;
  ingredients: string;
}

export interface ShareLinkConfig {
  drawerTitle: string;
  description: string;
  tags: string[];
  twitter?: {
    tags?: string[];
    description?: string;
  };
  facebook?: {
    description?: string;
    tag?: string;
  };
  whatsApp?: {
    description?: string;
  };
  email?: {
    subject?: string;
    body?: string;
  };
}
