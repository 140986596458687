import {
  AppBar,
  Dialog,
  IconButton,
  InputAdornment,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { stringify } from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";
import { AutoCompleteContainer } from "src/components";
import { IRestaurant } from "src/models";
import { COLORS } from "src/styles";
import { LocalStorageHelper } from "src/utils/helpers";
import { useStyles } from "./full-screen-search-dialog.styles";

interface IProps {
  dialogSearchType: string;
  handleClose: () => void;
  open: boolean;
  onSubmit: (event: any) => void;
  queryObj: {
    location: string;
  };
}

export const FullScreenSearchDialog: React.FC<IProps> = ({
  dialogSearchType,
  handleClose,
  open,
  onSubmit,
  queryObj,
}) => {
  const history = useHistory();
  const classes = useStyles();

  const onEnterSelect = (l) => {
    const locactionObj = {
      ...(l.longitude && {
        longitude: Number(l.longitude).toFixed(5),
      }),
      ...(l.latitude && {
        latitude: Number(l.latitude).toFixed(5),
      }),
      ...(!l.longitude && !l.latitude ? { location: l } : null),
    };
    history.push({
      pathname: "/menus",
      search: stringify(locactionObj),
    });
    // SET LOCATION PREFERENCE INTO LOCAL STORAGE
    if (l)
      LocalStorageHelper.setItem(
        "FOODNOME_LOCATION_PREFERENCE",
        JSON.stringify(locactionObj)
      );
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <AppBar className={classes.appBar}>
        <img
          className={classes.sideBubble}
          src={require(`src/assets/logos/main_header_logo.png`)}
          alt="Foodnome bubble background"
        />
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            data-testid="close-search-dialog-button"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.dialogTitle}>
            Search Menus
          </Typography>
          <IconButton
            disabled
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon style={{ opacity: 0 }} />
          </IconButton>
        </Toolbar>

        <form onSubmit={onSubmit}>
          <div className={classes.searchBarItem}>
            <Typography
              component="label"
              variant="caption"
              className="hidden-label"
              htmlFor="search-by-tag-input"
            >
              Search by Location
            </Typography>
            <AutoCompleteContainer
              size="small"
              autoFocus={true}
              isMobileSearchDialog={true}
              displaySearchFor={
                dialogSearchType === "address"
                  ? { google: true, restaurants: true }
                  : { google: false, restaurants: true }
              }
              useGeoLocation={true}
              onSelectRestaurant={(r: IRestaurant) =>
                history.push(`/${r.name.replace(/ /g, "-")}`)
              }
              initialState={
                dialogSearchType === "address"
                  ? queryObj
                    ? queryObj.location
                    : ""
                  : ""
              }
              onSelect={onEnterSelect}
              TextFieldProps={{
                variant: "outlined",
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search
                        style={{
                          color: COLORS.MEDIUM_GREEN,
                          zIndex: 1,
                        }}
                      />
                    </InputAdornment>
                  ),

                  placeholder: "Search by restaurant or location",
                  classes: {
                    input: classes.input,

                    notchedOutline: classes.notchedOutline,
                    focused: classes.focused,
                  },
                  id: "header-address-autocomplete-input",
                },
              }}
            />
          </div>
        </form>
      </AppBar>
    </Dialog>
  );
};
