import { useQuery } from "@apollo/client";
import React, { FC, useState } from "react";
import { ErrorLoadingComponent } from "src/components";
import { GET_ALL_REFERRALS } from "../api/graphql";
import { Layout } from "./layout";

export const ReferralContainer: FC = () => {
  const [pageNum, setPageNum] = useState(1);
  const { data, fetchMore, loading, error } = useQuery<any>(GET_ALL_REFERRALS, {
    variables: {
      input: {
        limit: 30,
      },
    },
  });

  const _fetchMore = (d: number) => {
    setPageNum(d);
    fetchMore({
      variables: {
        input: { limit: 30, offset: (d - 1) * 30 },
      },
    });
  };

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }
  return (
    <Layout
      referrals={data?.getReferrals.rows}
      count={data?.getReferrals.count}
      fetchMore={_fetchMore}
    />
  );
};
