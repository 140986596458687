import { TextField, Typography } from "@material-ui/core";
import { PersonOutline } from "@material-ui/icons";
import React from "react";
import { formStyles as useStyles } from "./styles";

interface IProps {
  firstName: string;
  lastName: string;
  onChange: ({
    target: { value, name },
  }: {
    target: { value: string; name: string };
  }) => void;
}

export const NameFields: React.FC<IProps> = ({
  firstName,
  lastName,
  onChange,
}) => {
  const classes = useStyles();

  // Using this unique id to prevent duplication errors since this component
  // is shown twice in the become-a-cook page
  const uniqueId = Date.now();
  return (
    <div className={classes.col2}>
      <div>
        <Typography
          component="label"
          variant="caption"
          className={"hidden-label"}
          htmlFor={"become-cook-firstName-" + uniqueId}
        >
          First Name*
        </Typography>
        <TextField
          size="small"
          autoComplete="new-password"
          fullWidth
          variant="outlined"
          InputProps={{
            labelWidth: 0,
            classes: {
              root: classes.textField,

              input: classes.input,
            },
            startAdornment: (
              <PersonOutline className={classes.startAdornment} />
            ),
          }}
          name="firstName"
          value={firstName}
          id={"become-cook-firstName-" + uniqueId}
          {...({ "data-e2e": "become-cook-firstName-" + uniqueId } as any)}
          onChange={onChange}
          className={classes.textField}
          placeholder="First Name"
          required
        />
      </div>
      <div>
        <Typography
          component="label"
          variant="caption"
          className={"hidden-label"}
          htmlFor={"become-cook-lastName-" + uniqueId}
        >
          Last Name*
        </Typography>
        <TextField
          size="small"
          autoComplete="new-password"
          fullWidth
          variant="outlined"
          name="lastName"
          value={lastName}
          id={"become-cook-lastName-" + uniqueId}
          {...({ "data-e2e": "become-cook-lastName-" + uniqueId } as any)}
          onChange={onChange}
          InputProps={{
            labelWidth: 0,
            classes: {
              root: classes.textField,
              input: classes.input,
            },
            startAdornment: (
              <PersonOutline className={classes.startAdornment} />
            ),
          }}
          placeholder="Last Name"
          required
        />
      </div>
    </div>
  );
};
