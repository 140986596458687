import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    // marginTop: "80px",
    position: "relative",
    maxWidth: 1230,
    width: "100%",
    boxShadow: "-10px 0px 36px 10px rgba(0,0,0,0.17)",
  },
  leftContainer: {
    height: 663,
    width: "100%",
    overflow: "hidden",
    position: "relative",
  },
  valuesContent: {
    height: 663,
    maxWidth: 650,
    width: "100%",
    position: "relative",
    zIndex: 999,
    backgroundColor: COLORS.WHITE,
    padding: 70,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    padding: "0 15px",
    fontFamily: "CustomFourMedium",
  },
  innerTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 15px",
  },
});
