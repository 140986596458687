import {
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  AttachMoney,
  Close,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  Help,
  Phone,
  SupervisorAccount,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { Toggler } from "src/components";
import { currentBuild } from "src/index";
import { IGetMe } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { BREAK_POINTS, COLORS } from "src/styles";
import "./animations.css";
import { fetchApiVersion } from "./services/appversion";
import { DrawerTop } from "./drawer-top";
import { authStaticRoutes, helpAndSupportRoutes } from "./static-routes";
import { useStyles } from "./styles";

interface LayoutProps {
  handleClose: () => void;
  handleOpen: () => void;
  handleLogout: () => Promise<any>;
  toggleCookMode?: () => void;
  user: IGetMe;
  open: boolean;
  isCordova: boolean;
  isCookMode: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
  isCordova,
  handleClose,
  handleOpen,
  handleLogout,
  user,
  open,
  isCookMode,
  toggleCookMode,
}) => {
  const classes = useStyles();
  // @kazam: added to have /api/ping build number in admin menu
  const [apiVersion, setApiVersion] = useState<string | null>(null);
  useEffect(() => {
    const fetchVersion = async () => {
      const version = await fetchApiVersion();
      setApiVersion(version);
    };
    fetchVersion();
  }, []);

  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  const [nestedListOpen, setNestedListOpen] = useState(false);
  const history = useHistory();
  const handleClick = () => {
    setNestedListOpen(!nestedListOpen);
  };

  return (
    <SwipeableDrawer
      data-testid="AuthDrawer-drawer"
      onClose={handleClose}
      onOpen={handleOpen}
      classes={{ paperAnchorRight: classes.drawerPaper }}
      BackdropProps={{
        classes: { root: classes.backdrop },
        ...({ "data-testid": "AuthDrawer-backdrop" } as any),
      }}
      open={open}
      anchor="right"
    >
      <div className={classes.innerDrawer}>
        <CSSTransition
          unmountOnExit
          classNames="scale-in"
          in={!isCookMode}
          timeout={300}
        >
          <img
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              width: 90,
              zIndex: 0,
            }}
            src={require(`src/assets/icons/yellow_drawer.svg`)}
          />
        </CSSTransition>
        {!isCordova && (
          <IconButton
            className={classes.closeButton}
            data-testid="AuthDrawer_closeButton"
            onClick={handleClose}
          >
            <Close className={classes.closeIcon} />
          </IconButton>
        )}

        <DrawerTop
          handleClose={handleClose}
          isCookMode={isCookMode}
          user={user}
        />

        <div
          style={{
            overflowY: "auto",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <section className={classes.linksContainer}>
            <List data-testid="links-container" className={classes.list}>
              {authStaticRoutes().map((route) => {
                if (typeof route.when === "boolean" && !route.when) return null;
                else if (
                  typeof route.when === "function" &&
                  !route.when({
                    user,
                    isCookMode,
                    isMobile,
                  })
                )
                  return null;

                return (
                  <div key={`${route.name}-${isCookMode ? "1" : "0"}`}>
                    <ListItem
                      {...({
                        to: route.pathname,
                        activeClassName: classes.activeNavLink,
                        className: classes.navlink,
                        exact: true,
                      } as any)}
                      component={NavLink as any}
                      onClick={handleClose}
                    >
                      <ListItemIcon style={{ paddingLeft: "0.5rem" }}>
                        {route.icon}
                      </ListItemIcon>
                      <Typography variant="body2" component="span">
                        {route.name}
                      </Typography>
                    </ListItem>
                    <Divider />
                  </div>
                );
              })}
              <ListItem
                key="refer a friend"
                button
                onClick={() => {
                  sharedAPI.setReferralDialogState(true);
                  handleClose();
                }}
                className={classes.navlink}
              >
                <ListItemIcon style={{ paddingLeft: "0.5rem" }}>
                  <AttachMoney style={{ fontSize: "2.5rem" }} />
                </ListItemIcon>
                <Typography variant="body2" component="span">
                  Refer a Friend
                </Typography>
              </ListItem>
              <Divider />
              <ListItem
                key="help & support"
                button
                className={classes.navlink}
                onClick={handleClick}
              >
                <ListItemIcon style={{ paddingLeft: "0.5rem" }}>
                  <Help />
                </ListItemIcon>

                <Typography variant="body2" component="span">
                  Help & Support
                </Typography>
                {nestedListOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={nestedListOpen} timeout="auto" unmountOnExit>
                <List className={classes.list} disablePadding>
                  {helpAndSupportRoutes.map((route) => (
                    <ListItem
                      key={route.name}
                      {...({
                        to: route.pathname,
                        activeClassName: classes.activeNavLink,
                        className: classes.navlink,
                        exact: true,
                      } as any)}
                      component={NavLink as any}
                      onClick={handleClose}
                      style={{ paddingLeft: "2rem" }}
                    >
                      <ListItemIcon>{route.icon}</ListItemIcon>
                      <Typography variant="body2" component="span">
                        {route.name}
                      </Typography>
                    </ListItem>
                  ))}
                  {isCookMode && (
                    <ListItem
                      className={classes.navlink}
                      style={{ paddingLeft: "2rem", cursor: "pointer" }}
                      onClick={() => sharedAPI.setContactModal(true)}
                    >
                      <ListItemIcon>
                        <Phone />
                      </ListItemIcon>
                      <Typography variant="body2" component="span">
                        Contact
                      </Typography>
                    </ListItem>
                  )}
                </List>
              </Collapse>
              <Divider />
            </List>
          </section>
          {isCordova && (
            <section className={classes.buildVersionContainer}>
              <Typography variant="caption" component="p">
                Build version: {currentBuild}
              </Typography>
            </section>
          )}
          {/* {!isCordova && (
            <>
              <List
                data-testid="AuthDrawer-appStoreLinks"
                className={classes.listHorizontal}
              >
                <ListItem
                  component={"li"}
                  disableGutters
                  button
                  className={classes.listHorizontalItem}
                >
                  <HybridLink href={_appstore.apple}>
                    <img
                      style={{ width: "100%", borderRadius: 12 }}
                      src={require(`src/assets/icons/app-apple-03.svg`)}
                    />
                  </HybridLink>
                </ListItem>
                <Divider />
                <ListItem
                  component={"li"}
                  disableGutters
                  className={classes.listHorizontalItem}
                  button
                >
                  <HybridLink href={_appstore.android}>
                    <img
                      style={{ width: "100%", borderRadius: 12 }}
                      src={require(`src/assets/icons/app-google-02.svg`)}
                    />
                  </HybridLink>
                </ListItem>
                <Divider />
              </List>
            </>
          )} */}
          <List className={classes.list}>
            <>
              {user.role === "ADMIN" && (
                <>
                  <ListItem
                    title="With great power comes great responsibility. ⚡️"
                    button
                    component="li"
                    onClick={() => {
                      history.push(`/admin/applications`);
                      handleClose();
                    }}
                    className={classes.rootListItem}
                  >
                    <Link
                      style={{
                        textDecoration: "none",
                        fontWeight: 100,
                        color: COLORS.BLACK,
                      }}
                      className={classes.linkItemLayout}
                      to={`/admin/applications`}
                    >
                      <Typography variant="body2" component="span">
                        Admin Portal{" "}
                        <span style={{ color: "purple", fontSize: 12 }}>
                          ({(process.env.REACT_APP_TAG as string)?.substring(0,7)}
                        </span>
                        <span style={{ color: 'purple', fontSize: 12 }}>
                          ,{apiVersion ?? 'N/A'})
                        </span>
                      </Typography>
                      <ListItemIcon className={classes.listItemIcon}>
                        <SupervisorAccount
                          style={{ color: "#7158e2", fontSize: "2.2rem" }}
                        />
                      </ListItemIcon>
                    </Link>
                  </ListItem>
                  <Divider />
                </>
              )}
              {!!user.restaurant && (
                <>
                  <ListItem
                    disableGutters
                    data-testid="AuthDrawer-toggleMode"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: COLORS.WHITE,
                    }}
                    component={"li"}
                  >
                    <Toggler checked={!isCookMode} onChange={toggleCookMode} />
                  </ListItem>
                  <Divider />
                </>
              )}
              <ListItem
                classes={{ root: classes.rootListItem }}
                button
                onClick={() => {
                  if (
                    isMobile
                      ? window &&
                        window.confirm &&
                        window.confirm("Are you sure you want to log out?")
                      : true
                  ) {
                    handleLogout();
                  }
                }}
                component={"li"}
              >
                <div className={classes.linkItemLayout}>
                  <Typography component="span" variant="body2">
                    Logout
                  </Typography>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ExitToApp />
                  </ListItemIcon>
                </div>
              </ListItem>
            </>
          </List>
        </div>
      </div>
    </SwipeableDrawer>
  );
};
