import { Button, IconButton, Typography } from "@material-ui/core";
import {
  InfoOutlined,
  Lock,
  Room,
  SpeakerNotes,
  Today,
} from "@material-ui/icons";
import HelpIcon from "@material-ui/icons/Help";
import moment from "moment";
import React, { FC, useContext, useState } from "react";
import { GoogleMapContainer, LocationDialog } from "src/components";
import { IEvent, IGetMe } from "src/models";
import { COLORS } from "src/styles";
import { eventTypeMap } from "src/utils/helpers";
import { OrderContext } from "../../../order-context";
import { EventInfoDeliveryNoteDialog } from "../../event-info/event-info-delivery-note-dialog";
import { EventInfoSection } from "../../event-info/event-info-section";
import { OrderSettingsDialog } from "../../event-info/order-settings-dialog";
import { useStyles } from "./mobile-event-info.styles";
import { MobilePaymentDialog } from "./mobile-payment-dialog";

interface IProps {
  getEvent: IEvent;
  getMe: IGetMe;
  total: number;
  autoFocus?: boolean;
  submitOrder: (token) => Promise<any>;
}

export const MobileEventInfo: FC<IProps> = ({
  getEvent,
  total,
  getMe,
  submitOrder,
}) => {
  const classes = useStyles();
  const {
    state: { arrivalTime, dineOption },
  } = useContext(OrderContext);
  const [orderSettingsDialogOpen, setorderSettingsDialogOpen] = useState(false);

  const [showLocationDialog, setShowLocationDialog] = useState(false);

  const closeLocationDialog = () => {
    setShowLocationDialog(false);
  };

  const handleOrderSettingsDialogOpen = () => {
    setorderSettingsDialogOpen(true);
  };

  const handleOrderSettingsDialogClose = () => {
    setorderSettingsDialogOpen(false);
  };

  return (
    <div className={classes.container}>
      <div
        onClick={() => setShowLocationDialog(true)}
        className={classes.locationContainer}
      >
        <GoogleMapContainer
          circleOverlay
          center={
            getEvent.locale.coordinates
              ? {
                  lat: getEvent.locale.coordinates.latitude,
                  lng: getEvent.locale.coordinates.longitude,
                }
              : undefined
          }
        />
      </div>
      <div className={classes.infoContainers}>
        {/* =========== Private =========== */}
        {getEvent.private && (
          <>
            <EventInfoSection
              title="Private"
              icon={
                <Lock style={{ color: COLORS.RED }} className={classes.icon} />
              }
              titleStyles={{ color: COLORS.RED }}
            />
            <section className={classes.textSection}>
              <Typography
                variant="body2"
                component="p"
                className={classes.description}
              >
                This is a private event, which means that the cook must approve
                you for security purposes. Please make sure to complete your
                profile to speed up this process. Once you have been confirmed,
                you will receive an email with the exact address and a receipt.
                If the cook doesn’t accept you in time or doesn’t accept your
                order, you will be refunded immediately.
              </Typography>
            </section>
          </>
        )}

        {/* =========== Location =========== */}
        {dineOption !== "DELIVERY" && (
          <>
            <EventInfoSection
              title={getEvent.locale.public}
              icon={<Room className={classes.icon} />}
            ></EventInfoSection>
            <section
              style={{ display: "flex" }}
              className={classes.textSection}
            >
              <Typography
                variant="body2"
                component="p"
                className={classes.description}
              >
                Exact location will be provided upon order completion
              </Typography>
              <IconButton
                disableRipple
                disableFocusRipple
                size="small"
                onClick={() => setShowLocationDialog(true)}
              >
                <HelpIcon />
              </IconButton>
            </section>
          </>
        )}

        {/* =========== Type =========== */}

        <section className={classes.customEventInfoSectionContainer}>
          <div className={classes.customEventInfoSectionIconWrapper}>
            <Today className={classes.icon} />
          </div>
          <div>
            <Typography
              variant="h4"
              component="h4"
              className={classes.customEventInfoSectionTitle}
            >
              {eventTypeMap[dineOption]}
            </Typography>
          </div>
        </section>

        {/* =========== Time =========== */}

        <section className={classes.textSection}>
          <div className={classes.customEventInfoSectionTitleWithButton}>
            <Typography
              variant="body2"
              component="time"
              className={classes.time}
            >
              {dineOption === "DELIVERY"
                ? `${moment(Number(arrivalTime)).format(
                    "MMM Do h:mm a"
                  )} - ${moment(Number(arrivalTime))
                    .add(1, "h")
                    .format("h:mm a")}`
                : `${moment(Number(arrivalTime)).calendar()}`}
            </Typography>
            <Button
              classes={{ label: classes.changeButtonLabel }}
              onClick={handleOrderSettingsDialogOpen}
              color="secondary"
            >
              Change
            </Button>
          </div>

          {getEvent.type.includes("FIXED_TIME") ? (
            <Typography
              variant="body2"
              component="p"
              className={classes.customEventInfoSectionDescription}
            >
              You will receive a notification{" "}
              <span style={{ textDecoration: "underline" }}>1 hr</span> prior to
              the event, please show up to the event on-time.
            </Typography>
          ) : null}
        </section>

        {/* =========== TAKE-OUT/DELIVERY =========== */}

        {/* <EventInfoSection
        title={eventTypeMap[dineOption]}
        icon={<Restaurant className={classes.icon} />}
      >
        {dineOption === "DELIVERY" && (
          <div className={classes.deliveryDescription}>{getMe.address}</div>
        )}
      </EventInfoSection> */}

        {/* =========== DELIVERY NOTES =========== */}
        {dineOption === "DELIVERY" && (
          <>
            <EventInfoSection
              title={"Delivery Instruction"}
              icon={<SpeakerNotes className={classes.icon} />}
            />
            <section className={classes.textSection}>
              <EventInfoDeliveryNoteDialog />
            </section>
          </>
        )}

        {/* =========== Instruction =========== */}
        {getEvent.instructions && (
          <EventInfoSection
            icon={<InfoOutlined className={classes.icon} />}
            title={"Instructions"}
          >
            <Typography
              variant="body2"
              component="p"
              className={classes.description}
              style={{ color: COLORS.RED }}
            >
              {getEvent.instructions}
            </Typography>
          </EventInfoSection>
        )}
      </div>

      <MobilePaymentDialog
        total={total}
        submitOrder={submitOrder}
        getMe={getMe}
      />

      <OrderSettingsDialog
        getMe={getMe}
        getEvent={getEvent}
        orderSettingsDialogOpen={orderSettingsDialogOpen}
        handleOrderSettingsDialogOpen={handleOrderSettingsDialogOpen}
        handleOrderSettingsDialogClose={handleOrderSettingsDialogClose}
      />

      <LocationDialog
        showLocationDialog={showLocationDialog}
        closeLocationDialog={closeLocationDialog}
        restaurant={getEvent.restaurant}
      />
    </div>
  );
};
