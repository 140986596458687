import { TextField, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { BecomeCookContext } from "src/screens/become-cook-info/store/become-cook-context";
import { ActionTypes } from "src/screens/become-cook-info/store/reducer";
import { useContentStyles } from "../styles";

export const InSlide2: React.FC = React.memo(() => {
  const classes = useContentStyles();

  const { state, dispatch } = useContext(BecomeCookContext);

  const onInputChange = (e) => {
    dispatch({
      type: ActionTypes.SET_QUESTION,
      name: e.target.name,
      value: e.target.value,
    });
  };

  return (
    <div className={classes.container}>
      <Typography variant="h2" component="h1" className={classes.header}>
        Intro Questions (1/3)
      </Typography>
      <Typography variant="body1" component="p">
        Please answer the following questions in as much detail as possible. Due
        to high volumes of applications, our team is only accepting serious
        candidates who have strong food concepts.
      </Typography>
      <section
        className={classes.questionsContainer}
        style={{ marginTop: "3rem" }}
      >
        <Typography variant="h5" component="p" className={classes.question}>
          1. What type of cooking experience do you have?*
        </Typography>
        <TextField
          multiline
          data-testid="question1"
          rows={10}
          fullWidth
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline,
            },
          }}
          name="question1"
          value={state.question1}
          onChange={(e) => onInputChange(e)}
          placeholder="Type here..."
          className={classes.textArea}
          variant="outlined"
        />
      </section>
    </div>
  );
});
