import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS } from "src/styles";
import { COLORS } from "src/styles/colors";

export const useStyles = makeStyles({
  backToEventsLink: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    textTransform: "uppercase",
    textDecoration: "none",
    color: COLORS.MEDIUM_GREEN,
  },
  searchInput: {
    paddingBottom: 10,
    paddingTop: 10,
  },
  toggleHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1em 1.75em",
    flexWrap: "wrap",
    alignItems: "center",
  },
  noResults: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    maxWidth: 500,
  },
  clearSearchButton: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    color: COLORS.LAVENDER,
    textTransform: "lowercase",
  },
  confirmationDialogWrapper: {},
  dialogTitle: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  dialogContent: {
    textAlign: "center",
  },
  yesButton: {
    color: COLORS.RED,
  },
  noButton: {
    color: COLORS.DARK_GREY,
  },
  container: {
    boxShadow: "0 2px 10px 0 #BCBCBC",
    borderRadius: 0,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginTop: 44,
    },
  },
  subcontainer: { maxWidth: 974, margin: "0 auto", width: "100%" },
  toggleContainer: {
    maxWidth: 100,
    display: "grid",
    gridTemplateColumns: "20px auto 20px",
    alignItems: "center",
  },
  h4: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    margin: 0,

    color: COLORS.DARK_GREY,

    textAlign: "right",
  },
  noGuest: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "2.5rem 0",
  },
});
