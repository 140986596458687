import { Container, Grid, useTheme } from "@material-ui/core";
import React from "react";
import { AppStatus, IGetMe } from "src/models";
import {
  ComingSoon,
  CongratsCard,
  ResourcesCard,
  WelcomeCard,
} from "./components";
import { CompleteOnboardFlowDialog } from "./components/complete-onboard-flow-dialog/complete-onboard-flow-dialog";

interface IProps {
  getMe: IGetMe;
}
export const HomeContainer: React.FC<IProps> = ({ getMe }) => {
  const appStatus = getMe.restaurant?.application.status as AppStatus;
  const theme = useTheme();

  return (
    <Container maxWidth="lg" style={{ marginTop: theme.spacing(3) }}>
      <Grid container spacing={5}>
        {appStatus === AppStatus.APPROVED && (
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <ComingSoon getMe={getMe} />
          </Grid>
        )}
        {appStatus === AppStatus.ON_HOLD && (
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <WelcomeCard getMe={getMe} />
          </Grid>
        )}
        {appStatus === AppStatus.PENDING && (
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <WelcomeCard getMe={getMe} />
          </Grid>
        )}
        {appStatus === AppStatus.SUBMITTED && (
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <CongratsCard getMe={getMe} />
          </Grid>
        )}

        <Grid item xs={12} sm={6} lg={6} xl={6}>
          <ResourcesCard status={appStatus} />
        </Grid>
      </Grid>
      <CompleteOnboardFlowDialog getMe={getMe} />
    </Container>
  );
};
