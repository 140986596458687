import { useLazyQuery, useQuery } from "@apollo/client";
import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { GET_ME } from "src/shared-graphql/queries";
import { BREAK_POINTS } from "src/styles";
import { SEARCH_RESTAURANTS } from "./api/queries";
import { DesktopView } from "./desktop/desktop-view";
import { MobileView } from "./mobile/mobile-view";

const defaultVar = {
  variables: {
    input: {
      bounds: {
        ne: {
          lat: 33.906395091341636,
          lng: -116.87336573447973,
        },
        nw: {
          lat: 33.906395091341636,
          lng: -117.76051173057348,
        },
        se: {
          lat: 33.54661501716696,
          lng: -116.87336573447973,
        },
        sw: {
          lat: 33.54661501716696,
          lng: -117.76051173057348,
        },
      },
    },
  },
};

export const Layout: React.FC = React.memo(() => {
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  const [search, { data, refetch, loading }] = useLazyQuery(
    SEARCH_RESTAURANTS,
    {
      variables: defaultVar.variables,
      notifyOnNetworkStatusChange: true,
    }
  );
  const {
    data: getMeData,
    error,
    loading: getMeLoading,
  } = useQuery(GET_ME, {
    fetchPolicy: "cache-and-network",
  });

  const refetchOrSearch = (d: any) => {
    if (!d.variables) {
      d = defaultVar;
    }
    if (refetch) {
      refetch(d.variables);
    } else {
      search(d);
    }
  };

  // Mobile view
  if (isMobile)
    return (
      <MobileView data={data} loading={loading} search={refetchOrSearch} />
    );

  // Desktop view
  return <DesktopView data={data} loading={loading} search={refetchOrSearch} />;
});
