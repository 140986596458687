import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
} from "@material-ui/core";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import { stringify } from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CookDish } from "src/assets/icons/svg/cook-dish";
import { CookMenu } from "src/assets/icons/svg/cook-menu";
import { UserMore } from "src/assets/icons/svg/user-more";
import { UserSearch } from "src/assets/icons/svg/user-search";
import { DynamicBanner } from "src/components";
import { IGetMe } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { AvatarBottomNavigationAction } from "./avatar-nav-action";
import { ChatNotificationBadge } from "./diner/chat-notification-badge";
import { NotificationBadge } from "./diner/notification-badge";
import { UpcomingMealsBadge } from "./diner/upcoming-meals-badge";
import { isBlackListed } from "./helpers";
import { useStyles } from "./layout.styles";

interface IProps {
  activeTab: any;
  isCookMode: boolean;
  getMeData: { getMe: IGetMe };
}

export const Layout: React.FC<IProps> = ({
  activeTab,
  isCookMode,
  getMeData,
}) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [height, setHeight] = useState<number | null>(null);
  const containerRef = React.useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    } else {
      setHeight(null);
    }
  }, []);
  useEffect(() => {
    if (!isBlackListed(location.pathname)) {
      const root = document.getElementById("root") as HTMLElement;
      // if (root) {
      //   root.style.marginBottom = `${height}px`;
      //   return () => {
      //     root.style.marginBottom = "unset";
      //   };
      // }
    }
  }, [location.pathname]);

  const loggedIn = getMeData && getMeData.getMe;

  const isPendingPermit =
    getMeData?.getMe?.restaurant?.application?.status === "PENDING";

  return (
    <section
      className={
        isBlackListed(location.pathname)
          ? classes.rootBottomNavigationDisplayNone
          : classes.rootBottomNavigation
      }
    >
      <DynamicBanner />
      <BottomNavigation
        showLabels
        value={activeTab}
        ref={containerRef}
        data-testid="BottomNavigation-container"
        id="bottom-nav-container"
      >
        {!loggedIn
          ? [
              <BottomNavigationAction
                key="search"
                label="Menus"
                value="search"
                classes={{
                  label: classes.label,
                  root: classes.rootAction,
                  selected: classes.selected,
                }}
                icon={<UserSearch />}
                onClick={() => history.push("/menus")}
              />,

              <BottomNavigationAction
                key="sign-in"
                onClick={() => {
                  history.replace({ search: stringify({ state: "login" }) });
                  sharedAPI.setUserSignInDialog(true);
                }}
                label=""
                icon={
                  <Button
                    component={"div"}
                    variant="contained"
                    key="login button"
                    color="primary"
                    fullWidth
                    className={classes.loginBtn}
                  >
                    Sign In
                  </Button>
                }
              />,

              <BottomNavigationAction
                key="more"
                label="More"
                value="more"
                classes={{
                  label: classes.label,
                  root: classes.rootAction,
                  selected: classes.selected,
                }}
                icon={<UserMore />}
                onClick={() => sharedAPI.setGlobalDrawerState(true)}
              />,
            ]
          : isCookMode
          ? [
              <BottomNavigationAction
                key={"home"}
                label={"Home"}
                value={"home"}
                classes={{
                  label: classes.label,
                  root: classes.rootAction,
                  selected: classes.selected,
                }}
                icon={<HomeOutlinedIcon />}
                onClick={() => history.push("/c/home")}
              />,
              <BottomNavigationAction
                key="menus"
                label="Menus"
                value="menus"
                classes={{
                  label: classes.label,
                  root: classes.rootAction,
                  selected: classes.selected,
                }}
                icon={<CookMenu />}
                onClick={() => history.push("/c/menus")}
              />,
              <BottomNavigationAction
                key="dishes"
                label="Dishes"
                value="dishes"
                classes={{
                  label: classes.label,
                  root: classes.rootAction,
                  selected: classes.selected,
                }}
                icon={<CookDish />}
                onClick={() => history.push("/c/dishes")}
              />,
              <BottomNavigationAction
                key="alerts"
                label="Alerts"
                value="alerts"
                selected={true}
                classes={{
                  label: classes.label,
                  root: classes.rootAction,
                  selected: classes.selected,
                }}
                icon={<NotificationBadge />}
                onClick={() => history.push("/notifications")}
              />,
            ]
          : [
              <BottomNavigationAction
                key="search"
                label="Search"
                value="search"
                classes={{
                  label: classes.label,
                  root: classes.rootAction,
                  selected: classes.selected,
                }}
                icon={<UserSearch />}
                onClick={() => history.push("/menus")}
              />,
              <BottomNavigationAction
                key="orders"
                label="Orders"
                value="orders"
                classes={{
                  label: classes.label,
                  root: classes.rootAction,
                  selected: classes.selected,
                }}
                icon={<UpcomingMealsBadge />}
                onClick={() => history.push("/d")}
              />,
              <BottomNavigationAction
                key="alerts"
                label="Alerts"
                value="alerts"
                classes={{
                  label: classes.label,
                  root: classes.rootAction,
                  selected: classes.selected,
                }}
                selected={true}
                icon={<NotificationBadge />}
                onClick={() => history.push("/notifications")}
              />,
              <BottomNavigationAction
                key="chat"
                label="Chat"
                value="chat"
                selected={true}
                classes={{
                  label: classes.label,
                  root: classes.rootAction,
                  selected: classes.selected,
                }}
                icon={<ChatNotificationBadge />}
                onClick={() => history.push("/chat")}
              />,
            ]}
        {loggedIn && <AvatarBottomNavigationAction />}
      </BottomNavigation>
    </section>
  );
};
