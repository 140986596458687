import { gql } from "@apollo/client";

export const GET_EVENT = gql`
  query getEvent($input: ID!) {
    getEvent(id: $input) {
      id
      name
      type
      price
      private
      status
      startTime
      endTime
      instructions
      tags
      locale {
        public
        private
        coordinates {
          longitude
          latitude
        }
      }
      eventUser {
        id
        reservedFor
        arrivalTime
        dineOption
        eventId
        paidAmount
        deliveryAddress
        deliveryCost
        creditAmount
        serviceFeeAmount
        taxAmount
        status
        updatedAt
        checkedIn
        orderedDishes {
          id
          name
          price
          imagesGQL {
            publicId
            small
            medium
            large
            original
          }
          options {
            name
          }
          EventUserDish {
            id
            count
            options {
              name
              count
              addition
            }
          }
        }
        orders {
          id
          reservedFor
          arrivalTime
          dineOption
          paidAmount
          deliveryAddress
          deliveryCost
          creditAmount
          serviceFeeAmount
          taxAmount
          status
          updatedAt
          checkedIn
          orderedDishes {
            id
            name
            price
            imagesGQL {
              publicId
              small
              medium
              large
              original
            }
            options {
              name
            }
            OrderDish {
              id
              count
              options {
                name
                count
                addition
              }
            }
          }
        }
      }
      dishes {
        id
        name
        options {
          name
          addition
        }
        DishEvent {
          id
          price
          sold
        }
        allergens {
          id
          name
        }
        __typename @skip(if: true)
      }
      restaurant {
        id
        name
        host {
          id
          firstName
          lastName
          picture
        }
      }
    }
  }
`;
