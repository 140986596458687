import { gql } from "@apollo/client";

export const GET_RESTAURANT_REVIEWS = gql`
  query adminGetRestaurantReviews($input: AdminGetRestaurantReviewsPL) {
    adminGetRestaurantReviews(adminGetRestaurantReviewsPL: $input) {
      count
      rows {
        id
        text
        quality
        hospitality
        updatedAt
        accuracy
        status
        tipAmount
        eventInfo {
          name
          hostName
        }
        restaurant {
          id
          name
        }
        guest {
          id
          email
          phoneNumber
          firstName
          lastName
        }
      }
    }
  }
`;
