import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  AttachMoney,
  ChevronRight,
  EventSeat,
  Help,
  QueryBuilder,
  Room,
} from "@material-ui/icons";
import classNames from "classnames";
import { isEqual } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Prompt, useHistory, useLocation } from "react-router-dom";
import { AutoCompleteContainer, CoverPhotoUploader } from "src/components";
import { upload } from "src/components/img-upload/api/upload";
import { IImage } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { DateTimeFieldSet } from "./fieldsets";
import { useStyles } from "./form.styles";
import { initialState as init, reducer } from "./state";
import { Actions } from "./state/actions";
import { IEventFormState } from "./state/reducers";

export interface IEventFormSubmitPL {
  eventType: string[];
  name: string;
  address: string;
  displayLocation: string;
  description: string;
  instructions: string;
  date: string;
  startTime: string;
  duration: string | number;
  reserveByDate: string;
  reserveByTime: string;
  images: string[];
  price?: string;
  minSeats?: string;
  maxSeats?: string;
  bufferTime?: string;
  pricePerMin?: number;
  pricePerMile?: number;
  maximumDriveTime?: number;
  minimumOrderAmount?: number;
}

interface EventFormProps {
  onSubmit: (pl: IEventFormSubmitPL) => Promise<any>;
  initialState?: IEventFormState;
  edit?: boolean;
  isActive?: boolean;
}

export const EventForm: React.FC<EventFormProps> = ({
  edit = false,
  isActive = false,
  onSubmit,
  initialState = init,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [deliveryToolTipOpen, setDeliveryToolTip] = useState(false);
  const [menuTypeToolTipOpen, setMenuTypeToolTip] = useState(false);
  const [menuDateTimeTooTipOpen, setMenuDateTimeTooTipOpen] = useState(false);
  const isBlocked = !isEqual(initialState, state);
  const [OK, setOK] = useState<boolean>(false);

  const warning = `Are you sure you want to navigate away without saving your changes?\n
Click "Save & Exit" to save your progress.`;

  // HANDLERS
  const onUpload = (image: IImage) =>
    dispatch({ type: Actions.UPLOAD_IMAGE, image });
  // const togglePrivateEvent = () =>
  //   dispatch({ type: Actions.TOGGLE_PRIVATE_EVENT });
  const onChangeHandler = (e: { target: { value: any; name: string } }) => {
    return dispatch({
      type: Actions.SET_FIELD,
      name: e.target.name,
      value: e.target.value,
    });
  };
  const submitHandler = (e) => {
    e.preventDefault();

    const {
      name,
      date,
      startTime: _startTime,
      duration: _duration,
      description,
      instructions,
      displayLocation,
      minSeats,
      maxSeats,
      price,
    } = e.target.elements;

    onSubmit({
      name: name.value,
      date: date.value,
      startTime: _startTime.value,
      duration: _duration.value,
      reserveByDate: state.reserveByDate,
      reserveByTime: state.reserveByTime,
      description: description.value,
      instructions: instructions.value,
      address: state.address,
      maximumDriveTime: Number(state.maximumDriveTime),
      minimumOrderAmount: Number(state.minimumOrderAmount),
      pricePerMin: Number(state.pricePerMin),
      pricePerMile: Number(state.pricePerMile),
      displayLocation: displayLocation.value,
      eventType: state.eventType,
      minSeats: minSeats ? minSeats.value : "1",
      maxSeats: maxSeats ? maxSeats.value : "1",
      price: price ? price.value : "",
      images: state.images ? [JSON.stringify(state.images)] : [],
      bufferTime: state.bufferTime ? state.bufferTime : "900000",
    })
      .then(
        ({
          data,
        }: {
          data: {
            cookUpdateEvent?: any;
            cookCreateEvent?: any;
            cookUpdateActiveEvent?: any;
          };
        }) => {
          const { id, name: eventName } = edit
            ? isActive
              ? data.cookUpdateActiveEvent
              : data.cookUpdateEvent
            : data.cookCreateEvent;
          setOK(true);

          return history.push(
            `/c/menus/dishes/${id}-${eventName.replace(/ /g, "-")}`,
            {
              from: location.pathname,
            }
          );
        }
      )
      .catch((_e) => {
        return sharedAPI.setSnackbarMsg({
          type: "error",
          msg: _e.message.replace(/Graphql Error: /i, ""),
        });
      });
  };

  const saveAndExitHandler = () => {
    const pl: IEventFormSubmitPL = {
      name: state.name,
      date: state.date ? state.date : moment().format("YYYY-MM-DD"),
      startTime: state.startTime ? state.startTime : moment().format("HH:mm"),
      duration: state.duration,
      reserveByDate: state.reserveByDate
        ? state.reserveByDate
        : moment().format("YYYY-MM-DD"),
      reserveByTime: state.reserveByTime
        ? state.reserveByTime
        : moment().format("HH:mm"),
      description: state.description,
      instructions: state.instructions,
      address: state.address,
      maximumDriveTime: Number(state.maximumDriveTime),
      minimumOrderAmount: Number(state.minimumOrderAmount),
      pricePerMin: Number(state.pricePerMin),
      pricePerMile: Number(state.pricePerMile),
      displayLocation: state.displayLocation,
      eventType: state.eventType,
      minSeats: state.minSeats ? state.minSeats : "1",
      maxSeats: state.maxSeats ? state.maxSeats : "1",
      price: state.price,
      images: state.images ? [JSON.stringify(state.images)] : [],
      bufferTime: state.bufferTime,
    };

    return onSubmit(pl)
      .then(() => setOK(true))
      .then(() => {
        history.push("/c/menus?status=PENDING");
        sharedAPI.setSnackbarMsg({
          msg: "Successfully saved!",
          type: "success",
        });
      })
      .catch((_e) => {
        return sharedAPI.setSnackbarMsg({
          type: "error",
          msg: _e.message.replace(/Graphql Error: /i, ""),
        });
      });
  };

  const types = [
    { label: "To-go", key: "TAKE_OUT" },
    { label: "Pop-up", key: "FIXED_TIME" },
    { label: "Delivery", key: "DELIVERY" },
    { label: "Dine-in", key: "DROP_IN" },
  ];

  return (
    <>
      <Prompt when={isBlocked && !OK} message={warning} />
      <form
        className={classes.form}
        style={{ backgroundColor: COLORS.WHITE }}
        onSubmit={submitHandler}
      >
        <div style={{ backgroundColor: "#F8F8F8" }}>
          <div className={classes.formHeaderWrapper}>
            <Typography variant="h2" component="h1" className={classes.header}>
              {edit ? `Edit` : `Create`} Menu
            </Typography>
          </div>
        </div>
        <div
          className={classes.formFieldContent}
          style={{ position: "relative" }}
        >
          <Typography
            variant="body2"
            component="p"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              padding: "1em",
            }}
            className={classes.hintText}
          >
            * Required Fields
          </Typography>
          <div className={classes.row}>
            <Typography
              component="label"
              variant="caption"
              className={classes.inputLabel}
              aria-hidden="true"
            >
              Cover Photo
            </Typography>
            <CoverPhotoUploader
              image={state.images ? (state.images.original as string) : ""}
              setImage={onUpload}
              uploadImage={upload}
            />
          </div>
          <div className={classes.sectionTitleContainer}>
            <Tooltip
              open={menuTypeToolTipOpen}
              onClose={() => setMenuTypeToolTip(false)}
              disableHoverListener={true}
              style={{ cursor: "pointer" }}
              onClick={() => setMenuTypeToolTip(!menuTypeToolTipOpen)}
              placement="top"
              title={
                isActive ? (
                  <div>
                    <Typography variant="body2" component="p">
                      Cannot edit menu type once menu is published
                    </Typography>
                  </div>
                ) : (
                  <div>
                    <Typography variant="body2" component="p">
                      <b>To go</b>: Diners will pay based on dish price, and
                      pick up at your house
                    </Typography>
                    <Typography variant="body2" component="p">
                      <b>Pop up</b>: Diners will pay per head count, and they
                      are expected to show up at the start time of the menu
                    </Typography>
                    <Typography variant="body2" component="p">
                      <b>Delivery</b>: Diners will get their food delivered
                    </Typography>
                    <Typography variant="body2" component="p">
                      <b>Dine in</b>: Similar to To-go, but they will eat in
                    </Typography>
                  </div>
                )
              }
            >
              <div className={classes.sectionTitleContainer}>
                <Typography
                  variant="h4"
                  component="h4"
                  className={classes.sectionTitle}
                >
                  Menu Type
                </Typography>
                <Help className={classes.helpIcon} />
              </div>
            </Tooltip>
          </div>
          <Divider />
          <FormControl
            disabled={isActive}
            fullWidth
            className={classes.row}
            component={"fieldset" as "div"}
          >
            <FormLabel
              classes={{ root: classes.inputLabel }}
              component={"legend" as "span"}
            ></FormLabel>

            <div className={classes.radioGroup}>
              {types.map((t) => {
                let value = state.eventType;
                if (
                  t.key === "FIXED_TIME" &&
                  state.eventType.includes("FIXED_TIME")
                ) {
                  value = [];
                } else if (
                  t.key === "FIXED_TIME" &&
                  !state.eventType.includes("FIXED_TIME")
                ) {
                  value = ["FIXED_TIME"];
                } else if (state.eventType.includes(t.key)) {
                  value = state.eventType.filter((ty) => ty !== t.key);
                } else {
                  value = [...state.eventType, t.key];
                }
                return (
                  <FormControlLabel
                    classes={{ label: classes.radioLabel }}
                    key={t.key}
                    control={
                      <Checkbox
                        checked={state.eventType.includes(t.key)}
                        classes={{
                          root: classes.checkbox,
                          disabled: classes.checkboxDisabled,
                        }}
                        disabled={
                          isActive ||
                          (state.eventType.includes("FIXED_TIME") &&
                            t.key !== "FIXED_TIME") ||
                          (state.eventType.length >= 2 &&
                            !state.eventType.includes(t.key))
                        }
                        onChange={() =>
                          onChangeHandler({
                            target: {
                              value,
                              name: "eventType",
                            },
                          })
                        }
                      />
                    }
                    label={t.label}
                  />
                );
              })}
            </div>
          </FormControl>

          {state.eventType.includes("DELIVERY") && (
            <>
              <div className={classes.sectionTitleContainer}>
                <Tooltip
                  open={deliveryToolTipOpen}
                  onClose={() => setDeliveryToolTip(false)}
                  disableHoverListener={true}
                  style={{ cursor: "pointer" }}
                  onClick={() => setDeliveryToolTip(!deliveryToolTipOpen)}
                  placement="top"
                  title={
                    isActive ? (
                      <div>
                        <Typography variant="body2" component="p">
                          Cannot edit delivery info once menu is published
                        </Typography>
                      </div>
                    ) : (
                      <div>
                        <Typography variant="body2" component="p">
                          <b>Minimum Order Amount</b>: Minimum amount of food in
                          DOLLARS the guest need to purchase
                        </Typography>
                        <Typography variant="body2" component="p">
                          <b>$/Minute of Driving</b>: Dollars you would charge
                          per MINUTE on the road including traffic (recommend:
                          $0.2){" "}
                        </Typography>
                        <Typography variant="body2" component="p">
                          <b>Maximum Drive Time</b>: Maximum time in MINUTE you
                          want to spend on the road
                        </Typography>
                      </div>
                    )
                  }
                >
                  <div className={classes.sectionTitleContainer}>
                    <Typography
                      variant="h4"
                      component="h4"
                      className={classes.sectionTitle}
                    >
                      Delivery Info
                    </Typography>
                    <Help className={classes.helpIcon} />
                  </div>
                </Tooltip>
              </div>
              <Divider />

              <div className={classes.row}>
                <div className={classes.popupFieldsWrapper}>
                  <div>
                    <Typography
                      component="label"
                      variant="caption"
                      className={classes.inputLabel}
                      htmlFor="minimum-order-amount"
                    >
                      Minimum Order Amount*{" "}
                    </Typography>
                    <OutlinedInput
                      value={state.minimumOrderAmount}
                      onChange={onChangeHandler}
                      fullWidth
                      disabled={isActive}
                      classes={{
                        root: classes.inputRoot,
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                      }}
                      required
                      labelWidth={0}
                      inputProps={{
                        id: "minimum-order-amount",
                        type: "number",
                        name: "minimumOrderAmount",
                        step: "1",
                        min: "0",
                        max: "50",
                      }}
                      startAdornment={
                        <AttachMoney style={{ color: COLORS.DARK_GREY }} />
                      }
                    />
                  </div>
                  <div>
                    <Typography
                      component="label"
                      variant="caption"
                      className={classes.inputLabel}
                      htmlFor="maximum-drive-time"
                    >
                      Maximum Drive Time (min)*
                    </Typography>
                    <OutlinedInput
                      value={state.maximumDriveTime}
                      onChange={onChangeHandler}
                      fullWidth
                      disabled={isActive}
                      classes={{
                        root: classes.inputRoot,
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                      }}
                      required
                      labelWidth={0}
                      inputProps={{
                        id: "maximum-drive-time",
                        type: "number",
                        name: "maximumDriveTime",
                        step: "5",
                        max: "120",
                      }}
                      endAdornment={
                        <QueryBuilder style={{ color: COLORS.DARK_GREY }} />
                      }
                    />
                  </div>
                  <div>
                    <Typography
                      component="label"
                      variant="caption"
                      className={classes.inputLabel}
                      htmlFor="price-per-min"
                    >
                      $/Minute Of Driving*
                    </Typography>
                    <OutlinedInput
                      value={state.pricePerMin}
                      onChange={onChangeHandler}
                      disabled={isActive}
                      fullWidth
                      classes={{
                        root: classes.inputRoot,
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                      }}
                      required
                      labelWidth={0}
                      inputProps={{
                        id: "price-per-min",
                        type: "number",
                        name: "pricePerMin",
                        step: "0.1",
                        min: "0",
                        max: "0.5",
                      }}
                      startAdornment={
                        <AttachMoney style={{ color: COLORS.DARK_GREY }} />
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {state.eventType.includes("FIXED_TIME") && (
            <>
              <Typography
                variant="h4"
                component="h4"
                className={classes.sectionTitle}
              >
                Pop-up Info
              </Typography>
              <Divider />
              <div className={classes.row}>
                <div className={classes.popupFieldsWrapper}>
                  <div>
                    <Typography
                      component="label"
                      variant="caption"
                      className={classes.inputLabel}
                      htmlFor="event-price-input"
                    >
                      Price (per seat)*
                    </Typography>
                    <OutlinedInput
                      disabled={isActive}
                      value={state.price}
                      onChange={onChangeHandler}
                      fullWidth
                      classes={{
                        root: classes.inputRoot,
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                      }}
                      required
                      labelWidth={0}
                      inputProps={{
                        id: "event-price-input",
                        type: "number",
                        name: "price",
                        step: ".01",
                        min: "0",
                      }}
                      startAdornment={
                        <AttachMoney style={{ color: COLORS.DARK_GREY }} />
                      }
                    />
                  </div>
                  <div>
                    <Typography
                      component="label"
                      variant="caption"
                      className={classes.inputLabel}
                      htmlFor="maximum-seats-input"
                    >
                      Maximum Seats*
                    </Typography>
                    <OutlinedInput
                      value={state.maxSeats}
                      onChange={onChangeHandler}
                      fullWidth
                      classes={{
                        root: classes.inputRoot,
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                      }}
                      required
                      labelWidth={0}
                      inputProps={{
                        id: "maximum-seats-input",
                        type: "number",
                        name: "maxSeats",
                        step: "1",
                        min: state.minSeats,
                      }}
                      endAdornment={
                        <EventSeat style={{ color: COLORS.DARK_GREY }} />
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <div className={classes.sectionTitleContainer}>
            <Tooltip
              open={menuDateTimeTooTipOpen}
              onClose={() => setMenuDateTimeTooTipOpen(false)}
              disableHoverListener={true}
              style={{ cursor: "pointer" }}
              onClick={() => setMenuDateTimeTooTipOpen(!menuDateTimeTooTipOpen)}
              placement="top"
              title={
                <div>
                  <Typography variant="body2" component="p">
                    <b>Date/Start time</b>: Menu start date/time
                  </Typography>
                  <Typography variant="body2" component="p">
                    <b>Duration</b>: How long would this menu last?
                  </Typography>
                  <Typography variant="body2" component="p">
                    <b>Reserve by date/time</b>: Date/time when you will stop
                    taking orders
                  </Typography>
                </div>
              }
            >
              <div className={classes.sectionTitleContainer}>
                <Typography
                  variant="h4"
                  component="h4"
                  className={classes.sectionTitle}
                >
                  Menu date/time
                </Typography>
                <Help className={classes.helpIcon} />
              </div>
            </Tooltip>
          </div>
          <Divider />
          <Divider />
          <DateTimeFieldSet
            classes={classes}
            isActive={isActive}
            date={state.date}
            duration={state.duration}
            bufferTime={state.bufferTime}
            reserveByDate={state.reserveByDate}
            reserveByTime={state.reserveByTime}
            startTime={state.startTime}
            eventType={state.eventType}
            onChangeHandler={onChangeHandler}
          />

          <Typography
            variant="h4"
            component="h4"
            className={classes.sectionTitle}
            style={{ marginTop: 10 }}
          >
            Menu Location
          </Typography>
          <Divider />
          <div
            className={classNames(classes.locationFieldsWrapper, classes.row)}
          >
            <div>
              <Typography
                component="label"
                variant="caption"
                className={classes.inputLabel}
                htmlFor="address-autocomplete-input"
              >
                Address*
              </Typography>
              <AutoCompleteContainer
                onSelect={(l) =>
                  onChangeHandler({
                    target: { name: "address", value: l },
                  })
                }
                onKey={(l) =>
                  onChangeHandler({
                    target: { name: "address", value: l },
                  })
                }
                initialState={state.address}
                TextFieldProps={{
                  fullWidth: true,
                  variant: "outlined",
                  required: true,
                  inputProps: {
                    maxLength: 200,
                  },
                  InputProps: {
                    labelWidth: 0,
                    id: "address-autocomplete-input",
                    classes: {
                      root: classes.inputRoot,
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                    endAdornment: <Room style={{ color: COLORS.DARK_GREY }} />,
                  },
                }}
              />
            </div>

            <div>
              <Typography
                component="label"
                variant="caption"
                className={classes.inputLabel}
                htmlFor="event-displayLocation-input"
              >
                Display Location*
              </Typography>
              <OutlinedInput
                value={state.displayLocation}
                onChange={onChangeHandler}
                placeholder="Riverside, CA..."
                required
                fullWidth
                classes={{
                  root: classes.inputRoot,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                }}
                labelWidth={0}
                inputProps={{
                  id: "event-displayLocation-input",
                  type: "text",
                  name: "displayLocation",
                  maxLength: 200,
                }}
                endAdornment={<Room style={{ color: COLORS.DARK_GREY }} />}
              />
            </div>
          </div>

          <Typography
            variant="h4"
            component="h4"
            className={classes.sectionTitle}
          >
            Menu Info
          </Typography>
          <Divider />
          <div
            className={classNames(classes.eventNameInputWrapper, classes.row)}
          >
            <Typography
              component="label"
              variant="caption"
              className={classes.inputLabel}
              htmlFor="event-name-input"
            >
              Name*
            </Typography>
            <OutlinedInput
              value={state.name}
              onChange={onChangeHandler}
              fullWidth
              classes={{
                root: classes.inputRoot,
                notchedOutline: classes.notchedOutline,
                input: classes.input,
              }}
              placeholder="Give your event a name that describes what you're serving"
              required
              labelWidth={0}
              inputProps={{
                id: "event-name-input",
                type: "text",
                name: "name",
                maxLength: 200,
                pattern: "[^()/]+",
                title:
                  "alpha-numeric values only, no parentheses or forward slashes.",
              }}
            />
          </div>
          <div className={classes.row}>
            <Typography
              component="label"
              variant="caption"
              className={classes.inputLabel}
              htmlFor="event-description-input"
            >
              Description*
            </Typography>
            <OutlinedInput
              required
              value={state.description}
              onChange={onChangeHandler}
              multiline
              fullWidth
              rows={5}
              rowsMax={5}
              placeholder={"Give your guests a description of your event."}
              classes={{
                root: classes.inputRoot,
                notchedOutline: classes.notchedOutline,
                input: classes.textarea,
              }}
              labelWidth={0}
              inputProps={{
                id: "event-description-input",
                type: "text",
                name: "description",
                maxLength: 600,
              }}
            />
          </div>

          <div className={classes.row}>
            <Typography
              component="label"
              variant="caption"
              className={classes.inputLabel}
              htmlFor="event-instructions-input"
            >
              Instructions
            </Typography>
            <OutlinedInput
              value={state.instructions}
              onChange={onChangeHandler}
              multiline
              fullWidth
              rows={5}
              rowsMax={5}
              placeholder={
                "If you have special instructions for your customers, share them here."
              }
              classes={{
                root: classes.inputRoot,
                notchedOutline: classes.notchedOutline,
                input: classes.textarea,
              }}
              labelWidth={0}
              inputProps={{
                id: "event-instructions-input",
                type: "text",
                name: "instructions",
                maxLength: 600,
              }}
            />
          </div>

          {/* <div className={classes.row}>
            <FormControlLabel
              checked={state.private}
              onChange={togglePrivateEvent}
              classes={{ label: classes.radioLabel }}
              control={<Checkbox value="private" name="private" />}
              label="Private Event**"
            />
            <Typography variant="body2" component="p"  className={classes.hintText}>
              **By checking this box, diners can only order if they are approved
              by you.
            </Typography>
          </div> */}

          <div className={classNames(classes.actionButtons, classes.row)}>
            <Button
              onClick={saveAndExitHandler}
              classes={{ root: classes.saveAndExitButton }}
            >
              {"Save & Exit"}
            </Button>
            <Button
              classes={{ contained: classes.submitButton }}
              variant="contained"
              type="submit"
            >
              {isActive ? (
                <>
                  Update Dishes{" "}
                  <ChevronRight style={{ verticalAlign: "middle" }} />
                </>
              ) : (
                "Next"
              )}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};
