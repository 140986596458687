import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useLayoutStyles = makeStyles({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "scroll",
    overflowX: "hidden",
    paddingBottom: 48,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      paddingBottom: "calc(48px + env(safe-area-inset-bottom))",
    },
  },
  root: { maxWidth: "unset" },
  headerSection: {
    position: "-webkit-sticky", // for sticky position on safari 👋
    top: 0,
    zIndex: 1,
    borderBottom: "1px solid lightgrey",
  },
  noNotificationContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: "grey",
    padding: "20px",
    flexGrow: 1,
  },
  bagImage: {
    height: 50,
    margin: "0 auto",
  },
  notificationHeader: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    margin: ".5em 0",
  },
  markAllBtn: {
    color: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",

    backgroundColor: "unset",
    border: "none",
    cursor: "pointer",
  },
  notifications: {},
});
