import amplitude from "amplitude-js";
import { LocalStorageHelper } from "src/utils/helpers";
const uuidv4 = require("uuid/v4");

if (
  process.env.REACT_APP_ENV === "production" ||
  process.env.REACT_APP_ENV === "cordova"
) {
  amplitude.getInstance().init("cb51440275468f90186f0cdae096687e", undefined, {
    includeUtm: true,
    includeReferrer: true,
    includeGclid: true,
  });
} else {
  amplitude.getInstance().init("1f24d0757ea8d55b88632920d9fc2ca4", undefined, {
    includeUtm: true,
    includeReferrer: true,
    includeGclid: true,
  });
}

let userId;

const deviceId = amplitude.getInstance().options.deviceId;
if (deviceId) {
  userId = deviceId;
} else {
  if (localStorage.getItem("foodnome-deviceId")) {
    userId = localStorage.getItem("foodnome-deviceId");
  } else {
    userId = uuidv4();
    LocalStorageHelper.setItem("foodnome-deviceId", userId);
  }
}

export { amplitude, userId };
