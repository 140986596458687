import { useMutation } from "@apollo/client";
import React, { FC, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { IEvent, IGetMe } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { GET_ME } from "src/shared-graphql/queries";
import { useScrollPosition } from "src/utils/hooks";
import { SelectDropIn, SelectSeat } from ".";
import { authAPI } from "../../api";
import { DINER_GET_DELIVERY_COST } from "../../gather-address-dialog/graphql/queries";
import { OrderContext } from "../../order-context";
import { ActionTypes } from "../../order-reducer";
import { CheckoutButton } from "./checkout-button";
import { useStyles } from "./mobile-order.styles";

interface IProps {
  getEvent: IEvent;
  getMe?: IGetMe;
  noOrder: boolean;
}

export const MobileOrder: FC<IProps> = ({ getEvent, getMe, noOrder }) => {
  const history = useHistory();
  const classes = useStyles();
  const [animate, setAnimate] = useState(false);
  const [getDeliveryCost, { loading }] = useMutation(DINER_GET_DELIVERY_COST, {
    refetchQueries: [{ query: GET_ME }],
  });

  const location = useLocation();

  const {
    state: { seats, orderedDishes, dineOption, dialogView, arrivalTime },
    dispatch,
  } = useContext(OrderContext);
  const [sticky, setSticky] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== sticky) setSticky(isShow);
    },
    [sticky],
    undefined,
    undefined,
    undefined
  );

  useEffect(() => {
    if (animate === true) {
      setTimeout(() => {
        setAnimate(false);
      }, 2000); // wait 2 seconds
    }
  }, [animate]);

  const persistMobileOrder =
    getEvent.type.includes("FIXED_TIME") ||
    orderedDishes.some((d) => d.count > 0);

  const totalCost = getEvent.type.includes("FIXED_TIME")
    ? getEvent.price * seats
    : orderedDishes.reduce((acc, next) => {
        let _total = 0;
        if (next.options?.length) {
          _total = next.options?.reduce(
            (_acc, _next) =>
              _acc +
              (_next?.count ?? 0) *
                (_next?.addition + (next.DishEvent?.price ?? 0)),
            0
          );
        } else {
          _total = (next.DishEvent?.price ?? 0) * next.count;
        }
        return acc + _total;
      }, 0);

  let total = totalCost;
  if (getMe && getMe.nomes + getMe.credits >= total) {
    total = 0;
  } else if (getMe) {
    total = total - getMe.nomes - getMe.credits;
  }

  const disabled =
    (dineOption === "DELIVERY" &&
      getEvent.deliveryInfo &&
      totalCost < getEvent.deliveryInfo.minimumOrderAmount) ||
    (getEvent.type.includes("FIXED_TIME") && getEvent.seatsLeft <= 0);
  return (
    <div className={classes.container}>
      {getEvent.type.includes("FIXED_TIME") &&
        getEvent.seatsLeft > 0 &&
        !getEvent.name.includes("Bulk order for") && (
          <SelectSeat seats={seats} dispatch={dispatch} getEvent={getEvent} />
        )}
      {!getEvent.type.includes("FIXED_TIME") && (
        <>
          <CSSTransition in={animate} classNames="tada" timeout={450}>
            <SelectDropIn error={animate} getEvent={getEvent} getMe={getMe} />
          </CSSTransition>
        </>
      )}
      <div
        id="checkoutBtnContainer"
        style={{
          position: "fixed",
          width: "100%",
          zIndex: 2,
          transform: sticky || !noOrder ? "translateY(0)" : "translateY(100%)",
          transition: "transform 400ms ease-in",
          bottom: 0,
          left: 0,
        }}
        // className={classes.checkoutBtnContainerMobile}
      >
        {/* <CSSTransition in={!noOrder} classNames="bounce" timeout={450}> */}
        <CheckoutButton
          currentUser={getMe}
          getEvent={getEvent}
          isMobile
          disabled={disabled}
          handleSubmit={() => {
            if (noOrder) {
              return sharedAPI.setSnackbarMsg({
                type: "error",
                msg: "Please select a dish",
              });
            }

            if (
              !dineOption ||
              (!getEvent.type.includes("FIXED_TIME") &&
                getEvent.soldOutTimeSlots?.some(
                  (slot) =>
                    Number(arrivalTime) >= Number(slot[0]) &&
                    Number(arrivalTime) < Number(slot[1])
                ))
            ) {
              window.scrollTo(0, 0);
              setAnimate(true);
              return sharedAPI.setSnackbarMsg({
                type: "error",
                msg: "Please select one of the options",
              });
            }

            if (!arrivalTime && !getEvent.type.includes("FIXED_TIME")) {
              window.scrollTo(0, 0);
              setAnimate(true);
              return sharedAPI.setSnackbarMsg({
                type: "error",
                msg: "Please select a time you want your food",
              });
            }

            if (
              !getEvent.type.includes("FIXED_TIME") &&
              getEvent.soldOutTimeSlots?.some(
                (slot) =>
                  Number(arrivalTime) >= Number(slot[0]) &&
                  Number(arrivalTime) < Number(slot[1])
              )
            ) {
              window.scrollTo(0, 0);
              setAnimate(true);
              return sharedAPI.setSnackbarMsg({
                type: "error",
                msg: "That timeslot is soldout",
              });
            }

            if (dineOption !== "DELIVERY") {
              dialogView
                ? dispatch({
                    type: ActionTypes.SET_EVENT_DIALOG_VIEW,
                    payload: "checkout",
                  })
                : history.push(`${location.pathname}/checkout`);
              return;
            } else if (dineOption === "DELIVERY") {
              if (!getMe?.address) {
                return dispatch({
                  type: ActionTypes.SET_ADDRESS_DIALOG_STATUS,
                  payload: {
                    url: `${location.pathname}/checkout`,
                    total: totalCost,
                  },
                });
              }
              return getDeliveryCost({
                variables: {
                  input: {
                    eventId: getEvent.id,
                    address: getMe?.address,
                    total: totalCost,
                  },
                },
              })
                .then((_d) => {
                  dispatch({
                    type: ActionTypes.SET_DELIVERY_COST,
                    payload: _d.data.dinerGetDeliveryCost.cost,
                  });
                  dialogView
                    ? dispatch({
                        type: ActionTypes.SET_EVENT_DIALOG_VIEW,
                        payload: "checkout",
                      })
                    : history.push(`${location.pathname}/checkout`);
                })
                .catch((e) =>
                  sharedAPI.setSnackbarMsg({
                    type: "error",
                    msg: e.message.replace(/Graphql Error: /i, ""),
                  })
                );
            }
          }}
          dialogCallback={(pl: {
            email: string;
            firstName: string;
            lastName: string;
          }) => authAPI.checkoutAsGuest(pl)}
        >
          {getEvent.type.includes("FIXED_TIME") && getEvent.seatsLeft <= 0
            ? "Sorry, it is sold out"
            : `View Order`}
          {!!(total || total === 0) &&
            !(
              getEvent.type.includes("FIXED_TIME") && getEvent.seatsLeft <= 0
            ) && (
              <span style={{ marginRight: "1.8rem" }}>
                {" "}
                ${total.toFixed(2)}
              </span>
            )}
        </CheckoutButton>
        {/* </CSSTransition> */}
      </div>
    </div>
  );
};
