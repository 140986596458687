import {
  Button,
  CircularProgress,
  Dialog,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { FC, useContext, useState } from "react";
import InputMask from "react-input-mask";
import { useHistory } from "react-router-dom";
import { OneSignal } from "src/components";
import { IEvent, IGetMe } from "src/models";
import { userAPI } from "src/screens/diner/account/api";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { collectPhoneStyles as useStyles } from "./collect-phone.styles";

interface IProps {
  getMe: IGetMe;
  getEvent: IEvent;
  open: boolean;
  dialogView?: string;
}

export const CollectPhone: FC<IProps> = ({
  getMe,
  open,
  getEvent,
  dialogView,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { state, dispatch } = useContext(OneSignal.Context);
  const [phone, setPhone] = useState(
    getMe.phoneNumber ? getMe.phoneNumber : ""
  );
  const [loading, setLoading] = useState(false);
  const [toggleChangePhoneNumber, setToggleChangePhoneNumber] = useState(false);

  const onCloseDialog = (toRestaurantListing?: boolean) => {
    const eventHash = `${getEvent.id}-${getEvent.name.replace(/ /g, "-")}`;
    sharedAPI.setDialogEventId("");
    !toRestaurantListing &&
      history.push(`/d/order/${eventHash}`, { status: "UPCOMING" });
  };

  const onSave = (toRestaurantListing?: boolean) => {
    dispatch({
      type: OneSignal.ActionTypes.REGISTER_DEVICE,
      payload: getMe.email,
    });
    userAPI
      .updateUser({
        phoneNumber: getMe.phoneNumber ? getMe.phoneNumber : phone.slice(2),
      })
      .finally(() => {
        setLoading(true);
        setTimeout(() => onCloseDialog(toRestaurantListing), 1000);
      });
  };
  return (
    <Dialog
      open={open}
      className={classes.dialog}
      PaperProps={{ classes: { root: classes.paperRoot } }}
      BackdropProps={{ classes: dialogView ? {} : { root: classes.backdrop } }}
    >
      <div className={classes.contentContainer}>
        <img
          className={classes.confirmationIcon}
          src={require("./assets/confirmation-46.svg")}
        />
        <Typography variant="h2" component="h1" className={classes.header}>
          Thanks, {getMe.firstName}!
        </Typography>
        <Typography variant="h2" component="h3">
          Your order is confirmed.{" "}
        </Typography>
        <Typography variant="body2" component="p" className={classes.subHeader}>
          {!!getMe.phoneNumber && !toggleChangePhoneNumber
            ? ""
            : `${
                getMe.phoneNumber ? "Update" : "Enter"
              } your phone number to receive text updates about your order.`}
        </Typography>

        {!getMe.phoneNumber || toggleChangePhoneNumber ? (
          <InputMask
            mask="1-(999)-999-9999"
            value={phone}
            classes={{ root: classes.inputMast }}
            onChange={(e: any) => setPhone(e.target.value)}
          >
            {(inputProps) => (
              <TextField
                size="small"
                {...inputProps}
                name="phoneNumber"
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.textField,
                  },
                }}
                placeholder="1-(___)-___-____"
                value={phone}
                type="tel"
              />
            )}
          </InputMask>
        ) : null}
        <div className={classes.btnContainer}>
          <Button
            onClick={() => onSave(false)}
            disabled={loading}
            variant={!!dialogView ? "text" : "contained"}
            color="secondary"
            classes={{ root: dialogView ? classes.btnDialogView : classes.btn }}
          >
            {loading ? (
              <CircularProgress style={{ color: COLORS.WHITE }} size={22} />
            ) : (
              "View Receipt"
            )}
          </Button>
          {!!dialogView && (
            <Button
              onClick={() => onSave(true)}
              disabled={loading}
              variant="contained"
              color="secondary"
              classes={{ root: classes.btn }}
            >
              Close
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};
