import { client } from "src/apollo";
import { CreateAllergenDishPL, CreateDishPL, UpdateDishPL } from "src/models";
import {
  CREATE_ALLERGEN_DISH,
  CREATE_DISH,
  DELETE_DISH,
  GET_HOST_DISHES,
  UPDATE_DISH,
} from "./graphql";

export const dishAPI = {
  updateDish: async (updateDishPL: UpdateDishPL) =>
    client.mutate({
      mutation: UPDATE_DISH,
      variables: {
        input: updateDishPL,
      },
      awaitRefetchQueries: true,
      refetchQueries: () => ["cookGetDishes"],
    }),

  deleteDish: async (id: number) =>
    client.mutate({
      mutation: DELETE_DISH,
      variables: {
        input: id,
      },
      awaitRefetchQueries: true,
      refetchQueries: () => ["cookGetDishes"],
    }),

  createDish: async (createDishPL: CreateDishPL) =>
    client.mutate({
      mutation: CREATE_DISH,
      variables: {
        input: createDishPL,
      },
      awaitRefetchQueries: true, // needed for create dish dialog
      refetchQueries: [
        {
          query: GET_HOST_DISHES,
          variables: {
            input: {
              pagePL: {
                offset: null,
                limit: 9,
              },
              searchTerm: "",
            },
          },
        },
        "dishSelection",
      ],
    }),

  createAllergenDish: (createAllergenDishPL: CreateAllergenDishPL) =>
    client.mutate({
      mutation: CREATE_ALLERGEN_DISH,
      variables: {
        input: createAllergenDishPL,
      },
      refetchQueries: () => ["cookGetDishes"],
    }),
};
