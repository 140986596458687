import { Paper } from "@material-ui/core";
import React, { FC } from "react";
import { PaginationComponent } from "src/components";
import { Header } from "./header";
import { layoutStyles as useStyles } from "./layout.styles";
import { EventListTable } from "./table";

interface IProps {
  totalCount: number;
  events: any[];

  fetchMore: (d: {
    pageNumber: number;
    searchTerm?: string;
    status?: string;
  }) => void;
  deleteEvent: (options: any) => void;
  promoEvent: (d: any) => Promise<any>;
  redirect: (path: string) => void;
}

export const Layout: FC<IProps> = ({
  totalCount,
  events,
  fetchMore,
  promoEvent,
  redirect,

  deleteEvent,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Header count={totalCount} onSearch={fetchMore} />
      <Paper className={classes.tableRoot}>
        <EventListTable
          events={events}
          redirect={redirect}
          deleteEvent={deleteEvent}
          promoEvent={promoEvent}
        />

        <PaginationComponent
          totalCount={totalCount}
          onChange={(pageNumber) => fetchMore({ pageNumber, status })}
        />
      </Paper>
    </div>
  );
};
