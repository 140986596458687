import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const collectPhoneStyles = makeStyles({
  dialog: { display: "relative", maxWidth: "unset" },
  header: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    marginTop: "0",
  },
  subHeader: {
    margin: "1rem 0",
  },
  contentContainer: {
    zIndex: 2,
    padding: "2rem",
    maxWidth: 400,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "2rem",
    },
  },
  backdrop: {
    backgroundColor: COLORS.WHITE,
    backgroundImage: `url(${require("./assets/hero_graphic-12.png")})`,
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center bottom",
  },

  paperRoot: {
    width: "80%",
    minWidth: 300,
    maxWidth: 600,
    borderRadius: "10px",
    boxShadow: "3px 4px 19px 0 rgba(31,64,80,0.28)",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "1rem",
  },
  label: {
    paddingLeft: "10px",
    color: "grey",

    marginBottom: "2px",
  },
  inputMast: {
    width: "80%",
    minWidth: 200,
  },
  textField: {
    margin: "0 auto",
    height: "20px",

    borderRadius: "2px",
    color: COLORS.BLACK,
    border: "1px solid",
    borderColor: COLORS.MEDIUM_GREEN,
    padding: "10px",
    opacity: 1,
    textAlign: "center",
  },
  textFieldPlaceholder: {
    textAlign: "center",
  },
  btnContainer: {
    marginTop: "20px",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
  },
  btn: {
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    width: "60%",
    minWidth: 200,
  },
  btnDialogView: {
    color: COLORS.GREEN,
    fontFamily: "CustomFourBold",
    width: "60%",
    minWidth: 200,
  },
  confirmationIcon: {
    margin: "0 auto",
    marginBottom: "1rem",
    width: 60,
    height: 60,
  },
  textNotificationSettingsButton: {
    textTransform: "none",
  },
});
