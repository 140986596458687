import React from "react";
import { Route, Switch } from "react-router-dom";
import { CreateDishContainer, DishListContainer, EditDishContainer } from ".";

export const DishRoutes = () => {
  return (
    <Switch>
      <Route exact path="/c/dishes" component={DishListContainer} />
      <Route path="/c/dishes/create" component={CreateDishContainer} />
      <Route path="/c/dishes/edit/:id" component={EditDishContainer} />
    </Switch>
  );
};
