import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  orderedDishesListItem: {
    boxShadow: " 0 2px 11px 0 rgba(187,187,187,0.5)",
    backgroundColor: COLORS.WHITE,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    alignSelf: "center",
    padding: ".5em 0",
  },
  orderedDishCount: {
    color: "#FB6C3A",

    marginRight: ".5rem",
  },
  soldOutBtn: {
    color: COLORS.MEDIUM_GREEN,
    backgroundColor: "unset",
    border: `1px solid ${COLORS.MEDIUM_GREEN}`,
    padding: "0px 4px",
    cursor: "pointer",
  },
  media: {
    height: "14rem",
  },
  cardTitle: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});
