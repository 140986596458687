import { Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./location-list.styles";
import { Suggestion } from "./suggestion";

export const LocationList = ({
  options,
  onSelectRestaurant,
  restaurants,
  highlightedIndex,
  selectedItem,
  suggestionStyle,
  truncateSuggestion,
  getItemProps,
}) => {
  const classes = useStyles();

  return (
    <>
      {!!(options?.length && onSelectRestaurant && restaurants?.length) && (
        <Typography
          variant="subtitle2"
          component="p"
          className={classes.locationHeader}
        >
          - Locations -
        </Typography>
      )}
      {options.map((suggestion, index) => (
        <Suggestion
          itemProps={getItemProps({
            item: suggestion.description,
          })}
          key={suggestion.description}
          suggestion={suggestion.description}
          index={index}
          highlightedIndex={highlightedIndex}
          selectedItem={selectedItem}
          suggestionStyle={suggestionStyle}
          truncateSuggestion={truncateSuggestion}
        />
      ))}
    </>
  );
};
