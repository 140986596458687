import { Button, MenuItem, MenuList, Typography } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";
import { IGetMe, ISidebarLink } from "src/models";
import "./animations.css";
import { useSidebarStyles } from "./styles";

interface SideNavigationProps {
  adornmentStart?: React.ReactNode;
  adornmentEnd?: React.ReactNode;
  sideBarLinks: ISidebarLink[];
  user?: IGetMe;
}

export const SideNavigation: React.FC<SideNavigationProps> = ({
  adornmentStart = null,
  adornmentEnd = null,
  sideBarLinks,
  user,
}) => {
  const classes = useSidebarStyles();
  return (
    <div className={classes.container}>
      {adornmentStart}
      <nav className={classes.nav}>
        <MenuList classes={{ root: classes.menuList }}>
          {sideBarLinks.map((sideBarLink) => {
            const renderMenuItem =
              typeof sideBarLink.when === "boolean"
                ? sideBarLink.when
                : sideBarLink.when(user);

            if (renderMenuItem) {
              return (
                <MenuItem
                  disableGutters
                  disableRipple
                  disableTouchRipple
                  key={sideBarLink.label}
                  classes={{ root: classes.menuItem }}
                >
                  {/* <NavLink
                    className={classes.navLink}
                    activeClassName={classes.navLinkActive}
                    to={sideBarLink.link}
                    exact
                  >
                    <div className={classes.navLinkLayout}>
                      <Typography
                        variant="body2"
                        component="span"
                        className={classes.navLinklabel}
                      >
                        {sideBarLink.label}
                      </Typography>
                      {sideBarLink.icon}
                    </div>
                  </NavLink> */}
                  <Button
                    className={classes.navLink}
                    activeClassName={classes.navLinkActive}
                    to={sideBarLink.link}
                    component={NavLink}
                    startIcon={sideBarLink.icon}
                  >
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.navLinklabel}
                    >
                      {sideBarLink.label}
                    </Typography>
                  </Button>
                </MenuItem>
              );
            } else return null;
          })}
        </MenuList>
      </nav>
      {adornmentEnd}
    </div>
  );
};
