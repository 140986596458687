import { gql } from "@apollo/client";

export const CREATE_RESTAURANT_GUEST = gql`
  mutation createRestaurantGuest($input: ID!) {
    createRestaurantGuest(guestId: $input) {
      id
      status
    }
  }
`;

export const UPDATE_RESTAURANT_GUEST = gql`
  mutation updateRestaurantGuest($input: UpdateRestaurantUserPL!) {
    updateRestaurantGuest(updateRestaurantPL: $input) {
      id
      status
    }
  }
`;
