import { useMediaQuery } from "@material-ui/core";
import React, { FC } from "react";
import { BREAK_POINTS } from "src/styles";
import { useStyles } from "./layout.styles";
import {
  ChefStories,
  Community,
  HowItWorksSection,
  OrderAMeal,
  PressSection,
  Reviews,
  TopDesktop,
  TopMobile,
} from "./sections";

export const Layout: FC = React.memo(() => {
  const classes = useStyles();
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  return (
    <div className={classes.container}>
      {isMobile ? <TopMobile /> : <TopDesktop />}
      <PressSection />
      <HowItWorksSection />
      <Community />
      {/* We want to hide the Download component until we have a better mobile app */}
      {/* {isMobile && <Download />} */}
      <Reviews />
      <ChefStories />
      <OrderAMeal />
    </div>
  );
});
