import React, { FC } from "react";
import { IUpdateUserPL, IUpdateUserPreferences } from "src/models";
import { Form } from "./form";
import { IFormState } from "./form/state/reducers";

interface IProps {
  initialState?: IFormState;
  updateUser: (d: IUpdateUserPL, p: IUpdateUserPreferences) => Promise<any>;
  uploadImg: (f: File) => Promise<any>;
  isCook: boolean;
}

export const Layout: FC<IProps> = ({
  updateUser,
  uploadImg,
  initialState,
  isCook,
}) => {
  return (
    <Form
      isCook={isCook}
      init={initialState}
      updateUser={updateUser}
      uploadImg={uploadImg}
    />
  );
};
