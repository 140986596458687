import { gql } from "@apollo/client";

export const GET_ALL_REFERRALS = gql`
  query getReferrals($input: PagePL) {
    getReferrals(pagePL: $input) {
      count
      rows {
        id
        code
        ipAddress
        status
        threshold
        referrerAmount
        referredAmount
        updatedAt
        createdAt
        referredBy {
          id
          email
          firstName
          lastName
        }
        referralUsers {
          id
          amount
          ipAddress
          status
          createdAt
        }
      }
    }
  }
`;
