import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  root: {
    width: "100%",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: 340,
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 30,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexWrap: "wrap",
      justifyContent: "center",
      marginTop: 0,
    },
  },
  textFieldRight: {
    marginLeft: 55,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginLeft: 0,
    },
  },
  label: {
    position: "absolute",
    display: "block",
    border: "0px",
    margin: "-1px",
    padding: "0px",
    height: "1px",
    width: "1px",
    clip: "rect(0, 0, 0, 0)",
    overflow: "hidden",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    borderRadius: 0,
    maxWidth: 500,
    width: "100%",
    overflow: "auto",
    justifyContent: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 60,
    width: "100%",
  },
  fieldsWrapper: {
    maxWidth: 780,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      alignItems: "center",
    },
  },
  fieldsContainer: {
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: "1em",
  },
  centered: {},
  logo: {
    width: 150,
  },
  link: {
    color: COLORS.MEDIUM_RED,
    padding: "1em 0",
  },
  inputLabel: {
    display: "none",
  },

  inputRoot: {
    height: "40px",
    maxWidth: 350,
    width: "100%",
    "&$inputFocused": {
      borderColor: "#FC5461 !important",
      borderWidth: "2px",
    },
    "&:hover:not($disabled):not($inputFocused):not($error)": {
      borderColor: "#FC5461 !important",
      borderWidth: "2px",
    },
  },
  messageInputRoot: {
    border: `1px solid ${COLORS.DARK_RED}`,
    marginTop: 30,
    borderRadius: "2px",
    // color: COLORS.MEDIUM_GREEN,
    "&:before": {
      borderColor: COLORS.MEDIUM_RED,
    },
    "&:after": {
      borderColor: COLORS.MEDIUM_RED,
    },
    "&:hover:not($underLineDisabled):not($underLineFocused):not($error)": {
      borderColor: COLORS.MEDIUM_RED,
    },
    "&::placeholder": {
      color: COLORS.MEDIUM_RED,
    },
    "&$messageInputFocused": {
      borderColor: `${COLORS.MEDIUM_RED} !important`,
      borderWidth: "1px",
    },
    "&:hover": {
      borderColor: `${COLORS.MEDIUM_RED} !important`,
      borderWidth: "1px",
    },
  },
  underline: {
    borderBottomColor: COLORS.MEDIUM_RED,
    "&:before": {
      borderBottomColor: COLORS.MEDIUM_RED,
    },
    "&:after": {
      borderBottomColor: COLORS.MEDIUM_RED,
    },
    "&:hover:not($underLineDisabled):not($underLineFocused):not($error)": {
      borderBottomColor: COLORS.MEDIUM_RED,
    },
  },
  underLineDisabled: {},
  underLineFocused: {},
  input: {
    color: COLORS.MEDIUM_RED,
    marginLeft: "10px",
    "&::placeholder": {
      color: COLORS.MEDIUM_RED,
    },
  },
  signUpInput: {
    color: COLORS.MEDIUM_RED,
    "&::placeholder": {
      color: COLORS.MEDIUM_RED,
    },
  },
  actionButton: {
    marginTop: "2em",
    background: COLORS.RED,
    borderRadius: "2px",
    color: COLORS.WHITE,
    boxShadow: "0 2px 6px 0 rgba(90,15,15,0.5)",

    "&:hover": {
      background: COLORS.RED,
    },
  },
  disabled: {},
  error: {},
  inputFocused: {},
  messageInputFocused: {},
  subCard: {
    textAlign: "center",
    padding: "1rem",
  },
  formHelperRoot: { position: "absolute", top: "38px" },
});
