import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import { useStyles } from "./community.styles";
import { HybridLink } from "src/components";

export const Community: FC = () => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <img
        src={require("../../assets/illustrations/leaf-13.svg")}
        className={classes.leaf}
      />
      <div className={classes.innerContainer}>
        <div className={classes.headerContainer}>
          <Fade triggerOnce={true}>
            <Typography variant="h2" component="h2" className={classes.title}>
              Food Safety & Community
            </Typography>
          </Fade>
          <Fade triggerOnce={true} delay={250}>
            <div className={classes.squiggleContainer}>
              <img
                src={require("../../assets/underline.svg")}
                className={classes.squiggle}
              />
            </div>
          </Fade>
        </div>
        <div className={classes.mainContent}>
          <Fade triggerOnce={true} delay={450}>
            <div className={classes.textContainer}>
              <Typography variant="h4" component="p" className={classes.text}>
                COOK has been working with legislators since 2015 to legalize
                Microenterprise Home Kitchen Operations (MEHKOs) across the
                country. MEKHO laws allow chefs to permit their home kitchens
                through the local Health Department & legally sell home cooked
                meals and other prepared foods to their neighbors. Learn more
                about our advocacy and discover if MEHKOs are legal in your
                jurisdiction{" "}
                <HybridLink
                  className={classes.bold}
                  href={"https://www.cookalliance.org/advancing-advocacy"}
                >
                  here
                </HybridLink>
                .
              </Typography>

              <Typography
                variant="h4"
                component="p"
                style={{ marginTop: "2.5rem" }}
                className={classes.text}
              >
                All chefs on COOK Connect are legally permitted to operate
                through their local Health Department. Obtaining a MEHKO permit
                requires chefs to obtain their Food Safety Manager’s
                certification and pass a plan check and home kitchen inspection.
                With a 100% safety track record, COOK Connect is dedicated to
                providing the safest experience possible. For more info, feel
                free to click here to read our{" "}
                <Link className={classes.bold} to="/faq">
                  FAQ
                </Link>
                !
              </Typography>
            </div>
          </Fade>
          <Fade triggerOnce={true} delay={650}>
            <div className={classes.imageContainer}>
              <img
                src={require("../../assets/images/community_safety.jpg")}
                className={classes.image}
              ></img>
              <img
                src={require("../../assets/illustrations/safety_badge.png")}
                className={classes.badge}
              />
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};
