import Tab, { TabProps } from "@material-ui/core/Tab";
import Tabs, { TabsProps } from "@material-ui/core/Tabs";
import React from "react";
import { useStyles } from "./styles";

export const StyledTabs: React.FC<TabsProps> = (props) => {
  const classes = useStyles();
  return (
    <Tabs
      classes={{
        root: classes.tabsRoot,
        scroller: classes.scroller,
        indicator: classes.colorSecondary,
      }}
      TabIndicatorProps={{
        children: <div />,
      }}
      {...props}
    />
  );
};

export const StyledTab: React.FC<TabProps> = (props) => {
  const classes = useStyles();
  return (
    <Tab
      classes={{
        root: classes.rootTab,
        selected: classes.selected,
        textColorInherit: classes.textColorInherit,
      }}
      {...props}
    />
  );
};
