import { Button, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { noItemsStyles as useStyles } from "./no-items.styles";

interface IProps {
  title: string;
  subText: string;
}

export const NoItemsContainer: FC<IProps> = ({ title, subText }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.container}>
      <Typography variant="body2" component="p" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body2" component="p" className={classes.subText}>
        {subText}
      </Typography>
      <Button
        onClick={() => history.push("/menus")}
        classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
      >
        Browse Menus
      </Button>
    </div>
  );
};
