import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { Add, Clear } from "@material-ui/icons";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ContactForm, CookDinerToggle } from "src/components";
import { COLORS } from "src/styles";
import { faqContent } from "./helpers";
import { styles as useStyles } from "./layout.styles";

export const Layout: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const param = new URLSearchParams(location.search).get("type") as
    | "diner"
    | "cook";

  // Hooks
  const [selection, setSelection] = useState<"diner" | "cook">("diner");
  const [expanded, setExpanded] = useState<number | false>(false);

  useEffect(() => {
    if (param === "diner" || param === "cook") {
      setSelection(param);
    }
  }, [param]);

  // Handlers
  const handleChange = (panel: number) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div style={{ marginBottom: "calc(env(safe-area-inset-bottom) + 8rem)" }}>
      <div
        className={classes.hero}
        style={{
          backgroundImage: `url(${require("./assets/chard.jpg")})`,
        }}
      >
        <div className={classes.overlay} />
        <Typography
          variant="h1"
          component="h1"
          style={{
            textShadow: "-3px 2px 11px rgba(24,56,71,0.7)",
          }}
          className={classes.heroText}
        >
          Frequently Asked Questions
        </Typography>
      </div>
      <div
        style={{
          margin: "50px 0",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CookDinerToggle
          selection={selection}
          handleSelect={(type: string) => {
            history.push({
              pathname: "/faq",
              search: `?type=${type}`,
            });
          }}
        />
      </div>
      <div style={{ margin: "60px 12.5%" }}>
        {faqContent[selection].map((faq) => {
          return (
            <Accordion
              key={faq.id}
              square
              expanded={expanded === faq.id}
              onChange={handleChange(faq.id)}
            >
              <AccordionSummary
                expandIcon={
                  expanded === faq.id ? (
                    <Clear style={{ color: COLORS.LIGHT_BLUE }} />
                  ) : (
                    <Add style={{ color: COLORS.MEDIUM_GREEN }} />
                  )
                }
              >
                {faq.question}
              </AccordionSummary>
              <AccordionDetails>{faq.answer}</AccordionDetails>
            </Accordion>
          );
        })}
      </div>
      <section className={classes.formContainer}>
        <div className={classes.headerContainer}>
          <Typography variant="h2" component="h2" className={classes.header}>
            More questions?
          </Typography>
          <Typography
            variant="h3"
            component="h3"
            className={classes.welcomeMsg}
          >
            Let us know what’s on your mind and we’ll get back to you as soon as possible.
          </Typography>
        </div>
        <ContactForm />
      </section>
    </div>
  );
};
