import { RestaurantReviewStatus } from "src/models";
import { client } from "../../../apollo";
import { DINER_REVIEW_RESPONSE } from "./mutations";

export interface IDinerReviewPL {
  id: number;
  status: RestaurantReviewStatus;
  text: string;
  quality: number;
  hospitality: number;
  accuracy: number;
  token?: string;
  tipAmount: number;
}

export const restaurantReviewAPIs = {
  createResponse: (d: IDinerReviewPL) =>
    client.mutate({
      mutation: DINER_REVIEW_RESPONSE,
      variables: {
        input: d,
      },
    }),
};
