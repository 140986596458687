import { List, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { IRestaurant } from "src/models";
import { useStyles } from "./layout.styles";
import { DateSelects, Event, Metrics, Payment } from "./sections";

interface IProps {
  restaurant: IRestaurant;
  refetch: (d: { input: any }) => Promise<any>;
}

export const Layout: FC<IProps> = ({ restaurant, refetch }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h1" component="h1">
        {restaurant.name}
      </Typography>
      <Typography variant="body2" component="p">
        {restaurant.host.firstName} {restaurant.host.lastName},{" "}
        {restaurant.host.email}, {restaurant.host.phoneNumber}
      </Typography>
      <div className={classes.headerContainer}>
        <Metrics restaurant={restaurant} />
        <DateSelects refetch={refetch} restaurant={restaurant} />
      </div>
      <div className={classes.sectionContainer}>
        <section className={classes.eventsSection}>
          <Typography variant="h1" component="h1">
            Menus
          </Typography>
          {restaurant.events.map((event) => (
            <Event event={event} key={event.id} />
          ))}
        </section>

        <section className={classes.paymentsSection}>
          <Typography variant="h1" component="h1">
            Payments
          </Typography>
          <List>
            {restaurant.host.payments.map((payment) => (
              <Payment payment={payment} key={payment.id} />
            ))}
          </List>
        </section>
      </div>
    </div>
  );
};
