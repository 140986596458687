import { makeStyles, Theme } from "@material-ui/core/styles";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: { margin: "0 1em 1em 0" },
  dialog: { display: "relative", maxWidth: "unset" },
  dialogPaperWidth: { maxWidth: "700px", margin: 0 },
  clickBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "2px",
    height: "32px",
    width: "32px",
    minWidth: 0,
    border: `solid 3px ${COLORS.MEDIUM_GREEN}`,

    color: COLORS.MEDIUM_GREEN,
    cursor: "pointer",
    "&:disabled": {
      border: `solid 2px ${COLORS.MEDIUM_GREY}`,
      cursor: "not-allowed",
      color: COLORS.MEDIUM_GREY,
    },
  },
  clickBtnLabel: {
    width: 32,
  },
  dialogContent: {
    overflow: "auto",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  infoContainer: {
    position: "relative",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  infoSubContainer: {
    overflow: "hidden",
    padding: 20,
  },
  iconBtn: {
    position: "absolute",
    right: "4px",
    top: "4px",
    backgroundColor: COLORS.WHITE,
    width: "32px",
    height: "32px",
    padding: 0,
    zIndex: 2,
  },
  closeBtn: { color: COLORS.MEDIUM_GREEN },

  dishLeft: {
    color: COLORS.RED,
  },
  price: { fontFamily: "CustomFourBold" },
  description: {
    whiteSpace: "pre-wrap",

    color: "grey",
  },
  allergenDietaryContainer: {
    fontWeight: 700,
    marginBottom: "20px",

    height: "40px",
  },
  label: {
    color: "grey",
    fontFamily: "CustomFour",
    display: "inline-block",
    width: "100px",
  },
  allergen: { margin: " 2px 0", fontFamily: "CustomFourBold" },
  dietary: { margin: " 2px 0", fontFamily: "CustomFourBold" },
  actionContainer: {
    width: "100%",
    backgroundColor: COLORS.WHITE,
    zIndex: 2,
    paddingBottom: "env(safe-area-inset-bottom)",
  },
  btnContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 20px",
  },
  quantityLabel: {
    display: "none",
  },
  radioGroup: { flexDirection: "unset" },
  radio: {},
  radioLabel: {
    ...theme.typography.subtitle2,
    fontFamily: "CustomFourBold",
    color: COLORS.DARK_GREY,
  },
  optionContainer: {
    width: "100%",
    backgroundColor: "#f0f0f0",
    padding: 10,
    borderRadius: 4,
  },
  formLable: {
    fontFamily: "CustomFourBold",
    color: COLORS.BLACK,
  },
  plusMinusBtnContainer: {
    width: "100%",
    maxWidth: "110px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  optionLabel: {
    top: -10,
  },

  clickBtnDisabled: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "2px",
    height: 36,
    padding: 0,
    width: 36,
    fontSize: "2.5rem",
    borderColor: COLORS.MEDIUM_GREY,

    borderWidth: "2px",
    color: COLORS.MEDIUM_GREY,
    cursor: "not-allowed",
  },
  count: {
    fontFamily: "CustomFourBold",
    fontWeight: "bold",
    marginBottom: 0,

    display: "inline-block",
    width: "34px",
    textAlign: "center",
  },
  button: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    padding: "5px 10px",
    borderRadius: "2px",
    minWidth: 160,

    height: "40px",

    // backgroundColor: COLORS.MEDIUM_GREEN,
    // "&:hover": {
    //   backgroundColor: COLORS.MEDIUM_GREEN,
    // },
  },

  addToOrderContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  addToOrderWord: {
    display: "block",
  },
  addToOrderPrice: {
    display: "block",
    marginLeft: ".35rem",
  },
  textAreaLabel: {
    fontFamily: "CustomFourBold",
    margin: "12px 0px",
  },
  textArea: {
    width: "100%",
    border: "1px solid #8f8fa1",
    borderRadius: "4px",
    padding: 16,

    marginTop: 16,
  },
  root: {
    borderRadius: 0,
    padding: 0,
  },
  icon: {
    fill: "#1F4050",

    top: 6,
    right: 20,
  },
  notchedOutline: {
    borderRadius: 2,
    borderWidth: 2,
    borderColor: COLORS.MEDIUM_GREEN,
    padding: 0,
  },
  menuItemRoot: {},
  formControl: {
    margin: "1em 0",

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      margin: "0",
    },
  },
  selected: {
    "&.focus": { backgroundColor: "pink" },
  },
  form: { width: "100%", height: "100%" },
  selectMenu: { textAlign: "center" },
  inputRoot: {
    height: "34px",
    borderColor: COLORS.MEDIUM_GREEN,
    "&.focused": {
      borderColor: COLORS.BLACK,
    },
  },
  focused: { borderColor: "purple" },
}));
