import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { FC, useState } from "react";
import { CreatePromotionPL, IEvent } from "src/models";
import { COLORS } from "src/styles";
import { useStyles } from "./promoDialog.styles";

interface IProps {
  close: () => any;
  createPromo: (d: CreatePromotionPL) => Promise<any>;
  promo?: number;
  event?: IEvent;
}

export const PromoDialog: FC<IProps> = ({
  close,
  createPromo,
  promo,
  event,
}) => {
  const classes = useStyles();
  const [promoValue, setPromo] = useState(promo ? promo : 0.1);

  if (!event) return null;
  return (
    <Dialog open={Boolean(event)} onClose={close}>
      <DialogTitle className={classes.title}>
        {event.name} ${event.price}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          size="small"
          type="number"
          label="Discount Amount (0.1 - 1)"
          fullWidth
          value={promoValue}
          InputProps={{ inputProps: { min: 0, max: 1 } }}
          onChange={(d) => setPromo(Number(d.target.value))}
          margin="normal"
          inputProps={{ maxLength: 200 }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={close}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ color: COLORS.WHITE }}
          onClick={() => createPromo({ eventId: event.id, amount: promoValue })}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
