import React, { FC } from "react";
import { IGetMe } from "src/models";
import { Form } from "./form";

interface IProps {
  error: string;
  loading: boolean;
  user: IGetMe | undefined;
  createRestaurant: (d: any) => void;
  submitMailchimp: (d: any) => void;
}

export const Layout: FC<IProps> = ({
  error,
  loading,
  createRestaurant,
  user,
  submitMailchimp,
}) => {
  return (
    <Form
      createRestaurant={createRestaurant}
      user={user}
      loading={loading}
      error={error}
      submitMailchimp={submitMailchimp}
    />
  );
};
