import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "src/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    borderRadius: 0,
    width: "100%",
    fontSize: theme.typography.h5.fontSize,
    lineHeight: "2rem",
    textAlignLast: "center",
    textAlign: "center",
    MozTextAlignLast: "center",

    fontWeight: "unset",

    backgroundColor: "transparent",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    fill: COLORS.MEDIUM_GREEN,
    fontSize: "2.5rem",
    paddingTop: ".5rem",
  },
  notchedOutline: {
    borderColor: "transparent",
    padding: 0,
  },
  menuItemRoot: {},

  formControl: {
    height: "100%",
    boxShadow: "unset",
  },
  selected: {
    paddingLeft: 30,
    paddingRight: 20,
  },
  form: {
    width: "100%",
    height: "100%",
  },
  selectMenu: {
    textAlign: "center",
  },

  inputRoot: {
    borderColor: COLORS.MEDIUM_GREEN,
    height: "100%",
    ...theme.typography.subtitle2,

    margin: "0 auto",
    fontWeight: "bold",

    "& $notchedOutline": {
      borderWidth: 0,
    },
    "&:hover $notchedOutline": {
      borderWidth: 0,
    },
    "&$focused $notchedOutline": {
      borderWidth: 0,
    },
  },
  focused: { borderColor: "transparent" },
}));
