import { Button, makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import { parse, stringify } from "query-string";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ResponsiveDialog } from "src/components";
import { AppStatus } from "src/models";
import { amplitude } from "src/services";
import { COLORS } from "src/styles";

interface IProps extends RouteComponentProps<any> {
  appStatus?: AppStatus;
  firstName?: string;
}

const useStyles = makeStyles({
  paper: {
    borderRadius: 40,
  },
  decoration: {
    backgroundImage: `url(${require(`src/assets/images/yellowveg-11.png`)})`,
    backgroundSize: "contain",
    width: "100%",
    minHeight: 171,
    backgroundRepeat: "no-repeat",
    position: "relative",
  },
  title: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    textAlign: "center",
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
  },
  name: {
    textAlign: "center",
    textTransform: "capitalize",
  },
  content: {
    padding: "0 2rem 2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: 400,
    margin: "0 auto",
  },
  text: {
    fontWeight: 100,
    marginBottom: "2rem",
    textAlign: "center",
  },
  boldText: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  button: {
    boxShadow: "1px 2px 6px 0 rgba(0,0,0,0.18)",
    color: COLORS.WHITE,

    backgroundColor: COLORS.MEDIUM_GREEN,
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_GREEN,
    },
  },
});

export const BecomeCookCompleteDialog: React.FC<IProps> = ({
  location,
  history,
  appStatus,
  firstName = "Home Cook",
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      amplitude.getInstance().logEvent(`[Become Cook] Final Step Dialog`);
    }
  }, [open]);

  useEffect(() => {
    const { bacwc } = parse(location.search) as { bacwc?: boolean }; // bacwc : become a cook workflow complete
    if (bacwc && appStatus === AppStatus.ACTION_REQUIRED) {
      setOpen(true);
    }
  }, [appStatus, location.search]);

  function cleanUpParams() {
    history.replace({
      search: stringify({}), // remove all query params
    });
  }

  function onClose() {
    setOpen(false);
  }

  return (
    <ResponsiveDialog
      onClose={onClose}
      onExited={cleanUpParams}
      data-testid="BecomeCookCompleteDialog-dialog"
      aria-describedby="become-cook-workflow-complete-dialog-title"
      open={open}
      classes={{ paper: classes.paper }}
      fullWidth
      maxWidth="sm"
    >
      <div className={classes.decoration}>
        <Typography
          variant="h1"
          component="h1"
          className={classNames(classes.boldText, classes.title)}
          id="become-cook-workflow-complete-dialog-title"
        >
          Welcome, <span className={classes.name}>{firstName}</span>!
        </Typography>
      </div>
      <div className={classes.content}>
        <Typography variant="body2" component="p" className={classes.text}>
          <b className={classes.boldText}>
            You're on your way to opening your home restaurant!
          </b>{" "}
          Once you receive your permit you'll be able to make your menus public,
          and officially open up for business!
        </Typography>
        <Button
          classes={{ contained: classNames(classes.boldText, classes.button) }}
          onClick={() => {
            setOpen(false);
            amplitude
              .getInstance()
              .logEvent(`[Become Cook] Final Step Dialog - click "Got it!"`);
          }}
          variant="contained"
          size="large"
        >
          Got it!
        </Button>
      </div>
    </ResponsiveDialog>
  );
};
