import axios from "axios";
import { client, SERVER_ADDRESS } from "src/apollo";
import { ICreateRestaurantPL } from "src/models";
import { GET_ME } from "src/screens/login/api";
import { CREATE_RESTAURANT, CREATE_RESTAURANT_AND_USER } from "./graphql";

export const restaurantAPI = {
  createRestaurant: async (createRestaurantPL: ICreateRestaurantPL) =>
    client.mutate({
      mutation: CREATE_RESTAURANT,
      variables: {
        input: createRestaurantPL,
      },
      refetchQueries: [
        {
          query: GET_ME,
        },
      ],
      awaitRefetchQueries: true,
    }),

  createRestaurantAndUser: async (createRestaurantPL: ICreateRestaurantPL) =>
    client.mutate({
      mutation: CREATE_RESTAURANT_AND_USER,
      variables: {
        input: createRestaurantPL,
      },
      // refetchQueries: [
      //   {
      //     query: GET_ME,
      //   },
      // ],
      // awaitRefetchQueries: true,
    }),
  submitMailchimp: (data: {
    email_address: string;
    status: string;
    FNAME: string;
    LNAME: string;
    ZIPCODE: string | number;
    SHARE: string;
    PHONE: string | number;
  }) =>
    axios(`${SERVER_ADDRESS}/mailchimp/add`, {
      method: "post",
      data,
    }),
};
