import { useQuery } from "@apollo/client";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";
import { uniq } from "lodash";
import { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SERVER_ADDRESS } from "src/apollo";
import { IGetMe } from "src/models";
import { GET_ME } from "src/shared-graphql/queries";
import { BREAK_POINTS } from "src/styles";
import { ChatContext } from "stream-chat-react";
import { ChatActionTypes, _ChatContext } from "./";

export const useCreateChannel = () => {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  const { client, setActiveChannel } = useContext(ChatContext);
  const {
    dispatch,
    state: { drawerStatus },
  } = useContext(_ChatContext);
  const [channel, setChannel] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const { data } = useQuery<{ getMe: IGetMe }>(GET_ME);

  const openDrawer = () => {
    if (isMobile && !location.pathname.includes("chat")) {
      return history.push("/chat");
    } else if (!isMobile && !drawerStatus) {
      return dispatch({ type: ChatActionTypes.SET_DRAWER_STATUS });
    }
  };

  const createChannel = async (id?: string) => {
    setLoading(true);
    if (!data?.getMe) return setLoading(false);
    let members;
    const d = await axios.get(`${SERVER_ADDRESS}/stream/admin-ids`);
    const adminList =
      data.getMe.role === "ADMIN"
        ? d.data.list.filter((i) => i !== data?.getMe.id)
        : d.data.list;
    members = [data.getMe.id, ...adminList];
    if (id) {
      members = [data.getMe.id, id, ...adminList];
    } else {
      members = [data.getMe.id, ...adminList];
    }

    const ch = client?.channel(
      "messaging",
      !id ? data.getMe.id + "Foodnome" : undefined,
      {
        members: uniq(members),
        session: 8, // custom field, you can add as many as you want
      }
    );

    if (setActiveChannel && ch) {
      setActiveChannel(ch, {} as any);
      setChannel(ch);
      dispatch({ type: ChatActionTypes.SET_ACTIVE_CHANNEL, payload: ch });
      openDrawer();
    }
    setLoading(false);
  };

  return { createChannel, channel, loading };
};
