import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  dialogTitle: {
    fontWeight: 100,

    textAlign: "center",
  },
  section: {
    "& h2": {
      marginTop: "1.5rem",
      marginBottom: "1rem",
      fontFamily: "CustomFourBold",
      fontWeight: 500,
    },
  },
  link: {
    fontWeight: 100,
    cursor: "pointer",
    color: COLORS.MEDIUM_GREEN,
    textDecoration: "none",
  },
  avatar: {
    height: 100,
    width: 100,
    margin: "0 auto",
  },
  instructions: {
    fontWeight: 100,
  },
  paper: {
    borderRadius: 0,
    minWidth: 300,
  },
  button: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.WHITE,
  },
  userOrderedItems: {
    margin: 0,
    fontWeight: 100,
  },
});
