import { graphql } from "@apollo/client/react/hoc";
import { flowRight } from "lodash";
import { GET_RESTAURANT, GET_RESTAURANT_REVIEWS } from "./graphql";

export { GET_RESTAURANT };

export const restaurantHOC = {
  withRestaurantReviews: flowRight(
    graphql(GET_RESTAURANT_REVIEWS, {
      options: (props: any) => {
        return {
          variables: {
            input: {
              pagePL: {
                limit: 10,
              },
              restaurantId: props.match.params.id.split("-")[0],
            },
          },
          fetchPolicy: "cache-and-network",
        };
      },
    })
  ),
};
