import { Typography } from "@material-ui/core";
import { LocalDining, Room } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import ReactStars from "react-stars";
import { GoogleMapContainer, LocationDialog } from "src/components";
import { IGetMe, IRestaurant } from "src/models";
import { useTopViewport } from "src/utils/hooks";
import { DistanceAwayButton } from "../distance-away-button";
import { useTabsState } from "../tabs/context";
import { Metrics } from "./metrics";
import { useOverviewStyles } from "./overview.styles";
import { Profile } from "./profile";

interface IProps {
  restaurant: IRestaurant;
  getMe?: IGetMe;
}

export const Overview: React.FC<IProps> = ({ restaurant, getMe }) => {
  const classes = useOverviewStyles();
  const ref = useRef<any>();
  const inView = useTopViewport(ref);
  const [showLocationDialog, setShowLocationDialog] = useState(false);

  const { dispatch } = useTabsState();
  useEffect(() => {
    if (inView) {
      dispatch({ type: "SET_TAB_VALUE", value: "overview" });
    }
  }, [inView]);

  const openLocationDialog = () => {
    setShowLocationDialog(true);
  };

  const closeLocationDialog = () => {
    setShowLocationDialog(false);
  };

  const scroll = () => {
    const id = "reviews-container";
    const yOffset = -160;
    const element = document.getElementById(id)!;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <>
      <div ref={ref}>
        <section className={classes.detailContainer}>
          {/* Profile */}
          <div style={{ marginTop: "4rem" }}>
            <Profile restaurant={restaurant} getMe={getMe} />
          </div>
          <section className={classes.rightSide}>
            <Typography
              variant="h2"
              component="h1"
              className={classes.restaurantName}
            >
              {restaurant.name}
            </Typography>

            {/* Reviews */}
            <div className={classes.reviewStarContainer}>
              <ReactStars
                count={5}
                color={"#F7CC15"}
                color2={"#ffd700"}
                value={Math.ceil(restaurant.overallReview.stars)}
                edit={false}
                size={20}
              />
              <Typography
                variant="body2"
                component="p"
                data-testid="total-review-count"
                className={classes.reviewAmount}
              >
                ({restaurant.overallReview.count})
              </Typography>
            </div>

            {/* Metrics Container  */}
            <Metrics scroll={scroll} restaurant={restaurant} />

            {/* Restaurant Information */}
            <ul style={{ listStyleType: "none", padding: 0, marginTop: 6 }}>
              {restaurant.tags && (
                <li className={classes.restaurantDetail}>
                  <LocalDining className={classes.icon} />
                  <Typography
                    variant="body1"
                    component="p"
                    className={classes.address}
                  >
                    {restaurant.tags.join(" • ")}
                  </Typography>
                </li>
              )}
              {restaurant.locale && (
                <li className={classes.restaurantDetail}>
                  <Room className={classes.icon} />

                  <Typography variant="body1" component="address">
                    {restaurant.locale.public}
                  </Typography>
                </li>
              )}
            </ul>
            <DistanceAwayButton
              restaurantCoordinates={restaurant?.locale?.coordinates}
            />
            {/* Google Maps and Permit Information */}
            <div>
              {/* Google Maps Container */}
              <div
                onClick={openLocationDialog}
                className={classes.googleMapsContainer}
              >
                <GoogleMapContainer
                  circleOverlay
                  center={
                    restaurant.locale && restaurant.locale.coordinates
                      ? {
                          lat: restaurant.locale.coordinates.latitude,
                          lng: restaurant.locale.coordinates.longitude,
                        }
                      : undefined
                  }
                />
              </div>
              {restaurant.permitNumber && (
                <Typography
                  variant="body2"
                  component="data"
                  style={{ marginBottom: "1rem" }}
                  className={classes.textSmall}
                  value={restaurant.permitNumber}
                >
                  Permit #{restaurant.permitNumber}&nbsp;
                </Typography>
              )}
              {/* Permit Number */}
              {restaurant && restaurant.locale && (
                <>
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.textSmall}
                  >
                    {" "}
                    {" | "}
                    {restaurant.locale.county}
                    {/ county$/i.test(restaurant.locale.county as string)
                      ? ""
                      : " County"}
                  </Typography>
                </>
              )}
            </div>
          </section>
        </section>
      </div>

      <LocationDialog
        showLocationDialog={showLocationDialog}
        closeLocationDialog={closeLocationDialog}
        restaurant={restaurant}
      />
    </>
  );
};
