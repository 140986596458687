import { useLazyQuery } from "@apollo/client";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import {
  AddCircle,
  AttachMoney,
  Chat,
  Delete,
  Lock,
  PhonelinkErase,
  Repeat,
  Report,
  StayPrimaryPortrait,
  Visibility,
} from "@material-ui/icons";
import moment from "moment";
import React, { FC, useState } from "react";
import { ConfirmationDialog } from "src/components";
import { useCreateChannel } from "src/components/chat2";
import { AdminUpdateUserPL, IUser } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { memberListAPI } from "../api";
import { ADMIN_GET_USER_ACCESS, ADMIN_SEND_RESET_TOKEN } from "../api/graphql";
import { getCookInfo } from "./helpers";
import { UserAccountDialog } from "./user-dialog";

interface IProps {
  users: IUser[];
  deleteUser: (d: number) => any;
  redirect: (d: string) => any;
  editUser: (d: AdminUpdateUserPL) => void;
}

export const MemberListTable: FC<IProps> = ({
  users,
  deleteUser,
  editUser,
  redirect,
}) => {
  const [hovered, setHover] = useState<string>("0");
  const [deletUserId, setDeleteUserId] = useState<any>(0);
  const { loading, createChannel } = useCreateChannel();
  const [getAccess, { called }] = useLazyQuery(ADMIN_GET_USER_ACCESS);
  const [sendResetToken] = useLazyQuery(ADMIN_SEND_RESET_TOKEN);
  const _deleteUser = async (id: number) => {
    await deleteUser(id);
    setDeleteUserId(0);
  };

  return (
    <>
      <ConfirmationDialog
        openDialog={Boolean(deletUserId)}
        onConfirm={() => _deleteUser(deletUserId)}
        title={"Delete User"}
        message={"Are you sure you want to delete this user?"}
        onClose={() => setDeleteUserId(0)}
      />
      <TableContainer component={Paper}>
        <Table style={{ maxWidth: "none" }}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Has App</TableCell>
              <TableCell>Nomes (credits)</TableCell>
              <TableCell>Since (last order)</TableCell>
              <TableCell className="table-header-cell">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row) => (
              <TableRow key={row.id} hover onMouseOver={() => setHover(row.id)}>
                <TableCell>
                  {getCookInfo(row)}, {row.source}, {row.lastName},{" "}
                  {row.firstName} ({row.id}),{" "}
                </TableCell>
                <TableCell>{row.phoneNumber}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.address}</TableCell>
                <TableCell>
                  {row?.hasApp && row?.hasApp !== "false" ? (
                    <>
                      <StayPrimaryPortrait style={{ color: COLORS.GREEN }} />
                      {row?.hasApp}
                    </>
                  ) : (
                    <PhonelinkErase style={{ color: COLORS.RED }} />
                  )}
                </TableCell>
                <TableCell>
                  {row.nomes} ({row.credits})
                </TableCell>
                <TableCell>
                  {moment(Number(row.createdAt)).format("ll")} (
                  {row.points?.lastOrderedAt
                    ? moment(Number(row.points?.lastOrderedAt)).format("ll")
                    : "Never"}
                  )
                </TableCell>
                <TableCell>
                  <UserAccountDialog
                    user={row}
                    hovered={hovered}
                    editUser={editUser}
                  />
                  <Tooltip title="User Detail">
                    <IconButton onClick={() => redirect(`members/${row.id}`)}>
                      <Visibility
                        style={
                          row.id === hovered
                            ? { cursor: "pointer" }
                            : { visibility: "hidden" }
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete User">
                    <IconButton onClick={() => setDeleteUserId(row.id)}>
                      <Delete
                        style={
                          row.id === hovered
                            ? { cursor: "pointer" }
                            : { visibility: "hidden" }
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Chat">
                    <IconButton onClick={() => createChannel(row.id)}>
                      <Chat
                        style={
                          row.id === hovered
                            ? { cursor: "pointer" }
                            : { visibility: "hidden" }
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Chat to USER">
                    <IconButton
                      onClick={() =>
                        memberListAPI
                          .deleteChat(row.id)
                          .then(() =>
                            sharedAPI.setSnackbarMsg({
                              type: "success",
                              msg: "Deleted",
                            })
                          )
                          .catch(() =>
                            sharedAPI.setSnackbarMsg({
                              type: "error",
                              msg: "Error",
                            })
                          )
                      }
                    >
                      <Report
                        style={
                          row.id === hovered
                            ? { cursor: "pointer" }
                            : { visibility: "hidden" }
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Add Chat to USER">
                    <IconButton
                      onClick={() =>
                        memberListAPI
                          .addChat(row.id)
                          .then(() =>
                            sharedAPI.setSnackbarMsg({
                              type: "success",
                              msg: "Deleted",
                            })
                          )
                          .catch(() =>
                            sharedAPI.setSnackbarMsg({
                              type: "error",
                              msg: "Error",
                            })
                          )
                      }
                    >
                      <AddCircle
                        style={
                          row.id === hovered
                            ? { cursor: "pointer" }
                            : { visibility: "hidden" }
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Get User Access">
                    <IconButton
                      onClick={() =>
                        getAccess({ variables: { input: row.id } })
                      }
                    >
                      <Lock
                        style={
                          row.id === hovered
                            ? { cursor: "pointer" }
                            : { visibility: "hidden" }
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Send Reset Token">
                    <IconButton
                      onClick={() =>
                        sendResetToken({ variables: { input: row.id } })
                      }
                    >
                      <Repeat
                        style={
                          row.id === hovered
                            ? { cursor: "pointer" }
                            : { visibility: "hidden" }
                        }
                      />
                    </IconButton>
                  </Tooltip>

                  {!!(row.restaurant && row.nomes) && (
                    <Tooltip title="Transfer Funds">
                      <IconButton
                        onClick={() =>
                          memberListAPI
                            .transferFunds(row.id)
                            .then(() =>
                              sharedAPI.setSnackbarMsg({
                                type: "success",
                                msg: "Completed",
                              })
                            )
                            .catch(() =>
                              sharedAPI.setSnackbarMsg({
                                type: "error",
                                msg: "Error",
                              })
                            )
                        }
                      >
                        <AttachMoney
                          style={
                            row.id === hovered
                              ? { cursor: "pointer" }
                              : { visibility: "hidden" }
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
