import { gql } from "@apollo/client";
import { client } from "src/apollo";

const PUBLISH_EVENT = gql`
  mutation cookPublishEvent($input: CookPublishEventPL!) {
    cookPublishEvent(cookPublishEventPL: $input) {
      id
    }
  }
`;

export const publishEvent = (cookPublishEventPL: {
  eventId: string;
  duplicateDates: string[];
}) =>
  client.mutate({
    mutation: PUBLISH_EVENT,
    variables: { input: cookPublishEventPL },
  });
