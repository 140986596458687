import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { sharedAPI } from "src/shared-graphql";
import { useMobileHeader } from "src/utils/hooks";
import { dishAPI } from "../api";
import { DishFormSubmitPL } from "../form/form";
import { getDishImages } from "./helpers";
import { Layout } from "./layout";

export const CreateDishContainer: FC = () => {
  useMobileHeader({ title: "Create Dish" });
  const history = useHistory();

  const handleCreate = ({
    images: dishImages,
    allergens,
    ...rest
  }: DishFormSubmitPL) => {
    const images = getDishImages(dishImages);
    const createDishPL = Object.assign({}, rest, {
      allergens: allergens.map((_allergen) => _allergen.id),
      images,
    });
    return dishAPI
      .createDish(createDishPL)
      .then(() => history.push("/c/dishes/"))
      .then(() =>
        sharedAPI.setSnackbarMsg({ type: "success", msg: "Dish Created!" })
      );
  };

  return <Layout onSubmit={handleCreate} />;
};
