import React, { FC, FunctionComponent } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { IGetMe } from "src/models";
import { compareRole } from "src/utils/helpers";

interface IProps extends RouteProps {
  auth: (getMe: IGetMe) => boolean;
  redirectPath?: string;
  data: { getMe: IGetMe; loading: boolean; error: any };
  component: FunctionComponent<any>;
}

export class AuthType {
  static isAuth(getMe: IGetMe) {
    return Boolean(getMe);
  }
  static isNotAuth(getMe: IGetMe) {
    return Boolean(!getMe);
  }
  static withRestaurant(getMe: IGetMe) {
    return Boolean(getMe?.restaurant);
  }
  static withoutRestaurant(getMe: IGetMe) {
    return Boolean(getMe && getMe?.restaurant);
  }
  static withAdminRole(getMe: IGetMe) {
    return Boolean(compareRole(getMe?.role, "ADMIN"));
  }
  static withInspectorRole(getMe: IGetMe) {
    return Boolean(compareRole(getMe?.role, "INSPECTOR"));
  }
  static withSuperAdminRole(getMe: IGetMe) {
    return Boolean(compareRole(getMe?.role, "SUPER_ADMIN"));
  }
}

export const GuardedRoute: FC<IProps> = ({
  component: Component,
  auth,
  data,
  redirectPath = "/",
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      auth(data.getMe) === true ? (
        <Component data={data} {...props} />
      ) : (
        <Redirect to={redirectPath} />
      )
    }
  />
);
