import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    maxWidth: "18rem",
    width: "100%",
    backgroundColor: "#fafafa",
    height: "25rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardTop: {
    position: "relative",
  },
  textContentCard: {
    padding: "0 1em",
    position: "absolute",
    margin: "0 .5em",
    bottom: 0,
    top: 185,
    left: 0,
    right: 0,
    height: "min-content",
    maxHeight: 154,
    overflowY: "scroll",
  },
  arrow: {
    color: "#a4b0be",
  },
  checkbox: {
    zIndex: 99,
    position: "absolute",
    top: 0,
    right: 0,
  },
});
