import { Button, InputAdornment, Typography } from "@material-ui/core";
import { Map, Room } from "@material-ui/icons";
import { stringify } from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AutoCompleteContainer } from "src/components";
import { IRestaurant } from "src/models";
import { COLORS } from "src/styles";
import { LocalStorageHelper } from "src/utils/helpers";
import { heroStyles as useStyles } from "./hero.styles";

interface DesktopHeroProps {
  queryObj: {
    location: string;
    tags: string[];
  };
  onSubmit: (event: any) => void;
}

export const DesktopHero: React.FC<DesktopHeroProps> = ({
  queryObj,
  onSubmit,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [hasSavedLocation, setSavedLocation] = useState<boolean>(
    () => !!getSavedLocation()
  );

  useEffect(() => {
    const savedLocation = getSavedLocation();
    if (savedLocation) setSavedLocation(true);
  });

  function getSavedLocation() {
    return window.localStorage.getItem("FOODNOME_LOCATION_PREFERENCE");
  }

  const onSelectLocation = (l: any) => {
    const locationObj = {
      ...(l.longitude && {
        longitude: Number(l.longitude).toFixed(5),
      }),
      ...(l.latitude && {
        latitude: Number(l.latitude).toFixed(5),
      }),
      ...(!l.longitude && !l.latitude ? { location: l } : null),
    };
    history.push({
      pathname: "/menus",
      search: stringify(locationObj),
    });
    // SET LOCATION PREFERENCE INTO LOCAL STORAGE
    if (l) {
      LocalStorageHelper.setItem(
        "FOODNOME_LOCATION_PREFERENCE",
        JSON.stringify(locationObj)
      );
    }
  };

  return (
    <div data-testid="events-list-hero-desktop" className={classes.hero}>
      <div className={classes.heroOverlay} />
      <div className={classes.heroTextContainer}>
        <Typography variant="h2" component="h1" className={classes.heroText}>
          Fresh. Local. Homemade.
        </Typography>
        <Typography
          variant="h4"
          component="h2"
          className={classes.subtitleText}
        >
          Order the best homemade food near you!
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <div className={classes.searchFormLayout}>
            <div className={classes.searchBarItem}>
              <Typography
                component="label"
                variant="caption"
                className="hidden-label"
                htmlFor="search-address"
              >
                Search by Location
              </Typography>
              <AutoCompleteContainer
                useGeoLocation={true}
                initialState={queryObj ? queryObj.location : ""}
                onSelect={onSelectLocation}
                onSelectRestaurant={(r: IRestaurant) =>
                  history.push(`/${r.name.replace(/ /g, "-")}`)
                }
                TextFieldProps={{
                  variant: "outlined",
                  fullWidth: true,
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Room
                          className={classes.icon}
                          style={{ color: COLORS.MEDIUM_RED }}
                        />
                      </InputAdornment>
                    ),
                    placeholder: `Enter address or zip code`,
                    classes: {
                      input: classes.input,
                      root: classes.rootInputLeft,

                      inputAdornedStart: classes.inputAdornStart,
                      focused: classes.focused,
                    },
                    id: "search-address",
                  },
                }}
              />
            </div>

            <Button
              fullWidth
              classes={{ contained: classes.searchButton }}
              variant="contained"
              type="submit"
            >
              Find Food
            </Button>
            <Button
              classes={{ root: classes.mapBtn }}
              startIcon={<Map className={classes.mapIcon} />}
              variant="contained"
              onClick={() => history.push("/restaurants")}
            >
              Map
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
