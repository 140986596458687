import { IImage } from "src/models";
import { Actions, IAction } from "./actions";

export interface IFormState {
  name: string;
  address: string;
  description: string;
  image: IImage | null;
  permitNumber: string;
  tags: string[];
}

export const initialState: IFormState = {
  name: "",
  description: "",
  address: "",
  image: null,
  permitNumber: "",
  tags: [],
};

export function reducer(state: IFormState, action: IAction): IFormState {
  switch (action.type) {
    case Actions.SET_ADDRESS:
      return { ...state, address: action.address };
    case Actions.SET_FIELD:
      return { ...state, [action.key]: action.value };
    case Actions.SET_BANNER_IMAGE:
      return { ...state, image: action.image };
    default:
      return state;
  }
}
