import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  messageContainer: {
    position: "absolute",
    bottom: 90,
    right: 50,
    width: "200px",
    opacity: 1,

    padding: "1rem",
    borderRadius: "20px 20px 0 20px",
    boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.4)",
    marginBottom: "env(safe-area-inset-bottom)",
  },
  contactFoodnomeBtn: {
    position: "absolute",
    bottom: 20,
    right: 20,

    marginBottom: "env(safe-area-inset-bottom)",
  },
  closeBtn: {
    position: "absolute",
    left: -30,
    top: -25,
  },
});
