import { gql } from "@apollo/client";

export const CREATE_COUPON = gql`
  mutation createCoupon($input: CreateCouponPL!) {
    createCoupon(createCouponPL: $input) {
      id
      code
      type
      start
      end
      count
      eventId
      updatedAt
      createdAt
    }
  }
`;

export const DELETE_COUPON = gql`
  mutation deleteCoupon($input: String!) {
    deleteCoupon(code: $input)
  }
`;
