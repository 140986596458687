import { SvgIcon } from "@material-ui/core";
import React from "react";

export const CookMenu = (props) => (
  <SvgIcon {...props}>
    <g id="Layer_2">
      <g>
        <g>
          <g>
            <path
              d="M17.74,2.13h-1.58V2h-2.33v0.13H6.17V2H3.84v0.13H2.27C1.01,2.13,0,3.17,0,4.44v15.78
					c0,1.27,1.01,2.31,2.27,2.31h15.47c1.25,0,2.26-1.04,2.26-2.31V4.44C20,3.17,18.99,2.13,17.74,2.13z M18.29,19.62
					c0,0.66-0.5,1.2-1.11,1.2H2.82c-0.61,0-1.11-0.54-1.11-1.2V7.05c0-0.62,0.51-1.15,1.11-1.15h14.36c0.6,0,1.11,0.53,1.11,1.15
					V19.62z"
            />
          </g>
          <g>
            <path d="M13.83,1.88v0.25h2.33V1.88H13.83z M3.84,1.88v0.25h2.33V1.88H3.84z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M4.34,1.05c0-0.3,0.27-0.55,0.64-0.55s0.69,0.25,0.69,0.55v2.12c0,0.3-0.32,0.55-0.69,0.55S4.34,3.47,4.34,3.17V1.05z" />
          </g>
          <g>
            <path
              d="M4.98,0C4.34,0,3.84,0.46,3.84,1.05v2.12c0,0.59,0.5,1.05,1.14,1.05c0.65,0,1.19-0.47,1.19-1.05V1.05
					C6.17,0.47,5.63,0,4.98,0z M5.17,3.14C5.15,3.18,4.85,3.2,4.84,3.17V1.05c0-0.01,0.04-0.01,0.08-0.01
					c0.09,0,0.23,0.01,0.25,0.01V3.14z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M14.33,1.05c0-0.3,0.32-0.55,0.69-0.55c0.37,0,0.64,0.25,0.64,0.55v2.12c0,0.3-0.27,0.55-0.64,0.55
					c-0.37,0-0.69-0.25-0.69-0.55V1.05z"
            />
          </g>
          <g>
            <path
              d="M15.02,0c-0.65,0-1.19,0.47-1.19,1.05v2.12c0,0.58,0.54,1.05,1.19,1.05c0.64,0,1.14-0.46,1.14-1.05V1.05
					C16.16,0.46,15.66,0,15.02,0z M15.16,3.17c-0.01,0.03-0.3,0-0.33,0v-2.1c0.04-0.05,0.32-0.04,0.33-0.02V3.17z"
            />
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                d="M13.38,7.88c-1.33,0-2.38,1.48-2.38,3.37c0,1.44,0.74,2.69,1.65,3.19l0.05,3.36c0,0.53,0.2,0.89,0.68,0.89
						c0.49,0,0.78-0.41,0.78-0.94l-0.05-3.36c0.92-0.5,1.66-1.7,1.66-3.14C15.77,9.36,14.72,7.88,13.38,7.88z M14.02,11.24
						c0,0.8-0.33,1.36-0.56,1.48h-0.01c-0.04,0.03-0.08,0.01-0.13,0c-0.24-0.11-0.54-0.68-0.54-1.48c0-0.87,0.4-1.48,0.62-1.48
						C13.61,9.76,14.02,10.37,14.02,11.24z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                d="M9.89,12.48c0,0.93-0.61,1.69-1.37,1.69H7.97v3.65c0.02,0.72-0.37,0.88-0.76,0.88c-0.39,0-0.66-0.4-0.66-0.88v-3.65
						H5.96c-0.79,0-1.43-0.8-1.43-1.77V9.01c0-0.48,0.32-0.88,0.71-0.88c0.47,0,0.67,0.38,0.71,0.84v3.44H6.5V9.03
						c0-0.23,0.06-0.45,0.2-0.62c0.13-0.16,0.31-0.25,0.51-0.25c0.39,0,0.71,0.4,0.71,0.88v3.38h0.54V9.03
						c0-0.48,0.31-0.86,0.7-0.88c0.42-0.05,0.66,0.4,0.72,0.8L9.89,12.48z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
