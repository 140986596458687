import { Button } from "@material-ui/core";
import { parse } from "query-string";
import React, { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { GuestCheckoutHOC } from "src/components";
import { IEvent, IGetMe } from "src/models";
import { amplitude } from "src/services";
import { checkoutButtonStyles as useStyles } from "./checkout-button.styles";
import { MobileOrderDialog } from "./mobile-order-dialog";

interface IProps {
  openGuestCheckout: () => any;
  dialogCallback: () => any;
  handleSubmit?: () => any;
  currentUser: IGetMe;
  children?: any;
  requireAddress: boolean;
  disabled?: boolean;
  noOrder: boolean;
  getEvent: IEvent;
  isMobile: boolean;
}

const _CheckoutButton: FC<IProps> = ({
  openGuestCheckout,
  currentUser,
  handleSubmit,
  children,
  disabled,
  noOrder,
  getEvent,
  isMobile,
}) => {
  const classes = useStyles();
  const location = useLocation();

  const variation = "var-2";
  const queryObj = parse(location.search);

  // var-1 = green button
  // var-2 = red button

  useEffect(() => {
    if (variation) {
      amplitude
        .getInstance()
        .logEvent(`[Event Detail] View Order Button ${variation}`, {
          source: queryObj.s,
        });
    }
  }, [variation]);

  const _handleSubmit = (e) => {
    e.stopPropagation();
    amplitude
      .getInstance()
      .logEvent("[Event Detail] proceed to checkout/view order");
    // This has been moved to when the user clicks on "Add To Bag" on the event detail dialog (dish-dialog.tsx)
    // process.env.NODE_ENV !== "test" &&
    // ReactPixel.trackSingle(dinerPixel, "AddToCart", {});
    if (!currentUser) return openGuestCheckout();
    if (handleSubmit) handleSubmit();
  };

  return (
    <div
      style={
        isMobile
          ? {
              padding: "3rem",
              paddingBottom: "1rem",
            }
          : {}
      }
    >
      <Button
        data-testid="proceed-to-checkout-button"
        variant="contained"
        disabled={disabled}
        onClick={_handleSubmit}
        // @ts-ignore
        color={variation === "var-1" ? "secondary" : "primary"}
        fullWidth
        startIcon={
          !noOrder && (
            <MobileOrderDialog
              variation={variation}
              isMobile={isMobile}
              noOrder={noOrder}
              getEvent={getEvent}
            />
          )
        }
        classes={{
          contained: classes.button,
          label: classes.label,
          startIcon: classes.startIcon,
        }}
      >
        {children}
      </Button>
    </div>
  );
};

export const CheckoutButton = GuestCheckoutHOC(_CheckoutButton);
