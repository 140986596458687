import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { IEvent } from "src/models";
import { formatDateShort, truncateText } from "src/utils/helpers";
import { savedEventStyles as useStyles } from "./saved-event.styles";

interface IProps {
  event: IEvent;

  scroll?: boolean;
}

export const SavedEventCard: FC<IProps> = ({ event, scroll }) => {
  const classes = useStyles();
  return (
    <Link
      className={classes.cardContainer}
      style={{ maxWidth: scroll ? 300 : 1100 }}
      to={{
        pathname: `/menus/${event.id}-${event.name.replace(/ /g, "-")}`,
      }}
    >
      <div
        className={classes.container}
        style={{
          backgroundImage: `url(${
            event.imagesGQL && event.imagesGQL.length
              ? event.imagesGQL[0].medium
              : ""
          })`,
        }}
      >
        <div className={classes.textContainer}>
          <Typography
            variant="body2"
            component="p"
            className={classes.eventTitle}
          >
            {truncateText(event.name, 24)}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.dateAndType}
          >
            {formatDateShort(event.startTime)}
          </Typography>
        </div>
      </div>
    </Link>
  );
};
