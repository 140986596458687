import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React, { FC, useState } from "react";
import { AdminUpdateUserPL, IUser } from "src/models";
import { COLORS } from "src/styles";

interface IProps {
  user: IUser;
  hovered: string;
  editUser: (d: AdminUpdateUserPL) => void;
}

export const UserAccountDialog: FC<IProps> = ({ editUser, user, hovered }) => {
  const [open, setStatus] = useState(false);
  const [nomes, setNomes] = useState(user.nomes ? user.nomes : 0);
  const [credits, setCredits] = useState(user.credits ? user.credits : 0);
  const [picture, setPicture] = useState(user.picture ? user.picture : "");
  const [address, setAddress] = useState(user.address ?? "");
  const [firstName, setFirstName] = useState(user.firstName ?? "");
  const [lastName, setLastName] = useState(user.lastName ?? "");
  const [password, setPassword] = useState("");
  const [active, setActive] = useState(user.active);
  const [eventsAttended, setEventsAttended] = useState(
    user.points?.eventsAttended
  );
  const [phoneNumber, setPhoneNumber] = useState(
    user.phoneNumber ? user.phoneNumber : ""
  );
  const [account, setAccount] = useState(user.account ? user.account : "");
  const [email, setEmail] = useState(user.email);

  const _editUser = () => {
    setStatus(false);
    editUser({
      userId: user.id,
      nomes,
      credits,
      firstName,
      lastName,
      active,
      address,
      ...(password && { password }),
      phoneNumber,
      email,
      picture,
      account,
      points: {
        eventsAttended,
      },
    });
  };

  return (
    <>
      <Tooltip title="Edit User">
        <IconButton onClick={() => setStatus(true)}>
          <Edit
            style={
              user.id === hovered
                ? { cursor: "pointer" }
                : { visibility: "hidden" }
            }
          />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={() => setStatus(false)}>
        <DialogTitle style={{ paddingBottom: 0 }}>
          Edit User Account
        </DialogTitle>
        <DialogContent style={{ width: "300px" }}>
          <TextField
            size="small"
            label="FirstName"
            value={firstName}
            fullWidth
            onChange={(d) => setFirstName(d.target.value)}
            margin="normal"
            inputProps={{ maxLength: 200 }}
          />
          <TextField
            size="small"
            label="Last Name"
            value={lastName}
            fullWidth
            onChange={(d) => setLastName(d.target.value)}
            margin="normal"
            inputProps={{ maxLength: 200 }}
          />
          <TextField
            size="small"
            label="Email"
            value={email}
            fullWidth
            onChange={(d) => setEmail(d.target.value)}
            margin="normal"
            inputProps={{ maxLength: 200 }}
          />
          <TextField
            size="small"
            label="Paypal Account"
            value={account}
            fullWidth
            onChange={(d) => setAccount(d.target.value)}
            margin="normal"
            inputProps={{ maxLength: 200 }}
          />
          <TextField
            size="small"
            label="Phone"
            value={phoneNumber}
            fullWidth
            onChange={(d) => setPhoneNumber(d.target.value)}
            margin="normal"
            inputProps={{ maxLength: 200 }}
          />
          <TextField
            size="small"
            label="Password"
            value={password}
            fullWidth
            onChange={(d) => setPassword(d.target.value)}
            margin="normal"
            inputProps={{ maxLength: 200 }}
          />
          <TextField
            size="small"
            label="Address"
            value={address}
            fullWidth
            onChange={(d) => setAddress(d.target.value)}
            margin="normal"
            inputProps={{ maxLength: 200 }}
          />
          <TextField
            size="small"
            label="Nome amount"
            type="number"
            value={nomes}
            fullWidth
            onChange={(d) => setNomes(Number(d.target.value))}
            margin="normal"
            inputProps={{ maxLength: 200 }}
          />
          <TextField
            size="small"
            label="Credit amount"
            type="number"
            value={credits}
            fullWidth
            onChange={(d) => setCredits(Number(d.target.value))}
            margin="normal"
            inputProps={{ maxLength: 200 }}
          />
          <TextField
            size="small"
            label="Picture"
            value={picture}
            fullWidth
            onChange={(d) => setPicture(d.target.value)}
            margin="normal"
            inputProps={{ maxLength: 200 }}
          />
          <TextField
            size="small"
            label="Events Attended"
            value={eventsAttended}
            fullWidth
            onChange={(d) => setEventsAttended(Number(d.target.value))}
            margin="normal"
            inputProps={{ maxLength: 200 }}
          />
          <FormControl>
            <RadioGroup
              name="eventType"
              value={active ? "0" : "1"}
              onChange={(d: any) => setActive(d.target.value ? true : false)}
            >
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="Active"
                style={{ color: "grey" }}
              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Not Active"
                style={{ color: "grey" }}
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setStatus(!open)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => _editUser()}
            style={{ color: COLORS.WHITE }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
