import { makeStyles } from "@material-ui/core";
import React from "react";
import { IPress } from "src/models";
import { BREAK_POINTS } from "src/styles";
import { FeaturedPress } from "./featured";
import { PressHeader } from "./header";
import { PastPressList } from "./past-press-list";

export interface LayoutProps {
  featuredPress: IPress;
  pastPress: IPress[];
}

const useStyles = makeStyles({
  container: {
    maxWidth: 1200,
    margin: `0 auto`,
    width: "100%",
    marginBottom: "calc(env(safe-area-inset-bottom) + 8rem)",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "1rem",
    },
    [`@media (max-width: 771px)`]: {
      padding: 0,
    },
  },
});

export const Layout: React.FC<LayoutProps> = ({ featuredPress, pastPress }) => {
  const classes = useStyles();
  return (
    <div id="press-container" className={classes.container}>
      <PressHeader />
      <FeaturedPress featuredPress={featuredPress} />
      <PastPressList pastPress={pastPress} />
    </div>
  );
};
