import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  editBtn: {
    color: COLORS.MEDIUM_GREEN,
  },
  editIcon: {},
  content: {
    margin: "0 auto",
  },
});
