import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  mainContentContainer: {
    display: "grid",
    // gridTemplateRows: "repeat(1, minmax(150px, min-content))",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
  },
  card: {
    backgroundColor: COLORS.WHITE,
    boxShadow: "3px 4px 19px 0 rgba(31,64,80,0.28)",
  },
  container: {
    width: "100%",
    // backgroundColor: COLORS.WHITE,
    // boxShadow: "3px 4px 19px 0 rgba(31,64,80,0.28)",
    padding: "0 7.5rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "2rem",
      // paddingBottom: "calc(10rem + env(safe-area-inset-bottom))",
    },
  },
  pickupAndEditContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  headerContainer: {
    backgroundColor: COLORS.FLASH_WHITE,
    width: "100%",
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginTop: "6rem",
    },
  },
  chatBtn: {
    marginLeft: "1rem",
    color: COLORS.WHITE,
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    justifyContent: "space-around",
  },
  avatar: {
    width: "9rem",
    height: "9rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "5rem",
      height: "5rem",
    },
  },

  reviewBtn: {
    padding: 0,
    fontFamily: "CustomFourBold",

    width: 120,
    height: 32,
  },
  privateNote: { color: COLORS.RED, margin: 0 },
  subContainer: {
    boxSizing: "border-box",
    padding: "1.8rem 0",
  },
  mapContainer: {
    maxWidth: 400,
    width: "100%",
    height: 150,
    borderRadius: 2,
  },
  eventTitle: {
    fontFamily: "CustomFourBold",

    fontWeight: 500,

    margin: 0,
    color: COLORS.BLACK,
  },
  subText: {
    marginBottom: "1rem",
    color: "#6F6F6F",
  },
  boldSubText: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.BLACK,
    margin: 0,
    textTransform: "capitalize",
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
    color: COLORS.MEDIUM_GREEN,
    marginBottom: "1rem",
    fontFamily: "CustomFourBold",
    display: "inline-block",
    borderBottom: `1px solid ${COLORS.MEDIUM_GREEN}`,
    fontWeight: 300,
    margin: 0,
  },
  question: {
    textDecoration: "none",
    color: COLORS.DARK_GREY,

    fontWeight: 300,

    margin: 0,
  },
  questionNum: {
    textDecoration: "none",
    cursor: "pointer",
    color: COLORS.MEDIUM_GREEN,

    fontWeight: 300,

    margin: 0,
  },
  loadingContainer: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  orderNavContainer: {
    display: "flex",
    fontFamily: "CustomFourBold",
    justifyContent: "center",
    alignItems: "center",
  },
  orderCount: {
    color: COLORS.RED,
  },
  navNext: {
    color: COLORS.RED,
    fontSize: "3rem",
  },
  linksContainer: {
    // display: "flex",
    // justifyContent: "flex-end",
    "& > a": {
      color: COLORS.MEDIUM_GREEN,
    },
  },
  helpContainer: {
    marginTop: "3rem",
    width: "100%",
    maxWidth: "40rem",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
  },
  foodnomeContainer: {
    display: "flex",
    flexDirection: "row",
  },
});
