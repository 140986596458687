import React, { FC } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Notifications } from "src/components";

interface IProps extends RouteComponentProps<any> {}

export const NotificationContainer: FC<IProps> = ({ history }) => {
  // Inner height is to fit the notification container inside
  // the inner window for IOS (not go under address bar and bottom footer)
  return (
    <section style={{ height: window.innerHeight }}>
      <Notifications />
    </section>
  );
};
