import { Button, Card, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";

interface IProps {
  history: any;
}

export const ThankYou: React.FC<IProps> = ({ history }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <section className={classes.contentWrapper}>
        <Card className={classes.card}>
          <Typography
            variant="h1"
            component="h1"
            className={classes.mainHeading}
          >
            Thank you for signing up!
          </Typography>
          <img
            className={classes.image}
            src={require("./assets/email.png")}
            alt="drawing of an envelope."
          />
          <Typography
            variant="h2"
            component="h2"
            className={classes.subHeading}
          >
            You'll receive an email from us shortly!
          </Typography>
        </Card>
        <div className={classes.buttonsWrapper}>
          <Button
            fullWidth
            classes={{ root: classes.rootButton1 }}
            variant="contained"
            onClick={() => history.push("/")}
          >
            {"Go back to the home page"}
          </Button>
          <Button
            fullWidth
            classes={{ root: classes.rootButton2 }}
            variant="contained"
            onClick={() => history.push("/menus")}
          >
            {"View events"}
          </Button>
        </div>
      </section>
    </div>
  );
};
