import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useGatherAddressDialogStyles = makeStyles({
  dialog: { display: "relative", maxWidth: "unset" },
  dialogPaperWidth: { maxWidth: "576px", margin: 0, minHeight: 260 },
  dialogContent: {
    overflow: "visible",
  },
  header: {
    margin: "10px 0 6px 0",
    fontFamily: "CustomFourBold",
  },
  subheader: {
    color: COLORS.BLACK,

    margin: "0 0 10px 0",
  },
  iconBtn: {
    position: "absolute",
    right: "4px",
    top: "4px",
    backgroundColor: COLORS.WHITE,
    width: "32px",
    height: "32px",
    padding: 0,
    zIndex: 2,
  },
  closeBtn: { color: COLORS.MEDIUM_GREEN },
  label: {
    color: "grey",
    fontWeight: "normal",

    display: "inline-block",
    width: "100px",
  },
  icon: {
    color: COLORS.MEDIUM_GREEN,
    zIndex: 1,
  },
  inputAdornStart: {
    paddingLeft: 4,
  },
  notchedOutline: {},
  focused: {},
  input: {
    borderRadius: "2px 0 0 2px",
    zIndex: 1,
    padding: 10,
    "&::placeholder": {},
  },
  rootInputLeft: {
    "&$focused $notchedOutline": {
      borderColor: COLORS.MEDIUM_GREEN,
      borderWidth: 1,
    },
    "& $notchedOutline": {
      borderRadius: "2px",
      borderRight: "solid 0.5px #E0E0E0",
      backgroundColor: COLORS.WHITE,
    },
  },
  dialagActionsContainer: {
    paddingTop: "1rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});
