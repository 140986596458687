import { useQuery } from "@apollo/client";
import { Avatar, Button, Card } from "@material-ui/core";
import moment from "moment";
import React, { FC } from "react";
import { ActionDialog } from "src/components";
import { useCreateChannel } from "src/components/chat2";
import { IRestaurant, IRestaurantGuest } from "src/models";
import { GET_ME } from "../header/api/graphql";
import { listItemStyles as useStyles } from "./list-item.styles";

interface IProps {
  restaurant: IRestaurant;
  restaurantGuest: IRestaurantGuest;
}

export const ListItem: FC<IProps> = ({ restaurantGuest, restaurant }) => {
  const classes = useStyles();
  const { data } = useQuery(GET_ME);

  return (
    <Card key={restaurantGuest.id} className={classes.card}>
      <section className={classes.container}>
        <Avatar
          alt={`${restaurantGuest.guest.firstName.charAt(
            0
          )}${restaurantGuest.guest.lastName.charAt(0)}`}
          src={restaurantGuest.guest.picture}
        />
        <div className={classes.info}>
          <div>
            {restaurantGuest.guest.firstName} {restaurantGuest.guest.lastName}
          </div>
          <div style={{ color: "grey" }}>
            Since{" "}
            {moment(Number(restaurantGuest.guest.createdAt)).format(
              "MM/DD/YYYY"
            )}
          </div>
        </div>
      </section>
      {data.getMe?.restaurant?.id === restaurant.id && (
        <ChatButton userId={restaurantGuest.id} currentUser={data?.getMe} />
      )}
    </Card>
  );
};

export const ChatButton = ({ userId, currentUser }) => {
  const classes = useStyles();
  const { createChannel, loading } = useCreateChannel();

  const initChat = () => {
    return createChannel(userId);
  };

  // successfully fire submit handler or ask the user to sign up / log in
  // const submitHandler = bindSubmitHandler(currentUser)({
  //   openLoginDialog,
  //   openVerifyDialog,
  //   handleSubmit: initChat,
  // });

  return (
    <ActionDialog getMe={currentUser}>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        className={classes.btn}
        onClick={initChat}
      >
        Chat
      </Button>
    </ActionDialog>
  );
};
