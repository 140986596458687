import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  nextButton: {
    padding: "1.2rem 6rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "0.6rem 4rem",
    },
  },
  linearProgressContainer: {
    height: "20vh",
    width: "100%",
    padding: "0 10rem",
    position: "relative",
    backgroundColor: COLORS.WHITE,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: "env(safe-area-inset-bottom)",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      height: "unset",
      padding: "unset",
      position: "fixed",
      bottom: 0,
      left: 0,
      border: "0.5px solid #E2E2E2",

      boxShadow: "0 2px 5px 0 rgba(0,0,0,0.3)",
    },
  },
  linearProgressBar: {
    height: "0.5rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      height: "1rem",
    },
  },
  backArrow: { color: COLORS.MEDIUM_GREEN, fontSize: "4rem" },
  actionButtonsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",

    alignItems: "center",
    padding: "1rem 0",
    marginBottom: "3rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "1rem",
      marginBottom: 0,
    },
  },
  linearProgressStepText: {
    fontWeight: 100,
    color: COLORS.DARK_GREY,

    textTransform: "capitalize",
  },
});
