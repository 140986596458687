import { gql } from "@apollo/client";

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInfo!) {
    updateUser(updateUserInfo: $input) {
      id
      email
      role
      account
      birthday
      picture
      address
    }
  }
`;

export const CREATE_COMPLAINT = gql`
  mutation createComplaint($input: CreateComplaintPL!) {
    createComplaint(createComplaintPL: $input)
  }
`;
