import React from "react";
import { IRestaurant, UpdateRestaurantPL } from "src/models";
import { Form } from "./form";
import { IFormState } from "./form/state/reducers";

interface IProps {
  restaurant: IRestaurant;
  updateRestaurant: (d: UpdateRestaurantPL) => Promise<any>;
  uploadImg: (f: File) => Promise<any>;
  initialState?: IFormState;
}

export const Layout: React.FC<IProps> = ({
  restaurant,
  updateRestaurant,
  uploadImg,
  initialState,
}) => {
  return (
    <Form
      restaurant={restaurant}
      updateRestaurant={updateRestaurant}
      uploadImg={uploadImg}
      init={initialState}
    />
  );
};
