import { Card, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { DishForm } from "../form";
import { DishFormSubmitPL } from "../form/form";
import { useStyles } from "./layout.styles";

interface IProps {
  onSubmit: (pl: DishFormSubmitPL) => Promise<any>;
}

export const Layout: FC<IProps> = ({ onSubmit }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h2" component="h1" className={classes.mainHeader}>
        Create a Dish
      </Typography>
      <Card className={classes.dishFormCard}>
        <DishForm handleSubmit={onSubmit} />
      </Card>
    </>
  );
};
