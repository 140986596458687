import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  makeStyles,
  Typography
} from "@material-ui/core";
import dropIn, { Dropin } from "braintree-web-drop-in";
import disableScroll from "disable-scroll";
import { parse } from "query-string";
import React, { FC, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GET_BT_TOKEN } from "src/components/braintree/graphql/mutations";
import { IGetMe } from "src/models";
import { amplitude } from "src/services";
import { BREAK_POINTS, COLORS } from "src/styles";

interface IProps {
  setBrainTreeInstanceHandler: (type: any) => void;
  total: number;
  button: boolean;
  handleSubmit: any;
  loading: boolean;
  getMe: IGetMe;
  paymentSelected: boolean;
  setLoading: (d: any) => any;
}
export const BTDropIn: FC<IProps> = ({
  setBrainTreeInstanceHandler,
  total,
  button,
  handleSubmit,
  loading,
  setLoading,
  getMe,
  paymentSelected,
}) => {
  const [getToken] = useMutation(GET_BT_TOKEN);
  const [instance, setInstance] = useState<Dropin | null>(null);
  const [token, setToken] = useState("");
  const [isToggleInView, setIsToggleInView] = useState<boolean>(false);
  const classes = useStyles();
  const ref = useRef(null);
  const location = useLocation();
  const history = useHistory();

  const variation = "var-2";
  const queryObj = parse(location.search);

  useEffect(() => {
    getToken()
      .then((d) => {
        setToken(d.data.userGetBTToken);
        reinit(d.data.userGetBTToken);
      })
      .catch((error) => {
        if (error?.message?.includes("Token does not match")) {
          history.push("/login");
        } else if (error?.message?.includes("Must be logged in")) {
          history.push("/login");
        }
      });
    return () => {
      disableScroll.off();
      instance?.teardown();
    };
  }, []);

  useEffect(() => {
    if (variation) {
      amplitude
        .getInstance()
        .logEvent(`[Event Detail] Place order ${variation}`, {
          source: queryObj.s,
        });
    }
  }, [variation]);

  useEffect(() => {
    const toggleBtn = document.querySelector('[data-braintree-id="toggle"]');

    const initToggleInView = !toggleBtn?.classList.contains("braintree-hidden");

    setIsToggleInView(initToggleInView);

    const checkToggle = () => {
      if (toggleBtn) {
        const toggleInView = !toggleBtn?.classList.contains("braintree-hidden");
        if (toggleInView) {
          setIsToggleInView(true);
        } else {
          setIsToggleInView(false);
        }
      }
    };
    window.setInterval(checkToggle, 500);
  }, [instance]);

  useEffect(() => {
    isToggleInView && paymentSelected
      ? disableScroll.on()
      : disableScroll.off(); // re-enable scroll
  }, [isToggleInView]);

  const reinit = (_token: string) => {
    return dropIn
      .create({
        container: ref.current as any,
        authorization: _token,
        vaultManager: true,
        paypal: {
          flow: "vault",
          buttonStyle: {
            color: "blue",
            shape: "rect",
            size: "responsive",
            label: "checkout",
            tagline: "true",
          } as any,
        },
        // venmo: {
        //   allowNewBrowserTab: true,
        // },
      })
      .then((int) => {
        setBrainTreeInstanceHandler(int);
        setInstance(int);
      })
      .catch(
        (err: any) => new Error("Error loading BrainTree component: " + err)
      );
  };

  return (
    <>
      {/* Braintree component */}
      {total > 0 && (
        <>
          <div
            className={classes.customBrainTreeStyles}
            style={{
              display: !!instance ? "unset" : "none",
            }}
            ref={ref}
          />
          {/* Loading */}
          {!instance && (
            <div className={classes.circularProgressContainer}>
              <CircularProgress />
            </div>
          )}
        </>
      )}
      {/* Pay button */}
      {button || total === 0 ? (
        <div style={{ padding: "1rem" }}>
          <Button
            disabled={loading}
            variant="contained"
            fullWidth
            // @ts-ignore
            color={variation === "var-1" ? "secondary" : "primary"}
            classes={{
              root: classes.proceedToCheckoutBtnRoot,
            }}
            onClick={() => {
              handleSubmit();
              paymentSelected &&
                (window as any).cordova?.platformId === "ios" &&
                setTimeout(() => {
                  setLoading(false);
                  instance?.teardown();
                  reinit(token);
                }, 2000);
            }}
          >
            {loading ? (
              <CircularProgress style={{ color: COLORS.WHITE }} />
            ) : (
              <>
                <div>Place Order </div>
                <div className={classes.totalContainerPayBtn}>
                  {total >= 0
                    ? `$${total.toFixed(2)}`
                    : `-$${Math.abs(total).toFixed(2)}`}
                </div>
              </>
            )}
          </Button>
        </div>
      ) : (
        <>
          <section className={classes.totalContainer}>
            <Typography variant="body1" component="p" className={classes.total}>
              Total:
            </Typography>
            <Typography
              variant="body1"
              component="p"
              className={classes.totalAmount}
            >
              {/* {!!eventUser && `$${eventUser.paidAmount}`} */}
              {total >= 0
                ? `$${total.toFixed(2)}`
                : `-$${Math.abs(total).toFixed(2)}`}
            </Typography>
          </section>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles({
  circularProgressContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "1rem 0",
  },

  paper: {
    position: "fixed",
    width: "100%",
    bottom: 0,
    left: 0,
    zIndex: 200,
    borderRadius: "25px 25px 0 0",
    backgroundColor: COLORS.WHITE,
    boxShadow: "0 -2px 8px 0 rgba(13,13,13,0.16)",
  },
  // Doesn't work yet
  btnAnimation: {
    "&:after": {
      animation: "shine 3s ease-in-out  infinite",
      animationFillMode: "forwards",
      content: "''",
      position: "absolute",
      top: "-110%",
      left: "-210%",
      width: "200%",
      height: "200%",
      opacity: 0,
      transform: "rotate(30deg)",
      // background: "rgba(255, 255, 255, 0.13)",
      background:
        "linear-gradient( to right, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0.0) 100%)",
    },
    "&:active:after": {
      opacity: 0,
    },
  },
  proceedToCheckoutBtnRoot: {
    // backgroundColor: COLORS.MEDIUM_GREEN,
    boxShadow: "0px 0px 10px 1px rgba(51,51,51,0.28);",
    // "&:hover": {
    //   backgroundColor: COLORS.MEDIUM_GREEN,
    // },
    borderRadius: 5,
    padding: "1.5rem 0",

    // "&:disabled": {
    //   backgroundColor: COLORS.MEDIUM_GREEN,
    //   opacity: "0.5",
    //   boxShadow: "0px 0px 10px 1px rgba(51,51,51,0.28);",
    // },
  },

  totalContainer: {
    backgroundColor: "#FFEEF1",
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    minHeight: "80px",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      minHeight: "unset",
      padding: "8px 22px",
    },
  },
  total: {
    fontFamily: "CustomFourBold",
  },
  totalAmount: {
    color: COLORS.RED,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  totalContainerPayBtn: {
    position: "absolute",
    top: "50%",
    right: "0",
    transform: "translate(-10px, -50%)",
  },
  customBrainTreeStyles: {
    "& > div[data-braintree-id=wrapper]": {
      margin: "1rem",
    },
    "& > div .braintree-options-list > div": {
      borderColor: "#00AE86",
    },
    "& div.braintree-sheet": {
      borderColor: "#00AE86",
    },
    "& div.braintree-sheet__header": {
      borderColor: "#00AE86",
    },
    "& [data-braintree-id=choose-a-way-to-pay]": {
      fontFamily: "CustomFourBold",
      fontWeight: 500,
    },
    "& [data-braintree-id=methods-label]": {
      fontFamily: "CustomFourBold",
      fontWeight: 500,
    },
    "& [data-braintree-id=toggle]": {
      color: "#00AE86",
      padding: 5,
    },
    "& [data-braintree-id=toggle] > span": {
      borderColor: "#00AE86",
    },
    "& [data-braintree-id=loading-indicator]": {
      borderColor: "#00AE86",
    },
    "& [data-braintree-id=upper-container]:before": {
      borderColor: "#00AE86",
    },
    "& [data-braintree-id=methods-edit]": {
      color: "#00AE86",
    },
  },
});
