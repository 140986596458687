import { makeStyles } from "@material-ui/core/styles";
import { BREAK_POINTS, COLORS } from "src/styles";

export const styles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 1440,
    margin: "0 auto",
    width: "100%",
    minHeight: "100%",
    justifyContent: "space-between",
    flexGrow: 1,
    flexShrink: 0,
    padding: "1rem",
  },
  dishesList: {
    listStyleType: "none",
    display: "grid",
    gridTemplateColumns: `repeat(auto-fill, minmax(260px, 1fr))`,
    gridAutoRows: "400px",
    justifyItems: "center",
    alignItems: "stretch",
    padding: 0,
    paddingBottom: "calc(2rem + env(safe-area-inset-bottom))",
    gridGap: "2rem",
  },
  searchInput: {
    padding: 10,
  },
  mainHeader: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    marginBottom: 0,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  actionButtonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem 0",
    marginBottom: "3rem",
  },
  mobileCreateButton: {
    backgroundColor: COLORS.MEDIUM_RED,
    color: COLORS.WHITE,
    position: "fixed",
    bottom: "calc(75px + env(safe-area-inset-bottom))",
    right: "1rem",
    zIndex: 10,
    display: "none",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "block",
    },
  },
  createButton: {
    backgroundColor: COLORS.MEDIUM_RED,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.WHITE,

    marginLeft: "1rem",
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_RED,
    },

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  createButtonIcon: {
    ...theme.typography.body2,
  },
  card: {
    width: "100%",
    maxWidth: 346,
    borderRadius: 0,
    position: "relative",
    boxShadow: "1px 2px 9px 0 rgba(39,39,39,0.2);",
    height: "100%",
    margin: "0 auto",
  },
  cardBottom: { padding: "1.5em", minHeight: 125 },
  dishName: {
    color: COLORS.MEDIUM_RED,
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    marginTop: 0,
    paddingTop: 0,
    overflow: "hidden",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
  },
  description: {
    color: COLORS.DARK_GREY,

    overflow: "hidden",
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
  },
  dishImage: {
    height: 200,
    objectFit: "cover",
    // minWidth: 300,
  },
}));
