import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles/colors";

export const guestItemStyles = makeStyles({
  card: {
    display: "flex",
    justifyContent: "space-between",
    width: "280px",
    minWidth: "400px",
    padding: "6px",
    marginTop: "12px",
  },
  info: {
    marginLeft: "6px",
  },
  guestEmail: {
    color: "grey",
  },
  cancelButton: {
    background: COLORS.RED,
    borderRadius: "2px",
    height: 40,
    color: COLORS.WHITE,
    boxShadow: "0 2px 6px 0 rgba(90,15,15,0.5)",
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    "&:hover": {
      background: COLORS.RED,
    },
    "&:disabled": {
      background: COLORS.RED,
    },
  },
});
