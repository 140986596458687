import { Button, CircularProgress } from "@material-ui/core";
import { PhotoCamera } from "@material-ui/icons";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { getFileFromAndroid } from "src/utils/android";
import { useStyles } from "./profile-img.styles";

interface IProps {
  src?: string;
  upload: (file: File) => Promise<any>;
}

export const ProfileImage: React.FC<IProps> = ({ src, upload }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: ".jpeg,.png,.jpg",
    disabled: loading,
  });

  function onDrop(acceptedFiles: File[]) {
    if (acceptedFiles.length) {
      const [file] = acceptedFiles;
      setLoading(true);
      upload(file).finally(() => setLoading(false));
    } else {
      sharedAPI.setSnackbarMsg({
        type: "error",
        msg:
          "File type not supported. Only .jpeg, .png, and .jpg are supported",
      });
    }
  }

  const _open = async () => {
    if ((window as any).cordova?.platformId === "android") {
      setLoading(true);
      const file = await getFileFromAndroid();
      upload(file as any).finally(() => setLoading(false));
    } else {
      return open();
    }
  };

  return (
    <>
      <div {...getRootProps()} className={classes.avatarWrapper}>
        <input autoComplete={"off"} {...getInputProps()} />
        <div
          style={{
            width: 126,
            height: 126,
            borderRadius: "50%",
            overflow: "hidden !important",
          }}
          className={src ? classes.avatar : classes.avatarEmpty}
        >
          <img
            style={{
              opacity: src ? 1 : 0,
              transition: `filter 300ms ease-out`,
              filter: isDragActive || loading ? "blur(2px)" : "blur(0px)",
              objectFit: "cover",
              borderRadius: "50%",
              overflow: "hidden !important",
            }}
            alt="user profile"
            src={src}
            className={classes.imgRoot}
          />
        </div>
        <div className={classes.editIcon}>
          {loading ? (
            <CircularProgress className={classes.spinner} />
          ) : src ? null : (
            <PhotoCamera
              style={{ cursor: "pointer", color: COLORS.MEDIUM_GREEN }}
            />
          )}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: ".85rem 0",
        }}
      >
        <Button
          onClick={(event) => {
            event.stopPropagation();
            _open();
          }}
          disabled={loading}
          size="small"
          classes={{ contained: classes.uploadButton }}
          variant="contained"
        >
          upload
        </Button>
      </div>
    </>
  );
};
