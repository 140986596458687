import { Badge, Card, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { IEvent } from "src/models";
import { formatPreptime } from "./helpers";
import { summaryCardStyles as useStyles } from "./summary-card.styles";

interface EventSummaryCardProps {
  event: IEvent;
  slug: string;
}

const getEventTypeText = (type: any[]) => {
  return type
    .map((t) =>
      t === "FIXED_TIME"
        ? "Pop-up"
        : t === "TAKE_OUT"
        ? "To-go"
        : t === "DROP_IN"
        ? "Dine-in"
        : "Delivery"
    )
    .join(" and ");
};

export const EventSummaryCard: React.FC<EventSummaryCardProps> = ({
  event,
  slug,
}) => {
  const classes = useStyles();
  const isFixedTime = event.type.includes("FIXED_TIME");

  return (
    <Badge
      classes={{
        colorPrimary: classes.badgeColorPrimary,
        root: classes.badgeRoot,
      }}
      component="div"
      color="primary"
      badgeContent={
        <Link
          data-testid={`edit-event-details-link`}
          className={classes.editLink}
          to={`/c/menus/edit/${slug}`}
        >
          <Edit style={{ fontSize: "2rem" }} />
        </Link>
      }
    >
      <Card className={classes.card}>
        <Typography variant="h2" component="h2" className={classes.cardHeading}>
          Summary
        </Typography>
        <div className={classes.cardBody}>
          <img
            className={classes.cardImage}
            src={event.imagesGQL[0].small}
            alt={event.name}
          />
          <table className={classes.table}>
            <tbody>
              <tr className={classes.tableRow}>
                <Typography
                  component="th"
                  variant="body2"
                  className={classes.tableHeader}
                >
                  Menu Type:
                </Typography>

                <Typography
                  component="td"
                  variant="body2"
                  className={classes.tableData}
                >
                  {getEventTypeText(event.type)}
                </Typography>
              </tr>
              <tr className={classes.tableRow}>
                <Typography
                  component="th"
                  variant="body2"
                  className={classes.tableHeader}
                >
                  Menu Title:
                </Typography>
                <Typography
                  component="td"
                  variant="body2"
                  className={classes.tableData}
                >
                  {event.name}
                </Typography>
              </tr>
              <tr className={classes.tableRow}>
                <Typography
                  component="th"
                  variant="body2"
                  className={classes.tableHeader}
                >
                  Description:
                </Typography>
                <Typography
                  component="td"
                  variant="body2"
                  className={classes.tableData}
                >
                  {event.description}
                </Typography>
              </tr>
              {event.type.includes("DELIVERY") && (
                <>
                  <tr className={classes.tableRow}>
                    <Typography
                      component="th"
                      variant="body2"
                      className={classes.tableHeader}
                    >
                      Min Order:
                    </Typography>
                    <Typography
                      component="td"
                      variant="body2"
                      className={classes.tableData}
                    >
                      ${event.deliveryInfo?.minimumOrderAmount}
                    </Typography>
                  </tr>
                  <tr className={classes.tableRow}>
                    <Typography
                      component="th"
                      variant="body2"
                      className={classes.tableHeader}
                    >
                      Max Distance:
                    </Typography>
                    <Typography
                      component="td"
                      variant="body2"
                      className={classes.tableData}
                    >
                      {event.deliveryInfo?.maximumDriveTime} Mins
                    </Typography>
                  </tr>
                  <tr className={classes.tableRow}>
                    <Typography
                      component="th"
                      variant="body2"
                      className={classes.tableHeader}
                    >
                      $ / Min
                    </Typography>
                    <Typography
                      component="td"
                      variant="body2"
                      className={classes.tableData}
                    >
                      $
                      {event.deliveryInfo?.pricePerMin ??
                        event.deliveryInfo?.pricePerMile}
                    </Typography>
                  </tr>
                </>
              )}
              <tr className={classes.tableRow}>
                <Typography
                  component="th"
                  variant="body2"
                  className={classes.tableHeader}
                >
                  {"Date & Time:"}
                </Typography>
                <Typography
                  component="td"
                  variant="body2"
                  className={classes.tableData}
                >
                  {`${moment(Number(event.startTime)).format(
                    "ddd MMM Do, YYYY h:mmA"
                  )}-${moment(Number(event.endTime)).format("h:mmA")}`}
                </Typography>
              </tr>
              <tr className={classes.tableRow}>
                <Typography
                  component="th"
                  variant="body2"
                  className={classes.tableHeader}
                >
                  Reserve by:
                </Typography>
                <Typography
                  component="td"
                  variant="body2"
                  className={classes.tableData}
                >
                  {moment(Number(event.reserveBy)).format(
                    "ddd MMM Do, YYYY h:mmA"
                  )}
                </Typography>
              </tr>
              <tr className={classes.tableRow}>
                <Typography
                  component="th"
                  variant="body2"
                  className={classes.tableHeader}
                >
                  Address:
                </Typography>
                <Typography
                  component="td"
                  variant="body2"
                  className={classes.tableData}
                >
                  <address>{event.locale.private}</address>
                </Typography>
              </tr>
              <tr className={classes.tableRow}>
                <Typography
                  component="th"
                  variant="body2"
                  className={classes.tableHeader}
                >
                  Display Location:
                </Typography>
                <Typography
                  component="td"
                  variant="body2"
                  className={classes.tableData}
                >
                  {event.locale.public}
                </Typography>
              </tr>
              {!isFixedTime && (
                <tr className={classes.tableRow}>
                  <Typography
                    component="th"
                    variant="body2"
                    className={classes.tableHeader}
                  >
                    Prep time:
                  </Typography>
                  <Typography
                    component="td"
                    variant="body2"
                    className={classes.tableData}
                  >
                    {formatPreptime(event)}
                  </Typography>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Card>
    </Badge>
  );
};
