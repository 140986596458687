import { gql } from "@apollo/client";

export const GET_DINER_EVENTS = gql`
  query getCooksToChat($input: GetMyEventsPL!) {
    getDinerEvents(getDinerEventsPL: $input) {
      id
      name
      restaurant {
        id
        name
        host {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
