import { Button, makeStyles, styled, Typography } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { COLORS } from "src/styles";

const useStyles = makeStyles({
  container: { display: "flex", alignItems: "center" },
  link: {
    textDecoration: "none",
    color: COLORS.MEDIUM_GREEN,

    fontWeight: 300,

    margin: 0,
  },
});

export const GoBack: FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const { pathname } = history.location;
  const ordersPage = pathname === "/d" || pathname === "/d/";
  if (ordersPage)
    return (
      <Typography
        variant="body2"
        component="p"
        style={{ width: "48px", height: "48px" }}
      />
    );
  return (
    <div className={classes.container}>
      <Button
        onClick={() => history.goBack()}
        style={{
          textTransform: "uppercase",
          fontFamily: "CustomFourBold",
          color: COLORS.MEDIUM_GREEN,
        }}
      >
        <ChevronLeft
          style={{
            marginRight: 2.5,
            fontSize: "2rem",
            verticalAlign: "middle",
            color: COLORS.MEDIUM_GREEN,
          }}
        />
        Back
      </Button>
    </div>
  );
};

export const TabButton = styled(Button)({
  root: {
    borderRadius: 0,
    border: "none",
    backgroundColor: "transparent",
    padding: "10px 10px 10px 50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    color: COLORS.MEDIUM_GREEN,
  },
  label: {
    fontWeight: 600,

    margin: 0,
  },
});
