import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles({
  card: {
    display: "flex",
    width: "100%",
    boxShadow: "none",
    padding: 12,
  },
  circle: {
    width: 48,
    height: 48,
  },
  leftContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "80%",
  },
  leftInnerContainer: { width: "80%", marginLeft: "12px" },
  rightContainer: { width: "20%" },
});
