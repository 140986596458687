import { useQuery } from "@apollo/client";
import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { TaxesFeesToolTip } from "src/components";
import { IEvent, IEventUser } from "src/models";
import { COLORS } from "src/styles";
import { GET_SERVICE_FEE } from "../../api/graphql";
import { ActionItems } from "./action-items";
import { OrderItems } from "./order-items";
import { useStyles } from "./transaction.styles";

interface IProps {
  handleOpenDialog: (type, e) => void;
  event: IEvent;
  currentOrder: IEventUser;
}

export const TransactionDetails: FC<IProps> = ({
  event,
  handleOpenDialog,
  currentOrder,
}) => {
  const classes = useStyles();
  const { data } = useQuery(GET_SERVICE_FEE, {
    variables: { input: event.restaurant.id },
  });
  const serviceFee = data?.getMeta?.serviceFee ?? 0;

  let subTotal = 0;
  if (event.type.includes("FIXED_TIME") && currentOrder) {
    subTotal = currentOrder.reservedFor * event.price;
  } else if (currentOrder && currentOrder.orderedDishes) {
    subTotal = currentOrder.orderedDishes.reduce((acc, next) => {
      const foundDish = event.dishes.find((d) => d.id === next.id);
      let _total = 0;
      const orderedDishes = next.OrderDish
        ? next.OrderDish
        : next.EventUserDish;
      if (orderedDishes?.options) {
        _total = orderedDishes?.options.reduce(
          (_acc, _next) =>
            _acc +
            (_next.addition + (foundDish?.DishEvent?.price ?? 0)) *
              (_next.count ?? 0),
          0
        );
      } else {
        _total = (foundDish?.DishEvent?.price ?? 0) * orderedDishes?.count;
      }

      return acc + _total;
    }, 0);
  }

  return (
    <div className={classes.transactionContainer}>
      <Typography
        variant="subtitle2"
        component="p"
        className={classes.transactionHeadingContainer}
      >
        Order Receipt
      </Typography>
      <div className={classes.orders}>
        <OrderItems event={event} currentOrder={currentOrder} />
      </div>
      <div className={classes.sectionTwo}>
        <div className={classes.priceBreakdown}>
          <div className={classes.flexContainer}>
            <Typography
              variant="body2"
              component="p"
              id="items-subtitle"
              className={classes.priceLabel}
            >
              Subtotal:
            </Typography>
            <Typography
              variant="body2"
              component="p"
              aria-labelledby="items-subtitle"
              data-testid="order-subtotal"
              className={classes.priceText}
            >
              ${subTotal}
            </Typography>
          </div>

          <div className={classes.flexContainer}>
            <Typography
              variant="body2"
              component="p"
              className={classes.priceLabel}
            >
              Taxes & Fees
              <TaxesFeesToolTip
                taxAmount={currentOrder.taxAmount}
                platformFee={currentOrder.serviceFeeAmount}
              />
              :
            </Typography>
            <Typography
              variant="body2"
              component="p"
              data-testid="order-platform-fee"
              className={classes.priceText}
            >
              $
              {(
                currentOrder.taxAmount + (currentOrder.serviceFeeAmount ?? 0)
              ).toFixed(2)}
            </Typography>
          </div>

          {!!(currentOrder && currentOrder.creditAmount) && (
            <div className={classes.flexContainer}>
              <Typography
                variant="body2"
                component="p"
                id="user-credits"
                className={classes.priceLabel}
              >
                Credits:
              </Typography>
              <Typography
                variant="body2"
                component="p"
                aria-labelledby="user-credits"
                data-testid="user-credits"
                className={classes.priceText}
                style={{ color: "purple" }}
              >
                -${currentOrder.creditAmount}
              </Typography>
            </div>
          )}

          {!!(currentOrder && currentOrder.deliveryCost) && (
            <div className={classes.flexContainer}>
              <Typography
                variant="body2"
                component="p"
                className={classes.priceLabel}
              >
                Delivery:
              </Typography>
              <Typography
                variant="body2"
                component="p"
                data-testid="order-tax"
                className={classes.priceText}
              >
                ${currentOrder.deliveryCost.toFixed(2)}
              </Typography>
            </div>
          )}
        </div>
        <div className={classes.totalContainer}>
          <div className={classes.flexContainer}>
            <Typography
              variant="body2"
              component="p"
              className={classes.boldSubText}
              style={{ paddingRight: 4 }}
            >
              Total:{" "}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              data-testid="order-total"
              className={classes.boldSubText}
              style={{ color: COLORS.RED }}
            >
              $
              {(
                subTotal +
                (currentOrder.serviceFeeAmount ?? 0) +
                (currentOrder.taxAmount ?? 0) +
                (currentOrder?.deliveryCost ?? 0) -
                currentOrder.creditAmount
              ).toFixed(2)}
            </Typography>
          </div>
        </div>
      </div>

      <ActionItems
        handleOpenDialog={handleOpenDialog}
        event={event as any}
        currentOrder={currentOrder}
      />
    </div>
  );
};
