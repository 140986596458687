import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { sharedAPI } from "src/shared-graphql";
import { Layout } from "./layout";

export const ContactContainer: FC = () => {
  useEffect(() => {
    sharedAPI.setMobileHeaderTitle({ title: "Contact Us" });
    return () => {
      sharedAPI.setMobileHeaderTitle({ title: "" });
    };
  }, []);

  const _description =
    "COOK Connect's team would love to hear from you! \
    Contact us here via email with any COOK Connect questions, concerns, or comments.";

  return (
    <>
      <Helmet>
        <title>Contact Us | COOK Connect</title>
        <meta name="description" content={_description} />
      </Helmet>
      <Layout />
    </>
  );
};
