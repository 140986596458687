import { useMutation } from "@apollo/client";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { Delete, Edit, RateReview } from "@material-ui/icons";
import moment from "moment";
import React, { FC, useState } from "react";
import { ConfirmationDialog } from "src/components";
import { IRestaurantReview } from "src/models";
import { COLORS } from "src/styles";
import { ADMIN_SEND_REVIEW } from "../api/graphql";
import { EditRestaurantReviewDialog } from "./edit-dialog";
import { useStyles } from "./table.styles";

interface IProps {
  restaurantReviews: IRestaurantReview[];
  redirect: (d: string) => void;
  deleteReview: (d: number) => void;
}
export const RestaurantReviewListTable: FC<IProps> = ({
  restaurantReviews,
  deleteReview,
}) => {
  const classes = useStyles();
  const [adminSendReview] = useMutation(ADMIN_SEND_REVIEW);
  const [hovered, setHover] = useState<string>("0");
  const [deletId, setDeleteId] = useState<number>(0);
  const [reviewTarget, setReviewTarget] = useState<IRestaurantReview>(
    null as any
  );

  const _deleteItem = async (id: number) => {
    deleteReview(id);
    setDeleteId(0);
  };

  return (
    <>
      <ConfirmationDialog
        openDialog={Boolean(deletId)}
        onConfirm={() => _deleteItem(deletId)}
        title={"Delete Restaurant Review"}
        message={"Are you sure you want to delete this review?"}
        onClose={() => setDeleteId(0)}
      />
      <EditRestaurantReviewDialog
        open={Boolean(reviewTarget)}
        handleClose={() => setReviewTarget(undefined as any)}
        review={reviewTarget}
      />
      <TableContainer component={Paper}>
        <Table style={{ maxWidth: "none" }}>
          <TableHead>
            <TableRow>
              <TableCell>Text (id)</TableCell>
              <TableCell>Q/H/A</TableCell>
              <TableCell>Event</TableCell>
              <TableCell>Guest (remindedAt)</TableCell>
              <TableCell>Restaurant</TableCell>
              <TableCell>Tip</TableCell>
              <TableCell className="table-header-cell">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {restaurantReviews.map((row) => {
              return (
                <TableRow
                  style={{
                    backgroundColor: COLORS.WHITE,
                  }}
                  key={row.id}
                  hover
                  onMouseOver={() => setHover(String(row.id))}
                >
                  <TableCell>
                    {row.text} ({row.id})
                  </TableCell>
                  <TableCell>
                    {row.quality}/{row.hospitality}/{row.accuracy}
                  </TableCell>
                  <TableCell>{row.eventInfo.name}</TableCell>
                  <TableCell>
                    {row.guest.firstName}, {row.guest.email},{" "}
                    {row.guest.phoneNumber}{" "}
                    <span style={{ color: COLORS.GREEN }}>
                      ({moment(Number(row.updatedAt)).format("ll")})
                    </span>
                  </TableCell>
                  <TableCell>{row.restaurant.name}</TableCell>
                  <TableCell>${row.tipAmount}</TableCell>
                  <TableCell>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => setReviewTarget(row)}>
                        <Edit
                          className={
                            String(row.id) === hovered
                              ? classes.icon
                              : classes.hidden
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    {row.status === "PENDING" && (
                      <Tooltip title="Send review">
                        <IconButton
                          onClick={() =>
                            adminSendReview({ variables: { input: row.id } })
                          }
                        >
                          <RateReview
                            className={
                              String(row.id) === hovered
                                ? classes.icon
                                : classes.hidden
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Delete">
                      <IconButton onClick={() => setDeleteId(Number(row.id))}>
                        <Delete
                          className={
                            String(row.id) === hovered
                              ? classes.icon
                              : classes.hidden
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
