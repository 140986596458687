import { IEvent, IImage } from "src/models";
import { formatStartToEndDate } from "src/utils/helpers";

const getImages = (e: IEvent) => {
  const sortOrder = ["ENTREE", "SIDE", "DESSERT", "BEVERAGE"];
  const carrotDefault = require("src/assets/logos/carrot.png");
  const imageList: IImage[] = [];
  e.dishes
    .map((_d) => _d)
    .sort(
      (a, b) =>
        sortOrder.indexOf(a.category as string) -
        sortOrder.indexOf(b.category as string)
    )
    .forEach((d) => d.imagesGQL.forEach((i) => imageList.push(i)));

  if (!imageList.length)
    imageList.push({
      publicId: "fakeId",
      small: carrotDefault,
      original: carrotDefault,
      medium: carrotDefault,
      large: carrotDefault,
    });

  return imageList;
};

function getMinPrice(dishes) {
  return dishes.reduce(
    (min, p) => (p.DishEvent.price < min ? p.DishEvent.price : min),
    dishes[0].DishEvent.price
  );
}
function getMaxPrice(dishes) {
  return dishes.reduce(
    (max, p) => (p.DishEvent.price > max ? p.DishEvent.price : max),
    dishes[0].DishEvent.price
  );
}

const getPriceRange = (dishes) => {
  const minPrice = getMinPrice(dishes);
  const maxPrice = getMaxPrice(dishes);
  return { minPrice, maxPrice };
};

function shuffle(array) {
  let counter = array.length;

  // While there are elements in the array
  while (counter > 0) {
    // Pick a random index
    const index = Math.floor(Math.random() * counter);

    // Decrease counter by 1
    counter--;

    // And swap the last element with it
    const temp = array[counter];
    array[counter] = array[index];
    array[index] = temp;
  }

  return array;
}

export { formatStartToEndDate, getImages, getPriceRange };
