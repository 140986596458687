import { useQuery } from "@apollo/client";
import {
  CircularProgress,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { stringify } from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";
import { BREAK_POINTS, COLORS } from "src/styles";
import { StatusTypes } from "../helpers";
import { QUERY_STATUS_COUNTS } from "./queries";

interface StatusTabsProps {
  activeStatus: StatusTypes;
}

const useStyles = makeStyles({
  tab: {
    textAlign: "center",

    textTransform: "capitalize",
    minWidth: 0,

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: ".5rem",
    },
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      maxWidth: 62,
      width: "100%",
      backgroundColor: COLORS.MEDIUM_GREEN,
    },
  },
  flexContainer: {
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      justifyContent: "space-around",
    },
  },
  tabScroller: {},
  tabSelected: {
    color: COLORS.BLACK,
    fontWeight: 500,
    fontFamily: "CustomFourBold",
  },
  tabsContainer: {
    paddingBottom: "1rem",
  },
  loadingSpinner: {
    color: COLORS.MEDIUM_GREEN,
  },
});

export const StatusTabs: React.FC<StatusTabsProps> = React.memo(
  ({ activeStatus }) => {
    const classes = useStyles();
    const history = useHistory();
    const { loading, error, data } = useQuery(QUERY_STATUS_COUNTS, {
      fetchPolicy: "cache-and-network",
    });

    if (error)
      return (
        <Typography variant="body2" component="p">
          Error: {error.message}
        </Typography>
      );

    return (
      <div className={classes.tabsContainer}>
        <Tabs
          classes={{
            indicator: classes.indicator,
            scroller: classes.tabScroller,
            flexContainer: classes.flexContainer,
          }}
          TabIndicatorProps={{ children: <div /> }}
          value={activeStatus}
          onChange={(e, newStatus) =>
            history.push({ search: stringify({ status: newStatus }) })
          }
        >
          <Tab
            classes={{
              root: classes.tab,
              selected: classes.tabSelected,
            }}
            disableRipple
            label={
              <>
                {loading ? (
                  <CircularProgress className={classes.loadingSpinner} />
                ) : (
                  <Typography variant="body2" component="span">
                    Live ({data.cookGetEventCounts.ACTIVE})
                  </Typography>
                )}
              </>
            }
            value="ACTIVE"
          />
          <Tab
            classes={{
              root: classes.tab,
              selected: classes.tabSelected,
            }}
            disableRipple
            label={
              <>
                {loading ? (
                  <CircularProgress className={classes.loadingSpinner} />
                ) : (
                  <Typography variant="body2" component="span">
                    Past ({data.cookGetEventCounts.COMPLETED})
                  </Typography>
                )}
              </>
            }
            value="COMPLETED"
          />
          <Tab
            classes={{
              root: classes.tab,
              selected: classes.tabSelected,
            }}
            disableRipple
            label={
              <>
                {loading ? (
                  <CircularProgress className={classes.loadingSpinner} />
                ) : (
                  <Typography variant="body2" component="span">
                    Drafts ({data.cookGetEventCounts.PENDING})
                  </Typography>
                )}
              </>
            }
            value="PENDING"
          />
          <Tab
            classes={{
              root: classes.tab,
              selected: classes.tabSelected,
            }}
            disableRipple
            label={
              <>
                {loading ? (
                  <CircularProgress className={classes.loadingSpinner} />
                ) : (
                  <Typography variant="body2" component="span">
                    Cancelled ({data.cookGetEventCounts.CANCELLED})
                  </Typography>
                )}
              </>
            }
            value="CANCELLED"
          />
        </Tabs>
      </div>
    );
  }
);
