import { SvgIcon } from "@material-ui/core";
import React from "react";

export const UserNotification = (props) => (
  <SvgIcon {...props} style={{}}>
    <path
      d="M18.32,15.85v-5.01c0-3.63-2.3-6.79-5.74-7.9V2.64c0-1.41-1.15-2.56-2.57-2.56c-1.41,0-2.56,1.15-2.56,2.56v0.31
					C4.01,4.05,1.7,7.22,1.7,10.85v5.01c-1.13,0.44-1.79,1.61-1.59,2.82c0.22,1.23,1.28,2.12,2.51,2.12h4.14
					c0.43,1.44,1.75,2.43,3.25,2.43s2.82-0.99,3.25-2.43h4.14c1.24,0,2.3-0.89,2.51-2.12C20.11,17.47,19.45,16.29,18.32,15.85z
					 M3.54,10.85c0-3.57,2.9-6.47,6.47-6.47c3.57,0,6.47,2.9,6.47,6.47v4.83H3.54V10.85z M17.4,18.96H2.62
					c-0.4,0-0.72-0.32-0.72-0.72c0-0.39,0.32-0.72,0.72-0.72H17.4c0.4,0,0.72,0.33,0.72,0.72C18.12,18.64,17.8,18.96,17.4,18.96z"
    />
  </SvgIcon>
);
