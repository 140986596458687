export interface IResource {
  title: string;
  subtitle: string;
  isExternal: boolean;
  path: string;
}

export const pendingResources = [
  {
    title: "MEHKO Overview",
    subtitle: "View & Download Overview PDF",
    isExternal: true,
    path: "https://drive.google.com/file/d/18KtXe6i1WMiKlJGnkEm5HDS_M_3EmOqo/view?usp=sharing",
  },
  // {
  //   title: "Home Restaurant Webinar",
  //   subtitle: "Attend one of our webinars if you haven’t already!",
  //   isExternal: true,
  //   path: "https://calendly.com/foodnome/info-session",
  // },
  // {
  //   title: "Watch Cook Story Videos",
  //   subtitle:
  //     "Meet some Foodnome cooks who have gone through the same process to start their home restaurant!",
  //   isExternal: true,
  //   path: "https://www.facebook.com/Foodnome/videos",
  // },
] as IResource[];

export const submittedApprovedResources = [
  {
    title: "Start Creating Your Dishes",
    subtitle: "You can also go to the ‘Dishes’ tab",
    isExternal: false,
    path: "/c/dishes",
  },
  // {
  //   title: "Buy Supplies",
  //   subtitle: "Bags, containers, safety supplies, and more…",
  //   isExternal: true,
  //   path: "https://shop.foodnome.com/collections/all",
  // },
  {
    title: "Write Your Bio",
    subtitle: "",
    isExternal: false,
    path: "/c/settings?view=RESTAURANT",
  },
  {
    title: "Read Our Marketing Guide",
    subtitle: "",
    isExternal: true,
    path:
      "https://docs.google.com/document/d/1NXXpyODoOclaRd5WMTVQ3WJ_hsC7jfnUnhTawHpxwTg/edit",
  },
  // {
  //   title: "Watch: Website Overview",
  //   subtitle: "",
  //   isExternal: true,
  //   path: "https://youtu.be/X4SX216LXZA",
  // },
];
