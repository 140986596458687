import {
  Button,
  Dialog,
  DialogActions,
  IconButton,
  Typography,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IGetMe } from "src/models";
import { amplitude } from "src/services";
import { BREAK_POINTS, COLORS } from "src/styles";
import { LocalStorageHelper } from "src/utils/helpers";

interface IProps {
  getMe: IGetMe;
}

export const WelcomeDialog: React.FC<IProps> = ({ getMe }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles();
  const location = useLocation();

  const handleClose = () => {
    setDialogOpen(false);
    LocalStorageHelper.setItem("welcome_dialog", "false");
    amplitude.getInstance().logEvent("[Whats Foodnome Dialog] close");
  };

  useEffect(() => {
    const displayed = localStorage.getItem("welcome_dialog") || "";
    amplitude.getInstance().logEvent("[Whats Foodnome Dialog] landing");
    // Only showing the banner if user is on /menus, is not logged in,
    // hasn't closed the dialog before, and after 10 seconds

    let timer: ReturnType<typeof setTimeout>;

    if (window.hasOwnProperty("cordova")) {
      if (location.pathname === "/") {
        timer = setTimeout(() => {
          if (location.pathname === "/" && !getMe && !displayed) {
            setDialogOpen(true);
          }
        }, 10000);
      }
    } else {
      if (location.pathname === "/menus") {
        timer = setTimeout(() => {
          if (location.pathname === "/menus" && !getMe && !displayed) {
            setDialogOpen(true);
          }
        }, 10000);
      }
    }

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => {
      clearTimeout(timer);
    };
  }, [location.pathname]);

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="welcome-dialog"
      fullWidth
      disableBackdropClick
      maxWidth="sm"
      classes={{
        paper: classes.dialog,
        paperFullWidth: classes.paperFullWidth,
      }}
    >
      <IconButton
        className={classes.closeBtnWelcomeDialog}
        aria-label="close-dialog"
        size="small"
        onClick={handleClose}
        data-testid="close-btn"
      >
        <CloseIcon style={{ color: COLORS.WHITE }} />
      </IconButton>

      <section
        className={classNames(classes.container, classes.headerContainer)}
      >
        <img
          src={require("src/assets/logos/cook.png")}
          className={classes.logo}
        />
        <Typography
          component="h2"
          variant="h2"
          className={classNames(classes.container, classes.h2)}
        >
          Welcome to <span style={{ color: COLORS.RED }}>COOK Connect</span>!
        </Typography>
        <Typography component="p" variant="body1" className={classes.subtitle}>
          We are the first marketplace for permitted{" "}
          <span style={{ fontFamily: "CustomFourBold" }}>home restaurants</span>{" "}
          in the US.
        </Typography>
      </section>
      <section className={classes.h4Background}>
        <Typography
          component="h4"
          variant="subtitle1"
          className={classNames(classes.container, classes.h4)}
        >
          What are home restaurants?
        </Typography>
      </section>
      <section className={classes.contentContainer}>
        <ul className={classes.container}>
          <li className={classes.li}>
            <img
              className={classes.checkIcon}
              src={require("src/assets/icons/check-46.svg")}
            />
            <Typography component="span" variant="body1">
              Small family owned businesses in your neighborhood
            </Typography>
          </li>
          <li className={classes.li}>
            <img
              className={classes.checkIcon}
              src={require("src/assets/icons/check-46.svg")}
            />

            <Typography component="span" variant="body1">
              Home kitchens inspected & permitted by the Health Department
            </Typography>
          </li>
          <li className={classes.li}>
            <img
              className={classes.checkIcon}
              src={require("src/assets/icons/check-46.svg")}
            />

            <Typography component="span" variant="body1">
              Professional cooks serving fresh, quality food - made with love!
            </Typography>
          </li>
        </ul>
        <img
          src={require("src/assets/images/modal_hero.png")}
          className={classes.backgroundImg}
        />
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            classes={{ root: classes.gotItBtn }}
          >
            Got It!
          </Button>
        </DialogActions>
      </section>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 450,
    margin: "0 auto",
    padding: "2rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "1.5rem 2rem",
    },
  },
  dialogActions: {
    backgroundColor: "#EEFFFA",
  },
  h2: {
    fontFamily: "CustomFourBold",
    padding: 0,
    textAlign: "center",
  },
  h4: {
    textAlign: "center",
    fontFamily: "CustomFourBold",
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
  },
  h4Background: {
    backgroundColor: "#FFC95B",
  },
  subtitle: {
    textAlign: "center",
    fontFamily: "CustomFour",
    marginTop: "1rem",
  },
  backgroundImg: {
    backgroundColor: "#EEFFFA",
    width: "100%",
    objectFit: "cover",
    [`@media (max-width: ${BREAK_POINTS.mobileRegular}em)`]: {
      objectFit: "contain",
    },
    [`@media (max-width: ${BREAK_POINTS.mobileSmall}em)`]: {
      display: "none",
    },
  },
  dialog: {
    display: "flex",
    flexDirection: "column",
    margin: 0,
    [`@media (max-width: ${BREAK_POINTS.mobileSmall}em)`]: {
      height: "unset",
      maxHeight: "95vh",
    },
  },
  contentContainer: {
    flexGrow: 1,
    minHeight: 300,
    backgroundColor: "#EEFFFA",
    paddingTop: "2rem",
    [`@media (max-width: ${BREAK_POINTS.mobileRegular}em)`]: {
      paddingTop: 0,
    },
  },
  paperFullWidth: {
    width: "calc(100% - 3rem)",
  },
  gotItBtn: {
    margin: "auto",
    width: "50%",
    padding: "1.5rem",
    marginBottom: "1rem",
    [`@media (max-width: ${BREAK_POINTS.mobileRegular}em)`]: {
      padding: "0.5rem",
      width: "80%",
    },
  },
  checkIcon: {
    height: "3rem",
    marginRight: "1.5rem",
    alignSelf: "flex-start",
    [`@media (max-width: ${BREAK_POINTS.mobileSmall}em)`]: {
      height: "2rem",
    },
  },
  li: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "1.5rem",
    padding: "0 2rem",
    alignItems: "center",
    [`@media (max-width: ${BREAK_POINTS.mobileSmall}em)`]: {
      padding: 0,
    },
  },
  logo: {
    maxHeight: "5rem",
    margin: "0 auto",
    marginBottom: "1rem",
    [`@media (max-width: ${BREAK_POINTS.mobileSmall}em)`]: {
      maxHeight: "3rem",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  closeBtnWelcomeDialog: {
    position: "absolute",
    top: 2,
    right: 2,
    backgroundColor: COLORS.MEDIUM_GREEN,
  },
}));
