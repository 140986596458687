import { client } from "src/apollo";
import { IDish, IPagePL } from "src/models";
import { ADMIN_GET_DISHES, ADMIN_UPDATE_DISH, DELETE_DISH } from "./graphql";

export const dishAPI = {
  getDishes: (pagePL: IPagePL) =>
    client.query<{ getDishes: IDish[] }>({
      query: ADMIN_GET_DISHES,
      variables: {
        input: pagePL,
      },
      fetchPolicy: "network-only",
    }),
  adminUpdateDish: (d: any) =>
    client.mutate({
      mutation: ADMIN_UPDATE_DISH,
      variables: { input: d },
    }),

  adminDeleteDish: async (id: number, offset: number) =>
    client.mutate({
      mutation: DELETE_DISH,
      variables: {
        input: id,
      },
      update: async (store) => {
        let d = store.readQuery({
          query: ADMIN_GET_DISHES,
          variables: { input: { limit: 10 } },
        }) as any;
        const dishes = await dishAPI.getDishes({ limit: 10, offset });
        d = dishes.data;
        store.writeQuery({
          query: ADMIN_GET_DISHES,
          variables: { input: { limit: 10 } },
          data: d,
        });
      },
    }),
};
