import { IconButton, makeStyles, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SERVER_ADDRESS } from "src/apollo";
import { COLORS } from "src/styles";
import { getEnv } from "src/utils/helpers";

interface BannerProps {
  text: string;
  code: string;
  show: boolean;
  externalLink: string;
  whiteList: string[];
  envs: string[];
  bannerColor: string;
  textColor: string;
  newUserOnly: boolean;
}

export const DynamicBanner: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const [bannerData, setBannerData] = useState<BannerProps | null>(null);
  const [displayBanner, setDisplayBanner] = useState<boolean>(false);

  useEffect(() => {
    let unmounted = false;
    const source = axios.CancelToken.source();
    axios
      .get(`${SERVER_ADDRESS}/banner`, { cancelToken: source.token })
      .then((res) => {
        if (!unmounted) {
          setBannerData(res.data);
        }
      })
      .catch((e) => {
        if (!unmounted) {
          if (axios.isCancel(e)) {
            // console.log(`request cancelled:${e.message}`);
          } else {
            // tslint:disable-next-line
            console.error("Error:" + e.message);
          }
        }
      });

    // Cleanup function so React doesn't yell at us
    return () => {
      unmounted = true;
      source.cancel("Cancelling in cleanup");
    };
  }, [location]);

  useEffect(() => {
    const env = getEnv() as string;

    if (bannerData && bannerData?.show) {
      const show =
        bannerData.envs.includes(env) &&
        bannerData.whiteList.includes(location.pathname);
      setDisplayBanner(show);
    }
  }, [bannerData, location]);

  // Use only if we are going to show the banner based on localstorage state
  // const [displayed, setDisplayed] = useState<boolean>(() => {
  //   const res = localStorage.getItem(localStorageKey);
  //   return res ? JsonHelper.Parse(res, () => null) : true;
  // });

  // useEffect(() => {
  //   LocalStorageHelper.setItem(localStorageKey, JSON.stringify(displayed));
  // }, [displayed]);

  if (!displayBanner) return null;
  return (
    <section
      data-testid="dynamic-banner"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: bannerData?.bannerColor,
        padding: ".5rem 2rem",
        width: "100%",
        zIndex: 99,
      }}
    >
      <div
        style={{
          margin: "auto",
        }}
      >
        <div
          style={{
            alignSelf: "center",
            justifySelf: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            textAlign: "center",
            cursor: bannerData?.externalLink ? "pointer" : "default",
          }}
          onClick={() =>
            bannerData?.externalLink
              ? window.open(bannerData.externalLink, "_blank")
              : null
          }
        >
          <Typography
            variant="body2"
            component="p"
            style={{ fontWeight: 100, color: bannerData?.textColor }}
          >
            {bannerData?.text}
          </Typography>
          {bannerData?.code !== "" && (
            <Typography variant="body2" component="p" className={classes.chip}>
              {bannerData?.code}
            </Typography>
          )}
        </div>
      </div>
      <IconButton
        classes={{ root: classes.iconButtonRoot }}
        onClick={() => setDisplayBanner(false)}
        size="small"
      >
        <Close style={{ color: COLORS.WHITE }} />
      </IconButton>
    </section>
  );
};

const useStyles = makeStyles({
  actionButtonsContainer: {},
  iconButtonRoot: {
    backgroundColor: COLORS.MEDIUM_GREEN,
    filter: "drop-shadow(0px 0px 8px rgba(0,0,0,0.2))",
  },
  chip: {
    color: COLORS.RED,
    backgroundColor: "#FFF6D3",
    borderRadius: 13,
    padding: "0 0.5rem",
    fontFamily: "CustomFourBold",
    margin: "0.5rem",
  },
});
