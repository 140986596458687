import { gql } from "@apollo/client";

export const USER_GET_REFERREE = gql`
  query userGetReferree($input: ID!) {
    userGetReferree(referralUserId: $input) {
      id
      firstName
      lastName
    }
  }
`;
