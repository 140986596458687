import { gql } from "@apollo/client";

export const GET_DINER_FIELDS = gql`
  query getDinerFields {
    getMe {
      id
      firstName
      lastName
      email
      phoneNumber
      address
      picture
      preferences {
        NEWS_LETTER_EMAIL_SUBSCRIPTION
      }
      restaurant {
        id
      }
    }
  }
`;
