import { Button, CircularProgress, Typography } from "@material-ui/core";
import { parse } from "query-string";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BTDropInDesktop, TaxesFeesToolTip } from "src/components";
import { IEvent } from "src/models";
import { amplitude } from "src/services";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { OrderItems } from "../../detail/order-info";
import { ActionTypes, ComponentActions } from "../../order-reducer";
import { useStyles } from "./cart.styles";
import { MobileHeader } from "./mobile/mobile-header";

interface IProps {
  getEvent: IEvent;
  isMobile: boolean;
  platformFee: number;
  serviceFeePerc: number;
  platformFeeFake: number;
  itemTotal: number;
  taxAmount: number;
  deliveryCost: number;
  discountPercent: number;
  discountedItemTotal: number;
  applyCoupon: any;
  nomeUsed: number;
  coupon: string;
  setCoupon: any;
  couponError: string;
  couponObj: any;
  dineOption: string;
  submitOrder: any;
  total: number;
  brainTreeInstance: any;
  dispatch: React.Dispatch<ComponentActions>;
  showButton: boolean;
  setBrainTreeInstance: any;
  dialogView?: string;
}

export const Cart: FC<IProps> = ({
  getEvent,
  isMobile,
  platformFee,
  serviceFeePerc,
  dialogView,
  dispatch,
  platformFeeFake,
  itemTotal,
  taxAmount,
  deliveryCost,
  discountPercent,
  discountedItemTotal,
  applyCoupon,
  nomeUsed,
  coupon,
  setCoupon,
  couponError,
  submitOrder,
  couponObj,
  dineOption,
  total,
  showButton,
  brainTreeInstance,
  setBrainTreeInstance,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [_loading, setLoading] = useState(false);

  const location = useLocation();

  const variation = "var-2";
  const queryObj = parse(location.search);

  useEffect(() => {
    if (variation) {
      amplitude
        .getInstance()
        .logEvent(`[Event Detail] Place order ${variation}`, {
          source: queryObj.s,
        });
    }
  }, [variation]);

  const handleSubmit = async () => {
    setLoading(true);
    if (total === 0) {
      // If total is 0, we don't need a BT token because we are not processing a payment
      submitOrder().finally(() => {
        amplitude.getInstance().logEvent("[Desktop Cart] pay with Braintree");
        setLoading(false);
      });
    } else {
      brainTreeInstance?.requestPaymentMethod((err, payload) => {
        if (err || !payload) {
          sharedAPI.setSnackbarMsg({ type: "error", msg: "" });
          setLoading(false);
        } else {
          submitOrder(payload.nonce).finally(() => {
            amplitude
              .getInstance()
              .logEvent("[Desktop Cart] pay with Braintree");
            setLoading(false);
          });
        }
      });
    }
  };

  return (
    <div className={classes.wrapper}>
      {<MobileHeader getEvent={getEvent} title={"Cart"} />}
      <div className={classes.container}>
        <div className={classes.subContainer}>
          <OrderItems getEvent={getEvent} noOrder={false} isMobile={isMobile} />
          <section className={classes.subTotalContainer}>
            {/* =========== Item Subtotal : subtotal =========== */}
            <section
              data-testid="item-subtotal-container"
              className={classes.subTotalItem}
            >
              <Typography
                variant="body2"
                component="p"
                className={classes.subTotalLabel}
              >
                Item Subtotal:
              </Typography>
              <Typography variant="body2" component="p">
                ${itemTotal.toFixed(2)}
              </Typography>
            </section>

            {/* =========== platform fee =========== */}
            {Number(platformFee.toFixed(2)) > 0 && (
              <section
                data-testid="platform-fee-container"
                className={classes.subTotalItem}
              >
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.subTotalLabel}
                >
                  {/* Tax and delivery cost is NOT included in the platform fee */}
                  Taxes & Fees
                  <TaxesFeesToolTip
                    taxAmount={taxAmount}
                    platformFee={platformFee}
                  />
                  :
                </Typography>
                <Typography variant="body2" component="p">
                  ${(platformFee + taxAmount).toFixed(2)}
                </Typography>
              </section>
            )}

            {/* =========== fake display of platform fee when % === 0 =========== */}
            {Number(platformFee.toFixed(2)) <= 0 &&
              Number(platformFeeFake.toFixed(2)) > 0 && (
                <section
                  className={classes.subTotalItem}
                  style={{ height: 22 }}
                >
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.subTotalLabel}
                  >
                    Taxes & Fees
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.subTotalStrike}
                  >
                    ${(platformFeeFake + taxAmount).toFixed(2)}
                  </Typography>
                </section>
              )}

            {/* =========== Delivery =========== */}
            {!!deliveryCost && dineOption === "DELIVERY" && (
              <section
                data-testid="delivery-cost-container"
                className={classes.subTotalItem}
              >
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.subTotalLabel}
                >
                  Delivery:
                </Typography>
                <Typography variant="body2" component="p">
                  ${deliveryCost.toFixed(2)}
                </Typography>
              </section>
            )}

            {/* =========== Tax =========== */}
            {/*
            {Number(taxAmount.toFixed(2)) > 0 && (
              <section
                data-testid="tax-container"
                className={classes.subTotalItem}
              >
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.subTotalLabel}
                >
                  TAX:
                </Typography>
                <Typography variant="body2" component="p">
                  ${taxAmount.toFixed(2)}
                </Typography>
              </section>
            )} */}

            {/* =========== Item Subtotal : credit =========== */}
            {Number(nomeUsed.toFixed(2)) > 0 && (
              <section
                data-testid="credit-container"
                className={classes.subTotalItem}
                style={{ color: COLORS.MEDIUM_GREEN }}
              >
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.subTotalLabel}
                >
                  Credits:
                </Typography>
                <Typography variant="body2" component="p">
                  - ${nomeUsed.toFixed(2)}
                </Typography>
              </section>
            )}

            {/* =========== Item Subtotal : discount =========== */}
            {Boolean(discountPercent) && (
              <section className={classes.discountContainer}>
                <div
                  className={classes.subTotalItem}
                  style={{ color: COLORS.MEDIUM_GREEN }}
                >
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.subTotalLabel}
                  >
                    Discount:
                  </Typography>
                  <Typography variant="body2" component="p">
                    - ${(itemTotal - discountedItemTotal).toFixed(2)}
                  </Typography>
                </div>
                {couponObj &&
                  couponObj.max === itemTotal - discountedItemTotal && (
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.couponMaxText}
                    >
                      {/* Maximum of ${couponObj.max} is applied */}
                      Discount has been applied
                    </Typography>
                  )}
              </section>
            )}

            {/* =========== Item Subtotal : coupon =========== */}
            {!discountPercent && (
              <section className={classes.promoContainer}>
                <input
                  autoComplete={"off"}
                  className={classes.inputField}
                  type="text"
                  placeholder="Add Promo Code"
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value)}
                />
                <Button
                  onClick={applyCoupon}
                  variant="contained"
                  color="secondary"
                  size="medium"
                  disableElevation
                  classes={{
                    root: classes.applyBtn,
                    disabled: classes.disabledBtn,
                  }}
                >
                  Apply
                </Button>
              </section>
            )}
            {couponError && (
              <Typography
                variant="body2"
                component="p"
                className={classes.couponError}
              >
                {couponError}
              </Typography>
            )}
          </section>
        </div>

        {isMobile && (
          <div
            className={
              dialogView
                ? classes.proceedToCheckoutContaineerDialog
                : classes.proceedToCheckoutContaineer
            }
          >
            {/* =========== Total =========== */}
            <section
              data-testid="grand-total-container"
              className={classes.totalContainer}
            >
              <Typography
                variant="body1"
                component="p"
                className={classes.total}
              >
                Total:
              </Typography>
              <Typography
                variant="body1"
                component="p"
                className={classes.totalAmount}
              >
                {/* {!!eventUser && `$${eventUser.paidAmount}`} */}
                {total >= 0
                  ? `$${total.toFixed(2)}`
                  : `-$${Math.abs(total).toFixed(2)}`}
              </Typography>
            </section>
            <div
              style={{
                padding: "1rem 3rem",
                backgroundColor: COLORS.WHITE,
                paddingBottom: "calc(1rem + env(safe-area-inset-bottom))",
              }}
            >
              <Button
                classes={{
                  root: classes.proceedToCheckoutBtnRoot,
                }}
                fullWidth
                variant="contained"
                onClick={() => {
                  amplitude
                    .getInstance()
                    .logEvent("[Mobile Cart] proceed to mobile-payment page");
                  dialogView
                    ? dispatch({
                        type: ActionTypes.SET_EVENT_DIALOG_VIEW,
                        payload: "checkout-mobile",
                      })
                    : history.push("checkout/mobile-payment");
                }}
                // @ts-ignore
                color={variation === "var-1" ? "secondary" : "primary"}
              >
                Proceed to checkout
              </Button>
            </div>
          </div>
        )}
        {/* DESKTOP */}
        {!isMobile && (
          <>
            {/* =========== Total =========== */}
            <section
              data-testid="grand-total-container"
              className={classes.totalContainer}
            >
              <Typography
                variant="body2"
                component="p"
                className={classes.total}
              >
                Total:
              </Typography>
              <Typography
                variant="body2"
                component="p"
                className={classes.totalAmount}
              >
                {/* {!!eventUser && `$${eventUser.paidAmount}`} */}
                {total >= 0
                  ? `$${total.toFixed(2)}`
                  : `-$${Math.abs(total).toFixed(2)}`}
              </Typography>
            </section>

            {total > 0 && (
              <>
                <div
                  style={{
                    display: !!brainTreeInstance ? "unset" : "none",
                  }}
                >
                  <BTDropInDesktop
                    setBrainTreeInstanceHandler={setBrainTreeInstance}
                  />
                </div>
                {!brainTreeInstance && (
                  <div className={classes.circularProgressContainer}>
                    <CircularProgress />
                  </div>
                )}
              </>
            )}
            <div style={{ padding: "1rem" }}>
              <Button
                disabled={(!showButton || _loading) && total > 0}
                variant="contained"
                fullWidth
                classes={{
                  root: classes.payBtnRoot,
                }}
                onClick={() => handleSubmit()}
                // @ts-ignore
                color={variation === "var-1" ? "secondary" : "primary"}
              >
                {_loading ? (
                  <CircularProgress style={{ color: COLORS.WHITE }} size={22} />
                ) : (
                  "Place Order"
                )}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
