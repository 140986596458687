import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@material-ui/core";
import { ChevronLeft, Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IGetMe } from "src/models";
import { COLORS } from "src/styles";
import { LocalStorageHelper } from "src/utils/helpers";
import { Questions } from "../../components/questions/questions";
import { useStyles } from "./out-county.styles";

interface IProps {
  getMe: IGetMe;
}

export const OutCounty: React.FC<IProps> = ({ getMe }) => {
  const classes = useStyles();

  const history = useHistory();
  const [emailSent, setSentEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openPreWrittenEmailDialog, setOpenPreWrittenEmailDialog] = useState(
    false
  );

  useEffect(() => {
    LocalStorageHelper.setItem("foodnome_become_cook_intro_complete", "true");
  }, []);

  const county = getMe.restaurant?.county
    ? getMe.restaurant?.county?.replace(/ county/i, "")
    : "";
  const firstName = getMe.firstName;
  const lastName = getMe.lastName;
  const email = getMe.email;

  // This is "off" because we are using the request that the ab626 page uses
  const checkbox = "off";

  const data = {
    "First-Name": firstName,
    "Last-Name": lastName,
    Email: email,
    County: county,
    checkbox,
  };

  // const sendEmail = () => {
  //   setLoading(true);
  //   contactSupervisor(data)
  //     .then((d) => {
  //       sharedAPI.setSnackbarMsg({
  //         type: "success",
  //         msg: `Your email was sent to ${county} county. Thanks!`,
  //       });
  //       setSentEmail(true);
  //       setLoading(false);
  //       LocalStorageHelper.setItem(
  //         "contact_supervisor_at",
  //         moment().valueOf().toString()
  //       );
  //     })
  //     .catch(() => {
  //       sharedAPI.setSnackbarMsg({
  //         type: "error",
  //         msg: `Oops! Something went wrong sending the email.`,
  //       });
  //       // Going to make the sent email to true if failure so
  //       // users don't keep trying to send email and can be directed somewhere else
  //       setSentEmail(true);
  //       setLoading(false);
  //     });
  // };

  const handleClose = () => {
    setOpenPreWrittenEmailDialog(false);
  };

  return (
    <>
      <img
        className={classes.tomato}
        alt="tomato"
        src={require("../../assets/tomato-10.svg")}
      />
      <img
        className={classes.leaf}
        alt="leaf"
        src={require("../../assets/leaf-11.svg")}
      />
      <img
        className={classes.noodle}
        alt="noodle"
        src={require("../../assets/noodle-12.svg")}
      />
      <img
        className={classes.squiggle}
        alt="squiggle"
        src={require("../../assets/squig-01.png")}
      />
      <div className={classes.container}>
        <div className={classes.row1}>
          <div className={classes.buttonsWrapper}>
            <div>
              <Button
                startIcon={<ChevronLeft className={classes.backButtonArrow} />}
                className={classes.backButton}
                onClick={() => history.goBack()}
              >
                Back
              </Button>
            </div>
          </div>
          {/* {counties.some((item) => item.label === county) && (
            <div className={classes.headerContainer}>
              <Typography variant="h2" component="h2" className={classes.title}>
                Make your voice heard
              </Typography>
            </div>
          )} */}
        </div>

        <div
          className={classes.row2}
          style={
            emailSent
              ? {
                  backgroundColor: COLORS.WHITE,
                  border: `1px solid ${COLORS.MEDIUM_GREEN}`,
                }
              : {}
          }
        >
          {/* {counties.some((item) => item.label === county) ? (
            <>
              {!emailSent ? (
                <>
                  <Typography variant="body1" component="p">
                    Help grow opportunity in our food system. Send a pre-written
                    email to {county} County’s local representatives in support
                    of the home cooking movement.
                  </Typography>
                  <div className={classes.actionButtonContainer}>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={loading}
                      onClick={() => sendEmail()}
                    >
                      {loading ? <CircularProgress /> : "Send Email"}
                    </Button>
                    <Button
                      onClick={() => setOpenPreWrittenEmailDialog(true)}
                      variant="text"
                      className={classes.readEmailBtn}
                    >
                      Click here to read pre-written email
                    </Button>
                  </div>
                </>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      className={classes.checkmark}
                      src={require("src/assets/icons/check-46.svg")}
                      alt="check mark"
                    />
                    <Typography variant="body1" component="p">
                      Your email has been sent!
                    </Typography>
                  </div>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.goToProfilePageBtn}
                    onClick={() => history.push("/c")}
                  >
                    Go to your profile
                  </Button>
                </div>
              )}
            </>
          ) : (
            <>
              <Typography variant="body1" component="p">
                Looks like you don't live in a county that offers in home
                restaurants. Please check back again later.
              </Typography>
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.goToProfilePageBtn}
                  onClick={() => history.push("/c")}
                >
                  Go to your profile
                </Button>
              </div>
            </>
          )} */}
        </div>
        <div className={classes.row3}>
          <Questions />
        </div>
      </div>
      <Dialog
        onClose={handleClose}
        className={classes.dialogContent}
        open={openPreWrittenEmailDialog}
      >
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <Close style={{ color: COLORS.MEDIUM_GREEN }} />
        </IconButton>
        <DialogContent>
          <Typography variant="body1" component="p">
            <strong style={{ fontFamily: "CustomFourBold" }}>
              Below is the pre-written email that will be sent to your local
              County Officials on your behalf:
              <br />‍<br />
            </strong>
            Dear [Supervisor Name],
            <br />‍<br />I am an active community member in your district, and a
            home cooking advocate. Our community is in crisis. Our workers are
            stuck at home without employment. Our seniors are struggling to find
            safe food to eat. We need a clear plan to rebuild our local economy.
            Now more than ever, we need to adopt the Home Cooking Bill AB626,
            for the future of our community.
            <br />‍<br />
            Home cooking permits supply employment, safe community service, and
            critical local economic stimulus at a time we need them most. <br />
            ‍<br />
            After a year of operation and tens of thousands of meals sold,
            Riverside County’s permitted home kitchens maintain a 100% food
            safety record. Riverside’s program has been hailed as an
            unquestionable success; their Board of Supervisors has been lauded
            by regional, state, and national press for this people-first policy.
            <br />‍<br />
            Home cooking is safe:
            <br />- A single Food Safety Manager certified home cook handling
            food
            <br />- All procedures reviewed and kitchen inspected by Health
            Department
            <br />- The cook is the sole face of their business, and has
            personal accountability to the public for food safety
            <br />
            <br />
            As emergency relief in the shutdown, Solano County just passed
            AB626, and will begin permitting kitchens after shelter in place is
            lifted. They’ve put forth one of the first proactive solutions to
            the unemployment crisis in our state, and there is no reason we
            cannot follow their lead. <br />‍<br />
            The opportunity in this bill is clear. As my representative, I
            implore you to vote “Yes” to adopt California’s Home Cooking Bill
            AB626 immediately.
            <br />‍<br />
            Please respond to this message so I know that my voice has been
            heard.
            <br />
            <br />
            Best Regards,
            <br />
            [Your Name]
            <br />
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};
