import { makeStyles, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import SwipeableViews from "react-swipeable-views";
import { COLORS } from "src/styles";

interface IValue {
  title: string;
  statement: string;
  image: string;
}
interface IProps {
  valuesContent: IValue[];
  step: number;
  setStep: (d: number) => void;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    maxWidth: 500,
    color: COLORS.DARK_BLUE,

    fontWeight: 300,

    margin: "15px 0 30px 0",
  },
  valueText: {
    fontFamily: "CustomFourMedium",
    marginBottom: "2.5rem",
    alignItems: "left",
  },
});

export const ValuesViewPort: FunctionComponent<IProps> = ({
  valuesContent,
  step,
  setStep,
}) => {
  const classes = useStyles();
  return (
    <SwipeableViews
      axis={"x"}
      index={step}
      onChangeIndex={setStep}
      enableMouseEvents
    >
      {valuesContent.map((value, index) => (
        <div className={classes.container} key={index}>
          <Typography variant="h2" component="h2" className={classes.title}>
            {value.title}
          </Typography>
          <Typography
            variant="h4"
            component="p"
            className={classes.valueText}
          >
            {value.statement}
          </Typography>
        </div>
      ))}
    </SwipeableViews>
  );
};
