import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    width: "100%",
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  expansionButton: {
    border: "1px solid black",
    borderRadius: 2,
    color: COLORS.BLACK,

    minWidth: "14rem",

    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:disabled": {
      border: "1px solid #858486",
      borderRadius: 2,
      color: COLORS.BLACK,
    },
  },
  text: {
    color: COLORS.BLACK,
  },
});
