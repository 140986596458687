import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    width: "100%",
    maxWidth: 400,
    zIndex: 10,
    backgroundColor: COLORS.WHITE,
    boxShadow: "0px 2px 14px #BCBCBC",

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  contentContainer: {
    top: 56,
    position: "sticky",
    width: "100%",
    height: "calc(100vh - 56px)",
    maxHeight: "calc(100vh - 56px)",
    display: "grid",
    gridAutoRows: "60% 40%",
  },
  top: {
    overflow: "auto",
  },
  bottom: {},
  privateTimeContainer: {
    display: "flex",
    height: "50px",
    borderBottom: "1px solid #E2E2E2",
    color: COLORS.BLACK,

    alignItems: "center",
  },
  private: {
    borderRight: "1px solid #E2E2E2",
    width: "34%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  privateWord: {
    fontWeight: 500,
    fontFamily: "CustomFourBold",
  },
  lock: {
    marginRight: ".75rem",
  },
  time: {
    textAlign: "center",
    margin: "0 auto",

    flexGrow: 1,
  },
  timeDate: {
    fontFamily: "CustomFourBold",
    marginRight: "4px",
    fontWeight: 500,
  },
  selection: {
    height: "190px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.WHITE,
  },
  refundNote: {
    width: "100%",
    margin: "1rem 0",
    color: COLORS.MEDIUM_GREY,
    textAlign: "center",
    minWidth: 220,
    marginBottom: "2rem",
  },
  creditContainer: {
    fontFamily: "CustomFourBold",
    padding: "16px",
    color: "#7528E7",

    display: "flex",
    justifyContent: "space-between",
  },
  subTotalContainer: {
    textAlign: "center",
    color: "grey",
    display: "flex",
    justifyContent: "space-between",
  },
  subTotalWord: {
    paddingTop: "6px",
  },
  totalAmount: {
    color: COLORS.BLACK,
  },
  promo: {
    margin: 0,
    textAlign: "left",

    color: COLORS.RED,
  },
  bagIcon: {
    color: COLORS.WHITE,
    marginLeft: 10,
    width: 24,
  },
  buttonPriceContainer: {
    alignSelf: "start",
    marginRight: "1.8rem",
  },
  priceContainer: {
    height: "3rem",
  },
});
