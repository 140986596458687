import { makeStyles } from "@material-ui/core/styles";
import React from "react";

export const CustomMarker = (props: any) => {
  const { color, name, id } = props;
  const classes = useStyles();
  return (
    <img
      src="https://foodnome-static-assets.s3.us-west-1.amazonaws.com/images/logos/simple_map_icon.png"
      className={classes.marker}
      style={{ backgroundColor: color, cursor: "pointer" }}
      title={name}
    />
  );
};

const useStyles = makeStyles(() => ({
  marker: {
    position: "absolute",
    top: "0",
    left: "50%",
    height: "40px",
    userSelect: "none",
    transform: "translate(-50%, -100%)",

    "&:hover": {
      zIndex: 1,
    },
    // [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
    //   height: "40px",
    // },
  },
}));
