import { parse, stringify } from "query-string";
import React, { useEffect, useReducer } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import { AppStatus, IGetMe } from "src/models";
import {
  initialState,
  reducer,
} from "src/screens/become-cook-info/store/reducer";
import { Layout } from "./layout";
import { BecomeCookContext } from "./store/become-cook-context";

interface IProps {
  data: { getMe: IGetMe; loading: boolean; error: any };
}

export const BecomeCookInfo: React.FC<IProps> = ({ data }) => {
  const { getMe } = data;
  const history = useHistory();
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);

  const { step } = parse(location.search);

  // Effect to remove token from URL params if successfully logged in with token
  useEffect(() => {
    const { token } = parse(location.search) as { token?: string };
    if (getMe && token) {
      history.replace({ search: stringify({ step }) });
    }
  }, [getMe, location.search]);

  useEffect(() => {
    // if the cook has already submitted or has been approved/rejected => send them to cook profile
    if (getMe?.restaurant?.application?.status) {
      if (
        getMe.restaurant.application.status === AppStatus.SUBMITTED ||
        getMe.restaurant.application.status === AppStatus.REJECTED ||
        getMe.restaurant.application.status === AppStatus.APPROVED
      ) {
        return history.push({
          pathname: `/c/menus`,
          search: stringify({ status: "ACTIVE" }),
        });
      }
    }
  }, [getMe]);

  return (
    <>
      <Helmet>
        <title>Home Restaurant Application | Foodnome</title>
        <meta
          name="description"
          content="Tools to help you start your home based business selling \
          food! Home restaurant permitting, online marketplace listing, and more."
        />
      </Helmet>
      <BecomeCookContext.Provider value={{ state, dispatch }}>
        <Layout getMe={getMe} />
      </BecomeCookContext.Provider>
    </>
  );
};
