import {
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React from "react";

export const Header = ({ totalCount, onSearch }) => {
  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
      }}
    >
      <Typography variant="h3" component="h1" style={{ marginBottom: "2rem" }}>
        Dishes ({totalCount})
      </Typography>
      <TextField
        size="small"
        variant="outlined"
        onChange={(e) => onSearch(e.target.value)}
        label="Search Dish name"
        style={{ maxWidth: "40rem", width: "100%" }}
        inputProps={{ maxLength: 200 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </Paper>
  );
};
