import { makeStyles } from "@material-ui/core/styles";
import { BREAK_POINTS, COLORS } from "src/styles";

const drawerWidth = "100%";

export const styles = makeStyles((theme) => ({
  container: {
    height: 56,
    display: "none",
    backgroundColor: COLORS.WHITE,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    color: COLORS.BLACK,
    fontFamily: "CustomFourBold",

    fontWeight: 500,
    margin: 0,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
}));
