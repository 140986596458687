import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS } from "src/styles";
import { COLORS } from "src/styles/colors";

export const styles = makeStyles({
  card: {
    width: "250px",
    height: "250px",
    borderRadius: "20px",
    margin: "1em .5em",
    position: "relative",
    boxShadow: "3px 4px 19px 0 rgba(31,64,80,0.28)",
  },
  image: {
    width: "100%",
    objectFit: "cover",
    backgroundPosition: "center center",
    zIndex: 1,
    position: "relative",
  },
  actions: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 5,
  },
  cardTop: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "CustomFourBold",

    textAlign: "center",
  },
  location: {
    color: "#616527",
  },
  content: {
    marginTop: -20,
    padding: "10px 10px 5px 10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  mainHeading: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    marginBottom: 0,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  container: {
    padding: "1rem",
  },
  headerContent: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexWrap: "wrap",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexGrow: 0,
    },
  },
  eventsList: {
    listStyleType: "none",
    display: "grid",
    gridTemplateColumns: `repeat(auto-fill, minmax(260px, 1fr))`,
    justifyItems: "center",
    alignItems: "stretch",
    padding: 0,
    paddingBottom: "calc(2rem + env(safe-area-inset-bottom))",
    margin: 0,
    gridGap: "2rem",
  },
  header: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  headerLayout: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: ".5rem 0",
  },
  headerActionItem: {
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      margin: "0 0 0 0.5rem",
    },
  },
  createEventButton: {
    backgroundColor: COLORS.MEDIUM_RED,
    color: COLORS.WHITE,
    fontWeight: 500,
    fontFamily: "CustomFourBold",

    "&:hover": {
      backgroundColor: COLORS.DARK_RED,
    },
  },
  createEventButtonMobile: {
    position: "fixed",
    right: "1rem",
    bottom: "calc(75px + env(safe-area-inset-bottom))",
    backgroundColor: COLORS.MEDIUM_RED,
    color: COLORS.WHITE,
    fontWeight: 500,
    fontFamily: "CustomFourBold",
    fontSize: "2rem",

    zIndex: 4,
    "&:hover": {
      backgroundColor: COLORS.DARK_RED,
    },
  },
});
