import {
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React, { FC, useEffect, useState } from "react";
import { useDelay } from "src/utils/hooks";
import { CouponCreateContainer } from "../create/container";

interface IProps {
  count: number;
  pageNum: number;
  onSearch: (d: { pageNumber: number; searchTerm?: string }) => void;
}
export const Header: FC<IProps> = ({ count, pageNum, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDelay(searchTerm, 300);

  useEffect(() => {
    onSearch({ pageNumber: 1, searchTerm: debouncedSearchTerm });
  }, [debouncedSearchTerm]);

  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
      }}
    >
      <Typography variant="h3" component="h1" style={{ marginBottom: "2rem" }}>
        Coupons ({count})
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "flex-end",
        }}
      >
        <TextField
          size="small"
          variant="outlined"
          label="Search by code or restaurant"
          style={{ maxWidth: "40rem", width: "100%" }}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <div style={{ marginTop: "1rem" }}>
          <CouponCreateContainer offset={(pageNum - 1) * 10} />
        </div>
      </div>
    </Paper>
  );
};
