import { Gavel, ImportContacts } from "@material-ui/icons";
import HomeIcon from "@material-ui/icons/Home";
import MapIcon from '@material-ui/icons/Map';
import React from "react";
import { CookDish } from "src/assets/icons/svg/cook-dish";
import { CookMenu } from "src/assets/icons/svg/cook-menu";
import { DinerOrder } from "src/assets/icons/svg/diner-order";
import { Setting } from "src/assets/icons/svg/setting";
import { UserFAQ } from "src/assets/icons/svg/user-faq";
import { IGetMe } from "src/models";

interface IWhenPL {
  user: IGetMe;
  isCookMode: boolean;
  isMobile?: boolean;
}

const isDinerAndMobileView = ({ user, isCookMode, isMobile }: IWhenPL) =>
  Boolean(!!user && !isCookMode && isMobile);
const isDinerAndDesktopView = ({ user, isCookMode, isMobile }: IWhenPL) =>
  !!user && !isCookMode && !isMobile;
const isCookAndDesktopView = ({ user, isCookMode, isMobile }: IWhenPL) =>
  !!user && isCookMode && !isMobile;
const isCookAndMobileView = ({ user, isCookMode, isMobile }: IWhenPL) =>
  Boolean(!!user && isCookMode && isMobile);

const hasNoRestaurant = ({ user }) => user && !user.restaurant;

const isDinerModeOn = ({ isCookMode }) => !isCookMode;
const isCookModeOn = ({ isCookMode }) => isCookMode;

export interface IAuthStaticRoute {
  pathname: string;
  name: string;
  when: boolean | ((pl: IWhenPL) => boolean);
  icon?: any;
}

export type IAuthFixedRoute = Omit<IAuthStaticRoute, "when">;

export const authStaticRoutes = (): IAuthStaticRoute[] => [
  {
    pathname: "/d/menus/upcoming",
    name: "Orders",
    when: isDinerAndDesktopView,
    icon: <DinerOrder />,
  },
  {
    pathname: "/c/home",
    name: "Home",
    when: isCookAndDesktopView,
    icon: <HomeIcon />,
  },
  {
    pathname: "/c/home",
    name: "Home",
    when: isCookAndMobileView,
    icon: <HomeIcon />,
  },
  {
    pathname: "/c/menus",
    name: "Menus",
    when: isCookAndDesktopView,
    icon: <CookMenu />,
  },
  {
    pathname: "/c/dishes",
    name: "Dishes",
    when: isCookAndDesktopView,
    icon: <CookDish />,
  },
  {
    pathname: "/d/settings?view=ACCOUNT",
    name: "Settings",
    when: isDinerAndDesktopView,
    icon: <Setting />,
  },
  {
    pathname: "/settings?view=ACCOUNT",
    name: "Settings",
    when: isDinerAndMobileView,
    icon: <Setting />,
  },
  {
    pathname: "/c/settings?view=RESTAURANT",
    name: "Settings",
    when: isCookAndDesktopView,
    icon: <Setting />,
  },
  {
    pathname: "/settings?view=RESTAURANT",
    name: "Settings",
    when: isCookAndMobileView,
    icon: <Setting />,
  },
  {
    pathname: "/sell-your-food",
    name: "Sell your Food",
    when: hasNoRestaurant,
    icon: <CookDish />,
  },
  {
    pathname: '/map',
    name: 'MEKHO Map',
    when: true, // or a function that determines when to show this route
    icon: <MapIcon />, // the imported icon component
  },
];

export const helpAndSupportRoutes = [
  {
    pathname: "/faq",
    name: "FAQ",
    icon: <UserFAQ />,
    when: true,
  },
  // Removed for Transition to Cook Alliance Feb, 24
  // {
  //   pathname: "/privacy",
  //   name: "Privacy Policy",
  //   icon: <ImportContacts />,
  //   when: true,
  // },
  // {
  //   pathname: "/terms-and-conditions",
  //   name: "Terms & Conditions",
  //   icon: <Gavel />,
  //   when: true,
  // },
];
