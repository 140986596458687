import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    height: "calc(100vh - 56px)",
    minHeight: "calc(100vh - 56px)",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "90%",
    height: "100%",
    maxWidth: "140rem",
    margin: "0 auto",
  },
  squiggle: {
    width: "100%",
    margin: "2rem 0",
    maxWidth: "27rem",
  },
  card: {
    position: "relative",
    backgroundColor: "rgba(255,255,255,0.89)",
    borderRadius: "1rem",
    display: "flex",
    flexDirection: "column",
    maxWidth: "64rem",
    padding: "6rem",
    paddingTop: "8rem",
    justifyContent: "center",
    alignItems: "center",
  },
  video: {
    position: "fixed",
    zIndex: -999,
    width: "auto",
    height: "auto",
    minWidth: "100%",
    minHeight: "100%",
    filter: "brightness(80%)",
  },
  title: {
    textAlign: "center",
  },
  text: {
    textAlign: "center",
    fontFamily: "CustomFour",
    marginBottom: "2rem",
  },
  searchContainer: {
    width: "90%",
  },
  logo: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0)",
    top: "-10%",
    width: "100%",
    maxWidth: "13rem",
  },
});
