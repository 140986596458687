import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { ActionDialog } from "src/components";
import { useCreateChannel } from "src/components/chat2";
import { IGetMe } from "src/models";

export interface SaveButtonProps {
  restaurant: any;
  currentUser: IGetMe | null | undefined;
  variant: "outlined" | "contained";
}

const useStyles = makeStyles({
  btn: {},
});

export const ChatButton: React.FC<SaveButtonProps> = ({
  restaurant,
  currentUser,
  variant,
}) => {
  const classes = useStyles();
  const { createChannel, loading } = useCreateChannel();

  const initChat = () => {
    return createChannel(restaurant.host.id);
  };

  // Successfully fire submit handler or ask the user to sign up / log in
  // const submitHandler = bindSubmitHandler(currentUser)({
  //   openLoginDialog,
  //   openVerifyDialog,
  //   handleSubmit: initChat,
  // });

  return (
    <ActionDialog getMe={currentUser}>
      <Button
        size="small"
        variant={variant}
        color="secondary"
        disabled={loading}
        fullWidth
        className={classes.btn}
        onClick={initChat}
      >
        Chat
      </Button>
    </ActionDialog>
  );
};
