import { gql } from "@apollo/client";

export const CREATE_DISH = gql`
  mutation createDish($input: CreateDishPL!) {
    createDish(createDishPL: $input) {
      id
      name
      description
      isPublished
      tags
      defaultPrice
      imagesGQL {
        publicId
        small
        medium
        large
        original
      }
      updatedAt
      createdAt
    }
  }
`;

export const SET_DISCOUNT_PROMPT = gql`
  mutation setDiscountPromopt($input: Boolean) {
    setDiscountPromopt(status: $input) @client
  }
`;

export const SET_DIALOG_EVENT_ID = gql`
  mutation setDialogEventId($input: String!) {
    setDialogEventId(eventId: $input) @client
  }
`;
export const SET_DIALOG_RESTAURANT_ID = gql`
  mutation setDialogRestaurantName($input: String!) {
    setDialogRestaurantName(restaurantName: $input) @client
  }
`;

export const SET_SIGNIN_DIALOG_STATUS = gql`
  mutation setSignInDialogStatus($input: Boolean) {
    setSignInDialogStatus(status: $input) @client
  }
`;

export const SET_SNACKBAR_MSG = gql`
  mutation setSnackbarMsg($input: SnackbarMsgPL) {
    setSnackbarMsg(snackbarMsgPL: $input) @client {
      type
      msg
    }
  }
`;

export const SET_MOBILE_HEADER_TITLE = gql`
  mutation setMobileHeaderTitle($input: MobileHeaderTitlePL!) {
    setMobileHeaderTitle(setMobileHeaderTitlePL: $input) @client {
      title
      pathname
    }
  }
`;

export const SET_GLOBAL_DRAWER_STATE = gql`
  mutation globalDrawerState($state: Boolean!) {
    setGlobalDrawerState(state: $state) @client
  }
`;

export const SET_SHARE_DRAWER = gql`
  mutation setShareDrawerConfig(
    $defaultArgs: ShareDrawerDefaultPL
    $facebook: ShareDrawerFacebookPL
    $twitter: ShareDrawerTwitterPL
    $whatsApp: ShareDrawerWhatsAppPL
    $email: ShareDrawerEmailPL
  ) {
    setShareDrawerConfig(
      defaultArgs: $defaultArgs
      facebook: $facebook
      twitter: $twitter
      whatsApp: $whatsApp
      email: $email
    ) @client {
      link
      drawerTitle
      description
      tags
      twitter {
        description
        tags
      }
      facebook {
        description
        tag
      }
      whatsApp {
        description
      }
      email {
        subject
        body
      }
    }
  }
`;

export const SET_AUTH_DRAWER_STATE = gql`
  mutation setAuthDrawerState($state: boolean!) {
    setAuthDrawerState(state: $state) @client
  }
`;

export const SET_REFERRAL_DIALOG_STATE = gql`
  mutation setReferralDialogState($state: boolean!) {
    setReferralDialogState(state: $state) @client
  }
`;

export const SET_DINER_PROFILE_DRAWER_STATE = gql`
  mutation setDinerProfileDrawer($state: boolean!) {
    setDinerProfileDrawerState(state: $state) @client
  }
`;

export const SET_ACTIVE_MODE = gql`
  mutation setActiveMode($mode: Boolean!) {
    setActiveMode(mode: $mode) @client
  }
`;

export const SET_GLOBAL_LOADING_STATE = gql`
  mutation setGlobalLoadingState($state: Boolean!) {
    setGlobalLoadingState(state: $state) @client
  }
`;
export const SET_CONTACT_MODAL = gql`
  mutation setContactModal($state: Boolean!) {
    setContactModal(state: $state) @client
  }
`;

export const SET_COVID_DIALOG_STATE = gql`
  mutation setCOVID19DialogState($state: Boolean!) {
    setCOVID19DialogState(state: $state) @client
  }
`;

export const SET_USER_UPDATE_EMAIL_DIALOG_STATE = gql`
  mutation setUserUpdateEmailDialogState($state: Boolean!) {
    setUserUpdateEmailDialogState(state: $state) @client
  }
`;
