import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { FC, useState } from "react";
import { IRestaurantReview, RestaurantReviewStatus } from "src/models";
import { restaurantReviewAPI } from "../../api";
import { useStyles } from "./edit-dialog.styles";

interface IProps {
  open: boolean;
  review: IRestaurantReview;
  handleClose: () => void;
}

export const EditRestaurantReviewDialog: FC<IProps> = ({
  open,
  review,
  handleClose,
}) => {
  if (!review) return null;
  const classes = useStyles();
  // hooks
  const [text, setText] = useState<string>(review.text);
  const [status, setStatus] = useState<RestaurantReviewStatus>(review.status);
  const [quality, setQuality] = useState<number>(review.quality);
  const [hospitality, setHospitality] = useState<number>(review.hospitality);
  const [accuracy, setAccuracy] = useState<number>(review.accuracy);

  const [_loading, setLoading] = useState<boolean>(false);

  // handlers
  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);

    restaurantReviewAPI
      .updateRestaurantReview({
        text,
        quality,
        hospitality,
        accuracy,
        status,
        id: review.id,
      })
      .then(() => handleClose());
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="application-dialog"
    >
      <DialogContent>
        <form onSubmit={submitHandler} className={classes.form}>
          <FormControl>
            <InputLabel shrink>Select Status</InputLabel>
            <Select
              value={status}
              className={classes.selectField}
              onChange={(d) =>
                setStatus(d.target.value as RestaurantReviewStatus)
              }
            >
              <MenuItem value={"PENDING"}>Pending</MenuItem>
              <MenuItem value={"SUBMITTED"}>Submitted</MenuItem>
              <MenuItem value={"REJECTED"}>Rejected</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            disabled={_loading}
            label="Text"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <TextField
            size="small"
            disabled={_loading}
            type="number"
            inputProps={{ min: "0", max: "5", step: ".1" }}
            label="Quality"
            value={quality}
            onChange={(e) => setQuality(Number(e.target.value))}
          />
          <TextField
            size="small"
            disabled={_loading}
            type="number"
            inputProps={{ min: "0", max: "5", step: ".1" }}
            label="Quality"
            value={hospitality}
            onChange={(e) => setHospitality(Number(e.target.value))}
          />
          <TextField
            size="small"
            disabled={_loading}
            type="number"
            inputProps={{ min: "0", max: "5", step: ".1" }}
            label="Accuracy"
            value={accuracy}
            onChange={(e) => setAccuracy(Number(e.target.value))}
          />

          <Button
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            style={{ margin: 10 }}
          >
            {_loading ? <CircularProgress /> : "Submit"}
          </Button>
        </form>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
};
