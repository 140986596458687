import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  buttonWrapper: {
    position: "absolute",
    bottom: 10,
    left: 10,
    zIndex: 1,
  },
  locationWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    color: COLORS.BLACK,
    fontStyle: "normal",

    margin: 0,
    textTransform: "capitalize",
  },
  tagsWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    color: COLORS.BLACK,
    marginBottom: 0,
  },
  icon: {
    marginRight: ".25rem",
    fontSize: "1.7rem",
  },
  seatsLeft: {
    margin: "0 0 0 20px",
    color: COLORS.RED,

    fontWeight: 300,
  },
  cardBottom: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 0,
    height: 160,
    padding: 21,
    paddingBottom: 13,
    paddingTop: 8,
    justifyContent: "space-between",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      height: 145,
    },
  },
  orderByContainer: {
    color: "#6C6C6C",
    marginBottom: 0,
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  eventTitle: {},
  restaurantNameContainer: {
    display: "flex",
  },
  restaurantName: {
    fontFamily: "CustomFourBold",
    textTransform: "uppercase",
    fontWeight: 500,
    color: COLORS.BLACK,

    margin: "0 0 0 .35em",
    alignSelf: "center",
    paddingBottom: ".02rem",
  },

  eventDetailsContainer: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
    display: "grid",
    gridTemplateRows: "repeat(3, auto)",
    gridAutoColumns: "70%",
    alignContent: "center",
  },
  container: {
    margin: "0 auto",
    position: "relative",
    width: "100%",
    minWidth: 280,
    display: "flex",
    flexDirection: "column",
    borderRadius: 2,
    padding: 0,
    boxShadow: "0 0 8px 2px rgba(29,29,29,0.25)",
    transition: `box-shadow 200ms ease-in, transform 200ms ease-in, border 200ms linear`,
    [`@media (min-width: ${BREAK_POINTS.mobileRegular}em)`]: {
      "&:hover": {
        boxShadow: `-2px 4px 18px 0 #C0C0C0`,
        transform: `translateY(-5px)`,
        border: "transparent",
      },
    },
  },
  typesContainer: {
    display: "flex",
    margin: "2px 0",
    justifyContent: "space-between",
    alignItems: "center",
  },

  deliveryTypeList: {
    color: COLORS.PURPLE,
  },
  tagsText: {
    textTransform: "capitalize",
    color: COLORS.BLACK,
    fontStyle: "normal",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    overflow: "hidden",
  },
  eventDetail: {
    margin: 0,
    flexGrow: 1,
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    overflow: "hidden",
    textTransform: "capitalize",
    color: COLORS.BLACK,
  },
  hostName: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: 100,
    },
  },
  cookContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    right: 15,
    top: 168,
    textAlign: "center",
  },
  avatar: {
    width: 70,
    height: 70,
    margin: "0 auto",
    boxSizing: "border-box",
    border: `2px solid ${COLORS.WHITE}`,
    zIndex: 2,
    backgroundColor: COLORS.RED,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    textTransform: "uppercase",
  },
  cookInfoContainer: {
    display: "flex",
    flexDirection: "column",
  },
  eventCardBanner: {
    position: "absolute",
    zIndex: 3,
    right: -13,
    top: 45,
  },
  name: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    textTransform: "capitalize",
    position: "relative",
    color: COLORS.BLACK,
    maxWidth: "8rem",

    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    overflow: "hidden",
    hyphens: "auto",
    WebkitHyphens: "auto",
  },
  starsContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  reviewContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },

  reviewText: {
    alignSelf: "center",
  },
  starsText: {
    fontFamily: "CustomFourBold",
  },
});
