import { useEffect } from "react";
import { sharedAPI } from "src/shared-graphql";

interface UseMobileHeaderProps {
  title: string;
  pathname?: string | null;
}

export default function useMobileHeader(pl: UseMobileHeaderProps) {
  useEffect(() => {
    sharedAPI.setMobileHeaderTitle({ title: pl.title, pathname: pl.pathname });
    return () => {
      sharedAPI.setMobileHeaderTitle({ title: "", pathname: null });
    };
  }, [pl.title, pl.pathname]);
}
