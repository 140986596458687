import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const heroStyles = makeStyles({
  heroContainer: {
    marginBottom: "5rem",
  },
  hero: {
    transition: "all 150ms cubic-bezier(0.25, -0.5, 0.75, 1.5)",
    background: `url("${require(`src/assets/images/menu_list_hero.jpg`)}")`,
    backgroundSize: "cover",
    backgroundPosition: "0 35%",
    marginBottom: "3rem",
    position: "relative",
    transform: `scale(1)`,
    zIndex: 10,
    backgroundRepeat: "no-repeat",
    minHeight: 364,
  },
  heroHeaderSideBubble: {
    width: "60%",
    maxWidth: 350,
    [`@media only screen and (min-device-width : ${BREAK_POINTS.mobileRegular}em)`]: {
      display: "none",
    },
  },
  sideBubble: {
    position: "absolute",
    left: -100,
    zIndex: -1,
    width: "60%",
    maxWidth: 285,
  },
  appBar: {
    backgroundColor: COLORS.WHITE,
    position: "relative",
  },
  foodnomeLogoWithText: {
    position: "absolute",
    top: "50%",
    left: "20%",
    transform: "translate(-50%,-50%)",
    width: "30%",
    maxWidth: 200,
    [`@media only screen and (min-device-width : ${BREAK_POINTS.mobileRegular}em)`]: {
      display: "none",
    },
  },
  foodnomeLogo: {
    position: "absolute",
    top: 12,
    left: "10%",
    width: 18,
    height: 18,
  },
  heroOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // background: `linear-gradient(180deg, rgba(0,0,0,0.34) 0%, rgba(0,0,0,0.89) 100%)`,
    background: "rgba(0,0,0,.59)",
  },
  heroTextContainer: {
    transition: "all 150ms ease-out",
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "100%",
    transform: "translate(-50%,-50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 1,
    padding: "0 1rem",
    flexDirection: "column",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      bottom: "20%",
      transform: "translate(-50%,0)",

      alignItems: "unset",
    },
  },
  heroText: {
    color: COLORS.WHITE,
    width: "100%",

    fontFamily: "CustomFourBold",
    fontWeight: 500,
    textShadow: "0 2px 5px rgba(0,0,0,0.5)",
    textAlign: "center",
    marginBottom: 0,

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      textAlign: "left",
      padding: 0,
      marginBottom: "1rem",
    },
  },
  subtitleText: {
    color: COLORS.WHITE,
    width: "100%",

    fontWeight: "normal",
    textShadow: "0 2px 5px rgba(0,0,0,0.5)",
    margin: 0,
    marginBottom: "1.5rem",
    marginTop: "1.5rem",
    textAlign: "center",

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      textAlign: "left",
      padding: 0,
      margin: "0 0 1rem",
    },
  },
  drawerSearchButton: {
    backgroundColor: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.WHITE,
    boxShadow: "none",

    padding: "1rem",

    "&:hover": {
      backgroundColor: COLORS.MEDIUM_GREEN,
      opacity: 0.8,
    },
  },
  searchButton: {
    backgroundColor: COLORS.MEDIUM_RED,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.WHITE,
    boxShadow: "none",

    borderRadius: "0 0.7rem 0.7rem 0",

    maxWidth: 170,
    minWidth: 103,
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_RED,
    },
  },
  input: {
    color: "#626262",
    borderRadius: "2px 0 0 2px",
    zIndex: 1,
    "&::placeholder": {},
  },

  rootInputLeft: {
    "&$focused $notchedOutline": {
      borderColor: COLORS.MEDIUM_GREEN,
      borderWidth: 1,
    },

    borderRadius: "0.7rem 0 0 0.7rem",
    backgroundColor: COLORS.WHITE,
  },
  rootInputRight: {
    maxWidth: 515,
    "&$focused $notchedOutline": {
      borderColor: COLORS.BLACK,
      borderWidth: 1,
    },
    "& $notchedOutline": {
      borderRadius: 2,
      backgroundColor: COLORS.WHITE,
      border: "none",
    },
  },
  shadow: {
    boxShadow: `0 4px 11px 0 rgba(0,0,0,0.19)`,
  },

  focused: {},
  inputAdornStart: {
    paddingLeft: 4,
  },
  icon: {
    zIndex: 1,
    fontSize: "2rem",
  },
  searchBarContainer: {
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: 750,
      display: "none",
    },
  },
  searchBarItem: {
    width: "100%",
    maxWidth: 750,
  },
  form: {
    width: "100%",
    maxWidth: "63rem",
    margin: "0 auto",
  },
  searchFormLayout: {
    display: "flex",
    width: "100%",
    margin: "0 auto",
  },
  rootInputBorderless: {
    "& $notchedOutline": {
      borderRadius: 0,
    },
  },
  inputBorderless: {},
  filterPaperBottom: {
    height: "95%",
  },
  drawerInput: {
    paddingLeft: "1rem",
  },
  closeDrawerButton: {
    minWidth: 0,
    padding: 0,
    color: COLORS.MEDIUM_GREEN,
  },
  drawerOverlay: {
    backgroundColor: "transparent",
  },
  tagsFormContainer: {
    backgroundColor: "#F9F9F9",
    minHeight: 130,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "3rem",
  },
  tagsFormInner: {
    maxWidth: 1128,
    width: "100%",
    margin: "0 auto",
    padding: "0 1rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: 752,
    },
  },
  tagsForm: {
    width: "100%",
    maxWidth: 640,
  },
  searchMenusTitle: {
    color: COLORS.BLACK,
    flex: 1,
    textAlign: "center",
    fontFamily: "CustomFourBold",

    margin: "8px 0",
  },
  enterAddressButtonRoot: {
    backgroundColor: COLORS.WHITE,

    color: "#717171",
    borderRadius: 18,
    textTransform: "unset",
    marginBottom: 8,
  },
  enterAddressButtonLabel: {
    justifyContent: "left",
  },
  mapBtn: {
    marginLeft: "2rem",
    padding: "0 4rem",
    border: `2px solid ${COLORS.MEDIUM_RED}`,
    backgroundColor: COLORS.WHITE,
    color: COLORS.BLACK,

    borderRadius: ".7rem",
  },
  mapIcon: {
    color: COLORS.MEDIUM_RED,
  },
});
