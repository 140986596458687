import IconButton from "@material-ui/core/IconButton";
import React, { FC } from "react";
import { objectToGetParams, openWindow } from "./functions";

interface IProps {
  children: any;
  url: string;
  separator?: string;
  body?: string;
}

type Options = {
  bodyPar?: string;
  separatorPar?: string;
};

export const SMSShareButton: FC<IProps> = ({
  children,
  url,
  separator = "",
  body = "",
}) => {
  const smsLink = (urlPar: string, { bodyPar, separatorPar }: Options) => {
    return (
      "sms:" +
      objectToGetParams(
        {
          body: bodyPar ? bodyPar + separatorPar + urlPar : urlPar,
        },
        "SMS"
      )
    );
  };

  const _link = smsLink(url, {
    bodyPar: body,
    separatorPar: separator,
  });

  const clickHandler = () => {
    // Test on real device for best performance
    openWindow(_link);
  };

  return (
    <IconButton aria-label="sms share button" onClick={clickHandler}>
      {children}
    </IconButton>
  );
};
