import { client } from "src/apollo";
import { IFacebookLoginPL } from "src/models";
import { GET_ME } from "src/shared-graphql/queries";
import { LocalStorageHelper } from "src/utils/helpers";
import { tagManager } from "src/utils/react-gtm";
import { APPLE_SIGN_IN, FACEBOOK_SIGN_IN, GOOGLE_SIGN_IN } from "./mutations";

// awaitRefetchQueries: false is needed because to display $10 prompt
// we need response from sign in right away to check on createdAT

// This creates a conflict for redirect to /checkout after sign in
// because the button is disabled if we dont refetch getMe
export const authAPI = {
  facebook: (pl: IFacebookLoginPL) => {
    return client
      .mutate({
        mutation: FACEBOOK_SIGN_IN,
        variables: {
          input: {
            ...pl,
            source: localStorage.getItem("foodnome-source"),
            referralCode: localStorage.getItem("foodnome-referree-code"),
          },
        },
        ...(process.env.REACT_APP_ENV !== "cordova" && {
          awaitRefetchQueries: false,
          refetchQueries: [{ query: GET_ME }],
        }),
      })
      .then(async (d) => {
        d?.data?.signInWithFacebook?.newUser &&
          tagManager.track({
            event: "UserSignUp",
          });
        if (process.env.REACT_APP_ENV === "cordova") {
          if (
            d.data &&
            d.data.signInWithFacebook &&
            d.data.signInWithFacebook.authToken
          ) {
            await LocalStorageHelper.setItem(
              "foodnome_auth_token",
              d.data.signInWithFacebook.authToken
            );
          }

          client.query({
            query: GET_ME,
            fetchPolicy: "network-only",
            errorPolicy: "ignore",
          });
        }
        return d;
      });
  },
  google: (token: string, referralCode?: string, ipAddress?: string) => {
    return client
      .mutate({
        mutation: GOOGLE_SIGN_IN,
        variables: {
          input: {
            token,
            referralCode: referralCode
              ? referralCode
              : localStorage.getItem("foodnome-referree-code"),
            ipAddress,
            source: localStorage.getItem("foodnome-source"),
          },
        },
        ...(process.env.REACT_APP_ENV !== "cordova" && {
          refetchQueries: [{ query: GET_ME }],
        }),
      })
      .then(async (d) => {
        d?.data?.signInWithGoogle?.newUser &&
          tagManager.track({
            event: "UserSignUp",
          });
        if (process.env.REACT_APP_ENV === "cordova") {
          if (
            d.data &&
            d.data.signInWithGoogle &&
            d.data.signInWithGoogle.authToken
          ) {
            await LocalStorageHelper.setItem(
              "foodnome_auth_token",
              d.data.signInWithGoogle.authToken
            );
          }
          client.query({
            query: GET_ME,
            fetchPolicy: "network-only",
            errorPolicy: "ignore",
          });
        }
        return d;
      });
  },
  apple: (
    token: string,
    firstName: string,
    lastName: string,
    source: any,
    referralCode?: string
  ) => {
    return client
      .mutate({
        mutation: APPLE_SIGN_IN,
        variables: {
          input: {
            token,
            firstName,
            lastName,
            source,
            referralCode,
          },
        },
        ...(process.env.REACT_APP_ENV !== "cordova" && {
          refetchQueries: [{ query: GET_ME }],
        }),
      })
      .then(async (d) => {
        d?.data?.signInWithApple?.newUser &&
          tagManager.track({
            event: "UserSignUp",
          });
        if (process.env.REACT_APP_ENV === "cordova") {
          if (
            d.data &&
            d.data.signInWithApple &&
            d.data.signInWithApple.authToken
          ) {
            await LocalStorageHelper.setItem(
              "foodnome_auth_token",
              d.data.signInWithApple.authToken
            );
          }
          client.query({
            query: GET_ME,
            fetchPolicy: "network-only",
            errorPolicy: "ignore",
          });
        }
        return d;
      });
  },
};
