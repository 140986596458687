import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    marginBottom: 75,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexGrow: 1,
    },
  },
  mainHeading: {
    margin: 0,
    padding: "0.5rem 0",
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  backButton: {
    color: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    padding: "1rem 1.25rem",
    textDecoration: "none",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  publishButton: {
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    backgroundColor: COLORS.MEDIUM_GREEN,
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_GREEN,
      opacity: 0.7,
    },
    "&:disabled": {
      backgroundColor: `${COLORS.MEDIUM_GREEN} !important`,
      opacity: 0.5,
    },
  },
  header: {
    backgroundColor: "#F1FFF5",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  headerInner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.5rem",
    width: "100%",
    maxWidth: 858,
    margin: "0 auto",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "100%",
    margin: "0 auto",
    padding: ".6rem",
    position: "fixed",
    left: 290,
    right: 0,
    bottom: 0,
    backgroundColor: COLORS.WHITE,
    borderTop: "1px solid #d3d3d3",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      left: 0,
    },
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexDirection: "row-reverse",
    },
  },
  cardsWrapper: {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fit, minmax(200px, 1fr))`,
    padding: "2rem 1rem",
    width: "90%",
    maxWidth: 858,
    margin: "0 auto",
    gridGap: "2rem",
  },
});
