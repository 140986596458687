export enum ActionTypes {
  SET_USER_ID = "SET_USER_ID",
  REGISTER_DEVICE = "REGISTER_DEVICE",
  SET_ONE_SIGNAL = "SET_ONE_SIGNAL",
}

export interface IState {
  userId: string;
  OneSignal:
    | {
        registerForPushNotifications: () => void;
        setSubscription: (d: boolean) => void;
        setExternalUserId: (d: string) => void;
      }
    | undefined;
}

interface ISetUserId {
  type: ActionTypes.SET_USER_ID;
  payload: string;
}

interface IRegisterDevice {
  type: ActionTypes.REGISTER_DEVICE;
  payload: string;
}

interface ISetOneSignal {
  type: ActionTypes.SET_ONE_SIGNAL;
  payload: any;
}

export type ComponentActions = IRegisterDevice | ISetOneSignal | ISetUserId;

const reducer: React.Reducer<IState, ComponentActions> = (state, action) => {
  if (process.env.REACT_APP_ENV !== "cordova") {
    return state;
  }
  switch (action.type) {
    case ActionTypes.SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case ActionTypes.SET_ONE_SIGNAL:
      return {
        ...state,
        OneSignal: action.payload,
      };

    case ActionTypes.REGISTER_DEVICE:
      if (state.OneSignal) {
        state.OneSignal.registerForPushNotifications();
        try {
          state.OneSignal.setSubscription(true);
          state.OneSignal.setExternalUserId(action.payload);
          return { ...state, userId: action.payload };
        } catch (e) {
          return state;
        }
      } else {
        return state;
      }

    default:
      return state;
  }
};
const initialState: IState = {
  userId: "123",
  OneSignal: undefined,
};

export { reducer, initialState };
