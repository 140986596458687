import {
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React, { FC, useEffect, useState } from "react";
import { useDelay } from "src/utils/hooks";
import { useStyles } from "./header.styles";

interface IProps {
  totalCount: number;
  onSearch: (d: { pageNumber: number; searchTerm?: string }) => void;
}
export const Header: FC<IProps> = ({ totalCount, onSearch }) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");

  const debouncedSearchTerm = useDelay(searchTerm, 300);

  useEffect(() => {
    if (debouncedSearchTerm) {
      onSearch({ pageNumber: 1, searchTerm: debouncedSearchTerm });
    }
  }, [debouncedSearchTerm]);

  return (
    <Paper className={classes.header}>
      <Typography variant="h3" component="h1" style={{ marginBottom: "2rem" }}>
        Members ({totalCount})
      </Typography>

      <TextField
        size="small"
        variant="outlined"
        label="Search by email, first or last name"
        style={{ maxWidth: "40rem", width: "100%" }}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </Paper>
  );
};
