import { Button, Paper, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getPasswordError, getRPasswordError } from "src/utils/user-helpers";
import { useStyles } from "./styles";

interface IProps {
  resetPass: (password: string) => void;
  error: string;
}

export const Layout: React.FC<IProps> = ({ resetPass, error }) => {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [passwordR, setPasswordR] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    return resetPass(password);
  };

  const isDisabled = () => {
    return (
      !password ||
      !passwordR ||
      !!getPasswordError(password) ||
      !!getRPasswordError(password, passwordR)
    );
  };

  return (
    <div className={classes.container}>
      <Paper elevation={3} className={classes.paper}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Typography variant="h1" component="h1" className={classes.title}>
            Password Reset
          </Typography>
          <div className={classes.fieldsContainer}>
            <div>
              <Typography
                component="label"
                variant="caption"
                className="hidden-label"
                htmlFor="password"
              >
                Password
              </Typography>
              <TextField
                size="small"
                FormHelperTextProps={{
                  classes: { root: classes.formHelperTextRoot },
                }}
                variant="outlined"
                id="password"
                type="password"
                placeholder="New Password"
                style={{ marginTop: "10px" }}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                error={Boolean(getPasswordError(password))}
                helperText={getPasswordError(password)}
                inputProps={{ maxLength: 200 }}
                InputProps={{
                  classes: {
                    input: classes.input,
                    root: classes.inputRoot,
                    notchedOutline: classes.notchedOutline,
                    focused: classes.inputFocused,
                  },
                  labelWidth: 0,
                }}
              />
            </div>
            <div>
              <Typography
                component="label"
                variant="caption"
                className="hidden-label"
                htmlFor="passwordR"
              >
                Retype Your Password
              </Typography>
              <TextField
                size="small"
                FormHelperTextProps={{
                  classes: { root: classes.formHelperTextRoot },
                }}
                variant="outlined"
                id="passwordR"
                type="password"
                placeholder="Verify Password"
                style={{ marginTop: "10px" }}
                onChange={(e) => setPasswordR(e.target.value)}
                fullWidth
                error={Boolean(getRPasswordError(password, passwordR))}
                helperText={getRPasswordError(password, passwordR)}
                inputProps={{ maxLength: 200 }}
                InputProps={{
                  classes: {
                    input: classes.input,
                    root: classes.inputRoot,
                    notchedOutline: classes.notchedOutline,
                    focused: classes.inputFocused,
                  },
                  labelWidth: 0,
                }}
              />
            </div>

            {!!error && (
              <Typography
                variant="body2"
                component="p"
                className={classes.errorText}
              >
                Sorry, the link has expired, click{" "}
                <Link to="/get-reset-token">here</Link> to get another link
              </Typography>
            )}

            <Button
              disabled={isDisabled()}
              type="submit"
              data-testid="reset-btn"
              id="reset-btn"
              variant="contained"
              fullWidth
              color="primary"
              classes={{ contained: classes.submitButton }}
            >
              Submit
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
};
