import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  commentLink: {
    backgroundColor: "unset",
    border: "none",
    fontFamily: "Avenir, Arial, Helvetica, sans-serif",
    fontSize: 14,
    color: "#414D54",
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: 800,
  },
});
