import { Button, Typography, useMediaQuery } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { HybridLink } from "src/components";
import { IGetMe } from "src/models";
import { FakeLinearProgress } from "src/screens/become-cook-info/components/fake-linear-progress/fake-linear-progress";
import { BREAK_POINTS, COLORS } from "src/styles";
import { LocalStorageHelper } from "src/utils/helpers";
import { Questions } from "../../../components/questions/questions";
import { useStyles } from "../in-county.styles";

interface IProps {
  getMe: IGetMe;
}

export const Riverside: React.FC<IProps> = ({ getMe }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

  const history = useHistory();

  useEffect(() => {
    LocalStorageHelper.setItem("foodnome_become_cook_intro_complete", "true");
  }, []);

  useEffect(() => {
    const head = document.querySelector("head") as any;
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );

    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, []);

  return (
    <div className={classes.container}>
      <section className={classes.contentContainer}>
        <img
          src={require("../../../assets/big-squig.png")}
          className={classes.squig}
        />
        <div className={classes.subContentContainer}>
          {isMobile && (
            <Button
              startIcon={<ChevronLeft className={classes.backButtonArrow} />}
              className={classes.backButton}
              onClick={() => history.goBack()}
              size="large"
            >
              Back
            </Button>
          )}
          <Typography variant="h2" component="h2" className={classes.title}>
            <img
              alt="cook connect logo"
              width="42px"
              height="42px"
              className={classes.logo}
              src={require(`src/assets/logos/cook.png`)}
            />
            Thanks, {getMe.firstName}!
          </Typography>
          <Typography variant="h2" component="h2" className={classes.subtitle}>
            Here's the next step:
          </Typography>
          <div className={classes.redBar} />

          <ol>
            <Typography component="li" variant="body1">
              Please read our ‘
              <HybridLink
                href="http://foodno.me/get-cookin"
                className={classNames(classes.bold, classes.link)}
              >
                How to Sell your Food
                </HybridLink>
              ’ Guide, outlining the process to{" "}
              <span className={classes.bold}>permit your home restaurant</span>{" "}
              on COOK Connect
            </Typography>
            <Typography component="li" variant="body1">
              Schedule a quick{" "}
              <span
                className={classes.bold}
                style={{ color: COLORS.MEDIUM_GREEN }}
              >
                Consultation Video Call
              </span>{" "}
              with us
            </Typography>
            <Typography component="li" variant="body1">
              If you meet all of our requirements, we will set you up to be
              selling food in as few as 2 weeks!
            </Typography>
          </ol>
          {/* This will show at the bottom for mobile */}
          {!isMobile && (
            <div className={classes.questionsContainer}>
              <Questions />
            </div>
          )}
        </div>
        <FakeLinearProgress />
      </section>

      <div
        className={classNames("calendly-inline-widget", classes.calendly)}
        data-url={`https://calendly.com/foodnome/consultation?name=${getMe.firstName}&email=${getMe.email}&hide_event_type_details=0`}
      />

      {/* Show only on mobile */}
      {isMobile && (
        <section className={classes.actionAndInfoSection}>
          <Button
            size="large"
            onClick={() => history.push("/c")}
            color="secondary"
            variant="contained"
          >
            GO TO PROFILE
          </Button>
          <div className={classes.questionsContainer}>
            <Questions />
          </div>
        </section>
      )}
    </div>
  );
};
