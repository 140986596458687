import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { BREAK_POINTS } from "src/styles/";
import { Carousel, Footer, Header, MobileValues , Legalisation, PressSection} from "./sections";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",
  },
  desktop: { display: "block" },
  mobile: { display: "none" },
  [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
    mobile: { display: "block" },
    desktop: { display: "none" },
  },
});

export const Layout: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.desktop}>
        <Header />
        <Carousel />
        <Legalisation />
        <PressSection />
        <Footer />
      </div>
      <div className={classes.mobile}>
        <MobileValues />
      </div>
    </div>
  );
};
