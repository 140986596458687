import { Avatar, Card, TextField, Typography } from "@material-ui/core";
import { AttachMoney } from "@material-ui/icons";
import moment from "moment";
import React, { FC, useEffect, useRef, useState } from "react";
import ReactStars from "react-stars";
import { IGetMe, IRestaurantReview, RestaurantReviewStatus } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { restaurantReviewAPIs } from "./api";
import { BTDropIn } from "./bt-dropin";
import { formStyles as useStyles } from "./reivew-form.styles";

interface IProps {
  review?: IRestaurantReview;
  user: IGetMe;
}

export const ReviewForm: FC<IProps> = ({ review, user }) => {
  const classes = useStyles();
  const [quality, setQuality] = useState(0);
  const [hospitality, setHospitality] = useState(0);
  const [accuracy, setAccuracy] = useState(0);
  const [text, setText] = useState("");
  const [tipAmount, setTipAmount] = useState<string>("0");
  const [customTip, setCustomTip] = useState(false);
  const customTipInputRef = useRef<any>();

  // Set to true and uncomment the useEffect to enable required word count for reviews
  const [requiredComment, setRequiredComment] = useState(false);

  useEffect(() => {
    if (customTip) {
      customTipInputRef.current.focus();
    }
  }, [customTip]);

  const characterReq = 20;

  const reset = () => {
    setQuality(5);
    setHospitality(5);
    setAccuracy(5);
    setText("");
    setTipAmount("");
    setCustomTip(false);
  };

  // useEffect(() => {
  //   const avg = (quality + accuracy + hospitality) / 3;

  //   // If the average review is equal to or less than 4, require user to say why
  //   avg <= 4 && text.length < characterReq
  //     ? setRequiredComment(true)
  //     : setRequiredComment(false);
  // }, [quality, accuracy, hospitality, text]);

  const submit = async (status: RestaurantReviewStatus, token?: string) => {
    if (review) {
      if (status === "REJECTED" || !requiredComment) {
        return restaurantReviewAPIs
          .createResponse({
            id: review.id,
            status,
            text,
            tipAmount:
              status !== RestaurantReviewStatus.REJECTED
                ? Number(tipAmount)
                : 0,
            token,
            quality: quality ? quality : 5,
            hospitality: hospitality ? hospitality : 5,
            accuracy: accuracy ? accuracy : 5,
          })
          .then(() =>
            sharedAPI.setSnackbarMsg({
              type: "success",
              msg: "Review submitted!",
            })
          )
          .catch((e) => {
            sharedAPI.setSnackbarMsg({
              type: "error",
              msg: e.message.replace(/^graphql error: /i, ""),
            });
          })
          .then(reset);
      } else {
        sharedAPI.setSnackbarMsg({
          type: "error",
          msg: "Please explain your current rating",
        });
      }
    }
  };

  const onClickTipOption = (amount: string) => () => {
    setCustomTip(false);
    setTipAmount(amount);
  };
  if (!review) return null;

  const avatarProps = Object.assign(
    {},
    review.eventInfo && review.eventInfo.hostPicture
      ? { src: review.eventInfo.hostPicture }
      : { children: review.eventInfo.hostName }
  );

  return (
    <div key={review.id} className={classes.container}>
      <Card className={classes.reviewSection}>
        <div style={{ minHeight: 50 }}>
          {review.eventInfo.hostPicture ? (
            <div style={{ position: "absolute", top: -43, right: 0, left: 0 }}>
              <Avatar
                className={classes.avatar}
                alt={review.eventInfo.hostName}
                {...avatarProps}
              />
              <Typography
                variant="h2"
                component="h2"
                className={classes.hostName}
              >
                {review.eventInfo.hostName.split(" ")[0]}
              </Typography>
            </div>
          ) : (
            <Typography variant="h2" component="h2" className={classes.title}>
              {review.eventInfo
                ? `How was ${review.eventInfo.name} on ${moment(
                    Number(review.eventInfo.time)
                  ).format("MMM Do")}?`
                : "How was your last meal?"}
            </Typography>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "2rem 0",
          }}
        >
          <Typography
            variant="body2"
            component="p"
            className={classes.textLabel}
          >
            How was the food quality?
          </Typography>
          <ReactStars
            count={5}
            half={false}
            color2={"#ffd700"}
            value={quality}
            onChange={setQuality}
            edit={true}
            size={25}
          />
          <Typography
            variant="body2"
            component="p"
            className={classes.textLabel}
          >
            How hospitable was the cook?
          </Typography>
          <ReactStars
            count={5}
            half={false}
            color2={"#ffd700"}
            value={hospitality}
            onChange={setHospitality}
            edit={true}
            size={25}
          />
          <Typography
            variant="body2"
            component="p"
            className={classes.textLabel}
          >
            How accurate was your order?
          </Typography>
          <ReactStars
            count={5}
            half={false}
            color2={"#ffd700"}
            value={accuracy}
            onChange={setAccuracy}
            edit={true}
            size={25}
          />
        </div>

        <section className={classes.question}>
          <Typography
            component="label"
            variant="caption"
            htmlFor="comments-input"
            className={classes.textLabel}
          >
            Comments:{" "}
          </Typography>
          <textarea
            id="comments-input"
            data-testid="comments-input"
            value={text}
            required={requiredComment}
            className={classes.textArea}
            onChange={(e) => setText(e.target.value)}
            rows={4}
            placeholder={"Leave your review here!"}
          />
          {requiredComment && (
            <Typography
              component="p"
              style={{ color: COLORS.RED }}
              variant="caption"
            >
              Min. {characterReq} Characters
            </Typography>
          )}
        </section>
      </Card>
      <section className={classes.actionContainer}>
        <div className={classes.btnGroup}>
          <button
            type="button"
            onClick={onClickTipOption("0")}
            style={{ borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}
            className={
              !customTip && tipAmount === "0"
                ? classes.selectedBtn
                : classes.nonSelectedBtn
            }
          >
            No tip
          </button>
          <button
            type="button"
            className={
              !customTip && tipAmount === "1"
                ? classes.selectedBtn
                : classes.nonSelectedBtn
            }
            onClick={onClickTipOption("1")}
          >
            $1
          </button>
          <button
            type="button"
            className={
              !customTip && tipAmount === "2"
                ? classes.selectedBtn
                : classes.nonSelectedBtn
            }
            onClick={onClickTipOption("2")}
          >
            $2
          </button>
          <button
            type="button"
            className={
              !customTip && tipAmount === "5"
                ? classes.selectedBtn
                : classes.nonSelectedBtn
            }
            onClick={onClickTipOption("5")}
          >
            $5
          </button>
          <button
            type="button"
            onClick={() => {
              setTipAmount("");
              setCustomTip(true);
            }}
            className={customTip ? classes.selectedBtn : classes.nonSelectedBtn}
            style={{ borderTopRightRadius: 4, borderBottomRightRadius: 4 }}
          >
            Other
          </button>
        </div>

        {customTip && (
          <div style={{ margin: ".5rem 0" }}>
            <Typography
              component="label"
              variant="caption"
              className="hidden-label"
              htmlFor="custom-tipping-amount"
            >
              Enter custom tipping amount
            </Typography>
            <TextField
              size="small"
              inputRef={customTipInputRef}
              inputProps={{
                id: "custom-tipping-amount",
              }}
              InputProps={{
                labelWidth: 0,
                startAdornment: <AttachMoney />,
                classes: {
                  input: classes.tipInput,
                },
              }}
              variant="outlined"
              required={customTip}
              value={tipAmount}
              placeholder="Enter custom tipping amount"
              type="number"
              style={{ width: "100%", margin: "4px 0px" }}
              onChange={(e) => setTipAmount(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        )}

        <BTDropIn tipAmount={tipAmount} user={user} submit={submit} />
      </section>
    </div>
  );
};
