import {
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { Search, Timeline, Toc } from "@material-ui/icons";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { ResponsiveDialog } from "src/components";
import { IEvent, IEventUser, IUser } from "src/models";
import { COLORS } from "src/styles";
import { DinerOrderDialog } from "./diner-order-dialog";
import { Header } from "./header";
import { getTimeSlotsWithGuests } from "./helpers";
import { useStyles } from "./layout.styles";
import { TableView } from "./table-view/table-view";
import { TimelineView } from "./timeline-view";

interface IProps {
  event: IEvent;
  cancelEvent: (eventId: string) => Promise<any>;
  publishEvent: (eventId: string) => Promise<any>;
}

export const Layout: FC<IProps> = ({ event, cancelEvent, publishEvent }) => {
  const classes = useStyles();
  const history = useHistory();
  const [activeAction, setCancelDialogState] = useState<string | null>(null);
  const [timelineView, setTimelineView] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [targetUser, setTargetUser] = useState<IUser | null>(null);
  const [currentOrder, setCurrentOrder] = useState<IEventUser | undefined>();

  const {
    timeSlotsWithGuests: _timeSlotsWithGuests,
    totalCompleted,
  } = getTimeSlotsWithGuests(event);

  const dialogAction = {
    PUBLISH: publishEvent,
    CANCEL: cancelEvent,
  };

  const _setTargetUser = (user: IUser, _currentOrder?: IEventUser) => {
    setTargetUser(user);
    setCurrentOrder(_currentOrder);
  };

  // ☝️ FIXED_TIME events are just a special case of a TAKE_OUT/DINE_IN event
  // since during a FIXED_TIME event, everyone's arrival time is the same -
  // the beginning of the event.
  const isFixedTime = event.type.includes("FIXED_TIME");

  const lookup = (guest: IUser, searchTerm: string) => {
    const { orderedDishes = [] } = guest.eventUser;
    // source: https://bit.ly/2Zzf6j7
    const cleanSearchTerm = searchTerm.replace(
      /[-[\]{}()*+?.,\\^$|#\s]/g,
      "\\$&"
    );
    return [
      guest.firstName,
      guest.lastName,
      guest.email,
      guest.phoneNumber,
      ...orderedDishes.map(({ name }) => name),
    ].some((values) => new RegExp(cleanSearchTerm, "ig").test(values));
  };

  const timeSlotsWithGuests = _timeSlotsWithGuests
    .map((timeSlotWithGuests) => {
      return {
        ...timeSlotWithGuests,
        guests: timeSlotWithGuests.guests.filter((guest) => {
          return lookup(guest, search);
        }),
      };
    })
    .filter((searchResult) => searchResult.guests.length);

  return (
    <>
      <div>
        <Card className={classes.container}>
          <div className={classes.subcontainer}>
            <Header
              event={event}
              openConfirmationDialog={setCancelDialogState}
            />

            <div>
              <div className={classes.toggleHeader}>
                {/* Only show guest list when it is a popup event.
                We don't want user to change views or see "completed" */}
                {event.type.includes("FIXED_TIME") ? (
                  <Typography
                    variant="h4"
                    component="h4"
                    className={classes.h4}
                  >
                    Guest List
                  </Typography>
                ) : (
                  <>
                    <Typography
                      component="label"
                      variant="caption"
                      className="hidden-label"
                      htmlFor="timeline-view-switch"
                    >
                      Toggle View
                    </Typography>
                    <div className={classes.toggleContainer}>
                      <Toc
                        style={{
                          transition: "all 300ms ease-in",
                          fontSize: "2rem",
                          color: timelineView
                            ? COLORS.DARK_GREY
                            : COLORS.MEDIUM_GREEN,
                        }}
                      />
                      <Switch
                        data-testid="toggle-timeline-table-view"
                        inputProps={{ id: "timeline-view-switch" }}
                        onChange={() => setTimelineView((prev) => !prev)}
                        value={timelineView}
                      />
                      <Timeline
                        style={{
                          transition: "all 300ms ease-in",
                          fontSize: "2rem",
                          color: timelineView
                            ? COLORS.MEDIUM_GREEN
                            : COLORS.DARK_GREY,
                        }}
                      />
                    </div>

                    <Typography
                      variant="h4"
                      component="h4"
                      className={classes.h4}
                      data-testid="completed-orders-count"
                    >
                      {`${totalCompleted}/${event.guests.reduce(
                        (acc, next) =>
                          acc + 1 + (next.eventUser?.orders?.length ?? 0),
                        0
                      )} completed`}{" "}
                    </Typography>
                  </>
                )}
                <Typography
                  component="label"
                  variant="caption"
                  className="hidden-label"
                  htmlFor="search-input"
                >
                  search
                </Typography>
                <TextField
                  size="small"
                  placeholder="search"
                  variant="outlined"
                  margin="dense"
                  value={search}
                  inputProps={{ id: "search-input" }}
                  InputProps={{
                    classes: { inputMarginDense: classes.searchInput },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search style={{ color: COLORS.DARK_GREY }} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>

              {timeSlotsWithGuests.length ? (
                <div>
                  {timelineView ? (
                    <TimelineView
                      timeSlotsWithGuests={timeSlotsWithGuests}
                      isSearchActive={search !== ""}
                      onClickUser={_setTargetUser}
                      getEvent={event}
                    />
                  ) : (
                    <TableView
                      isFixedTime={isFixedTime}
                      timeSlotsWithGuests={timeSlotsWithGuests}
                      onClickUser={_setTargetUser}
                      getEvent={event}
                    />
                  )}
                </div>
              ) : (
                <div className={classes.noGuest}>
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.noResults}
                  >
                    {search === ""
                      ? `No Guests.`
                      : `No search results found for "${
                          search.length > 30
                            ? search.slice(0, 27) + "..."
                            : search
                        }".`}
                  </Typography>
                </div>
              )}
              <div style={{ textAlign: "right", padding: "1em 1.75em" }}>
                {search !== "" && (
                  <Button
                    onClick={() => setSearch("")}
                    className={classes.clearSearchButton}
                    size="small"
                  >
                    clear search?
                  </Button>
                )}
              </div>
            </div>
            {/* cancel/publish event dialog */}
            <ResponsiveDialog
              onClose={() => setCancelDialogState(null)}
              aria-labelledby="cancel-publish-confirmation-dialog"
              data-testid="cancel-publish-dialog"
              open={Boolean(activeAction)}
            >
              <DialogTitle
                className={classes.dialogTitle}
                disableTypography
                id="cancel-publish-confirmation-dialog"
              >
                <Typography variant="h2" component="h2">
                  Are you sure you want to{" "}
                  {activeAction && activeAction.toLowerCase()} this event?*
                </Typography>
              </DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <Typography variant="body2" component="p">
                  *You <strong>cannot</strong> undo this action.
                </Typography>
                {!!errorMessage && (
                  <Typography
                    variant="body2"
                    component="p"
                    style={{ color: COLORS.RED }}
                  >
                    {errorMessage.replace(/graphql error:/i, "")}
                  </Typography>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  className={classes.yesButton}
                  onClick={() => {
                    if (activeAction) {
                      const action = dialogAction[activeAction];
                      action(event.id)
                        .then(() => {
                          history.push("/c/menus?status=CANCELLED");
                          setCancelDialogState(null);
                        })
                        .catch((e) => setErrorMessage(e.message));
                    }
                  }}
                >
                  Yes
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setCancelDialogState(null)}
                >
                  No
                </Button>
              </DialogActions>
            </ResponsiveDialog>

            {/* USER ORDER DIALOG */}
            <DinerOrderDialog
              targetUser={targetUser}
              currentOrder={currentOrder}
              onExited={() => setTargetUser(null)}
            />
          </div>
        </Card>
      </div>
    </>
  );
};
