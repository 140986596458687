import { useQuery } from "@apollo/client";
import { parse } from "query-string";
import React, { FC, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ErrorLoadingComponent } from "src/components";
import { EventDuplicatePL } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { GET_COOK_EVENTS } from "src/shared-graphql/queries";
import { useMobileHeader, useScrolledBottom } from "src/utils/hooks";
import { eventAPI } from "../api";
import { StatusTypes } from "./helpers";
import { Layout } from "./layout";

export const HostEventsListContainer: FC = () => {
  const limitRef = React.useRef<number>(12);
  const containerRef = React.useRef<any>();
  const [isFetchInflight, setFetchInflight] = useState<boolean>(false);

  useMobileHeader({
    title: "My Menus",
  });
  const history = useHistory();
  const location = useLocation();
  const search = parse(
    location && location.search ? location.search : `?status=ACTIVE`
  );
  const { data, loading, error, fetchMore } = useQuery(GET_COOK_EVENTS, {
    variables: {
      input: {
        status: (search as { status: string }).status,
        pagePL: {
          offset: null,
          limit: 12,
        },
      },
    },
    fetchPolicy: "cache-first",
  });

  const onScrolledBottom = React.useCallback(() => {
    if (data && data.cookGetEvents) {
      const { count } = data.cookGetEvents;
      if (limitRef.current < count) {
        setFetchInflight(true);
        const limit = limitRef.current + 9;
        fetchMore({
          variables: {
            input: {
              status: (search as { status: string }).status,
              pagePL: {
                offset: null,
                limit,
              },
            },
          },

          updateQuery: (prev: any, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return Object.assign({}, prev, {
              cookGetEvents: (fetchMoreResult as any).cookGetEvents,
            });
          },
        })
          .then(() => setFetchInflight(false))
          .catch(() => setFetchInflight(false));

        limitRef.current = limit;
      }
    }
  }, [fetchMore, data?.cookGetEvents]);

  // For infinite scroll
  useScrolledBottom({
    callback: onScrolledBottom,
    ref: containerRef,
    offset: 1000,
  });

  const duplicateEvent = (eventDuplicatePL: EventDuplicatePL) =>
    eventAPI
      .duplicateEvent(eventDuplicatePL)
      .then((event) => {
        return history.push(
          `/c/menus/summary/${
            event.data.duplicateEvent.id
          }-${event.data.duplicateEvent.name.replace(/ /g, "-")}`
        );
      })
      .then(() =>
        sharedAPI.setSnackbarMsg({ type: "success", msg: "Event Duplicated!" })
      );

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }

  return (
    <Layout
      containerRef={containerRef}
      duplicateEvent={duplicateEvent}
      deleteEvent={eventAPI.deleteEvent}
      activeStatus={(search.status as StatusTypes) || StatusTypes.ACTIVE}
      events={data.cookGetEvents.rows}
      isFetchInflight={isFetchInflight}
    />
  );
};
