import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import SwipeableViews from "react-swipeable-views";
import { EventCardDesktop } from "src/components/event-card-three";
import { IEvent } from "src/models";

interface IProps {
  onChangeIndex: (index: number) => void;
  index: number;
  width: number;
  groups: IEvent[][];
}

const useStyles = makeStyles({
  wrapper: {
    width: "100%",
    zIndex: 6,
  },
  buttonLeft: {
    zIndex: 99,
    boxShadow: "none",
    position: "absolute",
    left: 0,
    top: 160,
  },
  buttonRight: {
    zIndex: 99,
    boxShadow: "none",
    position: "absolute",
    right: 0,
    top: 160,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    minWidth: "100%",
  },
  group: {
    padding: "1rem .25rem",
    display: "grid",
    gridColumnGap: "1em",
    gridTemplateColumns: `repeat(auto-fit, minmax(1px, 1fr))`,
  },
});

function getPadding(_width: number) {
  if (_width < 340) return "0px 2px";
  else if (_width < 769) return "0 2.15em";
  else if (_width < 1025) return "0 3.25em";
  else return "0 8em";
}

export const EventsCarousel: FC<IProps> = ({
  onChangeIndex,
  index,
  groups,
  width,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <SwipeableViews
        onChangeIndex={onChangeIndex}
        enableMouseEvents
        ignoreNativeScroll
        style={{
          overflowX: width < 400 ? "visible" : "hidden",
          padding: getPadding(width),
          width: width < 340 ? 300 : "100%",
        }}
        index={index}
        slideStyle={{ paddingRight: 10 }}
      >
        {groups.map((group, idx) => (
          <div className={classes.group} key={idx}>
            {group.map((event) => (
              <div key={event.id} style={{ padding: 1 }}>
                <EventCardDesktop event={event} />
              </div>
            ))}
          </div>
        ))}
      </SwipeableViews>
    </div>
  );
};
