import {
  Avatar,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  ChevronLeft,
  Lock,
  Person,
  Room,
  SpeakerNotes,
  Today,
  Warning,
} from "@material-ui/icons";
import HelpIcon from "@material-ui/icons/Help";
import moment from "moment";
import React, { FC, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { GoogleMapContainer, LocationDialog } from "src/components";
import { IEvent, IGetMe } from "src/models";
import { BREAK_POINTS, COLORS } from "src/styles";
import { eventTypeMap } from "src/utils/helpers";
import { OrderContext } from "../../order-context";
import { ActionTypes } from "../../order-reducer";
import { EventInfoDeliveryNoteDialog } from "./event-info-delivery-note-dialog";
import { EventInfoSection } from "./event-info-section";
import { useStyles } from "./event-info.styles";

interface IProps {
  getEvent: IEvent;
  getMe: IGetMe;
}

export const EventInfo: FC<IProps> = ({ getEvent, getMe }) => {
  const classes = useStyles();
  const history = useHistory();
  const [showLocationDialog, setShowLocationDialog] = useState(false);

  const closeLocationDialog = () => {
    setShowLocationDialog(false);
  };

  const displayHeader = useMediaQuery(
    `(max-width: ${BREAK_POINTS.mobileRegular}em)`
  );
  const {
    state: { arrivalTime, dineOption, dialogView },
    dispatch,
  } = useContext(OrderContext);

  return (
    <div className={classes.container}>
      {/* =========== Header =========== */}

      <section className={classes.headerBackground}>
        <div className={classes.headerContainer}>
          {!displayHeader && (
            <>
              <IconButton
                onClick={() =>
                  dialogView
                    ? dispatch({
                        type: ActionTypes.SET_EVENT_DIALOG_VIEW,
                        payload: "checkout",
                      })
                    : history.goBack()
                }
              >
                <ChevronLeft className={classes.backArrow} />
              </IconButton>
              <Typography
                variant="h2"
                component="h1"
                className={classes.greeting}
              >
                Hey {getMe.firstName}, review your order details
              </Typography>
            </>
          )}
        </div>
      </section>

      <section className={classes.mobileLabelContainer}>Order details</section>
      {/* =========== Private =========== */}
      {getEvent.private && (
        <EventInfoSection
          title="Private"
          icon={<Lock style={{ color: COLORS.RED }} className={classes.icon} />}
          titleStyles={{ color: COLORS.RED }}
        >
          <Typography
            variant="body2"
            component="p"
            className={classes.description}
          >
            This is a private event, which means that the cook must approve you
            for security purposes. Please make sure to complete your profile to
            speed up this process. Once you have been confirmed, you will
            receive an email with the exact address and a receipt. If the cook
            doesn’t accept you in time or doesn’t accept your order, you will be
            refunded immediately.
          </Typography>
        </EventInfoSection>
      )}

      {/* =========== Time =========== */}
      <EventInfoSection
        title={
          (getEvent.name.includes("Bulk order for")
            ? "Custom Order"
            : eventTypeMap[dineOption]) +
          " for " +
          `${
            dineOption === "DELIVERY"
              ? `${moment(Number(arrivalTime)).format(
                  "MMM Do h:mm a"
                )} - ${moment(Number(arrivalTime))
                  .add(1, "h")
                  .format("h:mm a")}`
              : `${moment(Number(arrivalTime)).calendar()}`
          }`
        }
        icon={<Today className={classes.icon} />}
      >
        <Typography
          variant="body2"
          component="p"
          className={classes.description}
        >
          {getEvent.type.includes("FIXED_TIME") ? (
            <>
              You will receive a notification{" "}
              <span style={{ textDecoration: "underline" }}>1 hr</span> prior to
              the event, please show up to the event on-time.
            </>
          ) : null}
        </Typography>
      </EventInfoSection>

      {/* =========== TAKE-OUT/DELIVERY =========== */}

      {/* <EventInfoSection
        title={eventTypeMap[dineOption]}
        icon={<Restaurant className={classes.icon} />}
      >
        {dineOption === "DELIVERY" && (
          <div className={classes.deliveryDescription}>{getMe.address}</div>
        )}
      </EventInfoSection> */}

      {/* =========== DELIVERY NOTES =========== */}
      {dineOption === "DELIVERY" && (
        <EventInfoSection
          title={"Delivery Instruction"}
          icon={<SpeakerNotes className={classes.icon} />}
        >
          <EventInfoDeliveryNoteDialog />
        </EventInfoSection>
      )}

      {/* =========== Location =========== */}
      {dineOption !== "DELIVERY" && (
        <EventInfoSection
          title={getEvent.locale.public}
          icon={<Room className={classes.icon} />}
        >
          <div className={classes.locationTextContainer}>
            <Typography
              variant="body2"
              component="p"
              className={classes.description}
            >
              Exact location will be provided upon order completion
            </Typography>
            <IconButton
              disableRipple
              disableFocusRipple
              size="small"
              onClick={() => setShowLocationDialog(true)}
            >
              <HelpIcon />
            </IconButton>
          </div>
          <div
            onClick={() => setShowLocationDialog(true)}
            className={classes.locationContainer}
          >
            <GoogleMapContainer
              circleOverlay
              center={
                getEvent.locale.coordinates
                  ? {
                      lat: getEvent.locale.coordinates.latitude,
                      lng: getEvent.locale.coordinates.longitude,
                    }
                  : undefined
              }
            />
          </div>
        </EventInfoSection>
      )}

      {/* =========== Instruction =========== */}
      {getEvent.instructions && (
        <EventInfoSection
          icon={<Warning className={classes.icon} />}
          title={"Instructions"}
        >
          <Typography
            variant="body2"
            component="p"
            className={classes.description}
          >
            {getEvent.instructions}
          </Typography>
        </EventInfoSection>
      )}

      {/* =========== Host =========== */}
      <EventInfoSection title="" icon={<Person className={classes.icon} />}>
        <div className={classes.hostContainer}>
          <Avatar
            alt={`${getEvent.restaurant.host.firstName.charAt(
              0
            )} ${getEvent.restaurant.host.lastName.charAt(0)}`}
            src={getEvent.restaurant.host.picture}
            className={classes.avatar}
          />
          <div className={classes.hostInfoContainer}>
            <Typography
              variant="h4"
              component="h2"
              style={{ fontFamily: "CustomFourBold", marginBottom: "1rem" }}
            >
              Who's cooking?
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.hostName}
            >
              {getEvent.restaurant.host.firstName}{" "}
              {getEvent.restaurant.host.lastName.slice(0, 1)}.
            </Typography>
            <Link
              className={classes.viewCookLink}
              to={`/${getEvent.restaurant?.name?.replace(/ /g, "-")}`}
            >
              <Typography variant="body2" component="p">
                View Cook Profile
              </Typography>
            </Link>
          </div>
        </div>
      </EventInfoSection>
      <LocationDialog
        showLocationDialog={showLocationDialog}
        closeLocationDialog={closeLocationDialog}
        restaurant={getEvent.restaurant}
      />
    </div>
  );
};
