import React from "react";
import { IGetMe } from "src/models";
import { HomeContainer } from "./container";

interface IProps {
  getMe: IGetMe;
}
export const HomeLayout: React.FC<IProps> = ({ getMe }) => {
  return <HomeContainer getMe={getMe} />;
};
