import moment from "moment";
import { browserName, browserVersion } from "react-device-detect";
import { IApplication, IEvent, IRestaurant } from "src/models";

const carrotDefault = require("src/assets/logos/carrot.png");

declare let window: any;

export const isEmbeddedBrowser = () => {
  const ua =
    (window as any).navigator.userAgent ||
    (window as any).navigator.vendor ||
    (window as any).opera;
  return (
    ua.indexOf("FBAN") > -1 ||
    ua.indexOf("FBAV") > -1 ||
    ua.indexOf("Instagram") > -1
  );
};

export const openAddress = (link: string) => {
  // We want to open addresses and external links in separate browsers
  // for cordova. window.open works for IOS but not for Android so we use window.cordova.InAppBrowser
  // so it opens in separate browser regardless.
  if (window.cordova) {
    window.cordova.InAppBrowser.open(link, "_system", "location=yes");
  } else {
    window.open(link);
  }
};

export const getApplicationFinishedPerc = (app: IApplication) => {
  if (!app.info) return 0;
  let totalPoints = 0;

  app.info.address && totalPoints++;
  app.info.businessName && totalPoints++;
  app.info.hasDishwasher !== null && totalPoints++;
  app.info.idImage && totalPoints++;
  app.info.waterCompany && totalPoints++;
  app.info.dishInfos.forEach((d) => d.name && totalPoints++);

  return Number((totalPoints / 7).toFixed(2));
};
enum RoleEnum {
  USER = 0,
  INSPECTOR = 1,
  ADMIN = 2,
  SUPER_ADMIN = 3,
}
export const compareRole = (
  role1: "USER" | "ADMIN" | "SUPER_ADMIN" | "INSPECTOR",
  role2: "USER" | "ADMIN" | "SUPER_ADMIN" | "INSPECTOR"
) => {
  return RoleEnum[role1] >= RoleEnum[role2];
};

export function formatDate(date) {
  // Jun 3, 2019 5:40 PM
  return moment(Number(date)).format("lll");
}

export function formatDateShort(date) {
  // June 3, 2019
  return moment(Number(date)).format("LL");
}

export const formatAddressForRestaurant = (restaurant: IRestaurant) => {
  /* Used for restaurant public profile.
Currently going to only show City and State on public profile */
  if (!restaurant.address.split(",")[1]) {
    return "Unknown";
  }
  return truncateText(restaurant.address.split(",")[1], 35);
};

export const getAddress = (event: IEvent) => {
  if (!event.locale) return null;
  return truncateText(event.locale.public as string, 35);
};

export const getDishImage = (event: IEvent) => {
  let number = event.dishes
    ? event.dishes.findIndex((d) => d.category === "ENTREE")
    : 0;
  if (number === -1) number = 0;

  return event.dishes && event.dishes[number]
    ? event.dishes[number].imagesGQL[0].medium
    : carrotDefault;
};

export const getEnv = () => {
  if (process.env.REACT_APP_ENV === "cordova") {
    return "cordova";
  } else {
    return process.env.NODE_ENV;
  }
};

export const getEventImage = (event: IEvent) =>
  event && event.imagesGQL && event.imagesGQL.length
    ? event.imagesGQL[0].small
    : getDishImage(event);

export const getBrowser = () => {
  if (!browserName) return "Unknown";

  return `${browserName}@${browserVersion}`;
};

export function isBrowserSupported() {
  if (!browserName || !browserVersion) return true;

  const majorVersion = parseInt(browserVersion, 10);

  switch (browserName.toLowerCase()) {
    case "chrome":
      if (majorVersion && majorVersion >= 20) {
        return true;
      }
      return false;
    case "safari":
      if (majorVersion && majorVersion >= 6) {
        return true;
      }
      return false;
    case "firefox":
      if (majorVersion && majorVersion >= 27) {
        return true;
      }
      return false;
    case "edge":
      if (majorVersion && majorVersion >= 11) {
        return true;
      }
      return false;
    case "ie":
      return false;

    default:
      return true;
  }
}

export function getMonthAndDay(date) {
  return formatDate(date).split(",")[0].split(" ");
}

export function formatEventDate(event) {
  return moment(Number(event.startTime))
    .format("llll")
    .split(",")
    .slice(0, 2)
    .join(",");
}

export function getTotalReservations(event: IEvent) {
  return event.guests
    .map((guest) => guest.EventUser.reservedFor)
    .reduce((acc, next) => {
      return acc + next;
    }, 0);
}

export function getProfit(event: IEvent) {
  return (event.maxSeats - event.seatsLeft) * event.price;
}

export function validateReservation(event, user, setError, reservationCount) {
  let error;

  if (event.status !== "ACTIVE") error = "Event is not active";
  else if (moment().isAfter(moment(Number(event.reserveBy))))
    error = `Cannot reserve event after ${moment(
      Number(event.reserveBy)
    ).format("MMM Do YYYY, h:mm a")}`;
  else if (user.nomes < event.price * reservationCount)
    error = "You need more nomes to make this reservation.";
  else if (!event.seatsLeft) error = "Sorry, this event is fully booked";
  else return true;

  setError(error);
  return error;
}

export const truncateText = (text: string, cutOff: number) => {
  return text.length > cutOff ? `${text.slice(0, cutOff).trim()}...` : text;
};

export function getTimeForEventDetail(time) {
  return moment(Number(time)).format("MMM Do YYYY, h:mm a").split(" ").slice(3);
}

export const removeDuplicateStrings = (array: string[]) =>
  array.reduce((acc, next: string) => {
    if (acc.includes(next)) return acc;
    else return [...acc, next];
  }, [] as string[]);

export const getWhiteList = () => [
  /^\/verify-success$/,
  // /^\/login$/,
  /^\/how-it-works$/,
  // /^\/legislation$/,
  // /^\/get-reset-token$/,
  /^\/home-restaurant-info/,
  /^\/find-homemade-food/,
];

export const noMarginTop = (pathname: string) => {
  const whiteList = getWhiteList();
  const OK = whiteList
    .map((regex) => pathname.match(regex) !== null)
    .some(Boolean);
  return OK;
};

export const hideMainHeaderWhen = [
  ({ pathname, isMobile }) => isMobile && /^\/d\/?.*/.test(pathname),
  ({ pathname, isMobile }) => isMobile && /^\/events\/.+/.test(pathname),
  ({ pathname, isMobile }) => isMobile && /^\/c\/menus\/.*$/.test(pathname),
  ({ pathname, isMobile }) => isMobile && /^\/c\/dishes\/\w+/.test(pathname),
  ({ pathname }) => /^\/find-homemade-food/.test(pathname),
  ({ pathname, isMobile }) =>
    isMobile && /^\/home-restaurant-application/.test(pathname),
];

export const hideMainHeader = ({
  pathname,
  isMobile = false,
}: {
  pathname: string;
  isMobile?: boolean;
}) => {
  return (
    hideMainHeaderWhen.some((test) => {
      return test({ pathname, isMobile });
    }) || isMobile
  );
};

export const eventTypeMap = {
  FIXED_TIME: "Pop Up",
  TAKE_OUT: "Pickup",
  DROP_IN: "Dine In",
  DELIVERY: "Delivery",
};

export const getMask = () => [
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  ",",
  " ",
  /\d/,
  /\d/,
  ":",
  /\d/,
  /\d/,
  " ",
  /P|A/i,
  "M",
];

export const copyToClipboard = (text: string) => {
  const textField = document.createElement("textarea");
  textField.setAttribute("readonly", "true");
  textField.setAttribute("contenteditable", "true");
  textField.setAttribute("id", "text-field");
  textField.style.position = "fixed";
  textField.style.zIndex = "99999";
  // for ios mobile
  textField.style.fontSize = "16px";
  textField.innerText = text;
  // for ios mobile
  document.body.prepend(textField);
  textField.focus();
  textField.select();
  const range = document.createRange();
  range.selectNodeContents(textField);
  textField.setSelectionRange(0, textField.value.length);
  document.execCommand("copy");
  textField.remove();
};

export const dateToFromNowDaily = (myDate: number) => {
  // ensure the date is displayed with today and yesterday
  const _m = moment(myDate);
  return _m.calendar(undefined, {
    // when the date is closer, specify custom values
    lastWeek: "[Last] dddd",
    lastDay: "[Yesterday]",
    sameDay: `[Today]`,
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    sameElse() {
      return "[" + _m.format("lll") + "]";
    },
  });
};

export const formatDateTime = (
  {
    time,
  }: {
    time: string;
  },
  {
    removeMs = true,
    overrideFormats = {},
  }: {
    removeMs?: boolean;
    overrideFormats?: moment.CalendarSpec;
  } = {}
) => {
  const displayTime = `${moment(Number(time)).format("h:mma")}`;

  let displayDateTime = "";

  displayDateTime = moment(Number(time)).calendar(moment(), {
    sameDay: `[Today],[${displayTime}]`,
    nextDay: `[Tomorrow],[${displayTime}]`,
    nextWeek: `dddd,[${displayTime}]`,
    lastDay: `[Today],[${displayTime}]`,
    lastWeek: `[Today],[${displayTime}]`,
    sameElse(now: any) {
      if (now.isBefore(moment(Number(time)))) {
        const daysDiff = moment
          .duration(moment(Number(time)).diff(now))
          .asDays();
        if (daysDiff > 1 && daysDiff <= 3) {
          return `dddd,[${displayTime}]`;
        } else {
          return `MMM D,[${displayTime}]`;
        }
      } else return `[Today],[${displayTime}]`;
    },
    ...overrideFormats,
  });

  if (removeMs) {
    displayDateTime = displayDateTime.replace(/am/gi, "a").replace(/pm/gi, "p");
  }

  return displayDateTime;
};

export const formatToDate = (time: string) => {
  let displayDateTime = "";
  displayDateTime = moment(Number(time)).calendar(moment(), {
    sameDay: `[Today]`,
    nextDay: `[Tomorrow]`,
    nextWeek(now) {
      const daysDiff = moment.duration(moment(Number(time)).diff(now)).asDays();

      if (daysDiff > 1 && daysDiff <= 3) {
        return `dddd`;
      } else {
        return `MMM D`;
      }
    },
    lastDay: `[Today]`,
    lastWeek: `[Today]`,
    sameElse(now: any) {
      if (now.isBefore(moment(Number(time)))) {
        const daysDiff = moment
          .duration(moment(Number(time)).diff(now))
          .asDays();
        if (daysDiff > 1 && daysDiff <= 3) {
          return `dddd`;
        } else {
          return `MMM D`;
        }
      } else return `[Today]`;
    },
  });

  return displayDateTime;
};

export const formatStartToEndDate = (
  {
    startTime,
    endTime,
  }: {
    startTime: string;
    endTime: string;
  },
  {
    removeMs = true,
    spacedDashes = false,
    overrideFormats = {},
  }: {
    removeMs?: boolean;
    spacedDashes?: boolean;
    overrideFormats?: moment.CalendarSpec;
  } = {}
) => {
  const displayTime = `${moment(Number(startTime)).format("h:mma")}${
    spacedDashes ? " - " : "-"
  }${moment(Number(endTime)).format("h:mma")}`;

  let displayDateTime = "";

  displayDateTime = moment(Number(startTime)).calendar(moment(), {
    sameDay: `[Today],[${displayTime}]`,
    nextDay: `[Tomorrow],[${displayTime}]`,
    nextWeek(now) {
      const daysDiff = moment
        .duration(moment(Number(startTime)).diff(now))
        .asDays();

      if (daysDiff > 1 && daysDiff <= 3) {
        return `dddd,[${displayTime}]`;
      } else {
        return `MMM D,[${displayTime}]`;
      }
    },
    lastDay: `[Today],[${displayTime}]`,
    lastWeek: `[Today],[${displayTime}]`,
    sameElse(now: any) {
      if (now.isBefore(moment(Number(startTime)))) {
        const daysDiff = moment
          .duration(moment(Number(startTime)).diff(now))
          .asDays();
        if (daysDiff > 1 && daysDiff <= 3) {
          return `dddd,[${displayTime}]`;
        } else {
          return `MMM D,[${displayTime}]`;
        }
      } else return `[Today],[${displayTime}]`;
    },
    ...overrideFormats,
  });

  if (removeMs) {
    displayDateTime = displayDateTime.replace(/am/gi, "a").replace(/pm/gi, "p");
  }

  return displayDateTime;
};

export const escapedRegExp = (regexpAsString: string) =>
  new RegExp(regexpAsString.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"));

export const getCloudinaryCropDimensions = (cloudinaryURL: string) => {
  const payload = {} as any;
  const match = cloudinaryURL.match(/x_(\d+),y_(\d+),w_(\d+),h_(\d+)/);
  if (match) {
    const [_, x, y, w, h] = match;
    payload.x = x;
    payload.y = y;
    payload.width = w;
    payload.height = h;
  }
  return payload;
};

export const checkForSafari = () => {
  if (
    process.env.REACT_ADD_ENV === "cordova" &&
    (window as any).cordova?.platformId === "ios"
  )
    return true;
  return browserName ? /safari/gi.test(browserName.toLowerCase()) : false;
};

export class JsonHelper {
  static Parse(key: any, failCallback?: () => any) {
    try {
      return JSON.parse(key);
    } catch {
      if (typeof failCallback === "function") failCallback();
    }
    return null;
  }
}

export class LocalStorageHelper {
  static setItem(key: string, value: any) {
    try {
      localStorage.setItem(key, value);
    } catch {
      // Clearing all cart data in localstorage to hopefully make
      // space for new localstorage items. May remove if
      // causes issues.

      this.clearCartDataFromLocalStorage();
    }
  }
  static clearCartDataFromLocalStorage() {
    // Remove cart entries on logout
    Object.entries(localStorage).forEach(([key, val]) => {
      if (key.includes("foodnome-cart-")) localStorage.removeItem(key);
    });
  }
}
