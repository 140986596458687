import { makeStyles } from "@material-ui/core/styles";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useSelectDropInStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: 844,
    margin: "10px auto",
    boxShadow: "0px 0px 10px 1px rgba(21,21,21,0.20);",
  },
  deliveryText: {
    fontFamily: "CustomFourBold",

    margin: ".5rem auto",
    textAlign: "center",
    color: COLORS.MEDIUM_GREEN,
    cursor: "pointer",
    paddingBottom: 6,
  },
  minDeliveryText: {
    fontFamily: "CustomFourBold",

    margin: "auto",
    textAlign: "center",
    color: COLORS.RED,
    paddingBottom: 6,
  },
  deliveryTextsPlaceHolder: {
    fontFamily: "CustomFourBold",

    margin: "0px 0 10px 0",
    color: COLORS.MEDIUM_GREEN,
  },
  editDeliveryTextIcon: { marginLeft: 4 },
  radioContainer: {
    position: "relative",
    width: "100%",
    display: "flex",
    height: "unset",
    flexDirection: "row",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      height: "4rem",
    },
  },
  radioLabel: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    verticalAlign: "middle",
  },
  singleBtn: {
    fontSize: theme.typography.h4.fontSize,
    cursor: "not-allowed",
    width: "100%",
    height: "100%",
    borderRadius: 0,

    backgroundColor: "#EBFFFA",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    outline: "none",
    border: "none",
    padding: "1rem 0",
    borderBottom: "1px solid #E3E3E3",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: 0,
    },
  },
  selectedBtn: {
    cursor: "pointer",
    color: COLORS.BLACK,
    borderRadius: 0,

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: 0,
    },
  },
  nonSelectedBtn: {
    cursor: "pointer",
    color: COLORS.BLACK,
    backgroundColor: COLORS.WHITE,
    borderRadius: 0,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: 0,
    },
  },
  dropSelectContainer: {
    width: "100%",
    height: "unset",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      height: "4rem",
    },
  },
  btnLabelMobile: {
    justifyContent: "center",
    textTransform: "none",
  },
  btnLabel: {
    justifyContent: "center",
    textTransform: "none",
  },
  btnContainedLeft: {
    boxShadow: "unset",
    borderWidth: "0 1px 1px 0",
    borderStyle: "solid",
    borderColor: "#E3E3E3",
    "&:hover": {
      backgroundColor: "#EBFFFA",
    },
  },
  btnContainedLeftActive: {
    boxShadow: "unset",
    borderWidth: "0 1px 1px 0",
    borderStyle: "solid",
    borderColor: "#E3E3E3",
    backgroundColor: "#EBFFFA",
    "&:hover": {
      backgroundColor: "#EBFFFA",
    },
  },
  btnContainedRight: {
    boxShadow: "unset",
    borderWidth: "0 0 1px 0",
    borderStyle: "solid",
    borderColor: "#E3E3E3",

    "&:hover": {
      backgroundColor: "#EBFFFA",
    },
  },
  btnContainedRightActive: {
    boxShadow: "unset",
    borderWidth: "0 0 1px 0",
    borderStyle: "solid",
    borderColor: "#E3E3E3",
    backgroundColor: "#EBFFFA",
    "&:hover": {
      backgroundColor: "#EBFFFA",
    },
  },
}));
