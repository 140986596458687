import { useQuery } from "@apollo/client";
import { Button, Fab, makeStyles, useMediaQuery } from "@material-ui/core";
import { ChevronLeft, Reply } from "@material-ui/icons";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Element } from "react-scroll";
import { ShareDialog } from "src/components";
import { IRestaurant } from "src/models";
import { GET_ME } from "src/shared-graphql/queries";
import { BREAK_POINTS, COLORS } from "src/styles";
import { useVisibility } from "src/utils/hooks";
import { DishImagesHero } from "./hero";
import { LayoutLeft } from "./layout-left";
import { UpcomingEventsWidgetDesktop } from "./widgets";

interface LayoutProps {
  restaurant: IRestaurant;
}

export const Layout: React.FC<LayoutProps> = React.memo(({ restaurant }) => {
  const classes = useStyles();
  const { data, loading, error } = useQuery(GET_ME, { errorPolicy: "ignore" });
  const [isFabVisible, currentElement] = useVisibility<any>(25);
  const history = useHistory();
  const location = useLocation();
  const [shareDialog, setShareDialog] = useState(false);

  const shareUrl = `https://www.foodnome.com${location.pathname}`;

  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

  return (
    <>
      <Element name="overview" />
      <div className={classes.gridLayout}>
        <div className={classes.gridLayoutHero}>
          {isMobile && (
            <Fab
              size="small"
              aria-label="back"
              className={classes.fabBackButton}
              ref={currentElement}
              onClick={() => history.goBack()}
            >
              <ChevronLeft
                style={{ color: COLORS.MEDIUM_GREEN, fontSize: "2.5rem" }}
              />
            </Fab>
          )}
          {!isMobile && (
            <Button
              className={classes.shareButton}
              variant="contained"
              startIcon={<Reply className={classes.replyIcon} />}
              onClick={() => setShareDialog(true)}
            >
              Share
            </Button>
          )}

          <DishImagesHero dishes={restaurant.dishes} />
        </div>
        <div className={classes.gridLayoutHeaderWidgets}>
          <div className={classes.widgetLeftLayout}>
            <LayoutLeft
              isFabVisible={isFabVisible}
              restaurant={restaurant}
              getMe={data?.getMe}
            />
          </div>
          {/* Upcoming Events Widget - Right */}
          {!isMobile && (
            <div style={{ position: "sticky", top: 100 }}>
              <UpcomingEventsWidgetDesktop restaurantId={restaurant.id} />
            </div>
          )}
        </div>
      </div>
      <ShareDialog
        open={shareDialog}
        shareURL={shareUrl}
        onClose={() => setShareDialog(false)}
      />
    </>
  );
});

const useStyles = makeStyles({
  shareButton: {
    position: "absolute",
    top: "3rem",
    right: "3rem",
    backgroundColor: COLORS.WHITE,
  },
  // Grid Layout
  gridLayout: {
    display: "grid",
    gridTemplateRows: "auto 60px 1fr",
    gridTemplateColumns: "1fr",
  },
  gridLayoutHero: {
    gridRow: "1 / 3",
    gridColumn: 1,
  },
  gridLayoutHeaderWidgets: {
    gridRow: "2 / -1",
    gridColumn: 1,
    backgroundColor: "transparent",
    maxWidth: "120rem",
    margin: "0 auto",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      gridRow: "3 / -1",
    },
  },
  widgetLeftLayout: {
    borderRadius: "5px 5px 0 0",
    padding: ".5rem",
    maxWidth: 600,
    background: COLORS.WHITE,
    flexGrow: 1,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: "unset",
      width: "100%",
      paddingBottom: "calc(8rem + env(safe-area-inset-bottom))",
    },
  },
  fabBackButton: {
    position: "absolute",
  },
  replyIcon: {
    color: COLORS.MEDIUM_GREEN,
    transform: "scale(-1,1)",
  },
});
