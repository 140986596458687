import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: { paddingRight: "10px" },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  sectionContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },

  eventsSection: {
    width: "48%",
  },
  reviewsSection: {
    width: "48%",
  },
  paymentsSection: {
    width: "48%",
  },
});
