import React, { useState } from "react";
import { IImage } from "src/models";
import { DishImageCarousel } from "./dish-image-carousel";

export interface DishImageUploaderContainerProps {
  images: IImage[];
  onDeleteImage: (publicId: string) => void;
  onUploaded: (image: IImage) => void;
  onUploading: () => void;
}

export const DishImageUploaderContainer: React.FC<DishImageUploaderContainerProps> = ({
  images,
  onDeleteImage,
  onUploaded,
  onUploading,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleSetIndex = (newIndex: number) => setActiveIndex(newIndex);
  const handleDeleteImage = (index: number) => {
    const { publicId } = images[index];
    onDeleteImage(publicId);
    setActiveIndex((prev) => (activeIndex === 0 ? 0 : prev - 1));
  };
  const onClickLeft = (event) => {
    event.stopPropagation();
    setActiveIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
  };
  const onClickRight = (event) => {
    event.stopPropagation();
    setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  const handleUploaded = (i: IImage) => {
    setActiveIndex(images.length === 0 ? 0 : images.length);
    onUploaded(i);
  };

  return (
    <DishImageCarousel
      activeIndex={activeIndex}
      handleSetIndex={handleSetIndex}
      handleDeleteImage={handleDeleteImage}
      onClickLeft={onClickLeft}
      onClickRight={onClickRight}
      images={images}
      onUploaded={handleUploaded}
      onUploading={onUploading}
    />
  );
};
