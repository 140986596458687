import { IPressLogo, VerifiedPress } from "src/models";

export const constants = {
  LOGO_URL:
    "https://foodnome.sfo2.cdn.digitaloceanspaces.com/logos/carrot_logo_red.png",
  BEETS: "https://foodnome.sfo2.cdn.digitaloceanspaces.com/beats.jpg",
};

export const _socialMedia = {
  instagram: "http://www.instagram.com/thecookalliance",
  twitter: "https://twitter.com/AllianceCook",
  facebook: "https://www.facebook.com/cookalliance/",
  youtube: "https://www.youtube.com/channel/UCwZGNsc3qHslq_rrowJbYrg/featured",
  linkedin: "https://www.linkedin.com/company/cook-alliance",
};

export const _companyContact = {
  info: "support@cookalliance.org",
};

export const _press: IPressLogo[] = [
  {
    image: "NPRLogo-color.png",
    alt: VerifiedPress.NPR,
    url: "https://n.pr/2TWd0e8",
  },
  {
    image: "marketplace-color.png",
    alt: VerifiedPress.MARKET_PLACE,
    url:
      "https://www.marketplace.org/2019/08/27/california-law-puts-home-cooks-in-the-gig-economy/",
  },
  {
    image: "capradio-color.png",
    alt: VerifiedPress.CAPRADIO,
    url:
      "https://www.capradio.org/articles/2019/11/12/from-tikka-masala-to-mexican-bbq-home-kitchens-set-to-expand-across-state/",
  },
  {
    image: "yahoo-color.png",
    alt: VerifiedPress.YAHOO,
    url:
      "https://finance.yahoo.com/news/first-home-restaurant-united-states-130500356.html",
  },
  {
    image: "pe-color.png",
    alt: VerifiedPress.PE,
    url:
      "https://www.pe.com/2019/07/24/how-to-get-a-home-cooked-meal-you-didnt-cook/",
  },
  {
    image: "pbs-color.png",
    alt: VerifiedPress.PBS,
    url: "https://vimeo.com/377415563",
  },
];

export const _appstore = {
  apple:
    "https://apps.apple.com/us/app/foodnome-home-cooked-meals/id1487009046",
  android: "https://play.google.com/store/apps/details?id=com.app.foodnome",
};

export const FOODNOME_PRODUCTION_BASE_URL = "https://foodnome.com";

export const featuredCooks = [
  {
    name: "Cali Tardka",
    location: "Riverside, CA",
    picture:
      "https://res.cloudinary.com/foodnome/image/upload/w_666,h_410,c_fill,g_auto/v1583517024/sumy8dzyimi70giavpcj.jpg",
    videoLink: "https://foodnome.sfo2.digitaloceanspaces.com/videos/cali.mp4",
    bio:
      "Kulwant Sanghu, cook/owner of Cali Tardka, is a mother of two kids and loves to cook. She serves traditional Indian dishes using authentic flavors from her Punjab roots.",
  },
  {
    name: "Marcus Haynes",
    location: "Moreno Valley, CA",
    picture:
      "https://res.cloudinary.com/foodnome/image/upload/w_666,h_410,c_fill,g_auto/v1583516986/mkgkluqjiyvwwljkzcj5.jpg",
    videoLink: "https://foodnome.sfo2.digitaloceanspaces.com/videos/marcus.mp4",
    bio:
      "Marcus was born in Compton, CA but raised in the Inland Empire most of his life. He grew up eating foods from different cultures and now cooks on Foodnome sharing his culinary influences with his community.",
  },
  {
    name: "Arturo Ramos",
    location: "Perris, CA",
    picture:
      "https://res.cloudinary.com/foodnome/image/upload/w_666,h_410,c_fill,g_auto/v1583516936/v8ows7xikgzebnivm3bg.jpg",
    videoLink: "https://foodnome.sfo2.digitaloceanspaces.com/videos/arturo.mp4",
    bio:
      "Art is a passionate line cook with 5 years of culinary experience. The hunger for growing and learning lead to earning his Sous cook title. He currently cooks on Foodnome to earn extra cash and serve healthy fresh food to his community.",
  },
];
