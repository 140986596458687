import { Card } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import { useStyles } from "./loader.styles";

export const Loader = () => {
  const classes = useStyles();
  return (
    <Card className={classes.container}>
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton variant="circle" className={classes.circle} />
      <Skeleton variant="rect" className={classes.rectangle} animation="wave" />
      <Skeleton variant="rect" className={classes.rectangle} animation="wave" />
    </Card>
  );
};
