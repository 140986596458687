import { useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Close, Edit } from "@material-ui/icons";
import React, { FC, useContext, useState } from "react";
import { DropSelect } from "src/components";
import { IEvent, IGetMe } from "src/models";
import { amplitude } from "src/services";
import { sharedAPI } from "src/shared-graphql";
import { GET_ME } from "src/shared-graphql/queries";
import { BREAK_POINTS, COLORS } from "src/styles";
import { eventTypeMap } from "src/utils/helpers";
import { DINER_GET_DELIVERY_COST } from "../../gather-address-dialog/graphql/queries";
import { getMenuItems } from "../../helpers";
import { OrderContext } from "../../order-context";
import { ActionTypes } from "../../order-reducer";
import { useStyles } from "./order-settings-dialog.styles";

interface IProps {
  getEvent: IEvent;
  getMe: IGetMe;
  handleOrderSettingsDialogClose: () => void;
  handleOrderSettingsDialogOpen: () => void;
  orderSettingsDialogOpen: boolean;
}

export const OrderSettingsDialog: FC<IProps> = ({
  getEvent,
  getMe,
  handleOrderSettingsDialogClose,
  handleOrderSettingsDialogOpen,
  orderSettingsDialogOpen,
}) => {
  const classes = useStyles();
  const {
    dispatch,
    state: { arrivalTime, dineOption, orderedDishes, seats, total },
  } = useContext(OrderContext);
  const [selectedOrderType, setSelectedOrderType] = useState(dineOption);
  const [selectedArrivalTime, setSelectedArrivalTime] = useState(
    arrivalTime.toString()
  );
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  const items = getMenuItems(getEvent, selectedOrderType);
  const [getDeliveryCost, { loading }] = useMutation(DINER_GET_DELIVERY_COST, {
    refetchQueries: [{ query: GET_ME }],
  });

  const [address, setAddress] = useState(getMe.address);

  const totalCost = getEvent.type.includes("FIXED_TIME")
    ? getEvent.price * seats
    : orderedDishes.reduce((acc, next) => {
        let _total = 0;
        if (next.options) {
          _total = next.options?.reduce(
            (_acc, _next) =>
              _acc +
              (_next?.count ?? 0) *
                (_next?.addition + (next.DishEvent?.price ?? 0)),
            0
          );
        } else {
          _total = (next.DishEvent?.price ?? 0) * next.count;
        }
        return acc + _total;
      }, 0);

  const onSelect = (d: string) => {
    setSelectedOrderType(d);
  };

  const handleSubmit = async () => {
    if (selectedOrderType === "DELIVERY") {
      await getDeliveryCost({
        variables: {
          input: { address, eventId: getEvent.id, total: totalCost },
        },
      })
        .then((_d) => {
          dispatch({
            type: ActionTypes.SET_DELIVERY_COST,
            payload: _d.data.dinerGetDeliveryCost.cost,
          });
        })
        .catch((e) =>
          sharedAPI.setSnackbarMsg({
            type: "error",
            msg: e.message.replace(/Graphql Error: /i, ""),
          })
        );
    }

    // Order type dispatch
    dispatch({
      type: ActionTypes.SET_DINE_OPTION,
      payload: selectedOrderType,
    });
    // Arrial time dispatch
    amplitude
      .getInstance()
      .logEvent("[Event Detail Checkout] changed arrival time on checkout");
    dispatch({
      type: ActionTypes.SET_ARRIVAL_TIME,
      payload: selectedArrivalTime,
    });

    handleOrderSettingsDialogClose();
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogContainerPaper }}
      open={orderSettingsDialogOpen}
      onClose={handleOrderSettingsDialogClose}
      fullWidth={true}
      maxWidth="lg"
    >
      <IconButton
        data-testid="close-button-order-settings-dialog"
        aria-label="Close"
        onClick={handleOrderSettingsDialogClose}
        classes={{ root: classes.iconBtn }}
      >
        <Close className={classes.closeBtn} />
      </IconButton>
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h4" component="p">
          Order Settings
        </Typography>
      </DialogTitle>
      <Divider />

      <div>
        <div className={classes.deliveryTypeContainer}>
          {/* ========= SINGLE BUTTON IF ONLY 1 TYPE OF DELIVERY METHOD ========= */}

          {getEvent.type.length === 1 && (
            <Typography
              variant="body2"
              component="p"
              className={classes.deliveryTypeSingle}
            >
              {eventTypeMap[getEvent.type[0]]}
            </Typography>
          )}

          {/* ========= PICK UP / DELIVERY. ONLY SHOW IF TOTAL COST IS GREATER THAN MIN ========= */}
          {getEvent.type.length !== 1 && (
            <section
              data-testid="select-drop-container"
              className={classes.buttonsContainer}
            >
              {getEvent.deliveryInfo &&
              totalCost < getEvent.deliveryInfo.minimumOrderAmount &&
              getEvent.type[1] === "DELIVERY" ? (
                <div className={classes.deliveryTypeSingle}>
                  {eventTypeMap[getEvent.type[0]]}
                </div>
              ) : (
                <>
                  <Button
                    fullWidth
                    disableElevation
                    classes={{
                      root:
                        selectedOrderType === getEvent.type[0]
                          ? classes.selectedBtn
                          : classes.nonSelectedBtn,
                      label: isMobile ? classes.btnLabel : classes.btnLabel,
                      contained:
                        selectedOrderType === getEvent.type[0]
                          ? classes.btnContainedLeftActive
                          : classes.btnContainedLeft,
                    }}
                    variant="contained"
                    onClick={() => onSelect(getEvent.type[0])}
                  >
                    {eventTypeMap[getEvent.type[0]]}
                  </Button>
                  <Button
                    fullWidth
                    disableElevation
                    classes={{
                      root:
                        selectedOrderType === getEvent.type[1]
                          ? classes.selectedBtn
                          : classes.nonSelectedBtn,
                      label: isMobile
                        ? classes.btnLabelMobile
                        : classes.btnLabel,
                      contained:
                        selectedOrderType === getEvent.type[1]
                          ? classes.btnContainedRightActive
                          : classes.btnContainedRight,
                    }}
                    variant="contained"
                    onClick={() => onSelect(getEvent.type[1])}
                  >
                    {eventTypeMap[getEvent.type[1]]}
                  </Button>{" "}
                </>
              )}
            </section>
          )}
        </div>
        <Divider />
        {/* ========= DROP SELECT ========= */}
        {/* We dont want users to be able to choose/edit a time when it is fixed time event */}
        {getEvent.type[0] !== "FIXED_TIME" && (
          <section className={classes.dropSelectContainer}>
            <Typography
              variant="body2"
              component="p"
              className={classes.dropSelectTextContainer}
            >
              When do you want your food?
            </Typography>
            <DropSelect
              inputLabel="PICK A TIME"
              menuItems={items}
              defaultValue={selectedArrivalTime}
              onSelect={(value) => {
                setSelectedArrivalTime(value);
              }}
              reset={false}
            />
          </section>
        )}

        {selectedOrderType === "DELIVERY" && getMe.address ? (
          <>
            <Divider />
            <div
              className={classes.deliveryTextContainer}
              onClick={() =>
                dispatch({
                  type: ActionTypes.SET_ADDRESS_DIALOG_STATUS,
                  payload: { url: "" },
                })
              }
            >
              <Typography
                variant="body2"
                component="p"
                className={classes.deliveryAddressText}
              >
                Delivery address:
                <span className={classes.yourAddressText}>
                  {getMe.address}
                  <Edit className={classes.editDeliveryTextIcon} />
                </span>
              </Typography>
            </div>
          </>
        ) : null}
      </div>
      <Divider />

      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleSubmit}
          style={{ margin: ".5rem", color: COLORS.WHITE }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
