import { gql } from "@apollo/client";

export const ADMIN_UPDATE_APPLICATION = gql`
  mutation adminUpdateApplication($input: UpdateApplicationInfo!) {
    adminUpdateApplication(updateApplicationInfo: $input) {
      id
      description
      status
      assignee
      inspectionTime
      updatedAt
      createdAt
    }
  }
`;

export const CREATE_APPLICATION_STATUS = gql`
  mutation adminCreateApplicationStatus(
    $input: AdminCreateApplicationStatusPL
  ) {
    adminCreateApplicationStatus(adminCreateApplicationStatusPL: $input) {
      status
      description
      updatedAt
      createdAt
    }
  }
`;

export const DELETE_APPLICATION_STATUS = gql`
  mutation deleteAppStatus($input: ID!) {
    deleteAppStatus(id: $input)
  }
`;

export const ADMIN_SEND_REMINDER = gql`
  mutation adminSendReminder($input: ID!) {
    adminSendReminder(id: $input)
  }
`;

export const SEND_APPLICATION = gql`
  mutation sendApplication($input: SendApplicationPL!) {
    sendApplication(sendApplicationPL: $input)
  }
`;

export const SEND_REGISTRATION = gql`
  mutation sendRegistration($input: SendRegistrationPL!) {
    sendRegistration(sendRegistrationPL: $input)
  }
`;
