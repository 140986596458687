import { client } from "src/apollo";
import {
  CreateEventPL,
  DeleteDishEventPL,
  EventDuplicatePL,
  UpdateActiveEventPL,
  UpdateEventPL,
} from "src/models";
import {
  CANCEL_EVENT,
  COOK_CREATE_EVENT,
  COOK_UPDATE_EVENT,
  DELETE_DISH_EVENT,
  DELETE_EVENT,
  DUPLICATE_EVENT,
  PUBLISH_EVENT,
} from "./graphql";
import { COOK_UPDATE_ACTIVE_EVENT } from "./graphql/mutations";

export const eventAPI = {
  // TODO: When pagination for cookGetEvents is added, we need to refetchQueries
  // with the param - PENDING
  createEvent: (createEventPL: CreateEventPL) =>
    client.mutate({
      mutation: COOK_CREATE_EVENT,
      variables: { input: createEventPL },
      refetchQueries: ["getCookEventsActive", "statusCounts"],
    }),
  updateEvent: (updateEventPL: UpdateEventPL) =>
    client.mutate({
      mutation: COOK_UPDATE_EVENT,
      variables: { input: updateEventPL },
      refetchQueries: ["statusCounts", "cookGetEvents"],
    }),
  updateActiveEvent: (updateActiveEventPL: UpdateActiveEventPL) =>
    client.mutate({
      mutation: COOK_UPDATE_ACTIVE_EVENT,
      variables: { input: updateActiveEventPL },
    }),
  cancelEvent: (id: string) =>
    client.mutate({
      mutation: CANCEL_EVENT,
      variables: { input: id },
      refetchQueries: ["statusCounts"],
    }),

  deleteDishEvent: (deleteDishEventPL: DeleteDishEventPL) =>
    client.mutate({
      mutation: DELETE_DISH_EVENT,
      variables: { input: deleteDishEventPL },
      refetchQueries: ["cookGetEvents"],
    }),
  deleteEvent: (id: string) =>
    client.mutate({
      mutation: DELETE_EVENT,
      variables: { input: id },
      refetchQueries: ["cookGetEvents", "statusCounts"],
    }),
  publishEvent: (id: string) =>
    client.mutate({
      mutation: PUBLISH_EVENT,
      variables: { input: { eventId: id } },
      refetchQueries: ["cookGetEvents", "statusCounts"],
    }),
  duplicateEvent: (eventDuplicatePL: EventDuplicatePL) =>
    client.mutate({
      mutation: DUPLICATE_EVENT,
      variables: { input: eventDuplicatePL },
      refetchQueries: ["cookGetEvents", "statusCounts"],
      awaitRefetchQueries: true,
    }),
};
