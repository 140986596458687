import MomentUtils from "@date-io/moment";
import { CloudinaryContext } from "cloudinary-react";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import React from "react";
import { BrowserRouter, HashRouter, Route } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import { StreamChatPrivider } from "src/components/chat2";
import { CompatibilityDialog, OneSignal, SnackBarGlobal } from "./components";
import { Routes } from "./screens/routes";
import { withTracker } from "./with-tracker";

smoothscroll.polyfill();

const Router =
  process.env.REACT_APP_ENV === "cordova"
    ? (HashRouter as any)
    : (BrowserRouter as any);
export const App = () => {
  if (
    !process.env.REACT_APP_STRIPE_API ||
    !process.env.REACT_APP_STRIPE_API_LIVE
  )
    return alert("Looks like you do not have the correct env file") as any;
  return (
    <CloudinaryContext cloudName={process.env.REACT_APP_CLOUD_NAME}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <CompatibilityDialog>
          <SnackBarGlobal />
          <Router>
            <StreamChatPrivider>
              <OneSignal.Provider>
                <Route component={withTracker(Routes as any)} />
              </OneSignal.Provider>
            </StreamChatPrivider>
          </Router>
        </CompatibilityDialog>
      </MuiPickersUtilsProvider>
    </CloudinaryContext>
  );
};
