import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { sharedAPI } from "src/shared-graphql";
import { SET_USER_UPDATE_EMAIL_DIALOG_STATE } from "src/shared-graphql/mutations";
import { GET_USER_UPDATE_EMAIL_STATE } from "src/shared-graphql/queries";
import { getEmailError } from "src/utils/user-helpers";
import { USER_UPDATE_EMAIL } from "./api/mutations";
import { useStyles } from "./user-update-email-dialog.styles";

export const UserUpdateEmailDialog = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const { data } = useQuery(GET_USER_UPDATE_EMAIL_STATE);
  const [setUpdateEmailState] = useMutation(SET_USER_UPDATE_EMAIL_DIALOG_STATE);
  const [updateEmail] = useMutation(USER_UPDATE_EMAIL);

  const _updateEmail = () => {
    if (getEmailError(email) || !email) {
      sharedAPI.setSnackbarMsg({
        type: "error",
        msg: "Make sure to enter your full email",
      });
    } else {
      updateEmail({
        variables: { input: email.trim().toLowerCase() },
      })
        .then(() => {
          sharedAPI.setSnackbarMsg({
            type: "success",
            msg: "Your email is saved",
          });
          setUpdateEmailState({ variables: { state: false } });
        })
        .catch(() =>
          sharedAPI.setSnackbarMsg({
            type: "error",
            msg: "Sorry, we could not update your email",
          })
        );
    }
  };
  return (
    <Dialog
      open={!!data?.getUserUpdateEmailDialogState}
      onClose={() => setUpdateEmailState({ variables: { state: false } })}
    >
      <DialogTitle className={classes.title}>
        Looks like we do not a valid email on file for you!
        <Typography variant="body2" component="p" className={classes.subtitle}>
          Please enter your email to receive receipts, view past orders, chat
          with your cooks and more:
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          size="small"
          error={!!getEmailError(email)}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Enter your email here"
          fullWidth
          helperText={getEmailError(email)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="secondary" className={classes.notNowBtn}>
          Not now
        </Button>
        <Button
          variant="contained"
          onClick={_updateEmail}
          color="secondary"
          className={classes.saveBtn}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
