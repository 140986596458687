import React, { useState } from "react";
import { passwordResetAPI } from "../api";
import { Layout } from "./layout";

export const ResetPasswordContainer = ({ match, history }) => {
  const [error, setError] = useState("");

  const token = match.params.token;

  const _resetPass = (password: string) =>
    passwordResetAPI
      .resetPassword(token, password)
      .then(() => history.push("/d"))
      .catch((e) => setError(e.message.replace(/^Graphql Error: /i, "")));

  return <Layout resetPass={_resetPass} error={error} />;
};
