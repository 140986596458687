import { gql } from "@apollo/client";

export const ADMIN_DELETE_RESTAURANT_REVIEW = gql`
  mutation adminDeleteRestaurantReview($input: ID!) {
    adminDeleteRestaurantReview(id: $input)
  }
`;

export const ADMIN_CREATE_RESTAURANT_REVIEW = gql`
  mutation adminCreateRestaurantReview($input: AdminCreateRestaurantReviewPL!) {
    adminCreateRestaurantReview(adminCreateRestaurantReviewPL: $input) {
      id
      text
      quality
      hospitality
      accuracy
      eventInfo {
        name
      }
    }
  }
`;

export const ADMIN_UPDATE_RESTAURANT_REVIEW = gql`
  mutation adminUpdateRestaurantReview($input: AdminUpdateRestaurantReviewPL!) {
    adminUpdateRestaurantReview(adminUpdateRestaurantReviewPL: $input) {
      id
      text
      quality
      hospitality
      accuracy
      status
      eventInfo {
        name
        hostName
      }
    }
  }
`;

export const ADMIN_SEND_REVIEW = gql`
  mutation adminSendRestaurantReview($input: ID!) {
    adminSendRestaurantReview(id: $input) {
      id
      text
      quality
      hospitality
      updatedAt
      accuracy
      status
      tipAmount
      eventInfo {
        name
        hostName
      }
      restaurant {
        id
        name
      }
      guest {
        id
        email
        phoneNumber
        firstName
        lastName
      }
    }
  }
`;
