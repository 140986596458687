import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { IRestaurantGuest } from "src/models";
import { ListItem } from "./list-item";
import { listStyles as useStyles } from "./list.styles";

interface IProps {
  guests: IRestaurantGuest[];
  restaurant: any;
}

export const List: FC<IProps> = ({ guests, restaurant }) => {
  const classes = useStyles();

  if (!guests?.length) {
    return (
      <section
        className={classes.noGuestContainer}
        style={{ height: "10px", overflow: "scroll" }}
      >
        <Typography variant="body2" component="p">
          You do not have any followers yet.
        </Typography>
      </section>
    );
  }
  return (
    <div className={classes.container}>
      {guests.map((g: IRestaurantGuest) => (
        <ListItem restaurantGuest={g} key={g.id} restaurant={restaurant} />
      ))}
    </div>
  );
};
