import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { IEvent, IRestaurant } from "src/models";
import { metricsStyles as useStyles } from "./metrics.styles";

interface IProps {
  restaurant: IRestaurant;
}

export const Metrics: FC<IProps> = ({ restaurant }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="body2" component="p">
        # of menu completed: {restaurant.events.length}
      </Typography>

      <Typography variant="body2" component="p">
        Total earning to date: $
        {restaurant.host.payments.reduce(
          (prev, next) => prev + next.totalAmount * -1,
          0
        )}
      </Typography>
      <Typography variant="body2" component="p">
        Tax collected to date: $
        {restaurant?.events
          ?.reduce((lEvent, nEvent) => getEventTaxCollected(nEvent) + lEvent, 0)
          ?.toFixed(2)}
      </Typography>
    </div>
  );
};

const getEventTaxCollected = (event: IEvent) =>
  event?.eventUsers?.reduce((lEu, nEu) => lEu + nEu.taxAmount, 0);
