// eslint-disable-file
export const companyValues = [
  {
    title: `Creating Economic Opportunity`,
    statement: `We believe that every community has a right to self-determination, both in terms of economic opportunity and access to healthy food. COOK Connect aims to empower local home cooks to make a living cooking what they love,and connect these passionate cooks with their hungry neighbors.`,
    image: require("../assets/farm-to-table.jpg"),
  },
  {
    title: `Improving access to healthy food`,
    statement: `In many neighborhoods, fast food is the only option for affordable local meals, and community nutrition suffers. In supporting home cooked food sales, we hope to expand access to healthy food at reasonable prices in every neighborhood.`,
    image: require("../assets/chef.jpg"),
  },
  {
    title: `Turning Strangers Into Neighbors Through Food`,
    statement: `Sitting down in someone’s kitchen and sharing a meal is one of the simplest, most human ways to connect. Our movement facilitates face-to-face interactions between neighbors, encouraging cultural exchange, personal connection, and deepening of community bonds.`,
    image: require("../assets/hai_and_joe.jpeg"),
  },
];
