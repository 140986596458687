import { ShareLinkConfig } from "src/models";

export const config: ShareLinkConfig = {
  drawerTitle: "Share your menu with your followers!",
  description:
    "I hope you are hungry! Come check out what I am cooking on Foodnome!",
  tags: ["homerestaurant", "homecooking", "AB626"],
  twitter: {
    description:
      "Homemade meals by me, order now on @foodnome 💛. RT so your friends can get a plate!",
  },
  facebook: {
    description:
      "Hungry for a homemade meal? Check out Foodnome for my home cooking menu, where you can order online now!",
  },
  whatsApp: {
    description:
      "Hey there! If you’re looking for dinner plans, I’d love for you to come by for some home cooking 💛" +
      " I run a permitted home restaurant on foodnome.com where you can order my homemade meals ahead of time. " +
      " Lmk if you can see my menu at this link: ",
  },
  email: {
    subject: "Come Over For Dinner?",
    body:
      "Hey there!\n\rIf you’re looking for dinner plans, I’d love if you came by my place for some home cooking." +
      " I run a permitted home restaurant on foodnome.com, where you can order my homemade meals ahead of time 💛\n\r" +
      "It’s been wonderful cooking for new friends in the community, so I want you to join!\n\r",
  },
};
