import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS } from "src/styles/";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    [`@media (max-width: ${BREAK_POINTS.mobileRegular}em)`]: {
      overflowX: "hidden",
    },
  },
  layeredCard: {
    zIndex: 1,
    paddingBottom: "2em",
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
    boxShadow: "none",
    flexDirection: "column",
    alignItems: "center",
  },
  [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
    layeredCard: {
      padding: 0,
    },
  },
  sectionTitle: {
    textAlign: "center",
    width: 430,
    zIndex: 99,
    color: "#5B753C",
    margin: "50px 0 5px 0",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: 340,
    },
  },
  link: {
    textDecoration: "underline",
    color: "#BBCCD0",
    fontWeight: "bold",
    marginBottom: "1em",
    zIndex: 99,
  },
});
