import { Paper } from "@material-ui/core";
import React, { Fragment, FunctionComponent } from "react";
import { PaginationComponent } from "src/components";
import { IAllergen } from "src/models";
import { Header } from "./header";
import { AllergenTable } from "./table";

interface IProps {
  allergens: IAllergen[];
  count: number;
  pageNum: number;
  fetchMore: (d: number) => any;
  deleteAllergen: (d: any) => void;
}

export const Layout: FunctionComponent<IProps> = ({
  allergens,
  count,
  fetchMore,
  deleteAllergen,
  pageNum,
}) => {
  return (
    <Fragment>
      <Header count={count} pageNum={pageNum} />
      <Paper style={{ padding: "1rem", margin: "1rem" }}>
        <AllergenTable allergens={allergens} deleteAllergen={deleteAllergen} />

        <PaginationComponent totalCount={count} onChange={fetchMore} />
      </Paper>
    </Fragment>
  );
};
