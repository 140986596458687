import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import { Room, Search } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { FullScreenSearchDialog } from "src/components";
import { COLORS } from "src/styles";
import { truncateText } from "src/utils/helpers";
import { MobileEventSearch } from "./event-search-mobile";
import { heroStyles as useStyles } from "./hero.styles";

declare let window: any;

interface MobileHeroProps {
  queryObj: {
    location: string;
    tags: string[];
  };
  onSubmit: (event: any) => void;
}

export const MobileHero: React.FC<MobileHeroProps> = ({
  onSubmit,
  queryObj,
}) => {
  const classes = useStyles();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [hasSavedLocation, setSavedLocation] = useState<boolean>(
    () => !!getSavedLocation()
  );
  const drawerSearchInput = React.useRef<any>();

  const [open, setOpen] = useState(false);
  const [dialogSearchType, setDialogSearchType] = useState("");

  const handleSearchAddressClickOpen = () => {
    setDialogSearchType("address");
    setOpen(true);
  };
  const handleSearchRestaurantClickOpen = () => {
    setDialogSearchType("cook");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const savedLocation = getSavedLocation();
    if (savedLocation) setSavedLocation(true);
  });

  useEffect(() => {
    if (showFilters) {
      const _drawerSearchInput = drawerSearchInput.current;
      const timeout = setTimeout(
        () => _drawerSearchInput && _drawerSearchInput.focus(),
        500
      );
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showFilters]);

  function getSavedLocation() {
    return window.localStorage.getItem("FOODNOME_LOCATION_PREFERENCE");
  }

  const submitHandlerDrawer = (e) => {
    onSubmit(e);
    setShowFilters(false);
  };

  if (hasSavedLocation)
    return (
      <React.Fragment>
        <AppBar className={classes.appBar}>
          <div style={{ position: "relative" }}>
            {/* <img
              className={classes.sideBubble}
              src={require(`src/assets/logos/main_header_logo.png`)}
              alt="Foodnome bubble background"
            /> */}
            <img
              className={classes.foodnomeLogo}
              src={require(`src/assets/logos/cook.png`)}
              alt="COOK Connect logo"
            />
          </div>
          <Toolbar
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" className={classes.searchMenusTitle}>
              Search Menus
            </Typography>
            <Button
              fullWidth
              variant="contained"
              onClick={handleSearchAddressClickOpen}
              classes={{
                root: classes.enterAddressButtonRoot,
                label: classes.enterAddressButtonLabel,
              }}
              style={queryObj.location ? { color: COLORS.BLACK } : {}}
              startIcon={
                <Room
                  style={{
                    color: COLORS.BLACK,
                  }}
                />
              }
            >
              {queryObj.location
                ? truncateText(queryObj.location, 30)
                : "Search Address or Zip Code"}
            </Button>
            <Button
              fullWidth
              variant="contained"
              classes={{
                root: classes.enterAddressButtonRoot,
                label: classes.enterAddressButtonLabel,
              }}
              startIcon={
                <Search
                  style={{
                    color: COLORS.BLACK,
                  }}
                />
              }
              onClick={handleSearchRestaurantClickOpen}
            >
              Search Home Restaurant or Cook
            </Button>
          </Toolbar>
        </AppBar>
        <FullScreenSearchDialog
          dialogSearchType={dialogSearchType}
          handleClose={handleClose}
          open={open}
          onSubmit={onSubmit}
          queryObj={queryObj}
        />
      </React.Fragment>
    );

  return (
    <div style={{ marginBottom: "1rem" }} className={classes.hero}>
      <div className={classes.heroOverlay} />

      <div style={{ position: "relative" }}>
        {/* <img
          className={classes.heroHeaderSideBubble}
          src={require(`src/assets/logos/main_header_logo.png`)}
          alt="Foodnome bubble background"
        /> */}
        <img
          className={classes.foodnomeLogoWithText}
          src={require(`src/assets/logos/cook.png`)}
          alt="COOK Connect logo"
        />
      </div>
      <div className={classes.heroTextContainer}>
        <Typography variant="h1" component="h1" className={classes.heroText}>
          Fresh. Local. Homemade.
        </Typography>
        <Typography
          variant="h2"
          component="h2"
          className={classes.subtitleText}
        >
          Order the best homemade food near you!
        </Typography>

        <MobileEventSearch queryObj={queryObj} onSubmit={onSubmit} />
      </div>
    </div>
  );
};
