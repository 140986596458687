import {
  Divider,
  IconButton,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import React, { useState } from "react";
import { COLORS } from "src/styles";

interface IProps {
  taxAmount: number;
  platformFee: number;
}

export const TaxesFeesToolTip: React.FC<IProps> = ({
  taxAmount,
  platformFee,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const toolTipContent = (
    <Paper elevation={3} className={classes.toolTipDialog}>
      <section className={classes.toolTipFeesContainer}>
        <section className={classes.toolTipSalesTax}>
          <Typography component="p" variant="h6" className={classes.boldText}>
            Sales Tax
          </Typography>
          <Typography component="p" variant="h6">
            ${taxAmount.toFixed(2)}
          </Typography>
        </section>
        <Divider style={{ marginBottom: "1rem" }} />
        <section className={classes.toolTipSalesTax}>
          <Typography component="p" variant="h6" className={classes.boldText}>
            Platform Fee
          </Typography>
          <Typography component="p" variant="h6">
            ${platformFee?.toFixed(2) ?? "unknown"}
          </Typography>
        </section>
      </section>
      <Typography
        component="p"
        variant="caption"
        className={classes.toolTipDescription}
      >
        This fee helps create small family businesses in your neighborhood.
      </Typography>
    </Paper>
  );
  return (
    <Tooltip
      title={toolTipContent}
      open={open}
      disableHoverListener
      onClose={() => setOpen(false)}
      classes={{ tooltip: classes.toolTip }}
      leaveTouchDelay={8000}
    >
      <IconButton
        disableRipple
        disableFocusRipple
        size="small"
        onClick={() => setOpen(!open)}
      >
        <HelpIcon />
      </IconButton>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  toolTip: {
    padding: 0,
  },
  toolTipDialog: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: COLORS.WHITE,
    padding: "2rem",
    maxWidth: "20rem",
  },
  toolTipFeesContainer: {
    display: "flex",
    flexDirection: "column",
  },
  toolTipSalesTax: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "1rem",
  },
  boldText: {
    fontFamily: "CustomFourBold",
  },
  toolTipDescription: {
    color: COLORS.DARK_GREY,
  },
});
