import { gql } from "@apollo/client";

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordPL!) {
    resetPassword(resetPassPL: $input) {
      email
      role
      nomes
      account
    }
  }
`;
