import { useQuery } from "@apollo/client";
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import classNames from "classnames";
import React, { FC, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { GoogleMapContainer } from "src/components";
import { useCreateChannel } from "src/components/chat2";
import { GET_MY_PENDING_RESTAURANT_REVIEWS } from "src/components/review-request/api/queries";
import { IEvent } from "src/models";
import { formatDate, openAddress } from "src/utils/helpers";
// import { parseEventType } from "../../detail/order/helpers";
import { useStyles } from "./order-details.styles";
import { TransactionDetails } from "./transaction-details";

interface IProps {
  event: IEvent;
  handleOpenDialog: (type, e) => void;
}

export const OrderDetailsCard: FC<IProps> = ({ event, handleOpenDialog }) => {
  const classes = useStyles();
  const history = useHistory();
  const { createChannel, loading } = useCreateChannel();

  const { data, loading: _loading, error } = useQuery(
    GET_MY_PENDING_RESTAURANT_REVIEWS
  );
  const [orderNum, setOrderNum] = useState(0);

  const { name, locale, restaurant, eventUser } = event;

  if (!eventUser) return null;
  let currentOrder = eventUser;

  if (orderNum && eventUser?.orders)
    currentOrder = eventUser?.orders[orderNum - 1];

  const address = currentOrder?.deliveryAddress
    ? currentOrder?.deliveryAddress
    : locale.private
    ? locale.private
    : locale.public;
  return (
    <>
      {_loading ? (
        <CircularProgress
          className={classes.loadingContainer}
          data-testid="spinner"
        />
      ) : (
        <section className={classes.card}>
          <div
            className={classNames(classes.headerContainer, classes.container)}
          >
            <Typography
              variant="h2"
              component="h1"
              className={classes.eventTitle}
            >
              <span style={{ fontWeight: 500 }}>Order Summary</span>{" "}
              {/* {event && eventUser ? (
                <span style={{ fontWeight: 500 }}>
                  ({parseEventType(eventUser.dineOption, event.type[0])})
                </span>
              ) : null} */}
            </Typography>
          </div>

          <section className={classes.mainContentContainer}>
            {/* Main order details container */}
            <section className={classes.container}>
              {/* Pickup date and time */}
              <div style={{ padding: "18px 0" }}>
                <Typography
                  variant="body2"
                  component="h3"
                  className={classes.subText}
                >
                  {event.type.includes("FIXED_TIME")
                    ? "Arrival Date & Time"
                    : currentOrder?.dineOption === "DELIVERY"
                    ? "Delivery Date & Time"
                    : `Pick-up Date & Time`}
                </Typography>
                <div className={classes.pickupAndEditContainer}>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className={classes.boldSubText}
                  >
                    {currentOrder && !event.type.includes("FIXED_TIME")
                      ? formatDate(currentOrder.arrivalTime)
                      : formatDate(event.startTime)}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => history.push(`/menus/${event.id}`)}
                  >
                    Change
                  </Button>
                </div>
              </div>

              <Divider />

              {/* Location */}
              {locale && (
                <>
                  <div className={classes.subContainer}>
                    <Typography
                      variant="body2"
                      component="h3"
                      className={classes.subText}
                    >
                      {currentOrder?.deliveryAddress
                        ? "Delivery Location"
                        : "Location"}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      onClick={() =>
                        openAddress(
                          `http://maps.google.com/maps?q=${address
                            .split(/[\s,]+/)
                            .join("+")}`
                        )
                      }
                      className={classes.link}
                    >
                      {address}
                    </Typography>
                    {!currentOrder?.deliveryAddress && locale.coordinates && (
                      <div className={classes.mapContainer}>
                        <GoogleMapContainer
                          pointLocation
                          center={
                            locale.coordinates
                              ? {
                                  lat: locale.coordinates.latitude,
                                  lng: locale.coordinates.longitude,
                                }
                              : undefined
                          }
                        />
                      </div>
                    )}
                  </div>
                  <Divider />
                </>
              )}

              {/* Home Restaurant */}

              {event.eventUser && event.eventUser.status === "REQUESTED" && (
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.privateNote}
                >
                  *You have NOT yet been approved by the host. Please wait for a
                  response from your host before you attend.
                </Typography>
              )}
              <div className={classes.subContainer}>
                <Typography
                  variant="body2"
                  component="h3"
                  className={classes.subText}
                >
                  Home Restaurant{" "}
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  className={classes.boldSubText}
                >
                  {name}
                </Typography>
              </div>

              <Divider />

              {/* Cook */}

              <div
                className={classes.subContainer}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <Typography
                    variant="body2"
                    component="h3"
                    className={classes.subText}
                  >
                    Home cook{" "}
                  </Typography>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Avatar
                      alt={
                        restaurant.host.firstName +
                        " " +
                        restaurant.host.lastName
                      }
                      src={restaurant.host.picture}
                      className={classes.avatar}
                    />
                    <div
                      style={{
                        marginLeft: "2rem",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        component="p"
                        className={classes.boldSubText}
                      >{`${
                        restaurant.host.firstName
                      } ${restaurant.host.lastName.slice(0, 1)}.`}</Typography>
                      <Link
                        className={classes.link}
                        to={`/${restaurant?.name?.replace(/ /g, "-")}`}
                      >
                        <Typography variant="caption" component="span">
                          View profile
                        </Typography>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className={classes.btnContainer}>
                  <Button
                    onClick={() => createChannel(event.restaurant.host.id)}
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.chatBtn}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress data-testid="spinner" />
                    ) : (
                      "Message"
                    )}
                  </Button>

                  {data?.getMyPendingRestaurantReviews?.length > 0 && (
                    <Button
                      onClick={() => history.push("/review")}
                      variant="text"
                      color="secondary"
                      className={classes.reviewBtn}
                    >
                      Leave a Review
                    </Button>
                  )}
                </div>
              </div>
            </section>

            {/* Order Details */}
            <section style={{ marginBottom: "4rem", padding: "0 2rem" }}>
              {Boolean(event?.eventUser?.orders?.length) && (
                <div className={classes.orderNavContainer}>
                  <IconButton
                    data-testid="prev-order-btn"
                    onClick={() =>
                      setOrderNum(
                        orderNum === 0
                          ? event.eventUser?.orders?.length ?? 0
                          : orderNum - 1
                      )
                    }
                  >
                    <ChevronLeft className={classes.navNext} />
                  </IconButton>
                  <Typography
                    component="p"
                    variant="body2"
                    className={classes.orderCount}
                  >
                    Order {orderNum + 1} /{" "}
                    {(event.eventUser?.orders?.length ?? 0) + 1}
                  </Typography>
                  <IconButton
                    data-testid="next-order-btn"
                    onClick={() =>
                      setOrderNum(
                        orderNum === (event.eventUser?.orders?.length ?? 0)
                          ? 0
                          : orderNum + 1
                      )
                    }
                  >
                    <ChevronRight className={classes.navNext} />
                  </IconButton>
                </div>
              )}

              <TransactionDetails
                event={event}
                currentOrder={currentOrder}
                handleOpenDialog={handleOpenDialog}
              />
              <div className={classes.helpContainer}>
                <div className={classes.foodnomeContainer}>
                  <img
                    style={{ height: "3rem", width: "3rem" }}
                    src={require("src/assets/logos/cook.svg")}
                    alt="COOK Connect logo"
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "1rem",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.question}
                    >
                      Issues with your order?
                    </Typography>
                    <Button
                      color="secondary"
                      style={{ textTransform: "unset", padding: 0 }}
                      variant="text"
                      onClick={() => createChannel()}
                    >
                      Chat with us!
                    </Button>
                  </div>
                </div>
                {/* <div className={classes.linksContainer}>
                  <Link to="/terms-and-conditions">
                    <Typography variant="caption" component="span">
                      Terms & Conditions
                    </Typography>
                  </Link>
                </div> */}
              </div>
            </section>
          </section>
        </section>
      )}
    </>
  );
};
