import { Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./secure.styles";

export const Secure: React.FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.container}>
      <Typography variant="h5" component="h5" className={classes.title}>
        Secure Payment
      </Typography>
      <div className={classes.paymentContainer}>
        <img
          src={require("../../assets/trust_badge.png")}
          className={classes.payment}
        />
        <a
          href="https://www.braintreegateway.com/merchants/YOUR_MERCHANT_ID/verified"
          target="_blank"
        >
          <img
            src="https://s3.amazonaws.com/braintree-badges/braintree-badge-light.png"
            className={classes.braintreeBadge}
          />
        </a>
      </div>
    </section>
  );
};
