import Moment from "moment";
import { DateRange, extendMoment } from "moment-range";
import { IEvent, IUser } from "src/models";

const moment = extendMoment(Moment as any);

export interface ITimeSlotWithGuests {
  guests: IUser[];
  timeSlot: DateRange;
}

const matched = (term: string, guest: IUser) => {
  const fullName = `${guest.firstName} ${guest.lastName}`;
  const _term = term.trim();
  return (
    !!guest.email.match(new RegExp(_term, "ig")) || // check email
    !!fullName.match(new RegExp(_term, "ig")) || // check full name with space
    !!_term.match(new RegExp(guest.firstName, "ig")) || // if fail check if term contains either first or last
    !!_term.match(new RegExp(guest.lastName, "ig"))
  );
};

export const search = (term: string, guests: IUser[]) => {
  return guests.filter((guest) => matched(term, guest));
};

export const getDisplayTime = (
  startTime: Moment.Moment,
  endTime: Moment.Moment
) => {
  return `${startTime.format("hh:mma")}-${endTime.format("hh:mma")}`;
};

export const getTimeSlots = ({
  step = 15,
  units = "minutes",
  startTime,
  endTime,
}: {
  step?: number;
  units?: Moment.unitOfTime.Diff;
  startTime: string;
  endTime: string;
}) => {
  const eventStartTime = moment(Number(startTime));
  const eventEndTime = moment(Number(endTime));
  const eventRange = moment.range(eventStartTime, eventEndTime);
  const timeSlots = Array.from(eventRange.by(units, { step }));

  const intervals: DateRange[] = timeSlots.reduce(
    (acc: any, next: any, index, array: any) => {
      const fifteenMinuteRange = moment.range(next, array[index + 1]);
      return [...acc, fifteenMinuteRange];
    },
    []
  );

  return intervals;
};

export const getTimeSlotsWithGuests = (
  event: IEvent
): {
  timeSlotsWithGuests: ITimeSlotWithGuests[];
  totalCompleted: number;
} => {
  const isFixedTime = event.type.includes("FIXED_TIME");
  const placeGuestsIntoTimeSlots = (
    acc: {
      timeSlotsWithGuests: ITimeSlotWithGuests[];
      totalCompleted: number;
    },
    nextTimeSlot
  ) => {
    const slotWithGuests = {
      timeSlot: nextTimeSlot,
      guests: event.guests.filter((guest) => {
        const arrivalTime = isFixedTime
          ? moment(Number(event.startTime))
          : moment(Number(guest.eventUser.arrivalTime));
        return nextTimeSlot.contains(arrivalTime, {
          excludeEnd: true,
        });
      }),
    };

    if (!slotWithGuests.guests.length) return acc;

    // No guests found in the time slot

    const totalCheckedIn = slotWithGuests.guests.reduce(
      (totalGuestCount: any, next: IUser) => {
        if (next.eventUser?.orders?.length) {
          let count = next.eventUser.checkedIn ? 1 : 0;
          count = next.eventUser.orders.reduce(
            (_acc, _next) => _acc + (_next.checkedIn ? 1 : 0),
            count
          );
          return totalGuestCount + count;
        } else {
          return next.eventUser.checkedIn
            ? totalGuestCount + 1
            : totalGuestCount;
        }
      },
      0
    );

    return {
      ...acc,
      timeSlotsWithGuests: [...acc.timeSlotsWithGuests, slotWithGuests],
      totalCompleted: acc.totalCompleted + totalCheckedIn,
    };
  };

  const earliestTime = Math.min(
    ...event.guests.map((g) =>
      g.eventUser?.arrivalTime
        ? Number(g.eventUser.arrivalTime)
        : Number(event.startTime)
    )
  );
  // 1. Get all timeslots from the event beginning to event end.
  const timeSlots = getTimeSlots({
    startTime:
      Number(event.startTime) > earliestTime
        ? moment(earliestTime)
            .subtract(moment(earliestTime).minute() % 30, "m")
            .valueOf()
            .toString()
        : event.startTime,
    endTime: event.endTime,
    step: isFixedTime
      ? moment
          .duration(
            moment(Number(event.endTime)).diff(moment(Number(event.startTime)))
          )
          .asMinutes()
      : 15,
  });

  // 2. sort users into their respective timeslots
  const slots = timeSlots.reduce(placeGuestsIntoTimeSlots, {
    timeSlotsWithGuests: [],
    totalCompleted: 0,
  });

  return slots;
};
