import { Button, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Fade } from "react-awesome-reveal";
import { HybridLink } from "src/components";
import { useStyles } from "./about.styles";

export const About: FC = () => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.headerContainer}>
          <Fade triggerOnce={true}>
            <Typography variant="h2" component="h2" className={classes.title}>
              About Us
            </Typography>
          </Fade>
          <Fade triggerOnce={true} delay={250}>
            <div className={classes.squiggleContainer}>
              <img
                src={require("src/screens/home-new/assets/underline.svg")}
                className={classes.squiggle}
              />
            </div>
          </Fade>
        </div>
        <div className={classes.mainContent}>
          <Fade triggerOnce={true} delay={450}>
            <div className={classes.imageContainer}>
              <img
                src={require("src/screens/home-new/assets/images/community_safety.jpg")}
                className={classes.image}
              ></img>
            </div>
          </Fade>
          <Fade triggerOnce={true} delay={650}>
            <div className={classes.textContainer}>
              <Typography variant="h4" component="p" className={classes.text}>
                <span className={classes.bold}>COOK Connect </span>
                is the first legal marketplace for home
                cooked food operated by a nonprofit organization in the US.
                Our all-in-one ordering platform and simple management system
                have been specifically designed to turn culinary
                entrepreneurship dreams into a successful business —
                from the comfort of a permitted home kitchen! It offers
                diners the opportunity to support local family businesses
                and discover food as diverse as the communities we're a part
                of! As a nonprofit, proceeds from the platform are reinvested
                into supporting chefs and the home cooking movement through
                programs, training, and small business support.
              </Typography>

              <Typography
                variant="h4"
                component="p"
                style={{ marginTop: "2.5rem" }}
                className={classes.text}
              >
                COOK Connect is operated by COOK Alliance, the nonprofit has been working
                with legislators since 2015 to legalize Microenterprise Home
                Kitchen Operations (MEHKOs) across the country. We have
                advocated and organized for years to create a more
                equitable, inclusive, and human-centered food economy.
                Our work now includes education, permitting assistance,
                and mentorship for home-based culinary entrepreneurs.
                Join us as we work to empower food entrepreneurship,
                build community, and redefine our relationship to our food!
              </Typography>
              <HybridLink
                className={classes.link}
                href="https://www.cookalliance.org/"
              >
                {
                  <Button
                    color="primary"
                    size="large"
                    className={classes.button}
                    variant="contained"
                  >
                    Learn More about Cook Alliance
                  </Button>
                }
              </HybridLink>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};
