import { gql } from "@apollo/client";

export const GET_RESTAURANT_REVIEWS = gql`
  query getRestaurantReviewsByName($input: GetRestaurantReviewsByNamePL) {
    getRestaurantReviewsByName(getRestaurantReviewsByNamePL: $input) {
      count
      rows {
        id
        text
        cookResponse
        quality
        hospitality
        accuracy
        createdAt
        updatedAt
        restaurantId
        guest {
          id
          firstName
          lastName
          picture
        }
      }
    }
  }
`;

export const COOK_REVIEW_RESPONSE = gql`
  mutation cookUpdateRestaurantReview($input: CookUpdateRestaurantReviewPL!) {
    cookUpdateRestaurantReview(cookUpdateRestaurantReviewPL: $input) {
      id
      text
      cookResponse
      quality
      hospitality
      accuracy
      createdAt
      updatedAt
      restaurantId
      guest {
        id
        firstName
        lastName
        picture
      }
    }
  }
`;
