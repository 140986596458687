import { gql, useQuery } from "@apollo/client";
import {
  Avatar,
  Badge,
  Drawer,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Typography,
  useTheme,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import { UserNotification } from "src/assets/icons/svg/user-notification";
import {
  ChatActionTypes,
  StreamChatContainer,
  _ChatContext,
} from "src/components/chat2";
import { Notifications } from "src/components/notifications/container";
import { IGetMe } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles/colors";
import "../animations.css";
import { useAuthStyles } from "./authenticated.styles";

interface IProps {
  user: IGetMe;
}

const USER_GET_NOTIFICATION_COUNT = gql`
  query userGetNotificationCount {
    userGetNotificationCount
  }
`;
export const AuthenticatedUserActions: React.FC<IProps> = ({ user }) => {
  const {
    state: { drawerStatus, unreadCount },
    dispatch,
  } = useContext(_ChatContext);

  const theme = useTheme();

  const { data } = useQuery(USER_GET_NOTIFICATION_COUNT, {
    fetchPolicy: "no-cache",
  });
  const classes = useAuthStyles();
  const avatarProps = Object.assign(
    {},
    user
      ? user.picture
        ? { src: user.picture }
        : { children: `${user.firstName[0]}${user.lastName[0]}` }
      : {}
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  return (
    <>
      <IconButton
        id="chat-btn"
        onClick={() => dispatch({ type: ChatActionTypes.SET_DRAWER_STATUS })}
      >
        <Badge
          classes={{ anchorOriginTopRightRectangle: classes.circle }}
          showZero={false}
          badgeContent={unreadCount}
          color="primary"
        >
          <img
            className={classes.headerBarIcon}
            style={{ width: 24, height: 24 }}
            src={require(`src/assets/icons/chat-44.svg`)}
          />
        </Badge>
      </IconButton>
      <IconButton onClick={handleClick}>
        <Badge
          classes={{ anchorOriginTopRightRectangle: classes.circle }}
          showZero={false}
          badgeContent={
            data && data.userGetNotificationCount
              ? data.userGetNotificationCount
              : null
          }
          color="primary"
        >
          <UserNotification
            data-testid="notifications-button-desktop"
            className={classes.headerBarIcon}
          />
        </Badge>
      </IconButton>
      <Drawer
        open={drawerStatus}
        anchor="right"
        classes={{ paperAnchorRight: classes.drawer2 }}
        onClose={() => dispatch({ type: ChatActionTypes.SET_DRAWER_STATUS })}
      >
        <StreamChatContainer />
      </Drawer>
      <Popover
        onEntered={() => {
          // a bit hacky solution to removing the hidden overflow when
          // popover is open.
          const body = document.querySelector("body") as HTMLElement;
          body.style.overflow = "unset";
        }}
        classes={{ paper: classes.notificationPaper }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        style={{ zIndex: 1000, top: 20 }}
        onClose={() => setAnchorEl(null)}
      >
        <div className={classes.notificationsWrapper}>
          <Notifications />
        </div>
      </Popover>
      <MenuList
        classes={{
          root: classes.navBarItems,
          padding: classes.navBarItemsPadding,
        }}
      >
        <MenuItem
          button
          disableRipple
          disableGutters
          style={{
            cursor: "pointer",
            overflow: "visible",
            justifyContent: "space-around",
            alignItems: "center",
            padding: 8,
            margin: "0 .5em",
          }}
          aria-haspopup="true"
          data-testid="auth-avatar-menu-icon"
          data-e2e="header-logedin-user-menu-icon"
          onClick={() => sharedAPI.setAuthDrawerState(true)}
          classes={{ root: classes.navBarItem }}
        >
          <Avatar
            data-testid="AuthenticatedUserActions_user-avatar"
            style={{
              cursor: "pointer",
              width: 30,
              height: 30,
              backgroundColor: COLORS.RED,
              fontFamily: `CustomFourBold`,
              fontWeight: 500,

              textTransform: "uppercase",
            }}
            aria-haspopup="true"
            {...avatarProps}
          />
          <div
            style={{
              width: "100%",
              position: "relative",
            }}
          >
            <Typography
              variant="body2"
              component="p"
              className={classes.firstName}
            >
              {user.firstName}
            </Typography>
            <KeyboardArrowDown
              style={{
                position: "absolute",
                top: "50%",
                transform: "translate(0, -50%)",
                right: -15,
                zIndex: 2,
                fontSize: "2.5rem",
                color: COLORS.MEDIUM_GREEN,
              }}
            />
          </div>
        </MenuItem>
      </MenuList>
    </>
  );
};
