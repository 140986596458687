import {
  Button,
  Card,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { Delete, Edit, FileCopy, Settings } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import { DateIcon } from "src/components";
import { EventDuplicatePL, IEvent } from "src/models";
import { amplitude } from "src/services";
import { COLORS } from "src/styles";
import { formatStartToEndDate, getEventImage } from "src/utils/helpers";
import { ActionsOverlay } from "./action-overlay";
import { useStyles } from "./cook-event-card.styles";

interface IProps {
  event: IEvent;
  onClickCard: () => void;
  onClickDelete: () => void;
  onClickDuplicate: (eventDuplicatePL: EventDuplicatePL) => Promise<any>;
}

export const EventCard: React.FC<IProps> = React.memo(
  ({ event, onClickCard, onClickDelete, onClickDuplicate }) => {
    const classes = useStyles();
    const [isDeleteOverlayActive, setDeleteOverlayState] = useState<boolean>(
      false
    );
    const [isDuplicateOverlayActive, setDuplicateOverlayState] = useState<
      boolean
    >(false);
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const dateInputRef = React.useRef<any>();

    const withNoPropagation = React.useCallback(
      (fn: any) => (e) => {
        e.stopPropagation();
        fn();
      },
      []
    );

    const handleOpenMenu = (e) => {
      e.stopPropagation();
      setAnchorEl(e.currentTarget);
    };
    const handleCloseMenu = () => setAnchorEl(null);
    const handleClickDelete = () => {
      setDeleteOverlayState(true);
      handleCloseMenu();
    };
    const handleClickDuplicate = () => {
      amplitude
        .getInstance()
        .logEvent(`[cook/event card/duplicate] click "duplicate"`);
      setDuplicateOverlayState(true);
      handleCloseMenu();
    };

    const [_, time] = formatStartToEndDate({
      startTime: event.startTime,
      endTime: event.endTime,
    }).split(",");

    return (
      <Card
        data-testid={`cook-event-card-${event.name}`}
        component={"article"}
        className={classes.container}
        role="link"
        onClick={onClickCard}
      >
        <div className={classes.headerWrapper}>
          <div className={classes.dateIconWrapper}>
            <DateIcon date={event.startTime} />
          </div>
          <div className={classes.startEndTimeWrapper}>
            <Typography
              variant="body1"
              component="h4"
              className={classes.cardHeading}
            >
              {event.name ? event.name : "Untitled Event"}
            </Typography>

            <Typography
              variant="subtitle1"
              component="time"
              dateTime={moment(Number(event.startTime)).toISOString()}
              className={classes.startEndTime}
            >
              {time}
            </Typography>
          </div>
        </div>
        <div
          className={classes.cardBottom}
          style={{
            background: `url("${getEventImage(event)}")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <TransitionGroup>
            <ActionsOverlay appear={isDeleteOverlayActive}>
              <Typography
                variant="body2"
                component="p"
              >{`Are you sure you want to delete this event?`}</Typography>
              <div>
                <Button
                  style={{ color: COLORS.RED }}
                  classes={{
                    root: classes.overlayButton,
                  }}
                  onClick={onClickDelete}
                >
                  Delete
                </Button>
                <Button
                  classes={{
                    root: classes.overlayButton,
                  }}
                  onClick={() => setDeleteOverlayState(false)}
                >
                  No
                </Button>
              </div>
            </ActionsOverlay>
            <ActionsOverlay
              onEntered={() => dateInputRef.current.focus()}
              appear={isDuplicateOverlayActive}
            >
              <form
                style={{ display: "flex", flexDirection: "column" }}
                name="duplicate-date-input"
                onSubmit={(e: any) => {
                  e.preventDefault();
                  amplitude
                    .getInstance()
                    .logEvent(
                      `[cook/event card/duplicate] event successfully duplicated`
                    );
                  const { date } = e.target.elements;
                  const oldStartTime = moment(Number(event.startTime)).format(
                    "HH:mm"
                  );
                  onClickDuplicate({
                    eventId: event.id,
                    date: moment(
                      `${date.value} ${oldStartTime}`,
                      `YYYY-MM-DD HH:mm`
                    )
                      .valueOf()
                      .toString(),
                  });
                }}
              >
                <Typography
                  component="label"
                  variant="caption"
                  className="hidden-label"
                  htmlFor="date-input"
                >
                  Date
                </Typography>
                <OutlinedInput
                  inputRef={dateInputRef}
                  required
                  type="date"
                  id="date-input"
                  name="date"
                  labelWidth={0}
                  classes={{
                    notchedOutline: classes.dateInputOutlined,
                    input: classes.dateInput,
                  }}
                  inputProps={{ min: moment().format("YYYY-MM-DD") }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    data-e2e="duplicate-button-event-card"
                    style={{
                      color: COLORS.MEDIUM_GREEN,
                      fontFamily: "CustomFourBold",
                      fontWeight: 500,
                    }}
                    type="submit"
                  >
                    Duplicate
                  </Button>
                  <Button
                    onClick={() => setDuplicateOverlayState(false)}
                    style={{
                      color: COLORS.WHITE,
                      fontFamily: "CustomFourBold",
                      fontWeight: 500,
                    }}
                    type="button"
                  >
                    cancel
                  </Button>
                </div>
              </form>
            </ActionsOverlay>
          </TransitionGroup>

          {event.status === "PENDING" && (
            <div className={classes.draftOverlay}>
              <div className={classes.draftOverlayContent}>
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.draftOverlayText}
                >
                  Draft
                </Typography>
                <Edit style={{ margin: "0 .5rem", color: COLORS.WHITE }} />
              </div>
            </div>
          )}
          <div className={classes.cardBottomOverlay} />
          <div className={classes.cardBottomActions}>
            {event.status !== "PENDING" && (
              <Typography
                variant="body2"
                component="p"
                data-testid="guest-count"
                className={
                  event.guestCount
                    ? classes.activeGuestCount
                    : classes.zeroGuestCount
                }
              >
                {`${event.guestCount} guest${
                  event.guestCount === 1 ? "" : "s"
                }`}
              </Typography>
            )}
          </div>

          <IconButton
            classes={{ root: classes.menuButton }}
            onClick={handleOpenMenu}
            data-testid="menu-button"
          >
            <MenuIcon />
          </IconButton>

          <Menu
            data-e2e="event-card-menu-item"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            getContentAnchorEl={null}
            keepMounted
            onClose={withNoPropagation(handleCloseMenu)}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
          >
            <MenuItem onClick={withNoPropagation(onClickCard)}>
              <ListItemIcon>
                {event.status !== "PENDING" ? (
                  <Settings style={{ color: COLORS.MEDIUM_GREEN }} />
                ) : (
                  <Edit style={{ color: COLORS.MEDIUM_GREEN }} />
                )}
              </ListItemIcon>
              <ListItemText
                classes={{ root: classes.listItemText }}
                disableTypography
              >
                {event.status !== "PENDING" ? "Manage" : "Edit"}
              </ListItemText>
            </MenuItem>
            <MenuItem
              data-testid={`duplicate-${event.id}`}
              onClick={withNoPropagation(handleClickDuplicate)}
            >
              <ListItemIcon>
                <FileCopy style={{ color: COLORS.MEDIUM_GREEN }} />
              </ListItemIcon>
              <ListItemText
                classes={{ root: classes.listItemText }}
                disableTypography
              >
                Duplicate
              </ListItemText>
            </MenuItem>
            {event.status === "PENDING" && (
              <MenuItem
                data-testid={`delete-${event.id}`}
                onClick={withNoPropagation(handleClickDelete)}
              >
                <ListItemIcon>
                  <Delete style={{ color: COLORS.MEDIUM_GREEN }} />
                </ListItemIcon>
                <ListItemText
                  classes={{ root: classes.listItemText }}
                  disableTypography
                >
                  Delete
                </ListItemText>
              </MenuItem>
            )}
            {event.status === "ACTIVE" && (
              <MenuItem
                onClick={(e) => e.stopPropagation()}
                component={Link as any}
                {...({
                  to: `/c/menus/edit/${event.id}-${event.name.replace(
                    / /g,
                    "-"
                  )}`,
                } as any)}
              >
                <ListItemIcon>
                  <Edit style={{ color: COLORS.MEDIUM_GREEN }} />
                </ListItemIcon>
                <ListItemText
                  classes={{ root: classes.listItemText }}
                  disableTypography
                >
                  {"Edit"}
                </ListItemText>
              </MenuItem>
            )}
          </Menu>
        </div>
      </Card>
    );
  }
);
