import { useQuery } from "@apollo/client";
import {
  Button,
  IconButton,
  MenuItem,
  MenuList,
  SwipeableDrawer,
} from "@material-ui/core";
import {
  Close,
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  YouTube,
} from "@material-ui/icons";
import { parse, stringify } from "query-string";
import React from "react";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { HybridLink } from "src/components";
import { sharedAPI } from "src/shared-graphql";
import { GET_GLOBAL_DRAWER_STATE, GET_ME } from "src/shared-graphql/queries";
import { _socialMedia } from "src/utils/constants";
import { useNonAuthDrawerStyles } from "./non-auth-drawer.styles";
import { routes } from "./static-routes";

interface IProps extends RouteComponentProps<any> {
  anchor?: "right" | "left";
}

const _NonAuthNavigationDrawer: React.FC<IProps> = ({
  anchor = "left",
  history,
  location,
}) => {
  const classes = useNonAuthDrawerStyles();
  const { data: dataGetMe, loading: getMeLoading } = useQuery(GET_ME);
  const { data } = useQuery(GET_GLOBAL_DRAWER_STATE);

  const drawerClasses = Object.assign(
    {},
    anchor === "left"
      ? { paperAnchorLeft: classes.paper }
      : { paperAnchorRight: classes.paper }
  );

  const onClickLogin = () => {
    handleCloseGlobalDrawer();
    sharedAPI.setUserSignInDialog(true);
    const prevParams = location.search ? parse(location.search) : {};
    history.replace({
      search: stringify({
        ...prevParams,
        state: "login",
      }),
    });
  };

  const onClickSignUp = () => {
    handleCloseGlobalDrawer();
    sharedAPI.setUserSignInDialog(true);
  };

  const handleCloseGlobalDrawer = () => sharedAPI.setGlobalDrawerState(false);

  // Useless function but required for the onOpen prop for SwipeableDrawer
  const handleOpenGlobalDrawer = () => sharedAPI.setGlobalDrawerState(true);

  return (
    <SwipeableDrawer
      data-testid="navigation-drawer"
      classes={drawerClasses}
      BackdropProps={{
        classes: { root: classes.backdrop },
        ...({
          "data-testid": "NonAuthNavigationDrawer-backdrop",
        } as any),
      }}
      onOpen={handleOpenGlobalDrawer}
      open={!!data?.getGlobalDrawerState}
      anchor={anchor}
      onClose={handleCloseGlobalDrawer}
    >
      <div className={classes.innerDrawer}>
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img
            src={require(`src/assets/logos/main_header_logo.png`)}
            className={classes.textLogoRed}
            alt=""
          />
          <IconButton
            onClick={handleCloseGlobalDrawer}
            className={classes.closeButton}
            data-testid="navigation-drawer-close-button"
          >
            <Close className={classes.closeIcon} />
          </IconButton>
          <MenuList classes={{ root: classes.menuList }}>
            {routes.map((route) => {
              return (
                <MenuItem
                  disableRipple
                  disableTouchRipple
                  disableGutters
                  data-e2e={route.name}
                  classes={{ root: classes.menuItem }}
                  key={route.pathname}
                >
                  <NavLink
                    exact
                    className={classes.link}
                    activeClassName={classes.activeLink}
                    to={route.pathname}
                    onClick={handleCloseGlobalDrawer}
                  >
                    {route.name}
                  </NavLink>
                </MenuItem>
              );
            })}
          </MenuList>
        </div>

        <ul className={classes.unorderedList}>
          <li className={classes.socialMediaItem}>
            <HybridLink
              className={classes.socialMediaAnchor}
              ariaLabel="instagram"
              href={_socialMedia.instagram}
            >
              <Instagram className={classes.socialMediaIcon} />
            </HybridLink>
          </li>
          <li className={classes.socialMediaItem}>
            <HybridLink
              className={classes.socialMediaAnchor}
              ariaLabel="facebook"
              href={_socialMedia.facebook}
            >
              <Facebook className={classes.socialMediaIcon} />
            </HybridLink>
          </li>
          <li className={classes.socialMediaItem}>
            <HybridLink
              className={classes.socialMediaAnchor}
              ariaLabel="youtube"
              href={_socialMedia.youtube}
            >
              <YouTube className={classes.socialMediaIcon} />
            </HybridLink>
          </li>

          <li className={classes.socialMediaItem}>
            <HybridLink
              className={classes.socialMediaAnchor}
              ariaLabel="linkedin"
              href={_socialMedia.linkedin}
            >
              <LinkedIn className={classes.socialMediaIcon} />
            </HybridLink>
          </li>

          <li className={classes.socialMediaItem}>
            <HybridLink
              className={classes.socialMediaAnchor}
              ariaLabel="twitter"
              href={_socialMedia.twitter}
            >
              <Twitter className={classes.socialMediaIcon} />
            </HybridLink>
          </li>
        </ul>

        <div className={classes.menuListSecondaryWrapper}>
          <MenuList
            disablePadding
            classes={{ root: classes.menuListSecondary }}
          >
            {!getMeLoading && dataGetMe && !dataGetMe.getMe && (
              <MenuItem
                classes={{ root: classes.menuItemWithButtons }}
                disableGutters
                disableTouchRipple
                disableRipple
              >
                <Button
                  fullWidth
                  onClick={onClickSignUp}
                  classes={{ contained: classes.menuButton }}
                  variant="contained"
                  color="primary"
                >
                  Sign up
                </Button>
                <Button
                  fullWidth
                  onClick={onClickLogin}
                  classes={{ contained: classes.menuButton }}
                  variant="contained"
                  color="primary"
                >
                  Log in
                </Button>
              </MenuItem>
            )}
          </MenuList>
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export const NonAuthNavigationDrawer = withRouter(_NonAuthNavigationDrawer);
