import { SvgIcon } from "@material-ui/core";
import React, { FC } from "react";

interface IProps {
  className?: any;
}

export const CustomEmailIcon: FC<IProps> = ({ className, ...props }) => (
  <SvgIcon className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <title>Email</title>
      <path d="M0,24A24,24,0,1,1,24,48,24,24,0,0,1,0,24Z" fill="#ff3056" />
      <path
        d="M38.07,20.46V30.68a2.4,2.4,0,0,1-2.39,2.39H12.36A2.39,2.39,0,0,1,10,30.68V20.46l11.48,5.59a5.73,5.73,0,0,0,2.59.61,5.79,5.79,0,0,0,2.59-.61Z"
        fill="#fff"
      />
      <path
        d="M38.05,17.32v.19l-13,6.42a2.44,2.44,0,0,1-2.14,0L10,17.54v-.18a2.43,2.43,0,0,1,2.41-2.43H35.64A2.39,2.39,0,0,1,38.05,17.32Z"
        fill="#fff"
      />
    </svg>
  </SvgIcon>
);
