import { useQuery } from "@apollo/client";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { ErrorLoadingComponent } from "src/components";
import { CreatePromotionPL } from "src/models";
import { eventAPI } from "../api";
import { GET_EVENTS } from "../api/graphql";
import { Layout } from "./layout";

export const EventListContainer: FC = () => {
  const { error, loading, data, fetchMore } = useQuery(GET_EVENTS, {
    errorPolicy: "all",
    variables: { input: { pagePL: { limit: 10 } } },
  });
  const history = useHistory();
  const [pageNum, setPageNum] = useState(1);

  const _deleteEvent = (id: number) => {
    if (data.getEvents.rows.length === 1 && pageNum > 1) {
      setPageNum(pageNum - 1);
      return eventAPI.deleteEvent(id, (pageNum - 2) * 10);
    }
    return eventAPI.deleteEvent(id, (pageNum - 1) * 10);
  };

  const _promoEvent = (d: CreatePromotionPL) => {
    if (d.amount) {
      return eventAPI.promoEvents(d);
    } else {
      return eventAPI.deletePromo(d.eventId);
    }
  };

  const _fetchMore = (d: {
    pageNumber: number;
    searchTerm?: string;
    status?: string;
  }) => {
    setPageNum(d.pageNumber);
    fetchMore<any>({
      variables: {
        input: {
          pagePL: { limit: 10, offset: (d.pageNumber - 1) * 10 },
          status: d.status,
          searchTerm: d.searchTerm,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          getEvents: (fetchMoreResult as any).getEvents,
        });
      },
    });
  };
  if (loading || error || !data?.getEvents) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }
  return (
    <Layout
      events={data?.getEvents?.rows}
      totalCount={data?.getEvents?.count}
      fetchMore={_fetchMore}
      deleteEvent={_deleteEvent}
      promoEvent={_promoEvent}
      redirect={history.push}
    />
  );
};
