import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { AppStatus, IGetMe } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { COOK_CREATE_SPECIAL_EVENT } from "../api/mutations";
import { useStyles } from "./charge-dialog.styles";

interface IProps {
  getMe: IGetMe;
  channel: any;
  guestId: string;
}

export const ChargeDialog: FC<IProps> = ({ getMe, channel, guestId }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState<string>(
    moment().add(1, "d").format(moment.HTML5_FMT.DATETIME_LOCAL)
  );
  const [description, setDescription] = useState(
    "What dishes?\n\nHow many orders?\n\nOther info?\n\n"
  );
  const [price, setPrice] = useState(50);

  const [create, { data }] = useMutation(COOK_CREATE_SPECIAL_EVENT);
  const classes = useStyles();

  useEffect(() => {
    if (data?.cookCreateSpecialEvent) {
      channel
        .sendMessage({
          text: `Here is an invoice for $${price}.
            You can pay and find more detail here 👉 ${data?.cookCreateSpecialEvent.url}.`,
        })
        .then(() => {
          sharedAPI.setSnackbarMsg({ type: "success", msg: "Invoice sent" });
          setLoading(false);
          setOpen(false);
        });
    }
  }, [data]);

  if (getMe.restaurant?.application?.status !== AppStatus.APPROVED) return null;
  return (
    <>
      {/* <button className={classes.chargeBtn} onClick={() => setOpen(true)}>
        Request $
      </button> */}
      <Dialog open={open}>
        <IconButton
          style={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}
          onClick={() => setOpen(false)}
          data-testid="close-auth-dialog-button"
          aria-label="close"
        >
          <Close style={{ color: COLORS.MEDIUM_GREEN }} />
        </IconButton>
        <div className={classes.container}>
          <>
            <TextField
              value={price}
              label="$ to charge"
              inputProps={{ min: "5", max: "1000", step: "5" }}
              required
              type="number"
              onChange={(e) => setPrice(Number(e.target.value))}
            />
            <TextField
              value={description}
              required
              rows="6"
              label="Description"
              multiline
              inputProps={{
                type: "text",
                maxLength: 200,
                pattern: "[^()]+",
                title: "alpha numeric values only, no parentheses.",
              }}
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
              value={startTime}
              label="Pickup or delivery time"
              required
              type="datetime-local"
              onChange={(e) =>
                setStartTime(
                  moment(e.target.value).format(moment.HTML5_FMT.DATETIME_LOCAL)
                )
              }
            />
            <Button
              variant="contained"
              color="secondary"
              disabled={loading || !price || !description}
              className={classes.createBtn}
              onClick={() => {
                setLoading(true);
                create({
                  variables: {
                    input: {
                      guestId,
                      description,
                      price,
                      startTime: moment(startTime).valueOf().toString(),
                    },
                  },
                }).catch(() =>
                  sharedAPI.setSnackbarMsg({
                    type: "error",
                    msg: "Sorry, let's try again",
                  })
                );
              }}
            >
              {loading ? <CircularProgress size={16} /> : "Create Invoice"}
            </Button>
          </>
        </div>
      </Dialog>
    </>
  );
};
