import { useQuery } from "@apollo/client";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CreateApplicationStatusPL,
  IApplication,
  IGetMe,
  IPagePL,
} from "src/models";
import { useDelay } from "src/utils/hooks";
import { applicationAPI } from "../api";
import { GET_APPLICATIONS } from "../api/graphql";
import { Layout } from "./layout";

interface IProps {
  getMe: IGetMe;
}

export interface IFetchMorePL {
  pagePL?: IPagePL;
  status?: string;
  searchTerm?: string;
  county?: string;
  assignee?: string;
  description?: string;
}

export const ApplicationListContainer: FC<IProps> = ({ getMe }) => {
  const { data, loading, error, fetchMore } = useQuery<{
    getApplications: { count: number; rows: IApplication[] };
  }>(GET_APPLICATIONS, {
    variables: {
      input: {
        pagePL: {
          offset: undefined,
          limit: 30,
        },
      },
    },
  });

  // Hooks
  const history = useHistory();
  const [offset, setOffset] = useState<any>(undefined);
  const [status, setStatus] = useState<string>("ALL");
  const [assignee, setAssignee] = useState<string>("ALL");
  const [county, setCounty] = useState<string>("ALL");
  const [searchTerm, setSearchTerm] = useState("");
  const [descriptionSearchTerm, setDescriptionSearchTerm] = useState("");
  const [resetPage, setPageStatus] = useState<boolean>(false);

  const debouncedSearchTerm = useDelay(searchTerm, 300);
  const debouncedDescriptionSearchTerm = useDelay(descriptionSearchTerm, 300);

  // handler
  const redirect = (d: string) => history.push(d);

  useEffect(() => {
    _fetchMore({
      pagePL: { limit: 30 },
      county: county === "ALL" ? undefined : county,
      status: status === "ALL" ? undefined : status,
      assignee: assignee === "ALL" ? undefined : assignee,
      searchTerm: debouncedSearchTerm ? debouncedSearchTerm : undefined,
      description: descriptionSearchTerm ?? undefined,
    });
  }, [debouncedSearchTerm, debouncedDescriptionSearchTerm]);

  const _createApplicationStatus = async (d: CreateApplicationStatusPL) =>
    applicationAPI
      .createApplicationStatus({
        ...d,
      })
      .then(() =>
        _fetchMore({
          pagePL: { limit: 30, offset: offset ? offset : undefined },
          county: county === "ALL" ? undefined : county,
          status: status === "ALL" ? undefined : status,
          assignee: assignee === "ALL" ? undefined : assignee,
          searchTerm: debouncedSearchTerm ? debouncedSearchTerm : undefined,
          description: descriptionSearchTerm ?? undefined,
        })
      );

  const _deleteApplicationStatus = async (d: number | string) =>
    applicationAPI.deleteAppStatus(d).then(() =>
      _fetchMore({
        pagePL: { limit: 30, offset: offset ? offset : undefined },
        county: county === "ALL" ? undefined : county,
        status: status === "ALL" ? undefined : status,
        assignee: assignee === "ALL" ? undefined : assignee,
        searchTerm: debouncedSearchTerm ? debouncedSearchTerm : undefined,
        description: descriptionSearchTerm ?? undefined,
      })
    );

  // On select the drop down will trigger refetch
  const _setAssignee = async (d: string) => {
    await setPageStatus(true);
    setAssignee(d);
    _fetchMore({
      pagePL: { limit: 30, offset: undefined },
      county: county === "ALL" ? undefined : county,
      status: status === "ALL" ? undefined : status,
      assignee: d === "ALL" ? undefined : d,
      searchTerm: debouncedSearchTerm ? debouncedSearchTerm : undefined,
      description: descriptionSearchTerm ?? undefined,
    });
  };

  // On select the drop down will trigger refetch
  const _setStatus = async (d: string) => {
    await setPageStatus(true);
    setStatus(d);
    _fetchMore({
      pagePL: { limit: 30, offset: undefined },
      county: county === "ALL" ? undefined : county,
      status: d === "ALL" ? undefined : d,
      assignee: assignee === "ALL" ? undefined : assignee,
      searchTerm: debouncedSearchTerm ? debouncedSearchTerm : undefined,
      description: descriptionSearchTerm ?? undefined,
    });
  };

  const _setCounty = async (d: string) => {
    await setPageStatus(true);
    setCounty(d);
    _fetchMore({
      pagePL: { limit: 30, offset: undefined },
      status: status === "ALL" ? undefined : status,
      county: d === "ALL" ? undefined : d,
      assignee: assignee === "ALL" ? undefined : assignee,
      searchTerm: debouncedSearchTerm ? debouncedSearchTerm : undefined,
      description: descriptionSearchTerm ?? undefined,
    });
  };

  const _fetchMore = (d: IFetchMorePL) => {
    setPageStatus(false);
    if (d.pagePL && (d.pagePL.offset || d.pagePL.offset === 0))
      setOffset(d.pagePL.offset);

    return fetchMore({
      variables: {
        input: d,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          getApplications: (fetchMoreResult as any).getApplications,
        });
      },
    });
  };

  if (!data?.getApplications) return null;

  return (
    <Layout
      getMe={getMe}
      redirect={redirect}
      applications={data?.getApplications.rows}
      totalCount={data?.getApplications.count}
      fetchMore={_fetchMore}
      loading={loading}
      error={error}
      status={status}
      county={county}
      assignee={assignee}
      setStatus={_setStatus}
      setCounty={_setCounty}
      setAssignee={_setAssignee}
      onSearch={setSearchTerm}
      descriptionSearchTerm={descriptionSearchTerm}
      setDescriptionSearchTerm={setDescriptionSearchTerm}
      resetPage={resetPage}
      createApplicationStatus={_createApplicationStatus}
      deleteApplicationStatus={_deleteApplicationStatus}
    />
  );
};
