import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useStyles } from "./header.styles";

// tslint:disable-next-line:no-empty-interface
interface IProps {}

export const Header: FC<IProps> = () => {
  const classes = useStyles();
  return (
    <div className={classes.headerContainer}>
      <div className={classes.headerInner}>
        <div className={classes.headerLeft}>
          <Typography variant="h1" component="h1" className={classes.title}>
            Our Mission
          </Typography>
          <div className={classes.textContainer}>
            <Typography variant="h4" component="p" className={classes.text}>
              COOK Connect is part of COOK Alliance, the nonprofit working to
              legitimize and support home cooking businesses. We believe
              culinary entrepreneurship and good food should be accessible to
              all.
            </Typography>
          </div>
          <div className={classes.watchContainer}>
            <img
              src={require("../assets/squiggly_arrow.svg")}
              alt="arrow"
              className={classes.image}
            />
          </div>
        </div>
        <div className={classes.headerRight}>
          <div className={classes.imageContainer}>
            <img
              src={require("../assets/cook.jpg")}
              className={classes.image_main}
            ></img>
          </div>
          {/* Replaced videoContainer with imageContainer for cook transition */}
          {/* <div className={classes.videoContainer}>
            <video
              controls={true}
              className={classes.video}
              poster={require("../assets/poster.png")}
            >
              <source
                src={
                  "https://foodnome.sfo2.digitaloceanspaces.com/videos/FOODNOME_Character%20Reel_050321.mp4"
                }
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div> */}
        </div>
      </div>
    </div>
  );
};
