import {
  Button,
  Checkbox,
  FormControlLabel,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { Add, AttachMoney, Check } from "@material-ui/icons";
import React, { useEffect } from "react";
import { IDish } from "src/models";
import { COLORS } from "src/styles";
import { constants } from "src/utils/constants";
import { useStyles } from "./styles";

interface IProps {
  isFixedTimeEvent: boolean;
  dish: IDish;
  maxOrders?: string;
  price?: string;
  selected?: boolean;
  onInputChange: (event: { target: { value: string; name: string } }) => void;
  onSelectDish: () => void;
  onClickRemove: () => void;
  isPreviouslySelected: boolean;
}

export const EventFormDishCard: React.FC<IProps> = ({
  isFixedTimeEvent,
  dish,
  maxOrders,
  price,
  selected,
  onInputChange,
  onSelectDish,
  onClickRemove,
  isPreviouslySelected,
}) => {
  const classes = useStyles();
  const maxOrdersInputRef = React.useRef<any>();

  useEffect(() => {
    if (selected)
      maxOrdersInputRef.current && maxOrdersInputRef.current.focus();
  }, [selected]);

  const imageSrc = dish.imagesGQL?.length
    ? dish.imagesGQL[0].small
    : constants.BEETS;

  const toggleCheckbox = onSelectDish;
  const maxOrdersInputValue =
    maxOrders === null || maxOrders === undefined ? "" : maxOrders;
  const priceInputValue = price === null || price === undefined ? "" : price;

  return (
    <fieldset
      style={{ backgroundColor: selected ? COLORS.WHITE : "" }}
      className={classes.fieldset}
      name={dish.name}
      data-testid={`event-form-dish-card-${dish.id}`}
      onClick={toggleCheckbox}
    >
      <div style={{ borderBottom: "1px solid #CCCCCC", padding: "1rem 0" }}>
        <FormControlLabel
          classes={{
            root: classes.rootCheckboxLabel,
            label: classes.checkboxLabel,
          }}
          control={
            <Checkbox
              id={`select-input-${dish.id}`}
              style={{
                backgroundColor: selected ? COLORS.WHITE : COLORS.MEDIUM_GREEN,
              }}
              classes={{ colorSecondary: classes.checkboxRoot }}
              checked={selected}
              value="isSelected"
              checkedIcon={<Check style={{ color: COLORS.MEDIUM_GREEN }} />}
              icon={<Add style={{ color: COLORS.WHITE }} />}
            />
          }
          label={dish.name}
          htmlFor={`select-input-${dish.id}`}
          onClick={(e) => {
            e.preventDefault(); // prevent default input focus bahavior
            toggleCheckbox();
            e.stopPropagation(); // stop event bubbling
          }}
        />
      </div>
      {/* legend left for a11y purposes */}
      <legend className="hidden-label">{dish.name}</legend>
      {/* TODO: if !isFixedTimeEvent && isSelected then show additional fields */}
      {!isFixedTimeEvent && selected ? (
        <div
          className={classes.togoFieldsWrapper}
          onClick={(e) => e.stopPropagation()}
        >
          <Typography
            component="label"
            variant="caption"
            className={classes.inputLabel}
            htmlFor={`max-orders-input-${dish.id}`}
          >
            Availability:{" "}
            <OutlinedInput
              inputRef={maxOrdersInputRef}
              required
              value={maxOrdersInputValue}
              id={`max-orders-input-${dish.id}`}
              name="count"
              inputProps={{ min: "1", step: "1" }}
              type="number"
              labelWidth={0}
              onChange={onInputChange}
              classes={{
                root: classes.inputRoot,
                notchedOutline: classes.notchedOutline,
                input: classes.input,
              }}
            />
          </Typography>

          <Typography
            component="label"
            variant="caption"
            className={classes.inputLabel}
            htmlFor={`price-input-${dish.id}`}
          >
            Price per order:{" "}
            <OutlinedInput
              disabled={isPreviouslySelected}
              required
              value={priceInputValue}
              id={`price-input-${dish.id}`}
              name="price"
              inputProps={{ min: "0", step: "0.01" }}
              type="number"
              labelWidth={0}
              onChange={onInputChange}
              classes={{
                root: classes.inputRoot,
                notchedOutline: classes.notchedOutline,
                input: classes.input,
              }}
              startAdornment={
                <AttachMoney style={{ color: COLORS.DARK_GREY }} />
              }
            />
          </Typography>

          <Button className={classes.removeButton} onClick={onClickRemove}>
            Remove
          </Button>
        </div>
      ) : (
        <img className={classes.img} src={imageSrc} alt={dish.name} />
      )}
    </fieldset>
  );
};
