import { makeStyles } from "@material-ui/core/styles";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useNonAuthDrawerStyles = makeStyles((theme) => ({
  email: {
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    flexGrow: 1,
    textDecoration: "none",
    fontWeight: 500,
    textTransform: "uppercase",
  },
  backdrop: {
    backgroundColor: "rgba(255, 255, 255, 0.4)",
  },
  menuList: {
    display: "flex",
    flexDirection: "column",
    padding: "0 3rem",
    flexGrow: 1,
  },
  menuListSecondary: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: 120,
    maxHeight: 160,
    alignItems: "flex-start",
  },
  menuItemWithButtons: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    padding: 0,
    justifyContent: "space-evenly",
    flexGrow: 1,
    flexShrink: 0,
    minHeight: 110,
    "&:hover": {
      background: "transparent",
    },
  },
  menuButton: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    boxShadow: "none",
  },
  unorderedList: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    listStyleType: "none",
    listStyle: "none",
    padding: 0,
  },
  menuListSecondaryWrapper: {
    padding: "1rem 3rem",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    minHeight: 175,
  },
  menuItemSecondary: {
    height: 35,
    "&:hover": {
      backgroundColor: "transparent",
    },
    backgroundColor: "transparent",
  },
  paper: {
    width: "90%",
    maxWidth: 375,
    minWidth: 300,
  },
  closeButton: {
    position: "absolute",
    top: 20,
    right: 20,
    padding: 0,
    boxShadow: `0 1px 7px 0 rgba(0,0,0,0.17)`,
    backgroundColor: COLORS.WHITE,
  },
  closeIcon: { color: COLORS.MEDIUM_RED },
  innerDrawer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
    flexShrink: 0,
    position: "relative",
  },
  link: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    textDecoration: "none",
    color: COLORS.BLACK,
    textTransform: "uppercase",
    width: "100%",
    "&:hover": {
      color: COLORS.MEDIUM_RED,
    },
  },
  activeLink: {
    color: COLORS.MEDIUM_RED,
    transition: "color 250ms linear",
  },
  menuItem: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: "1rem",
    minHeight: 0,
    "&:hover": {
      background: COLORS.WHITE,
    },
  },
  icon: {
    verticalAlign: "middle",
    marginRight: ".5rem",
  },
  socialMediaIcon: {
    verticalAlign: "middle",
    ...theme.typography.body1,
  },
  socialMediaItem: {
    background: COLORS.WHITE,
    padding: 0,
    margin: 0,
    width: 30,
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",

    boxShadow: `0 1px 7px 0 rgba(0,0,0,0.26)`,
  },
  socialMediaAnchor: {
    color: COLORS.VIOLET,

    textDecoration: "none",
    display: "inherit",
  },
  textLogoRed: {
    width: 178,
    height: 32,
    margin: "1rem 3rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: 130,
    },
  },
}));
