import React, { FC } from "react";
import { Footer, Header, Legalisation } from "../";
import { companyValues } from "../helpers";
import { Value } from "./value";

export const MobileValues: FC = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Header />
      <Legalisation/>
      <div style={{ marginTop: 60 }}>
        {companyValues.map((value, idx) => (
          <Value key={idx} value={value} />
        ))}
      </div>
      <Footer />
    </div>
  );
};
