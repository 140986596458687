export enum ChatActionTypes {
  SET_LOADING_STATUS = "SET_LOADING_STATUS",
  SET_DRAWER_STATUS = "SET_DRAWER_STATUS",
  SET_UNREAD_COUNT = "SET_UNREAD_COUNT",
  SET_ACTIVE_CHANNEL = "SET_ACTIVE_CHANNEL",
}

interface ISetUnreadCount {
  type: ChatActionTypes.SET_UNREAD_COUNT;
  payload?: number;
}

interface ISetActiveChannel {
  type: ChatActionTypes.SET_ACTIVE_CHANNEL;
  payload?: any;
}

interface ISetLoadingStatus {
  type: ChatActionTypes.SET_LOADING_STATUS;
  payload: boolean;
}

interface ISetDrawerStatus {
  type: ChatActionTypes.SET_DRAWER_STATUS;
}

export type ComponentActions =
  | ISetActiveChannel
  | ISetUnreadCount
  | ISetDrawerStatus
  | ISetLoadingStatus;

export const chatReducer: React.Reducer<IState, ComponentActions> = (
  state,
  action
) => {
  const title = document.title.includes(") ")
    ? document.title.split(") ")[1]
    : document.title;

  switch (action.type) {
    case ChatActionTypes.SET_UNREAD_COUNT:
      document.title = action.payload ? `(${action.payload}) ` + title : title;
      return {
        ...state,
        unreadCount: action.payload ?? 0,
      };

    case ChatActionTypes.SET_ACTIVE_CHANNEL:
      return {
        ...state,
        activeChannel: action.payload,
      };

    case ChatActionTypes.SET_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload,
      };

    case ChatActionTypes.SET_DRAWER_STATUS:
      return {
        ...state,
        drawerStatus: !state.drawerStatus,
      };

    default:
      return state;
  }
};
export const initialState: IState = {
  loading: true,
  drawerStatus: false,
  unreadCount: 0,
  activeChannel: undefined,
};

export interface IState {
  loading: boolean;
  drawerStatus: boolean;
  unreadCount: number;
  activeChannel?: any;
}
