import { useQuery } from "@apollo/client";
import React from "react";
import { ErrorLoadingComponent } from "src/components";
import { ADMIN_GET_RESTAURANT } from "../api/graphql";
import { Layout } from "./layout";

export const RestaurantDetailContainer = ({ match }) => {
  const { data, loading, error, refetch } = useQuery(ADMIN_GET_RESTAURANT, {
    errorPolicy: "ignore",
    fetchPolicy: "no-cache",
    variables: { input: { id: match.params.id } },
  });

  if (loading || error || !data) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }
  return <Layout restaurant={data.adminGetRestaurant} refetch={refetch} />;
};
