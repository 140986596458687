import { Button, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { noItemsStyles as useStyles } from "./no-items.styles";

interface IProps {
  title: string;
  subText: string;
  buttonText: string;
  link: string;
}

export const NoItemsContainer: FC<IProps> = ({
  title,
  subText,
  buttonText,
  link,
}) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img
          className={classes.image}
          src={require("./assets/nodish.png")}
          alt="No Events"
        />
      </div>
      <div className={classes.createContainer}>
        <Typography variant="h4" component="h1" className={classes.titleText}>
          {title}
        </Typography>
        <Typography variant="body2" component="p" className={classes.subText}>
          {subText}
        </Typography>
        <Button
          onClick={() => history.push(link)}
          classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
        >
          <Add className={classes.icon} />
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
