import { Avatar, Typography } from "@material-ui/core";
import {
  CalendarToday,
  LocationOn,
  Restaurant,
  Star,
} from "@material-ui/icons";
import classNames from "classnames";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { IGetMe, IRestaurant } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { eventTypeMap } from "src/utils/helpers";
import { ListMapContext } from "../../container";
import { ActionTypes } from "../../reducer";
import { AvailableMenus } from "../components/available-menus";
import { useStyles } from "./restaurant-card.styles";
import { SwipeableImages } from "./swipeable-img";

interface IProps {
  restaurant: IRestaurant;
  getMe?: IGetMe;
}
export const RestaurantCard: FC<IProps> = React.memo(
  ({ restaurant, getMe }) => {
    const classes = useStyles();
    const {
      state: { hoveredRestaurant, clickedRestaurant },
      dispatch,
    } = useContext(ListMapContext);

    const [enableScrollIntoView, setEnableScrollIntoView] = useState(true);
    const cardRef = useRef<HTMLLIElement>(null);

    const [isHovered, setIsHovered] = useState<boolean>(false);

    useEffect(() => {
      if (
        hoveredRestaurant?.id === restaurant.id &&
        cardRef.current &&
        enableScrollIntoView
      ) {
        cardRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }, [hoveredRestaurant]);

    const avatarProps = Object.assign(
      { alt: restaurant.host.firstName },
      restaurant.host.picture
        ? { src: restaurant.host.picture }
        : {
            children: `${restaurant.host.firstName[0]}${restaurant.host.lastName[0]}`,
          }
    ) as any;

    const availTypes = [
      ...(new Set(restaurant.events.map((event) => event.type).flat()) as any),
    ];

    return (
      <li
        id={restaurant.id}
        className={classNames(
          classes.flexColumn,
          classes.card,
          hoveredRestaurant?.id === restaurant.id ||
            clickedRestaurant?.id === restaurant.id
            ? classes.cardHover
            : {}
        )}
        ref={cardRef}
        onMouseEnter={() => {
          setEnableScrollIntoView(false);
          setIsHovered(true);
          dispatch({
            type: ActionTypes.SET_HOVERED_RESTAURANT,
            payload: restaurant,
          });
        }}
        onMouseLeave={() => {
          setEnableScrollIntoView(true);
          setIsHovered(false);
          dispatch({
            type: ActionTypes.SET_HOVERED_RESTAURANT,
            payload: undefined,
          });
        }}
        onClick={(e) => {
          if (restaurant.fromCSV) {
            return;
          }
          if (restaurant.id !== clickedRestaurant?.id) {
            dispatch({
              type: ActionTypes.SET_CLICKEDED_RESTAURANT,
              payload: restaurant,
            });
          }
          e.stopPropagation();
          sharedAPI.setDialogRestaurantName(restaurant?.name);
        }}
      >
        <section className={classNames(classes.container, classes.flexRow)}>
          <div className={classes.flexColumn} style={{ flexGrow: 1 }}>
            {/* Name/Title */}
            <section
              className={classes.flexRow}
              style={{ marginBottom: "1rem" }}
            >
              <Typography
                variant="subtitle1"
                component="h3"
                className={classes.title}
              >
                {restaurant.name}
              </Typography>
            </section>

            {/* Avatar and Info */}
            <section className={classes.flexRow}>
              <div className={classes.flexColumn}>
                <Avatar
                  className={
                    restaurant.fromCSV ? classes.avatarCSV : classes.avatar
                  }
                  {...avatarProps}
                />
              </div>
              <div className={classes.flexColumn}>
                {restaurant.overallReview && (
                  <div className={classes.flexRow}>
                    <Star style={{ fontSize: "2rem", color: COLORS.YELLOW }} />
                    <Typography variant="body2" component="p">
                      <span className={classes.reviewStars}>
                        {`${restaurant.overallReview.stars}/5`}
                      </span>
                      ({restaurant.overallReview.count})
                    </Typography>
                  </div>
                )}

                {/* Tags and locations */}

                <div
                  className={classNames(
                    classes.flexRow,
                    classes.locationContainer
                  )}
                >
                  {restaurant.tags && restaurant.tags.length > 0 && (
                    <>
                      <Restaurant className={classes.icon} />
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.tags}
                      >
                        {restaurant.tags.join(", ")}
                      </Typography>
                    </>
                  )}
                </div>
                <div className={classes.flexRow}>
                  <LocationOn className={classes.icon} />
                  <Typography variant="body2" component="p">
                    {restaurant.city} {restaurant.state}
                  </Typography>
                </div>
              </div>
            </section>

            <section className={classes.flexRow} style={{ marginTop: "auto" }}>
              <CalendarToday className={classes.icon} />
              <Typography
                variant="h6"
                component="h6"
                className={classes.typesContainer}
              >
                {availTypes.length ? (
                  <>
                    Avail. for{" "}
                    {availTypes
                      .map((type) => (
                        <span
                          style={{
                            color: "#5708A8",
                            fontFamily: "CustomFourBold",
                          }}
                        >
                          {eventTypeMap[type]}
                        </span>
                      ))
                      .reduce(
                        (acc: any, x: any) =>
                          acc === null ? (
                            x
                          ) : (
                            <>
                              {acc} & {x}
                            </>
                          ),
                        null
                      )}
                    :
                  </>
                ) : (
                  "No avail. menus"
                )}
              </Typography>
            </section>
          </div>

          <section
            className={classes.flexColumn}
            style={{ position: "relative" }}
          >
            <SwipeableImages isHovered={isHovered} restaurant={restaurant} />
          </section>
        </section>

        <AvailableMenus restaurant={restaurant} getMe={getMe} />
      </li>
    );
  }
);
