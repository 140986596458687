import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Fade } from "react-awesome-reveal";
import { HybridLink } from "src/components";
import { useStyles } from "./press.styles";

interface IProps {
  showCucumber?: boolean;
}

export const PressSection: FC<IProps> = ({ showCucumber = true }) => {
  const classes = useStyles();
  return (
    <section className={classes.container}>
      <div className={classes.innerContainer}>
        {showCucumber && (
          <img
            src={require("../../assets/illustrations/cucumber-05.svg")}
            className={classes.cucumber}
          />
        )}
        <Fade triggerOnce={true}>
          <div className={classes.headerContainer}>
            <Typography variant="h2" component="h2" className={classes.title}>
              As Seen On
            </Typography>
            <div className={classes.squiggleContainer}>
              <img
                src={require("../../assets/underline.svg")}
                className={classes.squiggle}
              />
            </div>
          </div>
        </Fade>
        <div className={classes.linkContainer}>
          <Fade triggerOnce={true} delay={250} className={classes.link}>
            <HybridLink
              href={
                "https://www.nytimes.com/2018/09/21/us/california-today-home-cook-food-law.html"
              }
            >
              <img
                className={classes.nytimes}
                src={require("../../assets/press/nyt_multiline.png")}
                alt="nytimes"
              />
            </HybridLink>
          </Fade>
          <Fade triggerOnce={true} delay={350} className={classes.link}>
            <HybridLink
              href={
                "https://www.sfchronicle.com/food/article/Inside-one-of-the-Bay-Area-s-first-legal-home-16459655.php"
              }
            >
              <img
                className={classes.spectrum}
                src={require("../../assets/press/sfchroniclelogo.png")}
                alt="SF Chronicle"
              />
            </HybridLink>
          </Fade>
          <Fade triggerOnce={true} delay={450} className={classes.link}>
            <HybridLink href={"https://www.sandiegouniontribune.com/local/story/2023-11-25/from-just-a-dream-to-diy-restaurateurs-these-chefs-are-feeding-san-diegans-from-home-kitchens-under-a-just-expanded-program"}>
              <img
                className={classes.spectrum}
                src={require("../../assets/press/ut_logo.png")}
                alt="sdtribune"
              />
            </HybridLink>
          </Fade>
          <Fade triggerOnce={true} delay={550} className={classes.link}>
            <HybridLink
              href={
                "https://www.wsj.com/articles/in-the-covid-economy-laid-off-employees-become-new-entrepreneurs-11605716565?reflink=desktopwebshare_permalink"
              }
            >
              <img
                className={classes.wsj}
                src={require("../../assets/press/old/wsj.png")}
                alt="wsj"
              />
            </HybridLink>
          </Fade>
          <Fade triggerOnce={true} delay={650} className={classes.link}>
            <HybridLink
              href={
                "https://www.npr.org/sections/thesalt/2019/01/17/685626391/selling-food-from-your-kitchen-is-legal-in-california-but-theres-a-catch?utm_source=facebook.com&utm_medium=social&utm_campaign=npr&utm_term=nprnews&utm_content=20190117&fbclid=IwAR0zA4cMyg3Fe3kK6wGj8P76ypjOGsKaaamZeP4cxxE_RbeRxTeZhV6qEVk"
              }
            >
              <img
                className={classes.npr}
                src={require("../../assets/press/old/npr.png")}
                alt="npr"
              />
            </HybridLink>
          </Fade>
          <Fade triggerOnce={true} delay={750} className={classes.link}>
            <HybridLink
              href={
                "https://civileats.com/2020/06/30/its-now-legal-for-home-chefs-in-california-to-sell-meals-will-more-cities-get-on-board/"
              }
            >
              <img
                className={classes.civilEats}
                src={require("../../assets/press/civil_eats.png")}
                alt="civil eats"
              />
            </HybridLink>
          </Fade>
        </div>
        {/* <Fade triggerOnce={true} delay={1000}>
          <Link className={classes.seeMoreLink} to={"/press"}>
            <Typography component="p" variant="body1">
              See more
            </Typography>
          </Link>
        </Fade> */}
      </div>
    </section>
  );
};
