import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  // Dishes
  dishList: {
    marginTop: "1rem",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(210px, 1fr))",
    listStyleType: "none",
    padding: 0,
    gridGap: "1rem",
  },
  dishImage: {
    height: 200,
    width: "100%",
    objectFit: "cover",
  },
  dishDescription: {},
  dishName: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    margin: "0.5rem 0",

    color: COLORS.MEDIUM_GREEN,
  },
  dishHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 4,
    alignItems: "center",
  },
  availableOn: {
    fontFamily: "CustomFourBold",
    margin: "6px 0px",
    display: "flex",
    flexDirection: "column",
  },
  dishChip: {
    height: 24,
    width: 80,
    margin: "0px 4px 4px 4px",
  },
  cateringBtn: {
    padding: "6px 10px",
    cursor: "pointer",
    color: "white",
    height: 30,
    fontFamily: "CustomFourBold",
    backgroundColor: COLORS.RED,
    border: `none`,
  },
  requestBtn: {
    padding: "1rem",
    cursor: "pointer",
    color: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
    backgroundColor: "unset",
    border: `1px solid ${COLORS.MEDIUM_GREEN}`,
  },
  dishesExpansionButton: {
    border: "1px solid #858486",
    borderRadius: 2,
    color: COLORS.BLACK,
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    textTransform: "capitalize",
    minWidth: 120,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:disabled": {
      border: "1px solid #858486",
      borderRadius: 2,
      color: COLORS.BLACK,
    },
  },

  // Shared
  starsContainer: {
    display: "flex",
    margin: "1rem 0",
    cursor: "pointer",
  },
  reviewAmount: {
    alignSelf: "center",
    margin: "0 0.5rem",
    flexGrow: 1,

    color: COLORS.DARK_GREY,
  },
  subheading: {
    margin: "0.5rem 0",
    fontFamily: "CustomFourBold",
  },
  // Grid Layout
  gridLayout: {
    display: "grid",
    gridTemplateRows: "auto 60px 1fr",
    gridTemplateColumns: "1fr",
  },
  gridLayoutHero: {
    gridRow: "1 / 3",
    gridColumn: 1,
  },
  gridLayoutHeaderWidgets: {
    gridRow: "2 / -1",
    gridColumn: 1,
    backgroundColor: "transparent",
    maxWidth: 1053,
    margin: "0 auto",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      gridRow: "3 / -1",
    },
  },
});
