import { gql, useMutation } from "@apollo/client";
import { useEffect } from "react";
import { currentBuild } from "src/index";
import { IGetMe } from "src/models";

export const useAppDetection = (getMe?: IGetMe) => {
  const [update] = useMutation(USER_LOGIN_WITH_APP);

  useEffect(() => {
    if (
      getMe &&
      Boolean(
        !getMe.hasApp ||
          getMe.hasApp === "true" ||
          getMe.hasApp === "false" ||
          getMe.hasApp !==
            `${(window as any)?.cordova?.platformId}-${currentBuild}`
      ) &&
      (window as any).cordova?.platformId
    ) {
      update({
        variables: {
          input: `${(window as any)?.cordova?.platformId}-${currentBuild}`,
        },
      });
    }
  }, [getMe?.id]);
};

export const USER_LOGIN_WITH_APP = gql`
  mutation userLoginWithApp($input: String) {
    userLoginWithApp(app: $input) {
      id
      hasApp
    }
  }
`;
