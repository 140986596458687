import { useEffect, useState } from "react";
import { matchPath } from "react-router-dom";

const routes = [
  {
    options: {
      path: "/menus",
      exact: true,
      strict: false,
    },
    value: "search",
  },
  {
    options: {
      path: "/d",
      exact: true,
      strict: false,
    },
    value: "orders",
  },
  {
    options: {
      path: "/c/dishes",
      exact: true,
      strict: false,
    },
    value: "dishes",
  },
  {
    options: {
      path: "/c/menus",
      exact: true,
      strict: false,
    },
    value: "menus",
  },
  {
    options: {
      path: "/notifications",
      exact: true,
      strict: false,
    },
    value: "alerts",
  },
  {
    options: {
      path: "/c/home",
      exact: true,
      strict: false,
    },
    value: "home",
  },
  {
    options: {
      path: "/c/menus",
      exact: true,
      strict: false,
    },
    value: "menus",
  },
];

export const useBottomNavRoutes = (pathname: string): string => {
  const [activeTab, setActiveTab] = useState<string>("");
  useEffect(() => {
    const match = routes.find(({ options }) => !!matchPath(pathname, options));
    if (match) {
      setActiveTab(match.value);
    } else {
      setActiveTab(""); // reset state
    }
  }, [pathname]);
  return activeTab;
};
