import { makeStyles } from "@material-ui/core";
import React from "react";

export const Landscape = () => {
  const classes = useStyles();
  return (
    <section className={classes.container}>
      <img
        className={classes.landscapeImg}
        src={require("src/screens/how-it-works/assets/foodnome-scape.png")}
        alt="Landscape Foodnome Image"
      />
    </section>
  );
};

const useStyles = makeStyles({
  container: {
    width: "100%",
  },
  landscapeImg: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
});
