import React from "react";
import { IGetMe, IRestaurantReview } from "src/models";
import { ReviewForm } from "./review-form";

interface IProps {
  review?: IRestaurantReview;
  getMe: IGetMe;
}

export const Layout: React.FC<IProps> = ({ review, getMe }) => {
  return <ReviewForm review={review} user={getMe} />;
};
