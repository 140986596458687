import { Button, List, ListItem, Paper, Typography } from "@material-ui/core";
import { parse, stringify } from "query-string";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  AuthWithApple,
  AuthWithFacebook,
  AuthWithGoogle,
} from "src/components";
import {
  AppleBtn,
  DecoratedText,
  FacebookBtn,
  GoogleBtn,
} from "src/components/shared";
import { amplitude } from "src/services";
import { SignUpForm } from "./form";
import { useStyles } from "./sign-up-flow.styles";

interface IProps {
  onSubmit?: (d: any) => void;
  switchView?: () => void;
}

export const SignUpFlow: React.FC<IProps> = ({ switchView, onSubmit }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [referralCode, setReferralCode] = useState<any>("");
  const [thirdPartyAuthError, setThirdPartyAuthError] = useState<any>(null);
  const [isSignUpView, setSignUpView] = useState<boolean>(() => {
    if (location.search) {
      const { method } = parse(location.search);
      if (typeof method === "string") return method.toLowerCase() === "email";
    }
    return false;
  });

  useEffect(() => {
    const { method, code } = parse(location.search);
    setReferralCode(code);
    if (typeof method === "string") {
      if (method.toLowerCase() === "email") {
        setSignUpView(true);
        return;
      }
    }
    setSignUpView(false);
    // We want the user to be able to see the login with Facebook/Google and referral code on cordova
    // if (
    //   !document.URL.startsWith("http") &&
    //   process.env.REACT_APP_ENV === "cordova"
    // ) {
    //   history.push({
    //     pathname: location.pathname,
    //     search: stringify({ method: "email", code: referralCode }),
    //   });
    //   setSignUpView(true);
    // }
  }, [location.search]);

  return (
    <Paper className={classes.container}>
      <img
        className={classes.logo}
        src={require(`src/assets/logos/cook.svg`)}
      />
      <Typography variant="h4" component="h4" className={classes.mainHeading}>
        Sign up with COOK Connect!
      </Typography>
      <Typography variant="body1" component="p" className={classes.subHeading}>
        Find delicious food made by talented, permitted cooks in your
        neighborhood.
      </Typography>
      {isSignUpView ? (
        <SignUpForm
          redirect={history.push}
          onSubmit={onSubmit}
          switchView={switchView}
        />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: "1rem",
            }}
          >
            <Button
              fullWidth
              size="large"
              variant="contained"
              classes={{
                contained: classes.emailContained,
                label: classes.emailLabel,
              }}
              onClick={() => {
                amplitude.getInstance().logEvent("[Signup Flow] email signup");
                history.push({
                  pathname: location.pathname,
                  search: stringify({ method: "email", code: referralCode }),
                });
              }}
            >
              Continue with email
            </Button>
          </div>
          <DecoratedText>or</DecoratedText>
          <List className={classes.list}>
            <AuthWithGoogle
              onSubmit={onSubmit}
              onError={(err) => setThirdPartyAuthError(err)}
            >
              <ListItem classes={{ gutters: classes.listItemGutters }}>
                <GoogleBtn
                  onClick={() =>
                    amplitude
                      .getInstance()
                      .logEvent("[Signup Flow] thrid party")
                  }
                  text="Continue with Google"
                />
              </ListItem>
            </AuthWithGoogle>
            <AuthWithFacebook
              onSubmit={onSubmit}
              onError={(err) => setThirdPartyAuthError(err)}
            >
              <ListItem classes={{ gutters: classes.listItemGutters }}>
                <FacebookBtn
                  text="Continue with Facebook"
                  onClick={() =>
                    amplitude
                      .getInstance()
                      .logEvent("[Signup Flow] thrid party")
                  }
                />
              </ListItem>
            </AuthWithFacebook>
            <AuthWithApple
              onSubmit={onSubmit}
              onError={(msg) => setThirdPartyAuthError(msg)}
            >
              <ListItem classes={{ gutters: classes.listItemGutters }}>
                <AppleBtn
                  text="Continue with Apple"
                  onClick={() =>
                    amplitude
                      .getInstance()
                      .logEvent("[Signup Flow] third party")
                  }
                />
              </ListItem>
            </AuthWithApple>

            {thirdPartyAuthError && (
              <Typography
                variant="body2"
                component="p"
                className={classes.thirdPartyError}
              >
                {thirdPartyAuthError}
              </Typography>
            )}
          </List>

          <div className={classes.bottom}>
            <Typography
              variant="body2"
              component="p"
              className={classes.bottomText}
            >
              Already have an account?
            </Typography>
            <Button
              data-testid="switch-view-login-btn"
              onClick={switchView ? switchView : () => history.push("/login")}
              className={classes.loginButton}
            >
              Log in
            </Button>
          </div>

          <div
            style={{
              width: "100%",
              maxWidth: 328,
              borderTop: ` 1px solid #E0E0E0`,
              marginTop: "1rem",
              padding: "1rem 0",
            }}
          >
            <Typography
              variant="body2"
              component="p"
              style={{
                margin: "0 auto",

                maxWidth: 288,
                textAlign: "center",
              }}
            >
              By proceeding, you agree to the{" "}
              <Link className={classes.link} to="/terms-and-conditions">
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link className={classes.link} to="/privacy">
                Privacy Policy
              </Link>
              .
            </Typography>
          </div>
        </>
      )}
    </Paper>
  );
};
