import { Card } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { random } from "lodash";
import React from "react";
import { useStyles } from "./chat-list-item.styles";

export const DummyChatListItem: React.FC = () => {
  const classes = useStyles();
  const titleSize = random(70, 80);

  return (
    <>
      <Card className={classes.card}>
        <div className={classes.leftContainer}>
          <Skeleton
            variant="circle"
            width={52}
            height={52}
            className={classes.circle}
          />
          <div className={classes.leftInnerContainer}>
            <Skeleton variant="text" width={`${titleSize}%`} />
            <Skeleton variant="text" width={`${titleSize}%`} />
          </div>
        </div>
        <div className={classes.rightContainer}>
          <Skeleton variant="text" width={`${titleSize}%`} />
        </div>
      </Card>
    </>
  );
};
