import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const eventCardStyles = makeStyles({
  container: {
    maxWidth: 1100,
    width: "100%",
    backgroundColor: COLORS.WHITE,
    boxShadow: "1px 2px 9px 0 rgba(39,39,39,0.2)",
    display: "flex",
    position: "relative",
  },
  imageContainer: {
    maxWidth: 210,
    width: "100%",
    overflow: "hidden",
  },
  image: {
    backgroundRepeat: "no-repeat",
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "15px 26px",
  },
  sectionTop: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    borderBottom: "1px solid #E7E5E5",
    boxSizing: "border-box",
  },
  eventTitle: {
    color: "#3ABD5A",
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    margin: 0,
  },
  subText: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    margin: "10px 0 0",
    color: COLORS.BLACK,
  },
  dateAndType: {
    color: "#737373",
    margin: 0,
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 5,
  },
  numOfOrders: {
    position: "absolute",
    fontFamily: "CustomFourBold",
    color: COLORS.RED,
    bottom: 10,
    right: 10,
  },
  flexRow: {
    display: "flex",
  },
  bold: {
    fontFamily: "CustomFourBold",
    color: COLORS.BLACK,
  },
});
