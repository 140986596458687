import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Fade } from "react-awesome-reveal";
import { useStyles } from "./success-stories.styles";
import { CardMedia } from "@material-ui/core";

export const SuccessStories: FC = () => {
  const classes = useStyles();

  const successStories = [
    {
      delay: 550,
      name: "Chef Mal",
      location: "Chula Vista, CA",
      description:
        "Chef Malcolm ‘Mal’ Jamal Suggs operates Smoke ‘N Peppers BBQ. Originally from North Carolina, Mal is a career Navy Sailor who was stationed overseas and found himself craving Carolina BBQ.",

      image: require("../../assets/chefs/chef_mal.png"),
      videoLink: "",
    },
    {
      delay: 650,
      name: "Chef Denise",
      location: "Fallbrook, CA",
      description:
        "Grandma Dee’ offers ready-to-serve meals that are “yummy and family friendly” with the intention that “‘family mealtime will be a wonderful experience for gathering, conversation, and laughter.",
      image: require("../../assets/chefs/chef_denise.jpg"),
      videoLink: "",
    },
  ];

  return (
    <section className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.headerContainer}>
          <Fade triggerOnce={true} delay={250}>
            <Typography variant="h2" component="h2" className={classes.title}>
              Cook Success Stories
            </Typography>
          </Fade>
          <Fade triggerOnce={true} delay={350}>
            <div className={classes.squiggleContainer}>
              <img
                src={require("src/screens/home-new/assets/underline.svg")}
                className={classes.squiggle}
              />
            </div>
          </Fade>
        </div>
        <div className={classes.mainContent}>
          {successStories.map((story) => (
            <Fade
              triggerOnce={true}
              delay={story.delay}
              key={story.name}
              className={classes.card}
            >
              <>
                <CardMedia image={story.image} title={story.name}>
                  <img alt={story.name} style={{ display: "none" }} />
                </CardMedia>
                <div className={classes.lowerCardContainer}>
                  <Fade triggerOnce={true} delay={650}>
                    <Typography
                      variant="h3"
                      component="h3"
                      className={classes.cardTitle}
                    >
                      {story.name}{" "}
                      <span className={classes.cardLocation}>
                        | {story.location}
                      </span>
                    </Typography>
                  </Fade>
                  <Fade
                    triggerOnce={true}
                    delay={750}
                    style={{ width: "100%" }}
                  >
                    <div className={classes.redBar} />
                  </Fade>
                  <Fade triggerOnce={true} delay={850}>
                    <Typography
                      variant="h4"
                      component="p"
                      className={classes.description}
                    >
                      {story.description}
                    </Typography>
                  </Fade>
                </div>
              </>
            </Fade>
          ))}
        </div>
      </div>
    </section>
  );
};
