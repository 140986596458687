import { useQuery } from "@apollo/client";
import {
  Avatar,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Email, Phone } from "@material-ui/icons";
import React from "react";
import { sharedAPI } from "src/shared-graphql";
import { GET_CONTACT_MODAL } from "src/shared-graphql/queries";
import { _companyContact } from "src/utils/constants";

export const ContactDialog: React.FC = () => {
  const classes = useStyles();
  const { data } = useQuery(GET_CONTACT_MODAL);

  const handleClose = () => sharedAPI.setContactModal(false);

  return (
    <Dialog
      onClose={handleClose}
      open={Boolean(data && data.getContactModalState)}
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <List>
          {/* Cook Hotline */}
          <Typography variant="h4" component="h4">
            Cook Support
          </Typography>
          <a href={`tel:5107514902`} className={classes.link}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Phone />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={"(510)-751-4902"}
                secondary={"9am - 6pm PST"}
              />
            </ListItem>
          </a>
          {/* Diner Hotline */}
          <Typography variant="h4" component="h4">
            Diner Support
          </Typography>
          <a href={`tel:9518800422`} className={classes.link}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Phone />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={"(951)-880-0422"}
                secondary={"9am - 6pm PST"}
              />
            </ListItem>
          </a>
          {/* Email */}
          <Typography variant="h4" component="h4">
            Email
          </Typography>
          <a href={`mailto:${_companyContact.info}`} className={classes.link}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Email />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={_companyContact.info} />
            </ListItem>
          </a>
        </List>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles({
  link: {
    all: "unset",
    cursor: "pointer",
  },
});
