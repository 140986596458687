import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  headerSection: {
    height: "7vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: COLORS.WHITE,
    position: "relative",
    width: "100%",
    boxShadow: "0 1px 7px 0 rgba(0,0,0,0.17)",
  },
  backBtnChatHeader: {
    fontSize: "3rem",

    fontWeight: 500,
    color: COLORS.MEDIUM_GREEN,
  },
  chatHeader: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    margin: 0,

    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
  clearBtn: {
    fontFamily: "CustomFourBold",
    backgroundColor: COLORS.MEDIUM_GREEN,
    color: COLORS.WHITE,
    border: "none",
    cursor: "pointer",
    padding: "2px 8px",
    marginRight: 4,
  },
});
