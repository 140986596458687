import { useMutation } from "@apollo/client";
import { useMediaQuery } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { IGetMe } from "src/models";
import { BREAK_POINTS } from "src/styles";
import {
  Channel,
  ChatContext,
  MessageInput,
  MessageInputLarge,
  MessageInputSmall,
  MessageList,
  Thread,
  Window,
} from "stream-chat-react";
import { DINER_INIT_CONVERSATION, SEND_MSG } from "../api/mutations";
import { _ChatContext } from "../chat-provider";
import { LoadingErrorIndicator } from "../list/channel-list";
import { CustomChatRoomHeader } from "./custom-chat-room-header";

interface IProps {
  getMe: IGetMe;
}

export const ChatRoom: React.FC<IProps> = ({ getMe }) => {
  const [sendMsg] = useMutation(SEND_MSG);
  const [initConverstaionWithCook] = useMutation(DINER_INIT_CONVERSATION);
  const { channel } = useContext(ChatContext);
  const {
    state: { activeChannel },
  } = useContext(_ChatContext);

  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  const Input = isMobile ? MessageInputSmall : MessageInputLarge;

  const _sendMsg = (otherSender: any, msg: string) => {
    // send message will send a text message to the other sender if exists
    // if no other sender, it will send a notification to admin team (slack)
    return sendMsg({
      variables: {
        input: {
          userId: !otherSender ? "" : otherSender.id,
          msg: msg ?? "You got a new message!",
        },
      },
    });
  };

  useEffect(() => {
    const otherSender = Object.values(channel?.state?.members ?? {})
      .map((m: any) => m.user)
      .find((m: any) => m?.id !== getMe?.id && m?.appRole !== "ADMIN");

    // If there is no message between diner and cook, auto populate one
    if (!channel?.lastMessage() && otherSender) {
      initConverstaionWithCook({
        variables: {
          input: { cookID: otherSender.id, channelID: channel?.id },
        },
      });
    }

    // If there is no message between diner and admin. Come back and finish later.
    // else if (!channel?.lastMessage() && !otherSender) {
    //   initConverstaionWithCook({
    //     variables: {
    //       input: { cookID: "admin", channelID: channel?.id },
    //     },
    //   });
    // }
    const handleEvents = (e) => {
      if (e.type === "message.new" && e.user.id === getMe?.id) {
        _sendMsg(otherSender, e.message?.text ?? "You got a new message!");
      }
    };
    channel?.on(handleEvents);
    return function cleanup() {
      channel?.off(handleEvents);
    };
  }, [channel?.cid]);

  return (
    <div
      style={{
        display: activeChannel ? "unset" : "none",
        height: "100%",
        width: "100%",
        paddingBottom: process.env.REACT_APP_ENV === "cordova" ? 5 : 0,
      }}
    >
      <Channel LoadingErrorIndicator={LoadingErrorIndicator}>
        <Window>
          <CustomChatRoomHeader getMe={getMe} channel={channel} />
          <MessageList />
          <div id="message-input">
            <MessageInput noFiles={true} Input={Input} />
          </div>
        </Window>
        <Thread />
      </Channel>
    </div>
  );
};
