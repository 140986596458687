import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  input: {
    border: `1px solid ${COLORS.LIGHT_GREY}`,
    borderRadius: "2px",
    padding: "10px",
    width: "88%",
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    marginBottom: "1.5rem",
    "&::placeholder": {
      fontStyle: "italic",
    },
  },
  label: {},
  createOption: {
    height: 36,
    width: "10%",
    backgroundColor: COLORS.MEDIUM_GREEN,
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    "&:hover": {
      backgroundColor: COLORS.MEDIUM_GREEN,
    },
    "&:disabled": {
      backgroundColor: COLORS.LIGHT_GREY,
    },
  },
  form: {},
  formContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  currentOptionHeader: {
    fontFamily: "CustomFourBold",

    margin: 0,
  },
  dishName: {},
  optionItem: {
    margin: "0.5rem 0",
    display: "flex",
    justifyContent: "space-between",
  },
  deleteOptionBtn: {
    border: "none",
    backgroundColor: "unset",
    fontFamily: "CustomFourBold",
    color: COLORS.MEDIUM_GREEN,
    cursor: "pointer",
  },
  toolTip: {
    fontSize: "1.6rem",
  },
});
