import { Lottie } from "@crello/react-lottie";
import { Button, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { useHistory } from "react-router";
import { useStyles } from "./how-it-works.styles";

export const HowItWorksSection: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <section className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.headerContainer}>
          <Fade triggerOnce={true} delay={250}>
            <Typography variant="h2" component="h2" className={classes.title}>
              How It Works
            </Typography>
          </Fade>
          <Fade triggerOnce={true} delay={350}>
            <div className={classes.squiggleContainer}>
              <img
                src={require("../../assets/underline.svg")}
                className={classes.squiggle}
              />
            </div>
          </Fade>
        </div>

        <div className={classes.iconContainer}>
          {howItWorks.map((h) => (
            <Fade
              triggerOnce={true}
              delay={h.delay}
              className={classes.card}
              key={h.title}
            >
              <>
                <Fade triggerOnce={true} delay={350}>
                  <Lottie
                    config={h.option}
                    style={{
                      height: "20rem",
                      width: "20rem",
                      margin: "0 auto",
                    }}
                  />
                </Fade>
                <Fade triggerOnce={true} delay={550}>
                  <Typography
                    variant="h3"
                    component="h3"
                    className={classes.iconTitle}
                  >
                    {h.title}{" "}
                  </Typography>
                </Fade>
                <Fade triggerOnce={true} delay={750}>
                  <Typography
                    variant="h4"
                    component="h4"
                    className={classes.iconText}
                  >
                    {h.text}
                  </Typography>
                </Fade>
              </>
            </Fade>
          ))}
        </div>
        <Zoom triggerOnce={true} delay={650}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.searchMealsButton}
            onClick={() => history.push("/menus")}
          >
            Search Meals
          </Button>
        </Zoom>
      </div>
    </section>
  );
};

const howItWorks = [
  {
    title: "Browse",
    delay: 450,
    option: {
      loop: true,
      autoplay: true,
      animationData: require("src/assets/lottie/browse.json"),
    },
    text:
      "Search for authentic meals made by talented chefs near you. All of our chefs are permitted and vetted for utmost safety & quality.",
  },
  {
    title: "Order",
    delay: 550,
    option: {
      loop: true,
      autoplay: true,
      animationData: require("src/assets/lottie/order.json"),
    },
    text:
      // tslint:disable-next-line:max-line-length
      "Add dishes to your order and checkout in just a few steps. Full address is disclosed after order is completed.",
  },
  {
    title: "Enjoy!",
    delay: 650,
    option: {
      loop: true,
      autoplay: true,
      animationData: require("src/assets/lottie/enjoy.json"),
    },
    text:
      "Eat delicious homemade food made by certified chefs in your community! ",
  },
];
