export enum Actions {
  SET_FIELD = "SET_FIELD",
  SET_ADDRESS = "SET_ADDRESS",
  SET_BANNER_IMAGE = "SET_BANNER_IMAGE",
  SAVE_FORM = "SAVE_FORM",
}

export interface IAction {
  type: Actions;
  [key: string]: any;
}
