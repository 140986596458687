import React from "react";
import { IImage } from "src/models";
import { RestaurantBanner } from "./banner";

interface IProps {
  setImage: (d: IImage) => void;
  image: string;
  uploadImage: (f: File) => Promise<any>;
}

export const BannerUploader: React.FC<IProps> = ({
  setImage,
  image,
  uploadImage,
}) => {
  function onUpload(file: File): Promise<any> {
    return new Promise(async (res, rej) => {
      // Make API call to cloudinary with original file
      const { data } = await uploadImage(file);

      const response = {
        publicId: data.public_id,
        small: data.eager[0].secure_url.replace(
          "w_350,h_250,c_fill",
          "w_800,h_200,c_fill,g_auto"
        ),
        medium: data.eager[0].secure_url.replace(
          "w_350,h_250,c_fill",
          "w_700,h_832,c_fill"
        ),
        large: data.eager[0].secure_url.replace(
          "w_350,h_250,c_fill",
          "w_1663,h_1400,c_fill"
        ),
        original: data.secure_url,
        __typename: "IImage",
      };

      setImage(response);

      return res(response);
    });
  }

  return <RestaurantBanner src={image} upload={onUpload} />;
};
