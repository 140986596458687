export const getFileFromAndroid = () => {
  return new Promise((resolve, reject) => {
    (window.navigator as any)?.camera.getPicture(
      async (path) => {
        const fileEntry = await getFileEntry(path);
        const file = (await getFile(fileEntry)) as any;
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(file);
      },
      () => reject("Could not upload image"),
      {
        sourceType: (navigator as any)?.camera.PictureSourceType.PHOTOLIBRARY,
      }
    );
  });
};

const getFileEntry = (fileURL) => {
  return new Promise((resolve, reject) => {
    (window as any).resolveLocalFileSystemURL(
      fileURL,
      (fileEntry) => {
        resolve(fileEntry);
      },
      (err) => {
        reject(err);
      }
    );
  });
};

const getFile = async (fileEntry) => {
  try {
    return await new Promise((resolve, reject) => {
      fileEntry.file(resolve, reject);
    });
  } catch (err) {
    alert("Could not upload image");
  }
};
