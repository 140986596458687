import { Card, CardMedia, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { IDish } from "src/models";
import { ActionItems } from "./action-items";
import { styles as useStyles } from "./styles";

interface IProps {
  handleDelete: (dishId: number) => Promise<any>;
  handleUpdate: (dishId: number) => any;
  dish: IDish;
}

export const DishCard: FC<IProps> = ({ dish, handleDelete, handleUpdate }) => {
  const classes = useStyles();
  return (
    <Card data-testid={`dish-card-${dish.id}`} className={classes.card}>
      <div style={{ position: "relative" }}>
        <CardMedia
          className={classes.dishImage}
          image={dish.imagesGQL?.length ? dish.imagesGQL[0].small : ""}
          title="dish image"
        />

        <ActionItems
          dish={dish}
          handleDelete={handleDelete}
          handleUpdate={handleUpdate}
        />
      </div>
      <div className={classes.cardBottom}>
        <Typography variant="body1" component="h4" className={classes.dishName}>
          {dish.name}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          className={classes.description}
        >
          {dish.description}
        </Typography>
      </div>
    </Card>
  );
};
