import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch } from "react-router-dom";
import { MobileHeader, TimeoutDialog } from "src/components";
import { IGetMe } from "src/models";
import { useCookMode } from "src/utils/hooks/mode-hooks";
import { NoMatchComponent } from "../no-match";
import { SettingsContainer } from "../settings";
import { BecomeCookCompleteDialog } from "./components";
import { DishRoutes } from "./dish";
import { CreateEventFormContainer } from "./event/create";
import { EventDetailContainer } from "./event/detail/container";
import { EventDishSelectContainer } from "./event/dishes";
import { EditEventContainer } from "./event/edit";
import { HostEventsListContainer } from "./event/list";
import { EventSummaryContainer } from "./event/summary";
import { HomeLayout } from "./home";
import { NavbarContainerLeft } from "./navbar/container";
import { routeStyles as useStyles } from "./routes.styles";

interface IProps {
  data: { getMe: IGetMe; loading: boolean; error: any };
}

export const CookRoutes: FC<IProps> = ({ data }) => {
  const classes = useStyles();

  const { getMe } = data;

  useCookMode();

  return (
    <>
      <Helmet>
        <title>{"Cook Dashboard | Foodnome"}</title>
      </Helmet>
      <MobileHeader />
      <Route
        render={(p) => (
          <BecomeCookCompleteDialog
            firstName={getMe && getMe.firstName}
            appStatus={
              getMe &&
              getMe.restaurant &&
              getMe.restaurant.application &&
              getMe.restaurant.application.status
            }
            {...p}
          />
        )}
      />
      <div className={classes.container}>
        <div className={classes.colOne}>
          <NavbarContainerLeft user={getMe} />
        </div>
        <TimeoutDialog />
        <div className={classes.colTwo}>
          <Switch>
            <Route
              exact
              path="/c"
              render={() => <Redirect to={"/c/menus?status=ACTIVE"} />}
            />

            <Route path="/c/dishes" component={DishRoutes} />
            <Route exact path="/c/menus" component={HostEventsListContainer} />
            {/* <Route
              exact
              path="/c/profile"
              render={() => (
                <Redirect
                  to={{ pathname: "/settings", search: "?view=RESTAURANT" }}
                />
              )}
            /> */}
            <Route
              path="/c/settings"
              render={() => <SettingsContainer getMe={getMe} />}
            />
            <Route path="/c/home" render={() => <HomeLayout getMe={getMe} />} />

            <Route
              exact
              path="/c/menus/create"
              component={CreateEventFormContainer}
            />
            <Route
              exact
              path="/c/menus/edit/:id"
              component={EditEventContainer}
            />
            <Route
              exact
              path="/c/menus/dishes/:id"
              component={EventDishSelectContainer}
            />
            <Route
              exact
              path="/c/menus/summary/:id"
              component={EventSummaryContainer}
            />
            <Route path="/c/menus/:id" component={EventDetailContainer} />

            {/* Redirects we need to handle in case a user has /events saved.
            The routes with :id most likely won't work but they don't lead to 404.
            Not too much of an issue imo */}
            <Route
              exact
              path="/c/events/create"
              component={() => <Redirect to={"/c/menus/create"} />}
            />
            <Route
              exact
              path="/c/events/edit/:id"
              component={() => <Redirect to={"/c/menus/edit/:id"} />}
            />
            <Route
              exact
              path="/c/events/dishes/:id"
              component={() => <Redirect to={"/c/menus/dishes/:id"} />}
            />
            <Route
              exact
              path="/c/events/summary/:id"
              component={() => <Redirect to={"/c/menus/summary/:id"} />}
            />
            <Route
              exact
              path="/c/events/:id"
              component={() => <Redirect to={"/c/menus/:id"} />}
            />

            <Route component={NoMatchComponent} />
          </Switch>
        </div>
      </div>
    </>
  );
};

// export default flowRight(withRestaurant)(_CookRoutes);
