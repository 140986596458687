import { IconButton, Typography, useMediaQuery } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { parse, stringify } from "query-string";
import React from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { StyledTab, StyledTabs } from "src/components";
import { IGetMe } from "src/models";
import { BREAK_POINTS, COLORS } from "src/styles";
import { useVisibility } from "src/utils/hooks";
import { AccountContainer } from "./account";
import { PaymentContainer } from "./payment";
import { ProfileContainer } from "./restaurant";
import { useStyles } from "./styles";

enum Views {
  RESTAURANT = "RESTAURANT",
  ACCOUNT = "ACCOUNT",
  PAYMENT = "PAYMENT",
}

interface IProps {
  getMe?: IGetMe;
}

export const SettingsContainer: React.FC<IProps> = ({ getMe }) => {
  if (!getMe) return <Redirect to="/login" />;
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { view = Views.ACCOUNT } = parse(location.search) as {
    view?: Views;
  };
  const [isBackButtonVisible, currentElement] = useVisibility<any>(25);

  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

  const handleOnChange = (_, value) => {
    history.replace({
      search: stringify({ view: value }),
    });
  };

  const renderView = React.useCallback(
    (value: Views) => {
      switch (value) {
        case Views.RESTAURANT:
          return <ProfileContainer />;
        case Views.PAYMENT:
          return <PaymentContainer />;
        default:
          return <AccountContainer getMe={getMe} />;
      }
    },
    [getMe]
  );

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        {isMobile && (
          <IconButton
            size="small"
            aria-label="back"
            ref={currentElement}
            onClick={() => history.goBack()}
          >
            <ChevronLeft
              style={{ color: COLORS.MEDIUM_RED, fontSize: "2.5rem" }}
            />
          </IconButton>
        )}
        <Typography variant="h2" component="h1" className={classes.title}>
          Settings
        </Typography>
      </div>
      <StyledTabs onChange={handleOnChange as any} value={view}>
        {!isBackButtonVisible && isMobile && (
          <IconButton onClick={() => history.goBack()}>
            <ChevronLeft className={classes.backArrowFab} />
          </IconButton>
        )}
        <StyledTab label="Account" value="ACCOUNT" />
        {getMe?.restaurant && (
          <StyledTab label="Restaurant" value="RESTAURANT" />
        )}
        <StyledTab label="Payment" value="PAYMENT" />
      </StyledTabs>

      {renderView(view)}
    </div>
  );
};
