import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  isMobileSearchDialog: {
    position: "absolute",
    width: "100vw",
    height: "80vh",
    zIndex: 6,
    left: -15,
    top: 70,
    borderRadius: 0,
    boxShadow: ` 1px -7px 21px 2px rgba(0,0,0,0.15)`,
  },
  isNotMobileSearchDialog: {
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: 999,
    maxWidth: "100%",
    borderRadius: 0,
    boxShadow: `0 0 6px 0 rgba(0,0,0,0.15)`,
    maxHeight: "45rem",
    overflowY: "scroll",
  },
  loadingContainer: {
    textAlign: "center",
    width: "100%",
    margin: ".5em",
    zIndex: 10,
  },
});
