import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  mainHeading: {
    textAlign: "center",
    margin: "1rem 0",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  list: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    listStyleType: "none",
    width: "100%",
    maxWidth: 328,
    // margin: "0.5rem 0",
    padding: 0,
  },
  thirdPartyError: {
    fontFamily: "CustomFourBold",
    color: COLORS.RED,
    maxWidth: 328,
    margin: "0 auto",
    textAlign: "center",
  },
  listItemGutters: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  subHeading: {
    display: "inline-block",

    color: COLORS.DARK_GREY,

    fontWeight: "unset",
    textAlign: "center",

    marginBottom: "1.75rem",
  },
  label: { fontFamily: "CustomFour" },
  container: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    borderRadius: 0,
    width: "100%",
    maxWidth: 328,
    margin: "0 auto",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "2rem",
  },
  form: {
    width: "100%",
    maxWidth: 328,
    display: "flex",
    flexDirection: "column",
    margin: "1rem auto",
  },
  inputRoot: {
    marginBottom: "1.35rem",
  },
  input: {
    padding: ".75rem 1rem",

    "&::placeholder": {
      fontWeight: "normal",
      color: COLORS.DARK_GREY,

      textTransform: "capitalize",
    },
  },
  continueButton: {
    backgroundColor: COLORS.RED,
    color: COLORS.WHITE,

    fontFamily: "CustomFourBold",
    fontWeight: 500,
    boxShadow: `0 1px 9px 1px rgba(0,0,0,0.2)`,
    padding: "1.5rem",

    "&:disabled": {
      backgroundColor: `rgba(255, 89, 75, 0.7)`,
      transform: `translateY(8px)`,
      boxShadow: "none",
    },
    "&:hover": {
      background: COLORS.RED,
    },
  },
  facebookButton: {
    backgroundColor: "#3C579E",
    marginBottom: "1.35rem",
    height: 46,
    padding: ".5rem 1rem",
    width: "100%",
    "&:hover": {
      backgroundColor: "rgba(60, 87, 158, 0.7)",
    },
  },
  googleButton: {
    backgroundColor: "#5376D3",
    marginBottom: "1.35rem",
    height: 46,
    padding: ".5rem 1rem",
    width: "100%",
    "&:hover": {
      backgroundColor: `rgba(83, 118, 211, 0.7)`,
    },
  },
  buttonLabel: {
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    display: "flex",
    justifyContent: "space-between",
  },
  simpleButtonLabel: {
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  text: {
    textAlign: "center",
    color: COLORS.DARK_GREY,
    display: "flex",
    flexDirection: "row",
  },
  link: {
    color: COLORS.MEDIUM_GREEN,
    textDecoration: "none",
  },
  legalContainer: {
    textAlign: "center",
    padding: "1rem 0",
    color: COLORS.DARK_GREY,
  },
  textButton: {
    color: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    alignSelf: "flex-start",
  },
  icon: {
    color: COLORS.DARK_GREY,
    zIndex: 1,
  },
  inputAdornStart: {
    paddingLeft: 4,
  },
  logo: {
    width: 80,
    height: 80,
    margin: "0 auto",
  },
});
