import { makeStyles, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { COLORS } from "src/styles";

interface IProps {
  value: {
    title: string;
    statement: string;
    image: string;
  };
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textContainer: {
    maxWidth: 550,
    width: "100%",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "2rem",
    padding: "25px 40px",
  },
  title: {
    color: COLORS.DARK_BLUE,
    margin: "35px 0",
    textAlign: "center",
  },
  statement: {
    maxWidth: 752,
    color: COLORS.VIOLET,
    textAlign: "center",
    fontFamily: "CustomFourMedium",

    // margin: "25px 0 80px",
  },
  imageContainer: {
    width: "100%",
    height: 400,
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
});

export const Value: FC<IProps> = ({ value }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <Typography variant="h2" component="h2" className={classes.title}>
          {value.title}
        </Typography>
        <Typography variant="h4" component="h4" className={classes.statement}>
          {value.statement}
        </Typography>
      </div>
      <div className={classes.imageContainer}>
        <img src={value.image} alt="mission photo" className={classes.image} />
      </div>
    </div>
  );
};
