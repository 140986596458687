import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  headerTitle: {
    color: COLORS.RED,
    fontFamily: "CustomFourBold",
    textAlign: "center",
    marginBottom: "2rem",
  },
  subTitle: {
    fontFamily: "CustomFourBold",
    textAlign: "center",
    marginBottom: "2rem",
  },
  text: {
    textAlign: "center",
    marginBottom: "4rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginBottom: "2rem",
    },
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",

    padding: "7rem 12rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "3rem",
    },
  },
  button: {
    maxWidth: "20rem",
    margin: "auto",
  },
});
