import { Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import {
  ILinks,
  routesCompany,
  routesExplore,
  routesResources,
} from "../../helpers";
import { useStyles } from "./navlinks.styles";

export const NavLinks: React.FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.footerTop}>
      <section className={classes.col1}>
        <div>
          <img
            /* cook transition: replaced logo-pink.png with main_header_logo.png */
            src={require(`src/assets/logos/main_header_logo.png`)}
            alt="foodnome-logo"
            className={classes.logo}
          />
          <div className={classes.redBar} />
          <Typography variant="h5" component="h5" className={classes.motto}>
            Eat Local. Eat Homemade.
          </Typography>
        </div>
      </section>
      <div className={classes.col2}>
        <section className={classes.linkSection}>
          <Typography
            variant="h5"
            component="h5"
            className={classes.sectionTitle}
          >
            Explore
          </Typography>
          <ul className={classes.navigationLinks}>
            {routesExplore.map((route) => (
              <li className={classes.navigationItem} key={route.title}>
                {route.isExternalLink ? (
                  <Typography
                    component="a"
                    variant="body2"
                    data-e2e={`footer-${route.title}`}
                    href={route.path}
                    className={classes.link}
                  >
                    {route.title}
                  </Typography>
                ) : (
                  <Typography variant="body2" component="p">
                    <Link
                      to={route.path}
                      className={classes.link}
                      data-e2e={`footer-${route.title}`}
                    >
                      {route.title}
                    </Link>
                  </Typography>
                )}
              </li>
            ))}
          </ul>
        </section>
        <section className={classes.linkSection}>
          <Typography
            variant="h5"
            component="h5"
            className={classes.sectionTitle}
          >
            Company
          </Typography>
          <ul className={classes.navigationLinks}>
            {routesCompany.map((route: ILinks) => (
              <li className={classes.navigationItem} key={route.title}>
                {route.isExternalLink ? (
                  <Typography
                    component="a"
                    variant="body2"
                    data-e2e={`footer-${route.title}`}
                    href={route.path}
                    className={classes.link}
                  >
                    {route.title}
                  </Typography>
                ) : (
                  <Typography variant="body2" component="p">
                    <Link
                      to={route.path}
                      className={classes.link}
                      data-e2e={`footer-${route.title}`}
                    >
                      {route.title}
                    </Link>
                  </Typography>
                )}
              </li>
            ))}
          </ul>
        </section>
        <section className={classes.linkSection}>
          <Typography
            variant="h5"
            component="h5"
            className={classes.sectionTitle}
          >
            Resources
          </Typography>
          <ul className={classes.navigationLinks}>
            {routesResources.map((route: ILinks) => (
              <li className={classes.navigationItem} key={route.title}>
                {route.isExternalLink ? (
                  <Typography
                    component="a"
                    variant="body2"
                    href={route.path}
                    className={classes.link}
                    data-e2e={`footer-${route.title}`}
                  >
                    {route.title}
                  </Typography>
                ) : (
                  <Typography variant="body2" component="p">
                    <Link
                      to={route.path}
                      className={classes.link}
                      data-e2e={`footer-${route.title}`}
                    >
                      {route.title}
                    </Link>
                  </Typography>
                )}
              </li>
            ))}
          </ul>
        </section>
      </div>
    </section>
  );
};
