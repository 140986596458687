import { IconButton } from "@material-ui/core";
import React, { FC } from "react";
import { CSSTransition } from "react-transition-group";
import "./animations.css";
import { useStyles } from "./button.styles";

interface IProps {
  onClickLeft: (e: any) => void;
  onClickRight: (e: any) => void;
  isHovered: boolean;
  isMoreThanOne: boolean;
}

export const StepperButtons: FC<IProps> = ({
  onClickLeft,
  onClickRight,
  isHovered,
  isMoreThanOne,
}) => {
  const classes = useStyles();
  if (!isMoreThanOne) return null;

  return (
    <>
      <CSSTransition
        key="left-button"
        classNames="appear"
        timeout={300}
        unmountOnExit
        in={isHovered}
      >
        {/* 🛑 extra div is needed for animation purposes */}
        <div>
          <IconButton
            style={{ left: 14 }}
            classes={{ root: classes.button }}
            onClick={onClickLeft}
          >
            <img
              style={{ transform: `rotate(180deg)`, filter: "invert(1)" }}
              height={17}
              width={10}
              src={require(`src/assets/icons/event_arrow-19.svg`)}
              alt=""
            />
          </IconButton>
        </div>
      </CSSTransition>

      <CSSTransition
        key="right-button"
        classNames="appear"
        timeout={300}
        unmountOnExit
        in={isHovered}
      >
        {/* 🛑 extra div is needed for animation purposes */}
        <div>
          <IconButton
            style={{
              right: 14,
            }}
            classes={{ root: classes.button }}
            onClick={onClickRight}
          >
            <img
              style={{ filter: "invert(1)" }}
              height={17}
              width={10}
              src={require(`src/assets/icons/event_arrow-19.svg`)}
              alt=""
            />
          </IconButton>
        </div>
      </CSSTransition>
    </>
  );
};
