import { makeStyles } from "@material-ui/core";

export const sendAppStyles = makeStyles({
  paper: {
    display: "flex",
    marginRight: "1em",
    padding: "1em",
    justifyContent: "space-between",
    alignItems: "center",
  },
  count: {},
  searchField: { width: "240px", margin: "0px 10px" },
  selectField: { width: "240px" },
  aside: {
    width: "500px",
    display: "flex",
    justifyContent: "space-around",
  },
});
