import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "calc(100vh - 5.6rem)",
    display: "flex",
    flexDirection: "column",

    padding: "2rem",
  },
  row1: {
    margin: "0 auto",
    width: "100%",
    maxWidth: "85rem",
    padding: "0 2rem",
  },
  row2: {
    margin: "0 auto",
    marginTop: "4rem",
    width: "100%",
    maxWidth: "85rem",
    backgroundColor: "#FFC361",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: "6rem",
    borderRadius: 5,
  },
  row3: {
    margin: "0 auto",
    marginTop: "4rem",
    width: "100%",
    maxWidth: "85rem",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 2rem",
  },
  title: {
    fontFamily: "CustomFourBold",
    textAlign: "center",
  },

  backButton: {
    color: COLORS.MEDIUM_GREEN,
  },
  backButtonArrow: {
    color: COLORS.MEDIUM_GREEN,
  },
  buttonsWrapper: {
    margin: "0 auto",
    width: "100%",
    maxWidth: "85rem",

    display: "flex",
    flexDirection: "column",

    padding: "2rem",
  },

  hyperLink: {
    textDecoration: "none",
    color: COLORS.MEDIUM_GREEN,
  },
  tomato: {
    position: "absolute",
    zIndex: 4,
    top: "50%",
    right: -40,
    width: "12rem",
    height: "14rem",
    // We don't want to display SVGs on mobile
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  leaf: {
    position: "absolute",
    zIndex: 4,
    top: "5%",
    left: -20,
    width: "14rem",
    height: "21rem",
    // We don't want to display SVGs on mobile
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  noodle: {
    position: "absolute",
    zIndex: -1,
    top: "55%",
    left: -100,
    width: "46rem",
    height: "28rem",
    // We don't want to display SVGs on mobile
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  squiggle: {
    position: "absolute",
    zIndex: -1,
    top: 0,
    right: 0,
    width: "80rem",
    height: "74rem",
    // We don't want to display SVGs on mobile
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  headerContainer: {
    margin: "0 auto",
    maxWidth: "62rem",
  },

  closeButton: {
    alignSelf: "flex-end",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
  actionButtonContainer: {
    marginTop: "4rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignSelf: "center",
  },
  readEmailBtn: {
    marginTop: "2rem",
    color: COLORS.BLACK,
    borderBottom: `1px solid ${COLORS.MEDIUM_GREEN}`,
  },
  goToProfilePageBtn: {
    marginTop: "4rem",
  },
  checkmark: {
    width: "7rem",
    height: "7rem",
    marginBottom: "2rem",
  },
});
