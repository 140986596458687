import { Button } from "@material-ui/core";
import React from "react";
import {
  CustomEmailIcon,
  CustomFacebookIcon,
  CustomSmsIcon,
  CustomTwitterIcon,
  EmailShareButton,
  FacebookShareButton,
  SMSShareButton,
  TwitterShareButton,
} from "src/components";
import { DecoratedText } from "src/components/shared";
import { sharedAPI } from "src/shared-graphql";
import { useStyles } from "./share-button.styles";

interface IProps {
  shareUrl: string;
}

export const ShareButtonsContainer: React.FC<IProps> = React.memo(
  ({ shareUrl }) => {
    const classes = useStyles();

    const _copy = (text: string) => {
      const copyText = document.getElementById("myInput") as any;
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      copyText.setAttribute("style", "background-color: yellow;");
      sharedAPI.setSnackbarMsg({
        type: "success",
        msg: "Copied, paste it anywhere",
      });
    };

    return (
      <section className={classes.shareContainer}>
        <div className={classes.socialContainer}>
          <FacebookShareButton url={shareUrl}>
            <CustomFacebookIcon className={classes.shareBtnIcon} />
          </FacebookShareButton>
          <TwitterShareButton url={shareUrl}>
            <CustomTwitterIcon className={classes.shareBtnIcon} />
          </TwitterShareButton>
          <EmailShareButton url={shareUrl}>
            <CustomEmailIcon className={classes.shareBtnIcon} />
          </EmailShareButton>
          <SMSShareButton url={shareUrl}>
            <CustomSmsIcon className={classes.shareBtnIcon} />
          </SMSShareButton>
          {/* <IconButton onClick={_copyToClipboard}>
          <FaCopy className={classes.shareBtnIcon} style={{ color: "grey" }} />
        </IconButton> */}
        </div>
        <DecoratedText>or</DecoratedText>

        <div className={classes.shareLinkContainer}>
          <input
            readOnly
            value={shareUrl}
            className={classes.input}
            data-testid="referrer-input"
            id="myInput"
            onChange={() => null}
          ></input>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            classes={{ root: classes.shareLinkCopyBtn }}
            onClick={(e) => _copy(shareUrl ?? "")}
          >
            Copy
          </Button>
        </div>
      </section>
    );
  }
);
