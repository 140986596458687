import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";
import isEqual from "lodash/isEqual";
import React, { FC, useState } from "react";
import InputMask from "react-input-mask";
import { Link, Prompt } from "react-router-dom";
import { AutoCompleteContainer } from "src/components/auto-complete";
import { IUpdateUserPL, IUpdateUserPreferences } from "src/models";
import { COLORS } from "src/styles";
import { useFormStyles } from "./form.styles";
import { UploadProfileImage } from "./profile-image/upload-image";
import { initialState, reducer } from "./state";
import { Actions, IAction } from "./state/actions";
import { IFormState } from "./state/reducers";

interface IProps {
  updateUser: (d: IUpdateUserPL, p: IUpdateUserPreferences) => Promise<any>;
  uploadImg: (f: File) => Promise<any>;
  onClickLink?: () => void;
  init?: IFormState;
  isCook: boolean;
}

export const Form: FC<IProps> = ({
  updateUser,
  uploadImg,
  onClickLink = () => ({}),
  init = initialState,
}) => {
  // Hooks
  const classes = useFormStyles();
  const addressInputRef = React.useRef<any>();
  const [state, _dispatch] = React.useReducer(reducer, init);
  const [isLoading, setLoading] = useState<boolean>(false);
  const dispatch = (action: IAction) => _dispatch(action);

  // Helper functions
  const handleTextChange = (event: { target: { name: string; value: any } }) =>
    dispatch({
      type: Actions.SET_FIELD,
      key: event.target.name,
      value: event.target.value,
    });

  const setAddress = (value: string) =>
    dispatch({
      type: Actions.SET_ADDRESS,
      address: value,
    });

  const handleSave = (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      phoneNumber,
      address,
      newsletter,
    } = e.target.elements;
    setLoading(true);
    return updateUser(
      {
        firstName: firstName.value,
        lastName: lastName.value,
        phoneNumber: phoneNumber.value.slice(2),
        picture: state.picture,
        address: address.value,
      },
      { NEWS_LETTER_EMAIL_SUBSCRIPTION: newsletter.checked }
    ).finally(() => setLoading(false));
  };

  const toggleNewsletter = () =>
    handleTextChange({
      target: {
        name: "newsletter",
        value: !state.newsletter,
      },
    });

  const isDisabled = isEqual(
    { ...state, phoneNumber: state.phoneNumber?.replace("1-", "") },
    { ...init, phoneNumber: init.phoneNumber?.replace("1-", "") }
  );

  return (
    <>
      <Prompt
        when={!isDisabled}
        message={"Are you sure you want to leave without saving your changes?"}
      />
      <form className={classes.container} onSubmit={handleSave}>
        <div className={classes.innerContainer}>
          <div className={classes.avatarWrapper}>
            <div>
              <Typography
                variant="body2"
                component="p"
                className={classes.profileImageLabel}
              >
                Profile Image
              </Typography>
              <UploadProfileImage
                setPic={(imageURL: string) =>
                  dispatch({ type: Actions.SET_AVATAR, picture: imageURL })
                }
                pic={state.picture}
                uploadImage={uploadImg}
              />
            </div>
            {!state.picture && (
              <Typography
                variant="body2"
                component="p"
                style={{
                  padding: "1rem",
                  color: COLORS.RED,

                  textAlign: "center",
                  maxWidth: 200,
                }}
              >
                <span aria-label="hand wave emoji" role="img">
                  👋
                </span>{" "}
                Please upload a profile image to make it easier for cooks to
                recognize you.
              </Typography>
            )}
          </div>
          <div className={classes.formRight}>
            <div
              className={classNames(
                classes.fieldContainer,
                classes.nameInputContainer
              )}
            >
              <Typography
                component="label"
                variant="caption"
                className={classes.label}
                htmlFor="account-first-name"
              >
                First Name
              </Typography>
              <TextField
                size="small"
                required
                id="account-first-name"
                placeholder="First Name"
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.textField,
                  },
                }}
                name="firstName"
                value={state.firstName}
                onChange={handleTextChange}
                inputProps={{ maxLength: 20 }}
              />
            </div>
            <div
              className={classNames(
                classes.fieldContainer,
                classes.nameInputContainer
              )}
            >
              <Typography
                component="label"
                variant="caption"
                className={classes.label}
                htmlFor="account-last-name"
              >
                Last Name
              </Typography>
              <TextField
                size="small"
                required
                id="account-last-name"
                placeholder="Last Name"
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.textField,
                  },
                }}
                name="lastName"
                value={state.lastName}
                onChange={handleTextChange}
                inputProps={{ maxLength: 20 }}
              />
            </div>
            <div
              className={classNames(classes.fieldContainer, classes.fullRow)}
            >
              <Typography
                component="label"
                variant="caption"
                className={classes.label}
                htmlFor="account-email"
              >
                Email
              </Typography>
              <TextField
                size="small"
                id="account-email"
                placeholder="Email"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                  classes: {
                    input: classes.textField,
                  },
                }}
                name="email"
                value={state.email}
                inputProps={{ maxLength: 50 }}
              />
            </div>
            <div
              className={classNames(classes.fieldContainer, classes.fullRow)}
            >
              <Typography
                component="label"
                variant="caption"
                className={classes.label}
                htmlFor="account-phone"
              >
                Phone
              </Typography>
              <InputMask
                value={state.phoneNumber}
                mask="1-(999)-999-9999"
                onChange={handleTextChange}
              >
                {(inputProps) => (
                  <TextField
                    size="small"
                    {...inputProps}
                    name="phoneNumber"
                    InputProps={{
                      id: "account-phone",
                      disableUnderline: true,
                      classes: {
                        input: classes.textField,
                      },
                    }}
                    placeholder="1-(___)-___-____"
                    type="tel"
                  />
                )}
              </InputMask>
            </div>
            <div
              className={classNames(classes.fieldContainer, classes.fullRow)}
            >
              <Typography
                component="label"
                variant="caption"
                className={classes.label}
                htmlFor="account-address"
              >
                Address
              </Typography>
              <AutoCompleteContainer
                onSelect={setAddress}
                initialState={state.address}
                TextFieldProps={{
                  fullWidth: true,
                  InputProps: {
                    id: "account-address",
                    classes: {
                      input: classes.dinerInput,
                    },
                    name: "address",
                    inputRef: addressInputRef,
                  },
                }}
              />
            </div>
            <FormControlLabel
              classes={{ label: classes.formControlLabel }}
              control={
                <Checkbox
                  data-testid="newsletter-checkbox"
                  onChange={toggleNewsletter}
                  checked={state.newsletter}
                  value="newsletter"
                  name="newsletter"
                />
              }
              label="Opt in to the COOK Connect newsletter."
            />
            <Link
              onClick={onClickLink}
              className={classes.changePasswordLink}
              to="/d/passwords"
            >
              <Typography component="p" variant="body2">
                Change password?
              </Typography>
            </Link>
            <div
              className={classNames(classes.buttonContainer, classes.fullRow)}
            >
              <Button
                disabled={isDisabled || isLoading}
                type="submit"
                variant="contained"
                classes={{
                  root: classes.actionButton,
                  disabled: classes.disabled,
                }}
              >
                {isLoading ? (
                  <CircularProgress style={{ color: COLORS.WHITE }} />
                ) : (
                  "Save Changes"
                )}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
