import { useQuery } from "@apollo/client";
import { Button, Divider, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { GET_DINER_EVENTS } from "../api/queries";
import { useCreateChannel } from "../helpers";
import { useStyles } from "./upcoming-order.styles";

export const UpcomingOrder = () => {
  const history = useHistory();
  const { createChannel } = useCreateChannel();
  const classes = useStyles();
  const { data, loading, error } = useQuery(GET_DINER_EVENTS, {
    variables: {
      input: { status: "RESERVED", past: false, upcoming: true, limit: 1 },
    },
  });

  useEffect(() => {
    if (error && error.message.includes("logged in")) {
      history.push("/login");
    }
  }, [error]);

  if (loading || !data?.getDinerEvents?.length) return null;
  return (
    <>
      <div className={classes.container}>
        <Link to={`/d/menus/upcoming`} className={classes.link}>
          <Typography component="span" variant="body2">
            You have an order with{" "}
            <b className={classes.name}>
              {data.getDinerEvents[0].restaurant.name}
            </b>
            .
          </Typography>
        </Link>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() =>
            createChannel(data.getDinerEvents[0].restaurant.host.id)
          }
        >
          Chat
        </Button>
      </div>
      <Divider />
    </>
  );
};
