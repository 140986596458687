import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    [`@media (min-width: ${BREAK_POINTS.tablet}em)`]: {
      marginBottom: "3rem",
    },
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginTop: 20,
    },
  },
  header: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    display: "inline-block",
    margin: 0,
  },
  formFieldContent: {
    width: "100%",
    maxWidth: 858,
    padding: "1rem",
    margin: "0 auto",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      margin: "0 auto 4rem",
    },
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      margin: "0 auto 3rem",
    },
  },
  formHeaderWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem 2rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  radioLabel: {},
  popupFieldsWrapper: {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fill, minmax(200px, 1fr))`,
    justifyItems: "stretch",
    gridGap: "1em",
  },
  inputLabel: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    display: "flex",
    alignItems: "center",
    color: COLORS.BLACK,
  },
  inputRoot: {
    padding: 10,
    minWidth: 200,
    "&:placeholder": {
      color: "#B6B6B6",
    },
  },
  notchedOutline: {
    borderRadius: 2,
    border: `1px solid ${COLORS.LIGHT_GREY}`,
  },
  input: {
    height: "1.5em",
    padding: 0,
  },
  textarea: {},
  timeFieldsWrapper: {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fill, minmax(200px, 1fr))`,
    justifyItems: "flex-start",
    gridGap: "1em",
  },
  sectionTitleContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  sectionTitle: {
    // textAlign: "end",
    fontFamily: "CustomFourBold",
    margin: 0,
    color: COLORS.BLACK,
  },
  helpIcon: {
    ...theme.typography.body2,
    color: COLORS.MEDIUM_GREEN,
    marginLeft: 10,
  },
  eventNameInputWrapper: { maxWidth: 600, width: "100%" },
  timeInputWrapper: { width: "100%" },
  locationFieldsWrapper: {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fit, minmax(200px, 1fr))`,
    justifyItems: "stretch",
    gridGap: "1em",
  },
  preptime: {
    maxWidth: 343,
    margin: "2rem 0 1rem 0",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: "unset",
    },
  },
  row: {
    margin: "1rem 0",
  },
  helperText: {},
  radioGroup: {
    flexDirection: "row",
  },
  hintText: {
    color: COLORS.LIGHT_GREY,

    padding: 0,
    margin: 0,
  },
  submitButton: {
    display: "inline-block",
    backgroundColor: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.WHITE,
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_GREEN,
      opacity: 0.7,
    },
  },
  checkbox: {
    "&:disabled": {
      color: `${COLORS.LIGHT_GREY} !important`,
    },
  },
  checkboxDisabled: {
    color: `${COLORS.LIGHT_GREY} !important`,
  },
  saveAndExitButton: {
    color: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    opacity: 1,
    transition: `opacity 300ms ease-in-out`,
    "&:disabled": {
      opacity: 0,
    },
  },
  actionButtons: {
    maxWidth: "100%",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: COLORS.WHITE,
    zIndex: 1,
    padding: "0.6rem",
    paddingBottom: "calc(0.6rem + env(safe-area-inset-bottom))",
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
    margin: 0,
    borderTop: `solid 1px #d3d3d3`,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      left: 0,
    },
  },
  fieldset: { border: "none", margin: 0, padding: 0 },
  defaultReserveByContainer: {
    display: "flex",
    flexDirection: "row",
  },
  editReserveByBtn: {
    height: "2rem",
    width: "2.5rem",

    fontFamily: "CustomFourBold",
    borderRadius: 2,
    color: COLORS.MEDIUM_GREEN,
    border: `1px solid ${COLORS.MEDIUM_GREEN}`,
    backgroundColor: "unset",
    cursor: "pointer",
  },
}));
