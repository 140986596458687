import {
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React, { FC, useEffect, useState } from "react";
import { useDelay } from "src/utils/hooks";

interface IProps {
  count: number;

  onSearch: (d: {
    pageNumber: number;
    searchTerm?: string;
    status?: string;
  }) => void;
}
export const Header: FC<IProps> = ({ count, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDelay(searchTerm, 300);
  const [status, setStatus] = useState("ACTIVE");

  useEffect(() => {
    onSearch({ pageNumber: 1, searchTerm: debouncedSearchTerm, status });
  }, [debouncedSearchTerm, status]);

  const handleChange = (event: React.ChangeEvent<any>) => {
    setStatus(event.target.value);
  };

  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
      }}
    >
      <Typography variant="h3" component="h1" style={{ marginBottom: "2rem" }}>
        Events ({count})
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          alignItems: "flex-end",
        }}
      >
        <TextField
          size="small"
          variant="outlined"
          label="Search by name or location"
          style={{ maxWidth: "40rem", width: "100%" }}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />

        <Select
          value={status}
          style={{
            maxWidth: "40rem",
            width: "100%",
            marginLeft: "2rem",
            marginTop: "2rem",
          }}
          inputProps={{}}
          onChange={handleChange}
        >
          <MenuItem value={"PENDING"}>Pending</MenuItem>
          <MenuItem value={"ACTIVE"}>Active</MenuItem>
          <MenuItem value={"CANCELLED"}>Cancelled</MenuItem>
          <MenuItem value={"COMPLETED"}>Completed</MenuItem>
        </Select>
      </div>
    </Paper>
  );
};
