import React, { FC, useContext } from "react";
import { IEvent, IGetMe } from "src/models";
import { OrderContext } from "../../order-context";
import { ComponentActions } from "../../order-reducer";
import { Cart } from "./cart";
import { MobilePayment } from "./mobile/container";

interface IProps {
  getEvent: IEvent;
  isMobile: boolean;
  platformFee: number;
  serviceFeePerc: number;
  platformFeeFake: number;
  itemTotal: number;
  taxAmount: number;
  deliveryCost: number;
  discountPercent: number;
  discountedItemTotal: number;
  applyCoupon: any;
  nomeUsed: number;
  coupon: string;
  setCoupon: any;
  couponError: string;
  couponObj: any;
  dineOption: string;
  submitOrder: any;
  total: number;
  brainTreeInstance: any;
  showButton: boolean;
  setBrainTreeInstance: any;
  getMe: IGetMe;
  dispatch: React.Dispatch<ComponentActions>;
}
export const RoutesDialogView: FC<IProps> = ({
  getEvent,
  isMobile,
  platformFee,
  serviceFeePerc,
  platformFeeFake,
  itemTotal,
  taxAmount,
  deliveryCost,
  discountPercent,
  discountedItemTotal,
  applyCoupon,
  nomeUsed,
  coupon,
  setCoupon,
  couponError,
  couponObj,
  dispatch,
  dineOption,
  submitOrder,
  total,
  brainTreeInstance,
  showButton,
  setBrainTreeInstance,
  getMe,
}) => {
  const {
    state: { dialogView },
  } = useContext(OrderContext);

  if (dialogView === "detail" || dialogView === "checkout") {
    return (
      <Cart
        getEvent={getEvent}
        isMobile={isMobile}
        platformFee={platformFee}
        serviceFeePerc={serviceFeePerc}
        platformFeeFake={platformFeeFake}
        itemTotal={itemTotal}
        taxAmount={taxAmount}
        deliveryCost={deliveryCost}
        discountPercent={discountPercent}
        discountedItemTotal={discountedItemTotal}
        applyCoupon={applyCoupon}
        nomeUsed={nomeUsed}
        coupon={coupon}
        setCoupon={setCoupon}
        couponError={couponError}
        submitOrder={submitOrder}
        couponObj={couponObj}
        dineOption={dineOption}
        dialogView={dialogView}
        total={total}
        showButton={showButton}
        brainTreeInstance={brainTreeInstance}
        setBrainTreeInstance={setBrainTreeInstance}
        dispatch={dispatch}
      />
    );
  } else if (dialogView === "checkout-mobile") {
    return (
      <MobilePayment
        getEvent={getEvent}
        submitOrder={submitOrder}
        dialogView={dialogView}
        total={total}
        getMe={getMe}
      />
    );
  } else {
    return null;
  }
};
