import { Badge, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { _ChatContext } from "src/components/chat2";

export const ChatNotificationBadge = () => {
  const classes = useStyles();
  const {
    state: { unreadCount },
  } = useContext(_ChatContext);

  return (
    <Badge
      classes={{ anchorOriginTopRightRectangle: classes.circle }}
      showZero={false}
      badgeContent={unreadCount}
      color="primary"
    >
      <img
        style={{ width: 24, height: 24 }}
        src={require(`src/assets/icons/chat-44.svg`)}
      />
    </Badge>
  );
};

const useStyles = makeStyles({
  circle: {
    transform: `translate(50%, -30%)`,
  },
});
