import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import React, { FC, useEffect, useState } from "react";

interface IProps {
  totalCount: number;
  onChange: (d: number) => void;
  defaultPageSize?: number;
  resetPage?: boolean;
}
export const PaginationComponent: FC<IProps> = ({
  totalCount,
  onChange,
  resetPage,
  defaultPageSize,
}) => {
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (resetPage && page !== 1) setPage(1);
  }, [resetPage, page]);

  const _onChange = (d: number) => {
    onChange(d);
    setPage(d);
  };
  return (
    <Pagination
      current={page}
      style={{
        userSelect: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      defaultCurrent={1}
      defaultPageSize={defaultPageSize ? defaultPageSize : 10}
      total={totalCount}
      onChange={_onChange}
    />
  );
};
