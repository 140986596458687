import { Divider, Typography } from "@material-ui/core";
import React, { FC, useState } from "react";
import { CLIENT_ADDRESS } from "src";
import { sharedAPI } from "src/shared-graphql";
import { copyToClipboard } from "src/utils/helpers";
import { marketingAPI } from "./api";

interface IProps {
  data: {
    value: string;
    id: number;
  };
  fetch: () => any;
}
export const TextMessage: FC<IProps> = ({ data, fetch }) => {
  const [msg, setMsg] = useState(data.value);

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <Typography variant="h4" component="h4">
          Text message template for #{data.id}
        </Typography>
        <div style={{ display: "flex" }}>
          <Typography variant="body2" component="p">
            {CLIENT_ADDRESS}/api/crm/crm-text-msg/{data.id}
          </Typography>
          <button
            onClick={() =>
              copyToClipboard(
                `${CLIENT_ADDRESS}/api/crm/crm-text-msg/${data.id}`
              )
            }
          >
            Copy
          </button>
        </div>

        <textarea
          value={msg}
          style={{ whiteSpace: "pre-wrap", width: "500px" }}
          onChange={(e) => setMsg(e.target.value)}
          rows={6}
          placeholder={"Text message template"}
        />
        <button
          onClick={() =>
            marketingAPI
              .setTextMsg(data.id, msg)
              .then((d) => setMsg(d.data.value))
              .then(() =>
                sharedAPI.setSnackbarMsg({
                  type: "success",
                  msg: "Saved",
                })
              )
              .catch(() =>
                sharedAPI.setSnackbarMsg({
                  type: "error",
                  msg: " Sorry, could not save",
                })
              )
          }
        >
          Save
        </button>
        <button
          onClick={() =>
            marketingAPI
              .setTextMsg(data.id, "")
              .then(() => fetch())
              .then((d) => setMsg(d.data.value))
              .then(() =>
                sharedAPI.setSnackbarMsg({
                  type: "success",
                  msg: "Saved",
                })
              )
              .catch(() =>
                sharedAPI.setSnackbarMsg({
                  type: "error",
                  msg: " Sorry, could not save",
                })
              )
          }
        >
          Remove
        </button>
        <button
          onClick={() =>
            marketingAPI
              .testTextMsg(data.id)
              .then(() =>
                sharedAPI.setSnackbarMsg({
                  type: "success",
                  msg: "Check yall phone",
                })
              )
              .catch(() =>
                sharedAPI.setSnackbarMsg({
                  type: "error",
                  msg: " Sorry, could not test",
                })
              )
          }
        >
          Test
        </button>
      </div>
      <Divider />
    </>
  );
};
