import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const timeSlotStyles = makeStyles({
  expansionPanel: { boxShadow: "0 4px 11px 0 rgba(218,218,218,0.5)" },
  disabledPanel: { backgroundColor: "inherit" },
  expnsionPanelTitle: { fontFamily: "CustomFourBold", fontWeight: 500 },
  userList: {
    width: "100%",
    listStyleType: "none",
    padding: 0,
  },
  userListItem: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    border: "1px solid #DFDFDF",
  },
  stopTakingOrderBtn: {
    margin: 4,
    fontFamily: "CustomFourBold",
    maxWidth: 280,
  },
  panelDetail: { flexDirection: "column", alignItems: "flex-end" },
  userAvatar: {
    width: 75,
    height: 75,
    borderRadius: "50%",
    alignSelf: "center",
  },
  userOrderLayout: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    flexGrow: 1,
  },
  userOrderList: {
    listStyleType: "none",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  userFullName: {
    fontFamily: "CustomFourBold",
    color: COLORS.DARK_GREY,
    fontWeight: 500,

    margin: 0,
  },
  orderActionsLayout: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      flexGrow: 1,
    },
  },
  orderActionSuccessLayout: { display: "flex", alignItems: "center" },
  orderCompleteText: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  removeTimeSlotContainer: { display: "flex" },
  removeTimeSlotSpan: { color: COLORS.DARK_GREY },
  removeTimeSlotBtn: { color: COLORS.WHITE },
  undoCompleteButton: {
    color: COLORS.DARK_GREY,
    padding: 0,
    margin: 0,
  },
  completeOrderButton: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  userContact: {
    listStyleType: "none",
    padding: 0,
    display: "flex",
    justifyContent: "space-evenly",
  },
  userContactItem: { marginRight: "1rem", color: COLORS.MEDIUM_GREEN },
  userOrderedItems: {
    margin: 0,
    fontWeight: 500,
    fontFamily: "CustomFourBold",
  },
  userDineOption: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    margin: 0,
  },
});
