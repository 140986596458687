import { truncateText } from "src/utils/helpers";

const serviceFee = process.env.REACT_APP_SERVICE_FEE;

const getOrderPrice = (dish: any) => {
  const total = dish.EventUserDish.count * dish.price;
  return total.toFixed(2);
};

const getSubTotalDishes = (orderedDishes: any) => {
  const subTotal = orderedDishes.reduce(
    (acc, next) => acc + Number(getOrderPrice(next)),
    0
  );
  return Number(subTotal).toFixed(2);
};

const getSubTotalEvent = (price: number, reservedFor: number) =>
  price * reservedFor;

export const eventTypeMap = {
  FIXED_TIME: "Pop-up",
  TAKE_OUT: "To-go",
  DROP_IN: "Dine-in",
  DELIVERY: "Delivery",
};

const parseEventType = (dineOption: any, type: string) => {
  const parsed = Boolean(dineOption)
    ? eventTypeMap[dineOption]
    : eventTypeMap[type];
  return parsed;
};

export {
  getOrderPrice,
  truncateText,
  getSubTotalDishes,
  parseEventType,
  getSubTotalEvent,
};
