import { List } from "@material-ui/core";
import React from "react";
import "../animations.css";
import { DesktopGlobalMenu } from "../menu-desktop";
import { CookWithUsButton, LoginButton, SignUpButton } from "./auth-buttons";
import { useStyles } from "./auth-buttons/button.styles";

export const NonAuthenticatedUserActions: React.FC = React.memo(() => {
  const classes = useStyles();

  return (
    <List component="nav" className={classes.navBarItems}>
      <CookWithUsButton />
      <LoginButton />
      <SignUpButton />
      <DesktopGlobalMenu />
    </List>
  );
});
