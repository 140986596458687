import { useQuery } from "@apollo/client";
import { makeStyles, Typography } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import { IRestaurant } from "src/models";
import { GET_ME } from "src/shared-graphql/queries";
import { ListMapContext } from "../container";
import { RestaurantCard, RestaurantSkeleton } from "./restaurant-card";

export const RestaurantListDesktop: FC = React.memo(() => {
  const {
    state: { restaurants, loadingRestaurants },
  } = useContext(ListMapContext);
  const { data, error, loading } = useQuery(GET_ME, {
    fetchPolicy: "cache-and-network",
  });
  const classes = useStyles();
  const [restaurantsList, setRestaurantsList] = useState<IRestaurant[]>([]);
  const [recentlyViewed, setRecentlyViewed] = useState<IRestaurant[]>([]);

  // Just in case we want to add filter methods
  useEffect(() => {
    setRestaurantsList(restaurants);
  }, [restaurants]);

  // const moveToRecentlyViewed = useCallback(
  //   (restaurant: IRestaurant) => {
  //     if (recentlyViewed?.indexOf(restaurant) == -1) {
  //       const index = restaurantsList.indexOf(restaurant);
  //       const restaurantsListCopy = restaurantsList.slice();
  //       const recentRes = restaurantsListCopy.splice(index, 1);
  //       setRecentlyViewed([recentRes[0]]);
  //       setRestaurantsList(restaurantsListCopy);
  //     }
  //   },
  //   [restaurantsList]
  // );

  const restaurantContent = () => {
    if (loadingRestaurants) {
      return [1, 2, 3, 4].map((i) => <RestaurantSkeleton key={i} />);
    } else if (!loadingRestaurants && restaurants.length) {
      return (
        <>
          {/* {recentlyViewed?.length > 0 && (
            <section>
              <Typography
                variant="subtitle1"
                component="h5"
                style={{ textAlign: "center", marginBottom: "2rem" }}
              >
                Last Viewed
              </Typography>

              {recentlyViewed?.map((r) => (
                <RestaurantCard
                  getMe={data.getMe}
                  key={r.id + r.name + "-recently-viewed"}
                  restaurant={r}
                />
              ))}
              <div
                style={{
                  height: "1px",
                  border: "1px solid red",
                  width: "90%",
                  margin: "2rem auto",
                }}
              ></div>
            </section>
          )} */}

          {restaurantsList.map((r) => (
            <RestaurantCard
              getMe={data?.getMe}
              key={r.id + r.name}
              restaurant={r}
            />
          ))}
        </>
      );
    } else {
      return (
        <div className={classes.noResultsContainer}>
          <Typography variant="h4" component="h4">
            No results
          </Typography>
          <Typography variant="body1" component="p">
            Try zooming out or moving your location
          </Typography>
        </div>
      );
    }
  };

  return (
    <aside className={classes.container}>
      <Typography className={classes.title} component="h5" variant="h5">
        Home Restaurants Nearby
      </Typography>
      <ul className={classes.unorderedList}>{restaurantContent()}</ul>
    </aside>
  );
});

const useStyles = makeStyles({
  title: {
    padding: "1.5rem 3rem",
    paddingBottom: 0,

    fontFamily: "CustomFourBold",
  },
  container: {
    overflowY: "scroll",
    flexGrow: 1,
  },
  noResultsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  unorderedList: {
    margin: 0,
    padding: 0,
    listStyle: "none",
    marginTop: "1.5rem",
  },
});
