import { useLazyQuery, useQuery } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import {
  CustomEmailIcon,
  CustomFacebookIcon,
  CustomSmsIcon,
  CustomTwitterIcon,
  EmailShareButton,
  FacebookShareButton,
  SMSShareButton,
  TwitterShareButton,
} from "src/components";
import { sharedAPI } from "src/shared-graphql";
import { GET_REFERRAL_DIALOG_STATE } from "src/shared-graphql/queries";
import { LocalStorageHelper } from "src/utils/helpers";
import { USER_GET_REFERRAL_LINK } from "./graphql";
import { useStyles } from "./styles";

const facebook = {
  description:
    "Hungry for something new? You can now find fresh, authentic food made by local cooks in your community, thanks to this new app called Foodnome! Order your first meal with this link and you’ll get a free $5 credit:",
  tag: "foodnome",
};
const twitter = {
  description:
    "Hungry for something new? You can now find fresh, authentic food made by local cooks in your community, thanks to this new app called Foodnome! Order your first meal with this link and you’ll get a free $5 credit:",
  tags: ["foodnome", "homerestaurant"],
};
// Email will be the same as sms
const email = {
  subject: "Order homemade meals and get $5 off",
  body:
    "Hungry for something new? You can now find fresh, authentic food made by local cooks in your community, thanks to this new app called Foodnome! Order your first meal with this link and you’ll get a free $5 credit:",
};
export const ReferrerDialog = () => {
  const classes = useStyles();
  const [referralLink, setLink] = useState(
    localStorage.getItem("foodnome-referrer-link")
  );
  const { data: referralState } = useQuery<{ getReferralDialogState: boolean }>(
    GET_REFERRAL_DIALOG_STATE
  );
  const [getLink, { data }] = useLazyQuery(USER_GET_REFERRAL_LINK);

  useEffect(() => {
    if (referralState?.getReferralDialogState) {
      if (!referralLink) {
        getLink();
      }
    }
  }, [referralState?.getReferralDialogState]);

  useEffect(() => {
    if (data?.userGetReferralLink) {
      setLink(data?.userGetReferralLink?.link);
      LocalStorageHelper.setItem(
        "foodnome-referrer-link",
        data.userGetReferralLink.link
      );
    }
  }, [data]);

  const _copy = (text: string) => {
    const copyText = document.getElementById("myInput") as any;
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    copyText.setAttribute("style", "background-color: yellow;");
    sharedAPI.setSnackbarMsg({
      type: "success",
      msg: "Copied, paste it anywhere",
    });
  };
  return (
    <Dialog
      open={!!referralState?.getReferralDialogState}
      onClose={() => sharedAPI.setReferralDialogState(false)}
      classes={{ paper: classes.dialogPaper }}
    >
      <IconButton
        onClick={() => sharedAPI.setReferralDialogState(false)}
        className={classes.closeIconBtn}
        data-testid="refer-close-btn"
      >
        <Close className={classes.mdClose} />
      </IconButton>
      <DialogContent className={classes.dialogContent}>
        <section className={classes.dialogContentContainer}>
          <Typography variant="h2" component="h2" className={classes.h1}>
            Refer a Friend!
          </Typography>
          <Typography variant="body1" component="p" className={classes.body2}>
            Give & get $5 for every friend you invite that makes a purchase!
            Share the link below to get started. We will notify you each time a
            friend has successfully been referred!
          </Typography>

          <Typography variant="h5" component="h5" className={classes.h5}>
            Here is your link:
          </Typography>
          <div className={classes.shareLinkContainer}>
            <input
              readOnly
              value={referralLink?.split("//")[1] ?? ""}
              className={classes.input}
              data-testid="referrer-input"
              id="myInput"
              onChange={() => null}
            ></input>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              classes={{ root: classes.shareLinkCopyBtn }}
              onClick={(e) => _copy(referralLink ?? "")}
            >
              Copy
            </Button>
          </div>

          <Typography variant="h5" component="h5" className={classes.h5}>
            Share online:
          </Typography>
          <div className={classes.socialShareLinkContainer}>
            <FacebookShareButton
              url={referralLink ?? ""}
              quote={facebook.description}
              hashtag={facebook.tag ? "#" + facebook.tag : undefined}
            >
              <CustomFacebookIcon className={classes.shareBtnIcon} />
            </FacebookShareButton>
            <TwitterShareButton
              url={referralLink ?? ""}
              text={twitter.description}
              hashtags={twitter.tags}
            >
              <CustomTwitterIcon className={classes.shareBtnIcon} />
            </TwitterShareButton>
            <EmailShareButton
              url={referralLink ?? ""}
              separator=" "
              subject={email.subject}
              body={email.body}
            >
              <CustomEmailIcon className={classes.shareBtnIcon} />
            </EmailShareButton>
            <SMSShareButton
              url={referralLink ?? ""}
              separator=" "
              body={email.body}
            >
              <CustomSmsIcon className={classes.shareBtnIcon} />
            </SMSShareButton>
          </div>
        </section>
      </DialogContent>
    </Dialog>
  );
};
