import { Chip, TextField, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";
import { COLORS } from "src/styles";
import { useStyles } from "./styles";

interface IProps {
  label: string;
  placeholder?: string;
  initialState?: string[];
  deliminator?: string | null;
  onAddTag: (selectedTags: string[]) => void;
}

export const ChipsInput: React.FC<IProps> = ({
  initialState = [],
  onAddTag,
  deliminator = ",",
  label,
  placeholder,
}) => {
  const classes = useStyles();
  const [selectedTags, setSelectedTags] = useState<string[]>(() =>
    prepareInitialState(initialState)
  );
  const [tagInputValue, setTagInputValue] = useState<string>("");
  const inputRef = React.useRef<any>();
  const depsRef = React.useRef<any>();

  useEffect(() => {
    if (isEqual(depsRef.current, initialState)) return;
    setSelectedTags(prepareInitialState(initialState));
  }, [initialState]);

  useEffect(() => {
    depsRef.current = initialState;
  });

  function generateTags(input: string) {
    if (input !== "") {
      const cleanTags = input
        .split(deliminator as any)
        .map((term) => term.toLowerCase().trim())
        .filter((t) => t !== "" && !selectedTags.includes(t))
        .reduce(
          (acc: string[], next: string) =>
            acc.includes(next) ? acc : acc.concat([next]),
          []
        );

      if (cleanTags.length) {
        const newSelectedTags = selectedTags.concat(cleanTags);
        setSelectedTags(newSelectedTags);
        onAddTag(newSelectedTags);
      }

      setTagInputValue("");
    }
  }

  function onDeleteChip(tag) {
    const newSelectedTags = selectedTags.filter((t) => t !== tag);
    setSelectedTags(newSelectedTags);
    onAddTag(newSelectedTags);
  }

  function onKeyDown(event) {
    switch (event.key) {
      case "Enter":
        // prevent event bubbling
        event.preventDefault();
        generateTags(event.target.value);
        break;
      case "Backspace":
        if (event.target.value === "") {
          if (selectedTags.length === 0) break;
          const newSelectedTags = selectedTags.slice(0, -1);
          setSelectedTags(newSelectedTags);
          onAddTag(newSelectedTags);
        }
        break;
      default:
        break;
    }
  }

  function onClickWrapper() {
    inputRef.current.focus();
  }

  return (
    <>
      <Typography
        component="label"
        variant="caption"
        className={classes.inputLabel}
        htmlFor={`${label}-input`}
      >
        {label}
      </Typography>
      <div onClick={onClickWrapper} className={classes.inputWrapper}>
        <ul className={classes.tagsList} data-testid={`selected-${label}`}>
          {selectedTags?.map((tag) => (
            <Chip
              component={"li" as "div"}
              onDelete={() => onDeleteChip(tag)}
              key={tag}
              label={tag.trim()}
              classes={{ deletable: classes.deletableChip }}
              deleteIcon={
                <Close
                  aria-label="delete"
                  style={{ color: COLORS.WHITE }}
                  data-testid="delete-chip-icon"
                />
              }
            />
          ))}
        </ul>
        <TextField
          name="tags"
          size="small"
          id={`${label}-input`}
          type="text"
          placeholder={
            selectedTags?.length
              ? ""
              : placeholder ?? "Japanese, Healthy, Gluten-Free..."
          }
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.inputRoot },
          }}
          inputProps={{ style: { padding: "6px 0 7px 7px" } }}
          inputRef={inputRef}
          value={tagInputValue}
          onKeyDown={onKeyDown}
          required={selectedTags.length === 0}
          onChange={(event) => setTagInputValue(event.target.value)}
          onBlur={() => generateTags(tagInputValue)}
          onTouchStartCapture={() => generateTags(tagInputValue)}
        />
      </div>
    </>
  );
};
function prepareInitialState(initialState: string[]) {
  return initialState
    ?.filter((t) => t !== "")
    .reduce(
      (acc, next) =>
        acc.includes(next.toLowerCase())
          ? acc
          : acc.concat([next.toLowerCase()]),
      [] as string[]
    );
}
