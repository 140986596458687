import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  title: {
    fontFamily: "CustomFourBold",
  },

  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 24px",
  },
  error: {
    textAlign: "center",
    color: COLORS.RED,
    padding: "0 1rem",
  },
});
