import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useAuthStyles = makeStyles({
  drawer: {
    width: 320,
  },
  drawer2: {
    width: 340,
  },
  circle: {
    transform: `translate(50%, -30%)`,
  },
  headerBarIcon: { color: COLORS.BLACK, cursor: "pointer" },
  ul: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
    display: "flex",
    height: "100%",
  },
  navBarItems: {
    display: "flex",
    height: "100%",
  },
  navBarItemsPadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  navBarItem: {
    height: "100%",
    justifyContent: "space-evenly",
    minWidth: 140,
    "&:hover": {
      backgroundColor: COLORS.WHITE,
    },
  },
  firstName: {
    color: "#363636",

    maxWidth: `10ch`,
    overflow: "hidden",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "block",
    textTransform: "capitalize",
    textAlign: "center",
    paddingLeft: "1em",
    paddingRight: "1em",
  },
  hamburger: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  notificationPaper: {
    overflow: "visible",
    boxShadow: "0 1px 7px 0 rgba(0,0,0,0.17)",
  },
  notificationsWrapper: {
    backgroundColor: COLORS.WHITE,
    width: "400px",
    minHeight: 200,
    zIndex: 1000,
    position: "relative",
  },
});
