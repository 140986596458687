import { Button, CircularProgress, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { bugSnag, cookPixel, ReactPixel, _hsq } from "src";
import { ICreateRestaurantPL, IGetMe } from "src/models";
import { amplitude } from "src/services";
import { COLORS } from "src/styles";
import { useRecaptcha } from "src/utils/hooks";
import { ContactFields, LocationPhoneFields, NameFields } from ".";
import { Actions, IAction } from "./actions";
import { formReducer, initialFormState } from "./reducers";
import { formStyles as useStyles } from "./styles";

interface IProps {
  createRestaurant: (d: ICreateRestaurantPL) => void;
  user: IGetMe | undefined;
  loading: boolean;
  error: string;
  submitMailchimp: (d: any) => any;
}

export const Form: React.FC<IProps> = ({
  createRestaurant,
  user,
  error,
  submitMailchimp,
  loading,
}) => {
  // Hooks
  const classes = useStyles();
  const [formState, _dispatch] = React.useReducer(
    formReducer,
    initialFormState
  );
  const executeGoogleRecaptcha = useRecaptcha();

  // Using this unique id to prevent duplication errors since this component
  // is shown twice in the become-a-cook page
  const uniqueId = Date.now();

  useEffect(() => {
    if (user) {
      dispatch({
        type: Actions.SET_USER_INFO,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        email: user.email,
      });
    }
  }, [user]);

  // Handlers
  const dispatch = (action: IAction) => _dispatch(action);
  const setTextField = ({
    target: { value, name },
  }: {
    target: { value: string; name: string };
  }) => dispatch({ type: Actions.SET_FIELD, name, value });

  const onSubmit = async (event: any) => {
    event.preventDefault();

    const captchaToken = await executeGoogleRecaptcha("become_cook_form");

    const {
      email,
      zipCode,
      firstName,
      lastName,
      phoneNumber,
    } = event.target.elements;

    process.env.NODE_ENV !== "test" &&
      ReactPixel.trackSingle(cookPixel, "SubmitApplication", {
        zipcode: zipCode.value,
      });
    bugSnag?.setUser(email, email, firstName);
    amplitude.getInstance().setUserId(email.value);
    _hsq?.push(["identify", { email: email.value }]);
    amplitude.getInstance().logEvent("[Become Cook] submit", {
      email: email.value,
      zipcode: zipCode.value,
    });
    submitMailchimp({
      email_address: email.value,
      status: "subscribed",
      FNAME: firstName.value,
      LNAME: lastName.value,
      ZIPCODE: zipCode.value,
      SHARE: formState.callTime
        ? moment(Number(formState.callTime)).format("MMM Do YY, h:mm a")
        : "no call time",
      PHONE: phoneNumber.value
        ? phoneNumber.value.slice(2)
        : "no phone provided",
    }).catch((e) =>
      amplitude
        .getInstance()
        .logEvent("[Become Cook] submit error", { error: e.message })
    );
    return createRestaurant({
      firstName: firstName.value,
      lastName: lastName.value,
      phoneNumber: phoneNumber.value.slice(2),
      email: email.value,
      address: `${zipCode.value}`,
      captchaToken,
    });
  };

  return (
    <>
      <form
        autoComplete="off"
        onSubmit={onSubmit}
        id={"restaurant-form-" + uniqueId}
        data-testid={"restaurant-form-" + uniqueId}
        className={classes.form}
      >
        <NameFields
          firstName={formState.firstName}
          lastName={formState.lastName}
          onChange={setTextField}
        />
        <ContactFields email={formState.email} onChange={setTextField} />
        <LocationPhoneFields
          phone={formState.phoneNumber}
          onChange={setTextField}
          address={formState.address}
          city={formState.city}
          zipcode={formState.zipCode}
        />

        {!!error && (
          <div style={{ position: "relative", margin: ".5rem" }}>
            <Typography variant="body2" component="p" className={classes.error}>
              {error.includes("log in")
                ? "Oops! Looks like you already have an account. Please try signing in."
                : "Sorry, something is not correct. Try a different browser."}
            </Typography>
          </div>
        )}

        <Button
          size="large"
          variant="contained"
          type="submit"
          color="secondary"
          className={classes.submitBtn}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress style={{ color: COLORS.WHITE }} />
          ) : (
            "Get Started"
          )}
        </Button>

        <Typography
          variant="caption"
          component="p"
          className={classes.agreement}
        >
          *By submitting this form, you agree to our{" "}
          <Link to="/terms-and-conditions" className={classes.link}>
            terms and conditions
          </Link>
          .
        </Typography>
      </form>
    </>
  );
};
