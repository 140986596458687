import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    position: "relative",
    background:
      "linear-gradient(137.18deg, #FF3056 0%, #FE7544 33.92%, #FE9A32 53.19%, #FF7444 71.62%, #FF3056 100%)",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    maxWidth: "140rem",
    margin: "9rem auto",

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      margin: "5rem auto",
    },
  },

  title: {
    fontFamily: "CustomFourBold",
    textAlign: "center",
    color: COLORS.WHITE,
  },
  mainContent: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(24rem, 1fr))",
    alignItems: "center",
    padding: "0 4rem",
    columnGap: "7rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "0 2rem",
    },
  },
  textContainer: {
    margin: "0 auto",
    maxWidth: "48rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "3rem",
    zIndex: 10,
  },
  text: {
    fontFamily: "CustomFour",
    color: COLORS.WHITE,
    textAlign: "center",
  },

  image: {
    width: "100%",
  },

  imageContainer: {
    position: "relative",
    width: "100%",
    maxWidth: "48rem",
    margin: "auto",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  spagett: {
    position: "absolute",
    top: "-8%",
    left: -95,
    width: "17%",
    maxWidth: "42.5rem",
    minWidth: "22rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      top: "-13%",
      left: -130,
    },
  },
});
