import { useQuery } from "@apollo/client";
import {
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import React, { useState } from "react";
import { Activity, FlatFeed, StatusUpdateForm } from "react-activity-feed";
import "react-activity-feed/dist/index.css";
import { sharedAPI } from "src/shared-graphql";
import { BREAK_POINTS, COLORS } from "src/styles";
import { GET_ME } from "../header/api/graphql";
import { Footer } from "./footer";
import { useStyles } from "./styles";
import "./styles.css";

export const FeedContainer = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  const { data } = useQuery(GET_ME);

  return (
    <div style={{ padding: "0 14px" }}>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        classes={{
          paperWidthSm: isMobile ? classes.paperWidthMb : classes.paperWidth,
        }}
      >
        <IconButton
          className={classes.closeBtn}
          color="primary"
          aria-label="close"
          onClick={() => setOpen(false)}
        >
          <Close style={{ color: COLORS.MEDIUM_GREEN }} />
        </IconButton>
        <StatusUpdateForm
          onSuccess={() => {
            setOpen(false);
            sharedAPI.setSnackbarMsg({ type: "success", msg: "Posted :)" });
          }}
        />
      </Dialog>

      {data?.getMe?.restaurant && (
        <IconButton
          className={classes.newBtn}
          color="primary"
          aria-label="new post"
          onClick={() => setOpen(true)}
        >
          <Add style={{ color: COLORS.WHITE }} />
        </IconButton>
      )}

      <FlatFeed
        feedGroup="user"
        options={{
          withRecentReactions: true,
          limit: 10,
          withOwnChildren: true,
          reactions: { recent: true },
        }}
        LoadingIndicator={
          <section
            style={{
              height: 200,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </section>
        }
        Placeholder={
          <section className={classes.noNotificationContainer}>
            <img
              src={require("../../screens/event-detail/detail/order-info/images/emptybag.svg")}
              alt="Image"
              className={classes.bagImage}
            />
            <Typography variant="body2" component="p">
              Looks like you do not have feeds!
            </Typography>
          </section>
        }
        notify
        Activity={(props) => {
          const hasSubActivity = Boolean(props.activity.object.object);
          const activity = hasSubActivity
            ? props.activity.object
            : props.activity;
          return (
            <>
              <Activity
                {...props}
                activity={activity}
                Footer={
                  <Footer
                    {...props}
                    activity={activity}
                    hasSubActivity={hasSubActivity}
                  />
                }
              />
              <Divider style={{ margin: "16px 10px" }} />
            </>
          );
        }}
      />
    </div>
  );
};
