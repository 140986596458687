import { useMutation } from "@apollo/client";
import {
  Avatar,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { DirectionsCar, NotificationsActive } from "@material-ui/icons";
import isEqual from "lodash/isEqual";
import moment from "moment";
import { DateRange } from "moment-range";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IEvent, IEventUser, IUser } from "src/models";
import { eventTypeMap } from "src/utils/helpers";
import { CHECKIN_EVENT, CHECKIN_ORDER } from "../../api/graphql/mutations";
import { COOK_GET_EVENT } from "../graphql";
import { getDisplayTime, ITimeSlotWithGuests } from "../helpers";
import { OrderedItems } from "../timeline-view/user-list";
import { EditArrivalTime } from "./edit-arrival-time";
import { tableViewStyles as useStyles } from "./table-view.styles";

interface IProps {
  isFixedTime: boolean;
  timeSlotsWithGuests: ITimeSlotWithGuests[];
  onClickUser: (user: IUser, currentOrder?: IEventUser) => void;
  getEvent: IEvent;
}

interface IRow {
  guest: IUser;
  arrivalTime: DateRange;
}

export const TableView: React.FC<IProps> = ({
  timeSlotsWithGuests,
  isFixedTime,
  onClickUser,
  getEvent,
}) => {
  const classes = useStyles();
  const [rows, setRows] = useState<IRow[]>(
    setInitialState(timeSlotsWithGuests)
  );
  const timeSlotsWithGuestsRef = React.useRef<any>(timeSlotsWithGuests);

  function setInitialState(initialState: ITimeSlotWithGuests[]) {
    return initialState.reduce((acc: IRow[], next) => {
      const { guests, timeSlot } = next;
      return [
        ...acc,
        ...guests.map((guest) => ({ guest, arrivalTime: timeSlot })),
      ];
    }, []);
  }

  useEffect(() => {
    if (isEqual(timeSlotsWithGuests, timeSlotsWithGuestsRef.current)) return;
    setRows(setInitialState(timeSlotsWithGuests));
  });

  useEffect(() => {
    timeSlotsWithGuestsRef.current = timeSlotsWithGuests;
  });

  return (
    <div className={classes.tableRoot}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.columnHeader}>Name</TableCell>
            <TableCell className={classes.columnHeader}>Details</TableCell>
            {!isFixedTime ? (
              <>
                <TableCell className={classes.columnHeader} align="left">
                  Time
                </TableCell>

                <TableCell className={classes.columnHeader} align="left">
                  Order
                </TableCell>
                {/* <TableCell className={classes.columnHeader} align="left">
                  Dining Option
                </TableCell> */}
              </>
            ) : (
              <>
                <TableCell className={classes.columnHeader} align="left">
                  Arrival Time
                </TableCell>
                <TableCell className={classes.columnHeader} align="center">
                  Reserved For
                </TableCell>
              </>
            )}
            <TableCell className={classes.columnHeader} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(({ guest, arrivalTime }, index) => {
            const avatarProps = Object.assign(
              {},
              guest.picture
                ? { src: guest.picture, alt: `${guest.firstName} avatar` }
                : { children: guest.firstName[0]?.toUpperCase() }
            );
            return (
              <React.Fragment key={guest.id}>
                <_TableRow
                  classes={classes}
                  getEvent={getEvent}
                  onClickUser={onClickUser}
                  avatarProps={avatarProps}
                  guest={guest}
                  currentOrder={guest.eventUser}
                  isFixedTime={isFixedTime}
                  arrivalTime={arrivalTime}
                  index={index}
                />
                {guest.eventUser.orders?.map((order) => {
                  return (
                    <_TableRow
                      key={order.id + "order"}
                      currentOrder={order}
                      getEvent={getEvent}
                      classes={classes}
                      onClickUser={onClickUser}
                      avatarProps={avatarProps}
                      guest={guest}
                      isFixedTime={isFixedTime}
                      arrivalTime={{
                        start: moment(Number(order.arrivalTime)),
                        end: moment(Number(order.arrivalTime)).add(
                          order.dineOption === "DELIVERY" ? 45 : 15,
                          "minutes"
                        ),
                      }}
                      index={index}
                    />
                  );
                })}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

const _TableRow = ({
  classes,
  onClickUser,
  avatarProps,
  guest,
  currentOrder,
  isFixedTime,
  arrivalTime,
  getEvent,
  index,
}) => {
  const { id } = useParams<{ id: string }>();

  const [justCompleted, setJustCompleted] = useState(false);

  useEffect(() => {
    if (currentOrder.checkedIn) {
      setJustCompleted(true);
    }
  }, [currentOrder.checkedIn]);

  const [checkInEvent] = useMutation(CHECKIN_EVENT, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: COOK_GET_EVENT, variables: { input: id.split("-")[0] } },
    ],
  });
  const [checkInOrder] = useMutation(CHECKIN_ORDER, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: COOK_GET_EVENT, variables: { input: id.split("-")[0] } },
    ],
  });

  const checkin = currentOrder.eventId ? checkInEvent : checkInOrder;

  return (
    <TableRow data-testid="user-order-item-table">
      <TableCell align="left" className={classes.tableCell} scope="row">
        <div
          style={{ display: "flex", cursor: "pointer" }}
          onClick={() => onClickUser(guest, currentOrder)}
        >
          <Avatar
            data-testid="order-user-avatar"
            className={classes.userAvatar}
            {...avatarProps}
            onClick={() => onClickUser(guest, currentOrder)}
          />

          <div className={classes.contactContainer}>
            {!!(guest.points && guest.points.eventsAttended < 2) && (
              <div className={classes.newTag}>New</div>
            )}
            <Typography
              variant="h6"
              component="h4"
              className={classes.guestName}
            >
              {`${guest.firstName} ${guest.lastName}`}{" "}
            </Typography>
          </div>
        </div>
      </TableCell>
      <TableCell>
        {(currentOrder.dineOption === "DELIVERY" || currentOrder.notes) && (
          <IconButton
            className={classes.notificationContainer}
            onClick={() => onClickUser(guest, currentOrder)}
          >
            {currentOrder.dineOption === "DELIVERY" ? (
              <DirectionsCar className={classes.notification} />
            ) : (
              <NotificationsActive
                data-testid={`guest-notification-button-${index}`}
                className={classes.notification}
              />
            )}
          </IconButton>
        )}
      </TableCell>

      {!isFixedTime ? (
        <>
          <TableCell
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              minWidth: "20rem",
            }}
          >
            <Typography
              variant="body2"
              component="p"
              style={{
                width: "100%",
                fontFamily: "CustomFourBold",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {eventTypeMap[currentOrder.dineOption]}
              <EditArrivalTime guest={guest} currentOrder={currentOrder} />
            </Typography>
            <Typography
              variant="body2"
              component="p"
              style={{ display: "flex" }}
            >
              {getDisplayTime(
                arrivalTime.start,
                currentOrder.dineOption === "DELIVERY"
                  ? moment(arrivalTime.end).add(45, "minutes")
                  : moment(arrivalTime.end)
              )}
            </Typography>
          </TableCell>
          <TableCell>
            <OrderedItems
              join={"\r\n"}
              eventUser={currentOrder}
              classes={classes}
            />
          </TableCell>
        </>
      ) : (
        <>
          <TableCell className={classes.simpleCell} align="left">
            {arrivalTime.start.format("hh:mma")}
          </TableCell>
          <TableCell className={classes.simpleCell} align="center">
            {currentOrder.reservedFor}
          </TableCell>
        </>
      )}

      <TableCell align="left">
        {/* It doesn't make sense to have a "STOP TAKING ORDERS FOR THIS TIME" button on PopUp events */}
        {!getEvent.type.includes("FIXED_TIME") && (
          <div className={classes.statusCell}>
            <Button
              data-testid="user-action-button"
              onClick={() =>
                checkin({
                  variables: {
                    input: {
                      eventId: getEvent.id,
                      userId: guest.id,
                      orderId: currentOrder.id,
                    },
                  },
                }).then(() => setJustCompleted(true))
              }
              size="small"
              disabled={currentOrder.checkedIn || justCompleted}
              className={classes.completeButton}
            >
              Complete
            </Button>
          </div>
        )}
      </TableCell>
      {/* {guest.eventUser.orders.map((order) => order.notes)} */}
    </TableRow>
  );
};
