import { Button, Typography } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import classNames from "classnames";
import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { IDish, IEvent, IImage } from "src/models";
import { useStyles } from "./dish-dialog-carousel.styles";

interface DishDialogCarouselProps {
  images: IImage[];
  dish: IDish;
  getEvent: IEvent;
}

export const DishDialogCarousel: React.FC<DishDialogCarouselProps> = ({
  images = [],
  dish,
  getEvent,
}) => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  // HANDLERS
  const onClickLeft = () =>
    setActiveIndex((idx) => (idx > 0 ? idx - 1 : images.length - 1));
  const onClickRight = () => setActiveIndex((idx) => (idx + 1) % images.length);
  const containsMultiple = images.length > 1;

  return (
    <div className={classes.container}>
      <SwipeableViews
        onChangeIndex={(idx) => setActiveIndex(idx)}
        enableMouseEvents
        style={{ position: "relative" }}
        index={activeIndex}
      >
        {images.map((i) => (
          <img src={i.medium} className={classes.img} key={i.publicId} />
        ))}
      </SwipeableViews>
      <div className={classes.dishNameContainer}>
        <Typography variant="h4" component="h2" className={classes.name}>
          {dish.name}{" "}
        </Typography>
        {!getEvent.type.includes("FIXED_TIME") && (
          <Typography variant="h5" component="h5" className={classes.dishPrice}>
            ${dish.DishEvent?.price.toFixed(2)}
          </Typography>
        )}
      </div>

      {/* Center Dot Overview Buttons */}
      <div className={classes.overviewButtonsWrapper}>
        <ul className={classes.dotsWrapper}>
          {images.map((image, idx) => (
            <li
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                setActiveIndex(idx);
              }}
              className={classes.dotContainer}
              key={image.publicId}
            >
              <FiberManualRecordIcon
                style={{ fontSize: "0.7rem" }}
                className={
                  activeIndex === idx ? classes.dotActive : classes.dot
                }
              />
            </li>
          ))}
        </ul>
      </div>

      {/* Left and Right Navigation Buttons */}
      {containsMultiple && (
        <>
          <div className={classes.buttonLeftWrapper}>
            <Button onClick={onClickLeft}>
              <img
                className={classNames(classes.cheveron, classes.cheveronLeft)}
                src={require("src/assets/icons/event_arrow-19.svg")}
              />
            </Button>
          </div>
          <div className={classes.buttonRightWrapper}>
            <Button onClick={onClickRight}>
              <img
                className={classNames(classes.cheveron, classes.cheveronRight)}
                src={require("src/assets/icons/event_arrow-19.svg")}
              />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
