import {
  Card,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React from "react";
import { useHistory } from "react-router-dom";
import { HybridLink } from "src/components";
import { AppStatus } from "src/models";
import {
  IResource,
  pendingResources,
  submittedApprovedResources,
} from "./helpers";
import { useStyles } from "./resources.styles";

interface IResourcesCardProps {
  status: AppStatus;
}

export const ResourcesCard: React.FC<IResourcesCardProps> = ({ status }) => {
  const classes = useStyles();
  const history = useHistory();

  let resources: IResource[];

  switch (status) {
    case AppStatus.PENDING:
      resources = pendingResources;
      break;
    case AppStatus.ON_HOLD: // Out of county
      resources = pendingResources;
      break;
    case AppStatus.SUBMITTED:
      resources = submittedApprovedResources;
      break;
    case AppStatus.APPROVED:
      resources = submittedApprovedResources;
      break;
    default:
      resources = pendingResources;
      break;
  }

  return (
    <Card>
      <Typography variant="h2" component="h3" className={classes.header}>
        Resources
      </Typography>
      <List disablePadding>
        {resources.map((resource, idx: number) => (
          <>
            {resource.isExternal ? (
              <HybridLink href={resource.path} className={classes.hybridLink}>
                <ListItem key={idx} button classes={{ root: classes.listItem }}>
                  <ListItemContent resource={resource} idx={idx} />
                </ListItem>
              </HybridLink>
            ) : (
              <ListItem
                key={idx}
                button
                classes={{ root: classes.listItem }}
                onClick={() => history.push(resource.path)}
              >
                <ListItemContent resource={resource} idx={idx} />
              </ListItem>
            )}
            <Divider />
          </>
        ))}
      </List>
    </Card>
  );
};

interface IListItemContentProps {
  resource: any;
  idx: number;
}

const ListItemContent: React.FC<IListItemContentProps> = ({
  resource,
  idx,
}) => {
  const classes = useStyles();
  return (
    <>
      <ListItemText
        classes={{
          primary: classes.primaryTypography,
          secondary: classes.secondaryTypography,
        }}
        primary={
          <>
            <span className={classes.black}>{idx + 1}. </span>
            <span>{resource.title}</span>
          </>
        }
        secondary={resource.subtitle}
      />
      <ListItemSecondaryAction>
        <ChevronRightIcon color="secondary" fontSize="large" />
      </ListItemSecondaryAction>
    </>
  );
};
