import { useQuery } from "@apollo/client";
import pickBy from "lodash/pickBy";
import React from "react";
import { ErrorLoadingComponent } from "src/components";
import { IRestaurant, UpdateRestaurantPL } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { hostRestaurantAPI } from "./api";
import { GET_COOK_RESTAURANT } from "./api/graphql";
import { formatInitialFormState } from "./helpers";
import { Layout } from "./layout";

export const ProfileContainer: React.FC = () => {
  const { data, loading, error } = useQuery<{
    cookGetRestaurant: IRestaurant;
  }>(GET_COOK_RESTAURANT);

  const updateRestaurant = (d: UpdateRestaurantPL) => {
    return hostRestaurantAPI
      .updateRestaurant(d)
      .then(() => sharedAPI.setSnackbarMsg({ type: "success", msg: "Saved!" }))
      .catch((e) => {
        sharedAPI.setSnackbarMsg({
          type: "error",
          msg: e.message.replace("GraphQL error: ", ""),
        });
      });
  };

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }

  const init =
    data && data.cookGetRestaurant
      ? formatInitialFormState(
          pickBy(data.cookGetRestaurant, (value) => value !== null) as any
        )
      : undefined;

  return (
    <Layout
      restaurant={(data && data.cookGetRestaurant) as IRestaurant}
      updateRestaurant={updateRestaurant}
      uploadImg={hostRestaurantAPI.uploadImg}
      initialState={init}
    />
  );
};
