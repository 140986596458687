import { Button, Typography, useMediaQuery } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IGetMe } from "src/models";
import { FakeLinearProgress } from "src/screens/become-cook-info/components/fake-linear-progress/fake-linear-progress";
import { BREAK_POINTS } from "src/styles";
import { LocalStorageHelper } from "src/utils/helpers";
import { Questions } from "../../../components/questions/questions";
import { useStyles } from "../in-county.styles";

interface IProps {
  getMe: IGetMe;
}

export const AlamedaSolano: React.FC<IProps> = ({ getMe }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

  const history = useHistory();

  useEffect(() => {
    LocalStorageHelper.setItem("foodnome_become_cook_intro_complete", "true");
  }, []);

  useEffect(() => {
    const head = document.querySelector("head") as any;
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );

    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, []);

  return (
    <div className={classes.container}>
      <section className={classes.contentContainer}>
        <img
          src={require("../../../assets/big-squig.png")}
          className={classes.squig}
        />
        <div className={classes.subContentContainer}>
          {isMobile && (
            <Button
              startIcon={<ChevronLeft className={classes.backButtonArrow} />}
              className={classes.backButton}
              onClick={() => history.goBack()}
              size="large"
            >
              Back
            </Button>
          )}
          <Typography variant="h2" component="h2" className={classes.title}>
            <img
              alt="cook connect logo"
              width="42px"
              height="42px"
              className={classes.logo}
              src={require(`src/assets/logos/logo-icon.png`)}
            />
            Thanks, {getMe.firstName}!
          </Typography>
          <Typography variant="h2" component="h2" className={classes.subtitle}>
            Here's the next step:
          </Typography>
          <div className={classes.redBar} />

          <Typography component="p" variant="body1">
            Schedule to attend a Consultation Call, where we will go over all of
            the next steps to permit your home restaurant!
          </Typography>

          {/* This will show at the bottom for mobile */}
          {!isMobile && (
            <div className={classes.questionsContainer}>
              <Questions />
            </div>
          )}
        </div>
        <FakeLinearProgress />
      </section>

      <div
        className={classNames("calendly-inline-widget", classes.calendly)}
        data-url={`https://calendly.com/foodnome/consultation?name=${getMe.firstName}&email=${getMe.email}&hide_event_type_details=0`}
      />

      {/* Show only on mobile */}
      {isMobile && (
        <section className={classes.actionAndInfoSection}>
          <Button
            size="large"
            onClick={() => history.push("/c")}
            color="secondary"
            variant="contained"
          >
            GO TO PROFILE
          </Button>
          <div className={classes.questionsContainer}>
            <Questions />
          </div>
        </section>
      )}
    </div>
  );
};
