import { TextField, Typography } from "@material-ui/core";
import { MailOutline } from "@material-ui/icons";
import React from "react";
import { formStyles as useStyles } from "./styles";

interface IProps {
  email: string;
  onChange: ({
    target: { value, name },
  }: {
    target: { value: string; name: string };
  }) => void;
}

export const ContactFields: React.FC<IProps> = ({ email, onChange }) => {
  const classes = useStyles();

  // Using this unique id to prevent duplication errors since this component
  // is shown twice in the become-a-cook page
  const uniqueId = Date.now();
  return (
    <div>
      <Typography
        component="label"
        variant="caption"
        className={"hidden-label"}
        htmlFor={"become-cook-email-" + uniqueId}
      >
        Email*
      </Typography>
      <TextField
        size="small"
        autoComplete="new-password"
        {...({ "data-e2e": "become-cook-email-" + uniqueId } as any)}
        variant="outlined"
        InputProps={{
          labelWidth: 0,
          classes: {
            root: classes.textField,

            input: classes.input,
          },
          startAdornment: <MailOutline className={classes.startAdornment} />,
        }}
        placeholder="Email"
        type="email"
        name="email"
        value={email}
        id={"become-cook-email-" + uniqueId}
        onChange={onChange}
        className={classes.textField}
        required
        fullWidth
      />
    </div>
  );
};
