import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

const formStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  submitBtn: {
    margin: "0 auto",

    width: "100%",

    fontWeight: 500,

    padding: "1.5rem 2rem",
  },

  form: {
    display: "grid",
    gridTemplateRows: "repeat(5, auto)",
    gridRowGap: 20,
    padding: "1em",
    width: "100%",
    margin: "0 auto",
  },
  textField: {
    fontWeight: 100,
    backgroundColor: COLORS.WHITE,

    "&::placeholder": {
      fontWeight: 100,
      color: "#8D8D8D",
    },
  },
  input: {
    zIndex: 1,
    padding: "15px 20px",
    fontSize: theme.typography.h3.fontSize,

    "&::placeholder": {
      fontWeight: 100,
    },
  },

  label: {
    paddingLeft: "10px",
    color: "grey",

    marginBottom: "2px",
  },
  error: {
    color: COLORS.RED,

    position: "absolute",
    margin: 0,
  },
  col2: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridColumnGap: 20,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      gridTemplateRows: "auto auto",
      gridRowGap: 20,
      gridTemplateColumns: "unset",
      gridColumnGap: "unset",
    },
  },
  startAdornment: {
    zIndex: 1,
    color: "#8D8D8D",
  },
  link: {
    color: COLORS.MEDIUM_GREEN,
  },
  agreement: {
    textAlign: "center",
    color: COLORS.MEDIUM_GREY,
  },
}));

const layoutStyles = makeStyles({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "1em .5em",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "10px",
    },
  },
  formTitleRed: {
    margin: "0 0",
    color: "#FF5967",
    fontFamily: "Iowan Old Style",

    fontWeight: 900,

    textAlign: "center",
  },
  formTitleBlue: {
    margin: "0 0 38px 0",
    color: "#294E60",

    fontWeight: "bold",

    textAlign: "center",
    marginBottom: 40,
  },
  formDescription: {
    maxWidth: 340,
    width: "100%",
    color: "#446474",

    fontWeight: 300,

    textAlign: "center",
    margin: "0 0 50px 0",
  },
});

export { formStyles, layoutStyles };
