import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  flexCenter: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignSelf: "center",
    textAlign: "center",
  },

  btnSm: {
    width: "60%",
  },
  signUpInput: {
    color: "#294E60",
    "&::placeholder": {
      color: COLORS.DARK_GREY,
    },
  },
  inputRoot: {
    height: "40px",
    maxWidth: "37rem",
    margin: "auto",

    borderColor: `${COLORS.DARK_GREY} !important`,

    marginBottom: ".75rem",
  },
  formHelperText: {
    margin: "auto",
    color: COLORS.RED,
  },
  label: {
    position: "absolute",
    display: "block",
    border: "0px",
    margin: "-1px",
    padding: "0px",
    height: "1px",
    width: "1px",
    clip: "rect(0, 0, 0, 0)",
    overflow: "hidden",
  },
  logo: {
    margin: "auto",
  },
  dialogPadding: {
    padding: "5.5rem 10rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "1rem",
    },
  },
});
