import moment from "moment";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import { GoogleLogin } from "react-google-login";
import { useHistory, useLocation } from "react-router";
import { sharedAPI } from "src/shared-graphql";
import { isEmbeddedBrowser } from "src/utils/helpers";
import { authAPI } from "./graphql";

interface IGoogleAuthButtonProps {
  onSubmit?: (d?: any) => void;
  onError: (error: any) => void;
}

export const AuthWithGoogle: React.FC<IGoogleAuthButtonProps> = ({
  children,
  onSubmit,
  onError,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [isAvailable, setIsAvailable] = useState(true);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "cordova") {
      if (!(window as any).plugins || !(window as any).plugins.googleplus)
        setIsAvailable(false);
      if (
        (window as any).cordova &&
        (window as any).cordova.platformId === "android"
      )
        setIsAvailable(false);
    }
    // We want to disable 3rd party logins on Instagram and Facebook In-App-Browsers because
    // they disable popups and 3rd party cookies
    if (isEmbeddedBrowser()) setIsAvailable(false);
  }, []);
  const child = React.Children.only(children) as React.ReactElement;
  const queryObj = location
    ? (parse(location.search) as { code: string })
    : null;
  const referralCode = queryObj && queryObj.code ? queryObj.code : undefined;

  const googleSignIn = async (token: string) => {
    try {
      const d = await authAPI.google(token, referralCode);
      const createdAt = moment(Number(d.data.signInWithGoogle.createdAt));
      const duration = moment.duration(moment().diff(createdAt));
      if (duration.asSeconds() <= 10) {
        sharedAPI.setDiscountPrompt(true);
      }
      if (onSubmit) {
        setTimeout(() => onSubmit(d.data.signInWithGoogle), 500);
      } else {
        history.push("/d");
      }
    } catch (error) {
      onError(`Something wrong with your Google login. ${error}`);
    }
  };

  const nativeGoogleSignIn = () => {
    sharedAPI.setGlobalLoadingState(true);
    if ((window as any).plugins && (window as any).plugins.googleplus) {
      (window as any).plugins.googleplus.login(
        {
          webClientId: process.env.REACT_APP_GOOGLE_ID,
        },
        (obj) => {
          if (obj && obj.idToken) {
            googleSignIn(obj.idToken).then(() => {
              setTimeout(() => sharedAPI.setGlobalLoadingState(false), 1000);
            });
          } else {
            onError(
              `Oops! There was an error signing up with Google. Please try again later.`
            );
          }
        },
        (msg) => {
          sharedAPI.setGlobalLoadingState(false);
          onError(
            `Oops! There was an error signing up with Google. Please try again later. ${msg}`
          );
        }
      );
    }
  };

  if (!isAvailable) return null;
  return process.env.REACT_APP_ENV === "cordova" ? (
    <div onClick={nativeGoogleSignIn} style={{ width: "100%" }}>
      {children}
    </div>
  ) : (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_ID as string}
      render={(renderProps: any) =>
        React.cloneElement(child, {
          onClick: renderProps.onClick,
          disabled: renderProps.disabled,
        })
      }
      buttonText="Login"
      onRequest={() => sharedAPI.setGlobalLoadingState(true)}
      onSuccess={(d: any) => {
        return googleSignIn(d.tokenId).then(() =>
          setTimeout(() => sharedAPI.setGlobalLoadingState(false), 1000)
        );
      }}
      onFailure={(err) => {
        sharedAPI.setGlobalLoadingState(false);

        // Used to disable button if there is a cookies issue. Doesn't work well...
        // if (err && err.details) {
        //   if (/^cookies/i.test(err.details)) {
        //     console.log("cookiesss");
        //     setIsAvailable(false);
        //   }
        // }

        // tslint:disable-next-line: no-console
        console.error(err);
        return onError(
          err.details
            ? `${err.details} Please enable cookies in your browser settings.`
            : `${err.error}`
        );
      }}
    />
  );
};
