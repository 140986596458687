import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const layoutStyles = makeStyles({
  dialog: {},
  dialogPaperWidth: { maxWidth: "unset", margin: 0 },
  dialogContent: {
    padding: "0px",
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
    justifyContent: "space-between",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "unset",
    },
  },
  header: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  imgContainer: {
    width: "50%",
    backgroundColor: "#FFDA69",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      overflow: "visible",
      marginBottom: 41,
      zIndex: 1,
      height: 135,
    },
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      height: "20rem",
      overflow: "visible",
      objectPosition: "0% 92%",
      animation: `slide-in 500ms`,
    },
  },
  signUpContainer: {
    overflow: "hidden",
    maxWidth: "50%",
    minWidth: 300,
    padding: 10,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: "100%",
      margin: "0 auto",
    },
  },
  notFound: {
    width: "100%",
    margin: "1rem auto",
    maxWidth: 1128,
    padding: "0 1rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: 752,
    },
  },
  notFoundText: {
    fontFamily: "CustomFour", // was CustomFourBold before cook transition
    fontWeight: 500,
    textAlign: "center",
    margin: "0 auto",
    padding: "2rem 1rem",
    backgroundColor: "#F9F9F9",
  },
  closeButton: {
    height: 36,
    width: 36,
    zIndex: 2,
    padding: 0,
    position: "absolute",
    left: 6,
    top: 6,
    backgroundColor: COLORS.WHITE,
    "&:hover": {
      backgroundColor: COLORS.WHITE,
      opacity: 0.8,
    },
  },
  chipRoot: {
    transition: "none",
    borderRadius: 0,
    fontWeight: 500,

    textTransform: "capitalize",

    margin: "0 .25rem",
    backgroundColor: "#F4F4F6",

    color: "#626262",
  },
  deletable: {
    height: 30,
  },
  deleteIcon: {
    position: "static",
  },
  icon: {
    color: COLORS.MEDIUM_GREEN,
  },
  tagsLabel: {
    margin: 0,
  },
  tagsContainer: {
    width: "100%",
    maxWidth: 1128,
    margin: "1rem auto",
    padding: "0 1rem",
    display: "flex",
    alignItems: "center",
    overflowX: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      width: 0,
      height: 0,
    },
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      margin: "0px auto",
    },
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: 752,
    },
  },
  closeIcon: { color: COLORS.MEDIUM_RED },
  address: {
    color: "#626262",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    overflow: "hidden",
  },
  addressLabel: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    margin: 0,
  },
  addressContainer: {
    margin: "1rem auto",
    width: "100%",
    maxWidth: 1128,
    padding: "0 1rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: 752,
    },
  },
  addressContainerInner: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridGap: "2em",
    justifyItems: "flex-start",
    margin: "1rem 0",
  },
  chipRootLocation: {
    transition: "none",
    borderRadius: 0,
    fontWeight: 500,

    textTransform: "capitalize",

    margin: "0 .25rem",
    backgroundColor: COLORS.WHITE,
    color: COLORS.MEDIUM_RED,
    padding: "1rem",
    position: "relative",
    border: `1px solid #D4D4D4`,
  },
  deletableLocation: {
    height: 24,
  },
  deleteIconLocation: {},
  chipLabelLocation: {
    paddingLeft: 0,
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "inline-block",
    overflow: "hidden",
    maxWidth: 130,
  },
});
