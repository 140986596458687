import { client } from "src/apollo";
import { AdminUpdateRestaurantReviewPL } from "src/models";
import {
  ADMIN_DELETE_RESTAURANT_REVIEW,
  ADMIN_UPDATE_RESTAURANT_REVIEW,
} from "./graphql";

export const restaurantReviewAPI = {
  delteRestaurantReview: (d: number) => {
    return client.mutate({
      mutation: ADMIN_DELETE_RESTAURANT_REVIEW,
      variables: {
        input: d,
      },
    });
  },

  updateRestaurantReview: (d: AdminUpdateRestaurantReviewPL) => {
    return client.mutate({
      mutation: ADMIN_UPDATE_RESTAURANT_REVIEW,
      variables: {
        input: d,
      },
    });
  },
};
