import { client } from "src/apollo";
import { CreateComplaintPL } from "src/models";
import { CREATE_COMPLAINT } from "./graphql";

export const eventsAPI = {
  createComplaint: (createComplaintPL: CreateComplaintPL) =>
    client.mutate({
      mutation: CREATE_COMPLAINT,
      variables: { input: createComplaintPL },
    }),
};
