import { useMutation } from "@apollo/client";
import { Button, CircularProgress } from "@material-ui/core";
import dropIn, { Dropin } from "braintree-web-drop-in";
import React, { FC, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { IGetMe, RestaurantReviewStatus } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { GET_BT_TOKEN } from "../braintree/graphql/mutations";
import { formStyles } from "./reivew-form.styles";

interface IProps {
  submit: (status: RestaurantReviewStatus, token?: string) => Promise<void>;
  tipAmount: string;
  user: IGetMe;
}

export const BTDropIn: FC<IProps> = ({ submit, tipAmount, user }) => {
  const classes = formStyles();
  const history = useHistory();
  const [instance, setInstance] = useState<Dropin | null>(null);
  const [token, setToken] = useState("");
  const [getToken] = useMutation(GET_BT_TOKEN);
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);
  const buttonsRef = React.useRef<any>();

  useEffect(() => {
    getToken()
      .then((d) => {
        setToken(d.data.userGetBTToken);
        reinit(d.data.userGetBTToken);
      })
      .catch((error) => {
        if (error?.message?.includes("Token does not match")) {
          history.push("/login");
        } else if (error?.message?.includes("Must be logged in")) {
          history.push("/login");
        }
      });
  }, []);

  useEffect(() => {
    if (Number(tipAmount)) {
      buttonsRef.current &&
        buttonsRef.current.scrollIntoView &&
        buttonsRef.current.scrollIntoView();
    }
  }, [tipAmount]);

  const reinit = (_token: string) => {
    return dropIn
      .create({
        container: ref.current as any,
        authorization: _token,
        vaultManager: true,
        paypal: {
          flow: "vault",
          buttonStyle: {
            color: "blue",
            shape: "rect",
            size: "responsive",
            label: "checkout",
            tagline: "true",
          } as any,
        },
        // venmo: {
        //   allowNewBrowserTab: false,
        // },
      })
      .then(setInstance)
      .catch(
        (err: any) => new Error("Error loading BrainTree component: " + err)
      );
  };

  const onSubmit = () => {
    setLoading(true);
    if (!Number(tipAmount)) {
      return submit(RestaurantReviewStatus.SUBMITTED).finally(() =>
        setLoading(false)
      );
    } else {
      (window as any).cordova?.platformId === "ios" &&
        setTimeout(() => {
          setLoading(false);
          instance?.teardown();
          reinit(token);
        }, 2000);
      return instance?.requestPaymentMethod((err, payload) => {
        if (err || !payload) {
          setLoading(false);
          sharedAPI.setSnackbarMsg({ type: "error", msg: "Try again" });
        } else {
          submit(RestaurantReviewStatus.SUBMITTED, payload.nonce).finally(() =>
            setLoading(false)
          );
        }
      });
    }
  };

  return (
    <div className="bt-drop-in">
      <div
        ref={ref}
        style={{
          display: Number(tipAmount) ? "unset" : "none",
        }}
      />
      <div ref={buttonsRef} className={classes.btnContainer}>
        <Button
          disabled={loading}
          type="button"
          color="secondary"
          variant="outlined"
          className={classes.noBtn}
          onClick={() => submit(RestaurantReviewStatus.REJECTED)}
        >
          Skip
        </Button>

        <Button
          onClick={onSubmit}
          variant="contained"
          classes={{
            contained: classes.button,
          }}
          disabled={loading || !instance}
        >
          {loading || !instance ? (
            <CircularProgress size={18} className={classes.loader} />
          ) : (
            <>{!!Number(tipAmount) && `Tip $${tipAmount} & `}Submit </>
          )}
        </Button>
      </div>
    </div>
  );
};
