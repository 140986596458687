import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Fade } from "react-awesome-reveal";
import { useVisibility } from "src/utils/hooks";
import { useStyles } from "./benefits.styles";

export const Benefits: FC = () => {
  const classes = useStyles();
  const [isReviewsVisible, currentElement] = useVisibility<any>(25);

  const benefits = [
    {
      delay: 500,
      image: require("src/assets/icons/online-ordering.png"),
      title: "Online Ordering",
      text:
        "Access our easy-to-use digital ordering system built specifically for home-based food sales.",
    },
    {
      delay: 500,
      image: require("src/assets/icons/your-hours.png"),
      title: "Be Your Own Boss",
      text: "Your kitchen. Your menu. Your hours.",
    },
    {
      delay: 500,
      image: require("src/assets/icons/support.png"),
      title: "Business Support",
      text:
        "Get industry-leading marketing, permitting, & insurance support—proven success for 100s of chefs!",
    },
    {
      delay: 500,
      image: require("src/assets/icons/community.png"),
      title: "Network",
      text:
        "Join a supportive network of other culinary entrepreneurs as you build your business.",
    },
  ];

  return (
    <section ref={currentElement} className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.headerContainer}>
          {isReviewsVisible && (
            <Fade triggerOnce={true} delay={250}>
              <Typography variant="h2" component="h2" className={classes.title}>
                COOK Connect Benefits
              </Typography>
            </Fade>
          )}
          <Fade triggerOnce={true} delay={350}>
            <div className={classes.squiggleContainer}>
              <img
                src={require("src/screens/home-new/assets/underline.svg")}
                className={classes.squiggle}
              />
            </div>
          </Fade>
        </div>
        <div className={classes.mainContent}>
          {benefits.map((benefit) => (
            <Fade
              key={benefit.title}
              className={classes.card}
              triggerOnce={true}
              delay={benefit.delay}
            >
              <div className={classes.innerCard}>
                <img
                  src={benefit.image}
                  className={classes.image}
                  alt="cook image"
                />
                <div className={classes.textContainer}>
                  <Typography
                    variant="h4"
                    component="p"
                    className={classes.title}
                  >
                    {benefit.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className={classes.text}
                  >
                    {benefit.text}
                  </Typography>
                </div>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </section>
  );
};
