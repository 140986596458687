import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { PaginationComponent } from "src/components";
import { IApplication, IGetMe } from "src/models";
import { ApplicationTable } from "src/screens/admin/application/list/table";

interface IProps {
  applications: IApplication[];
  redirect: (d: string) => void;
  totalCount: number;
  fetchMore: (d: number) => any;
  createApplicationStatus: (d: any) => void;
  deleteApplicationStatus: (d: any) => void;
  resetPage: boolean;
  status: string;
  getMe: IGetMe;
  descriptionSearchTerm: string;
}

const Message = ({ status }) => (
  <Typography
    variant="body2"
    component="p"
  >{`Applications: Looks like there are no "${status}" applications.`}</Typography>
);

export const BodyContainer: FC<IProps> = ({
  applications,
  redirect,
  totalCount,
  fetchMore,
  resetPage,
  descriptionSearchTerm,
  createApplicationStatus,
  deleteApplicationStatus,
  getMe,
  status,
}) => {
  if (!Boolean(totalCount)) return <Message status={status} />;

  return (
    <>
      <div style={{ width: "100%", overflowX: "scroll" }}>
        {applications.map((app) => (
          <ApplicationTable
            key={app.id}
            getMe={getMe}
            descriptionSearchTerm={descriptionSearchTerm}
            application={app}
            redirect={redirect}
            createApplicationStatus={createApplicationStatus}
            deleteApplicationStatus={deleteApplicationStatus}
          />
        ))}
      </div>
      <PaginationComponent
        totalCount={totalCount}
        onChange={fetchMore}
        resetPage={resetPage}
        defaultPageSize={30}
      />
    </>
  );
};
