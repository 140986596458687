import { MutationFunctionOptions } from "@apollo/client";
import {
  Avatar,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { RadioButtonChecked, RadioButtonUnchecked } from "@material-ui/icons";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { INotification, NotificationStatus } from "src/models";
import { BREAK_POINTS } from "src/styles";
import { dateToFromNowDaily } from "src/utils/helpers";
import { useNotificationStyles } from "./notification.styles";

interface IProps {
  updateNotification: (d: MutationFunctionOptions) => void;
  notification: INotification;
}

export const Notification: FC<IProps> = ({
  updateNotification,
  notification,
}) => {
  const classes = useNotificationStyles();
  const history = useHistory();
  const [hovered, setHover] = useState(false);
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  return (
    <div
      data-testid="Notifications_notification"
      className={classes.container}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        backgroundColor:
          notification.status === NotificationStatus.READ
            ? "unset"
            : "aliceblue",
      }}
    >
      <div
        className={classes.subContainer}
        onClick={() => {
          updateNotification({
            variables: {
              input: { id: notification.id, status: NotificationStatus.READ },
            },
          });
          history.push(notification.redirectUrl);
        }}
      >
        <div className={classes.subSubContainer}>
          {notification.imageUrl && (
            <Avatar
              className={classes.avatar}
              alt={"No Image"}
              src={notification.imageUrl}
            />
          )}
          <div className={classes.descriptionContainer}>
            <Typography
              variant="body2"
              component="p"
              dangerouslySetInnerHTML={{ __html: notification.headings }}
              className={classes.heading}
            ></Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.createdAt}
            >
              {dateToFromNowDaily(Number(notification.createdAt))}
            </Typography>
          </div>
        </div>

        <div
          style={{
            visibility: hovered ? "unset" : "hidden",
            display: isMobile ? "none" : "unset",
          }}
          className={classes.actionBtnsContainer}
        >
          {notification.status === NotificationStatus.READ ? (
            <Tooltip placement="top" title="Mark as Unread">
              <IconButton
                className={classes.iconBtn}
                disableFocusRipple
                disableRipple
                onClick={(event) => {
                  event.stopPropagation();
                  updateNotification({
                    variables: {
                      input: {
                        id: notification.id,
                        status: NotificationStatus.UNREAD,
                      },
                    },
                  });
                }}
              >
                <RadioButtonUnchecked className={classes.icon} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip placement="top" title="Mark as Read">
              <IconButton
                className={classes.iconBtn}
                disableFocusRipple
                disableRipple
                onClick={(event) => {
                  event.stopPropagation();
                  updateNotification({
                    variables: {
                      input: {
                        id: notification.id,
                        status: NotificationStatus.READ,
                      },
                    },
                  });
                }}
              >
                <RadioButtonChecked className={classes.icon} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      <Divider />
    </div>
  );
};
