import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles/colors";

export const useStyles = makeStyles({
  navBarItems: {
    display: "flex",
    padding: 0,
    height: "100%",
  },
  noBorderMenuItem: {
    display: "flex",
    justifyContent: "center",

    fontWeight: 500,

    cursor: "pointer",
    fontFamily: "CustomFourBold",
    padding: 0,
    height: "100%",
    width: "auto",
    minWidth: 120,
    textTransform: "uppercase",
    color: COLORS.DARK_GREY,
    "&:hover": {
      backgroundColor: COLORS.WHITE,
      color: COLORS.MEDIUM_RED,
      transition: "all 300ms linear",
    },
  },
  outlinedButton: {
    display: "flex",
    justifyContent: "center",
    color: COLORS.MEDIUM_RED,
    border: `solid ${COLORS.MEDIUM_RED} 2px`,
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    padding: "0 10px",
    margin: "10px 10px",
    width: "auto",
    minWidth: 90,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_RED,
      color: COLORS.WHITE,
      border: `solid ${COLORS.MEDIUM_RED} 2px`,
    },
  },
});
