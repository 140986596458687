import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close, QuestionAnswer } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { UserAvatar } from "src/components";
import { useCreateChannel } from "src/components/chat2";
import { IEventUser, IUser } from "src/models";
import { OrderedItems } from "src/screens/cook/event/detail/timeline-view/user-list";
import { COLORS } from "src/styles";
import { openAddress } from "src/utils/helpers";
import { RefundDialog } from "./refund-dialog";
import { useStyles } from "./styles";

interface IProps {
  targetUser: IUser | null;
  onExited: () => void;
  currentOrder?: IEventUser;
}

declare let window: any;

export const DinerOrderDialog: React.FC<IProps> = ({
  targetUser,
  onExited,
  currentOrder,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(Boolean(targetUser));

  useEffect(() => {
    setOpen(Boolean(targetUser));
  }, [targetUser]);

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      classes={{ paperScrollPaper: classes.paper }}
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      aria-describedby="DinerOrderDialog-title"
      onExited={onExited}
    >
      <DialogBody
        targetUser={targetUser}
        handleClose={handleClose}
        currentOrder={currentOrder}
      />
    </Dialog>
  );
};

const DialogBody: React.FC<{
  targetUser: IUser | null;
  handleClose: () => void;
  currentOrder?: IEventUser;
}> = ({ targetUser, handleClose, currentOrder }) => {
  const { loading, createChannel } = useCreateChannel();
  const classes = useStyles();

  if (!targetUser) return null;

  return (
    <>
      <IconButton
        style={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}
        onClick={handleClose}
        data-testid="close-diner-order-dialog"
        aria-label="close"
      >
        <Close style={{ color: COLORS.MEDIUM_GREEN }} />
      </IconButton>
      <DialogTitle
        disableTypography
        className={classes.dialogTitle}
        id="DinerOrderDialog-title"
      >
        <Typography variant="h4" component="h4" style={{ marginBottom: 0 }}>
          {targetUser.firstName} {targetUser.lastName}
        </Typography>
        <Typography variant="body1">
          {targetUser.phoneNumber && (
            <a href={`tel:${targetUser.phoneNumber}`}>
              {targetUser.phoneNumber}
            </a>
          )}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div>
          <UserAvatar classes={{ root: classes.avatar }} user={targetUser} />
        </div>

        {currentOrder?.notes && (
          <section className={classes.section}>
            <Typography variant="h4" component="h4">
              Notes:
            </Typography>
            <ul className={classes.instructions}>
              {currentOrder?.notes
                .split("; ")
                .filter(Boolean)
                .map((n) => (
                  <li key={n} style={{ whiteSpace: "pre-wrap" }}>
                    <Typography component="p" variant="body2">
                      {n}
                    </Typography>
                  </li>
                ))}
            </ul>
          </section>
        )}
        {currentOrder?.dineOption === "DELIVERY" && (
          <section className={classes.section}>
            <Typography variant="h4" component="h4">
              Delivery Address:
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.link}
              style={{ margin: "6px 0" }}
              onClick={() =>
                openAddress(
                  `http://maps.google.com/maps?q=${currentOrder?.deliveryAddress
                    .split(/[\s,]+/)
                    .join("+")}`
                )
              }
            >
              {currentOrder?.deliveryAddress}
            </Typography>
          </section>
        )}

        {!!currentOrder?.orderedDishes?.length && (
          <section className={classes.section}>
            <Typography variant="h4" component="h4">
              Orders:
            </Typography>
            <OrderedItems
              join={"\r\n"}
              guest={targetUser}
              classes={classes}
              eventUser={currentOrder}
            />
          </section>
        )}
      </DialogContent>
      <DialogActions>
        <RefundDialog guest={targetUser} currentOrder={currentOrder} />
        <Button
          style={{ minWidth: 91 }}
          onClick={() => targetUser && createChannel(targetUser.id)}
          variant="contained"
          color="secondary"
          classes={{ contained: classes.button }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <QuestionAnswer style={{ marginRight: ".5em" }} /> {" Chat"}
            </>
          )}
        </Button>
      </DialogActions>
    </>
  );
};
