import { gql } from "@apollo/client";

export const GET_RESTAURANTS = gql`
  query adminGetRestaurants($input: AdminGetRestaurantsPL) {
    adminGetRestaurants(adminGetRestaurantsPL: $input) {
      count
      rows {
        id
        name
        description
        address
        hostBio
        tags
        serviceFeePerc
        serviceFeeCap
        serviceFeeInfo {
          month
          paid
        }
        imagesGQL {
          publicId
          small
          medium
          large
          original
        }
        county
        zipCode
        taxRate
        updatedAt
        createdAt
        permitNumber
        overallReview {
          count
          stars
          quality
          accuracy
          hospitality
        }
        host {
          id
          email
          phoneNumber
        }
        info {
          hasDog
          hasCat
          otherAnimals
        }
        badges {
          managerCertificate
          insurance
        }
        application {
          id
          status
        }
        complaints {
          id
          userId
          complaint
        }
      }
    }
  }
`;

export const ADMIN_GET_RESTAURANT = gql`
  query adminGetRestaurant($input: AdminGetRestaurantPL!) {
    adminGetRestaurant(adminGetRestaurantPL: $input) {
      id
      name
      host {
        firstName
        lastName
        email
        phoneNumber
        payments {
          id
          totalAmount
          createdAt
        }
      }
      events {
        id
        name
        startTime
        eventUsers {
          id
          paidAmount
          creditAmount
          taxAmount
        }
      }
    }
  }
`;
