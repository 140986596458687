import { makeStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

const useStyles = makeStyles({
  tooltip: {
    margin: 0,
  },
});

export const GrandOpeningBadge: React.FC = () => {
  const classes = useStyles();
  return (
    <div>
      <Tooltip
        placement="bottom"
        classes={{ tooltipPlacementBottom: classes.tooltip }}
        title="Grand opening!"
      >
        <img
          src={require("./opening badge-02.svg")}
          style={{ width: "10rem" }}
          data-testid="GrandOpeningBadge-banner"
        />
      </Tooltip>
    </div>
  );
};
