import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles/colors";

export const useStyles = makeStyles({
  actionBtn: {
    color: COLORS.MEDIUM_GREEN,
    borderColor: COLORS.MEDIUM_GREEN,
    minWidth: 90,
  },
  dialogBtn: {
    minWidth: 90,
  },
  dialogContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  messageBox: {
    marginTop: "2rem",
  },
});
