import { Typography } from "@material-ui/core";
import React, { FC } from "react";

export const PrivacyPolicy: FC = () => {
  return (
    <div
      style={{
        margin: "2rem",
        marginBottom: "calc(env(safe-area-inset-bottom) + 8rem)",
      }}
    >
      <Typography variant="h1" component="h1" style={{ marginBottom: "1rem" }}>
        Privacy Policy
      </Typography>
      <Typography variant="body1" component="p">
        The purpose of this Privacy Policy is to inform you about what
        information we collect from you as a user of this website and any of our
        mobile applications. We have summarized this in the simplest terms
        below:
      </Typography>
      <ol style={{ listStyleType: "disc", marginLeft: "2rem" }}>
        <li>
          <Typography variant="body2" component="p">
            This Privacy Policy is for users of the websites
            www.cookconnect.com, www.foodnome.com and any other mobile
            applications provided by COOK Alliance.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" component="p">
            This Policy does not apply to any other websites and we are not
            responsible for the content or privacy policies of other sites,
            including any that are reached through a link on this website.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" component="p">
            We are required under the Data Protection Act, to protect any
            information we collect from you and will use administrative,
            technical, and physical measures to safeguard personal information
            against loss, theft and unauthorized uses, access or modifications
            of it.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" component="p">
            Personal information may be collected from you, such as your Name,
            IP address, Email address, Mailing address, Credit Card details, and
            your Phone number. We may also analyze visitors and usage patterns
            of such visits to the website in order to make improvements to the
            website and the information within it and to be able to send you
            relevant information from time to time.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" component="p">
            We will only share personal information where compelled to do so
            under legal processes or statute.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" component="p">
            We will not sell or pass such information to third parties without
            your specific permission. However, whilst we will do everything we
            can to maintain the security of your personal information, it is not
            possible to guarantee it and therefore if you supply such
            information you do so at your own risk. If you find that the
            information we hold is incorrect or you wish to remove it, please
            contact us at tech@cookalliance.org .
          </Typography>
        </li>
      </ol>
    </div>
  );
};
