import { useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { Mail } from "@material-ui/icons";
import React, { FC, useState } from "react";
import { CLIENT_ADDRESS } from "src/index";
import { IEvent } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { COOK_MESSAGE_FOLLOWERS } from "./graphql";
import { useStyles } from "./message.styles";

interface IProps {
  event: IEvent;
}
export const Message: FC<IProps> = ({ event }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [text, setText] = useState(
    `Hello, \n\nReserve with the link below: \n${CLIENT_ADDRESS}/menus/${event.id}`
  );
  const [message] = useMutation(COOK_MESSAGE_FOLLOWERS);

  return (
    <>
      <Button
        variant="outlined"
        title="share to social media"
        classes={{ root: classes.actionBtn }}
        onClick={() => setOpen(true)}
        endIcon={<Mail />}
        size="small"
      >
        Message
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          <Typography variant="h3" component="h3">
            Send a message to all your followers
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContainer}>
          <TextField
            value={subject}
            label="Email Subject"
            fullWidth
            variant="outlined"
            required
            onChange={(e) => setSubject(e.target.value)}
          />
          <TextField
            value={text}
            label="Message"
            className={classes.messageBox}
            fullWidth
            variant="outlined"
            multiline
            rows={6}
            required
            onChange={(e) => setText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            classes={{ root: classes.actionBtn }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            classes={{ root: classes.dialogBtn }}
            variant="contained"
            color="secondary"
            disabled={!text || !subject}
            onClick={() =>
              message({
                variables: {
                  input: {
                    subject,
                    text,
                  },
                },
              })
                .then(() => {
                  sharedAPI.setSnackbarMsg({ type: "success", msg: "Done" });
                  setOpen(false);
                })
                .catch(() =>
                  sharedAPI.setSnackbarMsg({
                    type: "error",
                    msg: "Sorry, msg did not send.",
                  })
                )
            }
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
