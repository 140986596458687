import {
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React from "react";

export const Header = ({ totalCount, onSearch, searchTerm }) => {
  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="h3" component="h1" style={{ marginBottom: "2rem" }}>
        Restaurants ({totalCount})
      </Typography>
      <TextField
        size="small"
        value={searchTerm}
        variant="outlined"
        label="Search by email or cook name"
        style={{ maxWidth: "40rem", width: "100%" }}
        onChange={(e) => onSearch(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </Paper>
  );
};
