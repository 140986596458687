import { useQuery } from "@apollo/client";
import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import React from "react";
import { IAllergen } from "src/models";
import { GET_ALLERGENS } from "src/screens/admin/allergen/api/graphql";
import { useStyles } from "./styles";

interface IProps {
  onSelect: (selectedAllergens: IAllergen[]) => void;
  selectedAllergens?: IAllergen[];
}

export const AllergenSelect: React.FC<IProps> = ({
  selectedAllergens = [],
  onSelect,
}) => {
  const classes = useStyles();
  const { data, error, loading } = useQuery(GET_ALLERGENS);
  const isSelected = (id: string) => selectedAllergens.some((a) => a.id === id);
  const handleSelect = (allergen: IAllergen) => () =>
    onSelect(
      isSelected(allergen.id)
        ? selectedAllergens.filter((a) => a.id !== allergen.id)
        : selectedAllergens.concat([allergen])
    );

  if (loading)
    return (
      <Typography variant="body2" component="p">
        Loading...
      </Typography>
    );
  if (error)
    return (
      <Typography variant="body2" component="p">
        Error: {error.message}
      </Typography>
    );

  return (
    <FormControl
      component={"fieldset" as "div"}
      className={classes.formControl}
    >
      <FormLabel
        component={"legend" as "label"}
        classes={{
          root: classes.formLabel,
          focused: "focused",
        }}
      >
        Allergens
      </FormLabel>
      <FormGroup
        data-testid="AllergenSelect_allergens"
        className={classes.formGroup}
      >
        {data.getAllergens.rows.map((allergen) => {
          return (
            <FormControlLabel
              classes={{ label: classes.rootCheckLabel }}
              key={allergen.id}
              label={allergen.name}
              control={
                <Checkbox
                  checked={isSelected(allergen.id)}
                  onChange={handleSelect(allergen)}
                  value={allergen.name}
                />
              }
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};
