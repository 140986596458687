import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    backgroundColor: COLORS.WHITE,
    position: "relative",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    maxWidth: "140rem",
    margin: "9rem auto",
    marginBottom: "15rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      margin: "5rem auto",
      marginBottom: "6rem",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginBottom: "3rem",
    },
  },
  squiggleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
  },
  squiggle: {
    width: "60%",
    minWidth: "12rem",
    maxWidth: "18rem",
  },
  title: {
    fontFamily: "CustomFourBold",
    textAlign: "center",
  },
  mainContent: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    alignItems: "center",
    justifyContent: "center",
    placeItems: "center",
    padding: "0 4rem",
    columnGap: "7rem",
    rowGap: "7rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      gridTemplateColumns: "repeat(1, 1fr)",
      columnGap: "3rem",
      rowGap: "3rem",
      padding: "0 2rem",
    },
  },
  card: {
    backgroundColor: "#FFFAFA",
    borderRadius: "1rem",
    boxShadow: "0 2px 16px 0 rgba(0,0,0,0.22)",
    width: "100%",
    maxWidth: "56rem",

    // height: "100%",
  },
  innerCard: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gridAutoFlow: "column",
    padding: "3rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  text: {
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-word",
    WebkitBoxOrient: "vertical",
    lineClamp: 3,
    WebkitLineClamp: 3,
    fontFamily: "CustomFour",
    marginTop: "2rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      lineClamp: 5,
      WebkitLineClamp: 5,
    },
  },
  reviewAuthor: {
    color: "#18171C",
  },
  tomato: {
    position: "absolute",
    top: "-4%",
    right: "-2%",
    width: "17%",
    maxWidth: "14rem",
    minWidth: "6rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      top: "-3%",
    },
  },
  image: {
    width: "80%",
    maxWidth: "17rem",
    margin: "auto",
  },
  textContainer: {
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});
