import { gql } from "@apollo/client";

export const GET_EVENTS = gql`
  query getEvents($input: GetEventsPL) {
    getEvents(getEventsPL: $input) {
      count
      rows {
        id
        name
        description
        status
        bufferTime
        imagesGQL {
          publicId
          small
          medium
          large
          original
        }
        price
        deliveryInfo {
          maximumDriveTime
          minimumOrderAmount
          pricePerMin
          pricePerMile
        }
        type
        restaurant {
          id
          host {
            id
            email
          }
        }
        tags
        hidden
        locale {
          public
          private
        }
        dishes {
          id
          name
          __typename @skip(if: true)
          DishEvent {
            id
            price
            sold
            count
            options {
              name
              addition
              sold
              count
            }
          }
        }
        guests {
          id
          __typename @skip(if: true)
          firstName
          lastName
          email
          createdAt
          picture
          phoneNumber
          points {
            eventsAttended
          }
          EventUser {
            id
            reservedFor
            createdAt
            source
            couponInfo {
              id
              code
              newUser
              deduction
            }
            paidAmount
            creditAmount
            status
          }
        }
        reserveBy
        startTime
        endTime
      }
    }
  }
`;
