import { CircularProgress } from "@material-ui/core";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { EventCardDesktop } from "src/components";
import { IEvent } from "src/models";
import "./animations.css";
import { eventCardsStyles as useStyles } from "./styles";

interface IProps {
  searchEvents: { count: number; rows: IEvent[] };
  loading: boolean;
}

export const DesktopEventsList: React.FC<IProps> = ({
  loading,
  searchEvents,
}) => {
  const classes = useStyles();
  if (loading)
    return (
      <div className={classes.loadingWrapper}>
        <CircularProgress data-testid="loading-spinner" />
      </div>
    );

  return (
    <div style={{ margin: "1.5rem", display: "flex" }}>
      <TransitionGroup component={"ol"} className={classes.container}>
        {searchEvents.rows.map((event) => (
          <CSSTransition
            unmountOnExit
            key={event.id}
            classNames="fade-in"
            timeout={500}
          >
            <li className={classes.eventCardWrapper}>
              <EventCardDesktop event={event} />
            </li>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
};
