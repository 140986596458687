import {
  Button,
  Divider,
  Radio,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React, { FC, useContext, useEffect } from "react";
import { DropSelect } from "src/components";
import { IEvent, IGetMe } from "src/models";
import { amplitude } from "src/services";
import { BREAK_POINTS } from "src/styles";
import { eventTypeMap } from "src/utils/helpers";
import { getMenuItems } from "../../helpers";
import { OrderContext } from "../../order-context";
import { ActionTypes } from "../../order-reducer";
import { useSelectDropInStyles } from "./select-drop-in.styles";

interface IProps {
  error?: boolean;
  variation?: string;
  getEvent: IEvent;
  getMe?: IGetMe;
}
export const SelectDropIn: FC<IProps> = React.memo(
  ({ getEvent, variation, error = false, getMe }) => {
    const classes = useSelectDropInStyles();
    const {
      state: { arrivalTime, dineOption, orderedDishes, seats },
      dispatch,
    } = useContext(OrderContext);

    const items = getMenuItems(getEvent, dineOption);
    const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

    // This is used to set a time on render. I comment this out to force users to
    // pick a time before checkout
    useEffect(() => {
      dispatch({
        type: ActionTypes.SET_ARRIVAL_TIME,
        payload: !!arrivalTime
          ? arrivalTime
          : getEvent.type.includes("FIXED_TIME")
          ? getEvent.startTime
          : "",
      });
    }, [getEvent.id]);

    const onSelect = (d: string) => {
      dispatch({
        type: ActionTypes.SET_DINE_OPTION,
        payload: d,
      });
    };

    const totalCost = getEvent.type.includes("FIXED_TIME")
      ? getEvent.price * seats
      : orderedDishes.reduce((acc, next) => {
          let _total = 0;
          if (next.options) {
            _total = next.options?.reduce(
              (_acc, _next) =>
                _acc +
                (_next?.count ?? 0) *
                  (_next?.addition + (next.DishEvent?.price ?? 0)),
              0
            );
          } else {
            _total = (next.DishEvent?.price ?? 0) * next.count;
          }
          return acc + _total;
        }, 0);

    return (
      <div className={classes.container}>
        <div style={{ border: error ? "1px solid red" : "" }}>
          {/* ========= SINGLE BUTTON ========= */}

          {getEvent.type.length === 1 && (
            <section className={classes.radioContainer}>
              <button className={classes.singleBtn}>
                {eventTypeMap[getEvent.type[0]]}
              </button>
            </section>
          )}
          {/* ========= PICKUP / DROP IN ========= */}
          {getEvent.type.length !== 1 && (
            <section
              data-testid="select-drop-container"
              className={classes.radioContainer}
            >
              <Button
                data-testid={`select-drop-0`}
                disableElevation
                classes={{
                  root:
                    dineOption === getEvent.type[0]
                      ? classes.selectedBtn
                      : classes.nonSelectedBtn,
                  label: isMobile ? classes.btnLabel : classes.btnLabel,
                  contained:
                    dineOption === getEvent.type[0]
                      ? classes.btnContainedLeftActive
                      : classes.btnContainedLeft,
                }}
                fullWidth
                variant="contained"
                onClick={() => onSelect(getEvent.type[0])}
              >
                <div
                  style={
                    isMobile
                      ? {
                          display: "flex",
                          flexDirection: "row-reverse",
                          alignItems: "center",
                          paddingRight: 9,
                        }
                      : {}
                  }
                >
                  <Typography
                    variant="subtitle2"
                    component="span"
                    className={classes.radioLabel}
                  >
                    {eventTypeMap[getEvent.type[0]]}
                  </Typography>
                  <Radio
                    checked={dineOption === getEvent.type[0]}
                    value={getEvent.type[0]}
                    name="radio-button-demo"
                    color="secondary"
                    inputProps={{ "aria-label": "A" }}
                  />
                </div>
              </Button>
              <Button
                disableElevation
                data-testid={`select-drop-1`}
                classes={{
                  root:
                    dineOption === getEvent.type[1]
                      ? classes.selectedBtn
                      : classes.nonSelectedBtn,
                  label: isMobile ? classes.btnLabelMobile : classes.btnLabel,
                  contained:
                    dineOption === getEvent.type[1]
                      ? classes.btnContainedRightActive
                      : classes.btnContainedRight,
                }}
                fullWidth
                variant="contained"
                onClick={() => onSelect(getEvent.type[1])}
              >
                <div
                  style={
                    isMobile
                      ? {
                          display: "flex",
                          flexDirection: "row-reverse",
                          alignItems: "center",
                          paddingRight: 9,
                        }
                      : {}
                  }
                >
                  <Typography
                    variant="subtitle2"
                    component="span"
                    className={classes.radioLabel}
                  >
                    {eventTypeMap[getEvent.type[1]]}
                  </Typography>
                  <Radio
                    checked={dineOption === getEvent.type[1]}
                    value={getEvent.type[1]}
                    name="radio-button-demo"
                    color="secondary"
                    inputProps={{ "aria-label": "A" }}
                  />
                </div>
              </Button>
            </section>
          )}

          {/* ========= DROP SELECT ========= */}
          <section className={classes.dropSelectContainer}>
            <DropSelect
              inputLabel="PICK A TIME"
              defaultLabel="PICK A TIME"
              menuItems={items}
              onSelect={(value) => {
                amplitude
                  .getInstance()
                  .logEvent("[Event Detail] set arrival time");
                dispatch({
                  type: ActionTypes.SET_ARRIVAL_TIME,
                  payload: value,
                });
              }}
              reset={false}
            />
          </section>
        </div>
        <div>
          {dineOption === "DELIVERY" &&
          getEvent.deliveryInfo &&
          totalCost < getEvent.deliveryInfo.minimumOrderAmount ? (
            <>
              <Divider />
              <Typography
                component="p"
                variant="caption"
                className={classes.minDeliveryText}
              >
                {`Minimum delivery amount is $${getEvent.deliveryInfo?.minimumOrderAmount}.`}
              </Typography>
            </>
          ) : dineOption === "DELIVERY" && getMe?.address ? (
            <>
              <Divider />

              <Typography
                variant="caption"
                component="p"
                className={classes.deliveryText}
                onClick={() =>
                  dispatch({
                    type: ActionTypes.SET_ADDRESS_DIALOG_STATUS,
                    payload: { url: "" },
                  })
                }
              >
                {"Delivery to: " + getMe?.address}
                <Edit className={classes.editDeliveryTextIcon} />
              </Typography>
            </>
          ) : null}
        </div>
      </div>
    );
  }
);
