import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    padding: "5rem 0",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  title: {
    fontFamily: "CustomFourBold",
    marginBottom: "2rem",
  },
  payment: {
    width: "27rem",
    paddingRight: "5rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: 0,
      paddingBottom: "3rem",
    },
  },
  braintreeBadge: {
    width: "164px",
    height: "44px",
  },
  paymentContainer: {
    display: "flex",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexDirection: "column",
    },
  },
});
