import { MenuItem, OutlinedInput, Select, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { DietaryTypes } from "src/models";
import { useStyles } from "./select.styles";

interface DietaryTypeSelectProps {
  onSelect?: (type: DietaryTypes) => void;
  initialState?: DietaryTypes;
  menuItems?: DietaryTypes[];
}

export const DietaryTypeSelect: React.FC<DietaryTypeSelectProps> = ({
  onSelect,
  initialState = DietaryTypes.NONE,
  menuItems = ["NONE", "PESCETARIAN", "VEGETARIAN", "VEGAN", "KETO"],
}) => {
  const classes = useStyles();
  const [activeType, setActiveType] = useState<DietaryTypes>(initialState);

  const onChangeHandler = (e) => {
    const selectedType = e.target.value as DietaryTypes;
    setActiveType(selectedType);
    if (onSelect) onSelect(selectedType);
  };

  return (
    <div className={classes.container}>
      <Typography
        component="label"
        variant="caption"
        className={classes.label}
        htmlFor="dietary-type-select"
      >
        Dietary
      </Typography>
      <Select
        classes={{
          selectMenu: classes.selectMenu,
        }}
        input={
          <OutlinedInput
            labelWidth={0}
            classes={{ notchedOutline: classes.notchedOutline }}
          />
        }
        inputProps={{
          id: "dietary-type-select",
          name: "dietary",
        }}
        value={activeType}
        onChange={onChangeHandler}
      >
        {menuItems.map((item) => (
          <MenuItem
            style={{ textTransform: "capitalize" }}
            key={item}
            classes={{ root: classes.menuItem }}
            value={item}
          >
            {item.toLowerCase()}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
