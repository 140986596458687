import { Paper } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { IApplication, IGetMe } from "src/models";
import { BodyContainer } from "./body";
import { IFetchMorePL } from "./container";
import { Header } from "./header";

interface IProps {
  getMe: IGetMe;
  redirect: (d: string) => void;
  applications: IApplication[];
  loading: boolean;
  error: any;
  totalCount: number;
  fetchMore: (d: IFetchMorePL) => any;
  status: string;
  county: string;
  assignee: string;
  setStatus: (d: string) => void;
  setCounty: (d: string) => void;
  setAssignee: (d: string) => void;
  resetPage: boolean;
  onSearch: (d: string) => void;
  descriptionSearchTerm: string;
  setDescriptionSearchTerm: (d: string) => void;
  createApplicationStatus: (d: any) => void;
  deleteApplicationStatus: (d: any) => void;
}

export const Layout: FunctionComponent<IProps> = ({
  applications,
  fetchMore,
  totalCount,
  redirect,
  status,
  county,
  assignee,
  setStatus,
  setCounty,
  setAssignee,
  descriptionSearchTerm,
  resetPage,
  onSearch,
  setDescriptionSearchTerm,
  getMe,
  createApplicationStatus,
  deleteApplicationStatus,
}) => {
  const _fetchMore = (d: number) =>
    fetchMore({
      pagePL: { offset: (d - 1) * 30, limit: 30 },
      status: status === "ALL" ? undefined : status,
      county: county === "ALL" ? undefined : county,
      assignee: assignee === "ALL" ? undefined : assignee,
    });

  return (
    <div style={{ width: "100%", minWidth: "min-content" }}>
      <Header
        totalCount={totalCount}
        status={status}
        county={county}
        assignee={assignee}
        setAssignee={setAssignee}
        setStatus={setStatus}
        setCounty={setCounty}
        onSearch={onSearch}
        setDescriptionSearchTerm={setDescriptionSearchTerm}
      />
      <Paper style={{ margin: "1rem", padding: "1rem" }}>
        <BodyContainer
          applications={applications}
          redirect={redirect}
          totalCount={totalCount}
          fetchMore={_fetchMore}
          resetPage={resetPage}
          descriptionSearchTerm={descriptionSearchTerm}
          status={status}
          getMe={getMe}
          createApplicationStatus={createApplicationStatus}
          deleteApplicationStatus={deleteApplicationStatus}
        />
      </Paper>
    </div>
  );
};
