import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    height: "calc(100vh - 56px)",
    position: "relative",
    width: "100%",
    flexGrow: 1,
    display: "grid",
    gridTemplateColumns: `repeat(2, 1fr)`,
    gridAutoRows: "100%",
  },
  image: {
    gridRow: "1 / -1",
    backgroundSize: "cover",
    backgroundImage: `url(${require(`./assets/soup_dumplings-1.jpg`)})`,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  formWrapper: {
    gridRow: "1 / -1",
    display: "flex",
    justifyContent: "center",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      gridColumn: "1 / -1",
    },
  },
});
