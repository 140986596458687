import { gql } from "@apollo/client";
import { CREATE_DISH } from "src/shared-graphql/mutations";

export { CREATE_DISH };

export const UPDATE_DISH = gql`
  mutation updateDish($input: UpdateDishPL!) {
    updateDish(updateDishPL: $input) {
      id
      name
      description
      isPublished
      tags
      defaultPrice
      imagesGQL {
        publicId
        small
        medium
        large
        original
      }
      options {
        name
        addition
      }
      updatedAt
      createdAt
    }
  }
`;

export const DELETE_DISH = gql`
  mutation deleteDish($input: ID!) {
    deleteDish(id: $input)
  }
`;

export const CREATE_ALLERGEN_DISH = gql`
  mutation createAllergenDish($input: CreateAllergenDishPL!) {
    createAllergenDish(createAllergenDishPL: $input) {
      id
      allergenId
      dishId
    }
  }
`;
