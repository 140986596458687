import { Avatar, Button, Typography } from "@material-ui/core";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { IEvent } from "src/models";
import { useStyles } from "./cook-info.styles";

interface IProps {
  getEvent: IEvent;
}

export const CookInfo: FC<IProps> = ({ getEvent }) => {
  const classes = useStyles();
  const history = useHistory();
  const descriptionCharMax = 300;
  const [expanded, setExpanded] = useState<boolean>(
    getEvent.restaurant.description
      ? getEvent.restaurant.description.length < descriptionCharMax
      : true
  );

  const description = expanded
    ? getEvent.restaurant.description
    : getEvent.restaurant.description.slice(0, descriptionCharMax) + "...";

  return (
    <div className={classes.container}>
      <Typography variant="h4" component="h4" className={classes.cookTitle}>
        Meet the Cook{" "}
      </Typography>
      <div className={classes.cookInfoContainer}>
        <Avatar
          className={classes.cookAvatar}
          src={getEvent.restaurant.host.picture}
          alt={getEvent.restaurant.host.firstName}
        />
        <div>
          <Typography variant="h2" component="h2" className={classes.cookName}>
            {getEvent.restaurant.host.firstName +
              " " +
              getEvent.restaurant.host.firstName?.toUpperCase()[0] +
              "."}
          </Typography>

          <Typography variant="body2" component="p" className={classes.cookBio}>
            {description}
            {getEvent.restaurant.description &&
              getEvent.restaurant.description.length > descriptionCharMax && (
                <Button
                  classes={{ root: classes.expandButton }}
                  size="small"
                  onClick={() => setExpanded((p) => !p)}
                >
                  {expanded ? "- Show Less" : "+ Read More"}
                </Button>
              )}
          </Typography>
          <Button
            variant="contained"
            classes={{ containedSecondary: classes.btnContainedSecondary }}
            color="secondary"
            onClick={() =>
              history.push(`/${getEvent?.restaurant?.name?.replace(/ /g, "-")}`)
            }
          >
            View Profile
          </Button>
        </div>
      </div>
    </div>
  );
};
