import { Avatar, Button, Typography, useMediaQuery } from "@material-ui/core";
import { HelpOutline, Restaurant, Room, Star } from "@material-ui/icons";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ChatButton,
  GoogleMapContainer,
  LocationDialog,
  ShareButton,
} from "src/components";
import { IEvent, IGetMe } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { BREAK_POINTS, COLORS } from "src/styles";
import { eventTypeMap, formatStartToEndDate } from "src/utils/helpers";
import { FollowButton, MobileHeader } from "./";
import { useStyles } from "./header.styles";

interface IProps {
  getEvent: IEvent;
  getMe?: IGetMe;
  dialogView?: string;
}

export const Header: FC<IProps> = React.memo(
  ({ getEvent, getMe, dialogView }) => {
    const classes = useStyles();
    const history = useHistory();

    const image = getEvent.imagesGQL[0];
    const [showLocationDialog, setShowLocationDialog] = useState(false);

    // const [date, time] = formatStartToEndDate({
    //   startTime: getEvent.startTime,
    //   endTime: getEvent.endTime,
    // }).split(",");

    // For some reason the media query is not exact
    const isLargeScreen = useMediaQuery(
      `(min-width: ${BREAK_POINTS.tablet}em)`
    );

    const [date, time] = formatStartToEndDate(
      {
        startTime: getEvent.startTime,
        endTime: getEvent.endTime,
      },
      { removeMs: false, spacedDashes: true }
    ).split(",");

    const [orderByDate, orderByTime] = formatStartToEndDate(
      {
        startTime: getEvent.reserveBy,
        endTime: getEvent.endTime,
      },
      { removeMs: false, spacedDashes: false }
    ).split(",");

    const avatarProps = Object.assign(
      {},
      getEvent.restaurant.host.picture
        ? { src: getEvent.restaurant.host.picture }
        : {
            children: `${getEvent.restaurant.host.firstName[0]}${getEvent.restaurant.host.lastName[0]}`,
          }
    ) as any;

    const openLocationDialog = () => {
      setShowLocationDialog(true);
    };

    const closeLocationDialog = () => {
      setShowLocationDialog(false);
    };

    const goToCookRestaurant = () => {
      sharedAPI.setDialogEventId("");
      history.push(`/${getEvent?.restaurant?.name?.replace(/ /g, "-")}`);
    };

    const datesHeaderChips = (
      <div className={classes.datesHeaderContainer}>
        <Typography
          variant="subtitle1"
          component="p"
          className={classes.dateLayout}
        >
          <span
            data-testid="card-header-date"
            className={classes.cardHeaderDate}
          >
            {date}&nbsp;&nbsp;
          </span>
          <span
            data-testid="card-header-time"
            className={classes.cardHeaderTime}
          >
            {time}
          </span>
        </Typography>
        <Typography
          variant="subtitle1"
          component="p"
          data-testid="order-by-date-container"
          className={classes.orderByLayout}
        >
          <span style={{ verticalAlign: "middle" }}>
            Order by {orderByDate} {orderByTime.split("-")[0]}!
          </span>
        </Typography>
      </div>
    );

    return (
      <>
        {!dialogView && <MobileHeader getMe={getMe} getEvent={getEvent} />}
        {/* 🚨👀 image will look odd with seeder data but image.small is the correctly cropped image from cloudinary */}
        <div
          className={classes.container}
          style={{
            backgroundImage: `url(${image.original.replace(
              /\/upload\//,
              `/upload/g_auto,w_1280,h_280,c_fill/`
            )})`,
          }}
        >
          <div className={classes.overlay} />

          {!isLargeScreen && datesHeaderChips}

          <div className={classes.contentContainer}>
            <section
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: isLargeScreen ? 0 : "5rem",
                paddingRight: "1rem",
                flexGrow: 1,
              }}
            >
              {isLargeScreen && datesHeaderChips}

              <Typography
                variant="h2"
                component="h2"
                data-testid="event-name-header"
                className={classes.title}
              >
                {getEvent.name}
              </Typography>
              <ul className={classes.eventDetailContainer}>
                {getEvent.tags.length > 0 && (
                  <li
                    style={{ textTransform: "capitalize" }}
                    className={classes.eventInfoContainer}
                  >
                    <Restaurant
                      style={{ fontSize: "2.2rem" }}
                      className={classes.eventInfoIcon}
                    />
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.eventInfo}
                    >
                      {getEvent.tags.join(", ")}
                    </Typography>
                  </li>
                )}
                {getEvent.locale && (
                  <button
                    className={classes.locationSrollButton}
                    onMouseDown={openLocationDialog}
                  >
                    <li
                      onMouseDown={openLocationDialog}
                      style={{ cursor: "pointer" }}
                      className={classes.eventInfoContainer}
                    >
                      <Room
                        style={{ fontSize: "2.2rem" }}
                        className={classes.eventInfoIcon}
                      />
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.eventInfo}
                      >
                        {getEvent.locale.public}
                      </Typography>
                      <HelpOutline className={classes.helpIcon} />
                    </li>
                    {getEvent.locale && (
                      <section
                        id="google-map-container"
                        className={classes.mapWrapper}
                      >
                        <GoogleMapContainer
                          zoom={13}
                          circleOverlay
                          options={{
                            disableDefaultUI: true,
                            mapTypeControl: false,
                            streetViewControl: false,
                            zoomControl: false,
                            scaleControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                          }}
                          center={
                            getEvent.locale.coordinates
                              ? {
                                  lat: getEvent.locale.coordinates.latitude,
                                  lng: getEvent.locale.coordinates.longitude,
                                }
                              : undefined
                          }
                        />
                      </section>
                    )}
                  </button>
                )}
              </ul>
              <div className={classes.typesContainer}>
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.deliveryTypeList}
                >
                  {getEvent.type
                    .map((type) => {
                      const child = eventTypeMap[type];
                      switch (type) {
                        case "DELIVERY":
                          return child;

                        case "FIXED_TIME":
                          return child;

                        case "TAKE_OUT":
                          return child;

                        case "DROP_IN":
                          return child;
                        default:
                          throw new Error(
                            `renderType called with invalid type - ${type}`
                          );
                      }
                    })
                    .join(" • ")}
                </Typography>
              </div>
            </section>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div className={classes.shareSaveContainer}>
                <ShareButton
                  shareUrl={`https://foodnome.com/menus/${getEvent.id}`}
                />
                <FollowButton currentUser={getMe} event={getEvent} />
              </div>
              <section
                style={{ flexGrow: 1, minWidth: 80 }}
                className={classes.hostProfileContainer}
              >
                <div
                  data-testid="cook-info-container"
                  style={{ cursor: "pointer", marginTop: "1rem" }}
                  onClick={goToCookRestaurant}
                >
                  <Avatar className={classes.avatar} {...avatarProps} />
                  <div className={classes.hostInfoContainer}>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.hostName}
                    >
                      {getEvent.restaurant.host.firstName
                        .split(" ")[0]
                        .toUpperCase()}{" "}
                      {getEvent.restaurant.host.lastName
                        .slice(0, 1)
                        .toUpperCase()}
                      .
                    </Typography>
                    <div className={classes.reviewContainer}>
                      <Star
                        style={{ fontSize: "1.5rem", color: COLORS.YELLOW }}
                      />
                      <Typography variant="body2" component="p">
                        <span
                          className={classes.starsText}
                        >{`${getEvent.restaurant.overallReview.stars}/5`}</span>

                        <span className={classes.reviewText}>
                          (
                          {getEvent.restaurant.overallReview.count &&
                            getEvent.restaurant.overallReview.count}
                          )
                        </span>
                      </Typography>
                    </div>
                  </div>
                </div>

                <div className={classes.btnContainer}>
                  <Button
                    onClick={goToCookRestaurant}
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.viewProfileBox}
                  >
                    Profile
                  </Button>
                  <ChatButton
                    restaurant={getEvent.restaurant}
                    currentUser={getMe}
                    variant="contained"
                  />
                </div>
              </section>
            </div>
          </div>
        </div>

        <LocationDialog
          showLocationDialog={showLocationDialog}
          closeLocationDialog={closeLocationDialog}
          restaurant={getEvent.restaurant}
        />
      </>
    );
  }
);
