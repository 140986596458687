import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Skeleton from "@material-ui/lab/Skeleton";
import random from "lodash/random";
import sampleSize from "lodash/sampleSize";
import React from "react";
import { useDummyStyles } from "./dummy.styles";

const useStyles = makeStyles({
  card: {
    display: "flex",
    boxShadow: "0 0 8px 1px rgba(29,29,29,0.31)",
    maxWidth: 400,
    width: "100%",
    borderRadius: 2,
    marginBottom: 20,
  },
  textContentContainer: {
    flexGrow: 1,
    padding: ".5rem",
  },
  eventDetailContainer: { display: "flex", alignItems: "center" },
  chipsContainer: { display: "flex" },
});

export const DummyEventCardMobile: React.FC = () => {
  const classes = useDummyStyles();
  const numChips = sampleSize([1, 2], random(1, 2));
  const titleSize = random(30, 80);
  const numImages = sampleSize([1, 2, 3, 4, 5], random(3, 5));
  const titleLines = sampleSize([1, 2], random(1, 2));
  const eventDetailSize1 = random(30, 40);
  const eventDetailSize2 = random(30, 40);

  return (
    <Card className={classes.card}>
      <Skeleton
        className={classes.image}
        variant="rect"
        width={344}
        height={196}
      >
        <Skeleton
          animation={false}
          className={classes.eventDateTimeLeft}
          variant="text"
          width={180}
          height={47}
        />
        {/* <Skeleton
          animation={false}
          className={classes.eventDateTimeRight}
          variant="text"
          width={97}
          height={47}
        /> */}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(${numImages.length + 1}, 10px)`,
            gridGap: 10,
            alignItems: "center",
          }}
          className={classes.dotsContainer}
        >
          <Skeleton animation={false} variant="circle" width={12} height={12} />
          {numImages.map((_, idx) => (
            <Skeleton
              key={idx}
              animation={false}
              variant="circle"
              width={10}
              height={10}
            />
          ))}
        </div>

        {/* Circular Avatar */}
        <Skeleton
          animation={false}
          className={classes.avatar}
          variant="circle"
          width={63}
          height={63}
        />
      </Skeleton>
      <div className={classes.cardBottom}>
        {/* Restaurant Name + ratings */}
        {/* <div className={classes.restaurantNameRatingContainer}>
          <Skeleton variant="text" height={20} width={90} />
          <div className={classes.starsContainer}>
            <Skeleton
              animation={false}
              variant="circle"
              width={10}
              height={10}
            />
            <Skeleton
              animation={false}
              variant="circle"
              width={10}
              height={10}
            />
            <Skeleton
              animation={false}
              variant="circle"
              width={10}
              height={10}
            />
            <Skeleton
              animation={false}
              variant="circle"
              width={10}
              height={10}
            />
            <Skeleton
              animation={false}
              variant="circle"
              width={10}
              height={10}
            />
          </div>
        </div> */}
        <div className={classes.eventTitleContainer}>
          {/* Event Title */}
          {titleLines.map((_, idx) => {
            const width = random(120, 200);
            return (
              <Skeleton key={idx} variant="text" width={width} height={27} />
            );
          })}

          {/* Event Details */}
          <div className={classes.eventDetailsContainer}>
            <div className={classes.eventDetailContainer}>
              <Skeleton
                animation={false}
                variant="circle"
                width={10}
                height={10}
              />
              <Skeleton variant="text" width={eventDetailSize1} height={27} />
            </div>
            <div className={classes.eventDetailContainer}>
              <Skeleton
                animation={false}
                variant="circle"
                width={10}
                height={10}
              />
              <Skeleton variant="text" width={eventDetailSize2} height={27} />
            </div>
          </div>

          {/* Chips */}
          <div className={classes.cardFooterContainer}>
            <div className={classes.chipsContainer}>
              {numChips.map((_, idx) => (
                <Skeleton
                  key={idx}
                  style={{ borderRadius: 20, marginRight: ".25rem" }}
                  variant="text"
                  width={60}
                  height={34}
                />
              ))}
              {/* <Skeleton
                style={{ marginLeft: ".5rem" }}
                variant="circle"
                width={20}
                height={20}
              /> */}
            </div>
            {/* <Skeleton variant="rect" width={40} /> */}
          </div>
        </div>
      </div>
    </Card>
  );
};
