import IconButton from "@material-ui/core/IconButton";
import React, { FC } from "react";
import { openWindow } from "./functions";

interface IProps {
  children: any;
  url: string;
  text?: string;
  hashtags?: string[];
  via?: string;
}

export const TwitterShareButton: FC<IProps> = ({
  children,
  url,
  via,
  text = "",
  hashtags = [],
}) => {
  const _link = `https://twitter.com/intent/tweet?url=${url}${
    via ? `&via=` + via : ""
  }&text=${text}&hashtags=${hashtags.length > 0 ? hashtags.join(",") : ""}`;

  const clickHandler = () => {
    openWindow(_link);
  };

  return (
    <IconButton aria-label="twitter share button" onClick={clickHandler}>
      {children}
    </IconButton>
  );
};
