import { useQuery } from "@apollo/client";
import { Card } from "@material-ui/core";
import moment from "moment";
import React, { FC } from "react";
import { ErrorLoadingComponent } from "src/components";
import { useMobileHeader } from "src/utils/hooks";
import { eventAPI } from "../api";
import { formatSubmissionPayload } from "../form";
import { EventForm, IEventFormSubmitPL } from "../form/form";
import { COOK_GET_LAST_EVENT, RESTAURANT_LOCATION } from "./graphql/query";
import { formatInitialState } from "./helpers";

export const CreateEventFormContainer: FC = () => {
  const { data = {} as any, loading, error } = useQuery(RESTAURANT_LOCATION);
  const d = useQuery(COOK_GET_LAST_EVENT);

  useMobileHeader({
    title: "Create Menu",
  });

  const createEventHandler = (pl: IEventFormSubmitPL) => {
    const createEventPl = formatSubmissionPayload(pl);

    return eventAPI.createEvent(createEventPl);
  };

  const initialState = formatInitialState({
    images: data?.cookGetRestaurant?.imagesGQL[0],
    description:
      d?.data?.cookGetLastEvent?.description ??
      `Hello, thanks for checking out my home restaurant ${data.cookGetRestaurant?.name}. I will be serving ...`,
    instructions: d?.data?.cookGetLastEvent?.instructions ?? "",
    address: data.cookGetRestaurant?.address,
    name: data.cookGetRestaurant?.name,
    displayLocation: `${data.cookGetRestaurant?.city}, ${data.cookGetRestaurant?.state}`,
    reserveByDate: moment().startOf("h").add(7, "days").format("YYYY-MM-DD"),
    reserveByTime: moment()
      .startOf("h")
      .add(7, "days")
      .add(2, "hours")
      .format("HH:mm"),
    date: moment().startOf("h").add(7, "days").format("YYYY-MM-DD"),
    startTime: moment().startOf("h").add(7, "days").format("HH:mm"),
    duration: 2,
    maximumDriveTime:
      d?.data?.cookGetLastEvent?.deliveryInfo?.maximumDriveTime ?? 30,
    minimumOrderAmount:
      d?.data?.cookGetLastEvent?.deliveryInfo?.minimumOrderAmount ?? 30,
    pricePerMin: d?.data?.cookGetLastEvent?.deliveryInfo?.pricePerMin ?? 0.2,
    pricePerMile: d?.data?.cookGetLastEvent?.deliveryInfo?.pricePerMile ?? 0.6,
  });

  if (loading || d.loading || error || d.error) {
    return (
      <ErrorLoadingComponent error={error} loading={loading || d.loading} />
    );
  }

  return (
    <Card
      style={{
        boxShadow: "0 2px 10px 0 #BCBCBC",
        borderRadius: 2,
      }}
    >
      <EventForm initialState={initialState} onSubmit={createEventHandler} />
    </Card>
  );
};
