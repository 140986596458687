import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  paperWidthSm: {
    width: "500px",
    padding: "1rem",
  },
  dialogContent: {
    fontFamily: "CustomFourBold",
    minWidth: "250px",
    textAlign: "center",
    color: "#2E2E2E",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      minWidth: "unset",
      width: "unset",
      padding: 0,
    },
  },
  actionBtnContainer: { display: "flex", flexDirection: "column" },
  newOrderBtn: {
    width: "100%",
    fontWeight: "bold",
    maxWidth: "250px",
    margin: "20px auto",
    color: COLORS.WHITE,
  },
  link: {
    textDecoration: "none",
    color: COLORS.MEDIUM_GREEN,
    marginBottom: "20px",
  },
  headerContainer: {
    marginBottom: "1rem",
  },
  helloTitle: {},
  helloSubtitle: {
    margin: "1rem 0",
    textAlign: "center",
  },
  orderNavContainer: {
    display: "flex",
    justifyContent: "flex-end",
    fontFamily: "CustomFourBold",
    alignItems: "center",
  },
  orderCount: {
    color: COLORS.RED,
  },
  navNext: {
    color: COLORS.RED,
    fontSize: "3rem",
  },
});
