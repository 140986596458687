import React from "react";
import { Banner } from "./banner";

export const BannerContainer = () => {
  return (
    <section>
      <Banner />
    </section>
  );
};
