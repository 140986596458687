import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import React, { FC } from "react";
import { styles as useStyles } from "./styles";

interface IProps {
  handleStatus: (status: string) => any;
  status: string;
  statusOptions: string[];
  keyMap?: (key: string) => string;
}

export const StatusPicker: FC<IProps> = ({
  handleStatus,
  statusOptions,
  status,
  keyMap = (key: string) => key,
}) => {
  const classes = useStyles();
  const changeHandler = ({ target: { value } }) => handleStatus(value);

  return (
    <FormControl>
      <InputLabel className="hidden-label" htmlFor="status-select">
        Event Status
      </InputLabel>
      <Select
        native
        MenuProps={{
          classes: { paper: classes.menuRoot },
        }}
        value={status}
        autoWidth={false}
        onChange={changeHandler}
        classes={{
          root: classes.root,
          icon: classes.icon,
        }}
        input={
          <OutlinedInput
            labelWidth={0}
            name="status"
            inputProps={{
              id: "status-select",
            }}
            classes={{
              input: classes.input,
              notchedOutline: classes.notchedOutline,
            }}
          />
        }
      >
        {statusOptions.map((option, idx) => (
          <option key={idx} value={option} className={classes.menuItemRoot}>
            {keyMap(option)}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};
