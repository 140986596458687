import { gql } from "@apollo/client";

export const CREATE_ALLERGEN = gql`
  mutation createAllergen($input: CreateAllergenPL) {
    createAllergen(createAllergenPL: $input) {
      id
      name
      description
      updatedAt
      createdAt
    }
  }
`;

export const DELETE_ALLERGEN = gql`
  mutation deleteAllergen($input: ID!) {
    deleteAllergen(id: $input)
  }
`;
