import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    backgroundImage: `url("${require("./assets/bg-lg.png")}")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "90%",
    minWidth: "unset",
    maxWidth: "80rem",
    maxHeight: "unset",
    margin: "1rem",

    [`@media (max-width: ${BREAK_POINTS.mobileRegular}em)`]: {
      backgroundImage: `url("${require("./assets/refer-top-05.svg")}")`,
      backgroundSize: "contain",
      height: "66rem",
    },
    [`@media (max-width: ${BREAK_POINTS.mobileSmall}em)`]: {
      height: "54rem",
    },
  },
  dialogContent: {
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",

    [`@media (max-width: ${BREAK_POINTS.mobileRegular}em)`]: {
      padding: 0,
      flexDirection: "column",
      justifyContent: "flex-end",
    },
  },
  dialogContentContainer: {
    width: "40%",
    // minHeight: 480,
    padding: "6rem 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    [`@media (max-width: ${BREAK_POINTS.mobileRegular}em)`]: {
      padding: "8px 24px",
      backgroundImage: `url("${require("./assets/refer-bottom-05.svg")}")`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",

      width: "unset",
      height: "65%",
    },
  },
  input: {
    userSelect: "none",
    WebkitUserSelect: "none",
    WebkitTouchCallout: "none",
    border: "none",
    width: "100%",
    padding: "0 1rem",
    fontFamily: "CustomFourBold",
  },
  h1: {
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    marginBottom: "2.5rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginTop: "4rem",
      marginBottom: "0.5rem",
    },
  },
  body2: { color: COLORS.WHITE },
  closeIconBtn: {
    position: "absolute",
    right: 5,
    top: 5,
    zIndex: 5, // For some reason the button does not work on cordova so adjusting z index fixes it
    backgroundColor: COLORS.MEDIUM_GREEN,
  },
  mdClose: {
    color: COLORS.WHITE,
    fontSize: "2rem",
  },
  shareLinkContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: COLORS.WHITE,
    borderRadius: 6,
    height: "5rem",

    width: "100%",
  },
  socialShareLinkContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    backgroundColor: COLORS.WHITE,
    borderRadius: 6,
    height: "5rem",
    width: "100%",
    padding: 4,
  },
  shareLinkCopyBtn: {
    height: "100%",
    borderRadius: "0 6px 6px 0",
  },
  h5: {
    color: COLORS.WHITE,
    marginTop: "0.5rem",
    marginBottom: "1rem",
    fontFamily: "CustomFourBold",
    [`@media (max-width: ${BREAK_POINTS.mobileRegular}em)`]: {
      marginBottom: "0",
    },
  },

  shareBtnIcon: {
    fontSize: "3rem",
  },
}));
