import { range } from "lodash";
import moment from "moment";
import { IEvent, IGetMe } from "src/models";
import { formatDateTime, formatStartToEndDate } from "src/utils/helpers";

interface IArgs {
  openLoginDialog: () => any;
  openVerifyDialog: () => any;
  handleSubmit?: () => any;
}

export const bindSubmitHandler = (currentUser: IGetMe | null) => ({
  openLoginDialog,
  openVerifyDialog,
  handleSubmit,
}: IArgs): (() => any) => {
  if (!currentUser) return openLoginDialog;
  else if (currentUser.active) return handleSubmit as any;
  else return openVerifyDialog;
};

export const getMenuItems = (event: IEvent, dineOption?: string) => {
  const { startTime, endTime } = event;
  const isSameDate = moment(Number(endTime)).isSame(
    moment(Number(startTime)),
    "date"
  );
  const bufferTime = event.bufferTime ? event.bufferTime : 15 * 60 * 1000;

  let interval = 15 * 60 * 1000;
  const multiDays =
    moment(Number(endTime)).diff(moment(Number(startTime)), "days") > 7;
  if (multiDays) {
    interval = 60 * 1000 * 60 * 24 * 5;
  }
  if (dineOption === "DELIVERY") {
    interval = 60 * 1000 * 60;
  }
  const currentTimeInMs = moment().add(bufferTime, "milliseconds").valueOf();

  const timeRange = range(Number(startTime), Number(endTime), interval)
    .filter((t) => t > currentTimeInMs)
    .map((t, i) => {
      let disabled = false;
      let label = formatDateTime(
        {
          time: t.toString(),
        },
        { removeMs: false }
      ).replace(",", " ");
      if (
        event.soldOutTimeSlots?.some(
          (slot) => t >= Number(slot[0]) && t < Number(slot[1])
        )
      ) {
        // This was overflowing the select input and making it off center. Told to remove
        // by Kenji - Cameron
        label = "(sold out)";
        disabled = true;
      }

      return {
        label,
        value: t,
        disabled,
      };
    });

  if (!timeRange.length)
    timeRange[0] = {
      label: `ASAP ${moment(Number(endTime)).format("h:mm a")}`,
      value: Number(endTime),
      disabled: false,
    };

  return timeRange;
};

export const eventTypeMap = {
  FIXED_TIME: "Pop-up",
  TAKE_OUT: "Take-out",
  DROP_IN: "Dine-in",
};

export { formatStartToEndDate };
