import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { GET_ME } from "src/shared-graphql/queries";
import { Layout } from "./layout";

declare let window: any;

export const HowItWorksContainer: FC = () => {
  const location = useLocation();
  const param = new URLSearchParams(location.search).get("type") as
    | "diner"
    | "cook";

  const [isSelected, setSelected] = useState<"diner" | "cook">("diner");
  const [error, setError] = useState("");
  const [loading, updateLoading] = useState(false);

  const { data, loading: getMeLoading } = useQuery(GET_ME);

  useEffect(() => {
    if (param === "diner" || param === "cook") {
      setSelected(param);
    }
  }, [param]);

  const _description =
    "Learn how the COOK Connect platform can help you tap into the world of home restaurants food near you.\
     How COOK Connect works.";

  if (getMeLoading) return <CircularProgress />;

  const content = (
    <>
      <Helmet>
        <title>How it Works | COOK Connect</title>
        <meta name="description" content={_description} />
      </Helmet>
      <Layout
        selection={isSelected}
        setError={setError}
        loading={loading}
        error={error}
        user={data.getMe}
      />
    </>
  );
  if (window.cordova) {
    return content;
  }
  return (
    // GoogleReCaptchaProvider needs to wrap the container that has a captcha in it
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY as string}
      scriptProps={{ async: true, defer: true, appendTo: "head" }}
    >
      {content}
    </GoogleReCaptchaProvider>
  );
};
