import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import React, { FC, useState } from "react";
import { COLORS } from "src/styles/colors";

const useStyles = makeStyles({
  container: {
    width: 140,
    position: "absolute",
    bottom: 15,
    right: 40,
  },
  stepButtonContainer: {
    position: "relative",
    borderRadius: "50%",
    height: "28px",
    width: "28px",
    border: "1px solid rgba(239,69,82,0.97)",
  },
  stepButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  stepCount: {
    color: COLORS.DARK_BLUE,

    fontWeight: 300,
  },
  icon: {
    position: "relative",
    top: "-12.5px",
  },
});

interface IProps {
  step: number;
  steps: number;
  setStep: (d: number) => void;
}

export const Stepper: FC<IProps> = ({ step, steps, setStep }) => {
  const classes = useStyles();
  // Hooks
  const [leftDisabled, setLeftDisabled] = useState(true);
  const [rightDisabled, setRightDisabled] = useState(false);

  // Handlers
  const handleStepUp = () => {
    if (leftDisabled) setLeftDisabled(false);
    if (step + 1 >= steps - 1) {
      setRightDisabled(true);
    }
    return setStep(step + 1);
  };

  const handleStepDown = () => {
    if (rightDisabled) setRightDisabled(false);
    if (step - 1 <= 0) {
      setLeftDisabled(true);
    }
    return setStep(step - 1);
  };

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      justify="space-around"
      className={classes.container}
    >
      <div
        className={classes.stepButtonContainer}
        style={{
          backgroundColor: leftDisabled ? `rgba(255, 89, 103, .7)` : COLORS.RED,
        }}
      >
        <IconButton
          className={classes.stepButton}
          disableRipple
          onClick={() => handleStepDown()}
          disabled={step === 0}
        >
          <ChevronLeft
            className={classes.icon}
            style={{ left: -12.5, fontSize: "2.4rem", color: COLORS.WHITE }}
          />
        </IconButton>
      </div>
      <Typography variant="h4" component="p" className={classes.stepCount}>
        <b>{step + 1}</b>/{steps}
      </Typography>
      <div
        className={classes.stepButtonContainer}
        style={{
          backgroundColor: rightDisabled
            ? `rgba(255, 89, 103, .7)`
            : COLORS.RED,
        }}
      >
        <IconButton
          className={classes.stepButton}
          disableRipple
          onClick={() => handleStepUp()}
          disabled={step === steps - 1}
        >
          <ChevronRight
            className={classes.icon}
            style={{ left: -10.5, color: COLORS.WHITE, fontSize: "2.4rem" }}
          />
        </IconButton>
      </div>
    </Grid>
  );
};
