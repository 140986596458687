import { gql } from "@apollo/client";

export const CREATE_COMPLAINT = gql`
  mutation createComplaint($input: CreateComplaintPL!) {
    createComplaint(createComplaintPL: $input)
  }
`;
export const ADMIN_UPDATE_TAX_RATE = gql`
  mutation adminCalculateTaxRate($input: ID!) {
    adminCalculateTaxRate(id: $input) {
      id
      taxRate
    }
  }
`;

export const ADMIN_UPDATE_RESTAURANT = gql`
  mutation adminUpdateRestaurant($input: AdminUpdateRestaurantPL!) {
    adminUpdateRestaurant(adminUpdateRestaurantPL: $input) {
      id
      name
      description
      imagesGQL {
        publicId
        small
        medium
        large
        original
      }
      address
      hostBio
      serviceFeePerc
      street
      taxRate
      city
      state
      zipCode
      tags
      county
      updatedAt
      createdAt
      permitNumber
      info {
        hasDog
        hasCat
        otherAnimals
      }
      badges {
        managerCertificate
        insurance
      }
    }
  }
`;
