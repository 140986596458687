import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";

export const ResponsiveDialog: React.FC<DialogProps> = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullscreen = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <Dialog
      classes={{
        root: classes.dialogStyles,
      }}
      {...props}
      fullScreen={fullscreen}
    />
  );
};

const useStyles = makeStyles({
  dialogStyles: {
    root: {
      margin: "0 auto",
    },
  },
});
