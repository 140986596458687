import red from "@material-ui/core/colors/red";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { COLORS } from "./colors";

// Media query manager
/*
0px - 600px Phone
600px - 960px Tablet portrait
960px - 1280px Tablet landscape
1280px - 1920px Normal
+ 1920px Big desktop

1em = 16px
*/

export const BREAK_POINTS = {
  mobileSmall: 370 / 16, // 23.1em = 370
  mobileRegular: 767 / 16, // 47.93em = 767
  tablet: 991 / 16, // 61.9em = 991px
  desktop: 1232 / 16, // 77em = 1232px
};

const DEFINED_BREAK_POINTS_PX = {
  xs: 370, // 29.9em = 479
  sm: 767, // 47.93em = 767
  md: 991, // 61.9em = 991px
  lg: 1232, // 77em = 1232px
  xl: 1232,
};

const createTheme = createMuiTheme({
  breakpoints: { values: DEFINED_BREAK_POINTS_PX },

  typography: {
    fontFamily: `inherit`,
    // The font size in the html is 62.5% which is 10px
    htmlFontSize: 10,
    h1: {
      fontSize: "4.8rem",
      // Proxima Soft Extra Bold
      fontFamily: "CustomSeven",
      // marginBottom: "2.5rem",
    },
    h2: {
      fontSize: "3.2rem",
      fontFamily: "CustomFour",
      // marginBottom: "2.5rem",
    },
    h3: {
      fontSize: "2.8rem",
      fontFamily: "CustomFourBold",
      // color: COLORS.H3_HEADINGS,
      // marginBottom: "2.5rem",
    },
    h4: {
      fontSize: "2.4rem",
      fontFamily: "CustomFourBold",
      // marginBottom: "2.5rem",
    },
    h5: {
      fontSize: "2.3rem",
      fontFamily: "CustomFour",
      // marginBottom: "2.5rem",
    },
    h6: {
      fontSize: "1.5rem",
      // marginBottom: "2.5rem",
    },
    subtitle1: {
      fontSize: "2rem",
      fontFamily: "CustomFourBold",
      lineHeight: "1.1",
    },
    subtitle2: { fontSize: "1.7rem", fontFamily: "CustomFourBold" },
    // Until MUI V5 :(
    // subtitle3: { fontSize: "2.7rem", fontFamily: "CustomFour" },
    body1: {
      fontSize: "2rem",
      fontFamily: "CustomFour",
      lineHeight: "1.4",
    },
    body2: {
      fontSize: "1.7rem",
      fontFamily: "CustomFour",
    },
    button: {
      fontFamily: "CustomFourBold",
    },
    caption: { fontSize: "1.5rem", fontFamily: "CustomFour" },
    overline: {},
  },
  palette: {
    primary: {
      main: COLORS.MEDIUM_RED,
    },
    secondary: {
      main: COLORS.MEDIUM_RED,
    },
    info: {
      main: COLORS.BLUE,
    },

    action: {
      disabled: "white",
      // disabledBackground: "rgba(252, 84, 97, 0.5)",
      // disabledBackground: COLORS.LIGHT_GREEN,
      activatedOpacity: 0.5,
    },
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          backgroundColor: "red",
        },
      },
    },
    // Input fields overrides
    MuiInput: {
      input: {
        fontSize: "1.6rem",
      },
    },
    MuiTextField: {
      root: {
        fontSize: "1.6rem",
        "& label.Mui-focused": {
          color: COLORS.BLACK,
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: COLORS.BLACK,
          borderWidth: 1,
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: COLORS.MEDIUM_GREY,
          },
          "&:hover fieldset": {
            borderColor: COLORS.DARK_GREY,
          },
          "&.Mui-focused fieldset": {
            borderColor: COLORS.BLACK,
            borderWidth: 1,
          },
        },
      },
    },
    MuiInputBase: {
      input: {
        fontSize: "1.6rem",
      },
    },
    MuiOutlinedInput: {
      input: {
        fontSize: "1.6rem",
      },
    },

    // ************************ //
    MuiButton: {
      root: {
        color: "white",
        borderRadius: "2px",
        fontFamily: "CustomFourBold",
      },
      containedPrimary: {
        color: "white",
        borderRadius: "2px",
        fontFamily: "CustomFourBold",
        "&:disabled": {
          backgroundColor: COLORS.RED,
          opacity: "0.5",
        },
      },
      containedSecondary: {
        color: "white",
        borderRadius: "2px",
        fontFamily: "CustomFourBold",
        "&:disabled": {
          backgroundColor: COLORS.LIGHT_GREEN,
          opacity: "0.5",
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: "#fff176",
        color: "#757575",
        height: "44px",
        padding: "0 24px",
        fontFamily: "CustomFourBold",
      },
      message: {
        padding: "2px 0",
      },
    },
    MuiStepIcon: {
      text: {
        fill: "white",
      },
    },
    MuiSnackbar: {
      root: {},
    },
  },
});

export const theme = responsiveFontSizes(createTheme);
