import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  editBtn: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
    border: "none",
    cursor: "pointer",
    width: 50,
    height: 30,
  },
  paperWidthSm: {
    width: "500px",
    padding: "1rem",
  },
  dialogContent: {
    fontFamily: "CustomFourBold",
    fontSize: "16px",
    minWidth: "250px",
    textAlign: "center",
    color: "#2E2E2E",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      minWidth: "unset",
      width: "unset",
      padding: 0,
    },
  },
  viewEditBtn: {},
  changeMyOrder: {
    fontFamily: "CustomFourBold",
    cursor: "pointer",
    color: COLORS.WHITE,
    border: "none",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      fontSize: "12px",
    },
  },
  cancelBtn: {
    fontFamily: "CustomFourBold",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      fontSize: "12px",
    },
  },
});
