import { Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { SyncProblem } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import { CookProfile } from "src/assets/icons/svg/cook-profile";
import { DinerProfile } from "src/assets/icons/svg/diner-profile";
import { IGetMe } from "src/models";
import { COLORS } from "src/styles";
import { useStyles } from "./styles";

interface DrawerTopProps {
  user: IGetMe;
  isCookMode: boolean;
  handleClose: () => void;
}

export const DrawerTop: React.FC<DrawerTopProps> = ({
  user,
  isCookMode,
  handleClose,
}) => {
  const classes = useStyles();
  const avatarProps = Object.assign(
    {},
    user && user.picture
      ? { src: user.picture }
      : { children: `${user.firstName[0]}${user.lastName[0]}` }
  );

  return (
    <section className={classes.userInfoContainer}>
      <div className={classes.userInfoLayout}>
        <div>
          <div className={classes.nameContainer}>
            {isCookMode ? (
              <CookProfile width={25} height={25} style={{ marginRight: 4 }} />
            ) : (
              <DinerProfile width={25} height={25} style={{ marginRight: 4 }} />
            )}
            <Typography variant="body1" component="p" className={classes.name}>
              {user.firstName}{" "}
              {isCookMode && !!user.nomes && (
                <span className={classes.creditAmount}>
                  {user.nomes.toFixed(2)}
                </span>
              )}
            </Typography>
          </div>
          {!isCookMode ? (
            !!Number((user.credits + user.nomes).toFixed(2)) ? (
              <Typography
                variant="body2"
                component="p"
                className={classes.credits}
              >
                Credits:{" "}
                <data
                  value={user.credits + user.nomes}
                  className={classes.creditAmount}
                >
                  {(user.credits + user.nomes).toFixed(2)}
                </data>
              </Typography>
            ) : (
              <div style={{ minHeight: 20 }} /> // for display purposes...
            )
          ) : user?.restaurant?.application?.status !== "PENDING" ? (
            <Link
              onClick={handleClose}
              className={classes.link}
              to={`/${user?.restaurant?.name}`}
            >
              <Typography variant="caption" component="span">
                View Profile
              </Typography>
            </Link>
          ) : (
            <Typography
              variant="body2"
              component="p"
              className={classes.permitInProgress}
            >
              <SyncProblem
                style={{ marginRight: ".5rem", color: COLORS.GOLD }}
              />
              Application in progress
            </Typography>
          )}
        </div>
        <div className={classes.avatarContainer}>
          <Avatar
            className={classes.avatar}
            alt={user.firstName}
            {...avatarProps}
          />
        </div>
      </div>
    </section>
  );
};
