import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    width: "95%",

    margin: "3rem auto",
    marginTop: "auto",
    display: "flex",
    flexDirection: "column",
    backgroundColor: COLORS.WHITE,
    boxShadow: "rgba(0, 0, 0, 0.28) 0px 8px 15px",
    borderRadius: "1rem",
    height: "auto",
    maxHeight: "19rem",
  },
  topContainer: {
    padding: "1rem",
    paddingBottom: 0,
  },
  paperWidthSm: {
    maxWidth: "unset",
    margin: 0,
    width: "100%",
    [`@media (min-width: ${BREAK_POINTS.desktop}em)`]: {
      width: "90%",
    },
  },

  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },

  iconBtn: {
    position: "absolute",
    top: 4,
    right: 4,
    zIndex: 2000,
    backgroundColor: COLORS.MEDIUM_GREEN,
  },
  mdClose: {
    color: COLORS.WHITE,
  },
  avatar: {
    width: "6rem",
    height: "6rem",
    marginRight: "1rem",

    border: `2px solid ${COLORS.WHITE}`,
    zIndex: 2,
    backgroundColor: COLORS.RED,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    textTransform: "uppercase",
    [`@media (max-width: ${BREAK_POINTS.mobileSmall}em)`]: {
      display: "none",
    },
  },
  avatarCSV: {
    width: "6rem",
    height: "6rem",
    marginRight: "1rem",
    border: `2px solid ${COLORS.WHITE}`,
    zIndex: 2,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    textTransform: "uppercase",
    [`@media (max-width: ${BREAK_POINTS.mobileSmall}em)`]: {
      display: "none",
    },
  },

  reviewStars: {
    marginRight: "0.5rem",
    fontFamily: "CustomFourBold",
  },
  locationContainer: {
    marginRight: "1rem",
  },
  chipRoot: {
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    backgroundColor: "#27C9B1",
    margin: "1rem 0",
    marginRight: "1rem",

    boxShadow: "0 0 4px 0 rgba(0,0,0,0.33)",

    "&:hover": {
      webkitTransform: "scale(1.05) translateZ(0)",
      transform: "scale(1.05) translateZ(0)",
      boxShadow: "0 10px 10px -10px rgba(0, 0, 0, 0.5)",
      transitionDuration: "0.3s",
      webkitTransitionProperty: "box-shadow, transform",
      transitionProperty: "box-shadow, transform",
    },
  },
  availableMenusContainer: {
    paddingLeft: "3rem",
  },
  chipContainer: {
    display: "flex",

    overflowX: "scroll",

    "&::-webkit-scrollbar": {
      width: 0,
      height: 0,
      backgroundColor: "transparent",
    },
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "#babac0",
    //   borderRadius: "1.6rem",
    //   border: "5px solid #fff",
    // },
    "& div:first-child": {
      marginLeft: "1rem",
    },
  },
  description: {
    paddingRight: "1rem",
    color: "#6E6E6E",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-word",
    lineClamp: 3,
    WebkitLineClamp: 3,

    WebkitBoxOrient: "vertical",
  },
  card: {
    cursor: "pointer",
    borderLeft: "1rem solid transparent",

    boxShadow: "0 2px 19px 1px rgba(191,191,191,0.38)",
    "&:hover": {
      boxShadow: "0 2px 13px 1px rgba(206,0,0,0.27)",
      borderLeft: "1rem solid red",
    },
  },
  icon: {
    fontSize: "2rem",
    marginRight: ".5rem",
    color: COLORS.BLACK,
  },
  title: {
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    overflow: "hidden",
  },
  image: {
    width: "11rem",
    height: "10rem",
    objectFit: "cover",
    borderRadius: "1rem",
    [`@media (max-width: ${BREAK_POINTS.mobileSmall}em)`]: {
      width: "8rem",
      height: "8rem",
    },
  },
  moreButton: {
    color: "#6F6F6F",
    fontFamily: "CustomFour",
    paddingBottom: 0,
    "&:hover": {
      backgroundColor: "transparent",
      color: COLORS.LIGHT_GREY,
    },
  },
  typesContainer: {
    color: "#636363",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-word",
    lineClamp: 1,
    WebkitLineClamp: 1,

    WebkitBoxOrient: "vertical",
  },
  requestNowBtn: {
    margin: "1rem",
  },
  tags: {
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    overflow: "hidden",
    textTransform: "capitalize",
  },
});
