import { SvgIcon } from "@material-ui/core";
import React, { FC } from "react";

interface IProps {
  className?: any;
}

export const CustomTwitterIcon: FC<IProps> = ({ className, ...props }) => (
  <SvgIcon className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <title>Twitter1</title>
      <path d="M0,24A24,24,0,1,1,24,48,24,24,0,0,1,0,24Z" fill="#55acee" />
      <path
        d="M23.28,19.51l0,.83-.84-.1a13.69,13.69,0,0,1-8-3.94l-1.11-1.1-.28.82a4.87,4.87,0,0,0,1,5c.67.71.52.82-.64.39a2,2,0,0,0-.79-.18,6.07,6.07,0,0,0,.61,2.27,5.38,5.38,0,0,0,2.3,2.17l.82.39h-1c-1,0-1,0-.88.38a5.09,5.09,0,0,0,3.14,2.78l1,.35-.9.54a9.57,9.57,0,0,1-4.5,1.26,5.3,5.3,0,0,0-1.38.13A13,13,0,0,0,15.24,33a14.19,14.19,0,0,0,11-1.26,14.69,14.69,0,0,0,5.59-6.57A17.61,17.61,0,0,0,33,20.1c0-.78.05-.88,1-1.81A11.12,11.12,0,0,0,35.15,17c.17-.32.15-.32-.71,0-1.42.51-1.62.44-.92-.32a5,5,0,0,0,1.14-1.82c0-.05-.25,0-.53.19a10.65,10.65,0,0,1-1.48.58l-.91.28-.82-.56a6.77,6.77,0,0,0-1.43-.74,5.73,5.73,0,0,0-2.94.07A4.86,4.86,0,0,0,23.28,19.51Z"
        fill="#fff"
      />
    </svg>
  </SvgIcon>
);
