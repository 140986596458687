import HomeIcon from "@material-ui/icons/Home";
import React from "react";
import { CookDish } from "src/assets/icons/svg/cook-dish";
import { CookMenu } from "src/assets/icons/svg/cook-menu";
import { Setting } from "src/assets/icons/svg/setting";
import { AppStatus, IGetMe, ISidebarLink } from "src/models";

const isApprovedCook = (user?: IGetMe) => {
  return user?.restaurant?.application?.status === AppStatus.APPROVED;
};

export const cookTabs: ISidebarLink[] = [
  {
    label: "Home",
    link: "/c/home",
    icon: <HomeIcon style={{ width: 24, height: 24 }} />,
    when: true,
  },
  {
    label: "Menus",
    link: "/c/menus",
    icon: <CookMenu style={{ width: 24, height: 29 }} />,
    when: true, // Changed to True from isApprovedCook
  },

  {
    label: "Dishes",
    link: "/c/dishes",
    icon: <CookDish style={{ width: 24, height: 27 }} />,
    when: true,
  },
  {
    label: "Settings",
    link: "/c/settings?view=RESTAURANT",
    icon: <Setting style={{ width: 24, height: 29 }} />,
    when: true,
  },
];
