import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SERVER_ADDRESS } from "src/apollo";
import { ErrorLoadingComponent } from "src/components";
import { useCreateChannel } from "../chat2";
import { useStyles } from "./diner-is-here.styles";

export const DinerIsHere = () => {
  const [open, setOpen] = useState(true);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [cookId, setCookId] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { createChannel } = useCreateChannel();

  useEffect(() => {
    const search = parse(location.search) as { notifyKey: string };
    setLoading(true);

    axios
      .get(`${SERVER_ADDRESS}/diner/notify-cook?t=${search.notifyKey}`)
      .then((d) => {
        setCookId(d.data.cookId);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data ?? "");
      });
  }, []);

  if (loading) {
    return <ErrorLoadingComponent loading={loading} />;
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="xs"
      onClose={() => setOpen(false)}
    >
      <DialogContent>
        <Typography variant="h3" component="h3" className={classes.header}>
          {error ? error : "Your cook has been notified!"}
        </Typography>
        <Typography variant="body2" component="p" className={classes.text}>
          {error
            ? "Sorry, we could not notify your cook. Please message the cook directly, or pick up your food by knocking on their door. "
            : "You may wait in your car, the food will be delivered to you shortly. "}
        </Typography>
        {/* <Typography variant="body2" component="p" className={classes.text}>
          Questions? Contact Foodnome at{" "}
          <a href="tel:9518800422">+1 (951)-880-0422</a>
        </Typography> */}
      </DialogContent>
      <DialogActions className={classes.btnContainer}>
        <Button
          color="secondary"
          className={classes.notBtn}
          onClick={() => history.push("/d")}
        >
          Got it
        </Button>
        <Button
          color="secondary"
          variant="contained"
          className={classes.btn}
          onClick={() => createChannel(cookId)}
        >
          Message {cookId ? "the Cook" : ""}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
