import { Paper } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { PaginationComponent } from "src/components";
import { AdminUpdateUserPL, IUser } from "src/models";
import { Header } from "./header";
import { MemberListTable } from "./table";

interface IProps {
  users: IUser[];
  totalCount: number;
  onFetchMore: (d: { pageNumber: number; searchTerm?: string }) => void;
  redirect: (d: string) => void;
  deleteUser: (d: number) => void;
  editUser: (d: AdminUpdateUserPL) => void;
}
export const Layout: FunctionComponent<IProps> = ({
  users,
  totalCount,
  onFetchMore,
  redirect,
  deleteUser,
  editUser,
}) => {
  return (
    <div>
      <Header totalCount={totalCount} onSearch={onFetchMore} />
      <Paper style={{ margin: "1rem", padding: "1rem" }}>
        <MemberListTable
          users={users}
          deleteUser={deleteUser}
          editUser={editUser}
          redirect={redirect}
        />

        <PaginationComponent
          totalCount={totalCount}
          onChange={(d) => onFetchMore({ pageNumber: d })}
        />
      </Paper>
    </div>
  );
};
