import { useQuery } from "@apollo/client";
import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Element } from "react-scroll";
import { useCreateChannel } from "src/components/chat2";
import { IDish, IGetMe, IRestaurant } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { useTopViewport } from "src/utils/hooks";
import { useTabsState } from "../../tabs/context";
import { useStyles } from "./dishes.styles";
import { GET_RESTAURANT_DISHES } from "./graphql";

interface RestaurantDishesData {
  getRestaurantDishes: {
    count: number;
    rows: IDish[];
  };
}

interface DishesProps {
  getMe?: IGetMe;
  restaurant: IRestaurant;
}

export const Dishes: React.FC<DishesProps> = React.memo(
  ({ restaurant, getMe }) => {
    const classes = useStyles();
    const history = useHistory();
    const { createChannel } = useCreateChannel();
    const restaurantId = restaurant.id;

    const ref = React.useRef<any>();
    const inView = useTopViewport(ref);

    const { dispatch } = useTabsState();
    useEffect(() => {
      if (inView) {
        dispatch({ type: "SET_TAB_VALUE", value: "dishes" });
      }
    }, [inView]);

    const { error, loading, data, fetchMore, client, variables } = useQuery<
      RestaurantDishesData
    >(GET_RESTAURANT_DISHES, {
      notifyOnNetworkStatusChange: true,
      variables: {
        input: {
          pagePL: { limit: 4 },
          restaurantId,
        },
      },
    });
    const limitRef = React.useRef<number>(variables?.input.pagePL.limit);

    const displayShowMoreButton = () =>
      data &&
      data.getRestaurantDishes.rows.length < data.getRestaurantDishes.count;

    const displayShowLessButton = () => {
      if (data) {
        if (data.getRestaurantDishes.count < 5) return false;

        return (
          data.getRestaurantDishes.rows.length ===
          data.getRestaurantDishes.count
        );
      }

      return false;
    };

    const onClickShowMore = () => {
      fetchMore({
        variables: {
          input: {
            ...variables?.input,
            pagePL: {
              ...variables?.input.pagePL,
              limit: limitRef.current + 4,
            },
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, fetchMoreResult);
        },
      }).then(() => (limitRef.current += 4));
    };

    const onClickShowLess = () => {
      const res = client.readQuery({ query: GET_RESTAURANT_DISHES, variables });
      client.writeQuery({
        query: GET_RESTAURANT_DISHES,
        variables,
        data: {
          getRestaurantDishes: {
            ...res.getRestaurantDishes,
            rows: res.getRestaurantDishes.rows.slice(0, 4),
          },
        },
      });

      limitRef.current = 4;
    };

    if (error)
      return (
        <Typography variant="body2" component="p">
          Error: {error.message}
        </Typography>
      );

    return (
      <>
        <Element name="dishes" />
        <section ref={ref} style={{ marginBottom: "1.5rem" }}>
          <div className={classes.dishHeader}>
            <Typography
              variant="h5"
              component="h5"
              className={classes.subheading}
            >
              Dishes
            </Typography>
          </div>
          <Divider />
          {data?.getRestaurantDishes?.count &&
          data?.getRestaurantDishes?.count > 0 ? (
            <>
              <ul
                className={classes.dishList}
                data-testid="restaurant-published-dishes"
              >
                {data?.getRestaurantDishes?.rows?.map((dish) => (
                  <li key={dish.id}>
                    <article>
                      <img
                        className={classes.dishImage}
                        alt={dish.name}
                        src={dish.imagesGQL[0].small}
                      />
                      <Typography
                        variant="h4"
                        component="h4"
                        className={classes.dishName}
                      >
                        {dish.name}{" "}
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.dishDescription}
                      >
                        {dish.description}
                      </Typography>
                      {!!dish.events?.length ? (
                        <Typography
                          variant="h4"
                          component="h4"
                          className={classes.availableOn}
                        >
                          Available on:
                        </Typography>
                      ) : (
                        <div className={classes.availableOn}>
                          <Typography
                            variant="caption"
                            component="p"
                            style={{ fontFamily: "CustomFourBold" }}
                          >
                            This dish is currently not available
                          </Typography>
                          {getMe?.id !== restaurant.host.id && (
                            <Button
                              variant="outlined"
                              onClick={() =>
                                !getMe
                                  ? sharedAPI.setUserSignInDialog(true)
                                  : createChannel(restaurant.host.id)
                              }
                              className={classes.requestBtn}
                            >
                              Request Now
                            </Button>
                          )}
                        </div>
                      )}
                      {dish.events?.map((e) => (
                        <Chip
                          label={moment(Number(e.startTime)).format("MMM Do")}
                          onClick={() => history.push(`/menus/${e.id}`)}
                          key={e.id}
                          clickable
                          className={classes.dishChip}
                          variant="outlined"
                          color="secondary"
                        />
                      ))}
                    </article>
                  </li>
                ))}
              </ul>
              {displayShowMoreButton() && (
                <Button
                  classes={{ outlined: classes.dishesExpansionButton }}
                  variant="outlined"
                  onClick={onClickShowMore}
                  disabled={loading}
                >
                  {loading ? <CircularProgress /> : "Show More"}
                </Button>
              )}
              {displayShowLessButton() && (
                <Button
                  classes={{ outlined: classes.dishesExpansionButton }}
                  variant="outlined"
                  onClick={onClickShowLess}
                  disabled={loading}
                >
                  {loading ? <CircularProgress /> : "Show Less"}
                </Button>
              )}
            </>
          ) : (
            <Typography
              variant="body2"
              component="p"
              className={classes.dishDescription}
            >
              No Published Dishes
            </Typography>
          )}
        </section>
      </>
    );
  }
);
