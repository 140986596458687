import { gql } from "@apollo/client";

export const USER_LOGIN_WITH_APP = gql`
  mutation userLoginWithApp($input: String) {
    userLoginWithApp(app: $input) {
      id
      hasApp
    }
  }
`;
