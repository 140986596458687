import { gql } from "@apollo/client";

export const DELETE_USER = gql`
  mutation deleteUser($input: ID!) {
    deleteUser(id: $input)
  }
`;

export const ADMIN_REFUND_BRAINTREE = gql`
  mutation adminRefundBraintree($input: AdminRefundBraintreePL!) {
    adminRefundBraintree(adminRefundBraintreePL: $input) {
      id
      totalAmount
      payOutId
      createdAt
      raw
    }
  }
`;

export const ADMIN_VOID_BRAINTREE = gql`
  mutation adminVoidBraintree($input: AdminVoidBraintreePL!) {
    adminVoidBraintree(adminVoidBraintreePL: $input) {
      id
      totalAmount
      payOutId
      createdAt
      raw
    }
  }
`;

export const EDIT_USER = gql`
  mutation adminUpdateUser($input: AdminUpdateUserPL!) {
    adminUpdateUser(adminUpdateUserPL: $input) {
      id
      email
      role
      account
      nomes
      credits
      points {
        eventsAttended
      }
    }
  }
`;
