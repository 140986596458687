import { gql } from "@apollo/client";

export const GET_DISH_SELECTION = gql`
  query dishSelection($eventId: ID!, $cookGetDishesPL: CookGetDishesPL!) {
    cookGetDishes(cookGetDishesPL: $cookGetDishesPL) {
      count
      rows {
        id
        name
        defaultPrice
        imagesGQL {
          publicId
          small
          medium
          large
          original
        }
        __typename @skip(if: true)
      }
    }

    cookGetEvent(id: $eventId) {
      id
      type
      name
      status
      dishes {
        id
        name
        imagesGQL {
          publicId
          small
          medium
          large
          original
        }
        DishEvent {
          id
          count
          price
        }
        __typename @skip(if: true)
      }
    }
  }
`;
