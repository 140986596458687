import { MenuItem, OutlinedInput, Select, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { DishCategory } from "src/models";
import { useStyles } from "./select.styles";

interface DishTypeSelectProps {
  onSelect?: (type: DishCategory) => void;
  initialState?: DishCategory;
}

export const DishTypeSelect: React.FC<DishTypeSelectProps> = ({
  onSelect,
  initialState = DishCategory.ENTREE,
}) => {
  const classes = useStyles();
  const [activeType, setActiveType] = useState<DishCategory>(initialState);

  const onChangeHandler = (e) => {
    const selectedType = e.target.value as DishCategory;
    setActiveType(selectedType);
    if (onSelect) onSelect(selectedType);
  };

  return (
    <div className={classes.container}>
      <Typography
        component="label"
        variant="caption"
        className={classes.label}
        htmlFor="dish-type-select"
      >
        Type*
      </Typography>
      <Select
        classes={{
          selectMenu: classes.selectMenu,
        }}
        input={
          <OutlinedInput
            labelWidth={0}
            classes={{ notchedOutline: classes.notchedOutline }}
          />
        }
        inputProps={{
          id: "dish-type-select",
          name: "dishType",
        }}
        value={activeType}
        onChange={onChangeHandler}
      >
        <MenuItem
          classes={{ root: classes.menuItem }}
          value={DishCategory.ENTREE}
        >
          Entree
        </MenuItem>
        <MenuItem
          classes={{ root: classes.menuItem }}
          value={DishCategory.SIDE}
        >
          Side
        </MenuItem>
        <MenuItem
          classes={{ root: classes.menuItem }}
          value={DishCategory.BEVERAGE}
        >
          Beverage
        </MenuItem>
        <MenuItem
          classes={{ root: classes.menuItem }}
          value={DishCategory.DESSERT}
        >
          Dessert
        </MenuItem>
      </Select>
    </div>
  );
};
