import { useQuery } from "@apollo/client";
import { History } from "history";
import React, { FC } from "react";
import JSONTree from "react-json-tree";
import { useParams } from "react-router-dom";
import { ErrorLoadingComponent } from "src/components";
import { GET_USER } from "../api/graphql";
import { PaymentSection } from "./payment-section";

interface IProps {
  history: History;
}

export const UserDetailContainer: FC<IProps> = ({ history }) => {
  const { id } = useParams<{ id: string }>();
  const { data, error, loading } = useQuery(GET_USER, {
    variables: { input: { userId: id.split("-")[0] } },
  });
  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }
  return (
    <>
      {data.adminGetUser.payments.map((pm) => {
        return <PaymentSection payment={pm} key={pm.id} />;
      })}
      <JSONTree data={data.adminGetUser} />
    </>
  );
};
