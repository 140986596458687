import { Button, makeStyles, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { COLORS } from "src/styles";

interface IProps {
  history: any;
}

const useStyles = makeStyles({
  container: {
    fontFamily: "Signika, sans-serif",
    color: COLORS.WHITE,
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "#f05f66",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
  },

  button: {
    margin: "2em 0",
    borderColor: COLORS.WHITE,
    color: COLORS.WHITE,
    background: "transparent",
    borderRadius: 0,
    fontFamily: "Signika, sans-serif",

    "&:hover": {
      background: "#f05f66",
    },
  },
});

export const VerifyFailedContainer: FC<IProps> = ({ history }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="body2" component="p">
        {" "}
        Woops! Verification link expired.{" "}
      </Typography>
      Check your email for the latest verification email. Or sign in and click
      resend verification email for a new one.
      <Typography variant="body2" component="p">
        {" "}
        Hurry...dinner is almost ready!{" "}
      </Typography>
      <Button
        onClick={() => history.push("/")}
        size="large"
        variant="outlined"
        className={classes.button}
      >
        Take me back home!
      </Button>
    </div>
  );
};
