import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    width: "100%",
    padding: 4,
    display: "flex",
    justifyContent: "space-between",
  },
  link: {
    textDecoration: "none",

    color: COLORS.DARK_GREY,
  },
  name: { color: COLORS.MEDIUM_GREEN },
});
