import React, { FC } from "react";
import { useStyles } from "./layout.styles";
import { SignUpFlow } from "./sign-up-flow";

interface IProps {
  onSubmit?: (d: any) => void;
  switchView?: () => void;
  fromVerify?: boolean;
  history?: { push: (args: any) => void };
  location?: { search: string; pathname: string };
}

export const Layout: FC<IProps> = ({
  onSubmit,
  switchView,
  fromVerify,
  ...routerProps
}) => {
  const classes = useStyles();
  return !fromVerify ? (
    <div className={classes.container} style={{ alignItems: "stretch" }}>
      <div className={classes.image} />
      <div className={classes.signUpFormRight}>
        <SignUpFlow {...routerProps} onSubmit={onSubmit} />
      </div>
    </div>
  ) : (
    <SignUpFlow {...routerProps} switchView={switchView} onSubmit={onSubmit} />
  );
};
