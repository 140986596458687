import { SvgIcon } from "@material-ui/core";
import React from "react";

export const CookDish = (props) => (
  <SvgIcon {...props}>
    <g id="Layer_2">
      <g>
        <g id="XMLID_8_">
          <g>
            <g>
              <path
                d="M16.6,3.23v3.73c0,0.41-0.44,0.74-0.98,0.74c-0.53,0-0.98-0.33-0.98-0.74V3.23c0-0.41,0.45-0.74,0.98-0.74
            C16.16,2.49,16.6,2.82,16.6,3.23z"
              />
            </g>
            <g>
              <path
                d="M12.35,0.75v3.73c0,0.4-0.45,0.75-0.98,0.75c-0.54,0-0.98-0.35-0.98-0.75V0.75c0-0.42,0.44-0.75,0.98-0.75
            C11.9,0,12.35,0.33,12.35,0.75z"
              />
            </g>
            <g>
              <path
                d="M8.08,3.23v3.73c0,0.41-0.43,0.74-0.97,0.74S6.13,7.37,6.13,6.96V3.23c0-0.41,0.44-0.74,0.98-0.74S8.08,2.82,8.08,3.23
            z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M21.69,8.94H1.08C0.48,8.94,0,9.41,0,9.98c0,3.67,1.94,7.04,5.21,9.05v1.7c0,0.57,0.48,1.03,1.08,1.03h10.15
            c0.59,0,1.08-0.47,1.08-1.04v-1.67c3.24-1.97,5.25-5.43,5.25-9.07C22.77,9.41,22.28,8.94,21.69,8.94z M16.13,17.43h-9.5
            c-2.44-1.39-4.07-3.77-4.41-6.42h18.33C20.21,13.66,18.57,16.04,16.13,17.43z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
