import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { TimeoutDialog } from "src/components";
import { BREAK_POINTS } from "src/styles";
import { useDinerMode } from "src/utils/hooks/mode-hooks";
import { NoMatchComponent } from "../no-match";
import { SettingsContainer } from "../settings";
import { ChangePasswordContainer } from "./change-password";
import { OrderDetail } from "./event/detail/container";
import { DinerOrdersListContainer } from "./event/orders-list";
import { Layout } from "./mobile/layout";
import { NavbarContainerLeft } from "./navbar/container";
import { MobileHeader } from "./navbar/header/mobile-header";
import { routesStyles as useStyles } from "./routes.styles";

interface IProps extends RouteComponentProps<any> {
  data: any;
}

export const UserProfileRoutes: React.FC<IProps> = (props) => {
  const classes = useStyles();

  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

  const { ...rest } = props;
  const { getMe } = props.data;

  useDinerMode();

  return (
    <>
      <Helmet>
        <title>{"Diner Dashboard | Foodnome"}</title>
      </Helmet>
      {isMobile && <MobileHeader />}
      <div className={classes.container}>
        <div className={classes.colOneDiner}>
          <NavbarContainerLeft user={getMe} />
        </div>
        <TimeoutDialog />

        <div className={classes.colTwoDiner}>
          <Switch>
            <Route
              exact
              path="/d"
              render={(_props) => <Layout {...rest} user={getMe} />}
            />
            <Route path="/d/menus/:type" component={DinerOrdersListContainer} />
            <Route path="/d/order/:id" component={OrderDetail} />
            <Route path="/d/passwords" component={ChangePasswordContainer} />
            <Route
              path="/d/settings"
              render={() => <SettingsContainer getMe={getMe} />}
            />
            <Route component={NoMatchComponent} />
          </Switch>
        </div>
      </div>
    </>
  );
};
