import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS } from "src/styles";

export const eventCardsStyles = makeStyles({
  loadingWrapper: {
    position: "relative",
    zIndex: 1,
    width: "100vw",
    height: "60vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    width: "100%",
    maxWidth: 1128,
    margin: "0 auto 3rem auto",
    gridGap: "2em",
    padding: "0",
    listStyleType: "none",
    paddingBottom: "calc(5rem + env(safe-area-inset-bottom))",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: 752,
    },
    [`@media (max-width: ${BREAK_POINTS.mobileSmall}em)`]: {
      gridTemplateColumns: "repeat(auto-fit, minmax(290px, 1fr))",
      gridRowGap: "1em",
    },
  },
  eventCardWrapper: {
    maxWidth: 376,
    width: "100%",
    margin: "0 auto",
  },
});
