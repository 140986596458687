import { SvgIcon } from "@material-ui/core";
import React from "react";

export const UserSearch = (props) => (
  <SvgIcon {...props}>
    <path
      d="M20.76,16.88l-4.2-3.26c0.83-1.35,1.33-2.96,1.33-4.66C17.89,4.02,13.87,0,8.93,0C4.02,0,0,4.02,0,8.93
		c0,4.94,4.02,8.93,8.93,8.93c1.72,0,3.31-0.48,4.66-1.33l3.28,4.23c0.14,0.18,0.3,0.34,0.48,0.48c1.19,0.94,2.94,0.71,3.88-0.48
		C22.16,19.54,21.95,17.8,20.76,16.88z M2.62,8.93c0-3.49,2.82-6.31,6.31-6.31s6.31,2.85,6.31,6.31c0,3.49-2.82,6.31-6.31,6.31
		S2.62,12.42,2.62,8.93z"
    />
  </SvgIcon>
);
