import { useMutation } from "@apollo/client";
import { IconButton, Typography, useMediaQuery } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";
import { chatClient } from "src/index";
import { IGetMe } from "src/models";
import { BREAK_POINTS } from "src/styles";
import { ChatActionTypes, _ChatContext } from "..";
import { DELETE_ROOM } from "../api/mutations";
import { useStyles } from "./header.styles";

interface IProps {
  getMe?: IGetMe;
}
export const Header: FC<IProps> = ({ getMe }) => {
  const [deleteRoom] = useMutation(DELETE_ROOM);
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

  const {
    dispatch,
    state: { activeChannel },
  } = useContext(_ChatContext);

  const _deleteRoom = () => {
    deleteRoom({ variables: { input: activeChannel.id } }).then(() =>
      dispatch({ type: ChatActionTypes.SET_ACTIVE_CHANNEL })
    );
  };
  return (
    <div
      className={classes.headerSection}
      style={{
        maxWidth: isMobile ? "unset" : "340px",
      }}
    >
      <IconButton
        className={classes.backBtnChatHeader}
        id="chat-back-btn"
        onClick={() =>
          activeChannel
            ? dispatch({ type: ChatActionTypes.SET_ACTIVE_CHANNEL })
            : isMobile
            ? history.goBack()
            : dispatch({ type: ChatActionTypes.SET_DRAWER_STATUS })
        }
      >
        <ChevronLeft />
      </IconButton>
      <Typography variant="h2" component="h2" className={classes.chatHeader}>
        Chat
      </Typography>
      <button
        onClick={() => chatClient.markAllRead()}
        className={classes.clearBtn}
      >
        Clear
      </button>
      {getMe?.role === "ADMIN" && activeChannel && (
        <button onClick={_deleteRoom}>Delete</button>
      )}
    </div>
  );
};
