import { gql } from "@apollo/client";

export const GET_ALLERGENS = gql`
  query adminGetAllergens($input: PagePL) {
    getAllergens(pagePL: $input) {
      rows {
        id
        name
        description
        createdAt
      }
      count
    }
  }
`;
