import { Card, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { DishForm } from "../form";
import { DishFormSubmitPL } from "../form/form";
import { IDishFormState } from "../form/state/reducers";
import { useStyles } from "./layout.styles";

interface IProps {
  onSubmit: (pl: DishFormSubmitPL) => Promise<any>;
  initialState?: IDishFormState;
}

export const Layout: FC<IProps> = ({ onSubmit, initialState }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h2" component="h1" className={classes.mainHeader}>
        Edit Dish
      </Typography>
      <Card className={classes.dishFormCard}>
        <DishForm edit handleSubmit={onSubmit} init={initialState} />
      </Card>
    </>
  );
};
