import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    backgroundColor: COLORS.WHITE,
    position: "relative",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    maxWidth: "140rem",
    margin: "9rem auto",
    marginBottom: "15rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      margin: "5rem auto",
      marginBottom: "6rem",
    },
  },

  title: {
    fontFamily: "CustomFourBold",
    marginBottom: "2rem",
    color: COLORS.DARK_RED,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      textAlign: "center",
    },
  },

  text: {
    fontFamily: "CustomFour",
    marginBottom: "2rem",
    maxWidth: "47rem",
  },
  mainContent: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(28rem, 1fr))",
    alignItems: "center",
    padding: "0 4rem",
    columnGap: "7rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "0 2rem",
    },
  },

  image: {
    width: "100%",
  },
  formContainer: {
    maxWidth: "47rem",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },
});
