import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { BREAK_POINTS } from "src/styles";
import { DummyEventCard } from "./dummy";
import { DummyEventCardMobile } from "./dummy.mobile";

const useStyles = makeStyles({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
    width: "100%",
    maxWidth: 1128,
    margin: "0 auto 3rem auto",
    gridGap: "2em",
    padding: "0",
    maxHeight: `100vh`,
    overflow: "hidden",
    listStyleType: "none",
    justifyItems: "center",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: 752,
    },
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
      gridRowGap: "1em",
    },
  },
  containerMobile: {
    padding: 0,
    margin: "0 0.5rem",
    display: "flex",
    flexDirection: "column",
    listStyleType: "none",
    alignItems: "center",
  },
});

export const DesktopDummyEventsList: React.FC = () => {
  const [appear, setAppear] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAppear(true);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return isMobile ? (
    <CSSTransition
      in={appear}
      unmountOnExit
      classNames="slide-up"
      timeout={450}
    >
      <div className={classes.containerMobile}>
        <DummyEventCardMobile />
        <DummyEventCardMobile />
        <DummyEventCardMobile />
        <DummyEventCardMobile />
        <DummyEventCardMobile />
      </div>
    </CSSTransition>
  ) : (
    <CSSTransition
      in={appear}
      unmountOnExit
      classNames="slide-up"
      timeout={450}
    >
      <div className={classes.container}>
        <DummyEventCard />
        <DummyEventCard />
        <DummyEventCard />
        <DummyEventCard />
        <DummyEventCard />
        <DummyEventCard />
        <DummyEventCard />
        <DummyEventCard />
        <DummyEventCard />
      </div>
    </CSSTransition>
  );
};
