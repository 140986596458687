import { useQuery } from "@apollo/client";
import { parse } from "query-string";
import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { dinerPixel, ReactPixel } from "src/index";
import { IEvent } from "src/models";
import { amplitude } from "src/services";
import { GET_ME } from "src/shared-graphql/queries";
import { eventsAPI } from "../api";
import { Layout } from "./layout";

interface IProps {
  getEvent: IEvent;
}

export const EventDetailContainer: FC<IProps> = React.memo(({ getEvent }) => {
  const { data } = useQuery(GET_ME, { errorPolicy: "ignore" });
  const history = useHistory();

  // CREATE RESERVATION WITH STATUS OF VIEWED ON PAGE LOAD
  useEffect(() => {
    if (data?.getMe && getEvent && !getEvent.eventUser) {
      eventsAPI.createEventUser({ eventId: getEvent.id }).catch((error) => {
        if (error?.message?.includes("Must be logged in"))
          history.push("/login");
      });
    }
  }, [getEvent.id, data?.getMe]);

  useEffect(() => {
    const queryObj = parse(location.search) as { s: string };
    amplitude.getInstance().logEvent("[Event Detail] landing", {
      source: queryObj?.s ? queryObj?.s : "organic",
      restaurant: getEvent.restaurant.name,
    });

    process.env.NODE_ENV !== "test" &&
      ReactPixel.trackSingle(dinerPixel, "ViewContent", {
        content_type: "product", // required property
        content_ids: getEvent.restaurantId, // required property, if not using 'contents' property
      });
  }, []);

  return <Layout getEvent={getEvent} getMe={data?.getMe} />;
});
