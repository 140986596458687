import { gql } from "@apollo/client";

export const USER_UPDATE_NOTIFICATION = gql`
  mutation userUpdateNotification($input: UserUpdateNotificationPL!) {
    userUpdateNotification(userUpdateNotificationPL: $input) {
      id
      status
      headings
      subtitle
      contents
      imageUrl
      redirectUrl
      updatedAt
      createdAt
    }
  }
`;

export const USER_MARK_ALL_NOTIFICATIONS = gql`
  mutation userMarkAllNotifications($input: UserMarkAllNotifications!) {
    userMarkAllNotifications(userMarkAllNotificationsPL: $input)
  }
`;
