import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const styles = makeStyles({
  hero: {
    height: 503,
    backgroundSize: "cover",
    backgroundPosition: "25% 25%",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    position: "relative",
    overflow: "hidden",
    gridColumn: "1 / -1",
    gridRow: "1 / 4",
    zIndex: -99, // for safari
  },
  overlay: {
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.3)",
    position: "absolute",
  },
  heroText: {
    color: COLORS.WHITE,
    textAlign: "center",

    zIndex: 99,
  },
  welcomeMsg: {
    color: COLORS.DARK_BLUE,
    fontWeight: "normal",
    fontFamily: "CustomFour, sans-serif",

    margin: "10px 0 0 0",
    textAlign: "center",
  },
  header: {
    fontWeight: "normal",
    fontFamily: "CustomFourBold, sans-serif",

    color: COLORS.DARK_BLUE,
    margin: "0 0 10px 0",
    textAlign: "center",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  headerContainer: {
    maxWidth: 448,
    width: "100%",
    alignSelf: "center",
    marginBottom: 10,
    padding: 10,
  },
});
