import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import moment from "moment";
import React, { FC } from "react";
import { IReferral } from "src/models";

interface IProps {
  referrals: IReferral[];
}

export const ReferralTable: FC<IProps> = ({ referrals }) => {
  return (
    <>
      {referrals.map((r) => (
        <Accordion key={r.id}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="body2" component="p">
              Name: {r.referredBy.firstName} {r.referredBy.lastName}, Email:{" "}
              {r.referredBy.email}, Referrees: {r?.referralUsers?.length ?? 0},
              Redeemed:{" "}
              {r?.referralUsers?.reduce(
                (a, b) => a + (b.status === "REDEEMED" ? 1 : 0),
                0
              ) ?? 0}
              ,
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column" }}
          >
            {r.referralUsers.map((ru) => (
              <div key={ru.id} style={{ fontSize: 14 }}>
                - <b>Date</b>: {moment(Number(ru.createdAt)).format("ll")},{" "}
                <b>Amount</b>: {ru.amount}, <b>Status</b>: {ru.status}
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
