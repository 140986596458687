import { Actions, IAction } from "./actions";

export interface IFormState {
  firstName: string;
  lastName: string;
  picture: string;
  address: string;
  phoneNumber: string;
  email: string;
  newsletter: boolean;
}

const initialState: IFormState = {
  firstName: "",
  lastName: "",
  picture: "",
  address: "",
  phoneNumber: "",
  email: "",
  newsletter: false,
};

function reducer(state: IFormState, action: IAction): IFormState {
  switch (action.type) {
    case Actions.SET_INITIAL_STATE:
      return {
        ...state,
        ...action.user,
      };
    case Actions.SET_FIELD:
      return { ...state, [action.key]: action.value };
    case Actions.SET_AVATAR:
      return { ...state, picture: action.picture };
    case Actions.SET_ADDRESS:
      return { ...state, address: action.address };
    default:
      return state;
  }
}

export { initialState, reducer };
