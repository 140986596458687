import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import React, { FC, useState } from "react";
import { IRestaurant } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { applicationAPI } from "../../api";
import { sendAppStyles as useStyles } from "./send-app.styles";

interface IProps {
  restaurant: IRestaurant;
}

export const SendRegistration: FC<IProps> = ({ restaurant }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [nameOfOwner, setNameOfOwner] = useState(
    `${restaurant.host.firstName} ${restaurant.host.lastName}`
  );
  const [dba, setDba] = useState(restaurant.name);
  const [dbaAddress, setDbaAddress] = useState(restaurant.street as string);
  const [dbaCity, setDbaCity] = useState(restaurant.city as string);
  const [dbaState, setDbaState] = useState("CA");
  const [dbaZip, setDbaZip] = useState(restaurant.zipCode as string);
  const [phone, setPhone] = useState(restaurant.host.phoneNumber);
  const [website, setWebsite] = useState(
    restaurant.name ? `foodnome.com/${restaurant.name.replace(/ /g, "-")}` : ""
  );
  const [email, setEmail] = useState(restaurant.host.email);
  const [license, setLicense] = useState("");
  const [expDate, setExpDate] = useState("");
  const [dob, setDob] = useState("");

  const onSubmit = () =>
    applicationAPI
      .sendRegistrationToSign({
        restaurantId: restaurant.id,
        nameOfOwner,
        dba,
        dbaAddress,
        dbaCity,
        dbaState,
        dbaZip,
        phone,
        website,
        email,
        license,
        expDate,
        dob,
      })
      .then(() => {
        setOpen(false);
        sharedAPI.setSnackbarMsg({ type: "success", msg: "Form Submitted" });
      })
      .catch(() =>
        sharedAPI.setSnackbarMsg({ type: "error", msg: "Error Submitting" })
      );

  return (
    <>
      <button onClick={() => setOpen(true)} style={{ marginLeft: 6 }}>
        Send Registration
      </button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Typography component="label" variant="caption">
            Name
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={nameOfOwner}
            onChange={(e) => setNameOfOwner(e.target.value)}
          />
          <Typography component="label" variant="caption">
            DBA
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={dba}
            onChange={(e) => setDba(e.target.value)}
          />
          <Typography component="label" variant="caption">
            ID#
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={license}
            onChange={(e) => setLicense(e.target.value)}
          />
          <Typography component="label" variant="caption">
            ID exp date
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={expDate}
            onChange={(e) => setExpDate(e.target.value)}
          />
          <Typography component="label" variant="caption">
            ID Date of birth
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
          />
          <Typography component="label" variant="caption">
            Street address
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={dbaAddress}
            onChange={(e) => setDbaAddress(e.target.value)}
          />
          <Typography component="label" variant="caption">
            City
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={dbaCity}
            onChange={(e) => setDbaCity(e.target.value)}
          />
          <Typography component="label" variant="caption">
            State
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={dbaState}
            onChange={(e) => setDbaState(e.target.value)}
          />
          <Typography component="label" variant="caption">
            Zip
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={dbaZip}
            onChange={(e) => setDbaZip(e.target.value)}
          />
          <Typography component="label" variant="caption">
            Phone
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Typography component="label" variant="caption">
            Website
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
          />
          <Typography component="label" variant="caption">
            Email
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            color="secondary"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant={"contained"}
            color="secondary"
            style={{ color: COLORS.WHITE }}
            onClick={onSubmit}
            disabled={
              !nameOfOwner ||
              !dba ||
              !dbaAddress ||
              !dbaCity ||
              !dbaState ||
              !dbaZip ||
              !phone ||
              !website ||
              !email ||
              !license ||
              !expDate ||
              !dob
            }
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
