import GoogleMapReact from "google-map-react";
import React, { FC } from "react";
import { COLORS } from "src/styles";
import { CustomMarker } from "./custom-marker";

/*global google*/

interface Props {
  center?: {
    lat: number;
    lng: number;
    text?: string;
  };
  zoom?: number;
  circleOverlay?: boolean;
  pointLocation?: boolean;
  options?: any;
}

const GoogleMapContainer: FC<Props> = ({
  center,
  zoom,
  circleOverlay,
  pointLocation,
  options,
}) => {
  const apiIsLoaded = (map, maps) => {
    if (!map) return null;

    if (circleOverlay) {
      return new maps.Circle({
        strokeColor: COLORS.MEDIUM_RED,
        strokeOpacity: 0.8,
        strokeWeight: 0,
        fillColor: COLORS.MEDIUM_RED,
        fillOpacity: 0.35,
        map,
        center,
        radius: 500,
      });
    } else if (pointLocation) {
      return new maps.Marker({
        position: center,
        map,
        title: "COOK Connect!",
      });
    }
  };

  return (
    // Important! Always set the container height explicitly

    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_API as string }}
      defaultCenter={center}
      defaultZoom={zoom}
      yesIWantToUseGoogleMapApiInternals
      options={options}
      // user for google maps circle.  not currently working
      onGoogleApiLoaded={({ map, maps }) =>
        circleOverlay || pointLocation ? apiIsLoaded(map, maps) : null
      }
    >
      <CustomMarker lat={center?.lat} lng={center?.lng} text={""} />
    </GoogleMapReact>
  );
};

GoogleMapContainer.defaultProps = {
  center: {
    lat: 32.715736,
    lng: -117.161087,
    text: "San Diego",
  },
  zoom: 9,
};

export { GoogleMapContainer };
