import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation loginUser($input: LoginUserInfo!) {
    login(loginUserInfo: $input) {
      email
      role
      nomes
      account
      authToken
    }
  }
`;

export const GOOGLE_SIGN_IN = gql`
  mutation signInWithGoogle($input: GoogleUserInfo!) {
    signInWithGoogle(googleUserInfo: $input) {
      email
      firstName
      lastName
      role
      nomes
      account
      authToken
    }
  }
`;
