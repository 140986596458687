import { IconButton, IconButtonProps, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { COLORS } from "src/styles";

export const CloseMap: FC<IconButtonProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <IconButton
      className={classes.container}
      onClick={() => history.push("/menus")}
      id="close-map-btn"
      {...props}
      classes={{ sizeSmall: classes.sizeSmall }}
    >
      <Close style={{ color: "#3E3E3E" }} />
    </IconButton>
  );
};

const useStyles = makeStyles({
  container: {
    zIndex: 400,
    backgroundColor: COLORS.WHITE,
    boxShadow: "0 0 8px 0 rgba(0,0,0,0.2)",
    "&:hover": {
      backgroundColor: COLORS.LIGHT_GREY,
    },
  },
  sizeSmall: {
    padding: "1rem",
  },
});
