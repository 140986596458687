import { client } from "src/apollo";
import { IFacebookLoginPL, ISignUpPL } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { LocalStorageHelper } from "src/utils/helpers";
import { tagManager } from "src/utils/react-gtm";
import { FACEBOOK_SIGN_IN, GET_ME, GOOGLE_SIGN_IN, SIGN_UP } from "./graphql";

export const signUpAPI = {
  signup: async ({
    preferences: updateUserPreferences,
    ...userInfo
  }: ISignUpPL) =>
    client
      .mutate({
        mutation: SIGN_UP,
        variables: {
          userInfo: {
            ...userInfo,
            source: localStorage.getItem("foodnome-source"),
            referralCode: localStorage.getItem("foodnome-referree-code"),
          },
          updateUserPreferences,
        },
        ...(process.env.REACT_APP_ENV !== "cordova" && {
          awaitRefetchQueries: true,
          refetchQueries: [{ query: GET_ME }],
        }),
      })
      .then(async (d) => {
        tagManager.track({
          event: "UserSignUp",
        });
        if (process.env.REACT_APP_ENV === "cordova") {
          if (d.data && d.data.createUser && d.data.createUser.authToken) {
            await LocalStorageHelper.setItem(
              "foodnome_auth_token",
              d.data.createUser.authToken
            );
          }
          client.query({
            query: GET_ME,
            fetchPolicy: "network-only",
            errorPolicy: "ignore",
          });
        }

        return d;
      }),

  googleSignIn: (token: string, referralCode?: string, ipAddress?: string) =>
    client
      .mutate({
        mutation: GOOGLE_SIGN_IN,
        variables: {
          input: {
            token,
            referralCode,
            ipAddress,
            source: localStorage.getItem("foodnome-source"),
          },
        },
        ...(process.env.REACT_APP_ENV !== "cordova" && {
          refetchQueries: [{ query: GET_ME }],
        }),
      })
      .then((d) => {
        d?.data?.signInWithGoogle?.newUser &&
          tagManager.track({
            event: "UserSignUp",
          });
        if (
          d.data &&
          d.data.signInWithGoogle &&
          d.data.signInWithGoogle.authToken
        ) {
          LocalStorageHelper.setItem(
            "foodnome_auth_token",
            d.data.signInWithGoogle.authToken
          );
          client.query({
            query: GET_ME,
            fetchPolicy: "network-only",
            errorPolicy: "ignore",
          });
        }
        return d;
      }),
  facebookSignIn: (d: IFacebookLoginPL) => {
    return client
      .mutate({
        mutation: FACEBOOK_SIGN_IN,
        variables: {
          input: { ...d, source: localStorage.getItem("foodnome-source") },
        },
        ...(process.env.REACT_APP_ENV !== "cordova" && {
          refetchQueries: [{ query: GET_ME }],
        }),
      })
      .then((_d) => {
        _d?.data?.loginWithFacebook?.newUser &&
          tagManager.track({
            event: "UserSignUp",
          });
        if (
          _d.data &&
          _d.data.loginWithFacebook &&
          _d.data.loginWithFacebook.authToken
        ) {
          if (
            _d.data?.loginWithFacebook &&
            _d.data?.loginWithFacebook?.email?.toLowerCase() ===
              `${_d.data?.getMe?.firstName}.${_d.data?.getMe?.lastName}@foodnome.com`.toLowerCase()
          ) {
            sharedAPI.setUserUpdateEmailDialog(true);
          }
          LocalStorageHelper.setItem(
            "foodnome_auth_token",
            _d.data.loginWithFacebook.authToken
          );
          client.query({
            query: GET_ME,
            fetchPolicy: "network-only",
            errorPolicy: "ignore",
          });
        }
        return d;
      });
  },
};
