import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Fade } from "react-awesome-reveal";
import ReactStars from "react-stars";
import { CountUpAnimation } from "src/components";
import { useVisibility } from "src/utils/hooks";
import { useStyles } from "./reviews.styles";

export const Reviews: FC = () => {
  const classes = useStyles();
  const [isReviewsVisible, currentElement] = useVisibility<any>(25);

  return (
    <section ref={currentElement} className={classes.container}>
      <img
        src={require("../../assets/illustrations/tomato-02.svg")}
        className={classes.tomato}
      />
      <div className={classes.innerContainer}>
        <div className={classes.headerContainer}>
          {isReviewsVisible && (
            <Fade triggerOnce={true} delay={250}>
              <Typography variant="h2" component="h2" className={classes.title}>
                +<CountUpAnimation duration={4000}>2000</CountUpAnimation> 5
                Star Reviews
              </Typography>
            </Fade>
          )}
          <Fade triggerOnce={true} delay={350}>
            <div className={classes.squiggleContainer}>
              <img
                src={require("../../assets/underline.svg")}
                className={classes.squiggle}
              />
            </div>
          </Fade>
        </div>
        <div className={classes.mainContent}>
          <Fade triggerOnce={true} delay={500}>
            <div className={classes.card}>
              <div className={classes.innerCard}>
                <Typography
                  variant="h4"
                  component="p"
                  className={classes.reviewText}
                >
                  WOW! Amazing food and great price for the quality and
                  portions. Support your local chef and order from here!!
                </Typography>
                <ReactStars
                  count={5}
                  color={"#F7CC15"}
                  color2={"#ffd700"}
                  value={5}
                  size={21}
                  edit={false}
                />
                <Typography
                  variant="caption"
                  component="p"
                  className={classes.reviewAuthor}
                >
                  - Laura
                </Typography>
              </div>
            </div>
          </Fade>
          <Fade triggerOnce={true} delay={650}>
            <div className={classes.card}>
              <div className={classes.innerCard}>
                <Typography
                  variant="h4"
                  component="p"
                  className={classes.reviewText}
                >
                  Wonderful! Flavorful and aromatic and spicy as requested.
                  Always a treat to open these containers!
                </Typography>
                <ReactStars
                  count={5}
                  color={"#F7CC15"}
                  color2={"#ffd700"}
                  value={5}
                  size={21}
                  edit={false}
                />
                <Typography
                  variant="caption"
                  component="p"
                  className={classes.reviewAuthor}
                >
                  - Michelle
                </Typography>
              </div>
            </div>
          </Fade>
          <Fade triggerOnce={true} delay={800}>
            <div className={classes.card}>
              <div className={classes.innerCard}>
                <Typography
                  variant="h4"
                  component="p"
                  className={classes.reviewText}
                >
                  Fantastic. I wish i had more words. Wonderful food and great
                  hospitality. Wish i could have it every night 👏
                </Typography>
                <ReactStars
                  count={5}
                  color={"#F7CC15"}
                  color2={"#ffd700"}
                  value={5}
                  size={21}
                  edit={false}
                />
                <Typography
                  variant="caption"
                  component="p"
                  className={classes.reviewAuthor}
                >
                  - Denise
                </Typography>
              </div>
            </div>
          </Fade>
          <Fade triggerOnce={true} delay={950}>
            <div className={classes.card}>
              <div className={classes.innerCard}>
                <Typography
                  variant="h4"
                  component="p"
                  className={classes.reviewText}
                >
                  Great BBQ! Got the pulled pork sandwich and rib plate, both
                  were delicious! Had a great smoky flavor and moist! The beans
                  are SO good too!!!
                </Typography>
                <ReactStars
                  count={5}
                  color={"#F7CC15"}
                  color2={"#ffd700"}
                  value={5}
                  size={21}
                  edit={false}
                />
                <Typography
                  variant="caption"
                  component="p"
                  className={classes.reviewAuthor}
                >
                  - Courtney
                </Typography>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};
