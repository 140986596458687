import { Typography } from "@material-ui/core";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  YouTube,
} from "@material-ui/icons";
import moment from "moment";
import React from "react";
import { HybridLink } from "src/components";
import { _companyContact, _socialMedia } from "src/utils/constants";
import { useStyles } from "./bottom.styles";

export const FooterBottom: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.footerBottomContainer}>
      <div className={classes.footerBottom}>
        <section className={classes.copyRightContainer}>
          <Typography
            variant="caption"
            component="small"
            className={classes.copyRight}
          >
            Copyright &copy; {moment().format("YYYY")} COOK Alliance. All rights
            reserved.
          </Typography>
        </section>

        <section className={classes.socialButtonsContainer}>
          <ul className={classes.iconList}>
            <li className={classes.listItem}>
              <HybridLink
                className={classes.link}
                href={_socialMedia.instagram}
              >
                <Instagram />
              </HybridLink>
            </li>
            <li className={classes.listItem}>
              <HybridLink className={classes.link} href={_socialMedia.facebook}>
                <Facebook />
              </HybridLink>
            </li>
            <li className={classes.listItem}>
              <HybridLink className={classes.link} href={_socialMedia.youtube}>
                <YouTube />
              </HybridLink>
            </li>
            <li className={classes.listItem}>
              <HybridLink className={classes.link} href={_socialMedia.twitter}>
                <Twitter />
              </HybridLink>
            </li>
            <li className={classes.listItem}>
              <HybridLink className={classes.link} href={_socialMedia.linkedin}>
                <LinkedIn />
              </HybridLink>
            </li>
          </ul>
        </section>
        <section className={classes.submitRequestContainer}>
          <Typography
            component="a"
            variant="caption"
            className={classes.submitRequest}
            href={`mailto:${_companyContact.info}?subject=Request`}
          >
            Submit a Request
          </Typography>
        </section>
      </div>
    </div>
  );
};
