import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { CookProfile } from "src/assets/icons/svg/cook-profile";
import { SideNavigation, UserAvatar } from "src/components";
import { AppStatus, IGetMe } from "src/models";
import { cookTabs } from "./helpers";

export const NavbarContainerLeft: React.FC<{ user: IGetMe }> = ({ user }) => {
  const classes = useStyles();

  const isPermitInProgress =
    user?.restaurant?.application?.status !== AppStatus.APPROVED;

  return (
    <SideNavigation
      user={user}
      adornmentStart={
        <div className={classes.avatarContainer}>
          <div>
            <UserAvatar className={classes.large} user={user} />
          </div>
          <ul className={classes.cookInfo}>
            <li className={classes.cookNameContainer}>
              <CookProfile />
              <Typography
                variant="body1"
                component="p"
                className={classes.cookName}
              >
                {user.firstName}
              </Typography>
            </li>
            {isPermitInProgress && (
              <li>
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.progressText}
                >
                  Application in progress
                </Typography>
              </li>
            )}
          </ul>
        </div>
      }
      sideBarLinks={cookTabs}
    />
  );
};

const useStyles = makeStyles({
  avatarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1em",
  },
  large: {
    width: 74,
    height: 74,
  },
  cookInfo: {
    display: "flex",
    flexDirection: "column",
    listStyleType: "none",
    margin: 0,
    padding: 0,
    textAlign: "center",
  },
  cookNameContainer: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: ".5em",
    alignItems: "center",
  },
  cookName: {
    fontFamily: "CustomSeven",
    fontWeight: 800,

    overflow: "hidden",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "block",
    margin: 0,
  },
  progressText: {
    color: "#787878",

    fontWeight: 100,

    margin: ".25em 0",
  },
});
