import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { FC, useState } from "react";
import { StatusPicker } from "src/components";
import { IEvent, ReservationActionTypes } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";

interface IProps {
  targetEvent: IEvent;
  handleAction: (action: { type: ReservationActionTypes; payload: any }) => any;
  handleCloseDialog: (op: boolean) => void;
}

const useStyles = makeStyles({
  dialogTitle: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    textTransform: "uppercase",
  },
  inputLabel: {},
  inputRoot: {},
  label: {},
  button: {
    fontFamily: "CustomFourBold",
  },
  root: {
    display: "flex",
    justifyContent: "center",
  },
  input: {
    fontWeight: 100,
    "&::placeholder": {
      color: COLORS.MEDIUM_GREEN,
      padding: "0 10px",
    },
  },
});

export const ComplaintForm: FC<IProps> = ({
  targetEvent,
  handleAction,
  handleCloseDialog,
}) => {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [complaint, setComplaint] = useState("");
  const [complaintType, setComplaintType] = useState("FOOD_SAFETY");

  const handleCreateComplaint = () => {
    setError("");
    handleAction({
      type: ReservationActionTypes.COMPLAINT,
      payload: {
        eventId: targetEvent.id,
        restaurantId: targetEvent.restaurant.id,
        complaint,
        complaintType,
      },
    })
      .then(() => {
        handleCloseDialog(false);
        sharedAPI.setSnackbarMsg({
          type: "success",
          msg: "Complaint Submitted",
        });
      })
      .catch((_error: any) => setError(_error.message.split(":")[1]));
  };

  const handleComplaintType = (val: string) => setComplaintType(val);

  return (
    <>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        File a Complaint
      </DialogTitle>
      <DialogContent
        style={{
          position: "relative",
        }}
      >
        {error && (
          <div style={{ color: COLORS.RED, marginBottom: "20px" }}>{error}</div>
        )}

        <FormControl style={{ width: "100%" }}>
          <div>
            <StatusPicker
              handleStatus={handleComplaintType}
              statusOptions={["FOOD_SAFETY", "HOSPITALITY", "OTHER"]}
              status={complaintType}
              keyMap={(key: string) => {
                switch (key) {
                  case "FOOD_SAFETY":
                    return "Food Safety";
                  case "HOSPITALITY":
                    return "Hospitality";
                  case "OTHER":
                    return "Other";
                  default:
                    return key;
                }
              }}
            />
          </div>
          <Typography
            component="label"
            variant="caption"
            className="hidden-label"
            htmlFor="complaint-input"
          >
            Complaint
          </Typography>
          <TextField
            size="small"
            onChange={(e) => setComplaint(e.target.value)}
            id="complaint-input"
            value={complaint}
            placeholder="Submit a food-safety complaint"
            multiline
            fullWidth
            rows={4}
            InputProps={{
              classes: {
                notchedOutline: classes.input,
              },
              labelWidth: 0,
            }}
            margin="normal"
            variant="outlined"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={() => handleCloseDialog(false)}
          variant="outlined"
          size="small"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          className={classes.button}
          onClick={handleCreateComplaint}
          variant="contained"
          size="small"
          color="primary"
        >
          Submit Complaint
        </Button>
      </DialogActions>
    </>
  );
};
