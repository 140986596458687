import React, { useEffect, useState } from "react";

export const useDelay = (value: any, delay: number) => {
  const timeoutRef = React.useRef<any>();
  const [internalValue, setinternalValue] = useState(value);

  useEffect(() => {
    function onTimeout() {
      setinternalValue(value);
    }
    timeoutRef.current = setTimeout(onTimeout, delay);
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [delay, value]);

  return internalValue;
};
