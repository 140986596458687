import { Event, Search } from "@material-ui/icons";
import React from "react";
import { CookDish } from "src/assets/icons/svg/cook-dish";
import { Setting } from "src/assets/icons/svg/setting";
import { ISidebarLink } from "src/models";

export const dinerTabs: ISidebarLink[] = [
  {
    label: "Find a Meal",
    link: "/menus",
    icon: <Search style={{ width: 24, height: 28 }} />,
    when: true,
  },
  {
    label: "Upcoming Orders",
    link: "/d/menus/upcoming",
    icon: <CookDish style={{ width: 24, height: 28 }} />,
    when: true,
  },
  {
    label: "Past Orders",
    link: "/d/menus/past",
    icon: <Event style={{ width: 24, height: 28 }} />,
    when: true,
  },
  {
    label: "Settings",
    link: "/d/settings",
    icon: <Setting style={{ width: 24, height: 29 }} />,
    when: true,
  },
  // {
  //   label: "Fav Cooks",
  //   link: "/d/events/saved",
  //   icon: <BookmarkBorder style={{ width: 24, height: 28 }} />,
  //   when: true,
  // },
];
