import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { FC, useState } from "react";
import { IEvent } from "src/models";
import { eventAPI } from "../../api";
import { useStyles } from "./add-dish.styles";

interface IProps {
  open: boolean;
  event: IEvent | undefined;
  handleClose: () => void;
}

export const AddDishDialog: FC<IProps> = ({ open, event, handleClose }) => {
  if (!event) return null;
  const classes = useStyles();
  // hooks
  const [eventId, setEventId] = useState<any>(event.id);
  const [dishId, setDishId] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [count, setCount] = useState<string>("");
  const [sold, setSold] = useState<string>("");

  const [_loading, setLoading] = useState<boolean>(false);

  // handlers
  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    eventAPI
      .createDishEvent({
        eventId,
        dishId: Number(dishId),
        price: Number(price),
        count: Number(count),
        sold: Number(sold),
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="application-dialog"
      classes={{ paperWidthSm: classes.paperWidthSm }}
    >
      <DialogTitle>{event.name}</DialogTitle>
      <table className={classes.table}>
        <tbody>
          <tr>
            <th>
              <Typography component="span" variant="body2">
                Name (id)
              </Typography>
            </th>
            <th>
              <Typography component="span" variant="body2">
                Price
              </Typography>
            </th>
            <th>
              <Typography component="span" variant="body2">
                Count
              </Typography>
            </th>
            <th>
              <Typography component="span" variant="body2">
                Sold
              </Typography>
            </th>
            <th>
              <Typography component="span" variant="body2">
                Delete
              </Typography>
            </th>
          </tr>
          {event.dishes.map((d) => (
            <>
              <tr key={d.id}>
                <td>
                  <Typography component="span" variant="caption">
                    {d.name} ({d.id}) ({d.DishEvent?.id})
                  </Typography>
                </td>
                <td>
                  <Typography component="span" variant="caption">
                    {d.DishEvent ? d.DishEvent.price : "None"}
                  </Typography>
                </td>
                <td>
                  <Typography component="span" variant="caption">
                    {d.DishEvent ? d.DishEvent.count : "None"}
                  </Typography>
                </td>
                <td>
                  <Typography component="span" variant="caption">
                    {d.DishEvent ? d.DishEvent.sold : "None"}
                  </Typography>
                </td>
                <td>
                  <button
                    onClick={() =>
                      eventAPI.deleteDishEvent((d.DishEvent as any).id)
                    }
                  >
                    X
                  </button>
                </td>
                <td style={{ paddingLeft: 10 }}>
                  {d.DishEvent?.options?.length &&
                    d.DishEvent.options.map((o) => (
                      <li key={o.name}>
                        Name: {o.name}, Sold: {o.sold}
                      </li>
                    ))}
                </td>
              </tr>
              <Divider style={{ width: "100%" }} />
            </>
          ))}
        </tbody>
      </table>
      <DialogContent>
        <form onSubmit={submitHandler} className={classes.form}>
          <TextField
            size="small"
            disabled={_loading}
            label="Event Id"
            value={eventId}
            onChange={(e) => setEventId(e.target.value)}
          />
          <TextField
            size="small"
            multiline
            disabled={_loading}
            label="Dish Id"
            value={dishId}
            onChange={(e) => setDishId(e.target.value)}
          />
          <TextField
            size="small"
            disabled={_loading}
            label="Price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          <TextField
            size="small"
            disabled={_loading}
            label="Count"
            value={count}
            onChange={(e) => setCount(e.target.value)}
          />
          <TextField
            size="small"
            disabled={_loading}
            label="Sold"
            value={sold}
            onChange={(e) => setSold(e.target.value)}
          />

          <Button
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            style={{ margin: 10 }}
          >
            {_loading ? <CircularProgress /> : "Submit"}
          </Button>
        </form>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
};
