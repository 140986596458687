import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { ReviewRequest } from "src/components";
import { sharedAPI } from "src/shared-graphql";
import { GET_ME } from "../login/api";

export const ReviewContainer = () => {
  const { data, loading } = useQuery(GET_ME);

  useEffect(() => {
    if (!loading && !data?.getMe) {
      sharedAPI.setUserSignInDialog(true);
    }
    if (data?.getMe) {
      sharedAPI.setUserSignInDialog(false);
    }
  }, [data?.getMe, loading]);

  useEffect(() => {
    sharedAPI.setGlobalLoadingState(loading);
  }, [loading]);

  return (
    <div style={{ height: "100vh" }}>
      {data?.getMe && <ReviewRequest getMe={data?.getMe} />}
    </div>
  );
};
