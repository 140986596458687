import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  errorContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "3rem",
  },
  warning: { color: COLORS.DARK_GREY, fontSize: "3rem" },
  msg: {
    marginTop: "2rem",
    marginBottom: "2rem",
    color: COLORS.DARK_GREY,
    fontFamily: "CustomFourBold",
    textAlign: "center",
  },
});
