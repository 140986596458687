import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const selectSeatStyles = makeStyles({
  container: {
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "90%",
      margin: "10px auto",
      display: "flex",
      justifyContent: "space-between",
    },
  },
  popPrice: {
    color: COLORS.BLACK,
    margin: "0.5rem 0",
    textAlign: "center",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "flex",
      margin: 0,
      flexDirection: "column",
    },
  },
  popup: { fontWeight: "bold", marginRight: "6px" },
  priceSeat: {
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      textAlign: "left",
    },
  },
  actionContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    margin: "0 auto",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: 170,
      margin: "unset",
    },
  },
  clickBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "2px",
    height: "32px",
    width: "32px",
    minWidth: 0,
    border: `solid 3px ${COLORS.MEDIUM_GREEN}`,

    color: COLORS.MEDIUM_GREEN,
    cursor: "pointer",
    "&:disabled": {
      border: `solid 2px ${COLORS.MEDIUM_GREY}`,
      cursor: "not-allowed",
      color: COLORS.MEDIUM_GREY,
    },
  },
  clickBtnLabel: {
    width: 32,
  },
  seat: {
    color: "grey",
    verticalAlign: "center",
    width: "60%",
    border: "1px solid #E2E2E2;",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: 80,
    },
  },
  seatNumber: {
    color: COLORS.BLACK,

    fontWeight: 600,
    fontFamily: "CustomFourBold",
  },
});
