import { useMutation } from "@apollo/client";
import { Avatar, Button, List, ListItem, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import React from "react";
import { IEvent, IEventUser, IUser } from "src/models";
import { COLORS } from "src/styles";
import { CHECKIN_EVENT } from "../../api/graphql";

interface UserListProps {
  guests: IUser[];
  classes: any;
  getEvent: IEvent;
  onClickUser: (user: IUser) => void;
}

interface GuestProp {
  eventUser?: IEventUser;
  guest?: IUser;
  classes: any;
  join?: string;
}

export const OrderedItems: React.FC<GuestProp> = ({
  eventUser,
  classes,
  join = ", ",
}) => {
  if (!eventUser) return null;
  if (!eventUser.orderedDishes) return null;
  const key = eventUser?.eventId ? "EventUserDish" : "OrderDish";
  return (
    <>
      {eventUser.orderedDishes
        .map((d) => d)
        .sort((a, b) => a[key].count - b[key].count)
        .map(({ ...dish }) => {
          const orderedDishes = dish[key];
          if (orderedDishes.options?.length) {
            return orderedDishes.options.map((o) => (
              <Typography
                variant="body2"
                component="p"
                className={classes.userOrderedItems}
                key={dish.id + o.name}
              >
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {o.count}
                </span>{" "}
                x {dish.name} ({o.name})
              </Typography>
            ));
          }
          return (
            <Typography
              variant="body2"
              component="p"
              className={classes.userOrderedItems}
              key={dish.id}
            >
              <span style={{ color: "red", fontWeight: "bold" }}>
                {orderedDishes.count}
              </span>{" "}
              x {dish.name}
            </Typography>
          );
        })}
    </>
  );
};

export const UserContact: React.FC<GuestProp> = ({ guest, classes }) => {
  return (
    <ul className={classes.userContact}>
      {guest?.phoneNumber && (
        <li className={classes.userContactItem}>{guest.phoneNumber}</li>
      )}
    </ul>
  );
};

export const UserList: React.FC<UserListProps> = ({
  guests,
  classes,
  onClickUser,
  getEvent,
}) => {
  const [checkInUser] = useMutation(CHECKIN_EVENT);
  return (
    <List data-testid="users-order-list" className={classes.userList}>
      {guests.map((guest) => {
        const avatarProps = Object.assign(
          {},
          guest.picture
            ? { src: guest.picture, alt: `${guest.firstName} avatar` }
            : { children: guest.firstName[0]?.toUpperCase() }
        );

        return (
          <ListItem
            data-testid="user-order-item-timeline"
            key={guest.id}
            className={classes.userListItem}
          >
            <Avatar
              role="button"
              aria-label={`open dialog for ${guest.firstName} ${guest.lastName}`}
              style={{ cursor: "pointer" }}
              onClick={() => onClickUser(guest)}
              imgProps={{ "data-testid": "order-user-avatar" } as any}
              className={classes.userAvatar}
              {...avatarProps}
            />
            <div className={classes.userOrderLayout}>
              <ul className={classes.userOrderList}>
                <li
                  aria-label={`open dialog for ${guest.firstName} ${guest.lastName}`}
                  role="button"
                  style={{ cursor: "pointer" }}
                  onClick={() => onClickUser(guest)}
                >
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.userFullName}
                  >{`${guest.firstName} ${guest.lastName}`}</Typography>
                </li>

                {guest.eventUser.dineOption && (
                  <li>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.userDineOption}
                    >{`I'm ${
                      guest.eventUser.dineOption === "DROP_IN"
                        ? "dining in"
                        : "ordering to-go."
                    }`}</Typography>
                  </li>
                )}
                <li>
                  <OrderedItems eventUser={guest.eventUser} classes={classes} />
                </li>
              </ul>
              <div className={classes.orderActionsLayout}>
                {guest.eventUser.checkedIn && (
                  <div className={classes.orderActionSuccessLayout}>
                    <Check
                      style={{
                        marginRight: "1rem",
                        color: COLORS.MEDIUM_GREEN,
                      }}
                    />
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.orderCompleteText}
                    >
                      Completed
                    </Typography>
                  </div>
                )}
                {guest.eventUser.checkedIn ? (
                  <Button
                    classes={{ root: classes.undoCompleteButton }}
                    onClick={() =>
                      checkInUser({
                        variables: {
                          input: { userId: guest.id, eventId: getEvent.id },
                        },
                      })
                    }
                  >
                    Undo
                  </Button>
                ) : (
                  <Button
                    onClick={() =>
                      checkInUser({
                        variables: {
                          input: { userId: guest.id, eventId: getEvent.id },
                        },
                      })
                    }
                    classes={{ contained: classes.completeOrderButton }}
                    variant="contained"
                    size="small"
                  >
                    Complete
                  </Button>
                )}
              </div>
            </div>
          </ListItem>
        );
      })}
    </List>
  );
};
