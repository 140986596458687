import { gql } from "@apollo/client";

export const GET_RESTAURANT_DISHES = gql`
  query getRestaurantDishes($input: GetRestaurantDishesPL!) {
    getRestaurantDishes(getRestaurantDishesPL: $input) {
      count
      rows {
        id
        name
        description
        events {
          id
          name
          startTime
        }
        imagesGQL {
          publicId
          small
          medium
          large
          original
        }
      }
    }
  }
`;
