import { Button, Typography } from "@material-ui/core";
import haversine from "haversine";
import React, { useEffect, useState } from "react";
import { COLORS } from "src/styles";

interface DistanceAwayButtonProps {
  restaurantCoordinates?: {
    longitude: number;
    latitude: number;
  };
  isGeolocationSupported?: boolean;
}

export const DistanceAwayButton: React.FC<DistanceAwayButtonProps> = ({
  restaurantCoordinates,
  isGeolocationSupported = "navigator" in window,
}) => {
  const [isBlocked, setIsBlock] = useState<boolean>(false);
  const [userCoordinates, setUserCoordinates] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  let geo;

  useEffect(() => {
    if (!isGeolocationSupported) return;
    if ("permissions" in navigator) {
      navigator.permissions.query({ name: "geolocation" }).then((res) => {
        if (res.state === "granted") getUserCoordinates();
        else if (res.state === "denied") setIsBlock(true);
      });
    }
    return () => {
      // Cleanup to prevent console error
      navigator.geolocation.clearWatch(geo);
    };
  }, []);

  const getUserCoordinates = () => {
    if (isGeolocationSupported) {
      setLoading(true);
      geo = navigator.geolocation.watchPosition(
        (pos: any) => {
          setUserCoordinates({
            longitude: pos.coords.longitude,
            latitude: pos.coords.latitude,
          });
          setLoading(false);
        },
        () => {
          setIsBlock(true);
          setLoading(false);
        },
        {
          timeout: 10000,
          enableHighAccuracy: true,
          maximumAge: 100000,
        }
      );
    }
  };

  if (!isGeolocationSupported || !restaurantCoordinates) return null;
  else if (loading) return <span>Calculating Distance...</span>;
  else if (isBlocked) return null;
  else if (!!userCoordinates && !!restaurantCoordinates) {
    return (
      <Typography
        variant="body2"
        component="p"
        data-testid="distance-away-in-miles"
        style={{
          fontFamily: "CustomFourBold",
          fontWeight: 500,
          color: COLORS.BLACK,

          margin: 0,
        }}
      >{`${haversine(userCoordinates, restaurantCoordinates, {
        unit: "mile",
      }).toFixed(1)} miles away`}</Typography>
    );
  } else
    return (
      <>
        <Button
          onClick={getUserCoordinates}
          style={{
            color: COLORS.BLACK,

            fontFamily: "CustomFourBold",
            fontWeight: 500,
            textTransform: "capitalize",
            padding: 0,
          }}
        >
          Calculate Distance Away
        </Button>
      </>
    );
};
