import { useQuery } from "@apollo/client";
import axios from "axios";
import React, { createContext, Dispatch, useEffect, useReducer } from "react";
import { SERVER_ADDRESS } from "src/apollo";
import { chatClient } from "src/index";
import { IGetMe } from "src/models";
import { Chat } from "stream-chat-react";
import "stream-chat-react/dist/css/index.css";
import { GET_ME } from "../header/api/graphql";
import {
  ChatActionTypes,
  chatReducer,
  ComponentActions,
  initialState,
  IState,
} from "./chat-reducer";

export const _ChatContext = createContext<{
  state: IState;
  dispatch: Dispatch<ComponentActions>;
}>({} as any);

export const StreamChatPrivider = ({ children }) => {
  const { data } = useQuery<{ getMe: IGetMe }>(GET_ME);
  const [state, dispatch] = useReducer(chatReducer, initialState);

  useEffect(() => {
    if (data?.getMe && process.env.NODE_ENV !== "test") {
      dispatch({ type: ChatActionTypes.SET_LOADING_STATUS, payload: true });
      const token = localStorage.getItem("foodnome_auth_token");
      chatClient.disconnect();

      axios
        .get(`${SERVER_ADDRESS}/stream/get-token`, {
          withCredentials: true,
          ...(process.env.REACT_APP_ENV === "cordova" && {
            headers: { authorization: token ? token : "none" },
          }),
        })
        .then(async (d) => {
          chatClient.on(((event) => {
            if (event.total_unread_count !== undefined) {
              dispatch({
                type: ChatActionTypes.SET_UNREAD_COUNT,
                payload: event.total_unread_count,
              });
            }
          }) as any);

          chatClient
            .connectUser(
              {
                id: data?.getMe.id,
                name: data?.getMe.firstName + " " + data?.getMe.lastName,
                image: data?.getMe.picture,
                appRole: data?.getMe.role,
              },
              d.data.token
            )
            .then((_d: any) => {
              dispatch({
                type: ChatActionTypes.SET_UNREAD_COUNT,
                payload: _d?.me?.unread_count,
              });
            });
          dispatch({
            type: ChatActionTypes.SET_LOADING_STATUS,
            payload: false,
          });
        });
    }
  }, [data?.getMe?.id]);

  return (
    <_ChatContext.Provider value={{ state, dispatch }}>
      {chatClient ? (
        <Chat client={chatClient} theme={"messaging light"}>
          {children}
        </Chat>
      ) : (
        children
      )}
    </_ChatContext.Provider>
  );
};
