import { useQuery } from "@apollo/client";
import React, { FC, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ErrorLoadingComponent } from "src/components";
import { ReservationActionTypes } from "src/models";
import { eventsAPI } from "../api";
import { GET_EVENT } from "./api";
import { Layout } from "./layout";

export const OrderDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { data, loading, error } = useQuery(GET_EVENT, {
    variables: { input: (id as any).split("-")[0] },
    fetchPolicy: "cache-and-network",
  });
  // console.log(data);
  useEffect(() => {
    if (!loading && data?.getEvent?.eventUser?.status === "SAVED") {
      history.push(`/menus/${id}`);
    }
  }, [data?.getEvent]);

  const handleReservationAction = React.useCallback(
    (action: { type: ReservationActionTypes; payload: any }) => {
      switch (action.type) {
        case ReservationActionTypes.COMPLAINT:
          return eventsAPI.createComplaint(action.payload);
        default:
          throw new Error(
            `handleReservationAction called with invalid type ${action.type}`
          );
      }
    },
    []
  );

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }

  return (
    <Layout handleAction={handleReservationAction} event={data.getEvent} />
  );
};
