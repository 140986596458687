import { SvgIcon } from "@material-ui/core";
import React from "react";

export const HomeIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      d="M16,0A16,16,0,1,1,0,16,16,16,0,0,1,16,0Zm8.3,13.58a.69.69,0,0,0-.24-.81L16.48,6.92a.77.77,0,0,0-1,0L7.94,12.77a.71.71,0,0,0-.24.81.75.75,0,0,0,.71.49h.77v6.58a2.24,2.24,0,0,0,2.27,2.2h9.11a2.24,2.24,0,0,0,2.27-2.2V14.07h.76A.74.74,0,0,0,24.3,13.58Z"
      strokeWidth="2"
      stroke="white"
      strokeLinejoin="miter"
      strokeLinecap="round"
    />
    <path
      d="M24.06,12.77a.69.69,0,0,1,.24.81.74.74,0,0,1-.71.49h-.76v6.58a2.24,2.24,0,0,1-2.27,2.2H11.45a2.24,2.24,0,0,1-2.27-2.2V14.07H8.41a.75.75,0,0,1-.71-.49.71.71,0,0,1,.24-.81l7.59-5.85a.77.77,0,0,1,1,0Zm-2.75,7.88V14.07H10.69v6.58a.75.75,0,0,0,.76.74h2.73V17.73a.75.75,0,0,1,.76-.74h2.12a.75.75,0,0,1,.76.74v3.66h2.74A.75.75,0,0,0,21.31,20.65Z"
      fill="#fff"
    />
    <path d="M21.31,14.07v6.58a.75.75,0,0,1-.75.74H17.82V17.73a.75.75,0,0,0-.76-.74H14.94a.75.75,0,0,0-.76.74v3.66H11.45a.75.75,0,0,1-.76-.74V14.07Z" />
  </SvgIcon>
);
