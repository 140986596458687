import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "#E6FFFB",
    padding: "2rem 5rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "2rem",
      alignItems: "center",
      textAlign: "center",
    },
  },
  title: {
    fontFamily: "CustomFourBold",
  },
  link: {
    textDecoration: "none",
    color: COLORS.MEDIUM_GREEN,
  },
});
