import { makeStyles, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { HybridLink } from "src/components";
import { COLORS } from "src/styles";

const useStyles = makeStyles({
  tosHeading: {
    marginTop: "2rem",
    marginBottom: ".5rem",
  },
  link: {
    color: COLORS.MEDIUM_GREEN,
  },
});

export const TermsOfService: FC = () => {
  const classes = useStyles();
  return (
    <div
      style={{
        margin: "2rem",
        marginBottom: "calc(env(safe-area-inset-bottom) + 8rem)",
      }}
    >
      <Typography variant="h1" component="h1" className={classes.tosHeading}>
        Terms of Service
      </Typography>
      <Typography variant="body2" component="p">
        This notice sets forth the terms and conditions under which COOK
        Alliance, a California Nonprofit Corporation, any features and services
        from the websites cookconnect.com, foodnome.com and mobile applications
        (collectively COOK) provides information on this website, as well as the
        terms and conditions governing your use of this website, along with any
        additional terms and conditions that may be referenced herein or that
        are presented elsewhere on the website in relation to a specific service
        or feature. By making use of this website or being redirected here from
        another domain, you agree to be bound by the terms and conditions that
        appear hereunder. The terms and conditions are subject to updating at
        any time without notice and such changes, additions or deletions shall
        be effective immediately upon posting. We may terminate, change, suspend
        or discontinue any aspect of the website, including the availability of
        any features of the website, at any time. If at any time you do not
        accept the terms and conditions, do not access the website or any of its
        pages. Changes to the terms and conditions take effect when posted to
        the website. The COOK website allows food Microenterprises
        ("Microenterprises ") to market and offer for sale meals for dine in,
        delivery, and/or pickup and customers to search for and purchase such
        meals. You should closely review the information concerning the
        Microenterprises to ensure you are able to properly utilize and benefit
        from the COOK services. The Microenterprises are obligated to comply
        with federal, state and local laws, rules, regulations, and standards
        pertaining to food preparation, sale, marketing and safety. However, it
        is critical for you to understand that COOK does not in any way
        independently verify the credentials, representations or products of
        Microenterprises , the ingredients or the quality of any products, or
        that a Restaurant is in compliance with applicable laws. You must make
        yourself comfortable through information provided by Microenterprises on
        the COOK website or as requested by you directly from the
        Microenterprises as to the quality and reliability of the
        Microenterprises , as well as to their compliance with applicable laws.
        COOK does not in any way guaranty the quality of any food or that any
        food complies with applicable laws. In addition, a Restaurant may
        represent that food preparation is in accordance with special standards
        such as "organic," "kosher," "macrobiotic" or allergen-specific
        standards such as "nut-free," "gluten-free," or "lactose-free." However,
        COOK does not independently investigate or verify such representations.
        COOK shall not be liable or responsible for any food or services offered
        by Microenterprises that is unhealthy, is the cause of injury, that is
        otherwise unacceptable to buyers or that does not meet the expectation
        of buyers in any manner. You are solely responsible for verifying the
        accuracy of delivery addresses, and COOK shall have no liability or
        responsibility for any such erroneous addresses.
      </Typography>

      <Typography variant="h1" component="h1" className={classes.tosHeading}>
        NO WARRANTY
      </Typography>
      <Typography variant="body2" component="p">
        COOK SPECIFICALLY DISCLAIMS ALL WARRANTIES WITH RESPECT TO THIS WEBSITE
        OR YOUR USE THEREOF. THE CONTENT, INFORMATION AND SERVICES PROVIDED
        THROUGH THIS WEBSITE, INCLUDING BUT NOT LIMITED TO GRAPHICS, TEXT AND
        LINKS TO OTHER SITES, ARE PROVIDED ON AN "AS IS" AND AS AVAILABLE BASIS
        AND MAY BE CHANGED WITHOUT PRIOR NOTICE. YOU EXPRESSLY AGREE THAT THE
        USE OF THIS WEBSITE IS AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE
        SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF
        DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. COOK MAKES NO
        REPRESENTATIONS OR WARRANTIES OF ANY KIND WITH RESPECT TO THE ACCURACY,
        ADEQUACY, APPROPRIATENESS, COMPLETENESS, CONDITION, CONTENT, QUALITY,
        RELIABILITY, SUFFICIENCY, CORRECTNESS, VERACITY, VALUE, COMPLETENESS, OR
        TIMELINESS OF THE CONTENT, INFORMATION, OPINIONS, ADVICE, SERVICES,
        TEXT, GRAPHICS, LINKS OR OTHER ITEMS PROVIDED THROUGH THE WEBSITE OR
        WHETHER SAME WILL BE UNINTERRUPTED. COOK EXPRESSLY DISCLAIMS ALL SUCH
        REPRESENTATIONS AND WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING
        WITHOUT LIMITATION WARRANTIES OF TITLE, NONINFRINGEMENT, OR IMPLIED
        WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
      </Typography>

      <Typography variant="h1" component="h1" className={classes.tosHeading}>
        LIMITATION OF LIABILITY
      </Typography>
      <Typography variant="body2" component="p">
        THE INFORMATION CONTAINED ON THE COOK WEBSITE IS PROVIDED FOR
        EDUCATIONAL AND INFORMATION PURPOSES HEREIN. COOK DOES NOT WARRANT OR
        GUARANTEE THE ACCURACY OR AVAILABILITY OF THE CONTENT ON THIS WEBSITE OR
        ON OTHER SITES TO WHICH IT LINKS. OTHER THAN AS REQUIRED BY APPLICABLE
        LAW, IF ANY, IN NO EVENT SHALL COOK OR ITS SHAREHOLDERS, DIRECTORS,
        OFFICERS, EMPLOYEES, AGENTS, SUBSIDIARIES, COMMUNITY, AFFILIATES,
        LICENSORS, CO-BRANDERS OR OTHER PARTNERS, AND ANY AND ALL THIRD PARTIES
        THAT PROVIDE CONTENT, INFORMATION OR SERVICES FOR THIS WEBSITE BE LIABLE
        TO YOU FOR ANY LOSS, EXPENSE OR DAMAGES WHATSOEVER ARISING OUT OF THE
        USE OF, MISUSE OF, RELIANCE ON, OR INABILITY TO USE, THE WEBSITE OR ITS
        CONTENT, REGARDLESS OF: THE TYPE OF DAMAGES (INCLUDING BUT NOT LIMITED
        TO DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, CONSEQUENTIAL, LOSS
        OF DATA, INCOME, PROFITS OR REVENUE, LOSS OF OR DAMAGE TO PROPERTY, AND
        CLAIMS OF THIRD PARTIES - EVEN IF COOK HAS BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES); HOW IT WAS CAUSED (DIRECTLY OR INDIRECTLY
        BY ANY ERRORS OR OMISSIONS IN THE CONTENT, BY THE USE, INABILITY TO USE,
        EXISTENCE OR RELIANCE UPON SUCH CONTENT, INFORMATION OR SERVICES, BY THE
        USE OR PERFORMANCE OF THIS WEBSITE, BY THE PROVISION OF OR FAILURE TO
        PROVIDE SERVICES, BY THE INFORMATION OR SERVICES PROVIDED THROUGH THIS
        WEBSITE, OR OTHERWISE ARISING OUT OF THE USE OF THIS WEBSITE); OR THE
        LEGAL THEORY ASSERTED (CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR
        OTHERWISE). COOK ASSUMES NO DUTY OF CARE AND THE INFORMATION IS PROVIDED
        WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT
        LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, NON-INFRINGEMENT AND FREEDOM FROM COMPUTER VIRUSES
        OR SIMILAR DISABLING DEVICES. YOU ALONE ARE RESPONSIBLE FOR EVALUATING
        THE ACCURACY, COMPLETENESS OR USEFULNESS OF ANY INFORMATION, ADVICE OR
        OTHER CONTENT AVAILABLE ON THIS WEBSITE. ALTHOUGH WE MAKE GOOD FAITH
        EFFORTS TO MAINTAIN A SECURE OPERATING ENVIRONMENT, WE CANNOT GUARANTEE
        COMPLETE SECURITY. BY VISITING COOK WEBSITE, YOU AGREE TO INDEMNIFY AND
        HOLD COOK, AND ITS SUBSIDIARIES, COMMUNITY, AFFILIATES, OFFICERS,
        AGENTS, CO-BRANDERS OR OTHER PARTNERS, AND EMPLOYEES, HARMLESS FROM ANY
        CLAIM OR DEMAND, INCLUDING REASONABLE ATTORNEYS' FEES, MADE BY ANY THIRD
        PARTY DUE TO OR ARISING OUT OF CONTENT YOU SUBMIT, POST, TRANSMIT OR
        MAKE AVAILABLE THROUGH THE WEBSITE, YOUR USE OF THE WEBSITE, YOUR
        CONNECTION TO THE WEBSITE, YOUR VIOLATION OF THE TERMS AND CONDITIONS,
        OR YOUR VIOLATION OF ANY RIGHTS OF ANOTHER. Because some
        states/jurisdictions do not allow the exclusion or limitation of
        liability for consequential or incidental damages, the above limitation
        may not apply to you. If you are dissatisfied with any portion of the
        website or related services, or with any of these terms of use, your
        sole and exclusive remedy is to stop using the website and related
        services.
      </Typography>

      <Typography variant="h1" component="h1" className={classes.tosHeading}>
        ARBITRATION AND WAIVER OF CLASS ACTION AND JURY TRIAL
      </Typography>
      <Typography variant="body2" component="p">
        ALL CLAIMS AND DISPUTES ARISING UNDER OR RELATING TO THIS TERMS AND
        CONDITIONS ARE TO BE SETTLED BY BINDING ARBITRATION IN SAN FRANCISCO.
        YOU AGREE THAT ANY AND ALL CLAIMS AND DISPUTES, WHETHER SUBMITTED TO
        ARBITRATION OR DECIDED BY A COURT, MUST BE BROUGHT IN YOUR INDIVIDUAL
        CAPACITY AND NOT AS A CLASS ACTION PLAINTIFF OR CLASS MEMBER IN ANY
        PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. YOU AGREE TO WAIVE THE
        RIGHT TO PARTICIPATE IN A CLASS ACTION OR LITIGATE ON A CLASS-WIDE
        BASIS. YOU AGREE THAT YOU HAVE EXPRESSLY AND KNOWINGLY WAIVED THESE
        RIGHTS. YOU AND COOK HEREBY VOLUNTARILY AND KNOWINGLY WAIVE THE RIGHT TO
        TRIAL BY JURY OF ALL DISPUTES AND CLAIMS BY, BETWEEN OR AGAINST EITHER
        YOU OR COOK WHETHER THE DISPUTE OR CLAIM IS SUBMITTED TO ARBITRATION OR
        IS DECIDED BY A COURT, TO THE FULLEST EXTENT ALLOWED BY LAW.
      </Typography>
      <Typography variant="h1" component="h1" className={classes.tosHeading}>
        Reviews and Comments
      </Typography>
      <Typography variant="body2" component="p">
        If at any time, COOK enables site visitors to post reviews of and
        comments on products featured on the site, the party posting that
        comment or review grants COOK and its affiliates a non-exclusive,
        royalty-free, perpetual, irrevocable, and fully sub-licensable right to
        use, reproduce, modify, adapt, publish, translate, create derivative
        works from, distribute, and display such reviews and comments throughout
        the world in any media. You also grant COOK and its affiliates the right
        to use the name that you submit with any review or comment, if any, in
        connection with such review or comment.
      </Typography>
      <Typography variant="h1" component="h1" className={classes.tosHeading}>
        Intellectual Property
      </Typography>
      <Typography variant="body2" component="p">
        All of the information on this website contain copyrighted material,
        trademarks and other proprietary information, including all text,
        graphics, software applications, video and audio files and photos
        (collectively, "content"), and all rights in the pages and the screens
        displaying the pages are owned by COOK and its community or affiliated
        entities or their licensors, protected by copyright under United States
        copyright laws and foreign laws. COOK owns copyright in the selection,
        coordination, arrangement and enhancement of such content, as well as in
        the content original to it. You may not modify, reproduce, copy,
        distribute, transmit, display, publish, sell, license, create derivative
        works of or use any aspect of the website or its content, including, but
        not limited to copy, graphics, photos, web design, and the like for
        commercial or public purposes or on any other website or in a networked
        computer environment or engage in any other redistribution or
        republication of the protected content herein. All rights are reserved,
        including common law or registered trademarks, service marks, trade
        names, logos, URLs, domain names, and icons appearing on this website,
        which are owned by COOK and its community or affiliated entities or
        their licensors. Nothing on this website grants you any right or license
        to use any intellectual property including business methods or processes
        without the express written permission of COOK. No material appearing on
        any COOK domain may be copied, distributed, posted, reproduced,
        republished, sold, transmitted, uploaded, or exploited in any means or
        for any use not approved of in writing by COOK. You agree not to copy,
        duplicate, reproduce, sell, resell or exploit for any commercial
        purposes, any portion of this website, its use or access to it. You
        further acknowledge that you do not acquire any ownership rights by
        downloading copyrighted material. Any information sent to COOK via
        Internet e-mail or through the website is not secure and is done on a
        non-confidential basis. All information that you submit via our websites
        or related e-mail shall be deemed and remain the property COOK subject
        to our Privacy Policy. If you post, upload, submit or otherwise transmit
        any content on or through this website, you agree to provide true,
        accurate and complete information and to refrain from impersonating any
        person or entity and from falsely representing your affiliation with any
        person or entity. Any submission of such information will be subject to
        these terms of use. We shall, subject to our Privacy Policy, be free to
        use, for any purpose, any ideas, concepts, know-how or techniques
        contained in information a visitor to this website provides to us
        through this website. We shall not be subject to any obligations
        regarding such information except as expressly indicated with respect to
        personally identifiable information in statement contained within the
        website addressing privacy or to the extent required by law.
      </Typography>
      <Typography variant="h1" component="h1" className={classes.tosHeading}>
        Hypertext Links
      </Typography>
      <Typography variant="body2" component="p">
        You may create a text link to the home page of this website only for
        legitimate business reasons on the condition that it is clear to the
        users that they are exiting your own website and entering a new website,
        you do not frame or in-line our website or its content, and you do not
        create a false association or endorsement or otherwise misrepresent your
        relationship with COOK. Likewise, as a convenience to you, COOK may
        provide links to third party websites, or permit third parties to link
        to this website. Because these other websites are not under our control,
        we cannot guarantee the quality, accuracy, timeliness or security of
        those websites. Your business dealings or correspondence with, or
        participation in promotions of, advertisers other than COOK and any
        terms, conditions, warranties or representations associated with such
        dealings, are solely between you and such third party. A link to or from
        another website does not necessarily imply an affiliation between COOK
        and the third party website owner or an endorsement of the third party
        site or any products or services that it describes. Your access to and
        use of any other websites linked to or from this website or any result
        of the presence of third-party advertisers on this website is at your
        own risk and we assume no obligation or liability in connection
        therewith. We may disable a link at any time and may remove links from
        this website at the third party owner's request.
      </Typography>
      <Typography variant="h1" component="h1" className={classes.tosHeading}>
        Products and Services Disclaimer
      </Typography>
      <Typography variant="body2" component="p">
        Please note that none of the information contained in this website
        should be viewed as a formal offer to sell or as a direct solicitation
        to purchase any of our products or services, nor should it be considered
        advice. Rather, the information on our products and services is provided
        to you so that you can learn what COOK can offer. We may make
        improvements and/or changes in the products, services and/or programs
        described on this site at any time without notice. Information on the
        website may include references to products or services not offered in
        your state or country or not available for purchase over the Internet in
        your state or country. Variations in state law may exist, and any
        statements that are general in nature are subject to change based on
        such variations. Availability of products or services may be dependent
        upon state regulations.
      </Typography>
      <Typography variant="h1" component="h1" className={classes.tosHeading}>
        International Users
      </Typography>
      <Typography variant="body2" component="p">
        These Terms and any operating rules for this website established by COOK
        constitute the entire agreement of the parties with respect to the
        subject matter hereof, and supersede all previous written or oral
        agreements between the parties with respect to such subject matter. The
        provisions of these terms are for the benefit of COOK, its parent,
        subsidiaries, other affiliates and its third party content providers and
        licensors and each shall have the right to assert and enforce such
        provisions directly or on its own behalf. This website is controlled and
        operated by COOK from offices within the United States of America where
        you submit to the exclusive jurisdiction and venue of the courts in the
        state of California. We make no representation that materials at this
        website are appropriate or available for use outside of the United
        States or that access to their content from all territories is legal.
        You may not use the website or export the website materials in violation
        of U.S. laws and regulations. If you access this website from locations
        outside of the United States, you are responsible for compliance with
        all local laws. These terms and conditions which you have agreed to by
        visiting this website must be governed by the laws of the state of
        California, without giving effect to its conflict of laws provision.
      </Typography>
      <Typography variant="h1" component="h1" className={classes.tosHeading}>
        Ordering Products and Services
      </Typography>
      <Typography variant="body2" component="p">
        You must be 18 years or older, and have a valid credit card, with full
        authority to use it, to submit an order for products or services through
        any of COOK websites. If you are a minor, you must have your parent or
        guardian read and agree to this Agreement prior to you using the
        Website. Persons under the age of 13 are not permitted to register for
        the COOK website or use the COOK website services. All ordering is
        performed online. You will typically be billed directly by COOK for your
        orders, and "COOK" will be the name that appears on your payment
        records, but COOK shall not in any manner be considered the seller of
        the food. On occasion, credit card information will be provided to the
        Microenterprises , and in such case, the Restaurant will bill you
        directly. You will be notified at the time of purchase if the Restaurant
        will be billing you directly. In such case, you hereby authorize COOK to
        transfer your credit card information to the Restaurant for processing.
      </Typography>
      <Typography variant="h1" component="h1" className={classes.tosHeading}>
        Prices and Availability
      </Typography>
      <Typography variant="body2" component="p">
        All prices listed on COOK websites are subject to change. In the event a
        product or service is listed at an incorrect price or with incorrect
        information due to typographical error or error in pricing or product
        information received from our suppliers, COOK shall have the right,
        prior to the acceptance of your order pursuant to our Order Acceptance
        Policy described below, to refuse or cancel any such orders whether or
        not the order has been confirmed and your credit card account charged.
        If your credit card account has already been charged for the purchase
        and your order is canceled, COOK shall immediately issue a credit to
        your credit card account in the amount of the charge. The availability
        of products or services and other items on each of COOK websites may
        change at any time, without notice.
      </Typography>
      <Typography variant="h1" component="h1" className={classes.tosHeading}>
        Special offers
      </Typography>
      <Typography variant="body2" component="p">
        COOK might offer premiums, subject to the terms and conditions expressly
        specified by COOK.
      </Typography>
      <Typography variant="h1" component="h1" className={classes.tosHeading}>
        Terms of Purchase
      </Typography>
      <Typography variant="body2" component="p">
        Payment is due at the time of order. You agree to pay all fees and
        charges incurred in connection with your purchases including any sales
        taxes, surcharges and any delivery fees for the delivery service you
        select. The final charges for your order may be different than those
        stated on the website. COOK does not assume any responsibility or
        liability for the actions, products, and content of any third parties
        related to the creation or delivery of your order. COOK may require
        additional verification or information before accepting any order. By
        selecting to pay via credit card as a method of payment, you agree that
        you must be authorized to use the credit card presented as a method of
        payment. Prior to the purchase of any goods or services on our website,
        you must provide us with a valid credit card number information and
        associated payment information, including, but not limited to, the
        following: (i) your name as it appears on the card, (ii) your credit
        card number, (iii) the credit card type, (iv) the date of expiration and
        (v) any activation numbers or codes needed to charge your card. If COOK
        does not receive payment from your credit card issuer or its agent, you
        agree to pay all amounts due upon demand by COOK or its agents. The
        final charges on your credit card may be different than those stated on
        the website. Each customer shall be solely responsible for all sales
        taxes, or other taxes, customs, import/export charges, or similar
        governmental charges, if any. COOK does not collect sales or use taxes
        in all states. For states imposing sales or use taxes, your purchase is
        subject to use tax unless it is specifically exempt from taxation. Your
        purchase is not exempt merely because it is made over the Internet or by
        other remote means. Many states require purchasers to file a sales/use
        tax return at the end of the year reporting all of the taxable purchases
        that were not taxed and to pay tax on those purchases. Details of how to
        file these returns may be found at the websites of your respective
        taxing authorities. Without limiting other remedies, COOK reserves the
        right to charge a late fee on all past due payments equivalent to the
        lesser of one and a half percent (1.5%) per month on the unpaid balance
        or the highest rate allowed by law. You will pay for all collection
        costs, attorneys fees, and court costs incurred in the collection of
        past due amounts.
      </Typography>
      <Typography variant="h1" component="h1" className={classes.tosHeading}>
        Order Acceptance Policy
      </Typography>
      <Typography variant="body2" component="p">
        Your receipt of an electronic or other form of order confirmation does
        not signify COOK acceptance of your order, nor does it constitute
        confirmation of COOK offer to sell. COOK reserves the right at any time
        after receipt of your order to accept or decline your order for any
        reason. COOK reserves the right at any time after receipt of your order,
        without prior notice to you, to supply less than the quantity you
        ordered of any item. COOK may require additional verifications or
        information before accepting any order. Your order is not accepted until
        COOK sends you a receipt and/or pick-up information for the order (or
        the accepted portion thereof). Notwithstanding the foregoing, you agree
        that, if COOK cancels all or a portion of your order or if COOK provides
        you less than the quantity you ordered, your sole and exclusive remedy
        is either (a) COOK will issue a credit to your credit card account in
        the amount charged for the cancelled portion or the quantity not
        provided (if your credit card has already been charged for the order) or
        (b) COOK will not charge your credit card for the cancelled portion of
        the order or the quantity not provided.
      </Typography>
      <Typography variant="h1" component="h1" className={classes.tosHeading}>
        Questions and Suggestions
      </Typography>
      <Typography variant="body2" component="p">
        If you have questions or suggestions, please contact us at
        hello@cookalliance.org .This agreement to which you consent constitutes
        the entire agreement between you and COOK and its affiliated companies
        with respect to your use of our website.
      </Typography>
      <Typography variant="h1" component="h1" className={classes.tosHeading}>
        Funds/Refunds Policy
      </Typography>
      <Typography variant="body2" component="p">
        Once a reservation has been made a hold will be placed on the Guests'
        account equal to the price of the reservation. These funds will be held
        in escrow until 3 days after the date of the event.
      </Typography>
      <Typography variant="body2" component="p">
        Once a reservation is made guests will have 3 days with which to contact
        COOK at hello@cookalliance.org. After 3 days have passed funds will be
        released to the event's associated host.
      </Typography>
      <Typography variant="h1" component="h1" className={classes.tosHeading}>
        Other
      </Typography>
      <Typography variant="body2" component="p">
        This site is protected by reCAPTCHA and the Google{" "}
        <HybridLink
          href="https://policies.google.com/privacy"
          className={classes.link}
        >
          Privacy Policy
        </HybridLink>{" "}
        and{" "}
        <HybridLink
          href="https://policies.google.com/terms"
          className={classes.link}
        >
          {" "}
          Terms of Service
        </HybridLink>{" "}
        apply. By signing up for a COOK account, or submitting any information
        to COOK, you agree to receive calls, texts and email notifications.
      </Typography>
    </div>
  );
};
