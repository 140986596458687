import axios from "axios";
import { SERVER_ADDRESS } from "src/apollo";

export const getSignature = () => {
  const token = localStorage.getItem("foodnome_auth_token");
  return axios(`${SERVER_ADDRESS}/cloudinary/sign`, {
    method: "post",
    ...(!document.URL.startsWith("http") &&
      process.env.REACT_APP_ENV === "cordova" && {
        headers: { authorization: token ? token : "none" },
      }),
    data: {
      eager: "w_350,h_250,c_fill",
    },
    withCredentials: true,
  });
};
