import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    cursor: "pointer",

    fontWeight: 100,

    color: "#676767",
  },

  icon: {
    color: COLORS.MEDIUM_GREEN,
  },
  inputRoot: {
    marginBottom: "1.35rem",
  },
  input: {
    width: 300,
    "&::placeholder": {
      fontWeight: "normal",
      color: COLORS.DARK_GREY,

      textTransform: "capitalize",
    },
  },
});
