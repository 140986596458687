import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  blackStrikeOriginal: {
    marginLeft: ".5em",
    fontStyle: "normal",

    fontWeight: 500,
    fontFamily: "CustomFourBold",
    position: "relative",
    color: "rgba(255, 255, 255, 0.8)",
    "&::after": {
      content: `""`,
      borderBottom: `0.22em solid black`,
      transform: "rotate(20deg)",
      left: 0,
      marginTop: `calc(.25em / 2 * -1)`,
      position: "absolute",
      right: 0,
      top: "52%",
    },
  },
  overlay: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.2)",
    position: "absolute",
    top: 0,
    left: 0,
  },
  cookContainer: {
    position: "absolute",
    right: 15,
    top: 168,
    textAlign: "center",
  },
  avatar: {
    width: 70,
    height: 70,
    boxSizing: "border-box",
    border: "2px solid #FFFFFF",
    zIndex: 2,
    backgroundColor: COLORS.RED,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    textTransform: "uppercase",
  },
  name: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    textTransform: "capitalize",
    position: "relative",
    color: COLORS.BLACK,
  },
  reviewContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  reviewText: { fontFamily: "CustomFour" },

  tooltipPlacementBottom: { margin: "1em" },
  cardHeader: {
    flexGrow: 200,
    borderRadius: 2,
    position: "relative",
    height: 205,
    cursor: "pointer",
  },
  priceChiclet: {
    background: COLORS.RED,
    color: COLORS.WHITE,
    position: "absolute",
    top: 10,
    left: 10,
    padding: ".01rem .70rem",
    borderRadius: 15,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    zIndex: 1,

    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  icon: {
    marginRight: ".25rem",
  },
  seatsLeftValue: {
    fontWeight: 500,
    fontFamily: "CustomFourBold",
  },
  seatsLeftAdornment: {},
  seatsChiclet: {
    background: COLORS.WHITE,
    position: "absolute",
    bottom: 15,
    left: 10,
    padding: ".01rem .70rem",
    borderRadius: 15,
    zIndex: 1,
  },
  stepperButtonLayout: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  datesHeaderContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    top: 10,
    left: 0,
  },
  dateLayout: {
    position: "relative",
    width: "auto",
    padding: "0.2rem 0.5rem",
    borderRadius: "0 12px 12px 0",
    backgroundColor: `rgba(0, 0, 0, 0.91)`,
    color: COLORS.WHITE,

    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.24)",
    textTransform: "uppercase",
  },

  timeLayout: {
    position: "absolute",
    top: 10,
    right: 10,
    display: "flex",
    height: 27,

    borderRadius: 2,
    backgroundColor: `rgba(29, 29, 29, 0.58)`,
    color: COLORS.WHITE,
    justifyContent: "center",
    alignItems: "center",

    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.24)",
    padding: "0 .25rem",
  },
  carouselRoot: {
    borderRadius: 2,
    overflow: "hidden",
    height: 205,
  },
  image: {
    overflow: "hidden",
    width: "100%",
    borderRadius: 2,
    display: "block",
    objectFit: "cover",
  },
  dotContainer: {
    marginRight: ".5rem",

    cursor: "pointer",
    transition: "all 100ms ease-in",
    "&:last-child": {
      marginRight: 0,
    },
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
  dotActive: {
    opacity: 1,
    transform: "scale(1.4)",
    color: COLORS.WHITE,
  },
  dot: {
    color: COLORS.WHITE,
    opacity: 0.75,
    transform: "scale(1)",
  },
  cardHeaderDate: {
    verticalAlign: "middle",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    paddingLeft: "0.5rem",
  },
  cardHeaderTime: {
    verticalAlign: "middle",
    textTransform: "lowercase",
    paddingRight: "0.5rem",
  },
  cardHeaderOrderBy: {
    verticalAlign: "middle",
  },
  stepperDotsContainer: {
    padding: ".5rem",
    margin: 0,
    listStyleType: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imageContainer: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: "100%",
  },
  eventCardBanner: {
    position: "absolute",
    zIndex: 3,
    right: -25,
    top: 45,
  },
  cookInfoContainer: {
    marginTop: "1rem",
    display: "flex",
    flexDirection: "column",
  },
  soldOutWrapper: {
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.72)",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  soldOutText: {
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});
