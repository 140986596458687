import { IImage } from "src/models";
import { constants } from "src/utils/constants";

export function getDishImages(images: IImage[]) {
  if (images.length === 0)
    return [
      JSON.stringify({
        publicId: "publicId",
        small: constants.BEETS,
        medium: constants.BEETS,
        large: constants.BEETS,
        original: constants.BEETS,
      }),
    ];
  else if (images.length > 1) {
    return images
      .filter((i) => i.publicId !== "publicId")
      .map((i) => JSON.stringify(i));
  } else return [JSON.stringify(images[0])];
}
