import { gql } from "@apollo/client";

export const CREATE_EVENT_USER = gql`
  mutation createEventUser($input: CreateEventUserPL!) {
    createEventUser(createEventUserPL: $input)
  }
`;

export const DINER_ORDER_WITH_PAY = gql`
  mutation dinerOrderWithPayBT($input: DinerOrderWithPayPL!) {
    dinerOrderWithPayBT(dinerOrderWithPayPL: $input)
  }
`;

export const DINER_EDIT_ORDER = gql`
  mutation dinerEditEventUser($input: DinerEditEventUserPL!) {
    dinerEditEventUser(dinerEditEventUserPL: $input) {
      id
      arrivalTime
      dineOption
    }
  }
`;

export const DINER_UPDATE_ORDER = gql`
  mutation dinerEditOrder($input: DinerEditOrderPL!) {
    dinerEditOrder(dinerEditOrderPL: $input) {
      id
      arrivalTime
      dineOption
    }
  }
`;

export const DINER_CREATE_ORDER = gql`
  mutation dinerCreateOrder($input: DinerCreateOrderPL!) {
    dinerCreateOrder(dinerCreateOrderPL: $input)
  }
`;

export const INIT_EDIT_ORDER = gql`
  mutation dinerInitEditOrder($input: ID!) {
    dinerInitEditOrder(id: $input) {
      id
      status
    }
  }
`;

export const UPDATE_EVENT_USER_STATUS = gql`
  mutation updateEventUser($input: UpdateEventUserPL!) {
    updateEventUser(updateEventUserPL: $input)
  }
`;

export const GUEST_SIGN_IN = gql`
  mutation guestSignIn($input: GuestSignInPL!) {
    guestSignIn(guestSignInInfo: $input) {
      id
      firstName
      lastName
      email
      authToken
    }
  }
`;
