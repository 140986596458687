import { makeStyles } from "@material-ui/core/styles";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 450,
    margin: "0 auto",
    padding: "2rem",
  },
  h2: {
    fontFamily: "CustomFourBold",
  },
  h4: {
    textAlign: "center",
    fontFamily: "CustomFour",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  h4Background: {
    backgroundColor: "#FFC95B",
  },
  subtitle: {
    textAlign: "center",
    fontFamily: "CustomFour",
    marginTop: "1rem",
    marginBottom: "1rem",
  },

  dialog: {
    display: "flex",
    flexDirection: "column",
    height: "80vh",
    maxHeight: "80vh",
    overflow: "hidden",
  },
  contentContainer: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  gotItBtn: {
    position: "absolute",
    color: COLORS.WHITE,
    bottom: "2rem",
    left: "50%",
    transform: "translate(-50%, 0)",
    margin: "auto",
    width: "50%",
    padding: "1.5rem",

    [`@media (max-width: ${BREAK_POINTS.mobileRegular}em)`]: {
      padding: "0.5rem",
      width: "80%",
    },
  },
  checkIcon: {
    height: "4rem",
    marginRight: "1rem",
    [`@media (max-width: ${BREAK_POINTS.mobileSmall}em)`]: {
      height: "2rem",
    },
  },
  li: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "1.5rem",
    alignItems: "center",
  },
  logo: {
    maxHeight: "5rem",
    margin: "0 auto",
    marginBottom: "1rem",
    [`@media (max-width: ${BREAK_POINTS.mobileSmall}em)`]: {
      maxHeight: "3rem",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: "3rem",
  },
  closeBtnWelcomeDialog: {
    position: "absolute",
    top: 2,
    right: 2,
    backgroundColor: COLORS.MEDIUM_GREEN,
  },
  mapWrapper: {
    height: 350,
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    padding: 0,
  },
}));
