import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",

    [`@media (min-width: ${BREAK_POINTS.mobileRegular}em)`]: {
      width: "unset",
    },
  },
  container: {
    minWidth: "400px",
    boxShadow: "5px 2px 7px 0 rgba(0,0,0,0.9)",
    width: "100%",
    flexGrow: 1,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      boxShadow: "none",
      paddingBottom: "calc(8rem + env(safe-area-inset-bottom))",
      minWidth: 280,
      width: "100%",
    },
    // [`@media (min-width: ${BREAK_POINTS.tablet}em)`]: {
    //   minWidth: 280,
    //   width: "100%",
    // },
  },
  disabledBtn: {
    cursor: "not-allowed !important",
    pointerEvents: "auto !important" as any,
    backgroundColor: `${COLORS.LIGHT_GREY} !important`,
  },
  yourOrder: {
    height: "100px",

    fontWeight: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
  },
  subContainer: {
    width: "100%",
    minHeight: "200px",
    textAlign: "left",
    position: "relative",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      minWidth: "90%",
    },
  },
  subTotalContainer: {
    padding: "16px",
    fontWeight: 100,
  },
  discountContainer: {
    padding: "4px 0",
  },
  subTotalItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  couponMaxText: {
    margin: 0,

    color: COLORS.RED,
  },
  subTotalLabel: {
    color: "grey",
    display: "flex",
    alignItems: "center",
  },
  subTotalStrike: {
    height: 10,
    borderBottom: COLORS.RED,
    borderBottomWidth: "medium",
    borderBottomStyle: "solid",
  },
  promoContainer: {
    display: "flex",
    flexDirection: "row",
    width: "90%",
    margin: "1rem auto",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
    },
  },
  inputField: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#dedede",
    height: "30px",
    padding: "1rem",
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: COLORS.MEDIUM_GREEN,
    },
    "&:focus": {
      outline: "none",
    },
  },
  couponError: {
    margin: 0,
    color: COLORS.RED,

    textAlign: "center",
  },
  applyBtn: {
    borderRadius: "0px 2px 2px 0px",
    backgroundColor: COLORS.MEDIUM_GREEN,
    padding: "4px 13px",
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  totalContainer: {
    backgroundColor: "#FFEEF1",
    padding: "1.6rem",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "2rem 2rem 0 0",

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      minHeight: "unset",
      padding: "8px 22px",
    },
  },
  total: {
    fontFamily: "CustomFourBold",
  },
  totalAmount: {
    color: COLORS.BLACK,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },

  creditWarning: {
    margin: 0,
  },
  dialogContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  appBar: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
  },
  svgContainer: {
    marginBottom: "5rem",
  },
  proceedToCheckoutBtnRoot: {
    padding: "2rem 1rem",

    boxShadow: "0px 0px 10px 1px rgba(51,51,51,0.28);",

    borderRadius: 5,

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "1.5rem 1rem",
    },
  },
  proceedToCheckoutContaineerDialog: {
    width: "100%",
  },
  proceedToCheckoutContaineer: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: 0,
  },
  payBtnRoot: {
    padding: "1.5rem 0",
  },
  circularProgressContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "1rem 0",
  },
});
