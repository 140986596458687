import { gql } from "@apollo/client";

export const GET_USER_BY_EMAIL = gql`
  mutation getUserByEmail($input: String!) {
    getUserByEmail(email: $input) {
      id
      firstName
      picture
      lastName
    }
  }
`;
