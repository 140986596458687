import { Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { IGetMe } from "src/models";
import { useStyles } from "./welcome.styles";

interface IProps {
  getMe?: IGetMe;
}

export const WelcomeCard: React.FC<IProps> = ({ getMe }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent classes={{ root: classes.cardContent }}>
        <Typography variant="h2" component="h3" className={classes.header}>
          Welcome {getMe?.firstName}!
        </Typography>
        <Typography component="p" variant="body1" gutterBottom>
          Thank you for signing up to cook on COOK Connect, your application is in
          process!
        </Typography>
        <Typography component="p" variant="body1">
          We’ll reach out to you once we’ve reviewed your information to go over
          next steps. You can always go back and edit your Intro Questions by
          clicking{" "}
          <Link to="/home-restaurant-info?step=intro" className={classes.link}>
            HERE
          </Link>
          . In the meantime, check out some of our resources to get more info
          about opening a home restaurant on COOK Connect!
        </Typography>
      </CardContent>
    </Card>
  );
};
