import { client } from "src/apollo";
import { CreateAllergenPL, IPagePL } from "src/models";
import { CREATE_ALLERGEN, DELETE_ALLERGEN, GET_ALLERGENS } from "./graphql";

export const allergenAPI = {
  getAllergens: (pagePL: IPagePL) =>
    client.query({
      query: GET_ALLERGENS,
      variables: {
        input: pagePL,
      },
      fetchPolicy: "network-only",
    }),

  createAllergen: async (createAllergenPL: CreateAllergenPL, offset?: number) =>
    client.mutate({
      mutation: CREATE_ALLERGEN,
      variables: {
        input: createAllergenPL,
      },
      update: async (store) => {
        const allergens = await allergenAPI.getAllergens({ limit: 10, offset });
        store.writeQuery({
          query: GET_ALLERGENS,
          variables: { input: { limit: 10 } },
          data: allergens.data,
        });
      },
    }),

  deleteAllergen: async (id: string | number, offset: number) =>
    client.mutate({
      mutation: DELETE_ALLERGEN,
      variables: {
        input: id,
      },
      update: async (store) => {
        const allergens = await allergenAPI.getAllergens({ limit: 10, offset });
        store.writeQuery({
          query: GET_ALLERGENS,
          variables: { input: { limit: 10 } },
          data: allergens.data,
        });
      },
    }),
};
