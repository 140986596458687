import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useHistory, useLocation } from "react-router";
import { IFacebookLoginPL } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { isEmbeddedBrowser } from "src/utils/helpers";
import { authAPI } from "./graphql";

interface IFacebookAuthButtonProps {
  onSubmit?: (d?: any) => void;
  onError: (error: any) => void;
}

export const AuthWithFacebook: React.FC<IFacebookAuthButtonProps> = ({
  children,
  onSubmit,
  onError,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [isAvailable, setIsAvailable] = useState(true);

  useEffect(() => {
    if (
      process.env.REACT_APP_ENV === "cordova" &&
      !(window as any).facebookConnectPlugin
    ) {
      setIsAvailable(false);
    }
    // We want to disable 3rd party logins on Instagram and Facebook In-App-Browsers because
    // they disable popups and 3rd party cookies
    if (isEmbeddedBrowser()) setIsAvailable(false);
  }, []);

  const child = React.Children.only(children) as React.ReactElement;

  const queryObj = location
    ? (parse(location.search) as { code: string })
    : null;
  const referralCode = queryObj && queryObj.code ? queryObj.code : undefined;

  const facebookSignIn = async (facebookPL: IFacebookLoginPL) => {
    sharedAPI.setGlobalLoadingState(true);
    return authAPI
      .facebook({ ...facebookPL })
      .then((d) => {
        const createdAt = moment(Number(d.data.signInWithFacebook.createdAt));
        const duration = moment.duration(moment().diff(createdAt));
        if (duration.asSeconds() <= 10) {
          sharedAPI.setDiscountPrompt(true);
        }
        return onSubmit
          ? setTimeout(() => onSubmit(d.data.signInWithFacebook), 500)
          : history.push("/d");
      })
      .catch((err) => onError(`Make sure you have cookies enabled. ${err}`));
  };

  const handleLoginWithFacebook = (d: any) => {
    const {
      name = "",
      email = "",
      accessToken: token,
      userID: userId,
      picture: _picture,
    } = d;
    const [firstName, lastName] = name.split(" ");
    const { data } = _picture || { data: { url: "" } };

    return facebookSignIn({
      email,
      firstName,
      lastName,
      token,
      userId,
      picture: data.url,
      referralCode,
    }).then(() =>
      setTimeout(() => sharedAPI.setGlobalLoadingState(false), 1000)
    );
  };

  const nativeFBSignIn = () =>
    (window as any).facebookConnectPlugin.login(
      ["public_profile"],
      (obj) => {
        const { accessToken, userID } = obj.authResponse;
        return axios
          .get(
            "https://graph.facebook.com/me?fields=email,picture,name&access_token=" +
              accessToken
          )
          .then((d) =>
            handleLoginWithFacebook({
              picture: d.data.picture,
              name: d.data.name,
              email: d.data.email,
              accessToken,
              userID,
            })
          );
      },
      (msg) => onError(`Oops! There was an error signing up. ${msg}`)
    );

  if (!isAvailable) return null;

  return process.env.REACT_APP_ENV === "cordova" ? (
    <div role="button" onClick={nativeFBSignIn} style={{ width: "100%" }}>
      {children}
    </div>
  ) : (
    <FacebookLogin
      onFailure={(err) => {
        sharedAPI.setGlobalLoadingState(false);
        // tslint:disable-next-line: no-console
        console.error(err);

        onError(
          `There was an error. Make sure you have cookies enabled. ${err}`
        );
      }}
      isMobile={false}
      autoLoad={false}
      render={(props) => {
        return React.cloneElement(child, {
          onClick: props.onClick,
          disabled: props.isDisabled || props.isProcessing,
          children: props.isProcessing ? (
            <CircularProgress />
          ) : (
            child.props.children
          ),
        });
      }}
      fields="name,email,picture"
      callback={handleLoginWithFacebook}
      appId={process.env.REACT_APP_FACEBOOK_APP_ID as string}
    />
  );
};
