import React from "react";
import { ErrorComponent, LoadingComponent } from ".";

export const ErrorLoadingComponent: React.FunctionComponent<{
  loading: boolean;
  error?: any;
  refetch?: any;
}> = ({ error, loading, refetch }) => {
  if (error)
    return (
      <ErrorComponent refetch={refetch} message={error.message as string} />
    );
  if (loading) return <LoadingComponent loading={loading} />;

  return null;
};
