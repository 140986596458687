import { gql } from "@apollo/client";
import { GET_SERVICE_FEE } from "src/screens/event-detail/api/graphql";
import { GET_DINER_EVENTS, GET_EVENT } from "src/shared-graphql/queries";

export { GET_EVENT };
export { GET_DINER_EVENTS };
export { GET_SERVICE_FEE };

export const GET_MY_NOMES = gql`
  query getMyNomes {
    getMe {
      id
      nomes
      credits
    }
  }
`;
