import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";
import { IEvent } from "src/models";
import { ScrollList } from "../../mobile/scroll-list.layout";
import { EventCard } from "./events-card";
import { getStatus } from "./helpers";
import { layoutStyles as useStyles } from "./styles";

interface IProps {
  events: IEvent[];
  handleChange: (event: React.ChangeEvent<any>) => void;
  title: string;
  state?: any;
  mobile: boolean;
  scrollList: boolean;

  searchQuery: {
    limit: string;
    orderBy: "createdAt" | "arrivalTime";
  };
}

export const Layout: FC<IProps> = ({
  events,
  title,
  state,
  mobile,
  scrollList,
  handleChange,

  searchQuery,
}) => {
  const classes = useStyles();
  const mobileTitle = state && state.mobileTitle;
  const status = getStatus(title, mobileTitle, events);

  if (scrollList) {
    return <ScrollList title={title} events={events} status={status} />;
  }
  return (
    <div className={classes.eventsList}>
      <FormControl className={classes.formControl}>
        <InputLabel id="sort-by-select-label">Sort by</InputLabel>
        <Select
          labelId="sort-by-select-label"
          id="sort-by-select-label"
          value={searchQuery.orderBy}
          name="orderBy"
          color="secondary"
          onChange={handleChange}
        >
          <MenuItem value={"createdAt"}>Order Date (Most Recent)</MenuItem>
          <MenuItem value={"arrivalTime"}>Event Date (Soonest)</MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="show-select-label">Show</InputLabel>
        <Select
          labelId="show-select-label"
          id="show-select-label"
          color="secondary"
          value={searchQuery.limit}
          name="limit"
          onChange={handleChange}
        >
          <MenuItem value={"5"}>5</MenuItem>
          <MenuItem value={"25"}>25</MenuItem>
          <MenuItem value={"50"}>50</MenuItem>
          <MenuItem value={"100"}>100</MenuItem>
        </Select>
      </FormControl>
      <Typography variant="body2" component="p" className={classes.title}>
        {mobile ? mobileTitle : title}
      </Typography>
      {events.map((event) => (
        <EventCard
          key={event.id}
          event={event}
          mobile={mobile}
          status={status}
        />
      ))}
    </div>
  );
};
