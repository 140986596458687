import { TextField, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { BecomeCookContext } from "src/screens/become-cook-info/store/become-cook-context";
import { ActionTypes } from "src/screens/become-cook-info/store/reducer";
import { useContentStyles } from "../styles";

export const InSlide3: React.FC = React.memo(() => {
  const classes = useContentStyles();

  const { state, dispatch } = useContext(BecomeCookContext);

  const onInputChange = (e) => {
    dispatch({
      type: ActionTypes.SET_QUESTION,
      name: e.target.name,
      value: e.target.value,
    });
  };

  return (
    <div className={classes.container}>
      <Typography variant="h2" component="h1" className={classes.header}>
        Intro Questions (2/3)
      </Typography>
      <section className={classes.questionsContainer}>
        <div>
          <Typography variant="h5" component="p" className={classes.question}>
            2. Can you describe your menu in a few sentences? Feel free to share
            links.*
          </Typography>
          <TextField
            multiline
            data-testid="question2"
            rows={10}
            fullWidth
            name="question2"
            value={state.question2}
            onChange={(e) => onInputChange(e)}
            placeholder="Type here..."
            className={classes.textArea}
            variant="outlined"
          />
        </div>
      </section>
    </div>
  );
});
