import IconButton from "@material-ui/core/IconButton";
import React, { FC } from "react";
import { openWindow } from "./functions";

interface IProps {
  children: any;
  url: string;
  quote?: string;
  hashtag?: string | undefined;
}

export const FacebookShareButton: FC<IProps> = ({
  children,
  url,
  hashtag,
  quote = "",
}) => {
  const _link = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${quote}&hashtag=${hashtag}`;

  const clickHandler = () => {
    openWindow(_link);
  };

  return (
    <IconButton aria-label="facebook share button" onClick={clickHandler}>
      {children}
    </IconButton>
  );
};
