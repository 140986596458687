import { gql } from "@apollo/client";

export const GET_SEARCH_SUGGESTIONS = gql`
  query getSearchSuggestions($input: String!) {
    getSearchSuggestions(input: $input) {
      googlePredictions {
        status
        predictions {
          id
          place_id
          description
        }
      }
      restaurants {
        id
        name
        city
        state
        host {
          id
          picture
          firstName
          lastName
        }
      }
    }
  }
`;
