import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  form: { display: "flex", flexDirection: "column" },
  textFields: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "column",
    width: 300,
  },
  selectField: { width: "240px" },
});
