import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Fade } from "react-awesome-reveal";
import { useStyles } from "./legislation.styles";
import { HybridLink } from "src/components";

// tslint:disable-next-line:no-empty-interface
interface IProps {}

export const Legalisation: FC<IProps> = () => {
  const classes = useStyles();
  return (
    // add three titles and paragraphs using the typography container in material UI
    <section className={classes.container}>
      <img
        src={require("../assets/illustrations/leaf-13.svg")}
        className={classes.leaf}
      />
      <div className={classes.innerContainer}>
        <div className={classes.headerContainer}>
          <Fade triggerOnce={true}>
            <Typography variant="h2" component="h2" className={classes.title}>
              Legislation
            </Typography>
          </Fade>
          <div className={classes.squiggleContainer}>
            <img
              src={require("../assets/underline.svg")}
              className={classes.squiggle}
            />
          </div>
        </div>
        <div className={classes.mainContent}>
          <div className={classes.textContainer}>
            <Typography variant="h4" component="p" className={classes.subtitle}>
              AB-626: The Microenterprise Home Kitchen Operations (MEHKO) Act
            </Typography>
            <Typography variant="h4" component="p" className={classes.text}>
              AB-626 was signed into law by California Governor Brown on Sept
              18, 2018 making California the first state to create a permit for
              independent cooks to sell meals and a wide variety of prepared
              foods from their home kitchens. Since the introduction of AB 626,
              we have continued to pass new bills in California and advocate for
              legalizing home cooked food sales nationwide.
            </Typography>
            <Typography variant="h4" component="p" className={classes.text}>
              In California, each county’s Board of Supervisors must opt-in to
              the law before cooks can apply for a MEHKO permit. Learn more
              about our advocacy work at COOK Alliance,{" "}
              <HybridLink
                className={classes.bold}
                href={"https://www.cookalliance.org/advancing-advocacy"}
              >
                here
              </HybridLink>
              .
            </Typography>
          </div>
          <div className={classes.textContainer}>
            <Typography
              variant="h4"
              component="p"
              align="left"
              className={classes.subtitle}
            >
              A Gateway to the Food Economy
            </Typography>
            <Typography variant="h4" component="p" className={classes.text}>
              It costs an average of $250,000+ to open a restaurant in
              California and $100,000 to open a food truck. These huge financial
              barriers have prevented thousands of talented cooks from becoming
              food business owners and restricted access to affordable, healthy
              food in many neighborhoods. ‍{" "}
            </Typography>
            <Typography variant="h4" component="p" className={classes.text}>
              How much does it cost to permit a home restaurant? Less than
              $1,000!
            </Typography>
          </div>
          <div className={classes.textContainer}>
            <Typography
              variant="h4"
              component="p"
              align="left"
              className={classes.subtitle}
            >
              COOK Connect
            </Typography>
            <Typography variant="h4" component="p" className={classes.text}>
              COOK Connect by COOK Alliance is the first legal marketplace
              operated by nonprofit COOK Alliance, for home cooked food in the
              US. We have advocated and organized for years to create a more
              equitable, inclusive, and human-centered food economy. Join us as
              we work to empower food entrepreneurship, build community, and
              redefine our relationship to our food!
            </Typography>
            {/* Logo and badge */}
            <div className={classes.imageWrapper}>
              <div className={classes.logoContainer}>
                <img
                  src={require("../assets/main_header_logo.png")}
                  className={classes.logo}
                  alt="COOK Connect Logo"
                />
                {/* <img
          src={require("../assets/illustrations/certified_badge.png")}
          className={classes.badge}
        />  */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
