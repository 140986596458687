import { makeStyles } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import React from "react";
import { CSSTransition } from "react-transition-group";
import { COLORS } from "src/styles";
import "./fetch-loading.animations.css";

interface FetchLoadingProps {
  appear: boolean;
}

const useStyles = makeStyles({
  layout: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    margin: "1rem 0",
    zIndex: 100,
  },
  wrapper: {
    width: 100,
    margin: "0 auto",
    backgroundColor: COLORS.RED,
    color: COLORS.WHITE,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    boxShadow: `0 0 8px 1px rgba(29,29,29,0.31)`,
    padding: "1rem",
  },
  container: {
    width: "100%",
  },
  dotsLayout: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    maxWidth: 50,
    margin: "0 auto",
  },
});

export const FetchLoading: React.FC<FetchLoadingProps> = ({ appear }) => {
  const classes = useStyles();
  return (
    <CSSTransition
      unmountOnExit
      in={appear}
      classNames="slide-up"
      timeout={450}
    >
      <div className={classes.layout}>
        <div className={classes.wrapper}>
          <div className={classes.container}>
            <div className={classes.dotsLayout}>
              <FiberManualRecordIcon
                className="loading-dot-1"
                style={{ fontSize: "0.8rem" }}
              />
              <FiberManualRecordIcon
                className="loading-dot-2"
                style={{ fontSize: "0.8rem" }}
              />
              <FiberManualRecordIcon
                className="loading-dot-3"
                style={{ fontSize: "0.8rem" }}
              />
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
