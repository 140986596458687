import { Paper } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import classNames from "classnames";
import React from "react";
import { useStyles } from "./restaurant-card-mobile.styles";

export const RestaurantSkeletonMobile = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.container} component="li">
      <section className={classNames(classes.topContainer, classes.flexRow)}>
        <div className={classes.flexColumn} style={{ flexGrow: 1 }}>
          {/* Name/Title */}
          <section className={classes.flexRow} style={{ marginBottom: "1rem" }}>
            <Skeleton variant="text" width={150} />
          </section>

          {/* Avatar and Info */}
          <section className={classes.flexRow}>
            <div className={classes.flexColumn}>
              <Skeleton
                variant="circle"
                width={60}
                style={{ marginRight: "1rem" }}
                height={60}
              />
            </div>
            <div className={classes.flexColumn}>
              <div className={classes.flexRow}>
                <Skeleton variant="text" width={100} />
              </div>

              <div
                className={classNames(
                  classes.flexRow,
                  classes.locationContainer
                )}
              >
                <Skeleton variant="text" width={100} />
              </div>
              <div className={classes.flexRow}>
                <Skeleton variant="text" width={100} />
              </div>
            </div>
          </section>
        </div>

        <section
          className={classes.flexColumn}
          style={{ position: "relative" }}
        >
          <Skeleton variant="rect" className={classes.image} />
        </section>
      </section>

      <section className={classes.flexRow} style={{ marginLeft: "1rem" }}>
        <Skeleton variant="text" width={200} />
      </section>
      <section
        className={classes.chipContainer}
        style={{ marginLeft: "1rem", marginTop: "1rem" }}
      >
        {[1, 2, 3, 4].map((e) => (
          <Skeleton
            style={{ marginRight: "1rem", marginBottom: "1rem" }}
            variant="rect"
            key={e}
            width={60}
            height={30}
          />
        ))}
      </section>
    </Paper>
  );
};
