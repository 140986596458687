import { Paper } from "@material-ui/core";
import React, { Fragment, FunctionComponent } from "react";
import { PaginationComponent } from "src/components";
import { ICoupon } from "src/models";
import { Header } from "./header";
import { CouponTable } from "./table";

interface IProps {
  coupons: ICoupon[];
  count: number;
  pageNum: number;
  fetchMore: (d: { pageNumber: number; searchTerm?: string }) => void;
  deleteCoupon: (d: any) => void;
}

export const Layout: FunctionComponent<IProps> = ({
  coupons,
  count,
  fetchMore,
  deleteCoupon,
  pageNum,
}) => {
  return (
    <Fragment>
      <Header count={count} pageNum={pageNum} onSearch={fetchMore} />
      <Paper style={{ padding: "1rem", marginTop: "1rem" }}>
        <CouponTable coupons={coupons} deleteCoupon={deleteCoupon} />

        <PaginationComponent
          totalCount={count}
          onChange={(d) => fetchMore({ pageNumber: d })}
        />
      </Paper>
    </Fragment>
  );
};
