import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    backgroundColor: COLORS.WHITE,
    height: "100%",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    maxWidth: "140rem",
    margin: "9rem auto",
    marginBottom: "15rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      margin: "5rem auto",
      marginBottom: "6rem",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginBottom: "3rem",
    },
  },
  squiggleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
  },
  squiggle: {
    width: "60%",
    minWidth: "12rem",
    maxWidth: "18rem",
  },
  title: {
    fontFamily: "CustomFourBold",
    textAlign: "center",
  },
  mainContent: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    placeItems: "flex-start",
    justifyItems: "center",
    padding: "0 4rem",
    columnGap: "7rem",
    rowGap: "7rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      gridTemplateColumns: "repeat(1, 1fr)",
      columnGap: "3rem",
      rowGap: "3rem",
      padding: "0 2rem",
    },
  },
  card: {
    maxWidth: "55rem",
    width: "100%",
    backgroundColor: COLORS.WHITE,
    boxShadow: "-2px 7px 13px 0 rgba(61,61,61,0.33)",
    display: "grid",
    gridTemplateRows: "repeat(auto-fill, minmax(450px, 1fr));",
    borderRadius: "1rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      gridTemplateRows: "repeat(auto-fill, minmax(270px, 1fr));",
    },
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: "1rem 1rem 0 0",
    maxHeight: "45rem",
  },
  lowerCardContainer: {
    padding: "4rem",
    paddingBottom: "6rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "2rem",
    },
  },
  description: {
    fontFamily: "CustomFourMedium",
  },
  cardLocation: {
    fontFamily: "CustomFour",
  },
  redBar: {
    width: "40%",
    maxWidth: "13rem",
    height: "0.1rem",
    border: `0.2rem solid ${COLORS.RED}`,
    margin: "2rem auto",
  },
  cardTitle: {
    textAlign: "center",
  },
});
