import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "@material-ui/core";
import React, { FC, useState } from "react";
import { COLORS } from "src/styles";
import { useStyles } from "./dialog-styles";

interface IProps {
  onClose: () => void;
  onConfirm?: () => Promise<any>;
  onContinue?: () => void;
  onReject?: () => Promise<any>;
  openDialog: boolean;
  title: string;
  message: string;
  noKey?: string;
  yesKey?: string;
  fullWidth?: boolean;
}

export const ConfirmationDialog: FC<IProps> = ({
  openDialog,
  onConfirm,
  onClose,
  title,
  message,
  noKey,
  yesKey,
  onReject,
  onContinue,
  fullWidth = true,
}) => {
  const classes = useStyles();
  // Hooks
  const [error, setError] = useState<string>("");
  const [loading, setLoadingState] = useState<boolean>(false);

  // Handlers
  // No need to reset loadingState to false because we're closing
  // the dialogs anyway

  const handleConfirm = () => {
    // show spinner + reset error state
    setLoadingState(true);
    setError("");

    if (onConfirm) {
      return onConfirm()
        .then(() => setLoadingState(false))
        .catch(({ message: msg }) => {
          setLoadingState(false);
          setError(msg);
        });
    }
  };

  const handleReject = () => {
    // show spinner + reset error state
    setLoadingState(true);
    setError("");

    if (onReject) {
      return onReject()
        .then(() => setLoadingState(false))
        .catch(({ message: msg }) => {
          setLoadingState(false);
          setError(msg);
        });
    }
  };

  return (
    <Dialog
      data-testid="confirmation-dialog"
      fullWidth={fullWidth}
      maxWidth="xs"
      aria-labelledby={title}
      open={openDialog}
      onClose={onClose}
      PaperProps={{ classes: { root: classes.dialogRoot } }}
    >
      <DialogContent classes={{ root: classes.root }}>
        <div className={classes.container}>
          <Typography variant="h2" component="h1" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body2" component="p" className={classes.text}>
            {message}
          </Typography>
          <div className={classes.btnContainer}>
            {onConfirm && (
              <Button
                disabled={loading}
                onClick={handleConfirm}
                variant="outlined"
                color="secondary"
              >
                {loading ? (
                  <CircularProgress
                    className={classes.spinner}
                    data-testid="spinner"
                  />
                ) : yesKey ? (
                  yesKey
                ) : (
                  "Yes"
                )}
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              style={{ color: COLORS.WHITE }}
              onClick={
                onContinue
                  ? () => onContinue()
                  : onReject
                  ? handleReject
                  : onClose
              }
            >
              {noKey ? noKey : "No"}
            </Button>
          </div>
          <Typography variant="body2" component="p" className={classes.error}>
            {error.split(":")[1]}
          </Typography>
        </div>
      </DialogContent>
    </Dialog>
  );
};
