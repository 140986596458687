import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    boxShadow: "0 0 6px 0 rgba(0,0,0,0.15)",
    minHeight: 56,
  },

  headerText: {
    alignSelf: "center",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    textAlign: "center",
    color: COLORS.BLACK,
    width: "100%",
    margin: "0 auto",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    overflow: "hidden",
    maxWidth: 200,
  },
  foodnomeLogo: {
    width: "60%",
    maxWidth: "30rem",
  },
  innerContainer: {
    width: "100%",

    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  backArrow: { color: COLORS.MEDIUM_GREEN, fontSize: "2.5rem" },
});
