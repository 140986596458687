import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    padding: 12,

    alignItems: "center",
    cursor: "pointer",
  },
  leftContainer: { display: "flex" },
  infoContainer: {
    height: "100%",
    width: "100%",
    alignSelf: "flex-start",
    margin: 0,
  },
  nameAndTimeContainer: {
    width: "100%",

    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  unread: {
    color: COLORS.MEDIUM_GREEN,
  },
  avatar: {
    width: 36,
    height: 36,

    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.WHITE,
    textTransform: "uppercase",
    backgroundColor: COLORS.RED,
    marginRight: ".5em",
  },
  names: {
    margin: 0,
    fontFamily: "CustomFourBold",

    fontWeight: 500,
    overflow: "hidden",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
  },
  msg: {
    margin: 0,

    fontWeight: 100,
    overflow: "hidden",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    color: COLORS.DARK_GREY,
  },
  timeContainer: {
    fontWeight: 100,

    height: "100%",
    minWidth: 80,
  },
  online: {
    borderRadius: "50%",
    backgroundColor: COLORS.MEDIUM_GREEN,
    width: 8,
    height: 8,
    marginRight: 2,
    display: "inline-block",
  },
  offline: {
    borderRadius: "50%",
    backgroundColor: COLORS.DARK_GREY,
    width: 8,
    height: 8,
    marginRight: 2,
    display: "inline-block",
  },
});
