import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useDishListStyles = makeStyles({
  container: {
    width: "100%",
  },
  dishCategorySection: {
    marginTop: "1.5rem",
  },
  dishes: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gridAutoColumns: "auto",
    gridGap: "2rem",
  },
  categoryTitle: {
    fontFamily: "CustomFourBold",
    color: COLORS.DARK_GREY,
    margin: "1rem",
    marginLeft: 0,
  },
  divider: { margin: "auto 0", flexGrow: 1 },
});
