import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    width: "100%",
    backgroundColor: COLORS.WHITE,
    boxShadow: "3px 4px 19px 0 rgba(31,64,80,0.28)",
    padding: 75,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: 20,
    },
  },
  chatBtn: {},
  privateNote: { color: COLORS.RED, margin: 0 },
  subContainer: {
    borderBottom: "1px solid #E6E6E6",
    boxSizing: "border-box",
    padding: "18px 0",
  },
  mapContainer: {
    maxWidth: 340,
    width: "100%",
    height: 150,
    borderRadius: 2,
  },
  eventTitle: {
    fontFamily: "CustomFourBold",

    fontWeight: 500,

    margin: 0,
    color: COLORS.BLACK,
  },
  subText: {
    fontWeight: 100,

    margin: 0,
    color: COLORS.BLACK,
  },
  dishTotalText: {
    color: "#626262",
  },
  boldSubText: {
    fontFamily: "CustomFourBold",

    fontWeight: 500,

    color: COLORS.BLACK,
    margin: 0,
    textTransform: "capitalize",
  },
  link: {
    textDecoration: "none",
    color: COLORS.MEDIUM_GREEN,

    fontWeight: 300,

    margin: 0,
  },
  transactionContainer: {
    width: "100%",
    maxWidth: "40rem",
    border: "1px solid #E2E2E2",
    boxShadow: "1px 5px 17px 1px rgba(11,27,50,0.15)",
    margin: "1rem auto",
  },
  orders: {
    padding: "0 2rem",
  },
  dishImage: {
    height: "7rem",
    width: "7rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "5rem",
      height: "5rem",
    },
  },
  orderContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "2rem 0",

    borderBottom: "1px solid #E7E5E5",
  },
  priceContainer: {
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
  sectionTwo: {
    // display: "grid",
    // gridTemplateRows: "1fr 50px",
    // gridTemplateColumns: "50% 50%",
  },
  priceBreakdown: {
    padding: "2rem",
    gridRow: "1 / 2",
    gridColumn: "2 / 3",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      gridColumn: "1 / 3",
    },
  },
  priceText: {
    color: "#6F6F6F",
  },
  priceTextStrike: {
    color: COLORS.DARK_GREY,

    textDecoration: "line-through",
    textDecorationColor: COLORS.RED,
  },
  totalContainer: {
    padding: "1rem 2rem",
    backgroundColor: "#E9FFFC",
  },
  transactionHeadingContainer: {
    padding: "2rem 2rem",
    paddingBottom: 0,
  },

  totalInfoWrapper: {
    width: "50%",
    padding: "26px 2rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  totalInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  discountNote: {
    color: COLORS.BLACK,
    textAlign: "right",
    margin: 0,
  },
  flexContainer: {
    height: 34,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  priceLabel: {
    color: COLORS.BLACK,
  },
  eventName: {
    textTransform: "capitalize",
    overflow: "hidden",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    // display: "-webkit-box"
  },
});
