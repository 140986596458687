import { IDish } from "src/models";
import { IDishFormState } from "../form/state/reducers";

export function formatInitialState(getDish: IDish): IDishFormState {
  return {
    allergens: getDish.allergens ? getDish.allergens : [],
    name: getDish.name,
    description: getDish.description,
    images: getDish.imagesGQL,
    defaultPrice: getDish.defaultPrice,
    tags: getDish.tags ? getDish.tags : [],
    options: getDish.options
      ? getDish.options.map((o) => ({ name: o.name, addition: o.addition }))
      : [],
    dietary: getDish.dietary ? getDish.dietary : "NONE",
    isPublished: getDish.isPublished,
    category: getDish.category ? getDish.category : "ENTREE",
  };
}
