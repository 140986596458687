import AppleIcon from "@material-ui/icons/Apple";
import React, { FC } from "react";
import AddToCalendar from "react-add-to-calendar";
import { GoogleLogo } from "src/assets/icons/svg/google-logo";
import { MicrosoftLogo } from "src/assets/icons/svg/microsoft-logo";
import "./calendar-button.css";

// Change icons here

interface IProps {
  calendarEvent: {
    title: string;
    description: string;
    location: string;
    startTime: string;
    endTime: string;
  };
}

export const CalendarButton: FC<IProps> = ({ calendarEvent }) => {
  return (
    <AddToCalendar
      event={calendarEvent}
      buttonWrapperClass="calendar-button"
      buttonClassOpen="button-open"
      buttonLabel="Add to Calendar"
      listItems={[
        {
          google: (
            <>
              <GoogleLogo /> <span>Google</span>
            </>
          ),
        },
        {
          apple: (
            <>
              <AppleIcon /> <span>Apple Calendar</span>
            </>
          ),
        },
        {
          google: (
            <>
              <MicrosoftLogo /> <span>Outlook</span>
            </>
          ),
        },
      ]}
    />
  );
};
