import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const checkoutButtonStyles = makeStyles((theme) => ({
  button: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.WHITE,
    fontSize: theme.typography.h5.fontSize,

    padding: "1.5rem 0",

    // backgroundColor: COLORS.MEDIUM_GREEN,
    boxShadow: "0px 0px 10px 1px rgba(51,51,51,0.28);",
    // "&:hover": {
    //   backgroundColor: COLORS.MEDIUM_GREEN,
    // },
    borderRadius: 5,
    // "&:disabled": {
    //   backgroundColor: COLORS.MEDIUM_GREEN,
    //   opacity: "0.5",
    //   boxShadow: "0px 0px 10px 1px rgba(51,51,51,0.28);",
    // },
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "1.5rem 0",
    },
  },
  startIcon: {
    marginLeft: "1.8rem",
  },

  label: {
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
