import { Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import { IGetMe } from "src/models";
import { useStyles } from "./congrats.styles";

interface IProps {
  getMe?: IGetMe;
}

export const CongratsCard: React.FC<IProps> = ({ getMe }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent classes={{ root: classes.cardContent }}>
        <Typography variant="h2" component="h3" className={classes.header}>
          Congrats {getMe?.firstName}!
        </Typography>
        <Typography component="p" variant="body1" gutterBottom>
          You are on your way to opening one of the nation’s first home
          restaurants!
        </Typography>
        <Typography component="p" variant="body1">
          Please take a look at the following ‘Resources’ section to start
          getting ready for your Grand Opening.
        </Typography>
      </CardContent>
    </Card>
  );
};
