import { Avatar, Typography } from "@material-ui/core";
import React from "react";
import { CateringButton, ChatButton, FollowButton } from "src/components";
import { IGetMe, IRestaurant } from "src/models";
import { useOverviewStyles } from "./overview.styles";

interface IProps {
  restaurant: IRestaurant;
  getMe: IGetMe | null | undefined;
}

export const Profile: React.FC<IProps> = ({ restaurant, getMe }) => {
  const classes = useOverviewStyles();

  const avatarProps = Object.assign(
    {},
    restaurant.host.picture
      ? { src: restaurant.host.picture }
      : {
          children: `${restaurant.host.firstName[0]}${restaurant.host.lastName[0]}`,
        }
  ) as any;

  return (
    <section>
      <Avatar className={classes.hostAvatar} {...avatarProps} />
      <Typography
        variant="body2"
        component="p"
        className={classes.hostName}
      >{`${restaurant.host.firstName} ${restaurant.host.lastName[0]}.`}</Typography>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <FollowButton restaurant={restaurant} currentUser={getMe} />
        <div style={{ marginTop: "1rem" }}>
          <ChatButton
            restaurant={restaurant}
            currentUser={getMe}
            variant="outlined"
          />
        </div>
        <div style={{ marginTop: "1rem" }}>
          <CateringButton restaurant={restaurant} currentUser={getMe} />
        </div>
      </div>
    </section>
  );
};
