import {
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import React, { FC, useState } from "react";
import { DishOption } from "src/models";
import { useStyles } from "./options.styles";

interface IProps {
  options: DishOption[];
  addOption: (name: string) => any;
  removeOption: (event, name: string) => any;
}
export const DishOptions: FC<IProps> = ({
  options,
  addOption,
  removeOption,
}) => {
  const classes = useStyles();
  const [name, setOption] = useState("");

  const onSubmit = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
    e.preventDefault();
    setOption("");
    addOption(name);
  };

  return (
    <div>
      <div onSubmit={onSubmit} className={classes.form}>
        <Typography
          component="label"
          variant="caption"
          className={classes.label}
          htmlFor="option"
        >
          Add single-pick options to your dish{" "}
          <Tooltip
            title={
              "Diners will only be able to select one of the options you choose to include with your dish."
            }
            disableFocusListener
            classes={{ tooltip: classes.toolTip }}
          >
            <IconButton disableRipple disableFocusRipple size="small">
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </Typography>
        <div className={classes.formContainer}>
          <TextField
            size="small"
            id="option"
            name="option"
            fullWidth
            placeholder="Mild, Spicy, Chicken ..."
            onChange={(e) => setOption(e.target.value)}
            value={name}
            inputProps={{ maxLength: 600 }}
            InputProps={{
              disableUnderline: true,
              classes: {
                input: classes.input,
              },
            }}
          />
          <Button
            disabled={!name}
            classes={{ containedPrimary: classes.createOption }}
            variant="contained"
            color="primary"
            onClick={onSubmit}
          >
            Add
          </Button>
        </div>
      </div>
      {!!options.length && (
        <Typography
          variant="h4"
          component="h4"
          className={classes.currentOptionHeader}
        >
          Current options:
        </Typography>
      )}
      {options?.map((option) => (
        <ol key={option.name} className={classes.optionItem}>
          <Typography
            variant="body2"
            component="p"
            className={classes.dishName}
          >
            - {option.name}
          </Typography>
          <button
            className={classes.deleteOptionBtn}
            onClick={(e) => removeOption(e, option.name)}
          >
            Remove
          </button>
        </ol>
      ))}
    </div>
  );
};
