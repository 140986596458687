import { gql } from "@apollo/client";

export const GET_RESTAURANT = gql`
  query getRestaurantByName($input: String!) {
    getRestaurantByName(name: $input) {
      id
      name
      tags
      description
      locale {
        public
        private
        county
        coordinates {
          longitude
          latitude
        }
      }
      overallReview {
        count
        stars
        quality
        accuracy
        hospitality
      }
      permitNumber
      dishes {
        id
        name
        imagesGQL {
          publicId
          small
          medium
          large
          original
        }
        description
        category
        allergens {
          id
          name
        }
        dietary
        __typename @skip(if: true)
      }
      host {
        id
        firstName
        lastName
        picture
      }
    }
  }
`;

export const GET_RESTAURANT_MEAL_SERVED = gql`
  query getRestaurantMealServedEstimate($input: ID!) {
    getRestaurantMealServedEstimate(id: $input)
  }
`;

export const GET_RESTAURANT_REVIEWS = gql`
  query getRestaurantReviewsByName($input: GetRestaurantReviewsByNamePL) {
    getRestaurantReviewsByName(getRestaurantReviewsByNamePL: $input) {
      count
      rows {
        id
        text
        quality
        hospitality
        accuracy
        createdAt
        guest {
          id
          firstName
          lastName
          picture
        }
      }
    }
  }
`;
