import { useLazyQuery, useMutation } from "@apollo/client";
import { Button } from "@material-ui/core";
import { Bookmark, BookmarkBorder } from "@material-ui/icons";
import React, { useEffect } from "react";
import { ActionDialog } from "src/components";
import {
  DINER_GET_RESTAURANT_GUEST,
  DINER_UPSERT_RESTAURANT_GUEST,
} from "src/components/follow-button/graphql";
import { IEvent, IGetMe } from "src/models";
import { useStyles } from "./header.styles";

export interface FollowButtonProps {
  event: IEvent;

  currentUser: IGetMe | null | undefined;
}

// Rebuild this entire save button functionality
export const FollowButton: React.FC<FollowButtonProps> = React.memo(
  ({ event, currentUser }) => {
    const [getRestaurantGuest, d] = useLazyQuery(DINER_GET_RESTAURANT_GUEST);
    const [upsertRestaurantGuest] = useMutation(DINER_UPSERT_RESTAURANT_GUEST);
    const classes = useStyles();

    useEffect(() => {
      if (currentUser) {
        getRestaurantGuest({ variables: { input: event?.restaurant?.id } });
      }
    }, [currentUser]);

    const follow = () =>
      upsertRestaurantGuest({
        variables: {
          input: {
            restaurantId: event.restaurant.id,
          },
        },
      });

    // successfully fire submit handler or ask the user to sign up / log in
    // const submitHandler = bindSubmitHandler(currentUser)({
    //   openLoginDialog,
    //   openVerifyDialog,
    //   handleSubmit: follow,
    // });

    const isFollowing = d?.data?.dinerGetRestaurantUser?.isFollowing;

    return (
      <ActionDialog getMe={currentUser}>
        <Button
          size="small"
          className={classes.btn}
          disabled={d.loading}
          onClick={follow}
        >
          <>
            {isFollowing ? (
              <Bookmark className={classes.btnIcon} />
            ) : (
              <BookmarkBorder className={classes.btnIcon} />
            )}
            <span className={classes.btnLabel}>{`${
              isFollowing ? "Unfollow" : "Follow"
            }`}</span>
          </>
        </Button>
      </ActionDialog>
    );
  }
);
