import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { IGetMe } from "src/models";
import { useStyles } from "./empty-state.styles";

interface IProps {
  getMe?: IGetMe;
}
export const EmptyStateIndicator: FC<IProps> = ({ getMe }) => {
  const classes = useStyles();
  return (
    <section className={classes.noChatContainer}>
      <img
        src={require("../../../screens/event-detail/detail/order-info/images/emptybag.svg")}
        alt="Image"
        className={classes.bagImage}
      />
      {getMe?.restaurant ? (
        <Typography variant="body2" component="p">
          Looks like you do not have any conversations with your guests!
        </Typography>
      ) : (
        <Typography variant="body2" component="p">
          Looks like you do not have any conversations with any cooks!
        </Typography>
      )}
    </section>
  );
};
