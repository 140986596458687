import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS } from "src/styles";
import { COLORS } from "src/styles/colors";

export const useStyles = makeStyles({
  container: {
    width: "100%",
    padding: "1.75em",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: 0,
    },
  },
  headerContainer: {
    marginBottom: "1em",
    display: "flex",
    flexWrap: "wrap",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      margin: "1em",
    },
  },
  couponCode: {
    backgroundColor: COLORS.MEDIUM_GREEN,
    color: COLORS.WHITE,
    padding: "0px 4px",
    fontFamily: "CustomFourBold",
  },
  couponPercet: {
    fontFamily: "CustomFourBold",
    color: COLORS.BLACK,
    textDecoration: "underline",
  },
  header: {
    color: "#287CF9",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  soldOutSection: {
    paddingTop: "1em",
  },
  soldOutContainer: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: "1em",
  },
  chip: {
    marginRight: 4,
    marginBottom: 4,
  },
  note: {
    color: COLORS.DARK_GREY,
    margin: 0,
    maxWidth: 500,
  },
  actionButtonsContainer: {
    flexGrow: 1,
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(90px, 1fr))",
    alignItems: "center",
    alignContent: "center",
    gridGap: 5,
    padding: "1rem 0",
  },
  actionBtn: {
    color: COLORS.MEDIUM_GREEN,
    borderColor: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    minWidth: 90,
  },
  eventOverviewContainer: {
    backgroundColor: "#F5F7FB",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    padding: "1.75em",
    minHeight: 240,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexDirection: "column",
      padding: "1em",
    },
  },
  eventOverviewLayout: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  eventOverviewTopText: {
    padding: "0 1em",
  },
  eventOverviewTop: {
    flexGrow: 1,
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  eventTitle: {
    color: COLORS.MEDIUM_GREEN,
    textDecorationColor: COLORS.MEDIUM_GREEN,

    margin: "0",
    fontWeight: 500,
  },
  eventTime: { margin: "0", fontFamily: "CustomFourBold" },
  eventLocation: {
    color: COLORS.DARK_GREY,
    margin: "0",
  },
  eventOverviewBottom: { width: "100%", padding: "1em 0" },
  subHeader: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    margin: "0",
  },
  orderedDishesList: {
    listStyleType: "none",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(170px, 1fr))",
    gridGap: "1em",
    margin: 0,
    padding: "1em 0 0 0",
    alignItems: "center",
  },

  eventMetricsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",

    padding: ".5rem",
    marginLeft: "2rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexDirection: "row",
      marginLeft: 0,
    },
  },
  guestCountContainer: {
    backgroundColor: "#FFF7EE",
    boxShadow: "0 2px 12px 0 rgba(206,206,206,0.5)",
    padding: ".5rem 1.75rem",
    display: "flex",
    flexDirection: "column",

    alignItems: "center",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexGrow: 1,
    },
  },
  eventMetricUnits: {
    color: COLORS.DARK_GREY,
    whiteSpace: "nowrap",

    textTransform: "capitalize",
    margin: 0,
  },
  metricValue: { display: "block", order: -1, margin: 0 },
  totalSaleContainer: {
    backgroundColor: "#F7FAFF",
    boxShadow: "0 2px 12px 0 rgba(206,206,206,0.5)",
    padding: ".5em 1.75em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexGrow: 1,
    },
  },
  soldOutBtn: {
    color: COLORS.MEDIUM_GREEN,
    backgroundColor: "unset",
    border: `1px solid ${COLORS.MEDIUM_GREEN}`,
    padding: "0px 4px",
    cursor: "pointer",
  },
  blockOffContainer: {
    fontFamily: "CustomFourBold",
    display: "flex",
    alignItems: "center",
    color: "purple",
    fontWeight: 500,

    margin: "0",
    flexWrap: "wrap",
  },
  timeTextField: { margin: "0 10px" },
  saveBlockOffBtn: {
    fontFamily: "CustomFourBold",
    color: COLORS.WHITE,
  },
  help: {
    textAlign: "right",
    color: COLORS.BLUE,
    margin: "2rem",
    marginBottom: 0,
    textDecoration: "underline",
    cursor: "pointer",
  },
});
