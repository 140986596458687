import { Button, Dialog } from "@material-ui/core";
import React from "react";
import { COLORS } from "src/styles";
import { LocalStorageHelper } from "src/utils/helpers";

// Because the app has crashed at this point, we cannot use a lot of React functionality

interface IProps {
  error: any;
}
// Keep startup url (in case your app is an SPA with html5 url routing)
const initialHref = window.location.href;

export const FallbackComponent: React.FC<IProps> = () => {
  const restartApplication = () => {
    // Clearing cart data as a safety precaution
    LocalStorageHelper.clearCartDataFromLocalStorage();

    if ((navigator as any).splashscreen) {
      (navigator as any).splashscreen.show();
      (window as any).location = initialHref;
    } else {
      window.location.replace("/menus");
    }
  };

  return (
    <Dialog fullWidth={true} disableBackdropClick open={true}>
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          padding: "1rem",
        }}
      >
        <h2 style={{ fontSize: "2rem" }}>😔</h2>
        <h1
          style={{
            fontSize: "2rem",
            marginBottom: "1rem",
            fontFamily: "CustomFourBold",
          }}
        >
          Oops... That wasn't supposed to happen
        </h1>
        <p style={{ marginBottom: "0.5rem", fontSize: "1.6rem" }}>
          Our app just malfunctioned! We will work on fixing this so it does not
          happen again. Please click the button below, refresh the page, or
          close and reopen the app
        </p>

        <Button
          onClick={restartApplication}
          style={{
            backgroundColor: COLORS.MEDIUM_GREEN,
            color: COLORS.FLASH_WHITE,
            fontFamily: "CustomFourBold",
          }}
          variant="contained"
        >
          Take me back to safety
        </Button>
      </section>
    </Dialog>
  );
};
