import { gql } from "@apollo/client";

export const DINER_GET_RESTAURANT_GUEST = gql`
  query dinerGetRestaurantUser($input: ID!) {
    dinerGetRestaurantUser(restaurantId: $input) {
      id
      restaurantId
      userId
      status
      isFollowing
      updatedAt
      createdAt
    }
  }
`;
