import AppBar, { AppBarProps } from "@material-ui/core/AppBar";
import React, { useState } from "react";

export const TopBar: React.FC<AppBarProps> = ({ children, ...appBarProps }) => {
  const [height, setHeight] = useState<number | null>(null);
  const appBarBodyRef = React.useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    } else {
      setHeight(null);
    }
  }, []);
  const offsetRef = React.useRef<any>();

  React.useLayoutEffect(() => {
    if (height !== null) {
      offsetRef.current.style.marginTop = `${height}px`;
      return () => {
        offsetRef.current.style.marginTop = "unset";
      };
    }
  }, [height]);

  return (
    <>
      <AppBar position="fixed" {...appBarProps}>
        <div style={{ flexGrow: 1 }} ref={appBarBodyRef}>
          {children}
        </div>
      </AppBar>
      <div ref={offsetRef} />
    </>
  );
};
