import { makeStyles, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import classNames from "classnames";
import React, { FC } from "react";
import { StyledRedButton, StyledWhiteButton } from "src/components";
import { BREAK_POINTS, COLORS } from "src/styles";

interface IProps {
  history: any;
}

const useStyles = makeStyles({
  container: {
    height: "100vh",
    width: "100%",
    position: "relative",
  },
  background: {
    backgroundImage: `url(${require("./assets/success.jpg")})`,
    height: "100vh",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "70%",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  verifyContainer: {
    height: 250,
    width: 389,
    borderRadius: 2,
    backgroundColor: "rgba(255,255,255,0.75)",
    justifyContent: "space-evenly",
    padding: 30,
    position: "relative",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: 345,
    },
  },
  contentContainer: {
    justifyContent: "center",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  h1: {
    height: 55,
    width: 256,
    color: COLORS.DARK_BLUE,

    textAlign: "center",
    textShadow: "0 2px 4px rgba(74,74,74,0.5)",
    marginBottom: -20,
  },
  button: {
    width: 389,
    boxShadow: "0 2px 4px 0 rgba(31,64,80,0.31)",
    marginTop: 20,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: 345,
    },
  },
  buttonContent: {
    fontFamily: "CustomFourBold",

    textAlign: "center",
  },
  checkMark: {
    color: COLORS.MEDIUM_GREEN,
    height: 230,
    width: 180,
    textShadow: "0 2px 4px rgba(74,74,74,0.5)",
  },
});

export const VerifySuccessContainer: FC<IProps> = ({ history }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.background}>
        <div
          className={classNames(
            classes.contentContainer,
            classes.flexContainer
          )}
        >
          <div
            className={classNames(
              classes.verifyContainer,
              classes.flexContainer
            )}
          >
            <Typography variant="h1" component="h1" className={classes.h1}>
              Your account has been verified
            </Typography>
            <Check className={classes.checkMark} />
          </div>
          <StyledWhiteButton
            className={classes.button}
            onClick={() => history.push("/d")}
          >
            <span className={classes.buttonContent}>Go To My Account</span>
          </StyledWhiteButton>
          <StyledRedButton
            className={classes.button}
            onClick={() => history.push("/menus")}
          >
            <span
              className={classes.buttonContent}
              style={{ color: COLORS.WHITE }}
            >
              View Events
            </span>
          </StyledRedButton>
        </div>
      </div>
    </div>
  );
};
