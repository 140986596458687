import { makeStyles, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { BREAK_POINTS, COLORS } from "src/styles";
import "./first-steps.css";
import { getImageClassName } from "./helpers";

interface IProps {
  step: any;
  idx: number;
  selection: string;
}

export const Step: FC<IProps> = ({ step, idx, selection }) => {
  const classes = useStyles();
  return (
    <div
      className={idx % 2 === 0 ? classes.stepSection : classes.stepSectionTwo}
      style={{
        maxWidth: 400,
        width: "100%",
        padding: "0 10px",
        minWidth: 0,
        margin: "0 0 10px 0",
      }}
    >
      <div>
        <div className="stepLabelContainer">
          <Typography variant="body2" component="p" className="stepLabel">
            {idx + 1}
          </Typography>
        </div>
      </div>
      <div className={classes.wrapSection}>
        <div
          className={classes.stepContentContainer}
          style={{ maxWidth: 400, width: "100%" }}
        >
          {step.title}
        </div>
        <div className={getImageClassName(selection, idx)}>
          <img className={classes.image} src={step.image} alt="enjoy" />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  stepSection: {
    display: "flex",
    margin: "120px 0",
    justifyContent: "space-between",
    minWidth: 700,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      justifyContent: "center",
    },
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexDirection: "column",
      alignItems: "center",
      minWidth: 0,
    },
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      margin: "60px 0",
      padding: "0 20px",
    },
  },
  stepSectionTwo: {
    display: "flex",
    flexDirection: "row-reverse",
    margin: "120px 0",
    justifyContent: "space-between",
    minWidth: 700,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      justifyContent: "center",
    },
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexDirection: "column",
      alignItems: "center",
      minWidth: 0,
    },
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      margin: "60px 0",
      padding: "0 20px",
    },
  },
  stepContentContainer: {
    display: "flex",
    width: 441,
    flexDirection: "column",
    alignItems: "center",
    margin: "0 15px",
  },
  wrapSection: {
    display: "flex",
    flexWrap: "wrap",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: 400,
      width: "100%",
    },
  },
  image: {
    width: "100%",
    height: "100%",
  },
  stepLabelContainer: {
    height: 145,
    width: 145,
    borderRadius: "50%",
    opacity: 0.31,
    backgroundColor: "#3F9B69",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  stepLabel: {
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",

    margin: 0,
  },
});
