import { useQuery } from "@apollo/client";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { parse, stringify } from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ResponsiveDialog } from "src/components";
import { LoginContainer } from "src/screens/login/container";
import { SignUpContainer } from "src/screens/sign-up/container";
import { sharedAPI } from "src/shared-graphql";
import { GET_SIGNIN_DIALOG_STATUS } from "src/shared-graphql/queries";
import { COLORS } from "src/styles";

interface AuthDialogProps {
  dialogCallback?: (d?: any) => void;
}

export const AuthDialog: React.FC<AuthDialogProps> = ({ dialogCallback }) => {
  const { data, error, loading } = useQuery(GET_SIGNIN_DIALOG_STATUS);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const toggleLogin = () => setIsLogin((p) => !p);
  const onClose = () => {
    sharedAPI.setUserSignInDialog(false);
    history.replace({
      pathname: location.pathname,
    });
  };
  const onExited = () => {
    const { state, ...rest } = location.search
      ? parse(location.search)
      : ({} as any);
    history.replace({
      search: stringify(rest),
    });
  };

  useEffect(() => {
    if (!location.search) {
      setIsLogin(false);
      return;
    }
    const res = parse(location.search) as { state: string };
    setIsLogin(res.state ? /login/i.test(res.state) : false);
  }, [location.search]);

  return (
    <ResponsiveDialog
      BackdropProps={{
        style: { backgroundColor: `rgba(255, 255, 255, 0.6)` },
      }}
      fullWidth
      maxWidth="sm"
      scroll="body"
      data-testid={isLogin ? "login-dialog" : "sign-up-dialog"}
      open={!!data?.showSignInDialog}
      onClose={onClose}
      onExited={onExited}
    >
      <IconButton
        style={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}
        onClick={onClose}
        data-testid="close-auth-dialog-button"
        aria-label="close"
      >
        <Close style={{ color: COLORS.MEDIUM_GREEN }} />
      </IconButton>
      <div>
        {isLogin ? (
          <LoginContainer
            containerStyle={{ minHeight: "100%" }}
            switchView={toggleLogin}
            fromVerify={true}
            onSubmit={(d) => {
              if (dialogCallback) {
                dialogCallback(d);
              }
              onClose();
            }}
            closeDialog={onClose}
          />
        ) : (
          <SignUpContainer
            switchView={toggleLogin}
            fromVerify={true}
            onSubmit={(d) => {
              if (dialogCallback) {
                dialogCallback(d);
              }
              onClose();
            }}
          />
        )}
      </div>
    </ResponsiveDialog>
  );
};
