import { Button, Chip } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import { useCreateChannel } from "src/components/chat2";
import { IGetMe, IRestaurant } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { formatToDate } from "src/utils/helpers";
import { useStyles } from "../restaurant-card/restaurant-card.styles";

interface IProps {
  restaurant: IRestaurant;
  getMe?: IGetMe;
}

export const AvailableMenus: React.FC<IProps> = React.memo(
  ({ restaurant, getMe }) => {
    const classes = useStyles();
    const history = useHistory();
    const { createChannel } = useCreateChannel();

    const availTypes = [
      ...(new Set(restaurant.events.map((event) => event.type).flat()) as any),
    ];
    return (
      <section className={classes.chipContainer}>
        {restaurant?.events?.slice(0, 3).map((e) => (
          <Chip
            classes={{ root: classes.chipRoot }}
            key={e.id}
            color="secondary"
            label={formatToDate(e.startTime)}
            onClick={(event) => {
              event.stopPropagation();
              // sharedAPI.setDialogEventId(e.id);
              history.push(`/menus/${e.id}`);
            }}
          />
        ))}
        {restaurant?.events.length > 3 && (
          <Button
            variant="text"
            disableFocusRipple
            disableRipple
            className={classes.moreButton}
          >
            + more
          </Button>
        )}
        {/* If restaurants have not menus up, request now button */}
        {availTypes.length === 0 && !restaurant.fromCSV && (
          <Button
            variant="outlined"
            color="secondary"
            className={classes.requestNowBtn}
            onClick={(e) => {
              e.stopPropagation();
              !getMe
                ? sharedAPI.setUserSignInDialog(true)
                : createChannel(restaurant.host.id);
            }}
          >
            Request Now
          </Button>
        )}

        {restaurant.fromCSV && restaurant.externalLink && (
          <Button
            variant="outlined"
            color="secondary"
            className={classes.requestNowBtn}
            onClick={(e) => {
              e.stopPropagation();
              window.open(restaurant.externalLink, "_blank");
            }}
          >
            External Site
          </Button>
        )}
      </section>
    );
  }
);
