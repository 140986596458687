import { Divider, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { FC } from "react";
import { IRestaurantGuest } from "src/models";
import { layoutStyles as useStyles } from "./layout.styles";
import { List } from "./list";

interface IProps {
  guests: IRestaurantGuest[];
  count: number;
  onClose: () => void;
  restaurant: any;
  fetchMore: any;
}

export const Layout: FC<IProps> = ({
  guests,
  onClose,
  restaurant,
  fetchMore,
  count,
}) => {
  const containerRef = React.useRef<any>();
  const classes = useStyles();

  return (
    <div className={classes.layout} ref={containerRef}>
      <section className={classes.headerContainer}>
        <div style={{ width: 50 }}></div>
        <Typography variant="h4" component="h4" className={classes.title}>
          Followers
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </section>
      <Divider />
      <List guests={guests} restaurant={restaurant} />
      {guests?.length < count && (
        <button
          className={classes.moreBtn}
          onClick={() => fetchMore({ variables: {} })}
        >
          More
        </button>
      )}
    </div>
  );
};
