import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  main: {
    flexGrow: 1,
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  noMarginMain: {
    flexGrow: 1,
    marginTop: 0,
  },
});
