import React from "react";
import { Helmet } from "react-helmet";
import { Layout } from "./layout";

interface IProps {
  switchView?: () => void;
  onSubmit?: (d: any) => void;
  history?: { push: (args: any) => void };
  location?: any;
  fromVerify?: boolean;
}

export const SignUp: React.FC<IProps> = ({
  switchView,
  fromVerify,
  onSubmit,
  ...routerProps
}) => {
  const _description =
    "Sign up for a COOK Connect account, \
    and book meals at all of the home restaurants in your area. Create your diner profile, or become a home cook!";
  return (
    <>
      <Helmet>
        <title>Sign Up | Join Foodnome</title>
        <meta name="description" content={_description} />
      </Helmet>
      <Layout
        switchView={switchView}
        fromVerify={fromVerify}
        onSubmit={onSubmit}
        {...routerProps}
      />
    </>
  );
};

export const SignUpContainer = SignUp;
