import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    backgroundColor: "#f7cbca",
    position: "relative",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    maxWidth: "140rem",
    margin: "9rem auto",
    marginBottom: "15rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      margin: "5rem auto",
      marginBottom: "6rem",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginBottom: "2rem",
    },
  },
  squiggleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
  },
  squiggle: {
    width: "60%",
    minWidth: "12rem",
    maxWidth: "18rem",
  },
  title: {
    fontFamily: "CustomFourBold",
    textAlign: "center",
  },
  mainContent: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(24rem, 1fr))",
    alignItems: "center",
    padding: "0 4rem",
    columnGap: "7rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "0 2rem",
    },
  },
  textContainer: {
    margin: "0 auto",
    maxWidth: "48rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "3rem",
  },
  text: {
    fontFamily: "CustomFourMedium",
  },
  bold: {
    textDecoration: "none",
    fontFamily: "CustomFourBold",
    color: COLORS.DARK_RED,
  },
  image: {
    width: "100%",
  },
  badge: {
    position: "absolute",
    zIndex: 1,
    right: "-8%",
    bottom: "-8%",
    width: "20%",
    filter: "drop-shadow(0 2px 16px rgba(0,0,0,0.36))",
    minWidth: "8rem",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    maxWidth: "48rem",
    margin: "auto",
  },
  leaf: {
    position: "absolute",
    top: "-9%",
    left: "-4%",
    width: "17%",
    maxWidth: "18rem",
    minWidth: "8rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      top: "-5%",
    },
  },
});
