import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Fade } from "react-awesome-reveal";
import { IGetMe } from "src/models";
import { useStyles } from "./top-mobile.styles";

interface IProps {
  getMe: IGetMe;
}

export const TopMobileCook: FC<IProps> = ({ getMe }) => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      {/* <video autoPlay muted loop playsInline className={classes.video}>
        <source
          src="https://foodnome.sfo2.digitaloceanspaces.com/videos/home.mp4"
          type="video/mp4"
        />
      </video> */}

      <div className={classes.innerContainer}>
        <img
          className={classes.image}
          src="https://foodnome.sfo2.digitaloceanspaces.com/start-your-home-restaurant/cook-landing.jpg"
          alt="cook connect cook image"
        />
        <img
          className={classes.blob}
          src={require("src/assets/logos/main_header_logo_white_outline.png")}
          alt="cook connect logo blob"
        />
        <div className={classes.topSection}>
          <Fade triggerOnce={true} delay={250}>
            <Typography variant="h1" component="h1" className={classes.title}>
              Sell Home-Cooked Food from Your Kitchen!
            </Typography>
          </Fade>
          <Fade triggerOnce={true} delay={500}>
            <Typography variant="h4" component="h4" className={classes.text}>
              COOK Connect is the legal marketplace for home cooked food, run by
              the nonprofit that legalized Microenterprise Home Kitchen
              Operations (MEHKOs) . We support chefs & home cooks to make it
              easy to start selling food from your own kitchen! Currently COOK
              Connect is available by invitation-only as well as to members of
              the public who complete our COOK Academy program– which is open
              for enrollment!:
            </Typography>
          </Fade>
        </div>
        {/* <div className={classes.bottomSection}>
          <div className={classes.divContainer}>
            <Fade triggerOnce={true} delay={750}>
              <CreateRestaurantContainer user={getMe} />
            </Fade>
          </div>
        </div> */}
      </div>
    </section>
  );
};
