import { useQuery } from "@apollo/client";
import { makeStyles, Snackbar } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import React, { FC } from "react";
import { sharedAPI } from "src/shared-graphql";
import { GET_SNACKBAR_MSG } from "src/shared-graphql/queries";
import { COLORS } from "src/styles";

const SlideTransition = (props: TransitionProps) => {
  return <Slide {...props} direction="down" />;
};

export const SnackBarGlobal: FC = () => {
  const { data } = useQuery(GET_SNACKBAR_MSG);
  const classes = useStyles();
  const getSnackbarMsg = data?.getSnackbarMsg;

  const onClose = () => sharedAPI.setSnackbarMsg();

  const getSnackbarClassName = () => {
    if (!getSnackbarMsg) return classes.default;
    switch (getSnackbarMsg.type) {
      case "success":
        return classes.success;
      case "error":
        return classes.error;
      case "information":
        return classes.information;
      case "warning":
        return classes.warning;
      default:
        return classes.default;
    }
  };

  const getEmoji = () => {
    if (!getSnackbarMsg) return "";
    switch (getSnackbarMsg.type) {
      case "success":
        return (
          <span className={classes.emoji} aria-label="party emoji" role="img">
            {"🎉"}
          </span>
        );
      case "error":
        return (
          <span className={classes.emoji} aria-label="cross emoji" role="img">
            {"❌"}
          </span>
        );
      case "warning":
        return (
          <span
            className={classes.emoji}
            aria-label="warning sign emoji"
            role="img"
          >
            {"⚠️"}
          </span>
        );
      case "information":
        return (
          <span
            className={classes.emoji}
            aria-label="information emoji"
            role="img"
          >
            {"ℹ"}
          </span>
        );
      default:
        return null;
    }
  };

  if (!getSnackbarMsg) return null;
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={Boolean(getSnackbarMsg.msg)}
      autoHideDuration={8000}
      onClose={onClose}
      TransitionComponent={SlideTransition}
      ContentProps={{
        "aria-describedby": "message-id",
        classes: {
          root: getSnackbarClassName(),
          message: classes.message,
        },
        style: {
          borderRadius: 2,
        },
      }}
      message={
        <span className={classes.emoji} id="message-id">
          {" "}
          {getEmoji()} {getSnackbarMsg.msg}
        </span>
      }
    />
  );
};

const useStyles = makeStyles({
  emoji: {
    marginRight: "1em",
  },

  message: {},
  error: {
    backgroundColor: COLORS.RED,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.WHITE,
    height: "unset",
    minHeight: "4.4rem",
  },
  success: {
    backgroundColor: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.WHITE,
    height: "unset",
    minHeight: "4.4rem",
  },
  warning: {
    backgroundColor: COLORS.LIGHT_GOLD,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.DARK_GREY,
    height: "unset",
    minHeight: "4.4rem",
  },
  information: {
    backgroundColor: COLORS.LAVENDER,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.WHITE,
    height: "unset",
    minHeight: "4.4rem",
  },
  default: {
    backgroundColor: COLORS.GOLD,

    color: COLORS.DARK_GREY,
  },
});
