import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS } from "src/styles";

export const useStyles = makeStyles({
  buttonLabel: {
    padding: "1em",
  },
  button: {
    background: `rgba(255, 255, 255, 0.58)`,
    padding: 0,
    minWidth: "auto",
    width: "36px",
    height: "36px",
    // borderRadius: "50%",
    position: "absolute",
    top: "40%",
    "&:hover": {
      background: `rgba(255, 255, 255, 0.8)`,
    },
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
});
