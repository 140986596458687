import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { isAndroid, isIOS, osVersion } from "react-device-detect";
import { BREAK_POINTS } from "src/styles";

export const CompatibilityDialog: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const isSmallerThaniPad = useMediaQuery(
    `(max-width: ${BREAK_POINTS.mobileRegular}em)`
  );

  const classes = useStyles();

  // IOS 12 was released on September 17th, 2018. Probably a good idea to keep a 1 - 2 year threshold
  const blackListIOSVersion = 12.0;

  // Android Oreo 8 was released on July 24th, 2017
  const blackListAndroidVersion = 8.0;

  useEffect(() => {
    if (isSmallerThaniPad && (isIOS || isAndroid)) {
      if (
        isIOS &&
        parseFloat(osVersion) &&
        parseFloat(osVersion) < blackListIOSVersion
      ) {
        setOpen(true);
      }
      if (
        isAndroid &&
        parseFloat(osVersion) &&
        parseFloat(osVersion) < blackListAndroidVersion
      ) {
        setOpen(true);
      }
    }
  }, [isSmallerThaniPad]);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Typography variant="h2" component="h1" className={classes.title}>
            Darn...
          </Typography>
          <Typography variant="body1" component="p" className={classes.text}>
            We have detected that you are using{" "}
            <span className={classes.bold}>
              {isIOS
                ? `IOS version ${osVersion}`
                : `Android version ${osVersion}`}
            </span>
            .
          </Typography>
          <Typography variant="body1" component="p" className={classes.text}>
            To ensure you the best user experience, we recommend that you
            upgrade to{" "}
            <span className={classes.bold}>
              {isIOS
                ? `IOS version ${blackListIOSVersion}`
                : `Android version ${blackListAndroidVersion}`}
            </span>{" "}
            or better.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            onClick={() => setOpen(false)}
            variant="contained"
            color="secondary"
          >
            Proceed Anyway
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </>
  );
};

export const useStyles = makeStyles({
  container: {
    height: "90vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 1rem",
    textAlign: "center",
  },
  title: {
    marginBottom: "1rem",
  },
  text: {
    marginTop: "1rem",
  },
  bold: {
    fontFamily: "CustomFourBold",
  },
});
