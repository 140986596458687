import { makeStyles } from "@material-ui/core/styles";
import { BREAK_POINTS } from "src/styles";
import { COLORS } from "src/styles/colors";

export const useFormStyles = makeStyles((theme) => ({
  container: {},
  innerContainer: {
    display: "flex",
    flexDirection: "column",

    flexWrap: "wrap",
    margin: "2rem auto",
  },
  avatarWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    flexGrow: 0,
    flexShrink: 1,
    padding: "0 2rem 0 0",

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: 0,
    },
  },
  profileImageLabel: {
    textAlign: "center",
    margin: "0 0 1em",
  },
  formRight: {
    margin: "0 auto",
    display: "grid",
    gridTemplateRows: `repeat(6, auto)`,
    gridTemplateColumns: `1fr 1fr`,
    gridGap: 20,
    flex: 1,
    maxWidth: 900,
  },
  formContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  label: {},
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
  },
  nameInputContainer: {
    minWidth: 120,
  },
  fullRow: {
    gridColumn: "1 / -1",
  },
  textArea: {
    width: "100%",
  },
  textAreaSubContainer: {
    width: "85%",
    margin: "0 auto",
  },
  textField: {
    border: `1px solid ${COLORS.LIGHT_GREY}`,
    borderRadius: 2,
    backgroundColor: COLORS.WHITE,
    padding: "10px 10px",
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    "&:hover": {
      border: `1px solid ${COLORS.LIGHT_GREY}`,
    },
    "&::placeholder": {
      color: COLORS.MEDIUM_GREY,
      padding: "auto",
    },
  },
  actionButton: {
    background: COLORS.MEDIUM_RED,
    borderRadius: "2px",
    marginTop: "16px",
    height: 40,
    color: COLORS.WHITE,
    boxShadow: "0 2px 6px 0 rgba(90,15,15,0.5)",
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    minWidth: 136,
    "&:hover": {
      background: COLORS.MEDIUM_RED,
    },
    "&:disabled": {
      background: COLORS.MEDIUM_RED,
    },
  },
   disabled: { background: `rgba(64, 64, 64, 0.5) !important` },
  // '@media (max-width: 700px)': {
  //   textFieldContainer: {
  //     width: '100%',
  //   },
  //   textFieldSubContainer: {
  //     padding: '0px 10px 20px 20px',
  //   },
  //   textArea: {
  //     width: '100%',
  //   },
  //   textAreaSubContainer: {
  //     width: '90%',
  //   },
  // },
  dinerInput: {
    border: `1px solid ${COLORS.LIGHT_GREY}`,
    borderRadius: 2,
    backgroundColor: COLORS.WHITE,
    padding: "10px 10px",
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    "&:hover": {
      border: `1px solid ${COLORS.LIGHT_GREY}`,
    },
    "&::placeholder": {
      color: COLORS.MEDIUM_GREY,
      padding: "auto",
    },
  },
  formBottom: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "0 40px 0 20px",
    alignItems: "center",
    flexWrap: "wrap",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      justifyContent: "flex-end",
      margin: "1em 0",
      minHeight: 100,
    },
  },
  formControlLabel: {
    color: COLORS.DARK_GREY,
    ...theme.typography.body2,
  },
  changePasswordLink: {
    color: COLORS.MEDIUM_RED,

    textDecoration: "none",
    alignSelf: "center",
    justifySelf: "flex-end",
  },
}));
