import { gql } from "@apollo/client";

export const EDIT_ARRIVAL_TIME = gql`
  mutation cookEditArrivalTime($input: CookEditArrivalTimePL!) {
    cookEditArrivalTime(cookEditArrivalTimePL: $input) {
      id
      arrivalTime
    }
  }
`;

export const EDIT_ORDER_ARRIVAL_TIME = gql`
  mutation cookEditOrderArrivalTime($input: CookEditOrderArrivalTimePL!) {
    cookEditOrderArrivalTime(cookEditOrderArrivalTimePL: $input) {
      id
      arrivalTime
    }
  }
`;
