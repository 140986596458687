import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const formStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
    minWidth: "300px",
    backgroundColor: COLORS.GOLD,
  },
  reviewSection: {
    maxHeight: "calc(100% - 60px)",
    padding: "18px 15px",
    overflow: "visible",
    borderTopRightRadius: 40,
    borderTopLeftRadius: 40,
    position: "relative",
    margin: "50px .5rem .5rem",
  },
  title: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    margin: 0,
  },
  avatar: {
    margin: "0 auto",
    height: 100,
    width: 100,
    backgroundColor: COLORS.RED,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    textTransform: "uppercase",
    border: `solid white 5px`,
  },
  hostName: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    textAlign: "center",

    margin: "0 auto",
  },

  textLabel: {
    textAlign: "center",
    color: COLORS.BLACK,
    margin: 0,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    fontSize: "1.3rem",
    marginTop: "1rem",
  },
  textArea: {
    width: "100%",
    borderRadius: "4px",

    padding: 4,

    boxShadow: "none",
    border: `1px solid ${COLORS.LIGHT_GREY}`,
    "&:required": {
      border: `1px solid ${COLORS.RED}`,
    },
  },
  submitBtn: { color: COLORS.WHITE },
  loader: {
    color: COLORS.WHITE,
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  actionContainer: {
    padding: 10,
    paddingBottom: "calc(10px + env(safe-area-inset-bottom))",
    backgroundColor: COLORS.WHITE,
    boxShadow: `0 0 6px 0 rgba(0,0,0,0.15)`,
  },
  btnGroup: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  selectedBtn: {
    width: "20%",
    backgroundColor: COLORS.RED,
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",

    height: 30,
    borderStyle: "solid",
    borderColor: COLORS.RED,
    borderWidth: "1px",
    cursor: "pointer",
  },
  nonSelectedBtn: {
    width: "20%",
    fontFamily: "CustomFourBold",

    backgroundColor: "transparent",
    height: 30,
    borderStyle: "solid",
    borderColor: COLORS.LIGHT_GREY,
    borderWidth: "1px",
    cursor: "pointer",
  },
  noBtn: {
    fontFamily: "CustomFourBold",
  },

  button: {
    width: "100%",
    margin: "0 .5rem",
    whiteSpace: "nowrap",
    border: "0",
    cursor: "pointer",
    outline: "0",
    display: "inline-block",
    boxShadow: "0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)",
    color: COLORS.WHITE,
    borderRadius: "4px",

    fontWeight: 500,
    textTransform: "uppercase",

    backgroundColor: COLORS.MEDIUM_GREEN,
    textDecoration: "none",
    transition: "all 150ms ease",
    fontFamily: "CustomFourBold",
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_GREEN,
      "&:disabled": {
        backgroundColor: COLORS.LIGHT_GREEN,
        cursor: "not-allowed",
      },
    },
    "&:disabled": {
      cursor: "not-allowed",
      backgroundColor: COLORS.LIGHT_GREEN,
    },
  },

  cardEnd: { textTransform: "lowercase" },
  creditCardEndingContainer: {
    width: "100%",
    textAlign: "left",
    marginTop: "12px",
  },
  creditCardEndingBtn: {
    color: "grey",
    cursor: "pointer",
    border: "none",
    padding: 0,
    background: "none",
  },
  question: {
    margin: ".5rem 0",
  },
  tipInput: {
    padding: "10px",
  },
});
