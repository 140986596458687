import { Button, Toolbar, Typography, useMediaQuery } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { ShareButton, TopBar } from "src/components";
import { IEvent, IGetMe } from "src/models";
import { BREAK_POINTS } from "src/styles";
import { useShouldGoBack } from "src/utils/hooks/useShouldGoBack";
import { FollowButton } from "./";
import { useStyles } from "./mobile-header.styles";

interface IProps {
  getEvent: IEvent;
  getMe?: IGetMe;
}

export const MobileHeader: FC<IProps> = ({ getEvent, getMe }) => {
  const classes = useStyles();
  const history = useHistory();

  const shouldGoBack = useShouldGoBack();
  const isDesktop = useMediaQuery(`(min-width: ${BREAK_POINTS.tablet}em)`);

  if (isDesktop) return null;
  return (
    <TopBar className={classes.container}>
      {/* <Banner containerStyles={{ backgroundColor: COLORS.MEDIUM_GREEN }} /> */}
      <Toolbar className={classes.headerContent}>
        <section>
          {/* If the user arrives from an ad or an external place, we want the user to be able to click "All Menus" */}
          <Button
            onClick={() => history.goBack()}
            variant="text"
            color="secondary"
            startIcon={
              <ChevronLeft className={classes.backArrowMobileHeader} />
            }
          >
            Go Back
          </Button>
        </section>
        <Typography
          variant="h2"
          component="h2"
          className={classes.bookOrderTitle}
        >
          Menu{" "}
        </Typography>
        <section className={classes.btnContainer}>
          <ShareButton shareUrl={`https://foodnome.com/menus/${getEvent.id}`} />
          <FollowButton currentUser={getMe ? getMe : null} event={getEvent} />
        </section>
      </Toolbar>
    </TopBar>
  );
};
