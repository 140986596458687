import { Button, makeStyles } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import React, { FC } from "react";
import { COLORS } from "src/styles";

interface IProps {
  text: string;
  onClick: () => void;
}

const useStyles = makeStyles({
  facebookLabel: {
    color: COLORS.WHITE,

    textTransform: "none",

    justifyContent: "space-between",
  },
  facebookContained: {
    backgroundColor: "#3871C7",
    borderRadius: 2,
    width: "100%",
    height: 50,
    maxWidth: 328,
    padding: 8,
    margin: "0 auto",
    "&:hover": {
      backgroundColor: "rgb(56, 113, 199, 0.7)",
    },
  },
  labelText: {
    width: "100%",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
});

export const FacebookBtn: FC<IProps> = ({ onClick, text }) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      size="large"
      disableElevation
      fullWidth
      classes={{
        label: classes.facebookLabel,
        contained: classes.facebookContained,
      }}
      onClick={onClick}
    >
      <FacebookIcon
        style={{ width: "2.5rem", height: "2.5rem", color: COLORS.WHITE }}
      />{" "}
      <span className={classes.labelText}>{text}</span>
    </Button>
  );
};
