import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  header: {
    backgroundColor: "#FFEDE6",
    textAlign: "center",
    fontFamily: "CustomFourBold",
    padding: "2rem 0",
  },
  primaryTypography: {
    fontFamily: "CustomFourBold",
    color: COLORS.MEDIUM_GREEN,
  },
  secondaryTypography: {
    color: COLORS.BLACK,
  },
  black: {
    color: COLORS.BLACK,
  },
  listItem: {
    paddingLeft: "2.5rem",
    paddingTop: "2rem",
    paddingBottom: "2rem",
  },
  hybridLink: {
    textDecoration: "none",
  },
});
