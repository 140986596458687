import { useEffect } from "react";
import { sharedAPI } from "src/shared-graphql";

export const useDinerMode = () => {
  useEffect(() => {
    // Where `false` => Diner Mode
    sharedAPI.setActiveMode(false).then(() => {
      sharedAPI.setAuthDrawerState(false);
    });
  }, []);
};

export const useCookMode = () => {
  useEffect(() => {
    // Where `true` => Cook Mode
    sharedAPI.setActiveMode(true).then(() => {
      sharedAPI.setAuthDrawerState(false);
    });
  }, []);
};
