import { makeStyles, Typography } from "@material-ui/core";
import React, { FC } from "react";
// import { CookDinerToggle } from "src/components";
import { IGetMe } from "src/models";
import { BREAK_POINTS } from "src/styles";
import {
  AboutUs,
  Cards,
  CookDinerToggle,
  FindFoodButton,
  Hungry,
  Landscape,
  Reviews,
  SellYourFood,
  Success,
} from "./components";
import { CookAcademyInterestButton } from "./components/cook-acadmey-interest-button";

interface IProps {
  setError: (error: string) => void;

  selection: string;

  error: any;
  loading: boolean;
  user: IGetMe | undefined;
}

export const Layout: FC<IProps> = React.memo(
  ({ selection, loading, error, user }) => {
    const classes = useStyles();
    return (
      <div className={classes.container}>
        <div className={classes.howItWorksContainer}>
          <img
            src={require("src/screens/how-it-works/assets/tacos.png")}
            alt="tacos"
            className={classes.taco}
          />
          <img
            src={require("src/screens/how-it-works/assets/apron.png")}
            alt="tacos"
            className={classes.apron}
          />
          <img
            src={require("src/assets/logos/main_header_logo.png")}
            alt="COOK Connect Logo"
            className={classes.logo}
          />
          <Typography component="h1" variant="h1" className={classes.title}>
            How it Works
          </Typography>
          <CookDinerToggle selection={selection} />
        </div>

        {selection === "diner" ? (
          <>
            <Cards isDiner={true} />
            <FindFoodButton />
            <Landscape />
            <AboutUs />
            <Reviews />
            <Hungry />
          </>
        ) : (
          <>
            {/* <CookDash /> */}
            <Cards isDiner={false} />
            <CookAcademyInterestButton />
            <Landscape />
            <AboutUs />
            {/* <SellYourFood user={user} /> */}
            <Success />
          </>
        )}
      </div>
    );
  }
);

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  howItWorksContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "5rem 3rem",
    paddingBottom: 0,
  },
  title: {
    fontFamily: "CustomFourBold",
    margin: "4rem 0",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      margin: "2.5rem 0",
    },
  },
  taco: {
    position: "absolute",
    top: -100,
    left: -120,
    transform: "rotate(30deg)",
    height: "34rem",
    zIndex: -1,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      top: -100 / 2,
      left: -120 / 2,
      height: "20rem",
    },
    [`@media (max-width: ${BREAK_POINTS.mobileRegular}em)`]: {
      height: "15rem",
    },
  },
  apron: {
    position: "absolute",
    top: -70,
    right: -5,
    transform: "rotate(190deg)",
    height: "34rem",
    zIndex: -1,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      top: -70 / 2,
      right: -5 / 2,
      height: "25rem",
    },
    [`@media (max-width: ${BREAK_POINTS.mobileRegular}em)`]: {
      height: "15rem",
    },
  },
  logo: {
    display: "none",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "block",
      height: "5rem",
    },
  },
});
