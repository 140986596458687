import { gql } from "@apollo/client";

export const ADMIN_GET_DISHES = gql`
  query adminGetDishes($input: AdminGetDishesPL) {
    adminGetDishes(adminGetDishesPL: $input) {
      count
      rows {
        id
        name
        category
        description
        imagesGQL {
          publicId
          small
          medium
          large
          original
        }
        dietary
        tags
        updatedAt
        createdAt
      }
    }
  }
`;
