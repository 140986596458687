import { useQuery } from "@apollo/client";
import React, { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ErrorLoadingComponent } from "src/components";
import { sharedAPI } from "src/shared-graphql";
import { useMobileHeader } from "src/utils/hooks";
import { dishAPI } from "../api";
import { GET_DISH } from "../api/graphql";
import { DishFormSubmitPL } from "../form/form";
import { formatInitialState } from "./helpers";
import { Layout } from "./layout";

interface IProps {
  onDishCreated?: (args?: any) => any;
}

export const EditDishContainer: FC<IProps> = ({ onDishCreated }) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  if (!onDishCreated) onDishCreated = () => history.push(`/c/dishes`);
  const { data, loading, error } = useQuery<{ getDish: any }>(GET_DISH, {
    variables: { input: id },
  });

  useMobileHeader({
    title: data?.getDish ? `Edit Dish: "${data?.getDish.name}"` : "",
  });

  const initialState = data?.getDish
    ? formatInitialState(data?.getDish)
    : undefined;
  const handleSubmit = (pl: DishFormSubmitPL) =>
    dishAPI
      .updateDish({
        allergens: pl.allergens.map((a) => a.id),
        description: pl.description,
        dietary: pl.dietary,
        defaultPrice: pl.defaultPrice,
        id: data?.getDish.id,
        images: pl.images.map((i) => JSON.stringify(i)),
        isPublished: pl.isPublished,
        name: pl.name,
        tags: pl.tags,
        options: pl.options,
        category: pl.category,
      })
      .then(onDishCreated)
      .then(() =>
        sharedAPI.setSnackbarMsg({ type: "success", msg: "Dish Updated!" })
      );

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }

  return <Layout onSubmit={handleSubmit} initialState={initialState} />;
};
