import { useMutation } from "@apollo/client";
import { History } from "history";
import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import { GET_MY_PENDING_RESTAURANT_REVIEWS } from "src/components/review-request/api/queries";
import { LocalStorageHelper } from "src/utils/helpers";
import { client } from "../../apollo";
import { GET_ME, LOGIN } from "./api";
import { Layout } from "./layout";

interface IProps {
  switchView?: () => void;
  onSubmit?: (d?: any) => void;
  closeDialog?: () => void;
  containerStyle?: React.CSSProperties;
  history?: History;
  fromVerify?: boolean;
  location?: any;
  email?: string;
}
export const LoginContainer: FC<IProps> = React.memo(
  ({
    switchView,
    fromVerify,
    onSubmit,
    closeDialog,
    email,
    containerStyle = {},
  }) => {
    const [login] = useMutation(
      LOGIN,
      process.env.REACT_APP_ENV !== "cordova"
        ? {
            awaitRefetchQueries: true,
            refetchQueries: [
              { query: GET_ME },
              { query: GET_MY_PENDING_RESTAURANT_REVIEWS },
            ],
          }
        : {}
    );
    const history = useHistory();
    const location = useLocation();
    const [error, setError] = useState(null);

    const _login = (d: { email: string; password: string }) => {
      return login({ variables: { input: d } })
        .then(async (userD) => {
          if (process.env.REACT_APP_ENV === "cordova") {
            if (userD?.data?.login?.authToken) {
              await LocalStorageHelper.setItem(
                "foodnome_auth_token",
                userD.data.login.authToken
              );
            }
            client.query({
              query: GET_ME,
              fetchPolicy: "network-only",
              errorPolicy: "ignore",
            });
            client.query({
              query: GET_MY_PENDING_RESTAURANT_REVIEWS,
              fetchPolicy: "network-only",
              errorPolicy: "ignore",
            });
          }
          return d;
        })
        .then(() => {
          if (onSubmit) {
            return onSubmit(d);
          }
          if (history && (location?.state as any).from) {
            return history.push((location?.state as any).from);
          }
        })
        .catch((e) => setError(e));
    };

    const _description =
      "Log in to your COOK Connect account, view your upcoming meals, \
    and update your COOK Connect profile. Diner log in. Cook log in.";
    return (
      <>
        <Helmet>
          <title>Log In | My Account | COOK Connect</title>
          <meta name="description" content={_description} />
        </Helmet>
        <Layout
          login={_login}
          error={error}
          setError={setError}
          email={email}
          switchView={switchView}
          onSubmit={onSubmit}
          fromVerify={fromVerify}
          containerStyle={containerStyle}
          closeDialog={closeDialog}
        />
      </>
    );
  }
);
