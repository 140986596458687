import { Button, Card, Typography } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router";
import { IEvent } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import {
  eventTypeMap,
  formatStartToEndDate,
  getDishImage,
} from "src/utils/helpers";
import { useStyles } from "./restaurant-event-card.styles";

interface RestaurantEventCardProps {
  event: IEvent;
}

export const RestaurantEventCard: React.FC<RestaurantEventCardProps> = ({
  event,
}) => {
  const classes = useStyles();
  const image = getDishImage(event);
  const history = useHistory();
  const [date, time] = formatStartToEndDate(
    {
      startTime: event.startTime,
      endTime: event.endTime,
    },
    { removeMs: false, spacedDashes: true }
  ).split(",");

  // Make sure event is not a POPUP event to check if every dish is sold out.
  const allSoldOut = event.dishes.every((dish) => {
    if (!event.type.includes("FIXED_TIME") && dish.DishEvent) {
      return dish.DishEvent.count - dish.DishEvent.sold <= 0;
    }
    return false;
  });

  return (
    <Card
      component="article"
      className={classes.container}
      onClick={() => {
        // sharedAPI.setDialogEventId(event.id)
        sharedAPI.setDialogRestaurantName("");
        history.push(`/menus/${event.id}`);
      }}
    >
      <div style={{ position: "relative" }}>
        <div className={classes.timeLayout}>
          <div className={classes.redBar} />
          <div className={classes.dateLayout}>
            <Typography component="time" variant="subtitle1">
              {moment(Number(event.startTime)).format("MMM")}
            </Typography>
            <Typography
              component="time"
              variant="subtitle1"
              style={{ color: "#FF0050" }}
            >
              {moment(Number(event.startTime)).format("D")}
            </Typography>
          </div>
        </div>
        <div className={classes.imageContainer}>
          {allSoldOut ? (
            <>
              <div className={classes.soldOutWrapper}>
                <Typography
                  component="p"
                  variant="h3"
                  className={classes.soldOutText}
                >
                  Sold Out
                </Typography>
              </div>
              <img src={image} alt="dish image" className={classes.image} />
            </>
          ) : (
            <img src={image} alt="dish image" className={classes.image} />
          )}
        </div>
      </div>
      <div className={classes.cardBody}>
        <Typography variant="h4" component="h4" className={classes.eventTitle}>
          {event.name}
        </Typography>

        <div className={classes.typeAndTimeContainer}>
          <Typography
            variant="body2"
            component="p"
            className={classes.datetimeText}
          >
            <AccessTimeIcon className={classes.timeIcon} />
            {time}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.eventType}
          >
            {event.type
              .map((type) => {
                const child = eventTypeMap[type];
                switch (type) {
                  case "DELIVERY":
                    return child;

                  case "FIXED_TIME":
                    return child;

                  case "TAKE_OUT":
                    return child;

                  case "DROP_IN":
                    return child;
                  default:
                    throw new Error(
                      `renderType called with invalid type - ${type}`
                    );
                }
              })
              .join(" & ")}
          </Typography>
        </div>
        <Button
          classes={{
            contained: classes.viewButton,
          }}
          fullWidth
          color="secondary"
          variant="contained"
        >
          See Menu
        </Button>
      </div>
    </Card>
  );
};
