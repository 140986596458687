import axios from "axios";
import { client, SERVER_ADDRESS } from "src/apollo";
import { GET_ME, RESET_PASSWORD } from "./graphql";

export const passwordResetAPI = {
  getPasswordResetToken: (email: string) =>
    axios.post(`${SERVER_ADDRESS}/user-account/reset-token`, { email }),

  resetPassword: (token: string, password: string) =>
    client.mutate({
      mutation: RESET_PASSWORD,
      variables: {
        input: { token, password },
      },
      refetchQueries: [{ query: GET_ME }],
    }),
};
