import { makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { COLORS } from "src/styles";

interface IProps {
  date: string;
  color?: string;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    padding: ".123rem .45rem",
    boxShadow: `0 2px 4px 0 rgba(173,173,173,0.5)`,
    borderRadius: "2px 2px 0 0",
  },
  month: { display: "block", textTransform: "uppercase" },
  day: { display: "block" },
});

export const DateIcon: React.FC<IProps> = ({
  date,
  color = COLORS.LAVENDER,
}) => {
  const classes = useStyles();
  return (
    <Typography
      component="time"
      variant="body2"
      style={{ borderTop: `solid ${color} 7px` }}
      dateTime={moment(Number(date)).toISOString()}
      className={classes.container}
    >
      <span className={classes.month}>
        {moment(Number(date)).format("MMM")}
      </span>
      <span className={classes.month}>{moment(Number(date)).format("D")}</span>
    </Typography>
  );
};
