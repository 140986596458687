import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useFormStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    borderRadius: 0,

    width: "100%",
    justifyContent: "center",
    alignItems: "center",

    paddingTop: "2rem",
    // height: "92vh",
  },
  fieldsWrapper: {
    maxWidth: 332,
  },
  fieldsContainer: {
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: "1rem",
  },
  centered: {
    alignSelf: "center",
  },
  header: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    margin: "1rem 0",
    textAlign: "center",
  },
  loginLink: {
    color: COLORS.DARK_RED,
    fontFamily: "CustomFourBold",
    textDecoration: "none",
    textTransform: "uppercase",
    fontWeight: 500,

    marginLeft: "1rem",
  },
  inputLabel: {
    display: "none",
  },
  inputRoot: {
    height: "40px",
    "&$inputFocused": {
      borderColor: `${COLORS.DARK_GREY} !important`,
      borderWidth: "2px",
    },
    "&:hover:not($disabled):not($inputFocused):not($error)": {
      borderColor: `${COLORS.DARK_GREY} !important`,
      borderWidth: "2px",
    },
    marginTop: ".75rem",
  },
  underLineDisabled: {},
  underLineFocused: {},
  input: {
    color: "#294E60",
    marginLeft: "10px",
    "&::placeholder": {
      color: COLORS.DARK_GREY,
    },
  },
  signUpInput: {
    color: "#294E60",
    "&::placeholder": {
      color: COLORS.DARK_GREY,
    },
  },
  outlined: {
    borderRadius: 2,
  },
  actionButton: {
    backgroundColor: COLORS.MEDIUM_RED,
    borderRadius: 2,
    width: "100%",
    height: 46,
    maxWidth: 328,
    margin: "0 auto",
    marginTop: ".75rem",
    fontFamily: "CustomFourBold",
    textTransform: "uppercase",
    fontWeight: 500,

    color: COLORS.WHITE,
    "&:disabled": {
      backgroundColor: `rgba(255, 89, 75, 0.7)`,
      transform: `translateY(8px)`,
      boxShadow: "none",
    },
    "&:hover": {
      background: COLORS.DARK_RED,
    },
  },
  disabled: {},
  error: {},
  inputFocused: {},
  subCard: {
    width: "100%",
    maxWidth: 328,
    color: COLORS.DARK_GREY,
    textAlign: "center",
    padding: "1rem 0 0",

    borderTop: `solid 1px #E5E5E5`,
    marginTop: "1.75rem",
    verticalAlign: "center",
  },
  createAccountContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  formHelperRoot: {
    margin: 0,
  },
  label: {
    position: "absolute",
    display: "block",
    border: "0px",
    margin: "-1px",
    padding: "0px",
    height: "1px",
    width: "1px",
    clip: "rect(0, 0, 0, 0)",
    overflow: "hidden",
  },
  rememberMe: {
    color: "#FF5967",
  },
  link: {
    textDecoration: "none",

    color: COLORS.RED,
  },
  logo: {
    width: 40,
    height: 40,
    margin: "0 auto",
  },
  list: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    listStyleType: "none",
    width: "100%",
    margin: "0 auto",
    padding: 0,
  },
  listItemGutters: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  thirdPartyError: {
    fontFamily: "CustomFourBold",
    color: COLORS.RED,
    maxWidth: 328,
    margin: "0 auto",
  },
});
