import { useMutation, useQuery } from "@apollo/client";
import { Button, Typography, useMediaQuery } from "@material-ui/core";
import { Add, ChevronLeft, FilterList } from "@material-ui/icons";
import classNames from "classnames";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ErrorLoadingComponent, FetchLoading } from "src/components";
import { DishWithAmount, IDish, IEvent } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { BREAK_POINTS, COLORS } from "src/styles";
import { escapedRegExp } from "src/utils/helpers";
import { useMobileHeader, useScrolledBottom } from "src/utils/hooks";
import { CreateDishDialog } from "../../dish";
import { ActionButtons } from "./action-buttons";
import "./animations.css";
import { ADD_DISHES_TO_EVENT } from "./api";
import { GET_DISH_SELECTION } from "./api/graphql";
import { EventFormDishCard } from "./dish";
import { SearchDishForm } from "./search";
import { useStyles } from "./styles";

export const EventDishSelectContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  // All Hooks
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  const [selectedDishes, setSelectedDishes] = useState<DishWithAmount[]>([]);
  const [isCreateDishDialogOpen, setCreateDishDialogState] = useState<boolean>(
    false
  );
  const [isFetchInflight, setFetchInFlight] = useState<boolean>(false);
  const ref = useRef<any>();
  const formRef = useRef<any>();
  const [showSelectedDishes, setShowSelectedDishes] = useState(false);
  const [addDishesToEvent] = useMutation(ADD_DISHES_TO_EVENT);
  const { data = {} as any, error, loading, fetchMore, variables } = useQuery(
    GET_DISH_SELECTION,
    {
      variables: {
        eventId: id.split("-")[0],
        cookGetDishesPL: {
          pagePL: { offset: null, limit: 9 },
          searchTerm: "",
        },
      },
      fetchPolicy: "network-only",
    }
  );

  const limitRef = useRef<number>(variables!.cookGetDishesPL.pagePL.limit);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchScrollBottom = useCallback(() => {
    if (variables && data.cookGetDishes && !showSelectedDishes) {
      const { count } = data.cookGetDishes;
      if (limitRef.current < count) {
        setFetchInFlight(true);
        const limit = limitRef.current + 9;
        fetchMore({
          variables: {
            ...variables,
            cookGetDishesPL: {
              ...variables.cookGetDishesPL,
              pagePL: {
                ...variables.cookGetDishesPL.pagePL,
                limit,
              },
            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            const _data = Object.assign({}, prev, fetchMoreResult);
            return _data;
          },
        })
          .then(() => setFetchInFlight(false))
          .catch(() => setFetchInFlight(false));
        limitRef.current = limit;
      }
    }
  }, [data.cookGetDishes, variables, fetchMore, showSelectedDishes]);

  // For infinite scroll
  useScrolledBottom({
    callback: fetchScrollBottom,
    ref,
  });
  // Mobile Header Title Update
  useMobileHeader({
    title: data.cookGetEvent ? `Select Dish: "${data.cookGetEvent.name}"` : "",
    pathname: `/c/menus/edit/${id}`,
  });

  // Guard
  useEffect(() => {
    if (process.env.REACT_APP_ENV === "cordova") return;
    if (location && location.state && (location.state as any).from) {
      const whiteList = [
        /^\/c\/menus\/create/i,
        escapedRegExp(`/c/menus/edit/${id.split("-")[0]}`),
        escapedRegExp(`/c/menus/summary/${id.split("-")[0]}}`),
      ];
      if (
        whiteList.some(
          (regexp) =>
            location &&
            location.state &&
            (location.state as any).from &&
            regexp.test((location.state as any).from)
        )
      )
        return;
    } else history.push(`/c/menus/edit/${id}`);
  }, []);

  useEffect(() => {
    if (data.cookGetEvent) {
      setSelectedDishes(
        data.cookGetEvent.dishes.map((d: IDish) => {
          return {
            id: d.id,
            price:
              d.DishEvent && typeof d.DishEvent.price === "number"
                ? d.DishEvent.price.toString()
                : "",
            count:
              d.DishEvent && typeof d.DishEvent.count === "number"
                ? d.DishEvent.count.toString()
                : "",
            images: d.images,
            name: d.name,
          };
        })
      );
    }
  }, [data.cookGetEvent]);

  useEffect(() => {
    if (selectedDishes.length === 0) {
      setShowSelectedDishes(false);
    }
  }, [selectedDishes.length]);

  const handleSearch = useCallback(
    debounce((event: { target: { value: string } }) => {
      if (fetchMore && variables) {
        setFetchInFlight(true);
        setShowSelectedDishes(false);
        return fetchMore({
          variables: {
            ...variables,
            cookGetDishesPL: {
              ...variables.cookGetDishesPL,
              searchTerm: event.target.value,
            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            const _data = Object.assign({}, prev, fetchMoreResult);
            return _data;
          },
        })
          .then(({ data: { cookGetDishes } }) => {
            setFetchInFlight(false);
            window.scrollTo(0, 0);
            limitRef.current =
              event.target.value === "" ? 9 : cookGetDishes.count;
          })
          .catch(() => setFetchInFlight(false));
      }
    }, 500),
    [variables, fetchMore]
  );

  const getSearchedValue = () => {
    const input = document.getElementById(
      "search-dishes-input"
    ) as HTMLInputElement;
    return input ? input.value : "";
  };

  const isActive = data.cookGetEvent
    ? data.cookGetEvent.status === "ACTIVE"
    : false;

  const onSelectDish = (_dish: IDish) => () => {
    setSelectedDishes((prevDishes) => {
      const prevDish = prevDishes.find((d) => d.id === _dish.id);
      return !!prevDish
        ? prevDishes.filter((d) => d.id !== _dish.id)
        : prevDishes.concat([
            {
              id: _dish.id,
              name: _dish.name,
              images: _dish.images,
              price: _dish.defaultPrice?.toString() ?? null,
              count: "",
            },
          ]);
    });
  };

  const isFixedTimeEvent = data.cookGetEvent
    ? data.cookGetEvent.type.includes("FIXED_TIME")
    : false;

  const getOriginalDish = (dishId: number) => {
    return data.cookGetEvent.dishes.find((d: IDish) => d.id === dishId);
  };

  const getInputValue = (_id: number) => (key: "count" | "price") => {
    const found = selectedDishes.find((d) => d.id === _id);
    return found ? (found[key] as string) : "";
  };

  const onClickRemove = (_id: number) => () =>
    setSelectedDishes((prevDishes) => prevDishes.filter((d) => d.id !== _id));

  const onInputChange = (_id: number) => (event: {
    target: { value: string; name: string };
  }) => {
    const { name, value } = event.target;
    setSelectedDishes((prevDishes) =>
      prevDishes.map((d) => (d.id === _id ? { ...d, [name]: value } : d))
    );
  };

  const onSuccess = () => {
    const dishes = selectedDishes.map((d) =>
      isFixedTimeEvent
        ? {
            dishId: d.id,
            price: null,
            count: null,
          }
        : {
            dishId: d.id,
            price: d.price === "" ? null : Number(d.price),
            count: d.count === "" ? null : Number(d.count),
          }
    );

    return addDishesToEvent({
      variables: {
        input: {
          dishes,
          eventId: data.cookGetEvent.id,
        },
      },
    })
      .then(() =>
        isActive
          ? history.push(`/c/menus/${id}`)
          : history.push(`/c/menus/summary/${id}`, {
              from: location.pathname,
            })
      )
      .then(() => {
        isActive &&
          sharedAPI.setSnackbarMsg({ type: "success", msg: "Dishes Updated!" });
      })
      .catch((_e) =>
        sharedAPI.setSnackbarMsg({
          type: "error",
          msg: _e.message.replace(/Graphql Error: /i, ""),
        })
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSuccess();
  };

  const handleReview = (e) => {
    // if "Pop-up" just submit the form without going back to "All Selected"
    const isPopUp = data.cookGetEvent.type.includes("FIXED_TIME");
    // "To-go" and/or "Dine-in" events needs to have a count + price to be considered VALID.
    const isValidToGoDineIn = selectedDishes.every((dish) => {
      if (
        dish.count === null ||
        dish.price === null ||
        dish.count === "" ||
        dish.price === ""
      )
        return false;

      const count = Number(dish.count);
      const price = Number(dish.price);
      // Count needs to be greater than 0 but price could be "FREE"
      return count > 0 && price >= 0;
    });

    if (isPopUp || isValidToGoDineIn)
      return formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );

    setShowSelectedDishes(true);
    formRef.current.checkValidity();
  };

  const saveAndExit = () => {
    const dishes = selectedDishes.map((d) =>
      isFixedTimeEvent
        ? {
            dishId: d.id,
            price: null,
            count: null,
          }
        : {
            dishId: d.id,
            price: d.price === "" ? null : Number(d.price),
            count: d.count === "" ? null : Number(d.count),
          }
    );

    return addDishesToEvent({
      variables: {
        input: {
          dishes,
          eventId: data.cookGetEvent.id,
        },
      },
    })
      .then(() => history.push("/c/menus?status=PENDING"))
      .then(() =>
        sharedAPI.setSnackbarMsg({
          msg: "Successfully saved!",
          type: "success",
        })
      )
      .catch((err) => {
        // Assuming that the only error will be that the user does not have an approved restaurant...
        // This takes the user back to pending menus and tells the user that their menu has been saved successfully
        history.push("/c/menus?status=PENDING");
        sharedAPI.setSnackbarMsg({
          msg: "Successfully saved!",
          type: "success",
        });
      });
  };

  const onCreateDish = (_dish: IDish) => {
    onSelectDish(_dish)();
    // reset limitRef
    limitRef.current = 9;
  };

  const toggleSelectedDishes = () => {
    window.scrollTo(0, 0);
    setShowSelectedDishes((p) => !p);
  };

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }

  const displayDishes = showSelectedDishes
    ? selectedDishes
    : data.cookGetDishes.rows;

  return (
    <div ref={ref} className={classes.card}>
      <FetchLoading appear={isFetchInflight} />
      <CreateDishDialog
        open={isCreateDishDialogOpen}
        handleCloseDialog={() => setCreateDishDialogState(false)}
        onCreateDish={onCreateDish}
      />

      <div className={classes.cardHeader}>
        <div className={classes.cardHeaderContent}>
          <div className={classes.cardHeaderActions}>
            <Button
              className={classes.backButton}
              onClick={() => history.push(`/c/menus/edit/${id}`)}
            >
              <ChevronLeft style={{ color: COLORS.MEDIUM_GREEN }} />
              Back
            </Button>
            <Typography variant="h2" component="h1" className={classes.heading}>
              Choose Dish(es)
            </Typography>
          </div>

          <div style={{ marginRight: ".5rem" }}>
            <SearchDishForm handleSearch={handleSearch} />
          </div>

          <Button
            aria-label="create a dish"
            style={{ display: "flex", alignItems: "center" }}
            classes={{
              contained: classNames(classes.button, classes.createButton),
              startIcon: classes.startIcon,
            }}
            onClick={() => setCreateDishDialogState(true)}
            variant="contained"
            startIcon={<Add style={{ color: COLORS.WHITE }} />}
          >
            {!isMobile && "Create Dish"}
          </Button>
        </div>
      </div>
      {!!selectedDishes.length && (
        <div className={classes.cardHeaderBottom}>
          <Button
            type="button"
            onClick={toggleSelectedDishes}
            className={classNames(
              classes.filterButton,
              showSelectedDishes && classes.filterButtonActive
            )}
            disabled={selectedDishes.length === 0}
          >
            <FilterList style={{ marginRight: ".5rem" }} />
            {!!showSelectedDishes
              ? "View All Dishes"
              : `Show Selected (${selectedDishes.length})`}
          </Button>
        </div>
      )}

      <form ref={formRef} onSubmit={handleSubmit} className={classes.form}>
        <div className={classes.dishCardsWrapper}>
          {displayDishes.length > 0 ? (
            <ul
              className={classes.dishCardsList}
              data-testid="EventDishSelectContainer_dishList"
            >
              <TransitionGroup component={null}>
                {displayDishes.map((dish: IDish) => {
                  // Check to see if the dish WAS originally selected
                  const originalDish = getOriginalDish(dish.id);
                  // if it has already been selected set the price as the original price else
                  // use the user input
                  let price = getInputValue(dish.id)("price");
                  // Why do this? This is because the user could remove the dish and then add it back in
                  // with a new price.
                  if (isActive) {
                    price = originalDish ? originalDish.DishEvent.price : price;
                  }

                  return (
                    <CSSTransition
                      key={dish.id}
                      timeout={300}
                      classNames={"fade-in"}
                    >
                      <li>
                        <EventFormDishCard
                          isFixedTimeEvent={isFixedTimeEvent}
                          dish={dish}
                          selected={selectedDishes.some(
                            (d) => d.id === dish.id
                          )}
                          onSelectDish={onSelectDish(dish)}
                          onClickRemove={onClickRemove(dish.id)}
                          onInputChange={onInputChange(dish.id)}
                          maxOrders={getInputValue(dish.id)("count")}
                          price={price}
                          isPreviouslySelected={isActive && !!originalDish}
                        />
                      </li>
                    </CSSTransition>
                  );
                })}
              </TransitionGroup>
            </ul>
          ) : getSearchedValue() ? (
            <Typography
              variant="body2"
              component="p"
              style={{
                fontFamily: "CustomFourBold",
                fontWeight: 500,

                textAlign: "center",
              }}
            >
              0 results for "{getSearchedValue()}"
            </Typography>
          ) : (
            <Typography
              variant="body2"
              component="p"
              className={classNames(
                "delayed-animation",
                classes.noDishesMessage
              )}
            >
              You don't have any dishes.{" "}
              <span
                onClick={() => setCreateDishDialogState(true)}
                aria-label="create dish"
                role="button"
                style={{ cursor: "pointer", display: "inline-block" }}
              >
                Try creating one!
              </span>
            </Typography>
          )}
        </div>

        <ActionButtons
          event={data.cookGetEvent as IEvent}
          saveAndExit={saveAndExit}
          selectedDishes={selectedDishes}
          showSelectedDishes={showSelectedDishes}
          handleReview={handleReview}
        />
      </form>
    </div>
  );
};
