import { Button, CircularProgress, Fab, Typography } from "@material-ui/core";
import {
  AddToPhotos,
  ArrowDownward,
  Delete,
  InsertPhoto,
} from "@material-ui/icons";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import SwipeableViews from "react-swipeable-views";
import { CSSTransition } from "react-transition-group";
import { upload } from "src/components/img-upload/api/upload";
import { IImage } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { getFileFromAndroid } from "src/utils/android";
import { useStyles } from "./styles";

export interface DishImageCarouselProps {
  images: IImage[];
  activeIndex: number;
  handleDeleteImage: (index: number) => void;
  handleSetIndex: (index: number) => void;
  onClickLeft: (event: React.MouseEvent<HTMLElement>) => void;
  onClickRight: (event: React.MouseEvent<HTMLElement>) => void;
  onUploaded: (file: IImage) => void;
  onUploading: () => void;
}

export const DishImageCarousel: React.FC<DishImageCarouselProps> = ({
  images,
  handleDeleteImage,
  handleSetIndex,
  activeIndex,
  onClickRight,
  onClickLeft,
  onUploaded,
  onUploading,
}) => {
  const classes = useStyles();
  const [isCarouselDisplayed, setCarouselState] = useState<boolean>(
    !!images.length
  );
  const [loading, setLoading] = useState<boolean>(false);

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      onUploading();
      setLoading(true);
      const { data } = await upload(acceptedFiles[0]);
      setLoading(false);
      const response = {
        publicId: data.public_id,
        small: data.eager[0].secure_url,
        medium: data.eager[0].secure_url.replace(
          "w_350,h_250,c_fill",
          "w_1200,h_857,c_fill"
        ),
        large: data.eager[0].secure_url.replace(
          "w_350,h_250,c_fill",
          "w_2500,h_1785,c_fill"
        ),
        original: data.secure_url,
      };
      onUploaded(response);
    } else {
      sharedAPI.setSnackbarMsg({
        type: "error",
        msg:
          "File type not supported. Only .jpeg, .png, and .jpg are supported",
      });
    }
  };

  const {
    getInputProps,
    getRootProps,
    isDragActive,
    open: openFileSystem,
  } = useDropzone({
    onDrop,
    accept: ".jpeg,.png,.jpg",
    disabled: loading,
    multiple: false,
  });

  const _openFileSystem = async () => {
    if ((window as any).cordova?.platformId === "android") {
      setLoading(true);
      const file = await getFileFromAndroid();
      onDrop([file] as any);
    } else {
      return openFileSystem();
    }
  };
  const CHEVERON_SIZE = 50;

  return (
    <div {...getRootProps()} className={classes.container}>
      <input
        autoComplete={"off"}
        data-testid="dish-image-uploader"
        {...getInputProps()}
      />
      <div>
        {isCarouselDisplayed && (
          <SwipeableViews
            containerStyle={{
              width: "100%",
              backgroundColor: COLORS.LIGHT_GREY,
            }}
            slideStyle={{ flexGrow: 1, margin: "0 auto" }}
            index={activeIndex}
            onChangeIndex={handleSetIndex}
            enableMouseEvents
            ignoreNativeScroll
          >
            {images.map((image) => {
              return (
                <div
                  key={image.publicId}
                  style={{ maxHeight: 250 }}
                  data-testid={`dish-image-card-${image.publicId}`}
                >
                  <img
                    src={image.small}
                    alt="Dish Image"
                    style={{
                      backgroundColor: COLORS.DARK_GREY,
                      width: "100%",
                      display: "block",
                      margin: "auto auto",
                      backgroundSize: "contain",
                    }}
                  />
                </div>
              );
            })}
          </SwipeableViews>
        )}

        {(loading || isDragActive) && (
          <div className={classes.loadingContainer}>
            {loading && (
              <CircularProgress
                style={{ color: COLORS.RED }}
                data-testid="dish-image-loading-spinner"
              />
            )}
            {isDragActive && (
              <ArrowDownward
                className="downArrow"
                style={{ color: COLORS.MEDIUM_GREEN }}
              />
            )}
          </div>
        )}

        {isCarouselDisplayed && (
          <>
            <div className={classes.carouselActionButtonsWrapper}>
              <Fab
                className={classes.carouselFab}
                style={{ marginRight: "1rem" }}
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  handleDeleteImage(activeIndex);
                }}
                data-testid="delete-image-button"
              >
                <Delete style={{ color: COLORS.DARK_BLUE }} />
              </Fab>
              <Fab
                data-testid="upload-image-button"
                className={classes.carouselFab}
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  _openFileSystem();
                }}
              >
                <AddToPhotos style={{ color: COLORS.DARK_BLUE }} />
              </Fab>
            </div>
            {images.length > 1 && (
              <div className={classes.carouselNavButtonsWrapper}>
                <Button
                  classes={{ root: classes.carouselNavButton }}
                  onClick={onClickLeft}
                  data-testid="left-cheveron-button"
                >
                  <img
                    className={classes.cheveron}
                    style={{ transform: "rotate(180deg)" }}
                    src={require("./event_arrow-19.svg")}
                    width={CHEVERON_SIZE}
                  />
                </Button>
                <Button
                  classes={{ root: classes.carouselNavButton }}
                  onClick={onClickRight}
                  data-testid="right-cheveron-button"
                >
                  <img
                    width={CHEVERON_SIZE}
                    className={classes.cheveron}
                    src={require("./event_arrow-19.svg")}
                  />
                </Button>
              </div>
            )}
          </>
        )}

        <CSSTransition
          in={!images.length}
          classNames="fade-in-dish"
          timeout={300}
          onExited={() => setCarouselState(true)}
          onEnter={() => setCarouselState(false)}
          unmountOnExit
        >
          <div
            className={classes.initialDragHotSpot}
            onClick={(event) => {
              event.stopPropagation();
              !loading && _openFileSystem();
            }}
          >
            {isDragActive ? null : loading ? null : (
              <>
                <InsertPhoto style={{ fontSize: "3rem" }} />
                <Typography
                  variant="body2"
                  component="p"
                  style={{ display: "block" }}
                >
                  Upload images
                </Typography>
              </>
            )}
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};
