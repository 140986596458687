import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation loginUser($input: LoginUserInfo!) {
    login(loginUserInfo: $input) {
      email
      role
      nomes
      account
      authToken
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const SIGN_UP = gql`
  mutation createUser(
    $userInfo: UserInfo!
    $updateUserPreferences: UpdateUserPreferences
  ) {
    createUser(
      userInfo: $userInfo
      updateUserPreferences: $updateUserPreferences
    ) {
      id
      email
      role
      authToken
      preferences {
        NEWS_LETTER_EMAIL_SUBSCRIPTION
      }
    }
  }
`;

export const GOOGLE_SIGN_IN = gql`
  mutation signInWithGoogle($input: GoogleUserInfo!) {
    signInWithGoogle(googleUserInfo: $input) {
      email
      role
      firstName
      lastName
      nomes
      account
      newUser
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordPL!) {
    resetPassword(resetPassPL: $input) {
      email
      role
      nomes
      account
    }
  }
`;

export const FACEBOOK_SIGN_IN = gql`
  mutation loginWithFacebook($input: FacebookUserInfo!) {
    signInWithFacebook(facebookUserInfo: $input) {
      id
      firstName
      lastName
      email
      picture
      phoneNumber
      address
      newUser
      preferences {
        NEWS_LETTER_EMAIL_SUBSCRIPTION
      }
    }
  }
`;
