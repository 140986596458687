import { useQuery } from "@apollo/client";
import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { useLocation } from "react-router-dom";
import { GET_ACTIVE_MODE, GET_ME } from "src/shared-graphql/queries";
import { BREAK_POINTS } from "src/styles";
import { Layout } from "./layout";
import { useBottomNavRoutes } from "./routes";

export const BottomNavigation = () => {
  const location = useLocation();
  const isDesktop = useMediaQuery(`(min-width: ${BREAK_POINTS.tablet}em)`);
  const { data, loading, error } = useQuery(GET_ME, { errorPolicy: "ignore" });
  const { data: dataActiveMode } = useQuery(GET_ACTIVE_MODE);
  const activeTab = useBottomNavRoutes(location.pathname);

  // // we don't want to show this component on Desktop
  if (isDesktop) return null;

  return (
    // <CSSTransition
    //   in={!(loading || error)}
    //   unmountOnExit
    //   classNames="slide-up"
    //   timeout={450}
    // >
    <Layout
      isCookMode={dataActiveMode && dataActiveMode.getActiveMode}
      getMeData={data}
      activeTab={activeTab}
    />
    // </CSSTransition>
  );
};
