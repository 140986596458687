import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS } from "src/styles";

export const useStyles = makeStyles({
  mainHeader: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    marginBottom: "1rem",

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  dishFormCard: {
    boxShadow: `0 2px 10px 0 #BCBCBC`,
    borderRadius: 2,
    padding: "3rem",
    overflow: "visible",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "0.75rem",
    },
  },
});
