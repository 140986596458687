import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const tableViewStyles = makeStyles({
  userAvatar: {
    width: 50,
    height: 50,
    borderRadius: "50%",
    alignSelf: "center",
    flexShrink: 0,
  },
  tableRoot: {
    width: "100%",
    maxWidth: 900,
    margin: "0 auto",
    overflowX: "auto",
  },
  tableCell: {
    padding: "4px 24px",
    position: "relative",
  },
  displayTime: { position: "relative" },
  notificationContainer: {
    cursor: "pointer",
    color: COLORS.MEDIUM_GREEN,
    display: "flex",
    alignItems: "center",
    border: "none",
    backgroundColor: "transparent",
  },
  notification: {
    border: "none",
    fontSize: "2.5rem",
    backgroundColor: "transparent",
  },
  guestName: {
    color: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    margin: 0,
  },
  userContact: { listStyleType: "none", margin: 0, padding: 0, flexGrow: 1 },
  userContactItem: {
    color: COLORS.MEDIUM_GREEN,
  },
  contactContainer: {
    padding: "1em",
    position: "relative",
    pointer: "cursor",
  },
  newTag: {
    color: COLORS.RED,
    fontFamily: "CustomFourBold",

    position: "absolute",
    right: 0,
    top: 0,
  },

  completeButton: {
    fontFamily: "CustomFourBold",

    backgroundColor: COLORS.LAVENDER,
    "&:disabled": {
      backgroundColor: COLORS.LAVENDER,
      opacity: "50%",
    },
    "&:hover": {
      backgroundColor: COLORS.LAVENDER,
      opacity: "70%",
    },
  },
  undoButton: {
    color: COLORS.DARK_GREY,
  },
  statusCell: {
    minWidth: 147,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  simpleCell: {},
  columnHeader: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.DARK_GREY,
  },
  userOrderedItems: { whiteSpace: "pre" },
});
