import axios from "axios";
import { SERVER_ADDRESS } from "src/apollo";

export const kpiApi = {
  getNewDinerCount: (data) =>
    axios(`${SERVER_ADDRESS}/kpi/get-new-diner-count`, {
      method: "post",
      data,
      withCredentials: true,
    }),

  getMarketPlaceKpi: (data) =>
    axios(`${SERVER_ADDRESS}/kpi/get-market-place-kpi`, {
      method: "post",
      data,
      withCredentials: true,
    }),

  getDinerCount: (data) =>
    axios(`${SERVER_ADDRESS}/kpi/get-diner-count`, {
      method: "post",
      data,
      withCredentials: true,
    }),

  getCooksByPayment: (data) =>
    axios(`${SERVER_ADDRESS}/kpi/get-cooks`, {
      method: "post",
      data,
      withCredentials: true,
    }),

  getPayments: (data) =>
    axios(`${SERVER_ADDRESS}/kpi/get-payments`, {
      method: "post",
      data,
      withCredentials: true,
    }),

  getAveragePayments: (data) =>
    axios(`${SERVER_ADDRESS}/kpi/get-average-payments`, {
      method: "post",
      data,
      withCredentials: true,
    }),

  getAverageNumPayments: (data) =>
    axios(`${SERVER_ADDRESS}/kpi/get-average-num-payments`, {
      method: "post",
      data,
      withCredentials: true,
    }),

  getMenuLiquidity: (data) =>
    axios(`${SERVER_ADDRESS}/kpi/get-menu-liquidity`, {
      method: "post",
      data,
      withCredentials: true,
    }),

  getLTV: (data) =>
    axios(`${SERVER_ADDRESS}/kpi/get-ltv`, {
      method: "post",
      data,
      withCredentials: true,
    }),

  getRetention: (data) =>
    axios(`${SERVER_ADDRESS}/kpi/get-retention`, {
      method: "post",
      data,
      withCredentials: true,
    }),
};
