import { DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { ResponsiveDialog } from "src/components";
import { COLORS } from "src/styles";
import { IImageUploaderState } from "./state/reducers";

interface IProps {
  open: boolean;
  state: IImageUploaderState;
  onCropChange: (crop: Crop) => void;
  onCancel: () => void;
  clearSrc: () => void;
  onApplyCrop: () => void;
  onImageLoaded: (image: any) => void;
  CropperProps?: {
    minWidth?: number;
    maxWidth?: number;
    minHeight?: number;
    maxHeight?: number;
    aspect?: number;
  };
}

const useStyles = makeStyles({
  dialogActions: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: 1000,
    margin: "0 auto",
    padding: "1rem 0",
  },
  dialogRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "rgba(0, 0, 0, 0.85)",
    justifyContent: "space-evenly",
    padding: "1em",
  },
  actionButton: {
    color: COLORS.WHITE,
  },
});

export const CropDialog: React.FC<IProps> = ({
  open,
  state,
  onCropChange,
  onCancel,
  clearSrc,
  onApplyCrop,
  onImageLoaded,
  CropperProps,
}) => {
  const classes = useStyles();
  return (
    <ResponsiveDialog
      onClose={onCancel}
      onExited={clearSrc}
      classes={{ root: classes.dialogRoot, paper: classes.paper }}
      fullWidth
      maxWidth="md"
      open={open}
    >
      {open && (
        <ReactCrop
          style={{ margin: "0 auto" }}
          src={state.src ? state.src : (state.originalSrc as string)}
          crop={state.crop}
          onChange={onCropChange}
          onImageLoaded={onImageLoaded}
          {...CropperProps}
        />
      )}
      <DialogActions className={classes.dialogActions}>
        <Button className={classes.actionButton} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          onClick={onApplyCrop}
          disabled={state.loading}
          className={classes.actionButton}
        >
          Apply
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
};
