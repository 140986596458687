import { gql } from "@apollo/client";

export const GET_REFERRER_FROM_CODE = gql`
  query userGetReferrerFromCode($input: String!) {
    userGetReferrerFromCode(code: $input) {
      id
      firstName
      lastName
    }
  }
`;
