import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    borderRadius: 0,
    width: "100%",
    justifyContent: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  fieldsWrapper: {
    maxWidth: 332,
  },
  fieldsContainer: {
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: "1em",
  },
  centered: {
    alignSelf: "center",
  },
  header: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    textAlign: "center",
    paddingBottom: "1em",
  },
  logo: {
    width: 150,
  },

  loginLink: {
    color: COLORS.DARK_RED,
    padding: "0 1rem",

    fontFamily: "CustomFourBold",
    textDecoration: "none",
    textTransform: "uppercase",
    fontWeight: 500,
  },
  inputLabel: {
    display: "none",
  },
  inputRoot: {
    height: "40px",
    "&$inputFocused": {
      borderColor: `${COLORS.DARK_GREY} !important`,
      borderWidth: "2px",
    },
    "&:hover:not($disabled):not($inputFocused):not($error)": {
      borderColor: `${COLORS.DARK_GREY} !important`,
      borderWidth: "2px",
    },
    marginBottom: ".75rem",
  },
  underLineDisabled: {},
  underLineFocused: {},
  input: {
    color: "#294E60",
    marginLeft: "10px",
    "&::placeholder": {
      color: COLORS.DARK_GREY,
    },
  },
  signUpInput: {
    color: "#294E60",
    "&::placeholder": {
      color: COLORS.DARK_GREY,
    },
  },
  outlined: {
    borderRadius: 2,
  },
  actionButton: {
    transform: `translateY(0px)`,
    transition: "all 100ms ease-out",
    boxShadow: "0 1px 9px 1px rgba(0,0,0,0.2)",
    backgroundColor: COLORS.RED,
    borderRadius: 2,
    width: "100%",
    height: 46,
    maxWidth: 328,
    margin: "0 auto",
    marginTop: "0.5rem",
    fontFamily: "CustomFourBold",
    textTransform: "uppercase",
    fontWeight: 500,

    color: COLORS.WHITE,
    "&:disabled": {
      backgroundColor: `rgba(255, 89, 75, 0.7)`,
      transform: `translateY(8px)`,
      boxShadow: "none",
    },
    "&:hover": {
      background: COLORS.RED,
    },
  },
  disabled: {},
  error: {},
  inputFocused: {},
  subCard: {
    color: COLORS.DARK_GREY,
    textAlign: "center",
    padding: "1rem 0 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderTop: `solid 1px #E5E5E5`,
    marginTop: "1.75rem",
  },
  formHelperRoot: {
    position: "absolute",
    top: 51,
    margin: 0,
  },
  label: {
    position: "absolute",
    display: "block",
    border: "0px",
    margin: "-1px",
    padding: "0px",
    height: "1px",
    width: "1px",
    clip: "rect(0, 0, 0, 0)",
    overflow: "hidden",
  },
  rememberMe: {
    color: "#FF5967",
  },
  link: {
    textDecoration: "none",

    color: COLORS.MEDIUM_GREEN,
  },
  facebookLabel: {
    color: COLORS.DARK_GREY,

    textTransform: "none",
    display: "flex",
  },
  facebookOutlined: {
    border: `solid 1px ${COLORS.DARK_GREY}`,
    borderRadius: 2,
    padding: `.5rem 1rem`,
    width: "100%",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
  // label: {
  //   position: "absolute",
  //   display: "block",
  //   border: "0px",
  //   margin: "-1px",
  //   padding: "0px",
  //   height: "1px",
  //   width: "1px",
  //   clip: "rect(0, 0, 0, 0)",
  //   overflow: "hidden"
  // },
  formHelperText: {
    margin: "-8px 1px 10px",
  },
});
