import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import React, { FC, useState } from "react";
import { COLORS } from "src/styles";
import { useStyles } from "./dialog-styles";

interface IProps {
  onClose: () => void;
  onConfirm: () => Promise<any>;
  onContinue: () => void;
  openDialog: boolean;
}

export const ConfirmationDialog: FC<IProps> = ({
  openDialog,
  onConfirm,
  onClose,
  onContinue,
}) => {
  const classes = useStyles();
  // Hooks
  const [error, setError] = useState<string>("");
  const [loading, setLoadingState] = useState<boolean>(false);

  const handleConfirm = () => {
    // show spinner + reset error state
    setLoadingState(true);
    setError("");

    if (onConfirm) {
      return onConfirm().catch(({ message: msg }) => {
        setLoadingState(false);
        setError(msg);
      });
    }
  };

  return (
    <Dialog
      aria-labelledby={"Are you still there?"}
      open={openDialog}
      onClose={onClose}
    >
      <DialogContent>
        <div className={classes.container}>
          <Typography variant="h2" component="h1" className={classes.title}>
            Are you still there?
          </Typography>

          <Typography variant="body2" component="p" className={classes.error}>
            {error.split(":")[1]}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions className={classes.btnContainer}>
        <Button
          disabled={loading}
          onClick={handleConfirm}
          color="secondary"
          variant="contained"
          style={{ color: COLORS.WHITE }}
        >
          {loading ? (
            <CircularProgress
              className={classes.spinner}
              data-testid="spinner"
            />
          ) : (
            "Logout"
          )}
        </Button>

        <Button onClick={onContinue} color="primary" variant="outlined">
          Continue Session
        </Button>
      </DialogActions>
    </Dialog>
  );
};
