import { Button, CircularProgress, TextField } from "@material-ui/core";
import React, { FC, useState } from "react";
import { AppStatus, IApplication } from "src/models";
import { SelectAppStatus } from "../select-app-status";
import { useStyles } from "./styles";

interface IProps {
  applicationId: string;
  currentStatus: AppStatus;
  handleSubmit: (p: {
    applicationId: string;
    status: AppStatus;
    description: string;
  }) => Promise<any>;
  application: IApplication;
}

export const AppStatusForm: FC<IProps> = ({
  applicationId,
  currentStatus,
  handleSubmit,
  application,
}) => {
  const classes = useStyles();
  // hooks
  const [_status, setStatus] = useState<null | AppStatus>(currentStatus);
  const [_reason, setReason] = useState<string>("\n--------------------\n");
  const [_loading, setLoading] = useState<boolean>(false);

  // handlers
  const isDisabled = () => _status === null || _reason === "";
  const selectHandler = (value) => setStatus(value);
  const textChangeHandler = (e) => setReason(e.target.value);
  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    handleSubmit({
      applicationId,
      status: _status as AppStatus,
      description: _reason,
    }).then(() => {
      setReason("");
      setLoading(false);
    });
  };

  return (
    <form onSubmit={submitHandler} className={classes.form}>
      <section className={classes.textFields}>
        <SelectAppStatus
          applicationId={applicationId}
          disabled={_loading}
          currentStatus={currentStatus}
          handleSelect={selectHandler}
        />
        <TextField
          size="small"
          disabled={_loading}
          label="Note..."
          variant="outlined"
          multiline
          rows="5"
          value={_reason}
          style={{ width: "100%", marginTop: "20px" }}
          onChange={textChangeHandler}
          inputProps={{
            id: `input-reason-${applicationId}`,
          }}
          InputLabelProps={{
            htmlFor: `input-reason-${applicationId}`,
          }}
        />
      </section>
      <Button
        disabled={isDisabled() || _loading}
        size="large"
        variant="contained"
        color="primary"
        type="submit"
        style={{ margin: "10px" }}
      >
        {_loading ? <CircularProgress /> : "Submit"}
      </Button>
    </form>
  );
};
