import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";
import { useStyles } from "./header.styles";

interface IProps {
  totalCount: number;
  status: string;
  setStatus: (d: string) => void;
}

export const Header: FC<IProps> = ({ totalCount, status, setStatus }) => {
  const classes = useStyles();
  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
      }}
    >
      <Typography variant="h3" component="h1" style={{ marginBottom: "2rem" }}>
        Reviews ({totalCount})
      </Typography>
      <FormControl>
        <InputLabel shrink id="select-status">
          Select Status
        </InputLabel>
        <Select
          labelId="select-status"
          value={status}
          className={classes.selectField}
          onChange={(d) => setStatus(d.target.value as string)}
        >
          <MenuItem value={"PENDING"}>Pending</MenuItem>
          <MenuItem value={"SUBMITTED"}>Submitted</MenuItem>
          <MenuItem value={"REJECTED"}>Rejected</MenuItem>
        </Select>
      </FormControl>
    </Paper>
  );
};
