import { ListItem, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { useStyles } from "./button.styles";

export const CookWithUsButton: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <ListItem
      button
      component="a"
      classes={{ root: classes.noBorderMenuItem }}
      onClick={() => history.push("/sell-your-food")}
    >
      {/* commented for Cook transition */}
      {/* <Typography
        variant="caption"
        component="span"
        style={{ fontFamily: "CustomFourBold" }}
      >
        Cook With Us
      </Typography> */}
    </ListItem>
  );
};
