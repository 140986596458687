import { makeStyles, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { CookEventCard } from "src/components/shared/dashboard-event-card";
import { EventDuplicatePL, IEvent } from "src/models";
import { StatusTypes } from "./helpers";

interface IProps {
  events: IEvent[];
  duplicateEvent: (eventDuplicatePL: EventDuplicatePL) => Promise<any>;
  deleteEvent: (id: string) => Promise<any>;
  activeStatus: StatusTypes;
}

const useStyles = makeStyles({
  listItem: { width: "100%", maxWidth: 330 },
});

export const HostEventsDisplay: FC<IProps> = ({
  events,
  activeStatus,
  deleteEvent,
  duplicateEvent,
}) => {
  const history = useHistory();
  const classes = useStyles();
  if (events.length === 0 && activeStatus !== StatusTypes.ALL_EVENTS)
    return (
      <Typography
        variant="body2"
        component="p"
        style={{
          textAlign: "center",
        }}
      >{`You do not have any ${activeStatus.toLowerCase()} events.`}</Typography>
    );

  return (
    <>
      {events.map((event) => (
        <li className={classes.listItem} key={event.id}>
          <CookEventCard
            event={event}
            onClickDuplicate={duplicateEvent}
            onClickDelete={() => deleteEvent(event.id)}
            onClickCard={() =>
              history.push(
                `${event.status === "PENDING" ? "/c/menus/edit" : "/c/menus"}/${
                  event.id
                }-${event.name.replace(/ /g, "-")}`
              )
            }
          />
        </li>
      ))}
    </>
  );
};
