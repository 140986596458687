import FastfoodIcon from "@material-ui/icons/Fastfood";
import React from "react";
import { IUser } from "src/models";
import { COLORS } from "src/styles";

export const getCookInfo = (user: IUser) => {
  const { restaurant } = user;
  if (restaurant) {
    // If user has approved restaurant, show green hat by name
    if (restaurant?.application.status === "APPROVED") {
      return (
        <FastfoodIcon style={{ color: COLORS.MEDIUM_GREEN, marginRight: 5 }} />
      );
    } else if (restaurant?.application.status === "PENDING") {
      // If user has pending restaurant, show yellow hat
      return <FastfoodIcon style={{ color: COLORS.YELLOW, marginRight: 5 }} />;
    } else {
      // Flagged or Rejected restaurant show red hat
      return <FastfoodIcon style={{ color: COLORS.YELLOW, marginRight: 5 }} />;
    }
  } else return null;
};
