import { Button, Dialog, DialogContent, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { IEvent } from "src/models";
import { useStyles } from "./order-exist-dialog.styles";

interface IProps {
  isOpen: boolean;
  getEvent: IEvent;
}

export const PastEventDialog: FC<IProps> = ({ isOpen, getEvent }) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      classes={{ paperWidthSm: classes.paperWidthSm }}
      fullWidth
    >
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h2" component="h2" className={classes.helloTitle}>
          Hey there! 👋
        </Typography>
        <Typography
          variant="body2"
          component="h2"
          className={classes.helloSubtitle}
        >
          It looks like the cook has stopped taking orders for these dishes or
          is sold out. Still hungry? View other dishes being offered by{" "}
          <b>{getEvent.restaurant.name}</b>.
        </Typography>
        <div className={classes.actionBtnContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              history.push(`/${getEvent.restaurant.name}/order`);
            }}
            className={classes.newOrderBtn}
            size="large"
          >
            View Other Dishes
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
