import moment from "moment";
import { IEvent } from "src/models";

export const formatPreptime = (event: IEvent) => {
  const MbufferTime = moment.duration(
    event.bufferTime as number,
    "milliseconds"
  );
  let preptime = "<hours> <minutes>";
  if (event.type.includes("FIXED_TIME"))
    throw Error(`Pop-up events do not have a bufferTime.`);

  const hours = MbufferTime.hours();
  const minutes = MbufferTime.minutes();

  preptime = preptime.replace(
    "<hours>",
    hours > 0 ? `${hours} hr${hours > 1 ? "s" : ""}` : ""
  );

  preptime = preptime.replace(
    "<minutes>",
    minutes > 0 ? `${minutes} min${minutes > 1 ? "s" : ""}` : ""
  );

  return preptime;
};
