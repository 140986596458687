import { useQuery } from "@apollo/client";
import { Typography } from "@material-ui/core";
import { debounce } from "lodash";
import moment from "moment";
import React, { FC, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { IGetMe } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { GET_ME } from "src/shared-graphql/queries";
import { COLORS } from "src/styles/colors";
import { LocalStorageHelper } from "src/utils/helpers";
import { useScrollDirection } from "src/utils/hooks";
import "./animations.css";
import { resendLink } from "./api/resend";

const BUFFER = 1000 * 60 * 60; // 1 hour

export const VerifyBanner: FC = () => {
  const { data, error, loading } = useQuery<{ getMe: IGetMe }>(GET_ME, {
    fetchPolicy: "cache-and-network",
  });
  const [allowResend, setAllowResend] = useState<boolean>(() => {
    if (!!data?.getMe && !data?.getMe.active) {
      if (!!localStorage) {
        const timeResent = localStorage.getItem(`VERIFICATION_EMAIL_TIMESTAMP`);

        if (!timeResent) return true;

        if (isNaN(Number(timeResent))) {
          console.warn(
            "Error: timestamp in localStorage is not a valid time. Clear your cache and try again."
          );
          return false;
        }

        const timeElapsed = moment().diff(moment(Number(timeResent)));

        if (timeElapsed >= BUFFER) return true;
      }
    }

    return false;
  });

  const scrollUp = useScrollDirection();
  const topOfWindow = window.pageYOffset === 0;
  const offsetHeight = document.documentElement
    ? document.documentElement.offsetHeight
    : 0;
  const bottomOfWindow =
    Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    ) +
      window.innerHeight ===
    offsetHeight;

  const handleResend = debounce(
    () =>
      resendLink().then(() => {
        sharedAPI.setSnackbarMsg({
          type: "information",
          msg: `Verification email re-sent to ${data?.getMe.email}.`,
        });
        if (!!localStorage) {
          LocalStorageHelper.setItem(
            `VERIFICATION_EMAIL_TIMESTAMP`,
            moment().valueOf().toString()
          );
          setAllowResend(false);
        }
      }),
    1000
  );

  // if (loading || error) {
  //   return <ErrorLoadingComponent error={error} loading={loading} />;
  // }

  if (data?.getMe && !data?.getMe.active)
    return (
      <>
        <CSSTransition
          unmountOnExit
          in={(scrollUp || topOfWindow) && !bottomOfWindow}
          classNames="fade-in"
          timeout={300}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              backgroundColor: COLORS.MEDIUM_GREEN,
              fontFamily: "CustomFourBold",

              maxHeight: 48,
              textTransform: "uppercase",

              textAlign: "center",
              padding: "0 0.5rem",
              position: "fixed",
              bottom: 0,
              zIndex: 1000,
              color: COLORS.WHITE,
            }}
          >
            <Typography variant="body2" component="p">
              {!allowResend ? (
                `verification email sent to ${data.getMe.email}! Please check your inbox. 💌`
              ) : (
                <>
                  Your account has not been verified, please check your email or{" "}
                  <button
                    disabled={!allowResend}
                    onClick={handleResend}
                    style={{
                      textDecoration: "underline",
                      textUnderlinePosition: "under",
                      cursor: "pointer",
                      backgroundColor: "transparent",
                      border: "none",
                      color: COLORS.DARK_BLUE,
                      fontFamily: "CustomFourBold",

                      textTransform: "uppercase",

                      outline: "none",
                      padding: 0,
                    }}
                  >
                    click here
                  </button>{" "}
                  to resend.
                </>
              )}
            </Typography>
          </div>
        </CSSTransition>
      </>
    );
  return null;
};
