import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { SideNavigation } from "src/components/side-navigation";
import { IGetMe } from "src/models";
import { dinerTabs } from "./helpers";

export const NavbarContainerLeft: React.FC<{ user: IGetMe }> = ({ user }) => {
  const classes = useStyles();

  return (
    <SideNavigation
      user={user}
      adornmentStart={
        <div className={classes.adornmentStartContainer}>
          <Typography
            variant="h2"
            component="h2"
            className={classes.adornmentStart}
          >
            {`Hi, ${user?.firstName}!`}
          </Typography>
        </div>
      }
      sideBarLinks={dinerTabs}
    />
  );
};

const useStyles = makeStyles({
  adornmentStartContainer: {
    maxWidth: "75%",
    margin: "0 auto",
  },
  adornmentStart: {
    margin: "1rem 0",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    textTransform: "capitalize",

    overflow: "hidden",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "block",
    width: "100%",
  },
});
