import {
  Button,
  InputAdornment,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { parse, stringify } from "query-string";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AutoCompleteContainer, FullScreenSearchDialog } from "src/components";
import { IRestaurant } from "src/models";
import { amplitude } from "src/services";
import { BREAK_POINTS, COLORS } from "src/styles";

export const SearchContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  const [dialogSearchType, setDialogSearchType] = useState("address");

  const handleClose = () => {
    setOpen(false);
  };

  const onSearch = (l: any) => {
    amplitude.getInstance().logEvent("[Home] click search btn");
    history.push({
      pathname: "/menus",
      ...(l.location && {
        search: stringify({
          ...(l.location.longitude && {
            longitude: Number(l.location.longitude).toFixed(5),
            latitude: Number(l.location.latitude).toFixed(5),
          }),
          ...(!l.location.longitude && { location: l.location }),
        }),
      }),
    });
  };

  const queryObj = parse(location.search) as {
    location: string;
    longitude: string;
    latitude: string;
  };
  if (isMobile)
    return (
      <div className={classes.containerMobile}>
        <Button
          variant="contained"
          onClick={() => setOpen(true)}
          classes={{
            root: classes.buttonRoot,
          }}
          fullWidth
          disableRipple
          disableTouchRipple
          disableFocusRipple
          startIcon={<Search className={classes.search} />}
        >
          Search by location or chef
        </Button>
        <Button
          variant="contained"
          onClick={() => history.push("/menus")}
          color="primary"
          fullWidth
          className={classes.findFoodButton}
        >
          Find Food
        </Button>

        <FullScreenSearchDialog
          dialogSearchType={dialogSearchType}
          handleClose={handleClose}
          open={open}
          onSubmit={() => ""}
          queryObj={queryObj}
        />
      </div>
    );
  return (
    <div className={classes.containerDesktop}>
      <AutoCompleteContainer
        useGeoLocation={true}
        onKey={(k) => setSearchTerm(k)}
        onSelect={(l) => onSearch({ location: l })}
        suggestionStyle={{
          zIndex: 10,
        }}
        onSelectRestaurant={(r: IRestaurant) =>
          history.push(`/${r.name.replace(/ /g, "-")}`)
        }
        truncateSuggestion={false}
        initialState={queryObj ? queryObj.location : ""}
        TextFieldProps={{
          variant: "outlined",
          fullWidth: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">
                <Search className={classes.search} />
              </InputAdornment>
            ),
            placeholder: `Search by location or chef`,
            id: "homepage-address-autocomplete-input",
          },
        }}
      />
      <Button
        variant="contained"
        onClick={() => onSearch({ location: searchTerm })}
        color="secondary"
        disableElevation
        className={classes.button}
      >
        Find Food
      </Button>
    </div>
  );
};

const useStyles = makeStyles({
  containerDesktop: {
    display: "flex",
    boxShadow: "0 1px 11px 0 rgba(0,0,0,0.33)",
    borderRadius: "1rem",
  },
  containerMobile: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  button: {
    minWidth: "14rem",
    borderRadius: "0 1rem 1rem 0",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      minWidth: "unset",
    },
    backgroundColor: COLORS.MEDIUM_RED,
    boxShadow: "0 1px 11px 0 rgba(0,0,0,0.33)",
    "&:hover": {
      backgroundColor: COLORS.DARK_RED,
    },
  },
  search: {
    color: COLORS.RED,
  },
  buttonRoot: {
    fontFamily: "CustomFour",
    backgroundColor: COLORS.WHITE,
    color: "#717171",
    textTransform: "none",
    justifyContent: "flex-start",
    marginBottom: "1rem",
    fontSize: "1.6rem",
    borderRadius: "0.5rem",
    boxShadow: "0 1px 11px 0 rgba(0,0,0,0.33)",
    "&:hover": {
      backgroundColor: COLORS.WHITE,
    },
  },
  findFoodButton: {
    fontSize: "1.6rem",
    borderRadius: "0.5rem",
    boxShadow: "0 1px 11px 0 rgba(0,0,0,0.33)",
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_RED
    },
    backgroundColor: COLORS.MEDIUM_RED,
  },
});
