import { Button, CircularProgress, Typography } from "@material-ui/core";
import { AddAPhoto, ArrowDownward, Edit } from "@material-ui/icons";
import React, { FC, useState } from "react";
import { useDropzone } from "react-dropzone";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { getFileFromAndroid } from "src/utils/android";
import { useStyles } from "./cover-photo.styles";

interface IProps {
  src?: string;
  upload: (file: File) => Promise<any>;
  coverWrapperStyle?: any;
}

export const CoverPhoto: FC<IProps> = ({
  src,
  coverWrapperStyle = {},
  upload,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: ".jpeg,.png,.jpg",
    disabled: loading,
  });

  function onDrop(acceptedFiles: File[]) {
    if (acceptedFiles.length) {
      const [file] = acceptedFiles;
      setLoading(true);
      upload(file).finally(() => setLoading(false));
    } else {
      sharedAPI.setSnackbarMsg({
        type: "error",
        msg:
          "File type not supported. Only .jpeg, .png, and .jpg are supported",
      });
    }
  }

  const _open = async () => {
    if ((window as any).cordova?.platformId === "android") {
      setLoading(true);
      const file = await getFileFromAndroid();
      upload(file as any).finally(() => setLoading(false));
    } else {
      return open();
    }
  };

  if (src) {
    return (
      <div
        {...getRootProps()}
        className={classes.coverPhotoContainer}
        style={Object.assign({}, coverWrapperStyle)}
      >
        <div
          style={Object.assign(
            {},
            {
              backgroundImage: `url("${src}")`,
              width: "100%",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "center",
              backgroundPositionY: "center",
              height: 200,
              filter: "blur(16px)",
              transition: "all 500ms ease-in",
            },
            loading ? {} : { filter: "blur(0px)" }
          )}
        />
        <Button
          disabled={loading}
          style={loading ? { backgroundColor: "#f5f7fb" } : {}}
          className={classes.editButton}
          onClick={(event) => {
            event.stopPropagation();
            _open();
          }}
        >
          <input
            autoComplete={"off"}
            data-testid="CoverPhoto-uploader"
            {...getInputProps()}
          />
          {loading ? (
            <CircularProgress className={classes.spinner} />
          ) : (
            <>
              <Edit
                style={{
                  cursor: "pointer",
                  marginRight: ".35rem",
                  color: COLORS.WHITE,
                }}
              />
              <span>Edit Cover Photo</span>
            </>
          )}
        </Button>
        {isDragActive && <DropOverlay />}
      </div>
    );
  }

  return (
    <div
      {...getRootProps()}
      style={{
        border: `3px dashed ${"#d4d4d4"}`,
        background: "#dfe4ea",
        height: 200,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: COLORS.MEDIUM_GREEN,
        fontFamily: "CustomFourBold",
        fontWeight: 500,

        position: "relative",
      }}
    >
      <input
        autoComplete={"off"}
        data-testid="CoverPhoto-uploader"
        {...getInputProps()}
      />
      {loading ? (
        <CircularProgress className={classes.spinner} />
      ) : (
        <>
          <AddAPhoto style={{ color: COLORS.MEDIUM_GREEN }} />
          <span>Upload Cover Photo</span>
        </>
      )}
      {isDragActive && <DropOverlay />}
    </div>
  );
};

const DropOverlay: React.FC = () => (
  <div
    style={{
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      transition: "background-color 300ms ease-in",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    }}
  >
    <ArrowDownward style={{ color: COLORS.MEDIUM_GREEN }} />
    <Typography
      variant="body2"
      component="p"
      style={{
        fontFamily: "CustomFourBold",
        fontWeight: 500,
        color: COLORS.MEDIUM_GREEN,
        textAlign: "center",
      }}
    >
      Drop it in the skillet!{" "}
      <span role="img" aria-label="emoji pan">
        🍳
      </span>
    </Typography>
  </div>
);
