import React, { FC } from "react";
import { IEvent, IGetMe } from "src/models";
import { MobileEventInfo } from "./mobile-event-info";
import { MobileHeader } from "./mobile-header";

interface IProps {
  getEvent: IEvent;
  submitOrder: (token) => Promise<any>;
  total: number;
  dialogView?: string;
  getMe: IGetMe;
}
export const MobilePayment: FC<IProps> = ({
  getEvent,
  submitOrder,
  total,
  getMe,
  dialogView,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      {<MobileHeader getEvent={getEvent} title={"Checkout"} />}

      <MobileEventInfo
        submitOrder={submitOrder}
        total={total}
        getEvent={getEvent}
        getMe={getMe}
      />
    </div>
  );
};
