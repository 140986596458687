import { useQuery } from "@apollo/client";
import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { Element } from "react-scroll";
import { IRestaurant } from "src/models";
import { DishImagesHero } from "src/screens/restaurant/hero";
import { useStyles } from "src/screens/restaurant/layout.styles";
import {
  About,
  Dishes,
  Overview,
  Reviews,
  UpcomingEventsWidgetDesktop,
} from "src/screens/restaurant/widgets";
import { GET_ME } from "src/shared-graphql/queries";
import { BREAK_POINTS } from "src/styles";

interface LayoutProps {
  restaurant: IRestaurant;
}

export const Layout: React.FC<LayoutProps> = React.memo(({ restaurant }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  const { data, loading, error } = useQuery(GET_ME, { errorPolicy: "ignore" });

  return (
    <>
      <Element name="overview" />
      <div className={classes.gridLayout}>
        <div className={classes.gridLayoutHero}>
          <DishImagesHero dishes={restaurant.dishes} />
        </div>
        <div className={classes.gridLayoutHeaderWidgets}>
          <div className={classes.widgetLeftLayout}>
            <Overview restaurant={restaurant} getMe={data?.getMe} />
            {isMobile && (
              <UpcomingEventsWidgetDesktop restaurantId={restaurant.id} />
            )}
            <About restaurant={restaurant} />
            <Dishes restaurant={restaurant} getMe={data?.getMe} />
            <Reviews restaurant={restaurant} getMe={data?.getMe} />
          </div>
          {!isMobile && (
            <div style={{ position: "sticky", top: 100 }}>
              <UpcomingEventsWidgetDesktop restaurantId={restaurant.id} />
            </div>
          )}
        </div>
      </div>
    </>
  );
});
