import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    padding: "2rem  2rem",
    maxWidth: 844,
    margin: "0 auto",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "92%",
      padding: "2rem 0rem",
      paddingBottom: "calc(9rem + env(safe-area-inset-bottom))",
    },
  },
  expandButton: {
    display: "inline-block",

    color: COLORS.DARK_GREY,
    textTransform: "capitalize",
  },
  cookAvatar: {
    width: 130,
    height: 130,
    marginRight: "2rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: 80,
      height: 80,
    },
  },
  cookInfoContainer: {
    display: "flex",
    flexDirection: "row",
  },
  cookTitle: {
    fontFamily: "CustomFourBold",
    marginBottom: "1rem",

    fontWeight: 500,
  },
  cookName: {
    fontFamily: "CustomFourBold",
    marginBottom: "1rem",

    fontWeight: 500,
  },
  cookBio: {
    color: "#5F5F5F",
    marginBottom: "1rem",
    whiteSpace: "pre-line",
  },
  btnContainedSecondary: {
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
  },
});
