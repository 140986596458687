import React, { FunctionComponent } from "react";
import { CreateAllergenPL } from "src/models";
import { allergenAPI } from "../api";
import { AllergenCreateComponent } from "./component";

interface IProps {
  offset?: number; // this will refech the current page's record if provided
}
export const AllergenCreateContainer: FunctionComponent<IProps> = ({
  offset,
}) => {
  const createAllergen = (d: CreateAllergenPL) =>
    allergenAPI.createAllergen(d, offset);

  return <AllergenCreateComponent createAllergen={createAllergen} />;
};
