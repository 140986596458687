import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Fade } from "react-awesome-reveal";
import { COLORS } from "src/styles";
import { SearchContainer } from "../../../components/search-container";
import { useStyles } from "./top-mobile.styles";

export const TopMobile: FC = () => {
  const classes = useStyles();

  return (
    <section
      style={{
        height: window.innerHeight,
        minHeight: window.innerHeight,
      }}
    >
      <video autoPlay muted loop playsInline className={classes.video}>
        <source
          src="https://foodnome.sfo2.digitaloceanspaces.com/videos/home.mp4"
          type="video/mp4"
        />
      </video>
      <Fade triggerOnce={true} delay={250}>
        <img
          // main_header_logo_white_outline.png used for mobile only
          alt="COOK Connect logo"
          src={require(`src/assets/logos/main_header_logo_white_outline.png`)}
          className={classes.foodnomeLogoWhite}
        />
      </Fade>
      <div className={classes.innerContainer}>
        <div className={classes.bottomSection}>
          <Fade triggerOnce={true} delay={250}>
          {/* replaced logo circle_bug-11.svg with logo_cook.png */}
            <img
              src={require("src/assets/logos/cook.png")}
              className={classes.logo}
            />
          </Fade>
          <div className={classes.textContainer}>
            <img
              src={require("src/screens/become-cook-info/assets/squig-01.png")}
              className={classes.squig}
            />
            <Fade triggerOnce={true} delay={350}>
              <Typography variant="h1" component="h1" className={classes.title}>
                Order <span style={{ color: COLORS.DARK_RED }}>Home-Cooked</span>{" "}
                Food from Local Chefs!
              </Typography>
            </Fade>
            <Fade triggerOnce={true} delay={500}>
              <Typography variant="h4" component="h4" className={classes.text}>
                Eat authentic, homemade food & support small family businesses
                in your neighborhood!
              </Typography>
            </Fade>
            <Fade triggerOnce={true} delay={650} style={{ width: "100%" }}>
              <SearchContainer />
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};
