import { useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import moment from "moment";
import React, { FC, useState } from "react";
import { IEventUser, IUser } from "src/models";
import { eventTypeMap } from "src/screens/diner/event/detail/order/helpers";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { EDIT_ARRIVAL_TIME, EDIT_ORDER_ARRIVAL_TIME } from "./api";
import { useStyles } from "./edit-arrival-time.styles";

interface IProps {
  guest: IUser;
  currentOrder: IEventUser;
}
export const EditArrivalTime: FC<IProps> = ({ guest, currentOrder }) => {
  const [open, setOpen] = useState(false);
  const [arrivalTime, setArrivalTime] = useState(
    moment(Number(currentOrder.arrivalTime)).format("HH:mm")
  );
  const classes = useStyles();
  const [editArrival] = useMutation(EDIT_ARRIVAL_TIME);
  const [editOrderArrival] = useMutation(EDIT_ORDER_ARRIVAL_TIME);

  const edit = currentOrder?.eventId ? editArrival : editOrderArrival;
  return (
    <>
      <IconButton
        onClick={() => setOpen(true)}
        className={classes.editBtn}
        size="small"
      >
        <Edit className={classes.editIcon} />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent className={classes.content}>
          <Typography
            variant="h3"
            component="h3"
            style={{ marginBottom: "2rem" }}
          >
            Change {guest.firstName}'s {eventTypeMap[currentOrder.dineOption]}{" "}
            time
          </Typography>
          <TextField
            size="small"
            id="time"
            label={`${eventTypeMap[currentOrder.dineOption]} Time`}
            type="time"
            value={arrivalTime}
            onChange={(e) => setArrivalTime(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 60 * 15, // 5 min
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="contained"
            style={{ color: COLORS.WHITE }}
            onClick={() =>
              edit({
                variables: {
                  input: {
                    arrivalTime: moment(
                      moment(Number(currentOrder.arrivalTime)).format(
                        "YYYY-MM-DD"
                      ) +
                        " " +
                        arrivalTime,
                      "YYYY-MM-DD HH:mm"
                    )
                      .valueOf()
                      .toString(),
                    id: currentOrder.id,
                  },
                },
              })
                .then(() => {
                  sharedAPI.setSnackbarMsg({ type: "success", msg: "Done" });
                  setOpen(false);
                })
                .catch((e) => {
                  sharedAPI.setSnackbarMsg({
                    type: "error",
                    msg: e.message.replace(/Graphql Error: /i, ""),
                  });
                })
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
