import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import { COLORS } from "src/styles";
import { OrderContext } from "../../order-context";
import { ActionTypes } from "../../order-reducer";
import { useStyles } from "./event-info-delivery-note-dialog.styles";

export const EventInfoDeliveryNoteDialog = () => {
  const classes = useStyles();
  const {
    dispatch,
    state: { chefNotes },
  } = useContext(OrderContext);

  const [note, setNote] = useState(chefNotes);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Typography
        variant="body2"
        component="p"
        onClick={() => setOpen(!open)}
        className={classes.container}
      >
        {chefNotes ? chefNotes : "Edit this to tell us how to find you."}{" "}
        <Edit className={classes.icon} />
      </Typography>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <OutlinedInput
            classes={{
              root: classes.inputRoot,
              input: classes.input,
            }}
            fullWidth
            labelWidth={0}
            multiline={true}
            rows={5}
            placeholder="Leave a specific delivery note to the chef."
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch({ type: ActionTypes.SET_CHEF_NOTES, payload: note });
              setOpen(false);
            }}
            variant="contained"
            color="secondary"
            style={{ color: COLORS.WHITE }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
