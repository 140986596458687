import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  rootBottomNavigationDisplayNone: {
    display: "none",
  },
  rootBottomNavigation: {
    position: "fixed",
    bottom: 0,
    zIndex: 99,
    backgroundColor: COLORS.WHITE,
    left: 0,
    right: 0,
    overflowX: "scroll",
    overflowY: "visible",
    border: "1px solid #E0E0E0",
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.09)",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "env(safe-area-inset-bottom)",
  },
  label: {
    fontWeight: 100,

    color: COLORS.BLACK,
    "&$selected": {
      color: COLORS.MEDIUM_RED,
    },
  },
  loginBtn: {
    margin: "auto",
  },
  rootAction: {
    color: COLORS.BLACK,
    minWidth: "unset",
    "&$selected": {
      color: COLORS.MEDIUM_RED,
    },
  },
  selected: {},
  avatarRoot: {
    backgroundColor: COLORS.RED,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
});
