import { makeStyles } from "@material-ui/core";
import React from "react";
import { COLORS } from "src/styles";
import { MapSearchBar } from "../../shared/map-search-bar";

export const HeaderFilterMobile = () => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <MapSearchBar TextFieldProps={{ size: "small" }} />
    </section>
  );
};

const useStyles = makeStyles({
  container: {
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translate(-50%, 0)",
    width: "60%",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    borderRadius: 5,
    padding: "2px 0",
    backgroundColor: COLORS.WHITE,
    boxShadow: "0 3px 7px 0 rgba(0,0,0,0.23)",
  },
});
