import moment from "moment";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import AppleLogin from "react-apple-login";
import { useHistory, useLocation } from "react-router";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { isEmbeddedBrowser } from "src/utils/helpers";
import { authAPI } from "./graphql";

interface IAppleAuthButtonProps {
  onSubmit?: (d?: any) => void;
  onError: (error: any) => void;
}

export const AuthWithApple: React.FC<IAppleAuthButtonProps> = ({
  children,
  onSubmit,
  onError,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [isAvailable, setIsAvailable] = useState(false);

  useEffect(() => {
    // If ENV is cordova and platform is IOS
    if (
      process.env.REACT_APP_ENV === "cordova" &&
      (window as any).cordova.platformId === "ios"
    ) {
      // If SignInWithApple cordova plugin is detected
      if (
        (window as any).cordova?.plugins &&
        (window as any).cordova?.plugins.SignInWithApple
      )
        setIsAvailable(true);
    }
    // We want to disable 3rd party logins on Instagram and Facebook In-App-Browsers because
    // they disable popups and 3rd party cookies. We don't allow Apple login on web anyways but this is here
    // for future reference anyways
    if (isEmbeddedBrowser()) setIsAvailable(false);
  }, []);
  const child = React.Children.only(children) as React.ReactElement;
  const queryObj = location
    ? (parse(location.search) as { code: string })
    : null;
  const referralCode = queryObj && queryObj.code ? queryObj.code : undefined;

  const appleSignIn = async (d: any) => {
    const { token = "", firstName = "", lastName = "", source = "" } = d;

    try {
      const res = await authAPI.apple(
        token,
        firstName,
        lastName,
        source,
        referralCode
      );
      const createdAt = moment(Number(res.data.signInWithApple.createdAt));
      const duration = moment.duration(moment().diff(createdAt));
      if (duration.asSeconds() <= 10) {
        sharedAPI.setDiscountPrompt(true);
      }
      if (onSubmit) {
        setTimeout(() => onSubmit(res.data.signInWithApple), 500);
      } else {
        history.push("/d");
      }
    } catch (error) {
      onError(`Something wrong with your Apple login. ${error}`);
    }
  };

  const nativeAppleSignIn = () => {
    sharedAPI.setGlobalLoadingState(true);
    if (
      (window as any).cordova?.plugins &&
      (window as any).cordova?.plugins.SignInWithApple
    ) {
      (window as any)?.cordova?.plugins.SignInWithApple.signin(
        { requestedScopes: [0, 1] },
        (succ) => {
          if (!!succ && !!succ.identityToken) {
            appleSignIn({
              token: succ.identityToken,
              firstName: succ.fullName.givenName,
              referralCode: localStorage.getItem("foodnome-referree-code"),
              lastName: succ.fullName.familyName,
              source: localStorage.getItem("foodnome-source"),
            }).then(() =>
              setTimeout(() => sharedAPI.setGlobalLoadingState(false), 1000)
            );
          } else {
            onError(
              `Oops! There was an error signing up with Apple. Please try again later. Code 1`
            );
            sharedAPI.setGlobalLoadingState(false);
          }
        },
        (err) => {
          // tslint:disable-next-line: no-console
          // console.error(err);
          onError(`Error while trying to login with Apple. ${err.error}`);
          sharedAPI.setGlobalLoadingState(false);
        }
      );
    }
  };

  if (!isAvailable) return null;
  return process.env.REACT_APP_ENV === "cordova" ? (
    <div onClick={nativeAppleSignIn} style={{ width: "100%" }}>
      {children}
    </div>
  ) : (
    <AppleLogin
      clientId="com.react.apple.login"
      redirectURI="https://redirectUrl.com"
      responseType={"code"}
      responseMode={"query"}
      render={(renderProps: any) =>
        React.cloneElement(child, {
          onClick: renderProps.onClick,
          disabled: renderProps.disabled,
        })
      }
      designProp={{
        height: 30,
        width: 200,
        color: COLORS.WHITE,
        border: true,
        type: "sign-in",
        border_radius: 15,
        scale: 1,
        locale: "en_US",
      }}
    />
  );
};
