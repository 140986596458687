import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    display: "none",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "block",
      color: COLORS.BLACK,
      backgroundColor: COLORS.WHITE,
    },
  },
  headerContent: {
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
    minHeight: 56,
  },

  btnContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
    marginRight: "1rem",
  },
  backArrowMobileHeader: {
    fontSize: "2.5rem",
    color: COLORS.MEDIUM_GREEN,
  },
  bookOrderTitle: {
    padding: 0,
    margin: 0,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    fontFamily: "CustomFourBold",

    fontWeight: 500,
  },
  cancelEditBtn: {
    fontFamily: "CustomFourBold",

    color: COLORS.WHITE,
    marginRight: 4,
  },
});
