import { parse, stringify } from "query-string";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { client } from "src/apollo";
import { ICreateRestaurantPL, IGetMe } from "src/models";
import { amplitude } from "src/services";
import { sharedAPI } from "src/shared-graphql";
import { GET_ME } from "src/shared-graphql/queries";
import { getBrowser, LocalStorageHelper } from "src/utils/helpers";
import { restaurantAPI } from "../api";
import { Layout } from "./layout";

export interface IProps {
  user: IGetMe | undefined;
}

export const CreateRestaurantContainer: FC<IProps> = ({ user }) => {
  const location = useLocation();
  const history = useHistory();
  const [loading, updateLoading] = useState(false);
  const [error, updateError] = useState<any>(null);

  const queryObj = parse(location.search) as { s: string };
  const device = getBrowser();

  useEffect(() => {
    amplitude
      .getInstance()
      .logEvent(`[Become Cook] form ${location.pathname}`, {
        source: queryObj.s,
      });
  }, []);

  const _createRestaurant = async (d: ICreateRestaurantPL) => {
    updateLoading(true);

    if (user) {
      return restaurantAPI
        .createRestaurant({ ...d, source: queryObj.s, device })
        .then(() =>
          history.push({
            pathname: "/home-restaurant-info",
            search: stringify({ step: "intro" }),
          })
        )
        .catch((e) => {
          sharedAPI.setSnackbarMsg({ type: "error", msg: e.message });
          updateError(e.message);
          updateLoading(false);
        });
    }

    return restaurantAPI
      .createRestaurantAndUser({ ...d, source: queryObj.s, device })
      .then(async (_d: any) => {
        if (process.env.REACT_APP_ENV === "cordova") {
          if (_d.data && _d.data.createRestaurantAndUser) {
            await LocalStorageHelper.setItem(
              "foodnome_auth_token",
              _d.data.createRestaurantAndUser.authToken
            );
          }
        }
        await client.query({
          query: GET_ME,
          fetchPolicy: "network-only",
          errorPolicy: "ignore",
        });
      })
      .then(() => {
        amplitude.getInstance().setUserId(d.email);
        history.push({
          pathname: "/home-restaurant-info",
          search: stringify({ step: "intro" }),
        });
      })
      .catch((e) => {
        sharedAPI.setSnackbarMsg({ type: "error", msg: e.message });
        updateError(e.message);
        updateLoading(false);
      });
  };

  return (
    <Layout
      error={error}
      loading={loading}
      createRestaurant={_createRestaurant}
      submitMailchimp={restaurantAPI.submitMailchimp}
      user={user}
    />
  );
};
