import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  dishCardsList: {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fill, minmax(262px, 1fr))`,
    gridGap: "20px",
    maxWidth: 858,
    width: "100%",
    margin: "0 auto",
    padding: "1rem 1rem 5rem",
    listStyleType: "none",
  },
  card: {
    boxShadow: `0 2px 10px 0 #BCBCBC`,
    borderRadius: 2,
    width: "100%",
    margin: "0 auto 5rem",
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  dishCardsWrapper: {
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "1rem 0 5rem",
    },
  },
  form: {
    height: "100%",
    flexGrow: 1,
  },
  cardHeader: {
    backgroundColor: "#F8F8F8",
  },
  cardHeaderContent: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "0 auto",
    padding: "1rem",
  },
  cardHeaderActions: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  heading: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    margin: 0,
    marginLeft: "1rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  button: {
    display: "inline-block",
    backgroundColor: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.WHITE,
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_GREEN,
      opacity: 0.7,
    },
    "&:disabled": {
      backgroundColor: COLORS.MEDIUM_GREEN,
      opacity: 0.3,
    },
  },
  createButton: {
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      "&::before": {
        marginRight: 0,
      },
    },
  },
  startIcon: {
    marginRight: 0,
    marginLeft: 0,
  },

  filterButton: {
    color: COLORS.DARK_GREY,

    textTransform: "capitalize",
    fontWeight: 100,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      boxShadow: `0 2px 9px 0 rgba(22,22,22,0.34)`,
      padding: ".5rem 1rem",
      borderRadius: 40,
      backgroundColor: COLORS.WHITE,
      "&:hover": {
        backgroundColor: COLORS.WHITE,
      },
    },
  },
  filterButtonActive: {
    color: COLORS.RED,
  },
  cardHeaderBottom: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem",
    alignItems: "center",
    width: "100%",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      position: "fixed",
      bottom: 60,
      left: 0,
      right: 0,
      zIndex: 1,
      padding: ".5rem",
      paddingBottom: "calc(0.5rem + env(safe-area-inset-bottom))",
      justifyContent: "center",
    },
  },
  backButton: {
    color: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },

  noDishesMessage: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    textAlign: "center",
    padding: "1rem",
    animation: "appear 300ms linear",
  },
});
