import { gql } from "@apollo/client";

export const DELETE_EVENT = gql`
  mutation deleteEvent($input: ID!) {
    deleteEvent(id: $input)
  }
`;

export const SET_PROMOTION = gql`
  mutation createPromotion($input: CreatePromotionPL!) {
    createPromotion(createPromotionPL: $input) {
      id
      amount
      reason
      updatedAt
      createdAt
    }
  }
`;
export const SEND_USER_REMINDER = gql`
  mutation adminSendUserReminder($input: ID!) {
    adminSendUserReminder(id: $input)
  }
`;

export const SEND_REVIEWS_TO_GUESTS = gql`
  mutation adminRecreateReviews($input: ID!) {
    adminRecreateReviews(id: $input)
  }
`;

export const DELETE_PROMOTION = gql`
  mutation deletePromotion($input: ID!) {
    deletePromotion(id: $input)
  }
`;

export const DELETE_DISH_EVENT = gql`
  mutation adminDeleteDishEvent($input: ID!) {
    adminDeleteDishEvent(id: $input)
  }
`;

export const CREATE_UPDATE_DISH_EVENT = gql`
  mutation adminCreateUpdateDishEvent($input: AdminCreateUpdateDishEventPL!) {
    adminCreateUpdateDishEvent(createUpdateDishEventPL: $input) {
      id
      eventId
      dishId
      price
      count
      sold
    }
  }
`;

export const ADMIN_UPDATE_EVENT = gql`
  mutation adminUpdateEvent($input: EventUpdatePL!) {
    adminUpdateEvent(eventUpdatePL: $input) {
      id
      name
      status
      imagesGQL {
        publicId
        small
        medium
        large
        original
      }
      locale {
        public
        private
      }
      description
    }
  }
`;

export const ADMIN_COMPLETE_EVENT = gql`
  mutation adminCompleteEvent($input: ID!) {
    adminCompleteEvent(id: $input) {
      id
      name
      status
      imagesGQL {
        publicId
        small
        medium
        large
        original
      }
      locale {
        public
        private
      }
      startTime
      endTime
      description
    }
  }
`;

export const ADMIN_CANCEL_EVENT_USER = gql`
  mutation adminUpdateEventUser($input: AdminUpdateEventUserPL!) {
    adminUpdateEventUser(adminUpdateEventUserPL: $input) {
      id
      status
    }
  }
`;
