import React, { FC } from "react";
import { LoginForm } from "./form";
import { useStyles } from "./layout.styles";

interface IProps {
  login: (d: { email: string; password: string }) => void;
  switchView?: () => void; // need this for the dialog user-account
  setError: (d: any) => void;
  onSubmit?: (d?: any) => void;
  closeDialog?: () => void;
  error: any;
  email?: string;
  fromVerify?: boolean;
  containerStyle?: React.CSSProperties;
}

export const Layout: FC<IProps> = ({
  switchView,
  login,
  error,
  onSubmit,
  setError,
  fromVerify,
  closeDialog,
  email,
  containerStyle = {},
}) => {
  const classes = useStyles();
  return !fromVerify ? (
    <div className={classes.container}>
      <div className={classes.image} />
      <div className={classes.formWrapper}>
        <LoginForm
          login={login}
          error={error}
          email={email}
          setError={setError}
          switchView={switchView}
        />
      </div>
    </div>
  ) : (
    <LoginForm
      login={login}
      email={email}
      error={error}
      setError={setError}
      switchView={switchView}
      onSubmit={onSubmit}
      containerStyle={containerStyle}
      closeDialog={closeDialog}
    />
  );
};
