import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const mobileStyles = makeStyles({
  container: {
    width: "100%",
    maxWidth: 300,
    minWidth: 260,
    boxShadow: "0px 2px 10px #BCBCBC",
  },
  imageContainer: {
    maxWidth: 90,
    height: 90,
    width: "100%",
    overflow: "hidden",
    padding: 10,
  },
  image: {
    backgroundRepeat: "no-repeat",
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: 10,
  },
  sectionTop: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    borderBottom: "1px solid #E7E5E5",
    boxSizing: "border-box",
  },
  eventTitle: {
    color: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",

    fontWeight: 500,

    margin: 0,
    overflow: "hidden",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
  },
  price: {
    color: COLORS.BLACK,
    fontFamily: "CustomFourBold",

    fontWeight: 500,

    margin: 0,
  },
  subText: {
    fontFamily: "CustomFourBold",

    fontWeight: 500,

    margin: "10px 0 0",
    color: COLORS.BLACK,
  },
  dateAndType: {
    color: "#737373",

    margin: 0,
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 5,
  },
  buttonRoot: {
    width: 120,
    margin: "10px 0",
    borderRadius: "2px",
    backgroundColor: COLORS.MEDIUM_RED,
    color: COLORS.WHITE,
    boxShadow: "-1px 2px 4px 0 rgba(31,64,80,0.41)",
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_GREEN,
      color: COLORS.WHITE,
    },
  },
  buttonLabel: {
    fontWeight: 100,
  },
  numOfOrders: {
    fontFamily: "CustomFourBold",
    color: COLORS.RED,
  },
  bold: {
    fontFamily: "CustomFourBold",
    color: COLORS.BLACK,
  },
  flexRow: {
    display: "flex",
  },
});
