import { makeStyles, Typography } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { COLORS } from "src/styles";
import { isEmbeddedBrowser } from "src/utils/helpers";

interface IProps {
  children: any;
  disableIfEmbeddedBrowser?: boolean;
}

export const DecoratedText: FC<IProps> = ({
  children,
  disableIfEmbeddedBrowser = true,
}) => {
  const classes = useStyles();
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (disableIfEmbeddedBrowser) {
      if (isEmbeddedBrowser()) setShow(false);
    }
  }, []);

  if (!show) return null;

  return (
    <div className={classes.decoratedTextContainer}>
      <div className={classes.dividerContainer}>
        <div className={classes.divider} />
      </div>

      <Typography component="p" variant="caption" className={classes.children}>
        {children}
      </Typography>

      <div className={classes.dividerContainer}>
        <div className={classes.divider} />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  decoratedTextContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    margin: "1rem 0",
  },
  dividerContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  divider: {
    width: "100%",

    height: 1,
    backgroundColor: COLORS.LIGHT_GREY_1,
  },
  children: {
    color: COLORS.DARK_GREY,
    paddingBottom: "1.5%",
    margin: "0 1rem",
  },
});
