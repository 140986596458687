import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  noChatContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.MEDIUM_GREY,
    padding: "20px",
    maxHeight: 180,
    flexGrow: 1,
  },
  bagImage: {
    height: 50,
    margin: "0 auto",
  },
});
