import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${require("./assets/plates.jpg")})`,
  },
  card: {
    backgroundColor: "rgba(255,255,255,0.78)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem",
    width: "100%",
  },
  contentWrapper: {
    maxHeight: 600,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginTop: -64,
  },
  mainHeading: {
    color: COLORS.DARK_BLUE,
    textAlign: "center",
  },
  subHeading: {
    color: COLORS.DARK_BLUE,
    textAlign: "center",
  },
  image: { width: "50vw", maxWidth: 200 },
  rootButton1: {
    fontWeight: 600,

    backgroundColor: "#F8675B",
    color: COLORS.WHITE,
    borderRadius: 2,

    "&:hover": {
      backgroundColor: "#F8675B",
    },
  },
  rootButton2: {
    fontWeight: 600,

    backgroundColor: COLORS.WHITE,
    color: "#F8675B",
    borderRadius: 2,
    "&:hover": {
      backgroundColor: COLORS.WHITE,
    },
  },
  buttonsWrapper: {
    height: "100%",
    maxHeight: "7rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    width: "100%",
    flexGrow: 1,
  },
});
