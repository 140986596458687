import axios from "axios";
import { SERVER_ADDRESS } from "src/apollo";

export const marketingAPI = {
  getTextMsgs: () =>
    axios.get(`${SERVER_ADDRESS}/crm/get-text-msgs`, { withCredentials: true }),
  getTextMsg: (id: string | number) =>
    axios.get(`${SERVER_ADDRESS}/crm/get-text-msgs/${id}`, {
      withCredentials: true,
    }),
  setTextMsg: (id: string | number, value: string) =>
    axios.post(
      `${SERVER_ADDRESS}/crm/set-text-msg/${id}`,
      { value },
      { withCredentials: true }
    ),
  testTextMsg: (id: string | number) =>
    axios.get(`${SERVER_ADDRESS}/crm/test-text-msg/${id}`, {
      withCredentials: true,
    }),
};
