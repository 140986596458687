import { useQuery } from "@apollo/client";
import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { useLocation } from "react-router-dom";
import { GET_ME } from "src/shared-graphql/queries";
import { BREAK_POINTS } from "src/styles";
import { hideMainHeader, noMarginTop } from "src/utils/helpers";
import { Layout } from "./layout";

export const HeaderContainer = () => {
  const { data } = useQuery(GET_ME);
  const location = useLocation();
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

  if (hideMainHeader({ pathname: location.pathname, isMobile })) return null;
  return (
    <Layout user={data?.getMe} noMarginTop={noMarginTop(location.pathname)} />
  );
};
