import { makeStyles } from "@material-ui/core/styles";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    // backgroundColor: "#F9F9F9",
    backgroundColor: COLORS.WHITE,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      paddingBottom: "calc(250px + env(safe-area-inset-bottom))",
    },
  },
  infoContainers: {
    // Targets every other element to have the background color
    "& > section:nth-child(odd)": { backgroundColor: "#F9F9F9" },
  },
  disabled: {
    pointerEvents: "none",
  },
  headerBackground: {
    backgroundColor: COLORS.WHITE,
    width: "100%",
    height: "120px",
    display: "flex",
    alignItems: "center",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },

  headerContainer: {
    display: "flex",
    width: "96%",
    maxWidth: "600px",
    margin: " 0 auto",
  },
  backLink: {
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  mobileLabelContainer: {
    display: "none",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "1rem 0",
      backgroundColor: COLORS.WHITE,

      fontWeight: 100,
      textAlign: "center",
      display: "block",

      margin: "auto",
    },
  },

  backArrow: { color: COLORS.MEDIUM_GREEN, fontSize: "2.5rem" },

  sectionContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "96%",
    maxWidth: "600px",
    margin: "50px auto 10px auto",
  },
  locationContainer: {
    width: "100%",
    height: "120px",
  },
  icon: {
    color: COLORS.DARK_GREY,
    verticalAlign: "middle",
    ...theme.typography.h3,
    marginBottom: 0,
  },
  title: {
    fontFamily: "CustomFourBold",
    margin: 0,

    fontWeight: 500,
  },
  description: {
    fontWeight: 100,

    color: COLORS.DARK_GREY,
  },
  deliveryDescription: {
    fontWeight: 100,

    display: "flex",
    alignItems: "center",
    color: COLORS.MEDIUM_GREY,
  },
  deliveryEditIcon: {
    color: COLORS.MEDIUM_GREEN,
  },
  avatar: {
    width: 80,
    height: 80,
    borderColor: COLORS.WHITE,
    borderStyle: "solid",
    textTransform: "capitalize",
    backgroundColor: COLORS.RED,
    color: COLORS.WHITE,
  },
  hostName: {
    fontFamily: "CustomFourBold",

    textTransform: "capitalize",
  },
  viewCookLink: {
    textDecoration: "none",
    color: COLORS.MEDIUM_GREEN,

    fontWeight: 100,
  },
  hostInfoContainer: {
    padding: "10px",
  },
  hostContainer: {
    width: "94%",
    display: "flex",
  },
  addPaymentBtn: {
    textTransform: "none",
  },
  paymentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  circularProgress: {
    height: "26px !important",
    width: "26px !important",
    color: COLORS.WHITE,
    marginTop: 4,
  },
  placeOrderContainer: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: 0,
  },
  totalContainer: {
    backgroundColor: "#F9F9F9",
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    minHeight: "80px",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      minHeight: "unset",
      padding: "8px 22px",
    },
  },
  total: {
    fontFamily: "CustomFourBold",
  },
  totalAmount: {
    color: COLORS.RED,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  placeOrderBtnRoot: {
    padding: ".7rem",
    paddingBottom: "calc(.7rem + env(safe-area-inset-bottom))",
    borderRadius: 0,
  },
  placeOrderBtnContainedSecondary: {
    color: COLORS.WHITE,
  },
  customEventInfoSectionContainer: {
    display: "grid",
    gridTemplateColumns: "30px 1fr",
    margin: "0 auto",
    maxWidth: 600,
    width: "100%",
    padding: "1rem 0.5rem",
  },
  customEventInfoSectionIconWrapper: {
    gridColumn: "1 / 2",

    textAlign: "center",
    marginRight: ".5rem",
  },
  customEventInfoSectionTitle: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    margin: 0,
  },
  customEventInfoSectionDescription: {
    fontWeight: 100,
  },
  customEventInfoSectionTitleWithButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  changeButtonLabel: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  textSection: {
    display: "grid",
    gridTemplateColumns: "1fr",
    margin: "0 auto",
    maxWidth: 600,
    width: "100%",
    padding: "10px 30px 10px 15px",
  },
  time: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    color: COLORS.BLACK,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
