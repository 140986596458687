import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    width: "100%",
  },
  innerContentContainer: {
    maxWidth: "140rem",
    margin: "0 auto",
    padding: "0 4rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "0 2rem",
    },
  },
  color1: {
    backgroundColor: "#FFF8F8",
  },
  color2: {
    backgroundColor: COLORS.WHITE,
  },
  footer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    overflow: "hidden",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      paddingBottom: "calc(5rem + env(safe-area-inset-bottom))",
    },
  },
  footerLayout: {
    width: "100%",
    margin: "0 auto",
  },
});
