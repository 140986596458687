import React, { FC } from "react";

interface IProps {
  href: string;
  children: React.ReactNode;
  className?: any;
  ariaLabel?: string;
}

declare let window: any;

// Used for cordova

export const HybridLink: FC<IProps> = ({
  href,
  children,
  className,
  ariaLabel,
}) => {
  if (window.cordova) {
    return (
      <a
        rel="noopener noreferrer"
        className={className}
        onClick={() =>
          window.cordova.InAppBrowser.open(href, "_system", "location=yes")
        }
        aria-label={ariaLabel}
      >
        {children}
      </a>
    );
  }

  return (
    <a
      href={href}
      target="_blank"
      className={className}
      rel="noopener noreferrer"
      aria-label={ariaLabel}
    >
      {children}
    </a>
  );
};
