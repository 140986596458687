import { Button, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Fade } from "react-awesome-reveal";
import { IGetMe } from "src/models";
import { CreateRestaurantContainer } from "src/screens/become-cook/form";
import { useStyles } from "./apply.styles";

interface IProps {
  getMe: IGetMe;
}

export const ApplyForCookConnect: FC<IProps> = ({ getMe }) => {
  const classes = useStyles();

  return (
    <section className={classes.container} id="become-cook-form">
      <div className={classes.innerContainer}>
        <div className={classes.mainContent}>
          <Fade triggerOnce={true} delay={450}>
            <div className={classes.leftContainer}>
              <Typography variant="h1" component="h2" className={classes.title}>
                Register for COOK Connect now to start cooking?
              </Typography>
              <Typography variant="h2" component="h3" className={classes.text}>
                Launching your own food business has never been easier. Register
                your interest for COOK Academy to get started.
              </Typography>
              <div className={classes.formContainer}>
                <CreateRestaurantContainer user={getMe} />
              </div>
            </div>
          </Fade>
          <Fade triggerOnce={true} delay={650}>
            <img
              className={classes.image}
              alt="foodnome graphic"
              src={require(`src/screens/become-cook/assets/FRAME3.png`)}
            />
          </Fade>
        </div>
      </div>
    </section>
  );
};
