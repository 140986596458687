import { gql } from "@apollo/client";

export const DINER_UPSERT_RESTAURANT_GUEST = gql`
  mutation dinerUpsertRestaurantUser($input: DinerUpsertRestaurantUserPL!) {
    dinerUpsertRestaurantUser(dinerUpsertRestaurantUserPL: $input) {
      id
      restaurantId
      userId
      status
      isFollowing
      updatedAt
      createdAt
    }
  }
`;
