import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import React, { FC, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { StepperButtons } from "src/components/event-card-three/buttons/buttons";
import { IRestaurant } from "src/models";
import { useStyles } from "./swipeable-img.styles";

interface IProps {
  restaurant: IRestaurant;
  isHovered: boolean;
}

export const SwipeableImages: FC<IProps> = ({ restaurant, isHovered }) => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  // const images = restaurant.dishes.map((dish) => dish.imagesGQL[0]);
  // This is if we want to limit the amount of photos showing. May be useful
  const images = restaurant.dishes.slice(0, 7).map((dish) => dish.imagesGQL[0]);

  const handleSetIndex = (idx: number) => setIndex(idx);
  const onClickRight = (e) => {
    e.stopPropagation();
    setIndex((prev) => (prev + 1) % images.length);
  };
  const onClickLeft = (e) => {
    e.stopPropagation();
    setIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  return (
    <>
      <SwipeableViews
        index={index}
        onChangeIndex={handleSetIndex}
        enableMouseEvents
        ignoreNativeScroll
        className={classes.carouselRoot}
      >
        {images.map((image) => (
          <img
            key={image.publicId}
            className={classes.image}
            src={image.medium}
            alt={`dish from ${restaurant.name}`}
          />
        ))}
      </SwipeableViews>
      <div className={classes.stepperButtonLayout}>
        <ul className={classes.stepperDotsContainer}>
          {images.map((image, idx) => (
            <li
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                setIndex(idx);
              }}
              className={classes.dotContainer}
              key={image.publicId}
            >
              <FiberManualRecordIcon
                style={{ fontSize: "0.7rem" }}
                className={index === idx ? classes.dotActive : classes.dot}
              />
            </li>
          ))}
        </ul>
      </div>

      <StepperButtons
        isHovered={isHovered}
        onClickLeft={onClickLeft}
        onClickRight={onClickRight}
        isMoreThanOne={images.length > 1}
      />
    </>
  );
};
