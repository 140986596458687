import { Typography } from "@material-ui/core";
import moment from "moment";
import { parse } from "query-string";
import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { IEvent } from "src/models";
import { COLORS } from "src/styles";
import { formatDateShort, truncateText } from "src/utils/helpers";
import { parseEventType } from "../../detail/order/helpers";
import { eventCardStyles as useStyles } from "./events-card.styles";

interface IProps {
  event: IEvent;
}

export const EventsCard: FC<IProps> = ({ event }) => {
  const classes = useStyles();
  const location = useLocation();
  const { eventUser } = event;

  const { type = "" } = parse(location.search) as { type: string };

  if (!event.eventUser) return null;
  return (
    <Link
      style={{ textDecoration: "none" }}
      to={`${/^saved$/i.test(type) ? "/menus" : "/d/order"}/${
        event.id
      }-${event.name.replace(/ /g, "-")}`}
    >
      <div className={classes.container}>
        <div className={classes.imageContainer}>
          <img
            className={classes.image}
            src={event.imagesGQL?.length ? event.imagesGQL[0].medium : ""}
            alt="event-image"
          />
        </div>
        <div className={classes.detailsContainer}>
          <div className={classes.sectionTop}>
            <div className={classes.titleRow}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.eventTitle}
                >
                  {truncateText(event.name, 20)}
                </Typography>
              </div>
              <div>
                {eventUser && eventUser.paidAmount ? (
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.eventTitle}
                    style={{ color: COLORS.BLACK }}
                  >
                    ${eventUser.paidAmount}
                  </Typography>
                ) : null}
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <Typography
                variant="body2"
                component="p"
                className={classes.dateAndType}
              >
                {formatDateShort(event.startTime)}
              </Typography>
              <Typography
                variant="body2"
                component="p"
                className={classes.dateAndType}
              >
                {parseEventType(
                  eventUser && eventUser.dineOption,
                  event.type[0]
                )}
              </Typography>
              <div className={classes.flexRow}>
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.bold}
                >
                  Ordered on:{" "}
                  <Typography
                    variant="body2"
                    component="time"
                    className={classes.dateAndType}
                  >
                    {moment(Number(event.eventUser.createdAt)).format("LLL")}
                  </Typography>
                </Typography>
              </div>
            </div>
          </div>
          {/* <div style={{ width: "100%" }}>
            <OrderItems event={event} currentOrder={event.eventUser} />
          </div> */}
        </div>
        {Boolean(event?.eventUser?.orders?.length) && (
          <Typography
            component="p"
            variant="body2"
            className={classes.numOfOrders}
          >
            You have {event?.eventUser?.orders?.length} more orders
          </Typography>
        )}
      </div>
    </Link>
  );
};
