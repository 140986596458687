import isEqual from "lodash/isEqual";
import React, { useEffect, useRef, useState } from "react";

export default function useTopViewport(ref: React.MutableRefObject<any>) {
  const [isTop, setIsTop] = useState(false);
  const depsRef = useRef<any>();
  const deps = [ref, isTop];

  useEffect(() => {
    if (isEqual(depsRef.current, deps)) return;

    function scrollHandler() {
      const rect = ref.current.getBoundingClientRect();
      return setIsTop(rect.top > 0 && rect.top < 150);
    }

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  });

  useEffect(() => {
    depsRef.current = [ref];
  });

  return isTop;
}
