import React, {
  createContext,
  Dispatch,
  FC,
  useEffect,
  useReducer,
} from "react";
import { withRouter } from "react-router";
import { LocalStorageHelper } from "src/utils/helpers";
import {
  ActionTypes,
  ComponentActions,
  initialState,
  IState,
  reducer,
} from "./reducer";

const Context = createContext<{
  state: IState;
  dispatch: Dispatch<ComponentActions>;
}>({} as any);

interface IProps {
  history: any;
}
const _Provider: FC<IProps> = (props) => {
  const { children, history } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let notificationMethods = false;

    if ((window as any).IonicDeeplink)
      // tslint:disable-next-line: no-console
      (window as any).IonicDeeplink.route(
        {
          "/999": {
            target: "999",
            parent: "999",
          },
        },
        () => null,
        (nomatch) => {
          // tslint:disable-next-line: no-console
          if (
            nomatch.$link.queryString &&
            nomatch.$link.queryString?.includes("s=")
          ) {
            LocalStorageHelper.setItem(
              "foodnome-source",
              nomatch.$link.queryString?.split("s=")[1]
            );
          }
          return history.push(nomatch.$link.path);
        }
      );

    if ((window as any).plugins && (window as any).plugins.OneSignal) {
      const OneSignal = (window as any).plugins.OneSignal;

      dispatch({ type: ActionTypes.SET_ONE_SIGNAL, payload: OneSignal });

      const iosSettings = {
        kOSSettingsKeyAutoPrompt: false,
        kOSSettingsKeyInAppLaunchURL: true,
      };

      OneSignal.startInit(process.env.REACT_APP_ONE_SIGNAL)
        .handleNotificationReceived((jsonData) => {
          // alert("Notification received: \n" + JSON.stringify(jsonData));
          // tslint:disable-next-line: no-console
          console.log(
            "Did I receive a notification: " + JSON.stringify(jsonData)
          );
        })
        .handleNotificationOpened((jsonData) => {
          if (
            jsonData &&
            jsonData.notification.payload &&
            jsonData.notification.payload.additionalData &&
            jsonData.notification.payload.additionalData.redirect_url
          ) {
            history.push(
              jsonData.notification.payload.additionalData.redirect_url
            );
          }
        })
        // .inFocusDisplaying(OneSignal.OSInFocusDisplayOption.InAppAlert)
        .iOSSettings(iosSettings)
        .endInit();
      if (!notificationMethods) {
        notificationMethods = true;
        OneSignal.addSubscriptionObserver((stateChanges) => {
          // tslint:disable-next-line: no-console
          console.log(
            "Push subscription state changed: " +
              JSON.stringify(stateChanges, null, 2)
          );
        });
        OneSignal.addPermissionObserver((stateChanges) => {
          // tslint:disable-next-line: no-console
          console.log(
            "Push permission state changed: " +
              JSON.stringify(stateChanges, null, 2)
          );
        });
      }
      OneSignal.getIds((id) =>
        dispatch({ type: ActionTypes.SET_USER_ID, payload: id })
      );
    }
  }, []);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

const Provider = withRouter(_Provider);
export { Provider, Context };
