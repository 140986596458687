import { Button, IconButton, LinearProgress } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React, { useContext } from "react";
import { IFlowContent } from "../../content";
import { BecomeCookContext } from "../../store/become-cook-context";
import { useStyles } from "./linear-progress.styles";

interface IProps {
  goForward: (otherInformation) => void;
  goBack: () => void;
  stepIndex: number;
  display: IFlowContent[];
}

export const LinearProgressContainer: React.FC<IProps> = ({
  goForward,
  goBack,
  stepIndex,
  display,
}) => {
  const classes = useStyles();
  const { state, dispatch } = useContext(BecomeCookContext);

  return (
    <section className={classes.linearProgressContainer}>
      <div className={classes.actionButtonsContainer}>
        <div>
          {stepIndex !== 0 && (
            <IconButton
              data-testid="BecomeCookInfo-backButton"
              onClick={goBack}
              size="small"
            >
              <ChevronLeft className={classes.backArrow} />
            </IconButton>
          )}
        </div>

        <Button
          data-testid="BecomeCookInfo-nextButton"
          classes={{
            contained: classes.nextButton,
          }}
          size="large"
          variant="contained"
          color="secondary"
          onClick={goForward}
        >
          {state.buttonText}
        </Button>
      </div>
      <LinearProgress
        color="secondary"
        variant="determinate"
        value={Math.floor((stepIndex / display.length) * 100)}
        classes={{
          root: classes.linearProgressBar,
        }}
      />
    </section>
  );
};
