import { Button, Card, CardContent, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import classNames from "classnames";
import { parse } from "query-string";
import React, { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { IDish, IEvent } from "src/models";
import { amplitude } from "src/services";
import { COLORS } from "src/styles";
import { DishItemDialog } from "../dish-dialog";
import "./animations.css";
import { useDishItemStyles } from "./dish-item.styles";

interface IProps {
  getEvent: IEvent;
  dish: IDish;
  canNotEdit: boolean;
  displayPrice: boolean;
}

export const DishItem: FC<IProps> = React.memo(
  ({ dish, canNotEdit, displayPrice, getEvent }) => {
    const classes = useDishItemStyles();
    const variation = "var-2";
    const location = useLocation();
    const queryObj = parse(location.search);
    const image =
      dish.imagesGQL && dish.imagesGQL[0] ? dish.imagesGQL[0].medium : "";

    useEffect(() => {
      if (variation) {
        amplitude
          .getInstance()
          .logEvent(`[Event Detail] View Order Button ${variation}`, {
            source: queryObj.s,
          });
      }
    }, [variation]);

    const onClick = (disabled, setOpen) => {
      amplitude
        .getInstance()
        .logEvent(`[Event Detail] click dish dialog`, { name: dish.name });

      // if (!disabled) {
      // We want users to still be able to look at dishes event when not available anymore
      setOpen();
    };

    const Content: FC<any> = ({ setOpen, disabled }) => (
      <Card
        data-testid={`DishItem-${dish.id}`}
        className={classes.dishContainer}
        onClick={() => onClick(disabled, setOpen)}
      >
        {!getEvent.type.includes("FIXED_TIME") &&
          dish.dishLeft <= 5 &&
          dish.dishLeft > 0 && (
            <div className={classes.dishesLeftWrapper}>
              <Typography
                variant="body2"
                component="p"
                className={classes.dishesLeft}
              >
                {dish.dishLeft} left
              </Typography>
            </div>
          )}
        <CardContent classes={{ root: classes.cardContent }}>
          <div className={classes.titleContainer}>
            <Typography
              variant="body2"
              component="p"
              className={classNames(classes.name)}
            >
              {dish.name}
            </Typography>
          </div>
          <div style={{ flexGrow: 1 }}>
            <Typography
              variant="body2"
              component="p"
              className={classes.description}
            >
              {dish.description}
            </Typography>
          </div>
          {!!displayPrice && (
            <Typography
              variant="body2"
              component="aside"
              className={classes.price}
            >
              ${dish.DishEvent?.price.toFixed(2)}
            </Typography>
          )}
        </CardContent>
        <div className={classes.dishImageWrapper}>
          <img src={image} alt={dish.name} className={classes.dishImage} />
          <CSSTransition
            unmountOnExit
            timeout={1000}
            classNames="fade-in"
            in={dish.dishLeft <= 0 && !getEvent.type.includes("FIXED_TIME")}
          >
            <div className={classes.soldOutWrapper}>
              <Typography
                variant="subtitle1"
                component="p"
                className={classes.soldOutText}
              >
                Sold Out
              </Typography>
            </div>
          </CSSTransition>
        </div>
        {!canNotEdit && (
          // <div
          //   className={
          //     disabled ? classes.iconContainerDisabled : classes.iconContainer
          //   }
          //   style={{}}
          // >
          //   <Typography variant="subtitle2" component="p">
          //     <Add
          //       className={
          //         disabled ? classes.plusIconDisabled : classes.plusIcon
          //       }
          //     />
          //     ADD
          //   </Typography>
          // </div>
          <Button
            variant="contained"
            disabled={disabled}
            disableRipple
            style={
              // @ts-ignore
              variation === "var-1"
                ? { color: COLORS.MEDIUM_GREEN }
                : { color: COLORS.MEDIUM_RED }
            }
            startIcon={<Add />}
            classes={{ root: classes.iconContainer }}
          >
            ADD
          </Button>
        )}
      </Card>
    );

    return (
      <DishItemDialog dish={dish} getEvent={getEvent}>
        <Content
          disabled={!getEvent.type.includes("FIXED_TIME") && !dish.dishLeft}
        />
      </DishItemDialog>
    );
  }
);
