import { DialogContent, IconButton, styled } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import { ResponsiveDialog } from "src/components";
import { dinerPixel, ReactPixel } from "src/index";
import { LoginContainer } from "src/screens/login/container";
import { SignUpContainer } from "src/screens/sign-up/container";
import { amplitude } from "src/services";
import { COLORS } from "../../styles/colors";
import { AuthWithView } from "./auth-with";
import { NameForm } from "./name-form";

export enum DialogViews {
  LOGIN_VIEW = "LOGIN_VIEW",
  AUTH_WITH_VIEW = "AUTH_WITH_VIEW",
  NAME_VIEW = "NAME_VIEW",
  SIGN_UP_VIEW = "SIGN_UP_VIEW",
}

interface IState {
  view: DialogViews;
  isDialogOpen: boolean;
  email: string;
  firstName: string;
  lastName: string;
  address: string;
  password: string;
  isLoading: boolean;
  error: string;
}

export const GuestCheckoutHOC: any = (Component) => {
  class HOC extends React.Component<any, IState> {
    constructor(props) {
      super(props);
      this.state = {
        view: DialogViews.AUTH_WITH_VIEW,
        isDialogOpen: false,
        email: "",
        firstName: "",
        lastName: "",
        address: "",
        password: "",
        isLoading: false,
        error: "",
      };
    }

    onCloseDialog = () =>
      this.setState({
        firstName: "",
        lastName: "",
        email: "",
        isDialogOpen: false,
        error: "",
      });

    setLoadingState = (isLoading: boolean) =>
      this.setState({ isLoading, error: "" });

    onChangeEmail = (event) => this.setState({ email: event.target.value });

    handleSubmitEmail = (event) => {
      event.preventDefault();
      this.setState({ view: DialogViews.NAME_VIEW, error: "" });
    };

    _handleCheckoutAsGuest = (event) => {
      event.preventDefault();
      if (!this.state.password) {
        if (
          confirm(
            `A temporary password will be assigned and emailed to ${this.state.email}.`
          )
        ) {
          return this.handleCheckoutAsGuest(event);
        } else {
          return;
        }
      } else {
        this.handleCheckoutAsGuest(event);
      }
    };

    _handleSubmit = () => {
      return this.props
        .handleSubmit()
        ?.then(() => this.setState({ isDialogOpen: false }));
    }; // waiting for user to verify address

    handleCheckoutAsGuest = (event) => {
      if (this.props.dialogCallback) {
        this.setLoadingState(true);
        this.props
          .dialogCallback({
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: this.state.password,
            address: this.state.address,
          })
          .then(this._handleSubmit)
          .catch((error) => {
            amplitude.getInstance().logEvent("[Guest Checkout] error", {
              error: error.message,
            });
            this.setState({
              error: error.message,
              isLoading: false,
            });
          });
      }
    };

    handleInputChange = (event) =>
      this.setState({
        [event.target.name]: event.target.value,
      } as any);

    switchView = (view: DialogViews) => this.setState({ view });

    renderDialogContent = (view: DialogViews) => {
      switch (view) {
        case DialogViews.LOGIN_VIEW:
          return (
            <LoginContainer
              containerStyle={{ minHeight: "100%" }}
              switchView={() => this.switchView(DialogViews.SIGN_UP_VIEW)}
              fromVerify={true}
              email={this.state.email}
              onSubmit={this._handleSubmit}
            />
          );
        case DialogViews.SIGN_UP_VIEW:
          return (
            <SignUpContainer
              switchView={() => this.switchView(DialogViews.LOGIN_VIEW)}
              fromVerify={true}
              onSubmit={async (d) => {
                if (this.props.dialogCallback) {
                  await this.props.dialogCallback(d);
                }
                process.env.NODE_ENV !== "test" &&
                  ReactPixel.trackSingle(dinerPixel, "CompleteRegistration", {
                    value: 0,
                    currency: "USD",
                  });
                this.setState({ isDialogOpen: false });
              }}
            />
          );
        case DialogViews.AUTH_WITH_VIEW:
          return (
            <AuthWithView
              onSubmit={this._handleSubmit}
              onChangeEmail={this.onChangeEmail}
              handleSubmitEmail={this.handleSubmitEmail}
              email={this.state.email}
              switchView={() => this.switchView(DialogViews.LOGIN_VIEW)}
            />
          );
        case DialogViews.NAME_VIEW:
          return (
            <NameForm
              onSubmit={this._handleCheckoutAsGuest}
              requireAddress={this.props.requireAddress}
              firstName={this.state.firstName}
              address={this.state.address}
              lastName={this.state.lastName}
              handleInputChange={this.handleInputChange}
              password={this.state.password}
              isLoading={this.state.isLoading}
              onClickBack={() =>
                this.setState({ view: DialogViews.AUTH_WITH_VIEW })
              }
            />
          );
        default:
          throw new Error(
            `this.renderDialogContent called w/ an unknown view - "${view}"`
          );
      }
    };

    render() {
      return (
        <>
          <ResponsiveDialog
            data-testid="guest-checkout-dialog"
            BackdropProps={{
              style: { backgroundColor: `rgba(255, 255, 255, 0.6)` },
            }}
            fullWidth
            maxWidth="sm"
            scroll="body"
            open={this.state.isDialogOpen}
            onClose={this.onCloseDialog}
            onExited={() => this.setState({ view: DialogViews.AUTH_WITH_VIEW })}
          >
            <IconButton
              style={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}
              onClick={this.onCloseDialog}
              aria-label="close"
              data-testid="close-auth-dialog"
            >
              <Close style={{ color: COLORS.MEDIUM_GREEN }} />
            </IconButton>
            <StyledDialogContent>
              {this.renderDialogContent(this.state.view)}
            </StyledDialogContent>
            {this.state.error && (
              <Link
                to="/get-reset-token"
                style={{
                  textAlign: "center",
                  padding: "1em",
                  color: COLORS.RED,
                }}
              >
                {this.state.error.replace(/^graphql error: /i, "👋")}
              </Link>
            )}
          </ResponsiveDialog>
          <Component
            {...this.props}
            openGuestCheckout={() => this.setState({ isDialogOpen: true })}
          />
        </>
      );
    }
  }
  return HOC;
};

export const StyledDialogContent = styled(DialogContent)({
  root: {
    margin: "0 auto",
    padding: 0,
  },
});
