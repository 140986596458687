import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: { height: "calc(100vh - 56px)", display: "flex" },
  searchContainer: {
    boxShadow: " 0 2px 12px 0 rgba(0,0,0,0.16)",
    backgroundColor: COLORS.WHITE,
    padding: "2rem 4rem",
  },

  locationIcon: {
    color: COLORS.MEDIUM_GREEN,
    fontSize: "2.5rem",
  },
  searchBar: {
    border: "1px solid #CCCED1",
    borderRadius: "0.5rem",

    boxShadow: "0 2px 5px 0 rgba(0,0,0,0.23)",
  },
});
