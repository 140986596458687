import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    backgroundColor: COLORS.WHITE,
  },
  innerContainer: {
    backgroundColor: COLORS.WHITE,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    maxWidth: "140rem",
    margin: "9rem auto",
    marginBottom: "11rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      margin: "5rem auto",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginBottom: "2rem",
    },
  },
  squiggleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
  },
  squiggle: {
    width: "60%",
    minWidth: "12rem",
    maxWidth: "18rem",
  },
  title: {
    fontFamily: "CustomFourBold",

    textAlign: "center",
  },
  searchMealsButton: {
    marginTop: "5rem",

    padding: "2rem 11rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },

  iconContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  iconTitle: {
    marginBottom: "1rem",
    fontFamily: "CustomFourBold",

    color: COLORS.MEDIUM_RED,
  },
  iconText: {
    color: COLORS.BLACK,
    fontFamily: "CustomFourMedium",
    textAlign: "center",
  },
  card: {
    boxShadow: "0 2px 24px 0 rgba(0,0,0,0.26)",
    borderRadius: "1rem",
    backgroundColor: COLORS.WHITE,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "3rem",
    maxWidth: "35rem",
    margin: "1.5rem",
  },
});
