import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import React, { FC, useState } from "react";
import { IEvent } from "src/models";
import { eventAPI } from "../../api";
import { useStyles } from "./edit-dialog.styles";

interface IProps {
  open: boolean;
  event: IEvent | undefined;
  handleClose: () => void;
}

export const EditEventDialog: FC<IProps> = ({ open, event, handleClose }) => {
  if (!event) return null;
  const classes = useStyles();
  // hooks
  const [name, setName] = useState<string>(event.name);
  const [description, setDescription] = useState<string>(event.description);
  const [location, setLocation] = useState<string>(
    event.locale.private ? event.locale.private : ""
  );
  const [image, setImage] = useState<string>(
    event.imagesGQL?.length ? event.imagesGQL[0].original : ""
  );
  const [displayLocation, setdisplayLocation] = useState<string>(
    event.locale.public
  );
  const [bufferTime, setBufferTime] = useState<number>(event.bufferTime ?? 0);
  const [reserveBy, setRerserveBy] = useState<string>(
    moment(Number(event.reserveBy)).format(moment.HTML5_FMT.DATETIME_LOCAL)
  );
  const [startTime, setStartTime] = useState<string>(
    moment(Number(event.startTime)).format(moment.HTML5_FMT.DATETIME_LOCAL)
  );
  const [endTime, setEndTime] = useState<string>(
    moment(Number(event.endTime)).format(moment.HTML5_FMT.DATETIME_LOCAL)
  );

  const [type, setType] = useState(event.type);
  const [tags, setTags] = useState<string>(event.tags.join(","));
  const [maximumDriveTime, setMaximumDriveTime] = useState(
    event.deliveryInfo?.maximumDriveTime ?? 30
  );
  const [pricePerMin, setPricePerMin] = useState(
    event.deliveryInfo?.pricePerMin
  );
  const [pricePerMile, setPricePerMile] = useState(
    event.deliveryInfo?.pricePerMile
  );
  const [minimumOrderAmount, setMinimumOrderAmount] = useState(
    event.deliveryInfo?.minimumOrderAmount ?? 10
  );
  const [_loading, setLoading] = useState<boolean>(false);
  const [hidden, setHidden] = useState<string>(event.hidden ? "1" : "0");

  // handlers
  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    eventAPI
      .updateEvent({
        id: event.id,
        name,
        location,
        ...(type.includes("DELIVERY") && {
          deliveryInfo: {
            maximumDriveTime,
            pricePerMin,
            minimumOrderAmount,
            pricePerMile,
          },
        }),
        description,
        type,
        bufferTime,
        displayLocation,
        hidden: hidden === "1",
        images: [image],
        tags: tags.replace(" ", "").split(","),
        reserveBy: moment(reserveBy).valueOf().toString(),
        startTime: moment(startTime).valueOf().toString(),
        endTime: moment(endTime).valueOf().toString(),
      })
      .then(() => handleClose());
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="application-dialog"
    >
      <DialogTitle>{event.name}</DialogTitle>
      <DialogContent>
        <form onSubmit={submitHandler} className={classes.form}>
          <FormControlLabel
            control={
              <Checkbox
                checked={type.includes("FIXED_TIME")}
                onClick={() =>
                  setType(
                    type.includes("FIXED_TIME")
                      ? type.filter((t) => t !== "FIXED_TIME")
                      : [...type, "FIXED_TIME"]
                  )
                }
                name="checkedB"
                color="primary"
              />
            }
            label="Pop up"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={type.includes("TAKE_OUT")}
                onClick={() =>
                  setType(
                    type.includes("TAKE_OUT")
                      ? type.filter((t) => t !== "TAKE_OUT")
                      : [...type, "TAKE_OUT"]
                  )
                }
                name="checkedB"
                color="primary"
              />
            }
            label="To go"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={type.includes("DROP_IN")}
                onClick={() =>
                  setType(
                    type.includes("DROP_IN")
                      ? type.filter((t) => t !== "DROP_IN")
                      : [...type, "DROP_IN"]
                  )
                }
                name="checkedB"
                color="primary"
              />
            }
            label="Dine in"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={type.includes("DELIVERY")}
                onClick={() =>
                  setType(
                    type.includes("DELIVERY")
                      ? type.filter((t) => t !== "DELIVERY")
                      : [...type, "DELIVERY"]
                  )
                }
                name="checkedB"
                color="primary"
              />
            }
            label="Delivery"
          />
          {type.includes("DELIVERY") && (
            <>
              <TextField
                size="small"
                type={"number"}
                disabled={_loading}
                label="Maximum Drive Time"
                value={maximumDriveTime}
                onChange={(e) => setMaximumDriveTime(Number(e.target.value))}
              />
              <TextField
                size="small"
                type={"number"}
                disabled={_loading}
                label="Minimum Order Amount"
                value={minimumOrderAmount}
                onChange={(e) => setMinimumOrderAmount(Number(e.target.value))}
              />
              <TextField
                size="small"
                type={"number"}
                disabled={_loading}
                label="Price per min"
                value={pricePerMin}
                onChange={(e) => setPricePerMin(Number(e.target.value))}
              />
              <TextField
                size="small"
                type={"number"}
                disabled={_loading}
                label="Price per mile"
                value={pricePerMile}
                onChange={(e) => setPricePerMile(Number(e.target.value))}
              />
            </>
          )}
          <TextField
            size="small"
            disabled={_loading}
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            size="small"
            multiline
            disabled={_loading}
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            size="small"
            disabled={_loading}
            label="Location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
          <TextField
            size="small"
            disabled={_loading}
            label="Display Location"
            value={displayLocation}
            onChange={(e) => setdisplayLocation(e.target.value)}
          />
          <Select
            value={hidden}
            onChange={(e) => setHidden(e.target.value as string)}
          >
            <MenuItem value={"1"}>Is Hidden</MenuItem>
            <MenuItem value={"0"}>Not Hidden</MenuItem>
          </Select>
          <TextField
            size="small"
            disabled={_loading}
            label="Tags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
          <TextField
            size="small"
            disabled={_loading}
            label="Image URL"
            value={image}
            onChange={(e) => setImage(e.target.value)}
          />
          <TextField
            size="small"
            type={"number"}
            disabled={_loading}
            label="Prep Time (min)"
            value={bufferTime}
            onChange={(e) => setBufferTime(Number(e.target.value))}
          />
          <TextField
            size="small"
            type="datetime-local"
            label="Reserve By"
            value={reserveBy}
            onChange={(e) =>
              setRerserveBy(
                moment(e.target.value).format(moment.HTML5_FMT.DATETIME_LOCAL)
              )
            }
          ></TextField>
          <TextField
            size="small"
            type="datetime-local"
            label="Start Time"
            value={startTime}
            onChange={(e) =>
              setStartTime(
                moment(e.target.value).format(moment.HTML5_FMT.DATETIME_LOCAL)
              )
            }
          ></TextField>
          <TextField
            size="small"
            type="datetime-local"
            label="End Time"
            value={endTime}
            onChange={(e) =>
              setEndTime(
                moment(e.target.value).format(moment.HTML5_FMT.DATETIME_LOCAL)
              )
            }
          ></TextField>
          <Button
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            style={{ margin: 10 }}
          >
            {_loading ? <CircularProgress /> : "Submit"}
          </Button>
        </form>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
};
