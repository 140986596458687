import {
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";
import { TopBar } from "src/components";
import { IEvent } from "src/models";
import { OrderContext } from "src/screens/event-detail/order-context";
import { BREAK_POINTS } from "src/styles";
import { ActionTypes } from "../../../order-reducer";
import { useStyles } from "./mobile-header.styles";

interface IProps {
  getEvent: IEvent;
  title?: string;
}

export const MobileHeader: FC<IProps> = ({ getEvent, title = "Checkout" }) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    state: { dialogView },
    dispatch,
  } = useContext(OrderContext);
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

  if (!isMobile) return null;

  return (
    <TopBar
      className={classes.container}
      style={{ position: dialogView ? "unset" : "fixed" }}
    >
      <Toolbar
        className={classes.innerBar}
        style={
          getEvent?.eventUser?.status === "HOLD"
            ? { backgroundColor: "#ffd81b" }
            : {}
        }
      >
        <IconButton
          onClick={() =>
            dialogView
              ? dispatch({
                  type: ActionTypes.SET_EVENT_DIALOG_VIEW,
                  payload: dialogView === "checkout" ? "detail" : "checkout",
                })
              : history.goBack()
          }
        >
          <ChevronLeft className={classes.backArrow} />
        </IconButton>
        <Typography variant="h3" component="h3" className={classes.headerText}>
          {title}{" "}
        </Typography>
      </Toolbar>
      {getEvent?.eventUser?.status === "HOLD" && (
        <LinearProgress color="secondary" />
      )}
    </TopBar>
  );
};
