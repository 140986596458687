import { gql } from "@apollo/client";

export const GET_MY_PENDING_RESTAURANT_REVIEWS = gql`
  query getMyPendingRestaurantReviews {
    getMyPendingRestaurantReviews {
      id
      text
      quality
      hospitality
      accuracy
      status
      eventInfo {
        name
        hostName
        time
        hostPicture
      }
    }
  }
`;
