import { useQuery } from "@apollo/client";
import { History } from "history";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { ErrorLoadingComponent } from "src/components";
import { IPagePL, IRestaurantReview } from "src/models";
import { GET_RESTAURANT_REVIEWS } from "../api/graphql";
import { Layout } from "./layout";

interface IProps {
  data: {
    adminGetRestaurantReviews: { count: number; rows: IRestaurantReview[] };
  };
  history: History;
}

export interface IFetchMorePL {
  pagePL?: IPagePL;
  status?: string;
}

export const RestaurantReviewListContainer: FC<IProps> = () => {
  const { data, error, loading, fetchMore } = useQuery(GET_RESTAURANT_REVIEWS, {
    variables: {
      input: {
        pagePL: {
          limit: 10,
          offset: undefined,
        },
        status: "SUBMITTED",
      },
    },
  });

  const history = useHistory();
  const redirect = (url: string) => history.push(url);

  const _fetchMore = (d: IFetchMorePL) => {
    fetchMore({
      variables: {
        input: d,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          adminGetRestaurantReviews: (fetchMoreResult as any)
            .adminGetRestaurantReviews,
        });
      },
    });
  };

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }
  return (
    <Layout
      totalCount={data.adminGetRestaurantReviews.count}
      restaurantReviews={data.adminGetRestaurantReviews.rows}
      onFetchMore={_fetchMore}
      redirect={redirect}
    />
  );
};
