import { Toolbar, useMediaQuery } from "@material-ui/core";
import React, { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DynamicBanner, TopBar } from "src/components";
import { IGetMe } from "src/models";
import { BREAK_POINTS } from "src/styles";
import { useLayoutStyles } from "./layout.styles";
import { LocationHeaderWidget } from "./location-widget";
import {
  AuthenticatedUserActions,
  NonAuthenticatedUserActions,
} from "./user-actions";

interface IProps {
  user: IGetMe | null;
  noMarginTop: boolean;
}

export const Layout: FC<IProps> = React.memo(({ user }) => {
  const classes = useLayoutStyles();
  const isDesktop = useMediaQuery(`(min-width: ${BREAK_POINTS.tablet}em)`);
  const location = useLocation();
  const history = useHistory();

  return (
    <TopBar key={location.pathname} className={classes.appBarDesktop}>
      <DynamicBanner />
      <Toolbar className={classes.appBarDesktopInner}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={classes.logo} role="link" tabIndex={0}>
            {isDesktop ? (
              <img
                /* cook transition: replaced logo-pink.png with main_header_logo */
                src={require(`src/assets/logos/main_header_logo.png`)}
                alt="cook connect logo"
                data-e2e="header-logo"
                data-testid="header-logo"
                className={classes.logoIconLarge}
                onClick={() =>
                  user ? history.push("/menus") : history.push("/")
                }
                style={{ width: 175, height: 30, objectFit: "contain" }}
                width={175}
              />
            ) : (
              <img
                /* cook transition: replaced logo-icon.png with main_header_logo */
                src={require(`src/assets/logos/cook.png`)}
                alt="cook connect logo"
                className={classes.logoIconSmall}
                onClick={() => history.push("/")}
                style={{ width: 20, height: 23, objectFit: "contain" }}
              />
            )}
          </div>
          <LocationHeaderWidget />
        </div>

        <nav className={classes.rightContainer}>
          {user ? (
            <AuthenticatedUserActions user={user} />
          ) : (
            <NonAuthenticatedUserActions />
          )}
        </nav>
      </Toolbar>
    </TopBar>
  );
});
