import { Dialog } from "@material-ui/core";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppStatus, IGetMe } from "src/models";
import { PendingDialogContent } from "./pending-dialog-content";

interface IProps {
  getMe: IGetMe;
}

export const CompleteOnboardFlowDialog: React.FC<IProps> = ({ getMe }) => {
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const [display, setDisplay] = useState<any>(null);
  const value = parse(location.search);

  const status = value.status as AppStatus;

  useEffect(() => {
    if (status) {
      setOpen(true);

      switch (status) {
        case AppStatus.PENDING:
          setOpen(true);
          setDisplay(
            <PendingDialogContent getMe={getMe} handleClose={handleClose} />
          );
          break;

        case AppStatus.ACTION_REQUIRED:
          setOpen(false);
          setDisplay(
            <PendingDialogContent getMe={getMe} handleClose={handleClose} />
          );
          break;
        case AppStatus.APPROVED:
          setOpen(false);
          setDisplay(
            <PendingDialogContent getMe={getMe} handleClose={handleClose} />
          );
          break;
        case AppStatus.SCHEDULED:
          setOpen(false);
          setDisplay(
            <PendingDialogContent getMe={getMe} handleClose={handleClose} />
          );
          break;
        case AppStatus.SUBMITTED:
          setOpen(false);
          setDisplay(
            <PendingDialogContent getMe={getMe} handleClose={handleClose} />
          );
          break;
        case AppStatus.ON_HOLD:
          setOpen(false);
          setDisplay(
            <PendingDialogContent getMe={getMe} handleClose={handleClose} />
          );
          break;
        case AppStatus.REJECTED:
          setOpen(false);
          setDisplay(
            <PendingDialogContent getMe={getMe} handleClose={handleClose} />
          );
          break;

        default:
          setOpen(false);
          setDisplay(
            <PendingDialogContent getMe={getMe} handleClose={handleClose} />
          );
          break;
      }
    } else {
      setOpen(false);
    }
  }, [status]);

  const handleClose = () => {
    history.push(location.pathname);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="cook-flow-finished-dialog"
      aria-describedby="cook-flow-finished-dialog"
      maxWidth="sm"
      fullWidth
    >
      {display}
    </Dialog>
  );
};
