import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  root: {
    marginBottom: "2em",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    textAlign: "center",
    padding: "0 0 7px !important",
    color: COLORS.MEDIUM_GREEN,
    "&:after": {
      content: `""`,
      borderBottom: "2px solid #94A3AB",
    },
  },
  label: {
    position: "absolute",
    left: -10000,
    top: "auto",
    width: 1,
    height: 1,
    overflow: "hidden",
  },
  underline: {
    minWidth: 165,
    height: 2,
    borderBottom: "2px solid #94A3AB",
    "&:after": {
      content: `""`,
      borderBottom: "2px solid #94A3AB",
    },
  },
  menuItem: {},
  inputBase: {
    padding: "0px 0px 7px",
    minWidth: 197,
  },
  [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
    underline: {
      minWidth: 258,
    },
  },
});
