import { useQuery } from "@apollo/client";
import React, { FC, useState } from "react";
import { ErrorLoadingComponent } from "src/components";
import { ICoupon, IPagePL } from "src/models";
import { couponAPI } from "../api";
import { GET_COUPONS } from "../api/graphql";
import { Layout } from "./layout";

export interface IFetchMorePL {
  pagePL?: IPagePL;
}

export const CouponListContainer: FC = () => {
  const { data, loading, error, fetchMore } = useQuery<{
    getCoupons: { count: number; rows: ICoupon[] };
  }>(GET_COUPONS, {
    variables: { input: { pagePL: { limit: 10 } } },
    fetchPolicy: "cache-first",
    errorPolicy: "ignore",
  });

  const [pageNum, setPageNum] = useState(1);

  const _deleteCoupon = (code: string) => {
    if (data?.getCoupons.rows.length === 1 && pageNum > 1) {
      setPageNum(pageNum - 1);
      return couponAPI.deleteCoupon(code, (pageNum - 2) * 10);
    }
    return couponAPI.deleteCoupon(code, (pageNum - 1) * 10);
  };

  const _fetchMore = (d: { pageNumber: number; searchTerm?: string }) => {
    setPageNum(d.pageNumber);
    fetchMore({
      variables: {
        input: {
          pagePL: { limit: 10, offset: (d.pageNumber - 1) * 10 },
          searchTerm: d.searchTerm,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          getCoupons: (fetchMoreResult as any).getCoupons,
        });
      },
    });
  };

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }

  return (
    <Layout
      coupons={data?.getCoupons.rows as ICoupon[]}
      count={data?.getCoupons.count as number}
      pageNum={pageNum}
      fetchMore={_fetchMore}
      deleteCoupon={_deleteCoupon}
    />
  );
};
