import { useQuery } from "@apollo/client";
import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { StreamChatContainer } from "src/components/chat2";
import { sharedAPI } from "src/shared-graphql";
import { GET_ME } from "src/shared-graphql/queries";

export const Chat2Container: FC = () => {
  const { data, loading } = useQuery(GET_ME, { partialRefetch: true });
  const history = useHistory();

  useEffect(() => {
    sharedAPI.setGlobalLoadingState(loading);
  }, [loading]);

  useEffect(() => {
    if (loading) return;
    if (!data?.getMe) {
      history.push("/login", { from: "/chat" });
      return;
    }
  }, [data, loading]);

  return <StreamChatContainer />;
};
