import React, { useState } from "react";
import { sharedAPI } from "src/shared-graphql";
import { passwordResetAPI } from "../api";
import { Layout } from "./layout";

export const ResetTokenContainer = ({ history }) => {
  const [errorText, setErrorText] = useState("");

  const _getToken = (email: string) => {
    return passwordResetAPI
      .getPasswordResetToken(email)
      .then(() => {
        sharedAPI.setSnackbarMsg({
          type: "success",
          msg:
            "Please check your email for the reset link. May take up to 5 minutes for email to arrive",
        });
        history.push("/");
      })
      .catch((e) => {
        setErrorText(
          "Something went wrong. Make sure you have an account with this email"
        );
        sharedAPI.setSnackbarMsg({
          type: "error",
          msg: "Oops, something is not right",
        });
      });
  };
  return <Layout getToken={_getToken} errorText={errorText} />;
};
