import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";

interface IProps {
  src: string;
  alt: string;
  mounted: boolean;
}

const useStyles = makeStyles({
  container: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    maxHeight: "15rem",
    overflow: "hidden",
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: `linear-gradient(0deg, rgba(0,0,0,0) 68%, rgba(3,2,2,0.8477766106442577) 99%)`,
  },
  icon: {
    position: "relative",
    top: 0,
    left: 0,
  },
});

export const DishImageContainer: FC<IProps> = ({ src, alt, mounted }) => {
  const classes = useStyles();
  return mounted ? (
    <div className={classes.container}>
      <div className={classes.overlay} />
      <img src={src} alt={alt} className={classes.image} />
    </div>
  ) : null;
};
