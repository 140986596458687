import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  header: {
    textAlign: "center",
    fontFamily: "CustomFourBold",
    paddingBottom: "2rem",
  },
  card: {
    backgroundColor: "#FFD963",
  },
  cardContent: {
    padding: "3rem",
  },
  link: {
    color: COLORS.MEDIUM_GREEN,
    textDecoration: "none",
    fontFamily: "CustomFourBold",
  },
});
