import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  image: {
    overflow: "hidden",
    borderRadius: 5,
    display: "block",
    objectFit: "cover",
    height: "15rem",
    width: "25rem",
  },
  carouselRoot: {
    borderRadius: 5,
    overflow: "hidden",
    height: "15rem",
    width: "25rem",
  },
  imageContainer: {
    position: "relative",
    overflow: "hidden",
  },
  stepperButtonLayout: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  stepperDotsContainer: {
    padding: ".5rem",
    margin: 0,
    listStyleType: "none",
    display: "flex",
  },
  dotContainer: {
    marginRight: ".5rem",

    cursor: "pointer",
    transition: "all 100ms ease-in",
    "&:last-child": {
      marginRight: 0,
    },
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
  dotActive: {
    opacity: 1,
    transform: "scale(1.4)",
    color: COLORS.WHITE,
  },
  dot: {
    color: COLORS.WHITE,
    opacity: 0.75,
    transform: "scale(1)",
  },
});
