import { SvgIcon } from "@material-ui/core";
import React, { FC } from "react";

interface IProps {
  className?: any;
}

export const CustomSmsIcon: FC<IProps> = ({ className, ...props }) => (
  <SvgIcon className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <title>Text</title>
      <path d="M0,24A24,24,0,1,1,24,48,24,24,0,0,1,0,24Z" fill="#00ccad" />
      <path
        d="M36.39,29a13.57,13.57,0,0,1-1.93,3.54l1,4.21a1.21,1.21,0,0,1-.24,1,1,1,0,0,1-.79.37h-.16l-6-1a12.84,12.84,0,0,1-4.39.79c-7.39,0-13.37-6.34-13.37-14.11s6-14.07,13.37-14.07,13.37,6.3,13.37,14.07A14.81,14.81,0,0,1,36.39,29Z"
        fill="#fff"
      />
      <path
        d="M19.7,23.39a2.18,2.18,0,1,1-2.17-2.18A2.18,2.18,0,0,1,19.7,23.39Z"
        fill="#00ccad"
      />
      <path
        d="M26.13,23.39A2.18,2.18,0,1,1,24,21.21,2.18,2.18,0,0,1,26.13,23.39Z"
        fill="#00ccad"
      />
      <path
        d="M32.57,23.39a2.18,2.18,0,1,1-2.18-2.18A2.19,2.19,0,0,1,32.57,23.39Z"
        fill="#00ccad"
      />
    </svg>
  </SvgIcon>
);
