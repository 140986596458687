import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  paperWidth: {
    maxWidth: "unset",
    width: "600px",
    margin: 0,
    padding: 4,
  },
  paperWidthMb: {
    maxWidth: "unset",
    width: "100%",
    margin: 0,
    padding: 4,
  },
  bagImage: {
    height: 50,
    margin: "0 auto",
  },
  noNotificationContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: "grey",
    padding: "20px",
    flexGrow: 1,
  },
  newBtn: {
    position: "fixed",
    bottom: 70,
    right: 10,
    padding: 3,
    boxShadow: "0 1px 7px 0 rgba(0,0,0,0.17)",
    width: 56,
    height: 56,
    backgroundColor: COLORS.MEDIUM_GREEN,
    zIndex: 3,
    "&:hover": {
      backgroundColor: COLORS.LIGHT_GREEN,
    },
    [`@media (min-width: ${BREAK_POINTS.mobileRegular}em)`]: {
      position: "absolute",
      bottom: 10,
    },
  },

  closeBtn: {
    position: "absolute",
    right: 10,
    zIndex: 10,
  },
});
