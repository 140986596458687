import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { ActionDialog } from "src/components";
import { useCreateChannel } from "src/components/chat2";
import { IGetMe, IRestaurant } from "src/models";
import { COLORS } from "src/styles";

export interface IProps {
  restaurant: IRestaurant;
  currentUser: IGetMe | null | undefined;
}

export const CateringButton: React.FC<IProps> = ({
  restaurant,
  currentUser,
}) => {
  const { createChannel } = useCreateChannel();
  const classes = useStyles();

  return (
    <ActionDialog getMe={currentUser}>
      <Button
        size="small"
        color="secondary"
        fullWidth
        variant="outlined"
        classes={{ outlinedSecondary: classes.outlinedCateringButton }}
        onClick={() => createChannel(restaurant.host.id)}
      >
        Custom Order
      </Button>
    </ActionDialog>
  );
};

const useStyles = makeStyles({
  outlinedCateringButton: {
    color: COLORS.BLACK,
    borderColor: COLORS.BLACK,
  },
});
