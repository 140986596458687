import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { amplitude } from "src/services";
import { Layout } from "./layout";

export const Home: FC = () => {
  // if (!data || !data.getUpcomingEvents) return null;
  // The above line was causing the problems where only the footer shows up on home page
  // if (data.loading || data.error) {
  //   return <ErrorLoadingComponent error={data.error} loading={data.loading} />;
  // }
  useEffect(() => {
    amplitude.getInstance().logEvent("[Home] landing");
  }, []);

  const _description =
    "COOK Connect by COOK Alliance is the first legal marketplace for home cooked \
     food operated by a nonprofit organization in the US. We have advocated and \
     organized for years to create a more equitable, inclusive, and human-centered \
     food economy. As a nonprofit, proceeds from the platform are reinvested \
     into supporting chefs and the home cooking movement through programs, \
     training, and small business support. Join us as we work to empower \
     food entrepreneurship, build community, and redefine our relationship \
     to our food!.";
  return (
    <>
      <Helmet>
        <title>COOK Connect | #1 Legal Marketplace for Home Cooked Food</title>
        <meta name="description" content={_description} />
      </Helmet>
      <Layout />
    </>
  );
};
