import { Typography } from "@material-ui/core";
import React from "react";
import ReactStars from "react-stars";
import { IOverallReview } from "src/models";
import { useStyles } from "../../layout.styles";

function calculatePercentage(value: number, total: number = 5) {
  return ((value / total) * 100).toFixed(0);
}
interface OverallReviewProps {
  overallReview: IOverallReview;
}

export const OverallReview: React.FC<OverallReviewProps> = ({
  overallReview,
}) => {
  const classes = useStyles();
  const quality = calculatePercentage(overallReview.quality);
  const accuracy = calculatePercentage(overallReview.accuracy);
  const hospitality = calculatePercentage(overallReview.hospitality);

  if (overallReview.count === 0) return null;

  return (
    <div id="reviews-container" data-testid="overall-restaurant-review">
      <Typography variant="h3" component="h3" className={classes.heading}>
        Overall ratings and reviews
      </Typography>
      <div style={{ maxWidth: 380 }}>
        <Typography
          variant="body2"
          component="p"
          className={classes.disclosure}
        >
          Reviews can only be left by diners who've eaten at this restaurant.
        </Typography>
      </div>
      <div className={classes.starsContainer}>
        <ReactStars
          count={5}
          color={"#F7CC15"}
          color2={"#ffd700"}
          value={Math.ceil(overallReview.stars)}
          edit={false}
        />
        <Typography
          variant="body2"
          component="p"
          data-testid="total-review-count"
          className={classes.reviewAmount}
        >
          {overallReview.count} reviews
        </Typography>
      </div>
      <dl className={classes.reviewSummary}>
        <div className={classes.reviewSummaryPart}>
          <Typography
            variant="body2"
            component="p"
            className={classes.reviewSummaryTitle}
          >
            Food was good
          </Typography>
          <Typography
            variant="body2"
            component="p"
            data-testid="quality-percentage"
            className={classes.reviewSummaryPercentage}
          >
            {quality}%
          </Typography>
        </div>
        <div className={classes.reviewSummaryPart}>
          <Typography
            variant="body2"
            component="p"
            className={classes.reviewSummaryTitle}
          >
            Hospitable
          </Typography>
          <Typography
            variant="body2"
            component="p"
            data-testid="hospitality-percentage"
            className={classes.reviewSummaryPercentage}
          >
            {hospitality}%
          </Typography>
        </div>
        <div className={classes.reviewSummaryPart}>
          <Typography
            variant="body2"
            component="p"
            className={classes.reviewSummaryTitle}
          >
            Accurate
          </Typography>
          <Typography
            variant="body2"
            component="p"
            data-testid="accuracy-percentage"
            className={classes.reviewSummaryPercentage}
          >
            {accuracy}%
          </Typography>
        </div>
      </dl>
    </div>
  );
};
