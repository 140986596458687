import { ApolloProvider } from "@apollo/client";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import React, { FC, useEffect } from "react";
import { render } from "react-dom";
import { FallbackComponent } from "src/components";
import ReactPixel from "src/components/pixel";
import { StreamChat } from "stream-chat";
import { client } from "./apollo";
import { App } from "./App";
import "./i18n/i18n";
import "./styles/animations.css";
import "./styles/index.css";
import { theme } from "./styles/theme";
import { isBrowserSupported } from "./utils/helpers";
import { tagManager } from "./utils/react-gtm";
// ===================== Chat
export let chatApi = "2g56wb6kxfj5";
if (process.env.REACT_APP_ENV === "stage") chatApi = "7scf7se8esa9";
if (process.env.REACT_APP_ENV === "production") chatApi = "d3m6y6fentxd";
if (
  process.env.REACT_APP_ENV === "cordova" &&
  process.env.CORDOVA_BUILD_ENV === "production"
)
  chatApi = "d3m6y6fentxd";
export const chatClient = new StreamChat(chatApi, undefined, {
  timeout: 6000,
});

// ===================== Hubspot
export const _hsq = ((window as any)._hsq = (window as any)._hsq || []);

// ===================== Current build
export const currentBuild = 260;

// ===================== BugSnag
const bugSnagApi =
  (process.env.REACT_APP_ENV === "production" ||
    process.env.REACT_APP_ENV === "cordova") &&
  process.env.CORDOVA_BUILD_ENV === "production"
    ? (process.env.REACT_APP_BUG_SNAG as string)
    : ("abcdefg" as string);

export const bugSnag = Bugsnag.start({
  apiKey: bugSnagApi,
  plugins: [new BugsnagPluginReact()],
  appVersion: currentBuild.toString(),
  appType: (window as any)?.cordova?.platformId ?? process.env.REACT_APP_ENV,
});

const ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(
  React
) as any;

const env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "dev";

// ===================== Stripe
export const stripeAPIKey =
  (process.env.REACT_APP_ENV === "production" ||
    process.env.REACT_APP_ENV === "cordova") &&
  process.env.CORDOVA_BUILD_ENV === "production"
    ? (process.env.REACT_APP_STRIPE_API_LIVE as string)
    : (process.env.REACT_APP_STRIPE_API as string);

// ===================== Facebook Pixel
const cookPixel =
  (process.env.REACT_APP_ENV === "production" ||
    process.env.REACT_APP_ENV === "cordova") &&
  process.env.CORDOVA_BUILD_ENV === "production"
    ? "1943210312445473"
    : "dev";
const dinerPixel =
  (process.env.REACT_APP_ENV === "production" ||
    process.env.REACT_APP_ENV === "cordova") &&
  process.env.CORDOVA_BUILD_ENV === "production"
    ? "209923780248654"
    : "dev";
const options = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init(cookPixel, undefined, options);
ReactPixel.init(dinerPixel, undefined, options);
export { ReactPixel, cookPixel, dinerPixel };
export { CLIENT_ADDRESS };

let CLIENT_ADDRESS;

if (
  process.env.REACT_APP_ENV === "production" ||
  process.env.REACT_APP_ENV === "cordova"
) {
  CLIENT_ADDRESS = "https://cookconnect.com"; // production server
} else if (
  process.env.NODE_ENV === "development" ||
  process.env.REACT_APP_ENV === "development"
) {
  CLIENT_ADDRESS = "https://localhost:3000"; // local + CI test e2e
} else {
  CLIENT_ADDRESS = "https://stage.foodnome.com"; // stage
}

const Root: FC = () => {
  useEffect(() => {
    tagManager.init();
    ReactPixel.pageView();
    injectCordovaCSS();
  }, []);

  const injectCordovaCSS = () => {
    // This function is to inject CSS into the head. We will use this to inject the user-select
    // property since it will only be injected for cordova. All regular dynamic css will go in the
    // routes.tsx file
    if (window.hasOwnProperty("cordova")) {
      const css = `html { -webkit-user-select: none; -ms-user-select: none; user-select: none; height: 100vh; } /* Remove file input button */
          div[data-testid="fileinput"] {
            display: none;
          }`,
        head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style");
      head.appendChild(style);
      style.appendChild(document.createTextNode(css));
    }
  };
  if (!isBrowserSupported()) {
    alert(
      "Sorry your browser is not supported, please use current version of Chrome, Firefox, Safari or Edge."
    );
  }
  return (
    <ErrorBoundary FallbackComponent={FallbackComponent}>
      <MuiThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </MuiThemeProvider>
    </ErrorBoundary>
  );
};

const rootElement = document.getElementById("root");

if (!(window as any).cordova) {
  render(<Root />, rootElement);
} else {
  document.body.classList.add("in-device");
  document.addEventListener(
    "deviceready",
    () => render(<Root />, rootElement),
    false
  );
}
