import { useMutation } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import dropIn, { Dropin } from "braintree-web-drop-in";
import React, { FC, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { GET_BT_TOKEN } from "./graphql/mutations";

interface IProps {
  setBrainTreeInstanceHandler: (type: any) => void;
  total?: number;
}
export const BTDropIn2: FC<IProps> = ({ total }) => {
  const [getToken] = useMutation(GET_BT_TOKEN);
  const [token, setToken] = useState("");
  const classes = useStyles();
  const ref = useRef(null);
  const [instance, setInstance] = useState<Dropin | null>(null);
  const history = useHistory();

  const reinit = (_token: string) => {
    return dropIn
      .create({
        container: ref.current as any,
        authorization: _token,
        vaultManager: true,
        paypal: {
          flow: "vault",
          buttonStyle: {
            color: "blue",
            shape: "rect",
            size: "responsive",
            label: "checkout",
            tagline: "true",
          } as any,
        },
        // venmo: {
        //   allowNewBrowserTab: false,
        // },
      })
      .then((i) => {
        setInstance(i);
        instance?.isPaymentMethodRequestable();
      })
      .catch(
        (err: any) => new Error("Error loading BrainTree component: " + err)
      );
  };

  useEffect(() => {
    getToken()
      .then((d) => {
        setToken(d.data.userGetBTToken);
        reinit(d.data.userGetBTToken);
      })
      .catch((error) => {
        if (error?.message?.includes("Token does not match")) {
          history.push("/login");
        } else if (error?.message?.includes("Must be logged in")) {
          history.push("/login");
        }
      });
  }, []);

  return (
    <>
      <div className={classes.customBrainTreeStyles} ref={ref} />
      <button
        onClick={() => {
          instance?.requestPaymentMethod((err, payload) => {
            // tslint:disable-next-line: no-console
            console.log(err, payload);
            if (err || !payload) {
              // tslint:disable-next-line: no-console
              console.log(err, payload);
            }
          });
          setTimeout(
            () => instance?.teardown().then(() => reinit(token)),
            2000
          );
        }}
      >
        Add
      </button>
    </>
  );
};

const useStyles = makeStyles({
  customBrainTreeStyles: {
    "& > div[data-braintree-id=wrapper]": {
      margin: "1rem",
    },
    "& > div .braintree-options-list > div": {
      borderColor: "#00AE86",
    },
    "& div.braintree-sheet": {
      borderColor: "#00AE86",
    },
    "& div.braintree-sheet__header": {
      borderColor: "#00AE86",
    },
    "& [data-braintree-id=choose-a-way-to-pay]": {
      fontFamily: "CustomFourBold",
      fontWeight: 500,
    },
    "& [data-braintree-id=methods-label]": {
      fontFamily: "CustomFourBold",
      fontWeight: 500,
    },
    "& [data-braintree-id=toggle]": {
      color: "#00AE86",
      padding: 5,
    },
    "& [data-braintree-id=toggle] > span": {
      borderColor: "#00AE86",
    },
    "& [data-braintree-id=loading-indicator]": {
      borderColor: "#00AE86",
    },
    "& [data-braintree-id=upper-container]:before": {
      borderColor: "#00AE86",
    },
    "& [data-braintree-id=methods-edit]": {
      color: "#00AE86",
    },
  },
});
