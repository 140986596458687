import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { PaginationComponent } from "src/components";
import { IReferral } from "src/models";
import { Header } from "./header";
import { ReferralTable } from "./table";

interface IProps {
  referrals: IReferral[];
  fetchMore: (d: number) => any;
  count: number;
}

export const Layout: FC<IProps> = ({ referrals, count, fetchMore }) => {
  if (referrals && !referrals.length) {
    return (
      <div>
        Referrals:{" "}
        <Typography variant="body2" component="p">
          You don't have any.
        </Typography>
      </div>
    );
  }
  return (
    <div>
      <Header count={count} />
      <ReferralTable referrals={referrals} />
      <PaginationComponent totalCount={count} onChange={fetchMore} />
    </div>
  );
};
