import { Avatar, Divider, ListItem, ListItemText } from "@material-ui/core";
import { Room } from "@material-ui/icons";
import React, { FC } from "react";
import { COLORS } from "src/styles";
import { truncateText } from "src/utils/helpers";

interface IProps {
  suggestion?: any;
  index?: any;
  itemProps?: any;
  highlightedIndex?: any;
  selectedItem?: any;
  suggestionStyle?: any;
  truncateSuggestion?: any;
  restaurant?: any;
  onSelectRestaurant?: any;
}
export const Suggestion: FC<IProps> = ({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
  suggestionStyle,
  truncateSuggestion,
  restaurant,
  onSelectRestaurant,
}) => {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || "").indexOf(suggestion.text) > -1;

  const dy = {
    ...(restaurant && { onClick: () => onSelectRestaurant(restaurant) }),
  };
  return (
    <>
      <ListItem
        dense
        {...itemProps}
        {...dy}
        key={suggestion.place_id}
        data-e2e="address-auto-complete-suggestion"
        data-testid={`address-auto-complete-suggestion-${index}`}
        selected={isHighlighted}
        component="span"
        style={{
          ...{
            fontWeight: isSelected ? 500 : 400,
            cursor: "pointer",
            zIndex: 10,
          },
          ...suggestionStyle,
        }}
      >
        {restaurant ? (
          <>
            <Avatar
              style={{ width: 32, height: 32, marginRight: 10 }}
              alt={restaurant.host.firstName + " " + restaurant.host.lastName}
              src={restaurant.host.picture}
            />

            <ListItemText
              primary={
                <span
                  style={{
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    overflow: "hidden",
                  }}
                >
                  <span>
                    {truncateSuggestion
                      ? truncateText(suggestion, 44)
                      : suggestion}
                  </span>
                </span>
              }
              secondary={
                <span
                  style={{
                    color: "#9C9C9C",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    overflow: "hidden",
                  }}
                >
                  {restaurant.city + ", " + restaurant.state}
                </span>
              }
            />
          </>
        ) : (
          <>
            <span
              style={{
                width: 32,
                height: 32,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Room
                style={{
                  color: COLORS.BLACK,
                  marginRight: 10,
                  fontSize: "2rem",
                }}
              />
            </span>

            <ListItemText
              primary={
                <span
                  style={{
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    overflow: "hidden",
                  }}
                >
                  {truncateSuggestion
                    ? truncateText(suggestion, 44)
                    : suggestion}
                </span>
              }
            />
          </>
        )}
      </ListItem>
      <Divider />
    </>
  );
};

// {guest.picture ? (
//   <Avatar
//     className={classes.avatar}
//     alt={guest.firstName}
//     src={guest.picture}
//   />
// ) : (
//   <Avatar className={classes.avatar}>
//     {guest.firstName.charAt(0) + guest.lastName.charAt(0)}
//   </Avatar>
// )}
