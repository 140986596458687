import { SvgIcon } from "@material-ui/core";
import React from "react";

export const MicrosoftLogo = (props) => (
  <SvgIcon viewBox="0 0 14.402 14.402" {...props}>
    <polygon
      style={{ fill: "#030104" }}
      points="0,7.896 0,12.091 5.989,12.951 5.989,7.896 		"
    />
    <polygon
      style={{ fill: "#030104" }}
      points="5.989,1.452 0,2.312 0,6.507 5.989,6.507 		"
    />
    <polygon
      style={{ fill: "#030104" }}
      points="14.402,6.507 14.402,0.173 7.379,1.181 7.379,6.507 		"
    />
    <polygon
      style={{ fill: "#030104" }}
      points="7.379,13.222 14.402,14.229 14.402,7.896 7.379,7.896 		"
    />
  </SvgIcon>
);
