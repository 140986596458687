import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  chargeBtn: {
    position: "absolute",
    right: 6,
    fontFamily: "CustomFourBold",
    background: COLORS.MEDIUM_GREEN,
    padding: "2px 6px",
    border: "none",
    color: "white",
    cursor: "pointer",
  },
  createBtn: {
    marginTop: 10,
  },
  container: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
  },
});
