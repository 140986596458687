import { IImage } from "src/models";
import { initialState } from "../form/state/reducers";

export const formatInitialState = ({
  displayLocation = "",
  description = "",
  instructions = "",
  address = "",
  reserveByDate = "",
  reserveByTime = "",
  date = "",
  name = "",
  startTime = "",
  duration = 2,
  maximumDriveTime = 0,
  minimumOrderAmount = 0,
  pricePerMin = 0,
  pricePerMile = 0,
  images = { publicId: "", small: "", medium: "", large: "", original: "" },
}: {
  displayLocation?: string;
  description?: string;
  instructions?: string;
  name?: string;
  address?: string;
  reserveByDate?: string;
  reserveByTime?: string;
  duration?: number;
  date?: string;
  startTime?: string;
  images?: IImage;
  maximumDriveTime: number;
  minimumOrderAmount: number;
  pricePerMin: number;
  pricePerMile: number;
}) => ({
  ...initialState,
  address,
  name,
  reserveByDate,
  reserveByTime,
  duration,
  displayLocation,
  images,
  instructions,
  description,
  date,
  startTime,
  maximumDriveTime,
  minimumOrderAmount,
  pricePerMin,
  pricePerMile,
});
