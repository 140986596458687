import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  restaurantHeader: {
    color: COLORS.MEDIUM_RED,
    fontFamily: "CustomFourBold",
    textAlign: "right",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 0px",
  },
});
