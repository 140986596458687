import moment from "moment";
import { IEvent } from "src/models";
import { checkForSafari } from "src/utils/helpers";
import { IEventFormSubmitPL } from "./form";
import { IEventFormState } from "./state/reducers";

export function formatInitialState(event: IEvent): IEventFormState {
  const isSafari = checkForSafari();
  const startTime = moment(Number(event.startTime));
  const endTime = moment(Number(event.endTime));
  const reserveBy = moment(Number(event.reserveBy));
  return {
    name: event.name,
    isMile: !!event.deliveryInfo?.pricePerMile,
    description: event.description,
    address: event.locale.private ?? "",
    displayLocation: event.locale.public ?? "",
    duration: moment.duration(endTime.diff(startTime)).asHours().toFixed(1),
    eventType: event.type,
    pricePerMin: event.deliveryInfo?.pricePerMin ?? 0,
    pricePerMile: event.deliveryInfo?.pricePerMile ?? 0,
    maximumDriveTime: event.deliveryInfo?.maximumDriveTime ?? 0,
    minimumOrderAmount: event.deliveryInfo?.minimumOrderAmount ?? 0,
    images: event.imagesGQL.length ? event.imagesGQL[0] : null,
    instructions: event.instructions ? event.instructions : "",
    maxSeats: event.maxSeats ? String(event.maxSeats) : "1",
    minSeats: event.minSeats ? String(event.minSeats) : "1",
    price: event.price ? String(event.price) : "0",
    // private: event.private,
    reserveByDate: reserveBy.format("YYYY-MM-DD"),
    reserveByTime: reserveBy.format("HH:mm"),
    date: startTime.format("YYYY-MM-DD"),
    // startTime: startTime.format(isSafari ? "hh:mm A" : "HH:mm"),
    startTime: startTime.format("HH:mm"),
    bufferTime: event.bufferTime
      ? String(event.bufferTime)
      : (15 * 60 * 1000).toString(),
  };
}

export function formatSubmissionPayload(pl: IEventFormSubmitPL) {
  const _startTime = moment(`${pl.date} ${pl.startTime}`, "YYYY-MM-DD HH:mm");
  const formattedPayload = {
    name: pl.name,
    description: pl.description,
    instructions: pl.instructions,
    location: pl.address,
    displayLocation: pl.displayLocation,
    images: pl.images,
    reserveBy: moment(
      `${pl.reserveByDate} ${pl.reserveByTime}`,
      `YYYY-MM-DD HH:mm`
    )
      .valueOf()
      .toString(),
    startTime: _startTime.valueOf().toString(),
    endTime: _startTime.add(Number(pl.duration), "hours").valueOf().toString(),
  } as any;

  if (pl.eventType.includes("DELIVERY")) {
    formattedPayload.deliveryInfo = {
      maximumDriveTime: pl.maximumDriveTime,
      minimumOrderAmount: pl.minimumOrderAmount,
      // Not using price per mile anymore. Only priceperMin
      // ...(pl.pricePerMile
      //   ? { pricePerMile: pl.pricePerMile }
      //   : { pricePerMin: pl.pricePerMin }),
      pricePerMin: pl.pricePerMin,
    };
  }
  formattedPayload.type = pl.eventType;
  if (pl.eventType.includes("FIXED_TIME")) {
    formattedPayload.price = Number(pl.price);
    formattedPayload.minSeats = Number(pl.minSeats);
    formattedPayload.maxSeats = Number(pl.maxSeats);
  } else if (!pl.eventType.includes("FIXED_TIME")) {
    formattedPayload.bufferTime = Number(pl.bufferTime);
  }

  return formattedPayload;
}
