import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";

export function useScrollDirection() {
  const [scrollPos, setScrollPos] = useState(0);
  const scrollRef = useRef(0);

  useEffect(() => {
    function handleUpdateScroll() {
      setScrollPos(window.pageYOffset);
      scrollRef.current = window.pageYOffset;
    }
    const _handleUpdateScroll = debounce(handleUpdateScroll, 100);
    window.addEventListener("scroll", _handleUpdateScroll);
    return () => window.removeEventListener("scroll", _handleUpdateScroll);
  }, []);

  const difference = scrollPos - scrollRef.current;

  return difference > 0;
}

export function useExpand({ items, limit }: { items: any[]; limit: number }) {
  const [expanded, setExpandState] = useState(true);

  function toggleExpandState() {
    setExpandState((prevState) => !prevState);
  }
  return {
    expanded,
    toggleExpandState,
    displayItems: expanded ? items.slice(0, limit) : items,
  };
}
