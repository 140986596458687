import { Button, makeStyles, styled, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { BREAK_POINTS, COLORS } from "src/styles/";

interface IProps {
  selection: string;
  handleSelect: (type: string) => void;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexFlow: "row nowrap",
    border: `1px solid ${COLORS.RED}`,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexDirection: "column",
    },
  },
  selected: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.DARK_RED,
    "&:hover": {
      color: COLORS.WHITE,
      backgroundColor: COLORS.DARK_RED,

    },
  },
  notSelected: {
    color: COLORS.DARK_RED,
    backgroundColor: COLORS.WHITE,
    "&:hover": {
      color: COLORS.WHITE,
      backgroundColor: COLORS.MEDIUM_RED,

    },
  },
});

export const CookDinerToggle: FC<IProps> = ({ selection, handleSelect }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <ToggleButton
        onClick={() => handleSelect("diner")}
        className={
          selection === "diner" ? classes.selected : classes.notSelected
        }
      >
        <Typography variant="body2" component="p" style={{ margin: 0 }}>
          For Diners
        </Typography>
      </ToggleButton>
      <ToggleButton
        onClick={() => handleSelect("cook")}
        className={
          selection === "cook" ? classes.selected : classes.notSelected
        }
      >
        <Typography variant="body2" component="p" style={{ margin: 0 }}>
          For Cooks
        </Typography>
      </ToggleButton>
    </div>
  );
};

const ToggleButton = styled(Button)({
  root: {
    width: 294,
    border: "none",
    borderRadius: 0,
    height: 74,
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_RED,
      border: `1px solid #fff`,
      color: COLORS.WHITE,
    },
  },
  label: {
    fontFamily: "CustomFourBold",
  },
});
