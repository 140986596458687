import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { FC, useState } from "react";
import { IEvent, ReservationActionTypes } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";

interface IProps {
  targetEvent: IEvent;
  handleAction: (action: { type: ReservationActionTypes; payload: any }) => any;
  handleCloseDialog: () => void;
}

const useStyles = makeStyles({
  dialogTitle: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    textTransform: "uppercase",
  },
  inputLabel: {},
  inputRoot: {},
  label: {},
  button: {
    fontFamily: "CustomFourBold",
  },
});

export const CancelDialog: FC<IProps> = ({
  targetEvent,
  handleAction,
  handleCloseDialog,
}) => {
  const classes = useStyles();
  const [error, setError] = useState("");

  const handleCancel = (e) => {
    e.preventDefault();
    setError("");
    handleAction({
      type: ReservationActionTypes.CANCEL,
      payload: { eventId: targetEvent.id },
    })
      .then(() => {
        sharedAPI.setSnackbarMsg({
          type: "success",
          msg: "Reservation cancelled",
        });
      })
      .catch((_error: any) => setError(_error.message.split(":")[1]));
  };

  return (
    <>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        Are you sure you want to cancel your order?
      </DialogTitle>
      <DialogContent
        style={{
          position: "relative",
        }}
      >
        {error && (
          <div style={{ color: COLORS.RED, marginBottom: "20px" }}>{error}</div>
        )}
        <Typography
          variant="body2"
          component="p"
          style={{ fontFamily: "CustomFour" }}
        >
          Refund will be saved to your account as a credit, and you can use it
          anytime.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={handleCloseDialog}
          variant="outlined"
          size="small"
          color="primary"
        >
          No
        </Button>
        <Button
          className={classes.button}
          onClick={handleCancel}
          variant="contained"
          size="small"
          color="primary"
        >
          Yes
        </Button>
      </DialogActions>
    </>
  );
};
