import { makeStyles } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import React from "react";
import { COLORS } from "src/styles";

interface DotsProps {
  iterable: any[];
  activeIndex: number;
  onClickDot: (index: number) => void;
}

const useStyles = makeStyles({
  dotsContainer: {
    margin: "0 1.25rem",
    minHeight: 50,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  dotContainer: {
    marginRight: ".5rem",

    cursor: "pointer",
    transition: "all 100ms ease-in",
    "&:last-child": {
      marginRight: 0,
    },
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
  dotActive: {
    opacity: 1,
    transform: "scale(1.4)",
    color: COLORS.RED,
  },
  dot: {
    color: COLORS.DARK_GREY,
    opacity: 0.75,
    transform: "scale(1)",
  },
  dotsList: {
    padding: ".5rem",
    listStyleType: "none",
    display: "flex",
    maxWidth: 320,
    margin: "0 auto",
    justifyContent: "center",
  },
});

export const Dots: React.FC<DotsProps> = ({
  iterable,
  activeIndex,
  onClickDot,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.dotsContainer}>
      <ul className={classes.dotsList}>
        {iterable.map((i, idx) => (
          <li
            role="button"
            onClick={(e) => {
              e.stopPropagation();
              onClickDot(idx);
            }}
            className={classes.dotContainer}
            key={i.id}
          >
            <FiberManualRecordIcon
              style={{ fontSize: "0.7rem" }}
              className={activeIndex === idx ? classes.dotActive : classes.dot}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
