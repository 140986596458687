import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import "unfetch/polyfill";
import { initialCacheState, resolvers, typeDefs } from "./apollo.local";

let SERVER_ADDRESS;

if (process.env.REACT_APP_ENV === "production") {
  if (window.location.hostname.includes("cookconnect")) {
    SERVER_ADDRESS = "https://cookconnect.com/api"; // production server
  } else {
    SERVER_ADDRESS = "https://foodnome.com/api"; // production server
  }
} else if (process.env.REACT_APP_ENV === "development") {
  // SERVER_ADDRESS = 'https://7535280447.foodnome.com/api'; // Kenji
  SERVER_ADDRESS = "http://localhost:8080/api"; // local + CI test e2e
  // SERVER_ADDRESS = 'http://bs-local.com:8080/api'; // while running browserstack
} else if (process.env.REACT_APP_ENV === "stage") {
  if (window.location.hostname.includes("cookconnect")) {
    SERVER_ADDRESS = "https://staging.cookconnect.com/api"; // stage
  } else {
    SERVER_ADDRESS = "https://7535280447.foodnome.com/api"; // stage
  }
} else if (
  process.env.REACT_APP_ENV === "cordova" &&
  process.env.CORDOVA_BUILD_ENV === "production"
) {
  SERVER_ADDRESS = "https://foodnome.com/api"; // production server
} else if (
  process.env.REACT_APP_ENV === "cordova" &&
  process.env.CORDOVA_BUILD_ENV === "devAndroid"
) {
  SERVER_ADDRESS = "http://10.0.2.2:8080/api"; // dev server for android
} else if (
  process.env.REACT_APP_ENV === "cordova" &&
  process.env.CORDOVA_BUILD_ENV === "devIOS"
) {
  SERVER_ADDRESS = "http://localhost:8080/api"; // dev server for android
} else {
  SERVER_ADDRESS = "http://localhost:8080/api";
}

export { SERVER_ADDRESS };

const cache = new InMemoryCache().restore(initialCacheState);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("foodnome_auth_token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...(!document.URL.startsWith("http") &&
        process.env.REACT_APP_ENV === "cordova" && {
        authorization: token ? token : "none",
      }),
    },
  };
});

const httpLink = new HttpLink({
  credentials: "include",
  uri: `${SERVER_ADDRESS}/graphql`,
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  typeDefs,
  resolvers,
});
