import { IDishWithCount } from "src/models";

export enum ActionTypes {
  SET_SEATS = "SET_SEATS",
  SET_ARRIVAL_TIME = "SET_ARRIVAL_TIME",
  SET_DISH_COUNT = "SET_DISH_COUNT",
  SET_ORDERED_DISHES = "SET_ORDERED_DISHES",
  SET_DINE_OPTION = "SET_DINE_OPTION",
  RESET = "RESET",
  SET_DIALOG_STATUS = "SET_DIALOG_STATUS",
  SET_ADDRESS_DIALOG_STATUS = "SET_ADDRESS_DIALOG_STATUS",
  SET_DELIVERY_COST = "SET_DELIVERY_COST",
  SET_CHEF_NOTES = "SET_CHEF_NOTES",
  SET_DELIVERY_ADDRESS = "SET_DELIVERY_ADDRESS",
  SET_EVENT_DIALOG_VIEW = "SET_EVENT_DIALOG_VIEW",
}

export interface IState {
  orderedDishes: IDishWithCount[];
  seats: number;
  arrivalTime: string;
  dineOption: string;
  dialogStatus: boolean;
  addressDialogStatus: boolean;
  addressDialogRedirect: string;
  deliveryAddress: string;
  deliveryCost: number;
  total?: number;
  chefNotes: string;
  dialogView?: "detail" | "checkout" | "checkout-mobile";
}

interface ISetOrderedDishes {
  type: ActionTypes.SET_ORDERED_DISHES;
  payload: IDishWithCount[];
}

interface ISetEventDialogView {
  type: ActionTypes.SET_EVENT_DIALOG_VIEW;
  payload: "detail" | "checkout" | "checkout-mobile";
}

interface ISetChefNotes {
  type: ActionTypes.SET_CHEF_NOTES;
  payload: string;
}

interface ISetDeliveryAddress {
  type: ActionTypes.SET_DELIVERY_ADDRESS;
  payload: string;
}

interface ISetDeliveryCost {
  type: ActionTypes.SET_DELIVERY_COST;
  payload: number;
}

interface ISetDishCount {
  type: ActionTypes.SET_DISH_COUNT;
  payload: { id: number; count: number; notes?: string; option?: string };
}

interface ISetSeats {
  type: ActionTypes.SET_SEATS;
  payload: number;
}

interface ISetAddressDialogStatus {
  type: ActionTypes.SET_ADDRESS_DIALOG_STATUS;
  payload: { url: string; total?: number };
}

interface ISetArrivalTime {
  type: ActionTypes.SET_ARRIVAL_TIME;
  payload: string;
}

interface ISetDineOption {
  type: ActionTypes.SET_DINE_OPTION;
  payload: string;
}

interface IReset {
  type: ActionTypes.RESET;
}

interface ISetDialogStatus {
  type: ActionTypes.SET_DIALOG_STATUS;
  payload: boolean;
}

export type ComponentActions =
  | ISetDeliveryAddress
  | ISetOrderedDishes
  | ISetSeats
  | ISetDishCount
  | ISetArrivalTime
  | ISetDineOption
  | IReset
  | ISetDialogStatus
  | ISetAddressDialogStatus
  | ISetDeliveryCost
  | ISetChefNotes
  | ISetEventDialogView;

const orderReducer: React.Reducer<IState, ComponentActions> = (
  state,
  action
) => {
  switch (action.type) {
    case ActionTypes.SET_ORDERED_DISHES:
      return {
        ...state,
        orderedDishes: action.payload,
      };

    case ActionTypes.SET_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryAddress: action.payload,
      };

    case ActionTypes.SET_EVENT_DIALOG_VIEW:
      return {
        ...state,
        dialogView: action.payload,
      };

    case ActionTypes.SET_CHEF_NOTES:
      return {
        ...state,
        chefNotes: action.payload,
      };

    case ActionTypes.SET_ADDRESS_DIALOG_STATUS:
      return {
        ...state,
        addressDialogStatus: !state.addressDialogStatus,
        addressDialogRedirect: action.payload.url,
        total: action.payload.total,
      };

    case ActionTypes.SET_DELIVERY_COST:
      return {
        ...state,
        deliveryCost: action.payload,
      };

    case ActionTypes.SET_DISH_COUNT:
      const orderedDishes = state.orderedDishes.map((d) => {
        if (d.id !== action.payload.id) {
          return d;
        } else if (action.payload.option) {
          d.options = d?.options?.map((o) =>
            o.name === action.payload.option
              ? {
                  ...o,
                  name: o.name,
                  count: action.payload.count,
                }
              : o
          );
          if (action.payload.notes) {
            if (!d.notes?.includes(action.payload.option)) {
              d.notes =
                d.notes +
                "\n  " +
                action.payload.option +
                "👉" +
                action.payload.notes +
                ".\n";
            } else {
              const split = d.notes.split(".\n");
              const indx = split.findIndex((n) =>
                n.includes(action.payload.option ?? "")
              );
              split[indx] =
                "\n  " + action.payload.option + "👉" + action.payload.notes;
              d.notes = split.join(".\n");
            }
          }
          return d;
        } else {
          return {
            ...d,
            count: action.payload.count,
            notes: action.payload.notes,
          };
        }
      });
      return {
        ...state,
        orderedDishes,
      };

    case ActionTypes.SET_SEATS:
      return {
        ...state,
        seats: action.payload,
      };

    case ActionTypes.SET_ARRIVAL_TIME: {
      return {
        ...state,
        arrivalTime: action.payload,
      };
    }

    case ActionTypes.SET_DINE_OPTION:
      return {
        ...state,
        dineOption: action.payload,
      };

    case ActionTypes.RESET:
      return {
        ...state,
        seats: 1,
        dineOption: "",
        orderedDishes: state.orderedDishes.map((d) => ({
          ...d,
          count: 0,
          notes: "",
          ...(d.options?.length && {
            options: d.options.map((_o) => ({ ..._o, count: 0 })),
          }),
        })),
      };

    case ActionTypes.SET_DIALOG_STATUS:
      return {
        ...state,
        dialogStatus: action.payload,
      };

    default:
      return state;
  }
};
const initialState: IState = {
  orderedDishes: [],
  seats: 1,
  arrivalTime: "",
  dineOption: "",
  dialogStatus: false,
  addressDialogStatus: false,
  addressDialogRedirect: "",
  total: undefined,
  deliveryAddress: "",
  deliveryCost: 0,
  chefNotes: "",
};

export { orderReducer, initialState };
