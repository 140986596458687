import React from "react";
import { IEvent } from "src/models";
import { DesktopEventsList } from "./event-list-desktop";

interface EventListProps {
  searchEvents: { count: number; rows: IEvent[] };
  loading: boolean;
}

export const EventList: React.FC<EventListProps> = (props) => {
  return <DesktopEventsList {...props} />;
};
