import { makeStyles } from "@material-ui/core";
import flatten from "lodash/flatten";
import React from "react";
import { IDish, IImage } from "src/models";
import { BREAK_POINTS } from "src/styles";

interface DishImagesHeroProps {
  dishes: IDish[];
}

const useStyles = makeStyles({
  image: {
    height: "100%",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4, calc(100% / 4))",
    overflow: "hidden",
    gridGap: "1px",
    margin: "0 auto",
    height: 360,
    width: "100%",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      gridTemplateColumns: "repeat(3, calc(100% / 3))",
      "& :last-child": {
        display: "none",
      },
    },
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      height: 200,
      gridTemplateColumns: "repeat(3, calc(100% / 3))",
      "& :last-child": {
        display: "none",
      },
    },
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      height: 111,
      gridTemplateColumns: "repeat(3, calc(100% / 3))",
      "& :last-child": {
        display: "none",
      },
    },
  },
  restaurantImage: {
    height: 360,
    width: "100%",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      height: 200,
    },
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      height: 111,
    },
  },
});

export const DishImagesHero: React.FC<DishImagesHeroProps> = ({ dishes }) => {
  const classes = useStyles();
  const rawDishImages = flatten(
    dishes
      .map((d) => d)

      .sort((a) => (a.category === "ENTREE" ? -1 : 1))
      .filter(
        (d) => d.imagesGQL && d.imagesGQL.length && d.category !== "BEVERAGE"
      )
      .map((dish) => [dish.imagesGQL[0]])
  );

  const showRestaurantImage = rawDishImages.length < 4;
  const images = showRestaurantImage
    ? [
        {
          publicId: "axef",
          small:
            "https://foodnome.sfo2.digitaloceanspaces.com/restaurant-default.jpg",
          medium:
            "https://foodnome.sfo2.digitaloceanspaces.com/restaurant-default.jpg",
          large:
            "https://foodnome.sfo2.digitaloceanspaces.com/restaurant-default.jpg",
          original:
            "https://foodnome.sfo2.digitaloceanspaces.com/restaurant-default.jpg",
        },
      ]
    : (rawDishImages.slice(0, 4) as IImage[]);

  return (
    <div
      className={
        showRestaurantImage ? classes.restaurantImage : classes.gridContainer
      }
    >
      {images.map((img) => (
        <div
          style={{
            background: `url(${img.medium}) no-repeat center center / cover`,
          }}
          className={classes.image}
          key={img.publicId}
        />
      ))}
    </div>
  );
};
