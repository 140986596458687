import { client } from "src/apollo";
import { IUpdateUserPasswordPL } from "src/models";
import { CHANGE_PASSWORD } from "./mutations";

export const changePasswordAPI = {
  updateUserPassword: (updatePasswordPL: IUpdateUserPasswordPL) => {
    return client.mutate({
      mutation: CHANGE_PASSWORD,
      variables: {
        input: updatePasswordPL,
      },
    });
  },
};
