export interface ILinks {
  title: string;
  path: string;
  isExternalLink?: boolean;
}

export const routesExplore = [
  {
    title: "Menus",
    path: "/menus",
  },
  {
    title: "Become a Cook",
    path: "/sell-your-food",
  },
  // disabled for transition to Cook Alliance
  // {
  //   title: "Blog",
  //   path: "https://blog.foodnome.com",
  //   isExternalLink: true,
  // },
] as ILinks[];

export const routesCompany = [
  {
    title: "How it Works",
    path: "/how-it-works",
  },
  // disabled for transition to Cook Alliance
  // {
  //   title: "Press",
  //   path: "/press",
  // },
  {
    title: "Contact",
    path: "/contact",
  },
] as ILinks[];

export const routesResources = [
  {
    title: "FAQ",
    path: "/faq",
  },
  // Removed for transition to Cook Alliance, Feb 2024
  // {
  //   title: "Legislation",
  //   path: "/legislation",
  //   isExternalLink: false,
  // },
  // {
  //   title: "Terms & Conditions",
  //   path: "/terms-and-conditions",
  // },
  // {
  //   title: "Privacy Policy",
  //   path: "/privacy",
  // },
] as ILinks[];

const blacklist = [
  /^\/events/,
  /^\/menus/,
  /^\/menus-map/,
  /^\/restaurants/,
  /^\/map/,
  /^\/c/,
  /^\/d/,
  /^\/refer-a-friend\/?.*/,
  /^\/admin\/?.*/,
  /^\/home-restaurant-info\/?.*/,
  /^\/login\/?.*/,
  /^\/sign-up\/?.*/,
  /^\/password-reset/,
  /^\/get-reset-token/,
  /^\/home-restaurant-application\/?.*/,
  /^\/notifications\/?.*/,
  /^\/settings/,
  /^\/review/,
];

export function isBlackListed(pathname: string): boolean {
  return blacklist.some((regex) => regex.test(pathname));
}
