import { CircularProgress, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { StyledRedButton } from "src/components";
import { sharedAPI } from "src/shared-graphql";
import { submitForm } from "./api";
import { useStyles } from "./styles";

export const ContactForm: React.FC = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const classes = useStyles();
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const {
      firstName,
      lastName,
      email,
      zipCode,
      message,
    } = event.target.elements;

    // set loading state
    return submitForm({
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      zip: zipCode.value,
      message: message.value,
    })
      .then(() => {
        setLoading(false);
        sharedAPI.setSnackbarMsg({
          msg: "We got your message! We will get back to you shortly!",
          type: "success",
        });
        // clear all fields
        firstName.value = "";
        lastName.value = "";
        email.value = "";
        zipCode.value = "";
        message.value = "";
      })
      .catch(() => {
        setLoading(false);
        sharedAPI.setSnackbarMsg({
          msg: "Oops! Something went wrong. Please try again later!",
          type: "error",
        });
      });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit} id="contact-form">
      <section className={classes.fieldsContainer}>
        <div className={classes.fieldsWrapper}>
          <section className={classes.row}>
            <Typography
              component="label"
              variant="caption"
              className={classes.label}
              htmlFor="firstName"
            >
              First Name
            </Typography>
            <TextField
              size="small"
              required
              placeholder="First Name"
              id="firstName"
              name="firstName"
              type="text"
              classes={{ root: classes.root }}
              FormHelperTextProps={{
                classes: {
                  root: classes.formHelperRoot,
                },
              }}
              InputProps={{
                classes: {
                  underline: classes.underline,
                  input: classes.signUpInput,
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                },
              }}
              inputProps={{ maxLength: 200 }}
            />
            <Typography
              component="label"
              variant="caption"
              className={classes.label}
              htmlFor="lastName"
            >
              Last Name
            </Typography>
            <TextField
              size="small"
              required
              placeholder="Last Name"
              id="lastName"
              name="lastName"
              type="text"
              classes={{ root: classes.root }}
              FormHelperTextProps={{
                classes: {
                  root: classes.formHelperRoot,
                },
              }}
              inputProps={{ maxLength: 200 }}
              InputProps={{
                classes: {
                  underline: classes.underline,
                  input: classes.signUpInput,
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                },
              }}
              className={classes.textFieldRight}
            />
          </section>
          <section className={classes.row}>
            <Typography
              component="label"
              variant="caption"
              className={classes.label}
              htmlFor="email"
            >
              Email
            </Typography>
            <TextField
              size="small"
              required
              placeholder="Email"
              id="email"
              name="email"
              type="email"
              classes={{ root: classes.root }}
              inputProps={{ maxLength: 200 }}
              InputProps={{
                classes: {
                  underline: classes.underline,
                  input: classes.signUpInput,
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                },
              }}
            />
            <Typography
              component="label"
              variant="caption"
              className={classes.label}
              htmlFor="zip-code"
            >
              Zip code
            </Typography>
            <TextField
              size="small"
              placeholder="Zip Code"
              id="zip-code"
              name="zipCode"
              required
              classes={{ root: classes.root }}
              inputProps={{ maxLength: 200, pattern: "[0-9]{5}" }}
              InputProps={{
                classes: {
                  underline: classes.underline,
                  input: classes.signUpInput,
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                },
              }}
              className={classes.textFieldRight}
            />
          </section>
          <section style={{ width: "100%" }}>
            <Typography
              component="label"
              variant="caption"
              className={classes.label}
              htmlFor="message"
            >
              Message
            </Typography>
            <TextField
              size="small"
              placeholder="Message"
              id="message"
              name="message"
              required
              multiline
              rows={6}
              classes={{ root: classes.root }}
              inputProps={{ maxLength: 600 }}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                  root: classes.messageInputRoot,
                  focused: classes.messageInputFocused,
                },
              }}
            />
          </section>
          <section style={{ display: "flex", justifyContent: "center" }}>
            <StyledRedButton disabled={isLoading} type="submit">
              {isLoading ? <CircularProgress /> : "Submit"}
            </StyledRedButton>
          </section>
        </div>
      </section>
    </form>
  );
};
