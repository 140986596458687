import { Avatar, Card, Typography } from "@material-ui/core";
import { LocalDining, Room, Star } from "@material-ui/icons";
import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { IEvent } from "src/models";
import { amplitude } from "src/services";
import { COLORS } from "src/styles";
import { eventTypeMap } from "src/utils/helpers";
import { GrandOpeningBadge } from "../banner/grand-opening-badge";
import { EventCardHeader } from "../event-card-header/event-card-header";
import { formatStartToEndDate } from "../helpers";
import { useStyles } from "./event-card.styles";

interface IProps {
  event: IEvent;
}

export const EventCardDesktop: FC<IProps> = React.memo(
  ({ event }) => {
    const classes = useStyles();
    const [isHovered, setHovered] = useState<boolean>(false);
    const [orderByDate, orderByTime] = formatStartToEndDate(
      {
        startTime: event.reserveBy,
        endTime: event.endTime,
      },
      { removeMs: false, spacedDashes: false }
    ).split(",");

    const avatarProps = Object.assign(
      { alt: event.restaurant.host.firstName },
      event.restaurant.host.picture
        ? { src: event.restaurant.host.picture }
        : {
            children: `${event.restaurant.host.firstName[0]}${event.restaurant.host.lastName[0]}`,
          }
    ) as any;

    const cookDetails = (
      <>
        <Typography variant="h6" component="p" className={classes.name}>
          {`${event.restaurant.host.firstName} ${event.restaurant.host.lastName[0]}.`}
        </Typography>
        {!!event.restaurant.overallReview && (
          <span>
            {/* <div className={classes.reviewContainer}>
              <ReactStars
                count={5}
                color2={COLORS.YELLOW}
                value={Number(event.restaurant.overallReview.stars.toFixed(0))}
                edit={false}
              />
            </div> */}
            <div className={classes.reviewContainer}>
              <div className={classes.starsContainer}>
                <Star style={{ fontSize: "2rem", color: COLORS.YELLOW }} />
                <Typography variant="body2" component="p">
                  <span
                    className={classes.starsText}
                  >{`${event.restaurant.overallReview.stars}/5`}</span>
                </Typography>
              </div>
              <Typography
                variant="caption"
                component="p"
                className={classes.reviewText}
              >
                (
                {event.restaurant.overallReview.count
                  ? event.restaurant.overallReview.count
                  : "No"}{" "}
                ratings)
              </Typography>
            </div>
          </span>
        )}
      </>
    );

    return (
      <Card
        onMouseLeave={() => setHovered(false)}
        onMouseOver={() => setHovered(true)}
        data-testid={`event-card-${event.id}`}
        className={classes.container}
      >
        <EventCardHeader isHovered={isHovered} event={event} />
        <Link
          onClick={() => amplitude.getInstance().logEvent("[Event Card] click")}
          className={classes.link}
          to={`/menus/${event.id}-${event.name.replace(/ /g, "-")}`}
        >
          <section className={classes.cardBottom}>
            <ul className={classes.eventDetailsContainer}>
              <li
                data-testid="order-by-date-container"
                className={classes.orderByContainer}
              >
                <Typography variant="caption" component="p">
                  {" "}
                  Order by {orderByDate} {orderByTime.split("-")[0]}!
                </Typography>
              </li>
              <li
                className={classes.eventTitle}
                style={{ display: "flex", marginBottom: ".25rem" }}
              >
                <Typography
                  variant="subtitle1"
                  component="p"
                  style={{
                    fontFamily: "CustomFourBold",
                    display: "-webkit-box",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    wordBreak: "break-word",
                    lineClamp: 2,
                    WebkitLineClamp: 2,

                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {event.name}
                </Typography>
              </li>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className={classes.cookContainer}>
                  <Avatar className={classes.avatar} {...avatarProps} />
                  <div className={classes.cookInfoContainer}>
                    {/grand opening/gi.test(event.name) ? (
                      <>
                        <div className={classes.eventCardBanner}>
                          <GrandOpeningBadge />
                        </div>
                        <div style={{ marginTop: "1.2rem" }}>{cookDetails}</div>
                      </>
                    ) : (
                      <>{cookDetails}</>
                    )}
                  </div>
                </div>
              </div>

              <li className={classes.locationWrapper}>
                <Room className={classes.icon} />
                <Typography
                  variant="caption"
                  component="address"
                  style={{ fontStyle: "normal" }}
                >
                  {!!(event.locale && event.locale.distance) &&
                    `${event.locale.distance.toFixed(1)} miles`}
                  {!!(
                    event.locale &&
                    event.locale.public &&
                    !event.locale.distance
                  ) && <span>{event.locale.public}</span>}
                </Typography>
              </li>
              {!!event.tags?.length && (
                <li className={classes.tagsWrapper}>
                  <LocalDining className={classes.icon} />
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.tagsText}
                  >
                    {event.tags.join(" • ")}
                  </Typography>
                </li>
              )}
            </ul>
            <div className={classes.typesContainer}>
              <Typography
                variant="caption"
                component="p"
                className={classes.deliveryTypeList}
              >
                {event.type
                  .map((type) => {
                    const child = eventTypeMap[type];
                    switch (type) {
                      case "DELIVERY":
                        return child;

                      case "FIXED_TIME":
                        return child;

                      case "TAKE_OUT":
                        return child;

                      case "DROP_IN":
                        return child;
                      default:
                        throw new Error(
                          `renderType called with invalid type - ${type}`
                        );
                    }
                  })
                  .join(" • ")}
              </Typography>
            </div>
          </section>
        </Link>
      </Card>
    );
  },
  (prev, next) => prev.event.id === next.event.id
);
