import pickBy from "lodash/pickBy";
import { IAppInfo, IDishInfo } from "src/models";

export interface RequiredInfoFormState {
  businessName: string;
  address: string;
  hasVentilation: "yes" | "no";
  ventilation: string;
  isPublicSewer: "yes" | "no";
  isPublicWater: "yes" | "no" | "";
  hasDishwasher: "yes" | "no" | "";
  waterCompany: string;
  numberOfPerson: string;
  numberOfRestroom: string;
  numberOfSinks: string;
  question1: string;
  question2: string;
  dishInfos: IDishInfo[];
  idImage: string;
}

export const initialState: RequiredInfoFormState = {
  hasVentilation: "yes",
  ventilation: "",
  isPublicSewer: "yes",
  isPublicWater: "",
  businessName: "",
  address: "",
  hasDishwasher: "",
  waterCompany: "",
  numberOfPerson: "1",
  numberOfRestroom: "1",
  numberOfSinks: "1",
  question1: "",
  question2: "",
  dishInfos: [
    { name: "", ingredients: "" },
    { name: "", ingredients: "" },
    { name: "", ingredients: "" },
  ],

  idImage: "",
};

export interface IDishInputActions {
  idx: number;
  value: string;
  field: "ingredients" | "name";
  type: Actions;
}

export interface IInputActions {
  name: string;
  value: string;
  type: Actions;
}

export enum Actions {
  SET_DISH_INPUT = "SET_DISH_INPUT",
  SET_INPUT = "SET_INPUT",
  INITIALIZE_FORM_STATE = "INITIALIZE_FORM_STATE",
}

export type BecomeCookApplicationActions =
  | IDishInputActions
  | IInputActions
  | Actions;

export const transform = {
  question1: ({ question1 }: IAppInfo) =>
    typeof question1 === "string" && question1 !== "" ? question1 : null,
  question2: ({ question2 }: IAppInfo) =>
    typeof question2 === "string" && question2 !== "" ? question2 : null,
  hasVentilation: ({ hasVentilation }: IAppInfo) =>
    typeof hasVentilation === "boolean"
      ? hasVentilation
        ? "yes"
        : "no"
      : null,
  ventilation: ({ hasVentilation, ventilation }: IAppInfo) =>
    hasVentilation
      ? null
      : typeof ventilation === "string" && ventilation !== ""
      ? ventilation
      : null,
  isPublicSewer: ({ isPublicSewer }: IAppInfo) =>
    typeof isPublicSewer === "boolean" ? (isPublicSewer ? "yes" : "no") : null,
  isPublicWater: ({ isPublicWater }: IAppInfo) =>
    typeof isPublicWater === "boolean" ? (isPublicWater ? "yes" : "no") : null,
  businessName: ({ businessName }: IAppInfo) =>
    typeof businessName === "string" && businessName !== ""
      ? businessName
      : null,
  address: ({ address }: IAppInfo) =>
    typeof address === "string" && address !== "" ? address : null,
  hasDishwasher: ({ hasDishwasher }: IAppInfo) =>
    typeof hasDishwasher === "boolean" ? (hasDishwasher ? "yes" : "no") : null,
  waterCompany: ({ waterCompany }: IAppInfo) =>
    typeof waterCompany === "string" && waterCompany !== ""
      ? waterCompany
      : null,
  numberOfPerson: ({ numberOfPerson }: IAppInfo) =>
    typeof numberOfPerson === "number" ? String(numberOfPerson) : null,
  numberOfRestroom: ({ numberOfRestroom }: IAppInfo) =>
    typeof numberOfRestroom === "number" ? String(numberOfRestroom) : null,
  numberOfSinks: ({ numberOfSinks }: IAppInfo) =>
    typeof numberOfSinks === "number" ? String(numberOfSinks) : null,
  dishInfos: ({ dishInfos }: IAppInfo) =>
    !!dishInfos &&
    dishInfos.every(
      (d) => typeof d.name === "string" && typeof d.ingredients === "string"
    )
      ? dishInfos.map(({ __typename, ...rest }: any) => ({ ...rest }))
      : null,
  idImage: ({ idImage }: IAppInfo) =>
    typeof idImage === "string" && idImage !== "" ? idImage : null,
};

export const formatInitialState = (pl?: IAppInfo | null) => {
  if (!pl) return {};
  else {
    const { __typename, ...payload } = pl;

    const response = Object.entries(payload).reduce((acc, [key, value]) => {
      const transformation = transform[key];
      const newValue = transformation({ [key]: value });
      return { ...acc, [key]: newValue };
    }, {});

    return {
      ...initialState,
      ...pickBy(response, (value) => value !== null),
    };
  }
};

export const formatMutationPL = (state: RequiredInfoFormState) => {
  const hasPrivateSewer = state.isPublicSewer === "no";
  return {
    ...state,
    numberOfSinks: hasPrivateSewer
      ? state.numberOfSinks === ""
        ? null
        : Number(state.numberOfSinks)
      : null,
    numberOfPerson: hasPrivateSewer
      ? state.numberOfPerson === ""
        ? null
        : Number(state.numberOfPerson)
      : null,
    numberOfRestroom: hasPrivateSewer
      ? state.numberOfPerson === ""
        ? null
        : Number(state.numberOfRestroom)
      : null,
    hasVentilation: state.hasVentilation === "yes",
    question1: state.question1 === "" ? null : state.question1,
    question2: state.question2 === "" ? null : state.question2,

    ventilation: state.hasVentilation === "yes" ? null : state.ventilation,
    isPublicSewer: state.isPublicSewer === "yes",
    isPublicWater:
      state.isPublicWater === "" ? null : state.isPublicWater === "yes",
    hasDishwasher:
      state.hasDishwasher === "" ? null : state.hasDishwasher === "yes",
    idImage: state.idImage === "" ? null : state.idImage,
  };
};

export const cookWorkflowSlugs = ["info", "dishes", "uploadId"];
