import {
  Button,
  IconButton,
  LinearProgress,
  makeStyles,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router";
import { BREAK_POINTS, COLORS } from "src/styles";

export const FakeLinearProgress = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <section className={classes.fakeLinearProgressContainer}>
      <div className={classes.fakeLinearProgressActionContainer}>
        <IconButton
          data-testid="BecomeCookInfo-backButton"
          onClick={() => history.goBack()}
          size="small"
        >
          <ChevronLeft className={classes.backArrow} />
        </IconButton>

        <Button
          color="secondary"
          variant="text"
          size="large"
          onClick={() => history.push("/c")}
        >
          Go to profile
        </Button>
      </div>
      <LinearProgress
        color="secondary"
        variant="determinate"
        value={100}
        classes={{
          root: classes.linearProgressBar,
        }}
      />
    </section>
  );
};

const useStyles = makeStyles({
  fakeLinearProgressContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "5rem",
    paddingBottom: "4rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  fakeLinearProgressActionContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem 0",
    marginBottom: "3rem",
  },
  linearProgressBar: {
    height: "0.5rem",
  },
  backArrow: { color: COLORS.MEDIUM_GREEN, fontSize: "4rem" },
});
