import { useQuery } from "@apollo/client";
import { Button, Card, Typography } from "@material-ui/core";
import moment from "moment";
import { stringify } from "query-string";
import React, { useEffect, useState } from "react";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import { ErrorLoadingComponent } from "src/components";
import { amplitude } from "src/services";
import { useMobileHeader } from "src/utils/hooks";
import { COOK_GET_EVENT } from "../detail/graphql";
import { EventDishesCard } from "./event-dishes";
import { SelectMultipleDatesDialog } from "./select-dates-dialog";
import { useStyles } from "./styles";
import { EventSummaryCard } from "./summary-card";

interface EventSummaryContainerProps
  extends RouteComponentProps<any, any, { from: string }> {}

export const EventSummaryContainer: React.FC<EventSummaryContainerProps> = ({
  match,
  location,
  history,
}) => {
  const classes = useStyles();
  const [isRepeatDialogOpen, setRepeatDialogState] = useState<boolean>(false);

  const { loading, error, data } = useQuery(COOK_GET_EVENT, {
    variables: {
      input: match.params.id.split("-")[0],
    },
    fetchPolicy: "no-cache",
  });

  useMobileHeader({
    title:
      data && data.cookGetEvent ? `Summary: "${data.cookGetEvent.name}"` : "",
    pathname: `/c/menus/dishes/${match.params.id}`,
  });

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "cordova") return;

    if (!loading && !data?.cookGetEvent?.dishes?.length) {
      history.push(`/c/menus/edit/${match.params.id}`);
    }
  }, [loading]);

  const onClickPublish = () => {
    setRepeatDialogState(true);
    amplitude
      .getInstance()
      .logEvent(
        `[cook/event form/publish] click "publish" (open multi-date dialog)`
      );
  };

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }

  if (data && data.cookGetEvent && data.cookGetEvent.status === "ACTIVE")
    return (
      <Redirect
        to={{
          pathname: `/c/menus/${match.params.id}`,
          search: stringify({ open: true }),
        }}
      />
    );

  return (
    <>
      <Card
        className={classes.container}
        style={{
          boxShadow: "0 2px 10px 0 #BCBCBC",
          borderRadius: 0,
        }}
      >
        <div className={classes.header}>
          <div className={classes.headerInner}>
            <Typography
              variant="h2"
              component="h1"
              className={classes.mainHeading}
            >
              Review Details
            </Typography>
          </div>
        </div>
        <div className={classes.cardsWrapper}>
          <EventSummaryCard slug={match.params.id} event={data.cookGetEvent} />
          <EventDishesCard
            slug={match.params.id}
            dishes={data.cookGetEvent.dishes}
            isFixedTime={data.cookGetEvent.type.includes("FIXED_TIME")}
          />
        </div>
        <div>
          <div className={classes.actionButtons}>
            <Link to={`/c/menus?status=PENDING`} className={classes.backButton}>
              <Typography component="span" variant="body2">
                Back to My Menus
              </Typography>
            </Link>
            <Button
              onClick={onClickPublish}
              variant="contained"
              classes={{ contained: classes.publishButton }}
            >
              Publish!
            </Button>
          </div>
        </div>
      </Card>
      <SelectMultipleDatesDialog
        eventId={data.cookGetEvent.id}
        open={isRepeatDialogOpen}
        onClose={() => setRepeatDialogState(false)}
        SelectMultipleEventDatesProps={{
          eventStartDateTime: moment(Number(data.cookGetEvent.startTime)),
        }}
      />
    </>
  );
};
