import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  tagsList: { display: "inherit", padding: 0, margin: 0, flexWrap: "wrap" },
  inputWrapper: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    border: `solid 1px ${COLORS.LIGHT_GREY}`,
    borderRadius: 2,
    padding: ".5rem .25rem",
    cursor: "text",
    overflow: "auto",
    marginBottom: "1.5rem",
  },
  deletableChip: {
    marginRight: ".25rem",
    backgroundColor: COLORS.BLACK,
    marginTop: 4,
    color: COLORS.WHITE,
    height: 21,

    textTransform: "capitalize",
  },
  inputLabel: {},
  inputRoot: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    "&::placeholder": {
      fontStyle: "italic",
    },
  },
});
