import GoogleMapReact, { ChangeEventValue } from "google-map-react";
import { parse } from "query-string";
import React, { FC, useCallback, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDelay } from "src/utils/hooks";
import { ListMapContext } from "../container";
import { ActionTypes } from "../reducer";
import { RestaurantMarker } from "./restaurant-marker";
import { YourLocationMarker } from "./your-location-marker";

interface IProps {
  defaultOptions?: any;
  search: (any) => void;
  data: any;
  loading: boolean;
}
export const MapContainer: FC<IProps> = React.memo(
  ({ defaultOptions, search, data, loading }) => {
    const {
      state: { restaurants, bounds },
      dispatch,
    } = useContext(ListMapContext);
    const _bounds = useDelay(bounds, 1000);
    const location = useLocation();

    const params = parse(location.search) as {
      location: string;
      longitude: string;
      latitude: string;
    };

    useEffect(() => {
      dispatch({
        type: ActionTypes.SET_RESTAURANT_LOADING,
        payload: !!loading,
      });
      !loading &&
        dispatch({
          type: ActionTypes.SET_RESTAURANTS,
          payload: !!data?.searchRestaurants?.length
            ? data.searchRestaurants
            : [],
        });
    }, [data, loading]);

    useEffect(() => {
      _bounds &&
        search({
          variables: {
            input: { bounds: _bounds },
          },
        });
    }, [_bounds]);

    useEffect(() => {
      dispatch({ type: ActionTypes.SET_RESTAURANT_LOADING, payload: true });
    }, [bounds]);

    const setViewport = useCallback((d: ChangeEventValue) => {
      dispatch({
        type: ActionTypes.SET_MAP_POSITION,
        payload: { bounds: d.bounds, center: d.center },
      });
    }, []);

    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_API as string }}
        defaultCenter={{ lat: 33.783, lng: -117.257 }}
        defaultZoom={10}
        options={defaultOptions}
        margin={[30, 30, 30, 30]}
        zoom={10}
        onChange={setViewport}
        hoverDistance={50}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) =>
          dispatch({ type: ActionTypes.SET_MAP, payload: map })
        }
      >
        {restaurants.map((res) => (
          <RestaurantMarker
            key={res.id}
            lat={res.locale?.coordinates.latitude}
            lng={res.locale?.coordinates.longitude}
            restaurant={res}
          />
        ))}
        {params.longitude && params.latitude && (
          <YourLocationMarker lat={params.latitude} lng={params.longitude} />
        )}
      </GoogleMapReact>
    );
  }
);
