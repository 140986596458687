import { useLazyQuery } from "@apollo/client";
import { Button, Dialog, Typography, useMediaQuery } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { parse } from "query-string";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IGetMe } from "src/models";
import { amplitude } from "src/services";
import { BREAK_POINTS } from "src/styles";
import { LocalStorageHelper } from "src/utils/helpers";
import { GET_REFERRER_FROM_CODE } from "./graphql";
import { useStyles } from "./styles";

interface IProps {
  getMe?: IGetMe;
}
export const ReferreeDialog: FC<IProps> = ({ getMe }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [getReferrer, { data, error }] = useLazyQuery(GET_REFERRER_FROM_CODE);
  const d = parse(location.search);
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

  useEffect(() => {
    if (d.referral) {
      setOpen(true);
      getReferrer({ variables: { input: d.referral } });
    }
    amplitude.getInstance().logEvent("[Referree Dialog] landing");
  }, []);

  useEffect(() => {
    if (data) {
      LocalStorageHelper.setItem(
        "foodnome-referree-code",
        d.referral as string
      );
    }
  }, [data]);

  const close = () => {
    setOpen(false);
    history.push(location.pathname);
  };
  return (
    <Dialog
      open={open}
      onClose={close}
      classes={{ paper: classes.dialogPaper }}
    >
      <>
        <button className={classes.closeIconBtn} data-e2e="close-dialog">
          <Close className={classes.mdClose} onClick={close} />
        </button>

        <section className={classes.imgContainer}>
          <img
            src={require(isMobile
              ? "./assests/referree-sm.jpg"
              : "./assests/referree-lg.jpg")}
            alt="diner"
            className={classes.img}
          />
        </section>
        <section className={classes.infoContainer}>
          <img
            className={classes.logo}
            alt="foodnome-logo"
            src={require("src/assets/logos/cook.svg")}
          />
          <Typography variant="h3" className={classes.h3}>
            {!!(error || getMe) ? (
              <>Looks like the referral link did not work</>
            ) : (
              <>
                {data?.userGetReferrerFromCode?.firstName} invited you to sign
                up on <span className={classes.foodnome}>Foodnome</span>, redeem
                your $5!
              </>
            )}
          </Typography>
          {!(error || getMe) && (
            <Typography variant="body2" className={classes.body1}>
              Welcome to COOK Connect, the official marketplace for local, fresh
              homemade food! Thanks to{" "}
              {data?.userGetReferrerFromCode?.firstName}, you both will{" "}
              <span className={classes.boldText}>
                receive $5 credits once you make your first order of $10 or
                more!
              </span>{" "}
              Sign up & browse through delicious authentic food options near
              you! Happy eating 😋
            </Typography>
          )}

          <Button
            variant="contained"
            color="secondary"
            className={classes.continueBtn}
            onClick={() => {
              history.push("/menus");
              setOpen(false);
            }}
          >
            Browse Meals
          </Button>
        </section>
      </>
    </Dialog>
  );
};
