import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,

    width: "100%",
    height: "100%",
  },
  topSection: {
    height: "100%",
    flexShrink: 0,
    flexGrow: 1,
    minHeight: "50rem",
    padding: "0 3rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  bottomSection: {
    alignSelf: "flex-end",
    justifySelf: "flex-end",
    position: "relative",
    backgroundColor: COLORS.RED,

    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "2rem",

    justifyContent: "center",
    margin: "auto",
    alignItems: "center",
    paddingBottom: 0,
    [`@media (max-width: ${BREAK_POINTS.mobileSmall}em)`]: {
      paddingTop: "3rem",
    },
  },
  divContainer: {
    width: "95%",
    backgroundColor: COLORS.WHITE,
    marginTop: "-10rem",
  },
  image: {
    position: "fixed",
    zIndex: -999,
    top: 0,
    height: "80%",
    objectFit: "cover",
    filter: "brightness(50%)",
    left: 0,
    [`@media (max-width: ${BREAK_POINTS.mobileRegular}em)`]: {
      left: -250,
    },
    [`@media (max-width: ${BREAK_POINTS.mobileSmall}em)`]: {
      height: "100%",
    },
  },
  title: {
    textAlign: "center",
    marginBottom: "1.5rem",
    color: COLORS.WHITE,
  },
  text: {
    textAlign: "center",
    fontFamily: "CustomFourMedium",
    marginBottom: "2rem",
    color: COLORS.WHITE,
    lineHeight: "2.6rem",
  },
  foodnomeLogoWhite: {
    width: "50%",
    position: "absolute",
    left: "50%",
    top: "2.5rem",
    transform: "translate(-50%, 0)",
  },

  logo: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0)",
    top: "-10%",
    width: "15%",
    maxWidth: "7rem",
  },
  textContainer: {
    width: "100%",
    maxWidth: "40rem",
  },
  squig: {
    position: "absolute",
    right: -400,
    bottom: -200,
    width: "55rem",
  },
  blob: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "60%",
    maxWidth: "37rem",
  },
});
