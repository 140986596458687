declare let window: any;

export const openWindow = (link, w = 550, h = 400) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;

  const config: { [key: string]: string | number } = {
    left,
    top,
    width: w / systemZoom,
    height: h / systemZoom,
    location: "no",
    toolbar: "no",
    status: "no",
    directories: "no",
    menubar: "no",
    scrollbars: "yes",
    resizable: "no",
    centerscreen: "yes",
    chrome: "yes",
  };

  if (window.cordova) {
    window.cordova.InAppBrowser.open(link, "_system", "location=yes");
  } else {
    window.open(
      link,
      "",
      Object.keys(config)
        .map((key) => `${key}=${config[key]}`)
        .join(", ")
    );
  }
};

export const objectToGetParams = (
  object: {
    [key: string]: string | number | undefined | null;
  },
  type: "SMS" | undefined | null | string
) => {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    );

  // sms requires special params.
  // https://stackoverflow.com/questions/6480462/how-to-pre-populate-the-sms-body-text-via-an-html-link
  const encodedParams =
    type === "SMS" ? `?&${params.join("&")}` : `?${params.join("&")}`;

  return params.length > 0 ? encodedParams : "";
};
