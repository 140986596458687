import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    padding: "1rem 0",
  },
  reviewHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
    padding: "0 1rem",
  },
  reviewAuthor: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.BLACK,

    fontStyle: "normal",
    textTransform: "capitalize",

    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    overflow: "hidden",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: 100,
      maxHeight: 22,
    },
  },
  actionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  reviewDate: {
    color: COLORS.DARK_GREY,
    textAlign: "right",
  },
  replyBtn: {
    width: 50,
    marginTop: 4,
    fontFamily: "CustomFourBold",
    backgroundColor: "unset",
    cursor: "pointer",
    color: COLORS.MEDIUM_GREEN,
    border: `solid 1px ${COLORS.MEDIUM_GREEN}`,
  },
  reviewText: {
    whiteSpace: "pre-wrap",
    display: "inline",
    margin: 0,
  },
  avatar: {
    width: 68,
    height: 68,

    fontFamily: "CustomFourBold",
    color: COLORS.WHITE,
    textTransform: "uppercase",
    fontWeight: 500,
    backgroundColor: COLORS.RED,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: 51,
      height: 51,
    },
  },
  cookAvatar: {
    width: 51,
    height: 51,

    fontFamily: "CustomFourBold",
    color: COLORS.WHITE,
    textTransform: "uppercase",
    fontWeight: 500,
    backgroundColor: COLORS.RED,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: 31,
      height: 31,
    },
  },
  expansionButton: {
    color: COLORS.DARK_GREY,

    fontWeight: 500,

    textTransform: "capitalize",
  },
  padding: {
    padding: "0.5rem 0",
  },
  textArea: {
    width: "100%",
    borderRadius: "4px",

    padding: 4,

    boxShadow: "none",
    border: `1px solid ${COLORS.LIGHT_GREY}`,
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  cancelBtn: {
    color: COLORS.MEDIUM_GREEN,
    marginRight: 6,
    marginTop: 4,
  },
  submitBtn: {
    marginTop: 4,
    color: COLORS.WHITE,
  },
  cookResponseContainer: {
    backgroundColor: "#f0f0f0",
    marginLeft: "10%",
    display: "flex",
    padding: 4,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginLeft: "unset",
    },
  },
  cookResponseTitle: {
    marginLeft: 6,
  },
  hostName: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.BLACK,

    fontStyle: "normal",
    textTransform: "capitalize",

    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    overflow: "hidden",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: 100,
      maxHeight: 22,
    },
  },
});
