import { useQuery } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import { IGetMe } from "src/models";
import { GET_ME } from "../header/api/graphql";
import { _ChatContext } from "./chat-provider";
import { List } from "./list";
import { ChatRoom } from "./room/chat-room";
import "./styles.css";

declare let window: any;

export const StreamChatContainer = () => {
  const {
    state: { loading, activeChannel },
  } = useContext(_ChatContext);
  const { data } = useQuery<{ getMe: IGetMe }>(GET_ME);

  // Dynamically adds and removes padding to the bottom of body when on the chat containter
  useEffect(() => {
    if (window.cordova && window.Keyboard) {
      window.Keyboard.hideFormAccessoryBar(true);
    }
    return () => {
      if (window.cordova && window.Keyboard) {
        window.Keyboard.hideFormAccessoryBar(false);
      }
    };
  }, []);

  if (loading) return null;
  return (
    <div
      style={{
        height: window.innerHeight,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          ...(activeChannel && {
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
            height: "100%",
          }),
          width: "100%",
        }}
      >
        {/* {activeChannel ? (
          <ChatRoom adminList={adminIDList} getMe={data?.getMe} />
        ) : (
          <List activeChannel={activeChannel} getMe={data?.getMe} />
        )} */}

        {data?.getMe && <ChatRoom getMe={data.getMe} />}
        {!activeChannel && (
          <List activeChannel={activeChannel} getMe={data?.getMe} />
        )}
      </div>
    </div>
  );
};
