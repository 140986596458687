import { gql } from "@apollo/client";

export const GET_COOK_RESTAURANT = gql`
  query cookGetRestaurant {
    cookGetRestaurant {
      id
      name
      description
      tags
      imagesGQL {
        publicId
        small
        medium
        large
        original
      }
      address
      hostBio
      updatedAt
      createdAt
      permitNumber
      application {
        id
        status
      }
    }
  }
`;
