import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Fade } from "react-awesome-reveal";
import { COLORS } from "src/styles";
import { SearchContainer } from "../../../components/search-container";
import { useStyles } from "./top-desktop.styles";

export const TopDesktop: FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.container}>
      <video autoPlay muted loop playsInline className={classes.video}>
        <source
          src="https://foodnome.sfo2.digitaloceanspaces.com/videos/home.mp4"
          type="video/mp4"
        />
      </video>
      <div className={classes.innerContainer}>
        <Fade triggerOnce={true}>
          <div className={classes.card}>
            <Fade triggerOnce={true} delay={250}>
              {/* replaced logo circle_bug-11.svg with logo_cook.png */}
              <img
                src={require("src/assets/logos/cook.png")}
                className={classes.logo}
              />
            </Fade>
            <Fade triggerOnce={true} delay={500}>
              <Typography variant="h1" component="h1" className={classes.title}>
                Order <span style={{ color: COLORS.MEDIUM_RED }}>Home-Cooked</span>{" "}
                Food from Local Chefs!
              </Typography>
            </Fade>
            <Fade triggerOnce={true} delay={750}>
              <img
                src={require("../../../assets/underline.svg")}
                className={classes.squiggle}
              />
            </Fade>
            <Fade triggerOnce={true} delay={1000}>
              <Typography variant="h4" component="h4" className={classes.text}>
                Eat authentic, homemade food & support small family businesses
                in your neighborhood!
              </Typography>
            </Fade>
            <Fade
              triggerOnce={true}
              delay={1250}
              className={classes.searchContainer}
            >
              <SearchContainer />
            </Fade>
          </div>
        </Fade>
      </div>
    </section>
  );
};
