interface Navlink {
  name: string;
  pathname: string;
}

export const globalMenuLinks: Navlink[] = [
  {
    name: "Home",
    pathname: "/",
  },
  {
    name: "Find a Meal",
    pathname: "/menus",
  },
  {
    name: "Sell your Food",
    pathname: "/sell-your-food",
  },
  {
    name: "How it works",
    pathname: "/how-it-works",
  },
  {
    name: "Mission",
    pathname: "/mission",
  },
   {
    pathname: "/map",
    name: "MEHKO Map",
  },
  // {
  //   name: "Legislation",
  //   pathname: "/legislation",
  // },
  {
    name: "FAQ",
    pathname: "/faq",
  },
  // {
  //   pathname: "/press",
  //   name: "Press",
  // },
  {
    name: "Contact",
    pathname: "/contact",
  },
  // {
  //   name: "Privacy Policy",
  //   pathname: "/privacy",
  // },
  // {
  //   name: "Terms & Conditions",
  //   pathname: "/terms-and-conditions",
  // },
];
