import Skeleton from "@material-ui/lab/Skeleton";
import classNames from "classnames";
import React from "react";
import { useStyles } from "./restaurant-card.styles";

export const RestaurantSkeleton = () => {
  const classes = useStyles();

  return (
    <li className={classNames(classes.flexColumn, classes.card)}>
      <section className={classNames(classes.container, classes.flexRow)}>
        <div className={classes.flexColumn} style={{ flexGrow: 1 }}>
          {/* Name/Title */}
          <section className={classes.flexRow} style={{ marginBottom: "1rem" }}>
            <Skeleton variant="text" width={200} />
          </section>

          {/* Avatar and Info */}
          <section className={classes.flexRow}>
            <div className={classes.flexColumn}>
              <Skeleton
                variant="circle"
                style={{ marginRight: "1rem" }}
                width={70}
                height={70}
              />
            </div>
            <div className={classes.flexColumn}>
              <div className={classes.flexRow}>
                <Skeleton variant="text" width={100} />
              </div>

              <div
                className={classNames(
                  classes.flexRow,
                  classes.locationContainer
                )}
              >
                <Skeleton variant="text" width={100} />
              </div>
              <div className={classes.flexRow}>
                <Skeleton variant="text" width={100} />
              </div>
            </div>
          </section>
          <section className={classes.flexRow} style={{ marginTop: "auto" }}>
            <Skeleton variant="text" width={150} />
          </section>
        </div>

        <section
          className={classes.flexColumn}
          style={{ position: "relative" }}
        >
          <Skeleton variant="rect" width={250} height={150} />
        </section>
      </section>

      <section
        className={classes.chipContainer}
        style={{ marginLeft: "3rem", marginTop: "1rem" }}
      >
        {[1, 2, 3, 4].map((e) => (
          <Skeleton
            key={e}
            width={70}
            height={30}
            style={{ marginRight: "1rem" }}
          />
        ))}
      </section>
    </li>
  );
};
