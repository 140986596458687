import { Button, Popover } from "@material-ui/core";
import { Reply } from "@material-ui/icons";
import React, { FC, useState } from "react";
import { useStyles } from "./share-button.styles";
import { ShareButtonsContainer } from "./share-buttons-container";

interface IProps {
  shareUrl: string;
}

export const ShareButton: FC<IProps> = ({ shareUrl }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    // Not sure why copy to clipboard only works here and not in share buttons container
    // copyToClipboard(shareUrl);
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <>
      <Button size="small" className={classes.btn} onClick={handleClick}>
        <Reply className={classes.btnIcon} />{" "}
        <span className={classes.btnLabel}>Share</span>
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ marginTop: "10px" }}
      >
        <ShareButtonsContainer shareUrl={shareUrl} />
      </Popover>
    </>
  );
};
