import { gql } from "@apollo/client";

export const COOK_GET_EVENT = gql`
  query cookGetEvent($input: ID!) {
    cookGetEvent(id: $input) {
      id
      name
      description
      locale {
        public
        private
      }
      instructions
      minSeats
      maxSeats
      reserveBy
      startTime
      endTime
      bufferTime
      imagesGQL {
        publicId
        small
        medium
        large
        original
      }
      deliveryInfo {
        baseAmount
        maximumDriveTime
        minimumOrderAmount
        pricePerMin
        pricePerMile
      }
      private
      type
      status
      guestCount
      totalSale
      updatedAt
      soldOutTimeSlots
      requesters {
        id
      }
      guests {
        id
        firstName
        lastName
        picture
        phoneNumber
        points {
          eventsAttended
        }
        eventUser {
          id
          reservedFor
          deliveryAddress
          arrivalTime
          dineOption
          phone {
            phone
          }
          eventId
          checkedIn
          updatedAt
          notes
          orderedDishes {
            id
            name
            __typename @skip(if: true)
            EventUserDish {
              id
              count
              options {
                name
                count
                addition
              }
            }
          }
          orders {
            id
            reservedFor
            deliveryAddress
            arrivalTime
            dineOption
            checkedIn
            updatedAt
            notes
            orderedDishes {
              id
              name
              __typename @skip(if: true)
              OrderDish {
                id
                count
                options {
                  name
                  count
                  addition
                }
              }
            }
          }
        }
      }
      price
      dishes {
        id
        name
        imagesGQL {
          publicId
          small
          medium
          large
          original
        }
        DishEvent {
          id
          count
          price
          sold
          options {
            name
            count
            sold
            addition
          }
        }
        __typename @skip(if: true)
      }
    }
  }
`;

export const COOK_GET_EVENT_GUESTS = gql`
  query cookGetEventGuests($input: ID!) {
    cookGetEventGuests(id: $input) {
      id
      firstName
      lastName
      address
      picture
      points {
        eventsAttended
      }
      eventUser {
        id
        reservedFor
        arrivalTime
        dineOption
        checkedIn
      }
    }
  }
`;

export const COOK_CREDIT_GUEST = gql`
  mutation cookCreditGuest($input: CookCreditGuestPL!) {
    cookCreditGuest(cookCreditGuestPL: $input)
  }
`;

export const COOK_CANCEL_GUEST = gql`
  mutation cookCancelEventUser($input: CookCreditGuestPL!) {
    cookCancelEventUser(cookCreditGuestPL: $input)
  }
`;

export const COOK_CANCEL_EVENT_USER = gql`
  mutation cookCancelEventUser($input: ID!) {
    cookCancelEventUser(id: $input) {
      id
      status
    }
  }
`;

export const COOK_UPDATE_SOLD_OUT_TIME_SLOTS = gql`
  mutation cookUpdateSoldOutTimeSlots($input: CookUpdateSoldOutTimeSlotsPL!) {
    cookUpdateSoldOutTimeSlots(cookUpdateSoldOutTimeSlotsPL: $input) {
      id
      soldOutTimeSlots
    }
  }
`;

export const COOK_MARK_SOLD_OUT = gql`
  mutation cookUpdateDishEvent($input: CookUpdateDishEventPL!) {
    cookUpdateDishEvent(cookUpdateDishEventPL: $input) {
      id
      count
      price
      sold
    }
  }
`;

export const COOK_GET_COUPON = gql`
  query cookGetCoupon {
    cookGetCoupon {
      id
      code
      percent
    }
  }
`;

export const COOK_MESSAGE_FOLLOWERS = gql`
  mutation cookMessageGuests($input: CookMessageGuestsPL!) {
    cookMessageGuests(cookMessageGuestsPL: $input)
  }
`;
