import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "src/styles";

export const useStyles = makeStyles((theme) => ({
  formGroup: {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fill, minmax(120px, 1fr))`,
  },
  formControl: {
    marginTop: "1rem",
    width: "100%",
  },
  formLabel: {
    color: COLORS.BLACK,
    "&.focused": {
      color: COLORS.BLACK,
    },
  },
  rootCheckLabel: { ...theme.typography.body2 },
}));
