import { Chip, Divider, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { parse, stringify } from "query-string";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { DesktopDummyEventsList, FetchLoading } from "src/components";
import { IEvent, IGetMe } from "src/models";
import { COLORS } from "src/styles";
import { LocalStorageHelper } from "src/utils/helpers";
import { IFetchMorePL } from "./container";
import { EventList } from "./event-list";
import { Hero } from "./hero";
import { layoutStyles as useStyles } from "./layout.styles";
import { OtherMeals } from "./other-meals";

interface IProps {
  searchEvents: { count: number; rows: IEvent[] };
  loading: boolean;
  error: any;
  user: IGetMe;
  fetchMore: (d: IFetchMorePL) => any;
  handleSearch: (rawInput: string) => void;
  isFetchInflight: boolean;
  containerRef: any;
}

export const Layout: React.FC<IProps> = React.memo(
  ({
    searchEvents,
    loading,
    error,
    fetchMore,
    user,

    handleSearch,
    isFetchInflight,
    containerRef,
  }) => {
    const classes = useStyles();
    // state for tags
    const [_tags, _setTags] = useState<string[]>([]);
    // state for diner ad
    const [open, setOpen] = useState(false);
    const timeoutRef = React.useRef<any>(null);
    const getMeRef = React.useRef<any>();
    const history = useHistory();

    // Effects for diner promo ad
    // useEffect(() => {
    //   if (isEqual(user, getMeRef.current)) return;
    //   if (!user) {
    //     timeoutRef.current = setTimeout(() => {
    //       setOpen(true);
    //       amplitude.getInstance().logEvent('[Event List] ads pop up');
    //     }, 6000);
    //     return () => {
    //       clearTimeout(timeoutRef.current);
    //     };
    //   }
    // }, [user]);

    // useEffect(() => {
    //   getMeRef.current = user;
    // });

    useEffect(() => {
      const { tags = [] } = parse(location.search);
      if (tags) {
        _setTags(typeof tags === "string" ? [tags] : tags);
      }
    }, [location.search]);

    const onSubmit = (event) => {
      event.preventDefault();
      const { search, address } = event.target.elements;
      if (search) {
        handleSearch(search.value);
        search.value = "";
      } else if (address) {
        history.push({
          pathname: "/menus",
          search: stringify({
            location: address.value,
          }),
        });
        // SET LOCATION PREFERENCE INTO LOCAL STORAGE
        if (address.value)
          LocalStorageHelper.setItem(
            "FOODNOME_LOCATION_PREFERENCE",
            JSON.stringify({ location: address.value })
          );

        address.blur();
      }
    };

    const handleDeleteTag = (tag: string) => {
      const { tags: prevTags, ...rest } = parse(location.search);
      const result = _tags.filter((t) => t !== tag);
      const payload = Object.assign(
        {},
        {
          search: stringify(
            result.length ? { tags: result, ...rest } : { ...rest }
          ),
        }
      );
      history.push(payload);
    };

    const queryObj = parse(location.search) as {
      location: string;
      tags: string[];
      longitude?: string;
      latitude?: string;
    };

    return (
      <>
        <Hero onSubmit={onSubmit} queryObj={queryObj} />

        {(queryObj.location || (queryObj.latitude && queryObj.longitude)) && (
          <div className={classes.addressContainer}>
            <div className={classes.addressContainerInner}>
              <Typography
                variant="body2"
                component="p"
                className={classes.addressLabel}
              >
                Homemade Food in your areas:{" "}
              </Typography>
              <Chip
                deleteIcon={
                  <Close
                    data-testid="delete-location-chip"
                    className={classes.icon}
                  />
                }
                onDelete={() => {
                  const {
                    location: _,
                    longitude: __,
                    latitude: ___,
                    ...rest
                  } = Object.assign({}, queryObj) as any;
                  history.push({ search: stringify(rest) });
                  window.localStorage.removeItem(
                    "FOODNOME_LOCATION_PREFERENCE"
                  );
                }}
                label={queryObj.location ? queryObj.location : "Your Location"}
                classes={{
                  root: classes.chipRootLocation,
                  deletable: classes.deletableLocation,
                  deleteIcon: classes.deleteIconLocation,
                  label: classes.chipLabelLocation,
                }}
              />
            </div>
            <Divider />
          </div>
        )}

        {!!_tags.length && (
          <div className={classes.tagsContainer}>
            <Typography
              variant="body2"
              component="p"
              className={classes.tagsLabel}
            >
              Tags:{" "}
            </Typography>
            <ul
              style={{ display: "flex", padding: 0 }}
              data-testid="searched-tag-list"
            >
              {_tags.map((tag, index) => (
                <Chip
                  component={"li" as "div"}
                  deleteIcon={
                    <Close
                      data-testid={`delete-tag-${tag}`}
                      className={classes.icon}
                    />
                  }
                  onDelete={() => handleDeleteTag(tag)}
                  key={index}
                  label={tag}
                  classes={{
                    root: classes.chipRoot,
                    deletable: classes.deletable,
                    deleteIcon: classes.deleteIcon,
                  }}
                />
              ))}
            </ul>
          </div>
        )}

        <FetchLoading appear={isFetchInflight} />
        <div ref={containerRef}>
          {/* Events list for search events with location or tag */}
          {loading ? (
            <DesktopDummyEventsList />
          ) : searchEvents && searchEvents.count > 0 ? (
            <EventList searchEvents={searchEvents} loading={loading} />
          ) : (
            <div className={classes.notFound}>
              <Typography
                variant="h4" // variant was body2 prior to cook transition
                component="p"
                className={classes.notFoundText}
              >
                Looks like there aren’t any cooks selling food in your area right now via
                COOK Connect.
                Click
                {" "}
                <Link
                  style={{ color: COLORS.DARK_RED, textDecoration: "none" }}
                  to="/restaurants"
                >here
                </Link>
                {" "}to view a map of all permitted MEHKOs!
              </Typography>
            </div>
          )}
        </div>

        {/* Events list "other" events in your area */}
        <OtherMeals isDisplayed={searchEvents && searchEvents.count === 0} />
      </>
    );
  }
);
