import { makeStyles } from "@material-ui/core/styles";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    maxWidth: 365,
    maxHeight: 269,
    margin: "0 auto",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      minHeight: 200,
    },
  },
  initialDragHotSpot: {
    border: `dashed 3px ${COLORS.DARK_GREY}`,
    width: "100%",
    height: 269,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,

    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.DARK_GREY,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      height: 200,
    },
  },
  carouselNavButton: {
    minWidth: 0,
    backgroundColor: `rgba(0, 0, 0, 0.5)`,
    padding: "1rem",
  },
  cheveron: {
    width: 18,
  },
  carouselNavButtonsWrapper: {
    position: "absolute",
    top: 0,
    bottom: 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  carouselActionButtonsWrapper: {
    position: "absolute",
    bottom: "1em",
    left: "1em",
    zIndex: 2,
  },
  carouselFab: {
    ...theme.typography.body2,
    backgroundColor: COLORS.WHITE,
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: "rgba(255, 255, 255, 0.7)",
    transition: "all 300ms linear",
    zIndex: 4,
  },
}));
