import { useMutation } from "@apollo/client";
import { Button, Card, Typography } from "@material-ui/core";
import { parse } from "query-string";
import React, { useEffect } from "react";
import { USER_UPDATE_PREFERENCE } from "./api";
import { useStyles } from "./styles";

interface IProps {
  history: any;
}

export const Subscribe: React.FC<IProps> = ({ history }) => {
  const classes = useStyles();
  const [userUpdatePreference, { data }] = useMutation(USER_UPDATE_PREFERENCE);

  useEffect(() => {
    const query = parse(location.search) as { token: string };
    if (query && query.token) {
      userUpdatePreference({ variables: { input: query.token } }).then(() =>
        history.push("/subscription")
      );
    }
  }, []);

  return (
    <div className={classes.container}>
      <section className={classes.contentWrapper}>
        <Card className={classes.card}>
          <Typography
            variant="h1"
            component="h1"
            className={classes.mainHeading}
          >
            Unsubscribed
          </Typography>
          <img
            className={classes.image}
            src={require("./assets/email.png")}
            alt="drawing of an envelope."
          />
          <Typography
            variant="h2"
            component="h2"
            className={classes.subHeading}
          >
            We are sad to see you go :(
          </Typography>
        </Card>
        <div className={classes.buttonsWrapper}>
          <Button
            fullWidth
            classes={{ root: classes.rootButton1 }}
            variant="contained"
            onClick={() => history.push("/")}
          >
            {"Go back to the home page"}
          </Button>
          <Button
            fullWidth
            classes={{ root: classes.rootButton2 }}
            variant="contained"
            onClick={() => history.push("/menus")}
          >
            {"View Home Restaurants"}
          </Button>
        </div>
      </section>
    </div>
  );
};
