import { gql } from "@apollo/client";

export const UPDATE_USER_INFO = gql`
  mutation updateUserAccount(
    $updateUserInfo: UpdateUserInfo!
    $updateUserPreferences: UpdateUserPreferences
  ) {
    updateUser(
      updateUserInfo: $updateUserInfo
      updateUserPreferences: $updateUserPreferences
    ) {
      id
      email
      role
      birthday
      picture
      address
      phoneNumber
      description
      firstName
      lastName
      preferences {
        NEWS_LETTER_EMAIL_SUBSCRIPTION
      }
    }
  }
`;
