import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ResponsiveDialog } from "src/components";
import { EditDishContainer } from "src/screens/cook/dish/edit/container";
import { COLORS } from "src/styles";

export const AdminEditDishDialog = (rest) => {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const handleClose = () =>
    history.push(location.pathname.replace(`/${id}`, ""));

  return (
    <ResponsiveDialog
      style={{ minHeight: "100vh" }}
      {...rest}
      onClose={handleClose}
      open={!!id}
    >
      <div
        style={{ padding: "1rem", display: "flex", flexDirection: "column" }}
      >
        <IconButton onClick={handleClose} style={{ alignSelf: "flex-end" }}>
          <Close style={{ color: COLORS.MEDIUM_GREEN, fontSize: "3rem" }} />
        </IconButton>
        <EditDishContainer onDishCreated={handleClose} />
      </div>
    </ResponsiveDialog>
  );
};
