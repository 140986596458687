export const initialState = {
  value: "overview",
};

export default function reducer(state, action) {
  switch (action.type) {
    case "SET_TAB_VALUE":
      return { ...state, value: action.value };
    default:
      throw Error(
        `Restaurant Tabs Reducer called with invalid action: ${action.type}`
      );
  }
}
