import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ErrorLoadingComponent } from "src/components";
import { IUser } from "src/models";
import { memberListAPI } from "../api";
import { GET_ALL_USERS } from "../api/graphql";
import { Layout } from "./layout";

export const MemberListContainer = () => {
  const { data, loading, error, fetchMore } = useQuery<{
    adminGetUsers: { count: number; rows: IUser[] };
  }>(GET_ALL_USERS, {
    variables: {
      input: {
        pagePL: { limit: 10 },
        dinerOnly: false,
      },
    },
    fetchPolicy: "cache-first",
    errorPolicy: "ignore",
  });
  const history = useHistory();

  const [pageNum, setPageNum] = useState(1);

  const redirect = (url: string) => history.push(url);

  const _deleteUser = (id: number) => {
    if (data?.adminGetUsers.rows.length === 1 && pageNum > 1) {
      setPageNum(pageNum - 1);
      return memberListAPI.deleteUser(id, (pageNum - 2) * 10);
    }
    return memberListAPI.deleteUser(id, (pageNum - 1) * 10);
  };

  const _fetchMore = (d: { pageNumber: number; searchTerm?: string }) => {
    fetchMore({
      variables: {
        input: {
          pagePL: { limit: 10, offset: (d.pageNumber - 1) * 10 },
          searchTerm: d.searchTerm,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          adminGetUsers: (fetchMoreResult as any).adminGetUsers,
        });
      },
    });
  };

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }
  return (
    <Layout
      totalCount={data?.adminGetUsers.count as number}
      users={data?.adminGetUsers.rows as IUser[]}
      onFetchMore={_fetchMore}
      redirect={redirect}
      deleteUser={_deleteUser}
      editUser={memberListAPI.editUser}
    />
  );
};
