import React, { FunctionComponent } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { IImage } from "../../models";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

interface IProps {
  images: IImage[];
  step: number;
  setStep: (d: number) => void;
  onClick?: () => void;
}

export const ViewPort: FunctionComponent<IProps> = ({
  images,
  step,
  setStep,
  onClick,
}) => (
  <SwipeableViews index={step} onChangeIndex={setStep} enableMouseEvents>
    {images.map((img, index) => (
      <div key={img.publicId} style={{ overflow: "hidden" }} onClick={onClick}>
        {Math.abs(step - index) <= 2 ? (
          <img
            src={img.small}
            alt={img.original}
            style={{
              cursor: "pointer",
              width: "100%",
              height: 250,
              objectFit: "cover",
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
            }}
          />
        ) : null}
      </div>
    ))}
  </SwipeableViews>
);
