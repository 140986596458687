import React, { ReactElement } from "react";
import { IGetMe } from "src/models";
import {
  InSlide1,
  InSlide2,
  InSlide3,
  InSlide4,
  OutSlide1,
  OutSlide2,
} from "./stepper-content/index";

interface IFlowProps {
  getMe: IGetMe;
  classes: any;
}

export interface IFlowContent {
  slug: string;
  left: ReactElement;
  right: ReactElement;
}

export const outCounty = ({ getMe, classes }: IFlowProps) =>
  [
    {
      slug: "intro",
      left: <OutSlide1 getMe={getMe} />,
      right: (
        <img
          src={require(`./assets/images/cook.jpg`)}
          className={classes.image}
        />
      ),
    },
    {
      slug: "ab626",
      left: <OutSlide2 />,
      right: (
        <img
          src={require(`./assets/images/kitchen.jpg`)}
          className={classes.image}
        />
      ),
    },
  ] as IFlowContent[];

export const InCounty = ({ getMe, classes }: IFlowProps) =>
  [
    {
      slug: "intro",
      left: <InSlide1 getMe={getMe} />,
      right: (
        <img
          src={require(`./assets/images/cook.jpg`)}
          className={classes.image}
        />
      ),
    },
    {
      slug: "question1",
      left: <InSlide2 />,
      right: (
        <img src={require(`./assets/images/1.jpg`)} className={classes.image} />
      ),
    },
    {
      slug: "question2",
      left: <InSlide3 />,
      right: (
        <img src={require(`./assets/images/2.jpg`)} className={classes.image} />
      ),
    },
    {
      slug: "question3",
      left: <InSlide4 />,
      right: (
        <img src={require(`./assets/images/3.jpg`)} className={classes.image} />
      ),
    },
  ] as IFlowContent[];
