import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { DialogProps as MUIDialogProps } from "@material-ui/core/Dialog";
import { Close } from "@material-ui/icons";
import moment from "moment";
import { stringify } from "query-string";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ResponsiveDialog } from "src/components";
import { amplitude } from "src/services";
import { sharedAPI } from "src/shared-graphql";
import { BREAK_POINTS, COLORS } from "src/styles";
import { publishEvent } from "./api/mutations";
import {
  SelectMultipleEventDates,
  SelectMultipleEventDatesProps as _SelectMultipleEventDatesProps,
} from "./select-dates";

export interface SelectMultipleDatesDialogProps {
  eventId: string;
  SelectMultipleEventDatesProps: _SelectMultipleEventDatesProps;
}

export const SelectMultipleDatesDialog: React.FC<
  SelectMultipleDatesDialogProps & MUIDialogProps
> = ({
  onClose = (args: any) => ({}),
  open,
  SelectMultipleEventDatesProps: { eventStartDateTime, DayPickerProps = {} },
  eventId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [error, setError] = useState<string>("");
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);

  const onClickNoThanks = () => {
    amplitude
      .getInstance()
      .logEvent(`[cook/event form/duplicate event] click "No, Thanks!"`);
    setLoadingState(true);
    setError("");
    return publishEvent({
      eventId,
      duplicateDates: [],
    })
      .then(() => {
        history.push({
          pathname: `/c/menus/${eventId}`,
          search: stringify({ share: true }),
        });
        sharedAPI.setSnackbarMsg({
          msg: "Meal Published!",
          type: "success",
        });
      })
      .catch((e: { message: string }) => {
        setLoadingState(false);
        setError(e.message.replace(/^graphql error: /i, ""));
      });
  };

  const onClickConfirm = () => {
    setLoadingState(true);
    setError("");
    amplitude
      .getInstance()
      .logEvent(`[cook/event form/duplicate event] click "Confirm" selection`);
    return publishEvent({
      eventId,
      duplicateDates: selectedDates.map((date) =>
        moment(date)
          .set("hour", eventStartDateTime.hour())
          .set("minute", eventStartDateTime.minutes())
          .valueOf()
          .toString()
      ),
    })
      .then(() => {
        history.push({
          pathname: `/c/menus/${eventId}`,
          search: stringify({ share: true }),
        });
        sharedAPI.setSnackbarMsg({
          msg: "Meals Published!",
          type: "success",
        });
      })
      .catch((e: { message: string }) => {
        setLoadingState(false);
        setError(e.message.replace(/^graphql error: /i, ""));
      });
  };

  return (
    <ResponsiveDialog
      data-testid="SelectMultipleDatesDialog"
      open={open}
      aria-labelledby="SelectMultipleDatesDialog_title"
      classes={{
        paperScrollPaper: classes.paperScrollPaper,
      }}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <IconButton
        className={classes.closeButton}
        onClick={onClose as any}
        data-testid="SelectMultipleDatesDialog_closeButton"
      >
        <Close style={{ fontSize: "2rem" }} />
      </IconButton>
      <div className={classes.headerContent}>
        <Typography
          variant="h2"
          component="h2"
          className={classes.title}
          id="SelectMultipleDatesDialog_title"
        >
          Would you like to repeat this menu?
        </Typography>
        <Typography
          variant="body2"
          component="small"
          className={classes.caveat}
        >
          Choose up to <strong>5 more</strong> future dates (after the original
          start date) to serve this menu.
        </Typography>
        {error && (
          <Typography variant="body2" component="p" className={classes.error}>
            {error}
          </Typography>
        )}
      </div>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <SelectMultipleEventDates
          onSelectDay={(dates) => setSelectedDates(dates)}
          eventStartDateTime={eventStartDateTime}
          DayPickerProps={{
            className: classes.calendar,
            modifiersStyles: {
              selected: { backgroundColor: COLORS.RED },
            },
            ...DayPickerProps,
          }}
        />
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          classes={{ contained: classes.buttonConfirm }}
          disabled={selectedDates.length === 0 || isLoading}
          variant="contained"
          onClick={onClickConfirm}
        >
          {isLoading ? (
            <CircularProgress
              className={classes.circularProgress}
              data-testid="SelectDatesDialog_confirmLoadSpinner"
            />
          ) : (
            "Confirm"
          )}
        </Button>
        <Button
          disabled={isLoading}
          onClick={onClickNoThanks}
          className={classes.buttonNoThanks}
        >
          {isLoading ? (
            <CircularProgress
              className={classes.circularProgress}
              data-testid="SelectDatesDialog_noThanksLoadSpinner"
            />
          ) : (
            "No, thanks!"
          )}
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
};

const useStyles = makeStyles({
  paperScrollPaper: {
    borderRadius: 0,
  },
  headerContent: {
    padding: 24,
    borderRadius: 0,
  },
  backdrop: {
    backgroundColor: `rgba(255, 255, 255, 0.9)`,
  },
  title: {
    textAlign: "center",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    maxWidth: 320,

    margin: "auto auto",
    padding: "1rem",

    ["&::before"]: {
      content: `"👋"`,

      marginRight: ".5rem",
    },
    ["&::after"]: {
      content: `"*"`,
    },
  },
  caveat: {
    display: "block",
    textAlign: "center",
    fontFamily: "CustomFourBold",
    padding: ".25rem",
    fontWeight: 500,
    maxWidth: 300,
    margin: "auto auto",
    ["&::before"]: {
      content: `"*"`,
    },
  },
  buttonNoThanks: {
    fontWeight: 100,
    color: COLORS.BLACK,
    textTransform: "capitalize",
  },
  buttonConfirm: {
    color: COLORS.WHITE,
    textTransform: "capitalize",
    backgroundColor: COLORS.MEDIUM_GREEN,
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_GREEN,
    },
    "&:disabled": {
      backgroundColor: COLORS.LIGHT_GREY,
    },
  },
  calendar: {
    boxShadow: "0 0 6px 0 rgba(0,0,0,0.15)",
    fontSize: "1.6rem !important",
  },
  circularProgress: {
    color: COLORS.DARK_GREY,
  },
  dialogContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: 24,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: 12,
    },
  },
  dialogActions: {
    display: "flex",
    justifyContent: "space-between",
    padding: `0 24px 24px`,
    margin: 0,
  },
  error: {
    color: COLORS.RED,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    textAlign: "center",
  },
  closeButton: {
    minHeight: 0,
    color: COLORS.MEDIUM_GREEN,

    position: "absolute",
    top: 0,
    right: 0,
  },
});
