export enum Actions {
  SET_FIELD = "SET_FIELD",
  SET_ERROR = "SET_ERROR",
  SET_USER_INFO = "SET_USER_INFO",
}

export interface IAction {
  type: Actions;
  [key: string]: any;
}
