import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { DateTimePicker } from "material-ui-pickers";
import moment from "moment";
import React, { FC, Fragment, useState } from "react";
import { CreateCouponPL } from "src/models";

interface IProps {
  createCoupon: (d: CreateCouponPL) => void;
}
export const CouponCreateComponent: FC<IProps> = ({ createCoupon }) => {
  const [open, setStatus] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [code, setCode] = useState("");
  const [percent, setPercent] = useState(0.1);
  const [max, setMax] = useState(10);
  const [count, setCount] = useState(100);
  const [restaurantId, setRestaurantId] = useState<string | null>("");
  const [end, setEnd] = useState(moment().add(10, "days").valueOf());

  const _createCoupon = () => {
    setStatus(false);
    createCoupon({
      code,
      count,
      percent,
      max,
      newUser,
      start: String(moment().valueOf()),
      end: String(end),
      type: "NOME",
      eventId: null,
      restaurantId: restaurantId ? restaurantId : null,
    });
  };

  return (
    <Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setStatus(!open)}
      >
        Create Coupon
      </Button>
      <Dialog open={open} onClose={() => setStatus(false)}>
        <DialogTitle style={{ paddingBottom: 0 }}>Create Coupon</DialogTitle>
        <DialogContent style={{ width: "300px" }}>
          <TextField
            size="small"
            label="Code"
            value={code}
            fullWidth
            onChange={(d) => setCode(d.target.value)}
            margin="normal"
            inputProps={{ maxLength: 200 }}
          />
          <TextField
            size="small"
            type="number"
            label="Percent"
            fullWidth
            value={percent}
            onChange={(d) => setPercent(Number(d.target.value))}
            margin="normal"
            inputProps={{ maxLength: 200 }}
          />
          <TextField
            size="small"
            type="number"
            label="Coupon Cap $"
            fullWidth
            value={max}
            onChange={(d) => setMax(Number(d.target.value))}
            margin="normal"
            inputProps={{ maxLength: 200 }}
          />
          <TextField
            size="small"
            type="number"
            label="Count"
            value={count}
            fullWidth
            onChange={(d) => setCount(Number(d.target.value))}
            margin="normal"
          />
          <TextField
            size="small"
            label="Restaurant Id"
            fullWidth
            value={restaurantId}
            onChange={(d) => setRestaurantId(d.target.value)}
            margin="normal"
          />
          <div style={{ alignItems: "center", display: "flex" }}>
            <Checkbox
              checked={newUser}
              onChange={() => setNewUser(!newUser)}
              value="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />{" "}
            <Typography variant="caption" component="p">
              For New User Only?
            </Typography>
          </div>

          <DateTimePicker
            label="End Time"
            fullWidth
            value={moment(Number(end))}
            format={"MMM Do, h:mm a"}
            onChange={(d) => setEnd(d.valueOf())}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setStatus(!open)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => _createCoupon()}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
