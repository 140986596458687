import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";
import ReactStars from "react-stars";
import { CustomFacebookIcon, FacebookShareButton } from "src/components";
import { IRestaurantReview } from "src/models";
import { layoutStyles as useStyles } from "./layout.styles";

interface IProps {
  review: IRestaurantReview;
  onClose: () => void;
  url: string;
}

export const Layout: FC<IProps> = ({ review, onClose, url }) => {
  const classes = useStyles();
  const { guest, quality, hospitality, accuracy, text } = review;

  return (
    <>
      <DialogContent className={classes.container}>
        <div className={classes.guestContainer}>
          {guest.picture ? (
            <Avatar
              className={classes.avatar}
              alt={guest.firstName}
              src={guest.picture}
            />
          ) : (
            <Avatar className={classes.avatar}>
              {guest.firstName.charAt(0) + guest.lastName.charAt(0)}
            </Avatar>
          )}
          <div className={classes.guestInfoContainer}>
            <Typography
              variant="body2"
              component="p"
              className={classes.guestName}
            >
              {guest.firstName + " " + guest.lastName.charAt(0) + "."}
            </Typography>
            <ReactStars
              count={5}
              color2={"#ffd700"}
              value={(quality + hospitality + accuracy) / 3}
              edit={false}
            />
          </div>
        </div>
        <DialogContentText className={classes.text}>
          {text ? text : "[This diner did not leave a comment]"}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <div className={classes.shareContainer}>
          Share on
          <FacebookShareButton url={url}>
            <CustomFacebookIcon className={classes.shareBtnIcon} />
          </FacebookShareButton>
        </div>
        <Button
          color="secondary"
          variant="contained"
          className={classes.btn}
          onClick={() => onClose()}
        >
          Menus
        </Button>
      </DialogActions>
    </>
  );
};
