import { gql } from "@apollo/client";

export const RESTAURANT_LOCATION = gql`
  query restaurantLocation {
    cookGetRestaurant {
      id
      name
      address
      city
      state
      imagesGQL {
        publicId
        original
        small
        medium
        large
      }
    }
  }
`;

export const COOK_GET_LAST_EVENT = gql`
  query cookGetLastEvent {
    cookGetLastEvent {
      id
      description
      instructions
      startTime
      endTime
      deliveryInfo {
        baseAmount
        maximumDriveTime
        minimumOrderAmount
        pricePerMin
        pricePerMile
      }
    }
  }
`;
