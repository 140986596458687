import { gql } from "@apollo/client";
import {
  GET_ACTIVE_MODE,
  GET_AUTH_DRAWER_STATE,
  GET_CONTACT_MODAL,
  GET_COVID_DIALOG_STATE,
  GET_DIALOG_EVENT_ID,
  GET_DIALOG_RESTAURANT_NAME,
  GET_DINER_PROFILE_DRAWER_STATE,
  GET_DISCOUNT_PROMPT,
  GET_GLOBAL_DRAWER_STATE,
  GET_GLOBAL_LOADING_STATE,
  GET_MOBILE_HEADER_TITLE,
  GET_REFERRAL_DIALOG_STATE,
  GET_SHARE_DRAWER_CONFIG,
  GET_SIGNIN_DIALOG_STATUS,
  GET_SNACKBAR_MSG,
  GET_USER_UPDATE_EMAIL_STATE,
} from "./shared-graphql/queries";

export const typeDefs = gql`
  type SnackbarMsg {
    type: String
    msg: String
  }

  input SnackbarMsgPL {
    type: String
    msg: String
  }

  input MobileHeaderTitlePL {
    title: String!
    pathname: String
  }

  type MobileHeaderResponse {
    title: String!
    pathname: String
  }

  type Press {
    id: ID
    date: String!
    title: String!
    summary: String!
    source: String!
    sourceLogo: String!
    url: String!
    imageUrl: String!
    featured: Boolean
    type: PressType!
  }

  enum PressType {
    ARTICLE
    VIDEO
  }

  input ShareDrawerDefaultPL {
    drawerTitle: String!
    link: String!
    description: String!
    tags: [String!]!
  }

  input ShareDrawerTwitterPL {
    description: String
    tags: [String!]
  }

  input ShareDrawerFacebookPL {
    description: String
    tag: String
  }

  input ShareDrawerWhatsAppPL {
    description: String!
  }

  input ShareDrawerEmailPL {
    subject: String!
    body: String!
  }

  type ShareDrawerConfig {
    link: String!
    drawerTitle: String!
    description: String!
    tags: [String!]!
    twitter: TwitterShareConfig!
    facebook: FacebookShareConfig!
    whatsApp: WhatsAppShareConfig!
    email: EmailShareConfig!
  }

  type TwitterShareConfig {
    description: String!
    tags: [String!]!
  }

  type FacebookShareConfig {
    description: String!
    tag: String
  }

  type WhatsAppShareConfig {
    description: String!
  }

  type EmailShareConfig {
    subject: String!
    body: String!
  }

  extend type Query {
    getRerralDialogState: Boolean
    getSnackbarMsg: SnackbarMsg
    getDiscountPrompt: Boolean
    getDialogEventId: String
    getDialogRestaurantName: String
    showSignInDialog: Boolean
    mobileHeaderTitle: MobileHeaderResponse!
    press: [Press!]!
    getGlobalDrawerState: Boolean
    getShareDrawerConfig: ShareDrawerConfig
    getAuthDrawerState: Boolean
    getDinerProfileDrawerState: Boolean!
    """
    Diner Mode - false
    Cook Mode - true
    """
    getActiveMode: Boolean!
    getGlobalLoadingState: Boolean!
    getCOVID19DialogState: Boolean!
    getContactModalState: Boolean!
  }

  extend type Mutation {
    togglePaypalDrawer(amount: Float): Boolean
    setSnackbarMsg(snackbarMsgPL: SnackbarMsgPL): SnackbarMsg
    setMobileHeaderTitle(
      setMobileHeaderTitlePL: MobileHeaderTitlePL!
    ): MobileHeaderResponse!
    setGlobalDrawerState(state: Boolean!): Boolean!
    setShareDrawerConfig(
      defaultArgs: ShareDrawerDefaultPL
      twitter: ShareDrawerTwitterPL
      facebook: ShareDrawerFacebookPL
      whatsApp: ShareDrawerWhatsAppPL
      email: ShareDrawerEmailPL
    ): ShareDrawerConfig
    setAuthDrawerState(state: Boolean!): Boolean!
    setReferralDialogState(state: Boolean!): Boolean!
    setContactModal(state: Boolean!): Boolean!
    setDinerProfileDrawerState(state: Boolean!): Boolean!
    setDialogEventId(eventId: String!): String!
    setDialogRestaurantName(restaurantName: String!): String!
    """
    Diner Mode - false
    Cook Mode - true
    """
    setActiveMode(mode: Boolean!): Boolean!
    setGlobalLoadingState(state: boolean!): Boolean!
    setCOVID19DialogState(state: boolean!): Boolean!
    setUserUpdateEmailDialogState(state: boolean!): Boolean!
  }
`;

export const resolvers = {
  Mutation: {
    setSnackbarMsg: (_, { snackbarMsgPL }, { cache, client }) => {
      const data = snackbarMsgPL
        ? {
            getSnackbarMsg: {
              ...snackbarMsgPL,
              __typename: "SnackbarMsg",
            },
          }
        : {
            getSnackbarMsg: null,
          };

      client.writeQuery({
        query: GET_SNACKBAR_MSG,
        data,
      });

      return data.getSnackbarMsg;
    },
    setDiscountPromopt: (_, { status }, { cache, client }) => {
      const data = { getDiscountPrompt: status };

      client.writeQuery({
        query: GET_DISCOUNT_PROMPT,
        data,
      });
      return status;
    },
    setSignInDialogStatus: (_, { status }, { cache, client }) => {
      const data = { showSignInDialog: status };

      client.writeQuery({
        query: GET_SIGNIN_DIALOG_STATUS,
        data,
      });
      return status;
    },
    setMobileHeaderTitle: (_, { setMobileHeaderTitlePL }, { client }) => {
      const data = {
        mobileHeaderTitle: {
          title: setMobileHeaderTitlePL.title,
          pathname: setMobileHeaderTitlePL.pathname,
          __typename: "MobileHeaderResponse",
        },
      };

      client.writeQuery({
        query: GET_MOBILE_HEADER_TITLE,
        data,
      });

      return data.mobileHeaderTitle;
    },

    setDialogEventId: (_, { eventId }, { client }) => {
      const data = { getDialogEventId: eventId };

      client.writeQuery({
        query: GET_DIALOG_EVENT_ID,
        data,
      });

      return data.getDialogEventId;
    },
    setDialogRestaurantName: (_, { restaurantName }, { client }) => {
      const data = { getDialogRestaurantName: restaurantName };

      client.writeQuery({
        query: GET_DIALOG_RESTAURANT_NAME,
        data,
      });

      return data.getDialogRestaurantName;
    },
    setGlobalDrawerState: (_, { state }, { client }) => {
      const data = {
        getGlobalDrawerState: state,
      };

      client.writeQuery({
        query: GET_GLOBAL_DRAWER_STATE,
        data,
      });

      return state;
    },
    setShareDrawerConfig: (_, args, { client }) => {
      const data = {
        getShareDrawerConfig: args.defaultArgs
          ? {
              ...args.defaultArgs,
              facebook: {
                ...args.facebook,
                __typename: "FacebookShareConfig",
              },
              twitter: {
                ...args.twitter,
                __typename: "TwitterShareConfig",
              },
              whatsApp: {
                ...args.whatsApp,
                __typename: "WhatsAppShareConfig",
              },
              email: {
                ...args.email,
                __typename: "EmailShareConfig",
              },
              __typename: "ShareDrawerConfig",
            }
          : null,
      };

      client.writeQuery({ query: GET_SHARE_DRAWER_CONFIG, data });

      return data.getShareDrawerConfig;
    },
    setAuthDrawerState: (_, { state }, { client }) => {
      const data = {
        getAuthDrawerState: state,
      };

      client.writeQuery({
        query: GET_AUTH_DRAWER_STATE,
        data,
      });

      return state;
    },
    setReferralDialogState: (_, { state }, { client }) => {
      const data = {
        getReferralDialogState: state,
      };

      client.writeQuery({
        query: GET_REFERRAL_DIALOG_STATE,
        data,
      });

      return state;
    },
    setContactModal: (_, { state }, { client }) => {
      const data = {
        getContactModalState: state,
      };

      client.writeQuery({
        query: GET_CONTACT_MODAL,
        data,
      });

      return state;
    },
    setDinerProfileDrawerState: (_, { state }, { client }) => {
      const data = {
        getDinerProfileDrawerState: state,
      };

      client.writeQuery({
        query: GET_DINER_PROFILE_DRAWER_STATE,
        data,
      });

      return state;
    },
    setActiveMode: (_, { mode }, { client }) => {
      const data = {
        getActiveMode: mode,
      };

      client.writeQuery({
        query: GET_ACTIVE_MODE,
        data,
      });

      return mode;
    },
    setGlobalLoadingState: (_, { state }, { client }) => {
      const data = {
        getGlobalLoadingState: state,
      };

      client.writeQuery({
        query: GET_GLOBAL_LOADING_STATE,
        data,
      });

      return state;
    },
    setCOVID19DialogState: (_, { state }, { client }) => {
      const data = {
        getCOVID19DialogState: state,
      };

      client.writeQuery({
        query: GET_COVID_DIALOG_STATE,
        data,
      });

      return state;
    },
    setUserUpdateEmailDialogState: (_, { state }, { client }) => {
      const data = {
        getUserUpdateEmailDialogState: state,
      };

      client.writeQuery({
        query: GET_USER_UPDATE_EMAIL_STATE,
        data,
      });

      return state;
    },
  },
  Query: {
    press: () => {
      return fetch(
        "https://raw.githubusercontent.com/foodnome/foodnome-static/master/press.json"
      )
        .then((res) => res.json())
        .then(({ data }) => {
          const allPress = [
            // Add new featured press here to test
            ...data.press,
          ];
          return allPress.map((press) => ({
            ...press,
            __typename: "Press",
          }));
        });
    },
  },
};

export const initialCacheState = {
  ROOT_QUERY: {
    getSnackbarMsg: null,
    getDialogEventId: null,
    getDialogRestaurantName: null,
    getReferralDialogState: false,
    getDiscountPrompt: false,
    showSignInDialog: false,
    mobileHeaderTitle: null,
    getGlobalDrawerState: false,
    getShareDrawerConfig: null,
    getAuthDrawerState: false,
    getDinerProfileDrawerState: false,
    getActiveMode: false,
    getGlobalLoadingState: false,
    getCOVID19DialogState: false,
    getUserUpdateEmailDialogState: false,
    getContactModalState: false,
  },
};
