import { Avatar, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { IGetMe } from "src/models";
import { COLORS } from "src/styles";
import { ChannelPreviewProps } from "stream-chat-react";
import { useStyles } from "./preview.styles";

interface IPreviewProps extends ChannelPreviewProps {
  getMe?: IGetMe;
  setInChatRoom: (d: any) => void;
  setActiveChannel?: any;
}

export const Preview = ({
  channel,
  setActiveChannel,
  getMe,
  setInChatRoom,
}: IPreviewProps) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const unreadCount = channel.countUnread();

  const otherSenders = Object.values(channel?.state?.members)
    .filter((m: any) => m.user.id !== getMe?.id && m.user.appRole !== "ADMIN")
    .map((m: any) => m.user);

  const displayName = otherSenders.map((u, index) => (
    <span key={u.id}>
      {/* <span className={u.online ? classes.online : classes.offline}></span> */}
      {u.name}
      {index < otherSenders.length - 1 ? ", " : ""}
    </span>
  ));

  const { messages } = channel.state;
  return (
    <div
      onClick={() => {
        channel && setActiveChannel(channel);
        setInChatRoom(channel);
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={classes.container}
      style={{
        backgroundColor: hover
          ? "#ececec"
          : unreadCount
          ? "aliceblue"
          : "unset",
      }}
    >
      <Avatar
        className={classes.avatar}
        alt={otherSenders[0]?.name}
        src={
          !otherSenders.length
            ? "https://foodnome-static-assets.s3.us-west-1.amazonaws.com/images/logos/logo-transparent.png"
            : otherSenders[0]?.avatarURL ?? ""
        }
      >
        {otherSenders[0]?.name?.charAt(0)}
      </Avatar>

      <section className={classes.infoContainer}>
        <div className={classes.nameAndTimeContainer}>
          <Typography
            variant="body2"
            component="h4"
            className={classes.names}
            style={{
              color:
                otherSenders.length && channel?.id?.includes("Foodnome")
                  ? COLORS.GREEN
                  : COLORS.BLACK,
            }}
          >
            {otherSenders.length ? displayName : "COOK Connect Team"}
            {unreadCount ? (
              <b className={classes.unread}> ({unreadCount})</b>
            ) : (
              ""
            )}
          </Typography>
          {channel.state.last_message_at && (
            <Typography
              variant="caption"
              component="p"
              className={classes.timeContainer}
            >
              {moment(channel.state.last_message_at).fromNow()}
            </Typography>
          )}
        </div>
        <Typography variant="body2" component="p" className={classes.msg}>
          {messages[messages.length - 1]?.text}{" "}
        </Typography>
      </section>
    </div>
  );
};
