export const COLORS = {
  BLACK: "#000000",
  WHITE: "#FFFFFF",

  RED: "#F7CBCA", // cook red color for background instead of foodnome red "#FF3056",
  DARK_RED: "#B04B59", // cook red color for headings
  LIGHT_RED: "#FF6A99",
  MEDIUM_RED: "#FF6E61", // cook red color for buttons
  H3_HEADINGS: "#FE3056",
  YELLOW: "#FCC231",
  DARK_BLUE: "#1A051D",
  VIOLET: "#5C6071",
  BLUE: "#0000FF",
  LIGHT_BLUE: "#D6E5EB",
  LIGHT_GREY_1: "#E5E5E5",
  LIGHT_GREY: "#D0D0D0",
  DARK_GREY: "#858585",
  MEDIUM_GREY: "#bdbdbd",
  GREEN: "#479546",
  MEDIUM_GREEN: "#FF6E61", // 08CCAE swapped with medium_red
  LIGHT_GREEN: "#A0E0AF",
  FLASH_WHITE: "#F9F9F9",
  GOLD: "#FFE263",
  LIGHT_GOLD: "#fff0ac",
  LAVENDER: "#7AA3FF",
  PURPLE: "#5700B2",
};
