import { client } from "src/apollo";
import { upload } from "src/components/img-upload/api/upload";
import { UpdateRestaurantPL } from "src/models";
import { UPDATE_RESTAURANT } from "./graphql";

export const hostRestaurantAPI = {
  updateRestaurant: (updateRestaurantPL: UpdateRestaurantPL) =>
    client.mutate({
      mutation: UPDATE_RESTAURANT,
      variables: { input: updateRestaurantPL },
      refetchQueries: ["cookGetRestaurant"],
      awaitRefetchQueries: true,
    }),
  uploadImg: (f: File) => upload(f),
};
