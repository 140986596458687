import React, { FC } from "react";
import { COLORS } from "src/styles";

interface IProps {
  step: number;
  images: any;
}

export const StepProgress: FC<IProps> = ({ step, images }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "45px",
        alignSelf: "flex-start",
        borderRadius: 5,
        backgroundColor: "#F0F4F7",
      }}
    >
      {images.map((image, idx) => {
        return (
          <div
            key={idx}
            style={{
              width: 125,
              height: 10,
              backgroundColor: step === idx ? COLORS.RED : "#F0F4F7",
              // Only give border radius to first and last sections
              // Or if current step index is 1, give borderRadius
              borderRadius: idx !== 1 || step === idx ? 5 : 0,
            }}
          />
        );
      })}
    </div>
  );
};
