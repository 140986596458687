import { IRestaurant } from "src/models";
import { IFormState } from "./form/state/reducers";

export const formatInitialFormState = ({
  address = "",
  description = "",
  imagesGQL = [],
  name = "",
  permitNumber = "",
  tags = [],
}: IRestaurant): IFormState => {
  return {
    address,
    description,
    image: imagesGQL && imagesGQL.length ? imagesGQL[0] : null,
    name,
    permitNumber,
    tags,
  };
};
