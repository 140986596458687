import { client } from "src/apollo";
import { CreateApplicationStatusPL } from "src/models";
import { COOK_CREATE_APP_STATUS, CREATE_APPLICATION_STATUS } from "./graphql";

export const applicationAPI = {
  createApplicationStatus: async (
    createApplicationStatusPL: CreateApplicationStatusPL
  ) =>
    client.mutate({
      mutation: CREATE_APPLICATION_STATUS,
      variables: {
        input: createApplicationStatusPL,
      },
      refetchQueries: () => ["getMe"],
    }),
};

export const createApplication = (appInfo: any) =>
  client.mutate({
    mutation: COOK_CREATE_APP_STATUS,
    variables: {
      input: {
        appInfo,
      },
    },
  });
