import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import moment from "moment";
import React, { FC, useState } from "react";
import { ICoupon } from "src/models";

interface IProps {
  coupons: ICoupon[];
  deleteCoupon: (d: any) => void;
}

export const CouponTable: FC<IProps> = ({ coupons, deleteCoupon }) => {
  const [hovered, setHover] = useState<string>("0");

  return (
    <>
      <TableContainer component={Paper}>
        <Table style={{ maxWidth: "none" }}>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Count</TableCell>
              <TableCell>%off (cap)</TableCell>
              <TableCell>New User</TableCell>
              <TableCell>Restaurant</TableCell>
              <TableCell>End</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coupons.map((row) => (
              <TableRow
                key={row.id}
                hover
                onMouseOver={() => setHover(String(row.id))}
              >
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.count}</TableCell>
                <TableCell>
                  {row.percent * 100}% ({row.max})
                </TableCell>
                <TableCell>{row.newUser ? "Yes" : "No"}</TableCell>
                <TableCell>
                  {row.restaurant ? row.restaurant.name : "no"}
                </TableCell>
                <TableCell>{moment(Number(row.end)).format("lll")}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => deleteCoupon(row.code)}
                    style={
                      String(row.id) === hovered ? {} : { visibility: "hidden" }
                    }
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
