import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    position: "relative",
  },
  img: {
    width: "100%",
    objectFit: "cover",
    maxHeight: 250,
  },
  dishNameContainer: {
    position: "absolute",
    borderRadius: "4px",
    left: "4px",
    maxWidth: "97%",
    maxHeight: "80%",
    overflow: "hidden",
    bottom: "30px",
    fontFamily: "CustomFourBold",
    padding: 12,
    zIndex: 10,
    backgroundColor: "hsla(0,0%,100%,.75)",
  },
  name: { margin: 0, fontFamily: "CustomFourBold" },
  dishPrice: { margin: 0 },
  buttonLeftWrapper: {
    position: "absolute",
    display: "flex",
    top: "50%",
    left: 0,
    transform: "translateY(-50%)",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  buttonRightWrapper: {
    position: "absolute",
    top: "50%",
    right: 0,
    transform: "translateY(-50%)",
    display: "flex",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  dotContainer: {
    marginRight: ".5rem",

    cursor: "pointer",
    transition: "all 100ms ease-in",
    "&:last-child": {
      marginRight: 0,
    },
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
  dotActive: {
    opacity: 1,
    transform: "scale(1.4)",
    color: COLORS.WHITE,
  },
  dot: {
    color: COLORS.WHITE,
    opacity: 0.75,
    transform: "scale(1)",
  },
  overviewButtonsWrapper: {
    position: "absolute",
    bottom: 0,
    paddingBottom: "1em",
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
  },
  dotsWrapper: {
    padding: 0,
    margin: 0,
    listStyleType: "none",
    display: "flex",
  },
  cheveron: {
    width: 20,
  },
  cheveronLeft: { transform: "rotate(180deg)" },
  cheveronRight: {},
});

const a = {
  id: 2,
  name: "2 Events are 48 days from now",
  startTime: 1590471343345,
  eventUser: [
    {
      id: 2,
      checkedIn: false,
      paidAmount: 8,
      notes: null,
      eventId: 2,
      userId: 5,
      reservedFor: 1,
      status: "RESERVED",
      createdAt: "2019-01-19T09:25:28.445-08:00",
      updatedAt: "2019-01-19T09:25:28.445-08:00",
      arrivalTime: null,
      dineOption: null,
      creditAmount: 0,
      taxAmount: 0,
      deliveryCost: 0,
      deliveryAddress: "",
    },
  ],
};
