import { SvgIcon } from "@material-ui/core";
import React from "react";

export const UserFAQ = (props) => (
  <SvgIcon {...props}>
    <g>
      <path
        d="M10.27,0.27c-5.5,0-10,4.5-10,10s4.5,10,10,10s10-4.5,10-10S15.77,0.27,10.27,0.27z M10.27,18.27c-4.4,0-8-3.6-8-8
		s3.6-8,8-8s8,3.6,8,8S14.67,18.27,10.27,18.27z"
      />
      <path
        d="M10.97,4.37c-1.2-0.2-2.4,0.1-3.3,0.8c-0.9,0.8-1.4,1.9-1.4,3.1h2c0-0.6,0.3-1.2,0.7-1.5c0.5-0.4,1-0.5,1.7-0.4
		c0.8,0.1,1.5,0.8,1.6,1.6c0.2,1-0.4,2-1.4,2.3c-1,0.2-1.6,1-1.6,2v1h2v-1c0-0.1,0-0.1,0.1-0.1c2-0.6,3.2-2.5,2.9-4.6
		C13.97,5.97,12.57,4.57,10.97,4.37z"
      />
      <rect x="9.27" y="14.27" width="2" height="2" />
    </g>
  </SvgIcon>
);
