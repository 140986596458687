import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    position: "fixed",
    overflowY: "scroll",
    height: "calc(100% - 60px)",
    backgroundColor: "#e0e0e0",
    zIndex: 100,
  },
  menuBtn: {
    display: "none",
    position: "fixed",
    zIndex: 2999,

    right: "1rem",
    top: "1rem",

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "unset",
    },
  },
  navItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "10rem",
    height: "6rem",
    "&:hover": {
      backgroundColor: "aliceblue",
      borderRight: "5px solid #fff",
    },
  },
  active: {
    borderRight: "5px solid #ec407a",
    backgroundColor: "#eeeeee",
  },
  navItemIcon: {
    fontSize: "3rem",
  },
});
