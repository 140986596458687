import { gql } from "@apollo/client";
import { client } from "src/apollo";

interface SelectDishPL {
  dishId: number;
  price: number | null | "";
  count: number | null | "";
}

interface AddDishesToEventPL {
  dishes: SelectDishPL[];
  eventId: string;
}

export const ADD_DISHES_TO_EVENT = gql`
  mutation cookCreateUpdateDishEvent($input: CookCreateUpdateDishEventPL!) {
    cookCreateUpdateDishEvent(cookCreateUpdateDishEventPL: $input) {
      dishes {
        id
        DishEvent {
          id
          count
          price
        }
      }
    }
  }
`;

export const addDishesToEvent = (d: AddDishesToEventPL) => {
  return client.mutate({
    mutation: ADD_DISHES_TO_EVENT,
    variables: { input: d },
    refetchQueries: ["cookGetEvent"],
  });
};
