import { Divider, Typography } from "@material-ui/core";
import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { SERVER_ADDRESS } from "src/apollo";
import { sharedAPI } from "src/shared-graphql";
import { BannerForm } from "./banner-form";
import { useStyles } from "./banner.styles";

interface BannerProps {
  bannerColor: string;
  code: string;
  envs: string[];
  show: boolean;
  text: string;
  externalLink: string;
  textColor: string;
  whiteList: string[];
  newUserOnly: boolean;
}

export const Banner: FC = () => {
  const [bannerData, setBannerData] = useState<BannerProps | null>(null);
  const [newBannerState, setNewBannerState] = useState<BannerProps | null>(
    null
  );
  const [whiteListValue, setWhiteListValue] = useState<string>("");
  const classes = useStyles();

  const platforms = ["development", "stage", "production", "test"];
  const token = localStorage.getItem("foodnome_auth_token");

  useEffect(() => {
    getBannerData();
  }, []);

  const getBannerData = () => {
    axios
      .get(`${SERVER_ADDRESS}/banner`)
      .then((res) => {
        setBannerData(res.data);
        setNewBannerState(res.data);
      })
      .catch((err) =>
        sharedAPI.setSnackbarMsg({
          type: "error",
          msg: err,
        })
      );
  };

  const submit = (e) => {
    e.preventDefault();
    axios
      .post(`${SERVER_ADDRESS}/banner`, newBannerState, {
        withCredentials: true,
        ...(process.env.REACT_APP_ENV === "cordova" && {
          headers: { authorization: token ? token : "none" },
        }),
      })
      .then((d) => {
        if (d.status === 200) {
          sharedAPI.setSnackbarMsg({
            type: "success",
            msg: "Banner has been updated",
          });
          getBannerData();
        } else {
          sharedAPI.setSnackbarMsg({
            type: "error",
            msg: "Error updating banner",
          });
        }
      })
      .catch((err) => {
        sharedAPI.setSnackbarMsg({
          type: "error",
          msg: err,
        });
      });
  };

  // Handlers

  const handleShow = (e) => {
    const { name, value } = e.target;
    setNewBannerState(
      (prevState) =>
        ({
          ...prevState,
          [name]: value === "1" ? true : false,
        }) as any
    );
  };

  const handleWhiteListAdd = () => {
    // Clear text state
    setWhiteListValue("");

    // Make copu of state to manipulate and push
    const current = newBannerState?.whiteList.slice();
    current?.push(whiteListValue);
    setNewBannerState(
      (prevState) =>
        ({
          ...prevState,
          whiteList: current,
        }) as any
    );
  };

  const handleWhiteListDelete = (route) => {
    const current = newBannerState?.whiteList.slice();
    current?.splice(current.indexOf(route), 1);
    setNewBannerState(
      (prevState) =>
        ({
          ...prevState,
          whiteList: current,
        }) as any
    );
  };

  const handleEnvs = (e) => {
    const { name } = e.target;
    const current = newBannerState?.envs.slice();
    !current?.includes(name)
      ? current?.push(name)
      : current?.splice(current.indexOf(name), 1);

    setNewBannerState(
      (prevState) =>
        ({
          ...prevState,
          envs: current,
        }) as any
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewBannerState(
      (prevState) =>
        ({
          ...prevState,
          [name]: value,
        }) as any
    );
  };

  return (
    <section className={classes.bannerContainer}>
      <Typography variant="h2" component="h2">
        Banner
      </Typography>
      {bannerData && newBannerState && (
        <BannerForm
          handleShow={handleShow}
          handleWhiteListAdd={handleWhiteListAdd}
          handleWhiteListDelete={handleWhiteListDelete}
          handleEnvs={handleEnvs}
          handleChange={handleChange}
          submit={submit}
          platforms={platforms}
          newBannerState={newBannerState}
          setWhiteListValue={setWhiteListValue}
          whiteListValue={whiteListValue}
          bannerData={bannerData}
        />
      )}
      <Divider />
    </section>
  );
};
