import { Button, makeStyles } from "@material-ui/core";
import { Map, Search } from "@material-ui/icons";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FullScreenSearchDialog } from "src/components";
import { COLORS } from "src/styles";

interface MobileEventSearchProps {
  onSubmit: (d: any) => any;
  queryObj: {
    location: string;
    tags: string[];
  };
}

export const MobileEventSearch: React.FC<MobileEventSearchProps> = ({
  onSubmit,
  queryObj,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [dialogSearchType, setDialogSearchType] = useState("");

  const handleSearchAddressClickOpen = () => {
    setDialogSearchType("address");
    setOpen(true);
  };
  const handleSearchRestaurantClickOpen = () => {
    setDialogSearchType("cook");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",

          flexDirection: "row",
          height: "auto",
          marginTop: "1rem",
        }}
      >
        <Button
          fullWidth
          variant="contained"
          onClick={handleSearchAddressClickOpen}
          classes={{
            root: classes.enterAddressButtonRoot,
            label: classes.enterAddressButtonLabel,
          }}
          startIcon={
            <Search
              style={{
                color: COLORS.MEDIUM_GREEN,
                zIndex: 1,
              }}
            />
          }
        >
          {queryObj.location
            ? queryObj.location
            : "Search by restaurant or location"}
        </Button>
        <Button
          classes={{ root: classes.mapBtn }}
          startIcon={<Map className={classes.mapIcon} />}
          variant="contained"
          onClick={() => history.push("/restaurants")}
        >
          Map
        </Button>
        {/* <Button
          fullWidth
          variant="contained"
          classes={{
            root: classes.enterAddressButtonRoot,
            label: classes.enterAddressButtonLabel,
          }}
          startIcon={
            <Search
              style={{
                color: COLORS.BLACK,
              }}
            />
          }
          onClick={handleSearchRestaurantClickOpen}
        >
          Search Home Restaurant or Cook
        </Button> */}
      </div>
      <FullScreenSearchDialog
        dialogSearchType={dialogSearchType}
        handleClose={handleClose}
        open={open}
        onSubmit={onSubmit}
        queryObj={queryObj}
      />
    </>
  );
};

const useStyles = makeStyles({
  input: {
    zIndex: 1,
    color: COLORS.BLACK,
  },
  rootInput: {
    "& $notchedOutline": {
      backgroundColor: COLORS.WHITE,
      borderRadius: 44,
      color: COLORS.BLACK,
    },
    "&$focused $notchedOutline": {
      borderColor: COLORS.MEDIUM_GREY,
      borderWidth: 1,
    },
  },
  focused: {},
  notchedOutline: {},
  searchBarItem: {
    padding: "0 15px",
    paddingBottom: 12,
  },
  enterAddressButtonRoot: {
    backgroundColor: COLORS.WHITE,

    height: "4rem",

    color: "#717171",
    borderRadius: 18,
    textTransform: "unset",
  },
  enterAddressButtonLabel: {
    justifyContent: "left",
  },
  dialogTitle: {
    color: COLORS.BLACK,
    flex: 1,
    textAlign: "center",
    fontFamily: "CustomFourBold",
  },
  appBar: {
    backgroundColor: COLORS.WHITE,
    position: "relative",
  },
  sideBubble: {
    position: "absolute",
    left: -90,
    zIndex: -1,
    width: "60%",
    maxWidth: 285,
  },
  mapIcon: {
    color: COLORS.MEDIUM_GREEN,
  },
  mapBtn: {
    marginLeft: "1rem",
    padding: "0 2rem",
    border: `2px solid ${COLORS.MEDIUM_GREEN}`,
    backgroundColor: COLORS.WHITE,
    color: COLORS.BLACK,
    borderRadius: ".7rem",
    height: "4rem",
  },
});
