import axios from "axios";
import { getSignature } from "./signature";

const cloudinaryUrl = process.env.REACT_APP_CLOUDINARY_URL as string;
const api = process.env.REACT_APP_CLOUDINARY_API as string;

export const upload = async (file: File) => {
  const sigResponse = await getSignature();

  const { signature, timestamp, eager } = sigResponse.data;

  const formData = new FormData();
  formData.append("file", file);
  formData.append("signature", signature);
  formData.append("timestamp", timestamp);
  formData.append("eager", eager);
  formData.append("api_key", api);

  return axios.post(cloudinaryUrl, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
