import classNames from "classnames";
import React from "react";
import { FooterBottom, NavLinks, Secure } from "./components";
import { useStyles } from "./layout.styles";

export const Layout: React.FC = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer} data-testid="main-footer">
      <section className={classNames(classes.container, classes.color1)}>
        <div className={classes.innerContentContainer}>
          <NavLinks />
        </div>
      </section>
      <section className={classNames(classes.container, classes.color2)}>
        <div className={classes.innerContentContainer}>
          <Secure />
        </div>
      </section>
      <section className={classNames(classes.container, classes.color1)}>
        <div className={classes.innerContentContainer}>
          <FooterBottom />
        </div>
      </section>
    </footer>
  );
};
