import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS } from "src/styles";

const SIDEBAR_WIDTH = 291;

export const routeStyles = makeStyles({
  container: {
    flexGrow: 1,
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
  },
  colOne: {
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    top: 58,
    maxWidth: SIDEBAR_WIDTH,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  colTwo: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 0,
    marginLeft: SIDEBAR_WIDTH,
    padding: "1rem",
    minHeight: "100%",
    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginLeft: 0,
      width: "100%",
      padding: 0,
      paddingBottom: "calc(8rem + env(safe-area-inset-bottom))",
    },
  },
});
