import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  fieldset: {
    display: "flex",
    flexDirection: "column",
    width: 262,
    border: `1px solid #CCCCCC`,
    backgroundColor: "#F5F7FB",
    margin: "0 auto",
    position: "relative",
    cursor: "pointer",
    padding: 0,
  },
  img: {
    width: "100%",
    height: "182px",
    padding: "1rem",
    objectFit: "cover",
  },
  togoFieldsWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  rootCheckboxLabel: {
    margin: "0 1rem",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    overflow: "hidden",
  },
  checkboxLabel: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  inputLabel: {
    display: "grid",

    gridTemplateColumns: "auto auto",
    width: "100%",
    alignItems: "center",
    justifyItems: "flex-start",
    padding: "1em",
    borderBottom: "1px solid #CCCCCC",
  },
  checkboxRoot: {
    position: "absolute",
    top: "-2rem",
    left: "-2rem",
    backgroundColor: COLORS.WHITE,
    boxShadow: "0 2px 8px 0 rgba(157,157,157,0.5)",
    padding: 6,
    "&:hover": {
      backgroundColor: COLORS.WHITE,
    },
  },
  inputRoot: {
    "&:placeholder": {
      color: "#B6B6B6",
    },
  },
  notchedOutline: {
    borderRadius: 2,
    border: `1px solid ${COLORS.LIGHT_GREY}`,
  },
  input: {
    padding: 5,
    height: "1.5em",
    maxWidth: 80,
  },
  removeButton: {
    fontFamily: "CustomFourBold",

    fontWeight: 500,
    color: COLORS.MEDIUM_GREEN,
    margin: "1rem",
    padding: 0,
    textTransform: "capitalize",
  },
});
