import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    backgroundSize: "cover",
    position: "relative",
    paddingBottom: "1rem",
    margin: "auto 0",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "flex",
      alignItems: "center",
    },
  },
  shareBtnContainer: {
    display: "flex",

    padding: "5px",
    width: "160px",
    justifyContent: "space-between",
  },

  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    background: "rgba(0, 0, 0, 0.5)",
  },
  contentContainer: {
    padding: "23px 2rem 0",
    margin: "0 auto",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    maxWidth: "844px",

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "1rem 0",
      width: "92%",
      margin: "auto auto",
    },
  },
  title: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.WHITE,

    marginTop: "1rem",
    marginBottom: ".5rem",
    textTransform: "capitalize",
    textShadow: "0 2px 4px rgba(0,0,0,0.42)",
    overflow: "hidden",
    lineClamp: 3,
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      margin: "12 0",
    },
  },
  dateTimeContainer: {
    color: COLORS.WHITE,

    display: "flex",
    alignItems: "center",
  },
  reviewContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  starsText: {
    fontFamily: "CustomFourBold",
  },
  reviewText: {
    marginLeft: "0.5rem",
    marginBottom: "0.2rem",
    alignSelf: "center",
  },
  dateChip: {
    display: "flex",
    alignItems: "center",
    marginRight: 10,
    justifyContent: "center",
    backgroundColor: COLORS.WHITE,
    boxShadow: "0 2px 6px 0 rgba(0,0,0,0.27)",
    height: "28px",
    width: "114px",
    borderRadius: "23.5px",

    color: COLORS.BLACK,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      height: 20,
      width: 94,
    },
  },
  shareSaveContainer: {
    display: "flex",
    justifyContent: "space-between",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  eventInfoContainer: {
    color: COLORS.WHITE,
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "flex-start",
  },
  eventDetailContainer: {
    padding: 0,
    margin: 0,
  },
  eventInfo: {
    color: COLORS.WHITE,
    margin: 0,
    fontWeight: 400,

    alignSelf: "center",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    overflow: "hidden",
  },
  eventInfoIcon: { marginRight: ".5rem" },
  btn: {
    backgroundColor: COLORS.WHITE,
    // width: "90px",
    height: "28px",
    borderRadius: "2px",
    marginLeft: 4,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    padding: 1,
    paddingRight: 10,
    color: COLORS.BLACK,
    "&:hover": {
      backgroundColor: COLORS.WHITE,
    },
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "0",
      boxShadow: "none",
      minWidth: "unset",
      width: "unset",
      marginTop: "-8px",
    },
  },
  btnIcon: {
    color: COLORS.MEDIUM_GREEN,
    marginRight: 2,
    fontSize: "2rem",
  },
  btnLabel: {
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  hostProfileContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    color: COLORS.WHITE,
    justifyContent: "center",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginTop: 0,
    },
  },
  hostInfoContainer: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginTop: 0,
    },
  },
  avatar: {
    width: "10rem",
    height: "10rem",
    borderColor: COLORS.WHITE,
    borderStyle: "solid",
    borderWidth: 2,
    margin: "0 auto",
    textTransform: "uppercase",
    backgroundColor: COLORS.RED,
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "8rem",
      height: "8rem",
      marginBottom: ".75rem",
    },
  },
  hostName: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  timeDate: {
    fontFamily: "CustomFourBold",
    marginRight: "4px",
    fontWeight: 500,
  },
  viewCookLink: {
    textDecoration: "none",
    color: COLORS.WHITE,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: ".5rem",
  },
  cardHeaderDate: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  cardHeaderTime: {
    textTransform: "lowercase",
  },
  datesHeaderContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      position: "absolute",
      left: 0,
      top: 10,
    },
  },
  dateLayout: {
    position: "relative",
    height: 24,
    width: "auto",
    borderRadius: 12,
    backgroundColor: `#F7FDFF`,
    opacity: ".80",
    color: COLORS.BLACK,

    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.24)",
    textTransform: "uppercase",
    display: "flex",
    justifyContent: "left",
    flexDirection: "row",
    alignItems: "center",
    padding: "0 17px",
    marginBottom: ".3rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      borderRadius: "0 12px 12px 0",
    },
  },
  orderByLayout: {
    position: "relative",
    height: 24,
    width: "auto",
    borderRadius: 12,
    backgroundColor: `#0A0A0A`,
    opacity: ".80",
    color: COLORS.WHITE,

    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.24)",
    display: "flex",
    justifyContent: "left",
    flexDirection: "row",
    alignItems: "center",
    padding: "0 17px",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      borderRadius: "0 12px 12px 0",
    },
  },
  viewProfileBox: {
    color: COLORS.WHITE,
    marginBottom: "1rem",
  },

  typesContainer: {
    display: "block",
    position: "relative",
    margin: "2rem 0",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  btnContainer: {
    marginTop: "0.5rem",
    display: "flex",
    width: "100%",
    maxWidth: 100,
    justifyContent: "space-between",
    flexDirection: "column",
  },
  deliveryTypeList: {
    color: COLORS.WHITE,
  },
  locationSrollButton: {
    all: "unset",
    cursor: "pointer",
    color: COLORS.WHITE,
    WebkitTextFillColor: COLORS.WHITE,
    width: "100%",
  },
  mapWrapper: {
    marginTop: "1rem",
    height: 110,
    width: "100%",

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "90%",
      height: 80,
    },
  },
  helpIcon: {
    color: COLORS.MEDIUM_GREEN,
    marginLeft: ".5rem",
  },
  popoverRoot: {
    padding: 0,
  },
});
