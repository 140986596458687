import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { FC, useState } from "react";
import { IApplication, IGetMe } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { applicationAPI } from "../api";

interface IProps {
  getMe: IGetMe;
  application: IApplication;
}

export const SetReminder: FC<IProps> = ({ getMe, application }) => {
  const [open, setOpen] = useState(false);
  const [phone, setPhone] = useState(getMe.phoneNumber);
  const [time, setTime] = useState(
    moment().add(1, "minute").format(moment.HTML5_FMT.DATETIME_LOCAL)
  );
  const _msg =
    `${getMe.firstName}: ${application.restaurant.host.phoneNumber}, ` +
    `${application.restaurant.host.firstName} ${application.restaurant.host.lastName}, ` +
    `${application.status}`;
  const [msg, setMsg] = useState(_msg);

  const send = () =>
    applicationAPI
      .sendAdminReminderText(phone, msg, moment(time).valueOf().toString())
      .then(() => {
        sharedAPI.setSnackbarMsg({ type: "success", msg: "Set reminder" });

        setOpen(false);
      })
      .catch((e) => {
        sharedAPI.setSnackbarMsg({ type: "error", msg: "Something is wrong" });
      });

  return (
    <>
      <button onClick={() => setOpen(true)}>Set Reminder</button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        5 mins prior to the call time, you will get a text notification
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography
            component="label"
            variant="caption"
            htmlFor="sendReminderTo"
          >
            To
          </Typography>

          <select
            id="sendReminderTo"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          >
            <option value="(323)-605-1760">Isaac - (323)-605-1760</option>
            <option value="(925)-858-2691">Akshay - (925)-858-2691</option>
            <option value="(775)-686-8215">Leo - (775)-686-8215</option>
            <option value="(323)-376-2292">Raymond - (323)-376-2292</option>
            <option value="(415)-302-9221">Emily - (415)-302-9221</option>
          </select>
          <Typography
            component="label"
            variant="caption"
            htmlFor="reminder-time"
          >
            Call Time
          </Typography>
          <input
            autoComplete={"off"}
            style={{ margin: "0 4px" }}
            type="datetime-local"
            id={"reminder-time"}
            value={time}
            min={moment().format(moment.HTML5_FMT.DATETIME_LOCAL)}
            onChange={(e) =>
              setTime(
                moment(e.target.value).format(moment.HTML5_FMT.DATETIME_LOCAL)
              )
            }
          ></input>
          <Typography
            component="label"
            variant="caption"
            htmlFor="reminder-msg"
          >
            Time
          </Typography>
          <textarea
            id={"reminder-msg"}
            value={msg}
            onChange={(e) => setMsg(e.target.value)}
          ></textarea>
        </DialogContent>
        <DialogActions>
          <button onClick={() => setOpen(false)}>Cancel</button>
          <button onClick={() => send()}>Send</button>
        </DialogActions>
      </Dialog>
    </>
  );
};
