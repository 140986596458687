import { makeStyles } from "@material-ui/core";

export const headerStyles = makeStyles({
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
  },
  count: {
    marginBottom: "2rem",
  },
  searchField: { width: "240px", margin: "0px 10px" },
  selectField: { width: "240px" },
  aside: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "flex-end",
  },
});
