import { Button, TextField, Typography } from "@material-ui/core";
import { EventNote, QueryBuilder } from "@material-ui/icons";
import classNames from "classnames";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import { DateTextField } from "src/components";
import { COLORS } from "src/styles";
import { checkForSafari } from "src/utils/helpers";
import {
  useSafariDateTimeValidations,
  useSafariDateValidations,
} from "src/utils/hooks/useDatetimeValidation";

const moment = extendMoment(Moment as any);

interface DateTimeFieldSetProps {
  classes: any;
  date: string;
  startTime: string;
  duration: string | number;
  reserveByDate: string;
  reserveByTime: string;
  bufferTime: string;
  isActive: boolean;
  eventType: any;
  onChangeHandler: (event: { target: { value: string; name: string } }) => void;
}

export const DateTimeFieldSet: React.FC<DateTimeFieldSetProps> = ({
  classes,
  date,
  startTime,
  reserveByDate,
  reserveByTime,
  bufferTime,
  duration,
  isActive,
  onChangeHandler,
  eventType,
}) => {
  // HOOKS
  const isSafari = checkForSafari();
  const eventStart = moment(`${date} ${startTime}`, `YYYY-MM-DD HH:mm`);
  const eventEnd = moment(`${date} ${startTime}`, `YYYY-MM-DD HH:mm`).add(
    Math.round(Number(duration) * 10) / 10,
    "hours"
  );

  const [showReserveByTime, setShowReserveByTime] = useState(
    process.env.NODE_ENV === "test" ||
      reserveByTime !== eventEnd.format("HH:mm")
  );
  const { inputRef: dateInputRef } = useSafariDateValidations({ date });
  const { inputRef: reserveByDateInputRef } = useSafariDateValidations({
    date: reserveByDate,
  });
  const { inputRef: startTimeInputRef } = useSafariDateTimeValidations({
    datetime: { date, time: startTime },
  });
  const { inputRef: reserveByTimeInputRef } = useSafariDateTimeValidations({
    datetime: { date: reserveByDate, time: reserveByTime },
    additionalChecks: [
      {
        rule: () => {
          const _eventEnd = moment(
            `${date} ${startTime}`,
            "YYYY-MM-DD HH:mm"
          ).add(Number(duration), "hours");
          const _reserveByDatetime = `${reserveByDate} ${reserveByTime}`;

          return moment(_reserveByDatetime, "YYYY-MM-DD HH:mm").isSameOrBefore(
            _eventEnd
          );
        },
        message: "Reserve by date cannot be past the event end time.",
      },
    ],
  });

  // HANDLERS
  const onHoverHelper = () => {
    if (!date) {
      dateInputRef.current && dateInputRef.current.focus();
    } else if (!startTime)
      startTimeInputRef.current && startTimeInputRef.current.focus();
  };
  const getHelperText = () => {
    const _duration = Number(duration);
    if (!startTime || !date) return `select start date & time first`;
    else if (_duration < 1) return `must be at least an hour long.`;
    else if (_duration > 24) return `duration exceeds 24 hours`;
    else return `End at ${eventEnd.format("MMM Do hh:mma")}`;
  };

  const isOngoing = moment.range(eventStart, eventEnd).contains(moment());
  const getEventStartDateTimeProps = (props: any = {}, isDate: boolean) => {
    const _props = {} as any;

    if (isActive) {
      if (isOngoing) _props.disabled = true;
      else _props.min = isDate ? date : startTime;
    }

    if (isSafari) {
      _props.placeholder = isDate ? "YYYY/MM/DD" : "HH:MM A";
    }

    return {
      ...props,
      ..._props,
    };
  };

  useEffect(() => {
    if (!showReserveByTime) {
      onChangeHandler({
        target: {
          value: eventEnd.format("YYYY-MM-DD"),
          name: "reserveByDate",
        },
      });
      onChangeHandler({
        target: {
          value: eventEnd.format("HH:mm"),
          name: "reserveByTime",
        },
      });
    }
  }, [eventEnd.valueOf()]);

  const reserveByDatetime = `${reserveByDate} ${reserveByTime}`;

  return (
    <fieldset className={classes.fieldset} name="eventDateTime">
      <legend className="hidden-label">Event Date Fields</legend>
      <div className={classNames(classes.timeFieldsWrapper, classes.row)}>
        <div className={classes.timeInputWrapper}>
          <Typography
            component="label"
            variant="caption"
            className={classes.inputLabel}
            htmlFor="event-date-input"
          >
            Date*
          </Typography>
          <DateTextField
            fullWidth
            value={date}
            onChange={onChangeHandler}
            ref={dateInputRef}
            type="date"
            mask="9999-99-99"
            variant="outlined"
            required
            InputProps={{
              classes: {
                root: classes.inputRoot,
                notchedOutline: classes.notchedOutline,
                input: classes.input,
              },
              labelWidth: 0,
              endAdornment: <EventNote style={{ color: COLORS.DARK_GREY }} />,
            }}
            inputProps={{
              ...getEventStartDateTimeProps(
                {
                  id: "event-date-input",
                  min: moment().format(`YYYY-MM-DD`),
                  type: "date",
                  name: "date",
                },
                true
              ),
            }}
          />
        </div>

        <div className={classes.timeInputWrapper}>
          <Typography
            component="label"
            variant="caption"
            className={classes.inputLabel}
            htmlFor="startTime-input"
          >
            Start Time*
          </Typography>
          <DateTextField
            mask="99:99"
            inputRef={startTimeInputRef}
            fullWidth
            variant="outlined"
            InputProps={{
              classes: {
                root: classes.inputRoot,
                notchedOutline: classes.notchedOutline,
                input: classes.input,
              },
              labelWidth: 0,
              endAdornment: isSafari && (
                <QueryBuilder style={{ color: COLORS.DARK_GREY }} />
              ),
            }}
            required
            value={startTime}
            onChange={onChangeHandler}
            inputProps={{
              ...getEventStartDateTimeProps(
                Object.assign(
                  {},
                  {
                    id: "startTime-input",
                    type: "time",
                    name: "startTime",
                    // step: "900", // 15 min = 900 seconds
                  },
                  moment().isSame(moment(date, "YYYY-MM-DD"), "d") && {
                    min: moment().format("HH:mm"),
                  }
                ),
                false
              ),
            }}
          />
        </div>

        <div className={classes.timeInputWrapper}>
          <Typography
            component="label"
            variant="caption"
            className={classes.inputLabel}
            htmlFor="duration-input"
          >
            Duration (in hours)*
          </Typography>
          <TextField
            size="small"
            fullWidth
            required
            type="number"
            value={duration}
            onChange={onChangeHandler}
            variant="outlined"
            helperText={getHelperText()}
            FormHelperTextProps={Object.assign(
              {},
              {
                classes: { root: classes.helperText },
              },
              !startTime && {
                onMouseOver: onHoverHelper,
                style: { cursor: "pointer" },
              }
            )}
            InputProps={{
              readOnly: !startTime,
              classes: {
                root: classes.inputRoot,
                notchedOutline: classes.notchedOutline,
                input: classes.input,
              },
              endAdornment: (
                <QueryBuilder style={{ color: COLORS.DARK_GREY }} />
              ),
            }}
            inputProps={{
              id: "duration-input",
              name: "duration",
              step: ".5",
              min: "1",
              max: "24",
              ["data-testid"]: "duration",
            }}
          />
        </div>
      </div>
      {eventType[0] !== "FIXED_TIME" && (
        <>
          {showReserveByTime ? (
            <div className={classes.timeFieldsWrapper}>
              <div className={classes.timeInputWrapper}>
                <Typography
                  component="label"
                  variant="caption"
                  className={classes.inputLabel}
                  htmlFor="reserveBy-date-input"
                >
                  Reserve by date*
                </Typography>
                <DateTextField
                  ref={reserveByDateInputRef}
                  mask="9999/99/99"
                  value={reserveByDate}
                  onChange={onChangeHandler}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    classes: {
                      root: classes.inputRoot,
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                    labelWidth: 0,
                    endAdornment: (
                      <EventNote style={{ color: COLORS.DARK_GREY }} />
                    ),
                  }}
                  required
                  inputProps={Object.assign(
                    {},
                    {
                      id: "reserveBy-date-input",
                      name: "reserveByDate",
                      min: moment().format("YYYY-MM-DD"),
                      max: eventEnd.format("YYYY-MM-DD"),
                    },
                    { type: "date" }
                  )}
                />
              </div>

              <div className={classes.timeInputWrapper}>
                <Typography
                  component="label"
                  variant="caption"
                  className={classes.inputLabel}
                  htmlFor="reserveBy-time-input"
                >
                  Reserve by time*
                </Typography>
                <DateTextField
                  ref={reserveByTimeInputRef}
                  mask="99:99"
                  variant="outlined"
                  value={reserveByTime}
                  onChange={onChangeHandler}
                  fullWidth
                  InputProps={{
                    classes: {
                      root: classes.inputRoot,
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                    labelWidth: 0,
                    endAdornment: isSafari && (
                      <QueryBuilder style={{ color: COLORS.DARK_GREY }} />
                    ),
                  }}
                  required
                  inputProps={Object.assign(
                    {},
                    {
                      id: "reserveBy-time-input",
                      name: "reserveByTime",
                      // step: "900", // 15 min = 900 seconds
                    },
                    reserveByDate &&
                      date &&
                      startTime &&
                      duration &&
                      moment(reserveByDate, "YYYY-MM-DD").isSame(
                        eventEnd,
                        "d"
                      ) && {
                        max: eventEnd.format("HH:mm"),
                      },
                    { type: "time" }
                  )}
                />
              </div>
              <div className={classes.timeInputWrapper}>
                <Typography
                  component="label"
                  variant="caption"
                  className={classes.inputLabel}
                  htmlFor="buffer-time-input"
                >
                  Prep Time (in minutes)*
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  required
                  type="number"
                  value={(Number(bufferTime) / 60 / 1000).toString()}
                  onChange={onChangeHandler}
                  variant="outlined"
                  FormHelperTextProps={Object.assign(
                    {},
                    {
                      classes: { root: classes.helperText },
                    },
                    !startTime && {
                      onMouseOver: onHoverHelper,
                      style: { cursor: "pointer" },
                    }
                  )}
                  InputProps={{
                    classes: {
                      root: classes.inputRoot,
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                    endAdornment: (
                      <QueryBuilder style={{ color: COLORS.DARK_GREY }} />
                    ),
                  }}
                  inputProps={{
                    id: "buffer-time-input",
                    name: "bufferTime",
                    step: "15",
                    min: "15",
                    max: "240",
                    ["data-testid"]: "buffer-time-input",
                  }}
                />
              </div>
            </div>
          ) : (
            <div className={classes.defaultReserveByContainer}>
              <Typography
                variant="body2"
                component="p"
                style={{ margin: "auto 0", marginRight: "1rem" }}
              >
                Order Cutoff at{" "}
                <u>
                  {moment(reserveByDatetime, "YYYY-MM-DD HH:mm").format(
                    "MMM Do h:mma"
                  )}
                </u>{" "}
                with{" "}
                <u>{(Number(bufferTime) / 60 / 1000).toFixed(0)} minutes</u> of
                prep-time.
              </Typography>
              <Button
                variant="outlined"
                color="secondary"
                id="edit-reserveBy-btn"
                onClick={() => setShowReserveByTime(true)}
                data-testid="edit-rerserve-by-btn"
              >
                Edit
              </Button>
            </div>
          )}
        </>
      )}
    </fieldset>
  );
};
