import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";
import { IGetMe } from "src/models";
import { Layout } from "./layout";

interface IProps {
  data: { getMe: IGetMe; loading: boolean; error: any };
}

declare let window: any;

export const BecomeCookContainer: React.FC<IProps> = ({ data }) => {
  const content = (
    <>
      <Helmet>
        <title>Start Your Home Restaurant | Foodnome</title>
        <meta
          name="description"
          content="Tools to help you start your home based business selling \
          food! Home restaurant permitting, online marketplace listing, and more."
        />
      </Helmet>
      <Layout user={data?.getMe} />
    </>
  );

  if (window.cordova) {
    return content;
  }

  return (
    // GoogleReCaptchaProvider needs to wrap the container that has a captcha in it
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY as string}
      scriptProps={{ async: true, defer: true, appendTo: "head" }}
    >
      {content}
    </GoogleReCaptchaProvider>
  );
};
