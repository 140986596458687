export enum Actions {
  UPLOAD_IMAGE = "UPLOAD_IMAGE",
  DELETE_DISH_IMAGE = "DELETE_DISH_IMAGE",
  SET_INPUT = "SET_INPUT",
}

export interface IAction {
  type: Actions;
  [key: string]: any;
}
