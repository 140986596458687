import { useQuery } from "@apollo/client";
import {
  Avatar,
  BottomNavigationAction,
  CircularProgress,
} from "@material-ui/core";
import React from "react";
import { sharedAPI } from "src/shared-graphql";
import { GET_ME } from "src/shared-graphql/queries";
import { useStyles } from "./avatar-nav-action.styles";

export const AvatarBottomNavigationAction: React.FC = () => {
  const { data, error, loading } = useQuery(GET_ME);
  const classes = useStyles();

  if (error) return null;

  return (
    <BottomNavigationAction
      data-testid="AvatarBottomNavigationAction-avatar"
      key="avatar"
      style={{ paddingTop: 6 }}
      classes={{
        label: classes.label,
        root: classes.rootAction,
        selected: classes.selected,
      }}
      icon={
        loading ? (
          <CircularProgress />
        ) : (
          <Avatar
            classes={{ root: classes.avatarRoot }}
            alt={"Me."}
            {...Object.assign(
              {},
              data && data.getMe
                ? data.getMe.picture
                  ? { src: data.getMe.picture }
                  : {
                      children: `${data.getMe.firstName[0]}${data.getMe.lastName[0]}`,
                    }
                : {}
            )}
          />
        )
      }
      onClick={() => sharedAPI.setAuthDrawerState(true)}
    />
  );
};
