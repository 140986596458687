import { makeStyles } from "@material-ui/core";

export const listItemStyles = makeStyles({
  card: {
    display: "flex",
    justifyContent: "space-between",
    padding: "6px",
    marginTop: "12px",
  },
  container: {
    display: "flex",
  },
  info: {
    marginLeft: "6px",
  },
  actionBtns: {
    display: "flex",
  },
  btn: {
    color: "white",
    fontFamily: "CustomFourBold",
    height: 30,
  },
});
