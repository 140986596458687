import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const layoutStyles = makeStyles({
  container: {
    width: "100%",
    backgroundColor: "#F8F8F8",
  },
  listContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 2px",
    padding: "0 10px",
  },
  mobileEventList: {
    backgroundColor: "inherit",
    overflowX: "scroll",
    display: "flex",
  },
  title: {
    color: COLORS.BLACK,
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    margin: 0,
  },
  seeAll: {
    color: "#3ABD5A",

    fontWeight: 300,

    margin: 0,
    cursor: "pointer",
  },
  mobileContainer: {
    padding: "20px 5px",
    backgroundColor: COLORS.WHITE,
    "&:nth-child(even)": {
      backgroundColor: "#F8F8F8",
    },
  },
});
