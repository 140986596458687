import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Edit, Launch } from "@material-ui/icons";
import React, { FC, Fragment, useState } from "react";
import { IRestaurant } from "src/models";
import { EditRestaurantDialog } from "./edit-dialog";
import { useStyles } from "./table.styles";

interface IProps {
  restaurants: IRestaurant[];
  redirect: (d: string) => void;
}

export const RestaurantListTable: FC<IProps> = ({ restaurants, redirect }) => {
  const classes = useStyles();
  const [hovered, setHover] = useState<string>("0");
  const [restaurantTarget, setResTarget] = useState<IRestaurant>(null as any);

  const flagRestaurant = (row: IRestaurant) => {
    if (row.complaints && row.complaints.length >= 3) {
      return true;
    }
    return false;
  };

  return (
    <Fragment>
      <EditRestaurantDialog
        open={Boolean(restaurantTarget)}
        handleClose={() => setResTarget(undefined as any)}
        restaurant={restaurantTarget}
      />
      <TableContainer component={Paper}>
        <Table style={{ maxWidth: "none" }}>
          <TableHead>
            <TableRow>
              <TableCell>Name (email)</TableCell>
              <TableCell>Permit</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Address (Tax)</TableCell>
              <TableCell>%, Paid/Cap, Month</TableCell>
              <TableCell className="table-header-cell">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {restaurants.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  hover
                  onMouseOver={() => setHover(row.id)}
                >
                  <TableCell>
                    <Typography
                      variant="body2"
                      component="p"
                      style={{ margin: 0 }}
                    >
                      {row.name}({row.id}), {row.overallReview.stars} stars
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      style={{ margin: 0 }}
                    >
                      {row.host.email}, {row.host.phoneNumber}
                    </Typography>
                  </TableCell>
                  <TableCell>{row.permitNumber}</TableCell>
                  <TableCell>{row.description}</TableCell>

                  <TableCell>
                    {row.address.slice(0, 200)}
                    <span className={classes.taxRate}>
                      ({row.taxRate * 100}%)
                    </span>
                  </TableCell>
                  <TableCell>
                    {(row?.serviceFeePerc ?? 0) * 100}%, $
                    {row?.serviceFeeInfo?.paid ?? 0}/{row?.serviceFeeCap ?? 0},{" "}
                    {row?.serviceFeeInfo?.month?.substring(0, 3)}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => setResTarget(row)}>
                        <Edit
                          style={
                            row.id === hovered
                              ? { cursor: "pointer" }
                              : { visibility: "hidden" }
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Admin View">
                      <IconButton
                        onClick={() => redirect(`restaurants/${row.id}`)}
                      >
                        <Launch
                          style={
                            row.id === hovered
                              ? { cursor: "pointer" }
                              : { visibility: "hidden" }
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};
