import React, { createContext, Dispatch, useEffect, useReducer } from "react";
import { amplitude } from "src/services";
import { LocalStorageHelper } from "src/utils/helpers";
import { Layout } from "./layout";
import { ComponentActions, initialState, IState, reducer } from "./reducer";

export const ListMapContext = createContext<{
  state: IState;
  dispatch: Dispatch<ComponentActions>;
}>({} as any);

export const RestaurantListContainer = React.memo(() => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const source = localStorage.getItem("foodnome-source") ?? "";
    LocalStorageHelper.setItem("foodnome-source", source + "(map)");
    amplitude.getInstance().logEvent("[Restaurant List] landing");
  }, []);

  return (
    <ListMapContext.Provider value={{ state, dispatch }}>
      <Layout />
    </ListMapContext.Provider>
  );
});
