import { useMutation } from "@apollo/client";
import { Avatar, Button, Divider, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import ReactStars from "react-stars";
import { IGetMe, IRestaurant, IRestaurantReview } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { COOK_REVIEW_RESPONSE } from "./api";
import { useStyles } from "./restaurant-review.styles";

interface RestaurantReviewProps {
  review: IRestaurantReview;
  restaurant?: IRestaurant;
  getMe?: IGetMe;
  maxChar?: number;
  isLastChild?: boolean;
}

export const RestaurantReview: React.FC<RestaurantReviewProps> = ({
  review,
  getMe,
  maxChar = 200,
  isLastChild,
  restaurant,
}) => {
  const classes = useStyles();
  const [leaveResponse, { loading }] = useMutation(COOK_REVIEW_RESPONSE);

  const [isExpanded, setExpanded] = useState<boolean>(
    review.text.length < maxChar
  );
  const [isCookExpanded, setCookExpanded] = useState<boolean>(
    !!review.cookResponse && review.cookResponse.length < maxChar
  );
  const [cookResponse, setCookResponse] = useState(review.cookResponse ?? "");
  const [showResponseInput, setShowResponseInput] = useState(false);
  const date = moment(Number(review.updatedAt));
  const avatarProps = Object.assign(
    {},
    { alt: review.guest.firstName },
    review.guest.picture
      ? { src: review.guest.picture }
      : { children: `${review.guest.firstName[0]}${review.guest.lastName[0]}` }
  );

  const cookAvatarProps = Object.assign(
    {},
    { alt: restaurant?.host.firstName },
    restaurant?.host.picture
      ? { src: restaurant?.host.picture }
      : {
          children: `${restaurant?.host.firstName[0]}${restaurant?.host.lastName[0]}`,
        }
  );

  return (
    <>
      <article
        className={classes.container}
        data-testid="restaurant-user-review"
      >
        <div style={{ display: "flex" }}>
          <Avatar className={classes.avatar} {...avatarProps} />
          <div className={classes.reviewHeader}>
            <Typography
              variant="body2"
              component="p"
              className={classes.reviewAuthor}
            >
              {review.guest.firstName}
            </Typography>
            <section className={classes.actionContainer}>
              <Typography
                variant="body2"
                component="time"
                className={classes.reviewDate}
                dateTime={date.format("YYYY-MM-DD")}
              >
                {Math.abs(moment.duration(date.diff(moment())).asMonths()) > 1
                  ? date.format(`MMM D, YYYY`)
                  : date.fromNow()}
              </Typography>
              {review.restaurantId === getMe?.restaurant?.id &&
                !showResponseInput && (
                  <button
                    className={classes.replyBtn}
                    onClick={() => setShowResponseInput(true)}
                  >
                    {!review.cookResponse ? "Reply" : "Edit"}
                  </button>
                )}
            </section>
          </div>
        </div>
        <div className={classes.padding}>
          <ReactStars
            count={5}
            color={"#F7CC15"}
            color2={"#ffd700"}
            value={(review.quality + review.hospitality + review.accuracy) / 3}
            edit={false}
          />
        </div>
        <div className={classes.padding}>
          <Typography
            variant="body2"
            component="blockquote"
            className={classes.reviewText}
            data-testid="review-text"
          >
            {isExpanded
              ? review.text
                ? review.text
                : "[This diner did not leave a comment]"
              : review.text.slice(0, maxChar) + "..."}
          </Typography>
          {review.text.length > maxChar ? (
            <Button
              classes={{ root: classes.expansionButton }}
              size="small"
              onClick={() => setExpanded((p) => !p)}
            >
              {isExpanded ? "Minimize" : "Read More"}
            </Button>
          ) : null}
        </div>
        {!!review.cookResponse && !showResponseInput && (
          <div className={classes.cookResponseContainer}>
            <Avatar className={classes.cookAvatar} {...cookAvatarProps} />
            <div className={classes.cookResponseTitle}>
              <Typography
                variant="body2"
                component="p"
                className={classes.hostName}
              >
                {restaurant?.host.firstName}
              </Typography>
              <Typography
                variant="body2"
                component="p"
                className={classes.reviewText}
                data-testid="review-text"
              >
                {isCookExpanded
                  ? review.cookResponse
                  : review.cookResponse.slice(0, maxChar) + "..."}
              </Typography>
              {review.cookResponse?.length > maxChar ? (
                <Button
                  classes={{ root: classes.expansionButton }}
                  size="small"
                  onClick={() => setCookExpanded((p) => !p)}
                >
                  {isCookExpanded ? "Minimize" : "Read More"}
                </Button>
              ) : null}
            </div>
          </div>
        )}
        {showResponseInput && (
          <div className={classes.inputContainer}>
            <textarea
              id="comments-input"
              value={cookResponse}
              className={classes.textArea}
              onChange={(e) => setCookResponse(e.target.value)}
              rows={4}
              placeholder={"Leave your comment for this review here!"}
            />
            <div>
              <Button
                classes={{ root: classes.cancelBtn }}
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => setShowResponseInput(false)}
              >
                Cancel
              </Button>
              <Button
                classes={{ root: classes.submitBtn }}
                size="small"
                variant="contained"
                color="secondary"
                onClick={() =>
                  leaveResponse({
                    variables: { input: { cookResponse, id: review.id } },
                  }).then(() => {
                    setShowResponseInput(false);
                    sharedAPI.setSnackbarMsg({
                      type: "success",
                      msg: "Submitted",
                    });
                  })
                }
              >
                {"Submit"}
              </Button>
            </div>
          </div>
        )}
      </article>
      {/* We don't want to show the divider on the last review */}
      {!isLastChild && <Divider />}
    </>
  );
};
