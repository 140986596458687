import { Button, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { COLORS } from "src/styles";

interface IProps {
  history: any;
}

const useStyles = makeStyles({
  container: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "#f05f66",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
  },
  img: {
    maxWidth: 1000,
    width: "100%",
    objectFit: "contain",
  },
  button: {
    margin: "2em 0",
    borderColor: COLORS.WHITE,
    color: COLORS.WHITE,
    background: "transparent",
    borderRadius: 0,
    fontFamily: "Signika, sans-serif",

    "&:hover": {
      background: "#f05f66",
    },
  },
});

export const NoMatchComponent: FC<IProps> = ({ history, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {/* <div style={{ whiteSpace: "pre-wrap", width: "200px" }}>
        {JSON.stringify(window.location)}
      </div> */}
      <img
        className={classes.img}
        src={require("src/assets/images/404-error.jpg")}
        alt="404 error."
      />
      <Button
        onClick={() => history.push("/")}
        size="large"
        variant="outlined"
        className={classes.button}
      >
        Take me back home!
      </Button>
    </div>
  );
};
