import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  paper: {
    position: "fixed",
    width: "100%",
    bottom: 0,
    left: 0,
    zIndex: 200,
    borderRadius: "25px 25px 0 0",
    backgroundColor: COLORS.WHITE,
    boxShadow: "0 -2px 8px 0 rgba(13,13,13,0.16)",
    paddingBottom: "env(safe-area-inset-bottom)",
    transform: "none !important",
  },
  proceedToCheckoutBtnRoot: {
    padding: "1rem",
  },
  proceedToCheckoutBtnContainedSecondary: {
    color: COLORS.WHITE,
    "&:disabled": {
      backgroundColor: COLORS.LIGHT_GREEN,
    },
  },
  proceedToCheckoutBtnLabel: {
    fontFamily: "CustomFourBold",
  },
  totalContainer: {
    backgroundColor: "#FFEEF1",
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    minHeight: "80px",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      minHeight: "unset",
      padding: "8px 22px",
    },
  },
  total: {
    fontFamily: "CustomFourBold",
  },
  totalAmount: {
    color: COLORS.RED,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  totalContainerPayBtn: {
    position: "absolute",
    top: "50%",
    right: "0",
    transform: "translate(-10px, -50%)",
  },
  draggableContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  draggableBars: {
    // margin: "0 auto",
    cursor: "pointer",

    display: "block",

    width: "15%",
    minWidth: "4rem",
    margin: "1rem 0",
    marginBottom: "2rem",

    height: 4,
    borderRadius: 4,
    backgroundColor: "rgba(32, 32, 32, 0.2)",
  },
});
