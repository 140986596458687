import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";
import { headerStyles as useStyles } from "./header.styles";

interface IProps {
  totalCount: number;
  status: string;
  county: string;
  assignee: string;
  setStatus: (d: string) => void;
  setCounty: (d: string) => void;
  onSearch: (d: string) => void;
  setDescriptionSearchTerm: (d: string) => void;
  setAssignee: (d: string) => void;
}

export const Header: FC<IProps> = ({
  totalCount,
  status,
  county,
  assignee,
  setStatus,
  setCounty,
  setAssignee,
  onSearch,
  setDescriptionSearchTerm,
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Typography variant="h3" component="h1" className={classes.count}>
        Applications ({totalCount})
      </Typography>

      <aside className={classes.aside}>
        <FormControl>
          <InputLabel shrink id="select-assignee">
            Select Assignee
          </InputLabel>
          <Select
            labelId="select-assignee"
            value={assignee}
            className={classes.selectField}
            onChange={(d) => setAssignee(d.target.value as string)}
          >
            <MenuItem value={"ALL"}>All</MenuItem>
            <MenuItem value={"Akshay"}>Akshay</MenuItem>
            <MenuItem value={"Isaac"}>Isaac</MenuItem>
            <MenuItem value={"Leo"}>Leo</MenuItem>
            <MenuItem value={"AdamG"}>AdamG</MenuItem>
            <MenuItem value={"AdamS"}>AdamS</MenuItem>
            <MenuItem value={"Raymond"}>Raymond</MenuItem>
            <MenuItem value={"Emily"}>Emily</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel shrink id="select-by-county">
            Select By County
          </InputLabel>
          <Select
            labelId="select-by-county"
            value={county}
            className={classes.selectField}
            onChange={(d) => setCounty(d.target.value as string)}
          >
            <MenuItem value={"ALL"}>All</MenuItem>
            <MenuItem value={"Alameda County"}>Alameda County</MenuItem>
            <MenuItem value={"Imperial County"}>Imperial County</MenuItem>
            <MenuItem value={"Riverside County"}>Riverside County</MenuItem>
            <MenuItem value={"Santa Barbara County"}>
              Santa Barbara County
            </MenuItem>
            <MenuItem value={"Lake County"}>Lake County</MenuItem>
            <MenuItem value={"San Bernardino County"}>
              San Bernardino County
            </MenuItem>
            <MenuItem value={"Solano County"}>Solano County</MenuItem>
          </Select>
        </FormControl>
        <TextField
          size="small"
          id="standard-info"
          label="Search by general info"
          className={classes.searchField}
          onChange={(e) => onSearch(e.target.value)}
          margin="normal"
        />
        <TextField
          size="small"
          id="standard-description"
          label="Search by description"
          className={classes.searchField}
          onChange={(e) => setDescriptionSearchTerm(e.target.value)}
          margin="normal"
        />
        <FormControl>
          <InputLabel shrink id="select-by-application-status">
            Select By App. Status
          </InputLabel>
          <Select
            labelId="select-by-application-status"
            value={status}
            className={classes.selectField}
            onChange={(d) => setStatus(d.target.value as string)}
          >
            <MenuItem value={"ALL"}>All</MenuItem>
            <MenuItem value={"PENDING"}>PENDING</MenuItem>
            <MenuItem value={"ACTION_REQUIRED"}>ACTION REQUIRED</MenuItem>
            <MenuItem value={"SUBMITTED"}>WAITING ON COUNTY</MenuItem>
            <MenuItem value={"SCHEDULED"}>WAITING ON COOK</MenuItem>
            <MenuItem value={"REJECTED"}>REJECTED</MenuItem>
            <MenuItem value={"ON_HOLD"}>ON HOLD</MenuItem>
            <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
          </Select>
        </FormControl>
      </aside>
    </Paper>
  );
};
