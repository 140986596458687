import React, { useState } from "react";
import { MissionCarousel } from "../carousel";
import { companyValues } from "./helpers";
import { useStyles } from "./legislation.styles";
import { Typography } from "@material-ui/core";
import { Fade } from "react-awesome-reveal";
export const Carousel = () => {
  const [step, setStep] = useState(0);
  const classes = useStyles();

  return (
    <div className={classes.innerContainer}>
      <div className={classes.headerContainer}>
        <Fade triggerOnce={true}>
          <Typography variant="h2" component="h2" className={classes.title}>
            Our Impact
          </Typography>
        </Fade>
        <div className={classes.squiggleContainer}>
          <img
            src={require("../assets/underline.svg")}
            className={classes.squiggle}
          />
        </div>
      </div>
      <MissionCarousel
        valuesData={companyValues}
        setStep={setStep}
        step={step}
      />
    </div>
  );
};
