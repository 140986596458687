import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { userAccountAPI } from "src/components/header/api";
import { useActivityMonitor } from "src/utils/hooks";
import { ConfirmationDialog } from "./confirmation-dialog/confirmation-dialog";

export const TimeoutDialog: FC = () => {
  const history = useHistory();
  const logout = () => userAccountAPI.logout().then(() => history.push("/"));
  const { promptUser, restoreSession } = useActivityMonitor({
    timeout: 1000 * 60 * 30, // 30 minutes
    onKillSession: logout,
  });

  const handleLogout = () => {
    return logout();
  };

  return promptUser ? (
    <ConfirmationDialog
      onContinue={restoreSession}
      openDialog={promptUser}
      onClose={() => ({})}
      onConfirm={handleLogout}
    />
  ) : null;
};
