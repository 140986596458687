import React, { FC, useEffect, useState } from "react";
import { FullScreenLoading } from "src/components";
import { IUpdateUserPL, IUpdateUserPreferences } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { userAPI } from "./api";
import { initialState } from "./form/state";
import { Layout } from "./layout";

export const AccountContainer: FC<any> = ({ getMe }) => {
  const [init, setInit] = useState<any>(
    getMe
      ? {
          ...initialState,
          firstName: getMe.firstName,
          lastName: getMe.lastName,
          email: getMe.email,
          picture: getMe.picture ?? "",
          address: getMe.address ? getMe.address : "",
          phoneNumber: getMe.phoneNumber ? `1-${getMe.phoneNumber}` : "",
          newsletter: getMe.preferences
            ? getMe.preferences.NEWS_LETTER_EMAIL_SUBSCRIPTION
            : false,
        }
      : undefined
  );

  useEffect(() => {
    setInit(
      getMe
        ? {
            ...initialState,
            firstName: getMe.firstName,
            lastName: getMe.lastName,
            email: getMe.email,
            picture: getMe.picture ?? "",
            address: getMe.address ? getMe.address : "",
            phoneNumber: getMe.phoneNumber ? `1-${getMe.phoneNumber}` : "",
            newsletter: getMe.preferences
              ? getMe.preferences.NEWS_LETTER_EMAIL_SUBSCRIPTION
              : false,
          }
        : undefined
    );
  }, [getMe]);

  const updateUser = (d: IUpdateUserPL, p: IUpdateUserPreferences) => {
    return userAPI
      .updateUser(d, p)
      .then(() => {
        sharedAPI.setSnackbarMsg({
          type: "success",
          msg: "Account information saved!",
        });
        setInit({ ...init, ...d });
      })
      .catch(() =>
        sharedAPI.setSnackbarMsg({
          type: "error",
          msg: `Oops! That's embarrassing. We couldn't save your information at this time.`,
        })
      );
  };

  if (!getMe) return <FullScreenLoading />;

  return (
    <Layout
      initialState={init}
      updateUser={updateUser}
      uploadImg={userAPI.uploadImg}
      isCook={!!(getMe && getMe.restaurant)}
    />
  );
};
