import { Paper } from "@material-ui/core";
import { Dropin } from "braintree-web-drop-in";
import React, { FC, useEffect, useState } from "react";
import Draggable, { DraggableData } from "react-draggable"; // <DraggableCore>
import { IGetMe } from "src/models";
import { amplitude } from "src/services";
import { sharedAPI } from "src/shared-graphql";
import { BTDropIn } from "./bt-dropin";
import { useStyles } from "./mobile-payment-dialog.styles";

interface IProps {
  getMe: IGetMe;
  total: number;
  submitOrder: (token?) => Promise<any>;
}

export const MobilePaymentDialog: FC<IProps> = ({
  total,
  submitOrder,
  getMe,
}) => {
  const classes = useStyles();
  const [paymentSelected, setPaymentSelected] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [brainTreeInstance, setBrainTreeInstance] = useState<Dropin | null>(
    null
  );
  const [button, setButton] = useState<boolean>(false);
  const [dragging, setDragging] = useState<boolean>(false);

  const [height, setHeight] = useState<number>(window.innerHeight / 2);

  useEffect(() => {
    if (brainTreeInstance?.isPaymentMethodRequestable()) {
      setButton(true);
    }
    brainTreeInstance?.on("paymentMethodRequestable", (event) => {
      // If user has a card selected or is typing in a card and returns a card type
      setButton(event.paymentMethodIsSelected || event.type ? true : false);
    });
    brainTreeInstance?.on("noPaymentMethodRequestable", () => {
      setButton(false);
    });
    brainTreeInstance?.on("paymentOptionSelected", (event) => {
      setPaymentSelected(event.paymentOption);
      setDragging(false);
    });
  }, [brainTreeInstance]);

  useEffect(() => {
    if (paymentSelected === "card") {
      setHeight(window.innerHeight - 100);
    }
  }, [paymentSelected]);

  const handleSubmit = async () => {
    setLoading(true);
    if (total === 0) {
      // If total is 0, we don't need a BT token because we are not processing a payment
      submitOrder().finally(() => {
        amplitude
          .getInstance()
          .logEvent("[Mobile Checkout] Checkout with BrainTree");
        setLoading(false);
      });
    } else {
      brainTreeInstance?.requestPaymentMethod((err, payload) => {
        if (err || !payload) {
          sharedAPI.setSnackbarMsg({ type: "error", msg: "" });
          setLoading(false);
        } else {
          submitOrder(payload.nonce).finally(() => {
            amplitude
              .getInstance()
              .logEvent("[Mobile Checkout] Mobile checkout with BrainTree");
            setLoading(false);
          });
        }
      });
    }
  };

  const handleDrag = (e, ui: DraggableData) => {
    setDragging(true);

    setHeight((prev) => prev - ui.deltaY);
  };

  return (
    <Draggable onDrag={handleDrag} handle="strong" axis="y">
      <Paper
        elevation={24}
        style={{
          height,
          transform: "none",
          minHeight: "3.5rem",
          maxHeight: window.innerHeight - 56,

          transition: !dragging ? "height 2s" : "none",
          transitionTimingFunction: !dragging
            ? "cubic-bezier(0.25, 1, 0.5, 1)"
            : "none",
        }}
        className={classes.paper}
      >
        <strong className={classes.draggableContainer}>
          <div className={classes.draggableBars} />
        </strong>
        <BTDropIn
          setBrainTreeInstanceHandler={setBrainTreeInstance}
          total={total}
          button={button}
          paymentSelected={Boolean(paymentSelected === "card")}
          handleSubmit={handleSubmit}
          loading={loading}
          setLoading={setLoading}
          getMe={getMe}
        />
      </Paper>
    </Draggable>
  );
};
