import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const summaryCardStyles = makeStyles({
  badgeRoot: {
    width: "100%",
    maxWidth: 483,
    display: "block",
    margin: "0 auto",
    zIndex: 0,
  },
  badgeColorPrimary: {
    backgroundColor: COLORS.MEDIUM_GREEN,
    height: 30,
    width: 30,
    borderRadius: 30,
  },
  editLink: {
    color: COLORS.WHITE,
  },
  table: {
    padding: "1rem 0",
  },
  tableRow: {},
  tableHeader: {
    textAlign: "left",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: "#2E2E2E",
    verticalAlign: "top",
  },
  tableData: {
    color: COLORS.DARK_GREY,
  },
  cardImage: {
    maxWidth: "100%",
  },
  card: {
    border: `1px solid #CCCCCC`,
    boxShadow: "none",
    borderRadius: 0,
    position: "relative",
  },
  cardHeading: {
    textAlign: "center",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    margin: 0,
    backgroundColor: "#F8F8F8",

    padding: ".5rem",
  },
  cardBody: {
    maxWidth: 400,
    margin: "0 auto",
    width: "100%",
    padding: "1rem",
  },
});
