import {
  Grid,
  StepConnector,
  Typography,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { BREAK_POINTS } from "src/styles";
import { useStyles } from "./getting-started.styles";
import { stepsDesktop, stepsMobile } from "./helpers";

const ColorlibConnector = withStyles({
  lineVertical: {
    display: "none",
  },
  vertical: {
    display: "none",
  },
})(StepConnector);

export const Steps = () => {
  const classes = useStyles();

  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  return (
    <>
      {isMobile ? (
        <section className={classes.mobileStepContainer}>
          {stepsMobile.map((step, idx) => (
            <div key={idx} className={classes.mobileStep}>
              <div className={classes.mobileStepIndexContainer}>
                <Typography
                  variant="h1"
                  component="p"
                  className={classes.mobileStepIndex}
                >
                  {idx + 1}
                </Typography>
              </div>
              <Typography
                variant="h4"
                component="h4"
                className={classes.mobileStepTitle}
              >
                {step.title}
              </Typography>
              <Typography
                variant="h4"
                component="h4"
                className={classes.mobileStepText}
              >
                {step.text}
              </Typography>
            </div>
          ))}
        </section>
      ) : (
        <section className={classes.desktopStepContainer}>
          {stepsDesktop.map((step, idx) => (
            <Grid
              container
              key={idx}
              justify="space-between"
              direction="row"
              alignContent="flex-start"
              alignItems="flex-start"
              wrap="nowrap"
            >
              <div className={classes.desktopStepIndexContainer}>
                <Typography
                  variant="h1"
                  component="p"
                  className={classes.desktopStepIndex}
                >
                  {idx + 1}
                </Typography>
              </div>

              <Grid item className={classes.desktopTextContainer}>
                <Typography
                  variant="h3"
                  component="h3"
                  className={classes.desktopStepTitle}
                >
                  {step.title}
                </Typography>
                <Typography
                  variant="h4"
                  component="p"
                  className={classes.desktopStepText}
                >
                  {step.text}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </section>
      )}
    </>
  );
};
