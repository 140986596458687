import { SvgIcon } from "@material-ui/core";
import React from "react";

export const DinerOrder = (props) => (
  <SvgIcon {...props}>
    <g id="Layer_2">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        d="M15.21,0c-2.19,0-3.9,2.29-3.9,5.23c0,2.1,1.12,4.15,2.74,4.97l0.15,0.07v0.17l0.08,8.82c0,0.8,0.3,1.18,0.93,1.18
										c0.65,0,1.1-0.5,1.1-1.26l-0.09-8.98l0.14-0.08c1.66-0.84,2.78-2.8,2.78-4.89C19.14,2.29,17.41,0,15.21,0z M15.46,7.85
										l-0.05,0.03C15.35,7.9,15.28,7.9,15.23,7.9c-0.06,0-0.12-0.01-0.18-0.02l-0.04-0.01c-0.57-0.25-1.14-1.26-1.14-2.66
										c0-1.49,0.75-2.69,1.36-2.69c0.61,0,1.36,1.24,1.36,2.69C16.59,6.65,15.92,7.64,15.46,7.85z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M8.88,7.23c0,1.37-0.96,2.48-2.13,2.48H5.51v9.57c0.01,0.41-0.07,0.72-0.25,0.9c-0.18,0.18-0.44,0.26-0.82,0.26
								c-0.51,0-0.9-0.5-0.9-1.16V9.71H2.25C1.01,9.71,0,8.54,0,7.1V1.57C0,0.94,0.45,0.4,0.99,0.4c0.58,0,0.91,0.38,0.97,1.11v5.87
								h1.5V1.59c0-0.33,0.11-0.62,0.29-0.84c0.18-0.2,0.44-0.33,0.69-0.33c0.53,0,0.98,0.54,0.98,1.17v5.79h1.47V1.59
								c0-0.64,0.44-1.15,1-1.17c0.29-0.03,0.5,0.09,0.62,0.2c0.23,0.21,0.36,0.53,0.35,0.84L8.88,7.23z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
