import { AppBar, makeStyles, Toolbar, useMediaQuery } from "@material-ui/core";
import React from "react";
import { BREAK_POINTS } from "src/styles";

export const MobileCookFlowHeader: React.FC = () => {
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  const classes = useStyles();

  if (isMobile)
    return (
      <AppBar
        position="fixed"
        color="default"
        classes={{ root: classes.appBar }}
      >
        <Toolbar className={classes.cookFlowHeaderContainer}>
          <img
            alt="cook connect logo"
            className={classes.foodnomeLogo}
            // main_header_logo_white_outline.png used for mobile only
            src={require(`src/assets/logos/main_header_logo_white_outline.png`)}
          />
        </Toolbar>
      </AppBar>
    );
  return null;
};

export const useStyles = makeStyles({
  cookFlowHeaderContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  foodnomeLogo: {
    width: "60%",
  },
  appBar: {
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.12)",
  },
});
