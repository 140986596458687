import { gql } from "@apollo/client";

export const GET_APPLICATIONS = gql`
  query getApplications($input: GetApplicationsPL!) {
    getApplications(getApplicationPL: $input) {
      count
      rows {
        id
        description
        status
        device
        source
        progress
        inspectionTime
        assignee
        applicationStatuses {
          id
          description
          status
          createdAt
        }
        info {
          question1
          question2
          question3
          businessName
          address
          hasVentilation
          ventilation
          hasDishwasher
          isPublicWater
          waterCompany
          isPublicSewer
          numberOfPerson
          numberOfRestroom
          numberOfSinks
          dishInfos {
            name
            ingredients
          }
          idImage
          proofAddressImage
        }
        restaurant {
          id
          name
          street
          city
          zipCode
          county
          host {
            id
            firstName
            lastName
            email
            phoneNumber
          }
        }
        updatedAt
        createdAt
      }
    }
  }
`;

export const GET_APPLICATION = gql`
  query getApplication($input: ID!) {
    getApplication(id: $input) {
      id
      description
      inspectionTime
      updatedAt
      assignee
      createdAt
      restaurant {
        id
        name
      }
    }
  }
`;
