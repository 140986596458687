import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  Delete,
  Done,
  Edit,
  Loyalty,
  NoteAdd,
  People,
  RateReview,
  Restaurant,
} from "@material-ui/icons";
import moment from "moment";
import React, { FC, Fragment, useEffect, useState } from "react";
import { ConfirmationDialog } from "src/components";
import { CreatePromotionPL, IEvent } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { memberListAPI } from "../../member/api";
import { eventAPI } from "../api";
import { AddDishDialog } from "./add-dish/add-dish";
import { EditEventDialog } from "./edit-event/edit-dialog";
import { GuestsDialog } from "./guests-dialog";
import { PromoDialog } from "./promoDialog";
import { IActions, TableActions } from "./table-actions";
import { useStyles } from "./table.styles";

interface IProps {
  events: IEvent[];
  deleteEvent: (options: any) => void;
  redirect: (d: string) => void;
  promoEvent: (d: CreatePromotionPL) => Promise<any>;
}

export const EventListTable: FC<IProps> = ({
  events,
  redirect,
  deleteEvent,
  promoEvent,
}) => {
  const classes = useStyles();
  const [hovered, setHover] = useState<string>("");
  const [deletId, setDeleteId] = useState<string>("");

  const [promoEventTarget, setPromoEventTarget] = useState<IEvent | undefined>(
    undefined
  );
  const [editEventTarget, setEditEventTarget] = useState<IEvent | undefined>(
    undefined
  );
  const [guestsTarget, setGuestsTarget] = useState<IEvent | undefined>(
    undefined
  );
  const [addDishTarget, setAddDishTarget] = useState<IEvent | undefined>(
    undefined
  );

  const _deleteItem = async (id: string) => {
    await deleteEvent(id);
    setDeleteId("");
  };

  useEffect(() => {
    if (addDishTarget) {
      setAddDishTarget(events.find((e) => e.id === addDishTarget.id));
    }
  }, [events]);

  const _promoEvent = (d: CreatePromotionPL) => {
    return promoEvent(d)
      .then(() => setPromoEventTarget(undefined))
      .catch(() => setPromoEventTarget(undefined));
  };

  const actions = (row) =>
    [
      {
        title: "Manage Event",
        icon: <Restaurant />,
        show: true,
        onClick: () =>
          redirect(`/c/menus/${row.id}-${row.name.replace(/ /g, "-")}`),
      },
      {
        title: "Edit Event",
        icon: <Edit />,
        show: true,
        onClick: () => setEditEventTarget(row),
      },
      {
        title: "View Guest",
        icon: <People />,
        show: true,
        onClick: () => setGuestsTarget(row),
      },
      {
        title: "Delete",
        icon: <Delete />,
        show: true,
        onClick: () => setDeleteId(row.id),
      },
      {
        title: "Add Discount",
        onClick: () => setPromoEventTarget(row),
        icon: <Loyalty />,
        show: true,
      },
      {
        title: "Add/Edit Dish",
        show: true,
        icon: <NoteAdd />,
        onClick: () => setAddDishTarget(row),
      },

      {
        show: true,
        title: "Send reviews to all guests",
        icon: <RateReview />,
        onClick: () =>
          eventAPI.sendReviewsToGuests(row.id).then((d) =>
            sharedAPI.setSnackbarMsg({
              type: "success",
              msg: d.data.adminRecreateReviews,
            })
          ),
      },
      {
        show:
          moment(Number(row.endTime)).isBefore(moment()) &&
          row.status === "ACTIVE",
        onClick: () =>
          eventAPI
            .completeEvent(row.id)
            .then(() => memberListAPI.transferFunds(row.restaurant.host.id))
            .then((d) =>
              sharedAPI.setSnackbarMsg({
                type: "success",
                msg: "DONE",
              })
            )
            .catch((d) =>
              sharedAPI.setSnackbarMsg({
                type: "error",
                msg: "Error",
              })
            ),
        icon: <Done />,
        title: "Complete Event",
      },
    ] as IActions[];

  return (
    <Fragment>
      <AddDishDialog
        open={Boolean(addDishTarget)}
        handleClose={() => setAddDishTarget(undefined)}
        event={addDishTarget}
      />
      <ConfirmationDialog
        openDialog={!!deletId}
        onConfirm={() => _deleteItem(deletId)}
        title={"Delete Event"}
        message={"Are you sure you want to delete this event?"}
        onClose={() => setDeleteId("")}
      />
      <EditEventDialog
        open={Boolean(editEventTarget)}
        handleClose={() => setEditEventTarget(undefined)}
        event={editEventTarget}
      />
      <PromoDialog
        close={() => setPromoEventTarget(undefined)}
        createPromo={_promoEvent}
        event={promoEventTarget}
      />
      <GuestsDialog
        close={() => setGuestsTarget(undefined)}
        event={guestsTarget}
      />
      <TableContainer component={Paper}>
        <Table style={{ maxWidth: "none" }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography component="span" variant="body2">
                  Name (id)
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  Date/Time
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  Private (Public) location
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  Price (promo)
                </Typography>{" "}
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  Guest #
                </Typography>
              </TableCell>
              <TableCell className="table-header-cell">
                <Typography component="span" variant="body2">
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((row) => (
              <TableRow
                key={row.id}
                hover
                onMouseOver={() => setHover(String(row.id))}
              >
                <TableCell>
                  {row.name}({row.id}) - {row.restaurant.host.email}
                </TableCell>
                <TableCell>
                  {moment(Number(row.startTime)).format("lll")}
                </TableCell>
                <TableCell>
                  {row.locale && (
                    <>{`${row.locale.private} (${row.locale.public})`}</>
                  )}
                </TableCell>
                <TableCell>
                  {row.type.includes("FIXED_TIME") && <span>{row.price}</span>}
                  {!row.type.includes("FIXED_TIME") && (
                    <span>
                      {Math.max(
                        ...row.dishes.map((r) =>
                          r.DishEvent ? r.DishEvent.price : 0
                        )
                      )}
                    </span>
                  )}
                </TableCell>
                <TableCell>{row.guests.length}</TableCell>
                <TableCell>
                  <TableActions row={row} actions={actions} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};
