import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import React, { FC, useState } from "react";
import { IRestaurant } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { applicationAPI } from "../../api";
import { sendAppStyles as useStyles } from "./send-app.styles";

interface IProps {
  restaurant: IRestaurant;
}

export const SendApplication: FC<IProps> = ({ restaurant }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [dba, setDba] = useState(restaurant.name ? restaurant.name : "");
  const [ownerName, setOwnerName] = useState(
    `${restaurant.host.firstName} ${restaurant.host.lastName}`
  );
  const [phoneNumber, setPhoneNumber] = useState(restaurant.host.phoneNumber);
  const [foodEmployeeName, setFoodEmployeeName] = useState("na");
  const [ownerAddress, setOwnerAddress] = useState(
    restaurant.street ? restaurant.street : ""
  );
  const [ownerCity, setOwnerCity] = useState(
    restaurant.city ? restaurant.city : ""
  );
  const [ownerZip, setOwnerZip] = useState(
    restaurant.zipCode ? restaurant.zipCode : ""
  );
  const [email, setEmail] = useState(restaurant.host.email);
  const [website, setWebsite] = useState(
    restaurant.name ? `foodnome.com/${restaurant.name.replace(/ /g, "-")}` : ""
  );

  const [sundayTime, setSundayTime] = useState("5pm-8pm");
  const [mondayTime, setMondayTime] = useState("5pm-8pm");
  const [tuesdayTime, setTuesdayTime] = useState("5pm-8pm");
  const [wednesdayTime, setWednesdayTime] = useState("5pm-8pm");
  const [thursdayTime, setThursdayTime] = useState("5pm-8pm");
  const [fridayTime, setFridayTime] = useState("5pm-8pm");
  const [saturdayTime, setSaturdayTime] = useState("5pm-8pm");
  const [sundayNumber, setSundayNumber] = useState("8");
  const [mondayNumber, setMondayNumber] = useState("8");
  const [tuesdayNumber, setTuesdayNumber] = useState("8");
  const [wednesdayNumber, setWednesdayNumber] = useState("8");
  const [thursdayNumber, setThursdayNumber] = useState("8");
  const [fridayNumber, setFridayNumber] = useState("10");
  const [saturdayNumber, setSaturdayNumber] = useState("10");

  const [utensilWashingSink, setUtensilWashingSink] = useState(true);
  const [dishwasher, setDishwasher] = useState(true);
  const [cleanInPlace, setCleanInPlace] = useState(true);
  const [chlorine, setChlorine] = useState(true);
  const [quaternaryAmmonium, setQuaternaryAmmonium] = useState(false);
  const [iodine, setIodine] = useState(false);
  const [cleaningProcess, setCleaningProcess] = useState(
    `See attached "Warewash Addendum"`
  );

  const [locationServed, setLocationServed] = useState("Kitchen only");
  const [locationStored, setLocationStored] = useState(
    "Designated indoor home restaurant specific kitchen storage (labeled shelf area), indoor refrigerator (40F, verified daily), indoor freezer (40F, verified daily). Note: No food will be stored outside, all food for restaurant will be served indoors and not on ground."
  );
  const [remainingFood, setRemainingFood] = useState(
    "All leftover food will be discarded into trash can"
  );

  const [pickUp, setPickUp] = useState(true);
  const [delivery, setDelivery] = useState(false);
  const [deliverPerson, setDeliverPerson] = useState(
    `${restaurant.host.firstName} ${restaurant.host.lastName}`
  );
  const [transportation, setTransportation] = useState(
    `My own vehicle. Make: , Model: , Year: `
  );
  const [hold, setHold] = useState(
    `please see attached "foodnome safety supplies list"`
  );
  const [maximumGeo, setMaximumGeo] = useState(`5 miles`);
  const [maximumTime, setMaximumTime] = useState(`30 mins`);
  const [_package, setPackage] = useState(
    `Dart 60HT1 white foam, single compartment container with a hinged lid`
  );

  const [publicWaterCheck, setPublicWaterCheck] = useState(true);
  const [publicWater, setPublicWater] = useState(
    restaurant.application?.info?.waterCompany ??
      "Eastern Municipal Water District"
  );
  const [privateWater, setPrivateWater] = useState("na");

  const [publicSewerCheck, setPublicSewerCheck] = useState(true);
  const [numberPerson, setNumPerson] = useState("4 people");
  const [numberRestroom, setNumRestroom] = useState("3 restrooms");
  const [numberSink, setNumSink] = useState("2 sinks");
  const [refuseStored, setRefuseStored] = useState(
    "Stored in city provided bins on cement with closing lid. Liquid waste will be bagged and disposed in bins and NOT poured in drain"
  );
  const [refusePickUp, setRefusePickUp] = useState("Weekly on Friday");
  const [ventilation, setVentilation] = useState("Vent above kitchen stove");
  const [fireExtinguisher, setFireExtinguisher] = useState(
    "Kept under kitchen sink, no food will be stored in area to avoid contamination"
  );
  const [firstAid, setFirstAid] = useState(
    "Kept under kitchen sink, no food will be stored in area to avoid contamination"
  );
  const [meal, setMeal] = useState(`See attached "Menu Addendum"`);
  const [equipment, setEquipment] = useState(
    "Please see attached menue adendum"
  );

  const onSubmit = () =>
    applicationAPI
      .sendApplicationToSign({
        restaurantId: restaurant.id,
        dba,
        ownerName,
        phoneNumber,
        foodEmployeeName,
        ownerAddress,
        ownerCity,
        ownerZip,
        email,
        website,
        sundayTime,
        mondayTime,
        tuesdayTime,
        wednesdayTime,
        thursdayTime,
        fridayTime,
        saturdayTime,
        sundayNumber,
        mondayNumber,
        tuesdayNumber,
        wednesdayNumber,
        thursdayNumber,
        fridayNumber,
        saturdayNumber,
        utensilWashingSink,
        dishwasher,
        cleanInPlace,
        chlorine,
        quaternaryAmmonium,
        iodine,
        cleaningProcess,
        locationServed,
        locationStored,
        remainingFood,
        pickUp,
        delivery,
        deliverPerson,
        transportation,
        hold,
        maximumGeo,
        maximumTime,
        package: _package,
        publicWaterCheck,
        privateWaterCheck: !publicWaterCheck,
        publicWater,
        privateWater,
        publicSewerCheck,
        privateSepticCheck: !publicSewerCheck,
        numberPerson,
        numberRestroom,
        numberSink,
        refuseStored,
        refusePickUp,
        ventilation,
        fireExtinguisher,
        firstAid,
        equipment,
        meal,
      })
      .then(() => {
        setOpen(false);
        sharedAPI.setSnackbarMsg({ type: "success", msg: "Form Submitted" });
      })
      .catch(() =>
        sharedAPI.setSnackbarMsg({ type: "error", msg: "Error Submitting" })
      );

  return (
    <>
      <button onClick={() => setOpen(true)} style={{ marginLeft: 6 }}>
        Send Application
      </button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent style={{}}>
          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            DBA
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={dba}
            onChange={(e) => setDba(e.target.value)}
          />
          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Name
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={ownerName}
            onChange={(e) => setOwnerName(e.target.value)}
          />
          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Phone
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Employee Name
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={foodEmployeeName}
            onChange={(e) => setFoodEmployeeName(e.target.value)}
          />
          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Street address
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={ownerAddress}
            onChange={(e) => setOwnerAddress(e.target.value)}
          />
          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            City
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={ownerCity}
            onChange={(e) => setOwnerCity(e.target.value)}
          />
          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Zip
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={ownerZip}
            onChange={(e) => setOwnerZip(e.target.value)}
          />
          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Email
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Website
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
          />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              component="label"
              variant="caption"
              style={{ color: "purple" }}
            >
              Sunday Time
            </Typography>
            <input
              style={{ width: "45%" }}
              type="text"
              value={sundayTime}
              onChange={(e) => setSundayTime(e.target.value)}
            />
            <Typography
              component="label"
              variant="caption"
              style={{ color: "purple" }}
            >
              Sunday Number
            </Typography>
            <input
              style={{ width: "45%" }}
              type="text"
              value={sundayNumber}
              onChange={(e) => setSundayNumber(e.target.value)}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              component="label"
              variant="caption"
              style={{ color: "purple" }}
            >
              Monday Time
            </Typography>
            <input
              style={{ width: "45%" }}
              type="text"
              value={mondayTime}
              onChange={(e) => setMondayTime(e.target.value)}
            />
            <Typography
              component="label"
              variant="caption"
              style={{ color: "purple" }}
            >
              Monday Number
            </Typography>
            <input
              style={{ width: "45%" }}
              type="text"
              value={mondayNumber}
              onChange={(e) => setMondayNumber(e.target.value)}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              component="label"
              variant="caption"
              style={{ color: "purple" }}
            >
              Tuesday Time
            </Typography>
            <input
              style={{ width: "45%" }}
              type="text"
              value={tuesdayTime}
              onChange={(e) => setTuesdayTime(e.target.value)}
            />
            <Typography
              component="label"
              variant="caption"
              style={{ color: "purple" }}
            >
              Tuesday Number
            </Typography>
            <input
              style={{ width: "45%" }}
              type="text"
              value={tuesdayNumber}
              onChange={(e) => setTuesdayNumber(e.target.value)}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              component="label"
              variant="caption"
              style={{ color: "purple" }}
            >
              Wednesday Time
            </Typography>
            <input
              style={{ width: "45%" }}
              type="text"
              value={wednesdayTime}
              onChange={(e) => setWednesdayTime(e.target.value)}
            />
            <Typography
              component="label"
              variant="caption"
              style={{ color: "purple" }}
            >
              Wednesday Number
            </Typography>
            <input
              style={{ width: "45%" }}
              type="text"
              value={wednesdayNumber}
              onChange={(e) => setWednesdayNumber(e.target.value)}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              component="label"
              variant="caption"
              style={{ color: "purple" }}
            >
              Thursday Time
            </Typography>
            <input
              style={{ width: "45%" }}
              type="text"
              value={thursdayTime}
              onChange={(e) => setThursdayTime(e.target.value)}
            />
            <Typography
              component="label"
              variant="caption"
              style={{ color: "purple" }}
            >
              Thursday Number
            </Typography>
            <input
              style={{ width: "45%" }}
              type="text"
              value={thursdayNumber}
              onChange={(e) => setThursdayNumber(e.target.value)}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              component="label"
              variant="caption"
              style={{ color: "purple" }}
            >
              Friday Time
            </Typography>
            <input
              style={{ width: "45%" }}
              type="text"
              value={fridayTime}
              onChange={(e) => setFridayTime(e.target.value)}
            />
            <Typography
              component="label"
              variant="caption"
              style={{ color: "purple" }}
            >
              Friday Number
            </Typography>
            <input
              style={{ width: "45%" }}
              type="text"
              value={fridayNumber}
              onChange={(e) => setFridayNumber(e.target.value)}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              component="label"
              variant="caption"
              style={{ color: "purple" }}
            >
              Saturday Time
            </Typography>
            <input
              style={{ width: "45%" }}
              type="text"
              value={saturdayTime}
              onChange={(e) => setSaturdayTime(e.target.value)}
            />
            <Typography
              component="label"
              variant="caption"
              style={{ color: "purple" }}
            >
              Saturday Number
            </Typography>
            <input
              style={{ width: "45%" }}
              type="text"
              value={saturdayNumber}
              onChange={(e) => setSaturdayNumber(e.target.value)}
            />
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              component="label"
              variant="caption"
              htmlFor="utensilWashingSink"
            >
              Utensil Sink
            </Typography>
            <Checkbox
              id="utensilWashingSink"
              checked={utensilWashingSink}
              onChange={() => setUtensilWashingSink(!utensilWashingSink)}
              value="checkedA"
              inputProps={{
                "aria-label": "primary checkbox",
              }}
            />
            <Typography
              component="label"
              variant="caption"
              htmlFor="dishwasher"
            >
              Dishwasher
            </Typography>
            <Checkbox
              id="dishwasher"
              checked={dishwasher}
              onChange={() => setDishwasher(!dishwasher)}
              value="checkedA"
              inputProps={{
                "aria-label": "primary checkbox",
              }}
            />
            <Typography
              component="label"
              variant="caption"
              htmlFor="cleanInPlace"
            >
              Clean in place
            </Typography>
            <Checkbox
              id="cleanInPlace"
              checked={cleanInPlace}
              onChange={() => setCleanInPlace(!cleanInPlace)}
              value="checkedA"
              inputProps={{
                "aria-label": "primary checkbox",
              }}
            />
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography component="label" variant="caption" htmlFor="chlorine">
              chlorine
            </Typography>
            <Checkbox
              id="chlorine"
              checked={chlorine}
              onChange={() => setChlorine(!chlorine)}
              value="checkedA"
              inputProps={{
                "aria-label": "primary checkbox",
              }}
            />
            <Typography
              component="label"
              variant="caption"
              htmlFor="quaternaryAmmonium"
            >
              quaternaryAmmonium
            </Typography>
            <Checkbox
              id="quaternaryAmmonium"
              checked={quaternaryAmmonium}
              onChange={() => setQuaternaryAmmonium(!quaternaryAmmonium)}
              value="checkedA"
              inputProps={{
                "aria-label": "primary checkbox",
              }}
            />
            <Typography component="label" variant="caption" htmlFor="iodine">
              iodine
            </Typography>
            <Checkbox
              id="iodine"
              checked={iodine}
              onChange={() => setIodine(!iodine)}
              value="checkedA"
              inputProps={{
                "aria-label": "primary checkbox",
              }}
            />
          </div>

          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Cleaning Sanitizing Method
          </Typography>
          <textarea
            rows={8}
            style={{ width: "100%" }}
            value={cleaningProcess}
            onChange={(e) => setCleaningProcess(e.target.value)}
          />

          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Location Served
          </Typography>
          <textarea
            rows={2}
            style={{ width: "100%" }}
            value={locationServed}
            onChange={(e) => setLocationServed(e.target.value)}
          />
          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Location Stored
          </Typography>
          <textarea
            rows={2}
            style={{ width: "100%" }}
            value={locationStored}
            onChange={(e) => setLocationStored(e.target.value)}
          />
          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Remaining Food
          </Typography>
          <textarea
            rows={2}
            style={{ width: "100%" }}
            value={remainingFood}
            onChange={(e) => setRemainingFood(e.target.value)}
          />

          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Pick Up?
          </Typography>
          <select
            value={pickUp ? "1" : "0"}
            onChange={(e) => setPickUp(e.target.value === "1")}
            style={{ width: "100%" }}
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>

          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Delivery?
          </Typography>
          <select
            value={delivery ? "1" : "0"}
            onChange={(e) => setDelivery(e.target.value === "1")}
            style={{ width: "100%" }}
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>

          {delivery && (
            <>
              <Typography
                component="label"
                variant="caption"
                style={{ color: "purple" }}
              >
                Deliver Person
              </Typography>
              <input
                style={{ width: "100%" }}
                type="text"
                value={deliverPerson}
                onChange={(e) => setDeliverPerson(e.target.value)}
              />
              <Typography
                component="label"
                variant="caption"
                style={{ color: "purple" }}
              >
                Transportation
              </Typography>
              <input
                style={{ width: "100%" }}
                type="text"
                value={transportation}
                onChange={(e) => setTransportation(e.target.value)}
              />
              <Typography
                component="label"
                variant="caption"
                style={{ color: "purple" }}
              >
                Max Distance
              </Typography>
              <input
                style={{ width: "100%" }}
                type="text"
                value={maximumGeo}
                onChange={(e) => setMaximumGeo(e.target.value)}
              />
              <Typography
                component="label"
                variant="caption"
                style={{ color: "purple" }}
              >
                Max Time
              </Typography>
              <input
                style={{ width: "100%" }}
                type="text"
                value={maximumTime}
                onChange={(e) => setMaximumTime(e.target.value)}
              />
              <Typography
                component="label"
                variant="caption"
                style={{ color: "purple" }}
              >
                How to hold temp?
              </Typography>
              <textarea
                rows={4}
                style={{ width: "100%" }}
                value={hold}
                onChange={(e) => setHold(e.target.value)}
              />
              <Typography
                component="label"
                variant="caption"
                style={{ color: "purple" }}
              >
                Package Info
              </Typography>
              <textarea
                rows={2}
                style={{ width: "100%" }}
                value={_package}
                onChange={(e) => setPackage(e.target.value)}
              />
            </>
          )}
          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Public Water
          </Typography>
          <select
            value={publicWaterCheck ? "1" : "0"}
            onChange={(e) => setPublicWaterCheck(e.target.value === "1")}
            style={{ width: "100%" }}
          >
            <option value="1">Public</option>
            <option value="0">Private</option>
          </select>
          {publicWaterCheck ? (
            <>
              <Typography
                component="label"
                variant="caption"
                style={{ color: "purple" }}
              >
                Pulic Water Supply
              </Typography>
              <input
                style={{ width: "100%" }}
                type="text"
                value={publicWater}
                onChange={(e) => setPublicWater(e.target.value)}
              />
            </>
          ) : (
            <>
              <Typography
                component="label"
                variant="caption"
                style={{ color: "purple" }}
              >
                Private Water Supply
              </Typography>
              <input
                style={{ width: "100%" }}
                type="text"
                value={privateWater}
                onChange={(e) => setPrivateWater(e.target.value)}
              />
            </>
          )}

          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Public Sewer
          </Typography>
          <select
            value={publicSewerCheck ? "1" : "0"}
            onChange={(e) => setPublicSewerCheck(e.target.value === "1")}
            style={{ width: "100%" }}
          >
            <option value="1">Public</option>
            <option value="0">Septic</option>
          </select>
          {!publicSewerCheck && (
            <>
              <Typography
                component="label"
                variant="caption"
                style={{ color: "purple" }}
              >
                Number of person
              </Typography>
              <input
                style={{ width: "100%" }}
                type="text"
                value={numberPerson}
                onChange={(e) => setNumPerson(e.target.value)}
              />
              <Typography
                component="label"
                variant="caption"
                style={{ color: "purple" }}
              >
                Number of Restroom
              </Typography>
              <input
                style={{ width: "100%" }}
                type="text"
                value={numberRestroom}
                onChange={(e) => setNumRestroom(e.target.value)}
              />
              <Typography
                component="label"
                variant="caption"
                style={{ color: "purple" }}
              >
                Number of Sinks
              </Typography>
              <input
                style={{ width: "100%" }}
                type="text"
                value={numberSink}
                onChange={(e) => setNumSink(e.target.value)}
              />
            </>
          )}

          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Refuse Stored?
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={refuseStored}
            onChange={(e) => setRefuseStored(e.target.value)}
          />

          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Refuse Pick up?
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={refusePickUp}
            onChange={(e) => setRefusePickUp(e.target.value)}
          />

          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Ventilation
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={ventilation}
            onChange={(e) => setVentilation(e.target.value)}
          />

          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Fire Extinguisher
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={fireExtinguisher}
            onChange={(e) => setFireExtinguisher(e.target.value)}
          />

          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            First Aid
          </Typography>
          <input
            style={{ width: "100%" }}
            type="text"
            value={firstAid}
            onChange={(e) => setFirstAid(e.target.value)}
          />
          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            Equipments used
          </Typography>
          <textarea
            rows={4}
            style={{ width: "100%" }}
            value={equipment}
            onChange={(e) => setEquipment(e.target.value)}
          />

          <Typography
            component="label"
            variant="caption"
            style={{ color: "purple" }}
          >
            What defines a meal?
          </Typography>
          <textarea
            rows={4}
            style={{ width: "100%" }}
            value={meal}
            onChange={(e) => setMeal(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            color="secondary"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant={"contained"}
            color="secondary"
            style={{ color: COLORS.WHITE }}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
