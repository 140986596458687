import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    boxShadow: "0 0 6px 0 rgba(0,0,0,0.15)",
    backgroundColor: COLORS.WHITE,
    minHeight: 56,
  },
  innerBar: {
    padding: 0,
    minHeight: 56,
  },
  headerText: {
    padding: 0,
    margin: 0,
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    color: COLORS.BLACK,
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    overflow: "hidden",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  headerContainer: {},

  backArrow: { color: COLORS.MEDIUM_GREEN, fontSize: "2.5rem" },
  cancelEditBtn: {
    position: "absolute",
    right: "0",
    top: "50%",
    transform: "translate(0, -50%)",
    fontFamily: "CustomFourBold",

    color: COLORS.WHITE,
    marginRight: 4,
  },
});
