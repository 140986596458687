import { useMutation, useQuery } from "@apollo/client";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";
import { parse } from "query-string";
import React, { Suspense, useContext, useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { client } from "src/apollo";
import {
  AppUpdate,
  AuthDialog,
  AuthDrawer,
  AuthType,
  BottomNavigation,
  ContactDialog,
  COVID19Dialog,
  DinerIsHere,
  ErrorLoadingComponent,
  GlobalLoader,
  GuardedRoute,
  OneSignal,
  ReferreeDialog,
  ReferrerDialog,
  ReferrerSuccessDialog,
  ReviewPrompt,
  ShareDrawer,
  UserUpdateEmailDialog,
  VerifyBanner,
  WelcomeDialog,
} from "src/components";
import { BTDropIn2 } from "src/components/braintree/braintree2";
import { EventDialog } from "src/components/event-dialog/container";
import { FooterContainer } from "src/components/footer/container";
import { NonAuthNavigationDrawer } from "src/components/header/navigation-drawer";
import { RestaurantDialog } from "src/components/restaurant-dialog/container";
import { Chat2Container } from "src/screens/chat2";
import { HowItWorksContainer } from "src/screens/how-it-works";
import { MissionContainer } from "src/screens/mission-page";
import { amplitude } from "src/services";
import { sharedAPI } from "src/shared-graphql";
import { GET_ME } from "src/shared-graphql/queries";
import { BREAK_POINTS } from "src/styles";
import { LocalStorageHelper } from "src/utils/helpers";
import { useAppDetection, useCookModeAsDefault } from "src/utils/hooks";
import { bugSnag, _hsq } from "..";
import { HeaderContainer } from "../components/header/container";
import { AdminRoutes } from "./admin/routes";
import { USER_UPDATE_ADDRESS } from "./api";
import { BecomeCookContainer } from "./become-cook";
import { BecomeCookInfo } from "./become-cook-info/container";
import { ContactContainer } from "./contact/container";
import { CookRoutes } from "./cook/routes";
import { UserProfileRoutes } from "./diner/routes";
import { EventDetailRoutes } from "./event-detail/routes";
import { EventsListContainer } from "./events/list/container";
import { FAQContainer } from "./faq/container";
import { Home } from "./home-new";
import { LegislationContainer } from "./legislation";
import { LoginContainer } from "./login/container";
import { NoMatchComponent } from "./no-match";
import { NotificationContainer } from "./notification";
import { ResetTokenContainer } from "./password-reset/get-token/container";
import { ResetPasswordContainer } from "./password-reset/reset-password/container";
import { PressContainer } from "./press";
import { PrivacyPolicy } from "./privacy-policy";
import { ReferContainer } from "./refer/container";
import { RestaurantProfileContainer } from "./restaurant";
import { RestaurantListContainer } from "./restaurant-list/container";
import { ReviewContainer } from "./review/container";
import { useStyles } from "./routes.styles";
import { SettingsContainer } from "./settings";
import { SignUpContainer } from "./sign-up/container";
import { Subscribe } from "./subscribe";
import { TermsOfService } from "./terms-of-service";
import { ThankYou } from "./thank-you";
import { VerifyFailedContainer } from "./verification-failed/component";
import { VerifySuccessContainer } from "./verification-success/layout";
import { CreateRestaurantContainer } from "src/screens/become-cook/form/container";
import { ApplyForCookConnect } from "./apply/apply";

interface IProps {
  location: any;
}

export const Routes: React.FC<IProps> = ({ location }) => {
  const history = useHistory();

  const { token } = parse(location.search) as {
    token?: string;
  };
  const [updateUser] = useMutation(USER_UPDATE_ADDRESS, {
    errorPolicy: "ignore",
  });

  const { data, loading, error } = useQuery(GET_ME, {
    variables: { input: token },
    ...(token && { fetchPolicy: "network-only" }),
    onCompleted: () => {
      if (
        data?.getMe &&
        data?.getMe?.email?.toLowerCase() ===
          `${data?.getMe?.firstName}.${data?.getMe?.lastName}@foodnome.com`.toLowerCase()
      ) {
        sharedAPI.setUserUpdateEmailDialog(true);
      }
      client.query({
        query: GET_ME,
        fetchPolicy: "network-only",
        errorPolicy: "ignore",
      });
    },
  });
  useEffect(() => {
    if (error?.message?.includes("Must be logged in")) history.push("/login");
  }, [error]);

  (window as any).cordova && useAppDetection(data?.getMe);

  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

  useEffect(() => {
    if (
      location?.pathname &&
      whiteList.some((regexp) => regexp.test(location?.pathname)) &&
      !data?.getMe
    ) {
      document.getElementById("fb-root")?.removeAttribute("display");
    } else {
      document.getElementById("fb-root")?.setAttribute("display", "none");
    }
  }, [location?.pathname, data?.getMe?.id]);

  useEffect(() => {
    if (data?.getMe && !data.getMe.address) {
      axios.get("https://www.cloudflare.com/cdn-cgi/trace").then((d) => {
        updateUser({
          variables: {
            input: d.data,
          },
        });
      });
    }
  }, [data?.getMe?.address]);
  const { dispatch } = useContext(OneSignal.Context);
  const classes = useStyles();

  useEffect(() => {
    if (data?.getMe) {
      bugSnag.setUser(data?.getMe.id, data?.getMe.email, data?.getMe.firstName);
      amplitude.getInstance().setUserId(data.getMe.email);
      _hsq?.push(["identify", { email: data.getMe.email }]);

      dispatch({
        type: OneSignal.ActionTypes.REGISTER_DEVICE,
        payload: data.getMe.email,
      });
    }
  }, [data?.getMe]);

  useEffect(() => {
    // The path that the user wants to go to
    const destinationPath = location.pathname;

    // If Foodnome was opened in a new window or tab and accessed directly
    // OR the page before this one was NOT a page on Foodnome, then replace with /menus then push.
    // This is to keep users on our platform
    if (
      document.referrer === window.location.href ||
      document.referrer.indexOf(window.location.host) === -1
    ) {
      // Replaces the current pathname with the homepage
      history.replace("/menus");
      // Then pushes to the path the user wants to go to
      history.push({ pathname: destinationPath, search: location?.search });
    }
    const source = parse(location.search)?.s;
    if (source) {
      LocalStorageHelper.setItem("foodnome-source", source as any);
    }
  }, []);

  useEffect(() => {
    // We want to remember scroll location when user goes back to menus page
    if (location.pathname !== "/menus") {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  useCookModeAsDefault();

  const blacklistPaddingBottom = ["/restaurants", "/map", "/chat"];

  const bottomPadding = () => {
    // Padding we want to have at the bottom but only for mobile

    return isMobile && !blacklistPaddingBottom.includes(location.pathname)
      ? "calc(5.6rem + env(safe-area-inset-bottom))"
      : 0;
  };

  if (loading) {
    return <ErrorLoadingComponent loading={loading} />;
  }
  return (
    // We want to say what pages to put padding at the bottom because
    // some pages (like the map) don't need padding
    <div
      style={{
        marginBottom: bottomPadding(),
      }}
    >
      <Route component={VerifyBanner} />
      <Route component={HeaderContainer} />
      {process.env.REACT_APP_ENV === "cordova" && <AppUpdate />}
      <ShareDrawer />
      <AuthDialog />
      <EventDialog />
      {data?.getMe && <ReviewPrompt />}
      <RestaurantDialog />
      {data?.getMe && <ReferrerDialog />}
      {data?.getMe && <ReferrerSuccessDialog getMe={data?.getMe} />}
      <ReferreeDialog getMe={data?.getMe} />
      <AuthDrawer />
      <NonAuthNavigationDrawer anchor={"right"} />
      <BottomNavigation />
      <GlobalLoader />
      <COVID19Dialog />
      <ContactDialog />
      <WelcomeDialog getMe={data?.getMe} />

      <UserUpdateEmailDialog />

      {/* 🛑 please don't change "main" to any other tag. This is for accessibility purposes */}
      <main className={classes.main}>
        <Suspense fallback={<div />}>
          <Switch>
            <Route
              path="/"
              exact
              component={
                process.env.REACT_APP_ENV === "cordova"
                  ? EventsListContainer
                  : Home
              }
            />
            <Route path="/notify-cook" component={DinerIsHere} />
            <Route
              path="/sell-your-food"
              component={() => <BecomeCookContainer data={data} />}
            />

            {/* <GuardedRoute
              path="/home-restaurant-application"
              data={data}
              component={BecomeCookApplication}
              auth={AuthType.withRestaurant}
            /> */}

            <GuardedRoute
              path="/home-restaurant-info"
              data={data}
              component={BecomeCookInfo}
              auth={AuthType.withRestaurant}
            />
            <Route
              path="/become-a-cook"
              component={() => <Redirect to="sell-your-food" />}
            />
            <Route
              path="/apply"
              render={() => <ApplyForCookConnect getMe={data?.getMe} />}
            />
            <Route path="/notifications" component={NotificationContainer} />
            <Route path="/chat" component={Chat2Container} />
            <Route
              exact
              path="/restaurants"
              component={RestaurantListContainer}
            />
            <Route exact path="/map" component={RestaurantListContainer} />
            {/* /events is deprecated so we redirect to /menus */}
            <Route
              exact
              path="/events"
              component={() => <Redirect to="/menus" />}
            />
            <Route exact path="/menus" component={EventsListContainer} />
            <Route path="/menus/:id" component={EventDetailRoutes} />
            <Route path="/events/:id" component={EventDetailRoutes} />
            <Route path="/press" component={PressContainer} />
            <Route path="/refer" component={ReferContainer} />
            <Route path="/review" component={ReviewContainer} />

            <Route
              path="/ab-626"
              component={() => <Redirect to="/legislation" />}
            />
            <Route
              path="/ab626"
              component={() => <Redirect to="/legislation" />}
            />
           {/* commented for cook connect transition */}
            {/* <Route path="/legislation" component={LegislationContainer} /> */}

            <Route path="/mission" component={MissionContainer} />
            <Route path="/about" component={MissionContainer} />
            <Route path="/how-it-works" component={HowItWorksContainer} />
            <Route path="/terms-and-conditions" component={TermsOfService} />
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/faq" component={FAQContainer} />
            <Route path="/contact" component={ContactContainer} />
            <Route path="/verify-failed" component={VerifyFailedContainer} />
            <Route path="/verify-success" component={VerifySuccessContainer} />
            <Route path="/thank-you" component={ThankYou} />
            <Route path="/order" component={BTDropIn2} />
            <Route path="/subscription" component={Subscribe} />

            <Route
              path="/settings"
              render={() => <SettingsContainer getMe={data?.getMe} />}
            />

            {/* User Accounts */}

            <GuardedRoute
              path="/login"
              data={data}
              component={LoginContainer}
              auth={AuthType.isNotAuth}
              redirectPath={data?.getMe?.restaurant ? "/c" : "/d"}
            />

            <GuardedRoute
              path="/sign-up"
              data={data}
              component={SignUpContainer}
              auth={AuthType.isNotAuth}
              redirectPath={data?.getMe?.restaurant ? "/c" : "/d"}
            />

            <Route path="/get-reset-token" component={ResetTokenContainer} />
            <Route
              path="/password-reset/:token"
              component={ResetPasswordContainer}
            />

            <GuardedRoute
              path="/d"
              data={data}
              component={UserProfileRoutes}
              auth={AuthType.isAuth}
            />
            <GuardedRoute
              path="/c"
              data={data}
              component={CookRoutes}
              auth={AuthType.withRestaurant}
            />

            <GuardedRoute
              path="/admin"
              data={data}
              component={AdminRoutes}
              auth={AuthType.withAdminRole}
            />
            <Route path="/404" component={NoMatchComponent} />
            <Route path="/:name" component={RestaurantProfileContainer} />
          </Switch>
        </Suspense>
      </main>

      {process.env.REACT_APP_ENV !== "cordova" && (
        <Route component={FooterContainer} />
      )}
    </div>
  );
};

export const whiteList = [
  /^\/verify-success$/,
  /^\/$/,
  /^\/how-it-works$/,
  /^\/legislation$/,
  /^\/sell-your-food$/,
  /^\/get-reset-token$/,
  /^\/home-restaurant-info/,
  /^\/find-homemade-food/,
  /^\/events$/,
  /\/checkout/,
];
