import axios from "axios";
import { client, SERVER_ADDRESS } from "src/apollo";
import { AdminUpdateUserPL, IPagePL, IUser } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { DELETE_USER, EDIT_USER, GET_ALL_USERS } from "./graphql";

export const memberListAPI = {
  getAllUsers: (pagePL: IPagePL) =>
    client.query<{ getAllUsers: IUser[] }>({
      query: GET_ALL_USERS,
      variables: {
        input: { pagePL },
      },
      fetchPolicy: "network-only",
    }),

  transferFunds: (id) => {
    const token = localStorage.getItem("foodnome_auth_token");

    return axios(`${SERVER_ADDRESS}/paypal/payout`, {
      method: "post",
      data: {
        id,
      },
      ...(!document.URL.startsWith("http") &&
        process.env.REACT_APP_ENV === "cordova" && {
          headers: { authorization: token ? token : "none" },
        }),
      withCredentials: true,
    });
  },

  deleteChat: (id: string) => {
    const token = localStorage.getItem("foodnome_auth_token");

    return axios(`${SERVER_ADDRESS}/stream/${id}`, {
      method: "delete",
      ...(!document.URL.startsWith("http") &&
        process.env.REACT_APP_ENV === "cordova" && {
          headers: { authorization: token ? token : "none" },
        }),
      withCredentials: true,
    });
  },

  addChat: (id: string) => {
    const token = localStorage.getItem("foodnome_auth_token");

    return axios(`${SERVER_ADDRESS}/stream/${id}`, {
      method: "post",
      ...(!document.URL.startsWith("http") &&
        process.env.REACT_APP_ENV === "cordova" && {
          headers: { authorization: token ? token : "none" },
        }),
      withCredentials: true,
    });
  },

  editUser: (d: AdminUpdateUserPL) => {
    return client
      .mutate({
        mutation: EDIT_USER,
        variables: {
          input: d,
        },
        refetchQueries: ["adminGetUsers"],
      })
      .then(() => sharedAPI.setSnackbarMsg({ type: "success", msg: "Done" }))
      .catch(() => sharedAPI.setSnackbarMsg({ type: "error", msg: "Error" }));
  },
  deleteUser: (id: number, offset: number) =>
    client
      .mutate({
        mutation: DELETE_USER,
        variables: {
          input: id,
        },
        refetchQueries: ["adminGetUsers"],
      })
      .then(() => sharedAPI.setSnackbarMsg({ type: "success", msg: "Done" }))
      .catch(() => sharedAPI.setSnackbarMsg({ type: "error", msg: "Error" })),
};
