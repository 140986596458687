let initialized = !!(window as any).fbq;
let debug = false;

/**
 * Warn
 * @param  {...any} args
 */
const warn = (...args) => {
  if (!debug) {
    return;
  }
  console.info(...["[react-facebook-pixel]"].concat(args));
};

/**
 * Log
 * @param  {...any} args
 */
const log = (...args) => {
  if (!debug) {
    return;
  }
  console.info(...["[react-facebook-pixel]"].concat(args));
};

const verifyInit = () => {
  if (!initialized) {
    warn(
      "Pixel not initialized before using call ReactPixel.init with required params"
    );
  }
  return initialized;
};

//
const defaultOptions = {
  autoConfig: true,
  debug: false,
};

//
export default {
  init(pixelId, advancedMatching = {}, options = defaultOptions) {
    // tslint:disable-next-line: only-arrow-functions
    (function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
      if (f.fbq) return;
      // tslint:disable-next-line: only-arrow-functions
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );
    /* eslint-enable */

    if (!pixelId) {
      warn("Please insert pixel id for initializing");
    } else {
      if (options.autoConfig === false) {
        (window as any).fbq("set", "autoConfig", false, pixelId);
      }

      (window as any).fbq("init", pixelId, advancedMatching);
      initialized = true;
      debug = options.debug;
    }
  },

  pageView() {
    if (!verifyInit()) {
      return;
    }

    (window as any).fbq("track", "PageView");

    if (debug) {
      log("called fbq('track', 'PageView');");
    }
  },

  track(title, data) {
    if (!verifyInit()) {
      return;
    }

    (window as any).fbq("track", title, data);

    if (debug) {
      log(`called fbq('track', '${title}');`);

      if (data) {
        log("with data", data);
      }
    }
  },

  trackSingle(pixel, title, data) {
    if (!verifyInit()) {
      return;
    }

    (window as any).fbq("trackSingle", pixel, title, data);
    if (debug) {
      log(`called fbq('trackSingle', '${pixel}', '${title}');`);

      if (data) {
        log("with data", data);
      }
    }
  },

  trackCustom(event, data) {
    if (!verifyInit()) {
      return;
    }

    (window as any).fbq("trackCustom", event, data);

    if (debug) {
      log(`called fbq('trackCustom', '${event}');`);

      if (data) {
        log("with data", data);
      }
    }
  },

  trackSingleCustom(pixel, event, data) {
    if (!verifyInit()) {
      return;
    }

    (window as any).fbq("trackSingle", pixel, event, data);

    if (debug) {
      log(`called fbq('trackSingleCustom', '${pixel}', '${event}');`);

      if (data) {
        log("with data", data);
      }
    }
  },

  fbq(...args) {
    if (!verifyInit()) {
      return;
    }

    (window as any).fbq(...args);

    if (debug) {
      log(`called fbq('${args.slice(0, 2).join("', '")}')`);

      if (args[2]) {
        log("with data", args[2]);
      }
    }
  },
};
