import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    position: "relative",
    width: "100%",
    display: "grid",
    gridTemplateColumns: "60% 40%",
    height: "calc(100vh - 56px)",
    minHeight: "calc(100vh - 56px)",
    overflow: "hidden",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "flex",
      flexDirection: "column",
      height: "unset",
      maxHeight: "unset",
    },
  },
  contentContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",
  },
  subContentContainer: {
    maxWidth: "75rem",
    display: "block",
    position: "relative",
    flexGrow: 1,

    padding: "8rem",

    height: "100%",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "1rem",
    },
  },
  imgWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  image: {
    flexShrink: 0,
    objectFit: "cover",
    minHeight: "100%",
    minWeight: "100%",
  },
  calendly: {
    backgroundColor: "#FFC361",
    padding: "3rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      height: "80rem",
      padding: "2rem",
    },
  },
  title: {
    fontFamily: "CustomFourBold",
    display: "flex",
    alignItems: "center",
  },
  subtitle: {
    color: COLORS.RED,
    fontFamily: "CustomFour",
  },
  redBar: {
    width: "25%",
    minWidth: "14rem",
    height: 5,
    backgroundColor: COLORS.RED,
    margin: "3rem 0",
    borderRadius: 4,
  },
  squig: {
    position: "absolute",
    overflow: "hidden",
    top: "-25%",
    right: "-55%",
    width: "74rem",
    height: "64rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      position: "absolute",
      top: "-75%",
      right: "-55%",
      width: "37rem",
      height: "34rem",
    },
  },
  bold: {
    fontFamily: "CustomFourBold",
  },
  link: {
    color: COLORS.MEDIUM_GREEN,
    textDecoration: "none",
  },
  questionsContainer: {
    marginTop: "4rem",
  },
  logo: {
    display: "none",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "block",
    },
  },
  backButton: {
    color: COLORS.MEDIUM_GREEN,
  },
  backButtonArrow: {
    color: COLORS.MEDIUM_GREEN,
  },

  actionAndInfoSection: {
    padding: "3rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});
