import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  dialogPaper: {},
  paperScrollBody: {},
  iconContainer: {
    position: "absolute",
    right: "10px",
    top: "10px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  iconButton: {
    minWidth: 28,
    minHeight: 28,
    padding: 0,
    margin: ".25em",
  },
  dialogRoot: { padding: 0 },
  cardContainer: {
    flexGrow: 1,
    borderRadius: 20,
    boxShadow: "3px 4px 19px 0 rgba(31,64,80,0.28)",
    display: "flex",
    flexDirection: "column",
  },
  cardTitleContainer: {
    textAlign: "center",
    backgroundColor: COLORS.MEDIUM_GREEN,
    color: COLORS.WHITE,
  },
  statusPickerContainer: {
    maxWidth: 191,
    margin: "2em 0 0 2em",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: "none",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      margin: "2em 0 0 0",
    },
  },
  [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
    cardTitleContainer: {
      display: "none",
    },
    cardContainer: {
      borderRadius: 0,
    },
  },
});
