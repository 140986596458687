import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const noItemsStyles = makeStyles({
  container: {
    width: "100%",
    padding: "5px 30px",
    backgroundColor: COLORS.WHITE,
  },
  title: {
    color: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",

    fontWeight: 500,

    margin: "10px 0",
  },
  subText: {
    color: COLORS.BLACK,

    fontWeight: 100,

    margin: 0,
  },
  buttonRoot: {
    width: 120,
    margin: "10px 0",
    borderRadius: "2px",
    backgroundColor: COLORS.MEDIUM_RED,
    color: COLORS.WHITE,
    boxShadow: "-1px 2px 4px 0 rgba(31,64,80,0.41)",
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_GREEN,
      color: COLORS.WHITE,
    },
  },
  buttonLabel: {
    fontWeight: 100,
  },
});
