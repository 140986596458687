import { useQuery } from "@apollo/client";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { Route, Switch, useParams } from "react-router-dom";
import { ErrorLoadingComponent } from "src/components";
import { NoMatchComponent } from "../no-match";
import { GET_EVENT } from "./api/graphql";
import { CheckoutContainer } from "./checkout/container";
import { EventDetailContainer } from "./detail/container";
import { EventDetailDialogView } from "./event-detail-dialog-view";
import { GatherAddressDialog } from "./gather-address-dialog/";
import { OrderContextProvider } from "./order-context";

interface IProps {
  eventId?: string;
}
export const EventDetailRoutes: FC<IProps> = React.memo(({ eventId }) => {
  const { id } = useParams<{ id: string }>();

  const { data, error, loading } = useQuery(GET_EVENT, {
    variables: { input: eventId ?? id?.split("-")[0] },
    fetchPolicy: "no-cache",
  });

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }

  return (
    // Using context to provide order info and dispatch action for
    // detail left, detail right and checkout components.
    // These three components need to share the order info
    <>
      <Helmet>
        <title>{data.getEvent.name} | Foodnome</title>
        <meta name="description" content={data.getEvent.description} />
      </Helmet>
      <OrderContextProvider getEvent={data.getEvent}>
        <GatherAddressDialog getEvent={data.getEvent} />
        {eventId ? (
          <EventDetailDialogView getEvent={data.getEvent} />
        ) : (
          <Switch>
            <Route
              exact
              path="/menus/:id"
              render={(_props) => (
                <EventDetailContainer getEvent={data.getEvent} />
              )}
            />
            <Route
              exact
              path="/events/:id"
              render={(_props) => (
                <EventDetailContainer getEvent={data.getEvent} />
              )}
            />
            <Route
              path="/menus/:id/checkout"
              render={(_props) => (
                <CheckoutContainer getEvent={data.getEvent} />
              )}
            />
            <Route
              path="/events/:id/checkout"
              render={(_props) => (
                <CheckoutContainer getEvent={data.getEvent} />
              )}
            />
            <Route component={NoMatchComponent} />
          </Switch>
        )}
      </OrderContextProvider>
    </>
  );
});
