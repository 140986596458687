import { IGetMe } from "src/models";

interface IGlobalNavLink {
  pathname: string;
  name: string;
  when?: (user: IGetMe) => boolean;
  icon?: any;
}

export const routes: IGlobalNavLink[] = [
  ...(process.env.REACT_APP_ENV === "cordova"
    ? []
    : [
        {
          pathname: "/",
          name: "Home",
        },
      ]),
  {
    pathname: "/menus",
    name: "Find a Meal",
  },
  {
    pathname: "/sell-your-food",
    name: "Sell your Food",
    when: (user: IGetMe) => !user || !user.restaurant,
  },
  {
    name: "How it works",
    pathname: "/how-it-works",
  },
  {
    pathname: "/mission",
    name: "Mission",
  },
  {
    pathname: "/map",
    name: "MEHKO Map",
  },
  // {
  //   pathname: "/legislation",
  //   name: "Legislation",
  // },
  {
    pathname: "/faq",
    name: "FAQ",
  },
  // {
  //   pathname: "/press",
  //   name: "Press",
  // },
  {
    pathname: "/contact",
    name: "Contact",
  },
  // Disabled for transition to Cook Alliance, Feb 24
  // {
  //   pathname: "/privacy",
  //   name: "Privacy Policy",
  // },
  // {
  //   pathname: "/terms-and-conditions",
  //   name: "Terms & Conditions",
  // },
];
