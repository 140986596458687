import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  paperWidthSm: {
    maxWidth: "unset",
    margin: 0,
    width: "100%",
    [`@media (min-width: ${BREAK_POINTS.desktop}em)`]: {
      width: "90%",
    },
  },
  iconBtn: {
    position: "absolute",
    top: 4,
    right: 4,
    zIndex: 2000,
    backgroundColor: COLORS.MEDIUM_GREEN,
  },
  mdClose: {
    color: COLORS.WHITE,
  },
});
