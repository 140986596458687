import { Button, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { HybridLink } from "src/components";
import { IPress, PressType } from "src/models";
import { COLORS } from "src/styles";

export interface FeaturedPressProps {
  featuredPress: IPress;
}

export const FeaturedPress: React.FC<FeaturedPressProps> = ({
  featuredPress,
}) => {
  const classes = useStyles();
  const dateTime = moment(featuredPress.date);

  return (
    <HybridLink href={featuredPress.url} className={classes.link}>
      <article className={classes.article}>
        <div className={classes.featuredTextContent}>
          <img
            className={classes.sourceLogo}
            src={featuredPress.sourceLogo}
            alt="press logo"
            style={{
              height: 41,
              maxWidth: 200,
              objectFit: "contain",
              alignSelf: "flex-start",
            }}
          />
          <Typography
            component="time"
            variant="subtitle1"
            className={classes.date}
            dateTime={dateTime.format("YYYY-MM-DD")}
          >
            {dateTime.format("MMMM Do, YYYY")}
          </Typography>
          <Typography variant="h2" component="h2" className={classes.title}>
            {featuredPress.title}
          </Typography>
          <Typography variant="body1" component="p" className={classes.summary}>
            {featuredPress.summary}
          </Typography>
        </div>
        <div
          style={{
            backgroundImage: `url(${featuredPress.imageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
          className={classes.imageRight}
        >
          <Button classes={{ contained: classes.button }} variant="contained">
            {featuredPress.type === PressType.ARTICLE
              ? "Read More "
              : "Watch Video "}
            <img
              style={{ marginLeft: ".5rem" }}
              alt=""
              src={require("src/assets/icons/green_arrow-03-06.svg")}
              width="30"
            />
          </Button>
          <div className={classes.sourceLogoMobileWrapper}>
            <img
              src={featuredPress.sourceLogo}
              alt=""
              style={{ height: 41, maxWidth: 200, objectFit: "contain" }}
            />
          </div>
        </div>
      </article>
    </HybridLink>
  );
};

const useStyles = makeStyles({
  link: {
    textDecoration: "none",
  },
  article: {
    backgroundColor: COLORS.WHITE,
    display: "flex",
    flexWrap: "wrap-reverse",
    margin: "1rem 0",
    position: "relative",
  },
  title: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    marginBottom: 0,
    color: COLORS.BLACK,
    textTransform: "capitalize",
    overflow: "hidden",
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
  },
  summary: {
    fontWeight: 100,
    color: "#626262",
  },
  featuredTextContent: {
    display: "flex",
    flexDirection: "column",
    padding: "5rem",
    maxWidth: 440,
    flexGrow: 1,
    position: "relative",
    backgroundColor: COLORS.FLASH_WHITE,
    [`@media (max-width: 771px)`]: {
      padding: "4rem 1.5rem 1.5rem",
    },
  },
  sourceLogo: {
    marginBottom: "3.5rem",
    [`@media (max-width: 771px)`]: {
      display: "none",
    },
  },
  date: {
    fontWeight: 100,
    color: "#626262",

    position: "relative",
    ["&::before"]: {
      content: `""`,
      position: "absolute",
      left: 0,
      top: -15,
      width: "10rem",
      backgroundColor: COLORS.RED,
      border: `solid 2.5px ${COLORS.RED}`,
    },
  },
  imageRight: {
    width: "100%",
    position: "relative",
    minHeight: 250,
    minWidth: 300,
    flex: 1,
  },
  button: {
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: COLORS.WHITE,
    color: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    padding: "1rem 2rem",
    boxShadow: "none",
    borderRadius: 0,
    [`@media (max-width: 739px)`]: {
      display: "none",
    },
    [`&:hover`]: {
      backgroundColor: COLORS.WHITE,
    },
  },
  sourceLogoMobileWrapper: {
    display: "none",
    [`@media (max-width: 739px)`]: {
      display: "block",
      position: "absolute",
      bottom: 0,
      backgroundColor: `rgba(255, 255, 255, 0.9)`,
      left: 0,
      right: 0,
      padding: "1rem 1.5rem",
    },
  },
});
