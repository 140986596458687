import CircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress";
import React from "react";

export const FullScreenLoading: React.FC<CircularProgressProps> = (props) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.75)",
      }}
    >
      <CircularProgress {...props} />
    </div>
  );
};
