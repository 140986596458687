import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Fade } from "react-awesome-reveal";
import { useStyles } from "./getting-started.styles";
import { Steps } from "./steps";

export const GettingStarted: FC = () => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.headerContainer}>
          <Fade triggerOnce={true}>
            <Typography variant="h2" component="h2" className={classes.title}>
              Getting Started
            </Typography>
          </Fade>
          <Fade triggerOnce={true} delay={250}>
            <div className={classes.squiggleContainer}>
              <img
                src={require("src/screens/home-new/assets/underline.svg")}
                className={classes.squiggle}
              />
            </div>
          </Fade>
        </div>
        <div className={classes.mainContent}>
          <Fade
            className={classes.imageContainer}
            triggerOnce={true}
            delay={450}
          >
            <img
              src={require("src/screens/become-cook/assets/keion.jpg")}
              className={classes.image}
            ></img>
          </Fade>
          <Fade triggerOnce={true} delay={650}>
            <Steps />
          </Fade>
        </div>
      </div>
    </section>
  );
};
