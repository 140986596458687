import React, { FC, useContext, useEffect } from "react";
import { IEvent } from "src/models";
import { amplitude } from "src/services";
import { CheckoutContainer } from "./checkout/container";
import { EventDetailContainer } from "./detail/container";
import { OrderContext } from "./order-context";
import { ActionTypes } from "./order-reducer";

interface IProps {
  getEvent: IEvent;
}
export const EventDetailDialogView: FC<IProps> = ({ getEvent }) => {
  const {
    state: { dialogView },
    dispatch,
  } = useContext(OrderContext);

  useEffect(() => {
    amplitude.getInstance().logEvent("[Event Detail Dialog] Landing");
    dispatch({ type: ActionTypes.SET_EVENT_DIALOG_VIEW, payload: "detail" });
  }, []);

  if (dialogView === "detail") {
    return <EventDetailContainer getEvent={getEvent} />;
  } else if (dialogView === "checkout" || dialogView === "checkout-mobile") {
    return <CheckoutContainer getEvent={getEvent} />;
  } else {
    return null;
  }
};
