import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { BREAK_POINTS } from "src/styles";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 378,
    backgroundColor: "#F4F4F4",
    margin: "2rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      margin: "1rem",
    },
  },
  title: {
    order: 1,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: "#BEBEBE",
  },
  img: {
    maxWidth: 200,
  },
});

export const NoUpcomingMeals: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h2" component="h2" className={classes.title}>
        No Upcoming Meals
      </Typography>
      <img
        className={classes.img}
        src={require("./assets/no-events.svg")}
        alt="No Upcoming Meals."
      />
    </div>
  );
};
