import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import { AutoCompleteContainer, ChipsInput } from "src/components/";
import { IRestaurant, UpdateRestaurantPL } from "src/models";
import { COLORS } from "src/styles";
import { useFormStyles } from "./form.styles";
import { initialState, reducer } from "./state";
import { Actions, IAction } from "./state/actions";
import { IFormState } from "./state/reducers";
import { BannerUploader } from "./upload-banner";

interface IProps {
  restaurant: IRestaurant;
  updateRestaurant: (d: UpdateRestaurantPL) => Promise<any>;
  uploadImg: (f: File) => Promise<any>;
  init?: IFormState;
}

export const Form: React.FC<IProps> = ({
  restaurant,
  updateRestaurant,
  uploadImg,
  init = initialState,
}) => {
  const classes = useFormStyles();
  // Hooks
  const [state, _dispatch] = React.useReducer(reducer, init);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);

  // Helpers
  const dispatch = (action: IAction) => _dispatch(action);
  const handleUpdateRestaurant = (pl: any) => {
    return updateRestaurant(pl)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const { name, description, address, permitNumber } = e.target.elements;

    setLoading(true);

    return handleUpdateRestaurant({
      name: name.value,
      description: description.value,
      images: [JSON.stringify(state.image)],
      tags: state.tags,
      address: address.value,
      permitNumber: permitNumber ? permitNumber.value : "",
    });
  };

  const handleTextChange = (event: {
    target: { name: string; value: string };
  }) =>
    dispatch({
      type: Actions.SET_FIELD,
      key: event.target.name,
      value: event.target.value,
    });

  useEffect(() => {
    setIsBlocked(!isEqual(JSON.stringify(init), JSON.stringify(state)));
  }, [init, state]);

  const descriptionLimit = 1250;

  return (
    <>
      <Prompt
        message={"Are you sure you want to leave without saving your changes?"}
        when={isBlocked}
      />

      <form
        data-testid="account-profile-form"
        onSubmit={handleSubmit}
        className={classes.formContainer}
      >
        <div className={classes.innerContainer}>
          <div className={classes.formRight}>
            <div>
              <Typography
                variant="body2"
                component="p"
                className={classes.profileImageLabel}
              >
                Restaurant Image
              </Typography>
              <BannerUploader
                image={state.image ? (state.image.small as string) : ""}
                setImage={(i) =>
                  dispatch({ type: Actions.SET_BANNER_IMAGE, image: i })
                }
                uploadImage={uploadImg}
              />
            </div>
            <div>
              <div className={classes.nameFieldContainer}>
                <div
                  style={{ marginTop: "0.6rem" }}
                  className={classes.textAreaSubContainer}
                >
                  <Typography
                    component="label"
                    variant="caption"
                    className={classes.label}
                    htmlFor="restaurant-account-name"
                  >
                    Name
                  </Typography>
                  <TextField
                    size="small"
                    variant="outlined"
                    required
                    name="name"
                    id="restaurant-account-name"
                    placeholder="Name of your restaurant"
                    fullWidth
                    InputProps={{
                      classes: {
                        input: classes.textField,
                      },
                    }}
                    value={state.name}
                    onChange={handleTextChange}
                    inputProps={{ maxLength: 400 }}
                  />
                </div>
              </div>
            </div>
            <div className={classes.nameFieldContainer}>
              <div className={classes.textAreaSubContainer}>
                <Typography
                  component="label"
                  variant="caption"
                  aria-hidden="true"
                  className={classes.label}
                  htmlFor="account-address"
                >
                  Address
                </Typography>
                <AutoCompleteContainer
                  onSelect={(a) =>
                    dispatch({ type: Actions.SET_ADDRESS, address: a })
                  }
                  initialState={state.address}
                  TextFieldProps={{
                    fullWidth: true,
                    InputProps: {
                      id: "account-address",
                      classes: {
                        input: classes.textField,
                      },
                      name: "address",
                      required: true,
                    },
                  }}
                />
              </div>
            </div>
            <div className={classes.nameFieldContainer}>
              <div className={classes.textAreaSubContainer}>
                <Typography
                  component="label"
                  variant="caption"
                  className={classes.label}
                  htmlFor="restaurant-account-description"
                >
                  Description
                </Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  name="description"
                  id="restaurant-account-description"
                  placeholder="Tell us a little bit about your restaurant..."
                  multiline
                  fullWidth
                  rows="4"
                  InputProps={{
                    classes: {
                      input: classes.textField,
                      multiline: classes.multiline,
                      marginDense: classes.marginDense,
                    },
                  }}
                  value={state.description}
                  onChange={handleTextChange}
                  inputProps={{ maxLength: descriptionLimit }}
                />
                <Typography variant="caption" component="p">
                  {state.description.length} / {descriptionLimit}
                </Typography>
              </div>
              <div>
                <div className={classes.nameFieldContainer}>
                  <div
                    style={{ marginTop: "0.6rem" }}
                    className={classes.textAreaSubContainer}
                  >
                    <ChipsInput
                      initialState={state.tags}
                      onAddTag={(t) =>
                        handleTextChange({
                          target: { name: "tags", value: t as any },
                        })
                      }
                      label={"Tags"}
                    />
                  </div>
                </div>
              </div>

              <div
                className={[
                  classes.nameFieldContainer,
                  classes.marginTop20,
                ].join(" ")}
              >
                <div className={classes.textAreaSubContainer}>
                  <Typography
                    component="label"
                    variant="caption"
                    className={classes.label}
                    htmlFor="restaurant-account-permit-number"
                  >
                    MEHKO permit number
                  </Typography>
                  <TextField
                    size="small"
                    variant="outlined"
                    name="permitNumber"
                    id="restaurant-account-permit-number"
                    placeholder="Enter your permit number"
                    fullWidth
                    InputProps={{
                      classes: {
                        input: classes.textField,
                      },
                    }}
                    value={state.permitNumber}
                    onChange={handleTextChange}
                    inputProps={{ maxLength: 600 }}
                  />
                </div>
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                disabled={isLoading || !isBlocked}
                type="submit"
                variant="contained"
                classes={{
                  root: classes.actionButton,
                  disabled: classes.disabled,
                }}
              >
                {isLoading ? (
                  <CircularProgress style={{ color: COLORS.WHITE }} size={16} />
                ) : (
                  "Save Changes"
                )}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
