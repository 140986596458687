import { gql } from "@apollo/client";

export const GET_COUPONS = gql`
  query getCoupons($input: GetCouponsPL) {
    getCoupons(getCouponsPL: $input) {
      count
      rows {
        id
        code
        type
        start
        newUser
        max
        restaurantId
        end
        count
        percent
        eventId
        updatedAt
        createdAt
        restaurant {
          id
          name
        }
      }
    }
  }
`;
