import React, { FunctionComponent } from "react";
import { ViewPort } from "./view-port"; // you can use viewport instead for swapable

interface IProps {
  images: string[];
  step: number;
  setStep: (d: number) => void;
}

export const MissionPageCarouselContainer: FunctionComponent<IProps> = ({
  images,
  step,
  setStep,
}) => {
  if (!images.length) {
    return null;
  }
  return (
    <div
      style={{
        width: "100%",
        position: "relative",
      }}
    >
      <ViewPort step={step} images={images} setStep={setStep} />

      <div
        style={{
          top: "140px",
          position: "absolute",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      />
    </div>
  );
};
