import React from "react";

interface TabsStateProviderProps {
  reducer: (state, action) => void;
  initialState: any;
}

export const TabsStateContext = React.createContext<{
  state: any;
  dispatch: any;
}>({} as any);

export const useTabsState = () => React.useContext(TabsStateContext);

export const TabsStateProvider: React.FC<TabsStateProviderProps> = ({
  reducer,
  initialState,
  children,
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <TabsStateContext.Provider value={{ state, dispatch }}>
      {children}
    </TabsStateContext.Provider>
  );
};
