import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    backgroundColor: COLORS.WHITE,
    position: "relative",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    margin: "9rem auto",
    marginBottom: "15rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      margin: "5rem auto",
      marginBottom: "6rem",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginBottom: "3rem",
    },
  },
  text: {
    fontFamily: "CustomFour",
  },
  squiggleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
  },
  squiggle: {
    width: "60%",
    minWidth: "12rem",
    maxWidth: "18rem",
  },
  title: {
    fontFamily: "CustomFourBold",
    textAlign: "center",
  },
  mainContent: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    alignItems: "center",
    justifyContent: "center",
    placeItems: "center",
    padding: "0 4rem",
    columnGap: "7rem",
    rowGap: "7rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      gridTemplateColumns: "repeat(1, 1fr)",
      columnGap: "3rem",
      rowGap: "4rem",
      padding: "0 2rem",
    },
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
  },
  iPhoneImagesContainer: {
    position: "relative",
  },
  menuDetail: {
    minWidth: "15rem",
    maxWidth: "20rem",
    width: "44%",
  },
  menusApp: {
    position: "absolute",
    minWidth: "12rem",
    maxWidth: "16rem",
    width: "35%",
    bottom: "-10%",
    left: "50%",
  },
  appStoreButton: {
    marginBottom: "2rem",
    minWidth: 0,
    padding: 0,
  },
  appStoreLogo: {
    width: "16.5rem",
  },
  downloadImagesContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5rem",
  },
  yellowShape: {
    position: "absolute",
    bottom: 0,
    right: "-5%",
    width: "60%",
    maxWidth: "32rem",
    minWidth: "6rem",
  },
});
