import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { BREAK_POINTS } from "src/styles/";

interface IProps {
  children: any;
  className?: any;
}

const useStyles = makeStyles({
  container: {
    height: "100%",
    width: "100%",
    minHeight: "700px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: ".35rem",
    },
  },
});

export const DashboardWrapperContainer: FC<IProps> = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.container}>{children}</div>;
};
