import { gql } from "@apollo/client";

export const QUERY_STATUS_COUNTS = gql`
  query statusCounts {
    cookGetEventCounts {
      PENDING
      ACTIVE
      COMPLETED
      CANCELLED
    }
  }
`;
