import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { layoutStyles as useStyles } from "./styles";

interface IProps {
  title: string;
  status: "PAST" | "UPCOMING" | "SAVED";
}

export const ScrollHeader: FC<IProps> = ({ title, status }) => {
  const classes = useStyles();
  return (
    <div className={classes.listContainer}>
      <Typography variant="body2" component="p" className={classes.title}>
        {title}
      </Typography>
      <Link
        to={{
          pathname: `/d/menus/${status.toLowerCase()}`,
        }}
        style={{ textDecoration: "none", cursor: "pointer" }}
      >
        <Typography variant="body2" component="p" className={classes.seeAll}>
          See All
        </Typography>
      </Link>
    </div>
  );
};
