import { useQuery } from "@apollo/client";
import { Divider, makeStyles, Typography } from "@material-ui/core";
import { parse } from "query-string";
import React from "react";
import { useLocation } from "react-router-dom";
import { ErrorLoadingComponent } from "src/components";
import { BREAK_POINTS } from "src/styles";
import { SEARCH_EVENTS } from "./api/graphql";
import { EventList } from "./event-list";

interface IProps {
  isDisplayed: boolean;
}

const useStyles = makeStyles({
  container: {
    width: "100%",
    maxWidth: 1128,
    margin: "0 auto",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxWidth: 752,
    },
  },
  subtitle: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    textTransform: "capitalize",
  },
});

export const OtherMeals: React.FC<IProps> = ({ isDisplayed }) => {
  const classes = useStyles();
  const location = useLocation();
  const search = parse(location.search);
  const input = Object.assign(
    {},
    {
      pagePL: {
        limit: search && search.limit ? Number(search.limit) : 9,
      },
      location: "",
      tags: search.tags,
    },
    search.longitude && search.latitude
      ? {
          longitude: Number(search.longitude),
          latitude: Number(search.latitude),
        }
      : {}
  );
  const { data, error, loading } = useQuery(SEARCH_EVENTS, {
    variables: {
      input,
    },
    pollInterval: 30 * 60 * 1000,
  });

  // If there are search results then don't show this section
  if (!isDisplayed) return null;

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }

  return (
    <div className={classes.container}>
      <div style={{ padding: "0 1rem" }}>
        <Typography variant="h2" component="h2" className={classes.subtitle}>
          Other Homecooked Meals
        </Typography>
        <Divider />
      </div>
      <EventList searchEvents={data.searchEvents} loading={data.loading} />
    </div>
  );
};
