import { useQuery } from "@apollo/client";
import { CircularProgress, Typography } from "@material-ui/core";
import { parse, stringify } from "query-string";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppStatus, IAppInfo, IGetMe } from "src/models";
import { createApplication } from "src/screens/become-cook-info/api";
import { GET_COOK_APPLICATION } from "src/screens/become-cook-info/api/graphql/queries";
import {
  formatMutationPL,
  initialState,
} from "src/screens/become-cook-info/api/helpers";
import { amplitude } from "src/services";
import { sharedAPI } from "src/shared-graphql";
import { LinearProgressContainer } from "./components/linear-progress/linear-progress";
import { MobileCookFlowHeader } from "./components/mobile-cook-flow-header/mobile-cook-flow-header";
import { PasswordDialog } from "./components/password-dialog/password-dialog";
import { IFlowContent, InCounty } from "./content";
import { FinalDisplay } from "./final-display";
import { useStyles } from "./layout.styles";
import { BecomeCookContext } from "./store/become-cook-context";
import { ActionTypes } from "./store/reducer";

interface IProps {
  getMe: IGetMe;
}

export const Layout: React.FC<IProps> = ({ getMe }) => {
  const classes = useStyles();
  const { state, dispatch } = useContext(BecomeCookContext);
  const history = useHistory();
  const [display, setDisplay] = useState<IFlowContent[]>();
  const [stepIndex, setStepIndex] = useState<number>(0);
  const { data, error, loading } = useQuery<{
    cookGetApplication: { status: AppStatus; info: IAppInfo | null };
  }>(GET_COOK_APPLICATION, { fetchPolicy: "network-only" });

  const minCharLimit = 10;

  // ON_HOLD means that the user is out of county. PENDING is in county
  const appStatus = getMe.restaurant?.application.status as AppStatus;

  // Initialize the form state from query
  useEffect(() => {
    if (data && data.cookGetApplication && data.cookGetApplication.info) {
      dispatch({
        type: ActionTypes.SET_QUESTION,
        name: "question1",
        value: data.cookGetApplication.info.question1,
      });
      dispatch({
        type: ActionTypes.SET_QUESTION,
        name: "question2",
        value: data.cookGetApplication.info.question2,
      });
      dispatch({
        type: ActionTypes.SET_QUESTION,
        name: "question3",
        value: data.cookGetApplication.info.question3,
      });
    }
  }, [data]);

  useEffect(() => {
    if (appStatus === "ON_HOLD") {
      // setDisplay(outCounty({ getMe, classes }));
      setDisplay(InCounty({ getMe, classes }));
    } else {
      switch (
        getMe.restaurant?.county
          ?.toLocaleLowerCase()
          .replace("county", "")
          .trim()
      ) {
        case "riverside":
          return setDisplay(InCounty({ getMe, classes }));
        case "alameda":
          return setDisplay(InCounty({ getMe, classes }));
        case "solano":
          return setDisplay(InCounty({ getMe, classes }));
        case "santa barbara":
          return setDisplay(InCounty({ getMe, classes }));
        case "san mateo":
          return setDisplay(InCounty({ getMe, classes }));
        case "lake":
          return setDisplay(InCounty({ getMe, classes }));
        // Should not get to this point but just to be safe...
        default:
          return setDisplay(InCounty({ getMe, classes }));
      }
    }
  }, [appStatus]);

  useEffect(() => {
    // This could be simplified
    if (display) {
      // If current step is "final" set index to -1
      const currentStep = parse(history.location.search);
      if (currentStep.step === "final") {
        setStepIndex(-1);
      } else {
        const indexValue = display.findIndex(
          (i) => i.slug === currentStep.step
        );
        // If step in URL does not match ones in display, redirect with first indexed display
        if (indexValue === -1) {
          history.replace({
            search: stringify({ step: display[0].slug }),
          });
        } else {
          setStepIndex(indexValue);
        }
      }
    }
  }, [history.location.search, display]);

  useEffect(() => {
    if (display) {
      if (stepIndex !== -1 && display[stepIndex].slug === "question3") {
        dispatch({
          type: ActionTypes.SET_BUTTON_TEXT,
          payload: "SAVE & CONTINUE",
        });
      } else {
        dispatch({
          type: ActionTypes.SET_BUTTON_TEXT,
          payload: "Next",
        });
      }
    }
  }, [stepIndex]);

  const handleSubmit = () => {
    // Merge the initalState of the cook app with the 2 questions
    const form = {
      ...initialState,
      question1: state.question1,
      question2: state.question2,
      question3: state.question3,
    };

    createApplication(formatMutationPL(form))
      .then(() => {
        history.push({
          pathname: "/c/home",
          search: stringify({ status: appStatus }),
        });
      })
      .catch((e) => {
        return sharedAPI.setSnackbarMsg({
          type: "error",
          msg: `Error: ${e}`,
        });
      });
  };

  const charError = () => {
    return sharedAPI.setSnackbarMsg({
      type: "error",
      msg: `Please answer with at least ${minCharLimit} characters`,
    });
  };

  // HANDLERS
  const goForward = () => {
    if (display) {
      amplitude
        .getInstance()
        .logEvent(`[Become Cook] slide ${display[stepIndex].slug + 1}`);

      if (display[stepIndex].slug === "question1") {
        if (state.question1.length < minCharLimit) {
          return charError();
        }
      }
      if (display[stepIndex].slug === "question2") {
        if (state.question2.length < minCharLimit) {
          return charError();
        }
      }
      if (display[stepIndex].slug === "question3") {
        if (state.question3.length < minCharLimit) {
          return charError();
        } else {
          handleSubmit();
        }
      } else {
        const nextStepIndex =
          stepIndex + 1 < display.length ? stepIndex + 1 : null;
        history.push(
          nextStepIndex
            ? {
                search: stringify({
                  step: display[nextStepIndex].slug,
                }),
              }
            : "/c/home"
        );
      }
    }
  };

  const goBack = () => {
    history.goBack();
  };

  if (error) return <p>Error fetching data. Please try again.</p>;
  if (loading) return <CircularProgress />;

  if (display) {
    if (parse(history.location.search).step === "final" && stepIndex === -1)
      return <FinalDisplay getMe={getMe} />;

    if (stepIndex !== -1)
      return (
        <div className={classes.container}>
          <section className={classes.contentContainer}>
            <div className={classes.subContentContainer}>
              <MobileCookFlowHeader />
              <>
                <PasswordDialog getMe={getMe} />
                {display[stepIndex].left}
              </>
            </div>

            <LinearProgressContainer
              goForward={goForward}
              goBack={goBack}
              stepIndex={stepIndex}
              display={display}
            />
          </section>

          <section className={classes.imgWrapper}>
            {display[stepIndex].right}
          </section>
        </div>
      );
  }
  return (
    <Typography variant="body1" component="p">
      Error
    </Typography>
  );
};
