import React, { FunctionComponent } from "react";
import { CreateCouponPL } from "src/models";
import { couponAPI } from "../api";
import { CouponCreateComponent } from "./component";

interface IProps {
  offset?: number; // this will refech the current page's record if provided
}
export const CouponCreateContainer: FunctionComponent<IProps> = ({
  offset,
}) => {
  const createCoupon = (d: CreateCouponPL) => couponAPI.createCoupon(d, offset);

  return <CouponCreateComponent createCoupon={createCoupon} />;
};
