import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useDishItemStyles = makeStyles({
  dishContainer: {
    overflow: "visible",
    height: "15.5rem",
    width: "100%",
    display: "flex",
    cursor: "pointer",
    maxWidth: 684,
    position: "relative",
    borderRadius: 5,
    boxSizing: "border-box",
    boxShadow: `0 2px 9px 0 rgba(22,22,22,0.34)`,

    // We do not want the hover effect on small screens/mobile
    [`@media (min-width: ${BREAK_POINTS.tablet}em)`]: {
      transition: `box-shadow 200ms ease-in, transform 200ms ease-in`,
      "&:hover": {
        boxShadow: `0 6px 10px 0 rgba(22,22,22,0.8)`,
        transform: `translateY(-5px)`,
      },
    },
  },
  dishImageWrapper: {
    position: "relative",
    borderRadius: "0 5px 5px 0",
  },
  dishImage: {
    width: 140,
    height: "100%",
    objectFit: "cover",
    borderRadius: "0 5px 5px 0",
  },
  cardContent: {
    height: "100%",
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  name: {
    fontFamily: "CustomFourBold",

    fontWeight: 500,
    lineClamp: 1,
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    overflow: "hidden",
  },
  nameDisabled: {
    opacity: 0.52,
  },
  price: {
    fontFamily: "CustomFourBold",
  },
  description: {
    overflow: "hidden",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    margin: ".25rem 0",
  },
  allergenDietaryContainer: {
    fontWeight: 700,
  },
  label: {
    color: "grey",
    fontWeight: "normal",
    display: "inline-block",
    width: "70px",
  },
  allergen: { margin: " 2px 0" },
  dietary: { margin: " 2px 0" },
  readMore: { color: COLORS.MEDIUM_GREEN, cursor: "pointer" },

  iconContainerDisabled: {
    backgroundColor: COLORS.WHITE,
    position: "absolute",
    right: 6,
    bottom: 6,
    height: 30,
    fontFamily: "CustomFourBold",
    color: COLORS.LIGHT_GREY,
    width: 60,

    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconContainer: {
    backgroundColor: COLORS.WHITE,
    position: "absolute",
    right: 6,
    bottom: 6,
    height: 30,
    fontFamily: "CustomFourBold",

    width: 60,

    borderRadius: "4px",
    display: "flex",
    "&:hover": {
      backgroundColor: COLORS.WHITE,
    },
    "&:disabled": { display: "none" },
  },
  plusIcon: {
    color: COLORS.MEDIUM_GREEN,
  },
  plusIconDisabled: {
    color: COLORS.LIGHT_GREY,
  },
  dishesLeft: {
    margin: 0,
    padding: 0,
    fontFamily: "CustomFourBold",
    textAlign: "center",
  },
  dishesLeftWrapper: {
    zIndex: 1,
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    margin: 0,
    padding: 0,
    borderRadius: "50%",
    position: "absolute",
    top: -15,
    right: -15,
    backgroundColor: "rgba(0, 0, 0, 0.65)",
    height: 44,
    width: 44,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      boxShadow: `0 6px 10px 0 rgba(22,22,22,0.8)`,
      transform: `translateY(-5px)`,
    },

    [`@media (max-width: ${BREAK_POINTS.mobileRegular}em)`]: {
      top: -10,
      right: -6,
    },
  },
  soldOutWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.72)",
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0 5px 5px 0",
  },
  soldOutText: {
    width: 89,
    fontFamily: "CustomFourBold",

    textAlign: "center",
  },

  [`@media (max-width: ${BREAK_POINTS.mobileRegular}em)`]: {
    dishContainer: {
      maxWidth: 570,
    },
  },
  [`@media (max-width: ${BREAK_POINTS.mobileRegular}em)`]: {
    allergenDietaryContainer: {
      display: "none",
    },
    dishContainer: {
      height: 135,
    },
    dishImage: {
      width: 129,
    },
    cardContent: {
      padding: 10,
    },
  },
});
