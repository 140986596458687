import { IconButton, Typography } from "@material-ui/core";
import { Room } from "@material-ui/icons";
import HelpIcon from "@material-ui/icons/Help";
import React, { FC, useState } from "react";
import { GoogleMapContainer, LocationDialog } from "src/components";
import { IEvent } from "src/models";
import { locationStyles as useStyles } from "./location.styles";

interface IProps {
  getEvent: IEvent;
}

export const Location: FC<IProps> = ({ getEvent }) => {
  const classes = useStyles();
  const [showLocationDialog, setShowLocationDialog] = useState(false);

  const closeLocationDialog = () => {
    setShowLocationDialog(false);
  };

  return (
    <div className={classes.container}>
      <Typography variant="h4" component="h4" className={classes.locationTitle}>
        Location{" "}
      </Typography>
      <div className={classes.locationContainer}>
        <Typography variant="body1" component="address">
          <Room className={classes.eventInfoIcon} />
          {getEvent.locale.public}
        </Typography>
      </div>
      <div className={classes.locationTextContainer}>
        <Typography variant="body2" component="p" className={classes.subTitle}>
          Exact location will be provided upon order completion
        </Typography>
        <IconButton
          disableRipple
          disableFocusRipple
          size="small"
          onClick={() => setShowLocationDialog(true)}
        >
          <HelpIcon />
        </IconButton>
      </div>
      {getEvent.locale && (
        <section
          onClick={() => setShowLocationDialog(true)}
          id="google-map-container"
          className={classes.mapWrapper}
        >
          <GoogleMapContainer
            circleOverlay
            center={
              getEvent.locale.coordinates
                ? {
                    lat: getEvent.locale.coordinates.latitude,
                    lng: getEvent.locale.coordinates.longitude,
                  }
                : undefined
            }
          />
        </section>
      )}
      <LocationDialog
        showLocationDialog={showLocationDialog}
        closeLocationDialog={closeLocationDialog}
        restaurant={getEvent.restaurant}
      />
    </div>
  );
};
