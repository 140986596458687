import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useLayoutStyles = makeStyles({
  container: {
    display: "flex",
    position: "relative",
    flexGrow: 1,
    minHeight: "100vh",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      height: "100%",
      minHeight: "unset",
      flexDirection: "column",
    },
  },
  btnLabel: {
    color: COLORS.WHITE,
  },
});
