import { client } from "src/apollo";
import { upload } from "src/components/img-upload/api/upload";
import { IUpdateUserPL, IUpdateUserPreferences } from "src/models";
import { GET_DINER_FIELDS } from "src/screens/diner/account/api/graphql/queries";
import { GET_ME } from "src/shared-graphql/queries";
import { UPDATE_USER_INFO } from "./graphql";

const userAPI = {
  updateUser: (
    updateUserPL: IUpdateUserPL,
    updateUserPreferences?: IUpdateUserPreferences
  ) =>
    client.mutate({
      mutation: UPDATE_USER_INFO,
      variables: {
        updateUserInfo: updateUserPL,
        updateUserPreferences,
      },
      refetchQueries: [{ query: GET_DINER_FIELDS }, { query: GET_ME }],
    }),

  uploadImg: (f: File) => upload(f),
};

export { userAPI };
