import { IImage } from "src/models";
import { Actions, IAction } from "./actions";

export interface IEventFormState {
  address: string;
  displayLocation: string;
  eventType: string[];
  name: string;
  date: string;
  startTime: string;
  duration: string | number;
  reserveByDate: string;
  reserveByTime: string;
  description: string;
  instructions: string;
  images: IImage | null;
  price: string;
  minSeats: string;
  maxSeats: string;
  bufferTime: string;
  minimumOrderAmount: number;
  pricePerMile: number | null;
  pricePerMin: number | null;
  maximumDriveTime: number;
  isMile?: boolean;
}

export const initialState: IEventFormState = {
  address: "",
  displayLocation: "",
  eventType: ["TAKE_OUT"],
  name: "",
  date: "",
  startTime: "",
  duration: "",
  reserveByDate: "",
  reserveByTime: "",
  description: "",
  instructions: "",
  images: null,
  price: "",
  minSeats: "",
  maxSeats: "",
  bufferTime: (15 * 60 * 1000).toString(),
  minimumOrderAmount: 10,
  pricePerMin: 0.2,
  pricePerMile: 0.6,
  maximumDriveTime: 30,
  isMile: false,
};

export default function reducer(state: IEventFormState, action: IAction) {
  switch (action.type) {
    case Actions.SET_FIELD:
      return {
        ...state,
        [action.name]:
          action.name === "bufferTime"
            ? action.value * 1000 * 60
            : action.value,
      };
    case Actions.UPLOAD_IMAGE:
      return { ...state, images: action.image };
    case Actions.TOGGLE_IS_MILE:
      return {
        ...state,
        isMile: !state.isMile,
        pricePerMin: state.isMile ? 0.2 : null,
        pricePerMile: state.isMile ? null : 0.6,
      };
    default:
      throw new Error(`Invalid Action passed to EventForm reducer`);
  }
}
