import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  footerTop: {
    paddingTop: "8rem",
    paddingBottom: "2rem",
    flexGrow: 1,
    flexWrap: "wrap",
    display: "grid",
    gridTemplateColumns: "30% 70%",
    gridGap: "1rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  col1: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "6rem",
    },
  },
  col2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  motto: {
    fontFamily: "CustomFourBold",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  linkSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      alignItems: "center",
      justifyContent: "center",
      marginTop: "2rem",
    },
  },
  sectionTitle: {
    fontFamily: "CustomFourBold",
    color: COLORS.BLACK,
  },
  navigationLinks: {
    listStyleType: "none",
    display: "flex",
    paddingLeft: 0,
    flexDirection: "column",
    alignItems: "flex-start",

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      alignItems: "center",
      justifyContent: "center",
    },
  },
  link: {
    textDecoration: "none",
    color: COLORS.BLACK,

    margin: "1em 0",
  },
  navigationItem: {
    margin: "1rem 0",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      margin: "0.5rem 0",
    },
  },
  logo: {
    width: "24rem",
  },
  redBar: {
    width: "50%",
    height: 2,
    backgroundColor: COLORS.RED,
    margin: "3rem 0",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
});
