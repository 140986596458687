import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { isEqual } from "lodash";
import ColorPicker from "material-ui-color-picker";
import React, { SyntheticEvent } from "react";
import { useStyles } from "./banner.styles";

interface IProps {
  handleShow: (event: any) => void;
  handleWhiteListAdd: () => void;
  handleWhiteListDelete: (route: string) => void;
  handleEnvs: (event: SyntheticEvent) => void;
  handleChange: (event: any) => void;
  submit: (event: SyntheticEvent) => void;
  platforms: string[];
  newBannerState: any;
  setWhiteListValue: (value: string) => void;
  whiteListValue: string;
  bannerData: any;
}

export const BannerForm: React.FC<IProps> = React.memo(
  ({
    handleEnvs,
    handleShow,
    handleWhiteListAdd,
    handleWhiteListDelete,
    submit,
    handleChange,
    platforms,
    newBannerState,
    setWhiteListValue,
    whiteListValue,
    bannerData,
  }) => {
    const classes = useStyles();
    return (
      <form onSubmit={submit} className={classes.formContainer}>
        <InputLabel id="show-label">Show Banner</InputLabel>
        <Select
          native
          labelId="show-label"
          value={newBannerState.show === true ? "1" : "0"}
          name="show"
          onChange={handleShow}
        >
          <option value={1}>true</option>
          <option value={0}>false</option>
        </Select>

        <InputLabel id="show-new-users-label">
          Show only to new users
        </InputLabel>
        <Select
          native
          labelId="show-new-users-label"
          value={newBannerState.newUserOnly === true ? "1" : "0"}
          name="newUserOnly"
          onChange={handleShow}
        >
          <option value={1}>true</option>
          <option value={0}>false</option>
        </Select>
        <TextField
          label="Banner Text"
          defaultValue={newBannerState.text}
          onChange={handleChange}
          name="text"
        />
        <TextField
          label="External Link"
          defaultValue={newBannerState.externalLink}
          onChange={handleChange}
          name="externalLink"
        />
        <TextField
          label="Code"
          defaultValue={newBannerState.code}
          onChange={handleChange}
          name="code"
        />

        <ColorPicker
          label="Banner Color"
          defaultValue={newBannerState.bannerColor}
          name="bannerColor"
          helperText="Hex value or color"
          InputProps={{ value: newBannerState.bannerColor }}
          value={newBannerState.bannerColor}
          onChange={(color) =>
            handleChange({
              target: { name: "bannerColor", value: color },
            })
          }
        />

        <ColorPicker
          label="Text Color"
          defaultValue={newBannerState.textColor}
          name="textColor"
          helperText="Hex value or color"
          InputProps={{ value: newBannerState.textColor }}
          value={newBannerState.textColor}
          onChange={(color) =>
            handleChange({
              target: { name: "textColor", value: color },
            })
          }
        />
        <FormLabel component="legend">Show on:</FormLabel>
        <FormGroup>
          {platforms.map((platform) => (
            <FormControlLabel
              key={platform}
              control={
                <Checkbox
                  checked={newBannerState.envs.includes(platform)}
                  onChange={handleEnvs}
                  name={platform}
                />
              }
              label={platform}
            />
          ))}
        </FormGroup>
        <Typography component="p" variant="caption">
          Whitelist (pages to show on)
        </Typography>
        <div className={classes.whiteListContainer}>
          <TextField
            size="small"
            id="option"
            name="option"
            placeholder="/menus, /become-a-cook ..."
            onChange={(e) => setWhiteListValue(e.target.value)}
            value={whiteListValue}
          />
          <div>
            <Button
              variant="contained"
              color="primary"
              disabled={whiteListValue === ""}
              onClick={handleWhiteListAdd}
            >
              Add
            </Button>
          </div>
        </div>
        <ul>
          {newBannerState.whiteList.map((route) => (
            <Typography component="li" variant="caption" key={route}>
              {route}{" "}
              <IconButton
                aria-label="delete"
                onClick={() => handleWhiteListDelete(route)}
              >
                <Delete />
              </IconButton>
            </Typography>
          ))}
        </ul>
        <Button
          className={classes.saveBtn}
          color="secondary"
          variant="contained"
          type="submit"
          disabled={isEqual(newBannerState, bannerData)}
          value="submit"
        >
          Save
        </Button>
      </form>
    );
  }
);
