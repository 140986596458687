import { gql } from "@apollo/client";

export const GET_RESTAURANT_EVENTS = gql`
  query getRestaurantEvents($input: ID!) {
    getRestaurantEvents(id: $input) {
      id
      status
      name
      description
      type
      locale {
        public
      }
      imagesGQL {
        publicId
        small
        medium
        large
        original
      }
      dishes {
        id
        name
        imagesGQL {
          publicId
          small
          medium
          large
          original
        }
        category
        DishEvent {
          id
          price
          count
          sold
        }
        __typename @skip(if: true)
      }
      price
      startTime
      endTime
      tags
    }
  }
`;
