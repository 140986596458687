import { IconButton } from "@material-ui/core";
import React, { FC, useContext } from "react";
import { ListMapContext } from "../container";
import { HomeIcon } from "./assets";

interface IProps {
  lat: any;
  lng: any;
}
export const YourLocationMarker: FC<IProps> = () => {
  const {
    state: { loadingRestaurants },
  } = useContext(ListMapContext);

  if (loadingRestaurants) return null;
  return (
    <IconButton
      // This is needed to accurately position marker
      style={{ position: "absolute", transform: "translate(-50%, -50%)" }}
      size="small"
    >
      <HomeIcon style={{ fontSize: 24, zIndex: 500 }} />
    </IconButton>
  );
};
