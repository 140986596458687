import {
  AccordionDetails,
  ExpansionPanel,
  ExpansionPanelSummary,
} from "@material-ui/core";
import React, { FC } from "react";
import { IEvent } from "src/models";
import { useStyles } from "./event.styles";

interface IProps {
  event: IEvent;
}
export const Event: FC<IProps> = ({ event }) => {
  const classes = useStyles();
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary>
        {event.name}, # of guests: {event.eventUsers?.length}, earning: $
        {event.eventUsers
          ?.reduce((acu, next) => acu + next.paidAmount + next.creditAmount, 0)
          ?.toFixed(2)}
      </ExpansionPanelSummary>
      <AccordionDetails className={classes.panelDetail}>
        {event.eventUsers?.map((eu) => (
          <div key={eu.id}>
            {" "}
            Paid: ${eu.paidAmount?.toFixed(2)}, Credit: $
            {eu.creditAmount?.toFixed(2)}, Tax: ${eu.taxAmount?.toFixed(2)}
          </div>
        ))}
      </AccordionDetails>
    </ExpansionPanel>
  );
};
