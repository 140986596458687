import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import React, { FunctionComponent } from "react";

interface IProps {
  msg: string;
  onCancel: () => void;
  onSubmit: (d: any) => void;
  open: boolean;
  yesMsg: string;
  noMsg: string;
}

export const WarningComponent: FunctionComponent<IProps> = ({
  msg,
  onCancel,
  onSubmit,
  yesMsg,
  noMsg,
  open,
}) => {
  return (
    <Dialog data-e2e="warning-dialog" open={open}>
      <DialogTitle>{msg}</DialogTitle>
      <DialogActions>
        <Button onClick={onCancel} color="primary" data-e2e="confirmation-no">
          {noMsg}
        </Button>
        <Button onClick={onSubmit} color="primary" autoFocus>
          {yesMsg}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
