import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    padding: "1em",
    width: "100%",
    maxWidth: 1280,
    margin: "0 auto",
    paddingBottom: "calc(9rem + env(safe-area-inset-bottom))",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "2rem",
  },
  title: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    margin: 0,
  },
  backArrowFab: {
    color: COLORS.MEDIUM_RED,
    fontSize: "2.5rem",
  },
});
