import { gql } from "@apollo/client";

export const CREATE_RESTAURANT = gql`
  mutation createRestaurant($input: CreateRestaurantPL!) {
    createRestaurant(createRestaurantPL: $input) {
      id
      name
      description
      imagesGQL {
        publicId
        small
        medium
        large
        original
      }
      address
      street
      city
      state
      zipCode
      county
      updatedAt
      createdAt
    }
  }
`;

export const CREATE_RESTAURANT_AND_USER = gql`
  mutation createRestaurantAndUser($input: CreateRestaurantPL!) {
    createRestaurantAndUser(createRestaurantAndUserPL: $input) {
      id
      firstName
      lastName
      email
      description
      role
      nomes
      account
      picture
      birthday
      phoneNumber
      address
      authToken
    }
  }
`;
