import { FormControl, InputLabel, makeStyles, Select } from "@material-ui/core";
import React, { FC, useState } from "react";
import { AppStatus } from "src/models";

interface IProps {
  handleSelect: (status: AppStatus) => void;
  currentStatus: AppStatus;
  statuses?: AppStatus[];
  disabled: boolean;
  applicationId?: any;
}

export const SelectAppStatus: FC<IProps> = ({
  handleSelect,
  currentStatus,
  statuses,
  disabled,
  applicationId,
}) => {
  const classes = useStyles();
  const menuItems = statuses!.filter((s) => s);
  const [selected, setSelected] = useState<AppStatus | "">(currentStatus);
  const _selectHandler = (_selected: AppStatus) => {
    setSelected(_selected);
    handleSelect(_selected);
  };

  // useEffect(() => setSelected(currentStatus), [currentStatus]);

  return (
    <FormControl className={classes.container}>
      <InputLabel htmlFor={`app-status-${applicationId}`}>
        Application Status
      </InputLabel>
      <Select
        native
        disabled={disabled}
        // classes={{ selectMenu: classes.selectMenu }}
        displayEmpty
        onChange={(e) => _selectHandler(e.target.value as AppStatus)}
        value={selected}
        inputProps={{ id: `app-status-${applicationId}` }}
      >
        <option value="" disabled>
          Application Status
        </option>
        {menuItems.map((status) => (
          <option key={status} value={status}>
            {status}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

SelectAppStatus.defaultProps = {
  statuses: [
    AppStatus.PENDING,
    AppStatus.ACTION_REQUIRED,
    AppStatus.SCHEDULED,
    AppStatus.SUBMITTED,
    AppStatus.ON_HOLD,
    AppStatus.APPROVED,
    AppStatus.REJECTED,
  ],
};

const useStyles = makeStyles({
  container: {
    width: "100%",
  },
});
