import { useQuery } from "@apollo/client";
import { uniqBy } from "lodash";
import { parse, stringify } from "query-string";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import { ErrorLoadingComponent } from "src/components";
import { IPagePL } from "src/models";
import { amplitude } from "src/services";
import { GET_ME } from "src/shared-graphql/queries";
import { JsonHelper, LocalStorageHelper } from "src/utils/helpers";
import { useMobileHeader, useScrolledBottom } from "src/utils/hooks";
import { useDinerMode } from "src/utils/hooks/mode-hooks";
import { SEARCH_EVENTS } from "./api/graphql";
import { Layout } from "./layout";

export interface IFetchMorePL {
  pagePL?: IPagePL;
  location?: string;
}

export const EventsListContainer: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const search = parse(location.search);

  const [limit, setLimit] = useState<number>(9);
  const input = Object.assign(
    {},
    {
      pagePL: {
        limit: search && search.limit ? Number(search.limit) : limit,
        offset: 0,
      },
      location: search.location,
      tags: search.tags,
    },
    search.longitude && search.latitude
      ? {
          longitude: Number(search.longitude),
          latitude: Number(search.latitude),
        }
      : {}
  );

  const { data, error, loading, fetchMore } = useQuery(SEARCH_EVENTS, {
    variables: { input },
    pollInterval: 30 * 60 * 1000, // every half hour
  });
  const { data: queryUser } = useQuery(GET_ME, {
    fetchPolicy: "cache-first",
    errorPolicy: "ignore",
  });

  useDinerMode();

  const [isFetchInflight, setFetchInflight] = useState<boolean>(false);
  const containerRef = React.useRef<any>();

  const onScrolledBottom = React.useCallback(() => {
    if (data && data.searchEvents && input) {
      const { count } = data.searchEvents;

      const offsetCursor = data.searchEvents.rows.length;

      if (offsetCursor < count) {
        setFetchInflight(true);
        fetchMore({
          variables: {
            input: {
              ...input,
              pagePL: {
                limit,
                offset: offsetCursor,
              },
            },
          },

          updateQuery: (prev: any, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;

            // This uniq function might not be necessary...
            fetchMoreResult.searchEvents.rows = uniqBy(
              [...prev.searchEvents.rows, ...fetchMoreResult.searchEvents.rows],
              "id"
            );

            return fetchMoreResult;
          },
        })
          .then(() => setFetchInflight(false))
          .catch(() => setFetchInflight(false));
      }
    }
  }, [fetchMore, data?.searchEvents, input]);

  useMobileHeader({
    title: queryUser?.getMe ? "Search Meals" : "",
  });

  // For infinite scroll
  useScrolledBottom({
    callback: onScrolledBottom,
    ref: containerRef,
    offset: 1000,
  });

  useEffect(() => {
    // offsetRef.current = input.pagePL.limit;
    const source = parse(location.search)?.s ?? "organic";
    amplitude.getInstance().logEvent("[Event List] landing", { source });
  }, [location.search]);

  useEffect(() => {
    const params = parse(location.search) as any;

    // user has location data in the URL params
    if (params.location || (params.longitude && params.latitude)) {
      LocalStorageHelper.setItem(
        "FOODNOME_LOCATION_PREFERENCE",
        JSON.stringify(
          params.location
            ? { location: params.location }
            : { longitude: params.longitude, latitude: params.latitude }
        )
      );
    } else {
      const raw = window.localStorage.getItem("FOODNOME_LOCATION_PREFERENCE");
      if (!!raw) {
        const res = JsonHelper.Parse(raw, () => null) as any;
        if (typeof res === "string") {
          params.location = res;
          history.replace({ search: stringify(params) });
        } else {
          history.replace({ search: stringify({ ...params, ...res }) });
        }
      }
    }
  }, []);

  const _fetchMore = (d: IFetchMorePL) => {
    return history.push({
      search: stringify(
        Object.assign({}, d.pagePL, { location: d.location }) as any
      ),
    });
  };

  const handleSearch = (rawInput: string) => {
    const { tags: prevTags = [], ...rest } = parse(location.search);
    const tags = rawInput
      .split(",")
      .concat(prevTags ? prevTags : [])
      .map((term) => term.trim().toLowerCase())
      .reduce(
        (acc: string[], next) => acc.concat(acc.includes(next) ? [] : [next]),
        []
      );

    const searchObj = rawInput.length ? { tags, ...rest } : { ...rest };
    amplitude.getInstance().logEvent("[Event List] click search btn", search);

    return history.push({
      search: stringify(searchObj),
    });
  };

  const _description =
    "Find the best home restaurant near you. \
  Quick take-out meals to social dine-in events, hometown favorites to cultural cuisines from around the globe.";

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }

  return (
    <>
      <Helmet>
        <title>Homemade Meals in the Neighborhood | COOK Connect</title>
        <meta name="description" content={_description} />
      </Helmet>
      <Layout
        user={queryUser?.getMe as any}
        searchEvents={data?.searchEvents}
        loading={loading}
        error={error}
        fetchMore={_fetchMore}
        handleSearch={handleSearch}
        isFetchInflight={isFetchInflight}
        containerRef={containerRef}
      />
    </>
  );
};
