import { Button, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { COLORS } from "src/styles";

interface IProps {
  text: string;
  onClick: () => void;
}

const useStyles = makeStyles({
  googleLabel: {
    textTransform: "none",

    color: COLORS.WHITE,
    justifyContent: "space-between",
  },
  googleContained: {
    borderRadius: 2,
    height: 50,
    width: "100%",
    maxWidth: 328,
    margin: "0 auto",
    padding: 8,
    borderColor: "#4385F4",
    backgroundColor: "#4385F4",
    "&:hover": {
      backgroundColor: "#4385F4",
      opacity: 0.7,
    },
  },
  labelText: {
    width: "100%",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
});

export const GoogleBtn: FC<IProps> = ({ onClick, text }) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      size="large"
      disableElevation
      fullWidth
      classes={{
        label: classes.googleLabel,
        contained: classes.googleContained,
      }}
      onClick={onClick}
      data-testid="login-with-google-btn"
    >
      <img
        src={require(`src/assets/icons/btn_google_light_normal_ios.svg`)}
        style={{ width: "2.5rem", height: "2.5rem" }}
      />{" "}
      <span className={classes.labelText}>{text}</span>
    </Button>
  );
};
