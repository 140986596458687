import React, { FC } from "react";
import { ProfileImage } from "./profile-img";
import { useStyles } from "./profile-img.styles";

interface IProps {
  setPic: (d: string) => void;
  pic: string;
  uploadImage: (f: File) => Promise<any>;
}

export const UploadProfileImage: FC<IProps> = ({
  uploadImage,
  setPic,
  pic,
}) => {
  const classes = useStyles();

  function onUpload(file: File): Promise<any> {
    return new Promise(async (res, rej) => {
      // NOTE: you have to assign variables to the `naturalWidth` and `width` since
      // the reference to the image (i.e. the DOM node) can change.
      // const naturalWidth = image.naturalWidth;
      // const imageWidth = image.width;
      // const naturalHeight = image.naturalHeight;
      // const imageHeight = image.height;

      // Make API call to cloudinary with original file
      const { data } = await uploadImage(file);

      // calculate how much to scale crop
      // const scaleX = naturalWidth / imageWidth;
      // const scaleY = naturalHeight / imageHeight;
      // const width = (scaleX * crop.width).toFixed();
      // const height = (scaleY * crop.height).toFixed();
      // const x = (scaleX * crop.x).toFixed();
      // const y = (scaleY * crop.y).toFixed();

      // const cloudinaryConfig = `x_${x},y_${y},w_${width},h_${height},c_crop`;
      const imageURL = data.eager[0].secure_url;

      return res({ src: imageURL });
    });
  }

  function uploadProfilePic(file: File) {
    return onUpload(file).then(({ src }) => setPic(src));
  }

  return <ProfileImage src={pic} upload={uploadProfilePic} />;
};
