import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  rowThree: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 200,
  },
  largeSubText: {
    maxWidth: 600,
    width: "100%",
    color: COLORS.DARK_RED,
    fontWeight: "bold",
    textAlign: "center",
    margin: "15px 0 30px 0",
    padding: "0 40px",
  },
});
