import { useQuery } from "@apollo/client";
import { Card, makeStyles, Typography } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { GET_ME } from "src/shared-graphql/queries";
import { ListMapContext } from "../container";
import { ActionTypes } from "../reducer";
import { RestaurantCardMobile } from "./restaurant-card-mobile";
import { RestaurantSkeletonMobile } from "./restaurant-card-mobile/skeleton-mobile";

export const RestaurantListMobile: FC = React.memo(() => {
  const classes = useStyles();
  const {
    state: { clickedRestaurant, restaurants, loadingRestaurants },
    dispatch,
  } = useContext(ListMapContext);
  const { data, error, loading } = useQuery(GET_ME, {
    fetchPolicy: "cache-and-network",
  });
  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    if (clickedRestaurant && !loadingRestaurants && restaurants) {
      const index = restaurants.indexOf(clickedRestaurant);
      setActiveIndex(index === -1 ? 0 : index);
    }
  }, [clickedRestaurant, loadingRestaurants, restaurants]);

  useEffect(() => {
    if (restaurants) {
      dispatch({
        type: ActionTypes.SET_CLICKEDED_RESTAURANT,
        payload: restaurants[activeIndex],
      });
    }
  }, [activeIndex]);

  if (!loadingRestaurants && restaurants.length) {
    return (
      <SwipeableViews
        enableMouseEvents
        className={classes.swipeableViews}
        index={activeIndex > restaurants.length ? 0 : activeIndex}
        onChangeIndex={(index) => setActiveIndex(index)}
      >
        {restaurants.map((r) => (
          <RestaurantCardMobile
            getMe={data.getMe}
            restaurant={r}
            key={r.id + r.name}
          />
        ))}
      </SwipeableViews>
    );
  } else if (loadingRestaurants) {
    return (
      <div className={classes.loadingContainer}>
        <RestaurantSkeletonMobile />
      </div>
    );
  }
  return (
    <Card className={classes.noResultsCard}>
      <Typography component="p" variant="body1">
        No restaurants in this area. Try broadening your search
      </Typography>
    </Card>
  );
});

const useStyles = makeStyles({
  swipeableViews: {
    padding: "0 5%",
  },
  noResultsCard: {
    margin: "3rem",
    padding: "2rem",
    borderRadius: "1rem",
    boxShadow: "rgba(0, 0, 0, 0.28) 0px 8px 15px",
  },
  loading: {
    fontSize: "3rem",
  },
  loadingContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    //  marginBottom: "3rem",
    padding: "0 5%",
  },
});
