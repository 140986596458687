import {
  Button,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { Add, Close, Remove } from "@material-ui/icons";
import { parse } from "query-string";
import React, { FC, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ResponsiveDialog } from "src/components";
import { dinerPixel, ReactPixel } from "src/index";
import { IDish, IEvent } from "src/models";
import { amplitude } from "src/services";
import { OrderContext } from "../order-context";
import { ActionTypes } from "../order-reducer";
import { DishDialogCarousel } from "./dish-dialog-carousel";
import { useStyles } from "./dish-dialog.styles";

interface IProps {
  dish: IDish;
  children: any;
  getEvent: IEvent;
  initOption?: string;
}

export const DishItemDialog: FC<IProps> = React.memo(
  ({ dish, children, getEvent, initOption }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    // Setting the defualt option to a string to force users to pick an option
    const [option, setOption] = useState(
      initOption ? initOption : dish.DishEvent?.options?.length ? "" : ""
    );
    const location = useLocation();
    const [count, setCount] = useState(1);
    const [notes, setNotes] = useState(getEvent.eventUser?.notes ?? "");
    const [addition, setAddition] = useState(0);
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState("");
    const variation = "var-2";

    const queryObj = parse(location.search);

    const {
      dispatch,
      state: { orderedDishes },
    } = useContext(OrderContext);
    const dishFound = orderedDishes.find((d) => d.id === dish.id);

    const childrenWithProp = React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        setOpen: () => setOpen(true),
      });
    });

    useEffect(() => {
      if (variation) {
        amplitude
          .getInstance()
          .logEvent(`[Event Detail] Add To Cart Button ${variation}`, {
            source: queryObj.s,
          });
      }
    }, [variation]);

    useEffect(() => {
      if (dishFound?.notes) {
        const note = option
          ? dishFound.notes
              .split(".\n")
              .find((n) => n.includes(option))
              ?.split("👉")[1]
              ?.trim()
          : dishFound.notes;

        setNotes(note ?? "");
      }
    }, [option, open]);

    const onClick = (sign) => {
      sign === "+" ? setCount(count + 1) : setCount(count - 1);
    };

    const onClose = () => {
      if (!!dish.DishEvent?.options?.length && !option) {
        setHelperText("Please select an option above for your dish!");
        setError(true);
      } else {
        if (dishFound) {
          process.env.NODE_ENV !== "test" &&
            ReactPixel.trackSingle(dinerPixel, "AddToCart", {
              value: dish.DishEvent?.price,
              currency: "USD",
              content_type: "product", // required property
              content_ids: dish.id, // required property, if not using 'contents' property
            });
          amplitude.getInstance().logEvent("[Event Detail] add to order", {
            price: (count * (dishFound.DishEvent as any).price).toFixed(2),
          });
        }
        setCount(1);
        dispatch({
          type: ActionTypes.SET_DISH_COUNT,
          payload: {
            id: dish.id,
            count: option
              ? (dishFound?.options?.find((o) => o.name === option)?.count ??
                  0) + count
              : (dishFound?.count ?? 0) + count,
            option,
            notes,
          },
        });
        setOpen(false);
      }
    };

    if (!dishFound) return null;

    const images = dish.imagesGQL?.length ? dish.imagesGQL : [];

    const showActionButtons = Boolean(
      !getEvent || (getEvent && !getEvent.type.includes("FIXED_TIME"))
    );

    const dishLeft = dishFound.dishLeft;
    const totalDishInCart =
      count +
      (dishFound?.options?.reduce(
        (acc, next) => (next.count ?? 0) + acc,
        dishFound.count
      ) ?? dishFound.count);

    const _setOpenFalse = () => {
      setOpen(false);
      setNotes("");
    };
    return (
      <>
        {childrenWithProp}
        <ResponsiveDialog
          fullWidth
          maxWidth="md"
          open={open}
          onClose={_setOpenFalse}
          className={classes.dialog}
          classes={{
            paperWidthSm: classes.dialogPaperWidth,
            paperWidthMd: classes.dialogPaperWidth,
          }}
        >
          <IconButton
            data-testid="close-button-dish-dialog"
            aria-label="Close"
            onClick={_setOpenFalse}
            className={classes.iconBtn}
          >
            <Close className={classes.closeBtn} />
          </IconButton>

          {/* Need to give full height to Fixed_time event, 80% to non fixed event to allow scroll */}
          <DialogContent className={classes.dialogContent}>
            <div style={{ overflowY: "auto", maxHeight: "100%" }}>
              {/* Dish Image Carousel */}
              <DishDialogCarousel
                images={images}
                dish={dishFound}
                getEvent={getEvent}
              />
              <div className={classes.infoContainer}>
                <div className={classes.infoSubContainer}>
                  {showActionButtons && !!dish.DishEvent?.options?.length && (
                    <div className={classes.optionContainer}>
                      <FormControl
                        variant="outlined"
                        color="secondary"
                        error={error}
                        fullWidth
                      >
                        <FormLabel
                          component="legend"
                          className={classes.formLable}
                        >
                          <Typography variant="subtitle1" component="p">
                            Pick an option (Required)
                          </Typography>
                        </FormLabel>
                        <RadioGroup
                          aria-label="option"
                          name="option"
                          value={option}
                          className={classes.radioGroup}
                          onChange={(e) => {
                            setOption(e.target.value);
                            setError(false);
                            setHelperText("");
                            setAddition(
                              dishFound.options?.find(
                                (o) => o.name === e.target.value
                              )?.addition ?? 0
                            );
                          }}
                        >
                          {dishFound?.DishEvent?.options?.map((o, idx) => (
                            <FormControlLabel
                              value={o.name}
                              className={classes.radioLabel}
                              classes={{ label: classes.radioLabel }}
                              control={
                                <Radio
                                  className={classes.radio}
                                  size={"small"}
                                  inputProps={
                                    {
                                      "data-testid": `select-option-${idx}`,
                                    } as any
                                  }
                                />
                              }
                              label={`${o.name} ${
                                !!o.addition
                                  ? `(+$${o.addition.toFixed(2)})`
                                  : ""
                              }`}
                              key={o.name}
                            />
                          ))}
                        </RadioGroup>
                        <FormHelperText>{helperText}</FormHelperText>
                      </FormControl>
                    </div>
                  )}

                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.description}
                  >
                    {dish.description}
                  </Typography>
                  <div className={classes.allergenDietaryContainer}>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.allergen}
                    >
                      <span className={classes.label}>Allergens:</span>{" "}
                      {dish.allergens?.length
                        ? dish.allergens
                            .map((a) => a.name.toLowerCase())
                            .join(", ")
                        : "none"}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.dietary}
                    >
                      <span className={classes.label}>Dietary Type:</span>{" "}
                      {dish.dietary ? dish.dietary.toLowerCase() : "none"}
                    </Typography>
                  </div>
                  {!getEvent.type.includes("FIXED_TIME") && (
                    <>
                      <Typography
                        variant="body2"
                        component="label"
                        htmlFor="specialInstructionsTextarea"
                        className={classes.textAreaLabel}
                      >
                        Special Instructions
                      </Typography>
                      {/* <Typography  component="label" variant="caption"
                        htmlFor="specialInstructionsTextarea"
                        className={classes.textAreaLabel}
                      >
                        Special Instructions
                      </Typography> */}
                      <textarea
                        id="specialInstructionsTextarea"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        placeholder="Not too spicy? Dressing on the side? Let us know here."
                        rows={5}
                        className={classes.textArea}
                      ></textarea>
                    </>
                  )}
                </div>
              </div>
            </div>
            {showActionButtons && (
              <div className={classes.actionContainer}>
                <Divider />
                <section className={classes.btnContainer}>
                  <div className={classes.plusMinusBtnContainer}>
                    <div className={classes.quantityLabel}>Quantity:</div>
                    <Button
                      data-testid="subtract-items-button-dish-dialog"
                      variant="outlined"
                      name="-"
                      classes={{
                        outlined: classes.clickBtn,
                        label: classes.clickBtnLabel,
                      }}
                      onClick={() => onClick("-")}
                      disabled={count === 1}
                    >
                      <Remove />
                    </Button>
                    <Typography
                      variant="h4"
                      component="p"
                      className={classes.count}
                    >
                      {count}
                    </Typography>
                    <Button
                      data-testid="add-items-button-dish-dialog"
                      variant="outlined"
                      classes={{
                        outlined: classes.clickBtn,
                        label: classes.clickBtnLabel,
                      }}
                      disabled={
                        dishLeft <= totalDishInCart ||
                        (dishFound.DishEvent?.price === 0 && count >= 1)
                      }
                      onClick={() => onClick("+")}
                    >
                      <Add />
                    </Button>
                  </div>
                  <Button
                    data-testid="add-to-bag-button-dish-dialog"
                    variant="contained"
                    size="large"
                    classes={{
                      contained: classes.button,
                    }}
                    disabled={dishLeft < totalDishInCart && !!count}
                    onClick={onClose}
                    // @ts-ignore
                    color={variation === "var-1" ? "secondary" : "primary"}
                  >
                    <span className={classes.addToOrderContainer}>
                      <span className={classes.addToOrderWord}>Add to Bag</span>{" "}
                      <span className={classes.addToOrderPrice}>
                        $
                        {(
                          count *
                          (addition + (dishFound?.DishEvent?.price as number))
                        ).toFixed(2)}
                      </span>
                    </span>
                  </Button>
                </section>
              </div>
            )}
          </DialogContent>
        </ResponsiveDialog>
      </>
    );
  }
);
