import { CardMedia, makeStyles, Typography } from "@material-ui/core";
import { Fade } from "react-awesome-reveal";
import { useStyles } from "./success.styles";
import React from "react";
import { COLORS } from "src/styles";

const successStories = [
  {
    delay: 550,
    name: "Chef Mal",
    location: "Chula Vista, CA",
    description:
      "Chef Malcolm ‘Mal’ Jamal Suggs operates Smoke ‘N Peppers BBQ. Originally from North Carolina, Mal is a career Navy Sailor who was stationed overseas and found himself craving Carolina BBQ.",

    image: require("../assets/chefs/chef_mal.png"),
    videoLink: "",
  },
  {
    delay: 650,
    name: "Chef Denise",
    location: "Fallbrook, CA",
    description:
      "Grandma Dee’ offers ready-to-serve meals that are “yummy and family friendly” with the intention that “‘family mealtime will be a wonderful experience for gathering, conversation, and laughter.",
    image: require("../assets/chefs/chef_denise.jpg"),
    videoLink: "",
  },
];

export const Success = () => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.headerContainer}>
          <Fade triggerOnce={true} delay={250}>
            <Typography variant="h2" component="h2" className={classes.title}>
              MEHKO Chef Stories
            </Typography>
          </Fade>
          <Fade triggerOnce={true} delay={350}>
            <div className={classes.squiggleContainer}>
              <img
                src={require("../assets/underline.svg")}
                className={classes.squiggle}
              />
            </div>
          </Fade>
        </div>
        <div className={classes.mainContent}>
          {successStories.map((story) => (
            <Fade
              triggerOnce={true}
              delay={story.delay}
              key={story.name}
              className={classes.card}
            >
              <>
                <CardMedia image={story.image} title={story.name}>
                  <img alt={story.name} style={{ display: "none" }} />
                </CardMedia>
                <div className={classes.lowerCardContainer}>
                  <Fade triggerOnce={true} delay={650}>
                    <Typography
                      variant="h3"
                      component="h3"
                      className={classes.cardTitle}
                    >
                      {story.name}{" "}
                      <span className={classes.cardLocation}>
                        | {story.location}
                      </span>
                    </Typography>
                  </Fade>
                  <Fade
                    triggerOnce={true}
                    delay={750}
                    style={{ width: "100%" }}
                  >
                    <div className={classes.redBar} />
                  </Fade>
                  <Fade triggerOnce={true} delay={850}>
                    <Typography
                      variant="h4"
                      component="p"
                      className={classes.description}
                    >
                      {story.description}
                    </Typography>
                  </Fade>
                </div>
              </>
            </Fade>
          ))}
        </div>
      </div>
    </section>
  );
};

// export const Success = () => {
//   const classes = useStyles();

//   return (
//     <section className={classes.container}>
//       <Typography component="h2" variant="h2" className={classes.title}>
//         Success cook stories on COOK Connect
//       </Typography>
//       <div className={classes.gridContainer}>
//         {successStories.map((story) => (
//           <div key={story.name} className={classes.card}>
//             <iframe
//               className={classes.image}
//               width="100%"
//               height="42rem"
//               src={story.videoLink}
//               frameBorder="0"
//               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//               allowFullScreen
//             ></iframe>

//             <div className={classes.textContainer}>
//               <Typography component="h3" variant="h3">
//                 {story.name} |{" "}
//                 <span style={{ fontFamily: "CustomFour" }}>
//                   {story.location}
//                 </span>
//               </Typography>
//               <div className={classes.redLine}></div>
//               <Typography component="p" variant="body1">
//                 {story.description}
//               </Typography>
//             </div>
//           </div>
//         ))}
//       </div>
//     </section>
//   );
// };

// const useStyles = makeStyles({
//   container: {
//     display: "flex",
//     flexDirection: "column",

//     padding: "10rem 0",
//   },
//   gridContainer: {
//     display: "flex",

//     flexWrap: "wrap",
//     justifyContent: "center",
//   },
//   title: {
//     marginBottom: "5rem",
//     textAlign: "center",
//     fontFamily: "CustomFourBold",
//   },
//   image: {
//     width: "100%",

//     height: "42rem",

//     borderRadius: "1rem",
//     boxShadow: "-2px 7px 13px 0 rgba(61,61,61,0.33)",
//   },
//   card: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     margin: "3rem",

//     maxWidth: "56rem",
//   },
//   textContainer: {
//     padding: "5rem",
//     textAlign: "center",
//   },
//   redLine: {
//     margin: "2rem auto",
//     height: "1px",
//     width: "20%",
//     minWidth: "12rem",
//     backgroundColor: COLORS.RED,
//   },
// });
