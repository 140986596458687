import axios from "axios";
import { client, SERVER_ADDRESS } from "src/apollo";
import {
  CreateApplicationStatusPL,
  SendApplicationPL,
  SendRegistrationPL,
  UpdateApplicationPL,
} from "src/models";
import {
  ADMIN_UPDATE_APPLICATION,
  CREATE_APPLICATION_STATUS,
  DELETE_APPLICATION_STATUS,
  SEND_APPLICATION,
  SEND_REGISTRATION,
} from "./graphql";

export const applicationAPI = {
  createApplicationStatus: (d: CreateApplicationStatusPL) =>
    client.mutate({
      mutation: CREATE_APPLICATION_STATUS,
      variables: {
        input: {
          applicationId: d.applicationId,
          status: d.status,
          description: d.description,
        },
      },
    }),

  updateApplication: (updateApplicationPL: UpdateApplicationPL) =>
    client.mutate({
      mutation: ADMIN_UPDATE_APPLICATION,
      variables: { input: updateApplicationPL },
    }),

  deleteAppStatus: (id: any) =>
    client.mutate({
      mutation: DELETE_APPLICATION_STATUS,
      variables: {
        input: id,
      },
    }),

  sendApplicationToSign: (d: SendApplicationPL) =>
    client.mutate({
      mutation: SEND_APPLICATION,
      variables: {
        input: d,
      },
    }),

  sendRegistrationToSign: (d: SendRegistrationPL) =>
    client.mutate({
      mutation: SEND_REGISTRATION,
      variables: {
        input: d,
      },
    }),

  sendAdminReminderText: (to: string, msg: string, time: string) =>
    axios(`${SERVER_ADDRESS}/admin/send-reminder`, {
      method: "post",
      data: {
        to,
        msg,
        time,
      },
      withCredentials: true,
    }),

  sendReminderText: (to: string, message: string) =>
    axios(`${SERVER_ADDRESS}/twilio/send-text`, {
      method: "post",
      data: {
        to,
        message,
      },
      withCredentials: true,
    }),
};
