import { useLazyQuery } from "@apollo/client";
import { Button, Dialog, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { parse } from "query-string";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IGetMe } from "src/models";
import { amplitude } from "src/services";
import { sharedAPI } from "src/shared-graphql";
import { USER_GET_REFERREE } from "./graphql";
import { useStyles } from "./styles";

interface IProps {
  getMe: IGetMe;
}
export const ReferrerSuccessDialog: FC<IProps> = ({ getMe }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [getReferree, { data, error }] = useLazyQuery(USER_GET_REFERREE);
  const d = parse(location.search);

  useEffect(() => {
    if (d.referree) {
      setOpen(true);
      getReferree({ variables: { input: d.referree } });
    }
    amplitude.getInstance().logEvent("[Referrer Success Dialog] landing");
  }, []);

  const close = () => {
    setOpen(false);
    history.push(location.pathname);
  };
  return (
    <Dialog
      open={open}
      onClose={close}
      classes={{ paper: classes.dialogPaper }}
    >
      <>
        <button className={classes.closeIconBtn}>
          <Close className={classes.mdClose} onClick={close} />
        </button>

        <section className={classes.imgContainer}>
          <img
            src={require("src/screens/become-cook-info/assets/congrats.png")}
            alt="congrats"
            className={classes.img}
          />
        </section>
        <section className={classes.infoContainer}>
          <img
            className={classes.logo}
            alt="cookconnect-logo"
            src={require("src/assets/logos/cook.svg")}
          />
          <Typography variant="h3" className={classes.h3}>
            {!!error ? (
              error.message
            ) : (
              <>
                Cha-Ching!{" "}
                <span className={classes.fiveDollar}>
                  $5 has been added to your account.
                </span>
              </>
            )}
          </Typography>
          <Typography variant="body2" className={classes.body1}>
            {!!error ? (
              <>
                Get $5 for every friend you invite that makes a purchase! We
                will notify you each time a friend has successfully been
                referred!
              </>
            ) : (
              <>
                Your friend {data?.userGetReferree?.firstName} completed their
                first purchase with your referral link! Both of you received $5
                towards your next order. You now have $
                {getMe.credits.toFixed(2)} total credits on your account.
              </>
            )}
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            className={classes.continueBtn}
            onClick={() => {
              amplitude
                .getInstance()
                .logEvent("[Referrer Success Dialog] refer more btn");
              sharedAPI.setReferralDialogState(true);
              setOpen(false);
            }}
          >
            Refer More Friends
          </Button>
          {!error && (
            <Button
              variant="outlined"
              color="secondary"
              className={classes.findAMeal}
              onClick={() => {
                amplitude
                  .getInstance()
                  .logEvent("[Referrer Success Dialog] find a meal btn");
                history.push("/menus");
                setOpen(false);
              }}
            >
              Find a Meal
            </Button>
          )}
        </section>
      </>
    </Dialog>
  );
};
