import { useMutation } from "@apollo/client";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";
import { IDish } from "src/models";
import { COOK_MARK_SOLD_OUT } from "../graphql";
import { useStyles } from "./dish-card.styles";

interface IProps {
  dish: IDish;
}
export const DishCard: FC<IProps> = ({ dish }) => {
  const classes = useStyles();

  const [soldOut] = useMutation(COOK_MARK_SOLD_OUT, {
    refetchQueries: ["cookGetEvent"],
  });

  return (
    <>
      <Card component="li" elevation={5}>
        <CardActionArea>
          {/* <CardMedia
            title={dish.name}
            className={classes.media}
            image={dish.imagesGQL[0].small}
          /> */}
          <CardContent>
            <Typography
              variant="body2"
              component="p"
              gutterBottom
              className={classes.cardTitle}
            >
              {dish.name}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                component="p"
                className={classes.orderedDishCount}
              >
                {dish.DishEvent?.sold ?? 0} / {dish.DishEvent?.count} sold
              </Typography>
              {dish.DishEvent?.sold !== dish.DishEvent?.count && (
                <Button
                  className={classes.soldOutBtn}
                  variant="outlined"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    confirm("Are you sure you want mark this dish as sold out?")
                      ? soldOut({
                          variables: {
                            input: {
                              id: dish.DishEvent?.id,
                              count: dish.DishEvent?.sold,
                            },
                          },
                        })
                      : null;
                  }}
                >
                  Sold out
                </Button>
              )}
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
      {/* <DishDialog dish={dish} open={open} setOpen={setOpen} /> */}
    </>
  );
};
