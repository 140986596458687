import { DishOption, IAllergen, IImage } from "src/models";
import { Actions, IAction } from "./actions";

export interface IDishFormState {
  images: IImage[];
  name: string;
  description: string;
  defaultPrice?: number;
  category: string;
  tags: string[];
  allergens: IAllergen[];
  dietary: string;
  isPublished: boolean;
  options: DishOption[];
}

export const initialState: IDishFormState = {
  images: [],
  name: "",
  description: "",
  category: "ENTREE",
  defaultPrice: 0,
  tags: [],
  allergens: [],
  dietary: "NONE",
  isPublished: true,
  options: [],
};

export default function reducer(state: IDishFormState, action: IAction) {
  switch (action.type) {
    case Actions.UPLOAD_IMAGE:
      return {
        ...state,
        images: state.images.concat(
          state.images.some((i) => i.publicId === action.image.publicId)
            ? []
            : [action.image]
        ),
      };
    case Actions.DELETE_DISH_IMAGE:
      return {
        ...state,
        images: state.images.filter((i) => i.publicId !== action.publicId),
      };
    case Actions.SET_INPUT:
      return { ...state, [action.key]: action.value };
  }
}
