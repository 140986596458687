import { gql } from "@apollo/client";
import { CREATE_DISH } from "src/shared-graphql/mutations";

export { CREATE_DISH };

export const ADMIN_UPDATE_DISH = gql`
  mutation updateAdminDish($input: UpdateDishPL!) {
    updateAdminDish(updateDishPL: $input) {
      id
      name
      description
      imagesGQL {
        publicId
        small
        medium
        large
        original
      }
      dietary
      tags
      updatedAt
      createdAt
    }
  }
`;

export const DELETE_DISH = gql`
  mutation deleteDish($input: ID!) {
    deleteDish(id: $input)
  }
`;
