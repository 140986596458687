import { useQuery } from "@apollo/client";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ErrorLoadingComponent } from "src/components";
import { IPagePL } from "src/models";
import { useDelay } from "src/utils/hooks";
import { GET_RESTAURANTS } from "../api/graphql";
import { Layout } from "./layout";

export interface IFetchMorePL {
  pagePL?: IPagePL;
  status?: string;
  searchTerm?: string;
  county?: string;
}

export const RestaurantListContainer: FC = ({}) => {
  const history = useHistory();
  const { data, loading, error, fetchMore } = useQuery(GET_RESTAURANTS, {
    variables: {
      input: {
        pagePL: {
          offset: undefined,
          limit: 10,
        },
      },
    },
  });
  const [pageNum, setPageNum] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDelay(searchTerm, 300);

  const redirect = (url: string) => history.push(url);

  useEffect(() => {
    _fetchMore({
      pagePL: { limit: 10 },
      searchTerm: debouncedSearchTerm ? debouncedSearchTerm : undefined,
    });
  }, [debouncedSearchTerm]);

  const _fetchMore = (d: IFetchMorePL) => {
    fetchMore({
      variables: {
        input: d,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          adminGetRestaurants: (fetchMoreResult as any).adminGetRestaurants,
        });
      },
    });
  };

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }
  return (
    <Layout
      totalCount={data.adminGetRestaurants.count}
      restaurants={data.adminGetRestaurants.rows}
      onFetchMore={_fetchMore}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      redirect={redirect}
    />
  );
};
