import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS } from "src/styles/";

export const useStyles = makeStyles({
  container: {
    gridColumn: "1 / 2",
    gridRow: "1 / 2",
    display: "flex",
    flexDirection: "column",
    width: 205,
    height: "100%",
    alignItems: "center",
    boxShadow: "8px 0 13px -10px rgba(0,0,0,0.42)",
  },
  logoContainer: {
    height: 150,
    width: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cookIcon: {
    width: 70,
  },
  dinerIcon: {
    width: 100,
  },
  [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
    container: {
      display: "none",
    },
  },
});

export const rightStyles = makeStyles({
  container: {
    gridColumn: "3 / 4",
    gridRow: "1 / 2",
    height: "100%",
  },
});
