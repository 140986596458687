import { useMutation } from "@apollo/client";
import { Typography, useMediaQuery } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { IEvent, IGetMe } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { BREAK_POINTS } from "src/styles";
import { authAPI } from "../../api";
import { DINER_GET_DELIVERY_COST } from "../../gather-address-dialog/graphql/queries";
import { OrderContext } from "../../order-context";
import { ActionTypes } from "../../order-reducer";
import { OrderItems, SelectDropIn, SelectSeat } from "./";
import { CheckoutButton } from "./checkout-button";
import { useStyles } from "./order-info.styles";

interface IProps {
  getEvent: IEvent;
  getMe?: IGetMe;
}

export const OrderInfo: FC<IProps> = ({ getEvent, getMe }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [animate, setAnimate] = useState(false);
  const [getDeliveryCost, { loading }] = useMutation(DINER_GET_DELIVERY_COST, {
    refetchQueries: ["GET_ME"],
  });
  const {
    state: { orderedDishes, seats, dineOption, dialogView, arrivalTime },
    dispatch,
  } = useContext(OrderContext);
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

  useEffect(() => {
    if (animate === true) {
      setTimeout(() => {
        setAnimate(false);
      }, 2000);
    }
  }, [animate]);

  const totalCost = getEvent.type.includes("FIXED_TIME")
    ? getEvent.price * seats
    : orderedDishes.reduce((acc, next) => {
        let _total = 0;
        if (next.options?.length) {
          _total = next.options?.reduce((_acc, _next) => {
            return (
              _acc +
              (_next?.count ?? 0) *
                (_next?.addition + (next.DishEvent?.price ?? 0))
            );
          }, 0);
        } else {
          _total = (next.DishEvent?.price ?? 0) * next.count;
        }
        return acc + _total;
      }, 0);

  const credits = !getMe
    ? null
    : getMe.nomes + getMe.credits >= totalCost
    ? totalCost
    : getMe.nomes + getMe.credits;

  let total = totalCost;
  if (getMe && getMe.nomes + getMe.credits >= total) {
    total = 0;
  } else if (getMe) {
    total = total - getMe.nomes - getMe.credits;
  }

  const noOrder = getEvent.type.includes("FIXED_TIME")
    ? !Boolean(seats)
    : !orderedDishes.some(
        (d) => Boolean(d.count) || !!d.options?.some((o) => o.count)
      );

  return (
    <>
      {!isMobile && (
        <div className={classes.container}>
          <div
            className={classes.contentContainer}
            style={{
              position: "-webkit-sticky", // for sticky position on safari 👋
            }}
          >
            {/* ======= top box ======= */}
            <section className={classes.top}>
              <OrderItems getEvent={getEvent} noOrder={noOrder} />
            </section>

            {/* ======= bottom box ======= */}
            {/* <Divider style={{ margin: "2rem 0 1.5rem 0" }} /> */}

            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "0 1rem",
              }}
            >
              {/* Totals */}
              <div className={classes.priceContainer}>
                {!!(
                  credits &&
                  !noOrder &&
                  getMe &&
                  (getMe.nomes || getMe.credits)
                ) && (
                  <div className={classes.creditContainer}>
                    <Typography variant="body2" component="p">
                      Credits
                    </Typography>
                    <Typography variant="body2" component="p">
                      -$
                      {credits ? credits.toFixed(2) : ""}
                    </Typography>
                  </div>
                )}
                {!noOrder && (
                  <div className={classes.subTotalContainer}>
                    <div className={classes.subTotalWord}>
                      <Typography
                        variant="body2"
                        component="p"
                        style={{ textTransform: "uppercase" }}
                      >
                        Items subtotal:
                      </Typography>
                    </div>
                    <Typography
                      variant="body2"
                      component="p"
                      data-testid="items-subtotal-amount"
                      className={classes.totalAmount}
                    >
                      $
                      {total >= 0
                        ? `${total.toFixed(2)}`
                        : `-$${Math.abs(total).toFixed(2)}`}
                    </Typography>
                  </div>
                )}
              </div>

              {/* Select */}

              <div style={{ width: "100%" }}>
                {getEvent.type.includes("FIXED_TIME") &&
                  getEvent.seatsLeft > 0 &&
                  !getEvent.name.includes("Bulk order for") && (
                    <div style={{ marginBottom: "1rem" }}>
                      <SelectSeat
                        seats={seats}
                        dispatch={dispatch}
                        getEvent={getEvent}
                      />
                    </div>
                  )}
                {!getEvent.type.includes("FIXED_TIME") && (
                  <>
                    <CSSTransition in={animate} classNames="tada" timeout={450}>
                      <SelectDropIn
                        error={animate}
                        getEvent={getEvent}
                        getMe={getMe}
                      />
                    </CSSTransition>
                  </>
                )}

                {/* Button */}
                <CSSTransition in={!noOrder} classNames="bounce" timeout={450}>
                  <CheckoutButton
                    currentUser={getMe}
                    requireAddress={dineOption === "DELIVERY"}
                    disabled={
                      loading ||
                      (dineOption === "DELIVERY" &&
                        getEvent.deliveryInfo &&
                        totalCost < getEvent.deliveryInfo.minimumOrderAmount) ||
                      (getEvent.type.includes("FIXED_TIME") &&
                        getEvent.seatsLeft <= 0)
                    }
                    handleSubmit={() => {
                      if (noOrder) {
                        return sharedAPI.setSnackbarMsg({
                          type: "error",
                          msg: "Please select a dish",
                        });
                      }
                      if (!dineOption) {
                        window.scrollTo(0, 0);
                        setAnimate(true);
                        return sharedAPI.setSnackbarMsg({
                          type: "error",
                          msg: "Please select one of the options",
                        });
                      }
                      if (
                        !arrivalTime &&
                        !getEvent.type.includes("FIXED_TIME")
                      ) {
                        window.scrollTo(0, 0);
                        setAnimate(true);
                        return sharedAPI.setSnackbarMsg({
                          type: "error",
                          msg: "Please select a time you want your food",
                        });
                      }
                      if (
                        !getEvent.type.includes("FIXED_TIME") &&
                        getEvent.soldOutTimeSlots?.some(
                          (slot) =>
                            Number(arrivalTime) >= Number(slot[0]) &&
                            Number(arrivalTime) < Number(slot[1])
                        )
                      ) {
                        window.scrollTo(0, 0);
                        setAnimate(true);
                        return sharedAPI.setSnackbarMsg({
                          type: "error",
                          msg: "That time slot is sold out",
                        });
                      }
                      if (dineOption !== "DELIVERY") {
                        return dialogView
                          ? dispatch({
                              type: ActionTypes.SET_EVENT_DIALOG_VIEW,
                              payload: "checkout",
                            })
                          : history.push(`${location.pathname}/checkout`);
                      } else if (dineOption === "DELIVERY") {
                        if (!getMe?.address) {
                          return dispatch({
                            type: ActionTypes.SET_ADDRESS_DIALOG_STATUS,
                            payload: {
                              url: `${location.pathname}/checkout`,
                              total: totalCost,
                            },
                          });
                        }
                        return getDeliveryCost({
                          variables: {
                            input: {
                              eventId: getEvent.id,
                              address: getMe?.address,
                              total: totalCost,
                            },
                          },
                        })
                          .then((_d) => {
                            dispatch({
                              type: ActionTypes.SET_DELIVERY_COST,
                              payload: _d.data.dinerGetDeliveryCost.cost,
                            });
                            dialogView
                              ? dispatch({
                                  type: ActionTypes.SET_EVENT_DIALOG_VIEW,
                                  payload: "checkout",
                                })
                              : history.push(`${location.pathname}/checkout`);
                          })
                          .catch((e) =>
                            sharedAPI.setSnackbarMsg({
                              type: "error",
                              msg: e.message.replace(/Graphql Error: /i, ""),
                            })
                          );
                      }
                    }}
                    dialogCallback={(pl: {
                      email: string;
                      firstName: string;
                      lastName: string;
                    }) => authAPI.checkoutAsGuest(pl)}
                  >
                    {/* <img
                    className={classes.bagIcon}
                    src={require(`src/assets/icons/bag-43.svg`)}
                  /> */}
                    {getEvent.type.includes("FIXED_TIME") &&
                    getEvent.seatsLeft <= 0
                      ? "Sorry, it is sold out"
                      : `View Order`}
                    {getEvent.type.includes("FIXED_TIME") &&
                    getEvent.seatsLeft <= 0 ? (
                      ""
                    ) : (
                      <div className={classes.buttonPriceContainer}>
                        $
                        {total >= 0
                          ? `${total.toFixed(2)}`
                          : `-$${Math.abs(total).toFixed(2)}`}
                      </div>
                    )}
                  </CheckoutButton>
                </CSSTransition>

                {/* Refund */}
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.refundNote}
                >
                  {/* Full refund up to 24 hrs before event starts */}
                </Typography>
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  );
};
