import { useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { FC, useState } from "react";
import { useHistory } from "react-router";
import { IEventUser, IUser } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { COOK_CANCEL_EVENT_USER, COOK_CREDIT_GUEST } from "../graphql";
import { useStyles } from "./refund-dialog.styles";

interface IProps {
  guest: IUser;
  currentOrder?: IEventUser;
}
export const RefundDialog: FC<IProps> = React.memo(
  ({ guest, currentOrder }) => {
    const [open, setOpen] = useState(false);
    const { go } = useHistory();
    const [amount, setAmount] = useState(0);
    const [disableRefundButton, setDisableRefundButton] = useState(false);

    const classes = useStyles();

    const [credit] = useMutation(COOK_CREDIT_GUEST);
    const [cancel] = useMutation(COOK_CANCEL_EVENT_USER, {
      refetchQueries: ["getMe"],
      variables: { input: currentOrder?.id },
    });

    if (!currentOrder?.eventId) return null;
    return (
      <>
        <Button
          style={{ minWidth: 91 }}
          onClick={() => {
            if (
              confirm(
                "Guest will receive full refund after cancel, are you sure?"
              )
            ) {
              cancel().then(() => {
                go(0);
              });
            }
          }}
          variant="contained"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          style={{ minWidth: 91 }}
          onClick={() => setOpen(true)}
          variant="contained"
          color="primary"
        >
          Credits
        </Button>
        <Dialog
          open={open}
          fullWidth
          maxWidth="xs"
          onClose={() => setOpen(false)}
        >
          <div className={classes.dialogTitle}>
            <Typography variant="h4" className={classes.title}>
              Give Credits to {guest.firstName}
            </Typography>
            <IconButton
              onClick={() => setOpen(false)}
              data-testid="close-credits-dialog-button"
              aria-label="close"
            >
              <Close style={{ color: COLORS.MEDIUM_GREEN }} />
            </IconButton>
          </div>
          <DialogContent>
            <TextField
              value={amount}
              label="$ amount"
              inputProps={{ min: "0", max: "100", step: ".5" }}
              required
              disabled={disableRefundButton}
              fullWidth
              type="number"
              onChange={(e) => setAmount(Number(e.target.value))}
            />
            {disableRefundButton && (
              <Typography
                component="p"
                variant="body2"
                className={classes.error}
              >
                You have already refunded this diner
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              disabled={amount <= 0 || disableRefundButton}
              color="secondary"
              onClick={() =>
                credit({
                  variables: {
                    input: {
                      eventUserId: currentOrder?.id,
                      amount,
                    },
                  },
                })
                  .then(() => {
                    sharedAPI.setSnackbarMsg({
                      type: "success",
                      msg: "Credits were sent!",
                    });
                    setDisableRefundButton(true);
                    setOpen(false);
                  })
                  .catch((err) =>
                    sharedAPI.setSnackbarMsg({
                      type: "error",
                      msg: err.message.replace(/graphql error:/gi, ""),
                    })
                  )
              }
            >
              Refund
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);
