import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS } from "src/styles";

export const useDummyStyles = makeStyles({
  card: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 0 8px 1px rgba(29,29,29,0.31)",
    maxWidth: 344,
    width: "100%",
    borderRadius: 2,
    marginBottom: 20,
    maxHeight: 360,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      maxHeight: 330,
    },
  },
  image: {
    position: "relative",
  },
  eventDateTimeLeft: {
    position: "absolute",
    left: 10,
    top: 0,
  },
  eventDateTimeRight: {
    position: "absolute",
    right: 10,
    top: 0,
  },
  dotsContainer: {
    position: "absolute",
    bottom: ".5rem",
    left: "50%",
    transform: `translateX(-50%)`,
  },
  avatar: {
    position: "absolute",
    bottom: -33,
    right: 20,
    backgroundColor: `rgba(0, 0, 0, .1)`,
  },
  cardBottom: {
    height: 196,
    position: "relative",
  },
  restaurantNameRatingContainer: {
    position: "absolute",
    top: 3,
    left: 100,
  },
  starsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(5, auto)",
    gridGap: 2,
    padding: ".25rem",
  },
  eventTitleContainer: {
    position: "absolute",
    bottom: 0,
    left: 25,
    right: 25,
    top: 20,
  },
  eventDetailsContainer: {
    display: "grid",
    gridTemplateRows: `repeat(2, auto)`,
  },
  eventDetailContainer: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: 5,
    alignItems: "center",
  },
  cardFooterContainer: {
    position: "absolute",
    bottom: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  chipsContainer: {
    display: "flex",
    alignItems: "center",
  },
});
