import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { HybridLink } from "src/components";
import { useStyles } from "./questions.styles";

export const Questions: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="body1" component="p" className={classes.title}>
        Questions? Please refer to our{" "}
        <HybridLink
          href="https://foodnome.com/faq?type=cook"
          className={classes.link}
        >
          FAQ
        </HybridLink>{" "}
        or contact us!
      </Typography>
      <Typography variant="body1" component="p">
        Call us:{" "}
        <a href={"tel:5107514902"} className={classes.link}>
          (510)-751-4902
        </a>{" "}
        (9am - 6pm PST)
      </Typography>
      <Typography variant="body1" component="p">
        Email us:{" "}
        <a href={"mailto:cook@foodnome.com"} className={classes.link}>
          cook@foodnome.com
        </a>
      </Typography>
    </div>
  );
};
