import { client } from "src/apollo";
import { CreatePromotionPL, IEvent, IPagePL, UpdateEventPL } from "src/models";
import {
  ADMIN_CANCEL_EVENT_USER,
  ADMIN_COMPLETE_EVENT,
  ADMIN_UPDATE_EVENT,
  CREATE_UPDATE_DISH_EVENT,
  DELETE_DISH_EVENT,
  DELETE_EVENT,
  DELETE_PROMOTION,
  GET_EVENTS,
  SEND_REVIEWS_TO_GUESTS,
  SET_PROMOTION,
} from "./graphql";

interface IGetEventsPL extends IPagePL {
  status?: string;
}

export const eventAPI = {
  getEvents: (getEventsPL: IGetEventsPL) =>
    client.query<{ getEvents: IEvent[] }>({
      query: GET_EVENTS,
      variables: {
        input: getEventsPL,
      },
      fetchPolicy: "network-only",
    }),

  updateEvent: (adminUpdateEvent: UpdateEventPL) =>
    client.mutate({
      mutation: ADMIN_UPDATE_EVENT,
      variables: {
        input: adminUpdateEvent,
      },
      refetchQueries: ["getEvents"],
    }),

  sendReviewsToGuests: (id: any) =>
    client.mutate({
      mutation: SEND_REVIEWS_TO_GUESTS,
      variables: {
        input: id,
      },
      refetchQueries: ["getEvents"],
    }),

  promoEvents: (createPromotionPL: CreatePromotionPL) =>
    client.mutate({
      mutation: SET_PROMOTION,
      variables: {
        input: createPromotionPL,
      },
      refetchQueries: ["getEvents"],
    }),

  deletePromo: (id: string) =>
    client.mutate({
      mutation: DELETE_PROMOTION,
      variables: {
        input: id,
      },
    }),

  completeEvent: (id: string) =>
    client.mutate({
      mutation: ADMIN_COMPLETE_EVENT,
      variables: {
        input: id,
      },
      refetchQueries: ["getEvents"],
    }),

  deleteDishEvent: (d: number) =>
    client.mutate({
      mutation: DELETE_DISH_EVENT,
      variables: {
        input: d,
      },
    }),

  createDishEvent: (d: {
    eventId: string | string;
    dishId: number;
    count: number;
    sold: number;
    price: number;
  }) =>
    client.mutate({
      mutation: CREATE_UPDATE_DISH_EVENT,
      variables: {
        input: d,
      },
    }),

  deleteEvent: async (id: number, offset: number) =>
    client.mutate({
      mutation: DELETE_EVENT,
      variables: {
        input: id,
      },
      update: async (store) => {
        let d = store.readQuery({
          query: GET_EVENTS,
          variables: { input: { limit: 10 } },
        }) as any;
        const dishes = await eventAPI.getEvents({ limit: 10, offset });
        d = dishes.data;
        store.writeQuery({
          query: GET_EVENTS,
          variables: { input: { limit: 10 } },
          data: d,
        });
      },
    }),

  cancelEventUser: (eventUserId: number, status: string) =>
    client.mutate({
      mutation: ADMIN_CANCEL_EVENT_USER,
      variables: {
        input: {
          eventUserId,
          status,
        },
      },
      refetchQueries: ["getEvents"],
    }),
};
