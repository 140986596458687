import { Button, Typography } from "@material-ui/core";
import React from "react";
import { IGetMe } from "src/models";
import { useStyles } from "./dialog.styles";

interface IProps {
  getMe: IGetMe;
  handleClose: () => void;
}

export const PendingDialogContent: React.FC<IProps> = ({
  getMe,
  handleClose,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.dialogContent}>
      <Typography variant="h2" component="h2" className={classes.headerTitle}>
        Thanks {getMe?.firstName}!
      </Typography>
      <Typography variant="h3" component="h3" className={classes.subTitle}>
        We’ll be in touch in the next few days with next steps.
      </Typography>
      <Typography variant="body1" component="p" className={classes.text}>
        In the meantime, please feel free to explore your profile and our
        provided resources to familiarize yourself with our platform.
      </Typography>
      <Button
        onClick={handleClose}
        color="secondary"
        variant="contained"
        size="large"
        fullWidth
        className={classes.button}
      >
        Got It!
      </Button>
    </div>
  );
};
