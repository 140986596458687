import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  title: {},
  subtitle: {
    color: COLORS.DARK_GREY,
    margin: 0,
  },
  saveBtn: {
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
  },
  notNowBtn: {
    fontFamily: "CustomFourBold",
  },
  dialogContent: {
    height: 80,
  },
});
