import { useEffect, useState } from "react";
import { BREAK_POINTS } from "src/styles";

function getViewportWidth() {
  return Math.max(
    document!.documentElement!.clientWidth,
    window!.innerWidth || 0
  );
}

export default function useViewportWidth({
  breakPoints = Object.values(BREAK_POINTS).map(
    (width) => `screen and (max-width: ${width}px)`
  ),
}: {
  breakPoints?: string[];
} = {}) {
  const [vw, setViewportWidth] = useState<number>(getViewportWidth());

  useEffect(() => {
    function handleMatchBreakPoint() {
      setViewportWidth(getViewportWidth());
    }

    const listeners = breakPoints.map((breakPoint) => {
      const widthMatch = window.matchMedia(breakPoint) as any;
      widthMatch.addListener(handleMatchBreakPoint);
      return widthMatch;
    });

    return () => {
      listeners.forEach((l) => l.removeListener(handleMatchBreakPoint));
    };
  }, []);

  return vw;
}
