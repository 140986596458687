import { Fab, useMediaQuery } from "@material-ui/core";
import {
  Assignment,
  BugReport,
  CallSplit,
  CardGiftcard,
  Event,
  FlagOutlined,
  Group,
  RateReview,
  Restaurant,
  RoomService,
  Settings,
  ShoppingBasket,
} from "@material-ui/icons";
import classNames from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IGetMe } from "src/models";
import { BREAK_POINTS } from "src/styles";
import { useStyles } from "./container.styles";

interface IProps {
  data: { getMe: IGetMe; loading: boolean; error: any };
}

export const NavbarContainer: FunctionComponent<IProps> = ({ data }) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  const path = location.pathname;
  const [open, setOpen] = useState(!isMobile);

  const { getMe } = data;

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);

  const adminTabs = [
    {
      path: "/admin/members",
      name: "Members",
      icon: <Group className={classes.navItemIcon} />,
      show: true,
    },
    {
      path: "/admin/applications",
      name: "Applications",
      icon: <Assignment className={classes.navItemIcon} />,
      show: true,
    },
    {
      path: "/admin/restaurants",
      name: "Restaurants",
      icon: <Restaurant className={classes.navItemIcon} />,
      show: true,
    },
    {
      path: "/admin/events",
      name: "Events",
      icon: <Event className={classes.navItemIcon} />,
      show: true,
    },
    {
      path: "/admin/reviews",
      name: "Reviews",
      icon: <RateReview className={classes.navItemIcon} />,
      show: true,
    },
    {
      path: "/admin/coupons",
      name: "Coupons",
      icon: <CardGiftcard className={classes.navItemIcon} />,
      show: true,
    },
    {
      path: "/admin/referrals",
      name: "Referrals",
      icon: <CallSplit className={classes.navItemIcon} />,
      show: true,
    },
    {
      path: "/admin/dishes",
      name: "Dishes",
      icon: <RoomService className={classes.navItemIcon} />,
      show: true,
    },
    {
      path: "/admin/allergens",
      name: "Allergens",
      icon: <BugReport className={classes.navItemIcon} />,
      show: true,
    },
    {
      path: "/admin/kpi",
      name: "KPIs",
      icon: <Settings className={classes.navItemIcon} />,
      show: true,
    },
    {
      path: "/admin/marketing",
      name: "Marketing",
      icon: <ShoppingBasket className={classes.navItemIcon} />,
      show: true,
    },
    {
      path: "/admin/banner",
      name: "Banner",
      icon: <FlagOutlined className={classes.navItemIcon} />,
      show: true,
    },
  ];

  return (
    <>
      <Fab
        variant="extended"
        color="primary"
        onClick={() => setOpen(!open)}
        className={classes.menuBtn}
      >
        Menu
      </Fab>
      <div
        className={classes.container}
        style={{
          display: open ? "unset" : "none",
        }}
      >
        {adminTabs.map(
          (tab) =>
            tab.show && (
              <li
                key={tab.name}
                className={classNames(
                  classes.navItem,
                  path === tab.path ? classes.active : {}
                )}
                onClick={() => history.push(tab.path)}
              >
                {tab.icon}
                {tab.name}
              </li>
            )
        )}
      </div>
    </>
  );
};
