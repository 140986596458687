import { SvgIcon } from "@material-ui/core";
import React from "react";

export const CookProfile = (props) => (
  <SvgIcon {...props}>
    <g id="Layer_2">
      <g>
        <g>
          <path
            d="M19.04,4.17c-0.96-0.83-2.22-1.24-3.49-1.15C14.47,1.17,12.46,0,10.33,0C8.2,0,6.23,1.15,5.12,3.02
				C3.85,2.93,2.6,3.35,1.64,4.18C0.6,5.08,0,6.38,0,7.74c0,1.95,1.16,3.67,2.93,4.37v7.69c0,0.52,0.39,0.91,0.92,0.91h12.97
				c0.51,0,0.92-0.41,0.92-0.91v-7.7c1.77-0.75,2.93-2.46,2.93-4.36C20.67,6.37,20.08,5.07,19.04,4.17z M15.86,18.88H4.81v-1.01
				h11.05V18.88z M16.6,10.51c-0.44,0.09-0.74,0.46-0.74,0.91v4.61H4.81v-4.61c0-0.45-0.3-0.82-0.73-0.9
				c-1.3-0.29-2.25-1.45-2.25-2.78c0-0.86,0.4-1.66,1.08-2.21c0.7-0.56,1.63-0.77,2.53-0.57c0.39,0.1,0.83-0.12,1.04-0.54
				C7.15,2.9,8.7,1.88,10.33,1.88c1.64,0,3.19,1.02,3.87,2.57c0.21,0.41,0.67,0.61,1.06,0.46c0.9-0.19,1.78,0.02,2.48,0.58
				c0.7,0.56,1.09,1.38,1.09,2.25C18.83,9.05,17.91,10.2,16.6,10.51z"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);
