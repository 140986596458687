import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { BREAK_POINTS } from "src/styles";

interface EventInfoSectionProps {
  title?: string | React.ReactNode;
  icon: React.ReactNode;
  titleStyles?: React.CSSProperties;
}

export const EventInfoSection: React.FC<EventInfoSectionProps> = ({
  title,
  icon,
  titleStyles,
  children,
}) => {
  const classes = useStyles();
  return (
    <section className={classes.container}>
      <div className={classes.iconWrapper}>{icon}</div>
      <div>
        <Typography
          variant="h4"
          component="h2"
          style={Object.assign({}, titleStyles)}
          className={classes.title}
        >
          {title}
        </Typography>
        {children}
      </div>
    </section>
  );
};

const useStyles = makeStyles({
  container: {
    display: "grid",
    gridTemplateColumns: "30px 1fr",
    gridGap: "1rem",
    margin: "0 auto",
    maxWidth: 600,
    width: "100%",
    padding: "1rem 0.5rem",
  },
  iconWrapper: {
    gridColumn: "1 / 2",
    textAlign: "center",
  },
  title: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    marginBottom: "0.5rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginBottom: 0,
    },
  },
  description: {
    fontWeight: 100,
  },
});
