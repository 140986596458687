import { useLazyQuery } from "@apollo/client";
import {
  InputAdornment,
  makeStyles,
  TextFieldProps as MUITextFieldProps,
} from "@material-ui/core";
import { Room } from "@material-ui/icons";
import { parse, stringify } from "query-string";
import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AutoCompleteContainer } from "src/components";
import { COLORS } from "src/styles";
import { JsonHelper, LocalStorageHelper } from "src/utils/helpers";
import { GET_ADDRESS } from "../api/queries";
import { ListMapContext } from "../container";
import { ActionTypes } from "../reducer";

interface IProps {
  TextFieldProps?: MUITextFieldProps;
}

export const MapSearchBar: React.FC<IProps> = React.memo(
  ({ TextFieldProps }) => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const {
      state: { map, zoom },
      dispatch,
    } = useContext(ListMapContext);

    const [fetch, { data, loading }] = useLazyQuery<any>(GET_ADDRESS);

    useEffect(() => {
      if (map) {
        const params = parse(location.search) as {
          location: string;
          longitude: string;
          latitude: string;
        };

        // User has location data in the URL params
        if (params.location || (params.longitude && params.latitude)) {
          LocalStorageHelper.setItem(
            "FOODNOME_LOCATION_PREFERENCE",
            JSON.stringify({
              ...(params.longitude && {
                longitude: Number(params.longitude),
              }),
              ...(params.latitude && {
                latitude: Number(params.latitude),
              }),
              ...(!params.longitude && !params.latitude
                ? { location: params.location }
                : null),
            })
          );

          getLocation(params);
        } else {
          const raw = window.localStorage.getItem(
            "FOODNOME_LOCATION_PREFERENCE"
          );
          if (!!raw) {
            const res = JsonHelper.Parse(raw, () => null) as any;
            getLocation(res);
            history.replace({ search: stringify({ ...res }) });
          }
        }
      }
    }, [map, location]);

    useEffect(() => {
      if (data?.getGoogleGeoCode && !loading) {
        dispatch({
          type: ActionTypes.SET_MAP_POSITION,
          payload: {
            center: data.getGoogleGeoCode.results[0].geometry.location,
          },
        });
        map.setCenter(data.getGoogleGeoCode.results[0].geometry.location);
        map.setZoom(zoom);
      }
    }, [loading, data]);

    const getLocation = (params: {
      location: string;
      longitude: string;
      latitude: string;
    }) => {
      if (params.location) {
        fetch({ variables: { input: params.location } });
      } else if (params.latitude && params.longitude) {
        const center = {
          lat: Number(params.latitude),
          lng: Number(params.longitude),
        };

        dispatch({
          type: ActionTypes.SET_MAP_POSITION,
          payload: { center },
        });
        map.setCenter(center);
        map.setZoom(zoom);
      }
    };

    return (
      <AutoCompleteContainer
        useGeoLocation={true}
        onKey={(k) => null}
        onSelect={(l) =>
          history.push({
            pathname: "/restaurants",
            search: stringify({
              ...(l.longitude && {
                longitude: Number(l.longitude),
              }),
              ...(l.latitude && {
                latitude: Number(l.latitude),
              }),
              ...(!l.longitude && !l.latitude ? { location: l } : null),
            }),
          })
        }
        suggestionStyle={{
          zIndex: 10,
        }}
        truncateSuggestion={false}
        TextFieldProps={{
          variant: "outlined",
          fullWidth: true,
          ...(TextFieldProps as MUITextFieldProps),
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">
                <Room className={classes.locationIcon} />
              </InputAdornment>
            ),
            placeholder: `Search a location`,
            id: "restaurant-list-autocomplete-input",
          },
        }}
      />
    );
  }
);

export const useStyles = makeStyles({
  locationIcon: {
    color: COLORS.MEDIUM_GREEN,
    fontSize: "2.5rem",
  },
});
