import { useMutation, useQuery } from "@apollo/client";
import { Button, Drawer, OutlinedInput, Typography } from "@material-ui/core";
import { FileCopy, Link } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import {
  CustomEmailIcon,
  CustomFacebookIcon,
  CustomSmsIcon,
  CustomTwitterIcon,
  EmailShareButton,
  FacebookShareButton,
  SMSShareButton,
  TwitterShareButton,
} from "src/components";
import { sharedAPI } from "src/shared-graphql";
import { SET_SHARE_DRAWER } from "src/shared-graphql/mutations";
import { GET_SHARE_DRAWER_CONFIG } from "src/shared-graphql/queries";
import { useStyles } from "./styles";

export const ShareDrawer: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [closeDrawer] = useMutation(SET_SHARE_DRAWER);
  const { data, error, loading } = useQuery(GET_SHARE_DRAWER_CONFIG);
  const inputRef = React.useRef<any>();

  useEffect(() => {
    if (data && data.getShareDrawerConfig) {
      setOpen(true);
    } else setOpen(false);
  }, [data]);

  function onClickCopy() {
    inputRef.current.select();
    document.execCommand("copy");
    sharedAPI.setSnackbarMsg({ msg: "Copied to clipboard!", type: "success" });
  }

  if (error || loading) return null;

  const {
    link,
    drawerTitle,
    twitter,
    facebook,
    email,
    whatsApp,
  } = data?.getShareDrawerConfig || {
    link: "",
    drawerTitle: "",
    twitter: {
      description: "",
      tags: [],
    },
    facebook: { description: "", tag: "" },
    email: {
      subject: "",
      body: "",
    },
    whatsApp: { description: "" },
  };

  return (
    <Drawer
      BackdropProps={{ ["data-testid"]: "ShareDrawer-backdrop" } as any}
      data-testid="ShareDrawer-drawer"
      onClose={closeDrawer}
      aria-describedby="ShareDrawer-title"
      anchor="bottom"
      open={open}
    >
      <div className={classes.innerDrawer}>
        <Typography
          variant="h2"
          component="h2"
          className={classes.title}
          id="ShareDrawer-title"
        >
          {drawerTitle}
        </Typography>
        <div className={classes.drawerBottom}>
          <div className={classes.shareLinkRow}>
            <Typography
              component="label"
              variant="caption"
              className={classes.shareLinkLabel}
              htmlFor="copy-url-input"
            >
              Share link
            </Typography>
            <div style={{ display: "flex" }}>
              <OutlinedInput
                startAdornment={<Link />}
                labelWidth={0}
                inputProps={{ id: "copy-url-input" }}
                type="text"
                readOnly
                value={link}
                inputRef={inputRef}
                classes={{
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                  root: classes.inputRoot,
                }}
              />
              <Button
                classes={{ contained: classes.copyButton }}
                variant="contained"
                size="small"
                onClick={onClickCopy}
                aria-label="copy share link"
              >
                <FileCopy />
              </Button>
            </div>
          </div>
          <div className={classes.shareListWrapper}>
            <ul className={classes.shareList}>
              <li>
                <FacebookShareButton
                  quote={facebook.description}
                  hashtag={facebook.tag ? "#" + facebook.tag : undefined}
                  url={link}
                >
                  <CustomFacebookIcon className={classes.icon} />
                </FacebookShareButton>
              </li>
              {/* <li>
                <FacebookMessengerShareButton
                  url={link}
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID as string}
                >
                  <FacebookMessengerIcon className={classes.icon} />
                </FacebookMessengerShareButton>
              </li> */}
              <li>
                <TwitterShareButton
                  text={twitter.description}
                  hashtags={twitter.tags}
                  url={link}
                >
                  <CustomTwitterIcon className={classes.icon} />
                </TwitterShareButton>
              </li>
              <li>
                <EmailShareButton
                  separator=" "
                  subject={email.subject}
                  body={email.body}
                  url={link}
                >
                  <CustomEmailIcon className={classes.icon} />
                </EmailShareButton>
              </li>
              <li>
                <SMSShareButton url={link} separator=" " body={email.body}>
                  <CustomSmsIcon className={classes.icon} />
                </SMSShareButton>
              </li>
              {/* <li>
                <WhatsappShareButton
                  separator=" "
                  title={whatsApp.description}
                  url={link}
                >
                  <WhatsappIcon className={classes.icon} />
                </WhatsappShareButton>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
