import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "src/styles";

export const useStyles = makeStyles((theme) => ({
  container: {},
  emptyBagContainer: {
    paddingTop: 20,
    color: "#AEAEAE",
    textAlign: "center",
    height: "180px",
  },
  yourOrder: {
    padding: "1rem 0",
    backgroundColor: "#F9F9F9",
    textAlign: "center",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  eventName: {
    padding: "1rem 16px",
    backgroundColor: COLORS.WHITE,
    textAlign: "center",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  yourOrderEdit: {
    height: 61,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: 500,
    fontFamily: "CustomFourBold",

    backgroundColor: "#ffd81b",
  },
  cancelEditBtn: {
    fontFamily: "CustomFourBold",

    color: COLORS.WHITE,
    marginRight: 4,
  },
  emptyBag: {
    width: "100px",
    height: "103px",
    margin: "10px auto",
  },
  itemsContainer: {
    backgroundColor: COLORS.WHITE,
    borderBottom: "1px solid #E2E2E2",
    padding: "18px",
  },
  orderItem: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "4px",

    fontWeight: 300,

    margin: 0,
  },
  orderItemLeft: {
    display: "flex",
  },
  count: {
    fontWeight: "bold",
  },
  dishPriceWrapper: {
    display: "flex",
    alignItems: "center",
  },
  delete: {
    ...theme.typography.body1,
    color: "grey",

    cursor: "pointer",
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: "1rem",
  },
}));
