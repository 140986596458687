import { Tab, Tabs, withStyles } from "@material-ui/core";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { COLORS } from "src/styles/colors";
import { useStyles } from "./styles";

interface ITab {
  label: string;
  link: string;
  icon: any;
  cookDashboard?: boolean;
}

interface IProps {
  location: any;
  tabs: ITab[];
  cookDashboard?: boolean;
}
/**
 * @deprecated old abstraction which was rewritten. Use SidebarNavigation instead.
 */
export const ProfileNavbarLeft: FC<IProps> = ({
  location,
  tabs,
  cookDashboard,
}) => {
  const classes = useStyles();
  const isDinerDashboard = location.pathname.match(/^\/d/) !== null;

  // needs to match /menus/create to make events tab highlighted
  let value = tabs.findIndex((tab) => {
    return tab.link.includes(location.pathname.split("/")[2]);
  });
  value = value < 0 ? 0 : value;

  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        {isDinerDashboard ? (
          <img
            alt="diner icon."
            src={require(`src/assets/icons/serve_icon-03.png`)}
            className={classes.dinerIcon}
            data-e2e="diner-dashboard-navbar-icon"
          />
        ) : (
          <img
            className={classes.cookIcon}
            src={require(`src/assets/icons/create_host_icon-03-03.png`)}
            alt="cook icon."
            data-e2e="cook-dashboard-navbar-icon"
          />
        )}
      </div>
      <VerticalTabs value={value}>
        {tabs.map((tab, idx) => (
          <MyTab
            data-e2e={`dashboard-nav-${tab.label}`}
            key={tab.link}
            value={idx}
            label={tab.label}
            disableRipple
            fullWidth
            icon={tab.icon}
            {...({
              to: {
                pathname: tab.link,
                state: { value: idx },
              },
              component: Link,
            } as any)}
          />
        ))}
      </VerticalTabs>
    </div>
  );
};

export const VerticalTabs = withStyles((theme) => ({
  root: {
    width: "100%",
    boxShadow: "0 8px 13px -10px rgba(0,0,0,0.42)",
    borderTop: "1px solid rgba(0,0,0,0.42)",
  },
  flexContainer: {
    flexDirection: "column",
  },
  indicator: {
    display: "none",
  },
}))(Tabs);

export const MyTab = withStyles((theme) => ({
  root: {
    boxShadow: "0 8px 13px -10px rgba(0,0,0,0.42)",
    height: 80,
  },
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  labelContainer: {
    width: "50%",
  },
  selected: {
    backgroundColor: "rgb(236,241,244)",
    color: COLORS.RED,
    boxShadow: "none",
  },
}))(Tab);
