import React from "react";
import { IEvent, IUser } from "src/models";
import { ITimeSlotWithGuests } from "../helpers";
import { TimeSlot } from "./time-slot";

interface IProps {
  timeSlotsWithGuests: ITimeSlotWithGuests[];
  isSearchActive: boolean;
  onClickUser: (user: IUser) => void;
  getEvent: IEvent;
}

export const TimelineView: React.FC<IProps> = ({
  timeSlotsWithGuests,
  isSearchActive,
  onClickUser,
  getEvent,
}) => {
  return (
    <div data-testid="timeline-view">
      {timeSlotsWithGuests.map((data, index) => (
        <TimeSlot
          event={getEvent}
          key={index}
          guests={data.guests}
          timeSlot={data.timeSlot}
          isSearchActive={isSearchActive}
          onClickUser={onClickUser}
        />
      ))}
    </div>
  );
};
