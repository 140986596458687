import { Button, Typography } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import React, { FC, useEffect } from "react";
import { IEvent } from "src/models";
import { amplitude } from "src/services";
import { ActionTypes } from "../../order-reducer";
import { selectSeatStyles as useStyles } from "./select-seat.styles";

interface IProps {
  seats: number;
  dispatch: any;
  getEvent: IEvent;
}

export const SelectSeat: FC<IProps> = ({ seats, dispatch, getEvent }) => {
  const classes = useStyles();

  useEffect(() => {
    if (getEvent.type.includes("FIXED_TIME")) {
      dispatch({
        type: ActionTypes.SET_DINE_OPTION,
        payload: getEvent.type[0],
      });
    }
  }, [getEvent.id]);

  return (
    <div className={classes.container}>
      <Typography variant="body2" component="p" className={classes.popPrice}>
        <span className={classes.popup}>POP-UP</span>{" "}
        <span className={classes.priceSeat}>${getEvent.price} / seat</span>
      </Typography>
      <div className={classes.actionContainer}>
        <Button
          data-testid="subtract-seats-button"
          variant="outlined"
          classes={{
            outlined: classes.clickBtn,
            label: classes.clickBtnLabel,
          }}
          disabled={seats === 1}
          onClick={() => {
            amplitude.getInstance().logEvent("[Event Detail] set seats");
            dispatch({ type: ActionTypes.SET_SEATS, payload: seats - 1 });
          }}
        >
          <Remove />
        </Button>
        <div className={classes.seat}>
          <Typography variant="body2" component="span">
            {seats > 1 ? "Seats: " : "Seat: "}
            <span className={classes.seatNumber}>{seats}</span>
          </Typography>
        </div>
        <Button
          data-testid="add-seats-button"
          variant="outlined"
          classes={{
            outlined: classes.clickBtn,
            label: classes.clickBtnLabel,
          }}
          disabled={
            seats > getEvent.seatsLeft || (getEvent.price === 0 && seats >= 1)
          }
          onClick={() => {
            amplitude.getInstance().logEvent("[Event Detail] edit seat");
            dispatch({ type: ActionTypes.SET_SEATS, payload: seats + 1 });
          }}
        >
          <Add />
        </Button>
      </div>
    </div>
  );
};
