import { ListItem, Typography } from "@material-ui/core";
import React from "react";
import { ActionDialog } from "src/components";
import { useStyles } from "./button.styles";

export const LoginButton: React.FC = () => {
  const classes = useStyles();

  return (
    <ActionDialog view="LOGIN">
      <ListItem
        button
        component="a"
        classes={{ root: classes.noBorderMenuItem }}
        data-e2e="header-login"
        style={{ minWidth: 90 }}
      >
        <Typography
          variant="caption"
          component="span"
          style={{ fontFamily: "CustomFourBold" }}
        >
          Log in
        </Typography>
      </ListItem>
    </ActionDialog>
  );
};
