import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    backgroundColor: COLORS.MEDIUM_RED,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      position: "relative",
    },
  },
  innerContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    margin: "6rem auto",
    marginBottom: "4rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      margin: "5rem auto",
      marginBottom: "3rem",
    },
  },

  squiggleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
  },
  squiggle: {
    filter: "brightness(0) invert(1)",
    width: "60%",
    minWidth: "12rem",
    maxWidth: "18rem",
  },
  seeMoreLink: {
    color: COLORS.WHITE,
  },
  title: {
    fontFamily: "CustomFourBold",
    color: COLORS.WHITE,
    textAlign: "center",
  },

  headerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "3rem",
  },
  linkContainer: {
    width: "100%",
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "repeat(6, 1fr)",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      gridTemplateRows: "repeat(2 1fr)",
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  link: {
    textDecoration: "none",
    margin: "auto",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "40%",
      margin: "auto",
    },
  },

  eater: {
    width: "12rem",
    margin: "2.5rem 3rem",
    zIndex: 1,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      margin: "0.8rem 0.4rem",
    },
  },
  spectrum: {
    width: "12rem",
    margin: "2.5rem 3rem",
    zIndex: 1,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      margin: "0.8rem 0.4rem",
    },
  },
  npr: {
    width: "12rem",
    margin: "2.5rem 3rem",
    zIndex: 1,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      margin: "0.8rem 0.4rem",
    },
  },
  abc: {
    width: "9rem",
    margin: "2.5rem 3rem",
    zIndex: 1,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      margin: "0.8rem 0.4rem",
    },
  },
  nytimes: {
    width: "18rem",
    margin: "2.5rem 3rem",
    zIndex: 1,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      height: "auto",
      margin: "0.8rem 0.4rem",
    },
},
  civilEats: {
    width: "12rem",
    margin: "2.5rem 3rem",
    zIndex: 1,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      margin: "0.8rem 0.4rem",
    },
  },
  wsj: {
    width: "8.6rem",
    margin: "2.5rem 3rem",
    zIndex: 1,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "100%",
      margin: "0.8rem 0.4rem",
    },
  },
  cucumber: {
    position: "absolute",
    bottom: "-44%",
    right: -110,
    width: "17%",
    maxWidth: "14rem",
    minWidth: "6rem",
    zIndex: 5,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      right: -17,
      bottom: "-30%",
    },
  },
});
