import { useQuery } from "@apollo/client";
import {
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TopBar } from "src/components";
import { ChatNotificationBadge } from "src/components/bottom-nav/diner/chat-notification-badge";
import { GET_MOBILE_HEADER_TITLE } from "src/shared-graphql/queries";
import { BREAK_POINTS } from "src/styles";
import { checkBlackList } from "./config";
import { useStyles } from "./styles";

export const MobileHeader = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();

  const { data } = useQuery(GET_MOBILE_HEADER_TITLE) as any;
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

  const isBlackListed = checkBlackList(location.pathname);

  if (!isMobile || isBlackListed) return null;

  return (
    <TopBar className={classes.container} color="default">
      <Toolbar className={classes.innerContainer}>
        {location.pathname.split("/").length > 3 && (
          <IconButton
            aria-label="back"
            onClick={
              data.mobileHeaderTitle && data.mobileHeaderTitle.pathname
                ? () =>
                    history.push(data.mobileHeaderTitle.pathname, {
                      from: location.pathname,
                    })
                : () => history.goBack()
            }
          >
            <ChevronLeft className={classes.backArrow} />
          </IconButton>
        )}
        {data.mobileHeaderTitle && data.mobileHeaderTitle.title ? (
          <Typography
            variant="h3"
            component="h3"
            className={classes.headerText}
          >
            {data.mobileHeaderTitle && data.mobileHeaderTitle.title}
          </Typography>
        ) : (
          <img
            alt="cook connect logo"
            className={classes.foodnomeLogo}
            src={require(`src/assets/logos/main_header_logo.png`)}
          />
        )}

        <IconButton aria-label="chat" onClick={() => history.push("/chat")}>
          <ChatNotificationBadge />
        </IconButton>
      </Toolbar>
    </TopBar>
  );
};
