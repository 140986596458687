import { Lottie } from "@crello/react-lottie";
import { makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { FC } from "react";
import { BREAK_POINTS, COLORS } from "src/styles";
import { HybridLink } from "src/components";

const howItWorksDiner = [
  {
    title: "Browse",
    option: {
      loop: true,
      autoplay: true,
      animationData: require("src/assets/lottie/browse.json"),
    },
    text:
      "Search for homemade meals near you. All of our cooks are permitted and vetted for utmost safety & quality.",
  },
  {
    title: "Order",
    option: {
      loop: true,
      autoplay: true,
      animationData: require("src/assets/lottie/order.json"),
    },
    text:
      // tslint:disable-next-line:max-line-length
      "Add dishes to your order and checkout in just a few steps. Full address is disclosed after order is completed.",
  },
  {
    title: "Enjoy!",
    option: {
      loop: true,
      autoplay: true,
      animationData: require("src/assets/lottie/enjoy.json"),
    },
    text:
      "Eat fresh, authentic food made by permitted cooks in your neighborhood!",
  },
];

const howItWorksCook = [
  {
    title: "Register",
    text:
      "Fill out the COOK Academy interest form to get started! We’ll ask you a few simple questions & reach out to you if you qualify for the program.",
    image: require("src/screens/how-it-works/assets/cook/cook-icons-08.png"),
  },
  {
    title: "Get Approved",
    text:
      "COOK Academy will walk you through the food safety certification and permitting process. Requirements vary by county.",
    image: require("src/screens/how-it-works/assets/cook/cook-icons-06.png"),
  },
  {
    title: "Start Cooking! ",
    text:
      "Graduates of COOK Academy gain access to COOK Connect. Get paid doing what you love.",
    image: require("src/screens/how-it-works/assets/cook/cook-icons-07.png"),
  },
];

interface IProps {
  isDiner: boolean;
}

export const Cards: FC<IProps> = ({ isDiner }) => {
  const classes = useStyles();
  return (
    <section
      className={classNames(classes.dinnerCardsContainer, classes.flexRow)}
    >
      {isDiner
        ? howItWorksDiner.map((card) => (
            <div key={card.title} className={classes.card}>
              <Lottie
                config={card.option}
                style={{ height: "200px", width: "200px", margin: "0 auto" }}
              />
              <div className={classes.cardTextContainer}>
                <Typography
                  component="h3"
                  variant="h3"
                  className={classes.title}
                >
                  {card.title}
                </Typography>
                <Typography  component="a"  variant="body2">
                  {card.text}
                </Typography>
              </div>
            </div>
          ))
        : howItWorksCook.map((card) => (
            <div key={card.title} className={classes.card}>
              <img
                src={card.image}
                alt="Foodnome cook icon"
                style={{
                  height: "200px",
                  width: "200px",
                  margin: "0 auto",
                  marginTop: "1rem",
                }}
              />
              <div className={classes.cardTextContainer}>
                <Typography
                  component="h3"
                  variant="h3"
                  className={classes.title}
                >
                  {card.title}
                </Typography>
                <Typography component="p" variant="body2">
                  {card.text}
                </Typography>
              </div>
            </div>
          ))}
    </section>
  );
};

export const useStyles = makeStyles({
  flexRow: {
    display: "flex",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  dinnerCardsContainer: {
    padding: "10rem 0",
    paddingTop: "8rem",
    justifyContent: "center",
    flexWrap: "wrap",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "4rem 0",
    },
  },
  card: {
    maxWidth: "32rem",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "repeat(2, minmax(100px, min-content))",
    boxShadow: "0 0 20px 0 rgba(0,0,0,0.17)",
    borderRadius: "1rem",
    margin: "0 3rem",
    marginTop: "4rem",
  },
  cardTextContainer: {
    padding: "4rem",
    paddingTop: "2rem",
    textAlign: "center",
  },
  title: {
    marginBottom: "1rem",
    color: COLORS.MEDIUM_RED,
  },
});
