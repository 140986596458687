import { useQuery } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GET_MY_PENDING_RESTAURANT_REVIEWS } from "src/components/review-request/api/queries";
import { LocalStorageHelper } from "src/utils/helpers";

export const ReviewPrompt: React.FC = React.memo(() => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const { data, loading, error } = useQuery(GET_MY_PENDING_RESTAURANT_REVIEWS);

  useEffect(() => {
    // Only show on NOT /reviews, after 24 hours, pending reviews > 0
    const reviewPromptTime = localStorage.getItem("REVIEW_PROMPT") ?? 0;
    const now = moment().unix();

    if (
      data?.getMyPendingRestaurantReviews?.length > 0 &&
      location.pathname !== "/review" &&
      now > reviewPromptTime
    ) {
      setOpen(true);
    }
  }, [data?.getMyPendingRestaurantReviews]);

  const handleClose = () => {
    LocalStorageHelper.setItem(
      "REVIEW_PROMPT",
      JSON.stringify(moment().add(1, "days").unix())
    );
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
      disableBackdropClick
    >
      <DialogContent className={classes.contentContainer}>
        <Typography variant="h3" component="h3" className={classes.title}>
          You have pending reviews!
        </Typography>
        <Typography variant="body1" component="p">
          Show appreciation to independent cooks by leaving honest and positive
          reviews.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actionsContainer}>
        <Button onClick={handleClose} color="primary">
          Remind me later
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
            history.push("/review");
          }}
          variant="contained"
          color="secondary"
        >
          Leave reviews
        </Button>
      </DialogActions>
    </Dialog>
  );
});

const useStyles = makeStyles({
  actionsContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginBottom: "1rem",
  },
  title: {
    marginBottom: "2rem",
  },
});
