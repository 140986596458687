import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { getEnv } from "src/utils/helpers";

declare let window: any;

export const useRecaptcha = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const executeGoogleRecaptcha = async (name: string) => {
    const env = getEnv() as string;
    if (window.cordova) {
      return null;
    } else if (
      env !== "test" &&
      (process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY as string)
    ) {
      if (!executeRecaptcha) {
        console.warn("Execute recaptcha function not defined");
        // Returning this will send "error" to the server and fail the recaptcha
        return "error";
      }
      const captchaToken = await executeRecaptcha(name);

      return captchaToken;
    }
    return null;
  };

  return executeGoogleRecaptcha;
};
