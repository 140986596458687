import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import ReactStars from "react-stars";
import { BREAK_POINTS, COLORS } from "src/styles";

const reviews = [
  {
    text:
      "WOW! Amazing food and great price for the quality and portions. Support your local chef and order from here!!",
    author: "Laura",
  },
  {
    text:
      "Wonderful!  Flavorful and aromatic and spicy as requested. Always a treat to open these containers!",
    author: "Michelle",
  },
  {
    text:
      "Fantastic. I wish i had more words. Wonderful food and great hospitality. Wish i could have it every night 👏",
    author: "Denise",
  },
];

export const Reviews = () => {
  const classes = useStyles();
  return (
    <section className={classes.reviewsContainer}>
      <div>
        <Typography variant="h2" component="h2" className={classes.title}>
          +2000 5 star reviews
          {/* Maybe make this dynamic and pull acutal amount */}
        </Typography>
      </div>
      <section className={classes.reviewCardContainer}>
        {reviews.map((review) => (
          <div key={review.text} className={classes.card}>
            <ReactStars
              edit={false}
              count={5}
              value={5}
              size={24}
              color2={"#FFB130"}
            />
            <Typography
              variant="body1"
              component="p"
              className={classes.reviewText}
            >
              {review.text}
            </Typography>
            <Typography
              variant="body1"
              component="p"
              style={{ paddingTop: "2rem" }}
            >
              - {review.author}
            </Typography>
          </div>
        ))}
      </section>
    </section>
  );
};

const useStyles = makeStyles({
  reviewsContainer: {
    width: "100%",
    // This gives us a "darker" background without having to add another layer over it :)
    background: `linear-gradient(
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.6)
      ), url(${require("src/screens/how-it-works/assets/Food_Background.jpg")})`,
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "8rem 0",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "5rem 0",
    },
  },
  landscapeImg: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  reviewCardContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  card: {
    display: "flex",
    flexDirection: "column",

    alignItems: "center",
    borderRadius: "1rem",
    backgroundColor: COLORS.WHITE,
    padding: "4rem",

    maxWidth: "35rem",
    textAlign: "center",
    margin: "0 2.5rem",
    marginTop: "2rem",
    boxShadow: "0 0 20px 0 rgba(0,0,0,0.17)",
  },
  title: {
    paddingBottom: "5rem",
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      paddingBottom: "2rem",
    },
  },
  reviewText: {
    fontFamily: "CustomFourBold",
    paddingTop: "2rem",

    // Text related styles to prevent overflow of text
    WebkitLineClamp: 4,
    lineClamp: 4,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    overflow: "hidden",
    hyphens: "auto",
    WebkitHyphens: "auto",
  },
});
