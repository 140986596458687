import IconButton from "@material-ui/core/IconButton";
import React, { FC } from "react";
import { objectToGetParams, openWindow } from "./functions";

interface IProps {
  children: any;
  url: string;
  separator?: string;
  subject?: string;
  body?: string;
}

type Options = {
  bodyPar?: string;
  separatorPar?: string;
  subjectPar?: string;
};

export const EmailShareButton: FC<IProps> = ({
  children,
  url,
  separator = "",
  subject = "",
  body = "",
}) => {
  const emailLink = (
    urlPar: string,
    { subjectPar, bodyPar, separatorPar }: Options
  ) => {
    return (
      "mailto:" +
      objectToGetParams(
        {
          subject: subjectPar,
          body: bodyPar ? bodyPar + separatorPar + urlPar : urlPar,
        },
        null
      )
    );
  };

  const _link = emailLink(url, {
    subjectPar: subject,
    bodyPar: body,
    separatorPar: separator,
  });

  const clickHandler = () => {
    // Won't work in emulator but it does work on device!
    openWindow(_link);
  };

  return (
    <IconButton aria-label="email share button" onClick={clickHandler}>
      {children}
    </IconButton>
  );
};
