import { useQuery } from "@apollo/client";
import {
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { ResponsiveDialog } from "src/components/dialog";
import { sharedAPI } from "src/shared-graphql";
import { GET_COVID_DIALOG_STATE } from "src/shared-graphql/queries";
import { BREAK_POINTS, COLORS } from "src/styles";

export const COVID19Dialog: React.FC = () => {
  const classes = useStyles();
  const { data } = useQuery(GET_COVID_DIALOG_STATE);

  const handleClose = () => sharedAPI.setCOVID19DialogState(false);

  return (
    <ResponsiveDialog
      open={Boolean(data && data.getCOVID19DialogState)}
      classes={{
        paperScrollPaper: classes.paperScrollPaper,
      }}
      fullWidth
      maxWidth="lg"
      aria-describedby="covid-19-dialog"
    >
      <IconButton
        onClick={handleClose}
        aria-label="close dialog"
        className={classes.closeButton}
      >
        <Close style={{ color: COLORS.MEDIUM_GREEN }} />
      </IconButton>

      <DialogContent className={classes.dialogBody}>
        <section>
          <div>
            <Typography
              variant="h1"
              component="h1"
              className={classes.dialogTitle}
              id="covid-19-dialog"
            >
              Foodnome puts food safety first.
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.paragraph}
            >
              Foodnome is the first and only marketplace for legal and safe home
              cooked meals in the US.{" "}
              <b>
                With the news of Novel Coronavirus (COVID-19), we have
                implemented measures further ensuring food safety and health
                among our home cook network.
              </b>
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.paragraph}
            >
              Nothing is more important to us than community health, and the
              safety of your food.{" "}
              <strong>
                To that end, the following are required for all Foodnome cooks:
              </strong>
            </Typography>
          </div>
        </section>
        <section className={classes.listContainer}>
          <div>
            <ul className={classes.list}>
              <li>
                <Typography
                  variant="h2"
                  component="h2"
                  className={classes.heading2}
                >
                  Intensive inspection & permitting process
                </Typography>
                <Typography variant="body2" component="p">
                  All Foodnome cooks have their kitchen and preparation
                  protocols reviewed, inspected, and approved by a panel of the
                  county’s top health officials.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="h2"
                  component="h2"
                  className={classes.heading2}
                >
                  Rigorous food safety trainings & certifications
                </Typography>
                <Typography variant="body2" component="p">
                  All Foodnome cooks have received Food Safety Manager
                  Certification—a higher standard than regular restaurant
                  workers.
                  <em>
                    *To address the virus, all of our cooks have received
                    additional specialized safety training.
                  </em>
                </Typography>
              </li>
              <li>
                <Typography
                  variant="h2"
                  component="h2"
                  className={classes.heading2}
                >
                  Communication & transparency
                </Typography>
                <Typography variant="body2" component="p">
                  At home restaurants, you meet the cook — the face of their
                  business. Home cooks prepare your meals with the same level of
                  care they have for their families. Our team is also in
                  constant communication with cooks, maintaining the standards
                  of our network.
                </Typography>
              </li>
            </ul>
          </div>
        </section>
        <section>
          <div>
            <Typography
              variant="body2"
              component="p"
              className={classes.paragraph}
            >
              <i>
                With thousands of meals sold, Foodnome cooks maintain a food
                safety track record of 100%
              </i>
              . With hard earned community trust backing us up, we will continue
              serving the most delicious food in your neighborhood.
            </Typography>
          </div>
        </section>
      </DialogContent>
    </ResponsiveDialog>
  );
};

const useStyles = makeStyles({
  dialogTitle: {
    maxWidth: 680,
    margin: "1rem 0",
    color: "#3B7340",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
  },
  paragraph: {
    margin: "1rem 0",
  },
  heading2: {
    margin: "1rem 0",
  },
  dialogBody: {
    padding: "0 0 2em",
    "& p": {
      fontWeight: 100,

      "& strong, b": {
        fontFamily: "CustomFourBold",
        fontWeight: 500,
      },
      "& i": {
        fontFamily: "CustomFourBold",
        fontWeight: 500,
        color: COLORS.RED,
        fontStyle: "normal",
      },
      "& em": {
        color: COLORS.RED,
        fontStyle: "normal",
      },
    },
    "& h2": {
      fontFamily: "CustomFourBold",
      fontWeight: 500,
    },
    "& section > div": {
      maxWidth: 680,
      width: "100%",
      margin: "0 auto",
      padding: "0 1em",
    },
  },
  paperScrollPaper: {
    borderRadius: 40,
    position: "relative",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      borderRadius: 0,
    },
  },
  listContainer: {
    backgroundColor: "#EBFFF3",
    padding: "1rem 0",
  },
  list: {
    paddingLeft: "1rem",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    left: 10,
    zIndex: 100,
    backgroundColor: COLORS.WHITE,
    boxShadow: `0 0 6px 0 rgba(0,0,0,0.15)`,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      position: "absolute",
      right: 10,
      left: "unset",
    },
  },
});
