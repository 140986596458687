import { useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { Lock } from "@material-ui/icons";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import classNames from "classnames";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { IGetMe } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { COOK_EDIT_PASSWORD } from "../../api/graphql";
import { useStyles } from "./password-dialog.styles";

interface IProps {
  getMe: IGetMe;
}

export const PasswordDialog: FC<IProps> = ({ getMe }) => {
  const classes = useStyles();
  const [editPassword] = useMutation(COOK_EDIT_PASSWORD);
  const initialState = {
    password: "",
    reenterPassword: "",
    showPassword: false,
  };
  const [values, setValues] = useState(initialState);
  const [passwordError, setPasswordError] = useState<string>("");
  const [open, setOpen] = useState<boolean>(
    Number(getMe.createdAt) > moment().subtract(30, "minutes").valueOf() &&
      !localStorage.getItem("foodnome_become_cook_intro_complete")
  );

  useEffect(() => {
    if (values.password.length < 6) {
      return setPasswordError("Password must be greater than 6 characters");
    }
    if (values.password !== values.reenterPassword) {
      return setPasswordError("Passwords do not match");
    }
    setPasswordError("");
  }, [values]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleShowPassword = () => {
    setValues((prev) => ({ ...prev, showPassword: !prev.showPassword }));
  };

  const handleSubmit = () => {
    editPassword({ variables: { input: values.reenterPassword } })
      .then(() =>
        sharedAPI.setSnackbarMsg({
          type: "success",
          msg: "Your password is saved!",
        })
      )
      .catch((err: any) =>
        sharedAPI.setSnackbarMsg({
          type: "error",
          msg: `Sorry, we could not save your password. ${err}`,
        })
      )
      .finally(() => setOpen(false));
  };

  return (
    <Dialog open={open}>
      <div className={classes.dialogPadding}>
        <div className={classNames(classes.flexCenter)}>
          <img
            alt="cook connect logo"
            className={classes.logo}
            width="64px"
            height="64px"
            src={require(`src/assets/logos/logo-icon.png`)}
          />
          <Typography
            variant="h2"
            component="h2"
            style={{ marginBottom: "2rem", fontFamily: "CustomFourBold" }}
          >
            Create Password
          </Typography>
          <Typography
            variant="body1"
            component="p"
            style={{ marginBottom: 20 }}
          >
            Set your password to easily keep track of your application!
          </Typography>
          <Typography
            component="label"
            variant="caption"
            className={classes.label}
            htmlFor="password"
          >
            Password
          </Typography>

          <FormControl>
            <TextField
              size="small"
              FormHelperTextProps={{
                classes: { root: classes.formHelperText },
              }}
              variant="outlined"
              placeholder="Password"
              id="password"
              name="password"
              data-e2e={"password" as any}
              type={values.showPassword ? "text" : "password"}
              onChange={handleChange}
              fullWidth
              inputProps={{ maxLength: 50 }}
              InputProps={{
                endAdornment: (
                  <>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleShowPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    <InputAdornment position="end">
                      <Lock style={{ color: COLORS.DARK_GREY }} />
                    </InputAdornment>
                  </>
                ),
                classes: {
                  input: classes.signUpInput,
                  root: classes.inputRoot,
                },
              }}
            />
            <Typography
              component="label"
              variant="caption"
              className={classes.label}
              htmlFor="reenterpassword"
            >
              Re-Enter Password
            </Typography>
            <TextField
              size="small"
              FormHelperTextProps={{
                classes: { root: classes.formHelperText },
              }}
              variant="outlined"
              placeholder="Re-Enter Password"
              id="reenterpassword"
              name="reenterPassword"
              data-e2e={"password" as any}
              type="password"
              onChange={handleChange}
              fullWidth
              inputProps={{ maxLength: 50 }}
              helperText={passwordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Lock style={{ color: COLORS.DARK_GREY }} />
                  </InputAdornment>
                ),
                classes: {
                  input: classes.signUpInput,
                  root: classes.inputRoot,
                },
              }}
            />
          </FormControl>
        </div>
        <DialogActions className={classNames(classes.flexCenter)}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.btnSm}
            disabled={Boolean(passwordError)}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
