import { Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import { IGetMe } from "src/models";
import { useStyles } from "./coming-soon.styles";

interface IProps {
  getMe?: IGetMe;
}

export const ComingSoon: React.FC<IProps> = ({ getMe }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent classes={{ root: classes.cardContent }}>
        <Typography variant="h2" component="h3" className={classes.header}>
          Hi {getMe?.firstName}!
        </Typography>
        <Typography component="p" variant="body1" gutterBottom>
          Welcome to your new home dashboard! Over the next few months, we will
          be adding features and intuitive data about your performance.
        </Typography>
        <Typography component="p" variant="body1">
          Please check back here later.
        </Typography>
      </CardContent>
    </Card>
  );
};
