import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  title: {
    marginBottom: "1rem",
    textAlign: "center",
  },
  dialogRoot: {
    borderRadius: "20px",
  },
  root: {
    padding: "0 !important",
    borderRadius: "20px",
    boxShadow: "0 4px 19px 0 rgba(31,64,80,0.35)",
  },
  container: {
    padding: "1em",
    backgroundColor: COLORS.WHITE,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontFamily: "CustomFourBold",

    textAlign: "center",
    width: 256,
    color: COLORS.DARK_BLUE,
    marginBottom: 30,
  },
  btnContainer: {
    maxWidth: 250,
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
  },
  btnContent: {
    fontFamily: "CustomFourBold",

    textAlign: "center",
  },
  error: {
    color: COLORS.RED,
    fontWeight: "bold",
  },
  spinner: {
    color: COLORS.WHITE,
  },
});
