import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  avatarWrapper: {
    position: "relative",
    borderRadius: "50%",
    overflow: "hidden !important",
  },
  editIcon: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  spinner: { color: COLORS.MEDIUM_GREEN },
  avatarEmpty: {
    backgroundColor: COLORS.WHITE,
    border: `dashed 2px ${COLORS.MEDIUM_GREEN}`,
  },
  avatar: {
    backgroundColor: COLORS.WHITE,
    border: "none",
  },
  uploadButton: {
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    backgroundColor: COLORS.MEDIUM_GREEN,
    borderRadius: 2,
    padding: "2px 4px",

    textAlign: "center",
    "&:hover": {
      opacity: 0.8,
      backgroundColor: COLORS.MEDIUM_GREEN,
    },
    "&:disabled": {
      backgroundColor: COLORS.LIGHT_GREY,
    },
  },
  imgRoot: {
    width: 126,
    height: 126,
    borderRadius: "50%",
    overflow: "hidden",
  },
});
