import { useQuery } from "@apollo/client";
import { useMediaQuery } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { ErrorLoadingComponent } from "src/components";
import { GetMyEventsPL, IEvent } from "src/models";
import { BREAK_POINTS } from "src/styles";
import { GET_DINER_EVENTS } from "../api/graphql";
import { NoItemsContainer } from "../no-items";
import { NoItemsContainer as MobileNoItems } from "../no-items/mobile/no-items";
import { getMobileScroll } from "./helpers";
import { Layout } from "./layout";

interface IProps {
  // Status is being passed into HOC as argument to getDinerEvents query
  // Past and Upcoming are additional arguments to fetch only past or upcoming events
  // Arguments are passed as props in in the routes file
  status?: "RESERVED" | "SAVED" | "VIEWED" | "CANCELED";
  title: string;
  upcoming?: boolean;
  past?: boolean;
}

interface MatchTypes {
  params?: any;
  type?: string;
}

export const DinerOrdersListContainer: FC<IProps> = ({
  status = "RESERVED",
  title,
  upcoming = false,
  past = false,
}) => {
  const match = useRouteMatch<MatchTypes>();
  const type = match.params.type ?? "";

  const location = useLocation();

  const [searchQuery, setSearchQuery] = useState({
    limit: "5" as string,
    orderBy: "createdAt" as "createdAt" | "arrivalTime",
  });

  const { data, loading, error, fetchMore } = useQuery<{
    getDinerEvents: IEvent[];
    error: string;
    loading: boolean;
  }>(GET_DINER_EVENTS, {
    variables: {
      input: {
        status,
        past: past ? past : /^past$/i.test(type),
        upcoming: upcoming ? upcoming : /^upcoming$/i.test(type),
        limit: 5,
      } as GetMyEventsPL,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true, // update loading state on refetch
  });

  useEffect(() => {
    fetchMore({
      variables: {
        input: {
          status,
          past: past ? past : /^past$/i.test(type),
          upcoming: upcoming ? upcoming : /^upcoming$/i.test(type),
          limit: Number(searchQuery.limit),
          orderBy: searchQuery.orderBy,
        } as GetMyEventsPL,
      },
      updateQuery: (prev: any, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          getDinerEvents: (fetchMoreResult as any).getDinerEvents,
        });
      },
    });
  }, [searchQuery]);

  const handleChange = (
    event: React.ChangeEvent<{ value: unknown; name: string }>
  ) => {
    const { name, value } = event.target;
    setSearchQuery((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  const scrollList = getMobileScroll(location.pathname, isMobile);

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  } else if (data?.getDinerEvents.length === 0)
    return isMobile ? (
      <MobileNoItems
        title={title}
        subText="Plan out your meals for today and the coming week!"
      />
    ) : (
      <NoItemsContainer
        title={
          location.pathname.includes("past") ? "Past Orders" : "Upcoming Orders"
        }
        subText="Plan out your meals for today and the coming week!"
        buttonText="Browse Menus"
        link="/menus"
      />
    );
  return (
    <Layout
      events={data?.getDinerEvents as IEvent[]}
      title={title}
      state={location.state}
      mobile={isMobile}
      scrollList={scrollList}
      handleChange={handleChange}
      searchQuery={searchQuery}
    />
  );
};
