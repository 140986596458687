import { makeStyles } from "@material-ui/core";
import React from "react";
import { CSSTransition } from "react-transition-group";
import { COLORS } from "src/styles";

interface ActionsOverlayProps {
  appear: boolean;
  containerProps?: object;
  onEntered?: () => void;
}

const useStyles = makeStyles({
  container: {
    zIndex: 3,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: `rgba(0, 0, 0, 0.9)`,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.WHITE,

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  overlayContent: {
    width: "100%",
    maxWidth: 200,
    margin: "0 auto",
    textAlign: "center",
  },
});

export const ActionsOverlay: React.FC<ActionsOverlayProps> = ({
  appear,
  containerProps = {},
  children,
  onEntered = () => ({}),
}) => {
  const classes = useStyles();
  return (
    <CSSTransition
      onEntered={onEntered}
      unmountOnExit
      classNames="appear"
      in={appear}
      timeout={300}
    >
      <div
        {...containerProps}
        className={classes.container}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.overlayContent}>{children}</div>
      </div>
    </CSSTransition>
  );
};
