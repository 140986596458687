export const getMobileScroll = (location: string, isMobile: boolean) => {
  const mobileHome = /^\/d\/?$/.test(location);
  return isMobile && mobileHome;
};

export const getStatus = (title: string, mobileTitle: string, events?: any) => {
  let target: string;
  if (title) {
    target = title;
  } else if (mobileTitle) {
    target = mobileTitle;
  } else if (events) {
    target = events[0].eventUser.status;
  } else {
    throw new Error("title, mobileTitle, and events are all undefined");
  }

  switch (target) {
    case "Upcoming Orders":
      return "UPCOMING";
    case "RESERVED":
      return "UPCOMING";
    case "Past Orders":
      return "PAST";
    case "Saved Menus":
      return "SAVED";
    case "SAVED":
      return "SAVED";
    default:
      return "UPCOMING";
  }
};
