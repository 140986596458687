import React, { useState } from "react";
import {
  CommentField,
  CommentItem,
  CommentList,
  LikeButton,
} from "react-activity-feed";
import { useStyles } from "./footer.styles";

export const Footer: React.FC<any> = React.memo(
  ({ hasSubActivity, activity, ...props }) => {
    const classes = useStyles();
    const [showComments, setShowComments] = useState(false);

    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 16px",
          }}
        >
          <button
            className={classes.commentLink}
            onClick={() => setShowComments(!showComments)}
          >
            {activity.reaction_counts.comment ?? "0"}{" "}
            {activity.reaction_counts.comment > 1 ? "comments" : "comment"}
          </button>

          <LikeButton
            activity={activity}
            onToggleReaction={props.onToggleReaction}
          />
        </div>
        {showComments && (
          <>
            <CommentField
              activity={activity}
              onAddReaction={props.onAddReaction}
            />
            <CommentList
              activityId={activity.id}
              activityPath={
                hasSubActivity ? [props?.activity?.id, "object"] : null
              }
              CommentItem={(_props: any) => (
                <React.Fragment>
                  <CommentItem {..._props} />
                </React.Fragment>
              )}
            />
          </>
        )}
      </React.Fragment>
    );
  }
);
