import { makeStyles } from "@material-ui/core";

export const useNotificationStyles = makeStyles({
  container: {},
  subContainer: {
    padding: 12,
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  subSubContainer: {
    display: "flex",
    alignItems: "top",
  },
  avatar: {},
  descriptionContainer: { marginLeft: 6 },
  heading: { margin: 0 },
  createdAt: {
    margin: "4px 0px 0px 0px",
  },
  actionBtnsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
  },
  iconBtn: {},
  icon: {
    width: 20,
    height: 20,
  },
});
