import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  DialogContent,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Close, Room } from "@material-ui/icons";
import React, { FC, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AutoCompleteContainer, ResponsiveDialog } from "src/components";
import { IEvent } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { GET_ME } from "src/shared-graphql/queries";
import { BREAK_POINTS, COLORS } from "src/styles";
import { OrderContext } from "../order-context";
import { ActionTypes } from "../order-reducer";
import { useGatherAddressDialogStyles } from "./gather-address-dialog.styles";
import { DINER_GET_DELIVERY_COST } from "./graphql/queries";

interface IProps {
  getEvent: IEvent;
  children?: any;
}

export const GatherAddressDialog: FC<IProps> = ({ children, getEvent }) => {
  const history = useHistory();
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  const [getDeliveryCost, { loading }] = useMutation(DINER_GET_DELIVERY_COST, {
    refetchQueries: [{ query: GET_ME }],
  });
  const classes = useGatherAddressDialogStyles();
  const [address, setAddress] = useState("");

  const {
    dispatch,
    state: { addressDialogStatus, addressDialogRedirect, total },
  } = useContext(OrderContext);

  const _getDeliveryCost = (d: any) => {
    return getDeliveryCost(d)
      .then((_d) => {
        dispatch({
          type: ActionTypes.SET_DELIVERY_COST,
          payload: _d.data.dinerGetDeliveryCost.cost,
        });
        dispatch({
          type: ActionTypes.SET_ADDRESS_DIALOG_STATUS,
          payload: { url: "" },
        });
        addressDialogRedirect && history.push(addressDialogRedirect);
      })
      .catch((e) =>
        sharedAPI.setSnackbarMsg({
          type: "error",
          msg: e.message.replace(/Graphql Error: /i, ""),
        })
      );
  };

  return (
    <>
      {children}
      <ResponsiveDialog
        fullWidth
        maxWidth="md"
        open={addressDialogStatus}
        onClose={() =>
          dispatch({
            type: ActionTypes.SET_ADDRESS_DIALOG_STATUS,
            payload: { url: "" },
          })
        }
        className={classes.dialog}
        classes={{
          paperWidthSm: classes.dialogPaperWidth,
          paperWidthMd: classes.dialogPaperWidth,
        }}
      >
        <IconButton
          aria-label="Close"
          onClick={() =>
            dispatch({
              type: ActionTypes.SET_ADDRESS_DIALOG_STATUS,
              payload: { url: "" },
            })
          }
          className={classes.iconBtn}
        >
          <Close className={classes.closeBtn} />
        </IconButton>

        {/* Need to give full height to Fixed_time event, 80% to non fixed event to allow scroll */}
        <DialogContent className={classes.dialogContent}>
          <Typography variant="h2" component="h2" className={classes.header}>
            Does this restaurant deliver to you?
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.subheader}
          >
            Before starting your order, tell us your address.
          </Typography>

          <Typography
            component="label"
            variant="caption"
            className={classes.label}
          >
            Address
          </Typography>
          <AutoCompleteContainer
            initialState={""}
            onSelect={setAddress}
            onKey={setAddress}
            TextFieldProps={{
              variant: "outlined",
              fullWidth: true,
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Room className={classes.icon} />
                  </InputAdornment>
                ),
                placeholder: `Enter your delivery address`,
                classes: {
                  input: classes.input,
                  root: classes.rootInputLeft,
                  notchedOutline: classes.notchedOutline,
                  inputAdornedStart: classes.inputAdornStart,
                  focused: classes.focused,
                },
                id: "header-address-autocomplete-input",
              },
            }}
          />

          <div className={classes.dialagActionsContainer}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth={isMobile}
              onClick={() =>
                _getDeliveryCost({
                  variables: {
                    input: { address, eventId: getEvent.id, total },
                  },
                })
              }
              style={{ color: COLORS.WHITE }}
            >
              {loading ? (
                <CircularProgress style={{ color: COLORS.WHITE }} size={22} />
              ) : (
                "Continue"
              )}
            </Button>
          </div>
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
};
