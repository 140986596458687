import { gql, useQuery } from "@apollo/client";
import { Badge, CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";
import { UserNotification } from "src/assets/icons/svg/user-notification";
import { COLORS } from "src/styles";

export const USER_GET_NOTIFICATION_COUNT = gql`
  query userGetNotificationCount {
    userGetNotificationCount
  }
`;

export const NotificationBadge = () => {
  const classes = useStyles();
  const { data, error, loading } = useQuery(USER_GET_NOTIFICATION_COUNT, {
    fetchPolicy: "no-cache",
  });

  if (error) return <UserNotification />;

  const badgeContent = loading ? (
    <CircularProgress
      data-testid="_-spinner"
      size={8}
      style={{ color: COLORS.WHITE }}
    />
  ) : (
    data.userGetNotificationCount
  );

  return (
    <Badge
      classes={{ anchorOriginTopRightRectangle: classes.circle }}
      showZero={false}
      badgeContent={badgeContent}
      color="primary"
    >
      <UserNotification />
    </Badge>
  );
};

const useStyles = makeStyles({
  circle: {
    transform: `translate(50%, -30%)`,
  },
});
