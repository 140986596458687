import { gql } from "@apollo/client";

export const DINER_REVIEW_RESPONSE = gql`
  mutation dinerRestaurantReviewResponseBT($input: DinerRestaurantReviewPL!) {
    dinerRestaurantReviewResponseBT(dinerReviewResponsePL: $input) {
      id
      quality
      accuracy
      hospitality
      text
      status
    }
  }
`;
