import { ListItem, Typography } from "@material-ui/core";
import React from "react";
import { ActionDialog } from "src/components";
import { useStyles } from "./button.styles";

export const SignUpButton: React.FC = () => {
  const classes = useStyles();
  return (
    <ActionDialog view="SIGNUP">
      <ListItem
        button
        component="a"
        data-e2e="header-sign-up"
        classes={{ root: classes.outlinedButton }}
      >
        <Typography
          variant="caption"
          component="span"
          style={{ fontFamily: "CustomFourBold" }}
        >
          Sign Up
        </Typography>
      </ListItem>
    </ActionDialog>
  );
};
