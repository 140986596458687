import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {},
  header: { fontFamily: "CustomFourBold", marginBottom: "1rem" },
  text: { fontFamily: "CustomFour", marginBottom: "2rem" },
  notBtn: {
    color: COLORS.MEDIUM_GREEN,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    textTransform: "none",
  },
  btnContainer: { justifyContent: "space-between" },
  btnLink: { textDecoration: "none" },
  btn: {
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    textTransform: "none",
  },
});
