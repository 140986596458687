import { useMediaQuery } from "@material-ui/core";
import { isEqual, sortBy } from "lodash";
import moment from "moment";
import React, {
  createContext,
  Dispatch,
  FC,
  useEffect,
  useReducer,
  useState,
} from "react";
import { IEvent } from "src/models";
import { getMenuItems } from "src/screens/event-detail/helpers";
import { BREAK_POINTS } from "src/styles";
import { JsonHelper, LocalStorageHelper } from "src/utils/helpers";
import { OrderExistDialog } from "./order-exist-dialog";
import {
  ActionTypes,
  ComponentActions,
  initialState,
  IState,
  orderReducer,
} from "./order-reducer";
import { PastEventDialog } from "./past-event-dialog";

const OrderContext = createContext<{
  state: IState;
  dispatch: Dispatch<ComponentActions>;
}>({} as any);

const OrderContextProvider: FC<{
  getEvent: IEvent;
}> = ({ getEvent, children }) => {
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

  const [state, dispatch] = useReducer(orderReducer, initialState);

  useEffect(() => {
    if (eventUser) {
      return dispatch({
        type: ActionTypes.SET_DIALOG_STATUS,
        payload: eventUser.status === "RESERVED",
      });
    }
  }, [isMobile]);

  useEffect(() => {
    let _state = localStorage.getItem(`foodnome-cart-${getEvent.id}`);

    // If the dish data stored in localstorage is different than the queried data, reset
    // localstorage. This can happen if a cook changes the dishes

    if (!!_state) {
      // Get the new queried event dish ids
      const getEventDishIds = getEvent.dishes.map(
        (getEventDish) => getEventDish.id
      ) as number[];

      // Get the dish ids stored in local storage
      const getLocalStorageDishIds = JsonHelper.Parse(_state, () =>
        localStorage.removeItem(`foodnome-cart-${getEvent.id}`)
      )?.orderedDishes.map(
        (getLocalStorageDish) => getLocalStorageDish.id
      ) as number[];

      // Check to make sure that the ids are the same in both arrays.
      // If not, reset the localstorage state for that event
      if (!isEqual(sortBy(getLocalStorageDishIds), sortBy(getEventDishIds))) {
        localStorage.removeItem(`foodnome-cart-${getEvent.id}`);
        _state = null;
      }
    }

    let _payload;
    // Dish have options as an array of name and addition
    // orderedDish in store has options as an array of name, addition and count
    // We need the N*N search

    if (_state && getEvent?.eventUser?.status !== "RESERVED") {
      _payload = JsonHelper.Parse(_state, () =>
        localStorage.removeItem(`foodnome-cart-${getEvent.id}`)
      )?.orderedDishes;
    } else {
      _payload = getEvent.dishes.map((d) => {
        const foundDish = getEvent?.eventUser?.orderedDishes
          ? getEvent?.eventUser?.orderedDishes.find((od) => od.id === d.id)
          : null;
        return foundDish
          ? {
              count: foundDish.EventUserDish.count,
              ...d,
              notes: getEvent.eventUser?.notes
                ?.split(";")
                .find((n) => n.includes(foundDish.name)),
              options: d.DishEvent?.options?.map((o) => {
                const _foundedEUD = foundDish.EventUserDish.options?.find(
                  (_o) => o.name === _o.name
                );
                return {
                  name: o.name,
                  count: _foundedEUD?.count ?? o.count,
                  addition: _foundedEUD?.addition ?? o.addition,
                };
              }),
            }
          : {
              count: 0,
              ...d,
              notes: "",
              options: d.DishEvent?.options?.map((o) => ({
                name: o.name,
                count: 0,
                addition: o.addition,
              })),
            };
      });
    }
    dispatch({
      type: ActionTypes.SET_ORDERED_DISHES,
      payload: _payload,
    });

    dispatch({
      type: ActionTypes.SET_SEATS,
      payload: 1,
    });

    dispatch({
      type: ActionTypes.SET_DINE_OPTION,
      payload:
        getEvent.type.length === 1
          ? getEvent.type[0]
          : getEvent?.eventUser?.dineOption ?? "",
    });

    // getMenuItems only return empty array
    // when event is past, use current momoent as placeholder
    // const items = getMenuItems(getEvent);
    // dispatch({
    //   type: ActionTypes.SET_ARRIVAL_TIME,
    //   payload:
    //     items?.find((i) => !i.disabled)?.value?.toString() ??
    //     items[0].value.toString(),
    // });
    // If event is a POPUP/fixed time, set arrival time as start time
    if (getEvent.type.includes("FIXED_TIME")) {
      dispatch({
        type: ActionTypes.SET_ARRIVAL_TIME,
        payload: getEvent.startTime,
      });
    }
  }, [getEvent.id]);

  useEffect(() => {
    return () => {
      if (
        getEvent.eventUser?.status !== "RESERVED" &&
        state?.orderedDishes?.length
      ) {
        LocalStorageHelper.setItem(
          `foodnome-cart-${getEvent.id}`,
          JSON.stringify(state)
        );
      }
    };
  }, [state]);

  const eventUser =
    getEvent &&
    getEvent.eventUser &&
    ["REQUESTED", "RESERVED", "HOLD"].includes(getEvent.eventUser.status)
      ? getEvent.eventUser
      : null;

  return (
    <OrderContext.Provider value={{ state, dispatch }}>
      <Child
        dialogStatus={state.dialogStatus}
        dispatch={dispatch}
        getEvent={getEvent}
        eventUser={eventUser}
        children={children}
      />
    </OrderContext.Provider>
  );
};

interface IProps {
  dialogStatus: any;
  dispatch: any;
  getEvent: IEvent;
  eventUser: any;
  children: React.ReactNode;
}

const Child: React.FC<IProps> = React.memo(
  ({ dialogStatus, dispatch, getEvent, eventUser, children }) => {
    const [pastEventDialog, setPastEventDialog] = useState(false);
    useEffect(() => {
      // 1. if there is user, but before start time - 24hr, NO
      // 2. if there is user, with order, past start time 24 hr, YES
      // 3. if no user, YES

      const now = moment();
      const reserveBy = moment(Number(getEvent.reserveBy));
      const startTime = moment(Number(getEvent.startTime));
      const deltaTime = moment.duration(now.diff(startTime)).asSeconds();

      if (getEvent.status !== "ACTIVE") return setPastEventDialog(true);

      // If every time slot is "sold out" or "disabled" then show the dialog
      const items = getMenuItems(getEvent);
      if (items?.every((item) => item.disabled))
        return setPastEventDialog(true);
      if (reserveBy.isBefore(now)) {
        if (
          eventUser &&
          deltaTime > Number(process.env.REACT_APP_BUFFER_TIME)
        ) {
          return setPastEventDialog(true);
        }
        if (!eventUser) {
          return setPastEventDialog(true);
        }
      }
    }, []);
    return (
      <>
        <OrderExistDialog
          isOpen={dialogStatus}
          setStatus={(s) =>
            dispatch({ type: ActionTypes.SET_DIALOG_STATUS, payload: s })
          }
          getEvent={getEvent}
        />
        <PastEventDialog isOpen={pastEventDialog} getEvent={getEvent} />
        {children}
      </>
    );
  }
);

export { OrderContextProvider, OrderContext };
