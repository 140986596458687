import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const actionItemsStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "1rem",
    zIndex: 0,
    backgroundColor: COLORS.WHITE,
  },
  cancelRoot: {
    height: 35,
    width: 157,

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "2px",
    },
  },
  complainRoot: {},
  editBtn: {
    marginRight: "1rem",
    color: COLORS.WHITE,
  },
  cancelBtn: {
    color: COLORS.RED,
    zIndex: 2,
    border: "none",
    cursor: "pointer",

    background: "none",
  },
  dialogContent: {
    textAlign: "center",
    padding: "4rem",
  },
  text: {
    marginBottom: "1.5rem",
  },
  phoneNumber: {
    color: COLORS.MEDIUM_RED,
    fontFamily: "CustomFourBold",
  },
  closeBtn: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  closeIcon: {
    fontSize: "3rem",
    color: COLORS.MEDIUM_RED,
  },
});
