import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "1.5rem",
  },
  label: {
    margin: "7px 0",
  },
  notchedOutline: {
    borderRadius: 2,
  },
  selectMenu: {
    padding: 15,

    textTransform: "capitalize",
  },
  menuItem: {},
});
