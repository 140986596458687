import { DialogContent, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { FC, ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";
import { IGetMe } from "src/models";
import { LoginContainer } from "src/screens/login/container";
import { SignUpContainer } from "src/screens/sign-up/container";
import { COLORS } from "src/styles";
import { ResponsiveDialog } from "../dialog";

interface IProps {
  getMe?: IGetMe | null;
  view?: "LOGIN" | "SIGNUP";
  dialogCallback?: (d: any) => any;
}

export const ActionDialog: FC<IProps> = React.memo(
  ({ getMe, dialogCallback, view, children }) => {
    const [open, setOpen] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const history = useHistory();

    const handleClick = () => {
      if (!getMe) {
        if (view === "LOGIN") setIsLogin(true);
        return setOpen(true);
      }
    };

    const handleClose = () => {
      setOpen(false);

      // Use setTimeout to prevent the other display from showing after closing dialog
      setTimeout(() => {
        setIsLogin(false);
      }, 2000);
    };

    const switchView = () => setIsLogin(!isLogin);

    return (
      <>
        <ResponsiveDialog
          BackdropProps={{
            style: { backgroundColor: `rgba(255, 255, 255, 0.6)` },
          }}
          fullWidth
          keepMounted={false}
          maxWidth="sm"
          scroll="body"
          data-testid={isLogin ? "login-dialog" : "sign-up-dialog"}
          open={open}
          onBackdropClick={() => {
            if (process.env.REACT_APP_ENV !== "cordova") {
              // This is used to remove query param when user click on close or when dialog closes
              history.replace({
                pathname: location.pathname,
              });
            }
          }}
          onClose={handleClose}
        >
          <IconButton
            style={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}
            onClick={() => {
              handleClose();
              if (process.env.REACT_APP_ENV !== "cordova") {
                // This is used to remove query param when user click on close or when dialog closes
                history.replace({
                  pathname: location.pathname,
                });
              }
            }}
            data-e2e="user-account-dialog-close"
            aria-label="close"
          >
            <Close style={{ color: COLORS.MEDIUM_GREEN }} />
          </IconButton>
          <DialogContent>
            {isLogin ? (
              <LoginContainer
                containerStyle={{ minHeight: "100%" }}
                switchView={switchView}
                fromVerify={true}
                onSubmit={(d) => {
                  handleClose();
                  if (dialogCallback) {
                    dialogCallback(d);
                  }
                }}
                closeDialog={handleClose}
              />
            ) : (
              <SignUpContainer
                switchView={switchView}
                fromVerify={true}
                onSubmit={(d) => {
                  handleClose();
                  if (dialogCallback) {
                    dialogCallback(d);
                  }
                }}
              />
            )}
          </DialogContent>
        </ResponsiveDialog>
        {getMe?.active
          ? children
          : React.cloneElement(children as ReactElement, {
              onClick: handleClick,
            })}
      </>
    );
  }
);
