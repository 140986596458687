import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const useStyles = makeStyles({
  title: {
    textAlign: "center",
    marginBottom: 0,
    fontFamily: "CustomFourBold",
  },
  dialogRoot: {
    borderRadius: "20px",
    maxWidth: 450,
    width: "100%",
  },
  root: {
    padding: "0 !important",
    borderRadius: "20px",
    boxShadow: "0 4px 19px 0 rgba(31,64,80,0.35)",
  },
  container: {
    padding: "1em",
    backgroundColor: COLORS.WHITE,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontFamily: "CustomFourBold",

    textAlign: "center",
    maxWidth: 256,
    width: "100%",
    padding: "0 10px",
    color: COLORS.DARK_BLUE,
    margin: 5,
  },
  btnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "1rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  btnContent: {
    fontFamily: "CustomFourBold",

    textAlign: "center",
  },
  error: {
    color: COLORS.RED,
    fontWeight: "bold",
  },
  spinner: {
    color: COLORS.WHITE,
  },
});
