import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { BREAK_POINTS, COLORS } from "src/styles";

export const CookAcademyInterestButton = () => {
  const classes = useStyles();
  return (
    <section className={classes.container}>
      <Button
        classes={{ root: classes.buttonRoot }}
        variant="contained"
        color="primary"
        size="large"
        href={"https://www.cookalliance.org/cookacademy"}
        target="_blank"
      >
        Register for Cook Academy!
      </Button>
    </section>
  );
};

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "8rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      paddingBottom: "4rem",
    },
  },
  buttonRoot: {
    // Styles we only want to show on desktop (greater than tablet)
    [`@media (min-width: ${BREAK_POINTS.tablet}em)`]: {
      width: "42.5rem",
      fontSize: "1.8rem",
      padding: "17px 22px",
      backgroundColor: COLORS.MEDIUM_RED,
    },
  },
});
