import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { IGetMe } from "src/models";
import { CreateRestaurantContainer } from "src/screens/become-cook/form/container";
import { BREAK_POINTS, COLORS } from "src/styles";

interface IProps {
  user: IGetMe | undefined;
}

export const SellYourFood: React.FC<IProps> = React.memo(({ user }) => {
  const classes = useStyles();

  return (
    <section className={classes.sellYourFoodContainer}>
      <Typography component="h2" variant="h2" className={classes.title}>
        Start selling your food!
      </Typography>
      <div className={classes.card}>
        <Typography component="p" variant="h3" className={classes.cardHeader}>
          <span style={{ color: COLORS.RED, fontFamily: "CustomFourBold" }}>
            Fill out the form{" "}
          </span>
          below to get started:
        </Typography>
        <CreateRestaurantContainer user={user} />
      </div>
    </section>
  );
});

const useStyles = makeStyles({
  sellYourFoodContainer: {
    width: "100%",
    // This gives us a "darker" background without having to add another layer over it :)
    background: `linear-gradient(
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.6)
      ), url(${require("src/screens/how-it-works/assets/Food_Background.jpg")})`,
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "8rem 2rem",
  },
  card: {
    backgroundColor: COLORS.WHITE,
    borderRadius: "1rem",
    boxShadow: "0 0 20px 0 rgba(52,55,70,0.26)",
    padding: "6rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "3rem",
    },
  },
  title: {
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    marginBottom: "4rem",
    textAlign: "center",
  },
  cardHeader: {
    textAlign: "center",
    fontFamily: "CustomFour",
    marginBottom: "1rem",
  },
});
