import React from "react";
import { MapSearchBar } from "../../shared/map-search-bar";
import { useStyles } from "./header-filter.styles";

export const HeaderFilter = () => {
  const classes = useStyles();

  return (
    <section>
      <div className={classes.searchContainer}>
        <div className={classes.searchBar}>
          <MapSearchBar />
        </div>
      </div>
    </section>
  );
};
