import { SvgIcon } from "@material-ui/core";
import React from "react";

export const UserMore = (props) => (
  <SvgIcon {...props}>
    <g id="Layer_2">
      <g>
        <g>
          <g>
            <path
              d="M18.83,3.68H1.85c-0.71,0-1.29-0.51-1.29-1.14s0.58-1.14,1.29-1.14h16.98c0.71,0,1.29,0.51,1.29,1.14
					S19.54,3.68,18.83,3.68z"
            />
          </g>
          <g>
            <path
              d="M18.83,11.5H1.85c-0.71,0-1.29-0.51-1.29-1.14c0-0.63,0.58-1.14,1.29-1.14h16.98c0.71,0,1.29,0.51,1.29,1.14
					C20.12,10.99,19.54,11.5,18.83,11.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M18.83,19.32H1.85c-0.71,0-1.29-0.51-1.29-1.14c0-0.63,0.58-1.14,1.29-1.14h16.98c0.71,0,1.29,0.51,1.29,1.14
					C20.12,18.81,19.54,19.32,18.83,19.32z"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
