import { useQuery } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import moment from "moment";
import React, { FC, useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { IEvent, IEventUser } from "src/models";
import { eventTypeMap } from "src/utils/helpers";
import { GET_ME } from "../login/api";
import { OrderItems } from "./detail/order-info/order-items";
import { EditTimeDialog } from "./edit-time-dialog";
import { OrderContext } from "./order-context";
import { useStyles } from "./order-exist-dialog.styles";
import { ActionTypes } from "./order-reducer";

interface IProps {
  isOpen: boolean;
  setStatus: (d: any) => void;
  getEvent: IEvent;
}

export const OrderExistDialog: FC<IProps> = ({
  isOpen,
  setStatus,
  getEvent,
}) => {
  const { data } = useQuery(GET_ME, { errorPolicy: "ignore" });
  const { dispatch } = useContext(OrderContext);
  const classes = useStyles();
  const history = useHistory();
  const [orderNum, setOrderNum] = useState(0);

  let currentOrder = getEvent.eventUser;
  if (orderNum && getEvent?.eventUser?.orders) {
    currentOrder = getEvent.eventUser.orders[orderNum - 1];
  }

  useEffect(() => {
    let _currentOrder = getEvent.eventUser;
    if (orderNum && getEvent?.eventUser?.orders) {
      _currentOrder = getEvent.eventUser.orders[orderNum - 1];
    }
    const key = orderNum > 0 ? "OrderDish" : "EventUserDish";
    dispatch({
      type: ActionTypes.SET_ORDERED_DISHES,
      payload: getEvent.dishes.map((d) => {
        const foundDish = _currentOrder?.orderedDishes
          ? _currentOrder?.orderedDishes.find((od) => od.id === d.id)
          : null;
        return foundDish
          ? {
              count: foundDish[key].count,
              ...d,
              notes: _currentOrder?.notes
                ?.split(";")
                .find((n) => n.includes(foundDish.name)),
              options: d.DishEvent?.options?.map((o) => {
                const _foundedEUD = foundDish[key].options?.find(
                  (_o) => o.name === _o.name
                );
                return {
                  name: o.name,
                  count: _foundedEUD?.count ?? o.count,
                  addition: _foundedEUD?.addition ?? o.addition,
                };
              }),
            }
          : {
              count: 0,
              ...d,
              notes: "",
              options: d.DishEvent?.options?.map((o) => ({
                name: o.name,
                count: 0,
                addition: o.addition,
              })),
            };
      }),
    });
  }, [orderNum]);
  return (
    <Dialog
      disableBackdropClick
      open={isOpen}
      classes={{ paperWidthSm: classes.paperWidthSm }}
      fullWidth
      onClose={() => history.push("/events")}
    >
      <DialogTitle>
        <Button
          variant="text"
          onClick={() => history.goBack()}
          color="secondary"
          startIcon={<ChevronLeft />}
        >
          Back
        </Button>
      </DialogTitle>
      {Boolean(getEvent.eventUser?.orders?.length) && (
        <div className={classes.orderNavContainer}>
          <IconButton
            onClick={() =>
              setOrderNum(
                orderNum === 0
                  ? getEvent.eventUser?.orders?.length ?? 0
                  : orderNum - 1
              )
            }
          >
            <ChevronLeft className={classes.navNext} />
          </IconButton>
          <div className={classes.orderCount}>
            Order {orderNum + 1} /{" "}
            {(getEvent.eventUser?.orders?.length ?? 0) + 1}
          </div>
          <IconButton
            onClick={() =>
              setOrderNum(
                orderNum === (getEvent.eventUser?.orders?.length ?? 0)
                  ? 0
                  : orderNum + 1
              )
            }
          >
            <ChevronRight className={classes.navNext} />
          </IconButton>
        </div>
      )}
      <DialogContent className={classes.dialogContent}>
        <div className={classes.headerContainer}>
          <Typography
            variant="body2"
            component="p"
            className={classes.helloSubtitle}
          >
            Hi {data?.getMe?.firstName}, you have a{" "}
            <u>
              <b>{eventTypeMap[currentOrder?.dineOption as string]}</b>
            </u>{" "}
            order on{" "}
            <u>
              {moment(Number(currentOrder?.arrivalTime)).format(
                `MMM Do hh:mma`
              )}
            </u>
            .
          </Typography>
          <EditTimeDialog
            getEvent={getEvent}
            order={currentOrder as IEventUser}
          />
        </div>
        <OrderItems getEvent={getEvent} noOrder={false} displayOnly={true} />
        <div className={classes.actionBtnContainer}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.newOrderBtn}
            onClick={() => {
              dispatch({ type: ActionTypes.RESET });
              setTimeout(() => {
                getEvent.type.length === 1 &&
                  dispatch({
                    type: ActionTypes.SET_DINE_OPTION,
                    payload: getEvent.type[0],
                  });
                dispatch({
                  type: ActionTypes.SET_ARRIVAL_TIME,
                  payload: getEvent.type.includes("FIXED_TIME")
                    ? getEvent.startTime
                    : "",
                });
              }, 100);
              setStatus(false);
            }}
          >
            Make Another Order
          </Button>
          <Link to="/menus" className={classes.link}>
            <Typography variant="body2" component="p">
              View Other Menus
            </Typography>
          </Link>
        </div>
      </DialogContent>
    </Dialog>
  );
};
