import { useQuery } from "@apollo/client";
import debounce from "lodash/debounce";
import React, { FC, useEffect, useState } from "react";
import { ErrorLoadingComponent } from "src/components";
import { sharedAPI } from "src/shared-graphql";
import { useMobileHeader, useScrolledBottom } from "src/utils/hooks";
import { dishAPI } from "../api";
import { GET_HOST_DISHES } from "../api/graphql";
import { Layout } from "./layout";

export const DishListContainer: FC = () => {
  useMobileHeader({ title: "My Dishes" });

  const { data, loading, error, fetchMore, variables } = useQuery(
    GET_HOST_DISHES,
    {
      variables: {
        input: {
          pagePL: {
            offset: null,
            limit: 9,
          },
          searchTerm: "",
        },
      },
    }
  );

  const aggregateDishes = data?.aggregateDishes;
  const cookGetDishes = data?.cookGetDishes;
  const [isFetchInflight, setFetchInflight] = useState<boolean>(false);
  const limitRef = React.useRef<any>(variables?.input?.pagePL?.limit);
  const searchInputRef = React.useRef<any>();

  const onScrolledBottom = React.useCallback(() => {
    if (cookGetDishes && fetchMore && variables) {
      const { count } = cookGetDishes;
      if (limitRef.current < count) {
        setFetchInflight(true);
        const limit = limitRef.current + 9;
        fetchMore({
          variables: {
            ...variables,
            input: {
              ...variables.input,
              pagePL: {
                ...variables.input.pagePL,
                limit,
              },
            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return Object.assign({}, prev, {
              cookGetDishes: (fetchMoreResult as any).cookGetDishes,
            });
          },
        })
          .then(() => setFetchInflight(false))
          .catch(() => setFetchInflight(false));
        limitRef.current = limit;
      }
    }
  }, [cookGetDishes, fetchMore, variables]);
  const containerRef = React.useRef<any>();

  useScrolledBottom({
    callback: onScrolledBottom,
    ref: containerRef,
  });

  useEffect(() => {
    const executeSearch = debounce((event: { target: { value: string } }) => {
      setFetchInflight(true);
      return fetchMore({
        variables: {
          input: {
            ...variables?.input,
            searchTerm: event.target.value,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            cookGetDishes: (fetchMoreResult as any).cookGetDishes,
          });
        },
      })
        .then((d: any) => {
          limitRef.current =
            event.target.value === "" ? 9 : d.cookGetDishes.count;
          setFetchInflight(false);
        })
        .catch(() => setFetchInflight(false));
    }, 500);

    const instanceRef = searchInputRef.current;

    if (instanceRef) {
      instanceRef.addEventListener("input", executeSearch);
      return () => {
        instanceRef.removeEventListener("input", executeSearch);
      };
    }
  });

  const _deleteDish = (dishId: number) => {
    return dishAPI
      .deleteDish(dishId)
      .then(() => {
        limitRef.current = 9;
        sharedAPI.setSnackbarMsg({ type: "success", msg: "Dish Deleted" });
      })
      .catch(() => {
        sharedAPI.setSnackbarMsg({
          type: "error",
          msg: "This dish is being used",
        });
      });
  };

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }

  return (
    <Layout
      dishes={cookGetDishes.rows}
      deleteDish={_deleteDish}
      error={error}
      isFetchInflight={isFetchInflight}
      searchInputRef={searchInputRef}
      totalDishCount={aggregateDishes.count}
      containerRef={containerRef}
    />
  );
};
