import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { BREAK_POINTS, COLORS } from "src/styles";
import { _companyContact } from "src/utils/constants";

const useStyles = makeStyles({
  container: {
    paddingTop: "6rem",
    paddingBottom: "3rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "0 1.5rem",
    },
  },
  title: {
    position: "relative",

    margin: "1rem 0",

    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  headerText: {
    fontWeight: 100,

    margin: "1rem 0",
  },
  email: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.MEDIUM_GREEN,
    textDecoration: "none",
  },
});

export const PressHeader: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h1" component="h1" className={classes.title}>
        Press
      </Typography>
      <Typography variant="body1" component="h2" className={classes.headerText}>
        For press opportunities, please email{" "}
        <a
          className={classes.email}
          href={`mailto:${_companyContact.info}?subject=Press Inquiry`}
        >
          {_companyContact.info}
        </a>
        .
      </Typography>
    </div>
  );
};
