import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  input: {
    zIndex: 1,
    color: COLORS.BLACK,
  },

  focused: { borderColor: COLORS.MEDIUM_GREY, borderWidth: 1 },
  notchedOutline: {
    backgroundColor: COLORS.WHITE,
    borderRadius: 44,
    color: COLORS.BLACK,
    borderColor: COLORS.LIGHT_GREY,
  },
  searchBarItem: {
    padding: "0 15px",
    paddingBottom: 12,
  },
  enterAddressButtonRoot: {
    backgroundColor: COLORS.WHITE,

    color: "#717171",
    borderRadius: 18,
    textTransform: "unset",
  },
  enterAddressButtonLabel: {
    justifyContent: "left",
  },
  dialogTitle: {
    color: COLORS.BLACK,
    flex: 1,
    textAlign: "center",
    fontFamily: "CustomFourBold",
  },
  appBar: {
    backgroundColor: COLORS.WHITE,
    position: "relative",
  },
  sideBubble: {
    position: "absolute",
    left: -90,
    zIndex: -1,
    width: "60%",
    maxWidth: 285,
  },
});
