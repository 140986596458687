import { Paper } from "@material-ui/core";
import React, { FC, useState } from "react";
import { PaginationComponent } from "src/components";
import { IRestaurantReview } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { restaurantReviewAPI } from "../api";
import { IFetchMorePL } from "./container";
import { Header } from "./header";
import { RestaurantReviewListTable } from "./table";

interface IProps {
  restaurantReviews: IRestaurantReview[];
  totalCount: number;
  onFetchMore: (d: IFetchMorePL) => void;
  redirect: (d: string) => void;
}
export const Layout: FC<IProps> = ({
  restaurantReviews,
  totalCount,
  onFetchMore,
  redirect,
}) => {
  const [status, setStatus] = useState("SUBMITTED");
  const [number, setNumber] = useState(1);

  const _setStatus = (d: string) => {
    setStatus(d);
    onFetchMore({ pagePL: { limit: 10 }, status: d });
  };
  const _deleteReview = (d: number) => {
    restaurantReviewAPI
      .delteRestaurantReview(d)
      .then(() =>
        sharedAPI.setSnackbarMsg({ type: "success", msg: "Review Deleted" })
      )
      .catch(() => sharedAPI.setSnackbarMsg({ type: "error", msg: "Error" }));
    onFetchMore({ pagePL: { limit: 10, offset: (number - 1) * 10 }, status });
  };
  const _changePage = (d: number) => {
    setNumber(d);
    return onFetchMore({
      pagePL: { offset: (d - 1) * 10, limit: 10 },
      status,
    });
  };
  return (
    <div>
      <Header totalCount={totalCount} status={status} setStatus={_setStatus} />
      <Paper style={{ padding: "1rem", margin: "1rem" }}>
        <RestaurantReviewListTable
          restaurantReviews={restaurantReviews}
          redirect={redirect}
          deleteReview={_deleteReview}
        />

        <PaginationComponent totalCount={totalCount} onChange={_changePage} />
      </Paper>
    </div>
  );
};
