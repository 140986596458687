import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS, COLORS } from "src/styles";

export const styles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    borderRadius: 0,
    width: "100%",
    justifyContent: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  fieldsWrapper: {
    maxWidth: 332,
  },
  fieldsContainer: {
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: "1em",
  },
  centered: {
    alignSelf: "center",
  },
  header: {
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    textAlign: "center",
    paddingBottom: "1em",
  },
  logo: {
    width: 150,
  },

  loginLink: {
    color: COLORS.MEDIUM_GREEN,
    padding: "0 1rem",

    fontFamily: "CustomFourBold",
    textDecoration: "none",
    textTransform: "uppercase",
    fontWeight: 500,
  },
  inputLabel: {
    display: "none",
  },
  helperTextRoot: {
    color: COLORS.DARK_RED,
  },
  inputRoot: {
    height: "40px",
    "&$inputFocused": {
      borderColor: `${COLORS.DARK_GREY} !important`,
      borderWidth: "2px",
    },
    "&:hover:not($disabled):not($inputFocused):not($error)": {
      borderColor: `${COLORS.DARK_GREY} !important`,
      borderWidth: "2px",
    },
    marginBottom: ".75rem",
  },
  underLineDisabled: {},
  underLineFocused: {},
  input: {
    color: "#294E60",
    marginLeft: "10px",
    "&::placeholder": {
      color: COLORS.DARK_GREY,
    },
  },
  signUpInput: {
    color: "#294E60",
    "&::placeholder": {
      color: COLORS.DARK_GREY,
    },
  },
  outlined: {
    borderRadius: 2,
  },
  actionButton: {
    marginTop: "2em",
    background: COLORS.MEDIUM_RED,
    borderRadius: "2px",
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    color: COLORS.WHITE,
    transform: `translateY(0px)`,
    transition: "all 100ms ease-out",
    boxShadow: "0 1px 9px 1px rgba(0,0,0,0.2)",

    "&:disabled": {
      backgroundColor: `rgba(255, 89, 75, 0.7)`,
      transform: `translateY(8px)`,
      boxShadow: "none",
    },
    "&:hover": {
      background: COLORS.MEDIUM_RED,
    },
  },
  disabled: {},
  error: {},
  inputFocused: {},
  subCard: {
    color: COLORS.DARK_GREY,
    textAlign: "center",
    padding: "1rem 0 0",

    borderTop: `solid 1px #E5E5E5`,
    marginTop: "1.75rem",
  },
  formHelperRoot: {
    position: "absolute",
    top: 51,
    margin: 0,
  },
  label: {
    position: "absolute",
    display: "block",
    border: "0px",
    margin: "-1px",
    padding: "0px",
    height: "1px",
    width: "1px",
    clip: "rect(0, 0, 0, 0)",
    overflow: "hidden",
  },
  rememberMe: {
    color: "#FF5967",
  },
  link: {
    textDecoration: "none",

    color: COLORS.MEDIUM_RED,
  },
  facebookLabel: {
    color: COLORS.DARK_GREY,

    textTransform: "none",
    display: "flex",
  },
  facebookOutlined: {
    border: `solid 1px ${COLORS.DARK_GREY}`,
    borderRadius: 2,
    padding: `.5rem 1rem`,
    width: "100%",
  },
  formContainer: {
    display: "flex",
    boxShadow: "-2px 4px 13px 3px rgba(0,0,0,0.21)",
    justifyContent: "flex-start",
    alignItems: "center",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },

  formPaper: {
    zIndex: 1,
  },
  p: {
    color: COLORS.MEDIUM_RED,

    marginBottom: "1rem",
  },
  h1: {
    color: COLORS.DARK_RED,
    fontFamily: "CustomFourBold",

    marginTop: 7,
  },
  beetBackgroundImg: {
    backgroundImage: `url(${require("./assets/sliced_beets.jpg")})`,
    filter: "blur(1px)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    minHeight: "100vh",
  },
  formFieldsWrapper: {
    height: 250,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-evenly",
  },
  thisRedBoxShadow: {
    left: 0,
    right: 0,
    margin: "auto",
    position: "absolute",
  },
  // container: {
  //   position: "relative",
  //   width: "100%",
  //   flexGrow: 1,
  //   display: "grid",
  //   gridTemplateColumns: `repeat(2, 1fr)`,
  //   gridAutoRows: "100%",
  // },
  image: {
    gridRow: "1 / -1",
    backgroundSize: "cover",
    backgroundImage: `url(${require(`./assets/triple_beet.jpg`)})`,
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "none",
    },
  },
  formWrapper: {
    gridRow: "1 / -1",
    display: "flex",
    justifyContent: "center",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      gridColumn: "1 / -1",
    },
  },
});
