import { useMutation } from "@apollo/client";
import { Button, Paper, Typography } from "@material-ui/core";
import dropIn, { Dropin } from "braintree-web-drop-in";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { GET_BT_TOKEN } from "src/components/braintree/graphql/mutations";
import { sharedAPI } from "src/shared-graphql";
import { useStyles } from "./layout.styles";

export const Layout: React.FC = () => {
  const [instance, setInstance] = useState<Dropin | null>(null);
  const history = useHistory();

  const [getToken] = useMutation(GET_BT_TOKEN);
  const ref = useRef(null);

  const [button, setButton] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    getToken()
      .then((d) => {
        reinit(d.data.userGetBTToken);
      })
      .catch((error) => {
        if (error?.message?.includes("Token does not match")) {
          history.push("/login");
        } else if (error?.message?.includes("Must be logged in")) {
          history.push("/login");
        }
      });
    return () => {
      instance?.teardown();
    };
  }, []);

  useEffect(() => {
    instance?.on("paymentMethodRequestable", (event) => {
      // If user has a card selected or is typing in a card and returns a card type
      setButton(event.paymentMethodIsSelected ? false : true);
    });
    instance?.on("noPaymentMethodRequestable", () => {
      setButton(false);
    });
    instance?.on("paymentOptionSelected", () => {
      setButton(false);
    });
  }, [instance]);

  const reinit = (_token: string) => {
    return dropIn
      .create({
        container: ref.current as any,
        authorization: _token,
        vaultManager: true,
        preselectVaultedPaymentMethod: false,
        paypal: {
          flow: "vault",
          buttonStyle: {
            color: "blue",
            shape: "rect",
            size: "responsive",
            label: "checkout",
            tagline: "true",
          } as any,
        },
        // venmo: {
        //   allowNewBrowserTab: true,
        // },
      })
      .then((int) => {
        setInstance(int);
      })
      .catch(
        (err: any) => new Error("Error loading BrainTree component: " + err)
      );
  };

  const submit = () => {
    instance?.requestPaymentMethod((err, payload) => {
      if (err || !payload) {
        sharedAPI.setSnackbarMsg({ type: "error", msg: "" });
      }
    });
  };
  return (
    <Paper elevation={3} className={classes.paper}>
      <Typography variant="h4" component="h2" className={classes.title}>
        Update your payment info
      </Typography>
      <div ref={ref} className={classes.customBrainTreeStyles} />
      {button && (
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          onClick={submit}
        >
          Save
        </Button>
      )}
    </Paper>
  );
};
