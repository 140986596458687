import { gql } from "@apollo/client";
import { GET_HOST_DISHES } from "src/shared-graphql/queries";

export const GET_DISH = gql`
  query getDish($input: ID!) {
    getDish(id: $input) {
      id
      name
      description
      defaultPrice
      imagesGQL {
        publicId
        small
        medium
        large
        original
      }
      tags
      isPublished
      updatedAt
      createdAt
      dietary
      category
      options {
        name
        addition
      }
      restaurant {
        id
        name
        description
      }
      allergens {
        id
        name
        description
        updatedAt
        createdAt
      }
    }
  }
`;

export { GET_HOST_DISHES };

export const GET_ALLERGENS = gql`
  query getAllergens {
    getAllergens {
      count
      rows {
        id
        name
      }
    }
  }
`;

export const GET_FOODTYPES = gql`
  query getFoodTypes {
    getFoodTypes {
      id
      name
    }
  }
`;
