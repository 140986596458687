import React from "react";
import { scroller } from "react-scroll";
import { COLORS } from "src/styles";

export const stepsDesktop = [
  {
    title: "Submit Application",
    text: (
      <>
        <span
          role="link"
          style={{
            color: COLORS.DARK_RED,
            fontFamily: "CustomFourBold",
            fontWeight: 500,
            textDecoration: "none",
            cursor: "pointer",
          }}
          onClick={
            () =>
              // add a static link to open in new tab on click
              window.open("https://www.cookalliance.org/cookacademy", "_blank")
            // scroller.scrollTo("become-cook-form", {
            //   duration: 1500,
            //   delay: 100,
            //   smooth: "easeInOutCubic",
            // })
          }
        >
          Click here{" "}
        </span>{" "}
        and fill out the COOK Academy interest form to get started! We’ll ask
        you a few simple questions & reach out to you if you qualify for the
        program.{" "}
      </>
    ),
  },
  {
    title: "Get Approved",
    text: "COOK Academy will walk you through the food safety certification and permitting process. Requirements vary by county.",
  },
  {
    title: "Start Cooking!",
    text: "Get paid doing what you love. We’ll help you succeed from day one.",
  },
];

export const stepsMobile = [
  {
    title: "Submit Application",
    text: (
      <>
        <span
          role="link"
          style={{
            color: COLORS.DARK_RED,
            fontFamily: "CustomFourBold",
            fontWeight: 500,
            textDecoration: "none",
            cursor: "pointer",
          }}
          onClick={() =>
            scroller.scrollTo("become-cook-form", {
              duration: 1500,
              delay: 100,
              smooth: "easeInOutCubic",
            })
          }
        >
          Fill out the form
        </span>{" "}
        and get started!
      </>
    ),
  },
  {
    title: "Get Approved",
    text: "We’ll walk you through the food safety certification process. Requirements vary by county.",
  },
  {
    title: "Start Cooking!",
    text: "Get paid for doing what you love. We’ll make sure you succeed from day one.",
  },
];
