import { CircularProgress, Divider, MenuItem } from "@material-ui/core";
import React from "react";
import { COLORS } from "src/styles";

export const AutoLocate = ({
  useGeoLocation,
  geoCodeDisabled,
  displaySearchFor,
  suggestionStyle,
  geoCodeLoading,
  closeMenu,
  onSelect,
  longitude,
  latitude,
}) => {
  if (useGeoLocation && !geoCodeDisabled && displaySearchFor.google) {
    return (
      <>
        <MenuItem
          style={suggestionStyle}
          disabled={geoCodeLoading}
          onClick={() => {
            onSelect({ longitude, latitude });
            closeMenu();
          }}
        >
          {geoCodeLoading ? (
            <>
              <CircularProgress
                data-testid="spinner"
                size={16}
                style={{ marginRight: "10px" }}
              />{" "}
              Searching for you
            </>
          ) : (
            <>
              <img
                src={require(`src/assets/icons/location-triangle-icon-green.svg`)}
                style={{
                  marginRight: 10,

                  width: 20,
                }}
              />
              <span
                style={{
                  color: COLORS.MEDIUM_GREEN,
                }}
              >
                Your Location
              </span>
            </>
          )}
        </MenuItem>
        <Divider />
      </>
    );
  } else {
    return null;
  }
};
