import { gql } from "@apollo/client";

export const GET_RESTAURANT_GUESTS = gql`
  query getRestaurantGuests($input: GetRestaurantGuestsPL!) {
    getRestaurantGuests(getRestaurantGuestsPL: $input) {
      count
      rows {
        id
        status
        updatedAt
        createdAt
        guest {
          id
          firstName
          lastName
          picture
          createdAt
        }
      }
    }
  }
`;

export const CONTACT_GUESTS = gql`
  query cookContactAllGuests($input: String!) {
    cookContactAllGuests(msg: $input)
  }
`;
