import { gql } from "@apollo/client";

export const UPDATE_RESTAURANT = gql`
  mutation updateRestaurant($input: UpdateRestaurantPL!) {
    updateRestaurant(updateRestaurantPL: $input) {
      id
      name
      description
      tags
      imagesGQL {
        publicId
        small
        medium
        large
        original
      }
      address
      hostBio
      street
      city
      state
      zipCode
      county
      updatedAt
      createdAt
      permitNumber
    }
  }
`;
