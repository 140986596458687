import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS } from "src/styles";
import { COLORS } from "src/styles/colors";

export const useFormStyles = makeStyles({
  container: {},
  innerContainer: {
    width: "100%",

    maxWidth: 1058,
  },
  avatarWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    flexGrow: 0,
    flexShrink: 1,
    width: "100%",
    maxWidth: 300,
    paddingBottom: "50px",
  },
  profileImageLabel: {
    textAlign: "center",
    display: "inline-block",
  },
  formRight: {
    display: "grid",
    gridTemplateRows: `repeat(4, auto)`,
    gridGap: 20,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexWrap: "wrap",
    paddingBottom: "calc(3rem + env(safe-area-inset-bottom))",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  label: {},
  textFieldContainer: {
    width: "100%",
  },
  profileImage: {},
  nameFieldContainer: { width: "100%" },
  marginTop20: { marginTop: "10px" },
  textFieldSubContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 40px 20px 20px",
    width: "100%",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: "10px 20px",
    },
  },
  textArea: {
    width: "100%",
  },
  multiline: { padding: 0 },
  textAreaSubContainer: {
    // width: "85%",
    margin: "0 auto",
  },
  marginDense: {
    padding: 0,
  },
  textField: {
    // border: `1px solid ${COLORS.LIGHT_GREY}`,
    borderRadius: 2,
    backgroundColor: COLORS.WHITE,
    padding: "10px 5px",
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    "&::placeholder": {
      color: COLORS.MEDIUM_GREY,
      padding: "auto",
    },
  },
  actionButton: {
    background: COLORS.MEDIUM_RED,
    borderRadius: "2px",
    marginTop: "16px",
    height: 40,
    color: COLORS.WHITE,
    boxShadow: "0 2px 6px 0 rgba(90,15,15,0.5)",
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    minWidth: 136,
    "&:hover": {
      background: COLORS.MEDIUM_RED,
    },
    "&:disabled": {
      background: COLORS.MEDIUM_RED,
    },
  },
  disabled: { background: `rgba(64, 64, 64, 0.5) !important` },
  dinerInput: {
    border: `1px solid ${COLORS.LIGHT_GREY}`,
    borderRadius: 2,
    backgroundColor: COLORS.WHITE,
    padding: "10px 10px",
    fontFamily: "CustomFourBold",
    fontWeight: 500,

    "&:hover": {
      border: `1px solid ${COLORS.LIGHT_GREY}`,
    },
    "&::placeholder": {
      color: COLORS.MEDIUM_GREY,
      padding: "auto",
    },
  },
  formBottom: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "0 40px 0 20px",
    alignItems: "center",
    flexWrap: "wrap",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      justifyContent: "flex-end",
      margin: "1em 0",
      minHeight: 100,
    },
  },
  formControlLabel: {
    color: COLORS.DARK_GREY,
  },
  changePasswordLink: {
    color: COLORS.MEDIUM_RED,

    textDecoration: "none",
  },
});
