import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    marginLeft: "12rem",
    marginTop: "2rem",
    width: "100%",
    overflow: "scroll",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      marginLeft: 0,
    },
  },
});
