import {
  Button,
  Card,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { sharedAPI } from "src/shared-graphql";
import { BREAK_POINTS, COLORS } from "src/styles";
import { getPasswordError, getRPasswordError } from "src/utils/user-helpers";
import { changePasswordAPI } from "./api";

export const ChangePasswordContainer: React.FC = () => {
  const classes = useStyles();
  const [password, setPassword] = useState<string>("");
  const [newPasswordState, setNewPassword] = useState<string>("");
  const [reNewPasswordState, setReNewPassword] = useState<string>("");
  const originalPasswordInputRef = React.useRef<any>(null);
  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);
  const history = useHistory();

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const { oldPassword, newPassword } = event.target.elements;
    changePasswordAPI
      .updateUserPassword({
        password: oldPassword.value,
        newPassword: newPassword.value,
      })
      .then(() => {
        sharedAPI.setSnackbarMsg({
          type: "success",
          msg: "Password updated successfully!",
        });
        setPassword("");
        setNewPassword("");
        setReNewPassword("");
      })
      .catch((error) => {
        sharedAPI.setSnackbarMsg({
          msg: error.message.replace(/^graphql error: /i, ""),
          type: "error",
        });

        if (new RegExp("password is not correct", "i").test(error.message)) {
          originalPasswordInputRef.current.focus();
        }
      });
  };

  const passwordError = getPasswordError(newPasswordState);
  const rePasswordError = getRPasswordError(
    newPasswordState,
    reNewPasswordState
  );

  const isDisabled = () => {
    return (
      !password ||
      !newPasswordState ||
      !reNewPasswordState ||
      !!passwordError ||
      !!rePasswordError
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        {isMobile && (
          <IconButton onClick={() => history.goBack()}>
            <ChevronLeft className={classes.backArrowFab} />
          </IconButton>
        )}
      </div>
      <div style={{ margin: "1em" }}>
        <Card className={classes.card}>
          <Typography
            variant="h2"
            component="h1"
            className={classes.mainHeading}
          >
            Change your password
          </Typography>
          <form className={classes.form} onSubmit={handleSubmitForm}>
            <Typography
              component="label"
              variant="caption"
              className="hidden-label"
              htmlFor="old-password-input"
            >
              Old Password
            </Typography>
            <TextField
              size="small"
              inputRef={originalPasswordInputRef}
              margin="dense"
              FormHelperTextProps={{
                classes: { root: classes.formHelperText },
              }}
              classes={{ root: classes.input }}
              variant="outlined"
              type="password"
              placeholder="Old password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              InputProps={{
                id: "old-password-input",
                name: "oldPassword",
                classes: {
                  inputMarginDense: classes.inputMarginDense,
                  notchedOutline: "",
                },
              }}
            />
            <Typography
              component="label"
              variant="caption"
              className="hidden-label"
              htmlFor="new-password-input"
            >
              New Password
            </Typography>
            <TextField
              size="small"
              margin="dense"
              FormHelperTextProps={{
                classes: { root: classes.formHelperText },
              }}
              classes={{ root: classes.input }}
              variant="outlined"
              type="password"
              placeholder="New password"
              error={!!passwordError}
              helperText={passwordError}
              value={newPasswordState}
              onChange={(event) => setNewPassword(event.target.value)}
              InputProps={{
                id: "new-password-input",
                name: "newPassword",
                classes: {
                  inputMarginDense: classes.inputMarginDense,
                  notchedOutline: "",
                },
              }}
            />
            <Typography
              component="label"
              variant="caption"
              className="hidden-label"
              htmlFor="new-password-reinput"
            >
              Retype New Password
            </Typography>
            <TextField
              size="small"
              margin="dense"
              FormHelperTextProps={{
                classes: { root: classes.formHelperText },
              }}
              classes={{ root: classes.input }}
              variant="outlined"
              type="password"
              placeholder="Retype new password"
              value={reNewPasswordState}
              onChange={(event) => setReNewPassword(event.target.value)}
              error={!!rePasswordError}
              helperText={rePasswordError}
              InputProps={{
                id: "new-password-reinput",
                classes: {
                  inputMarginDense: classes.inputMarginDense,
                  notchedOutline: "",
                },
              }}
            />
            <Button
              className={classes.button}
              disabled={isDisabled()}
              type="submit"
            >
              Change password
            </Button>
          </form>
        </Card>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  mainHeading: {
    marginBottom: "2rem",
    fontFamily: "CustomFourBold",
  },
  card: {
    boxShadow: `3px 4px 18px 0 rgba(70,70,70,0.2)`,
    padding: "1.5rem",
    width: "100%",
    maxWidth: 600,
  },
  input: {
    marginBottom: "2em",
    "&::placeholder": {},
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  inputMarginDense: { paddingTop: 10, paddingBottom: 10 },
  button: {
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",

    fontWeight: 500,

    alignSelf: "flex-end",
    backgroundColor: COLORS.MEDIUM_GREEN,
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_GREEN,
    },
    "&:disabled": {
      backgroundColor: COLORS.DARK_GREY,
    },
  },
  formHelperText: {
    position: "absolute",
    top: 45,
    margin: 0,
  },
  backArrowFab: {
    color: COLORS.MEDIUM_GREEN,
    fontSize: "2.5rem",
  },
});
