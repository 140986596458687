import { Button, Fab, OutlinedInput, Typography } from "@material-ui/core";
import { Add, Search } from "@material-ui/icons";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { FetchLoading, NoItemsContainer } from "src/components";
import { IDish } from "src/models";
import { COLORS } from "src/styles";
import { DishCard } from "./dish-card";
import { styles as useStyles } from "./styles";

export const CreateDishButton: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Button
      onClick={() => history.push("/c/dishes/create")}
      variant="contained"
      classes={{
        contained: classes.createButton,
        startIcon: classes.createButtonIcon,
      }}
      startIcon={<Add style={{ color: COLORS.WHITE }} />}
    >
      Create Dish
    </Button>
  );
};

interface IProps {
  deleteDish: (dishId: number) => any;
  dishes: IDish[];
  error: any;
  isFetchInflight: boolean;
  searchInputRef: any;
  totalDishCount: number;
  containerRef: any;
}

export const Layout: React.FC<IProps> = React.memo(
  ({
    dishes,
    deleteDish,

    isFetchInflight,
    searchInputRef,
    totalDishCount,
    containerRef,
  }) => {
    const history = useHistory();

    const classes = useStyles();
    const handleUpdate = (dishId: number) =>
      history.push(`/c/dishes/edit/${dishId}`);

    return (
      <div className={classes.container}>
        <FetchLoading appear={isFetchInflight} />
        <Fab
          id="create-dish-button"
          onClick={() => history.push("/c/dishes/create")}
          className={classes.mobileCreateButton}
        >
          <Add style={{ verticalAlign: "middle", fontSize: "2rem" }} />
        </Fab>
        <div className={classes.actionButtonWrapper}>
          <Typography
            variant="h2"
            component="h1"
            className={classes.mainHeader}
          >
            My Dishes
          </Typography>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            {totalDishCount !== 0 && (
              <div>
                <Typography
                  component="label"
                  variant="caption"
                  className="hidden-label"
                  htmlFor="search-input"
                >
                  Search Dishes
                </Typography>
                <OutlinedInput
                  disabled={totalDishCount === 0}
                  labelWidth={0}
                  classes={{ input: classes.searchInput }}
                  inputRef={searchInputRef}
                  placeholder="Search"
                  inputProps={{ id: "search-input" }}
                  startAdornment={
                    <Search style={{ color: COLORS.DARK_GREY }} />
                  }
                />
              </div>
            )}
            {!!totalDishCount && <CreateDishButton />}
          </div>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", flex: 1 }}
          ref={containerRef}
        >
          {totalDishCount === 0 ? (
            <NoItemsContainer
              title={"You haven't created any dishes"}
              subtext={"Get started and create your first dish!"}
              button={<CreateDishButton />}
            />
          ) : dishes.length === 0 && !isFetchInflight ? (
            <Typography
              variant="body2"
              component="p"
              style={{
                fontFamily: "CustomFourBold",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              No dishes found for "{searchInputRef?.current?.value}"
            </Typography>
          ) : (
            <TransitionGroup component="ul" className={classes.dishesList}>
              {dishes.map((dish) => {
                return (
                  <CSSTransition
                    key={dish.id}
                    timeout={300}
                    classNames="fade-in"
                    unmountOnExit
                  >
                    <li style={{ width: "100%" }}>
                      <DishCard
                        dish={dish}
                        handleDelete={deleteDish}
                        handleUpdate={handleUpdate}
                      />
                    </li>
                  </CSSTransition>
                );
              })}
            </TransitionGroup>
          )}
        </div>
      </div>
    );
  }
);
