import { useQuery } from "@apollo/client";
import { Divider, IconButton, makeStyles, Typography } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { ErrorLoadingComponent } from "src/components";
import { BREAK_POINTS, COLORS } from "src/styles";
import { useViewportWidth } from "src/utils/hooks";
import { Dots } from "./dots";
import { GET_RESTAURANT_EVENTS } from "./graphql";
import { NoUpcomingMeals } from "./no-upcoming-meals";
import { RestaurantEventCard } from "./restaurant-event-card";

interface IProps {
  restaurantId: string | number;
}

export const UpcomingEventsWidgetDesktop: React.FC<IProps> = ({
  restaurantId,
}) => {
  const classes = useStyles();
  const vw = useViewportWidth({
    breakPoints: [1000, 950, 900, 850, 800, 750, 650, 630, 600, 400, 300].map(
      (w) => `screen and (max-width: ${w}px)`
    ),
  });
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { data, loading, error } = useQuery(GET_RESTAURANT_EVENTS, {
    variables: { input: restaurantId },
    fetchPolicy: "no-cache",
  });
  const onClickRight = () => setActiveIndex((i) => (i + 1) % events.length);
  const onClickLeft = () =>
    setActiveIndex((i) => (i === 0 ? events.length - 1 : i - 1));

  if (loading || error) {
    return <ErrorLoadingComponent error={error} loading={loading} />;
  }

  // Should already be sorted in the backend but just for safe measures...
  const events =
    data?.getRestaurantEvents
      .slice()
      .sort((a, b) => parseInt(a.startTime, 10) - parseInt(b.startTime, 10)) ??
    [];

  return (
    <aside className={classes.container}>
      <div
        className={classes.header}
        style={{ padding: `0 ${setPadding(vw)}px` }}
      >
        <Typography variant="h5" component="h5" className={classes.title}>
          Upcoming Menus
        </Typography>
        <Divider />
      </div>
      {events.length ? (
        <>
          <SwipeableViews
            style={{
              marginTop: "1.25rem",
              padding: `0 ${setPadding(vw)}px`,
              WebkitMaskImage:
                "linear-gradient(to right, transparent 0%, black 10%, black 90%, transparent 100%)",
            }}
            containerStyle={{
              minHeight: 370,
            }}
            enableMouseEvents
            index={activeIndex}
            onChangeIndex={(index) => setActiveIndex(index)}
          >
            {events.map((event) => (
              <RestaurantEventCard key={event.id} event={event} />
            ))}
          </SwipeableViews>

          <div className={classes.controlContainer}>
            <IconButton
              onClick={onClickLeft}
              classes={{ root: classes.carouselButton }}
              color="secondary"
              size="small"
            >
              <ChevronLeft style={{ fontSize: "3rem", color: COLORS.WHITE }} />
            </IconButton>
            <Dots
              iterable={events}
              activeIndex={activeIndex}
              onClickDot={(i) => setActiveIndex(i)}
            />
            <IconButton
              onClick={onClickRight}
              classes={{ root: classes.carouselButton }}
              color="secondary"
              size="small"
            >
              <ChevronRight style={{ fontSize: "3rem", color: COLORS.WHITE }} />
            </IconButton>
          </div>
        </>
      ) : (
        <NoUpcomingMeals />
      )}
    </aside>
  );
};

const useStyles = makeStyles({
  container: {
    width: "100vw",
    maxWidth: "47rem",
    borderRadius: "0.5rem",

    padding: "1rem 0",
    backgroundColor: COLORS.WHITE,
    boxShadow: "3px 4px 19px 0 rgba(56,56,56,0.28)",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      display: "inline-grid",
      maxWidth: "unset",
      margin: `2rem -1rem`,
    },
  },
  header: {
    padding: "0 4rem",
    [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
      padding: 0,
    },
  },
  title: {
    fontFamily: "CustomFourBold",

    margin: "1rem 0",
  },
  carouselButton: {
    boxShadow: `0 0 7px 1px rgba(0,0,0,0.28)`,
    backgroundColor: COLORS.MEDIUM_GREEN,
    "&:hover": {
      backgroundColor: COLORS.MEDIUM_GREEN,
      opacity: 0.5,
    },
  },
  controlContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

function setPadding(vw: number) {
  if (vw > 990) return 40;
  else if (vw > 850) return 270;
  else if (vw > 800) return 250;
  else if (vw > 750) return 200;
  else if (vw > 650) return 175;
  else if (vw > 630) return 150;
  else if (vw > 550) return 100;
  else if (vw > 500) return 100;
  else return 10;
}
