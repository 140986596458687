import { makeStyles } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import SwipeableViews from "react-swipeable-views";

interface IProps {
  images: string[];
  step: number;
  setStep: (d: number) => void;
}

const useStyles = makeStyles({
  colOne: {
    position: "relative",
    height: 663,
    overflow: "hidden",
  },
  img: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
});

export const ViewPort: FunctionComponent<IProps> = ({
  images,
  step,
  setStep,
}) => {
  const classes = useStyles();
  return (
    <SwipeableViews
      axis={"x"}
      index={step}
      onChangeIndex={setStep}
      enableMouseEvents
    >
      {images.map((image, index) => (
        <div key={index}>
          <div className={classes.colOne}>
            <img src={image} className={classes.img} />
          </div>
        </div>
      ))}
    </SwipeableViews>
  );
};
