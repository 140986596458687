import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { IGetMe, IRestaurant } from "src/models";
import { BREAK_POINTS } from "src/styles";
import { useStyles } from "./layout.styles";
import { NavigationTabs } from "./tabs/tabs";
import {
  About,
  Dishes,
  Overview,
  Reviews,
  UpcomingEventsWidgetDesktop,
} from "./widgets";

interface LayoutLeftProps {
  restaurant: IRestaurant;
  getMe?: IGetMe;
  isFabVisible: boolean;
}

export const LayoutLeft: React.FC<LayoutLeftProps> = ({
  restaurant,
  isFabVisible,
  getMe,
}) => {
  const classes = useStyles();

  const isMobile = useMediaQuery(`(max-width: ${BREAK_POINTS.tablet}em)`);

  return (
    <div className={classes.widgetLeftLayout}>
      <NavigationTabs isFabVisible={isFabVisible} />
      <div>
        <Overview restaurant={restaurant} getMe={getMe} />
        {isMobile && (
          <UpcomingEventsWidgetDesktop restaurantId={restaurant.id} />
        )}
        <About restaurant={restaurant} />

        <Dishes restaurant={restaurant} getMe={getMe} />
        <Reviews restaurant={restaurant} getMe={getMe} />
      </div>
    </div>
  );
};
