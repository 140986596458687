import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  bannerContainer: {
    position: "relative",
    borderRadius: 10,
    height: 200,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",

    justifyContent: "center",
    alignItems: "center",
  },
  overlay: {
    // backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
    objectFit: "cover",
    filter: "blur(10px)",
    transition: "all 300ms ease-out",

    width: "100%",
    // height: "100%",
  },
  editIcon: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: 0,
    height: 0,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  spinner: { color: COLORS.MEDIUM_GREEN },
  uploadButton: {
    color: COLORS.WHITE,
    fontFamily: "CustomFourBold",
    fontWeight: 500,
    backgroundColor: COLORS.MEDIUM_GREEN,
    borderRadius: 2,
    padding: "4px 4px",

    textAlign: "center",
    "&:hover": {
      opacity: 0.8,
      backgroundColor: COLORS.MEDIUM_GREEN,
    },
    "&:disabled": {
      backgroundColor: COLORS.LIGHT_GREY,
    },
  },
  uploadImgBtn: {
    position: "absolute",
    bottom: 10,
    right: 10,

    fontWeight: 500,
    // zIndex: 1,
  },
});
