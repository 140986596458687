import { useQuery } from "@apollo/client";
import { Typography } from "@material-ui/core";
import React from "react";
import { FollowersDialog } from "src/components";
import { IRestaurant } from "src/models";
import { GET_RESTAURANT_MEAL_SERVED } from "../api/graphql/index";
import { useOverviewStyles } from "./overview.styles";

interface IProps {
  restaurant: IRestaurant;

  scroll: () => void;
}

export const Metrics: React.FC<IProps> = ({ restaurant, scroll }) => {
  const classes = useOverviewStyles();

  const { data, loading } = useQuery(GET_RESTAURANT_MEAL_SERVED, {
    variables: { input: restaurant.id },
  });

  return (
    <div className={classes.metricContainer}>
      <section
        onClick={scroll}
        className={classes.subsection}
        style={{ cursor: "pointer" }}
      >
        <Typography component="span" variant="h5" className={classes.num}>
          {restaurant.overallReview.count < 10
            ? "< 20"
            : restaurant.overallReview.count}
        </Typography>
        <Typography component="span" variant="caption">
          reviews
        </Typography>
      </section>

      <section className={classes.subsection} style={{ cursor: "pointer" }}>
        <FollowersDialog restaurant={restaurant}>
          <FollowerButton />
        </FollowersDialog>
      </section>
      <section className={classes.subsection}>
        <Typography component="span" variant="h5" className={classes.num}>
          {loading ? "..." : data?.getRestaurantMealServedEstimate}
        </Typography>
        <Typography component="span" variant="caption">
          # of orders
        </Typography>
      </section>
    </div>
  );
};

const FollowerButton: React.FC<any> = ({ setOpen, loading, count }) => {
  const classes = useOverviewStyles();
  return (
    <div onClick={setOpen} className={classes.subsection}>
      <Typography component="span" variant="h5" className={classes.num}>
        {loading ? "..." : count}
      </Typography>{" "}
      <Typography component="span" variant="caption">
        followers
      </Typography>
    </div>
  );
};
