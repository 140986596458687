import { makeStyles } from "@material-ui/core";
import { COLORS } from "src/styles";

export const useStyles = makeStyles({
  container: {
    width: "46%",
    display: "flex",
    maxWidth: 320,
    justifyContent: "space-around",
  },
  input: {
    height: 36,
  },
  btn: {
    color: COLORS.WHITE,
    height: 36,
  },
});
