import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import React, { FC, useState } from "react";
import { useHistory } from "react-router";
import { useCreateChannel } from "src/components/chat2";
import { IEvent, IEventUser } from "src/models";
import { sharedAPI } from "src/shared-graphql";
import { COLORS } from "src/styles";
import { CANCEL_EVENT_USER, CANCEL_ORDER, NOTIFY_COOK } from "../api";
import { actionItemsStyles as useStyles } from "./action-items.styles";

interface IProps {
  handleOpenDialog: (type, e) => void;
  event: IEvent;
  currentOrder: IEventUser;
}

export const ActionItems: FC<IProps> = ({
  event,
  handleOpenDialog,
  currentOrder,
}) => {
  const [cancelEventUser] = useMutation(CANCEL_EVENT_USER, {
    refetchQueries: ["getMe"],
  });
  const [cancelOrder] = useMutation(CANCEL_ORDER, {
    refetchQueries: ["getMe"],
  });
  const [notifyCook] = useMutation(NOTIFY_COOK, {
    refetchQueries: ["getMe"],
  });

  const { createChannel, loading } = useCreateChannel();
  const classes = useStyles();

  const [notifyCookDialogOpen, setNotifyCookDialogOpen] = useState<boolean>(
    false
  );

  const cancel = currentOrder.eventId ? cancelEventUser : cancelOrder;
  const bindDialogHandler = (e: IEvent) => (
    type: "Manage" | "Complaint"
  ) => () => handleOpenDialog(type, e);
  const { push } = useHistory();

  const dialogHandlerFor = bindDialogHandler(event);

  const isAfterEvent = moment().isAfter(moment(Number(event.endTime)));

  const isBeforeCancellationPeriod =
    moment.duration(moment(Number(event.startTime)).diff(moment())).asHours() >
    24;

  if (isAfterEvent)
    return (
      <div className={classes.container}>
        <Button
          variant="outlined"
          color="primary"
          classes={{ root: classes.complainRoot }}
          onClick={dialogHandlerFor("Complaint")}
        >
          File Complaint
        </Button>
      </div>
    );
  else if (isBeforeCancellationPeriod)
    return (
      <div className={classes.container}>
        { <Button
          classes={{ containedSecondary: classes.editBtn }}
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => push(`/menus/${event.id}`)}
        >
          Order more
        </Button> }
        {
          <Button
            variant="outlined"
            onClick={() =>
              confirm(
                "Are you sure you want to cancel your order? The refund amount will be kept in your COOK Connect account for your next order. Please contact COOK Connect at support@cookalliance.org for a refund to your bank account or credit card."
              )
                ? cancel({ variables: { input: currentOrder.id } })
                    .then(() => {
                      push("/d");
                      sharedAPI.setSnackbarMsg({
                        type: "success",
                        msg: "Your Order was successfully cancelled",
                      });
                    })
                    .catch(() =>
                      sharedAPI.setSnackbarMsg({
                        type: "error",
                        msg: "Sorry, we could not cancel your order",
                      })
                    )
                : ""
            }
            color="primary"
            size="small"
          >
            Cancel order
          </Button>
        }
      </div>
    );
  else
    return (
      <>
        <div className={classes.container}>
          <Button
            classes={{ containedSecondary: classes.editBtn }}
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => push(`/menus/${event.id}`)}
          >
            Order more
          </Button>
          {/* We want to show notify cook only on event types that do not include FIXED_TIME and DELIVERY */}
          {!currentOrder?.dineOption?.includes("FIXED_TIME") && (
            <Button
              classes={{ containedSecondary: classes.editBtn }}
              variant="contained"
              color="secondary"
              size="small"
              disabled={currentOrder.checkedIn}
              onClick={() => {
                confirm(
                  "Are you sure you want to notify cook you that you are outside waiting for your food?"
                )
                  ? notifyCook({ variables: { input: currentOrder.id } })
                      .then(() => {
                        sharedAPI.setSnackbarMsg({
                          type: "success",
                          msg: "Cook has been notified! 🎉",
                        });
                        setNotifyCookDialogOpen(true);
                      })
                      .catch((e) => {
                        // In development this throws an error because for some
                        // reason it cannot convert to correct number format in backend
                        sharedAPI.setSnackbarMsg({
                          type: "error",
                          msg:
                            "Sorry, we could not notify the cook. Please contact us...",
                        });
                      })
                  : "";
              }}
            >
              {currentOrder.checkedIn ? "Cook Notified" : "Notify Cook"}
            </Button>
          )}
        </div>
        <div>
          <Typography
            variant="caption"
            component="p"
            style={{
              color: COLORS.RED,
              padding: "1rem",
            }}
          >
            **Orders cannot be canceled at this time.
          </Typography>
        </div>
        <Dialog
          onClose={() => setNotifyCookDialogOpen(false)}
          aria-labelledby="notify-cook-dialog"
          open={notifyCookDialogOpen}
          maxWidth="xs"
          fullWidth
          disableBackdropClick
        >
          <IconButton
            className={classes.closeBtn}
            onClick={() => setNotifyCookDialogOpen(false)}
          >
            <Close className={classes.closeIcon} />
          </IconButton>
          <DialogContent className={classes.dialogContent}>
            <Typography
              variant="subtitle1"
              component="h4"
              className={classes.text}
            >
              Your cook has been notified 😋
            </Typography>
            <Typography variant="body1" component="p" className={classes.text}>
              Sit back & relax… cook {event.restaurant.host.firstName} will come
              out with your food shortly!
            </Typography>
            <Divider className={classes.text} />
            <Typography variant="body1" component="p" className={classes.text}>
              For inquiries about your order:
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              className={classes.text}
              disabled={loading}
              onClick={() => createChannel(event.restaurant.host.id)}
            >
              {loading ? (
                <CircularProgress data-testid="spinner" />
              ) : (
                "Message cook"
              )}
            </Button>

            <Typography
              variant="caption"
              component="p"
              className={classes.text}
              style={{ color: "#6F6F6F" }}
            >
              Or
            </Typography>
            <Typography variant="body1" component="p">
              Contact Foodnome at
            </Typography>
            <Typography
              variant="body1"
              component="a"
              href={`tel:9518800422`}
              className={classes.phoneNumber}
            >
              (951)-880-0422
            </Typography>
          </DialogContent>
        </Dialog>
      </>
    );
};
