import { Badge, Card, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import { IDish } from "src/models";
import { summaryCardStyles as useStyles } from "./summary-card.styles";

interface EventDishesCardProps {
  dishes: IDish[];
  slug: string;
  isFixedTime: boolean;
}

export const EventDishesCard: React.FC<EventDishesCardProps> = ({
  dishes,
  slug,
  isFixedTime,
}) => {
  const classes = useStyles();
  return (
    <Badge
      classes={{
        colorPrimary: classes.badgeColorPrimary,
        root: classes.badgeRoot,
      }}
      component="div"
      color="primary"
      badgeContent={
        <Link
          data-testid="edit-dishes-link"
          className={classes.editLink}
          to={{
            pathname: `/c/menus/dishes/${slug}`,
            state: { from: `/c/menus/summary/${slug}` },
          }}
        >
          <Edit style={{ fontSize: "2rem" }} />
        </Link>
      }
    >
      <Card className={classes.card}>
        <Typography variant="h2" component="h2" className={classes.cardHeading}>
          Dishes
        </Typography>
        <ul className={classes.cardBody}>
          {dishes.map((d) => (
            <li
              style={{
                display: "flex",
                boxShadow: "1px 2px 9px 0 rgba(39,39,39,0.2)",
                margin: "1rem 0",
                minHeight: 100,
                maxHeight: 120,
                overflow: "hidden",
              }}
              key={d.id}
            >
              <img
                style={{ width: 115, objectFit: "cover" }}
                src={(!!d?.imagesGQL?.length && d.imagesGQL[0].small) as string}
              />
              <div style={{ flexGrow: 1, padding: "1rem" }}>
                <Typography
                  variant="h4"
                  component="h4"
                  style={{
                    fontFamily: "CustomFourBold",
                    fontWeight: 500,
                    margin: 0,
                    overflow: "hidden",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                  }}
                >
                  {d.name}
                </Typography>
                {!isFixedTime && (
                  <ul
                    style={{
                      margin: "1rem 0 0",
                      padding: 0,
                      listStyleType: "none",
                    }}
                  >
                    <li
                      style={{
                        fontFamily: "CustomFourBold",
                        fontWeight: 500,
                      }}
                    >
                      <Typography component="span" variant="body2">
                        Availability:{" "}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        style={{ fontFamily: "CustomFour" }}
                      >
                        {d.DishEvent && d.DishEvent.count}
                      </Typography>
                    </li>
                    <li
                      style={{
                        fontFamily: "CustomFourBold",
                        fontWeight: 500,
                      }}
                    >
                      <Typography component="span" variant="body2">
                        Price:{" "}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        style={{ fontFamily: "CustomFour" }}
                      >
                        ${d.DishEvent && d.DishEvent.price}
                      </Typography>
                    </li>
                  </ul>
                )}
              </div>
            </li>
          ))}
        </ul>
      </Card>
    </Badge>
  );
};
