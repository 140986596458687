import { makeStyles } from "@material-ui/core";
import { MapOptions } from "google-map-react";
import React, { useEffect } from "react";
import { COLORS } from "src/styles";
import { CloseMap, MapContainer } from "../map";
import { HeaderFilterMobile } from "./header-filter-mobile/header-filter-mobile";
import { RestaurantListMobile } from "./restaurant-list-mobile";

interface IProps {
  data: any;
  loading: boolean;
  search: any;
}

export const MobileView: React.FC<IProps> = React.memo(
  ({ data, loading, search }) => {
    const classes = useStyles();

    useEffect(() => {
      // We want to prevent scrolling on mobile, particularly IOS
      document.body.style.overflow = "hidden";

      return () => {
        document.body.style.overflow = "unset";
      };
    }, []);

    const defaultMapOptionsMobile: MapOptions = {
      fullscreenControl: false,
      disableDefaultUI: true,
      clickableIcons: false,
      minZoom: 6,
      maxZoom: 16,
    };
    return (
      // Inner height is to fit the map container inside
      // the inner window for IOS (not go under address bar and bottom footer)
      <div className={classes.container} style={{ height: window.innerHeight }}>
        {/* Component section. Put components you want to overlay on the map here */}
        <section className={classes.headerFilterMobileContainer}>
          <CloseMap
            style={{ position: "absolute", top: 0, left: "1.5rem" }}
            size="small"
          />
          <HeaderFilterMobile />
        </section>
        <section className={classes.mobileRestaurantsList}>
          <RestaurantListMobile />
        </section>

        {/* Map section */}
        <section className={classes.mobileContainter}>
          <MapContainer
            data={data}
            loading={loading}
            search={search}
            defaultOptions={defaultMapOptionsMobile}
          />
        </section>
      </div>
    );
  }
);

const useStyles = makeStyles({
  container: {
    // height: "calc(100vh - 56px)",
    display: "flex",
    borderRadius: "1rem",
    flexDirection: "row",

    // [`@media (max-width: ${BREAK_POINTS.tablet}em)`]: {
    //   height: "100vh",
    // },
  },
  mobileContainter: {
    // zIndex: 200,
    width: "100%",
    position: "relative",
  },
  mobileRestaurantsList: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translate(-50%, 0%)",
    zIndex: 300,
  },
  headerFilterMobileContainer: {
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translate(-50%, 0%)",
    zIndex: 300,
    width: "100%",
    boxShadow: "rgba(0, 0, 0, 0.28) 0px 8px 15px",
    marginTop: "2.7rem",
    backgroundColor: COLORS.WHITE,
  },
});
